import React from 'react';
import PropTypes from 'prop-types';
import BaseSettingForm from './base-setting-form.react';
import _ from 'lodash';
/**
 * Component for Select Options - Allows someone to define the options for a select field, in [{name: "Option Name", value: "Option Value"}] Format.
 * 
 * @class SelectOptions
 * @extends {Component}
 */
export default class SelectOptions extends BaseSettingForm {
	/**
	 * Creates instance of SelectOptions
	 * 
	 * @returns React
	 * 
	 * @memberof SelectOptions
	 */
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			value: ''
		};
		this._onChange = this._onChange.bind(this);
		this._renderList = this._renderList.bind(this);
		this._addNewRow = this._addNewRow.bind(this);
		this._handleNewInputChange = this._handleNewInputChange.bind(this);
		this._addRow = this._addRow.bind(this);
		this._removeOptionFromList = this._removeOptionFromList.bind(this);
	}
	/**
	 * Adds the local Row to the store 
	 * 
	 * 
	 * @memberof SelectOptions
	 */
	_addRow(){
		//new Values to add
		let newItemName = this.state.name;
		let newItemValue = this.state.value;
		
		//building Object to pass to parent: 
		let newSelectItem = {};
		newSelectItem['name'] = newItemName;
		newSelectItem['value'] = newItemValue;

		let {onChange, value, fieldSchemaName } = this.props;
		
		//Check Value type:
		if(!value){
			value = [];
		}

		let newValue = _.cloneDeep(value);
		//add the new Item:
		newValue.push(newSelectItem);


		let changeEvent = {
			target:{
				id: fieldSchemaName,
				value: newValue
			}
		};

		onChange(changeEvent);
		
		//Reset Values of state:
		this.setState({
			name: '',
			value: ''
		});
	}
	/**
	 * private handles changeEvent and bubbles up
	 * 
	 * @memberof SelectOptions
	 */
	_onChange(newItemValue, valueKey, index) {
		
		let {onChange, value, fieldSchemaName } = this.props;

		//Check Value type:
		if(!value){
			value = [];
		}
		//change the Item:
		let newValue = _.cloneDeep(value);
		newValue[index][valueKey]= newItemValue;
		let changeEvent = {
			target:{
				id: fieldSchemaName,
				value: newValue
			}
		};

		if(valueKey === 'default'){
			this.setState({
				default: valueKey
			});
		}
		// console.log('changeEvent', changeEvent);
		onChange(changeEvent);
	}
	/**
	 * Render the Select Options
	 * 
	 * @returns React
	 * 
	 * @memberof SelectOptions
	 */
	render() {
		let {value} = this.props;
		return(
			<div id="short-text-list">
				<div className="container-fluid">
					<div className="row">
						<table id="select-table" className="table table-striped text-center">
							<thead className="container">
								<tr className="row text-center">
									<th className="col-sm-5">Name</th>
									<th className="col-sm-5">Value</th>
									<th className="col-sm-2"></th>
								</tr>
							</thead>
							<tbody className="container">
								{this._renderList(value)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
	/**
	 * renders the rows of the list 
	 * 
	 * @returns 
	 * 
	 * @memberof SelectOptions
	 */
	_renderList(value){
		let listRows = [];
		//If the value is undefined or the list is emtpy 
		if(!value || value.length === 0) {
			//show empty row
			listRows.push(this._addNewRow(0));
		} else {
			listRows = value.map((item, index) => {
				// console.log(this.state);
				// console.log(item.default, item.default ? item.default : 0);

				return (
					<tr className="row text-center" key={index}>
					<td className="col-sm-5">
						<div className="input-form-container">
							<input
							className="form-control" 
							name='name' 
							onChange={({target : {value, name}} = event) => this._onChange(value, name, index)} 
							value={item.name}
							type="text" placeholder='Name, for example "Apple"'/>
						</div>
					</td>
					<td className="col-sm-5">
						<div className="input-form-container">
							<input
								className="form-control" 
								name='value' 
								onChange={({target : {value, name}} = event) => this._onChange(value, name, index)} 
								value={item.value}
								type="text" placeholder='Value, for example "a"'/>
						</div>
					</td>
					<td className="col-sm-1">
						<label className='form-check-label'>
							<input type='radio' value={item.default ? item.default : 0} name='default' checked={'checkedOption' === item.value} onChange={({target : {value, name}} = event) => this._onChange(value, name, index)}/>
						</label>
					</td>
					<td className="col-sm-1">
						<div className="text-center">
							<button type="button" className="close" aria-label="add" onClick={() => this._removeOptionFromList(index)}>
								<i className="fa fa-minus" aria-hidden="false"></i>
							</button>
						</div>
					</td>
				</tr>
				);
			});	
			let listLength = value.length;
			listRows.push(this._addNewRow(listLength));
		}

		return listRows;
	}
	/**
	 * Adds a new Row at the end of the list
	 * 
	 * @memberof SelectOptions
	 */
	_addNewRow(listLength){
		return(
			<tr className="row text-center" key={listLength}>
				<td className="col-sm-5">
					<div className="input-form-container">
						<input
						className="form-control" 
						name='name' 
						onChange={({target : {value, name}} = event) => this._handleNewInputChange(value, name)} 
						value={this.state.name}
						type="text" placeholder='Name, for example "Apple"'/>
					</div>
				</td>
				<td className="col-sm-5">
					<div className="input-form-container">
						<input
							className="form-control" 
							name='value' 
							onChange={({target : {value, name}} = event) => this._handleNewInputChange(value, name)} 
							value={this.state.value}
							type="text" placeholder='Value, for example "a"'/>
					</div>
				</td>
				<td className="ml-auto col-sm-1">
					<div className="text-center">
						<button type="button" className="close" aria-label="close" onClick={() => this._addRow()}>
						<i className="fa fa-plus" aria-hidden="false"></i>
						</button>
					</div>
				</td>
			</tr>
		);
	}
	/**
	 * handles Changes for new Rows. saves it to local state
	 * 
	 * @param {String} value - input value
	 * @param {String} name  - input name 
	 * 
	 * @memberof SelectOptions
	 */
	_handleNewInputChange(value, name){
		this.setState({
			[name] : value
		});
	}
	/**
	 * removes a synonym row from the list 
	 * 
	 * @param {String} synonymKey 
	 * 
	 * @memberof SelectOptions
	 */
	_removeOptionFromList(index){
		
		let {onChange, value, fieldSchemaName } = this.props;
		
		//Check Value type:
		if(!value){
			value = [];
		}

		let newValue = _.cloneDeep(value);
		//Remove the item
		newValue.splice(index, 1);

		let changeEvent = {
			target:{
				id: fieldSchemaName,
				value: newValue
			}
		};

		onChange(changeEvent);
	}


}

if ('development' === process.env.NODE_ENV) {
	SelectOptions.propTypes = {
		fieldSchemaName: PropTypes.string.isRequired,
		value: PropTypes.any,
		onChange: PropTypes.func
	};
}