import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import RecordSetConstants from '../constants/record-set-constants';
import ListConstants from '../constants/list-constants';
import ObjectUtils from '../utils/object-utils';
import uuid from 'uuid';

/**
 * Store to contain the record sets generated by fields on the page
 *
 * @class ListStore
 * @extends {ReduceStore}
 */
class ListStore extends ReduceStore {
	/**
	 * Initial state for ListStore
	 *
	 * @memberof ListStore
	 */
	getInitialState() {
		return Immutable.Map();
	}

	getRows(renderId) {
		let state = this.getState();
		return state && state.hasIn([renderId, 'rows']) ?
			state.getIn([renderId, 'rows']) :
			undefined;
	}
	getAsJS(renderId) {
		let state = this.getState();
		return state && state.has(renderId) ?
			state.get(renderId).toJS() :
			undefined;
	}
	getDependenciesNeedRecalculation(renderId) {
		let state = this.getState();
		return state && state.has(renderId) && state.hasIn([renderId, 'dependenciesNeedRecalculation']) ?
			state.getIn([renderId, 'dependenciesNeedRecalculation']) :
			false;
	}

	/**
	 * Gets whether or not the List row heights are marked for recalculation.
	 * 
	 * @param {string} renderId The renderId of the list
	 * @returns boolean
	 */
	getHeightsNeedRecalculation(renderId) {
		let state = this.getState();
		return state && state.has(renderId) && state.hasIn([renderId, 'heightsNeedRecalculation']) ?
			state.getIn([renderId, 'heightsNeedRecalculation']) :
			false;
	}


	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 * @ignore
	 *
	 * @memberOf ListStore
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			// We only listen to record sets which we're already tracking in the store
			// Otherwise it can get gnarly
			case RecordSetConstants.SET_RECORD_SET: {
				let renderId = action.get('renderId');
				// Avoid false positives from selected record set
				let setName = action.get('setName');
				let newState = state;
				if (state.has(renderId) && state.getIn([renderId, 'setName']) === setName) {
					let prev = state.get(renderId);
					
					let fieldsNeedLoading = prev.get('fieldsNeedLoading');
					let queryFields = prev.get('queryFields');
					let singleRelationshipFields = prev.get('singleRelationshipFields');
					let prevRows = prev.get('rows');
					// To be used for lookups in order to permit render ID reuse
					let prevMap = Immutable.Map().withMutations(prevMap => {
						if(prevRows) {
							prevRows.forEach(row => {
								prevMap.set(row.get('recordId'), row)
							});
						}
					});
					// let expressionFields = prev.get('expressionFields');
					// let parallelQueries = prev.get('parallelQueries');

					let rows = action.get('rows');
					let relatedRecords = action.get('relatedRecords');
					// We reference this when recalculating records + dispatch to the store with any results
					let recordSets = {};
					let newRows = Immutable.List().withMutations(newRows => {
						rows.forEach(row => {
							let recordId = row.get('recordId');
							let prevRow = prevMap.get(recordId);
							let tableSchemaName = row.get('tableSchemaName');
							let newRow = Immutable.Map().withMutations(newRow => {
								newRow.set('recordId', recordId);
								newRow.set('tableSchemaName', tableSchemaName);
								if (fieldsNeedLoading) {
									// Start by marking all of the fields which may require loading as loading
									fieldsNeedLoading.forEach(fieldSchemaName => {
										let prevRenderId = prevRow && prevRow.hasIn([fieldSchemaName, 'renderId']) ? 
											prevRow.getIn([fieldSchemaName, 'renderId']) :
											null;
										newRow.set(fieldSchemaName, Immutable.Map({
											isLoading: true
										}));
										if(prevRenderId) {
											newRow.setIn([fieldSchemaName, 'renderId'], prevRenderId);
										}
									});
								}
								if (queryFields) {
									queryFields.forEach((queryField) => {
										let order = queryField.get('order');
										let fieldSchemaName = queryField.get('fieldSchemaName');
										let dataType = queryField.get('dataType');
										let includeInRows = queryField.get('includeInRows');
										let prevRenderId = prevRow && prevRow.hasIn([order + '-' + fieldSchemaName, 'renderId']) ? 
											prevRow.getIn([order + '-' + fieldSchemaName, 'renderId']) :
											null;
										// If it's not a relationship field, push everything in normally
										if (dataType !== 'relationship' && includeInRows !== false) {
											let value = row.get(fieldSchemaName);
											if((dataType === 'multipart' || dataType === 'file') && value) {
												// Parse the value if it's a multipart field
												value = Immutable.fromJS(ObjectUtils.getObjFromJSON(value));
											}
											newRow.set(order + '-' + fieldSchemaName, Immutable.fromJS({
													// The field's actual value (not necessarily the 'display' value)
													value: value,
													// We use this value for sorting, etc., and populate the record set store as appropriate
													displayValue: value,
													// Establish a renderId here for each cell that we will then just use
													renderId: prevRenderId || uuid.v4(), // Look this up from the old render ID to reuse where possible!
													dataRecordId: recordId,
													dataTableSchemaName: tableSchemaName
												}));
										}
									});
								}
								if (singleRelationshipFields) {
									singleRelationshipFields.forEach(singleRelationshipField => {
										let resultTableSchemaName = singleRelationshipField.get('resultTableSchemaName');
										let fieldSchemaName = singleRelationshipField.get('fieldSchemaName');
										let fieldId = singleRelationshipField.get('fieldId');
										let viewFieldSchemaName = singleRelationshipField.get('viewFieldSchemaName');
										let relationSchemaName = singleRelationshipField.get('relationSchemaName');
										let relatedRecordId = row.get(relationSchemaName);
										let relationValueObj = relatedRecords && relatedRecords.hasIn([resultTableSchemaName, relatedRecordId]) ?
											relatedRecords.getIn([resultTableSchemaName, relatedRecordId]) :
											Immutable.Map();
										let relatedRecordValue = relationValueObj.get(viewFieldSchemaName);
										// Reuse render IDs where possible
										let cellRenderId = prevRow && prevRow.hasIn([fieldSchemaName, 'renderId']) ? 
											prevRow.getIn([fieldSchemaName, 'renderId']) :
											null;
										cellRenderId = cellRenderId || uuid.v4();

										// To build the value of dynamic selection fields
										let recordJSON = JSON.stringify([{
											recordId: relatedRecordId,
											tableSchemaName: resultTableSchemaName
										}])
	
										newRow.set(fieldSchemaName, Immutable.Map({
											// Calculate 'value' appropriately according to the value format for dynamic selection fields
											value: JSON.stringify({
												newRecordJSON: recordJSON,
												startingRelatedRecordsJSON: recordJSON
											}),
											// We use this value for sorting, etc., and populate the record set store as appropriate
											displayValue: relatedRecordValue,
											// Establish a renderId here for each cell that we will then just use
											renderId: cellRenderId,
											dataRecordId: recordId,
											dataTableSchemaName: tableSchemaName,
											waitForRecordSets: true
										}));
	
										// Also push into the record sets
										recordSets[cellRenderId] = recordSets[cellRenderId] || {};
										recordSets[cellRenderId][fieldId + '-original'] = {
											setName: fieldId + '-original',
											uiName: 'Original User Selected Record(s)',
											renderId: cellRenderId,
											tableSchemaName: resultTableSchemaName,
											recordSetArray: [relatedRecordId]
										};
										recordSets[cellRenderId][fieldId + '-selected'] = {
											setName: fieldId + '-selected',
											uiName: 'Current User Selected Record(s)',
											renderId: cellRenderId,
											tableSchemaName: resultTableSchemaName,
											recordSetArray: [relatedRecordId]
										};
									});
								}
							});
							newRows.push(newRow);
						});
					});
					newState = state.set(renderId, prev.withMutations(prev => {
						prev.set('rows', newRows);
						prev.set('dependenciesNeedRecalculation', true);
						prev.set('recordSets', Immutable.fromJS(recordSets));
						prev.set('recordSetRows', rows);
						prev.set('lastDt', action.get('lastDt'));
					}));
				}
				return newState;
			}

			// Initialize the List with the render ID (so we know to watch its record sets)

			case ListConstants.INIT_LIST: {
				let renderId = action.get('renderId');
				let rows = action.get('rows');
				let setName = action.get('setName');
				let fieldsNeedLoading = action.get('fieldsNeedLoading');
				let queryFields = action.get('queryFields');
				let expressionFields = action.get('expressionFields');
				let singleRelationshipFields = action.get('singleRelationshipFields');
				let parallelQueries = action.get('parallelQueries');
				let lastDt = action.get('lastDt');
				let mergeIn = action.get('mergeIn');
				let prevLastDt = state.hasIn([renderId, 'lastDt']) ? state.getIn([renderId, 'lastDt']) : null;
				let heightsNeedRecalculation = !mergeIn;
				// Only update if this is the first time the store is seeing this List OR if it's a more recent dispatch
				// Otherwise, we can run into timing issues
				if(!state.has(renderId) || lastDt >= prevLastDt) {
					let oldRows = state.hasIn([renderId, 'rows']) ? state.getIn([renderId, 'rows']) : undefined;
					if(mergeIn && oldRows) {
						let newRows = rows;
						// Build a dict for new rows lookup
						// let oldRowsDict = {};
						// oldRows.forEach((val) => oldRowsDict[val.get('recordId')] = val);
						let newRowsDict = {};
						rows.forEach((val) => newRowsDict[val.get('recordId')] = val);
						// Basically, we're trying to preserve reference equality so long as nothing has changed
						// to avoid unsightly row refresh flickering
						// but if anything has materially changed, we trigger a refresh
						if(oldRows.size === newRows.size) {
							let newRows = rows;
							let recordMismatch = false;
							rows = oldRows.withMutations((oldRows) => {
								oldRows.forEach((row, index) => {
									let recordId = row.get('recordId');
									let newRow = newRowsDict[recordId];
									if(newRows.get(index) !== newRow) {
										// Order has not changed
										recordMismatch = true;
									} else if(newRow) {
										let oldRow = row.withMutations(oldRow => {
											newRow.forEach((value, key) => {
												oldRow.set(key, value);
											});
										});
										oldRows.set(index, oldRow);
									}
								});
							});
							if(recordMismatch) {
								// Forget it; just refresh the whole thing
								rows = newRows;
							}
						}
						// else {
							// At this point a full list refresh is fine
							// rows = rows.withMutations((rows) => {
							// 	rows.forEach((newRow, index) => {
							// 		let recordId = newRow.get('recordId');
							// 		let oldRow = oldRowsDict.recordId;
							// 		if(oldRow) {
							// 			rows.set(recordId, oldRow.withMutations(oldRow => {
							// 				newRow.forEach((value, key) => {
							// 					oldRow.set(key, value);
							// 				});
							// 			}));
							// 		}
							// 	});
							// });
						// }
					}
					let toSet = Immutable.fromJS({
						rows: rows,
						setName: setName,
						fieldsNeedLoading: fieldsNeedLoading,
						queryFields: queryFields,
						expressionFields: expressionFields,
						singleRelationshipFields: singleRelationshipFields,
						parallelQueries: parallelQueries,
						recordSets: action.get('recordSets'), // We use this when recalculating our dependencies + dispatching record sets
						recordSetRows: action.get('recordSetRows'),
						lastDt: lastDt,
						// We mark this as false because we'll be doing our own dependency recalculations and don't want List to try to fire another one off
						// The reason we don't just let List handle this is that we want to start our parallel queries processing in parallel with our init utility method
						dependenciesNeedRecalculation: false
					});
					if(mergeIn) {
						toSet = toSet.set('heightsNeedRecalculation', heightsNeedRecalculation);
					}
					let newState = state.set(renderId, toSet);
					return newState;
				} else {
					return state;
				}
			}

			case ListConstants.SET_LIST_ROWS: {
				let renderId = action.get('renderId');
				let lastDt = action.get('lastDt');
				let prevLastDt = state.hasIn([renderId, 'lastDt']) ? state.getIn([renderId, 'lastDt']) : null;
				// Only update if this is the first time the store is seeing this List OR if it's a more recent dispatch
				// Otherwise, we can run into timing issues
				if(!state.has(renderId) || lastDt >= prevLastDt) {
					return state.mergeIn([renderId], action.delete('renderId'));
				} else {
					return state;
				}
			}

			case ListConstants.SET_REFS: {
				let renderId = action.get('renderId');
				let refs = action.get('refs');
				if(state.has(renderId)) {
					return state.setIn([renderId, 'refs'], refs).setIn([renderId, 'heightsNeedRecalculation'], true);
				} else {
					return state;
				}
			}

			case ListConstants.SET_ROW_HEIGHTS: {
				let renderId = action.get('renderId');
				let heights = action.get('heights');
				let rows = state.getIn([renderId, 'rows']);
				rows = rows.withMutations(rows => {
					rows.forEach((row, rowIndex) => {
						let recordId = row.get('recordId');
						let rowHeights = heights.get(recordId);
						let rowHeightsArr = [];
						if(rowHeights) {
							rowHeights.map(rowHeight => rowHeightsArr.push(rowHeight));
						}
						let rowHeight = rowHeightsArr.length ? Math.max(...rowHeightsArr) : 0;
						if(rowHeight) {
							row = row.set('rowHeight', rowHeight);
							rows.set(rowIndex, row);
						}
					});
				});
				let newState = state.withMutations(state => {
					state.setIn([renderId, 'rows'], rows);
					state.setIn([renderId, 'heightsNeedRecalculation'], false);
				});
				return newState;
			}

			case ListConstants.DELETE_LIST: {
				let renderId = action.get('renderId');
				return state.delete(renderId);
			}

			default: {
				return state;
			}
		}
	}
}

const instance = new ListStore(AppDispatcher);
export default instance;