const DataTypes = {
	SHORT_TEXT: 'shortText',
	LONG_TEXT: 'longText',
	NUMBER: 'number',
	MULTIPART: 'multipart',
	FILE: 'file',
	EXPRESSION: 'expression'
};

module.exports = DataTypes;
