import React from 'react';
import _ from 'lodash';
import BaseSettingForm from './base-setting-form.react';
/**
 * Component for Number
 * 
 * @class Number
 * @extends {Component}
 */
export default class Number extends BaseSettingForm {
	/**
	 * Render the Number
	 * 
	 * @returns React
	 * 
	 * @memberof Number
	 */
	render() {
		let {onChange, fieldSchemaName, maxWidth, value, disabled, min} = this.props;
		let style = maxWidth ? {'maxWidth' : maxWidth } : {};

		return(
			<input style={style} value={value} id={fieldSchemaName} disabled={disabled} min={min} className="form-control m-auto" type="number" onChange={onChange} />
		);
	}
}
