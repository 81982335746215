/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';
import {
	AdminSettingsActions,
	InterfaceActions,
	MetadataActions,
	LogicFunctionActions
} from '../../../actions';
import { AdminSettingsStore, FieldStore, MetadataStore, ContextStore } from '../../../stores';

// Utils
import { 
	LogicUtils, 
	UIUtils,
	ObjectUtils,
	ActionProcessor,
	BlocklyUtils
} from '../../../utils';

class ScheduledLogicSettingsChooser extends Component {
	/**
	 * Creates instance of ScheduledLogicSettingsChooser
	 */
	constructor(props) {
		super(props);
		this._getSettingsList = this._getSettingsList.bind(this);
		this._onDeleteHandler = this._onDeleteHandler.bind(this)
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, FieldStore, MetadataStore];
	}

	/**
	 * Calculate the current state of this component
	 * @static
	 * @param {Object} prevState 
	 * @param {Object} prevState 
	 * @returns {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId = AdminSettingsStore.getRecordId();
		let tableSchemaName = AdminSettingsStore.getTableSchemaName();
		let componentObj = MetadataStore.get(recordId, 'scheduledLogic') || {};
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let componentSettings = [];

		let scheduledSettings =
			[
				{ recordId: '97b28c00-147a-4ea2-9c15-8658e575083d',
				  sortOrder: 0  }, // Name
				{ recordId: '79be6552-3504-42b4-8dca-a38238fce0e4',
				  sortOrder: 0, valueWrappingClass: 'setting-overflow-show-all' }, // Schedule
				{ recordId: '7e76cecf-b2f9-4c11-b302-c37d9b2e6822',
				  sortOrder: 0 }, // Logic
				{ recordId: 'b85c19ea-8d62-4829-b040-1c6892300dab',
				  sortOrder: 1  }, // High Memory
				{ recordId: '6baf0a50-569a-4f1a-92d3-0c67321c29de',
				  sortOrder: 2, valueWrappingClass: 'setting-overflow-show-all'  }, // Run Duration
			];

		scheduledSettings.forEach(fieldTypeSetting => {
			let settingId = fieldTypeSetting.recordId;
			let settingObj = FieldStore.get(settingId) || {};
			let settingSchemaName = settingObj.fieldSchemaName;

			// Empty/No Value values.
			let valueObj = {};
			if (componentObj[settingSchemaName]) {
				valueObj.value = componentObj[settingSchemaName];
			}

			componentSettings.push({
				// Basics
				fieldId: settingId,
				fieldObj: settingObj,

				// Label, Value and Sorting..
				label: settingObj.fieldLabel,
				valueObj: valueObj,
				sortOrder: fieldTypeSetting.sortOrder,

				// Used for styling
				settingSelected: (selectedSetting === settingSchemaName),
				valueWrappingClass: (fieldTypeSetting.valueWrappingClass ? fieldTypeSetting.valueWrappingClass : '')
			});
		});

		// Sort the results by setting name
		componentSettings.sort(function (a, b) {
			if (a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			return 1;
		});

		return {
			componentSettings: componentSettings,
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			settingsHidden: AdminSettingsStore.getSettingsListHidden(),
		}
	}

	/**
	 * Render the component
	 * @returns JSX
	 */
	render() {
		let { componentSettings } = this.state;

		if (!componentSettings.length) {
			return (
				<div key="settings" className='settings-list-wrapper'>
					<div style={{ marginTop: '8px' }} className='no-settings-found'>No Settings Found</div>
				</div>
			);
		}

		let settingsList = this._getSettingsList(componentSettings); // component settings

		//<button key="run" style={{ width: '60px' }} className="btn btn-secondary btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Run" onClick={this._onRunScheduledLogic}>Run</button>,
		let buttons = [
			<button 
				id={'scheduledLogicSave'}
				key="submit" 
				className="btn btn-primary btn-lg setting-list-button mr-0 d-flex justify-content-center" 
				form="appearance-form" 
				aria-label="Save" 
				title="Save (Ctrl-S)" 
				onClick={LogicUtils.saveScheduledLogic}>
					Save
				</button>,			
			<button 
				id={'scheduledLogicReset'}
				key="reset" 
				className="btn btn-warning btn-lg setting-list-button mr-0 d-flex justify-content-center" 
				form="appearance-form" 
				aria-label="Reset" 
				onClick={LogicUtils.resetScheduledLogic}>
					Reset
				</button>,
			<button 
				key="delete" 
				className="btn btn-danger btn-lg setting-list-button mr-0 d-flex justify-content-center" 
				form="appearance-form" 
				aria-label="Delete" 
				onClick={this._onDeleteHandler}>
					Delete
				</button>
		];
		if(this.props.disabledRetailRestrictions) { 
			buttons = [<button 
				key="submit" 
				className="btn btn-success btn-lg setting-list-button mr-3 ml-1" 
				aria-label="Upgrade to use Schedule's"
				style={{ width: '100%'}}
				onClick={this.props.onUpgradeClick}>
				Upgrade to use Schedule's
			</button>]
		}

		let componentIcon = ContextStore.getUrlMedia() + "/icon-logic.svg";

		return [
			<div key="settings" className='settings-list-wrapper'>
				<ul key="list" className="nav flex-column">
					<li className="settings-list-header">
						<div className="d-flex">
							<img height="22" width="22" style={{ marginTop:'0.40rem' }} className="mr-2" src={componentIcon} alt="" />
							<h3>Schedule Settings</h3>
						</div>
					</li>
					{ settingsList }
				</ul>
			</div>,
			<div key="buttons" className="btn-wrapper appearance-btn-wrapper">
				{buttons}
			</div>
		];
	}

	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @returns { array } array of <li> settings elements
	 */
	_getSettingsList(settingsArr) {

		let { fieldType } = this.state;

		// iterate over the settings and return an array of list items
		return settingsArr.map(setting => {
			let labelClassNames = 'setting-label';
			if (setting.settingSelected) {
				labelClassNames += ' selected';
			}

			let componentName = FieldStore.getDefaultVariantComponentName(
				setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
			let componentProps = setting.fieldObj;
			componentProps.value = setting.valueObj.value;
			componentProps.fieldType = fieldType;

			// These props will only be used by the fake table setting
			componentProps.customExpressionResult = setting.customExpressionResult;
			componentProps.renderAsHTML = setting.renderAsHTML;

			let valueDisplay = <h5 className={`settings-list-value ${componentName} ${setting.valueWrappingClass}`}>
					{(componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null)}
				</h5>;

			// Clear the value for logic
			if(setting.fieldId === '7e76cecf-b2f9-4c11-b302-c37d9b2e6822') {
				valueDisplay = componentProps.value && componentProps.value.includes('js')
					? <span className='fa fa-check'><span className='sr-only'>(Has Logic)</span></span> 
					: null;
			}

			return (<li key={setting.fieldId} className={"nav-item d-flex flex-column justify-content-center " + (setting.settingSelected ? 'setting-selected' : '')}>
				<div className="nav-link" onClick={this._onSettingClick.bind(this, setting.fieldId)}>
					{/* Temporarily removed the following div */}
					{/* <div className={"setting-icon " + iconClassNames} /> */}
					{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
					{/* Add this back in when the setting-icon is used again */}
					<div className="d-flex setting-text-container">
						<div className="w-50 setting-label-container">
							<div className={labelClassNames}><h4>{setting.label}</h4></div>
							<div className="setting-pattern-list">{setting.mostRecentPattern}</div>
						</div>
						<div className="w-50 d-flex justify-content-end setting-value-container">
							<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
								{valueDisplay}
							</div>
							{setting.valueObj.source ?
								<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}>
									{setting.valueObj.source}
								</div>
								: null
							}
						</div>
					</div>
				</div>
			</li>);
		});
	}

	/**
	 * _onDeleteHandler - Deletes the object from the store and the database.
	 *
	 * @param  {object} event
	 */
	_onDeleteHandler(event) {
		event.preventDefault();
		let { recordId } = this.state;
		let confirmRemove = confirm('Are you sure you want to delete this Schedule?');
		if(confirmRemove) {
			// Display notification to user
			let id = InterfaceActions.stickyNotification({ 'level': 'warning', 'message': 'Deleting Schedule...' });
			// Push to database
			MetadataActions.deleteFromDatabasePromise(recordId, 'scheduledLogic').then(() => {
				InterfaceActions.clearStickyNotification(id);
				UIUtils.closeSettingsPanel();
				MetadataActions.deleteFromStore(recordId, 'scheduledLogic');
			}).catch(error => {
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to delete Schedule' });
				console.error('Unable to delete Schedule:', error);
			})		
		}
	}

	/**
	 * _onResetHandler - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event
	 */
	_onResetHandler(event) {
		event.preventDefault();
		let { recordId } = this.state;
		let currentObj = MetadataStore.get(recordId, 'scheduledLogic');
		if(currentObj && currentObj.logic) {
			let logicObj = ObjectUtils.getObjFromJSON(currentObj.logic);
			// Handle converting logicFunctionsUsed into an array
			let logicFunctionIdsArr =[]; 
			let logicFunctionIds = logicObj.logicFunctionsUsed;
			if (logicFunctionIds) {
				if(Array.isArray(logicFunctionIds)) {
					logicFunctionIdsArr = logicFunctionIds;
				} else {
					logicFunctionIdsArr = logicFunctionIds.split(',');
				}
			}
			if (logicFunctionIdsArr && logicFunctionIdsArr.length) {
				InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting all functions used within this trigger to their saved values...' });
				logicFunctionIdsArr.forEach(logicFunctionId => {
					LogicFunctionActions.pullFromDatabase(logicFunctionId);
				});
			}
		}
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Resetting Schedule...' });
		AdminSettingsActions.onSettingChange('', ''); // Temporary workaround pending further reset work
		// Pull from database, therefore resetting it
		MetadataActions.pullFromDatabase(recordId, 'scheduledLogic');
	}

	/**
	 * Execute a piece of scheduled logic, now.
	 *
	 * @param {Object} event
	 * @memberof LogicMapContainer
	 */
	_onRunScheduledLogic(event) {
		event.preventDefault();
		let { recordId } = this.state;

		let isDirty = false;
		let recordObj = MetadataStore.getFull(recordId, 'scheduledLogic');
		if(recordObj) {
			recordObj.forEach((value, key) => {
				// @TODO: Should we limit this so it's only marked as dirty if the logic is different?
				isDirty = isDirty || value.get('isDirty');
			});
		}

		if(isDirty) {
			InterfaceActions.notification({message: 'This logic has unsaved changes. Processing logic from the last saved version.', level: 'warning'});
		}
		let actionRequest = {
			'actionRecordId': recordId,
			'actionTableSchemaName': 'scheduledLogic',
			'hookId': 'scheduledLogic'
		}

		InterfaceActions.notification({message: 'Scheduled Action Running...'});
		ActionProcessor.processAction(actionRequest).then(result => {
			if (result.message === "Complete") {
				InterfaceActions.notification({
					message: 'Scheduled Action Complete',
					level: 'success'
				});
			} else {
				InterfaceActions.notification({
					message: 'Scheduled Action Failed',
					level: 'error'
				});
			}
		}).catch(error => {
			console.error(error);
		});
	}
	

	/**
	 * _onSaveHandler - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	_onSaveHandler(event) {
		event.preventDefault();
		let { recordId } = this.state;
		let id = InterfaceActions.stickyNotification({ 'level': 'success', 'message': 'Updating Schedule...' });
		let metaRecord = MetadataStore.get(recordId, 'scheduledLogic', true);
		// We need to evaluate any logic functions which may have changed
		let logicObj = ObjectUtils.getObjFromJSON(metaRecord.logic);
		if(logicObj && logicObj.blocklyxml) {
			delete logicObj.forceDirty;
			// Regenerate the JS
			let params = {
				defaultToNull: true,
				memUse: logicObj.memUse ? logicObj.memUse : 'l',
				includeJs: true
			};

			
			BlocklyUtils.saveAutomationFromWorkspaceV2(BlocklyUtils.getWorkspaceFromXml(logicObj.blocklyxml), params, (blocklyValueObj) => {
				metaRecord.logic = JSON.stringify(blocklyValueObj);
				MetadataActions.pushToStore(recordId, 'scheduledLogic', metaRecord);
			});
		}
		// Only changed something not involving logic; just push to database
		MetadataActions.pushToDatabasePromise(MetadataStore.get(recordId, 'scheduledLogic', true), 'scheduledLogic').then(() => {
			InterfaceActions.clearStickyNotification(id);
		}).catch(error => {
			InterfaceActions.clearStickyNotification(id);
			InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save Schedule' });
			console.error('Unable to save Schedule:', error);
		});
	}


	/**
	 * Click on a Setting and update the admin settings store.
	 * 
	 * @param {string} settingFieldId
	 * @param {Object} event 
	 */
	_onSettingClick(settingFieldId, event) {
		event.preventDefault();

		let settingFieldObj = FieldStore.get(settingFieldId);
		let settingSchemaName = settingFieldObj.fieldSchemaName;

		// Toggle to select and deselect the Setting
		if(settingSchemaName === 'logic') {
			// If logic is already selected
			if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
				// Expand the Settings Panel
				AdminSettingsActions.onSettingsListHideChange(true);
			} else {
				// Hide the Settings Panel
				AdminSettingsActions.onSettingsListHideChange(true);
				AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
			}
		} else {
			// Expand the Settings Panel
			AdminSettingsActions.onSettingsListHideChange(false);
			if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
				AdminSettingsActions.onSettingChange('', '');
			} else {
				AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
			}
		}
	}
}
const container = Container.create(ScheduledLogicSettingsChooser);
export default container;