import React from 'react';
import _ from 'lodash';
import AceEditor from 'react-ace';
import BaseSettingForm from './base-setting-form.react';
// import 'brace/mode/jsx';
// import 'brace/theme/chrome';
// import 'brace/theme/monokai';
// import 'brace/ext/searchbox';

// import 'ace-builds/src-noconflict/theme-solarized_dark';

/**
 * Component for CodeEditor
 * 
 * @class CodeEditor
 * @extends {Component}
 */
export default class CodeEditor extends BaseSettingForm {
	/**
	 * Creates an instance of CodeEditor
	 * 
	 * @param {Object} props
	 * @constructor
	 * 
	 * @memberof CodeEditor
	 */
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	/**
	 * Handles and bubble onChange event from AceEditor
	 */
	onChange(value) {
		let {onChange, fieldSchemaName} = this.props,
			changeEvent = {
				target: {
					id: fieldSchemaName,
					value: value
				}
			};

		if(onChange) {
			onChange(changeEvent);
		}
	}

	/**
	 * Render the CodeEditor
	 * 
	 * @returns React
	 * 
	 * @memberof CodeEditor
	 */
	render() {
		let {fieldSchemaName, value, readOnly} = this.props;
		// let {fieldSchemaName, theme, value, readOnly} = this.props;
		return(
			<AceEditor 
				mode="jsx" 
				theme={'solarized_dark'} 
				id={fieldSchemaName} 
				className="form-control code-block indentation" 
				value={value} 
				onChange={this.onChange} 
				height="95%" 
				width="100%" 
				editorProps={{$blockScrolling: Infinity}}
				readOnly={readOnly}
				setOptions={
					{fontFamily: 'monospace'}
				}
				/>
		);
	}
}