import InterfaceActions from '../actions/interface-actions';
import ContextStore from '../stores/context-store';
import socketFetcher from './socket-fetcher';
import RecordStore from '../stores/record-store';
import RecordActions from '../actions/record-actions';
import BrowserStorageStore from '../stores/browser-storage-store';
import AuthenticationStore from '../stores/authentication-store';
import FieldStore from '../stores/field-store';
import RecordVariableUtils from './record-variable-utils';
import ProcessorUtils from './processor-utils.js';
import pointInPolygon from 'robust-point-in-polygon';
import ObjectUtils from './object-utils';

let _metadataBuilder = null;

//Used in expression processing
// eslint-disable-next-line
import moment from 'moment-timezone';
// eslint-disable-next-line
let numberToWords = require('number-to-words');
// eslint-disable-next-line
let co = require('co');
// eslint-disable-next-line
let async = require('async');

// used when generating secure key/UUID
import uuid from 'uuid';
let randomBytes = require('crypto').randomBytes;

let citdevUrlCommunity = ContextStore.getUrlCommunity();
let expressionPath = '/expressions-configuration.json';

let globalCitDev = {
	loadExpression: function(functionName, functionBody) {
		var compiledFunction = null;
		var wrappedFunction = 'compiledFunction = (function () {'+
			'var args = new Array(arguments.length); '+
			'for(var i = 0; i < args.length; ++i) { '+
			'  args[i] = arguments[i]; '+
			'} '+
			'let citDev = this; ' +
			'return new Promise(function(resolve, reject) { '+
			'  Promise.all(args).then(function(inputs) {'+
			'    var output = null;'+
			functionBody+
			'}).catch(function(err) { reject(err); }); '+
			'});})';

		try {
			// If our browser does not support generators (IE11.. grumble.. ) then babel the code.
			if(!window.supportsGenerators) {
				/*global Babel*/
				wrappedFunction = Babel.transform(wrappedFunction,{ presets: ['es2015','react'] }).code;
			}

			// eslint-disable-next-line
			eval(wrappedFunction);
		} catch (e) {
			console.warn('Expression Compile Error for Expression Function ' + functionName + ': ' + e.message);
			return false;
		}

		globalCitDev.functions[functionName] = compiledFunction;
		return true;
	},
	getExpressions: function(configPath) {
		return new Promise(function(resolve, reject) {
			fetch(configPath, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json; charset=UTF-8'
				}
			}).then(function (response) {
				resolve(response.json());
			}).catch(function (error) {
				reject(error);
			});
		});	
	},
	functions: {}
}

// Expressions need to be loaded from the CDN, watch on context store
// ensures that we don't attempt to load them before having the CDN url
if (citdevUrlCommunity) {
	expressionPath = citdevUrlCommunity + expressionPath;
} else {
	let subscription = ContextStore.addListener(function() {
		citdevUrlCommunity = ContextStore.getUrlCommunity();
		if (citdevUrlCommunity) {
			expressionPath = citdevUrlCommunity + expressionPath;
			subscription.remove();

			//Pre load the expressions:
			globalCitDev.getExpressions(expressionPath).then(function(results) {
				Object.keys(results).forEach(function(expressionKey){
					globalCitDev.loadExpression(results[expressionKey].functionName, 
						results[expressionKey].blockJs);
				});
			}).catch(function(err) {
				console.error('Expression Load Error: '+err.message);
			});
		}
	});
}

// let scriptCache = {};
let ExpressionObj = {
	processExpression: function(expressionRequest) {
		return new Promise(function(resolve, reject) {
			let citDev = {
				callFunction: function() {
					var functionName = arguments[0],
						args = new Array(arguments.length - 1);

					for(var i = 0; i < args.length; ++i) {
						args[i] = arguments[i+1];
					} 

					if (!globalCitDev.functions[functionName]) {
						return new Promise(function(resolve, reject) {
							globalCitDev.getExpressions(citdevUrlCommunity + '/expressions-configuration.json').then(function(results) {
								Object.keys(results).forEach(function(expressionKey){
									globalCitDev.loadExpression(results[expressionKey].functionName, 
										results[expressionKey].blockJs);
								});
								if (globalCitDev.functions[functionName]) {
									globalCitDev.functions[functionName].apply(citDev, args).then(function(result) {
										resolve(result);
									}).catch(function (err) {
										reject(err);
									});
								} else {
									reject('Could Not Load Expression Function: '+functionName);
								}
							}).catch(function(err) {
								console.error(err);
								reject(err);
							});
						});
					} else {
						return globalCitDev.functions[functionName].apply(this, args);
					}
				},
				callService: ProcessorUtils.callService.bind(this, expressionRequest.installationId || ContextStore.getInstallationId(), expressionRequest.renderId),
				getUserAgent: ProcessorUtils.getUserAgent.bind(this),
				returnOutput: function(output, error) {
					if (!error) {
						resolve(output);
					} else {
						reject(error);
					}
				},
				getFieldValue: function(fieldSchemaName, recordId, tableSchemaName, fieldPart, fieldId, fieldType, useInterface) {
					if (!fieldSchemaName) {
						console.error('getFieldValue called with no fieldSchemaName');
						return Promise.reject(new Error('getFieldValue called with no fieldSchemaName'));
					}
					
					if (!recordId || !tableSchemaName) {
						return Promise.resolve(null);
					}
					let valueObj = RecordStore.getValueByFieldSchemaName(tableSchemaName, recordId, fieldSchemaName);

					// If it's already in the store
					if (typeof valueObj === 'object' && valueObj !== null) {
						let fieldValue = (valueObj.get('isDirty') && !useInterface) ? valueObj.get('originalValue') : valueObj.get('value');
						// And we have a part..
						if(fieldPart) {
							// Read the JSON and Parse it if we have a value
							let fieldValueObj = {};
							if (fieldValue) {
								try {
									fieldValueObj = JSON.parse(fieldValue);
								} catch(error) {
									console.warn('Invalid multipart field value', fieldValue, 
										'from record:', recordId, 'in field:', fieldSchemaName, '. Continuing with null.');
								}
							}

							// return the part
							return Promise.resolve(fieldValueObj && fieldValueObj[fieldPart] ? fieldValueObj[fieldPart] : null);
						} else {
							// No part?  Just resolve the value.
							return Promise.resolve(fieldValue);
						}
					} else {
						return new Promise(function(resolve, reject){
							
							// Loop over the fieldSchemaNames, and remove/store those that are dataType === none
							let fieldSchemaNamesWithoutData = [];
							let requestFields = [];
							
							let fieldId = FieldStore.getFieldId(fieldSchemaName, tableSchemaName);
							let fieldObj = FieldStore.get(fieldId);
							if(!fieldId || (fieldId && !FieldStore.getHasData(fieldId))) {
								fieldSchemaNamesWithoutData.push(fieldSchemaName);
							} else {
								requestFields.push({
									fieldSchemaName: fieldSchemaName, 
									fieldType: fieldObj.fieldType,
									fieldId: fieldId
								});
							}

							if (requestFields.length) {
								//Do service call for field value
								socketFetcher('gw/recordRead-v4', JSON.stringify({
									recordId: recordId,
									tableSchemaName: tableSchemaName,
									fields: requestFields
								})).then(function(result){
									if (result.responseCode === 200) {
										RecordActions.onDataLoaded(result.response);
										if(fieldPart) {
											let response = result.response;
											if (
												result.response &&
												result.response[tableSchemaName] && 
												result.response[tableSchemaName][recordId] &&
												result.response[tableSchemaName][recordId][fieldSchemaName]
											) {
												let fieldValueJSON = response[tableSchemaName][recordId][fieldSchemaName],
													fieldValueObj = {};

												try {
													fieldValueObj = JSON.parse(fieldValueJSON);
												} catch(error) {
													console.warn('Error parsing multipart field value JSON:', fieldValueJSON);
												}
												resolve(fieldValueObj && fieldValueObj[fieldPart] ? fieldValueObj[fieldPart] : null);
											} else {
												resolve(null);
											}
										} else {
											let toResolve = (result && result.response && result.response[tableSchemaName] && result.response[tableSchemaName][recordId] && result.response[tableSchemaName][recordId][fieldSchemaName] ?
												result.response[tableSchemaName][recordId][fieldSchemaName] : null);
											resolve(toResolve);
										}
									} else {
										reject(result.response);
									}
								}).catch(function(err){
									reject(err);
								});
							} else {
								//if there are no request fields then the field schema name was missing or
								//for a field that has no data so just resolve null
								resolve(null);
							}
						});
					}
				},
				getFieldValueBulk: function(fieldSchemaName, recordSet, fieldPart, fieldId) {
					if (!fieldSchemaName) {
						console.error('getFieldValueBulk called with no fieldSchemaName');
						return Promise.reject(new Error('getFieldValueBulk called with no fieldSchemaName'));
					}
			
					if(!recordSet || !recordSet.length) {
						return Promise.resolve([]);
					}
			
					// Try to get the TSN from the record set
					let tableSchemaName = recordSet[0] && recordSet[0].tableSchemaName ? recordSet[0].tableSchemaName : '';
			
					if (!tableSchemaName) {
						console.error('getFieldValueBulk called with no tableSchemaName provided either explicitly or implicitly');
						return Promise.reject(new Error('getFieldValueBulk called with no tableSchemaName'));
					}
			
					let fields = [];
					// Make sure we have the fieldId;
					fieldId = fieldId || FieldStore.getFieldId(fieldSchemaName, tableSchemaName);
					let fieldObj = FieldStore.get(fieldId);
					if (!fieldId || (fieldId && !FieldStore.getHasData(fieldId))) {
						// If this isn't even a data-storing field then just give them a bunch of empty strings
						return Promise.resolve(new Array(recordSet.length).fill(''));
					} else {
						fields.push({
							fieldSchemaName: fieldSchemaName,
							fieldType: fieldObj.fieldType,
							fieldId: fieldId
						});
					}
			
					// Due to query length limitations, we limit the number of records that we can use per record browse call
					// So if we have a million records, we split it up into 40 record browse calls
					// (which is still a lot fewer than the record-read calls we'd have if using getFieldValue)
					// Calculating using (1000000 - 'SELECT `root.'.length - 128 - '` FROM `'.length - 129 - ' WHERE `root.recordId` IN ['.length - 1) / 38
					// we could actually go up to 26307, but this is a round number that leaves a bit of room for error
					// Lowered to 1000 after running into issues with return size in grpc
					let maxRecords = 1000;
					let i = 0;
					let len = recordSet.length;
					let lookupPromises = [];
					while(i < len) {
						// Get the end index of the record subset
						let end = Math.min(i + maxRecords, len);
						let recordSubset = recordSet.slice(i, end);
						let nodeId = 'return-node';
			
						// We need our results to be sorted in the appropriate order according to the records.
						// I'm doing this within this loop in hopes that it will result in garbage cleanup cleaning up this
						// variable once it's done so that we don't have to load a potentially very large object into memory
						let indexLookup = {};
						recordSubset.forEach(({recordId}, index) => {
							indexLookup[recordId] = index;
						});
						let results = [];
						
						let query = {
							queryId: 'getFieldBulk-FE-EP-1234-abcd-123412341234',
							returnNode: nodeId,
							nodes: [
								{
									nodeId,
									tableSchemaName
								}
							],
							sorts: [],
							filters: [{
								type: 'filter',
								operation: 'recordIs',
								filteredNode: nodeId,
								fieldSchemaName: 'recordIs',
								value: recordSubset
							}]
						};
						// Now actually make the record browse call
						// (Notably, this is the ONLY place in the action processor right now where we call record browse)
						// @TODO: Why are we stringifying this, anyway? It's wasteful here and I'd prefer not to if we don't need to.
						let lookupPromise = new Promise((resolve, reject) => {
							socketFetcher('gw/recordBrowse-v4', JSON.stringify({
								// We don't actually need to provide recordSets because we 
								tableSchemaName,
								fields,
								query,
								recordSets: {},
								browserStorage: {}
							}))
								.then(({responseCode, response, error}) => {
									// If our response is a 200 response, it was successful
									if(responseCode >= 200 && responseCode < 300) {
										// Unfortunately, due to the way that record-browse returns, we have no choice but to do this
										Object.keys(response.records[tableSchemaName]).forEach(recordId => {
											let record = response.records[tableSchemaName][recordId];
											let value = record[fieldSchemaName];
											// If this is a multipart field, we need to get the part from it
											if(fieldPart && value) {
												let fieldValueObj = typeof value === 'string' ? ObjectUtils.getObjFromJSON(value) : value;
												value = typeof fieldValueObj[fieldPart] === 'undefined' ? null : fieldValueObj[fieldPart];
											}
											// Make sure the results are sorted appropriately
											results[indexLookup[recordId]] = value;
										});
										// Load our response into the record store for later use.
										RecordActions.onDataLoaded(response.records);
										return resolve(results);
									} else if (responseCode < 500) {
										console.warn('%s response received from record browse in front end getFieldValueBulk: ', responseCode, response || error);
										resolve([]);
									} else {
										let err = new Error(responseCode + ' error received from recordBrowse in front end getFieldValue bulk: ' + (response || error));
										return reject(err);
									}
								})
								.catch(reject);
						});
						lookupPromises.push(lookupPromise);
						i = end;
					}
					
					return new Promise((resolve, reject) => {
						Promise.all(lookupPromises)
							.then(results => {
								// Concatenate them all into one array
								let flattenedResults = [];
								results.forEach(resultSet => {
									flattenedResults = flattenedResults.concat(resultSet);
								});
								return resolve(flattenedResults);
							})
							.catch(reject);
					});
				},
				log: function(message, level) {
					if(!level || !console[level]) {
						level = 'log';
					}
					console[level](message);
				},
				getUserIp: function() {
					return AuthenticationStore.getUserIp();
				},
				getUserLocation: function(userLoc) {
					return InterfaceActions.getUserLocation(userLoc);
				},
				math: {
					geo: {
						pointInPolygon
					}
				}
			};
			
			// let hash = crypto.createHash('md5');
			if (expressionRequest.expression) {
				let script = null;
				// let expressionHash = hash.update(expressionRequest.expression).digest('base64');
				// if (!scriptCache[expressionHash]) {
					script = 'co(function* () {\n'+
						'	'+expressionRequest.expression+'\n'+
						'	return outputs;\n'+
						'}).then(function(results){\n'+
						'	Promise.all(results).then(function(results) {\n'+
						'		citDev.returnOutput(results.length > 1 ? results.join("") : results[0], "");\n'+
						'	}).catch( function(err){\n'+
						'		citDev.returnOutput("", err.message ? err.message : err);\n'+
						'	});\n'+
						'}).catch(function(err){\n'+
						'	citDev.returnOutput("", err.message ? err.message : err);\n'+
						'});';
				// 	scriptCache[expressionHash] = script;
				// } else {
				// 	script = scriptCache[expressionHash];
				// }

				//Used in expression processing
				// eslint-disable-next-line
				let tableSchemaName = expressionRequest.tableSchemaName;
				//Used in expression processing
				// eslint-disable-next-line
				let recordId = expressionRequest.recordId;
				//Used in expression processing
				// eslint-disable-next-line
				let renderId = expressionRequest.renderId;
				//Used in expression processing
				// eslint-disable-next-line
				let runTimeVariables = expressionRequest.runTimeVariables ? expressionRequest.runTimeVariables : null;
				//Used in expression processing
				// eslint-disable-next-line
				let outputs = [];
				//Used in expression processing
				// eslint-disable-next-line
				let namedContexts = Object.assign(RecordVariableUtils.buildNamedContexts(renderId), expressionRequest.namedContexts);
				
				//Used in expression processing
				// eslint-disable-next-line
				let browserStorage = expressionRequest.browserStorage || 
					BrowserStorageStore.getStateJS();

				citDev.processQuery = function (query) {
					// Removing all escaping slashes from query so as to catch cases like 'namedContexts[\"pagePageRecord\"], etc.
					let unescapedQuery = query ? query.replace(/\\/g, '') : '';
					let hasPageInterfaceValues = (unescapedQuery.includes('namedContexts["pagePageRecord"]'));
					let hasCurrentRecordInterfaceValues = (unescapedQuery.includes('namedContexts["pageCurrentRecord"]'));
					let recordStoreRecords = {};
					if (hasPageInterfaceValues) {
						let tsn = namedContexts && namedContexts['page'] && namedContexts['page'][0] ?
							namedContexts['page'][0].tableSchemaName :
							'';
						let recordId = namedContexts && namedContexts['page'] && namedContexts['page'][0] ?
							namedContexts['page'][0].recordId :
							'';
						if (tsn && recordId) {
							recordStoreRecords[tsn] = {
								[recordId]: RecordStore.getRecord(tsn, recordId)
							};
						}
					}
					if (hasCurrentRecordInterfaceValues) {
						let tsn = namedContexts && namedContexts['startingContext'] && namedContexts['startingContext'][0] ?
							namedContexts['startingContext'][0].tableSchemaName :
							'';
						let recordId = namedContexts && namedContexts['startingContext'] && namedContexts['startingContext'][0] ?
							namedContexts['startingContext'][0].recordId :
							'';
						if (tsn && recordId) {
							recordStoreRecords[tsn] = {
								[recordId]: RecordStore.getRecord(tsn, recordId)
							};
						}
					}
					return citDev.callService('query-processor-v2', {
						query: query,
						browserStorage: browserStorage,
						runTimeVariables: runTimeVariables || {},
						context: {
							namedContexts: namedContexts,
							user: namedContexts && namedContexts['currentUser'] && namedContexts['currentUser'][0] ?
								namedContexts['currentUser'][0].recordId :
								'',
							installationId: namedContexts && namedContexts['installation'] && namedContexts['installation'][0] ?
								namedContexts['installation'][0].recordId :
								'',
							page: namedContexts && namedContexts['page'] && namedContexts['page'][0] ?
								namedContexts['page'][0].recordId :
								'',
							application: namedContexts && namedContexts['application'] && namedContexts['application'][0] ?
								namedContexts['application'][0].recordId :
								''
						},
						recordStoreRecords: recordStoreRecords
					});
				}
	
				citDev.processQueryV2 = ProcessorUtils.processQueryV2.bind(this, expressionRequest.installationId || ContextStore.getInstallationId(), expressionRequest.renderId, runTimeVariables);

				citDev.crypto = {};
				citDev.crypto.uuid = uuid;
				citDev.crypto.generateSecureKey = function (byteLength) {
					return new Promise((resolve, reject) => {
						randomBytes(byteLength, (err, buffer) => {
							if(err) {
								return reject(err);
							} else {
								return resolve(buffer.toString('hex'));
							}
						});
					});
				};

				// If our browser does not support generators (IE11.. grumble.. ) then babel the code.
				if(!window.supportsGenerators) {
					/*global Babel*/
					script = Babel.transform(script,{ presets: ['es2015','react'] }).code;
				}
				
				try {
					// eslint-disable-next-line
					eval(script);
				} catch(e) {
					citDev.returnOutput(null, 'Script Error: ' + e.message + '.  Script was:' + script);
				}
			} else {
				citDev.returnOutput(null, 'Invalid Expression');
			}
		});
	}
};

export default ExpressionObj;