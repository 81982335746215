import React from 'react';
import _ from 'lodash';
import BaseSettingForm from './base-setting-form.react';
/**
 * Component for ShortText
 * 
 * @class ShortText
 * @extends {Component}
 */
export default class ShortText extends BaseSettingForm {
	/**
	 * Render the ShortText
	 * 
	 * @returns React
	 * 
	 * @memberof ShortText
	 */
	render() {
		let {onChange, fieldSchemaName, pattern, maxWidth, value, disabled, required} = this.props;
		let style = maxWidth ? {'maxWidth' : maxWidth } : {};
		value = value ? value : '';
		disabled = disabled ? true : false;
		return(
			<input style={style} pattern={pattern} id={fieldSchemaName} required={required} disabled={disabled} className="form-control" value={value} type="text" onChange={onChange} />
		);
	}
}
