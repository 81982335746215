import React, { Component } from 'react';
import {ContextStore} from '../../../stores';

/**
 * Renderer for the appearance assistant. The output from this is used primarily for debugging.
 * 
 * @class AppearanceAssistant
 * @extends {Component}
 */
class AnalyzerTabs extends Component {

	/**
	 * Constructor; calls parent constructor and binds methods
	 * 
	 * @param {object} props 
	 * 
	 */
	constructor(props) {
		super(props);
		this.renderKeyValuePair = this.renderKeyValuePair.bind(this);
		this.renderNestedList = this.renderNestedList.bind(this);
	}

	/**
	 * Renders a key/value pair; used to show a key/value pair in an object
	 * 
	 * @param {string} key 
	 * @param {string} value 
	 */
	renderKeyValuePair(key,value) {
		return (key || key === 0) 
				? (<li>{key}:&nbsp;{this.renderNestedList(value)}</li>) 
				: <li><br />{this.renderNestedList(value)}</li>;
	}
	
	/**
	 * Used to render a nested list; either iterates over keys in an object or iterates over values in an array.
	 * May also just attempt to render an inputted value if it is not either an object or array
	 * 
	 * @param {any} value 
	 */
	renderNestedList(value) {
		if(!value) {
			return '' + value;
		} else if (Array.isArray(value)) {
			return (
				<ol>
					{value.map((val) => this.renderKeyValuePair(null,val))}
				</ol>);
		} else if (typeof value === "object"){
			return (<ul>
				{Object.keys(value).map(key => {
					return this.renderKeyValuePair(key, value[key]);
				})}
			</ul>);
		} else {
			return value;
		}
	}

	/**
	 * render - NLPBuilder
	 *
	 * @return {JSX}
	 */
	render() {
		let {content} = this.props;
		if(!content) {
			return (<div>No analyzer data to display</div>);
		}
		let contentObj = JSON.parse(content);
		let {intentAnalyzer} = contentObj;
		return (
			<div className="list-group list-content-wrapper">
				<div className="analyzer-results">	
					Analyzer Version: {ContextStore && ContextStore.getVersionObject && ContextStore.getVersionObject() && ContextStore.getVersionObject().platform}
				</div>
				<ol className="analyzer-results">
					<li className="role-group-item">
						<div 
							className="back-strike role-group-name" 
							data-toggle="collapse"
							data-target="#nlpResult">
							<a>Google NLP Results:</a>
						</div>
						<pre id="nlpResult" className="collapse">
							<code>
								{JSON.stringify(contentObj.googleNL,null,'\t')}
							</code>
						</pre>
					</li>
					<li className="role-group-item">
						<div 
							className="back-strike role-group-name" 
							data-toggle="collapse"
							data-target="#tokenizerResult">
							<a>Tokenizer Results:</a>
						</div>
						<pre id="tokenizerResult" className="collapse show">
							<code>
								{JSON.stringify(contentObj.tokenizer,null,'\t')}
							</code>
						</pre>
					</li>
					<li className="role-group-item">
						<div 
							className="back-strike role-group-name" 
							data-toggle="collapse"
							data-target="#intentAnalizerResult">
							<a>Intent Analyzer Results:</a>
						</div>
						{/*intentAnalyzerResults*/}
						<div id="intentAnalizerResult" className="collapse show">
							{this.renderNestedList(intentAnalyzer)}
						</div>
					</li>
					<li>
						<div>
							Final Results:
						</div>
						<div id="finalResult">
							{JSON.stringify(contentObj.finalResults,null,'\t')}
						</div>
					</li>
				</ol>
			</div>
		);
	};
}
// export default AppearanceAssistant;
export default AnalyzerTabs;
