/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container } from 'flux/utils';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import AssistantResultsStore from '../../../stores/assistant-results-store';
import FieldSettingsStore from '../../../stores/field-settings-store';
import FieldStore from '../../../stores/field-store';
import FieldTypeStore from '../../../stores/field-type-store';
import PageStore from '../../../stores/page-store';

// Utils
import FieldUtils from '../../../utils/field-utils';
import PageUtils from '../../../utils/page-utils';
import UIUtils from '../../../utils/ui-utils';

// Components
import PopoverHeader from './header';

/**
 * Container to field type wrapper
 *
 * @class FieldTypeWrapperContainer
 * @extends {Component}
 */
class AppearancePinPopover extends Component {
	constructor(props) {
		super(props);
		this.onSettingClick = this.onSettingClick.bind(this);
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf AppearancePin
	 */
	static getStores() {
		return [AdminSettingsStore, FieldSettingsStore, FieldStore, PageStore, AssistantResultsStore];
	}

	/**
	 * 
	 * 
	 * @param {any} settingSchemaName 
	 * @param {any} event 
	 * @memberof AppearancePinPopover
	 */
	onSettingClick(settingFieldId, event) {
		event.preventDefault();

		let settingFieldObj = FieldStore.get(settingFieldId);
		let settingSchemaName = settingFieldObj.fieldSchemaName;
		
		// Open Settings tab under right-panel
		AdminSettingsActions.onSettingsTabChangeMain('settings');

		UIUtils.openSettingsPanel('appearance', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName,
			'settings', undefined, undefined, this.props.renderId, this.props.attachmentId
		);

		AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
	}

	/**
	 * Returns the current State of the FieldType Settings
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf AppearancePin
	 */
	static calculateState(prevState, props) {
		let { recordId, parentRecordId, tableSchemaName, parentTableSchemaName, attachmentId } = props;
		let componentId = AdminSettingsStore.getRecordId();
		let componentSettings = [];
		let parentSettings = [];

		let settingsSource = attachmentId ? FieldSettingsStore.getSettingsFromAttachmentIdWithSource(attachmentId, recordId, parentRecordId) : FieldSettingsStore.getSettingsWithSource(recordId, parentRecordId);
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();

		// If we're a field, then capture our field type for components later.
		// If not (we're a page) this will just be empty
		let fieldType = '';

		let componentHeader = '';
		let parentHeader = '';
		// set parent header Icon
		let parentHeaderIcon = null;

		let parentFieldObj = FieldStore.get(parentRecordId) || {};
		let parentFieldTypeObj = FieldTypeStore.get(parentFieldObj.fieldType) || {};

		if(tableSchemaName === 'field') {
			let fieldId = recordId;
			let fieldObj = FieldStore.get(fieldId) || {};
			fieldType = fieldObj.fieldType;
			let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType) || {};

			// set component header
			componentHeader = fieldTypeObj && fieldTypeObj.name + " Settings";

			// set parent header
			parentHeader = (parentFieldTypeObj && parentFieldTypeObj.name) 
						 ? parentFieldTypeObj.name + " Attachment Settings" 
						 : parentRecordId ? "Page Attachment Settings" : '';

			// Get the field's direct settings
			let fieldTypeSettings = fieldTypeObj.settings || [];

			// Get the field's parent's child-settings.
			let parentSettingsArr = [];
			if(parentFieldTypeObj && parentRecordId && parentTableSchemaName){
				switch (parentTableSchemaName) {
					case 'page':{
						parentSettingsArr = PageUtils.getChildSettings();

						// Setup the parent header icon, from page
						parentHeaderIcon = FieldUtils.getFieldTypeIcon('page');
						break;
					}
					case 'field': {
						parentSettingsArr = FieldUtils.getChildSettings(parentRecordId);
						
						// Setup the parent header icon, from Field
						parentHeaderIcon = FieldUtils.getFieldTypeIcon(parentRecordId);
						break;
					}
					default: 
						console.warn('Unsupported Parent Table Schema Name:', parentTableSchemaName);
						break;
				}
			} else {
				// parentSettingsArr = FieldUtils.getDefaultSettings();
				// parentHeaderIcon = FieldUtils.getFieldTypeIconByFieldTypeId('page');
			}

			if (fieldTypeSettings && fieldTypeSettings.length) {
				fieldTypeSettings.forEach(fieldTypeSetting => {
					let settingId = fieldTypeSetting.recordId;
					let settingObj = FieldStore.get(settingId) || {};
					let settingSource = settingsSource[settingObj.fieldSchemaName] || {};
					let historyObj = FieldUtils.getSettingHistoryDetails(settingId, fieldId, parentRecordId, parentTableSchemaName);

					// If this IS a query or relationship selector setting, then jump out of the loop.
					if(settingObj.fieldType === '45b6601f-02e8-4dcd-acce-9815d094f009' ||
					   settingObj.fieldType === 'ce0dbfec-e9d9-4dc3-b39a-456eca2b5282') {
							return false;
					}

					// Empty/No Value values.
					let valueObj = {};

					// Check whether value for setting is present
					if(settingSource.value || (!settingSource.value && settingSource.source === 'local')){
						valueObj.value = settingSource.value;
					
						// Work with Value Source
						if(settingSource.source !== 'global') {
							valueObj.source = 'O';
							valueObj.badgeTypeClass = 'badge-secondary';
						}
					}

					componentSettings.push({
						// Basics
						fieldId: settingId,
						fieldObj: settingObj,

						// Label, Value and Sorting..
						label: settingObj.fieldLabel,
						valueObj: valueObj,
						sortOrder: historyObj.order,

						// Used for styling
						quality: historyObj.valueQuality, 
						settingSelected: (selectedSetting === settingObj.fieldSchemaName)
					});
				});

				if (parentSettingsArr && parentSettingsArr.length) {
					parentSettingsArr.forEach(fieldTypeSetting => {
						let settingId = fieldTypeSetting.recordId;
						let settingObj = FieldStore.get(settingId) || {};
						let settingSource = settingsSource[settingObj.fieldSchemaName] || {};
						let historyObj = FieldUtils.getSettingHistoryDetails(settingId, fieldId, parentRecordId, parentTableSchemaName);
	
						// If this IS a query or relationship selector setting, then jump out of the loop.
						if(settingObj.fieldType === '45b6601f-02e8-4dcd-acce-9815d094f009' ||
							settingObj.fieldType === 'ce0dbfec-e9d9-4dc3-b39a-456eca2b5282') {
								return false;
						}
						// Empty/No Value values.
						let valueObj = {};
	
						// Check whether value for setting is present
						if(settingSource.value || (!settingSource.value && settingSource.source === 'local')){
							valueObj.value = settingSource.value;
						
							// Work with Value Source
							if(settingSource.source !== 'global') {
								valueObj.source = 'O';
								valueObj.badgeTypeClass = 'badge-secondary';
							}
						}
		
						parentSettings.push({
							// Basics
							fieldId: settingId,
							fieldObj: settingObj,
	
							// Label, Value and Sorting..
							label: settingObj.fieldLabel,
							valueObj: valueObj,
							sortOrder: historyObj.order,
	
							// Used for styling
							quality: historyObj.valueQuality, 
							settingSelected: (selectedSetting === settingObj.fieldSchemaName)
						});
					});
				}
			}
		} else if (tableSchemaName === 'page') {
			let pageId = componentId;
			let pageObj = PageStore.get(pageId);
			let pageSettings = PageUtils.getPageSettings();

			componentHeader = 'Page Settings';

			pageSettings.forEach(pageSetting => {
				let settingId = pageSetting.recordId;
				let settingObj = FieldStore.get(settingId) || {};
				let settingsValue = pageObj[settingObj.fieldSchemaName];
				
				// Empty/No Value values.
				let valueObj = {};

				// Check whether value for setting is present
				if(settingsValue){
					valueObj.value = settingsValue;
				}

				componentSettings.push({
					// Basics
					fieldId: settingId,
					fieldObj: settingObj,

					// Label, Value and Sorting..
					label: settingObj.fieldLabel,
					valueObj: valueObj,
					sortOrder: 0,

					// Used for styling
					settingSelected: (selectedSetting === settingObj.fieldSchemaName)
				});
			});
		}

		// Sort the results by setting name
		componentSettings.sort(function(a,b){
			if(a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if(a.label.toLowerCase() < b.label.toLowerCase()) { 
				return -1;
			}
			return 1;
		});

		// Sort the parent results by setting name
		parentSettings.sort(function(a,b){
			if(a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if(a.label.toLowerCase() < b.label.toLowerCase()) { 
				return -1;
			}
			return 1;
		});

		return {
			componentSettings: componentSettings,
			parentSettings: parentSettings,
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			fieldType: fieldType,
			parentRecordId: parentRecordId,
			parentTableSchemaName: parentTableSchemaName,
			searchResults: AssistantResultsStore.getAllArray(),
			componentHeader: componentHeader,
			parentHeader: parentHeader,
			parentHeaderIcon: parentHeaderIcon,
		}
	}

	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @returns { array } array of <li> settings elements
	 */
	 getSettingsList(settingsArr) {

		let { fieldType } = this.state;

		// iterate over the settings and return an array of list items
		return settingsArr.map(setting => {
			let labelClassNames = 'setting-label';
			if(setting.settingSelected) { 
				labelClassNames += ' selected';
			}

			/*************************
			    The following commented out statements are for the setting-icon, which is not currently in use
				Uncomment, when setting-icon is used again
			**************************/

			// let iconClassNames = 'fa';
			
			// switch(setting.quality) {
			// 	case 'custom':
			// 		iconClassNames += ' custom fa-check-square-o';
			// 	break;
			// 	default:
			// 	case 'low':
			// 		iconClassNames += ' high fa-check-circle-o';
			// 	break;
			// 	case 'high':
			// 		iconClassNames += ' low fa-exclamation-triangle';
			// 	break;
			// }

			let componentName = FieldStore.getDefaultVariantComponentName(setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
			let componentProps = setting.fieldObj;
			componentProps.value = setting.valueObj.value;
			componentProps.fieldType = fieldType;

			return (<li key={setting.fieldId} className="nav-item">
			<div className="nav-link" onClick={(event) => { this.onSettingClick(setting.fieldId, event)} }>
				{/* Temporarily removed the following div */}
				{/* <div className={"popover-settings-icon " + iconClassNames} /> */}
				{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
				{/* Add this back in when the setting-icon is used again */}
				<div className="d-flex w-100 setting-text-container">
					<div className={"w-50 d-flex align-items-center p-1 " + labelClassNames}><h4>{setting.label}</h4></div>
					{/*Change the className*/}
					<div className="w-50 d-flex justify-content-end setting-value-container">
						<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
							<h5 className={`settings-list-value ${componentName}`}>{ (componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null) }</h5>
						</div>
						{ setting.valueObj.source ? 
							<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}> 
								{ setting.valueObj.source } 
							</div>
							: null
						}
					</div>
				</div>
			</div>
		</li>);
		});
	}

	render() {
		let {componentSettings, parentSettings, parentHeader, componentHeader, searchResults, parentHeaderIcon, componentId, tableSchemaName } = this.state;
		let settingsList = this.getSettingsList(componentSettings);
		let parentSettingsList = parentHeader && this.getSettingsList(parentSettings);
		let componentIcon = (tableSchemaName === 'field' ? FieldUtils.getFieldTypeIcon(componentId) : FieldUtils.getFieldTypeIcon('page'));

		return (
			<div id="popover-content" style={{ height: '100%', overflow: 'hidden' }} className='cd-tools'>
				<PopoverHeader 
					recordId={this.props.recordId} 
					tableSchemaName={this.props.tableSchemaName} 
					parentRecordId={this.props.parentRecordId} 
					parentTableSchemaName={this.props.parentTableSchemaName} 
					iconBase="pencil" 
					overlayName="Appearance"/>
					{
						(!searchResults || !searchResults.length) ?
							(<div style={{ height: '100%', overflow: 'hidden' }} className="tab-content row mb-2">
							<div style={{ height: '100%', overflowY: 'auto' }} className="ml-3 pr-1 mr-2">
								<ul id="popover-settings" className="nav flex-column popover-scrollable">
									{/* not all fields have child attachment settings
										don't show the parent name if there are no child attachment settings */}
									{settingsList && settingsList.length
										? <li className="settings-list-header">
											<div className="d-flex">
												<img style={{ marginTop: '2px' }} height="22" width="22" className="mr-2" src={componentIcon} alt="" />
												<h3>{componentHeader}</h3>
											</div>
										</li>
										: null
									}
									{settingsList && settingsList.length
										? settingsList
										: null
									}
									{parentSettingsList && parentSettingsList.length
										? <li className="settings-list-header field-settings-list-header">
											<div className="d-flex">
												<img height="22" width="22" className="mr-2" src={parentHeaderIcon} alt="" />
												<h3>Parent Settings</h3>
											</div>
										  </li>
										: null
									}
									{parentSettingsList}
									{!parentSettingsList && !settingsList && <div className='no-settings-found'>No Settings Found</div>}
								</ul>
							</div>
						</div>) : null
					}
			</div>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
  AppearancePinPopover.propTypes = {
    recordId: PropTypes.string.isRequired,
	tableSchemaName: PropTypes.string.isRequired,
    parentRecordId: PropTypes.string,
    parentTableSchemaName: PropTypes.string,
    // // gridKey: PropTypes.any,
    // // fieldType: PropTypes.string,
  };
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(AppearancePinPopover, {withProps: true});
export default container;