import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import {MapConstants} from '../constants';

/**
 * Actions for data-map
 *
 * @class MapActions
 */
export default class MapActions {
  /**
   * Adds filter column term to filters
   *
   * @params {String} column name that's being sorted
   * @params {String} enum ASC or DESC showing direction of sorting
   * @params {String} enum elementType field/page/record/relationship/table
   */
  static addFilterColumn(filterColumn, term, elementType) {
    AppDispatcher.dispatch(Immutable.fromJS({
      type: MapConstants.MAP_ADD_FILTER_COLUMN,
      filterColumn: filterColumn,
      term: term,
      elementType: elementType
    }));
  }

  /**
   * Clears stored filters
   */
  static clearFilters() {
    AppDispatcher.dispatch(Immutable.fromJS({
      type: MapConstants.MAP_FILTER_CLEAR
    }));
  }

  /**
   * Stores grouping filter
   *
   * @params {String} group selections from constants/group-filters.js
   */
  static groupBy(groupBy) {
    AppDispatcher.dispatch(Immutable.fromJS({
      type: MapConstants.MAP_GROUP_BY,
      groupBy: groupBy
    }));
  }

  /**
   * Stores term to be searched
   *
   * @params {String} term to search/filter tables on data-map left panel
   */
  static search(search) {
    AppDispatcher.dispatch(Immutable.fromJS({
      type: MapConstants.MAP_SEARCH,
      search: search
    }));
  }

  /**
   * Store a Group's Show/Hide status - for a Dashboard+GroupBy
   * @param {string} dashboard Dashboard to do the toggle for.
   * @param {string} groupBy Group by ON the Dashboard to do the toggle for.
   * @param {string} groupName Name of the group to toggle.
   * @param {boolean} toggleState Is this toggled on (true) or off (false)
   */
  static groupToggle(dashboard, groupBy, groupName, toggleState) {
    AppDispatcher.dispatch(Immutable.fromJS({
      type: MapConstants.MAP_GROUP_TOGGLE,
      dashboard,
      groupBy,
      groupName,
      toggleState
    }));
  }

  /**
   * Store a Group's Show/Hide status - for a Dashboard+GroupBy
   * @param {string} dashboard Dashboard to do the toggle for.
   * @param {string} groupBy Group by ON the Dashboard to do the toggle for.
   * @param {string} groupToggleStateObj Object of toggle state booleans for all groups in the group by
   */
  static groupToggleBulk(dashboard, groupBy, groupToggleStateObj) {
    AppDispatcher.dispatch(Immutable.fromJS({
      type: MapConstants.MAP_GROUP_TOGGLE_BULK,
      dashboard,
      groupBy,
      groupToggleStateObj
    }));
  }

  /**
   * Stores fieldId that is being edited
   *
   * @params {String} uuid.v4 fieldId
   * @params {Boolean} flag for new field
   */
  static selectField(fieldId, isNew = false) {
    AppDispatcher.dispatch(Immutable.fromJS({
      type: MapConstants.MAP_SET,
      elementType: 'field',
      elementId: fieldId,
      isNew: isNew
    }));
  }

  /**
   * sets selected page
   *
   * @params {String} uuid.v4 pageId
   * @params {Boolean} flag for new page
   * @todo Remove?
   */
  static selectPage(pageId, isNew = false) {
    AppDispatcher.dispatch(Immutable.fromJS({
      type: MapConstants.MAP_SET,
      elementType: 'page',
      elementId: pageId,
      isNew: isNew
    }));
  }

  /**
   * sets selected record
   *
   * @params {String} uuid.v4 recordId
   * @params {Boolean} flag for new record
   */
  static selectRecord(recordId, isNew = false) {
    AppDispatcher.dispatch(Immutable.fromJS({
      type: MapConstants.MAP_SET,
      elementType: 'record',
      elementId: recordId,
      isNew: isNew
    }));
  }

  /**
   * Stores relationshipId that is being edited
   *
   * @params {String} uuid.v4 relationshipId
   * @params {Boolean} flag for new relationship
   */
  static selectRelationship(relationshipId, isNew = false) {
    AppDispatcher.dispatch(Immutable.fromJS({
      type: MapConstants.MAP_SET,
      elementType: 'relationship',
      elementId: relationshipId,
      isNew: isNew
    }));
  }


  /**
   * sets selected table
   *
   * @params {String} uuid.v4 tableId
   * @params {Boolean} flag for new table
   */
  static selectTable(tableId, isNew = false) {
    AppDispatcher.dispatch(Immutable.fromJS({
      type: MapConstants.MAP_SET,
      elementType: 'table',
      elementId: tableId,
      isNew: isNew
    }));
  }

  /**
   * Stores column and direction of sorted data
   */
  static sortColumn(sortColumn, direction, elementType) {
    AppDispatcher.dispatch(Immutable.fromJS({
      type: MapConstants.MAP_SORT_COLUMN,
      sortColumn: sortColumn,
      direction: direction,
      elementType: elementType
    }));
  }
}
