import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'flux/utils';
import { AdminSettingsStore, FieldSettingsStore, FieldStore, PageStore } from '../../stores';
import { FieldActions, PageActions } from '../../actions';
import VisibilitySettingsConfigurationV1 from './visibility-settings-configuration-v1.react'
import ObjectUtils from '../../utils/object-utils';
/*global Blockly:true*/

class VisibilitySettingV1 extends Component {
	constructor(props) {
		super(props)
		this.onGlobalSettingChange = this.onGlobalSettingChange.bind(this);
		this.renderLocalValues = this.renderLocalValues.bind(this);
		this.addNewRow = this.addNewRow.bind(this);
		this.addLocalSetting = this.addLocalSetting.bind(this);
		this.deleteLocalSetting = this.deleteLocalSetting.bind(this);
		this.updateLocalSettingRow = this.updateLocalSettingRow.bind(this);
		this.updateAddRowSettingScreenSize = this.updateAddRowSettingScreenSize.bind(this);
		this.updateExistingScreenSizes = this.updateExistingScreenSizes.bind(this);
		this.updateLocalValues = this.updateLocalValues.bind(this);
		this.updateNewLocalValues = this.updateNewLocalValues.bind(this);
	}
	/**
	 * @static
	 * @returns getStores - Loads the Stores to watch
	 * @memberof VisibilitySetting
	 */
	static getStores() {
		return [AdminSettingsStore, FieldSettingsStore, FieldStore, PageStore];
	}
	/**
	 * @static
	 * @param {any} prevState 
	 * @param {any} props 
	 * @memberof VisibilitySetting
	 */
	static calculateState(prevState, props) {
		let recordId = AdminSettingsStore.getRecordId(),
			tableSchemaName = AdminSettingsStore.getTableSchemaName(),
			parentRecordId = AdminSettingsStore.getParentRecordId(),
			parentTableSchemaName = AdminSettingsStore.getParentTableSchemaName();
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let controlsAddAndEdit = AdminSettingsStore.getVisibilityControlsAddAndEdit();

		let { subSettingIndex, subSettingSchemaName } = props;
		let settingConfig = '';
		let visibilityRulesArray = [];
		let newState = {};
		let subSetting = [];
		//Calculate the local values
		let localVisibilityRulesArray = [];
		if (!localVisibilityRulesArray.length) {
			newState.addRow = { 'value': {}, 'screenSizes': [] };
		} else {
			newState.addRow = localVisibilityRulesArray;
		}
		switch (tableSchemaName) {
			case 'field': {
				try {
					settingConfig = FieldSettingsStore.getSettings(recordId, parentRecordId);
				} catch (error) {
					console.log('Error JSON parsing: ', error);
				}
				// Calculate Local Value
				if (settingConfig) {

					/* use the subSetting and index if there is one (tab options), 
					 * or use the visibilityRules directly if not
					 */
					if (settingConfig[subSettingSchemaName]) {
						try {
							subSetting = JSON.parse(settingConfig[subSettingSchemaName]);
							if (subSetting[subSettingIndex].visibilityRules) {
								let visibilityRules = JSON.parse(subSetting[subSettingIndex].visibilityRules);
								if (visibilityRules) {
									visibilityRulesArray = visibilityRules[selectedSetting];
								}
							}
						} catch (error) {
							console.log('Error JSON parsing: ', error);
						}
					} else {
						// check for visibility rules object
						if (settingConfig.visibilityRules) {
							try {
								let visibilityRules = JSON.parse(settingConfig.visibilityRules);
								if (visibilityRules) {
									visibilityRulesArray = visibilityRules[selectedSetting];
								}
							} catch (error) {
								console.log('Error JSON parsing: ', error);
							}
						}
					}
				}
				break;
			}
			case 'page': {
				let pageObject = PageStore.get(recordId);
				if (pageObject && pageObject.visibilityRules) {
					try {
						let visibilityRules = JSON.parse(pageObject.visibilityRules);
						if (visibilityRules) {
							visibilityRulesArray = visibilityRules[selectedSetting];
						}
					} catch (error) {
						console.log('Error JSON parsing: ', error);
					}
				}
				break;
			}
			default: {
				if (tableSchemaName) {
					console.warn('Invalid tableschema name', tableSchemaName);
				}
			}
		}

		// @todo Update this to be empty if there is a subSetting and index

		let childConfiguration = parentTableSchemaName === 'page' ?
			PageStore.getChildConfigurations(parentRecordId, recordId, 'visibilityRules') :
			FieldStore.getChildConfigurations(parentRecordId, recordId, 'visibilityRules');
		if (childConfiguration) {
			localVisibilityRulesArray = childConfiguration;
		}

		// @todo Probably need to include subSetting and index?
		return {
			'recordId': recordId,
			'tableSchemaName': tableSchemaName,
			'parentRecordId': parentRecordId,
			'parentTableSchemaName': parentTableSchemaName,
			'selectedSetting': selectedSetting,
			'visibilityRulesArray': visibilityRulesArray,
			'localVisibilityRulesArray': localVisibilityRulesArray,
			'newState': newState,
			'subSetting': subSetting,
			'subSettingSchemaName': subSettingSchemaName,
			'subSettingIndex': subSettingIndex,
			'controlsAddAndEdit': controlsAddAndEdit,
		};
	}
	/**
	 * Updates Global Values
	 * 
	 * @param {any} selectedSetting 
	 * @param {any} newArrayObj 
	 * @memberof VisibilitySetting
	 */
	onGlobalSettingChange(selectedSetting, newArrayObj) {
		let { recordId, tableSchemaName, subSetting, controlsAddAndEdit, subSettingIndex, subSettingSchemaName } = this.state;
		let currentObj = {};
		currentObj[selectedSetting] = newArrayObj;
		// Check if the only rule present is the "Always Rule"
		if (currentObj[selectedSetting] && currentObj[selectedSetting].length === 1) {
			let defaultRow = currentObj[selectedSetting][0];
			// controlsAddAndEdit is true (means only "show" mode) and show value is set to true
			if (controlsAddAndEdit) {
				if (defaultRow.show) {
					// then set rule to empty string
					currentObj = "";
				}
			} else {
				// show/edit values are set to true
				if (defaultRow.show && defaultRow.edit) {
					// then set rule to empty string
					currentObj = "";
				} else if (defaultRow.show && defaultRow.add) { //  or show/add values are set to true
					// then set rule to empty string
					currentObj = "";

				}
			}
		}
		switch (tableSchemaName) {
			case 'field':
				if (typeof currentObj !== 'string') {
					currentObj = JSON.stringify(currentObj);
				}
				// Check if subsetting visibility pin is to be updated
				if (subSetting.length && (subSettingIndex !== 'undefined') && subSettingSchemaName) {
					// Add/update visibilityRules for subsetting
					subSetting[subSettingIndex]['visibilityRules'] = currentObj;
					subSetting = JSON.stringify(subSetting);
					// push to store the subsetting
					FieldActions.pushSettingToStore(recordId, subSettingSchemaName, subSetting);
				} else {
					FieldActions.pushSettingToStore(recordId, 'visibilityRules', currentObj);
				}
				break;
			case 'page':
				let pageValue = {};
				if (typeof currentObj !== 'string') {
					currentObj = JSON.stringify(currentObj);
				}
				pageValue["visibilityRules"] = currentObj;
				PageActions.pushToStore(recordId, pageValue);
				break;
			default:
				if (tableSchemaName) {
					console.warn('Invalid table schema name ', tableSchemaName);
				}
				break;
		}
	}
	/**
	 * Updates Local Settings Screen Sizes that is about to be added
	 * 
	 * @param {any} selectedSetting 
	 * @param {any} newArrayObj 
	 * @memberof VisibilitySetting
	 */
	updateAddRowSettingScreenSize(event) {
		let newState = ObjectUtils.copyObj(this.state.newState);
		let value = event.target.value;
		if (event.target.checked) {
			newState.addRow.screenSizes.push(value);
		} else if (newState.addRow.screenSizes.includes(value)) {
			newState.addRow.screenSizes.splice(
				newState.addRow.screenSizes.indexOf(value), 1);
		}
		this.setState({ newState });
	}
	/**
	 * Update screen sizes for local settings that have been already added
	 * @param {any} index 
	 * @param {any} event 
	 * @memberof VisibilitySetting
	 */
	updateExistingScreenSizes(index, event) {
		let newRulesObj = ObjectUtils.copyObj(this.state.localVisibilityRulesArray[index]);
		let value = event.target.value;
		if (event.target.checked) {
			newRulesObj.screenSizes.push(value);
		} else if (newRulesObj.screenSizes.includes(value)) {
			newRulesObj.screenSizes.splice(
				newRulesObj.screenSizes.indexOf(value), 1);
		}
		newRulesObj.action = 'update';
		newRulesObj.index = index;
		this.updateLocalSettingRow({
			'target': {
				'value': newRulesObj
			}
		});
	}
	/**
	 * Update the Local Values that have been already added.
	 * 
	 * @param {any} selectedSetting 
	 * @param {any} index 
	 * @param {any} currentObj 
	 * @memberof VisibilitySetting
	 */
	updateLocalValues(selectedSetting, index, currentObj) {
		let newRulesObj = ObjectUtils.copyObj(this.state.localVisibilityRulesArray[index]);
		let value = {};
		value[selectedSetting] = currentObj;
		newRulesObj.value = value;
		newRulesObj.action = 'update';
		newRulesObj.index = index;
		this.updateLocalSettingRow({
			'target': {
				'value': newRulesObj
			}
		});
	}
	/**
	 * Update new local value values
	 * @param {any} selectedSetting 
	 * @param {any} index 
	 * @param {any} currentObj 
	 * @memberof VisibilitySetting
	 */
	updateNewLocalValues(selectedSetting, index, currentObj) {
		let newState = ObjectUtils.copyObj(this.state.newState);
		let value = {};
		value[selectedSetting] = currentObj;
		newState.addRow.value = value;
		this.setState({ newState });
	}
	/**
	 * Delete the entire row
	 * 
	 * @param {any} index 
	 * @param {any} event 
	 * @memberof VisibilitySetting
	 */
	deleteLocalSetting(index, event) {
		event.preventDefault();
		let value = {
			action: 'delete',
			index: index
		};
		this.updateLocalSettingRow({
			'target': {
				'value': value
			}
		});
	}
	/**
	 * Add the row under local settings
	 * @param {any} index 
	 * @param {any} event 
	 * @memberof VisibilitySetting
	 */
	addLocalSetting(index, event) {
		event.preventDefault();
		let { newState } = this.state;
		let value = ObjectUtils.copyObj(newState.addRow);
		value.action = 'add';
		value.index = index;
		this.updateLocalSettingRow({
			'target': {
				'value': value
			}
		});
	}
	/**
	 * Update the Visibility Rule Local values to the store
	 * 
	 * @param {any} event 
	 * @memberof VisibilitySetting
	 */
	updateLocalSettingRow(event) {
		let { recordId, parentRecordId, parentTableSchemaName, selectedSetting } = this.state;
		let value = event.target.value;
		let childConfiguration = parentTableSchemaName === 'page' ?
			PageStore.getChildConfigurations(parentRecordId, recordId, 'visibilityRules') :
			FieldStore.getChildConfigurations(parentRecordId, recordId, 'visibilityRules');
		childConfiguration = childConfiguration || [];
		switch (parentTableSchemaName) {
			case 'page': {

				if (value.action === 'delete') {
					if (value.index < childConfiguration.length) {
						childConfiguration.splice(value.index, 1);
					}
				} else {
					let valueObj = value.value;
					// Check if the only rule present is the "Always Rule"
					if (valueObj[selectedSetting] && valueObj[selectedSetting].length === 1) {
						let defaultRow = valueObj[selectedSetting][0];
						// and show/edit are set to true 
						if (defaultRow.show && defaultRow.edit) {
							// then set the value to an empty object
							valueObj = {};
						} else if (defaultRow.show && defaultRow.add) { // or show/add are true
							// then set the value to an empty object
							valueObj = {};

						}
					}
					let newValue = {
						value: valueObj,
						screenSizes: value.screenSizes
					};
					if (value.index < childConfiguration.length) {
						childConfiguration[value.index] = newValue;
					} else {
						childConfiguration.push(newValue);
					}
				}
				PageActions.pushChildConfigurationToStore(parentRecordId, recordId, 'visibilityRules', childConfiguration);
				break;
			}
			case 'field': {

				if (value.action === 'delete') {
					if (value.index < childConfiguration.length) {
						childConfiguration.splice(value.index, 1);
					}
				} else {
					let valueObj = value.value;
					// Check if the only rule present is the "Always Rule"
					if (valueObj[selectedSetting] && valueObj[selectedSetting].length === 1) {
						let defaultRow = valueObj[selectedSetting][0];
						// and show/edit are set to true
						if (defaultRow.show && defaultRow.edit) {
							// then set the value to an empty object
							valueObj = {};
						} else if (defaultRow.show && defaultRow.add) { // or show/add are true
							// then set the value to an empty object
							valueObj = {};
						}
					}
					let newValue = {
						value: valueObj,
						screenSizes: value.screenSizes
					};
					if (value.index < childConfiguration.length) {
						childConfiguration[value.index] = newValue;
					} else {
						childConfiguration.push(newValue);
					}
				}
				FieldActions.pushChildConfigurationToStore(parentRecordId, recordId, 'visibilityRules', childConfiguration);
				break;
			}
			default:
				console.warn('Unsupported Parent Table Schema Name');
		}
	}
	/**
	 * Add new row under local setting
	 * @param {any} index 
	 * @returns 
	 * @memberof VisibilitySetting
	 */
	addNewRow(index) {
		let { selectedSetting, newState } = this.state;
		let value = newState.addRow.value;
		let valueArray = [];
		if (value) {
			valueArray = value[selectedSetting] ? value[selectedSetting] : [];
		}
		return (<tr key={index}>
			<td>
				<VisibilitySettingsConfigurationV1
					visibilityRulesArray={valueArray}
					onChange={this.updateNewLocalValues.bind(this, selectedSetting, index)}
				/>
			</td>
			<td>
				<div className="form-check">
					<label className="form-check-label">
						{/* <input value="lg" type="checkbox" className="form-check-input"
							checked={newState.addRow.screenSizes.includes('lg')}
							onChange={this.updateAddRowSettingScreenSize} /> */}
						<span>Desktop</span>
					</label>
				</div>
				<div className="form-check">
					<label className="form-check-label">
						{/* <input value="md" type="checkbox" className="form-check-input"
							checked={newState.addRow.screenSizes.includes('md')}
							onChange={this.updateAddRowSettingScreenSize} /> */}
						<span>Tablet</span>
					</label>
				</div>
				<div className="form-check">
					<label className="form-check-label">
						{/* <input value="sm" type="checkbox" className="form-check-input"
							checked={newState.addRow.screenSizes.includes('sm')}
							onChange={this.updateAddRowSettingScreenSize} /> */}
						<span>Mobile</span>
					</label>
				</div>
			</td>
			<td>
				{/* Add a values */}
				{/* <button
					className="close"
					onClick={this.addLocalSetting.bind(this, index)}>
					<i className="fa fa-plus" aria-hidden="false"></i>
				</button> */}
			</td>
		</tr>)
	}
	/**
	 * Render all Local Setting Values
	 * @returns Local Setting Values 
	 * @memberof VisibilitySetting
	 */
	renderLocalValues() {
		let { localVisibilityRulesArray, selectedSetting } = this.state;
		let rows = []
		if (!localVisibilityRulesArray || localVisibilityRulesArray.length === 0) {
			rows.push(this.addNewRow(0));
		} else {
			rows = localVisibilityRulesArray.map((visibilityRules, index) => {
				let valueArray = [];
				if (visibilityRules.value) {
					valueArray = visibilityRules.value[selectedSetting] ?
						visibilityRules.value[selectedSetting] : [];
				}
				return (
					<tr key={index}>
						<td>
							<VisibilitySettingsConfigurationV1
								visibilityRulesArray={valueArray}
								onChange={this.updateLocalValues.bind(this, selectedSetting, index)}
							/>
						</td>
						<td>
							<div className="form-check">
								<label key={index} className="form-check-label">
									{/* <input value="lg" type="checkbox" className="form-check-input"
										checked={visibilityRules.screenSizes.includes('lg')}
										onChange={this.updateExistingScreenSizes.bind(this, index)} /> */}
									<span>
										Desktop
									</span>
								</label>
							</div>
							<div className="form-check">
								<label key={index} className="form-check-label">
									{/* <input value="md" type="checkbox" className="form-check-input"
										checked={visibilityRules.screenSizes.includes('md')}
										onChange={this.updateExistingScreenSizes.bind(this, index)} /> */}
									<span>
										Tablet
									</span>
								</label>
							</div>
							<div className="form-check">
								<label key={index} className="form-check-label">
									{/* <input value="sm" type="checkbox" className="form-check-input"
										checked={visibilityRules.screenSizes.includes('sm')}
										onChange={this.updateExistingScreenSizes.bind(this, index)} /> */}
									<span>
										Mobile
									</span>
								</label>
							</div>
						</td>
						<td>
							{/* Delete a value*/}
							<button
								className="close"
								onClick={this.deleteLocalSetting.bind(this, index)} >
								<i className="fa fa-minus" aria-hidden="false"></i>
							</button>
						</td>
					</tr>
				)
			});
			let rowsLength = localVisibilityRulesArray.length;
			// Always push the default row at the end
			rows.push(this.addNewRow(rowsLength))
		}
		return (
			<table>
				<thead>
					<tr>
						<th className="p-0">Value</th>
						<th className="p-0">Screen Size</th>
						<th className="p-0"></th>
					</tr>
				</thead>
				<tbody>
					{rows}
				</tbody>
			</table>
		)
	}
	/**
	 * render Global and Local Values
	 * @return {JSX}
	 */
	render() {
		let { visibilityRulesArray, parentRecordId, selectedSetting, subSettingSchemaName } = this.state;
		let isList = subSettingSchemaName && subSettingSchemaName === 'listColumns'
			? true : false;
		if (!selectedSetting) {
			return (
				<div className="select-setting">
					<div className="select-setting-text-wrap">
						Select a Security Group on the left.
					</div>
				</div>
			);
		} else {
			// @todo Need to support subSetting and index NOT SHOWING the Local.
			return (
				<div id="visibility-setting">
					<div className="visibility-setting-container mx-3">
						<form id="visibility-form">
							<div className="setting-global">
								<h3>
									Global
									<small className="setting-subheader"> (all instances will reflect this value unless locally overridden)</small>
								</h3>
								<VisibilitySettingsConfigurationV1
									visibilityRulesArray={visibilityRulesArray}
									onChange={this.onGlobalSettingChange.bind(this, selectedSetting)}
								/>
							</div>
							{/*If there is parent record id then show local settings*/}
							{/* @todo: Remove subSettingSchemaName check once visibility store has been setup */}
							{parentRecordId && !isList ?
								<div className="setting-local">
									<h3>
										Local
									<small className="setting-subheader"> (reflect this value only for this instance)</small>
									</h3>
									{this.renderLocalValues()}
								</div>
								: null
							}
						</form>
					</div>
				</div>
			);
		}
	}
}

if ('development' === process.env.NODE_ENV) {
	VisibilitySettingV1.propTypes = {
		subSettingIndex: PropTypes.number,
		subSettingSchemaName: PropTypes.string,
	};
}

const container = Container.create(VisibilitySettingV1, { withProps: true });
export default container;