import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import PageModeConstants from '../constants/page-mode-constants';
import { PageConstants } from '../constants/page-constants';
import PageStore from './page-store';
import RenderStore from './render-store';
import RecordStore from './record-store';
import RenderConstants from '../constants/render-constants';

/**
 * Store to contain the current fieldType configurationJson and settings values
 *
 * @class PageModeStore
 * @extends {ReduceStore}
 */
class PageModeStore extends ReduceStore {

	/**
	 * Initial state for PageModeStore
	 *
	 * @param {Object} event
	 *
	 * @memberOf PageModeStore
	 */
	getInitialState() {
		return Immutable.Map();
	}

	/**
	 * Get current mode from the page id
	 *
	 * @param {string} renderId
	 * @returns {string} mode
	 *
	 * @memberOf PageModeStore
	 */
	getCurrentMode(renderId) {
		let modeData =  this.getState().get(renderId);
		if (modeData) {
			return modeData.get('currentMode');
		}

		return undefined;
	}

	/**
	 * hasAvailableMode - determines if target mode is listed in page's availableModes
	 *
	 * @param  {string} pageId
	 * @param  {string} mode    target mode
	 * @return {boolean}
	 */
	hasAvailableMode(pageId, mode) {
		let hasMode = false;
		let modeData = this.getState().get(pageId);
		if (modeData) {
			let availableModes = modeData.get('availableModes');
			if(availableModes) {
				hasMode = availableModes.includes(mode);
			}
		}

		return hasMode;
	}

	/**
	 * getAvailableModes - Gets availableModes
	 *
	 * @param {string} pageId Page to look for the field's modes on
	 * @return {string}	Available modes for this this page.
	 */
	getAvailableModes(pageId) {
		let availableModes = undefined;
		let page = this.getState().get(pageId);
		if (page !== undefined) {
			availableModes = page.get('availableModes');
		}

		return availableModes;
	}

	/**
	 * Has the page been loaded into this store yet?
	 *
	 * @param {*} pageId
	 * @return {*} 
	 * @memberof PageModeStore
	 */
	hasPage(pageId) {
		return this.getState().has(pageId);
	}

	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 *
	 * @memberOf PageModeStore
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			// When a page is pulled from the database.. set its modes.
			case PageConstants.PAGE_PUSH_TO_STORE: {
				AppDispatcher.waitFor([PageStore.getDispatchToken()]);
				let pageId = action.get('recordId');
				let pageObj = PageStore.get(pageId);
				let availableModes = ['edit', 'view'];
				if (pageObj.availableModes) {
					availableModes = pageObj.availableModes.split(',');
				} else if(pageObj.allowAdding && pageObj.allowAdding === "yes") {
					availableModes.push('add');
				}
				return state.setIn([pageId, 'availableModes'], Immutable.List(availableModes));
			}
			// Also handle it when we process a page update via broadcast
			case PageConstants.PAGE_RECEIVE_BROADCAST: {
				AppDispatcher.waitFor([PageStore.getDispatchToken()]);
				return state.withMutations(state => {
					let records = action.get('records');
					records.forEach((record) => {
						let pageId = record.get('recordId');
						if(!pageId) {
							console.warn('Received page broadcast with no record ID. Action was', action.toJS());
							return state;
						}
						let pageObj = PageStore.get(pageId);
						let availableModes = ['edit', 'view'];
						if (pageObj && pageObj.availableModes) {
							availableModes = pageObj.availableModes.split(',');
						} else if(pageObj && pageObj.allowAdding && pageObj.allowAdding === "yes") {
							availableModes.push('add');
						}
						state.setIn([pageId, 'availableModes'], Immutable.List(availableModes));
					});
				});
			}
			case PageConstants.PAGE_PULL_FROM_DATABASE_ALL: {
				//Waiting for PageStore to load Data 
				AppDispatcher.waitFor([PageStore.getDispatchToken()]);
				let newState = state.withMutations(function(state) {
					action.get('pageArray').forEach((page) => {
						let pageId = page.get('recordId');
						let pageObj = PageStore.get(pageId);
						
						if (pageObj) {
							let availableModes = ['edit', 'view'];
							if (pageObj.availableModes) {
								availableModes = pageObj.availableModes.split(',');
							} else if(pageObj.allowAdding && pageObj.allowAdding === "yes") {
								availableModes.push('add');
							}
			
							state.setIn([pageId, 'availableModes'], Immutable.List(availableModes));
						}
					});
				});
				return newState
			}

			case PageConstants.PAGE_PULL_FROM_DATABASE: {
				//Waiting for PageStore to load Data 
				AppDispatcher.waitFor([PageStore.getDispatchToken()]);

				// retrieve components array from page data
				let pageData = action.get('pageArray').toJS()[0];
				let pageId = pageData.recordId;
				let pageObj = PageStore.get(pageId);
				
				let availableModes = ['edit', 'view'];
				if (pageObj.availableModes) {
					availableModes = pageObj.availableModes.split(',');
				} else if (pageObj.allowAdding && pageObj.allowAdding === "yes") {
					availableModes.push('add');
				}

				let newState = state.setIn([pageId, 'availableModes'], Immutable.List(availableModes));
				return newState;
			}

			case RenderConstants.DELETE_RENDER: {
				let renderId = action.get('renderId');
				let reassignPageChildren = action.get('reassignPageChildren');
				let childIds = RenderStore.findAllChildren(renderId, reassignPageChildren);
				return state.withMutations((state) => {
					state.delete(renderId);
					
					childIds.forEach((childRenderId) => {
						let childDetails = RenderStore.get(childRenderId);
						let componentType = childDetails.componentType;
						let shouldReassign = reassignPageChildren && componentType === 'page';

						// Only delete the non-page children or delete page children if they are not being reassigned
						// (This is a part of the fix for ticket 8292)
						if(!shouldReassign) {
							state.delete(childRenderId);
						}
					});
				});
			}
			// case ContextConstants.CONTEXT_CHANGE: {
			// 	let pageId = action.get('pageId');
			// 	if(!state.getIn([pageId, 'currentMode'])) {
			// 		return state.setIn([pageId, 'currentMode'], 'view');
			// 	}
			// 	return state;
			// }
			case RenderConstants.SET_RENDER: {
				AppDispatcher.waitFor([RenderStore.getDispatchToken(), RecordStore.getDispatchToken()]);

				let renderId = action.get('renderId');
				let renderObj = RenderStore.get(renderId);

				if (renderObj.componentType === 'page') {

					let recordIsNew = renderObj.dataRecordId && renderObj.dataTableSchemaName ? RecordStore.isRecordNew(renderObj.dataTableSchemaName, renderObj.dataRecordId) : true;
					// let currentMode = state.hasIn([renderObj.componentId, 'currentMode']) ? state.getIn([renderObj.componentId, 'currentMode']) : undefined;
					if (
						recordIsNew &&
						this.hasAvailableMode(renderObj.componentId, 'add')
					) {
						return state.setIn([renderId, 'currentMode'], 'add');
					} else if (this.hasAvailableMode(renderObj.componentId, 'view')) {
						return state.setIn([renderId, 'currentMode'], 'view');
					} else if (this.hasAvailableMode(renderObj.componentId, 'edit')) {
						return state.setIn([renderId, 'currentMode'], 'edit');
					} else {
						return state;
					}
				} else {
					return state;
				}
			}

			case RenderConstants.INIT_PAGE:
			case RenderConstants.INIT_GRID: {
				AppDispatcher.waitFor([RenderStore.getDispatchToken(), RecordStore.getDispatchToken()]);

				return state.withMutations(state => {
					if(action.has('grids')) {
						let grids = action.get('grids');
						grids.forEach(grid => {
							let renderId = grid.get('parentRenderId');
							let renderObj = RenderStore.get(renderId);

							if (renderObj && renderObj.componentType === 'page') {
								let recordIsNew = renderObj.dataRecordId && renderObj.dataTableSchemaName ? RecordStore.isRecordNew(renderObj.dataTableSchemaName, renderObj.dataRecordId) : true;
								// let currentMode = state.hasIn([renderObj.componentId, 'currentMode']) ? state.getIn([renderObj.componentId, 'currentMode']) : undefined;
								let availableModes = state.hasIn([renderObj.componentId, 'availableModes']) ? state.getIn([renderObj.componentId, 'availableModes']) : undefined;
								// If this is already in add mode, don't change that just because we got a data record ID
								// that can happen when the dummy record is instantiated
								if (
									recordIsNew &&
									this.hasAvailableMode(renderObj.componentId, 'add')
								) {
									state.setIn([renderId, 'currentMode'], 'add').setIn([renderId, 'availableModes'], availableModes);
								} else if (this.hasAvailableMode(renderObj.componentId, 'view')) {
									state.setIn([renderId, 'currentMode'], 'view').setIn([renderId, 'availableModes'], availableModes);
								} else if (this.hasAvailableMode(renderObj.componentId, 'edit')) {
									state.setIn([renderId, 'currentMode'], 'edit').setIn([renderId, 'availableModes'], availableModes);
								} 
							}
						})
					}
				});
			}

			case PageModeConstants.SET_PAGE_MODE: {
				let RenderStore = require('./render-store').default;
				// We want to make sure the renderObj exists first
				AppDispatcher.waitFor([RenderStore.getDispatchToken()]);
				let renderId = action.get('renderId');
				let renderObj = RenderStore.get(renderId);
				let mode = action.get('mode');
				if (this.hasAvailableMode(renderObj.componentId, mode)) {
					return state.setIn([renderId, 'currentMode'], mode);
				} else {
					return state;
				}
			}

			default: {
				return state;
			}
		}
	}
}

const instance = new PageModeStore(AppDispatcher);
export default instance;