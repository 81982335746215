export const TableRolesConstants = {
  PRIMARY_TABLE: 'primaryTable',
//   ADMIN_TABLE: 'adminTable',
//   CLASSIFICATION_TABLE: 'classificationTable',
//   HISTORY_TABLE: 'historyTable',
//   INTERMEDIARY_TABLE: 'intermediaryTable',
//   TEMPLATE_TABLE: 'templateTable',
//   SYSTEM_TABLE: 'systemTable'
};

export const TableRolesLabels = {
  'primaryTable': 'Primary Table',
//   'adminTable': 'Admin Table',
//   'classificationTable': 'Classification Table',
//   'historyTable': 'History Table',
//   'intermediaryTable': 'Intermediary Table',
//   'templateTable': 'Template Table',
//   'systemTable': 'System Table'
};

export default TableRolesConstants;
