/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';
import Immutable from 'immutable';

// Actions
import AdminSettingsActions from '../../actions/admin-settings-actions';
import InterfaceActions from '../../actions/interface-actions';
import DiscussionActions from '../../actions/discussion-actions';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import ContextStore from '../../stores/context-store';
import DiscussionStore from '../../stores/discussion-store';
import RenderStore from '../../stores/render-store';

// Utils
import uuid from 'uuid';

import { ReactSVG } from 'react-svg';

class DiscussionSettingsChooser extends Component {
	/**
	 * Creates instance of DiscussionSettingsChooser
	 *
	 * @memberOf DiscussionSettingsChooser
	 */
	constructor(props) {
		super(props);
		this._renderOption = this._renderOption.bind(this);
		this.onAddClick = this.onAddClick.bind(this);
		this.onResetClick = this.onResetClick.bind(this);
		this.onSaveClick = this.onSaveClick.bind(this);
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, RenderStore, DiscussionStore];
	}
	
	/**
	 * onAddClick - Sets up a new recordId for the security setting UI to use.
	 *
	 * @param {object} event
	 */
	onAddClick(event){
		let newRecordId = uuid.v4();
		let {
			componentId,
			applicationId,
			pageId,
			dataRecordId,
			dataTableSchemaName,
			installationId
		} = this.state;
		let newRecordObj = {
			temporary: true,
			componentId,
			applicationId,
			pageId,
			dataRecordId,
			dataTableSchemaName,
			installationId,
			discussionId: newRecordId,
			status: 'open',
			priority: 'trivial',
			type: 'discussion',
		};
		DiscussionActions.pushToStore(newRecordId, newRecordObj);
		AdminSettingsActions.onSettingChange('', newRecordId);
	}

	/**
	 * onSaveClick - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	onSaveClick(event) {
		// Display notification to user
		let generalSaveNotification = 
			InterfaceActions.stickyNotification({level: 'info', message: 'Updating Discussion and any Comments. This may take a few seconds, please wait...'});
		
		let {discussions} = this.state;
		let discussionUpdatePromises = [];

		let discussionsToUpdate = discussions
		.filter(discussion => {
			return !!discussion.isDirty || (!!discussion.wipComment && !!discussion.wipComment.comment);
		});
		discussionsToUpdate
			.forEach(discussionObj => {
				discussionUpdatePromises.push(DiscussionActions.pushToDatabasePromise(discussionObj));
			});

		Promise.all(discussionUpdatePromises)
		.then(() => {
			InterfaceActions.clearStickyNotification(generalSaveNotification);			
			AdminSettingsActions.onSettingChange('', '');
		})
		.catch(error => {
			console.error(error);
			InterfaceActions.clearStickyNotification(generalSaveNotification);
			InterfaceActions.notification({level: 'error', message: 'Discussion save error.  See your console for details.'});
			AdminSettingsActions.onSettingChange('', '');
		});
	}

	/**
	 * onResetClick - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event
	 */
	onResetClick(event){
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Resetting Discussion and any Comments...' });

		let toReset = AdminSettingsStore.getSettingRecordId();;
		let discussionObj = DiscussionStore.get(toReset) || Immutable.Map();
		if(discussionObj.get('temporary')) {
			DiscussionActions.deleteFromStore(toReset);
			AdminSettingsActions.onSettingChange('');
		} else {
			DiscussionActions.pullFromDatabase(toReset, true);
		}
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 * @memberof QuerySettingsChooser
	 */
	onSettingClick(discussionId, event) {
		event.preventDefault();

		if(!discussionId) {
			return;
		}

		// Ensure the Settings are shown
		AdminSettingsActions.onSettingsListHideChange(false);

		// Toggle to select and deselect the Setting
		if(AdminSettingsStore.getSettingRecordId() === discussionId) {
			AdminSettingsActions.onSettingChange('');
		} else {
			AdminSettingsActions.onSettingChange(undefined, discussionId);
		}
	}

	/**
	 * 
	 * @static
	 * @param {any} prevState 
	 * @returns {Object} - Query Setting list
	 * @memberof QuerySettingsChooser
	 */
	static calculateState(prevState, props) {

		let renderId = AdminSettingsStore.getRenderId();
		let renderObj = RenderStore.get(renderId) || {};
		let pageObj = RenderStore.getPageRenderObj(renderId) || {};
		let componentId = AdminSettingsStore.getRecordId();
		let {dataRecordId, dataTableSchemaName} = renderObj;
		let pageId = pageObj.componentId;
		let applicationId = ContextStore.getApplicationId();
		let installationId = ContextStore.getInstallationId();

		// For now, default to all discussions if no component specified
		let discussions = componentId 
			? DiscussionStore.getDiscussionsForComponent(componentId) 
			: DiscussionStore.getAllArray();

		return {
			discussions,
			componentId,
			applicationId,
			pageId,
			dataRecordId,
			dataTableSchemaName,
			installationId
		};
	}

/**
	 * Renders a single discussion as an LI option.
	 * 
	 * @param {Object} discussionObj 
	 * @param {string} discussionObj.isSelected Is this option the selected UI option?
	 * @param {string} discussionObj.discussionId Record Id
	 * @param {number} discussionObj.number Discussion Number.
	 * @param {number} index The Index of this option
	 * @return JSX
	 */
	_renderOption(discussionObj, index) {
		let disabledSuffix = ' ';
		if(discussionObj.status === 'closed') {
			disabledSuffix = ' disabled ';
		}
		let labelClassNames = 'setting-label' + disabledSuffix;

		let isSelected = false;
		if(discussionObj.discussionId === AdminSettingsStore.getSettingRecordId()) { 
			labelClassNames += ' selected';
			isSelected = true;
		}

		// Type Icon
		let typeIcon = null;
		if(discussionObj.type) {
			let faIconName = '';
			switch(discussionObj.type) {
				case 'bug': {
						faIconName = 'bug';
					break;
				}
				case 'change': {
						faIconName = 'plus-circle';
					break;
				}
				default:
				case 'discussion': {
						faIconName = 'comment';
					break;
				}
			}
			typeIcon = <div className={"pr-1 setting-icon" + disabledSuffix + "fa fa-" + faIconName} />
		}

		// Priority Label
		let priorityLabel = null;
		if(discussionObj.priority) {
			priorityLabel = {
				'critical': 'Critical',
				'major': 'Major',
				'minor': 'Minor',
				'trivial': 'Trivial',
			}[discussionObj.priority];
		}

		// Override the Prority lable with "Closed" if the status is closed.
		if(discussionObj.status === 'closed') {
			priorityLabel = 'Closed';
		}

		return (
			/* Had classname discussion here.. not sure what that did */
			<li key={index} className={"nav-item d-flex flex-column justify-content-center" + (isSelected ? ' setting-selected' : '')}>
				<div className="nav-link" onClick={this.onSettingClick.bind(this, discussionObj.discussionId)}>
					<div className="d-flex setting-text-container">
						<div className='setting-label-container'>
							<div class={labelClassNames + " d-flex align-items-center position-relative"}>
								<h4>{discussionObj.name || '[ No Name Found ]'}</h4>
							</div>
						</div>
						<div className="d-flex justify-content-end setting-value-container">
							<div className={`text-right setting-pattern-list setting-value align-self-center`}>
								<h5 className={"settings-list-value " + disabledSuffix}>{priorityLabel}</h5>
							</div>
							<div className={"setting-scope badge badge-pill align-self-center"}> 
								{typeIcon}
							</div>
						</div>
					</div>
				</div>
			</li>);
	}

	/**
	 * 
	 * @returns - DOM of list of Query
	 * @memberof QuerySettingsChooser
	 */
	render() {
		let settingsList = [];

		settingsList.push(this.state.discussions.sort((a, b) => {
			// Put closed stuff last.
			if(a.status === 'closed' && b.status !== 'closed') {
				return 1;
			} else if (a.status !== 'closed' && b.status === 'closed') {
				return -1;
			}

			// No number?  Go to the bottom:
			if(a.number === '' || a.number === null || a.number === undefined) return 1;
			if(b.number === '' || b.number === null || b.number === undefined) return -1;
			
			// Same Number?  Don't Swap.
			if(a.number === b.number) return 0;

			// Ascending Numericallly.
			return +a.number < +b.number ? 1 : -1;
		}).map(this._renderOption.bind(this)));

		return [
			<div key="list" className='settings-list-wrapper mt-0'>
				<ul key="list" className="nav flex-column">
					<li className="settings-list-header field-settings-list-header">
						<div className="d-flex">
							<ReactSVG 
								beforeInjection={svg => {
									svg.setAttribute('style', 'width: 22px; height: 22px');
								}}
								src={ContextStore.getUrlMedia() + "/icon-discussion.svg"} />
							<h3>Discussions</h3>
						</div>
					</li>
					{settingsList}
					<li 
						key="add" 
						className="nav-header d-flex" 
						form="appearance-form" 
						aria-label="Add Discussion">
							<button onClick={this.onAddClick} style={{ marginTop: '10px', marginBottom: '15px' }} className="btn btn-success">
								<label>Add Discussion</label>
							</button>
					</li>
				</ul>
			</div>,
			<div key="buttons" className="btn-wrapper mx-2">
				<button 
					id={'discussionSave'}
					title="Save (Ctrl-S)"
					key="submit" 
					className="btn btn-primary btn-lg setting-list-button w-50 mr-1" 
					form="appearance-form" 
					aria-label="Save"
					onClick={this.onSaveClick}>
					Save
				</button>
				<button 
					id={'discussionReset'}
					title="Reset (Ctrl-R)"
					key="reset" 
					className="btn btn-warning btn-lg setting-list-button w-50 ml-1" 
					form="appearance-form" 
					aria-label="Reset" 
					onClick={this.onResetClick}>
					Reset
				</button>
			</div>];
	}
}
const container = Container.create(DiscussionSettingsChooser, { withProps: true });
export default container;