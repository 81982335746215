var keyMirror = require('keymirror');

// Define action constants
var AuthenticationConstants = keyMirror({
  'AUTH_CREDS_SET': null,
  'AUTH_ERROR': null,
  'AUTH_LOGIN': null,
  'AUTH_LOGOUT': null,
  'SET_USER_IP': null
});
module.exports = AuthenticationConstants;
