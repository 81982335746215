import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DataTypes from '../../constants/data-types';
import BaseSettingForm from './base-setting-form.react';
/**
 * Component for DataTypeSelect
 * 
 * @class DataTypeSelect
 * @extends {Component}
 */
export default class DataTypeSelect extends BaseSettingForm {
	/**
	 * Creates an instance of DataTypeSelect
	 * 
	 * @param {Object} props
	 * @constructor
	 * 
	 * @memberof DataTypeSelect
	 */
	constructor(props) {
		super(props);
		this.renderOption = this.renderOption.bind(this);
	}

	/**
	 * Renders FieldType datatype option
	 *
	 * @param {String} datatype option
	 * @return React
	 *
	 * @memberof DataTypeSelect
	 */
	renderOption(datatype, index) {
		let {ommit} = this.props;
		if(ommit && ommit.includes(datatype)) {
			return null;
		}
		return <option key={'datatype-' + index} value={datatype}>{datatype}</option>;
	}
	/**
	 * Render the DataTypeSelect
	 * 
	 * @returns React
	 * 
	 * @memberof DataTypeSelect
	 */
	render() {
		let {onChange, fieldSchemaName, value} = this.props,
			options = _.values(DataTypes);

		return(
			<select id={fieldSchemaName} className="form-control" value={value} onChange={onChange}>
				{options.map(this.renderOption)}
			</select>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	DataTypeSelect.propTypes = Object.assign({}, BaseSettingForm.propTypes, {
		value: PropTypes.any,
		ommit: PropTypes.array
	});
}
