import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormBuilder from '../../utils/form-builder';
import PageStore from '../../stores/page-store';
import FieldStore from '../../stores/field-store';

require ('../../styles/components/_child-field-table.scss');

/**
 * Table of child fields - for one screen size.
 * 
 * @class AttachmentParentTable
 * @extends {Component}
 */
class AttachmentParentTable extends Component {
	/**
	 * Create instance of fieldTypeChooserContainer
	 *
	 * @memberof AttachmentParentTable
	 */
	constructor(props) {
		super(props);
		this.screensizeHeaderNames = {
			'lg': 'Desktop',
			'md': 'Tablet',
			'sm': 'Mobile'
		};
		this._onChildSettingValueChange = this._onChildSettingValueChange.bind(this);
		this._renderAttachmentParentTable = this._renderAttachmentParentTable.bind(this);
	}
	/**
	 * Change a setting value for a child field.
	 * 
	 * @param {string} childFieldId Field ID of the child Field (IE, the List's Column, or : Note Body)
	 * @param {string} settingSchemaName Schema Name of the child field setting we're changing (IE, Resizable)
	 * @param {string} settingValue New value for this setting.
	 * @param {Function} onChange Function to run with the new value, on change.
	 * 
	 * @memberof AttachmentParentTable
	 */
	_onChildSettingValueChange(childFieldId, settingSchemaName, settingValue) {
		// console.log(childFieldId, settingSchemaName, settingValue);
		//Grab the value that contains only the current field
		let {value, screenSize, parentTableSchemaName, parentRecordId} = this.props,
			parentObj = {},
			allChildFieldsJSON= '',
			allChildFieldsObj = {};

		//Check if parent is Page or Field 
		if(parentTableSchemaName === 'page'){
			//Grab the Array of Components for this Page and this ScreenSize
			parentObj = PageStore.get(parentRecordId);
			allChildFieldsJSON = (parentObj && parentObj.components) ? parentObj.components : '';
		} else if(parentTableSchemaName === 'field'){
			//Grab the Array of Components for this Page and this ScreenSize
			parentObj = FieldStore.get(parentRecordId);
			let parentSettingsObj = FieldStore.getSettings(parentRecordId);
			allChildFieldsJSON = parentSettingsObj['columnsConfig'] ? parentSettingsObj['columnsConfig'] : parentSettingsObj['childFields'];
		}
		try {
			//Validation to Get The Entire Value of the  Page
			allChildFieldsObj = JSON.parse(allChildFieldsJSON);
		}  catch(e){
			console.warn('Error parsing Parent Components JSON: ', allChildFieldsJSON);
		}

		if(value) {
			// make sure this screensize is managing itself...tho it only contains 1 object (the single row)
			if(Array.isArray(value)) {
				let myColumnConfig = value.find(childFieldConfig => {
					if(childFieldConfig.fieldId === childFieldId) {
						return true;
					}
					return false;
				});
				// If we found this child field...
				if(myColumnConfig) {
					//Update it's setting's value
					myColumnConfig[settingSchemaName] = settingValue;

					//Now replace it in the Values that we are sending to the Store:
					let newValue =  allChildFieldsObj[screenSize].map(component => {
						if(component.fieldId === childFieldId) {
							return myColumnConfig;
						} else {
							return component;
						}
					});
					// Push to store.
					this.props.onChange({ target: {
						screenSize: this.props.screenSize,
						value: newValue
					}});
				} else {
					console.warn('Unable to find my column config');
				}
			} else {
				console.warn('Value is not an array in _onChildSettingValueChange?');
			}
		} else {
			console.warn('value is empty in _onChildSettingValueChange');
		}
	}
	/**
	 * Render the JSX for the Child Fields Table, if our value is NOT a string.
	 * 
	 * @param {mixed} value Our table's Value.. either a string, or an array
	 * @param {Object} childColumnSettings Definition of the columns of our table
	 * @param {Function} onChange Function to run with the new value, on change.
	 * @todo Split Header Row into a functions
	 * @todo Split Body Rows into a function
	 * @todo Split empty/add row into a function
	 * @memberof AttachmentParentTable
	 */
	_renderAttachmentParentTable(value, childColumnSettings, onChange) {
		let rowColIndex = 0;

		// Check to see if our screensize config is an array...
		if(Array.isArray(value)) { 
			// Loop over the child columns and generate our table's header.
			let headerColumns = [];
			childColumnSettings.forEach(childColumnSetting => {
				headerColumns.push(<th key={rowColIndex} className="text-center">{childColumnSetting.label}</th>);
				rowColIndex++;
			});

			// Loop over the value and generate one row for each object in the array.
			let bodyRows = [];
			value.forEach(childField => {
				var rowColumns = [],
					childFieldId = childField.fieldId;
				
				// Loop over each child column setting and add it's column...
				childColumnSettings.forEach(childColumnSetting => {
					let componentName = _.upperFirst(childColumnSetting.dataType),
						component = FormBuilder.componentFor(componentName),
						// Create child props from an empty object {}, merged with childColumnsettings, merged with our own object.
						childProps = Object.assign({}, childColumnSetting, {
							onChange: function(event){
								let newValue = event.target.value;
								this._onChildSettingValueChange(childFieldId, childColumnSetting.fieldSchemaName, newValue);
							}.bind(this),
							value: childField[childColumnSetting.fieldSchemaName],
							fieldTypeId: childField['fieldTypeId']
						});
					rowColumns.push((
						<td className="text-center" key={rowColIndex}>
							{React.createElement(component, childProps, null)}
						</td>
					));
					rowColIndex++;
				});

				bodyRows.push((
					<tr key={rowColIndex}>{rowColumns}</tr>
				));
				rowColIndex++;
			});
			
			return (
				<div className="row">
					<table className="table table-striped">
					<thead>
						<tr>
							{headerColumns}
						</tr>
					</thead>
					<tbody>
						{bodyRows}
					</tbody>
					</table>
				</div>);

		} else {
			//Define the Label of the ScreenSize for this Setting
			let sameAsScreenSize = '';

			if(value === 'lg'){
				sameAsScreenSize = 'Desktop';
			} else if(value === 'md'){
				sameAsScreenSize = 'Tablet';
			} else if(value === 'sm'){
				sameAsScreenSize = 'Mobile';
			}

			return (
				<div className='row manage-text'>
				Manage: Same as {sameAsScreenSize}
				</div>
			);
		}
	}

	/**
	 * Render the Child Field Table, for a screen size
	 * 
	 * @returns React
	 * 
	 * @memberof AttachmentParentTable
	 */
	render() {
		let {onChange, screenSize, value} = this.props,
			headerTitle = `Screen size settings for ${this.screensizeHeaderNames[screenSize]}`,
			// Assume that we are managing ourselves:
			childColumnSettings = this.props.childSettings;
		let childFieldSettingsTable = this._renderAttachmentParentTable(value, childColumnSettings, onChange);	

		return (
			<div id="attachment-parent">
				{/* Header Div */}
				<div className="row">
					<h5>{headerTitle}</h5>
				</div>
				{childFieldSettingsTable}
			</div>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	AttachmentParentTable.propTypes = {
		fieldId: PropTypes.string,
		fieldSchemaName: PropTypes.string,
		childSettings: PropTypes.array,
		screenSize: PropTypes.string,
		onChange: PropTypes.func,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		tableSchemaName: PropTypes.string,
		recordId: PropTypes.string,
		parentRecordId: PropTypes.string,
		parentTableSchemaName: PropTypes.string
	};
}

export default AttachmentParentTable;