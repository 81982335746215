var keyMirror = require('keymirror');

// Define action constants
var ComplianceConstants = keyMirror({
	'COMPLIANCE_DELETE_FROM_STORE': null,
	'COMPLIANCE_PULL_FROM_DATABASE': null,
	'COMPLIANCE_PULL_FROM_DATABASE_ALL': null,
	'COMPLIANCE_PULL_ERROR': null,
	'COMPLIANCE_PUSH_TO_DATABASE': null,
	'COMPLIANCE_PUSH_TO_STORE': null,
	'COMPLIANCE_COMMENT_PULL_FROM_DATABASE_ALL': null,
	'COMPLIANCE_COMMENT_PUSH_TO_STORE': null,
	'COMPLIANCE_COMMENT_PUSH_TO_DATABASE': null
});
module.exports = ComplianceConstants;
