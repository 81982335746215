// Disappointments
import $ from 'jquery';

// Actions
import AdminSettingsActions from '../actions/admin-settings-actions';
import AppearanceAssistantActions from '../actions/appearance-assistant-actions';

// React
// import ReactDOM from 'react-dom';
import React from 'react';

// Stores
import {
	AdminSettingsStore,
	ContextStore,
	FieldStore,
	FieldTypeStore,
	LogicFunctionStore,
	MetadataStore,
	PageStore,
	RelationshipStore,
	TableStore,
} from '../stores'

// Utils
import FieldUtils from './field-utils';
import RelationshipUtils from './relation-utils';

// Components
import Select, { components } from 'react-select';

/**
 * UI Utils
 * 
 * @export
 * @class UIUtils
 */
export default class UIUtils {
	static closeSettingsPanel() {
		AdminSettingsActions.onRightPanelChange(false);
		AdminSettingsActions.onShowComponentDetails(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,'appearance');
	}

	/**
	 * getResponsiveMode - determines if resizer is in lg, md, or sm mode
	 *
	 * @static
	 * @param  {number} width width of container
	 * @return {string}       'lg', 'md', or 'sm'
	 * @memberof UIUtils
	 */
	static getResponsiveMode(width) {
		let mode;
		if (width >= 992) {
			mode = 'lg';
		} else if (width >= 768) {
			mode = 'md';
		} else {
			mode = 'sm';
		}
		return mode;
	}

	/**
	 * Return the "Viewport" height and width, regardless of Dev or Test/Prod.
	 * 
	 * @static
	 * @returns {{height: number, width: number}} {height: number, width: number}
	 * @memberof UIUtils
	 */
	static getSize() {
		if(!window.AdminSettingsStore) {
			return {
				width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
				height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
			}
		} else {
			return window.AdminSettingsStore.getSizeJS();
		}
	}

	/**
	 * Get the SVN Icon Path for the Field Type.
	 * @param {string} fieldTypeId 
	 * @returns 
	 */
	static getFieldTypeIcon(fieldTypeId) {
		FieldUtils.getFieldTypeIconByFieldTypeId(fieldTypeId);
	}

	/**
	 * Determine if we should include stores/actions/etc. from the window's opener
	 * or create a new one
	 * 
	 * @static
	 * @returns boolean
	 * @memberof UIUtils
	 */
	static includeFromOpener() {
		try {
			if(window.location.href.includes('settings.html') && typeof window.opener.location.href === 'string') {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	}

	/* Capture the Ctrl-S or Ctrl-R for Save and Reset for each of the settings panels */
	static onKeyDown(event) {
		if(event.ctrlKey && event.key === 's') {
			event.preventDefault();
			document.getElementById(AdminSettingsStore.getSelectedOverlay() + 'Save').click();
		}
		// when on MAC
		if(event.metaKey && event.key === 's') {
			event.preventDefault();
			document.getElementById(AdminSettingsStore.getSelectedOverlay() + 'Save').click();
		}

		// Check if keys pressed are ctrl+r
		if(event.ctrlKey && event.key === 'r') {
			event.preventDefault();
			document.getElementById(AdminSettingsStore.getSelectedOverlay() + 'Reset').click();
		}
		// when on MAC
		if(event.metaKey && event.key === 'r') {
			event.preventDefault();
			document.getElementById(AdminSettingsStore.getSelectedOverlay() + 'Reset').click();
		}
	}

	/**
	 * Opens the settings panel
	 * 
	 * @static
	 * @param {any} targetOverlay 
	 * @param {any} recordId 
	 * @param {any} tableSchemaName 
	 * @param {any} parentRecordId 
	 * @param {any} parentTableSchemaName 
	 * @param {any} mainTab 
	 * @memberof UIUtilingss
	 */
	static openSettingsPanel(targetOverlay, recordId, tableSchemaName, parentRecordId, parentTableSchemaName, mainTab,
		subSettingSchemaName, subSettingIndex, renderId, attachmentId) {

		if(tableSchemaName === 'page') {
			AdminSettingsActions.onSettingsTabChangeSub('main');
		}	
		
		if (mainTab) {
			AdminSettingsActions.onSettingsTabChangeMain(mainTab);
		}

		if(subSettingSchemaName) {
			AdminSettingsActions.onSubSettingChange(subSettingSchemaName, subSettingIndex);
		} else {
			AdminSettingsActions.onSubSettingChange(null, null);
		}

		// Assume we should open the RH Panel
		let panelShouldBeOpen = true;

		// Check to see if it's already open...
		// let isRightPanelOpen = AdminSettingsStore.getIsRightPanelOpen();
		// if(!isRightPanelOpen && targetOverlay === 'appearance') {
		// 	// If it's not open, we're likely detached.. but only if we're looking for appearance
		// 	window.detachedSettingWindows.forEach(detachedSettingWindow => {
		// 		// Is this detached window the same overlay as the one we're trying to open?
		// 		if(detachedSettingWindow.name.includes(targetOverlay)) {
		// 			// If so, don't open the RH Panel.
		// 			panelShouldBeOpen = false;
		// 			// Focus on this popup
		// 			detachedSettingWindow.ref.focus();
		// 		}
		// 	});
		// }

		if(targetOverlay !== 'automation') {
			// Ensure the Settings are shown
			AdminSettingsActions.onSettingsListHideChange(false);
		}

		// Clear the assistant input.
		AppearanceAssistantActions.onInputChange('');
		// Clear the selected setting
		AdminSettingsActions.onSettingChange(null, null);
		
		// hide the settings preview
		AdminSettingsActions.onSettingsPreviewChange(false);

		// Open the Settings Panel
		AdminSettingsActions.onRightPanelChange(panelShouldBeOpen);

		// Load the Admin Settings Store with *this* components info
		AdminSettingsActions.onShowComponentDetails(
			recordId, renderId, attachmentId, tableSchemaName, 
			parentRecordId, parentTableSchemaName, 
			undefined, undefined, // Grid Key?!  and Field Type (which we derive)
			targetOverlay);
	}


	/**
	 * Opens the settings preview
	 * 
	 * @static
	 * @param {any} targetOverlay 
	 * @param {any} recordId 
	 * @param {any} tableSchemaName 
	 * @param {any} parentRecordId 
	 * @param {any} parentTableSchemaName 
	 * @param {any} mainTab 
	 * @memberof UIUtilingss
	 */
	static openSettingsPreview(targetOverlay, recordId, tableSchemaName, parentRecordId, parentTableSchemaName, mainTab,
		subSettingSchemaName, subSettingIndex, renderId, attachmentId) {

		// @TODO Determine what the Settings TAB .. Sub ... is?
		if(tableSchemaName === 'page') {
			AdminSettingsActions.onSettingsTabChangeSub('main');
		}	
		
		// @TODO Determine what the Settings TAB .. Main ... is?
		if (mainTab) {
			AdminSettingsActions.onSettingsTabChangeMain(mainTab);
		}

		// @TODO When do we do this?
		if(subSettingSchemaName) {
			AdminSettingsActions.onSubSettingChange(subSettingSchemaName, subSettingIndex);
		} else {
			AdminSettingsActions.onSubSettingChange(null, null);
		}

		// Clear the assistant input.
		AppearanceAssistantActions.onInputChange('');
		// Clear the selected setting
		AdminSettingsActions.onSettingChange(null, null);
		
		// Load the Admin Settings Store with *this* components info
		AdminSettingsActions.onShowComponentDetails(
			recordId, renderId, attachmentId, tableSchemaName, 
			parentRecordId, parentTableSchemaName, 
			undefined, undefined, // Grid Key?!  and Field Type (which we derive)
			targetOverlay);

		// Toggle the settings preview
		AdminSettingsActions.onSettingsPreviewChange(true);
	}

	/**
	 * Finds and replaces SVG Images
	 * 
	 * @static
	 * @memberof UIUtils
	 */
	static replaceSVGImages() {
		$('img.icon').each(function () {
			let $img = $(this);
			let imgID = $img.attr('id');
			let imgClass = $img.attr('class');
			let imgURL = $img.attr('src');

			$.get(imgURL, function (data) {
				// Get the SVG tag, ignore the rest
				var $svg = $(data).find('svg');

				// Add replaced image's ID to the new SVG
				if (typeof imgID !== 'undefined') {
					$svg = $svg.attr('id', imgID);
				}
				// Add replaced image's classes to the new SVG
				if (typeof imgClass !== 'undefined') {
					$svg = $svg.attr('class', imgClass + ' replaced-svg');
				}

				// Remove any invalid XML tags as per http://validator.w3.org
				$svg = $svg.removeAttr('xmlns:a');

				// Replace image with new SVG
				$img.replaceWith($svg);

			}, 'xml');
		});
	}

	/**
	 * Handle clicking on an upgrade link
	 * @param {string} url
	 */
	static onUpgradeClick(url) {
		url = url.replace('INSTALLATIONID', ContextStore.getInstallationId());
		if(ContextStore.getMDGWMode() === 'stage') {
			url = url.replace('platform', 'test-platform');
		}
		window.open(url, 'upgrade');
	}

	/**
	 * Handle clicking on an help link
	 * @param {string} helpKey 
	 */
	static onHelpClick(url) {
		this.onUpgradeClick(url);
	}


	/**
	 * Returns the overlay sub-header elements for a selected field or page.
	 * @param {string} recordId fieldId or pageId
	 * @param {string} tableSchemaName field | page
	 * @return {Array} Sub Header JSX Elements
	 */
	static getOverlaySubHeaderElements(recordId, tableSchemaName) {
		let subHeaderElements = [];
		if(tableSchemaName === 'field') {
			let fieldId = recordId;
			let fieldObj = FieldStore.get(fieldId) || {};
			
			// Check whether tableSchemaName is present
			if(fieldObj.tableSchemaName) {
				let fieldsTable = fieldObj.tableSchemaName;
				let tableObj = TableStore.getByTableSchemaName(fieldsTable);
				if(tableObj) {
					let tableElement = [];
					if(tableObj.icon) {
						tableElement.push(<div key={"icon"} className={"mx-1 fa fa-" + tableObj.icon}></div>);
					}
					if(tableObj.singularName) {
						tableElement.push(tableObj.singularName);
					}
					subHeaderElements.push(tableElement);
				}
			}
			// Check whether field has a fieldType
			if(fieldObj.fieldType) {
				let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType) || {};
				if(fieldTypeObj && fieldTypeObj.name) {
					subHeaderElements.push(fieldTypeObj.name);
				}
			}
		} else if (tableSchemaName === 'page') {
			let pageId = recordId;
			let pageObj = PageStore.get(pageId);

			// Check whether tableSchemaName is present
			if(pageObj.tableSchemaName) {
				let pagesTable = pageObj.tableSchemaName;
				let tableObj = TableStore.getByTableSchemaName(pagesTable);
				if(tableObj) {
					let tableElement = [];
					if(tableObj.icon) {
						tableElement.push(<div key={"icon"} className={"mx-1 fa fa-" + tableObj.icon}></div>);
					}
					if(tableObj.singularName) {
						tableElement.push(tableObj.singularName);
					}
					subHeaderElements.push(tableElement);
				}
			}
			subHeaderElements.push('Page');
		} else if (tableSchemaName === 'table') {
			subHeaderElements.push('Table');
		}

		let subHeaderArr = [];

		// Create the subHeaderElements variable, an array of the state's subheader elements with : between them.
		subHeaderElements.forEach((element, key) => {
			subHeaderArr.push(
				<span key={key}>{ element }</span>
			);
			if((key + 1) !== subHeaderElements.length) {
				subHeaderArr.push(
					<span key={'colon' + key}> : </span>
				);
			}
		});

		return subHeaderArr;
	}

	/**
	 * Returns the overlay header for a selected field or page.
	 * @param {string} componentId fieldId or pageId
	 * @param {string} componentType field | page
	 * @return {string}
	 */
	static getSettingsHeader(componentId, componentType) {
		let headerLabel = '';
		switch(componentType) {
			case 'apiconfig':
			case 'scheduledLogic':
				let logicObj = MetadataStore.get(componentId, componentType) || {};
				headerLabel = logicObj.name;
				if(!headerLabel) {
					headerLabel = '[ No Name found ]';
				}
			break;
			case 'applications':
				headerLabel = 'Theme';
			break;
			case 'field':
				let fieldObj = FieldStore.get(componentId) || {};
				headerLabel = fieldObj.fieldLabel;
				if(!headerLabel) {
					headerLabel = '[ No Field Label found ]';
				}
			break;
			case 'logicfunctions':
				let functionObj = LogicFunctionStore.get(componentId) || {};
				headerLabel = functionObj.name;
				if(!headerLabel) {
					headerLabel = '[ No Name found ]';
				}
			break;
			case 'page':
				let pageObj = PageStore.get(componentId) || {};
				headerLabel = pageObj.name;
				if(!headerLabel) {
					headerLabel = '[ No Page Name found ]';
				}
			break;
			case 'relationship':
				let relationshipObj = RelationshipStore.get(componentId) || {};
				headerLabel = relationshipObj.ltorLabel;
				if(!headerLabel) {
					headerLabel = '[ No Relationship Name found ]';
				}
			break;
			case 'table':
				let tableObj = TableStore.get(componentId) || {};
				headerLabel = tableObj.pluralName;
				if(!headerLabel) {
					headerLabel = '[ No Table Name found ]';
				}
			break;
			case 'webhook':
				headerLabel = 'Stripe Payments - WebHooks';
			break;
			default:
				console.warn('Unable to generate heading label for:', componentType);
			break;
		}
		return headerLabel;
	}

	/**
	 * Generate the first header sub row
	 */
	static getSettingsHeaderSub1(componentId, componentType) {
		let returnObj = {};
		switch(componentType)
		{
			case 'apiconfig':
				returnObj.label = 'Incoming REST API Endpoint'
				returnObj.icon = ContextStore.getUrlMedia() + "/icon-logic.svg";
			break;
			case 'applications':
				returnObj.label = 'Manage Application Theme'
				returnObj.icon = ContextStore.getUrlMedia() + "/icon-theme.svg";
			break;
			case 'field':
				let fieldObj = FieldStore.get(componentId);
				if(fieldObj) {
					returnObj.label = 'Field Type: ' + FieldUtils.getFieldTypeName(componentId);
					returnObj.icon = FieldUtils.getFieldTypeIcon(componentId);
				}
			break;
			case 'logicfunctions':
				returnObj.label = 'Logic Function'
				returnObj.icon = ContextStore.getUrlMedia() + "/icon-logic.svg";
			break;
			case 'page':
				returnObj.label = 'Page'
				returnObj.icon = FieldUtils.getFieldTypeIcon('page');
			break;
			case 'scheduledLogic':
				returnObj.label = 'Scheduled Logic'
				returnObj.icon = ContextStore.getUrlMedia() + "/icon-logic.svg";
			break;
			case 'relationship':
				returnObj.label = 'Relationship';
				returnObj.icon = RelationshipUtils.getRelationIcon(componentId);
			break;
			case 'table':
				returnObj.label = 'Table'
				returnObj.icon = ContextStore.getUrlFontawesome() + "/database.svg";
			break;
			case 'webhook':
				returnObj.label = 'Webhook - Stripe'
				returnObj.icon = ContextStore.getUrlMedia() + "/icon-logic.svg";
			break;
			default:
				console.warn('No Sub Header 1 Defined for:', componentType);
			break;
		}

		return returnObj;		
	}

	/**
	 * Generate the second header sub row
	 */
	static getSettingsHeaderSub2(parentComponentId, parentComponentType) {
		let returnObj = {};
		switch(parentComponentType) {
			case 'field':
				let fieldObj = FieldStore.get(parentComponentId);
				if(fieldObj && fieldObj.fieldType) {
					let fieldTypeId = fieldObj.fieldType;
					let fieldTypeObj = FieldTypeStore.get(fieldTypeId);

					let fieldLabel = fieldObj.fieldLabel;
					if(!fieldLabel || fieldLabel.length === 0) {
						fieldLabel = '[ No Field Label found ]';
					}

					if(!fieldTypeObj) {
						console.warn('Missing fieldTypeObj for fieldType', fieldTypeId);
						break;
					}

					returnObj.label = 'Parent: ' + fieldLabel + '; Field Type: ' + fieldTypeObj.name;	
					returnObj.icon = FieldUtils.getFieldTypeIconByFieldTypeId(fieldTypeId);
				}
			break;
			case 'page':
				let pageObj = PageStore.get(parentComponentId);
				if(pageObj) {
					let pageName = pageObj.name;
					if(!pageName || pageName.length === 0) {
						pageName = '[ No Page Name found ]';
					}
					returnObj.label = 'Parent: ' + pageName + ' Page';
					returnObj.icon = FieldUtils.getFieldTypeIconByFieldTypeId('page');
				}
			break;
			default:
			break;
		}

		return returnObj;		
	}

	/**
	 * Returns true if field allows overrides
	 * @param {string} parentRecordId 
	 * @param {string} fieldSchemaName 
	 * @param {string} fieldType 
	 * @returns boolean
	 */
	static getFieldAllowsOverrides(parentRecordId, fieldSchemaName, fieldType) {
		let nonLocalSettingSchemaNames = [
			"roles", "searchSynonyms", "barConfig", "columnConfig", "donutConfig", "ganttConfig", "gaugeConfig", "solidgaugeConfig", 
			"lineConfig", "pieConfig", "xAxisGridLineWidth", "xAxisTitle", "yAxisGridLineWidth", "yAxisTitle", "yAxisType", 
			"aggregation", "displayDataLabels", "chartDescription", "chartTitle", "displayLegend", "subtitle", "currentDateIndicator", "enableZooming",
			"colorBands", 'authorizeNetConfig'
		];

		//Make sure to have a parent Record Id and 
		if (parentRecordId && 
			// The setting to render is not the roles, BLAH or BLAH setting
			!(nonLocalSettingSchemaNames.includes(fieldSchemaName) && fieldType !== undefined) &&
			// The setting to render is not a fieldType "Setting - Child Fields" of a Field and
			!(fieldType === "f3cc4b2c-e8cf-4fcc-a2a7-605b9bac0531" && fieldType !== undefined) &&
			!(fieldType === "81976a6b-5973-4876-b999-d3d9b63ee2dd" && fieldType !== undefined) &&
			// The setting to render is not a fieldType "Setting - Content Tab Builder" of a Field and
			!(fieldType === "5115adfb-bc59-471c-9f7f-e8c180528e37" && fieldType !== undefined) &&
			// The setting to render is not a fieldType "Setting - Screensize Management" of a Field 
			!(fieldType === "8ce97c76-c4d3-43d6-9ff9-490e2c05a40e" && fieldType !== undefined) &&
			// The setting to render is not a fieldType "Relationship to Manage" of a Field
			!(fieldType === "ce0dbfec-e9d9-4dc3-b39a-456eca2b5282" && fieldType !== undefined)) {
			
			return true;
		}

		return false;

	}

	/**
	 * Helper method to display popover content
	 * 
	 * @static
	 * @param {Object} overlayPinElement 
	 * @param {Element} referenceLink 
	 * @param {string} recordId 
	 * @param {string} tableSchemaName 
	 * @param {string} parentRecordId 
	 * @param {string} parentTableSchemaName 
	 * @param {string} subSettingIndex 
	 * @param {string} subSettingSchemaName 
	 * @param {string} renderId 
	 * @memberof UIUtils
	 */
	static getPopoverDataContent(overlayPinElement, referenceLink, recordId, 
		tableSchemaName, parentRecordId, parentTableSchemaName,
		subSettingIndex, subSettingSchemaName, renderId, attachmentId) {

		// let popoverOpenTimer = null;
		// let popoverCloseTimer = null;
		// $(referenceLink).popover({
		// 	html: true,
		// 	animation: true,
		// 	container: '#App',
		// 	placement: "auto",
		// }).on('mouseenter', function(event){
		// 	clearTimeout(popoverCloseTimer);
		// 	var _this = this;
		// 	// set delay for showing the popover, save it so we can clear it on mouseleave
		// 	popoverOpenTimer = setTimeout(function(){
		// 		$(_this).popover('show');
		// 		let popoverDivId = $(_this).attr('aria-describedby');
		// 		$(_this).attr('lastPopoverId', popoverDivId);
		// 		ReactDOM.render( 
		// 			React.createElement(overlayPinElement, {
		// 				recordId:recordId, 
		// 				renderId: renderId,
		// 				attachmentId: attachmentId,
		// 				tableSchemaName: tableSchemaName, 
		// 				parentRecordId: parentRecordId,
		// 				parentTableSchemaName: parentTableSchemaName,
		// 				subSettingIndex: subSettingIndex,
		// 				subSettingSchemaName: subSettingSchemaName,
		// 			}), $('#'+popoverDivId + ' .popover-body')[0]);
		// 		$('.popover')
		// 			.on('mouseleave',function(){
		// 				popoverCloseTimer = setTimeout(function(){
		// 					$(_this).popover('hide');
		// 				}, 500);
		// 			})
		// 			.on('mouseenter',function(){
		// 				clearTimeout(popoverCloseTimer);
		// 			});
		// 	}, 500);
		// }).on('mouseleave', function() {
		// 	clearTimeout(popoverOpenTimer);
		// 	// Popover is not being hovered 
		// 	var _this = $(this);
		// 	if(!$('.popover:hover').length){
		// 		// set delay for hiding popover (allows us to "cut the corners")
		// 		popoverCloseTimer = setTimeout(function(){
		// 			$(_this).popover('hide');
		// 		}, 500);
		// 	}
		// }).on('hide.bs.popover', function(event){
		// 	// Clear our overridden overflow (from show.bs.popover)
		// 	event.target.parentNode.parentNode.parentNode.style.overflow = '';

		// 	let popoverDivId = $(this).attr('lastPopoverId');
		// 	if(popoverDivId) {
		// 		if($('#'+popoverDivId + ' .popover-body').length){
		// 			ReactDOM.unmountComponentAtNode($('#'+popoverDivId + ' .popover-body')[0]);
		// 		}
		// 	}
		// }).on('show.bs.popover', function(event, blah){
		// 	// Make the overflow visible on the grid-item that is our parent, so the plugin knows it has room to work!
		// 	event.target.parentNode.parentNode.parentNode.style.overflow = 'visible';
		// });
	}

	/**
	 * Generate the components for the Settings Panel Switcher and the 
	 * Multiple Component Switcher.
	 * 
	 * @returns {Object} Multiple Drop downs
	 * 
	 */
	static getSettingSwitcherDropdown(componentType, componentId) {
		let isDisabled = false;

		// Determine if the component is New, and so the dropdown needs to be
		// Disabled.
		let componentObj = {};
		if(componentType === 'page') {
			componentObj = PageStore.get(componentId);
		}
		if(componentType === 'field') {
			componentObj = FieldStore.get(componentId);
		}
		if(componentObj && componentObj.new) {
			isDisabled = true;
		}

		// Drop Down Options
		const options = [
			{ value: "appearance", label: "Appearance", icon: "icon-appearance.svg", bg: !isDisabled ? '#556ae9' : 'var(--theme-grey-3)' },
			{ value: "query", label: "Records", icon: "icon-records-tables.svg", bg: '#87b74a' },
			{ value: "automation", label: "Logic", icon: "icon-logic.svg", bg: '#e4ca26' },
			{ value: "discussion", label: "Discussion", icon: "icon-discussion.svg", bg: '#804ee0' },
			{ value: "visibility", label: "Visibility", icon: "icon-visibility.svg", bg: '#b760d4' },
			{ value: "security", label: "Security", icon: "icon-security.svg", bg: '#e63f3f' },
			// { value: "compliance", label: "Compliance", icon: "icon-compliance.svg", bg: '#ec992b' }
		];

		// From react Select's Components
		const { Option } = components;

		/* Setup the Option with the name of the Setting page being displayed */
		const IconOption = props => {
			return (
				<Option className={`panelChooserSelectOption ${AdminSettingsStore.getSelectedOverlay() === props.data.value ? 'selected' : ''}`} {...props}>
					<img
						style={{ padding: '10px', marginRight: '10px', backgroundColor: props.data.bg, width: 40, height: 40 }}
						src={ContextStore.getUrlMedia() + "/" + props.data.icon}
						alt={props.data.label}
					/>
					{props.data.label}
				</Option>
			);
		}

		const ValueContainer = ({ children, getValue, ...props}) => {
			let selectedOption = options.find(option => option.value === AdminSettingsStore.getSelectedOverlay());

			/* Not all options will be in the options array, namely:
				field-add
				relationship (add/edit)
				
			*/
			if(!selectedOption) {
				selectedOption = options[0];
			}

			let colorValue = (isDisabled ? 'var(--theme-grey-3)' : 'var(--theme-text-1)');

			return (
				<div className='panelChooserSelected' style={{ display: 'flex', alignItems: 'center' }}>
					<div className='panelChooserImgContainer' style={{ backgroundColor: selectedOption && selectedOption.bg }}>
						<img
							style={{ padding: '10px', width: 40, height: 40, marginTop: '1px' }}
							src={selectedOption && ContextStore.getUrlMedia() + "/" + selectedOption.icon}
							alt={selectedOption && selectedOption.value}
						/>
					</div>
					<div style={{ color: colorValue, fontSize: '1rem' }}>
						{selectedOption && selectedOption.label}
					</div>
					{/* Since we are overriding the ValueContainer (which includes the input), we have to put */}
					{/* the input back in - the original ValueContainer included a div and an input. children[1] is the input */}
					{ children[1] }
				</div>
			)
		}

		let dropdownStyles = {
			container: (base, state) => ({
				...base,
				paddingRight: '.7rem',
				paddingLeft: '.7rem',
				paddingTop: '1rem',
				position: 'static'
			}),
			control: (base, state) => ({
				...base,
				backgroundColor: 'transparent',
				color: '#fff',
				borderRadius: '5px',
				borderWidth: '2px',
				borderColor: 'var(--theme-grey-3)',
				boxShadow: 'var(--theme-grey-3)',
				"&:hover": {
					borderColor: 'var(--theme-grey-3)',
					cursor: 'pointer'
				}
			}),
			indicatorSeparator: (base, state) => ({
				...base,
				backgroundColor: 'transparent'
			}),
			menu: (base, state) => ({
				...base,
				padding: '0 1px 1px',
				width: 'calc(100% - 1.4rem)',
				backgroundColor: 'var(--theme-grey-3)',
				borderRadius: '5px',
				border: '2px solid var(--theme-grey-3)',
				boxShadow: 'none',
				top: 'auto'
			}),
			menuList: (base, state) => ({
				...base,
				padding: 0,
				maxHeight: 'none',
				color: '#fff',
				borderRadius: '5px'
			}),
			option: (base, state) => ({
				...base,
				padding: 0,
				backgroundColor: state.isSelected ? 'var(--theme-level-1-hilite)' : 'var(--theme-level-4)',
				"&:hover": {
					backgroundColor: state.isSelected ? 'var(--theme-level-1-hilite)' : 'var(--theme-level-3-hilite)',
					cursor: 'pointer'
				}
			}),
		};

		return (<Select
			id='selectPanelChooser'
			components={{ Option: IconOption, ValueContainer }}
			defaultValue={(isDisabled ? options[options.findIndex(option => option.value === AdminSettingsStore.getSelectedOverlay())] : undefined)}
			value={(!isDisabled ? AdminSettingsStore.getSelectedOverlay() : undefined)}
			isDisabled={isDisabled}
			isSearchable={false}
			onChange={onChangeSettingsPanel}
			options={options}
			styles={dropdownStyles}
		/>);
	}

	/**
	 * Determine what should be in the little i popover.
	 * @returns {string} HTML for inside the i popover.
	 */
	static getPopoverContent(attachmentId, componentId, componentType, renderId) {
		let componentTypeLabel = false;
		let technicalName = false;
		let tableName = false;
		let contentArray = [];

		switch(componentType) {
			case 'field': 
				componentTypeLabel = 'Field';
				let fieldObj = FieldStore.get(componentId);
				if(fieldObj) {
					technicalName = fieldObj.fieldSchemaName;
					let tableObj = TableStore.getByTableSchemaName(fieldObj.tableSchemaName);
					if(tableObj) {
						tableName = tableObj.pluralName;
					}
				}
				break;
			case 'page': 
				componentTypeLabel = 'Page';
				let pageObj = PageStore.get(componentId);
				if(pageObj) {
					let tableObj = TableStore.getByTableSchemaName(pageObj.tableSchemaName);
					if(tableObj) {
						tableName = tableObj.pluralName;
					}
				}
			break;
			case 'table': // Tables have no popover content
				// let tableObj = TableStore.get(componentId);
				// if(tableObj) {
				// 	technicalName = tableObj.tableSchemaName;
				// }
				componentTypeLabel = 'Table';
			break;
			case 'relationship':
				let relationshipObj = RelationshipStore.get(componentId);
				if(relationshipObj) {
					technicalName = relationshipObj.relationSchemaName;
				}
				componentTypeLabel = 'Relationship';
			break;
			case 'scheduledLogic': 
				componentTypeLabel = 'Schedule';
			break;
			case 'apiconfig': 
				componentTypeLabel = 'Incoming REST API Call';
			break;
			case 'logicfunctions': 
				componentTypeLabel = 'Logic Function';
			break;
			default:
				console.log('Component Type: ', componentType, 'Not found.');
			break;
		}

		if(componentTypeLabel) {
			contentArray.push('<div class="info-container d-flex w-100 justify-content-between">' + 
				'<span class="info-label">' + componentTypeLabel + ' ID: </span>'+
				'<div>' + 
					'<span class="info-value">'+componentId+'</span>' + 
					'<i class="fa fa-clipboard copy-recordid info-copy" aria-hidden="true"></i>' + 
				'</div>' + 
			'</div>');
		}

		if(renderId) {
			contentArray.push('<div class="info-container d-flex w-100 justify-content-between">' + 
				'<span class="info-label">Render ID: </span>'+
				'<div>' + 
					'<span class="info-value">'+renderId+'</span>' + 
					'<i class="fa fa-clipboard copy-renderid info-copy" aria-hidden="true"></i>' + 
				'</div>' + 
			'</div>');
		}
		if(technicalName) {
			contentArray.push('<div class="info-container d-flex w-100 justify-content-between">' + 
				'<span class="info-label">Technical Name: </span>'+
				'<div>' + 
					'<span class="info-value no-copy">'+technicalName+'</span>' + 
				'</div>' + 
			'</div>');
		}
		
		if(tableName) {
			contentArray.push('<div class="info-container d-flex w-100 justify-content-between">' + 
				'<span class="info-label">Table Name: </span>'+
				'<div>' + 
					'<span id="info-renderid" class="info-value no-copy">'+tableName+'</span>' + 
				'</div>' + 
			'</div>');
		}
		
		if(attachmentId) {
			contentArray.push('<div class="info-container d-flex w-100 justify-content-between">' +
					'<span class="info-label">Attachment ID: </span>' +
					'<div>' +
						'<span class="info-value">' + attachmentId + '</span>' +
						'<i class="fa fa-clipboard copy-attachmentid info-copy" aria-hidden="true"></i>' +
					'</div>' +
				'</div>');
			}

		return contentArray.join('');
	}

}

/**
 * Change to the new Settings Panel
 * @param {Object} event Select change event
 */
function onChangeSettingsPanel(event) {
   // events from react-select are event.value
   let newSettingsPanel = event.value;
   // reset the selected setting - it caused issues when changing settings panels
   AdminSettingsActions.onSettingChange(null,null);
   if(newSettingsPanel === 'security') { 
	   let tableSchemaName = 'applications';
	   let fieldId = AdminSettingsStore.getRecordId();
	   if(fieldId) { 
		   let fieldObj = FieldStore.get(fieldId);
		   if(fieldObj) {
			   tableSchemaName = fieldObj.tableSchemaName;
		   }
	   }
	   AdminSettingsActions.onSubSettingChange(tableSchemaName, null);
   }
   AdminSettingsActions.onChangeDisplayedSettings(newSettingsPanel);
}