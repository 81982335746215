import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';

/**
 * Displays dashboard header
 */
class EngineeringDashboardHeader extends Component {
	/**
	 * Return the stores we're listening to for changes.
	 *
	 * @return {array}  description
	 */
	static getStores() {
		return [ AdminSettingsStore ];
	}

	/**
	 * Calculate our state anytime a store changes.
	 *
	 * @param  {object} prevState
	 * @return {object}
	 */
	static calculateState(prevState) {
		return {
			'isActive' : (AdminSettingsStore.getActiveDashboard() === 'engineering' ? true : false),
		};
	}

	/**
	 * render the component
	 *
	 * @return {JSX}
	 */
	render() {
		let { isActive } = this.state;

		let { 
			onDashboardSelect,
		} = this.props;

		return (
			<div onClick={() => { onDashboardSelect('engineering') }} className="cd-dashboard-header engineering">
				<div className="d-flex justify-content-between">
					<div className="d-flex align-items-center">
						<div className="d-flex cd-dashboard-title">
							<h4 className="cd-dashboard-sign">
								{isActive 
									? <i title="Collapse" className="fa fa-minus" aria-hidden="true"></i> 
									: <i title="Expand" className="fa fa-plus" aria-hidden="true"></i>
								}
							</h4>
							<h4 className="ml-1">Engineering</h4>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const container = Container.create(EngineeringDashboardHeader);
export default container;