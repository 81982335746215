import socketFetcher from '../utils/socket-fetcher';
import AssistantResultsActions from '../actions/assistant-results-actions';
import uuid from 'uuid';

export default {
	/**
	* Loads a single record into the record store. Record id is extracted from passed context object
	*
	* @param {Object} contextObj
	* @param {Array} fieldSchemaNames list of field schema names pulled from page data
	*/
	getResults: function(input) {
		var newUUID = uuid.v4();
		// Set the mostRecentConnection here
		AssistantResultsActions.onMostRecentConnectionChange(newUUID);
		if(!input) {
			return Promise.resolve({});
		}
		let bodyData = JSON.stringify({
			sentence: input ? input[0].toLowerCase() + input.slice(1) : '',
			connectionId: newUUID
		});
		// console.log('bodyData', bodyData)
		return new Promise((resolve, reject) => {
			socketFetcher('gw/googleNLPAPI-v1', bodyData).then(function (data) {
				// console.log('got it?', data);
				// Add to the response my fields that are dataType === none, and a null value, so that goes into the store.
				if(data.responseCode === 200 && data.response) {
					return resolve(data);
				} else {
					return reject({code: data.responseCode, message: data.response || 'No response'});
				}
			});
		});
	}
};