import React, { Component } from 'react';
import AppearanceSearch from './search/appearance-assistant-search.react';

/**
 * Renderer for the appearance assistant
 * 
 * @class AppearanceAssistant
 * @extends {Component}
 */
class AppearanceAssistant extends Component {
	/**
	 * Creates an instance of AppearanceAssistant.
	 * @param {any} props 
	 * 
	 * @memberOf AppearanceAssistant
	 */

	/**
	 * render - NLPBuilder
	 *
	 * @return {JSX}
	 */
	render() {
		return (
			<div id="appearance-assistant">
				<div className="row">
					<div className="col-12 px-1">
						<AppearanceSearch
							componentId={this.props.componentId}
							componentType={this.props.componentType}
							parentComponentId={this.props.parentComponentId}
							parentComponentType={this.props.parentComponentType}
							contentsClass={this.props.contentsClass}
						/>
					</div>
				</div>
			</div>
		);
	};
}
export default AppearanceAssistant;
