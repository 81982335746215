import InterfaceActions from '../actions/interface-actions';
import FieldStore from '../stores/field-store';
import AuthenticationStore from '../stores/authentication-store';
import FieldSettingsStore from '../stores/field-settings-store';
import PatternStore from '../stores/pattern-store';
import TableStore from '../stores/table-store';
import RenderStore from '../stores/render-store';
import ContextStore from '../stores/context-store';
import NLPBuilderDictionariesStore from '../stores/nlp-builder-dictionaries-store';
import PageActions from '../actions/page-actions';
import FieldActions from '../actions/field-actions';
import PageStore from '../stores/page-store';
import AdminSettingsStore from '../stores/admin-settings-store';
import AssistantSearchUtils from '../utils/assistant-search-utils';
import PageUtils from '../utils/page-utils';
import UIUtils from '../utils/ui-utils';

import uuid from 'uuid';

// Used in eval
// eslint-disable-next-line
var AssistantFields = require('../utils/assistant-fields').default;
// eslint-disable-next-line
let co = require('co');
// eslint-disable-next-line
let QueryUtils = require('../utils/query-utils').default;
// eslint-disable-next-line
let AssistantAutomation = require('../utils/assistant-automation').default;

export default {
	/**
	 * Process a Page pattern to "Attach" one field
	 * 
	 * @param {object} searchResult Object packed full of params!
	 * @param {string} searchResult.currentParentInfo.componentId Page to attach fields TO
	 * @param {string} searchResult.currentComponentInfo.componentId Field to attach
	 */
	processAttach(searchResult){
		
		let parentRecordId = searchResult.currentParentInfo && searchResult.currentParentInfo.componentId ? searchResult.currentParentInfo.componentId : '';
		let componentId = searchResult.currentComponentInfo && searchResult.currentComponentInfo.componentId ? searchResult.currentComponentInfo.componentId : '';
		let roles = searchResult.roles;

		let fieldSettings = FieldSettingsStore.getSettings(componentId) || {};
		let fieldLabel = fieldSettings.fieldLabel;
		//Notify it is being detached 
		InterfaceActions.notification({ 'level': 'info', 'message': 'Attaching ' + fieldLabel + ' to Page...' });
		return PageUtils.attachChildPromise(parentRecordId, componentId).then(() => {
			let attachedFieldSettings = FieldSettingsStore.getSettings(componentId);
			let newRoles = attachedFieldSettings && attachedFieldSettings.roles ? attachedFieldSettings.roles.split(',') : [];
			// Insert while removing duplicates
			newRoles = newRoles.concat(roles || []);
			let newRolesObj = {};
			newRoles.forEach(role => {
				newRolesObj[role] = true;
			});
			newRoles = Object.keys(newRolesObj).join(',');
			FieldActions.pushSettingToStore(componentId, 'roles', newRoles);
			FieldActions.appendSettingHistory(componentId, 'roles', newRoles, 
				AuthenticationStore.getUserId(), AuthenticationStore.getUsername(), 'attach', 'low');
			return FieldActions.pushToDatabasePromise(FieldStore.get(componentId, true));
		});
		//AssistantSearchUtils.refreshSearch();
	},

	/**
	 * Process a Page pattern to "Attach All", pulling all children from another page
	 * 
	 * @param {object} searchResult Object packed full of params!
	 * @param {string} searchResult.sourcePageName Name of the page to read fields from
	 * @param {string} searchResult.sourcePageId Page to read Fields FROM
	 * @param {string} searchResult.targetPageId Page to Attach Fields TO
	 * @todo Add and test calls to this function
	 */
	processAttachAllFromAnotherPage({sourcePageName, sourcePageId, targetPageId}){
		// Get the children from the source page
		let pageChildren = PageUtils.getChildren(sourcePageId, true);

		pageChildren.forEach(pageChild => {
			// Attach Each to the target page
			PageUtils.attachChild(targetPageId, pageChild.fieldId, false);
		});

		PageActions.pushToDatabase(PageStore.get(targetPageId));
		
		//Notify it is being attached
		InterfaceActions.notification({ 'level': 'info', 'message': 'Attaching Fields from ' + sourcePageName + ' Page...' });

		// Refresh the search
		//AssistantSearchUtils.refreshSearch();
	},

	/**
	 * Process a Page pattern as "Detach"
	 * 
	 * @param {object} searchResult Object packed full of params!
	 * @param {string} searchResult.currentParentInfo.componentId Page to attach fields FROM
	 * @param {string} searchResult.currentComponentInfo.componentId Field to detach
	 * @param {boolean} searchResult.closeSettingsPanel - Should close panel after detaching?
	 * 
	 * @todo Add and test calls to this function
	 */
	processDetach(searchResult){
		let parentRecordId = searchResult.currentParentInfo && searchResult.currentParentInfo.componentId ? searchResult.currentParentInfo.componentId : '';
		let componentId = searchResult.currentComponentInfo && searchResult.currentComponentInfo.componentId ? searchResult.currentComponentInfo.componentId : '';
		let closeSettingsPanel = searchResult.closeSettingsPanel;
		if(componentId !== 'all'){
			let fieldSettings = FieldSettingsStore.getSettings(componentId) || {};
			let fieldLabel = fieldSettings.fieldLabel;
			PageUtils.detachChild(parentRecordId, componentId, true);
			//Notify it is being detached 
			InterfaceActions.notification({ 'level': 'info', 'message': 'Detaching ' + fieldLabel + '...' });
		} else { 
			PageUtils.detachAllChildren(parentRecordId, true);
			//Notify it is being detached
			InterfaceActions.notification({ 'level': 'info', 'message': 'Detaching all fields...' });
		}

		//close the Panel
		if(closeSettingsPanel) {
			UIUtils.closeSettingsPanel();
		} else {
			//AssistantSearchUtils.refreshSearch();
		}
	},

	/**
	 * Process a Page as "Delete"
	 * @param {object} searchResult - Object with all the params 
	 * @param {string} searchResult.recordId - page Id to delete  
	 * @param {string} searchResult.pageName - name of page to delete  
	 * @param {string} searchResult.tableName - name of table to delete Page from  
	 */
	processDelete(searchResult) {
		let componentId = searchResult.currentComponentInfo.componentId;
		let componentName = searchResult.currentComponentInfo.componentName;

		//Confirm Message is Delete
		InterfaceActions.notification({ 'level': 'success', 'message': 'Deleting Page' + componentName + '...' });
		if (componentId === RenderStore.get(ContextStore.getPageRenderId().componentId)) {
			location.href = '/';
		}
		// If we're deleting the page that the settings panel is about, close it
		if(AdminSettingsStore.getRecordId() === componentId) {
			UIUtils.closeSettingsPanel();
		}
		
		//Deletes the Page  
		return PageActions.deleteFromDatabasePromise(componentId);
		
	},	
	/**
	 * Get Attach Search Results for Pages
	 * 
	 * @param {string} recordId 
	 * @param {string} pageName 
	 * @param {string} tableSchemaName 
	 * @param {object} inputParsed 
	 * @returns - Search Results
	 */
	attachResults(recordId, pageName, tableSchemaName, inputParsed){

		let searchResults = [];
		// Get this field's Children (fields attached to this List, Container, etc)
		let attachChildrenArray = PageUtils.getPossibleChildren(recordId);

		// Turn them into Search Results
		searchResults = AssistantSearchUtils.getAttachChildrenResults(tableSchemaName, pageName, recordId, attachChildrenArray, inputParsed);
		
		// List other pages on this same table, and allow copying all children from those other pages.
		let pageObj = PageStore.get(recordId),
			pageTable = pageObj.tableSchemaName;
		if(pageTable) {
			let pagesForTable = PageStore.getArrayByTableSchemaName(pageTable);
			pagesForTable.forEach(page => {
				if(page.recordId === recordId) { 
					return;
				}

				//Get the Other List Name: 
				let otherPageLabel = (page && page.name) ? page.name : '[ No Page Name ]';

				//Initialize the Scoring:
				let score = 0,
					scoreDescription = [];
			
				//Add score for Operation 
				if(inputParsed['operation']['Attach']){
					// Add +2 to score when matching operation; 
					scoreDescription.push(`+2 - Operation Match: Attach`);
					score += 2;
				}
				
				//Add scoring for Matches representing All: 
				if(inputParsed && Array.isArray(inputParsed.inputArray)){
					let inputArray = inputParsed.inputArray;
					inputArray.forEach(word =>{
						let keywords = otherPageLabel.split(' ');
							//include general keywords: 
							keywords = keywords.concat(['all', 'from', 'other']);
						keywords.forEach(nameWord => {
							if(word.toLowerCase() === nameWord.toLowerCase()){
								//Add +1 to score for keywords of this other List 
								scoreDescription.push(`+1 - Other Page Match '${nameWord}'`);
								score += 1;
							}
						})
					})
				} //End of Finding matches for this child Name in the input 

				// Show this as a result.
				searchResults.push({
					resultId: 'attach-' + page.recordId,
					label: `Attach Fields from the [ ${otherPageLabel} ] Page to this Page.`,
					score: score,
					scoreDescription: scoreDescription,
					sourcePageName: page.name,
					sourcePageId: page.recordId,
					targetPageId: recordId,
					closeSettingsPanel: false,
					type: 'attachAllFromAnotherPage'
				}); //End of Pushing Results to Search results Array
			});
		}

		return searchResults;
	},
	/**
	 * Get Create Search Results for Pages
	 * 
	 * @param {string} recordId 
	 * @param {string} tableSchemaName 
	 * @param {string} parentTableSchemaName 
	 * @param {string} settingSchemaName 
	 * @param {string} NLPValue 
	 * @param {object} inputParsed 
	 * @returns - Search Results
	 */
	createResults(recordId, tableSchemaName, parentTableSchemaName, settingSchemaName, NLPValue, inputParsed){
		let searchResults = [];
		//Get the Possible Create Patterns
		let createPatterns = NLPBuilderDictionariesStore.getPatternsByKeyAndBlockType('operation', 'create', 'field');
			
		// Turn them into Search Results
		searchResults = AssistantSearchUtils.getCreateFieldResults(createPatterns, recordId, settingSchemaName, NLPValue, inputParsed, tableSchemaName, parentTableSchemaName);

		return searchResults;
	},
	/**
	 * Get detach Search Results for Pages
	 * 
	 * @param {strng} recordId 
	 * @param {strng} tableSchemaName 
	 * @param {strng} pageName 
	 * @param {object} inputParsed 
	 * @returns - Search Results
	 */
	detachResults(recordId, tableSchemaName, pageName, inputParsed){
		let searchResults = [];
		// Find the Children of the Page
		let detachChildrenArray = PageUtils.getChildren(recordId, true);
		// Turn them into search results
		searchResults = AssistantSearchUtils.getDetachChildrenResults(tableSchemaName, pageName, recordId, detachChildrenArray, inputParsed);

		return searchResults;
	},
	/**
	 * Get delete Search Results for Pages
	 * 
	 * @param {string} recordId 
	 * @param {string} pageTableSchemaName 
	 * @param {object} inputParsed 
	 * @returns - Search Results
	 */
	deleteResults(recordId, pageTableSchemaName, inputParsed){
		let searchResults = [];
		//Get the page Matches
		let pageMatches = PageStore.getArrayByTableSchemaName(pageTableSchemaName)
		//Turn them into search Results 
		searchResults = AssistantSearchUtils.getDeletePageResults(pageMatches, inputParsed, recordId);
		return searchResults;
	},
	/**
	 * Process a Page pattern as "Create"
	 * 
	 * @param {object} searchResult 
	 */
	processCreate(searchResult) {
		let pageCount = PageStore.getAllArray().length;
		let maxPageCount = AuthenticationStore.getMaxPages();
		if(maxPageCount && pageCount >= maxPageCount) {
			InterfaceActions.notification({'message': 'Page creation failed: you are over your maximum page count.', 'level': 'error'});
			return Promise.reject('Max page count exceeded.');
		}
		let tableSchemaName = searchResult.currentTableInfo.tableSchemaName;
		let recordId = uuid.v4();

		let newPageObject = {
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			name: 'New Page'
		};

		let creationNotification = InterfaceActions.stickyNotification({'message': 'Creating page on ' + tableSchemaName + '. This may take a moment; please wait...', 'level': 'info'});

		PageActions.pushToStore(recordId, newPageObject);
		// searchResult['pageId'] = recordId;
		//Update the actual database with these settings and return it
		searchResult.currentComponentInfo = {
			componentType: 'page',
			componentSubtype: 'page',
			componentId: recordId
		};
		return PageActions.pushToDatabasePromise(PageStore.get(recordId, true)).then(() => {
			InterfaceActions.clearStickyNotification(creationNotification);
			InterfaceActions.notification({'message': 'Page creation complete!', 'level': 'success'});	
			// UIUtils.openSettingsPanel('appearance', 
			// 	recordId, 'page');

			// InterfaceActions.notification({'message': 'Switching overlay to new page...', 'level': 'info'});

			return searchResult;
		});
	},

	/**
	 * Update a page with pattern-like logic, using the settings passed into settingsArray
	 * 
	 * @TODO: Finish this altogether.
	 * 
	 * @param {string} pageRecordId Field ID to set the settings on
	 * @param {any} patternId Pattern ID to use for the settings' history
	 * @param {any} settingsArray Array of settings, each one with fieldSchemaName, value, chanceOfChange
	 */
	processUpdateWithSettings(pageRecordId, settingsObj) {

		// We have to run against a page record.
		if(!pageRecordId) {
			console.warn(new Error('Aborting: No page ID specified'));
			return Promise.resolve(false);
		} else {
			console.log('pageRecordId', pageRecordId);
		}

		let newSettingsObj = {
			recordId: pageRecordId
		};

		Object.keys(settingsObj).forEach(settingSchemaName => {
			let setting = settingsObj[settingSchemaName];
			let newValue = setting ? setting.value : undefined;
			// Split up automation
			if(settingSchemaName === 'automation' && newValue) {
				Object.keys(newValue).forEach(trigger => {
					let triggerValue = newValue[trigger];
					if(trigger) {
						newSettingsObj['automation-' + trigger] = triggerValue;
					}
				})
			} else {
				// let valueQuality = setting.chanceOfChange;
				newSettingsObj[settingSchemaName] = newValue;
				// Update the history (commented out until support implemented)
				// PageActions.appendSettingHistory(pageRecordId, settingSchemaName, newValue, 
				// 	AuthenticationStore.getUserId(), AuthenticationStore.getUsername(), patternId, valueQuality);
			}

		});

		PageActions.pushToStore(pageRecordId, newSettingsObj);

		// Commit to the database.
		InterfaceActions.notification({'message': 'Updating field...', 'level': 'info'});
		return PageActions.pushToDatabasePromise(PageStore.get(pageRecordId, true));
	},

	/**
	 * Process a Page pattern as "Update"
	 * 
	 * @param {object} searchResult - Object packed full of params!
	 * @param {string} searchResult.NLPInput - Input in quotes
	 * @param {string} searchResult.fieldId - field to update
	 * @param {string} searchResult.patternId - patternId to process
	 * @returns boolean
	 */
	processUpdate(searchResult) {

		let pageRecordId = searchResult.currentComponentInfo.componentId;
		let method = searchResult.methodInfo.method;
		let NLPInput = searchResult.methodInfo.NLPInput;

		if(method === 'patternless') {
			let settingSchemaName = searchResult.methodInfo.settingSchemaName;
			let newSettingsObj = {
				recordId: pageRecordId,
				[settingSchemaName]: NLPInput
			};
			// let settingObj = FieldStore.get("27fdf34c-252d-4196-bbd8-33486e18db64");
			// Update the store
			PageActions.pushToStore(pageRecordId, newSettingsObj);
	
		} else {
			console.warn('Update patterns not supported for page components.');
		}


		// Commit to the database.
		let updateNotification = InterfaceActions.stickyNotification({'message': 'Updating page...', 'level': 'info'});
		let updatePromise = PageActions.pushToDatabasePromise(PageStore.get(pageRecordId, true)).then(() => {
			InterfaceActions.clearStickyNotification(updateNotification);
			InterfaceActions.notification({'message': 'Update successful!', 'level': 'success'});	
			return searchResult;
		});
		
		return updatePromise;
	},

	/**
	 * Processes a page pattern from context information in searchRequest.
	 * 
	 * @param {object} searchResult 
	 */
	processPattern(searchResult) {
		let tableSchemaName = searchResult.currentTableInfo.tableSchemaName;
		let pageId = searchResult.currentComponentInfo.componentId;
		let patternId = searchResult.methodInfo.patternId;
		let tableObj = TableStore.getByTableSchemaName(tableSchemaName) || {};
		
		// Used in eval
		// eslint-disable-next-line
		let assistantVariables = {
			parentType: 'page',
			parentRecordId: pageId,
			parentNextYPosition: searchResult.parentNextYPosition,  // Integer - the Parent's Next Y Position.
			table: tableSchemaName,  					// String - table Schema Name of the table to assign for everything new that's generated.
			tableSingularName: tableObj.singularName,
			tablePluralName: tableObj.pluralName,
			patternId: patternId,  						// String - The pattern ID of this pattern, use this for calls to processCreate when making new Fields, so their histories can be set appropriately.,
			tableIcon: tableObj.icon
		};

		// Lookup Form Blockly Code
		let patternObj = PatternStore.get(patternId);
		let patternJS = JSON.parse(patternObj.processing);
		// Used in eval
		// eslint-disable-next-line
		let AssistantPages = this;
		let code = [];
		let toReturn = null;
		try {
			code.push('toReturn = co(function* () {');
			code.push(patternJS.js);
			code.push('}).then(function(results) {');
			code.push('}).catch(function(error) {');
			code.push('console.error(error.message ? error.message : error);');
			code.push('});');
			code = code.join('\n');  // Blank line between each section.

			// If our browser does not support generators (IE11.. grumble.. ) then babel the code.
			if(!window.supportsGenerators) {
				/*global Babel*/
				code = Babel.transform(code,{ presets: ['es2015','react'] }).code;
			}

			// eslint-disable-next-line
			eval(code);
		} catch (e) {
			console.warn('Evaluation of pattern code failed. Code was', code.join('\n'));
		}
		return toReturn;
		// eslint-disable-next-line
		// eval(patternJS.js);
	},
};
