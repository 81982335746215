//Actions
import AdminSettingsActions from '../actions/admin-settings-actions';
import InterfaceActions from '../actions/interface-actions';
import MapActions from '../actions/map-actions';
import PageActions from '../actions/page-actions';
import RecordActions from '../actions/record-actions';
import RenderActions from '../actions/render-actions';

// APIs
import socketFetcher from './socket-fetcher';

//Stores
import FieldStore from '../stores/field-store';
import FieldSettingsStore from '../stores/field-settings-store';
import FieldTypeStore from '../stores/field-type-store';
import RecordStore from '../stores/record-store';
import RenderStore from '../stores/render-store';
import ContextStore from '../stores/context-store';
import PageStore from '../stores/page-store';
import PageModeStore from '../stores/page-mode-store';

// Utils
import BlockUtils from './block-utils';
import FieldUtils from './field-utils';
import ObjectUtils from './object-utils';
import UIUtils from './ui-utils';
import uuid from 'uuid';

export class PageUtils {

	/**
	 * Handle adding a page.
	 */
	static addNewPage(tableSchemaName) {
		let recordId = uuid.v4();
		let newPage = {
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			allowPublicAccess: 'no',
			availableModes: 'edit,view',
			name: 'New Page',
			new: true
		};
		PageActions.pushToStore(recordId, newPage);

		// @todo - Why?
		MapActions.selectPage(recordId, true);

		// Tableschemaname is REQUIRED for new Pages.
		// Need to add the Table Setting?

		// targetOverlay, recordId, tableSchemaName, 
		// parentRecordId, parentTableSchemaName, mainTab,
		// subSettingSchemaName, subSettingIndex, renderId, attachmentId
		UIUtils.openSettingsPanel('page-add', recordId, 'page');

		// Select the Page Name field.
		// settingSchemaName, settingRecordId
		AdminSettingsActions.onSettingChange('name', 'e547119d-0f76-4ab1-844e-9f44f740d3e8');
	}

	/**
	 * Attach a field to the Page
	 * 
	 * @param {string} pageId - Page Record Id
	 * @param {object} childId - Child Field Id
	 * @param {boolean} pushToDatabase - Optional - save in Database? 
	 * @param {boolean} coordinates - Optional - Object of coordinates - If all 4 are not provided, anything provided is ignored.
	 * @param {boolean} coordinates.x - Optional - X Position on Parent
	 * @param {boolean} coordinates.y - Optional - Y Position on Parent
	 * @param {boolean} coordinates.height - Optional - Height of child object
	 * @param {boolean} coordinates.width - Optional - Width of child object
	 * @param {string} attachmentId - Optional - the attachment ID to use
	 */
	static attachChild(pageId, childId, pushToDatabase, coordinates, attachmentId) {
		attachmentId = attachmentId || uuid.v4();
		//Get the PageObject 
		let pageObj = PageStore.get(pageId);

		// If we didn't find the page.. return.
		if (!pageObj) {
			return false;
		}


		// Grab our existing layouts
		let fieldPositionObj = ObjectUtils.getObjFromJSON(pageObj.fieldPosition);

		// If we don't have layouts yet, this is probably the first field attached to a page; assume it's so and make a new one.
		fieldPositionObj = fieldPositionObj && Object.keys(fieldPositionObj).length ?
			fieldPositionObj :
			{
				lg: [],
				md: [],
				sm: []
			};

		let attachedFieldsArr = ObjectUtils.getObjFromJSON(pageObj.attachedFields);

		//Make sure the value object has the default format:
		if (!Array.isArray(attachedFieldsArr)) {
			attachedFieldsArr = [];
		}

		//Attach the new Child field to the screensize Array
		attachedFieldsArr.push({
			recordIds: [childId],
			attachmentId: attachmentId,
			order: attachedFieldsArr.length + 1
		});

		//Stringify the Value 
		let appendObj = {};
		appendObj['attachedFields'] = JSON.stringify(attachedFieldsArr);

		// If no coordinates are provided, look up for the field's fieldtype 
		let coordX = 0, // Always 0
			coordY = 0, // Calculate based on last attached field 
			width = 6, // Always 1/2 the grid
			height = FieldUtils.getDefaultRows(childId); // default to 7 if not set in the variant

		// Calculate the coordinates for each screenSize 
		// Loop over all screensizes
		['lg', 'md', 'sm'].forEach(screenSize => {
			// If this screensize manages itself...
			if (Array.isArray(fieldPositionObj[screenSize])) {
				let fieldPositionArr = fieldPositionObj[screenSize];
				// How many fields are attached to this screen already
				let arrLength = fieldPositionObj[screenSize].length;

				let newFieldCoordinates = {
					w: width,
					h: height,
					x: 0,
					y: 0,
					i: attachmentId
				};

				// Manage here if we do have coordinates passed from the Params: 
				if (coordinates &&
					coordinates.x !== undefined && !isNaN(coordinates.x) &&
					coordinates.y !== undefined && !isNaN(coordinates.y) &&
					coordinates.height !== undefined && !isNaN(coordinates.height) &&
					coordinates.width !== undefined && !isNaN(coordinates.width)) {
					newFieldCoordinates = {
						w: coordinates.width,
						h: coordinates.height,
						x: coordinates.x,
						y: coordinates.y,
						i: attachmentId
					}
				} else if (arrLength && !coordinates) {

					// Find the attached field that's lowest down the screen
					let lastY = 0;
					let lastHeight = 0;
					attachedFieldsArr.forEach(attachmentObj => {
						let id = attachmentObj.attachmentId || attachmentObj.recordId;
						let matchingPositionObj = fieldPositionArr.find(positionObj => positionObj.i === id);
						if (matchingPositionObj && matchingPositionObj.y >= lastY) {
							lastY = matchingPositionObj.y;
							lastHeight = matchingPositionObj.h;
						}
					});

					// Re calculate coordY
					coordY = lastHeight + lastY;

					newFieldCoordinates = {
						w: width,
						h: height,
						x: coordX,
						y: coordY,
						i: attachmentId
					};

				} else if (!arrLength && !coordinates) {
					// We are the first child
					newFieldCoordinates = {
						w: width,
						h: height,
						x: 0,
						y: 0,
						i: attachmentId,
					}
				}
				// Validate coordinates
				newFieldCoordinates = BlockUtils.getValidCoordinates(newFieldCoordinates);

				//Append Coordinates to Screen Size: 
				fieldPositionObj[screenSize].push(newFieldCoordinates);
			}
		});

		// Include the updated layouts for the store.
		appendObj.fieldPosition = JSON.stringify(fieldPositionObj);

		//Update the Store with the Attached Field to the Page
		PageActions.pushToStore(pageId, appendObj);

		//Update the Database
		if (pushToDatabase) {
			let pageObj = PageStore.get(pageId, true);
			PageActions.pushToDatabase(pageObj);
		}
	}
	/**
	 * Attach a field to the Page asynchronously. (Necessary to avoid issues with too many attach calls spamming the DataStore)
	 * Will automatically save to the database.
	 * 
	 * @param {string} pageId - Page Record Id
	 * @param {object} childId - Child Field Id
	 * @param {boolean} coordinates - Optional - Object of coordinates - If all 4 are not provided, anything provided is ignored.
	 * @param {boolean} coordinates.x - Optional - X Position on Parent
	 * @param {boolean} coordinates.y - Optional - Y Position on Parent
	 * @param {boolean} coordinates.height - Optional - Height of child object
	 * @param {boolean} coordinates.width - Optional - Width of child object
	 * @param {string} attachmentId - Optional - the attachment ID to use
	 * 
	 * @returns {Promise}
	 */
	static attachChildPromise(pageId, childId, coordinates, attachmentId) {
		this.attachChild(pageId, childId, false, coordinates, attachmentId);
		let pageObj = PageStore.get(pageId);
		return PageActions.pushToDatabasePromise(pageObj, true).then(results => {
			return results;
		});
	}
	/**
	 * Detaches a field from the Page. 
	 * 
	 * @static
	 * @param {string} pageId - Page Record Id
	 * @param {string} childFieldId - Field Child record Id
	 * @param {boolean} pushToDatabase - save in Database? 
	 */
	static detachChild(pageId, childFieldId, pushToDatabase) {

		//Get the PageObject 
		let pageObj = PageStore.get(pageId);
		// If we didn't find the page.. return.
		if (!pageObj) {
			return false;
		}

		//Get the attachedFields key from which we will delete a child
		let attachedFieldsArr = ObjectUtils.getObjFromJSON(pageObj.attachedFields);

		// Do we have any render entries for the parent? If so, we may need to update the render store to remove the children
		let parentRenderObjs = RenderStore.getRenderObjectsForComponent('page', pageId);
		let rendersToRemove = [];
		let fieldAttachmentId;
		if (Array.isArray(attachedFieldsArr)) {
			for (let i = (attachedFieldsArr.length - 1); i >= 0; i--) {
				let { attachmentId, recordId, recordIds, order } = attachedFieldsArr[i];
				let attachmentMatches = false;
				if (attachmentId === childFieldId || recordId === childFieldId) {
					fieldAttachmentId = attachmentId;
					attachedFieldsArr.splice(i, 1);
					attachmentMatches = true;
				} else if (recordIds) {
					// If the ID is in any of the child record IDs, remove it
					// @TODO: Should we update the display field automation in here somehow, too? How do we do that?
					// Maybe table it for when we have a more holistic approach to deleting fields?
					let index = attachedFieldsArr[i].recordIds.indexOf(childFieldId);
					// Remove the attached field from recordIds if it's in here
					if (index > -1) {
						fieldAttachmentId = attachmentId;
						attachedFieldsArr[i].recordIds.splice(index, 1);
						// If it was our only field, remove this attachment entry entirely
						if (!attachedFieldsArr[i].recordIds.length) {
							attachedFieldsArr.splice(i, 1);
							attachmentMatches = true;
						}
					}
				}

				if (attachmentMatches) {
					parentRenderObjs.forEach((renderImmutable) => {
						let renderId = renderImmutable.get('renderId');
						let childRenderId = RenderStore.findChildRenderId(renderId, childFieldId, order);
						if (childRenderId) {
							rendersToRemove.push(childRenderId);
						}
					});
				}
			}
		}

		//Stringify the Value 
		let appendObj = {};
		appendObj.attachedFields = JSON.stringify(attachedFieldsArr);

		// Remove any child configuration entries for this ID
		let childConfigurationsObj = PageStore.getChildConfigurations(pageId) || {};
		if (pageObj.childConfigurations && childConfigurationsObj[childFieldId]) {
			delete childConfigurationsObj[childFieldId];
			// @TODO: This is backwards compatible and can stay for now, but at some point we may need to update this for child configuration splitting
			appendObj.childConfigurations = JSON.stringify(childConfigurationsObj);
		}

		// Also remove any visibility for the attached object
		if (pageObj[fieldAttachmentId + '-visibility']) {
			appendObj[fieldAttachmentId + '-visibility'] = null;
		}

		RenderActions.deleteRenderBulk(rendersToRemove);

		//Update the Store with the Removed Field from the Page
		PageActions.pushToStore(pageId, appendObj);

		//Update the Database
		if (pushToDatabase) {
			let pageObj = PageStore.get(pageId);
			PageActions.pushToDatabase(pageObj);
		}
	}

	/**
	 * Detaches all the child fields
	 * 
	 * @static
	 * @param {string} parentPageId 
	 * @param {boolean} pushToDatabase 
	 * @memberof PageUtils
	 */
	static detachAllChildren(parentPageId, pushToDatabase) {
		//Get the PageObject 
		let pageObj = PageStore.get(parentPageId);
		// If we didn't find the page.. return.
		if (!pageObj) {
			return false;
		}

		//Get the components key from which we will delete a child
		let attachedFieldsArr = ObjectUtils.getObjFromJSON(pageObj.attachedFields);

		if (Array.isArray(attachedFieldsArr)) {
			attachedFieldsArr.forEach(attachedField => {
				this.detachChild(parentPageId, attachedField.attachmentId || attachedField.recordId, pushToDatabase);
			});
		}
	}

	/**
	 *  Returns all the child fields
	 * 
	 * @static
	 * @param {string} pageId - page Id
	 * @param {string} getArrayResults - Optional - If no array parameter, it returns an Object 
	 * 
	 * @returns {mixed} - Object (default) or array of child fields on this page
	 * @memberof PageUtils
	 */
	static getChildren(pageId, getArrayResults) {

		//By default Return Object 
		let childrenResults = {}


		if (!pageId) {
			console.error('Can not get Children for Page of: ', pageId);
			return childrenResults;
		}

		//Get the PageObject 
		let pageObj = PageStore.get(pageId);
		// If we didn't find the page.. return.
		if (!pageObj) {
			return false;
		}

		//Get the components key from which we will delete a child
		let attachedFieldsArr = ObjectUtils.getObjFromJSON(pageObj.attachedFields);

		//loop over the children of this page
		if (Array.isArray(attachedFieldsArr)) {
			attachedFieldsArr.forEach(attachedField => {
				let recordIds = attachedField.recordIds ? attachedField.recordIds : [attachedField.recordId];

				recordIds.forEach(recordId => {
					if (!childrenResults[recordId]) {
						//Grab the object child
						let childSettings = FieldStore.get(recordId);

						//Return The Child Only with the Keys we care 
						childrenResults[recordId] = {
							fieldId: recordId,
							fieldLabel: (childSettings && childSettings.fieldLabel ? childSettings.fieldLabel : '[ No Field Label ]'),
							fieldType: childSettings && childSettings.fieldType ? childSettings.fieldType : undefined
						}
					}
				});
			});
		}

		//Return array of results or []
		return getArrayResults ? Object.keys(childrenResults).map(key => childrenResults[key]) : childrenResults;
	}

	/**
	 * Get the highest Y position of any element on the grid.
	 * 
	 * @static
	 * @param {string} pageId Record ID of the Page to look at.
	 * @returns 
	 * @memberof PageUtils
	 */
	static getNextYPosition(pageId) {
		let nextYPosition = 0,
			pageObj = PageStore.get(pageId);
		if (!pageObj || !pageObj.fieldPosition) {
			return 0;
		}

		let lastYs = { lg: 0, md: 0, sm: 0 },
			fieldPositionObj = ObjectUtils.getObjFromJSON(pageObj.fieldPosition);

		['lg', 'md', 'sm'].forEach(screenSize => {
			if (Array.isArray(fieldPositionObj[screenSize])) {
				fieldPositionObj[screenSize].forEach(field => {
					if (field.y >= lastYs[screenSize]) {
						lastYs[screenSize] = (field.y + field.h); // Highest Y + Objects Height
					}
				});
			}
		});

		nextYPosition = lastYs['sm'];
		if (lastYs['md'] > nextYPosition) {
			nextYPosition = lastYs['md'];
		}
		if (lastYs['lg'] > nextYPosition) {
			nextYPosition = lastYs['lg'];
		}
		return nextYPosition;
	}

	/**
	 * Get Fields to attach to a Page, by TableSchemaName  
	 * 
	 * @static
	 * @param {string} pageId - Page Id
	 * @param {string} tableSchemaName - Optional Parent TableSchemaName
	 * @memberof PageUtils
	 */
	static getPossibleChildren(pageId, tableSchemaName) {

		// get the tableSchemaName of the parent page
		if (!tableSchemaName) {
			tableSchemaName = this.getDefaultChildTableSchemaName(pageId);
		}

		//Get the Children Already attached to this parent 
		let fieldsAlreadyAttached = this.getChildren(pageId);

		//Children to Return :	 
		let childrenToAttach = FieldUtils.getFieldsWithFilter(fieldsAlreadyAttached, tableSchemaName);

		return childrenToAttach;
	}

	/**
	 * Attach all possible children (which store data, including relationships) to a Page, by TableSchemaName
	 * 
	 * @static
	 * @param {string} pageId - Page Id
	 * @param {string} tableSchemaName - Optional Parent TableSchemaName
	 * @memberof PageUtils
	 */
	static attachAllChildrenWithData(pageId, tableSchemaName) {
		// Get the possible children
		let possibleChildren = this.getPossibleChildren(pageId, tableSchemaName) || [];

		let index = 0;
		let lastYs = [0, 0]; // Make sure that each field is always positioned directly below the previous one
		// Attach all children whose dataType is not 'none'
		possibleChildren.sort((a, b) => {
			return a.name.localeCompare(b.name);
		}).forEach(possibleChild => {
			let childFieldTypeId = possibleChild.fieldType;
			let childFieldTypeObj = FieldTypeStore.get(childFieldTypeId) || {};
			if (childFieldTypeObj.dataType !== 'none') {
				let i = index % 2;
				let height = FieldUtils.getDefaultRows(possibleChild.value);
				let y = lastYs[i];
				lastYs[i] += height;
				this.attachChild(pageId, possibleChild.value, false, {
					x: index % 2 ? 6 : 0,
					y: y,
					height: height,
					width: 6
				});
				index++;
			}
		});

		return PageActions.pushToDatabasePromise(PageStore.get(pageId, true));
	}

	/**
	 * Attach all possible children (which store data, including relationships) to a Page, by TableSchemaName
	 * 
	 * @static
	 * @param {string} pageId - Page Id
	 * @param {string} tableSchemaName - Optional Parent TableSchemaName
	 * @param {string} role The role of the fields to attach
	 * @memberof PageUtils
	 */
	static attachAllChildrenWithRole(pageId, tableSchemaName, role) {
		// Get the possible children
		let possibleChildren = this.getPossibleChildren(pageId, tableSchemaName) || [];

		let index = 0;
		let lastYs = [0, 0]; // Make sure that each field is always positioned directly below the previous one
		// Attach all children whose dataType is not 'none'
		possibleChildren.sort((a, b) => {
			return a.name.localeCompare(b.name);
		}).forEach(possibleChild => {
			// We need to track the last y of children as they're attached
			// in order to avoid overlaps (24613 - Fields seem overlapped when created using NLP)
			let settings = FieldSettingsStore.getSettings(possibleChild.value) || {};
			let roles = settings.roles ? settings.roles.split(',') : [];
			if (roles.indexOf(role) > -1) {
				let i = index % 2;
				let height = FieldUtils.getDefaultRows(possibleChild.value);
				let y = lastYs[i];
				lastYs[i] += height;

				this.attachChild(pageId, possibleChild.value, false, {
					x: index % 2 ? 6 : 0,
					y: y,
					height: height,
					width: 6
				});
				index++;
			}
		});

		return PageActions.pushToDatabasePromise(PageStore.get(pageId, true));
	}

	/**
	 * Gets default Child TableSchemaName
	 * 
	 * @static
	 * @param {any} recordId 
	 * @returns 
	 * @memberof PageUtils
	 */
	static getDefaultChildTableSchemaName(recordId) {
		if (!recordId) {
			console.warn('No recordId provided in PageUtils.getDefaultChildTableSchemaName');
			return;
		}
		//Grab the TableSchemaName from the PageObj
		let pageObj = PageStore.get(recordId);
		if (!pageObj) {
			console.warn('Aborting: can not get TableSchemaName of Page of :', recordId);
			return;
		}
		//Set the value of the tableSchemaName
		let tableSchemaName = pageObj.tableSchemaName;

		return tableSchemaName;
	}

	static processCreate(searchResult) {
		let page = {};
		// Push the page to the database
		PageActions.pushToDatabase(page);
	}

	/**
	 * Utility function to find the page on a table with a given role.
	 * Where multiple pages with a given role are found, the first one found will be used.
	 * 
	 * @param {string} tableSchemaName The tableSchemaName on which the page is
	 * @param {string} role The role of the page being searched for
	 */
	static getPageIdByRole(tableSchemaName, role) {
		let pages = PageStore.getArrayByTableSchemaName(tableSchemaName) || [];
		let page = pages.find(candidatePage => {
			let roles = candidatePage.roles ? candidatePage.roles.split(',') : [];
			return roles.indexOf(role) > -1;
		});
		return page && page.recordId ? page.recordId : '';
	}

	/**
	 * Open a page. Determines the context based on page role, currently opened record and the records available on the table
	 * 
	 * @param {string} pageId  - Page to load.
	 * @param {string} tableSchemaName - Table Schema Name of the record to load the page about
	 * @param {string} recordId - Optional, the recordId to load the page about
	 */
	static loadPage(pageId, tableSchemaName, recordId) {
		let pageObj = PageStore.get(pageId) || {};
		let { roles, availableModes } = pageObj;
		// Only open about no record if the only role is add or there are no records
		if (roles === 'add' && availableModes && availableModes.includes('add')) {
			_loadPage_openPage(pageId);
		} else if (typeof recordId === 'string') {
			_loadPage_openPage(pageId, tableSchemaName, recordId);
		} else if (PageModeStore.getCurrentMode(ContextStore.getPageRenderId()) !== 'add' && RecordStore.hasTableSchemaName(tableSchemaName)) {
			let renderObj = RenderStore.get(ContextStore.getPageRenderId()) || {};
			if (tableSchemaName === renderObj.dataTableSchemaName) {
				recordId = ContextStore.getRecordId();
			} else {
				let records = RecordStore.getRecords(tableSchemaName);
				recordId = Object.keys(records)[0];
			}
			_loadPage_openPage(pageId, tableSchemaName, recordId);
		} else {
			let fields = FieldStore.getByTableSchemaName(tableSchemaName);
			let requestFields = [];
			let fieldSchemaNamesWithData = [];
			fields.forEach(function (fieldObj) {
				let fieldId = fieldObj.recordId;
				let fieldSchemaName = fieldObj.fieldSchemaName;
				if (fieldId && FieldStore.getHasData(fieldId)) {
					if (fieldSchemaNamesWithData.indexOf(fieldSchemaName) === -1) {
						fieldSchemaNamesWithData.push(fieldSchemaName);
						requestFields.push({
							fieldSchemaName: fieldSchemaName,
							fieldType: fieldObj.fieldType,
							fieldId: fieldObj.recordId
						});
					}
				}
			});

			// Query gets all of our tableSchemaName, limit 1
			socketFetcher('gw/recordBrowse-v4', JSON.stringify({
				'fields': requestFields,
				'recordSets': {},
				'query': '{"queryId":"06aed78d-ba3a-499c-a2ce-b0304754f627","returnNode":"2cfe6315-19ba-436f-a227-1fe865a9fb9f","filters":[],"nodes":[{"tableSchemaName":"' + tableSchemaName + '","nodeId":"2cfe6315-19ba-436f-a227-1fe865a9fb9f"}],"sorts":[],"limit":1}',
				'tableSchemaName': tableSchemaName
			})).then(data => {
				if (data.responseCode === 200) {
					RecordActions.onDataLoaded(data.response.records);
					// data.response.records is an object, looks like this : 
					/* users: {
						userRecordId: 
							{
								recordId: [ We want this! ],
								otherProp: blah,
								etc: etc
							}
						} */
					// Get the first (zero-th) object under 'tableSchemaName'
					let recordObj = data.response.records[tableSchemaName][Object.keys(data.response.records[tableSchemaName])[0]];
					if (recordObj) {
						// Get its recordId
						recordId = recordObj.recordId;
						_loadPage_openPage(pageId, tableSchemaName, recordId);
					} else {
						_loadPage_openPage(pageId);
					}
				} else {
					_loadPage_openPage(pageId);
				}
			}).catch(error => {
				console.warn('Error retrieving records from table ' + tableSchemaName, error);
			});
		}
	}

	/**
	 * Method to get the settings to append onto child field settings
	 * 
	 * @returns {Array} Array of {recordId} objects
	 */
	static getChildSettings() {
		return [
			{ 'recordId': '4a80da03-821a-4e1b-a59a-78e5b71018ac' }, // Label Position
			{ 'recordId': '763ebd7e-2555-47df-845d-532353a8f4da' }, // Required
			{ 'recordId': 'ef33f299-8440-4605-b0ba-e19d23468d88' }, // View Render Variant
			{ 'recordId': 'c5429518-d94f-4d03-a917-68a4bc551bdf' } // Edit Render Variant
		];
	}

	/**
	 * Gets the settings for a page
	 * 
	 * @returns {Array} Array of {recordId} objects
	 */
	static getPageSettings() {
		return [
			{ recordId: 'e547119d-0f76-4ab1-844e-9f44f740d3e8' }, // Page Name
			{ recordId: '98e527c9-f497-4d12-9d79-aa10bb2e7881' }, // Roles
			{ recordId: 'c976803d-b867-4891-bcda-1c6995b70cb2' }, // Child Fields
			{ recordId: '2b037f0d-806c-41b8-ade9-7290c14ae492' }, // Unsaved Changes Warning
			// {recordId: 'c2e85d75-17e5-407e-8f09-9b62ead06e06'}, // Allow Adding Records
			{ recordId: 'aa9db60b-66a8-4c2c-ac2a-82a776bb1ff4' }, // Screen size management.
			{ recordId: '91533550-8284-4d7e-b698-01101e2b47f7' }, // Publicly available
			{ recordId: '5963f936-2a7d-4213-8e87-dbd9c1cc53fe' }, // Available Modes
			{ recordId: 'b3a5fe5f-8d17-4cfc-aad3-e1dc6cd61145' }, // Add Button Text
			{ recordId: 'b7de4279-f7c1-4df9-a391-9a087d81f20d' }, // Edit Button Text
			{ recordId: '98c112bc-7aec-432c-aef5-35c978f2f060' }, // Display Controls Location
			{ recordId: '05565c3e-6507-4b0b-9e4c-0962b26c1220' }, // Save Button Text
			{ recordId: 'aeb2dc2c-3153-4a27-8820-cd3e1379d29c' }, // Table
		];
	}

	static getAddPageSettings() {
		return [
			{ recordId: 'e547119d-0f76-4ab1-844e-9f44f740d3e8',
				sortOrder: 0, required: true }, // Page Name
			{ recordId: 'aeb2dc2c-3153-4a27-8820-cd3e1379d29c',
				sortOrder: 1, required: true }, // Table
			{ recordId: 'c976803d-b867-4891-bcda-1c6995b70cb2',
				sortOrder: 2, required: false }, // Child Fields
			{ recordId: '5963f936-2a7d-4213-8e87-dbd9c1cc53fe',
				sortOrder: 3, required: false }, // Available Modes
			{ recordId: '98e527c9-f497-4d12-9d79-aa10bb2e7881',
				sortOrder: 4, required: false }, // Roles
		];
	}
}

/**
   * Loads and Opens a Page for the Context TableSchemaName
   * 
   * @param {String} pageId 
   * @param {String} tableSchemaName 
   * @param {String} recordId 
   * @returns 
   * 
   * @memberof SiteMapContainer
   */
function _loadPage_openPage(pageId, tableSchemaName, recordId) {
	return InterfaceActions.replacePage({ pageId: pageId, tableSchemaName: tableSchemaName, recordId: recordId, renderParentId: ContextStore.getPageRenderId() });
}

export default PageUtils;
