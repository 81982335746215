import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { AdminSettingsActions, InterfaceActions, MetadataActions } from '../../../actions';
import { AdminSettingsStore, FieldStore, MetadataStore, ToolboxStore, ContextStore } from '../../../stores';

// Components;
import ReactBlocklyComponent from '@dmclain-citizendeveloper/citdev-react-blockly-component';

// Utils
import { 
	BlocklyUtils,
	LogicUtils, 
	ObjectUtils,
} from '../../../utils';

/**
 * Right panel displaying Scheduled Logic settings
 */
class ScheduledLogicSetting extends Component {
	constructor(props) {
		super(props)
		this._onCopyWorkspace = this._onCopyWorkspace.bind(this);
		this._onKeyDown = this._onKeyDown.bind(this);
		this._onPasteWorkspace = this._onPasteWorkspace.bind(this);
		this._onResizeBlockly = this._onResizeBlockly.bind(this);
		this._onSettingChange = this._onSettingChange.bind(this);
		this._onWorkspaceChange = this._onWorkspaceChange.bind(this);
	}
	
	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
	 return [ AdminSettingsStore, FieldStore, MetadataStore, ToolboxStore ]; 
	}

	/**
	 * Calculate the current state of the component
	 * @static
	 *
	 * @param  {Object} prevState Previous State
	 * @param  {Object} props     Previous Props
	 * @return {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId =  AdminSettingsStore.getRecordId();
		let settingRecordId = AdminSettingsStore.getSettingRecordId();
		let settingObj = FieldStore.get(settingRecordId);
		let value = '';

		if(settingObj){
			let settingSchemaName = settingObj.fieldSchemaName;

			let scheduleObj = MetadataStore.get(recordId, 'scheduledLogic');
			if(scheduleObj) {
				value = scheduleObj[settingSchemaName];
			}
		}

		let actionToolbox = (prevState ? prevState.actionToolbox : undefined);

		let newToolbox = ToolboxStore.getActionToolboxJS();
		if((!prevState || !prevState.actionToolbox) && newToolbox && Object.keys(newToolbox).length) {
			actionToolbox = newToolbox;
		}

		return {
			settingObj: settingObj,
			settingRecordId: settingRecordId,
			recordId: recordId,
			value: value,
			actionToolbox: actionToolbox,
			settingsHidden: AdminSettingsStore.getSettingsListHidden()
		};
	}

	/**
	 * Calling resizeBlockly during this lifecycle phase to ensure
	 * Blockly resizes when user closes and reopens right panel with same
	 * Blockly component displaying
	 */
	componentDidUpdate(prevProps, prevState) {
		// Resize blockly
		// @TODO: Check if we need to resize in more limited circumstances
		this._onResizeBlockly();
	}

	/**
	 * Calling resizeBlockly during this lifecycle phase to ensure
	 * Blockly resizes when opens right panel for the first time
	 */
	 componentDidMount() {
		this._onResizeBlockly();
	}

	/**
	 * Render the component
	 * @return JSX
	 */
	render() {
		let {value, settingObj, settingsHidden } = this.state;

		if (!settingObj || !settingObj.fieldSchemaName) {
			return (<div className="select-setting">
						<div className="select-setting-text-wrap">
							Select a Setting to configure on the left.
						</div>
					</div>);
		}

		let label = settingObj.fieldLabel;

		try {
			value = value ? JSON.parse(value) : {};
		} catch(error){
			console.warn(error);
		}

		const workspaceConfiguration = {
			grid: {
				spacing: 20,
				length: 3,
				colour: '#ccc',
				snap: true
			},
			zoom: {
				controls: true,
				wheel: true,
				startScale: 0.9,
				maxScale: 3,
				minScale: 0.3,
				scaleSpeed: 1.2
			}
		};

		return (
			<div className={`automation-setting-container pt-0 cd-bg-3 pr-3 ${settingsHidden ? 'pl-3' : ''}`}>
				<h2 className="d-flex w-100 justify-content-between align-items-center py-3">
					<div className='d-flex align-items-center justify-content-between mr-2' style={{ flex: 1 }}>
						<div className='d-flex align-items-center'>
							<h3 className='my-1'>
								{/* Show collapse only when setting has been selected */} 
								{settingsHidden ?
									<button 
										className="btn btn-back"
										title="Triggers" 
										form="appearance-form" 
										onClick={() => {AdminSettingsActions.onSettingsListHideChange(false);}}>
										<img height="26" width="26" src={ContextStore.getUrlMedia() + "/expand-settings-list.svg"} alt="" />
									</button>
								: null }
								{label}
							</h3>
						</div>
						<div className="btn-wrapper d-flex align-items-center justify-content-around">
							<button
								key="save"
								className="btn btn-primary ml-2"
								form="appearance-form"
								aria-label="Save"
								onClick={LogicUtils.saveScheduledLogic}>
								Save
							</button>
							<button
								key="reset"
								className="btn btn-warning ml-2"
								form="appearance-form"
								aria-label="Reset"
								onClick={LogicUtils.resetScheduledLogic}>
								Reset
							</button>
							<button
								key="run-once"
								className="btn btn-secondary ml-2"
								form="appearance-form"
								aria-label="Run Once"
								onClick={LogicUtils.runScheduledLogic}>
								Run Once
							</button>
							<button
								key="copy"
								className="btn btn-secondary ml-2"
								form="appearance-form"
								aria-label="Copy"
								onClick={this._onCopyWorkspace}>
								Copy
							</button>
							<button
								key="paste"
								className="btn btn-secondary ml-2"
								form="appearance-form"
								aria-label="Paste"
								onClick={this._onPasteWorkspace}>
								Paste
							</button>
						</div>
					</div>
				</h2>
				<div className={"automation-settings--blockly"}>
					<ReactBlocklyComponent.BlocklyEditor
						initialXml={value && value.blocklyxml}
						ref={(blocklyEditor) => { this.blocklyEditor = blocklyEditor; }}
						workspaceConfiguration={workspaceConfiguration}
						wrapperDivClassName='automation-settings--blockly'
						toolboxCategories={ToolboxStore.getActionToolboxJS()}
						workspaceDidChange={this._onWorkspaceChange}
					/>
				</div>
			</div>);
	}

	/**
		 * Copies blocks to citdev clipboard
		 */
	_onCopyWorkspace() {
		try {
			let blocklyValueObj = BlocklyUtils.getWorkspaceInfo(this.blocklyEditor.workspace.workspace, {
				defaultToNull: true,
				includeJs: false // We don't need to bother with the JS for this
			});
			localStorage.logicClipboard = JSON.stringify(blocklyValueObj);
			InterfaceActions.notification({ 'level': 'success', 'message': 'Copying logic to clipboard...' });
		} catch (err) {
			console.error('Error when copying logic: ', err);
			InterfaceActions.notification({ 'level': 'error', 'message': 'Error when copying logic!' });
		}
	}

	/**
	 * Listen to key down events for a control/open apple S.. and save.
	 * 
	 * @todo Bailed out on this functionality because the save method is one component higher then this component and didn't
	 * have time to resolve.  But, this method runs when someone hits Control-S in the Appearance Settings area.
	 */
	_onKeyDown(event) {
		// Check if keys pressed are ctrl+s
		if(event.ctrlKey && event.key === 's') {
			console.warn('Ctrl-S to save not yet implemented.');
			event.preventDefault();
		}
		// when on MAC
		if(event.metaKey && event.key === 's') {
			console.warn('Ctrl-S to save not yet implemented.');
			event.preventDefault();
		}
	}

	/**
	 * Pastes blocks from citdev clipboard to workspace; appends to, not overrides, blocks
	 */
	_onPasteWorkspace() {
		let value = localStorage.logicClipboard;
		try {
			let valueObj = ObjectUtils.getObjFromJSON(value);

			let pastedblocklyxml = valueObj.blocklyxml;
			BlocklyUtils.appendToWorkspace(pastedblocklyxml, this.blocklyEditor.workspace.workspace);
			InterfaceActions.notification({ 'level': 'success', 'message': 'Pasting new logic below existing logic. Please check your new blocks to make sure they don\'t overlap!' });
			// let oldblocklyxml = this.state.value ? this.state.value.blocklyxml : '';


			// // Pass the pasted value into the utility function to combine them into one workspace XML string
			// let newxml = BlocklyUtils.combineWorkspaces(pastedblocklyxml, oldblocklyxml);

			// // Make sure that our new code still compiles; the catch will catch it if it's invalid XML
			// if(Blockly.Xml.textToDom(newxml)){
			// 	this.handleXMLChange(newxml);
			// 	InterfaceActions.notification({ 'level': 'success', 'message': 'Pasting new logic below existing logic. Please check your new blocks to make sure they don\'t overlap!' });
			// }
		} catch (err) {
			InterfaceActions.notification({ 'level': 'error', 'message': 'Attempted to paste invalid value into workspace.' });
			console.warn('Attempted to paste with invalid data in clipboard. Value was', value);
			console.warn('Error was', err);
		}
	}

	/**
	 * resizeBlockly - Resizes blockly instances to full width by triggering resize() from ref.
	 * A setTimeout of 400 milliseconds is used to allow the CSS transition to complete
	 * Prior to calculation of new width
	 *
	 * @param  {string} (optional) blocklyRefId of ref to Blockly refs. Will pull state value when left undefined
	 */
	_onResizeBlockly() {
		if (this.blocklyEditor) {
			setTimeout(this.blocklyEditor.resize, 500);
		}
	}

	/**
	 * Save the value as a value is entered
	 * @param {String} value
	 */
	_onSettingChange(value) {
		let { recordId, settingObj } = this.state;
		let settingSchemaName = settingObj.fieldSchemaName;
		let scheduleObj = MetadataStore.get(recordId, 'scheduledLogic');

		// Update the Schedule Object with this setting, at its new value
		scheduleObj[settingSchemaName] = value;

		// Push the update Schedule Object into the store.
		MetadataActions.pushToStore(recordId, 'scheduledLogic', scheduleObj);
	}
	
	/**
	 * Respond to when the blockly workspace changes
	 */
	_onWorkspaceChange() {
		let { recordId } = this.state;
		
		// First, debounce for blockly XML
		let value = {}; 
		if(this.props.value && typeof this.props.value === 'string') {
		    value = JSON.parse(this.props.value);
		} else {
		    value = Object.assign(value, this.props.value);
		}
		if(!value.isProcessing) {
		    value.isProcessing = true;
		}
		clearTimeout(this.xmlChange);
		this.xmlChange = setTimeout(() => {
		    let blocklyValueObj = BlocklyUtils.getWorkspaceInfo(this.blocklyEditor.workspace.workspace, {
				defaultToNull: true,
				includeJs: true // @TODO: review this later
			});
			Object.assign(value, blocklyValueObj);
			delete value.isProcessing

			// Lookup the Highmem Flag, and add it
			let scheduleObj = MetadataStore.get(recordId, 'scheduledLogic');
			value.memUse = (scheduleObj && scheduleObj.highMemory && scheduleObj.highMemory === 'true' ? 'h' : 'l')

			value.forceDirty = true;
			this._onSettingChange(JSON.stringify(value));
		}, 500);
	}
}
const container = Container.create(ScheduledLogicSetting, {withProps: true});
export default container;