/*eslint no-eval: "off"*/
import AppDispatcher from '../dispatcher/app-dispatcher';
import RecordActions from '../actions/record-actions';
import InterfaceActions from '../actions/interface-actions';
import socketFetcher from '../utils/socket-fetcher';
import FieldStore from '../stores/field-store';
import FieldSettingsStore from '../stores/field-settings-store';
import FieldTypeStore from '../stores/field-type-store';
import RemoteFileStorage from '../utils/stream-data-utils.js';
import TimeUtils from '../utils/time-utils';
import RecordVariableUtils from '../utils/record-variable-utils'; 
import {RecordConstants} from '../constants/record-constants';
import RecordStore from '../stores/record-store';
import TableStore from '../stores/table-store';
import ContextStore from '../stores/context-store';
import AuthenticationStore from '../stores/authentication-store';
import FieldComponents from '../utils/field-components';
import { ObjectUtils } from '../utils';
import uuid from 'uuid';

//@TODO Change to citDev
let citdev = { 
	socketFetcher,
	gcs: {
		uploadFile: RemoteFileStorage.uploadFile
	},
	file: {
		mimeLookup: RemoteFileStorage.mimeLookup,
		uploadFile: RemoteFileStorage.uploadFile,
		sanitizeFilename: RemoteFileStorage.sanitizeFilename
	},
	time: {
		getTimezone: TimeUtils.getTimezone
	}
};

export default {
	/**
	 * Fetches and loads all the records for a given table schema name
	 *
	 * @param {string} tableSchemaName
	 */
	getAllRecordsByTable: function (contextObj, tableSchemaName) {
		let fields = FieldStore.getByTableSchemaName(tableSchemaName);
		let requestFields = [];
		let fieldSchemaNamesWithData = [];
		fields.forEach(function(fieldObj) {
			let fieldId = fieldObj.recordId;
			let fieldSchemaName = fieldObj.fieldSchemaName;
			if(fieldId && FieldStore.getHasData(fieldId)) {
				if (fieldSchemaNamesWithData.indexOf(fieldSchemaName) === -1) {
					fieldSchemaNamesWithData.push(fieldSchemaName);
					requestFields.push({
						fieldSchemaName: fieldSchemaName, 
						fieldType: fieldObj.fieldType,
						fieldId: fieldObj.recordId
					});
				}
			}
		});
		
		socketFetcher('gw/recordBrowse-v4', JSON.stringify({ 
			'recordSets': RecordVariableUtils.buildNamedContexts(),
			'tableSchemaName': tableSchemaName,
			'fields': requestFields})).then(function (data) {
				if(200 <= data.responseCode && data.responseCode < 300) {
					// If a 200 response is received
					RecordActions.onDataLoaded(data.response.records, true);
				} else if(data.responseCode === 404) {
					let emptyResults = {};
					emptyResults[tableSchemaName] = {};
					RecordActions.onDataLoaded(emptyResults, true);
				} else {
					console.warn('Error getting records for table %s. Response received was', tableSchemaName, data);
					RecordActions.onError(data.response);
				}
		}).catch(RecordActions.onError);
	},

	addRecord: function (contextObj, record) {
		return new Promise(function(resolve, reject) {

			let tableSchemaName = record.tableSchemaName;
			// Check if we're over capacity
			let recordCount = RecordStore.getRecordCount(tableSchemaName);
			let maxTables = AuthenticationStore.getMaxRecordsPerTable();
			if(maxTables && recordCount >= maxTables) { 
				let tableObj = TableStore.getByTableSchemaName(tableSchemaName);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Permission Denied: Record count for ' + tableObj.singularName + ' exceeded.' });
				return reject(new Error('Record count exceeded for table ' + tableSchemaName));
			}

			// merge session into context
			
			// Loop over the fieldSchemaNames, and remove/store those that are dataType === none
			let requestFields = {};
			let frontEndPromises = [];
			let relationshipFields = {};
			Object.keys(record.fields).forEach(function(fieldSchemaName) {
				let fieldId = FieldStore.getFieldId(fieldSchemaName, record.tableSchemaName);
				if (fieldId) {
					let fieldObj = FieldStore.get(fieldId);
					let fieldType = fieldObj ? fieldObj.fieldType : null;
					let fieldRenderInfo = record && record.fieldsRender && record.fieldsRender[fieldSchemaName]
						? record.fieldsRender[fieldSchemaName]
						: undefined;
					if (fieldType) {
						//Get the FieldType Obj of this field: 
						let fieldTypeObj = FieldTypeStore.get(fieldType);

						// Special handling for relationshipFields to prevent them from getting left as dirty and then mistakenly cleaned
						if(fieldTypeObj.dataType === "relationship") {
							relationshipFields[fieldObj.fieldSchemaName] = record.fields[fieldSchemaName];
						}

						if(fieldTypeObj) {
							// Get the Front End pre Storage Code: 
							let frontEndPreStorageCode = fieldTypeObj ? fieldTypeObj.fePreStorageCode : null;
	
							if(frontEndPreStorageCode){
								let fieldSettings;
								if(fieldRenderInfo && fieldRenderInfo.attachmentId) {
									fieldSettings = FieldSettingsStore.getSettingsFromAttachmentId(fieldRenderInfo.attachmentId, fieldId, fieldRenderInfo.parentId);
								} else if (fieldRenderInfo.parentId) {
									fieldSettings = FieldSettingsStore.getSettings(fieldId, fieldRenderInfo.parentId);
								} else {
									fieldSettings = FieldStore.getSettings(fieldId);
								}

								frontEndPromises.push(
									//return a promise while Community code returns Processed Value
									new Promise((resolve, reject) =>{
										let frontEndPreStorage = '';
	
										//Wrap the FrontEnd Code in a wrapper Function 
										let wrappedFrontEndFunction = 
										'frontEndPreStorage = (function(dataRecordId, dataTableSchemaName, fieldId, fieldSchemaName, value, fieldSettings, citdev) { \n' +
											'\t return new Promise(function(resolve, reject) { \n' +
											frontEndPreStorageCode + 
											'})})';
										
										// If our browser does not support generators (IE11.. grumble.. ) then babel the code.
										if(!window.supportsGenerators) {
											/*global Babel*/
											wrappedFrontEndFunction = Babel.transform(wrappedFrontEndFunction,{ presets: ['es2015','react'] }).code;
										}
										//Compile the WrappedFrontend Function 
										eval(wrappedFrontEndFunction);
										
										//Pack citdev Obj with context Values:
										citdev.sessionObj = {
											installationId: contextObj.get('installationId'),
											applicationId: contextObj.get('applicationId')
										};
	
										// console.groupCollapsed('====================== Calling Front End Pre Storage Code  with PARAMETERS: =======================');
										// console.log('recordId: ', fieldId);
										// console.log('fieldSchemaName: ', fieldSchemaName);
										// console.log('value: ', record.fields[fieldSchemaName]);
										// console.log('fieldSettings: ', FieldStore.getSettings(fieldId));
										// console.log('citdev: ', citdev);
										// console.groupEnd();
										//Do the Front End Pre Storage Code here 
										frontEndPreStorage(
											record.recordId,
											record.tableSchemaName,
											fieldId, 
											fieldSchemaName, 
											record.fields[fieldSchemaName],
											fieldSettings,
											citdev)
											.then(value => {
												// console.groupCollapsed('Success Front end Pre Storage');
												// console.log('Processed Value: ', value);
												// console.groupEnd();
												if(fieldTypeObj.dataType !== "none" && fieldTypeObj.dataType !== "relationship") {
													resolve({
														value: value,
														fieldId: fieldId,
														fieldSchemaName: fieldSchemaName,
														fieldType: fieldObj.fieldType
													});
												} else {
													resolve({});
												}
											}).catch(error => {
												console.error('Front End Pre Storage Code Error.  Your message was: ', error, 'Code Block was:', frontEndPreStorageCode); 
												resolve({error: error});
											});
									})
								);
								console.groupEnd();
							} else {
								// End if there was no pre storage code...

								// If the field does not have front end pre Storage Key and it stores data...
								if(fieldTypeObj.dataType !== "none" && fieldTypeObj.dataType !== "relationship" ) {
									requestFields[fieldObj.fieldSchemaName] = {
										value: record.fields[fieldSchemaName],
										fieldSchemaName: fieldSchemaName,
										fieldType: fieldObj.fieldType,
										fieldId: fieldId
									};
								}
							} 	
						} // End if we didn't find a fieldTypeObj!
					}
				}
			});

			// console.log('request fields before the promise.all:', requestFields);

			//Wait until all Promises are resolved to build the body Data for the socketFetcher
			Promise.all(frontEndPromises).then(fieldData => {
				//Look for error in the fields Processing: 
				let errors = fieldData.filter(function(field) {
					return !!field.error;
				});
				//If any error, send an Interface message and reject the entire Save process
				if(errors.length){
					errors.forEach(function(errorField){
						try {
							let error = errorField.error && errorField.error.message ? errorField.error.message : errorField.error.toString();
							//Notify there has been an error saving a Field: 
							InterfaceActions.notification({ 'level': 'error', 'message': error});
						} catch(err) {
							InterfaceActions.notification({ 'level': 'error', 'message': 'Unknown error saving field. Check your console for more information.'});;
							console.error('errorField', errorField);
						}
					});
					reject();
				} else { //If no errors, proceed to save
					// Build the content of the requestFields object with all the fields to Update
					fieldData.forEach(field => {
						//Build the body 
						if(field.fieldSchemaName) {
							requestFields[field.fieldSchemaName] ={
								fieldId: field.fieldId,
								fieldSchemaName: field.fieldSchemaName,
								fieldType: field.fieldType,
								value: field.value
							};
						}
					}); 
					
					// If we have any fields to send...
					let bodyData = JSON.stringify({
						currentContextObj: contextObj,
						tableSchemaName: record.tableSchemaName,
						recordId: record.recordId,
						fields: requestFields
					});

					socketFetcher('gw/recordAdd-v4', bodyData).then(function (data) {
						if (data.responseCode >= 200 && data.responseCode < 400) {
							let recordLoaded = {};
							if (data.response) {
								// Postprocessed fields are returned by service
								recordLoaded = data.response;
								if (recordLoaded[record.tableSchemaName]) {
									delete recordLoaded[record.tableSchemaName].recordId;
								}
							} else {
								recordLoaded[record.tableSchemaName] = {};
								recordLoaded[record.tableSchemaName][record.recordId] = record.fields;
							}

							// Add the dynamic selection fields back in to avoid them being cleaned incorrectly (fix for 4336)
							Object.assign(recordLoaded[record.tableSchemaName][record.recordId], relationshipFields);
							// console.log('added', data);
							// Force the updated values as "clean" in the Record Store (second param = true)
							RecordActions.onDataLoaded(recordLoaded, true);
							if(maxTables) {
								RecordActions.updateTableCounts({[tableSchemaName]: recordCount ? (recordCount + 1) : 1});
							}
							resolve();
						} else {
							let response = data.response,
								responseCode = data.responseCode,
								records = (response[record.tableSchemaName] ? response[record.tableSchemaName] : {});
							// console.log('response', response);
							if (!records) {
								if (data.error) {
									let err = new Error(data.error);
									err.httpCode = data.responseCode;
									return reject(err);
								} else if (data.response) {
									let err = new Error(data.response);
									err.httpCode = data.responseCode;
									return reject(err);
								} {
									let err = new Error(data.responseCode + ' response received from record add service, but no error provided');
									err.httpCode = data.responseCode;
									return reject(err);
								}
							}
							Object.keys(records).forEach(recordId => {
								let fields = records[recordId];
								Object.keys(fields).forEach(fieldKey => {
									let fieldObj = fields[fieldKey];
									let uiMessage = fieldObj && fieldObj.uiMessage ? fieldObj.uiMessage : fieldObj;
									console.error('Error ' + responseCode + ': ' + uiMessage);
								});
							});

							if (data.responseCode === 403) {
								let tableObj = TableStore.getByTableSchemaName(record.tableSchemaName);
								InterfaceActions.notification({ 'level': 'error', 'message': 'Permission Denied: Cannot add ' + tableObj.singularName + ' records' });
							}

							reject(new Error(data.response));
						}
					}).catch(function (error) {
						console.error(error);
						reject(error);
					});
				}
			}).catch(error => {
				console.error(error);
				reject(error);
			});
		});
	},

	/**
	* Loads a single record into the record store. Record id is extracted from 
	* a RecordId in a TableSchemaName
	*
	* @param {string} tableSchemaName Table for Record to retrieve field data for
	* @param {string} recordId Record to retrieve field data for
	* @param {Array} fieldSchemaNames list of field schema names pulled from page data
	*/
	getRecord: function (tableSchemaName, recordId, fieldSchemaNames) {
		// Make sure this exists
		fieldSchemaNames = fieldSchemaNames || [];
		// Loop over the fieldSchemaNames, and remove/store those that are dataType === none
		let fieldSchemaNamesWithoutData = [];
		let fieldSchemaNamesWithData = [];
		let fieldSchemaNamesRelationships = [];
		let relationshipLookupPromises = [];
		let requestFields = [];
		fieldSchemaNames.forEach((fieldSchemaName) => {
			let fieldId = FieldStore.getFieldId(fieldSchemaName, tableSchemaName);
			let fieldObj = FieldStore.get(fieldId);
			if(!fieldId || (fieldId && !FieldStore.getHasData(fieldId))) {
				let fieldTypeObj = fieldObj ? FieldTypeStore.get(fieldObj.fieldType) : {};
				if(fieldTypeObj.dataType === 'relationship') {
					let settings = FieldStore.getSettings(fieldId);
					let selectListOfObj = settings.selectListOf && typeof settings.selectListOf === 'string' 
							? ObjectUtils.getObjFromJSON(settings.selectListOf)
							: settings.selectListOf;
					let valueLookupQuery = settings.relationshipSelector ? FieldComponents.query.getValueLookupQuery(fieldId, settings.relationshipSelector, selectListOfObj) : '';
					if(valueLookupQuery) {
						let lookupTableSchemaName = FieldComponents.query.getReturnTable(settings.selectListOf);
						let viewMode = settings.viewMode ? FieldComponents.UIUtils.convertFieldSelectorToFieldSchemaName(settings.viewMode,  tableSchemaName, false) : '';
						let editMode = settings.editMode ? FieldComponents.UIUtils.convertFieldSelectorToFieldSchemaName(settings.editMode,  tableSchemaName, false) : '';

						let fields = [];
						if (viewMode) {
							let fieldId = FieldStore.getFieldId(viewMode, tableSchemaName);
							let fieldObj = FieldStore.get(fieldId);
							if (fieldObj && fieldObj.tableSchemaName === tableSchemaName) {
								fields.push({
									fieldSchemaName: viewMode,
									fieldType: fieldObj.fieldType,
									fieldId: fieldId
								});
							}
						}
						if (editMode && editMode !== viewMode) {
							let fieldId = FieldStore.getFieldId(editMode, tableSchemaName);
							let fieldObj = FieldStore.get(fieldId);
							if (fieldObj && fieldObj.tableSchemaName === tableSchemaName) {
								fields.push({
									fieldSchemaName: editMode,
									fieldType: fieldObj.fieldType,
									fieldId: fieldId
								});
							}
						}

						let recordSets = {
							startingContext: [{
								'recordId': recordId,
								'tableSchemaName': tableSchemaName
							}],
							'page': [{
								'recordId': recordId,
								'tableSchemaName': tableSchemaName
							}],
							'application': [{
								'recordId': ContextStore.getApplicationId(),
								'tableSchemaName': 'applications'
							}],
							'installation': [{
								'recordId': ContextStore.getInstallationId(),
								'tableSchemaName': 'installations'
							}],
							'currentUser': [{
								'recordId': AuthenticationStore.getUserId(),
								'tableSchemaName': 'users'
							}],
						};
						let request = {
							tableSchemaName: lookupTableSchemaName,
							fields: fields,
							query: valueLookupQuery,
							recordSets: recordSets
						};
						relationshipLookupPromises.push(socketFetcher('gw/recordBrowse-v4', request));
						fieldSchemaNamesRelationships.push(fieldSchemaName);
					} else {
						fieldSchemaNamesRelationships.push(fieldSchemaName);
						relationshipLookupPromises.push(Promise.resolve({responseCode: 500, response: 'Malformed relationship field ' + fieldId + ' (' + fieldSchemaName + ')'}));
					}
				} else {
					fieldSchemaNamesWithoutData.push(fieldSchemaName);
				}
			} else {
				if (fieldSchemaNamesWithData.indexOf(fieldSchemaName) === -1) {
					fieldSchemaNamesWithData.push(fieldSchemaName);
					requestFields.push({
						fieldSchemaName: fieldSchemaName, 
						fieldType: fieldObj.fieldType, 
						fieldId: fieldId
					});
				}
			}
		});

		let bodyData = JSON.stringify({
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			fields: requestFields
		});

		return Promise.all([socketFetcher('gw/recordRead-v4', bodyData)].concat(relationshipLookupPromises)).then((results) => {

			let [data, ...relationshipValues] = results;
			let correlationId = uuid.v4();

			// Add to the response my fields that are dataType === none, and a null value, so that goes into the store.
			if(data.responseCode === 200) {
				if(data.response) {
					let records = data.response[tableSchemaName];
					//If we cannot load a Field
					Object.keys(records).forEach(recordId => {
						let fields = records[recordId];
						Object.keys(fields).forEach(fieldKey => {
							let fieldObj = fields[fieldKey]; 
							if (fieldObj && (fieldObj.uiMessage || fieldObj.message)) {
								let errorMessage = fieldObj && fieldObj.message ? fieldObj.message : fieldObj.uiMessage;
								console.error(errorMessage);
							}
						});
					});

					if(data.response[tableSchemaName] && data.response[tableSchemaName][recordId]) {
						fieldSchemaNamesWithoutData.forEach(fieldSchemaNameWithoutData =>{
							data.response[tableSchemaName][recordId][fieldSchemaNameWithoutData] = null;
						});
	
						if(relationshipValues && relationshipValues.length) {
							relationshipValues.forEach((relationshipData, index) => {
								let fieldSchemaName = fieldSchemaNamesRelationships[index];
								if(relationshipData.responseCode >= 200 && relationshipData.responseCode < 300) {
									let responseTableSchemaName = Object.keys(relationshipData.response.records)[0];
									let startingRelatedRecordsArr = Object.keys(relationshipData.response.records[responseTableSchemaName]).map((recordId) => {
										return ({
											recordId,
											tableSchemaName: responseTableSchemaName
										});
									});
									let startingRelatedRecordsJSON = JSON.stringify(startingRelatedRecordsArr);
									data.response[tableSchemaName][recordId][fieldSchemaName] = JSON.stringify({
										startingRelatedRecordsJSON,
										newRecordJSON: startingRelatedRecordsJSON
									});
								} else if (relationshipData.responseCode < 500) {
									console.warn(relationshipData.response);
									data.response[tableSchemaName][recordId][fieldSchemaName] = JSON.stringify({
										startingRelatedRecordsJSON: '[]',
										newRecordJSON: '[]'
									});
								} else {
									console.error('Error getting values for relationship field %s on %s %s', fieldSchemaName, tableSchemaName, recordId, new Error(relationshipData.response));
									data.response[tableSchemaName][recordId][fieldSchemaName] = null;
								}
							});
						}
					}
					
					return new Promise((resolve) => {
						let token = AppDispatcher.register((action) => {
							if (action.get('type') === RecordConstants.RECORDS_LOADED) {
							 if (action.get('correlationId') === correlationId) {
								AppDispatcher.waitFor([RecordStore.getDispatchToken()]);
								AppDispatcher.unregister(token);
								resolve();
							 }
							}
						});
						RecordActions.onDataLoaded(data.response, false, correlationId);
					});
				}
			} else {
				let nullRecord = {};
					nullRecord[tableSchemaName] = {};
					nullRecord[tableSchemaName][recordId] = {};
				return new Promise((resolve) => {
					let token = AppDispatcher.register((action) => {
						if (action.get('type') === RecordConstants.RECORDS_LOADED) {
							if (action.get('correlationId') === correlationId) {
								AppDispatcher.waitFor([RecordStore.getDispatchToken()]);
								AppDispatcher.unregister(token);
								resolve();
							}
						}
					});
					RecordActions.onDataLoaded(nullRecord, false, correlationId);
				});
			}
		});
	},

	updateRecord: function (contextObj, record) {
		return new Promise(function(resolve, reject) {

			// Loop over the fieldSchemaNames, and remove/store those that are dataType === none
			let requestFields = {};
			let frontEndPromises = [];
			let relationshipFields = {};

			record.fields = record.fields || {};
			Object.keys(record.fields).forEach(function(fieldSchemaName) {
				let fieldRenderInfo = record && record.fieldsRender && record.fieldsRender[fieldSchemaName]
					? record.fieldsRender[fieldSchemaName]
					: undefined;
				let fieldId = FieldStore.getFieldId(fieldSchemaName, record.tableSchemaName);
				if (fieldId) {
					let fieldObj = FieldStore.get(fieldId);
					let fieldType = fieldObj ? fieldObj.fieldType : null;
				
					//Get the Settings of the field to grab the FrontEnd PreCode: 
					if (fieldType) {
						//Get the FieldType Obj of this field: 
						let fieldTypeObj = FieldTypeStore.get(fieldType);
						// Special handling for relationshipFields to prevent them from getting left as dirty and then mistakenly cleaned
						if(fieldTypeObj.dataType === "relationship") {
							relationshipFields[fieldObj.fieldSchemaName] = record.fields[fieldSchemaName];
						}

						// Get the Front End pre Storage Code: 
						let frontEndPreStorageCode = fieldTypeObj ? fieldTypeObj.fePreStorageCode : null;

						if(frontEndPreStorageCode){
							let fieldSettings;
							if(fieldRenderInfo && fieldRenderInfo.attachmentId) {
								fieldSettings = FieldSettingsStore.getSettingsFromAttachmentId(fieldRenderInfo.attachmentId, fieldId, fieldRenderInfo.parentId);
							} else if (fieldRenderInfo.parentId) {
								fieldSettings = FieldSettingsStore.getSettings(fieldId, fieldRenderInfo.parentId);
							} else {
								fieldSettings = FieldStore.getSettings(fieldId);
							}
							frontEndPromises.push(
								//return a promise while Community code returns Processed Value
								new Promise((resolve, reject) =>{
									let frontEndPreStorage = '';
									//Wrap the FrontEnd Code in a wrapper Function 
									let wrappedFrontEndFunction = 
									'frontEndPreStorage = (function(dataRecordId, dataTableSchemaName, fieldId, fieldSchemaName, value, fieldSettings, citdev) {' +
										'return new Promise(function(resolve, reject) {' +
										frontEndPreStorageCode + 
										'})})';

									// console.log('wrappedFrontEndFunction', wrappedFrontEndFunction);

									// If our browser does not support generators (IE11.. grumble.. ) then babel the code.
									if(!window.supportsGenerators) {
										/*global Babel*/
										wrappedFrontEndFunction = Babel.transform(wrappedFrontEndFunction,{ presets: ['es2015','react'] }).code;
									}
									//Compile the WrappedFrontend Function 
									eval(wrappedFrontEndFunction);

									//Pack citdev Obj with context Values:
									citdev.sessionObj = {
										installationId: contextObj.get('installationId'),
										applicationId: contextObj.get('applicationId')
									};

									// console.groupCollapsed('====================== Calling Front End Pre Storage Code  with PARAMETERS: =======================');
									// console.log('recordId: ', fieldId);
									// console.log('fieldSchemaName: ', fieldSchemaName);
									// console.log('value: ', record.fields[fieldSchemaName]);
									// console.log('fieldSettings: ', FieldStore.getSettings(fieldId));
									// console.log('contextObj.get(installationId): ', contextObj.get('installationId'));
									// console.log('citdev: ', citdev);
									// console.groupEnd();

									//Do the Front End Pre Storage Code here 
									frontEndPreStorage(
										record.recordId,
										record.tableSchemaName,
										fieldId, 
										fieldSchemaName, 
										record.fields[fieldSchemaName],
										fieldSettings,
										citdev)
										.then(value => {
											// console.groupCollapsed('Success Front end Pre Storage');
											// console.log('Processed Value: ', value);
											// console.groupEnd();
											if(fieldTypeObj.dataType !== "none" && fieldTypeObj.dataType !== "relationship" ) {
												resolve({
													value: value,
													fieldId: fieldId,
													fieldSchemaName: fieldSchemaName,
													fieldType: fieldObj.fieldType
												});
											} else {
												resolve({});
											}
										}).catch(error => {
											console.error('Front End Pre Storage Code Error.  Your message was: ', error, 'Code Block was:', frontEndPreStorageCode); 
											resolve({error: error});
										});
								})
							);
						} else {
							// If the field does not have front end pre Storage Key and it stores data...
							if(fieldTypeObj.dataType !== "none" && fieldTypeObj.dataType !== "relationship" ) {
								requestFields[fieldObj.fieldSchemaName] = {
									value: record.fields[fieldSchemaName],
									fieldSchemaName: fieldSchemaName,
									fieldType: fieldObj.fieldType,
									fieldId: fieldId
								};
							}
						}
					}
				}
			});

			//Wait until all Promises are resolved to build the body Data for the socketFetcher
			Promise.all(frontEndPromises).then(fieldData => {
				//Look for error in the fields Processing: 
				let errors = fieldData.filter(function(field) {
					return !!field.error;
				});
				//If any error, send an Interface message and reject the entire Save process
				if(errors.length){
					errors.forEach(function(errorField){
						//Notify there has been an error saving a Field: 
						try {
							let error = errorField.error && errorField.error.message ? errorField.error.message : errorField.error.toString();
							//Notify there has been an error saving a Field: 
							InterfaceActions.notification({ 'level': 'error', 'message': error});
						} catch(err) {
							InterfaceActions.notification({ 'level': 'error', 'message': 'Unknown error saving field. Check your console for more information.'});;
							console.error('errorField', errorField);
						}
					});
					reject();
				} else { //If no errors, proceed to save
					// Build the content of the requestFields object with all the fields to Update
					fieldData.forEach(field => {
						//Build the body 
						if(field.fieldSchemaName) {
							requestFields[field.fieldSchemaName] ={
								fieldSchemaName: field.fieldSchemaName,
								fieldType: field.fieldType,
								fieldId: field.fieldId,
								value: field.value
							};
						}
					});

					// If we have any fields to send...
					if(Object.keys(requestFields).length) {
						let bodyData = JSON.stringify({
							currentContextObj: contextObj,
							tableSchemaName: record.tableSchemaName,
							recordId: record.recordId,
							installationId: contextObj.get('installationId'),
							fields: requestFields
						});
						socketFetcher('gw/recordEdit-v3', bodyData).then(function (data) {
							if (data.responseCode === 200) {
								let recordLoaded = data.response && data.response[record.tableSchemaName] ?
									data.response :
									{
										[record.tableSchemaName]: {
											[record.recordId]: record.fields
										}
									};

								// Add the dynamic selection fields back in to avoid them being cleaned incorrectly (fix for 4336)
								Object.assign(recordLoaded[record.tableSchemaName][record.recordId], relationshipFields);
								// Force the updated values as "clean" in the Record Store (second param = true)
								RecordActions.onDataLoaded(recordLoaded, true);
								resolve();
							} else if(data.responseCode === 403) {
								reject(new Error('Unauthorized'));
							} else {
								let response = data.response, 
								responseCode = data.responseCode,
								records = response[record.tableSchemaName];
								
								if(!records) {
									if(data.error) {
										let err = new Error(data.error);
										err.httpCode = data.responseCode;
										return reject(err);
									} else if (data.response) {
										let err = new Error(data.response);
										err.httpCode = data.responseCode;
										return reject(err);
									} {
										let err = new Error(data.responseCode + ' response received from record edit service, but no error provided');
										err.httpCode = data.responseCode;
										return reject(err);
									}
								}
								Object.keys(records).forEach(recordId => {
									let fields = records[recordId];
									Object.keys(fields).forEach(fieldKey => {
										let fieldObj = fields[fieldKey]; 
										let uiMessage = fieldObj && fieldObj.uiMessage ? fieldObj.uiMessage : fieldObj;
										console.error('Error ' + responseCode + ': ' + uiMessage);
									});
								});
								
								reject(new Error(data.response));
							}
						}).catch(function(error) {
							reject(error.message);
						});
					} else if (relationshipFields && Object.keys(relationshipFields).length) {
						// If only relationship fields were saved, make sure they don't get cleaned inappropriately
						let recordLoaded = {
							[record.tableSchemaName]: {
								[record.recordId]: relationshipFields
							}
						}
						RecordActions.onDataLoaded(recordLoaded, true);
						return resolve();
					} else {
						return resolve();
					}
				}
			});
		});
	},

	/**
	 * Gets the counts of records on all tables from the API GW
	 * @returns Promise
	 */
	getRecordCounts() {
		return socketFetcher('gw/get-table-counts-v1', JSON.stringify({
			'tables': TableStore.getAllArray(),
			requestInfo: {}
		}))
		.then(function (data) {
			if(200 <= data.responseCode && data.responseCode < 300) {
				// If a 200 response is received
				return data.response;
			} else if(data.responseCode === 404) {
				return {};
			} else {
				console.warn('Error getting record counts. Response received was', data);
				throw new Error(data.response);
			}
		})
		.then(tableCounts => {
			// Dispatch
			RecordActions.updateTableCounts(tableCounts);
		})
		.catch(error => {
			RecordActions.onError(error);
		});
	}
};