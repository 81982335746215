import AppDispatcher from '../dispatcher/app-dispatcher';
import {RecordConstants} from '../constants/record-constants';
import Immutable from 'immutable';
// import UIUtils from '../utils/ui-utils'; 

const RecordActions = {
	/**
     * Reset the data in the record store.
     * 
     */
	onDataReset() {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RecordConstants.RECORDS_RESET,
		}));
	},
	
    /**
     * Loads record(s) into the record store.
	 * The object should be Object.<tableSchemaName, Object.<recordId, Object.<fieldId, any>>>>
     * 
     * @param {Object} data
     * @param {boolean} forceClean Force accepting the data that comes in as a "clean" record.
     */
	onDataLoaded(data, forceClean, correlationId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RecordConstants.RECORDS_LOADED,
			records: data,
			forceClean: forceClean,
			correlationId: correlationId
		}));
	},

	/**
	 * Adds a temporary new record to the record store.
	 * Used by add pages to store record data until
	 * the record is added for real.
	 * 
	 * @param {Object} data
	 */
	createNewRecords(data) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RecordConstants.NEW_RECORDS_CREATED,
			records: data
		}));
	},

	/**
	 * Update a Single Record into the Record Store
	 * 
	 * @param {string} tableSchemaName
	 * @param {string} recordId
	 * @param {Object} values
	 * @param {boolean} forceClean - it accepts updates to the record in the record store, and does NOT mark them as dirty
	 */
	updateRecord(tableSchemaName, recordId, values, forceClean) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RecordConstants.RECORD_UPDATE,
			tableSchemaName: tableSchemaName,
			recordId: recordId,
			values: values,
			forceClean: forceClean
		}));
	},

	/**
	 * Updates the counts of records per table.
	 * @param {object} tableCounts Key/value pair of TSNs and record counts
	 */
	updateTableCounts(tableCounts) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RecordConstants.GET_TABLE_COUNT,
			tableCounts: tableCounts
		}));
	},
	/**
	 * Loads an Error
	 * 
	 * @param {Object} data
	 */
	onError(data) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RecordConstants.RECORDS_LOADED_ERROR,
			error: data
		}));
	}
};

export default RecordActions;