import InterfaceActions from '../actions/interface-actions';
import PatternStore from '../stores/pattern-store';
import NLPBuilderDictionariesStore from '../stores/nlp-builder-dictionaries-store';
import AssistantSearchUtils from '../utils/assistant-search-utils';
// Used in eval
// eslint-disable-next-line
var AssistantFields = require('../utils/assistant-fields').default;
// eslint-disable-next-line
var FieldUtils = require('../utils/field-utils').default;
// eslint-disable-next-line
var PageUtils = require('../utils/page-utils').default;
// eslint-disable-next-line
let co = require('co');
// eslint-disable-next-line
let QueryUtils = require('../utils/query-utils').default;

export default {
	/**
	 * Get Create Search Results for Pages
	 * 
	 * @param {string} recordId 
	 * @param {object} inputParsed 
	 * @returns - Search Results
	 */
	createResults(recordId, inputParsed){
		let searchResults = [];
		//Get the Possible Form
		let createPatterns = NLPBuilderDictionariesStore.getPatternsByKeyAndBlockType('operation', 'create', 'form');
			
		// Turn them into Search Results
		searchResults = AssistantSearchUtils.getCreateFormResults(createPatterns, recordId, inputParsed, 'page');
		return searchResults;
	},
	/**
	 * Process a Field pattern as "Create"
	 * 
	 * @param {object} searchResult 
	 */
	processCreate(searchResult) {
		// setup object for Form Blockly Code to run against.
		// eslint-disable-next-line
		let assistantVariables = {
			parentType: searchResult.currentParentInfo.componentType,    				// String, either 'page' or 'field', idenfies when you should call PageUtils vs. FieldUtils for attaching a child.
			parentRecordId: searchResult.currentParentInfo.componentId,  			// String - UUID of the Page, or Field that is your parent.
			parentNextYPosition: searchResult.parentNextYPosition,  // Integer - the Parent's Next Y Position.
			table: searchResult.currentTableInfo.tableSchemaName,  					// String - table Schema Name of the table to assign for everything new that's generated.
			patternId: searchResult.methodInfo.patternId  						// String - The pattern ID of this pattern, use this for calls to processCreate when making new Fields, so their histories can be set appropriately.
		}
		// Lookup Form Blockly Code
		let patternObj = searchResult.methodInfo && searchResult.methodInfo.patternId ? PatternStore.get(searchResult.methodInfo.patternId) : {};
		let patternJS = JSON.parse(patternObj.processing);
		//Notify it is being created
		InterfaceActions.notification({ 'level': 'success', 'message': 'Creating a new form...' });

		let code = [];
		try {
			code.push('co(function* () {');
			code.push(patternJS.js);
			code.push('}).catch(function(error) {');
			code.push('console.error(error.message ? error.message : error);');
			code.push('});');
			code = code.join('\n');  // Blank line between each section.

			// If our browser does not support generators (IE11.. grumble.. ) then babel the code.
			if(!window.supportsGenerators) {
				/*global Babel*/
				code = Babel.transform(code,{ presets: ['es2015','react'] }).code;
			}

			// eslint-disable-next-line
			eval(code);
		} catch (e) {
			console.warn('Evaluation of pattern code failed. Code was', code.join('\n'));
		}
		// eslint-disable-next-line
		// eval(patternJS.js);
	},
	
};
