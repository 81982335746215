import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { TableActions } from '../../../actions';
import { AdminSettingsStore, FieldStore, TableStore } from '../../../stores';
// import { ObjectUtils } from '../../../utils';

// Components;
import FieldTypeSettingsUI from '../../fieldtype-settings-ui.react'

/**
 * Right panel displaying Table settings
 */
class TableSetting extends Component {
	constructor(props) {
		super(props)
		this._onSettingChange = this._onSettingChange.bind(this);
		this._onKeyDown = this._onKeyDown.bind(this);
	}
	
	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
	 return [ AdminSettingsStore, FieldStore, TableStore ]; 
	}

	/**
	 * Calculate the current state of the component
	 * @static
	 *
	 * @param  {Object} prevState Previous State
	 * @param  {Object} props     Previous Props
	 * @return {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId =  AdminSettingsStore.getRecordId();
		let settingRecordId = AdminSettingsStore.getSettingRecordId();
		let settingObj = FieldStore.get(settingRecordId);
		let value = '';
		let isNew = false;

		// Pull the value from the MD Store
		if(settingObj){
			let settingSchemaName = settingObj.fieldSchemaName;

			let tableObj = TableStore.get(recordId);
			if(tableObj) {
				value = tableObj[settingSchemaName];
				isNew = tableObj['new'];
			}

			// Audit Field Selector
			if(settingRecordId === '64dc7521-b93c-4ecd-831d-4dab540e924b') {
				settingObj.selectFromTableSchemaName = tableObj.tableSchemaName;
			}
		}

		return {
			settingObj: settingObj,
			settingRecordId: settingRecordId,
			recordId: recordId,
			value: value,
			isNew: isNew
		};
	}

	/**
	 * Listen to key down events for a control/open apple S.. and save.
	 * 
	 * @todo Bailed out on this functionality because the save method is one component higher then this component and didn't
	 * have time to resolve.  But, this method runs when someone hits Control-S in the Appearance Settings area.
	 */
	_onKeyDown(event) {
		// Check if keys pressed are ctrl+s
		if(event.ctrlKey && event.key === 's') {
			console.warn('Ctrl-S to save not yet implemented.');
			event.preventDefault();
		}
		// when on MAC
		if(event.metaKey && event.key === 's') {
			console.warn('Ctrl-S to save not yet implemented.');
			event.preventDefault();
		}
	}
	
	/**
	 * Save the value as a value is entered
	 * @param {Object} event
	 */
	_onSettingChange(event) {
		let { recordId, settingObj } = this.state;
		let settingSchemaName = settingObj.fieldSchemaName;
		let value = event.target.value;
		let tableObj = TableStore.get(recordId);

		// Update the Table Object with this setting, at its new value
		tableObj[settingSchemaName] = value;

		// Push the update Table Object into the store.
		TableActions.pushToStore(recordId, tableObj);
	}

	/**
	 * Render the component
	 * @return JSX
	 */
	render() {
		let {recordId, value, settingObj, isNew } = this.state;

		if (!settingObj || !settingObj.fieldSchemaName) {
			return (<div className="select-setting">
						<div className="select-setting-text-wrap">
							Select a Setting to configure on the left.
						</div>
					</div>);
		}

		let settingSchemaName = settingObj.fieldSchemaName;
		let settingFieldType = settingObj.fieldType;
		let descriptiveText = settingObj.descriptiveText;
		let label = settingObj.fieldLabel;

		if(settingSchemaName === 'tableSchemaName' && !isNew) {
			settingObj.editVariant = settingObj.viewVariant;
			descriptiveText = "The name used in the database's schema for this table. Rarely needed, but important. <div><i>This is read only because Technical Names cannot be changed once the Table is made.</i></div>"
		}

		return (<div id="appearance-settings" onKeyDown={this.onKeyDown}>
					<div className="appearance-settings-container mx-3">
						<h3 className='my-1'>
							{label}
						</h3>
						<div className="setting-global mt-0">
							<div className={"setting-wrapper setting-" + settingSchemaName}>
								<FieldTypeSettingsUI
									dataRecordId={recordId} 
									dataTableSchemaName='table'
									recordId={recordId} 
									tableSchemaName='table'
									value={value} 
									setting={settingObj} 
									onChange={this._onSettingChange} 
									fieldType={settingFieldType} 
									label={label}
									skipDescriptiveText={true}
									/>
								{descriptiveText && 
									<small id={'description-' + this.props.renderId} className='form-text text-muted' dangerouslySetInnerHTML={{ __html: descriptiveText || '' }}></small>
								}
							</div>
						</div>
					</div>
				</div>);
	}
}
const container = Container.create(TableSetting, {withProps: true});
export default container;