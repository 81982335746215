import AppDispatcher from '../dispatcher/app-dispatcher'
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import AuthenticationConstants from '../constants/authentication-constants';
import SecretUtils from '../utils/secret-utils';
import ObjectUtils from '../utils/object-utils';

/**
 * Store to contain user authentication
 *
 * @class AuthenticationStore
 * @extends {ReduceStore}
 */
class AuthenticationStore extends ReduceStore {
	/**
	 * getInitialState - initial state for AuthenticationStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		let originalUsername = localStorage.getItem('originalUsername');
		let originalUserId = localStorage.getItem('originalUserId');
		let userId = localStorage.getItem('userId');
		let username = localStorage.getItem('username');
		let signedMdKey = localStorage.getItem('signedMdKey');
		let userSecurityGroups = localStorage.getItem('userSecurityGroups');
		let haveAuthCreds = localStorage.getItem('haveAuthCreds');
		let platformCredentialsEncrypted = localStorage.getItem('platformCredentials');
		let platformCredentials = {};
		if(platformCredentialsEncrypted && platformCredentialsEncrypted.length) {
			let platformCredentialsJSON = SecretUtils.decryptString(platformCredentialsEncrypted);
			platformCredentials = ObjectUtils.getObjFromJSON(platformCredentialsJSON);
		}
		userSecurityGroups = userSecurityGroups ? userSecurityGroups.split(',') : [];

		return Immutable.Map({
			'originalUsername': originalUsername,
			'originalUserId': originalUserId,
			'userId': userId, 
			'username': username,
			'signedMdKey': signedMdKey,
			'userSecurityGroups': userSecurityGroups,
			'haveAuthCreds': haveAuthCreds,
			'userIp': undefined,
			'platformCredentials': platformCredentials
		});
	}
	
	/**
	 * Returns the enableDevTools boolean
	 * 
	 * @returns {boolean} enableDevTools
	 */
	getHasDevTools() {
		return this.getRetailRestriction('enableDevTools');
	}
	
	/**
	 * Returns the enableEngineeringTools boolean
	 * 
	 * @returns {boolean} enableEngineeringTools
	 */
	getHasEngineeringTools() {
		let users = [
			'c5f56a58-2951-49e2-b934-c0ca5f562ca3', // rbeyer@citizendeveloper.com - stage
			'244fbbd1-e834-4d52-81d1-f0c050973334', // ndundas@citizendeveloper.com - stage
			'9b6db261-461a-4db6-83b6-cb7bfa777cae', // dmclain@citizendeveloper.com - stage
			'904e3c5b-5d78-44cb-8481-e8c2346054ae', // bschack@citizendeveloper.com - stage
			'867c6f62-5293-414e-bb6c-0803fc347731', // bbransteitter@citizendeveloper.com - stage
			'49696418-fff2-4c89-a5b6-dbd729df403b', // ablaylock@citizendeveloper.com - stage
			'259c9850-3d92-49a5-9960-7c9f1120fa59', // rbeyer@citizendeveloper.com - prod
			'244fbbd1-e834-4d52-81d1-f0c050973334', // ndundas@citizendeveloper.com - prod
			'62ba772b-3b7d-4a81-b128-cc147524e8d5', // dmclain@citizendeveloper.com - prod
			'0ac985cd-1c2e-45e4-8200-538c9a49d308', // bschack@citizendeveloper.com - prod
			'e19c38f9-9e8f-487c-8670-4e3c16d15776', // bbransteitter@citizendeveloper.com - prod
			'9c708bdf-1c4d-45b3-8307-2389b8bbf7f3', // ablaylock@citizendeveloper.com - prod
			];
		return (users.includes(this.getOriginalUserId()) ||  this.getRetailRestriction('enableEngineeringTools'));
	}

	/**
	 * Returns the maxRecordsPerTable number.  0 means unlimited.
	 * 
	 * @returns {Number} maxRecordsPerTable
	 */
	getMaxRecordsPerTable() {
		return parseInt(this.getRetailRestriction('maxRecordsPerTable'),10)
	}

	/**
	 * Returns the maxTables number.  0 means unlimited.
	 * 
	 * @returns {Number} maxTables
	 */
	getMaxTables() {
		return parseInt(this.getRetailRestriction('maxTables'),10)
	}

	/**
	 * Returns the maxPages number.  0 means unlimited.
	 * 
	 * @returns {Number} maxPages
	 */
	getMaxPages() {
		return parseInt(this.getRetailRestriction('maxPages'),10)
	}

	/**
	 * Returns whether or not the user has access to Rest API Logic
	 * 
	 * @returns {boolean} enableRestAPILogic
	 */
	getHasRestAPILogic() {
		return this.getState().get('platformCredentials').enableRestAPILogic;
	}
	/**
	 * Returns the Plan Name
	 * 
	 * @returns {string} Plan Name
	 */
	getPlanName() {
		return this.getRetailRestriction('planName');
	}

	/**
	 * Gets a Retail Restriction by name.
	 * @param {string} restriction Restriction to get
	 * @returns boolean || number
	 */
	getRetailRestriction(restriction) {
		let restrictions = this.getRetailRestrictions();
		if(restrictions) {
			return restrictions[restriction];
		} else {
			return null;
		}
	}

	/**
	 * Gets all retail restrictions
	 * @returns Object
	 */
	getRetailRestrictions() {
		return this.getState().get('platformCredentials');
	}

	/**
	 * Returns the signedMdKey
	 * 
	 * @returns {string} signedMdKey
	 */
	getSignedMdKey() {
		return this.getState().get('signedMdKey');
	}

	/**
	 * Returns the user Id
	 * 
	 * @returns {string} userId
	 */
	getUserId() {
		return this.getState().get('userId');
	}

	/**
	 * gets the IP
	 * 
	 * @returns {string} unEncodedIp
	 */
	getUserIp() {
		return this.getState().get('userIp');
	}

	/**
	 * Returns the username
	 * 
	 * @returns {string} username
	 */
	getUsername() {
		return this.getState().get('username');
	}

	/**
	 * Returns the original user Id
	 * 
	 * @returns {string} originalUserId
	 */
	getOriginalUserId() {
		return this.getState().get('originalUserId');
	}

	/**
	 * Returns the original username
	 * 
	 * @returns {string} originalUsername
	 */
	getOriginalUsername() {
		return this.getState().get('originalUsername');
	}

	/**
	 * gets the user's security groups
	 * 
	 * @returns {array} userSecurityGroups
	 */
	getUserSecurityGroups() {
		let toReturn = this.getState().get('userSecurityGroups');
		toReturn = toReturn && toReturn.toJS ? toReturn.toJS() : toReturn;
		if(typeof toReturn === 'string') {
			toReturn = toReturn.split(',');
		}
		return toReturn;
	}

	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 */
	reduce(state, action) {
		let type = action.get('type');
		let context;

		switch (type) {
			case AuthenticationConstants.AUTH_LOGIN:
				let userSecurityGroups = action.get('userSecurityGroups');

				let platformCredentials = {
					enableDevTools: !!action.get('hasDevTools'),
					enableEngineeringTools: !!action.get('enableEngineeringTools'),
					planName: action.get('planName'),
					maxRecordsPerTable: (
						parseInt(action.get('maxRecordsPerTable'),10) 
						? parseInt(action.get('maxRecordsPerTable'),10) 
						: 0
					),
					maxTables: (
						parseInt(action.get('maxTables'),10) 
						? parseInt(action.get('maxTables'),10) 
						: 0
					),
					maxPages: (
						parseInt(action.get('maxPages'),10) 
						? parseInt(action.get('maxPages'),10) 
						: 0
					),
					enableIncomingAPI: !!action.get('enableIncomingAPI'),
					enableRestAPILogic: !!action.get('enableRestAPILogic'),
					enableScheduledLogic: !!action.get('enableScheduledLogic'),
					enableVisibility: !!action.get('enableVisibility'),
					enableSecurityGroups: !!action.get('enableSecurityGroups'),
					enableAuditLogging: !!action.get('enableAuditLogging'),
					enableCompliance: !!action.get('enableCompliance'),
					enableSecurity: !!action.get('enableSecurity'),
					enableStripe: !!action.get('enableStripe'),
					enableChangeManagement: !!action.get('enableChangeManagement')
				};
				
				let originalUsername = localStorage.getItem('originalUsername');
				if(!originalUsername || !originalUsername.length) {
					originalUsername = action.get('username');
					localStorage.setItem('originalUsername', action.get('username'));
				}
				let originalUserId = localStorage.getItem('originalUserId');
				if(!originalUserId || !originalUserId.length) {
					originalUserId = action.get('userId');
					localStorage.setItem('originalUserId', action.get('userId'));
				}
				localStorage.setItem('authenticated', true);
				localStorage.setItem('userId', action.get('userId'));
				localStorage.setItem('username', action.get('username'));
				localStorage.setItem('signedMdKey', action.get('signedMdKey'));
				localStorage.setItem('userSecurityGroups', userSecurityGroups && userSecurityGroups.toJS ? userSecurityGroups.toJS() : userSecurityGroups);
				localStorage.setItem('haveAuthCreds', true);
				localStorage.setItem('platformCredentials', 
					SecretUtils.encryptString(JSON.stringify(platformCredentials)));
				
				context = state
					.set('originalUserId', originalUserId)
					.set('originalUsername', originalUsername)
					.set('userId', action.get('userId'))
					.set('userId', action.get('userId'))
					.set('username', action.get('username'))
					.set('signedMdKey', action.get('signedMdKey'))
					.set('userSecurityGroups', userSecurityGroups ? userSecurityGroups.join(',') : '')
					.set('haveAuthCreds', true)
					.set('platformCredentials', platformCredentials);
				break;
			case AuthenticationConstants.AUTH_LOGOUT:
				localStorage.removeItem('authenticated');
				localStorage.removeItem('userId');
				localStorage.removeItem('username');
				localStorage.removeItem('originalUserId');
				localStorage.removeItem('originalUsername');
				localStorage.removeItem('signedMdKey');
				localStorage.removeItem('userSecurityGroups');
				localStorage.removeItem('haveAuthCreds');
				localStorage.removeItem('platformCredentials');
				context = state
					.set('userId', null)
					.set('username', null)
					.set('originalUserId', null)
					.set('originalUsername', null)
					.set('signedMdKey', null)
					.set('haveAuthCreds', null)
					.set('platformCredentials', null)
					.set([]);
				break;
			case AuthenticationConstants.AUTH_CREDS_SET:
				let haveAuthCreds = action.get('haveAuthCreds');
				localStorage.setItem('haveAuthCreds', haveAuthCreds);
				context = state.set('haveAuthCreds', haveAuthCreds);
				break;
			case AuthenticationConstants.AUTH_ERROR:
				context = state;
				break;
			case AuthenticationConstants.SET_USER_IP:
				let userIp =  action.get('userIp');
				context = state.set('userIp', userIp);
				break;
			default:
				context = state;
				break;
		}
		return context;
	}
}

const instance = new AuthenticationStore(AppDispatcher);
export default instance;