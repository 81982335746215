import keymirror from 'keymirror';

export const RecordConstants = keymirror({
	'GET_TABLE_COUNT': null,
	'RECORD_UPDATE': null,
	'RECORD_SAVE': null,
	'RECORDS_LOADED': null,
	'RECORDS_LOADED_ERROR': null,
	'RECORDS_RESET': null,
	'SET_SELECTED_RECORD_SET': null,
	'NEW_RECORDS_CREATED': null
});