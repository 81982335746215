import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { map } from 'lodash';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';
import MapActions from '../../../actions/map-actions';

// Consants
import GroupFilters from '../../../constants/group-filters';
import Help from '../../../constants/help-upgrade-constants';

//Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import ContextStore from '../../../stores/context-store';
import MapStore from '../../../stores/map-store';
import PageStore from '../../../stores/page-store';
import TableStore from '../../../stores/table-store';

// Utils
import FieldUtils from '../../../utils/field-utils';
import MapUtils from '../../../utils/map-utils';
import PageUtils from '../../../utils/page-utils';
import UIUtils from '../../../utils/ui-utils';

/**
 * Listing of Pages for Presentation Dashboard
 *
 * @class PagesPresentationDashboardTabContainer
 * @extends {Component}
 */
class PagesPresentationDashboardTabContainer extends Component {
  /**
   * Creates an instance of PagesPresentationDashboardTabContainer
   */
  constructor(props) {
    super(props);

    this._renderGroupBy = this._renderGroupBy.bind(this);
    this._onAddPage = this._onAddPage.bind(this);
    this._onChangeSearch = this._onChangeSearch.bind(this);
    this._onEditPage = this._onEditPage.bind(this);
    this._onLoadPage = this._onLoadPage.bind(this);
    this._onToggleShow = this._onToggleShow.bind(this);
    this._onToggleAllOpen = this._onToggleAllOpen.bind(this);
    this._onToggleAllClosed = this._onToggleAllClosed.bind(this);
  }

  /**
  * Loads the Stores to watch
  *
  * @static
  * @returns {Array of Object}
  */
  static getStores() {
    return [ MapStore, PageStore, ContextStore ];
  }

  /**
   * Returns the current State
   *
   * @static
   * @returns {Object}
   */
  static calculateState(prevState) {
    let groupBy = (MapStore.getGroupBy() ? MapStore.getGroupBy() : 'type');
		let pages = PageStore.getAllArray();
		let search = MapStore.getSearch();
		
		if (prevState
			&& prevState.pages === pages
			&& prevState.groupBy === groupBy
			&& prevState.search === search) {
			return false;
		}

    return {
      pages: pages,
      groupBy: groupBy,
      search: search,
      groupedPages: this._performGroupBy(pages,GroupFilters.TABLE_NAME, search,'1'),
      groupToggleStatus: MapStore.getGroupToggleStatusGroupBy(AdminSettingsStore.getActiveDashboard(), 'pages'),
      currentPage: ContextStore.getPageId()
    }
  }

  /**
   * Using the group by set in the UI, group the pages and return
   * an object with the groups as keys and functions array as value.
   * @param {Array} pages Pages to group
   * @param {String} groupBy How to group the pages
   * @param {String} search What search to apply, if any.
   * @return Object
   * 
   * @Todo Implement
   */
  static _performGroupBy(pages, groupBy, search) {
    let groups = {};

    if (!pages) {
      return groups;
    }
    
    let currentPage = ContextStore.getPageId();

    // Filter out the pages that dont have the search term, and aren't current
    pages = pages.filter(page => {
      if (page.recordId === currentPage) {
        return true;
      } else if (search && search.length > 0) {
        let filterableString = (page.name) ? page.name.toLowerCase() : '';
        return (filterableString.toLowerCase().includes(search.toLowerCase()));
      } else {
        return true;
      }
      // Sort the pages alphabetically, with [ at the end
    });

    pages.map(page => {
      if(page.recordId === currentPage) {
        page.youAreHere = true;
      }
      return page;
    })

    // Prepare the final return
    let returnGroups = MapUtils.groupBy(pages, groupBy, 'name', 
      '[ No Page Name found ]');

    // If we have a search...
    if(search.length > 0) {
      let groupToggleStatus = { };
      Object.keys(returnGroups).forEach(group => {
        groupToggleStatus[group] = true;
      })
      // @TODO REMOVE!
      /*
      In order to remove this, all of the performGroupBy stuff needs to be moved
      to the MapSTORE - and done in response to the OnDashboardChange 
      [ which should be moved there from the AdminSettingsStore ],
      onSearchChange, and onGroupByChange Actions. If we did this, then these
      components would become very `dumb` rendering components - as they are 
      meant to be.
       */
      setTimeout(() => {
        MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'pages', groupToggleStatus);
      },0);
    }

    return returnGroups;
  }

  /**
   * Render method
   */
  render() {
    let { groupedPages, search } = this.state;

    let noSearchFound = null;
		if(Object.keys(groupedPages).length === 0 && search.length) {
			noSearchFound = <li className="no-search-found" style={{ color: 'white'}}><h4>No Results for '{search}' found.</h4></li>
		}

    return (
      <div id="data-map__content" className="map">
        <div className="cd-search-container">
			<input className="form-control select-group-by" placeholder="Search" type="text" value={search} onChange={this._onChangeSearch} />
		</div>
		<div className="section-header" key="expand-collapse-tools">
			<div className="d-flex justify-content-between align-items-center">
				<div className="d-flex">
					<div title="Expand All" onClick={this._onToggleAllOpen}>
						<i className="fa fa-plus"></i>
					</div>
					<div>|</div>
					<div title="Collapse All" onClick={this._onToggleAllClosed}>
						<i className="fa fa-minus"></i>
					</div>
				</div>
				<div className="d-flex align-items-center">
					<h5 className="bold">Pages</h5>
					<div title="Pages Help" className="info-icon ml-2" onClick={() => { UIUtils.onHelpClick(Help.HELP_DASHBOARD_PRESENTATION_PAGES); }}>
						<i className="fa fa-info-circle mr-1"></i> | 
					</div>
					<div title="Create Page" data-toggle="modal" onClick={this._onAddPage.bind(this,'')} >
						<i className="fa fa-plus-circle ml-1"></i>
					</div>
				</div>
			</div>
		</div>
        <div className="list-content-wrapper" key="pages">
          <ol>
            {map(groupedPages, this._renderGroupBy)}
          	{ noSearchFound }
          </ol>
        </div>
      </div>
    );
  }

  /**
   * private Render on group of pages - from the list of ALL pages.
   * @param {Array} pages All of the pages
   * @param {string} groupBy The value to show these pages as grouped by - in this case, the table name.
   */
  _renderGroupBy(pages, groupBy) {
    let { groupToggleStatus, currentPage } = this.state;

    // Figure out if this group contains the current page.
    let pageThatIsCurrent = pages.filter(page => {
      return page.recordId === currentPage;
    })

    // Basic JSX for the youAreHereIndicator
    let youAreHereIndicator = (<span>
		<i className="fa fa-circle fa-1 selection-marker" aria-hidden="true" />
		<span className="sr-only">(Current)</span>
	  </span>);
  
    // This is a group by table - so lets get the TSN
    let tableSchemaName = '';
    let icon = '';
    if (pages.length) {
      tableSchemaName = pages[0].tableSchemaName;
      icon = TableStore.getByTableSchemaName(tableSchemaName).icon;
    } else {
      return null;
    }

    let pageList = [];
    let iconJSX = <img height="10" width="10" className="mr-2" src={FieldUtils.getFieldTypeIconByFieldTypeId('page')} alt="" />

    // Generate the LI list for all pages..
    pages.forEach((page, index) => {
      let pageName = page.name;
      if(pageName === 'null') {
        pageName = '[ No Page Name found ]';
      }

      pageList.push(
        <li key={index} className="table-name-item">
          <div className="d-flex justify-content-between">
			<h5 className="page-load-link" data-toggle="modal" data-target="#page-dialog"
				onClick={this._onLoadPage.bind(this, page.recordId, page.tableSchemaName)}>
        {iconJSX}
				{pageName}
			</h5>
			<div className="d-flex">
				{(page.recordId === currentPage ? youAreHereIndicator : null)}
				<span onClick={this._onEditPage.bind(this, page.recordId)} title="Update Page">
					<img height="14" width="14" src={ContextStore.getUrlMedia() + "/icon-appearance.svg"} alt="" />
				</span>
			</div>
          </div>
        </li>
      );
    }); // End loop over all the pages.

    if (pageList.length) {
      // Add a Group of pages for this Table.
      let show = groupToggleStatus[groupBy];

      // If this is hte group that has the current page in it, and there is NO 
      // indicator for if the user wants this group open or not... open it.
      if (pageThatIsCurrent.length && show === undefined) {
        show = true;
      }
      return (
        <li key={groupBy} className="role-group-item">
          <div className="role-group-name d-flex" onClick={this._onToggleShow.bind(this, groupBy, !show)}>
			<div className="mr-1">
				{show 
					? <i title="Collapse" className="fa fa-minus"></i>
					: <i title="Expand" className="fa fa-plus"></i>
				}
			</div>
			<div className="d-flex justify-content-between w-100">
				<div className="d-flex align-items-center">
					{!icon ? null :
						<span className={"fa fa-" + icon}></span>
					}
					<h4 className="bold ml-1">{groupBy}</h4>
				</div>
				<div className="d-flex">
					{(pageThatIsCurrent.length ? youAreHereIndicator : null)}
					<h4 className="bold">({pageList.length})</h4>
					<div title="Create Page" onClick={this._onAddPage.bind(this, tableSchemaName)}>
						<i style={{ color: 'var(--theme-text-1)' }} className="fa fa-plus-circle ml-1"></i>
					</div>
				</div>
			</div>
          </div>
          <ol id={groupBy} className={'collapse pl-4 pt-2 pr-0 ' + (show ? 'show ' : '') + 'groupby-list'}>
            {pageList}
          </ol>
        </li>);
    } else {
      return null;
    }
  } // end _renderGroupBy

  /**
   * Handle adding a page.
   */
  _onAddPage(tableSchemaName) {
    PageUtils.addNewPage(tableSchemaName);
    // Unhide the settings, always
		AdminSettingsActions.onSettingsListHideChange(false);
  }

	/**
	 * Handles typing into the search box.
	 * @param {object} event Change event
	 */
	_onChangeSearch(event) {
		let search = event.target.value;
		MapActions.search(search);
	}

  /**
   * Edit this page in the Details Pane
   * @param {string} pageId 
   * @param {string} tableSchemaName 
   */
  _onEditPage(pageId) {
    UIUtils.openSettingsPanel('appearance',
      pageId, 'page',
      undefined, undefined
    );
    // Unhide the settings, always
		AdminSettingsActions.onSettingsListHideChange(false);
  }

  /**
   * Change the current page to be this page.
   * @param {string} pageId 
   * @param {string} tableSchemaName 
   */
  _onLoadPage(pageId, tableSchemaName) {
    PageUtils.loadPage(pageId, tableSchemaName);
  }

  /**
   * Toggle one group.
   */
  _onToggleShow(groupLabel, show) {
    MapActions.groupToggle(AdminSettingsStore.getActiveDashboard(), 'pages', groupLabel, show);
  }

  /**
   * Open all of the groups
   */
  _onToggleAllOpen() {
    let { groupedPages, groupToggleStatus } = this.state;
    Object.keys(groupedPages).forEach(group => {
      groupToggleStatus[group] = true;
    })
    MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'pages', groupToggleStatus);
  }

  /**
   * Close all of the groups
   */
  _onToggleAllClosed() {
    let { groupToggleStatus } = this.state;
    Object.keys(groupToggleStatus).forEach(key => {
      groupToggleStatus[key] = false;
    })
    MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'pages', groupToggleStatus);
  }
}

const container = Container.create(PagesPresentationDashboardTabContainer);
export default container;
