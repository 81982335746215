import NLPBuilderDictionariesStore from '../stores/nlp-builder-dictionaries-store';
import AssistantResultsStore from '../stores/assistant-results-store';
import FieldTypeStore from '../stores/field-type-store';
import FieldStore from '../stores/field-store';
import TableStore from '../stores/table-store';
import RelationshipStore from '../stores/relationship-store';
import FieldSettingsStore from '../stores/field-settings-store';
import PageStore from '../stores/page-store';
import FieldUtils from './field-utils';
import PageUtils from './page-utils';
import ObjectUtils from './object-utils';
import FieldComponents from './field-components';
import NlpProcessorObj from './nlp-processor-obj';
import GoogleNLPApi from '../apis/google-nlp-api';

var toExport = {
	/**
	 * Determine if a value has a classification
	 * 
	 * @param {string} value
	 * @returns {string} The classification of the value
	 */
	classifyValue(value) {

		if (value === undefined || value === null) {
			return null;
		}

		// Check for Number - using base radix 10 for decimal on parseInt
		// eslint-disable-next-line
		if (value == Number.parseInt(value, 10) || value == Number.parseFloat(value)) {
			return 'number';
		}

		// Check for Boolean
		if (value === 'yes' || value === 'true' || value === 'on' ||
			value === 'no' || value === 'false' || value === 'off') {
			return 'boolean';
		}

		// Check for Color 
		let colorRegex = /^#([0-9a-f]{3}){1,2}$/i,
			squishedValue = value.toLowerCase().replace(' ', '').replace('_', '').replace('-', ''),
			colorObj = _getColorObj();

		if (colorRegex.test(value) || colorObj[squishedValue]) {
			return 'color';
		}

		return 'string';
	},


	/**
	 * Perform NLP Search for Tables, optionally including the table from context in the results automatically.
	 * 
	 * @param {array} inputArray 
	 * @param {string} tableIdFromContext 
	 * @returns array
	 */
	getTableMatches(inputArray, tableIdFromContext) {
		let dictionary = NLPBuilderDictionariesStore.getTables();
		let matches = _metadataMatches(dictionary, inputArray);
		//Handle the Tables from our parent
		if (tableIdFromContext) {
			return _includeMetadataFromContext(tableIdFromContext, matches, dictionary);
		} else {
			return matches;
		}
	},

	/**
	 * Perform NLP Search for Fields, optionally including the field from context in the results automatically.
	 * 
	 * @param {array} inputArray 
	 * @param {string} fieldIdFromContext 
	 * @returns array
	 */
	getFieldMatches(inputArray, fieldIdFromContext) {
		let dictionary = NLPBuilderDictionariesStore.getFields();
		let matches = _metadataMatches(dictionary, inputArray);
		//Handle the Field from the pin we clicked on
		if (fieldIdFromContext) {
			return _includeMetadataFromContext(fieldIdFromContext, matches, dictionary);
		} else {
			return matches;
		}
	},

	/**
	 * Perform NLP Search for Field Types, optionally including the field type from context in the results automatically.
	 * 
	 * @param {array} inputArray 
	 * @param {string} fieldIdFromContext 
	 * @returns array
	 */
	getFieldTypeMatches(inputArray, fieldTypeFromContext) {
		let dictionary = NLPBuilderDictionariesStore.getFieldTypes(),
			matches = _metadataMatches(dictionary, inputArray);

		if (fieldTypeFromContext) {
			return _includeMetadataFromContext(fieldTypeFromContext, matches, dictionary);
		} else {
			return matches;
		}
	},

	/**
	 * Perform NLP Search for Field Types, optionally including the field type from context in the results automatically.
	 * 
	 * @param {array} inputArray 
	 * @returns {array}
	 */
	getFieldSettingMatches(inputArray) {
		return _metadataMatches(NLPBuilderDictionariesStore.getFieldTypeSettings(), inputArray);
	},

	/**
	 * Process raw NLP Input into an object of inputArray, quotedValues and operation.
	 * 
	 * @param {string} input 
	 * @returns  {object}
	 */
	processInput(input) {
		//Grab the Object containing the processed Input 
		let inputToProcess = _wordsToEvaluate(input);

		//Get the words we care to match to dicionaries 
		let inputArray = inputToProcess.userInputStripped;

		//Get the quoted Values from the input, to pass them to nlp-processor 
		let quotedValues = inputToProcess.quotedValues;

		return {
			inputArray: inputArray,
			inputString: input,
			quotedValues: quotedValues,
			operation: this.getOperation(inputArray)
		};
	},

	/**
	 * Process raw NLP Input into an appropriate pattern object and return a Promise which resolves with that value.
	 * 
	 * @param {string} input 
	 * @returns {Promise}
	 */
	processInputNlp(input) {

		var quotedValuesObjMain = {}, connectionIdMain;
		return GoogleNLPApi.getResults(input)
			.then(({ response: { syntax: [data], quotedValuesObj, connectionId }, responseCode }) => {
				if (AssistantResultsStore.getMostRecentConnection() !== connectionId) {
					return null;
				}
				quotedValuesObjMain = quotedValuesObj;
				connectionIdMain = connectionId;
				var testProcessor = new NlpProcessorObj(data, quotedValuesObj);
				// testProcessor.log();
				return testProcessor.getResults();
			}).then((results) => {
				if (!results) {
					return null;
				}
				var patterns = _formatPatternsNlp(results, quotedValuesObjMain);
				return { patterns, connectionId: connectionIdMain };
			});
	},

	/**
	 * Takes in a string or an array of inputs and attempts to match the most likely operation candidates.
	 * 
	 * @param {array} inputArray 
	 * @returns {object} An object whose keys are candidate actions
	 */
	getOperation(inputArray) {

		if (!inputArray) {
			return null;
		}

		let synonymDict = [
			{
				primary: 'Attach',
				synArray: ['attach', 'join', 'link', 'connect', 'add', 'associate'],
			},
			{
				primary: 'Create',
				synArray: ['create', 'add', 'build', 'start', 'generate', 'new', 'put', 'make', 'store'],
			},
			{
				primary: 'Delete',
				synArray: ['remove', 'release', 'cancel', 'erase', 'destroy', 'recycle', 'drop', 'eliminate', 'delete', 'trash', 'wipe', 'discard', 'withdraw'],
			},
			{
				primary: 'Update',
				synArray: ['make', 'adjust', 'apply', 'change', 'convert', 'disable', 'edit', 'enable', 'fix', 'include', 'modify', 'redo', 'refine', 'revise', 'set', 'store', 'switch', 'transform', 'turn', 'tweak', 'undo', 'update'],
			},
			{
				primary: 'Detach',
				synArray: ['drop', 'recycle', 'cancel', 'trash', 'eliminate', 'wipe', 'remove', 'detach', 'erase', 'withdraw', 'hide']
			}
		];

		//Return only Operations that match the Input 
		let operations = {}, len1 = synonymDict.length, len2 = inputArray.length;

		// Different handling based on inputArray
		if (Array.isArray(inputArray)) {
			for (let i = 0; i < len1; i++) {
				for (let j = 0; j < len2; j++) {
					if (synonymDict[i].synArray.includes(inputArray[j])) {
						operations[synonymDict[i].primary] = synonymDict[i].primary;
					}
				}
			}
		} else {
			var action = inputArray.toLowerCase();
			for (var i = 0; i < len1; i++) {
				if (synonymDict[i].synArray.indexOf(action) > -1) {
					operations[synonymDict[i].primary] = synonymDict[i].primary;
				}
			}
		}
		return operations;
	},

	/**
	 * Takes in a set of operation candidates and attempts to determine from context
	 * what the most likely operation(s) are. (E.g., "make" may match both "add" and "update", but clearly be "add" from context)
	 * 
	 * @param {string} det The determinant of a sentence, used to help identify the operation
	 * @param {object} actions Object whose keys are candidate actions
	 * @returns {object} Object whose keys are equally likely actions
	 */
	guessOperation(det, actions) {
		let theseActions = JSON.parse(JSON.stringify(actions));
		if (Object.keys(actions).length > 1 && det && ['a', 'an'].indexOf(det) > -1) {
			theseActions = { 'Create': 'Create' };
		} else if (actions['Update'] > 1 && det && ['this', 'the'].indexOf(det) > -1) {
			theseActions = { 'Update': 'Update' };
		} else if (Object.keys(actions).length > 1 && theseActions['Create']) {
			// Otherwise if there are multiple matched actions and there's no "a" or "an" determiner, it probably isn't "Create", so delete that key.
			delete theseActions['Create'];
		}
		return theseActions;
	},

	/**
	 * Takes in a string with keywords and context information and attempts to determine the likely component type
	 * (field, page, etc.) being referenced.
	 * 
	 * @param {string} objectPhrase A string containing keywords to be analyzed
	 * @param {object} contextComponent An object containing information regarding the currently selected component
	 * @param {object} parentComponent An object containing information regarding the parent of the currently selected component
	 * @returns {array} Array of objects whose keys include the likely component type, the keywords used to match it, the score,
	 * and any remaining keywords
	 */
	getComponentType(objectPhrase, contextComponent, parentComponent) {
		if (!objectPhrase) {
			return null;
		} else if (!objectPhrase.toLowerCase) {
			console.warn('Invalid value passed into getComponentType. Value was', objectPhrase);
			return null;
		}
		objectPhrase = objectPhrase.toLowerCase();
		let synonymDict = [
			{
				primary: 'field',
				synArray: ['field']
			},
			{
				primary: 'form',
				synArray: ['form']
			},
			{
				primary: 'table',
				synArray: ['table']
			},
			{
				primary: 'page',
				synArray: ['page']
			},
			{
				primary: 'relationship',
				synArray: ['relationship', 'relate']
			}
		];
		let highScore = 0, bestMatches = null;
		// Check to see if they are talking about "this field" or "this page", and return right away.
		if (['this'].indexOf(objectPhrase) > -1) {
			bestMatches = contextComponent ? [{
				componentType: contextComponent.componentType,
				keywordsMatched: objectPhrase,
				remainingKeywords: objectPhrase,
				score: objectPhrase.length
			}] : null;
		} else {
			synonymDict.forEach(synonymObj => {
				synonymObj.synArray.forEach(synonym => {
					let match = _matchKeyword(objectPhrase, synonym);
					if (match.score > highScore) {
						bestMatches = [Object.assign({ componentType: synonymObj.primary }, match)];
						highScore = match.score;
					} else if (match.score && match.score === highScore) {
						bestMatches.push(Object.assign({ componentType: synonymObj.primary }, match));
					}
				});
			});
		}
		return bestMatches;
	},
	/**
	 * Takes in a string with keywords and context information and attempts to determine the likely component subtype
	 * (address field, phone number field, etc.) being referenced.
	 * 
	 * @param {string} objectPhrase A string containing keywords to be analyzed
	 * @param {string} componentType The type of component whose subtypes to find (Optional - all will be searched if not provided)
	 * @param {object} contextComponent An object containing information regarding the currently selected component
	 * @param {object} parentComponent An object containing information regarding the parent of the currently selected component
	 * @returns {array} Array of objects whose keys include the likely component subtype, its parent component type, the keywords used to match it,
	 * the score, and any remaining keywords
	 */
	getComponentSubtype(objectPhrase, componentType, contextComponent, parentComponent) {
		let highScore = 0, bestMatches = null;
		if (['this'].indexOf(objectPhrase) > -1) {
			let field = FieldStore.get(contextComponent.componentId);
			let fieldTypeObj = field && field.fieldType ? FieldTypeStore.get(field.fieldType) : {};
			let { recordId: fieldType, name: fieldTypeName } = fieldTypeObj;
			bestMatches = contextComponent ? [{
				componentType: contextComponent.componentType,
				componentSubtype: ['field', 'form'].indexOf(contextComponent.componentType) > -1 ? fieldType : contextComponent.componentType,
				componentSubtypeName: fieldTypeName ? fieldTypeName : contextComponent.componentType,
				keywordsMatched: objectPhrase,
				remainingKeywords: '',
				score: objectPhrase.length
			}] : null;
		} else if (['page', 'table', 'relationship'].indexOf(componentType) > -1) {
			// For now, these have no subtypes; their only subtype is the parent component type
			bestMatches = [{
				keywordsMatched: '',
				componentType: componentType,
				componentSubtype: componentType,
				componentSubtypeName: componentType,
				remainingKeywords: objectPhrase
			}];
		} else {
			// We assume for now that unspecified component types are fields, because there's no keywords that could mean otherwise at this stage
			let bareBestMatches = [];
			FieldTypeStore.getAllArray().forEach(fieldType => {
				let synArray = [];
				try {
					synArray = fieldType.searchSynonyms ? JSON.parse(fieldType.searchSynonyms) : [];
				} catch (err) {
					console.warn('Attempted to parse searchSynonyms for fieldType %s and failed. searchSynonyms were', fieldType.recordId, fieldType.searchSynonyms);
				}
				synArray.forEach(synonym => {
					let match = _matchKeyword(objectPhrase, synonym);
					if (match.score > highScore) {
						bareBestMatches = [Object.assign(
							{
								componentType: 'field',
								componentSubtype: fieldType.recordId,
								componentSubtypeName: fieldType.name
							},
							match
						)];
						highScore = match.score;
					} else if (match.score && match.score === highScore) {
						bareBestMatches.push(Object.assign({ componentType: 'field', componentSubtype: fieldType.recordId, componentSubtypeName: fieldType.name }, match));
					}
				});
			});
			bestMatches = bareBestMatches;
		}
		return bestMatches;
	},

	/**
	 * Gets the settings for a target component which match a set of keywords and possible value
	 * @TODO: This is a method meant for implementation in the next phase of the NLP
	 * And is not yet finished.
	 * 
	 * @param {object} targetComponent The component whose settings are potentially being updated
	 * @param {string} objectPhrase The set of keywords being considered
	 * @param {string | number} value The value potentially being set into the setting (Optional)
	 * @returns {array} Array of objects including the candidate setting, the matched keywords, the remaining keywords,
	 * the value being assigned to it, and the score.
	 */
	getSettingMatches(settingInfo) {

		// Some settings use the fieldComponents key on the citDev object to generate their options
		let citDev = {
			fieldComponents: FieldComponents
		};

		let { componentType, componentSubtype: fieldTypeId, parentComponentType, parentComponentId, NLPInput: providedNLPInput, remainingKeywords } = settingInfo;
		let candidateSettingsObj = {};
		if (componentType === 'field') {
			let parentSettings = [];
			if(!parentComponentType && !parentComponentId) {
				parentSettings = FieldUtils.getDefaultSettings();
			} else if (parentComponentType === 'page') {
				parentSettings = PageUtils.getChildSettings();
			} else if (parentComponentId && parentComponentType === 'field') {
				let parentObj = FieldStore.get(parentComponentId);
				let parentFieldType = FieldTypeStore.get(parentObj.fieldType);
				if (parentFieldType.settingsForChildFields) {
				   if (typeof parentFieldType.settingsForChildFields === 'string')
				   {
					   try {
						   parentSettings = JSON.parse(parentFieldType.settingsForChildFields);
					   } catch (error) {
						   console.error(error);
					   }
				   } else {
					   parentSettings = parentFieldType.settingsForChildFields;
				   }
				}
			}

			parentSettings.forEach(setting => {
				candidateSettingsObj[setting.recordId] = true;
			});

			let candidateFieldTypes = fieldTypeId ? [FieldTypeStore.get(fieldTypeId)] : FieldTypeStore.getAllArray();
			candidateFieldTypes.forEach(fieldTypeObj => {
				fieldTypeObj.settings.forEach(setting => {
					candidateSettingsObj[setting.recordId] = true;
				});
			});
		} else if (componentType === 'page') {
			let pageSettings = PageUtils.getPageSettings();
			pageSettings.forEach(setting => {
				candidateSettingsObj[setting.recordId] = true;
			});
		} else {
			console.warn('Attempting to get settings for unsupported componentType', componentType);
		}

		let candidatesForSearching = {};

		let highScore = 0, bestMatches = {};
		Object.keys(candidateSettingsObj).forEach(settingId => {
			providedNLPInput = providedNLPInput.trim();
			let options = [{
				displayName: providedNLPInput,
				synonyms: [providedNLPInput],
				value: providedNLPInput
			}];
			let settingsObj = FieldSettingsStore.getSettings(settingId) || {};
			let settingsFieldObj = FieldStore.get(settingId) || {};
			settingsObj.fieldType = fieldTypeId;
			let settingsFieldTypeObj = FieldTypeStore.get(settingsFieldObj.fieldType) || {};
			let isDiscrete = !!settingsFieldTypeObj.generateDiscreteOptions;
			let generateDiscreteOptions = '';
			if(isDiscrete) {
				let wrappedOptionGeneratorFunction = `generateDiscreteOptions = (function generateDiscreteOptions(fieldSettings, value, citDev) {
					try {
						` + settingsFieldTypeObj.generateDiscreteOptions + `
					} catch(err) {
						console.error('Error when generating discrete options: for fieldType', settingsFieldTypeObj.name, err);
					}
				})`;
				// If our browser does not support generators (IE11.. grumble.. ) then babel the code.
				if(!window.supportsGenerators) {
					/*global Babel*/
					wrappedOptionGeneratorFunction = Babel.transform(wrappedOptionGeneratorFunction,{ presets: ['es2015','react'] }).code;
				}
				//Compile the Wrapped Generator Function
				try {
					//eslint-disable-next-line
					eval(wrappedOptionGeneratorFunction);
					options = generateDiscreteOptions(settingsObj, providedNLPInput, citDev);
				} catch(err) {
					console.error('Error when generating discrete options:', err);
				}
			}
			options = options ? options : [];
			let settingSchemaName = settingsFieldObj.fieldSchemaName;
			let keywords = [settingsObj.fieldLabel && settingsObj.fieldLabel.toLowerCase()];
			keywords = keywords.concat(JSON.parse(settingsObj.searchSynonyms || '[]'));
			options.forEach(({displayName, synonyms, value: NLPInput}) => {
				let assignTemplate = {
					operation: settingInfo.operation,
					methodInfo: {
						method: 'patternless',
						NLPInput,
						NLPDisplayName: displayName,
						settingId,
						settingSchemaName
					},
					currentParentInfo: {
						componentType: settingInfo.parentComponentType,
						componentSubtype: settingInfo.parentComponentSubtype,
						componentId: settingInfo.parentComponentId
					},
					currentComponentInfo: {
						componentType: settingInfo.componentType,
						componentSubtype: settingInfo.componentSubtype,
						componentId: settingInfo.componentId
					},
					settingName: settingsObj.fieldLabel,
					patternName: 'Set ' + settingsObj.fieldLabel + ' on <<FieldLabel>> to "' + displayName + '"'
				};
				keywords.forEach(synonym => {
					let match = _matchKeyword(remainingKeywords, synonym);
					let score = match.score;
					if (score > highScore) {
						// If there's a new high score, we don't want to keep the lower scoring records. Remove and replace.
						bestMatches = {};
						bestMatches[settingId + NLPInput] = Object.assign({}, assignTemplate, match);
						highScore = score;
					} else if (score && score === highScore) {
						// If this matches the existing  high score, we don't want to overwrite the equally scoring records. Insert into the object.
						bestMatches[settingId + NLPInput] = Object.assign({}, assignTemplate, match);
					}
				});
				candidatesForSearching[settingId + NLPInput] = {
					label: settingsObj.fieldLabel,
					keywords: keywords
				};
			});
		});
		return bestMatches;
	},

	/**
	 * 
	 * Takes in information regarding a component being created and returns matches
	 * 
	 * @param {object} matchInfo Object with information regarding a pattern
	 * @param {string} matchInfo.remainingKeywords The keywords to  be matched for the pattern
	 * @param {string | number} matchInfo.NLPInput The NLPInput for the pattern, if provided
	 * @param {string} matchInfo.componentType The type of the component being considered
	 * @param {string} matchInfo.componentSubtype The subtype of the component being considered
	 * @param {string} matchInfo.componentId The ID of the component being considered
	 * @param {array | null} matchInfo.preps Array of preposition information to consider.
	 */
	getCreateMatches(matchInfo) {

		let guesses = [];

		// Pull relevant information out of the matchInfo object
		let { remainingKeywords, NLPInput, preps } = matchInfo;
		
		// Unnest preps (needed in case Google NL decides that "with interfaces" in "create a customer table from the person template with interfaces" modifies "template")
		preps = _prepUnnester(preps);

		// Handle component types with pattern-based creation
		if (matchInfo.componentSubtype !== 'table' && matchInfo.componentSubtype !== 'relationship') {
			guesses = this.getPatternMatches(matchInfo);
		}

		// Handle relationship creation
		if (matchInfo.componentSubtype === 'relationship') {

			let toJoin = [];
			if(matchInfo.det) {
				toJoin.push(matchInfo.det);
			}
			if(matchInfo.NLPInput) {
				toJoin.push(matchInfo.NLPInput);
			}
			toJoin.push(remainingKeywords);
			remainingKeywords = toJoin.join('');

			let cardinalities = {
				'one': [
					'one',
					'1',
					'a',
					'an',
					'single',
					'a single'
				],
				'many': [
					'many',
					'm',
					'n',
					'multiple'
				]
			};

			// For now, relationship processing will use contrived language for relationship creation
			let lhsTableCandidates = this.getMetadataMatches(remainingKeywords, 'update', null, null, [], ['table']);
			
			let rhsTableCandidates = [];
			preps.forEach(prep => {
				if(prep.prep === 'to') {
					let pobjs = prep.pobjs || [];
					pobjs.forEach(pobj => {
						let keyword = (pobj.det ? pobj.det + ' ' : '') + pobj.objectAdjectives.join(' ') + (pobj.objectAdjectives.length && pobj.objectUnit ? ' ' : '') + pobj.objectUnit;
						rhsTableCandidates = rhsTableCandidates.concat(this.getMetadataMatches(keyword, 'update', null, null, [], ['table']));
					});
				}
			});
			lhsTableCandidates.forEach(lhsCandidate => {
				let lhsTable = TableStore.get(lhsCandidate.componentId) || {};
				let lhsSingularName = lhsTable.singularName || lhsTable.tableSchemaName;
				let lhsPluralName = lhsTable.pluralName || lhsTable.tableSchemaName;
				rhsTableCandidates.forEach(rhsCandidate => {
					let rhsTable = TableStore.get(rhsCandidate.componentId) || {};
					let rhsSingularName = rhsTable.singularName || rhsTable.tableSchemaName;
					let rhsPluralName = rhsTable.pluralName || rhsTable.tableSchemaName;
					let lhsCardinalityBestMatches = {}, lhsHighScore = 0;
					let rhsCardinalityBestMatches = {}, rhsHighScore = 0;
					Object.keys(cardinalities).forEach(cardinality => {
						let synonyms = cardinalities[cardinality];
						synonyms.forEach(synonym => {
							// Match lhs
							let lhsMatch = _matchKeyword(lhsCandidate.remainingKeywords, synonym);
							if (lhsMatch.score > lhsHighScore) {
								lhsCardinalityBestMatches = {};
								lhsCardinalityBestMatches[cardinality] = lhsMatch;
								lhsHighScore = lhsMatch.score;
							} else if (lhsMatch.score === lhsHighScore) {
								lhsCardinalityBestMatches[cardinality] = lhsMatch;
							}
							// Match rhs
							let rhsMatch = _matchKeyword(rhsCandidate.remainingKeywords, synonym);
							if (rhsMatch.score > rhsHighScore) {
								rhsCardinalityBestMatches = {};
								rhsCardinalityBestMatches[cardinality] = rhsMatch;
								rhsHighScore = rhsMatch.score;
							} else if (rhsMatch.score === rhsHighScore) {
								rhsCardinalityBestMatches[cardinality] = rhsMatch;
							}
						});
					});
					Object.keys(lhsCardinalityBestMatches).forEach(lhsCardinality => {
						Object.keys(rhsCardinalityBestMatches).forEach(rhsCardinality => {
							guesses.push({
								operation: 'create',
								currentComponentInfo: {
									componentType: 'relationship',
									componentSubtype: 'relationship'
								},
								methodInfo: {
									method: 'patternless',
									lhsTable: lhsCandidate.componentId,
									lhsCardinality: lhsCardinality,
									rhsTable: rhsCandidate.componentId,
									rhsCardinality: rhsCardinality
								},
								score: lhsCandidate.score + rhsCandidate.score +
									lhsCardinalityBestMatches[lhsCardinality].score +
									rhsCardinalityBestMatches[rhsCardinality].score, // Sum of all scores matches for this
								matchedKeywords: [
									lhsCandidate.matchedKeywords,
									rhsCandidate.matchedKeywords,
									lhsCardinalityBestMatches[lhsCardinality].matchedKeywords,
									rhsCardinalityBestMatches[rhsCardinality].matchedKeywords
								].join(' '),
								remainingKeywords: [
									lhsCandidate.remainingKeywords,
									rhsCandidate.remainingKeywords,
									lhsCardinalityBestMatches[lhsCardinality].remainingKeywords,
									rhsCardinalityBestMatches[rhsCardinality].remainingKeywords
								].join(' '),
								patternName: 'Relate ' +
									lhsCardinality + ' ' + (lhsCardinality === 'one' ? lhsSingularName : lhsPluralName) + ' to ' +
									rhsCardinality + ' ' + (rhsCardinality === 'one' ? rhsSingularName : rhsPluralName),
								componentType: 'relationship',
								componentSubtype: 'relationship'
							});
						});
					});

				});
			});
		}
		
		
		// Handle table creation
		if (!guesses || !guesses.length || !guesses[0]) {

			// Get possible table creation information
			let udmPatternMatches = [];

			// First test if any UDM information was provided via prepositions (e.g., "from the person template")
			if(preps) {

				let udmHighScore = 0;
				preps.forEach(prep => {
					// Only look at 'with' and 'from' prepositions.
					if (['with', 'from'].indexOf(prep.prep) > -1) {
						let pobjs = prep.pobjs || [];
						pobjs.forEach(pobj => {
							let keyword = pobj.objectAdjectives.join(' ') + (pobj.objectAdjectives.length && pobj.objectUnit ? ' ' : '') + pobj.objectUnit;
							
							// Search localUdmMatches for the preposition's keyword. (We use Object.assign to dereference the matchInfo argument)
							let localUdmMatches = this.getPatternMatches(Object.assign({}, matchInfo, {
								remainingKeywords: keyword
							}));
							let localHighScore = localUdmMatches && localUdmMatches.length ? localUdmMatches[0].score : 0;
							if(localHighScore === udmHighScore) {
								udmPatternMatches = udmPatternMatches.concat(localUdmMatches);
							} else if (localHighScore > udmHighScore) {
								udmHighScore = localHighScore;
								udmPatternMatches = localUdmMatches;
							}
						});
					}
				});
			}

			if(!udmPatternMatches || !udmPatternMatches.length) {
				// Get possible UDM matches based on the table name
				udmPatternMatches = this.getPatternMatches(matchInfo);
				// We want the basic table creation to always be an option
				// (e.g., "Make a person table" should offer to both use the person template
				// and to just create a basic table)
				udmPatternMatches.unshift({
					currentComponentInfo: {
						componentType: 'table',
						componentSubtype: 'table'
					},
					methodInfo: {
						method: 'patternless',
						NLPInput: NLPInput || remainingKeywords
					}
				});
			}

			
			// Get the possible types of CRUD
			// (Currently only two hardcoded CRUD types available; may be made dynamic later)
			let crudCandidates = [{
				title: 'with interfaces',
				searchSynonyms: ['crud', 'interface'],
				value: 'CRUD'
			}, {
				title: 'without interfaces',
				searchSynonyms: ['basic', 'no crud', 'no interface'],
				value: 'no CRUD'
			}];
			let crudHighScore = 0, crudBestMatches = [];
			if (preps) {
				preps.forEach(prep => {
					// Only look at 'with' and 'without' prepositions.
					if (prep.prep === 'with') {
						let pobjs = prep.pobjs || [];
						pobjs.forEach(pobj => {
							let keyword = pobj.objectAdjectives.join(' ') + (pobj.objectAdjectives.length && pobj.objectUnit ? ' ' : '') + pobj.objectUnit;
							crudCandidates.forEach(candidate => {
								candidate.searchSynonyms.forEach(synonym => {
									let match = _matchKeyword(keyword, synonym);
									if (match.score > crudHighScore) {
										crudBestMatches = [Object.assign({}, candidate, match)];
										crudHighScore = match.score;
									} else if (match.score && match.score === crudHighScore) {
										crudBestMatches.push(Object.assign({}, candidate, match));
									}
								});
							});
						});
					} else if (prep.prep === 'without') {
						let pobjs = prep.pobjs || [];
						pobjs.forEach(pobj => {
							let keyword = pobj.objectAdjectives.join(' ') + (pobj.objectAdjectives.length && pobj.objectUnit ? ' ' : '') + pobj.objectUnit;
							// "Without" implies no CRUD interfaces.
							let candidate = crudCandidates[1];
							['crud', 'interface'].forEach(synonym => {
								let match = _matchKeyword(keyword, synonym);
								if (match.score > crudHighScore) {
									crudBestMatches = [Object.assign({}, candidate, match)];
									crudHighScore = match.score;
								} else if (match.score && match.score === crudHighScore) {
									crudBestMatches.push(Object.assign({}, candidate, match));
								}
							});
						});
					}
				});
			}

			// If no best matches are found, just use all possible CRUD types
			crudBestMatches = crudBestMatches && crudBestMatches.length ? crudBestMatches : crudCandidates;

			// Combine udm and crud matches into appropriate create guess objects
			udmPatternMatches.forEach(udmCandidate => {
				crudBestMatches.forEach(crudBestMatch => {
					guesses.push(Object.assign({}, matchInfo, udmCandidate, {
						currentComponentInfo: udmCandidate.currentComponentInfo,
						methodInfo: Object.assign({},
							udmCandidate.methodInfo,
							{
								NLPInput: NLPInput || remainingKeywords,
								CRUD: crudBestMatch.value
							}),
						score: (crudBestMatch.score || 0) + (udmCandidate.score || 0),
						matchedKeywords: [udmCandidate.matchedKeywords, crudBestMatch.matchedKeywords].join(' '),
						remainingKeywords: udmCandidate.remainingKeywords ? udmCandidate.remainingKeywords.replace(crudBestMatch.matchedKeywords, '') : crudBestMatch.matchedKeywords,
						patternName: 'Add a table named "' + (NLPInput || remainingKeywords || '(What do you want to track?)') + '"' + (udmCandidate.methodInfo.method === 'pattern' ? ' copied from the ' + udmCandidate.patternName + ' template' : '') + ' ' + crudBestMatch.title,
					}));
				});
			});
		}

		return guesses;
	},

	/**
	 * Takes in information regarding constraints on a pattern and returns matching patterns
	 * 
	 * @param {object} patternInfo Object with information regarding a pattern
	 * @param {string} patternInfo.remainingKeywords The keywords to  be matched for the pattern
	 * @param {string | number} patternInfo.NLPInput The NLPInput for the pattern, if provided
	 * @param {string} patternInfo.componentType The type of the component being considered
	 * @param {string} patternInfo.componentSubtype The subtype of the component being considered
	 * @param {string} patternInfo.componentId The ID of the component being considered
	 * 
	 * @returns {array} Array of objects which include pattern match information.
	 */
	getPatternMatches(patternInfo) {
		let { remainingKeywords, NLPInput, componentType, componentSubtype, componentId, operation } = patternInfo;
		remainingKeywords = remainingKeywords || '';
		// let keyword = (matchedKeywords.trim() + ' ' + remainingKeywords.trim()).trim();
		let keyword = remainingKeywords.trim();
		if(operation === 'create') {
			// Strip out any 'new' words ("create a new page", etc.)
			keyword = keyword.replace(/\bnew\b/g, '');
		}
		// Array of pattern objects
		let patterns = NLPBuilderDictionariesStore.getPatterns();
		patterns = patterns.filter(pattern => {
			let toReturn = componentType ? componentType === pattern.type || (componentType === 'field' && pattern.type === 'form') : true;
			toReturn = toReturn && (componentSubtype && componentSubtype !== componentType ? pattern.subType === componentSubtype || pattern.type === 'form' : toReturn);
			toReturn = operation ? toReturn && pattern.operation === operation.toLowerCase() : toReturn;
			return toReturn;
		});
		let highScore = 0, bestMatches = [];
		let getToAssign = (pattern) => {
			let subtype = componentSubtype || pattern.subType || pattern.type;
			let toReturn = {
				operation: pattern.operation,
				patternName: pattern.label,
				methodInfo: {
					method: 'pattern',
					NLPInput: NLPInput,
					patternId: pattern.id,
				},
				currentComponentInfo: {
					componentType: pattern.type,
					componentSubtype: subtype,
				}
			};
			if (componentId) {
				toReturn.currentComponentInfo.componentId = componentId;
			}
			return toReturn;
		};
		if (keyword) {
			patterns.forEach(pattern => {
				let synArray = pattern.searchable ? pattern.searchable : [];
				let toAssign = getToAssign(pattern);
				synArray.forEach(synonym => {
					let match = _matchKeyword(keyword, synonym);
					if (match.score > highScore) {
						bestMatches = [Object.assign(toAssign, match)];
						highScore = match.score;
					} else if (match.score && match.score === highScore) {
						bestMatches.push(Object.assign({}, patternInfo, toAssign, match));
					}
				});
			});
		} else {
			bestMatches = patterns.map(pattern => {
				let toAssign = getToAssign(pattern);
				return Object.assign({}, patternInfo, toAssign);
			});
		}

		return bestMatches;
	},

	/**
	 * Finds the matching "metadata" (e.g., specific fields, tables, pages, relationships, etc. being updated)
	 * for given keywords and context information.
	 * 
	 * @param {string} objectPhrase A set of keywords being matched
	 * @param {string} operation The operation being performed (can control the metadata considered; e.g., attach/detach will only consider fields not already attached/already attached, etc.)
	 * @param {object} contextComponent Object containing information regarding the currently selected component
	 * @param {object} parentComponent Object containing information regarding the parent of the currently selected component
	 * @param {array} childAttachmentPoints Valid locations to attach a field to/detach a field from
	 * @param {string} componentType The type of the component being considered
	 */
	getMetadataMatches(objectPhrase, operation, contextComponent, parentComponent, childAttachmentPoints, componentTypes) {

		// valid component types currently are 'field', 'table', 'page' and 'relationship'

		componentTypes = componentTypes ? componentTypes : ['field', 'table', 'page', 'relationship'];

		// If there are no provided childAttachmentPoints
		if (!childAttachmentPoints) {
			if(parentComponent && parentComponent.recordId) {
				childAttachmentPoints = [parentComponent];
			} else if (contextComponent && contextComponent.recordId) {
				childAttachmentPoints = [contextComponent];
			} else {
				childAttachmentPoints = [];
			}
		}

		let matches = [];

		// Beginning of 'this' matching section. This deals with matching keywords that represent
		// The current component or its parent. Some scenarios may match 'this' without using any of these keywords;
		// These are handled below

		let thisMatches = [];
		// If the keywords include "parent", it is probably referencing the parent component: push it as a match.
		if (parentComponent && objectPhrase.includes('parent') && componentTypes.indexOf(parentComponent.componentType) > -1) {
			thisMatches.push({
				componentType: parentComponent.componentType,
				componentId: parentComponent.recordId,
				matchedKeywords: 'parent',
				remainingKeywords: objectPhrase.replace('parent', ''),
				score: 'parent'.length
			});
		}

		let matchesThis = false, thisKeyword = '';

		// Because 'delete' and 'update' are valid operations on any component type,
		// just entering 'delete' or an update method should prompt to delete/update the current point ('this')
		if(!objectPhrase && ['delete', 'update'].indexOf(operation) > -1) {
			objectPhrase = 'this';
		}

		// Because 'detach' is a valid operation on only fields, just entering 'detach' should prompt to detach the current point ONLY if it's a field
		if(operation === 'detach' && contextComponent && contextComponent.componentType === 'field' && !objectPhrase) {
			objectPhrase = 'this';
		}

		// This regex matches a keyword only if it is surrounded by a word boundary (e.g., will match "this" in "make this red" but not in "Thistle")
		let keywordMatches = objectPhrase.match(/\bthis\b/ig);
		if (keywordMatches && keywordMatches.length) {
			matchesThis = true;
			thisKeyword = 'this';
		} else {
			// This regex matches a keyword only if it is surrounded by a word boundary (e.g., will match "it" in "make it red" but not in "summit")
			keywordMatches = objectPhrase.match(/\bit\b/ig);
			if (keywordMatches && keywordMatches.length) {
				matchesThis = true;
				thisKeyword = 'it';
			} else {
				keywordMatches = objectPhrase.match(/\bthese\b/ig);
				if (keywordMatches && keywordMatches.length) {
					matchesThis = true;
					thisKeyword = 'these';
				}
			}
		}

		// If the keywords include "this" or "it", and the current component is a valid component type,
		// it is probably referencing the current context's component: push it as a match.
		if (matchesThis && contextComponent && componentTypes.indexOf(contextComponent.componentType) > -1) {
			let childAttachmentPointName = '';
			let childAttachmentPoint = Object.assign({}, parentComponent);
			if (childAttachmentPoint.componentType === 'page') {
				let pageObj = PageStore.get(childAttachmentPoint.componentId) || {};
				childAttachmentPointName = pageObj.name;
			} else if (childAttachmentPoint.componentType === 'field') {
				let parentFieldObj = FieldStore.get(childAttachmentPoint.componentId) || {};
				let parentFieldSettings = ObjectUtils.getObjFromJSON(parentFieldObj.settings);
				childAttachmentPointName = parentFieldSettings.fieldLabel;
			} else {
				console.warn('Unknown configuration in getMetadataMatches.');
				console.warn('childAttachmentPoint', childAttachmentPoint);
			}
			thisMatches.push({
				componentType: contextComponent.componentType,
				componentId: contextComponent.componentId,
				matchedKeywords: thisKeyword,
				remainingKeywords: objectPhrase.replace(thisKeyword, ''),
				score: thisKeyword.length,
				parentComponentId: parentComponent ? parentComponent.componentId : '',
				parentComponentType: parentComponent ? parentComponent.componentType : '',
				parentLabel: childAttachmentPointName
			});
		}

		// End of 'this' matching section.

		// Begin looking over the different metadata types.
		// The general idea here is to look over all possible component types
		// for the highest-scoring matches, and then determine the highest-scoring
		// methods of the entire set

		// This is used to keep track of the highest-scoring match of all component types
		// Without using this, this algorithm could potentially combine the highest-scoring
		// matches of fields, pages and tables each, even if some are clearly worse matches.
		let topScore = 0;
		// Any combination of potential componentTypes may be passed in to find. (If none is specified, all are used). Different component types require different handling.
		componentTypes.forEach(componentType => {
			// Fields, particularly, require operation-specific handling, as valid options for attach/detach/delete/etc. may differ based on context.
			if (componentType === 'field') {
				// The appropriate results will vary based on the type of operation. Break it down as appropriate and delegate to each helper method.

				if (operation === 'attach') {
					childAttachmentPoints.forEach(childAttachmentPoint => {
						let tableSchemaName = '';

						if (contextComponent && contextComponent.componentType === 'field') {
							tableSchemaName = FieldUtils.getDefaultChildTableSchemaName(childAttachmentPoint.componentId);
							if (!tableSchemaName) {
								let fieldObj = FieldStore.get(childAttachmentPoint.componentId) || {};
								// Do NOT attach to content tabs or content dropdowns
								if(fieldObj.fieldType === '846b747e-25a0-40df-8115-af4a00a1cab5' || fieldObj.fieldType === 'bb5bedc3-44d1-4e4c-9c40-561a675173b1') {
									return;
								}
								tableSchemaName = fieldObj.tableSchemaName;
							}
						} else {
							tableSchemaName = PageUtils.getDefaultChildTableSchemaName(childAttachmentPoint.componentId);
							if (!tableSchemaName) {
								let pageObj = PageStore.get(childAttachmentPoint.componentId) || {};
								tableSchemaName = pageObj.tableSchemaName;
							}
						}
						// Get the name of the attachment point and a dictionary of the attached fields.
						let { childAttachmentPointName, attachedFieldsDictionary } = _getAttachmentPointInfo(childAttachmentPoint);

						// Use the helper method to get the best matches for the keywords
						let bestMatches = _getMetadataMatchesAttach(objectPhrase, tableSchemaName, attachedFieldsDictionary, childAttachmentPoint, childAttachmentPointName);
						bestMatches = Object.keys(bestMatches).map(key => bestMatches[key]);

						let highScore = bestMatches && bestMatches[0] && bestMatches[0].score ? bestMatches[0].score : 0;
						// Overwrite lower scoring matches from above
						if(bestMatches.length && topScore < highScore) {
							topScore = highScore;
							matches = bestMatches;
						} else if (topScore === highScore) {
							// Add onto equal-scoring matches
							matches =  matches.concat(bestMatches);
						}

						// Add the best matches into the existing match candidates
						// matches = matches.concat(Object.keys(bestMatches).map(key => bestMatches[key]));
					});
				} else if (operation === 'detach') {
					childAttachmentPoints.forEach(childAttachmentPoint => {
						// let tableSchemaName = contextComponent.componentType === 'field' ?
						// 	(FieldUtils.getDefaultChildTableSchemaName(childAttachmentPoint.componentId) || FieldStore.get(childAttachmentPoint.componentId).tableSchemaName) :
						// 	(PageUtils.getDefaultChildTableSchemaName(childAttachmentPoint.componentId) || PageStore.get(childAttachmentPoint.componentId).tableSchemaName);

						let tableSchemaName = '';

						if (contextComponent && contextComponent.componentType === 'field') {
							tableSchemaName = FieldUtils.getDefaultChildTableSchemaName(childAttachmentPoint.componentId);
							if (!tableSchemaName) {
								let fieldObj = FieldStore.get(childAttachmentPoint.componentId) || {};
								tableSchemaName = fieldObj.tableSchemaName;
							}
						} else {
							tableSchemaName = PageUtils.getDefaultChildTableSchemaName(childAttachmentPoint.componentId);
							if (!tableSchemaName) {
								let pageObj = PageStore.get(childAttachmentPoint.componentId) || {};
								tableSchemaName = pageObj.tableSchemaName;
							}
						}

						// Get the name of the attachment point and a dictionary of the attached fields.
						let { childAttachmentPointName, attachedFieldsDictionary } = _getAttachmentPointInfo(childAttachmentPoint);

						// Use the helper method to get the best matches for the keywords
						let bestMatches = _getMetadataMatchesDetach(objectPhrase, tableSchemaName, attachedFieldsDictionary, childAttachmentPoint, childAttachmentPointName);
						bestMatches = Object.keys(bestMatches).map(key => bestMatches[key]);

						// Add the best matches into the existing match candidates
						let highScore = bestMatches && bestMatches[0] && bestMatches[0].score ? bestMatches[0].score : 0;
						// Overwrite lower scoring matches from above
						if(bestMatches.length && topScore < highScore) {
							topScore = highScore;
							matches = bestMatches;
						} else if (topScore === highScore) {
							// Add onto equal-scoring matches
							matches =  matches.concat(bestMatches);
						}
					});
				} else if (operation === 'update') {

					let bestMatches = {};

					// Different child attachment points may have different table schema names
					childAttachmentPoints.forEach(childAttachmentPoint => {
						let tableSchemaName = '';

						if (contextComponent && contextComponent.componentType === 'field') {
							tableSchemaName = FieldUtils.getDefaultChildTableSchemaName(childAttachmentPoint.componentId);
							if (!tableSchemaName) {
								let fieldObj = FieldStore.get(childAttachmentPoint.componentId) || {};
								tableSchemaName = fieldObj.tableSchemaName;
							}
						} else {
							tableSchemaName = PageUtils.getDefaultChildTableSchemaName(childAttachmentPoint.componentId);
							if (!tableSchemaName) {
								let pageObj = PageStore.get(childAttachmentPoint.componentId) || {};
								tableSchemaName = pageObj.tableSchemaName;
							}
						}

						// Use the helper method to get the best matches for the keywords
						let bestMatchesTemp = _getMetadataMatchesUpdate(objectPhrase, contextComponent, parentComponent, tableSchemaName, thisMatches);
						bestMatches = Object.assign({}, bestMatches, bestMatchesTemp);
					});

					bestMatches = Object.keys(bestMatches).map(key => bestMatches[key]);

					// Add the best matches into the existing match candidates
					// matches = matches.concat(Object.keys(bestMatches).map(key => bestMatches[key]));

					let highScore = bestMatches && bestMatches[0] && bestMatches[0].score ? bestMatches[0].score : 0;
						// Overwrite lower scoring matches from above
						if(bestMatches.length && topScore < highScore) {
							topScore = highScore;
							matches = bestMatches;
						} else if (topScore === highScore) {
							// Add onto equal-scoring matches
							matches = bestMatches;
						}
				} else if (operation === 'delete') {
					// Use the helper method to get the best matches for the keywords

					let bestMatches = {};

					// Different child attachment points may have different table schema names
					childAttachmentPoints.forEach(childAttachmentPoint => {
						let tableSchemaName = '';

						if (contextComponent && contextComponent.componentType === 'field') {
							tableSchemaName = FieldUtils.getDefaultChildTableSchemaName(childAttachmentPoint.componentId);
							if (!tableSchemaName) {
								let fieldObj = FieldStore.get(childAttachmentPoint.componentId) || {};
								tableSchemaName = fieldObj.tableSchemaName;
							}
						} else {
							tableSchemaName = PageUtils.getDefaultChildTableSchemaName(childAttachmentPoint.componentId);
							if (!tableSchemaName) {
								let pageObj = PageStore.get(childAttachmentPoint.componentId) || {};
								tableSchemaName = pageObj.tableSchemaName;
							}
						}

						// Use the helper method to get the best matches for the keywords
						let bestMatchesTemp = _getMetadataMatchesDelete(objectPhrase, tableSchemaName, thisMatches);
						bestMatches = Object.assign({}, bestMatches, bestMatchesTemp);
					});
					bestMatches = Object.keys(bestMatches).map(key => bestMatches[key]);
					
					let highScore = bestMatches && bestMatches[0] && bestMatches[0].score ? bestMatches[0].score : 0;
					// Overwrite lower scoring matches from above
					if(bestMatches.length && topScore < highScore) {
						topScore = highScore;
						matches = bestMatches;
					} else if (topScore === highScore) {
						// Add onto equal-scoring matches
						matches =  matches.concat(bestMatches);
					}

					// Add the best matches into the existing match candidates
					// matches = matches.concat(Object.keys(bestMatches).map(key => bestMatches[key]));
				}
			} else if (componentType === 'table') {

				// Tables are a fairly straightforward keyword match.

				let tables = TableStore.getAllArray() || [];
				// Local high score
				let highScore = 0, bestMatches = {};

				tables.forEach(tableObj => {
					let { singularName, pluralName, tableSchemaName, searchSynonyms } = tableObj;
					let searchableArray = [];
					if (singularName) {
						searchableArray.push(singularName);
					}
					if (pluralName) {
						searchableArray.push(pluralName);
					}
					if (tableSchemaName) {
						searchableArray.push(tableSchemaName);
					}
					if (searchSynonyms) {
						try {
							let searchSynonymsArray = JSON.parse(searchSynonyms);
							searchableArray = searchableArray.concat(searchSynonymsArray);
						} catch (err) {
							console.warn('Error parsing searchSynonyms in getMetadataMatches. Value was', searchSynonyms);
						}
					}
					searchableArray.forEach(searchable => {
						let match = _matchKeyword(objectPhrase, searchable);
						let assignTemplate = {
							componentType: 'table',
							componentSubtype: 'table',
							componentName: tableObj.singularName,
							componentId: tableObj.recordId
						};
						let score = match.score;
						if (score > highScore) {
							// If there's a new high score, we don't want to keep the lower scoring records. Remove and replace.
							bestMatches = {};
							bestMatches[tableObj.recordId] = Object.assign({}, assignTemplate, match);
							highScore = score;
						} else if (score && score === highScore) {
							// If this matches the existing  high score, we don't want to overwrite the equally scoring records. Insert into the object.
							bestMatches[tableObj.recordId] = Object.assign({}, assignTemplate, match);
						}
					});

				});

				bestMatches = Object.keys(bestMatches).map(key => bestMatches[key]);
				
				// Overwrite lower scoring matches from above
				if(bestMatches.length && topScore < highScore) {
					topScore = highScore;
					matches = bestMatches;
				} else if (topScore === highScore) {
					// Add onto equal-scoring matches
					matches =  matches.concat(bestMatches);
				}
				// No action need be taken if these do not match the best total score


			} else if (componentType === 'page') {
				// Pages are a fairly straightforward keyword match.

				// Get all pages
				let pages = PageStore.getAllArray() || [];
				// Local high score - tracks the best-scoring page, even if it's not the best-scoring match of the whole thing
				let highScore = 0;
				// bestMatches. We make this an object keyed by recordId to avoid duplicate insertion
				let bestMatches = {};

				// For each page
				pages.forEach(pageObj => {

					// Get keywords which will be used to identify the page
					let { name, searchSynonyms } = pageObj;
					
					// Push the name into searchableArray if there is one
					let searchableArray = [];
					if (name) {
						searchableArray.push(name);
					}

					// If the page has a searchSynonyms property, add its keywords in
					if (searchSynonyms) {
						try {
							let searchSynonymsArray = JSON.parse(searchSynonyms);
							searchableArray = searchableArray.concat(searchSynonymsArray);
						} catch (err) {
							console.warn('Error parsing searchSynonyms in getMetadataMatches. Value was', searchSynonyms);
						}
					}

					// assignTemplate has the information about the page
					// It is combined with individual match objects using
					// object.assign to give full match information later
					let assignTemplate = {
						componentType: 'page',
						componentSubtype: 'page',
						componentId: pageObj.recordId
					};
					
					// Match each keyword against the keyword and find the highest scoring keyword match
					// This is then the match score for that page
					searchableArray.forEach(searchable => {
						let match = _matchKeyword(objectPhrase, searchable);
						let score = match.score;
						if (score > highScore) {
							// If there's a new high score, we don't want to keep the lower scoring records. Remove and replace.
							bestMatches = {};
							// We use an object assign to avoid overwriting assignTemplate.
							bestMatches[pageObj.recordId] = Object.assign({}, assignTemplate, match);
							highScore = score;
						} else if (score && score === highScore) {
							// If this matches the existing high score, we don't want to overwrite the equally scoring records. Insert into the object.
							bestMatches[pageObj.recordId] = Object.assign({}, assignTemplate, match);
						}
					});
				});

				// Transform bestMatches from an object into an array
				bestMatches = Object.keys(bestMatches).map(key => bestMatches[key]);

				// Check how this compares to any previous matches from other component types
				if(bestMatches.length && topScore < highScore) {
					// Overwrite lower scoring matches from above if these are scored better
					topScore = highScore;
					matches = Object.keys(bestMatches).map(key => bestMatches[key]);
				} else if (topScore === highScore) {
					// Add equal-scoring matches into the full matches object
					matches =  matches.concat(Object.keys(bestMatches).map(key => bestMatches[key]));
				}
				// No action need be taken if these do not match the best total score

			} else if (componentType === 'relationship') {
				// Relationships are a fairly straightforward keyword match.

				let relationships = RelationshipStore.getAllArray() || [];
				// Local high score
				let highScore = 0, bestMatches = {};

				relationships.forEach(relationshipObj => {
					let { ltorLabel, rtolLabel, relationSchemaName, searchSynonyms } = relationshipObj;
					let searchableArray = [];
					if (ltorLabel) {
						searchableArray.push(ltorLabel);
					}
					if (rtolLabel) {
						searchableArray.push(rtolLabel);
					}
					if (relationSchemaName) {
						searchableArray.push(relationSchemaName);
					}
					if (searchSynonyms) {
						try {
							let searchSynonymsArray = JSON.parse(searchSynonyms);
							searchableArray = searchableArray.concat(searchSynonymsArray);
						} catch (err) {
							console.warn('Error parsing searchSynonyms in getMetadataMatches. Value was', searchSynonyms);
						}
					}
					searchableArray.forEach(searchable => {
						let match = _matchKeyword(objectPhrase, searchable);
						let assignTemplate = {
							componentType: 'relationship',
							componentSubtype: 'relationship',
							componentName: ltorLabel + '/' + rtolLabel + ' (' + relationSchemaName + ')',
							componentId: relationshipObj.recordId
						};
						let score = match.score;
						if (score > highScore) {
							// If there's a new high score, we don't want to keep the lower scoring records. Remove and replace.
							bestMatches = {};
							bestMatches[relationshipObj.recordId] = Object.assign({}, assignTemplate, match);
							highScore = score;
						} else if (score && score === highScore) {
							// If this matches the existing  high score, we don't want to overwrite the equally scoring records. Insert into the object.
							bestMatches[relationshipObj.recordId] = Object.assign({}, assignTemplate, match);
						}
					});

				});

				bestMatches = Object.keys(bestMatches).map(key => bestMatches[key]);
				
				// Overwrite lower scoring matches from above
				if(bestMatches.length && topScore < highScore) {
					topScore = highScore;
					matches = bestMatches;
				} else if (topScore === highScore) {
					// Add onto equal-scoring matches
					matches =  matches.concat(bestMatches);
				}
				// No action need be taken if these do not match the best total score
			} else {
				console.warn('componentType %s not yet supported in getMetadataMatches', componentType);
			}
		});

		
		// Update operations should impact the current context point and only the current context point if there's no better match found
		if(!topScore && contextComponent && operation === 'update' && !thisMatches.length) {
			let childAttachmentPointName = '';
			let childAttachmentPoint = Object.assign({}, parentComponent);
			if (childAttachmentPoint.componentType === 'page') {
				let pageObj = PageStore.get(childAttachmentPoint.componentId) || {};
				childAttachmentPointName = pageObj.name;
			} else if (childAttachmentPoint.componentType === 'field') {
				let parentFieldObj = FieldStore.get(childAttachmentPoint.componentId) || {};
				let parentFieldSettings = ObjectUtils.getObjFromJSON(parentFieldObj.settings);
				childAttachmentPointName = parentFieldSettings.fieldLabel;
			} else {
				console.warn('Unknown configuration in getMetadataMatches.');
				console.warn('childAttachmentPoint', childAttachmentPoint);
			}
			matches = [{
				componentType: contextComponent.componentType,
				componentId: contextComponent.componentId,
				matchedKeywords: thisKeyword,
				remainingKeywords: objectPhrase.replace(thisKeyword, ''),
				score: thisKeyword.length,
				parentComponentId: parentComponent ? parentComponent.componentId : '',
				parentComponentType: parentComponent ? parentComponent.componentType : '',
				parentLabel: childAttachmentPointName
			}];
		} else {
			matches = thisMatches.concat(matches);
		}

		if (!matches.length && contextComponent && componentTypes.indexOf(contextComponent.componentType) > -1) {
			let childAttachmentPointName = '';
			let childAttachmentPoint = Object.assign({}, parentComponent);
			if (childAttachmentPoint.componentType === 'page') {
				let pageObj = PageStore.get(childAttachmentPoint.componentId) || {};
				childAttachmentPointName = pageObj.name;
			} else if (childAttachmentPoint.componentType === 'field') {
				let parentFieldObj = FieldStore.get(childAttachmentPoint.componentId) || {};
				let parentFieldSettings = ObjectUtils.getObjFromJSON(parentFieldObj.settings);
				childAttachmentPointName = parentFieldSettings.fieldLabel;
			} else {
				console.warn('Unknown configuration in getMetadataMatches.');
				console.warn('childAttachmentPoint', childAttachmentPoint);
			}
			matches.push({
				componentType: contextComponent.componentType,
				componentId: contextComponent.componentId,
				matchedKeywords: thisKeyword,
				remainingKeywords: objectPhrase,
				score: 0,
				parentComponentType: parentComponent ? parentComponent.componentType : '',
				parentComponentId: parentComponent ? parentComponent.componentId : '',
				parentLabel: childAttachmentPointName
			});
		}


		// @TODO: Deal with other types of metadata, matching by type name ("The phone number", etc.)

		return matches;
	},

	/**
 * Function to suggest the likely role for a field when it is attached to a page
 * 
 * @param {object} childAttachmentPoint The point at which the field is being attached or added
 * @param {object} currentFieldId (optional) The id of the field being added
 */
	guessFieldRoles(childAttachmentPoint, currentFieldId) {
		
		// If this field isn't being attached to anything, no roles will be suggested
		if (!childAttachmentPoint) {
			return '';
		}

		// Get the information regarding where this field will be attached
		let { componentType: parentComponentType, componentId: parentComponentId } = childAttachmentPoint;

		// Insert an empty match, as all role suggestions will include no role changes as an option
		let roles = [{
			value: [],
			label: '',
		}];

		// matchedKeys and matchedLabels track what roles are matched and their user-friendly display name
		let matchedKeys = [];
		let matchedLabels = [];
		
		// If the field already has roles, we want to add to them, not overwrite them.
		// Get them and add them to matchedKeys and matchedLabels so they will be included.
		if(currentFieldId) {
			let currentFieldSettings = FieldSettingsStore.getSettings(currentFieldId) || {};
			let baseRoles = currentFieldSettings.roles ? currentFieldSettings.roles.split(',') : [];
			baseRoles.forEach(role => {
				matchedKeys.push(role);
				matchedLabels.push(role.charAt(0).toUpperCase() + role.slice(1))
			});
		}

		if (parentComponentType === 'page') {
			// Get information regarding the parent page
			let pageObj = PageStore.get(parentComponentId) || {};
			// Get the roles of the parent page
			let pageRoles = pageObj && pageObj.roles ? pageObj.roles.split(',') : [];
			// If the page is either a view/edit or an add page, and recordDetails is not already accounted for,
			// Then suggest the recordDetails role
			if (matchedKeys.indexOf('recordDetails') === -1 && (pageRoles.indexOf('viewandedit') > -1 || pageRoles.indexOf('add') > -1)) {
				matchedKeys.push('recordDetails');
				matchedLabels.push('Record Details');
			}
		} else if (parentComponentType === 'field') {
			// Get information regarding the parent field
			let fieldObj = FieldStore.get(parentComponentId) || {};
			// If the parent field is a list and the 'list' role is not already accounted for
			if (matchedKeys.indexOf('list') === -1 && fieldObj.fieldType === '9b782b83-4962-4bd6-993c-f72096e02610') {
				// Then suggest the list role
				matchedKeys.push('list');
				matchedLabels.push('List');
			}
		}

		// If any roles were matched, suggest the combination of those roles
		if(matchedKeys.length) {
			roles.push({
				value: matchedKeys,
				// One-liner to separate labels by commas, with an 'and' before the last one.
				label: [matchedLabels.slice(0, -1).join(', '), matchedLabels.slice(-1)[0]].join(matchedLabels.length < 2 ? '' : ' and ')
			});
		}

		return roles;

	}
};

export default toExport;

/**
 * Used to build a proto-pattern object for an adjective and object unit
 * 
 * @param {string} adjective The adjective for which the pattern is being made
 * @param {object} obj The object of the action being considered
 */
function _adjectivePatternBuilder(adjective, obj) {
	var inputString = obj.objectUnit || '', quotedValues = obj.quotedObjs || [];

	// Check if the adjective matches a possible setting value. If it does, it goes with the quotedValues.
	// (E.G., "red" in "Make a red phone number field")

	if (toExport.classifyValue(adjective) === 'string') {
		inputString = adjective + ' ' + inputString;
	} else {
		quotedValues.push(adjective);
	}
	return {
		operation: { 'Update': 'Update' },
		inputString: inputString,
		inputArray: inputString.split(' '),
		quotedValues: quotedValues,
		// fieldType: _guessFieldType(obj.objectUnit)
	};
}

// Add a number field.
/**
 * Gets an object which matches up strings to color codes.
 */
function _getColorObj() {
	return {
		"aliceblue": "#f0f8ff",
		"almond": "#efdecd",
		"antiquebrass": "#cd9575",
		"antiquewhite": "#faebd7",
		"apricot": "#fdd9b5",
		"aqua": "#00ffff",
		"aquamarine": "#7fffd4",
		"asparagus": "#87a96b",
		"azure": "#f0ffff",
		"bananamania": "#fae7b5",
		"beige": "#f5f5dc",
		"bisque": "#ffe4c4",
		"black": "#000000",
		"blackblue": "#18171c",
		"blackred": "#412227",
		"blanchedalmond": "#ffebcd",
		"blizzardblue": "#ace5ee",
		"blue": "#0000ff",
		"bluebell": "#a2a2d0",
		"bluegray": "#6699cc",
		"bluegreen": "#0D98BA",
		"bluelilac": "#6c4675",
		"blueviolet": "#8a2be2",
		"blush": "#DE5D83",
		"brickred": "#CB4154",
		"brown": "#a52a2a",
		"burlywood": "#deb887",
		"burntsienna": "#EA7E5D",
		"cadetblue": "#5f9ea0",
		"canary": "#ffff99",
		"caribbeangreen": "#1cd3a2",
		"carnationpink": "#ffaacc",
		"cerise": "#dd4492",
		"cerulean": "#1dacd6",
		"chartreuse": "#7fff00",
		"chestnut": "#bc5d58",
		"chocolate": "#d2691e",
		"copper": "#dd9475",
		"coral": "#ff7f50",
		"coralred": "#b32821",
		"cornflower": "#9aceeb",
		"cornflowerblue": "#6495ed",
		"cornsilk": "#fff8dc",
		"cottoncandy": "#ffbcd9",
		"crimson": "#dc143c",
		"cream": "fdf4e3",
		"cyan": "#00ffff",
		"dandelion": "#fddb6d",
		"darkblue": "#00008b",
		"darkcyan": "#008b8b",
		"darkgoldenrod": "#b8860b",
		"darkgray": "#a9a9a9",
		"darkgreen": "#006400",
		"darkgrey": "#a9a9a9",
		"darkkhaki": "#bdb76b",
		"darkmagenta": "#8b008b",
		"darkolivegreen": "#556b2f",
		"darkorange": "#ff8c00",
		"darkorchid": "#9932cc",
		"darkred": "#8b0000",
		"darksalmon": "#e9967a",
		"darkseagreen": "#8fbc8f",
		"darkslateblue": "#483d8b",
		"darkslategray": "#2f4f4f",
		"darkslategrey": "#2f4f4f",
		"darkturquoise": "#00ced1",
		"darkviolet": "#9400d3",
		"deeppink": "#ff1493",
		"deeporange": "#ec7c26",
		"deepskyblue": "#00bfff",
		"denim": "#2b6cc4",
		"desertsand": "#efcd88",
		"dimgray": "#696969",
		"dimgrey": "#696969",
		"dodgerblue": "#1e90ff",
		"eggplant": "#6e5160",
		"electriclime": "#ceff1d",
		"fern": "#71bc78",
		"firebrick": "#b22222",
		"floralwhite": "#fffaf0",
		"forestgreen": "#228b22",
		"fuchsia": "#ff00ff",
		"gainsboro": "#dcdcdc",
		"ghostwhite": "#f8f8ff",
		"gold": "#ffd700",
		"goldenrod": "#daa520",
		"gray": "#95918c",
		"green": "#008000",
		"greenblue": "#1164b4",
		"greenyellow": "#adff2f",
		"grey": "#808080",
		"honeydew": "#f0fff0",
		"honeyyellow": "#a98307",
		"hotpink": "#ff69b4",
		"indianred": "#cd5c5c",
		"indigo": "#4b0082",
		"ivory": "#fffff0",
		"lightivory": "e1cc4f",
		"jetblack": "0a0a0a",
		"junglegreen": "#3bb08f",
		"khaki": "#f0e68c",
		"khakigrey": "#6a5f31",
		"laserlemon": "#fefe22",
		"lavender": "#e6e6fa",
		"lavenderblush": "#fff0f5",
		"lawngreen": "#7cfc00",
		"lemonchiffon": "#fffacd",
		"lemonyellow": "#fff44f",
		"lightblue": "#add8e6",
		"lightcoral": "#f08080",
		"lightcyan": "#e0ffff",
		"lightgoldenrodyellow": "#fafad2",
		"lightgray": "#d3d3d3",
		"lightgreen": "#90ee90",
		"lightgrey": "#d3d3d3",
		"lightpink": "#ffb6c1",
		"lightsalmon": "#ffa07a",
		"lightseagreen": "#20b2aa",
		"lightskyblue": "#87cefa",
		"lightslategray": "#778899",
		"lightslategrey": "#778899",
		"lightsteelblue": "#b0c4de",
		"lightyellow": "#ffffe0",
		"lime": "#00ff00",
		"limegreen": "#32cd32",
		"linen": "#faf0e6",
		"luminousorange": "#ff2301",
		"maccasin": "ffe4b5",
		"magenta": "#ff00ff",
		"magicmint": "#aaf0d1",
		"mahogany": "#cd4a4c",
		"maroon": "#800000",
		"maize": "#edd19c",
		"mediumaquamarine": "#66cdaa",
		"mediumblue": "#0000cd",
		"mediumorchid": "#ba55d3",
		"mediumpurple": "#9370db",
		"mediumseagreen": "#3cb371",
		"mediumslateblue": "#7b68ee",
		"mediumspringgreen": "#00fa9a",
		"mediumturquoise": "#48d1cc",
		"mediumvioletred": "#c71585",
		"melon": "#fdbcb4",
		"midnightblue": "#191970",
		"mintcream": "#f5fffa",
		"mintgreen": "#20603D",
		"mistyrose": "#ffe4e1",
		"moccasin": "#ffe4b5",
		"mountainmeadow": "#30ba8f",
		"mulberry": "#c54b8c",
		"navajowhite": "#ffdead",
		"navy": "#000080",
		"navyblue": "#1974d2",
		"oldlace": "#fdf5e6",
		"olive": "#808000",
		"olivegreen": "#bab86C",
		"oliveyellow": "#999950",
		"olivedrab": "#6b8e23",
		"orange": "#ffa500",
		"orangered": "#ff4500",
		"orangeyellow": "#f8d568",
		"orchid": "#da70d6",
		"outerspace": "#414a4c",
		"oysterwhite": "#eae6ca",
		"pacificblue": "#1ca9c9",
		"palegoldenrod": "#eee8aa",
		"palegreen": "#98fb98",
		"paleturquoise": "#afeeee",
		"palevioletred": "#db7093",
		"papayawhip": "#ffefd5",
		"papyruswhite": "d7d7d7",
		"pastelyellow": "#efa94a",
		"pastelorange": "#f44611",
		"peach": "#ffcfab",
		"peachpuff": "#ffdab9",
		"periwinkle": "#c5d0e6",
		"pearldarkgrey": "#828282",
		"pearlgreen": "#1c542d",
		"pearlopalgreen": "#193737",
		"peru": "#cd853f",
		"pinegreen": "#158078",
		"pink": "#ffc0cb",
		"pinkflamingo": "#FC74FD",
		"plum": "#dda0dd",
		"darkenedplum": "#8e4585",
		"powderblue": "#b0e0e6",
		"puregreen": "#008f39",
		"purple": "#800080",
		"purpleheart": "#7442c8",
		"raspberryred": "#c51d34",
		"rawumber": "#714b23",
		"rebeccapurple": "#663399",
		"red": "#ff0000",
		"redviolet": "#c0448f",
		"redorange": "#ff5349",
		"rose": "#E63244",
		"rosybrown": "#bc8f8f",
		"royalblue": "#4169e1",
		"royalpurple": "#7851a9",
		"rubyred": "#9b111e",
		"saddlebrown": "#8b4513",
		"saffronyellow": "#f5d033",
		"salmon": "#fa8072",
		"sandybrown": "#f4a460",
		"sandyellow": "#c6a664",
		"scarlet": "#fc2847",
		"seagreen": "#2e8b57",
		"seashell": "#fff5ee",
		"sepia": "#a5694f",
		"shadow": "#8a795d",
		"shamrock": "#45Cea2",
		"sienna": "#a0522d",
		"silver": "#c0c0c0",
		"skyblue": "#87ceeb",
		"slateblue": "#6a5acd",
		"slategray": "#708090",
		"slategrey": "#708090",
		"snow": "#fffafa",
		"springgreen": "#00ff7f",
		"steelblue": "#4682b4",
		"sunglow": "#ffcf48",
		"sunsetorange": "#fd5e53",
		"tan": "#d2b48c",
		"teal": "#008080",
		"tealblue": "#18a7b5",
		"thistle": "#d8bfd8",
		"timberwolf": "#dbd7d2",
		"tomato": "#ff6347",
		"tropicalrainforest": "#17806d",
		"tumbleweed": "#deaa88",
		"turquoise": "#40e0d0",
		"turquoiseblue": "#77dde7",
		"unmellowyellow": "#ffff66",
		"vermillion": "#cb2821",
		"violet": "#ee82ee",
		"violetblue": "#324ab2",
		"Violetred": "#f75394",
		"vividtangerine": "#ffa089",
		"vividviolet": "#8f509d",
		"wheat": "#f5deb3",
		"winered": "#5e2129",
		"white": "#ffffff",
		"whitesmoke": "#f5f5f5",
		"wildstrawberry": "#ff43a4",
		"wildwatermelon": "#fc6c85",
		"wisteria": "#cda4de",
		"yellow": "#ffff00",
		"yellowgreen": "#9acd32",
		"yelloworange": "ffae42",
		"zincyellow": "#F8F32B"
	};
}

/**
 * Takes in a keyword, matches it within an objectPhrase, and assigns it a score.
 * The algorithm currently used is a basic "phrase contains keyword" algorithm, and the score is just the length of the keyword.
 * (This allows for, for example, the keyword "phone number" to match the phrase "phone number" better than the keyword "number" would,
 * but prevents the keyword "phone number" from matching the phrase "number"). This may be refined later to include something like Hamming distance
 * or other more advanced algorithms.
 * 
 * @param {string} objectPhrase 
 * @param {string} keyword 
 * @returns {object} An object including the matched keyword, the remaining keywords, and the score
 */
function _matchKeyword(objectPhrase, keyword) {
	keyword = keyword ? keyword.toLowerCase().trim() : '';
	objectPhrase = objectPhrase ? objectPhrase.toLowerCase().trim() : '';
	if (objectPhrase.includes(keyword)) {
		return {
			matchedKeywords: keyword,
			remainingKeywords: objectPhrase.replace(keyword, ''),
			score: keyword.length
		};
	} else {
		return {
			matchedKeywords: null,
			remainingKeywords: objectPhrase,
			score: 0
		};
	}
}

/**
 * Helper function to iterate over and match both view and edit variants.
 * 
 * @param {array} variantArr Array of candidate variants
 * @param {string} objectPhrase Set of keywords being matched
 * @returns {array} Array of most likely variants as well as information regarding match score and reasoning
 */
function _getVariantMatches(objectPhrase, variantArr) {
	// If the variantArr nonexistent or an empty array, just return []
	if (!variantArr || !variantArr.length) {
		return [];
	}
	// If there's only one view variant, then it must be the best match.
	if (variantArr.length === 1) {
		return variantArr;
	}
	// Otherwise, process the logic to determine which is the best match.
	let highScore = 0, bestMatches = variantArr;
	variantArr.forEach(variant => {
		let synArray = [];
		try {
			synArray = variant.searchSynonyms ? JSON.parse(variant.searchSynonyms) : [];
		} catch (err) {
			console.warn('Attempted to parse searchSynonyms for viewVariant %s and failed. searchSynonyms were', variant.name, variant.searchSynonyms);
		}
		synArray.forEach(synonym => {
			let match = _matchKeyword(objectPhrase, synonym);
			if (match.score > highScore) {
				bestMatches = [Object.assign(variant, match)];
				highScore = match.score;
			} else if (match.score && match.score === highScore) {
				bestMatches.push(Object.assign(variant, match));
			}
		});
	});
	return bestMatches;
}

/**
 * Attempts to determine the variants that match a given set of keywords.
 * 
 * @param {string} objectPhrase A 'phrase' containing keywords used for identification
 * @param {string} fieldType The recordId of the fieldType of which to find the variants. (Optional - all will be checked otherwise.)
 * 
 * @returns {array} An array of objects including the variants potentially being returned here, the keywords matched and the remaining keywords.
 */
function _getVariants(objectPhrase, fieldTypeId) {
	let toReturn = [];
	let fieldTypeObj = fieldTypeId ? FieldTypeStore.get(fieldTypeId) : null;
	// Either use the fieldTypeObj from the fieldTypeId provided (in an array) or get all of the Field Types from the store.
	let fieldTypes = fieldTypeObj ? [fieldTypeObj] : FieldTypeStore.getAllArray();
	fieldTypes.forEach(fieldType => {
		let variants = {
			view: [],
			edit: []
		}
		fieldType.variants.forEach(variant => {
			variants[variant.type].push(variant);
		});
		let viewBestMatches = _getVariantMatches(objectPhrase, variants.view);
		let editBestMatches = _getVariantMatches(objectPhrase, variants.edit);
		viewBestMatches.forEach(viewVariant => {
			editBestMatches.forEach(editVariant => {
				let toPush = {
					fieldType: fieldType.recordId,
					fieldTypeName: fieldType.name,
					viewVariant: {
						name: viewVariant.name,
						reactComponentName: viewVariant.reactComponentName,
						matchedKeywords: viewVariant.matchedKeywords,
						remainingKeywords: viewVariant.remainingKeywords,
						score: viewVariant.score
					},
					editVariant: {
						name: editVariant.name,
						reactComponentName: editVariant.reactComponentName,
						matchedKeywords: editVariant.matchedKeywords,
						remainingKeywords: editVariant.remainingKeywords,
						score: editVariant.score
					}
				};
				if (viewVariant.matchedKeywords || viewVariant.remainingKeywords) {
					let viewKeywords = viewVariant.matchedKeywords || '';
					let editKeywords = editVariant.matchedKeywords || '';
					let viewScore = viewVariant.score || 0;
					let editScore = editVariant.score || 0;
					toPush.matchedKeywords = viewKeywords + (viewKeywords && editKeywords ? ' ' : '') + editKeywords;
					toPush.remainingKeywords = objectPhrase.replace(viewKeywords, '').replace(editKeywords, '');
					toPush.score = viewScore + editScore;
				}
				toReturn.push(toPush);
			});
		});
	});
	return toReturn;
}

/**
 * Process the userInput and returns 2 arrays of words 
 * userInputStripped Array-> to evaluate them against a Dictionary
 * quotedValues Array -> User Input Values to use.
 *  
 * @param {String} userInput 
 * @returns {Object} - Returns an Object of 2 arrays, the array of values for pre-match and the array of values in quotes 
 */
function _wordsToEvaluate(userInput) {
	// Strip the input for our matching needs...
	let userInputStripped = userInput,
		quotedValues = [];

	// Remove values from the input which are in single and double quotes and store them in quotedValues array
	// Double quote Values:
	let dqRegex = /".*?"/g,
		wordInQuote = userInputStripped.match(dqRegex);

	if (wordInQuote) {
		//Store the values in Quotes
		wordInQuote.forEach(value => {
			quotedValues.push(value.substring(1, value.length - 1));
		});
		// Remove these values from the input.
		userInputStripped = userInputStripped.replace(dqRegex, '');
	}

	// Transform Input to lowercase and into an Array
	userInputStripped = userInputStripped.toLowerCase().split(' ');

	// Remove words that are actually just spaces
	userInputStripped = userInputStripped.filter(userInput => {
		return userInput;
	})
		// Remove punctuation (.?!) from the end of anyword.
		.map(userInput => {
			let lastChar = userInput.slice(-1);
			if (lastChar === '!' || lastChar === '.' || lastChar === '?') {
				return userInput.slice(0, userInput.length - 1);
			}
			return userInput;
		});

	return {
		userInputStripped: userInputStripped,
		quotedValues: quotedValues
	}
}


/**
 * Returns an Array of metadata matches to pass to nlp-processor
 * 
 * @param {Array} dictionary - The dictionary Array
 * @param {Array} inputArray - the user input, in Array format
 * @returns 
 */
function _metadataMatches(dictionary, inputArray) {

	//Temporary Matches Storage - They guarantee no match is repeated 
	let matchesObject = {};

	//The final Array to pass to the nlp-processor 
	let metaDataResultsArray = [];

	//Loop through each word of the user input
	inputArray.forEach(wordToFind => {
		//Stores 1 match at a time if found in the passed dictionary
		let foundMetadataMatch = _findMetaData(dictionary, wordToFind);

		//for each match found, populate the matchesObject. It guarantees, no repeated matches
		Object.keys(foundMetadataMatch).forEach(matchKey => {
			//Include a matchCount property to put the best match to the top
			let matchCount = 0;

			//If metadata doesnt exist yet in the object, create its count property 
			if (!matchesObject[matchKey]) {
				matchCount++;
				matchesObject[matchKey] = foundMetadataMatch[matchKey];
				matchesObject[matchKey]['matchCount'] = matchCount;
			} else {
				//Keep track of the old match Value 
				let oldMatch = matchesObject[matchKey]['matchCount'];

				//ReWrite the Object if it alreaady Exists
				matchesObject[matchKey] = foundMetadataMatch[matchKey];

				//Add the countVariable again:
				matchesObject[matchKey]['matchCount'] = oldMatch + 1;

			}
		});
	});
	//Once all the Matches are found, turn the object into an array to be processed by nlp-processor 
	metaDataResultsArray = _turnObjectIntoArray(matchesObject);

	return metaDataResultsArray;
}

/**
 * Helper method to get a dictionary of fields attached to a page, keyed by the record 
 * and the name of an attachment point.
 * @param {object} childAttachmentPoint The attachment point whose children to find
 */
function _getAttachmentPointInfo(childAttachmentPoint) {

	// Initialize the variables
	let childAttachmentPointName = '';
	let attachedFieldsJSON = '[]';


	// If the current context is a page or a top-level field on a page, get its information from the page store
	if (childAttachmentPoint.componentType === 'page') {
		let pageObj = PageStore.get(childAttachmentPoint.componentId) || {};
		attachedFieldsJSON = pageObj.attachedFields || '[]';
		childAttachmentPointName = pageObj.name;
	} else if (childAttachmentPoint.componentType === 'field') {
		// Otherwise, get it from the field store
		let parentFieldObj = FieldStore.get(childAttachmentPoint.componentId) || {};
		let parentFieldSettings = ObjectUtils.getObjFromJSON(parentFieldObj.settings);
		attachedFieldsJSON = parentFieldSettings.attachedFields || parentFieldSettings.childFields || '[]';
		childAttachmentPointName = parentFieldSettings.fieldLabel;
	} else {
		console.warn('Unknown configuration in getMetadataMatches. childAttachmentPoint was', childAttachmentPoint);
	}

	let attachedFields = [];

	// Parse the attachedFields. We're not using objectUtils because this gives an array if empty, not an object
	try {
		attachedFields = attachedFieldsJSON ? JSON.parse(attachedFieldsJSON) : [];
	} catch (err) {
		console.warn('Error parsing attachedFields in getMetadataMatches. Value was', attachedFieldsJSON);
		attachedFields = [];
	}

	// Map the attachedFields array into an object for easier lookup, as it will be used repeatedly and this improves performance
	let attachedFieldsDictionary = {};
	attachedFields.forEach(attachedField => {
		if(attachedField.recordIds) {
			attachedField.recordIds.forEach(recordId => {
				attachedFieldsDictionary[recordId] = true;
			});
		} else {
			attachedFieldsDictionary[attachedField.recordId] = true;
		}
	});

	// Return
	return { childAttachmentPointName, attachedFieldsDictionary };
}

/**
 * Helper method to find the metadata matches for a specific set of keywords and attachment point.
 * Will not include fields which are already attached to the attachment point.
 * 
 * @param {string} objectPhrase The keywords being matched
 * @param {string} tableSchemaName The name of the table matching the current context
 * @param {object} attachedFieldsDictionary An object keyed by the record ID of fields attached to a context point
 * @param {object} childAttachmentPoint An object containing information about the context point being attached to
 * @param {string} childAttachmentPointName The name of the context point being attached to
 */
function _getMetadataMatchesAttach(objectPhrase, tableSchemaName, attachedFieldsDictionary, childAttachmentPoint, childAttachmentPointName) {
	// Get all fields on the table that are not already attached to the page/field
	let fields = FieldStore.getByTableSchemaName(tableSchemaName).filter(field => {
		return !attachedFieldsDictionary[field.recordId];
	});

	// let possibleRoles = toExport.guessFieldRoles(childAttachmentPoint);

	let highScore = 0, bestMatches = {};
	// Look over all of the fields and find all of the ones that best match the keywords.
	fields.forEach(fieldObj => {
	
		// We don't want to permit a field to be attached as its own child. That won't end well.
		// (It's extremely unlikely that a page will have the same recordId as a field but just to be safe)
		if(fieldObj.recordId === childAttachmentPoint.componentId && childAttachmentPoint.componentType === 'field') {
			return;
		}
		let assignTemplate = {
			componentType: 'field',
			componentId: fieldObj.recordId,
			parentComponentId: childAttachmentPoint.componentId,
			parentComponentType: childAttachmentPoint.componentType,
			parentLabel: childAttachmentPointName
		};
		if (!objectPhrase) {
			bestMatches[fieldObj.recordId] = Object.assign({}, assignTemplate, {
				score: 0,
				matchedKeywords: '',
				remainingKeywords: ''
			});
		} else {
			let settings = ObjectUtils.getObjFromJSON(fieldObj.settings);
			let { fieldLabel, searchSynonyms } = settings;
			let searchableArray = [];
			if (fieldLabel) {
				searchableArray.push(fieldLabel);
			}
			if (searchSynonyms) {
				try {
					let searchSynonymsArray = JSON.parse(searchSynonyms);
					searchableArray = searchableArray.concat(searchSynonymsArray);
				} catch (err) {
					console.warn('Error parsing searchSynonyms in getMetadataMatches. Value was', searchSynonyms);
				}
			}
			searchableArray.forEach(searchable => {
				let match = _matchKeyword(objectPhrase, searchable);
				let score = match.score;
				if (score > highScore) {
					// If there's a new high score, we don't want to keep the lower scoring records. Remove and replace.
					bestMatches = {};
					bestMatches[fieldObj.recordId] = Object.assign({}, assignTemplate, match);
					highScore = score;
				} else if (score && score === highScore) {
					// If this matches the existing  high score, we don't want to overwrite the equally scoring records. Insert into the object.
					bestMatches[fieldObj.recordId] = Object.assign({}, assignTemplate, match);
				}
			});
		}
	});
	// possibleRoles.forEach(role => {
	// });


	// Return the best matches.
	return bestMatches;
}

/**
 * Helper method to find the metadata matches for a specific set of keywords and detachment point.
 * Will only include fields which are attached to the detachment point.
 * 
 * @param {string} objectPhrase The keywords being matched
 * @param {string} tableSchemaName The name of the table matching the current context
 * @param {object} attachedFieldsDictionary An object keyed by the record ID of fields attached to a context point
 * @param {object} childAttachmentPoint An object containing information about the context point being detached from
 * @param {string} childAttachmentPointName The name of the context point being detached from
 */
function _getMetadataMatchesDetach(objectPhrase, tableSchemaName, attachedFieldsDictionary, childAttachmentPoint, childAttachmentPointName) {
	
	// Get all fields on the table that are already attached to the page/field
	let fields = FieldStore.getByTableSchemaName(tableSchemaName).filter(field => {
		return !!attachedFieldsDictionary[field.recordId];
	});

	// Look over all of the fields and find all of the ones that best match the keywords.
	let highScore = 0, bestMatches = {};
	fields.forEach(fieldObj => {
		let settings = ObjectUtils.getObjFromJSON(fieldObj.settings);
		let { fieldLabel, searchSynonyms } = settings;
		let searchableArray = [];
		if (fieldLabel) {
			searchableArray.push(fieldLabel);
		}
		if (searchSynonyms) {
			try {
				let searchSynonymsArray = JSON.parse(searchSynonyms);
				searchableArray = searchableArray.concat(searchSynonymsArray);
			} catch (err) {
				console.warn('Error parsing searchSynonyms in getMetadataMatches. Value was', searchSynonyms);
			}
		}
		searchableArray.forEach(searchable => {
			let assignTemplate = {
				componentType: 'field',
				componentId: fieldObj.recordId,
				parentComponentId: childAttachmentPoint.componentId,
				parentComponentType: childAttachmentPoint.componentType,
				parentLabel: childAttachmentPointName
			};
			if (!objectPhrase) {
				bestMatches[fieldObj.recordId] = Object.assign({}, assignTemplate, {
					score: 0,
					matchedKeywords: '',
					remainingKeywords: ''
				});
			} else {
				let match = _matchKeyword(objectPhrase, searchable);
				let score = match.score;
				if (score > highScore) {
					// If there's a new high score, we don't want to keep the lower scoring records. Remove and replace.
					bestMatches = {};
					bestMatches[fieldObj.recordId] = Object.assign({}, assignTemplate, match);
					highScore = score;
				} else if (score && score === highScore) {
					// If this matches the existing  high score, we don't want to overwrite the equally scoring records. Insert into the object.
					bestMatches[fieldObj.recordId] = Object.assign({}, assignTemplate, match);
				}
			}
		});
	});

	// Return the best matches.
	return bestMatches;
}

/**
 * Helper method to get the metadataMatches specifically for update operations.
 * Update operations should prioritize updating fields attached as children/siblings of the current component.
 * This prioritization does not occur if a match has already been found (e.g., for "make this red")
 * 
 * @param {string} objectPhrase The keywords being matched
 * @param {object} contextComponent Object with information about the current context component
 * @param {object} parentComponent Object with information about the parent context component
 * @param {string} tableSchemaName The name of the table matching the current context
 * @param {array} matches Any matches already found
 */
function _getMetadataMatchesUpdate(objectPhrase, contextComponent, parentComponent, tableSchemaName, matches) {
	// Account for both fields on the page and any child fields of the current field.
	// Because we only update global and not local settings, we only want each field to appear once, even if present on the page multiple times.

	// Get all attached fields from the current context component
	let { attachedFieldsDictionary } = _getAttachmentPointInfo(contextComponent);
	// Get all attached fields from the parent context component
	let { attachedFieldsDictionary: parentAttachedFieldsDictionary } = _getAttachmentPointInfo(parentComponent);

	// Combine them into one dictionary
	attachedFieldsDictionary = Object.assign({}, attachedFieldsDictionary, parentAttachedFieldsDictionary);


	let fields = FieldStore.getByTableSchemaName(tableSchemaName);
	// Look over all of the fields and find all of the ones that best match the keywords.
	// Here, we make bestMatches an object to avoid duplicate matches.
	// It may be more appropriate as an array elsewhere.
	let highScore = 0, bestMatches = {};
	fields.forEach(fieldObj => {
		let settings = ObjectUtils.getObjFromJSON(fieldObj.settings);
		let { fieldLabel, searchSynonyms } = settings;
		let searchableArray = [];
		if (fieldLabel) {
			searchableArray.push(fieldLabel);
		}
		if (searchSynonyms) {
			try {
				let searchSynonymsArray = JSON.parse(searchSynonyms);
				searchableArray = searchableArray.concat(searchSynonymsArray);
			} catch (err) {
				console.warn('Error parsing searchSynonyms in getMetadataMatches. Value was', searchSynonyms);
			}
		}
		searchableArray.forEach(searchable => {
			let match = _matchKeyword(objectPhrase, searchable);
			let assignTemplate = {
				componentType: 'field',
				componentId: fieldObj.recordId
			};
			// We don't want this to show all fields on the page if there's already a 'this' or 'parent' found
			let score = match.score + (attachedFieldsDictionary[fieldObj.recordId] && !matches.length ? 1 : 0);
			if (score > highScore) {
				// If there's a new high score, we don't want to keep the lower scoring records. Remove and replace.
				bestMatches = {};
				bestMatches[fieldObj.recordId] = Object.assign({}, assignTemplate, match);
				highScore = score;
			} else if (score && score === highScore) {
				// If this matches the existing  high score, we don't want to overwrite the equally scoring records. Insert into the object.
				bestMatches[fieldObj.recordId] = Object.assign({}, assignTemplate, match);
			}
		});
	});
	return bestMatches;
}

/**
 * Helper method to get the metadataMatches specifically for delete operations.
 * Delete operations should prioritize deleting fields on the current table, but allow for deletion of all fields
 * This prioritization does not occur if a match has already been found (e.g., for "delete this")
 * 
 * @TODO: Support deletion of other component types
 * @TODO: Further prioritize fields on the current page/children of the current field
 * 
 * @param {string} objectPhrase The keywords being matched
 * @param {string} tableSchemaName The name of the table matching the current context
 * @param {array} matches Any matches already found
 * @returns {object}
 */
function _getMetadataMatchesDelete(objectPhrase, tableSchemaName, matches) {
	// Rank fields on table above other fields, but permit access to all.
	let fields = FieldStore.getAllArray();
	let highScore = 0, bestMatches = {};

	// Look over all of the fields and find all of the ones that best match the keywords.
	fields.forEach(fieldObj => {
		let settings = ObjectUtils.getObjFromJSON(fieldObj.settings);
		let { fieldLabel, searchSynonyms } = settings;
		let searchableArray = [];
		if (fieldLabel) {
			searchableArray.push(fieldLabel);
		}
		if (searchSynonyms) {
			try {
				let searchSynonymsArray = JSON.parse(searchSynonyms);
				searchableArray = searchableArray.concat(searchSynonymsArray);
			} catch (err) {
				console.warn('Error parsing searchSynonyms in getMetadataMatches. Value was', searchSynonyms);
			}
		}
		searchableArray.forEach(searchable => {
			let match = _matchKeyword(objectPhrase, searchable);
			let fieldTableSchemaName = fieldObj.tableSchemaName;
			let assignTemplate = {
				componentType: 'field',
				componentId: fieldObj.recordId
			};
			// We don't want this to show all fields on the table if there's already a 'this' or 'parent' found
			let score = match.score + (tableSchemaName === fieldTableSchemaName && !matches.length ? 1 : 0);
			if (score > highScore) {
				// If there's a new high score, we don't want to keep the lower scoring records. Remove and replace.
				bestMatches = {};
				bestMatches[fieldObj.recordId] = Object.assign({}, assignTemplate, match);
				highScore = score;
			} else if (score && score === highScore) {
				// If this matches the existing  high score, we don't want to overwrite the equally scoring records. Insert into the object.
				bestMatches[fieldObj.recordId] = Object.assign({}, assignTemplate, match);
			}
		});
	});

	return bestMatches;
}

/**
 * Loops through an Array of meta data objects and selects the matching Objects by searching the label for the input.
 * 
 * @param {Array} metaDataArray - Array of metadata to search
 * @param {String} wordToFind - String that is the word we're looking for, in the "label" of the Array of objects.
 * @return {Object} Object of metadata objects that matched the word to find, key'd by ID.
 */
function _findMetaData(metaDataArray, wordToFind) {
	// Keep track of what ID's we've added to our return array
	let returnObj = {};

	// Strip possible punctuation from our word to Find
	wordToFind = wordToFind.replace('.', '').replace(',', '').replace('?', '').replace('!', '');

	// Loop over the metadata array for each object
	metaDataArray.forEach(metaDataObj => {
		let searchable = metaDataObj.searchable;
		if (searchable) {

			//Patterns are returned as Array but other componenttypes are strings from NLP dictionaries
			if (!Array.isArray(searchable)) {
				searchable = searchable.split(' ');
			}

			searchable.forEach(mdSearchableWord => {
				// If there is a match between the metadata label word and wordToFind...

				// if(mdSearchableWord.toLowerCase().substr(0, searchLength) === wordToFind){
				if (mdSearchableWord.toLowerCase() === wordToFind) {

					// If our ID is NOT in the returnId's list...
					if (!returnObj[metaDataObj.id]) {

						// Add to the returnArray
						returnObj[metaDataObj.id] = ObjectUtils.copyObj(metaDataObj);
					}
				}
			});
		}
	});
	return returnObj;
}
/**
 * Includes the context metadata in matches Array
 * 
 * @param {any} metadataId 
 * @param {any} matchesArray 
 * @param {any} dictionary 
 * @returns 
 */
function _includeMetadataFromContext(metadataId, matchesArray, dictionary) {
	//Update array: 
	let newMatchesArray = matchesArray;

	//Handle the Field from the pin we clicked on
	let contextMetadataId = metadataId;

	let matchIndex = undefined;
	// Check the field matchs (from our typing) to see if we referenced our own field
	for (let i = 0; i < newMatchesArray.length; i++) {
		// Check to see if the field from the matchs is our field...
		if (newMatchesArray[i]['id'] === contextMetadataId) {
			matchIndex = i;
			break;
		}
	}

	if (matchIndex === undefined) {
		let metadataObj = {};

		// Find it in the dictionary so we can get data from it
		for (let j = 0; j < dictionary.length; j++) {
			if (dictionary[j]['id'] === contextMetadataId) {
				metadataObj = dictionary[j];
				break;
			}
		}

		//Transform into pre-match obj to pass to nlp-processor
		newMatchesArray.push({
			id: metadataObj['id'],
			label: (metadataObj['label'] ? metadataObj['label'] : ''),
			matchCount: 1
		});
	} else {
		let addCount = 1;
		newMatchesArray[matchIndex]['matchCount'] += addCount;
	}

	//Return updated match array
	return newMatchesArray;
}
/**
 * Returns an array with elements that are in the object
 * 
 * @param {object} object Input Object
 * @returns {Array} Return array
 */
function _turnObjectIntoArray(object) {
	var returnArray = [];
	// Make an array from the field match object.
	Object.keys(object).forEach(function (objectKey) {
		returnArray.push(object[objectKey]);
	});
	return returnArray;
}

/**
 * Takes the results from the NlpProcessorObj and breaks them down into discrete proto-patterns by likely action, keyword and quoted values
 * 
 * @param {array} results The results of processing by the NlpProcessorObj
 * @param {object} quotedValuesObj Any quoted values to be swapped out
 * 
 * @returns {array} An array of proto-patterns broken down by likely action, keyword and quoted values
 */
function _formatPatternsNlp(results, quotedValuesObj) {

	// Initialize the patterns array to the empty array
	var patterns = [];

	// Each entry in the results is an "actionObj" consisting of information about the action identified by the NlpProcessorObj
	results.forEach(actionObj => {

		// Look at the action of the actionObj and try to find what it corresponds to and initialize len to the number of objects it's acting on
		var actions = toExport.getOperation(actionObj.action), len = actionObj.objects ? actionObj.objects.length : 0;

		function forEachAttachOrDetachChild(theseActions, attachOrDetachChild) {
			patterns.push({
				operation: theseActions,
				inputString: attachOrDetachChild,
				inputArray: attachOrDetachChild.split(' '),
				quotedValues: []
			});
		};

		// For each object the action is acting on
		for (var i = 0; i < len; i++) {

			// Clone the actions object so as to modify it without mutating the original
			var theseActions = Object.assign({}, actions);

			// Initialize variables used within loop
			var obj = actionObj.objects[i], toPush = [], adjectivePatterns = [], quotedObjs = JSON.parse(JSON.stringify(actionObj.quotedObjs || []));

			// If there are multiple actions and the object has a determiner of "a" or "an", we can guess that this will only match Create patterns.
			if (Object.keys(actions).length > 1 && obj.det && ['a', 'an'].indexOf(obj.det) > -1) {
				theseActions = { 'Create': 'Create' };
			} else if (Object.keys(actions).length > 1 && theseActions['Create']) {
				// Otherwise if there are multiple matched actions and there's no "a" or "an" determiner, it probably isn't "Create", so delete that key.
				delete theseActions['Create'];
			}

			// If no actions were found, we assume that it's probably some wacky verb that matches an update pattern. Add the verb to the keywords to match that, too.
			if (!Object.keys(actions).length) {
				toPush.push(actionObj.action);
				theseActions = { 'Update': 'Update' };
			}

			if ((theseActions['Attach'] || theseActions['Detach']) && actionObj.attachOrDetachChildren && actionObj.attachOrDetachChildren.length) {
				actionObj.attachOrDetachChildren.forEach(forEachAttachOrDetachChild.bind(this, theseActions));
				return patterns;
			}

			// If there's not already an 'Update' action being considered or there are multiple adjectives
			if (!theseActions['Update'] || (obj.objectAdjectives && obj.objectAdjectives.length > 2)) {

				var objectAdjectives = (obj.objectAdjectives || []), adjLen = objectAdjectives.length;

				for (var j = 0; j < adjLen; j++) {
					adjectivePatterns.push(_adjectivePatternBuilder(objectAdjectives[j], obj));
				}

			}

			// Now add the object adjectives and objectUnit into toPush to put into the keywords
			toPush = toPush.concat(obj.objectAdjectives);
			toPush.push(obj.objectUnit);

			// Add the full text of any prepositions to quotedObjs.
			// (This may need further refining once we can do stuff like "attach to this page" or "add this field to the Companies table")
			if (actionObj.preps) {
				quotedObjs.push(actionObj.preps[0].fullText);
			}

			// Obviously, if obj has any quotedObjs, put them into the quotedObjs array to be used for quotedValues
			if (obj.quotedObjs) {
				quotedObjs = quotedObjs.concat(obj.quotedObjs);
			}

			// Build the full proto-pattern to push into the patterns array
			var actionObjToPush = {
				operation: theseActions,
				inputString: toPush.join(' '),
				inputArray: toPush,
				quotedValues: quotedObjs,
				// fieldType: _guessFieldType(obj.objectUnit)
			};

			// Push actionObjToPush into the patterns array
			patterns.push(actionObjToPush);

			// Concatenate any adjectivePatterns built above to the patterns array
			patterns = patterns.concat(adjectivePatterns);
		}
	});

	// Return the patterns array
	return patterns;
};

/**
 * Thanks to the current behavior of the NLP, prepositions may sometimes be
 * nested within each other inappropriately. This flattens them out into a single
 * preposition array for review.
 * 
 * @param {array} preps Array of prepositions
 */
function _prepUnnester(preps) {
	let unnestedPreps = [];
	if(preps) {
		preps.forEach(prep => {
			unnestedPreps.push(prep);
			prep.pobjs.forEach(pobj => {
				if(pobj.preps) {
					unnestedPreps = unnestedPreps.concat(_prepUnnester(pobj.preps));
				}
			});
		});
	}
	return unnestedPreps;
}