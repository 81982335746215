import BlockUtils from './block-utils';
import { 
	AdminSettingsActions, 
	RelationshipActions
} from '../actions';
import {
	ContextStore, 
	FieldStore, 
	RelationshipStore, 
	TableStore
} from '../stores';
import uuid from 'uuid';
import UIUtils from './ui-utils';

export class RelationUtils {
	/**
	 * Add a Field
	 * @param {string} tableSchemaName Optional - Table Schema Name to set for new field.
	 */
	static addNewRelationship(tableSchemaName) {
		let recordId = uuid.v4();
		let newRelation = {
			recordId: recordId,
			ltorLabel: '',
			lCardinality: '',
			lTableSchemaName: ( tableSchemaName || ''),
			rtolLabel: '',
			rCardinality: '',
			rTableSchemaName: '',
			new: true
		};

		RelationshipActions.pushToStore(recordId, newRelation);

		UIUtils.openSettingsPanel('relationship', recordId, 'relationship');

		// Select the Field Label field.
		AdminSettingsActions.onSettingChange('relation-tables', 'f3e86ca3-48ed-474c-a21f-dcb8396ff0bc');
	}

	/**
	 * Determine if, given the inputted tableSchemaName, we're looking at this
	 * relationship from ltor or rtol.
	 * @param {string} relationId relationship to check
	 * @param {string} tableSchemaName Table to check for
	 * @returns undefined or string ltor or rtol
	 */
	static getRelationDirectionByTable(relationId, tableSchemaName) {
		let relationObj = RelationshipStore.get(relationId);
		if(!relationObj) {
			return undefined;
		}

		// If the table is in question is on the left...
		// Of the table on the right isn't the table either... 
		if(relationObj.lTableSchemaName === tableSchemaName || 
			relationObj.rTableSchemaName !== tableSchemaName) {
			return 'ltor';
		} else {
			return 'rtol';
		}
	}

	/**
	 * Returns the relationship cardinality Icon for this relationship, based on the table
	 * @param {string} relationId Relation ID to check
	 * @param {string} tableSchemaName table against which to check
	 * @returns 
	 */
	static getRelationIcon(relationId, tableSchemaName) {
		let relationObj = RelationshipStore.get(relationId);
		if(!relationObj) {
			return ContextStore.getUrlMedia() + '/relation-cardinality/one-to-many.svg';
		}

		let renames = {
			'1': 'one',
			'm': 'many'
		}

		let lCardinality = relationObj.lCardinality;
		let rCardinality = relationObj.rCardinality;

		if(!lCardinality || !rCardinality) {
			return ContextStore.getUrlMedia() + '/relation-cardinality/one-to-many.svg';
		} else {
			let direction = this.getRelationDirectionByTable(relationId, tableSchemaName);
			if(direction === 'ltor') {
				return ContextStore.getUrlMedia() + '/relation-cardinality/' +
					renames[rCardinality] + '-to-' + renames[lCardinality] + '.svg';	
			} else {
				return ContextStore.getUrlMedia() + '/relation-cardinality/' +
					renames[lCardinality] + '-to-' + renames[rCardinality] + '.svg';
			}
		}
	}

	/**
	 * Returns the relationship name for this relationship, based on the table
	 * @param {string} relationId Relation ID to check
	 * @param {string} tableSchemaName table against which to check
	 * @returns 
	 */
	static getRelationName(relationId, tableSchemaName) {
		let relationObj = RelationshipStore.get(relationId);
		if(!relationObj) {
			return '[ No Relationship Name Found ]';
		}

		let lCardinality = relationObj.lCardinality;
		let ltorLabel = relationObj.ltorLabel;
		let rCardinality = relationObj.rCardinality;
		let rtolLabel = relationObj.rtolLabel;

		if(!lCardinality || !rCardinality) {
			if(ltorLabel.length) {
				return ltorLabel;
			} else {
				return '[ No Relationship Name Found ]';
			}
		} else {
			let direction = this.getRelationDirectionByTable(relationId, tableSchemaName);
			if(direction === 'ltor') {
				return ltorLabel;
			} else {
				return rtolLabel;
			}
		}
	}

	/**
	 * Validates RelationSchemaName of new Relationship 
	 * 
	 * @static
	 * @param {String} schemaName - users' name for the block
	 * @param {String} currentRecordid - The ID of the current record being added
	 * @param {Array} errors - Any errors already existing
	 * @param {Array} additionalInvalidNames - Any additional names to consider invalid (used to power validation through MDGW)
	 * @returns {object} - { valid: true/false, validSchemaName: schemaName, errors: []};
	 * @memberof RelationUtils
	 */
	static validateRelationSchemaName(schemaName, currentRecordId, errors, additionalInvalidNames) {
		
		//General Check up for Schema Names 
		let returnObj = BlockUtils.validateSchemaName(schemaName);

		//Update the SchemaName with the General Validation for Blocks
		schemaName = returnObj['validSchemaName'];

		// SchemaName is required, can not be empty
		if(!schemaName){
			returnObj['isValidAndUnique'] = false;
			returnObj['validSchemaName'] = 'field1';
			returnObj['errors'].push('Schema Name is required');
			return returnObj;
		}
		
		//Push the Errors from previous recursive calls the new Errors found: 
		if(errors){
			//Dont repeat same error messages
			for(let i = 0; i < errors.length; i++){
				if(!returnObj['errors'].includes(errors[i]))
				returnObj['errors'].push(errors[i]);	
			}
		}
		
		// Get records for Fields, Relations and Tables
		let relationRecords = RelationshipStore.getAllArray(); 
		let fieldRecords = FieldStore.getAllArray();
		let tableRecords = TableStore.getAllArray();
		// Concatenate all the records
		let allRecords = []
			allRecords = allRecords.concat(relationRecords, fieldRecords, tableRecords);

		let schemaNamesAlreadyUsed = [];
		if(additionalInvalidNames && Array.isArray(additionalInvalidNames)) {
			schemaNamesAlreadyUsed = schemaNamesAlreadyUsed.map(schemaName => schemaName.toLowerCase()).concat(additionalInvalidNames);
		}
		
		//Look for the Table's relations and loop if any matches to what we are returning, error it 
		allRecords.forEach(record => {
			// do not include the current schemaName in the schemaNamesAlreadyUsed array
			if(record.recordId !== currentRecordId){
				// push fieldSchemaName, tableSchemaName and relationSchemaName in the schemaNamesAlreadyUsed artray
				if(record.relationSchemaName){
					schemaNamesAlreadyUsed.push(record.relationSchemaName.toLowerCase());
				} else if(record.fieldSchemaName){
					schemaNamesAlreadyUsed.push(record.fieldSchemaName.toLowerCase());
				} else if(record.tableSchemaName){
					schemaNamesAlreadyUsed.push(record.tableSchemaName.toLowerCase());
				}
			}
		});
		
		let digit = 1;
		//Should be unique 
		if(schemaNamesAlreadyUsed.includes(schemaName.toLowerCase())) {
			// Find the next number this schemaName should have as its sufix, to be valid 
			while(schemaNamesAlreadyUsed.includes(schemaName.toLowerCase() + digit)) {
				digit++;
			}

			//Split digits at the end of the string 
			let nextDigit = schemaName.match(/\d+$/);

			if(!Array.isArray(nextDigit)){
				nextDigit = 0;
			} else {
				nextDigit = nextDigit[0];
			}

			try {
				nextDigit = JSON.parse(nextDigit);
			} catch(error) {
				console.error(error.message);
			}

			//Remove the digits at the end of the string 
			let newSchemaName = schemaName.replace(/\d+$/, '');

			//add digit to current serial number 
			nextDigit += digit;

			//Join string and new Digit 
			newSchemaName += nextDigit;

			returnObj['isValidAndUnique'] = false;
			returnObj['validSchemaName'] = newSchemaName;
			returnObj['errors'].push('Technical Name already exits');
		} else {
			//Breaks the Recursion when All the Tests pass 
			returnObj['isValidAndUnique'] = true;
		}
		
		//Run the recursion: 
		if(!returnObj['isValidAndUnique']){
			//If we are still not unique then...run itself again
			return this.validateRelationSchemaName(returnObj['validSchemaName'], currentRecordId, returnObj['errors'])
		}

		return returnObj;
	}
}

export default RelationUtils;