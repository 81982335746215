import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { MetadataActions } from '../../../actions';
import { AdminSettingsStore, FieldStore, MetadataStore } from '../../../stores';

// Components;
import FieldTypeSettingsUI from '../../fieldtype-settings-ui.react';

/**
 * Right panel displaying Incoming Rest API Endpoint Settings
 */
class APIConfigSetting extends Component {
	constructor(props) {
		super(props)
		this._onSettingChange = this._onSettingChange.bind(this);
		this._onKeyDown = this._onKeyDown.bind(this);
	}
	
	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
	 return [ AdminSettingsStore, FieldStore, MetadataStore ]; 
	}

	/**
	 * Calculate the current state of the component
	 * @static
	 * @param  {Object} prevState Previous State
	 * @param  {Object} prevProps Previous Props
	 * @return {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId =  AdminSettingsStore.getRecordId();
		let settingRecordId = AdminSettingsStore.getSettingRecordId();
		let settingObj = FieldStore.get(settingRecordId);
		let value = '';

		// Pull the value from the MD Store
		if(settingObj){
			let settingSchemaName = settingObj.fieldSchemaName;

			let apiObj = MetadataStore.get(recordId, 'apiconfig');
			if(apiObj) {
				value = apiObj[settingSchemaName];
			}
		}

		return {
			settingObj: settingObj,
			settingRecordId: settingRecordId,
			recordId: recordId,
			value: value,
		};
	}

	/**
	 * Listen to key down events for a control/open apple S.. and save.
	 * 
	 * @todo Bailed out on this functionality because the save method is one component higher then this component and didn't
	 * have time to resolve.  But, this method runs when someone hits Control-S in the Appearance Settings area.
	 */
	_onKeyDown(event) {
		// Check if keys pressed are ctrl+s
		if(event.ctrlKey && event.key === 's') {
			console.warn('Ctrl-S to save not yet implemented.');
			event.preventDefault();
		}
		// when on MAC
		if(event.metaKey && event.key === 's') {
			console.warn('Ctrl-S to save not yet implemented.');
			event.preventDefault();
		}
	}
	

	/**
	 * Save the value as a value is entered
	 * @param {Object} event
	 */
	_onSettingChange(event) {
		let { recordId, settingObj } = this.state;
		let settingSchemaName = settingObj.fieldSchemaName;
		let value = event.target.value;
		let apiObj = MetadataStore.get(recordId, 'apiconfig');

		// Update the API Object with this setting, at its new value
		apiObj[settingSchemaName] = value;

		// Push the update API Object into the store.
		MetadataActions.pushToStore(recordId, 'apiconfig', apiObj);
	}

	/**
	 * Render the component
	 * @return JSX
	 */
	render() {
		let {recordId, value, settingObj } = this.state;

		if (!settingObj || !settingObj.fieldSchemaName) {
			return (<div className="select-setting">
						<div className="select-setting-text-wrap">
							Select a Setting to configure on the left.
						</div>
					</div>);
		}

		let settingSchemaName = settingObj.fieldSchemaName;
		let settingFieldType = settingObj.fieldType;
		let descriptiveText = settingObj.descriptiveText;
		let label = settingObj.fieldLabel;

		return (
			<div id="appearance-settings" onKeyDown={this._onKeyDown}>
				<div className="appearance-settings-container mx-3">
					<h3 className='my-1'>
						{label}
					</h3>
					<div className="setting-global mt-0">
						<div className={"setting-wrapper setting-" + settingSchemaName}>
							<FieldTypeSettingsUI
								recordId={recordId} 
								tableSchemaName='apiconfig'
								value={value} 
								setting={settingObj} 
								onChange={this._onSettingChange} 
								fieldType={settingFieldType} 
								label={label}
								skipDescriptiveText={true}
								dataRecordId={recordId}
								dataTableSchemaName='apiconfig'
								/>
								{descriptiveText && 
									<small id={'description-' + this.props.renderId} className='form-text text-muted' dangerouslySetInnerHTML={{ __html: descriptiveText || '' }}></small>
								}
						</div>
					</div>
				</div>
			</div>);
	}
}
const container = Container.create(APIConfigSetting, {withProps: true});
export default container;