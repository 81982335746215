import React, { Component } from 'react';
import { Container } from 'flux/utils';
import AdminSettingsStore from '../../../stores/admin-settings-store';
import AdminSettingsActions from '../../../actions/admin-settings-actions';
// import ContextActions from '../../../actions/context-actions';
import ContextStore from '../../../stores/context-store';
// import RenderActions from '../../../actions/render-actions';
// import { UIUtils } from '../../../utils';
import { ReactSVG } from 'react-svg';

/**
 * Renders interface of width/height input fields and desktop/tablet/mobile buttons.
 */
class ResizerControls extends Component {
  /**
   * Creates ResizerControls
   *
   * @param {Object} props
   * @constructor
   *
   * @memberOf ResizerControls
   */
  constructor(props) {
    super(props);
    this.setSize = this.setSize.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  /**
   * Loads the Stores to watch
   *
   * @static
   * @returns {Array of Object}
   *
   * @memberOf WindowFrame
   */
  static getStores() {
    return [AdminSettingsStore, ContextStore];
  }

  /**
   * Returns the current State of the FieldType Settings
   *
   * @static
   * @returns {Object}
   *
   * @memberOf WindowFrame
   */
  static calculateState(prevState, props) {
    let sizeObj = AdminSettingsStore.getSizeJS();
    let state = {
      'width': 0,
      'height': 0,
      'responsiveMode': ContextStore.getResponsiveMode(),
      'fontAwesomeUrl': ContextStore.getUrlFontawesome()
    };

    if (sizeObj.width) {
      state.width = sizeObj.width;
    }

    if (sizeObj.height) {
      state.height = sizeObj.height;
    }

    state.responsiveMode = ContextStore.getResponsiveMode();

    return state;
  }
  /**
   * setSize - resizes resizer container
   *
   * @param  {string} resizeMode mode passed - 'desktop', 'tablet', 'mobile'
   */
  setSize(resizeMode) {
    // if(resizeMode === this.state.responsiveMode) {
    //   // If the responsive mode hasn't changed, don't do any of this
    //   // (Solves the issue where clicking does not toggle the correct mode in all cases)
    //   return;
    // }
    let screenWidth = document.body.clientWidth || window.innerWidth;
    let screenHeight = document.body.clientHeight || window.innerHeight;

    // determine mode and set width and height
    let newWidth, newHeight;
    switch (resizeMode) {
      case 'lg':
        newWidth = Math.round(screenWidth - 350); // at least needs to be width - 100 to show the tools on the left without page content going under them.
        newHeight = Math.round(screenHeight * 0.98);
        break;
      case 'md':
        newWidth = 991;
        newHeight = Math.round(screenHeight * 0.98);
        break;
      case 'sm':
        newWidth = 767;
        newHeight = Math.round(screenHeight * 0.98);
        break;
      default:
        break;
    }

    // Get current size and compare with target size
    // if matching, resize to 80% of window
    // let currentSize = AdminSettingsStore.getSizeJS();
    // if (currentSize.width === newWidth) {
    //   // If you change these values (which are used on clicking the same size twice..) you also need to change them in index-dev.js
    //   newWidth = Math.round(screenWidth - 400); // at least needs to be width - 100 to show the tools on the left without page content going under them.
    //   newHeight = Math.round(screenHeight - 75);
    // }
    // dispatch actions with new width and height
    // let mode = UIUtils.getResponsiveMode(newWidth);
    // ContextActions.onResponsiveModeChange(mode);
    AdminSettingsActions.onCenterColumnResize({ 'width': newWidth, 'height': newHeight });
    // RenderActions.onCenterColumnResize({ 'width': newWidth, 'height': newHeight });
  }
  /**
   * handleChange - Handles input field changes
   *
   * @param  {Object} event event object fired
   */
  handleChange(event) {
    let width = this.state.width;
    let height = this.state.height;

    // determine which input field has been modified and set the new property value
    if (event.target.id === 'output__window-width') {
      width = event.target.value;
    } else {
      height = event.target.value;
    }
    // call onResize to dispatch action and notify store
    this.onResize(width, height);
  }
  /**
   * onResize - rounds width and height values, determines whether to set new
   * responsive mode within ContextStore, and updates AdminSettingsStore with new width/height values
   *
   * @param  {number} width  width of window
   * @param  {number} height height of window
   */
  onResize(width, height) {
    width = Math.round(width);
    height = Math.round(height);

    // if (width !== this.state.width) {
    //   let mode = UIUtils.getResponsiveMode(width);
    //   ContextActions.onResponsiveModeChange(mode);
    // }

    if (width !== this.state.width) {
      AdminSettingsActions.onCenterColumnResize({ 'width': width, 'height': height });
    }
    // clearTimeout(this.gridRecalcTimeout);
    // this.gridRecalcTimeout = setTimeout(() => {
    // }, 10);
  }
  /**
   * render - ResizerControls
   *
   * @return {JSX}
   */
  render() {
	let { responsiveMode, fontAwesomeUrl } = this.state;
    let icon = <ReactSVG 
					beforeInjection={svg => {
						svg.setAttribute('style', 'width: 25px; height: 25px');
					}}
					src={fontAwesomeUrl + '/desktop.svg'} 
					className="resizer-icon" 
					alt="desktop" />;
    if(responsiveMode === 'md') {
    	icon = <ReactSVG 
					beforeInjection={svg => {
						svg.setAttribute('style', 'width: 25px; height: 25px');
					}}
			src={fontAwesomeUrl + '/tablet.svg'} 
			className="resizer-icon" 
			alt="desktop" />;
    } else if (responsiveMode === 'sm') {
		icon = <ReactSVG 
					beforeInjection={svg => {
						svg.setAttribute('style', 'width: 25px; height: 25px');
					}}
					src={fontAwesomeUrl + '/mobile.svg'} 
					className="resizer-icon" 
					alt="desktop" />;
    }

    return (
      <nav id="resizer-controls">
        <div>
          <div className="dropdown">
			<div className="resizer-down-arrow"></div>
            <div title="Screen Size" className="dropdown-toggle form-inline" data-toggle="dropdown">
              {icon}
            </div>
            <div className="dropdown-menu">
            	<h4 className="resizer-title">View Screen Size</h4>
				<div className="screensize-container">
					<div onClick={this.setSize.bind(this, 'lg')} className="d-flex justify-content-between screensize-option">
						<div>
							<h5 className="bold">Desktop</h5>
							<h5>992px wide or higher</h5>
						</div>
						<div 
							id="desktop-button" 
							type="button" 
							className={responsiveMode === 'lg' ? 'active' : ''}>
								<ReactSVG 
									beforeInjection={svg => {
										svg.setAttribute('style', 'width: 25px; height: 25px');
									}}
									src={fontAwesomeUrl + '/desktop.svg'} 
									className="resizer-icon" 
									alt="desktop" />
						</div>
					</div>
					<div onClick={this.setSize.bind(this, 'md')} className="d-flex justify-content-between screensize-option">
						<div>
							<h5 className="bold">Tablet</h5>
							<h5>991px through 768px wide</h5>
						</div>
						<div 
							id="tablet-button" 
							type="button" 
							className={responsiveMode === 'md' ? 'active' : ''}>
								<ReactSVG 
									beforeInjection={svg => {
										svg.setAttribute('style', 'width: 25px; height: 25px');
									}}
									src={fontAwesomeUrl + '/tablet.svg'} 
									className="resizer-icon" 
									alt="tablet" />
						</div>
					</div>
					<div onClick={this.setSize.bind(this, 'sm')} className="d-flex justify-content-between screensize-option">
						<div>
							<h5 className="bold">Phone</h5>
							<h5>767px wide or lower</h5>
						</div>
						<div 
							id="tablet-button" 
							type="button" 
							className={responsiveMode === 'sm' ? 'active' : ''}>
								<ReactSVG 
									beforeInjection={svg => {
										svg.setAttribute('style', 'width: 25px; height: 25px');
									}}
									src={fontAwesomeUrl + '/mobile.svg'} 
									className="resizer-icon" 
									alt="mobile" />
						</div>
					</div>
				</div>
            	<div className="dropdown-divider"></div>
            	<div className="d-flex align-items-center height-container">
                	<label className="sr-only" htmlFor="output__window-width">Window Width</label>
                	<input 
						type="number" 
						className="form-control" 
						id="output__window-width" 
						value={this.state.width} 
						onChange={this.handleChange} />
                	<span> + </span>
                	<label className="sr-only" htmlFor="output__window-height">Window Height</label>
                	<input 
						type="number" 
						className="form-control" 
						id="output__window-height" 
						value={this.state.height} 
						onChange={this.handleChange} />
              	</div>
            </div>
          </div>
        </div>
      </nav>
    );
  };
}
const container = Container.create(ResizerControls);
export default container;