import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Container } from 'flux/utils';
import Immutable from 'immutable';
import DialogContainer from './components/dialog-container.react';
import { SizeMe } from 'react-sizeme'

// Disappointments
import $ from 'jquery';

// Bootstrap
// eslint-disable-next-line
require('popper.js/dist/umd/popper');
// eslint-disable-next-line
import { tooltip } from 'bootstrap';

// SCSS
import './styles/main.scss';

// Constants
// import GroupFilters from './constants/group-filters';

// Components - Third Party
import Helmet from 'react-helmet';
import { ResizableBox } from 'react-resizable';
import ElementQueries from 'css-element-queries/src/ElementQueries';
import { NotificationContainer } from 'react-notifications';

// Actions
import AdminSettingsActions from './actions/admin-settings-actions';
// import ContextActions from './actions/context-actions';
import MapActions from './actions/map-actions';
// import RenderActions from './actions/render-actions';

// Components
import DashboardPanel from './components/dashboard-panel.react';
import SettingsPanel from './components/settings-panel.react';
import SettingsPreview from './components/pins/settings-preview.react';
import ModalContainer from './components/modal-container.react';
import ReactBlocklyComponent from '@dmclain-citizendeveloper/citdev-react-blockly-component';

//Stores
import AdminSettingsStore from './stores/admin-settings-store';
import AuthenticationStore from './stores/authentication-store';
import ContextStore from './stores/context-store';
import MetadataStore from './stores/metadata-store';
import ToolboxStore from './stores/toolbox-store';

//Utils
// import { UIUtils } from './utils';

/**
 * Alpha UI app
 */
class App extends Component {
	/**
	 * Creates an instance of App
	 *
	 * @param {Object} props
	 * @constructor
	 *
	 * @memberOf App
	 */
	constructor(props) {
		super(props);
		this.onResize = this.onResize.bind(this);
		this.onResizeStart = this.onResizeStart.bind(this);
		this.onResizeStop = this.onResizeStop.bind(this);
		this.onLeftPanelTrigger = this.onLeftPanelTrigger.bind(this);
		this.onRightPanelTrigger = this.onRightPanelTrigger.bind(this);
	}
	/**
	 * @static getStores - description
	 *
	 * @return {type}  description
	 */
	static getStores() {
		return [ContextStore, AdminSettingsStore, MetadataStore, ToolboxStore];
	}
	/**
	 * Returns the current State of Context
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf App
	 */
	static calculateState(prevState, props) {

		// retreive theme builder css
		let themeBuilderCSS = '';
		let metadata = MetadataStore.get(ContextStore.getApplicationId(), 'applications');
		if (metadata !== undefined) {
			if(!metadata.hideCSS) {
				themeBuilderCSS = metadata.css;
			}
		}
		// Start by assuming its closed.
		let isLeftPanelOpen = false;
		// If we are authenticated, and hasDevTools.. then show it open, always.
		// @TODO Later, include a check for seeing if the tools themselves are collapsed or open
		// 'isLeftPanelOpen': AdminSettingsStore.getIsLeftPanelOpen(),
		if(localStorage.authenticated && AuthenticationStore.getHasDevTools()) {
			isLeftPanelOpen = true;
		}

		return {
			'context': ContextStore.getState(),
			'expressionToolbox': ToolboxStore.getExpressionToolbox(),
			'actionToolbox': ToolboxStore.getActionToolbox() || Immutable.List(),
			'isAuthenticated': localStorage.authenticated,
			'isRightPanelExpanded': true,
			'isRightPanelOpen': AdminSettingsStore.getIsRightPanelOpen(),
			'isLeftPanelOpen': isLeftPanelOpen,
			'sizeObj': AdminSettingsStore.getSize(),
			'selectedOverlay': AdminSettingsStore.getSelectedOverlay(),
			'themeBuilderCSS': themeBuilderCSS,
			'isSettingsPreviewOpen': AdminSettingsStore.getIsSettingsPreviewOpen(),
			'activeOverlays': AdminSettingsStore.getActiveOverlays(),
			'activeDashboard': AdminSettingsStore.getActiveDashboard(),
		};
	}
	/**
	 * onLeftPanelTrigger - dispatches action telling store if left panel is open.
	 *
	 * @param  {string} activeDashboard Which map is now active
	 * @param  {boolean} isOpen
	 * @param  {object} event       event object
	 */
	onLeftPanelTrigger(activeDashboard, isOpen, event) {
		event.preventDefault();
		let anchor = event.target;
		// find DOM node anchor
		while (anchor.nodeName !== 'A') {
			anchor = anchor.parentNode;
		}

		if ($(anchor).hasClass('close-button') || $(anchor).hasClass('active')) {
			// remove all active states on tabs
			$('.left-panel__tabs-menu a').removeClass('active');
			$('#data-map').removeClass('active');
			$('#site-map').removeClass('active');
			$('#logic-map').removeClass('active');
			AdminSettingsActions.onLeftPanelChange(activeDashboard, false);
			MapActions.search('');
		} else {
			let targetTab = $(anchor).attr('href');
			// hide previous active tab
			$(targetTab).siblings().removeClass('active');
			// remove all active states on tabs
			$('.left-panel__tabs-menu a').removeClass('active');
			// add active state to clicked tab only
			$(anchor).addClass('active');
			// display tab content based on href
			$(targetTab).show().siblings().hide();
			AdminSettingsActions.onLeftPanelChange(activeDashboard, true);
			MapActions.search('');
		}

		// // Set the default Group By 
		// switch(activeDashboard) {
		// 	case 'site': {
		// 		MapActions.groupBy(GroupFilters.TABLE_NAME);
		// 		break;
		// 	}
		// 	case 'data': {
		// 		MapActions.groupBy(GroupFilters.NAME);
		// 		break;
		// 	}
		// 	// Logic
		// 	// Reporting
		// 	case 'discussion': {
		// 		MapActions.groupBy(GroupFilters.TYPE);
		// 		break;
		// 	}
		// 	case 'compliance': {
		// 		MapActions.groupBy(GroupFilters.COMPLIANCE);
		// 		break;
		// 	}
		// 	// Theme
		// 	case 'field-components': {
		// 		if(MetadataStore.getAllArray('fieldtypevariant').length) {
		// 			MapActions.groupBy(GroupFilters.CHANGED);
		// 		} else {
		// 			MapActions.groupBy(GroupFilters.FIELD_TYPE);
		// 		}
		// 		break;
		// 	}
		// 	default: {
		// 		MapActions.groupBy('');
		// 		break;
		// 	}
		// }
	}
	/**
	 * onRightPanelTrigger - dispatches action telling store if right panel is open.
	 *
	 * @param  {boolean} isOpen
	 * @param  {object} event      event object
	 */
	onRightPanelTrigger(isOpen, event) {
		event.preventDefault();
		// Are we closing the right panel?
		if(!isOpen) {
			AdminSettingsActions.onSettingChange('');
		}
		AdminSettingsActions.onRightPanelChange(isOpen);
	}

	/**
	 * componentDidMount - Initializes offcanvas menu and gets session ID.
	 */
	componentDidMount() {

		// create theme builder styling
		let style = document.createElement('style');
		style.id = 'theme-builder';
		document.head.appendChild(style);
		if(this.state && this.state.themeBuilderCSS) {
			// add theme builder styles to style tag in HTML head
			document.querySelector('#theme-builder').innerHTML = this.state.themeBuilderCSS;
		}

		// Initializes CSS Element Queries
		ElementQueries.init();
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.themeBuilderCSS !== this.state.themeBuilderCSS) {
			// add theme builder styles to style tag in HTML head
			document.querySelector('#theme-builder').innerHTML = this.state.themeBuilderCSS;
		}
	}

	/**
	 * onResize - Fires on resizer resize; saves dimensions to store
	 *
	 * @param  {object} event
	 * @param  {object} element
	 * @param  {object} size
	 */
	onResize(event, { element, size }) {
		let newWidth = Math.round(size.width);
		let newHeight = Math.round(size.height);
		// let mode = UIUtils.getResponsiveMode(newWidth);

		// ContextActions.onResponsiveModeChange(mode);
		AdminSettingsActions.onCenterColumnResize({ 'width': newWidth, 'height': newHeight });

		// clearTimeout(this.gridRecalcTimeout);
		// this.gridRecalcTimeout = setTimeout(() => {
		// 	RenderActions.onCenterColumnResize({'width': newWidth, 'height': newHeight});
		// }, 10);
	}
	/**
	 * onResizeStart - adds class that removes transition
	 *
	 * @param  {object} event
	 */
	onResizeStart(event) {
		$('.react-resizable').addClass('react-resizable--dragging');
	}
	/**
	 * onResizeStop - removes class that restores transition
	 *
	 * @param  {object} event
	 */
	onResizeStop(event) {
		$('.react-resizable').removeClass('react-resizable--dragging');
	}
	/**
	 * Render the wrapper container
	 *
	 * @returns React
	 *
	 * @memberOf Component
	 */
	render() {
		let isRightPanelOpen = this.state.isRightPanelOpen;
		let isSettingsPreviewOpen = this.state.isSettingsPreviewOpen;
		let resizerWidth = this.state.sizeObj ? this.state.sizeObj.get('width') : 0;
		let resizerHeight = this.state.sizeObj ? this.state.sizeObj.get('height') : 0;
		let isLeftPanelOpen = this.state.isLeftPanelOpen;
		let isRightPanelExpanded = this.state.isRightPanelExpanded;
		let isAuthenticated = this.state.isAuthenticated;
		let hasDevTools = AuthenticationStore.getHasDevTools();
		
		// Dev:
		if(process.env.CITDEV_ENV === 'development') {
			$('body').addClass('cd-d');
		}
		else { // Test and Prod:
			$('body').addClass('cd-tp');
		}

		if (isLeftPanelOpen) {
			$('body').addClass('show-left');
		} else {
			$('body').removeClass('show-left');
		}

		if (isRightPanelOpen) {
			$('body').addClass('show-right');
		} else {
			$('body').removeClass('show-right');
		}
		// determine right panel content to display
		let selectedOverlay = this.state.selectedOverlay;

		// if isRightPanelExpanded, set extended class
		if (isRightPanelExpanded) {
			$('body').addClass('expanded-panel-open');
			$('.blocklySvg').css('width', '100%');
		} else {
			$('body').removeClass('expanded-panel-open');
		}

		let leftPanel = null;
		let settingsPreviewPanel = null;
		let rightPanel = null;
		// let footer = null;
		let content = null;

		if (process.env.CITDEV_ENV === 'development' && isAuthenticated ) {
			let actionToolbox = this.state.actionToolbox;
			actionToolbox = actionToolbox.push(Immutable.fromJS(
				{
					'type': 'sep'
				}));
			actionToolbox = actionToolbox.push(Immutable.fromJS(
				{
					'name': 'Output',
					'style': 'output_category',
					'blocks': [
						{
							'type': 'citdev_return_statement'
						}
					]
				}));
			// let fieldComponentsLI = null;
			// if(users.includes(AuthenticationStore.getUserId()) || AuthenticationStore.getHasEngineeringTools()) { 
			// 	fieldComponentsLI = 
			// 		<li className="field-components-map">
			// 			<a onClick={this.onLeftPanelTrigger.bind(this, 'field-components', true)} href="#field-components-map">
			// 				<img src={urlFontawesome + '/cogs.svg'} className="icon" alt="" />
			// 				<span>Field Components</span>
			// 			</a>
			// 		</li>;
			// }

			if(hasDevTools) {
				if(isLeftPanelOpen) {
					leftPanel = <DashboardPanel />;
				}
	
				// Open right panel only when a pin is being clicked on
				if(isRightPanelOpen){
					rightPanel = <SettingsPanel selectedOverlay={selectedOverlay} onRightPanelTrigger={this.onRightPanelTrigger} />
				}

				if(isSettingsPreviewOpen) {
					settingsPreviewPanel = <SettingsPreview key="settingsPreview" />
				}

				content = (<div id="content" className="development">
					<DialogContainer/>
					<div id="overlay" onClick={this.onRightPanelTrigger.bind(this, false)}></div>

					<div className="container-fluid content-container">
						<ResizableBox
							id="resizable-box"
							className="application-canvas"
							width={resizerWidth}
							height={resizerHeight}
							onResizeStart={this.onResizeStart}
							onResize={this.onResize}
							onResizeStop={this.onResizeStop}
							minConstraints={[320, 240]}
						>
							<div id="resizable-box__inner" style={{ 'height': resizerHeight, 'width': resizerWidth }}>
								{React.Children.map(this.props.children, (child) => {
									if(child !== null) {
										return React.cloneElement(child, {
											size: {
												height: resizerHeight,
												width: resizerWidth
											}
										});
									}
								})}
							</div>
						</ResizableBox>
					</div>
					<div id="expressionToolbox">
						<ReactBlocklyComponent.BlocklyToolbox categories={this.state.expressionToolbox} didUpdate={function () { /*just to suppress warning*/ }} />
					</div>
					<div id="actionToolbox">
						<ReactBlocklyComponent.BlocklyToolbox categories={actionToolbox} didUpdate={function () { /*just to suppress warning*/ }} />
					</div>
				</div>);

				// footer = [<MainFooter key="mainfooter" />, <ModalContainer key="modalcontainer" />];
			} else {
				// No Dev Tools
				content = (<SizeMe monitorHeight={true}>
					{({ size }) =>
					<div id="content" className="development application-canvas">
						<DialogContainer/>
						<div className="container-fluid h-100">
							{size && size.height &&
							React.Children.map(this.props.children, (child) => {
								if (child !== null) {
									return React.cloneElement(child, {
									size: size,
									});
								}
							})
							}
						</div>
					</div>
					}
					</SizeMe>);

				// footer = [<MainFooter key="mainfooter" />];
			}
		} else {
			content = (<SizeMe monitorHeight={true}>
					{({ size }) =>
					<div id="content" className="production application-canvas">
						<DialogContainer/>
						<div className="container-fluid h-100">
							{size && size.height &&
							React.Children.map(this.props.children, (child) => {
								if (child !== null) {
									return React.cloneElement(child, {
									size: size,
									});
								}
							})
							}
						</div>
					</div>
					}
					</SizeMe>);
		}

		return (
			<main className="fixed-top" id="App">
				<div id="tetherBurgerMenu" style={{ position: 'static' }}></div>

				<Helmet
					defaultTitle="CitizenDeveloper"
					title="CitizenDeveloper"
					titleTemplate="%s | CitizenDeveloper"
				/>

				{ReactDOM.createPortal(
					<NotificationContainer />, document.getElementById('root'))}
				
				{leftPanel}
				<div id="site-canvas">
					{content}
				</div>
				{rightPanel}
				{settingsPreviewPanel}
				<ModalContainer key="modalcontainer" />
			</main>
		);
	}
}

const container = Container.create(App, { withProps: true });
export default container;
