import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import {MetadataConstants} from '../constants/metadata-constants';
import ObjectUtils from '../utils/object-utils';

/**
 * Core store that contains metadata records
 *
 * @class MetadataStore
 * @extends {ReduceStore}
 */
class MetadataStore extends ReduceStore {
	/**
	 * getInitialState - initial state for MetadatasStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.Map({
			allPulledFromDatabase: Immutable.Map(),
			records: Immutable.Map()
		});
	}

	/**
	 * Called every time an action is dispatched (for any store)
	 *
	 * @param {Object} state - current state of this store
	 * @param {Object} action - action that's coming in
	 * @returns {Object} new state after the action has been processed.
	 */
	reduce(state, action) {
		let kind = action.get('kind');
		switch (action.get('type')) {
			case MetadataConstants.METADATA_DELETE_FROM_DATABASE: {
				// @TODO: Deal with "cleaning" any dirty flags in the future. Currently literally unused
				return state;
			}
			case MetadataConstants.METADATA_DELETE_FROM_STORE: {
				// Delete the record in the state['records']['recordId'] spot
				return state.deleteIn(['records', kind, action.get('recordId')]);
			}
			case MetadataConstants.METADATA_PUSH_TO_DATABASE: {
				let metadataObject = action.get('metadataObject');
				let recordId = metadataObject.get('recordId');
				// At least for now, treat this as forceClean
				let newState = state.withMutations(state => {
					let oldRecord = state.getIn(['records', kind, recordId]) || Immutable.Map();
					oldRecord = oldRecord.withMutations(oldRecord => {
						metadataObject.forEach((newSettingValue, settingKey) => {
							let oldValue = oldRecord.get(settingKey) || Immutable.Map();
							let newValue = oldValue.withMutations(oldValue => {
								oldValue.set('value', newSettingValue);
								oldValue.set('isDirty', false);
								oldValue.set('originalValue', null);
							});
							oldRecord.set(settingKey, newValue);
						});
					});
					// I believe that we want to merge deep in here as well
					state.mergeDeepIn(['records', kind, recordId], oldRecord);
				});
				return newState;
			}
			case MetadataConstants.METADATA_PUSH_TO_STORE: {
				let recordId = action.get('recordId');
				let recordProperties = action.get('recordProperties');
				let forceClean = action.get('forceClean');
				return recordId && recordProperties ? state.withMutations(state => {
					let oldRecord = state.getIn(['records', kind, recordId]) || Immutable.Map();
					let newRecord = oldRecord.withMutations(newRecord => {
						recordProperties.forEach((newSettingValue, settingKey) => {
							if(oldRecord.has(settingKey)) {
								let oldValue = oldRecord.has(settingKey) ? oldRecord.get(settingKey) : Immutable.Map();
								let newValue = oldValue.withMutations(oldValue => {
									if(forceClean) {
										oldValue.set('value', newSettingValue);
										oldValue.set('isDirty', false);
										oldValue.set('originalValue', null);
									} else {
										// If we're not already dirty and the original value hasn't been set, then set it
										if(!oldValue.get('isDirty') && oldValue.get('originalValue') === null) {
											oldValue.set('originalValue', oldValue.get('value'));
										}
	
										if(oldValue.get('originalValue') === newSettingValue) {
											// If the new value is the original value, then revert it and clear the isDirty flag
											oldValue.set('value', newSettingValue);
											oldValue.set('isDirty', false);
											oldValue.set('originalValue', null);
										} else {
											// Otherwise, just set the new value and mark it as dirty
											oldValue.set('value', newSettingValue);
											oldValue.set('isDirty', true);
										}
									}
								});
								newRecord.set(settingKey, newValue);
							} else {
								newRecord.set(settingKey, Immutable.fromJS({
									value: newSettingValue,
									isDirty: forceClean ? false : true,
									inConflict: false,
									conflictValue: null,
									originalValue: forceClean ? null : ''
								}));
							}
						});
					});
					// Merge the recordProperties into the state['records']['recordId'] object... and return it.
					// We want mergeDeepIn and not set in order to preserve old values
					state.mergeDeepIn(['records', kind, recordId], newRecord);
					return state;
				}) : state;
			}
			case MetadataConstants.METADATA_RECEIVE_BROADCAST: {
				// This is similar to pulling from the database, but needs to consider conflict work
				let records = action.get('records');
				let kind = action.get('kind');
				return state.withMutations(newState => {
					if(!state.hasIn(['records', kind])) {
						newState.setIn(['records', kind], Immutable.Map());
					}
					records.forEach((record) => {
						let recordId = record.get('recordId');
						let oldRecord = newState.getIn(['records', kind, recordId]) || Immutable.Map();
						let newRecord = oldRecord.withMutations(newRecord => {
							record.get('properties').forEach((value, key) => {
								// Update the value
								
								let isDirty = oldRecord.hasIn([key, 'isDirty']) ? oldRecord.getIn([key, 'isDirty']) : false;
								if(!isDirty) {
									// If it's not dirty, then just update the value
									newRecord.setIn([key, 'value'], value);
									newRecord.setIn([key, 'isDirty'], false);
									newRecord.setIn([key, 'originalValue'], null);
								} else {
									let dirtyValue = oldRecord.getIn([key, 'value']);
									if(dirtyValue !== value) {
										// @TODO: How do we want to handle dirty values from an end user perspective?
										// Warn the user for now, I guess?
										let InterfaceActions = require('../actions/interface-actions').default;
										InterfaceActions.stickyNotification({
											level: 'warning',
											title: 'Changed: '+oldRecord.getIn(['singularName', 'value'])+' '+kind+' / '+key+ ' Setting',
											message: 'Your modified setting has been changed by another citizen developer. Save your work to overwrite their changes, or reload to accept their update.',
											id: kind + recordId + '-' + key + '-conflict'
										});
										// Update the original value, since that reflects the DB now
										newRecord.setIn([key, 'originalValue'], value);
									} else {
										// The value in the DB is now actually the same as the "dirty" value, so we can just clean the value in the store
										newRecord.setIn([key, 'isDirty'], false);
										newRecord.setIn([key, 'originalValue'], null);
									}
								}
							});
						});
						newState.setIn(['records', kind, recordId], newRecord);
					})
				});
			}
			case MetadataConstants.METADATA_PULL_FROM_DATABASE: {
				// Basically the same as pull from database all, except we don't assume we have all the records loaded

				let overwriteStore = action.get('overwriteStore');
				return state.withMutations(state => {
					let recordMap = Immutable.Map().withMutations(recordMap => {
						action.get('metadataArray').forEach(metaRecord => {
							let recordId = metaRecord.get('recordId');
							let newRecord = Immutable.Map().withMutations(newRecord => {
								let overrideValues = {};

								// When reading the scheduled logic records 
								// from the MD Database - Update the highMemory 
								// key if its set int the logic and not in the record.
								if(kind === 'scheduledLogic') {
									let logicJSON = metaRecord.get('logic');
									let logicObj = ObjectUtils.getObjFromJSON(logicJSON);
									if(logicObj && logicObj.memUse && logicObj.memUse === 'h') {
										overrideValues['highMemory'] = true
									}
								}

								// Set  up the record as normal
								metaRecord.forEach((settingVal, settingKey) => {
									// Treat as forceClean = true
									newRecord.set(settingKey, Immutable.fromJS({
										value: (overrideValues[settingKey] ? overrideValues[settingKey] : settingVal),
										isDirty: false,
										originalValue: null
									}));
								});

								// If there are overrideValues, make sure they are set
								// This is in case the key was never set to begin with.
								Object.keys(overrideValues).forEach(settingKey => {
									let settingVal = overrideValues[settingKey];
									// Treat as forceClean = true
									newRecord.set(settingKey, Immutable.fromJS({
										value: settingVal,
										isDirty: false,
										originalValue: null
									}));
								})
							});
							recordMap.set(recordId, newRecord);
						});
					});
					if(overwriteStore) {
						if(state.has('records') && state.get('records').has(kind)) {
							// If we already have some records of this kind, merge the incoming records in here
							state.mergeDeepIn(['records', kind], recordMap);
						} else {
							// We don't already have any of these records, so just set
							state.setIn(['records', kind], recordMap);
						}
					} else {
						// Start with the new records, then merge in what we have in the state already to avoid overwriting
						state.setDeepIn(['records', kind, recordMap.mergeDeep(state.getIn(['records', kind]))]);
					}
				});
			}
			case MetadataConstants.METADATA_PULL_FROM_DATABASE_ALL: {
				let overwriteStore = action.get('overwriteStore');
				return state.withMutations(state => {
					let recordMap = Immutable.Map().withMutations(recordMap => {
						action.get('metadataArray').forEach(metaRecord => {
							let recordId = metaRecord.get('recordId');
							let newRecord = Immutable.Map().withMutations(newRecord => {
								let overrideValues = {};

								// When reading the scheduled logic records 
								// from the MD Database - Update the highMemory 
								// key if its set int the logic and not in the record.
								if(kind === 'scheduledLogic') {
									let logicJSON = metaRecord.get('logic');
									let logicObj = ObjectUtils.getObjFromJSON(logicJSON);
									if(logicObj && logicObj.memUse && logicObj.memUse === 'h') {
										overrideValues['highMemory'] = true
									}
								}

								// Set  up the record as normal
								metaRecord.forEach((settingVal, settingKey) => {
									// Treat as forceClean = true
									newRecord.set(settingKey, Immutable.fromJS({
										value: (overrideValues[settingKey] ? overrideValues[settingKey] : settingVal),
										isDirty: false,
										originalValue: null
									}));
								});

								// If there are overrideValues, make sure they are set
								// This is in case the key was never set to begin with.
								Object.keys(overrideValues).forEach(settingKey => {
									let settingVal = overrideValues[settingKey];
									// Treat as forceClean = true
									newRecord.set(settingKey, Immutable.fromJS({
										value: settingVal,
										isDirty: false,
										originalValue: null
									}));
								})
							});
							recordMap.set(recordId, newRecord);
						});
					});
					if(overwriteStore) {
						if(state.hasIn('records', kind)) {
							// If we already have some records of this kind, merge the incoming records in here
							state.mergeDeepIn(['records', kind], recordMap);
						} else {
							// We don't already have any of these records, so just set
							state.setIn(['records', kind], recordMap);
						}
					} else {
						// Start with the new records, then merge in what we have in the state already to avoid overwriting
						state.setDeepIn(['records', kind, recordMap.mergeDeep(state.getIn(['records', kind]))]);
					}
					state.setIn(['allPulledFromDatabase', kind], true);
				});
			}
			case MetadataConstants.METADATA_PULL_ERROR: {
				console.error('Metadata Store Error: ' + action.get('error'));
				return state;
			}
			default: {
				return state;
			}
		}
	}

	/**
	 * Gets the entire store as an object
	 *
	 * @param {string} kind Kind of metadata to retrieve
	 * @returns {Object} current store as an object
	 */
	getAll(kind) {
		if (this.allPulledFromDatabase(kind) === true) {
			let records = this.getState().getIn(['records', kind]);
			let recordsObj = {};
			records.forEach((record, recordId) => {
				let recordObj = {};
				record.forEach((settingVal, settingKey) => {
					let val = settingVal.get('value');
					recordObj[settingKey] = val;
				});
				recordsObj[recordId] = recordObj;
			});
			return recordsObj;
		} else {
			// console.warn('pullFromDatabaseAll(' + kind + ') not run, did you mean get()?');
			return undefined;
		}
	}

	/**
	 * Gets the entire store as an array
	 *
	 * @param {string} kind Kind of metadata to retrieve
	 * @returns {Array} current store as an array
	 */
	getAllArray(kind) {
		if (this.allPulledFromDatabase(kind) === true) {
			let records = this.getState().getIn(['records', kind]);
			let recordsArr = [];
			records.forEach((record) => {
				let recordObj = {};
				record.forEach((settingVal, settingKey) => {
					let val = settingVal.get('value');
					recordObj[settingKey] = val;
				});
				recordsArr.push(recordObj);
			});
			return recordsArr;
		} else {
			// console.warn('pullFromDatabaseAll(' + kind + ') not run, did you mean get()?');
			return undefined;
		}
	}

	/**
	 * Gets an individual record from the store of a particular kind
	 * @param {string} recordId UUID of the record to get
	 * @param {string} kind Kind of metadata to retrieve
	 * @returns {Object} current store as an object
	 */
	get(recordId, kind, justDirty) {
		if(recordId && kind && this.getState().hasIn(['records', kind, recordId])) {
			let kindRec = this.getState().getIn(['records', kind, recordId]);
			let toReturn = {
				recordId
			};
			kindRec.forEach((settingVal, settingKey) => {
				let val = settingVal.get('value');
				let isDirty = settingVal.get('isDirty');
				if(justDirty ? isDirty : true) {
					toReturn[settingKey] = val;
				}
			});
			return toReturn;
		}
		return undefined;
	}

	/**
	 * Gets the full information for a meta record
	 * @param {string} recordId UUID of the record to get
	 * @param {string} kind Kind of metadata to retrieve
	 */
	getFull(recordId, kind) {
		return this.getState().hasIn(['records', kind, recordId]) ?
			this.getState().getIn(['records', kind, recordId]) :
			undefined;
	}

	/**
	 * 
	 * @param {*} kind 
	 */
	getAllFull(kind) {
		if (this.allPulledFromDatabase(kind) === true) {
			return this.getState().getIn(['records', kind]);
		} else {
			// console.warn('pullFromDatabaseAll(' + kind + ') not run, did you mean get()?');
			return undefined;
		}
	}

	/**
	 * Get whether all have been pulled from the database or not yet.
	 * 
	 * @param {string} kind Kind of metadata to retrieve the all pulled for
	 * @returns {boolean}
	 */
	allPulledFromDatabase(kind) {
		return this.getState().getIn(['allPulledFromDatabase', kind]);
	}
}

const instance = new MetadataStore(AppDispatcher);
export default instance;