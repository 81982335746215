import AppDispatcher from '../dispatcher/app-dispatcher';
import ContextConstants from '../constants/context-constants';
import Immutable from 'immutable';
// import UIUtils from '../utils/ui-utils';

const ContextActions = {
	/**
	 * onApplicationInit - Stores application id and installation version retrieved from body's data- attributes
	 * 
	 * @param {string} applicationId 
	 * @param {string} installationId 
	 * @param {string} installationVersion 
	 * @param {string} urlMedia 
	 * @param {string} urlFontawesome 
	 * @param {string} urlExpression 
	 * @param {string} urlBlockly 
	 * @param {string} urlCommunity 
	 * @param {string} mdgwMode stage | prod
	 * @param {string} defaultHomePageId
	 * @param {string} defaultLoginPageId
	 * @param {string} basePath
	 */
	onApplicationInit(applicationId, installationId, installationVersion, installationRole, urlMedia, urlFontawesome, urlExpression, 
		urlBlockly, urlEngine, urlCommunity, mdgwMode, defaultHomePageId, defaultLoginPageId, basePath) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ContextConstants.APPLICATION_INIT,
			'applicationId': applicationId,
			'installationId': installationId,
			'installationVersion': installationVersion,
			'installationRole': installationRole,
			'urlMedia': urlMedia,
			'urlFontawesome': urlFontawesome,
			'urlExpression': urlExpression,
			'urlBlockly': urlBlockly,
			'urlEngine': urlEngine,
			'urlCommunity': urlCommunity,
			'mdgwMode':mdgwMode,
			'defaultHomePageId':defaultHomePageId,
			'defaultLoginPageId':defaultLoginPageId,
			'basePath':basePath
		}));
	},
    /**
     * onContextChange - When URL has changed - passes values in URL string
     *
     * @param  {string} pageId          page type
     * @param  {string} tableSchemaName table schema from where record is retrieved
     * @param  {string} recordId        record
     */
	onContextChange(pageId, tableSchemaName, recordId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ContextConstants.CONTEXT_CHANGE,
			'pageId': pageId,
			'tableSchemaName': tableSchemaName,
			'recordId': recordId
		}));
	},
    /**
     * onResponsiveModeChange - Sets responsive design mode
     *
     * @param  {string} responsiveMode "lg", "md", or "sm"
     */
	onResponsiveModeChange(responsiveMode) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ContextConstants.RESPONSIVE_MODE_CHANGE,
			'responsiveMode': responsiveMode
		}));
	},
	
	/**
	 * onAllRecordDataReceived - tell the system that all record data has been received for this page.
	 * 
	 * @param {boolean} allRecordDataReceived 
	 */
	onAllRecordDataReceived(allRecordDataReceived, pageId, tableSchemaName, recordId) {
		if (recordId || pageId) { 
			AppDispatcher.dispatch(Immutable.fromJS({
				'type': ContextConstants.ALL_RECORD_DATA_RECEIVED_CHANGE_WITH_PATH_CHANGE,
				'allRecordDataReceived': allRecordDataReceived,
				'pageId': pageId,
				'tableSchemaName': tableSchemaName,
				'recordId': recordId
			}));
		} else {
			AppDispatcher.dispatch(Immutable.fromJS({
				'type': ContextConstants.ALL_RECORD_DATA_RECEIVED_CHANGE,
				'allRecordDataReceived': allRecordDataReceived,
			}));
		}
	},

	/**
	 * Sets the render id for the main page
	 * 
	 * @param {string} renderId 
	 */
	setPageRenderId(renderId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ContextConstants.SET_PAGE_RENDER_ID,
			'renderId': renderId,
		}));
	},

	/**
	 * Adds an action into the list of currently running actions
	 * 
	 * @param {string} renderId 
	 */
	addActionId(actionId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ContextConstants.ADD_RUNNING_ACTION,
			'actionId': actionId
		}));
	},

	updateShouldDisplayNotification(actionId, shouldDisplayNotification) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ContextConstants.UPDATE_DISPLAY_NOTIFICATION,
			'actionId': actionId,
			'shouldDisplayNotification': shouldDisplayNotification
		}));
	},

	/**
	 * Removes an action from the list of currently running actions
	 * 
	 * @param {string} renderId 
	 */
	removeActionId(actionId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ContextConstants.REMOVE_RUNNING_ACTION,
			'actionId': actionId,
		}));
	},

	/**
	 * Triggers the state to recalculate whether or not to display
	 * a notification.
	 */
	recalculateDisplayNotification() {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ContextConstants.RECALCULATE_DISPLAY_NOTIFICATION,
		}));
	}
};

export default ContextActions;