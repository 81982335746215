import AppDispatcher from '../dispatcher/app-dispatcher';
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';
import RenderConstants from '../constants/render-constants';
import RecordSetConstants from '../constants/record-set-constants';
import FieldModesConstants from '../constants/field-modes-constants';
import PageModeConstants from '../constants/page-mode-constants';
import {FieldConstants} from '../constants/field-constants';
import {PageConstants} from '../constants/page-constants';
import ListConstants from '../constants/list-constants';
import {RecordConstants} from '../constants/record-constants';
import AdminSettingsConstants from '../constants/admin-settings-constants';
import FieldStore from './field-store';
import PageStore from './page-store';
import RecordStore from './record-store';
import RecordSetStore from './record-set-store';
import AdminSettingsStore from './admin-settings-store';

/**
 * Store to contain the render objects generated by fields on the page
 *
 * @class RenderStore
 * @extends {ReduceStore}
 */
class RenderStore extends ReduceStore {
	/**
	 * Initial state for RenderStore
	 *
	 * @ignore
	 * @memberOf RenderStore
	 */
	getInitialState() {
		return Immutable.fromJS({
			renderIds: {},
			recordIds: {}
		});
	}

	/**
	 * Get the render Object for a given renderId
	 *
	 * @param {string} renderId 
	 * @returns {Object} Render Object corresponding to the given renderId
	 *
	 * @memberOf RenderStore
	 */
	get(renderId) {
		let state = this.getState();
		if (state.hasIn(['renderIds', renderId])) {
			return state.getIn(['renderIds', renderId]).toJS();
		} else {
			return undefined;
		}
	}

	/**
	 * Get the render Immutable for a given renderId
	 *
	 * @param {string} renderId 
	 * @returns {Immutable} Immutable corresponding to the given renderId
	 *
	 * @memberOf RenderStore
	 */
	 getImmutable(renderId) {
		let state = this.getState();
		if (state.hasIn(['renderIds', renderId])) {
			return state.getIn(['renderIds', renderId]);
		} else {
			return undefined;
		}
	}

	getLastRefresh(renderId) {
		let state = this.getState();
		if (state.hasIn(['renderIds', renderId])) {
			return state.getIn(['renderIds', renderId, 'lastRefresh']);
		} else {
			return undefined;
		}
	}
	
	getValidationLastFailed(renderId) {
		let state = this.getState();
		if (state.hasIn(['renderIds', renderId])) {
			return state.getIn(['renderIds', renderId, 'validationLastFailed']);
		} else {
			return undefined;
		}
	}

	getIsDisabled(renderId) {
		let state = this.getState();
		if (state.hasIn(['renderIds', renderId])) {
			let disabled = state.getIn(['renderIds', renderId, 'disabled'])
			return disabled === true;
		} else {
			return undefined;
		}
	}

	getChildGridHeight(renderId) {
		let state = this.getState();
		if (state.hasIn(['renderIds', renderId, 'children'])) {
			let children = state.getIn(['renderIds', renderId, 'children']);
			let sum = 0;
			children.forEach(childRenderId => {
				let childHeight = this.getGridHeight(childRenderId) || 0;
				sum += childHeight;
			})
			return sum;
		} else {
			return undefined;
		}
	}

	getGridHeight(renderId) {
		let state = this.getState();
		if (state.hasIn(['renderIds', renderId])) {
			return state.getIn(['renderIds', renderId, 'gridHeight'])
		} else {
			return undefined;
		}
	}

	/**
	 * 
	 * @param {string} componentType The type of the component whose render objects are being found
	 * @param {string} componentId The ID of the component whose render objects are being found
	 * @param {string} dataTableSchemaName The tableSchemaName of the data values for the component
	 * whose render objects are being found
	 * Optional - if not provided, will return all render objects for the component
	 * @param {string} dataRecordId The recordId of the of the data values for the component
	 * whose render objects are being found
	 * Optional - if not provided, will return all render objects for the component
	 */
	getRenderObjectsForComponent(componentType, componentId, dataTableSchemaName, dataRecordId) {
		let state = this.getState();
		if(dataRecordId) {
			if(!state.hasIn(['recordIds', dataRecordId])) {
				return Immutable.Map();
			}
			let renderStores = state.getIn(['recordIds', dataRecordId]).filter((value, renderId) => {
				let renderMap = state.getIn(['renderIds', renderId]);
				if(!renderMap) {
					console.warn('No render store entry found for render %s', renderId);
					return false;
				}
				let matchesComponentType = renderMap.get('componentType') === componentType;
				let matchesComponentId = renderMap.get('componentId') === componentId;
				let matchesDataTableSchemaName = dataTableSchemaName ? renderMap.get('dataTableSchemaName') === dataTableSchemaName : true;
				return matchesComponentType && matchesComponentId && matchesDataTableSchemaName;
			});
			return renderStores;
		} else {
			let renderStores = state.get('renderIds').filter((renderMap, key) => {
				let matchesComponentType = renderMap.get('componentType') === componentType;
				let matchesComponentId = renderMap.get('componentId') === componentId;
				return matchesComponentType && matchesComponentId;
			});
			return renderStores;
		}
	}

	/**
	 * Look through the renderIds in the store and return all page renders that are NOT the primary page's render.
	 * 
	 * @param {string} primaryPageRenderId Primary Page's Render Id (ContextStore.getPageRenderId())
	 * @returns {Object[]}
	 * @memberof RenderStore
	 */
	getDialogRenders(primaryPageRenderId) {
		let renderIds = this.getState().get('renderIds').toJS();
		let returnArray = [];
		Object.keys(renderIds).forEach(renderId => {
			let render = renderIds[renderId];
			if((render.componentType === 'page' || render.componentType === 'componentDialog') &&
			 render.renderId !== primaryPageRenderId) {
				returnArray.push(render);
			}
		});

		// Return the array of renders, sorted by renderSequence
		return returnArray.sort((a, b) => {
			return (a.renderSequence > b.renderSequence) ? 1 : -1;
		});
	}

	/**
	 * Start with a render ID, and get all children of that render id, recursively
	 * 
	 * @param {String} renderParentId
	 * @param {Boolean}  excludePageChildren Exclude any page children and their children recursively
	 * @return {Immutable.List} An Immutable List of all render ID's under this renderId
	 * @memberof RenderStore
	 */
	findAllChildren(renderParentId, excludePageChildren) {
		let state = this.getState();
		return _findAllChildren(state, renderParentId, excludePageChildren);
	}

	/**
	 * Determines all descendant renders of a render store entry
	 * (Used when refreshing fields)
	 * @param {*} renderId 
	 */
	getDescendants(renderId, excludePageChildren) {
		let renderObj = this.get(renderId);
		if(!renderObj) {
			return [];
		}

		let children = renderObj.children && (!excludePageChildren || renderObj.componentType === 'field') ? [].concat(renderObj.children) : [];
		children.forEach(childRenderId => {
			let grandchildren = this.getDescendants(childRenderId, excludePageChildren);
			children = children.concat(grandchildren);
		});
		return children;
	}

	/**
	 * Determines which records are used by a render and its children
	 * 
	 * @param {string} renderId The render ID whose descendant records to get
	 * @param {boolean} excludePageChildren Consider the page children when getting the records
	 * @param {boolean} alwaysIncludeRecords Ignore the unsavedChangesWarning and just always get the records.
	 */
	getDescendantRecords(renderId, excludePageChildren, alwaysIncludeRecords) {

		// This operates similarly to findAllChildren, except that
		// it needs to filter out dialogs which are set not to warn about unsaved data
		// without filtering out any dialogs which are themselves the children of those dialogs

		let state = this.getState();
		let render = state.getIn(['renderIds', renderId]);
		if(!render) {
			return {};
		}
		// @TODO: What do we do if the top-level renderId has saving disabled?
		let unsavedChangesWarning = true;
		let componentType = render.get('componentType');
		let componentId = render.get('componentId');
		let dataTableSchemaName = render.get('dataTableSchemaName');
		let dataRecordId = render.get('dataRecordId');
		if(componentType === 'page') {
			let pageObj = PageStore.get(componentId);
			unsavedChangesWarning = alwaysIncludeRecords || ('' + pageObj.unsavedChangesWarning) === 'true';
		} else {
			// Find the parent page for this component and determine if it has unsaved changes warnings
			let pageRenderObj = this.getPageRenderObj(renderId);
			let pageObj = pageRenderObj ? PageStore.get(pageRenderObj.renderId) : undefined;
			unsavedChangesWarning = alwaysIncludeRecords || (pageObj && ('' + pageObj.unsavedChangesWarning) === 'true');
		}
		let records = {};
		if(dataTableSchemaName && dataRecordId && unsavedChangesWarning) {
			records[dataTableSchemaName] = {
				[dataRecordId]: true
			}
		}

		if (render && render.has('children')) {
			let children = render.get('children');
			children.forEach(childRenderId => {
				// If we're excluding page children, do NOT recurse over any page children
				// This should allow the pages themselves to be found in findAllChildren
				// but not their children. (Used for the 8292 fix.)
				let childRenderObj = state.getIn(['renderIds', childRenderId]);
				let dataTableSchemaName = childRenderObj.get('dataTableSchemaName');
				let dataRecordId = childRenderObj.get('dataRecordId');
				let componentType = childRenderObj.get('componentType');
				let componentId = childRenderObj.get('componentId');

				if(componentType === 'page') {
					// If we're excluding page children, we're done; move on to the next iteration
					if(excludePageChildren) {
						return;
					}
					
					// Otherwise, we want to see whether this page factors in save messages or not
					let pageObj = PageStore.get(componentId);
					let includePage = pageObj && ('' + pageObj.unsavedChangesWarning === 'true');
					if(includePage) {
						records[dataTableSchemaName] = records[dataTableSchemaName] || {};
						records[dataTableSchemaName][dataRecordId] = true;

						// Recurse in order to find all field children
						let childRecords = this.getDescendantRecords(childRenderId, excludePageChildren, alwaysIncludeRecords);
						Object.keys(childRecords).forEach(tsn => {
							records[tsn] = records[tsn] || {};
							Object.assign(records[tsn], childRecords[tsn]);
						});
					} else {
						// Skip over this page and all its field children, but still recurse over all child pages
						let grandchildren = childRenderObj.get('children');
						if(grandchildren) {
							grandchildren.forEach(grandchild => {
								let grandchildRenderObj = state.getIn(['renderIds', grandchild])
								let grandchildType = grandchildRenderObj.get('componentId');
								if(grandchildType === 'page') {
									// Recurse in order to find all field children
									let grandchildRecords = this.getDescendantRecords(grandchild, excludePageChildren, alwaysIncludeRecords);
									Object.keys(grandchildRecords).forEach(tsn => {
										records[tsn] = records[tsn] || {};
										Object.assign(records[tsn], grandchildRecords[tsn]);
									});
								}
							});
						}
					}
				} else {
					// Recurse in order to find all field children, as long as this page has unsaved changes warnings
					let childRecords = this.getDescendantRecords(childRenderId, excludePageChildren, alwaysIncludeRecords);
					Object.keys(childRecords).forEach(tsn => {
						records[tsn] = records[tsn] || {};
						Object.assign(records[tsn], childRecords[tsn]);
					});
				}
			});
		}

		return records;
	}

	/**
	 * Determines whether a render or any of its children have fields from dirty records.
	 * Slightly different from hasDirtyRecords because this will return false
	 * if a render is about a dirty record but none of the fields are themselves dirty.
	 * 
	 * @param {string} renderId 
	 */
	hasDirtyFields(renderId) {
		// Check if there are any unsaved records on this page
		let records = this.getDescendantRecords(renderId, true);
		let FieldUtils = require('../utils/field-utils').default;
		let fields = FieldUtils.getAllRenderedChildren(renderId);

		let hasDirty = Object.keys(records).find(tsn => {
			let tableRecords = records[tsn];
			let isDirty = false;
			Object.keys(tableRecords).forEach(recordId => {
				// If we already know it's dirty, don't bother with anything else
				if(!isDirty) {
					let recordIsDirty = false;
					fields.forEach(field => {
						recordIsDirty = recordIsDirty || RecordStore.fieldIsDirty(tsn, recordId, field);
					});
					isDirty = isDirty || recordIsDirty;
				}
			});
			return isDirty;
		});

		return !!hasDirty;
	}

	/**
	 * Determines whether a render or any of its children have dirty records
	 * 
	 * @param {*} renderId 
	 * @param {*} excludePageChildren 
	 */
	hasDirtyRecords(renderId, excludePageChildren) {
		// Check if there are any unsaved records on this page
		let records = this.getDescendantRecords(renderId, excludePageChildren);

		let hasDirty = Object.keys(records).find(tsn => {
			let tableRecords = records[tsn];
			let dirtyRecord = Object.keys(tableRecords).find(recordId => RecordStore.isDirty(tsn, recordId));
			return !!dirtyRecord;
		});

		return !!hasDirty;
	}

	/**
	 * Gets the attached fields for a render ID, in the form
	 * [{
	 * 	recordId,
	 * 	order // Uses attachmentKey
	 * }] 
	 *
	 * @param {*} renderParentId
	 * @memberof RenderStore
	 */
	getAttachedFields(renderParentId) {
		let state = this.getState();
		let render = state.getIn(['renderIds', renderParentId]);
		let children = Immutable.List();
		let returnList = [];

		if (render && render.has('children')) {
			children = render.get('children');
			children.forEach(childRenderId => {
				let child = this.get(childRenderId) || {};
				if(child.componentType === 'field') {
					returnList.push({
						recordId: child.componentId,
						attachmentId: child.attachmentId,
						renderId: childRenderId,
						gridInfo: child.gridInfo,
						currentMode: child.currentMode,
						availableModes: child.availableModes,
						dataTableSchemaName: child.dataTableSchemaName,
						dataRecordId: child.dataRecordId,
						order: (child.attachmentKey || child.attachmentKey === 0) && !isNaN(child.attachmentKey) ? +child.attachmentKey : undefined
					})
				}
			});
		}

		return returnList;
	}

	/**
	 * Get the field positions for the child of a grid
	 * given a screensize and whether or not the resizer is active
	 * @param {string} renderParentId 
	 * @param {string} screenSize 
	 * @param {boolean} resizerIsActive 
	 * @returns 
	 */
	getFieldPositions(renderParentId, screenSize, resizerIsActive) {
		let state = this.getState();
		// let resizerOverlayEnabled = AdminSettingsStore.getIsOverlayActive('resizer');
		let render = state.getIn(['renderIds', renderParentId]);
		let fieldPositions = [];
		let spacerHeight = 0;
		let hasExpanding = false;
		// let columnHeights = new Array(12).fill(0);
		// let columnLastPositions = [];
		// let columnYTracker = new Array(12);

		if (render && render.has('children')) {
			hasExpanding = render.hasIn(['hasExpanding', screenSize]) ? render.getIn(['hasExpanding', screenSize]) : false;
			spacerHeight = !hasExpanding && render.hasIn(['spacerHeights', screenSize]) ? render.getIn(['spacerHeights', screenSize]) : 0;
			let children = render.get('children');
			children.forEach(childRenderId => {
				let child = this.get(childRenderId) || {};
				if(child.gridInfo && child.gridInfo[screenSize]) {
					let gridInfo = child.gridInfo[screenSize];

					if(resizerIsActive) {
						// If the resizer is active, then we want to use the originalH and originalY values
						let minH = 2; // Not visible under a certain threshhold
						gridInfo.h = Math.max(gridInfo.originalH, minH);
						gridInfo.y = gridInfo.originalY;
						gridInfo.minH = minH;
					}

					if(child.gridLayoutHeights && child.gridLayoutHeights[screenSize] && !resizerIsActive) {
						gridInfo.gridLayoutHeight = child.gridLayoutHeights[screenSize];
					}

					fieldPositions.push(gridInfo);
				}
			});
		}

		return {fieldPositions, spacerHeight, hasExpanding};
	}

	/**
	 * Find all render store entries which will require their attached fields
	 * to be recalculated in order to do a bulk run and update.
	 * 
	 * We need to do this by levels, so we get only the pages
	 * 
	 * @returns 
	 */
	getGridsToRecalculate() {
		let state = this.getState();
		let toRecalc = [];
		let FieldModesStore = require('./field-modes-store').default;
		state.get('renderIds').forEach(renderEntry => {
			if(renderEntry.has('children')) {
				// If it has child renders, it's probably a grid component
				let renderId = renderEntry.get('renderId');
				let gridLayoutHeights = renderEntry.get('gridLayoutHeights');
				let componentType = renderEntry.get('componentType');
				let componentId = renderEntry.get('componentId');

				let componentObj = componentType === 'page' ? PageStore.get(componentId) : FieldStore.get(componentId);
				
				// The existence of fieldPosition is the best check
				if(componentObj && componentObj.fieldPosition && componentType === 'page') {
					let availableModes = FieldModesStore.getAvailableModes(renderId);
					toRecalc.push({
						// Needs to be of the form attachedFields, modes, renderId, componentId, dataRecordId, dataTableSchemaName
						attachedFields: componentObj.attachedFields ? JSON.parse(componentObj.attachedFields) : [],
						fieldPosition: componentObj.fieldPosition ? JSON.parse(componentObj.fieldPosition) : {},
						fieldPositionExtras: componentObj.fieldPositionExtras ? JSON.parse(componentObj.fieldPositionExtras) : {},
						modes: availableModes && availableModes.toJS ? availableModes.toJS() : availableModes,
						dataRecordId: renderEntry.get('dataRecordId'),
						dataTableSchemaName: renderEntry.get('dataTableSchemaName'),
						renderId,
						renderParentId: renderEntry.has('renderParentId') ? renderEntry.get('renderParentId') : undefined,
						gridLayoutHeights: gridLayoutHeights ? gridLayoutHeights.toJS() : undefined,
						componentType,
						componentId,
						componentObj,
					});
				}
			}
		});

		return toRecalc;
	}

	/**
	 * Takes in a renderId and returns the full object for the page in which the render is embedded.
	 * if a page's renderId is passed in, just returns the page.
	 * 
	 * @param {string} renderId 
	 * @returns {Object}
	 */
	getPageRenderObj(renderId) {
		let pageRenderObj = this.get(renderId);
		// Find the current page. (Note: for pages with parents, this will still use the first page that it finds.)
		while(pageRenderObj && pageRenderObj.componentType && pageRenderObj.componentType !== 'page' && pageRenderObj.renderParentId) {
			pageRenderObj = this.get(pageRenderObj.renderParentId);
		}

		return pageRenderObj ? pageRenderObj : null;
	}

	/**
	 * Find a child render id given the parent render Id, component, attachment key we're looking for.
	 * @param {string} renderParentId 
	 * @param {string} componentId OR attachmentId
	 * @param {string} attachmentKey 
	 * @returns {string}
	 */
	findChildRenderId(renderParentId, componentId, attachmentKey) {
		let parentRenderObj = this.get(renderParentId);
		if(!parentRenderObj) {
			return undefined;
		}

		let childRenders = parentRenderObj.children;
		if(!childRenders) {
			return undefined;
		}

		let returnRenderId = undefined;
		childRenders.forEach(childRenderId => {
			let renderObj = this.get(childRenderId);

			if(renderObj && (renderObj.componentId === componentId || renderObj.attachmentId === componentId) && 
				(!attachmentKey || renderObj.attachmentKey === attachmentKey)) {
					returnRenderId = childRenderId;
			}
		});

		return returnRenderId;
	}

	/**
	 * Find a child render id given the parent render Id, component, attachment ID we're looking for.
	 * @param {string} renderParentId 
	 * @param {string} componentId OR attachmentId
	 * @param {string} attachmentId 
	 * @returns {string}
	 */
	 findChildRenderIdFromAttachment(renderParentId, componentId, attachmentId) {
		let parentRenderObj = this.get(renderParentId);
		if(!parentRenderObj) {
			return undefined;
		}

		let childRenders = parentRenderObj.children;
		if(!childRenders) {
			return undefined;
		}
		
		let returnRenderId = undefined;
		childRenders.forEach(childRenderId => {
			let renderObj = this.get(childRenderId);

			if(renderObj && (renderObj.componentId === componentId || renderObj.attachmentId === componentId) && 
				(!attachmentId || renderObj.attachmentId === attachmentId)) {
					returnRenderId = childRenderId;
			}
		});

		return returnRenderId;
	}

	/**
	 * Internal method to lookup a render's recordSet Array, and add one to it, then return the updated array.
	 * @param {string} renderId 
	 * @param {string} recordSetName 
	 * @return {Array}
	 * @ignore
	 */
	_addRecordSetToArray(renderId, recordSetName) {
		let renderObj = this.get(renderId);
		let renderRecordSets = (renderObj && renderObj.recordSets && Array.isArray(renderObj.recordSets) 
				? renderObj.recordSets
				: []);
		if(renderRecordSets && !renderRecordSets.includes(recordSetName)) {
			renderRecordSets.push(recordSetName);
		}
		return renderRecordSets;
	}

	/**
	 * Get all ancestors of a field
	 * (Used when passing updates up the chain,
	 * usually when recalculating the height)
	 * 
	 * @param {string} renderId The ID of the render whose ancestors to get
	 * @returns Array
	 */
	getAncestors(renderId) {
		console.warn(new Error('Deprecated call to getAncestors'));
		return [];
	}

	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 * @ignore
	 *
	 * @memberOf RenderStore
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			case RenderConstants.DELETE_RENDER: {
				let FieldModesStore = require('./field-modes-store').default;
				let LocalContextStore = require('./local-context-store').default;
				let PageModeStore = require('./page-mode-store').default;
				let RecordSetStore = require('./record-set-store').default;

				//The Other Stores need information before we delete the render
				AppDispatcher.waitFor([
					FieldModesStore.getDispatchToken(), 
					LocalContextStore.getDispatchToken(),
					PageModeStore.getDispatchToken(),
					RecordSetStore.getDispatchToken()
				]);
				
				let renderId =  action.get('renderId');
				let reassignPageChildren = action.get('reassignPageChildren');
				if (state.hasIn(['renderIds', renderId])) {
					let parentRenderId;
					return state.withMutations((state) => {
						let renderDetails = state.getIn(['renderIds', renderId]);

						// Delete the child from its parent's children list.
						if(renderDetails) {
							if(renderDetails.has('renderParentId')) {
								parentRenderId = renderDetails.get('renderParentId');
								if (parentRenderId) {
									let parentRenderDetails = state.getIn(['renderIds', parentRenderId]);
									let newChildren = parentRenderDetails.get('children').filter(childRenderId => {
										return childRenderId !== renderId;
									})
									if(newChildren.includes(parentRenderId)) {
										console.error(new Error('Parent render ' + parentRenderId + ' tried to become its own child!'));
										newChildren = newChildren.filter(childRenderId => childRenderId !== parentRenderId);
									}
									state.setIn(['renderIds', parentRenderId, 'children'], newChildren);
								}
							}
							if(renderDetails.has('dataRecordId')) {
								let dataRecordId = renderDetails.get('dataRecordId') || '';
								state.deleteIn(['recordIds', dataRecordId, renderId]);
							}
						}

						// Find all of this parent's children... and delete them.
						let childRenderIds = _findAllChildren(state, renderId, reassignPageChildren);
						childRenderIds.forEach(childRenderId => {
							let childDetails = state.getIn(['renderIds', childRenderId]);
							let dataRecordId = childDetails.get('dataRecordId') || '';
							let componentType = childDetails.get('componentType');
							let shouldReassign = reassignPageChildren && componentType === 'page';
							// If we're reassigning child dialogs, reassign here
							if(shouldReassign) {
								state.setIn(['renderIds', childRenderId, 'renderParentId'], parentRenderId);
							} else {
								// Otherwise, delete it
								if(state.hasIn(['recordIds', dataRecordId, childRenderId])) {
									state.deleteIn(['recordIds', dataRecordId, childRenderId]);
								}
								state.deleteIn(['renderIds', childRenderId]);
							}
						});
						
						// Delete this render from renderIds
						state.deleteIn(['renderIds', renderId]);
					});
				} else {
					return state;
				}
			}
			case RenderConstants.DELETE_RENDER_BULK: {
				let FieldModesStore = require('./field-modes-store').default;
				let LocalContextStore = require('./local-context-store').default;
				let PageModeStore = require('./page-mode-store').default;
				let RecordSetStore = require('./record-set-store').default;

				//The Other Stores need information before we delete the render
				AppDispatcher.waitFor([
					FieldModesStore.getDispatchToken(),
					LocalContextStore.getDispatchToken(),
					PageModeStore.getDispatchToken(),
					RecordSetStore.getDispatchToken()
				]);

				let renderIds = action.get('renderIds');
				return state.withMutations(state => {
					renderIds.forEach(renderId => {
						if (state.hasIn(['renderIds', renderId])) {
							let renderDetails = state.getIn(['renderIds', renderId]);

							// Delete the child from its parent's children list.
							if (renderDetails) {
								if (renderDetails.has('renderParentId')) {
									let parentRenderId = renderDetails.get('renderParentId');
									if (parentRenderId) {
										let parentRenderDetails = state.getIn(['renderIds', parentRenderId]);
										let newChildren = parentRenderDetails.get('children').filter(childRenderId => {
											return childRenderId !== renderId;
										});
										if(newChildren.includes(parentRenderId)) {
											console.error(new Error('Parent render ' + parentRenderId + ' tried to become its own child!'));
											newChildren = newChildren.filter(childRenderId => childRenderId !== parentRenderId);
										}
										state.setIn(['renderIds', parentRenderId, 'children'], newChildren);
									}
								}
								let dataRecordId = renderDetails.get('dataRecordId') || '';
								if(state.hasIn(['recordIds', dataRecordId, renderId])) {
									state.deleteIn(['recordIds', dataRecordId, renderId]);
								}
							}

							// Find all of this parent's children... and delete them.
							let childRenderIds = _findAllChildren(state, renderId);
							childRenderIds.forEach(childRenderId => {
								let childDetails = state.getIn(['renderIds', childRenderId]);
								state.deleteIn(['renderIds', childRenderId]);
								let dataRecordId = childDetails.get('dataRecordId') || '';
								if(state.hasIn(['recordIds', dataRecordId, childRenderId])) {
									state.deleteIn(['recordIds', dataRecordId, childRenderId]);
								}
							})

							// Delete this render.
							state.deleteIn(['renderIds', renderId]);
						}
					});
				});
			}
			case RenderConstants.DELETE_RENDER_RECORDSET: {
				let renderId = action.get('renderId'),
					recordSetName = action.get('recordSetName'),
					renderObj = this.get(renderId),
					newState = state,
					renderRecordSets = (renderObj && renderObj.recordSets && Array.isArray(renderObj.recordSets) 
						? renderObj.recordSets
						: []);

				if(renderRecordSets) {
					const index = renderRecordSets.indexOf(recordSetName);
					if (index !== -1) {
						renderRecordSets.splice(index, 1);
					}

					// Store the updated old parent in the state.
					newState = state.mergeIn([renderId], Immutable.Map({ 
						recordSets: renderRecordSets
					}));
				}
				
				return newState;
			}
			case RenderConstants.SET_RENDER_RECORD: {
				let renderId =  action.get('renderId');
				let dataRecordId = action.get('dataRecordId') || '';
				let dataTableSchemaName = action.get('dataTableSchemaName');
				let oldDataRecordId = state.getIn(['renderIds', renderId, 'dataRecordId']);
				if(dataRecordId !== oldDataRecordId) {
					return state.withMutations(state => {
						state.mergeIn(['renderIds', renderId], {dataRecordId, dataTableSchemaName});
						state.setIn(['recordIds', dataRecordId, renderId], true);
						state.deleteIn(['recordIds', oldDataRecordId, renderId]);
					});
				} else {
					return state;
				}
			}

			case RenderConstants.SET_RENDER_RECORD_BULK: {
				return state.withMutations((state) => {
					action.get('renders').forEach((render) => {
						let renderId = render.get('renderId');
						let dataRecordId = render.get('dataRecordId') || '';
						let oldDataRecordId = state.getIn(['renderIds', renderId, 'dataRecordId']) || '';
						state.setIn(['renderIds', renderId, 'dataRecordId'], dataRecordId);
						state.setIn(['renderIds', renderId, 'dataTableSchemaName'], render.get('dataTableSchemaName'));
						state.setIn(['recordIds', dataRecordId, renderId], true);
						if(dataRecordId !== oldDataRecordId) {
							state.deleteIn(['recordIds', oldDataRecordId, renderId]);
						}
					});
				})
			}

			case RenderConstants.SET_RENDER_RECORDSET: {
				let renderId = action.get('renderId'),
					recordSetName = action.get('recordSetName'),
					renderRecordSets = this._addRecordSetToArray(renderId, recordSetName);

				// Store the updated old parent in the state.
				let newState = state.mergeIn([renderId], Immutable.Map({ 
					recordSets: renderRecordSets
				}));
				
				return newState;
			}

			case RenderConstants.SET_RENDER_BULK: {
				let toReturn = state.withMutations((state) => {
					let renders = action.get('renders');
					for (let index = 0; index < renders.size; index++) {
						let render = renders.get(index);
						setRender(state, render);
					}
				});
				return toReturn;
			}

			case RenderConstants.SET_RENDER: {
				return state.withMutations((state) => {
					setRender(state, action);
				});
			}

			case ListConstants.INIT_LIST: {
				let setName = action.get('setName');
				let renderId = action.get('renderId');
				let newState = state;
				if(renderId && setName) {
					let renderRecordSets = this._addRecordSetToArray(renderId, setName);

					// Store the updated old parent in the state.
					if(!state.hasIn(['renderIds', renderId])) {
						console.warn('Initting list for renderId %s without an existing renderIds entry', renderId);
					}
					newState = state.mergeIn(['renderIds', renderId], Immutable.Map({ 
						recordSets: renderRecordSets
					}));
				}
				return newState;
			}

			// If a record set is being set through the record actions...
			case RecordConstants.RECORD_UPDATE: {
				let tableSchemaName = action.get('tableSchemaName');
				let recordId = action.get('recordId');
				let affectedRenders = state.hasIn(['recordIds', recordId]) ? state.getIn(['recordIds', recordId]) : undefined;
				let newState = state;
				if(affectedRenders) {
					let values = action.get('values');
					let recordSetNames = {};
					// Find the names of the impacted record sets and which fields they impact
					values.forEach((value, fieldSchemaName) => {
						let fieldId = FieldStore.getFieldId(fieldSchemaName, tableSchemaName);
						let fieldObj = FieldStore.get(fieldId) || {};
						if(fieldObj.fieldType === '528c3e72-3a0d-4dc9-8e81-8be6f3c29c5c') {
							recordSetNames[fieldId] = [fieldId + '-original', fieldId + '-selected'];
						}
					});
					newState = state.withMutations((state) => {
						affectedRenders.forEach((value, renderId) => {
							let renderObj = this.get(renderId) || {};
							let componentId = renderObj.componentId;
							if(recordSetNames[componentId]) {
								let recordSets = renderObj.recordSets || [];
								recordSetNames[componentId].forEach(recordSet => {
									if(recordSets.indexOf(recordSet) === -1) {
										recordSets.push(recordSet);
									}
								});
								renderObj.recordSets = recordSets;
								setRender(state, Immutable.fromJS(renderObj));
							}
						});
					});
				}
				return newState;
			}

			// case RecordSetConstants.SET_SELECTED_CONTENT_TAB: {
			// Removed because it is now causing undesired behavior (31090 - changeing tabs forgets autofit height.)
			// }

			case RecordSetConstants.SET_RECORD_SET: {
				let setName = action.get('setName'),
					renderId = action.get('renderId'),
					newState = state;

				if(renderId && setName) {
					newState = newState.withMutations(state => {
						let renderRecordSets = this._addRecordSetToArray(renderId, setName);

						// Is this container relying on that query result?
						// Because if so, we need to update it
	
						if(!state.hasIn(['renderIds', renderId])) {
							console.warn('Initting record set for renderId %s and setName %s without an existing renderIds entry', renderId, setName);
						} else {
							let oldRender = state.getIn(['renderIds', renderId]);
							let componentType = oldRender && oldRender.get('componentType');
							let componentId = oldRender && oldRender.get('componentId');
							if(oldRender && !oldRender.get('repeatingGrid') && componentType === 'field' && setName === (componentId + '-results')) {
								// Is this a container field?
								// Because if it is, we need to update its children with the new record information
								let field = FieldStore.get(componentId);
								let fieldType = field ? field.fieldType : undefined;
								if(fieldType === '7ebd9251-675c-4129-95e3-6b8e31c135a2') {
									let rows = action.get('rows');
									let rowsJS = rows ? rows.toJS() : [];
									if(rowsJS) {
										let lastRefresh = action.get('lastDt');
										let dataTsn = rowsJS && rowsJS[0] ? rowsJS[0].tableSchemaName : '';
										let dataRecordId = rowsJS && rowsJS[0] ? rowsJS[0].recordId : '';
										state.setIn(['renderIds', renderId, 'dataTableSchemaName'], dataTsn);
										state.setIn(['renderIds', renderId, 'dataRecordId'], dataRecordId);
										// Update the lastRefresh of this and all descendants
										state.setIn(['renderIds', renderId, 'lastRefresh'], lastRefresh);
										// Now recurse over any non-page children
										let children = oldRender.children || [];
										children.forEach(renderId => {
											recursivelySetRecordSet(state, renderId, dataRecordId, dataTsn, lastRefresh);
										});
										// Now refresh any children of that field, so long as they are not pages
										// let descendants = this.getDescendants(renderId, true);
										// descendants.forEach(renderId => {
										// 	let protectDataRecordId = state.getIn(['renderIds', renderId, 'protectDataRecordId']);
										// 	let isRepeating = state.getIn(['renderIds', renderId, 'repeatingGrid']);
										// 	if(!protectDataRecordId && !isRepeating) {
										// 		state.setIn(['renderIds', renderId, 'dataTableSchemaName'], dataTsn);
										// 		state.setIn(['renderIds', renderId, 'dataRecordId'], dataRecordId);
										// 	}
										// 	let descendantLastRefresh = state.getIn(['renderIds', renderId, 'lastRefresh'], lastRefresh);
										// 	state.setIn(['renderIds', renderId, 'lastRefresh'], Math.max(descendantLastRefresh, lastRefresh));
										// });
									}
								}
							}
						}
						// Store the updated old parent in the state.
						state.mergeIn(['renderIds', renderId], Immutable.Map({ 
							recordSets: renderRecordSets
						}));
					})
				}

				return newState;
			}

			case RecordSetConstants.SET_SELECTED_RECORD_SET: {
				let setName = action.get('setName');
				let renderId = action.get('renderId');
				let newState = state;

				if(renderId && setName) {
					let renderRecordSets = this._addRecordSetToArray(renderId, setName);

					if(!state.hasIn(['renderIds', renderId])) {
						console.warn('Setting selected record set for renderId %s and setName %s without an existing renderIds entry', setName, renderId);
					}
					// Store the updated old parent in the state.
					newState = state.mergeIn(['renderIds', renderId], Immutable.Map({ 
						recordSets: renderRecordSets
					}));
				}

				return newState;
			}

			case RenderConstants.REFRESH_FIELD: {
				let fieldId = action.get('fieldId');
				let lastRefresh = action.get('lastRefresh');
				let lastChildCalculation = lastRefresh;
				let newState = state.withMutations(state => {
					
					
					// First update any child grids
					if(action.has('grids')) {
						let grids = action.get('grids');
						grids.forEach(grid => {
							updateGridWithChildren.call(this, state, grid, lastChildCalculation);
						});
					}

					// THEN update the lastRefresh. (Do it in this order to the child grid update doesn't think it's already been updated - ticket 27036)
					state.get('renderIds').forEach((renderMap, renderId) => {
						if(renderMap.get('componentType') === 'field' && renderMap.get('componentId') === fieldId && (!renderMap.has('lastRefresh') || renderMap.get('lastRefresh') < lastRefresh)) {
							// Avoid obnoxious nested withMutation and reassignment stuff
							state.setIn(['renderIds', renderId, 'lastRefresh'], lastRefresh);
							// Now refresh any children of that field, so long as they are not pages
							let descendants = this.getDescendants(renderId, true);
							descendants.forEach(renderId => {
								let descendantLastRefresh = state.getIn(['renderIds', renderId, 'lastRefresh'], lastRefresh);
								state.setIn(['renderIds', renderId, 'lastRefresh'], Math.max(descendantLastRefresh, lastRefresh));
							});

						} else if(renderMap.get('componentType') === 'field' && renderMap.get('componentId') === fieldId) {
							// Even if we don't want to update the parent, we want to update the descendants. (Part of the solution for 30474 - expressions not getting updating value)
							let descendants = this.getDescendants(renderId, true);
							descendants.forEach(renderId => {
								let descendantLastRefresh = state.getIn(['renderIds', renderId, 'lastRefresh'], lastRefresh);
								state.setIn(['renderIds', renderId, 'lastRefresh'], Math.max(descendantLastRefresh, lastRefresh));
							});
						}
					});
				});
				return newState;
			}

			case RenderConstants.CALCULATE_ATTACHED_FIELDS: {
				let parentRenderId = action.get('parentRenderId');
				let attachedFields = action.get('attachedFields');
				let dataRecordId = action.get('dataRecordId');
				let dataTableSchemaName = action.get('dataTableSchemaName');
				let lastChildCalculation = action.get('lastChildCalculation');
				let componentId = action.get('componentId');
				let componentType = action.get('componentType');

				return state.withMutations(state => {
					updateChildFields.call(this, state, parentRenderId, undefined, componentId, componentType, dataRecordId, dataTableSchemaName, attachedFields, lastChildCalculation, undefined);
				});
			}

			case RenderConstants.INIT_PAGE: {
				let lastChildCalculation = action.get('lastChildCalculation');
				let query = action.get('query');

				return state.withMutations(state => {
					if(action.has('grids')) {
						let grids = action.get('grids');
						grids.forEach(grid => {
							updateGridWithChildren.call(this, state, grid, lastChildCalculation);
						});
					}
					if(query) {
						// Store the record set in the parent grid
						let renderId = action.get('parentRenderId');
						let setName = action.get('setName');
						state.setIn(['renderIds', renderId, 'recordSets'], this._addRecordSetToArray(renderId, setName));
					}
				});
			}
			case RenderConstants.INIT_GRID: {
				// I think that we need to delete the children which are no longer present in this
				let lastChildCalculation = action.get('lastChildCalculation');
				let query = action.get('query');

				return state.withMutations(state => {
					if(action.has('grids')) {
						let grids = action.get('grids');
						grids.forEach(grid => {
							let parentRenderId = grid.get('parentRenderId');
							let attachedFields = grid.get('attachedFields');
							let dataRecordId = grid.get('dataRecordId');
							let dataTableSchemaName = grid.get('dataTableSchemaName');
							let componentId = grid.get('componentId');
							let componentType = grid.get('componentType');
							let grandparent = grid.get('grandparent');
							let hasExpanding = grid.get('hasExpanding');
							let spacerHeights = grid.get('spacerHeights');
							let gridLayoutHeights = grid.get('gridLayoutHeights');
							let renderObj = state.getIn(['renderIds', parentRenderId]);
							if(renderObj && !gridLayoutHeights) {
								gridLayoutHeights = renderObj.gridLayoutHeights;
							}
							if(grid.has('repeatingGrid')) {
								state.setIn(['renderIds', parentRenderId, 'repeatingGrid'], grid.get('repeatingGrid'));
							}
							updateChildFields.call(this, state, parentRenderId, grandparent, componentId, componentType, dataRecordId, dataTableSchemaName, attachedFields, lastChildCalculation, gridLayoutHeights, hasExpanding, spacerHeights);
							if(grid.has('gridInfo')) {
								state.setIn(['renderIds', parentRenderId, 'gridInfo'], grid.get('gridInfo'));
							}
							if(grid.has('attachmentKey')) {
								state.setIn(['renderIds', parentRenderId, 'attachmentKey'], grid.get('attachmentKey'));
							}

							let recordSets = grid.get('recordSets');
							if(recordSets) {
								recordSets.forEach(setValue => {
									let setName = setValue.get('setName');
									state.setIn(['renderIds', parentRenderId, 'recordSets'], this._addRecordSetToArray(parentRenderId, setName));
								})
							}
						});
					}
					if(query) {
						// Store the record set in the parent grid
						let renderId = action.get('parentRenderId');
						let setName = action.get('setName');
						state.setIn(['renderIds', renderId, 'recordSets'], this._addRecordSetToArray(renderId, setName));
					}
				});
			}

			case RenderConstants.INIT_REPEATING_GRID:
			case RenderConstants.RECALC_REPEATING_GRID: {
				let lastChildCalculation = action.get('lastDt');

				return state.withMutations(state => {
					if(action.has('grids')) {
						let grids = action.get('grids');
						grids.forEach((grid, index) => {
							if(!grid) {
								// Skip if grid is somehow missing.
								return;
							}
							// This setup is slightly different from INIT_GRID
							// The reason for this is that the children may be direct
							// renderStore entries
							let parentRenderId = grid.get('parentRenderId');
							let renderEntry = state.getIn(['renderIds', parentRenderId]);
							if(grid.get('repeatingGrid') && renderEntry && !renderEntry.get('repeatingGrid')) {
								// If we're switching from a singular to repeating grid
								// then we need to clear out any existing children
								deleteChildren(state, parentRenderId);
							}
							if(grid.get('repeatingGrid')) {
								state.setIn(['renderIds', parentRenderId, 'repeatingGrid'], grid.get('repeatingGrid'));
							}
							// let attachedFields = !index && action.get('type') === RenderConstants.INIT_REPEATING_GRID ? [] : undefined;
							let attachedFields = grid.get('attachedFields');
							let dataRecordId = grid.get('dataRecordId');
							let dataTableSchemaName = grid.get('dataTableSchemaName');
							let componentId = grid.get('componentId');
							let componentType = grid.get('componentType');
							let grandparent = grid.get('grandparent');
							let hasExpanding = grid.get('hasExpanding');
							let spacerHeights = grid.get('spacerHeights');
							updateChildFields.call(this, state, parentRenderId, grandparent, componentId, componentType, dataRecordId, dataTableSchemaName, attachedFields, lastChildCalculation, undefined, hasExpanding, spacerHeights);
							if(grid.has('gridInfo')) {
								state.setIn(['renderIds', parentRenderId, 'gridInfo'], grid.get('gridInfo'));
							}
							if(grid.has('attachmentKey')) {
								state.setIn(['renderIds', parentRenderId, 'attachmentKey'], grid.get('attachmentKey'));
							}
							if(grid.has('repeatingGrid')) {
								state.setIn(['renderIds', parentRenderId, 'repeatingGrid'], grid.get('repeatingGrid'));
							}
							if(!index) {
								// If this is the first one, we need to store the record sets as well
								let setName = action.get('setName');
								if(setName) {
									state.setIn(['renderIds', parentRenderId, 'recordSets'], Immutable.fromJS([setName]));
								}
							}
						});
					}
				});
			}

			case AdminSettingsConstants.OVERLAY_CHANGE_WITH_RECALC: {
				AppDispatcher.waitFor([AdminSettingsStore.getDispatchToken()]);
				let lastChildCalculation = action.get('lastChildCalculation');
				return state.withMutations(state => {
					if(action.has('grids')) {
						let grids = action.get('grids');
						grids.forEach(grid => {
							updateGridWithChildren.call(this, state, grid, lastChildCalculation);
						});
					}
				});
			}

			// case AdminSettingsConstants.CENTER_COLUMN_RESIZE: {
			case RenderConstants.GRID_UPDATE: {
				AppDispatcher.waitFor([AdminSettingsStore.getDispatchToken()]);
				let lastChildCalculation = action.get('lastChildCalculation');
				return state.withMutations(state => {
					if(action.has('grids')) {
						let grids = action.get('grids');
						grids.forEach(grid => {
							updateGridWithChildren.call(this, state, grid, lastChildCalculation);
						});
					}
				});
			}

			case RenderConstants.ATTACHED_FIELDS_CHANGE: {
				let componentType = action.get('componentType');
				let componentStore = componentType === 'page' ? PageStore : FieldStore;
				AppDispatcher.waitFor([componentStore.getDispatchToken()]);
				let recordId = action.get('recordId');
				let recordProperties = action.get('recordProperties');
				// Don't waste time recalculating if the overlay is active
				// because that can result in a lot of changes
				let resizerOverlayEnabled = AdminSettingsStore.getIsOverlayActive('resizer');

				return state.withMutations(state => {
					if(action.has('grids')) {
						let lastChildCalculation = action.get('lastChildCalculation');
						let grids = action.get('grids');
						grids && grids.forEach(grid => {
							let parentRenderId = grid.get('parentRenderId');
							let attachedFields = grid.get('attachedFields');
							let dataRecordId = grid.get('dataRecordId');
							let dataTableSchemaName = grid.get('dataTableSchemaName');
							let componentId = grid.get('componentId');
							let componentType = grid.get('componentType');
							let grandparent = grid.get('grandparent');
							let hasExpanding = grid.get('hasExpanding');
							let spacerHeights = grid.get('spacerHeights');
							updateChildFields.call(this, state, parentRenderId, grandparent, componentId, componentType, dataRecordId, dataTableSchemaName, attachedFields, lastChildCalculation, undefined, hasExpanding, spacerHeights);
							if(grid.has('attachmentKey')) {
								state.setIn(['renderIds', parentRenderId, 'attachmentKey'], grid.get('attachmentKey'));
							}
						});
					}
					if(recordProperties && (recordProperties.has('fieldPosition') || recordProperties.has('fieldPositionExtras'))) {
						// If the page's attached fields, field position or field position extras have changed
						// then we need to update the render store appropriately
						let componentObj = componentStore.get(recordId);
						let fieldPositionJSON = recordProperties.has('fieldPosition') ? recordProperties.get('fieldPosition') : componentObj.fieldPosition;
						let fieldPositionExtrasJSON = recordProperties.has('fieldPositionExtras') ? recordProperties.get('fieldPositionExtras') : componentObj.fieldPositionExtras;

						let fieldPosition = fieldPositionJSON ? JSON.parse(fieldPositionJSON) : {};
						let fieldPositionExtras = fieldPositionExtrasJSON ? JSON.parse(fieldPositionExtrasJSON) : {};

						// Make an index to make this lookup easier

						// Find all render store entries which match this page
						// then find all of its children and update them as per the new fieldPosition and fieldPositionExtras information
						
						reconcileFieldPosition.call(this, state, fieldPosition, fieldPositionExtras, componentType, recordId, resizerOverlayEnabled);
					}
				});
			}

			case PageConstants.PAGE_DELETE_FROM_STORE:
			case FieldConstants.FIELD_DELETE_FROM_STORE: {
				AppDispatcher.waitFor([RecordSetStore.getDispatchToken()]);
				let recordId = action.get('recordId');
				// Find all render store entries which match this and delete them
				return state.withMutations(state => {
					let matchingRenders = this.getRenderObjectsForComponent(action.get('type') === PageConstants.PAGE_DELETE_FROM_STORE ? 'page' : 'field', recordId);
					matchingRenders.forEach((render, renderId) => {
						if(state.hasIn(['renderIds', renderId])) {
							let parentRenderId;

							let renderDetails = state.getIn(['renderIds', renderId]);

							// Delete the child from its parent's children list.
							if(renderDetails) {
								if(renderDetails.has('renderParentId')) {
									parentRenderId = renderDetails.get('renderParentId');
									if (parentRenderId) {
										let parentRenderDetails = state.getIn(['renderIds', parentRenderId]);
										let newChildren = parentRenderDetails.get('children').filter(childRenderId => {
											return childRenderId !== renderId;
										});
										if(newChildren.includes(parentRenderId)) {
											console.error(new Error('Parent render ' + parentRenderId + ' tried to become its own child!'));
											newChildren = newChildren.filter(childRenderId => childRenderId !== parentRenderId);
										}
										state.setIn(['renderIds', parentRenderId, 'children'], newChildren);
									}
								}
								if(renderDetails.has('dataRecordId')) {
									let dataRecordId = renderDetails.get('dataRecordId') || '';
									state.deleteIn(['recordIds', dataRecordId, renderId]);
								}
							}

							// Find all of this parent's children... and delete them.
							let childRenderIds = _findAllChildren(state, renderId, true);
							childRenderIds.forEach(childRenderId => {
								let childDetails = state.getIn(['renderIds', childRenderId]);
								let dataRecordId = childDetails.get('dataRecordId') || '';
								let componentType = childDetails.get('componentType');
								let shouldReassign = componentType === 'page';
								// If we're reassigning child dialogs, reassign here
								if(shouldReassign) {
									state.setIn(['renderIds', childRenderId, 'renderParentId'], parentRenderId);
								} else {
									// Otherwise, delete it
									if(state.hasIn(['recordIds', dataRecordId, childRenderId])) {
										state.deleteIn(['recordIds', dataRecordId, childRenderId]);
									}
									state.deleteIn(['renderIds', childRenderId]);
								}
							});
							
							// Delete this render from renderIds
							state.deleteIn(['renderIds', renderId]);
						}
					});
				});
			}

			case PageConstants.PAGE_PUSH_TO_STORE: {
				AppDispatcher.waitFor([PageStore.getDispatchToken()]);
				let recordId = action.get('recordId');
				let recordProperties = action.get('recordProperties');
				// Don't waste time recalculating if the overlay is active
				// because that can result in a lot of changes
				let resizerOverlayEnabled = AdminSettingsStore.getIsOverlayActive('resizer');


				return state.withMutations(state => {
					if(recordProperties && (recordProperties.has('fieldPosition') || recordProperties.has('fieldPositionExtras'))) {
						// If the page's attached fields, field position or field position extras have changed
						// then we need to update the render store appropriately
						let pageObj = PageStore.get(recordId);
						let fieldPositionJSON = recordProperties.has('fieldPosition') ? recordProperties.get('fieldPosition') : pageObj.fieldPosition;
						let fieldPositionExtrasJSON = recordProperties.has('fieldPositionExtras') ? recordProperties.get('fieldPositionExtras') : pageObj.fieldPositionExtras;

						let fieldPosition = fieldPositionJSON ? JSON.parse(fieldPositionJSON) : {};
						let fieldPositionExtras = fieldPositionExtrasJSON ? JSON.parse(fieldPositionExtrasJSON) : {};

						// Make an index to make this lookup easier

						// Find all render store entries which match this page
						// then find all of its children and update them as per the new fieldPosition and fieldPositionExtras information
						
						reconcileFieldPosition.call(this, state, fieldPosition, fieldPositionExtras, 'page', recordId, resizerOverlayEnabled);
					}
				});
			}

			case PageConstants.PAGE_RECEIVE_BROADCAST: {
				AppDispatcher.waitFor([PageStore.getDispatchToken()]);
				let records = action.get('records');
				let resizerOverlayEnabled = AdminSettingsStore.getIsOverlayActive('resizer');
				return state.withMutations(state => {
					// First of all, handle any grids
					if(action.has('grids')) {
						let lastChildCalculation = action.get('lastChildCalculation');
						let grids = action.get('grids');
						grids && grids.forEach(grid => {
							let parentRenderId = grid.get('parentRenderId');
							let attachedFields = grid.get('attachedFields');
							let dataRecordId = grid.get('dataRecordId');
							let dataTableSchemaName = grid.get('dataTableSchemaName');
							let componentId = grid.get('componentId');
							let componentType = grid.get('componentType');
							let grandparent = grid.get('grandparent');
							let hasExpanding = grid.get('hasExpanding');
							let spacerHeights = grid.get('spacerHeights');
							updateChildFields.call(this, state, parentRenderId, grandparent, componentId, componentType, dataRecordId, dataTableSchemaName, attachedFields, lastChildCalculation, undefined, hasExpanding, spacerHeights);
							if(grid.has('attachmentKey')) {
								state.setIn(['renderIds', parentRenderId, 'attachmentKey'], grid.get('attachmentKey'));
							}
						});
					}

					if(records) {
						let filteredRecords = records.filter(record => record.hasIn(['properties', 'fieldPosition']) || record.hasIn(['properties', 'fieldPositionExtras']));
						filteredRecords.forEach(record => {
							let recordId = record.get('recordId');
							// If the page's attached fields, field position or field position extras have changed
							// then we need to update the render store appropriately
							let pageObj = PageStore.get(recordId);
							let fieldPositionJSON = record.hasIn(['properties', 'fieldPosition']) ? record.getIn(['properties', 'fieldPosition']) : pageObj.fieldPosition;
							let fieldPositionExtrasJSON = record.hasIn(['properties', 'fieldPositionExtras']) ? record.get(['properties', 'fieldPositionExtras']) : pageObj.fieldPositionExtras;

							let fieldPosition = fieldPositionJSON ? JSON.parse(fieldPositionJSON) : {};
							let fieldPositionExtras = fieldPositionExtrasJSON ? JSON.parse(fieldPositionExtrasJSON) : {};

							// Make an index to make this lookup easier

							// Find all render store entries which match this page
							// then find all of its children and update them as per the new fieldPosition and fieldPositionExtras information
							
							reconcileFieldPosition.call(this, state, fieldPosition, fieldPositionExtras, 'page', recordId, resizerOverlayEnabled);
						});
					}

					// Next up, handle any fieldPositions and fieldPositionExtras changes

				});
			}

			case FieldConstants.FIELD_PUSH_TO_STORE: {
				AppDispatcher.waitFor([FieldStore.getDispatchToken()]);
				let recordId = action.get('recordId');
				let recordProperties = action.get('recordProperties');
				// Don't waste time recalculating if the overlay is active
				// because that can result in a lot of changes
				let resizerOverlayEnabled = AdminSettingsStore.getIsOverlayActive('resizer');
				return state.withMutations(state => {
					if(recordProperties && (recordProperties.has('fieldPosition') || recordProperties.has('fieldPositionExtras'))) {
						// @TODO: I don't think this is handling properly for repeating grid fields
						// If the page's attached fields, field position or field position extras have changed
						// then we need to update the render store appropriately
						let fieldObj = FieldStore.get(recordId);
						let fieldPositionJSON = recordProperties.has('fieldPosition') ? recordProperties.get('fieldPosition') : fieldObj.fieldPosition;
						let fieldPositionExtrasJSON = recordProperties.has('fieldPositionExtras') ? recordProperties.get('fieldPositionExtras') : fieldObj.fieldPositionExtras;

						let fieldPosition = fieldPositionJSON ? JSON.parse(fieldPositionJSON) : {};
						let fieldPositionExtras = fieldPositionExtrasJSON ? JSON.parse(fieldPositionExtrasJSON) : {};

						// Make an index to make this lookup easier

						// Find all render store entries which match this page
						// then find all of its children and update them as per the new fieldPosition and fieldPositionExtras information
						
						reconcileFieldPosition.call(this, state, fieldPosition, fieldPositionExtras, 'field', recordId, resizerOverlayEnabled);
					}
				});
			}

			case FieldConstants.FIELD_PUSH_SETTING_TO_STORE: {
				AppDispatcher.waitFor([FieldStore.getDispatchToken()]);
				let recordId = action.get('recordId');
				let settingSchemaName = action.get('settingSchemaName');
				let settingValue = action.get('settingValue');
				// Don't waste time recalculating if the overlay is active
				// because that can result in a lot of changes
				let resizerOverlayEnabled = AdminSettingsStore.getIsOverlayActive('resizer');
				// If the page's attached fields, field position or field position extras have changed
				// then we need to update the render store appropriately
				if(settingSchemaName ==='fieldPosition' || settingSchemaName === 'fieldPositionExtras') {
					return state.withMutations(state => {
						let fieldObj = FieldStore.get(recordId);
						let fieldPositionJSON = settingSchemaName === 'fieldPosition' ? settingValue : fieldObj.fieldPosition;
						let fieldPositionExtrasJSON = settingSchemaName === 'fieldPositionExtras' ? settingValue : fieldObj.fieldPositionExtras;

						let fieldPosition = JSON.parse(fieldPositionJSON);
						let fieldPositionExtras = fieldPositionExtrasJSON ? JSON.parse(fieldPositionExtrasJSON) : {};

						reconcileFieldPosition.call(this, state, fieldPosition, fieldPositionExtras, 'field', recordId, resizerOverlayEnabled);
					});
				} else {
					return state;
				}
			}

			case FieldConstants.FIELD_RECEIVE_BROADCAST: {
				AppDispatcher.waitFor([FieldStore.getDispatchToken()]);

				let records = action.get('records');
				let resizerOverlayEnabled = AdminSettingsStore.getIsOverlayActive('resizer');
				return state.withMutations(state => {
					// First of all, handle any grids
					if(action.has('grids')) {
						let lastChildCalculation = action.get('lastChildCalculation');
						let grids = action.get('grids');
						grids && grids.forEach(grid => {
							let parentRenderId = grid.get('parentRenderId');
							let attachedFields = grid.get('attachedFields');
							let dataRecordId = grid.get('dataRecordId');
							let dataTableSchemaName = grid.get('dataTableSchemaName');
							let componentId = grid.get('componentId');
							let componentType = grid.get('componentType');
							let grandparent = grid.get('grandparent');
							let hasExpanding = grid.get('hasExpanding');
							let spacerHeights = grid.get('spacerHeights');
							updateChildFields.call(this, state, parentRenderId, grandparent, componentId, componentType, dataRecordId, dataTableSchemaName, attachedFields, lastChildCalculation, undefined, hasExpanding, spacerHeights);
							if(grid.has('attachmentKey')) {
								state.setIn(['renderIds', parentRenderId, 'attachmentKey'], grid.get('attachmentKey'));
							}
						});
					}

					if(records) {
						let filteredRecords = records.filter(record => record.hasIn(['properties', 'fieldPosition']) || record.hasIn(['properties', 'fieldPositionExtras']));
						filteredRecords.forEach(record => {
							let recordId = record.get('recordId');
							// If the page's attached fields, field position or field position extras have changed
							// then we need to update the render store appropriately
							let fieldObj = FieldStore.get(recordId);
							let fieldPositionJSON = record.hasIn(['properties', 'fieldPosition']) ? record.getIn(['properties', 'fieldPosition']) : fieldObj.fieldPosition;
							let fieldPositionExtrasJSON = record.hasIn(['properties', 'fieldPositionExtras']) ? record.get(['properties', 'fieldPositionExtras']) : fieldObj.fieldPositionExtras;

							let fieldPosition = fieldPositionJSON ? JSON.parse(fieldPositionJSON) : {};
							let fieldPositionExtras = fieldPositionExtrasJSON ? JSON.parse(fieldPositionExtrasJSON) : {};

							// Make an index to make this lookup easier

							// Find all render store entries which match this page
							// then find all of its children and update them as per the new fieldPosition and fieldPositionExtras information
							
							reconcileFieldPosition.call(this, state, fieldPosition, fieldPositionExtras, 'field', recordId, resizerOverlayEnabled);
						})
					}

					// Next up, handle any fieldPositions and fieldPositionExtras changes

				});
			}

			case RenderConstants.FAIL_SAVE_GRID: {
				let gridId = action.get('gridId');
				let failedAt = action.get('failedAt');
				let newState = state.withMutations(newState => {
					// Update the last failed validation time for the grid
					if(newState.hasIn(['renderIds', gridId])) {
						newState.setIn(['renderIds', gridId, 'validationLastFailed'], failedAt);
					};

					// Clear out the disabled flags for all components.
					// (This is a necessary part of our enable/disable field actions workaround for 8806)
					newState.get('renderIds').forEach((renderMap, renderId) => {
						// This is harmless and will do nothing if it's not already set
						newState.deleteIn(['renderIds', renderId, 'disabled']);
					});
				});
				return newState;
			}

			case FieldModesConstants.SET_AVAILABLE_MODES:
			case PageModeConstants.SET_PAGE_MODE: {
				let pageRenderId = action.get('renderId');
				let lastChildCalculation = action.get('lastChildCalculation');
				let grids = action.get('grids');
				if(!grids) {
					return state;
				}
				return state.withMutations(state => {
					state.deleteIn(['renderIds', pageRenderId, 'validationLastFailed']);
					grids.forEach(grid => {
						updateGridWithChildren.call(this, state, grid, lastChildCalculation);
					});
				});
			}

			case FieldModesConstants.SET_MODE: {
				// Whenever the field mode changes, clear the last failed validation time
				let renderId = action.get('renderId');
				let lastChildCalculation = action.get('lastChildCalculation');
				return state.withMutations(state => {
					state.deleteIn(['renderIds', renderId, 'validationLastFailed']);
					let grids = action.get('grids');
					if(grids) {
						grids.forEach(grid => {
							updateGridWithChildren.call(this, state, grid, lastChildCalculation);
						});
					}
				})
			}

			case RenderConstants.COMPONENT_DISABLE: {
				let componentId = action.get('componentId');
				return state.withMutations((state) => {
					state.get('renderIds').forEach((renderMap, renderId) => {
						if (renderMap.get('componentId') === componentId) {
							state.setIn(['renderIds', renderId, 'disabled'], true);
						}
					});
				});

			}

			case RenderConstants.COMPONENT_ENABLE: {
				let componentId = action.get('componentId');
				return state.withMutations((state) => {
					state.get('renderIds').forEach((renderMap, renderId) => {
						if (renderMap.get('componentId') === componentId) {
							state.deleteIn(['renderIds', renderId, 'disabled']);
						}
					});
				});
			}

			case RenderConstants.UPDATE_GRID_ITEM_HEIGHTS_BULK: {
				let rowHeight = 12;
				let heightsByScreensize = action.get('heightsByScreensize');
				let GridHeightUtils = require('../utils/grid-height-utils').default;
				return state.withMutations(state => {
					heightsByScreensize.forEach((heights, managingScreensize) => {
						let parents = {};
						// Update each measured height
						heights.forEach((heightInfo, renderId) => {
							let height = heightInfo.get('height');
							let resizerHeightType = heightInfo.get('resizerHeightType');
							let lastDt = heightInfo.get('lastDt');
							let gridHeight = Math.ceil(height/rowHeight);
							if(managingScreensize && state.hasIn(['renderIds', renderId, 'gridInfo', managingScreensize])) {
								let render = state.getIn(['renderIds', renderId]);
								let oldHeight = render.getIn(['gridInfo', managingScreensize, 'h']) || 0;
								let originalHeight = render.getIn(['gridInfo', managingScreensize, 'originalH']) || 0;
								let parentRenderId = render.get('renderParentId');
								if(parentRenderId) {
									parents[parentRenderId] = true;
								}
								state.setIn(['renderIds', renderId, 'gridInfo', managingScreensize, 'h'], resizerHeightType === 'fit' ? Math.max(gridHeight, originalHeight) : Math.max(oldHeight, gridHeight));
								state.setIn(['renderIds', renderId, 'gridInfo', managingScreensize, 'lastDt'], lastDt);
							} else {
								let render = state.getIn(['renderIds', renderId]);
								if(!render) {
									// Initialize the entry for later use
									state.setIn(['renderIds', renderId], Immutable.fromJS({renderId, gridInfo: {[managingScreensize]: {h: gridHeight}}}));
								}
							}
						});

						// Now go through each parent and recalculate its children based on the above
						Object.keys(parents).forEach(parentRenderId => {
							let parentRender = state.getIn(['renderIds', parentRenderId]);
							if(parentRender) {
								let gridLayoutHeight = parentRender.hasIn(['gridLayoutHeights', managingScreensize]) ? parentRender.getIn(['gridLayoutHeights', managingScreensize]) : 0;
								gridLayoutHeight = gridLayoutHeight || 0;
								let columnYTracker = new Array(12);
								let fieldPositions = [];
								let gridInfoLookup = {};
								let children = parentRender.get('children');
								children.forEach(childRenderId => {
									let child = state.getIn(['renderIds', childRenderId]);
									if(child && child.hasIn(['gridInfo', managingScreensize])) {
										// We convert this to JS because we adjust this in place and it's easier
										let gridInfo = child.getIn(['gridInfo', managingScreensize]).toJS();
										// We reset the y's because they get re-adjusted
										// However, we leave the h's alone
										// unless it's an expanding field, in which case we need to recalculate later
										gridInfo.y = gridInfo.originalY;
										if(gridInfo.expands) {
											gridInfo.h = gridInfo.originalH;
										}
	
										fieldPositions.push(gridInfo);
	
										// We don't need to calculate autofit here because it's handled above
										// Grid information tracking
										let left = gridInfo.x;
										let right = gridInfo.x + gridInfo.w;
	
										for (let i = left; i < right; i++) {
											columnYTracker[i] = columnYTracker[i] || [];
											columnYTracker[i].push(gridInfo);
										}
										gridInfoLookup[childRenderId] = gridInfo;
									}
								});
	
								columnYTracker.forEach((col, index) => {
									// Sort based on the y values
									// We do this before repositioning any fields because when dealing with staggered fields,
									// sometimes fields can be bumped down below other fields by changes in one column and then
									// end up above that field in the second column, causing it not to be pushed down.
									// (Issue discovered by Ben in testing on https://dev-eng-stage.citizendeveloper.com/7567d364-bca1-41b3-8c63-7efab3fb559b/Ben_Testings/03917b82-a5dd-414d-ab62-15634ec4e6c9)
									col = col.sort((a, b) => {
										return a.y - b.y;
									});
									columnYTracker[index] = col;
								});
	
	
								let {columnSpacers} = GridHeightUtils.reconcileGridShifts(gridLayoutHeight, columnYTracker, fieldPositions);

								state.setIn(['renderIds', parentRender.get('renderId'), 'spacerHeights', managingScreensize], Immutable.fromJS(columnSpacers));
								Object.keys(gridInfoLookup).forEach(childRenderId => {
									state.setIn(['renderIds', childRenderId, 'gridInfo', managingScreensize], Immutable.fromJS(gridInfoLookup[childRenderId]));
								});
							}
						});
					});
				});
			}

			case RenderConstants.UPDATE_GRID_ITEM_HEIGHT: {
				let rowHeight = 12;
				let renderId = action.get('renderId');
				let height = action.get('height');
				let managingScreensize = action.get('managingScreensize');
				let lastDt = action.get('lastDt');
				let GridHeightUtils = require('../utils/grid-height-utils').default;
				let resizerHeightType = action.get('resizerHeightType');
				return state.withMutations(state => {
					// We need to update the h in the gridInfo
					let gridHeight = Math.ceil(height/rowHeight);
					if(managingScreensize && state.hasIn(['renderIds', renderId, 'gridInfo', managingScreensize])) {
						let oldHeight = state.getIn(['renderIds', renderId, 'gridInfo', managingScreensize, 'h']) || 0;
						let originalHeight = state.getIn(['renderIds', renderId, 'gridInfo', managingScreensize, 'originalH']) || 0;
						state.setIn(['renderIds', renderId, 'gridInfo', managingScreensize, 'h'], resizerHeightType === 'fit' ? Math.max(gridHeight, originalHeight) : Math.max(oldHeight, gridHeight));
						state.setIn(['renderIds', renderId, 'gridInfo', managingScreensize, 'lastDt'], lastDt);
						// Now we need to update the y positions of our other fields
						// to avoid overlap.
						let render = state.getIn(['renderIds', renderId]);
						let parentRender = render.has('renderParentId') ? state.getIn(['renderIds', render.get('renderParentId')]) : undefined;
						if(parentRender) {
							let gridLayoutHeight = parentRender.hasIn(['gridLayoutHeights', managingScreensize]) ? parentRender.getIn(['gridLayoutHeights', managingScreensize]) : 0;
							gridLayoutHeight = gridLayoutHeight || 0;
							let columnYTracker = new Array(12);
							let fieldPositions = [];
							let gridInfoLookup = {};
							let children = parentRender.get('children');
							children.forEach(childRenderId => {
								let child = state.getIn(['renderIds', childRenderId]);
								if(child && child.hasIn(['gridInfo', managingScreensize])) {
									// We convert this to JS because we adjust this in place and it's easier
									let gridInfo = child.getIn(['gridInfo', managingScreensize]).toJS();
									// We reset the y's because they get re-adjusted
									// However, we leave the h's alone
									// unless it's an expanding field, in which case we need to recalculate later
									gridInfo.y = gridInfo.originalY;
									if(gridInfo.expands) {
										gridInfo.h = gridInfo.originalH;
									}

									fieldPositions.push(gridInfo);

									// We don't need to calculate autofit here because it's handled above
									// Grid information tracking
									let left = gridInfo.x;
									let right = gridInfo.x + gridInfo.w;

									for (let i = left; i < right; i++) {
										columnYTracker[i] = columnYTracker[i] || [];
										columnYTracker[i].push(gridInfo);
									}
									gridInfoLookup[childRenderId] = gridInfo;
								}
							});

							columnYTracker.forEach((col, index) => {
								// Sort based on the y values
								// We do this before repositioning any fields because when dealing with staggered fields,
								// sometimes fields can be bumped down below other fields by changes in one column and then
								// end up above that field in the second column, causing it not to be pushed down.
								// (Issue discovered by Ben in testing on https://dev-eng-stage.citizendeveloper.com/7567d364-bca1-41b3-8c63-7efab3fb559b/Ben_Testings/03917b82-a5dd-414d-ab62-15634ec4e6c9)
								col = col.sort((a, b) => {
									return a.y - b.y;
								});
								columnYTracker[index] = col;
							});


							let {columnSpacers} = GridHeightUtils.reconcileGridShifts(gridLayoutHeight, columnYTracker, fieldPositions);

							state.setIn(['renderIds', parentRender.get('renderId'), 'spacerHeights', managingScreensize], Immutable.fromJS(columnSpacers));
							Object.keys(gridInfoLookup).forEach(childRenderId => {
								state.setIn(['renderIds', childRenderId, 'gridInfo', managingScreensize], Immutable.fromJS(gridInfoLookup[childRenderId]));
							});
						}
					} else {
						let render = state.getIn(['renderIds', renderId]);
						if(!render) {
							// Initialize the entry for later use
							state.setIn(['renderIds', renderId], Immutable.fromJS({renderId, gridInfo: {[managingScreensize]: {h: gridHeight}}}));
						}
					}
				});
			}

			case RenderConstants.UPDATE_GRID_LAYOUT_HEIGHT: {
				let renderId = action.get('renderId');
				let height = action.get('height');
				let managingScreensize = action.get('managingScreensize');
				let GridHeightUtils = require('../utils/grid-height-utils').default;
				return state.withMutations(state => {
					let render = state.getIn(['renderIds', renderId]);
					// We need to update the h in the gridInfo
					if (managingScreensize && render) {

						// We have a problem where gridLayoutHeights is being set as a number and not a map
						// track it down and fix that
						if (state.hasIn(['renderIds', renderId, 'gridLayoutHeights'])) {
							state.setIn(['renderIds', renderId, 'gridLayoutHeights', managingScreensize], height);
						} else {
							state.setIn(['renderIds', renderId, 'gridLayoutHeights'], Immutable.fromJS({ [managingScreensize]: height }));
						}

						// Now we need to update the y positions of our other fields
						// to avoid overlap.

						let gridLayoutHeight = state.hasIn(['renderIds', renderId, 'gridLayoutHeights', managingScreensize]) ? state.getIn(['renderIds', renderId, 'gridLayoutHeights', managingScreensize]) : 0;
						gridLayoutHeight = gridLayoutHeight || 0;
						let columnYTracker = new Array(12);
						let fieldPositions = [];
						let gridInfoLookup = {};
						let children = render.get('children');
						children.forEach(childRenderId => {
							let child = state.getIn(['renderIds', childRenderId]);
							if (child && child.hasIn(['gridInfo', managingScreensize])) {
								// We convert this to JS because we adjust this in place and it's easier
								let gridInfo = child.getIn(['gridInfo', managingScreensize]).toJS();
								// We reset the y's because they get re-adjusted
								// However, we leave the h's alone
								// unless it's an expanding field, in which case we need to recalculate later
								gridInfo.y = gridInfo.originalY;
								// We don't need to calculate autofit here because it's handled above
								// Grid information tracking
								let left = gridInfo.x;
								let right = gridInfo.x + gridInfo.w;

								fieldPositions.push(gridInfo);

								for (let i = left; i < right; i++) {
									columnYTracker[i] = columnYTracker[i] || [];
									columnYTracker[i].push(gridInfo);
								}
								gridInfoLookup[childRenderId] = gridInfo;
							}
						});

						columnYTracker.forEach((col) => {
							// Sort based on the y values
							// We do this before repositioning any fields because when dealing with staggered fields,
							// sometimes fields can be bumped down below other fields by changes in one column and then
							// end up above that field in the second column, causing it not to be pushed down.
							// (Issue discovered by Ben in testing on https://dev-eng-stage.citizendeveloper.com/7567d364-bca1-41b3-8c63-7efab3fb559b/Ben_Testings/03917b82-a5dd-414d-ab62-15634ec4e6c9)
							col = col.sort((a, b) => {
								return a.y - b.y;
							});
						});

						let {columnSpacers} = GridHeightUtils.reconcileGridShifts(gridLayoutHeight, columnYTracker, fieldPositions);

						state.setIn(['renderIds', renderId, 'spacerHeights', managingScreensize], Immutable.fromJS(columnSpacers));
						Object.keys(gridInfoLookup).forEach(childRenderId => {
							state.setIn(['renderIds', childRenderId, 'gridInfo', managingScreensize], Immutable.fromJS(gridInfoLookup[childRenderId]));
						});
					} else {
						if (!render) {
							// Initialize the entry for later use
							state.setIn(['renderIds', renderId], Immutable.fromJS({ renderId, gridInfo: { [managingScreensize]: { gridLayoutHeight: height } } }));
						}
					}
				});
			}

			case RenderConstants.UPDATE_FIELD_HEIGHT: {
				let renderId = action.get('renderId');
				let height = action.get('height');
				return state.withMutations(state => {
					if(state.hasIn(['renderIds', renderId])) {
						state.setIn(['renderIds', renderId, 'gridHeight'], height);
					}
				})
			}

			case RenderConstants.RESET_FIELD_HEIGHTS: {
				let renderIds = action.get('renderIds');
				return state.withMutations(state => {
					renderIds.forEach(renderId => {
						if(state.hasIn(['renderIds', renderId])) {
							state.setIn(['renderIds', renderId, 'gridHeight'], 0);
						}
					});
				})
			}

			default: {
				return state;
			}
		}
	}
}

var lastRenderSequence = 0;

/**
 * Sets the render and updates the Parent with the child information as needed
 * 
 * @param {Immutable.Map} state 
 * @param {Immutable.Map} render 
 */
function setRender(state, render) {
	let renderId = render.get('renderId');
	let renderParentId = render.get('renderParentId');
	let dataRecordId = render.get('dataRecordId') || '';
	let protectDataRecordId = render.get('protectDataRecordId');

	/** 
	 * Before we add ourselves, lets see if we're already there.  If we are, and we have a renderParent that's 
	 * different from what our new render parent WILL be, then we need to remove ourselves there before going forward and 
	 * overwritting ourselves.
	 * **/

	// check our state to see if we're already in the state...
	let resettingRender = state.hasIn(['renderIds', renderId]);
	let oldRenderObject = resettingRender ? state.getIn(['renderIds', renderId]) : null;
	if(oldRenderObject) {
		// And if we are check to see if the renderParentId Matches...
		if(oldRenderObject.has('renderParentId') && oldRenderObject.get('renderParentId') !== renderParentId) {
			// We need to remove ourselves from the old parent here (and add it later)

			// Find our old parent's ID
			let oldRenderParentId = oldRenderObject.get('renderParentId');

			// Get our old parent's Object
			let oldRenderParentObj = state.getIn(['renderIds', oldRenderParentId]);

			// Find the children of our old parent
			let oldRenderParentChildList = oldRenderParentObj && oldRenderParentObj.has('children') 
				? oldRenderParentObj.get('children') : Immutable.List();

			// Delete ourselves...
			const index = oldRenderParentChildList.indexOf(renderId);
			if (index !== -1) {
				oldRenderParentChildList = oldRenderParentChildList.delete(index);
			}

			if(oldRenderParentChildList.includes(oldRenderParentId)) {
				console.error(new Error('Parent render ' + oldRenderParentId + ' tried to become its own child!'));
				oldRenderParentChildList = oldRenderParentChildList.filter(childRenderId => childRenderId !== oldRenderParentId);
			}

			// Store the updated old parent in the state.
			state.setIn(['renderIds', oldRenderParentId, 'children'], oldRenderParentChildList);	
		}

		// Also check if we need to delete the old record ID entry
		let oldRecordId = oldRenderObject.get('dataRecordId');
		if(oldRecordId && dataRecordId !== oldRecordId) {
			state.deleteIn(['recordIds', oldRecordId, renderId]);
		}

		// If our component ID has changed, we need to clear out the children
		// (This happens when navigating to a different page within a modal)

		if(oldRenderObject.get('componentId') !== render.get('componentId')) {
			state.setIn(['renderIds', renderId, 'children'], Immutable.List());
		}
	}

	/** 
	 * Now we can go ahead and add ourselves.
	 * **/

	// Set our new render into the state
	if (resettingRender) {
		let toMerge = { 
			renderId: renderId,
			// renderSequence: ++lastRenderSequence, // This can cause issues when two stacked dialogs are opened about the same page after refreshing a content tab below. (Ticket 26203 - Infinitely reloading dialog?)
			dataRecordId: dataRecordId,
			dataTableSchemaName: render.get('dataTableSchemaName'),
			renderParentId: renderParentId,
			componentId: render.get('componentId'),
			componentType: render.get('componentType')
		};

		// Don't overwrite the old options
		if(render.has('options')) {
			toMerge.options = render.get('options');
		}

		// Don't override the old attachment ID if it hasn't been included (ticket 6901)
		if(render.has('attachmentId')) {
			toMerge.attachmentId = render.get('attachmentId');
		}

		// Likewise, don't override the old gridInfo and gridHeight if they haven't been included
		if(render.has('gridInfo')) {
			toMerge.gridInfo = render.get('gridInfo');
		}

		if(render.has('lastRefresh')) {
			toMerge.lastRefresh = render.get('lastRefresh');
		}

		// Don't override the old attachment key if it hasn't been included
		if(render.has('attachmentKey')) {
			toMerge.attachmentKey = render.get('attachmentKey');
		}

		// Don't override the old record sets key if it hasn't been included
		if(render.has('recordSets')) {
			toMerge.recordSets = render.get('recordSets');
		}

		if(render.has('repeatingGrid')) {
			toMerge.repeatingGrid = render.get('repeatingGrid');
		}

		if(typeof protectDataRecordId !== 'undefined') {
			toMerge.protectDataRecordId = protectDataRecordId;
		}

		state.mergeIn(['renderIds', renderId], Immutable.Map(toMerge));

		// Add the new record ID entry if necessary
		if(!state.hasIn(['recordIds', dataRecordId, renderId])) {
			state.setIn(['recordIds', dataRecordId, renderId], true);
		}
	} else {
		let toSet = { 
			renderId: renderId,
			renderSequence: ++lastRenderSequence,
			dataRecordId: dataRecordId,
			dataTableSchemaName: render.get('dataTableSchemaName'),
			renderParentId: renderParentId,
			componentId: render.get('componentId'),
			componentType: render.get('componentType'),
			options: render.get('options'),
			attachmentId: render.get('attachmentId'),
			attachmentKey: render.get('attachmentKey'),
			gridInfo: render.get('gridInfo'),
			lastRefresh: render.get('lastRefresh') || +new Date()
		};
		if(render.has('repeatingGrid')) {
			toSet.repeatingGrid = render.get('repeatingGrid');
		}
		if(typeof protectDataRecordId !== 'undefined') {
			toSet.protectDataRecordId = protectDataRecordId;
		}
		// Add the full render information
		state.setIn(['renderIds', renderId], Immutable.Map(toSet));
		// Now also track the lookup
		state.setIn(['recordIds', dataRecordId, renderId], true);
	}

	/** 
	 * And then, last, go find our parent and add ourselves to the children array!
	 * **/

	// If there is a renderParentId...
	if(renderParentId) {
		// Get our parent's Object
		let renderParentObject = state.hasIn(['renderIds', renderParentId]) ? 
			state.getIn(['renderIds', renderParentId]) : Immutable.Map();

		if(renderParentObject) {
			// Find the children of our parent
			let renderParentsChildList = renderParentObject.has('children') 
				? renderParentObject.get('children') : Immutable.List();

			if(renderId === renderParentId) {
				console.error(new Error('Parent render ' + renderParentId + ' tried to become its own child!'));
				renderParentsChildList = renderParentsChildList.filter(childRenderId => childRenderId !== renderParentId);
			}
			
			// Is our RenderId already in it?
			if(renderId !== renderParentId && !renderParentsChildList.includes(renderId)) {
					
				// Add ourselves...
				renderParentsChildList = renderParentsChildList.push(renderId);

				if(renderParentsChildList.includes(renderParentId)) {
					console.error(new Error('Parent render ' + renderParentId + ' tried to become its own child!'));
					renderParentsChildList = renderParentsChildList.filter(childRenderId => childRenderId !== renderParentId);
				}

				// Store the updated parent in the state.
				state.setIn(['renderIds', renderParentId, 'children'], renderParentsChildList);	
			}
		} else {
			// Store the updated parent in the state
			state.mergeIn(['renderIds', renderParentId], Immutable.Map({ 
				children: Immutable.List([renderId])
			}));
		}
	}
}

function reconcileFieldPositionChildrenHelper(state, children, fieldPosition, fieldPositionExtras, resizerOverlayEnabled, gridLayoutHeights) {
	let GridHeightUtils = require('../utils/grid-height-utils').default;
	let columnYTracker = {};
	let fieldPositionsBySize = {};
	
	children.forEach(childRenderId => {
		let child = state.getIn(['renderIds', childRenderId]);
		if (child) {
			// Now find the matching sizing information
			let gridInfo = {};
			let gridKey = child.get('attachmentId') || child.get('componentId');
			Object.keys(fieldPosition).forEach(screensize => {
				columnYTracker[screensize] = columnYTracker[screensize] || new Array(12);
				fieldPositionsBySize[screensize] = fieldPositionsBySize[screensize] || [];

				let positionsForSize = fieldPosition[screensize];
				let fieldPositionExtrasObj = {};
				if (fieldPositionExtras && fieldPositionExtras[screensize]) {
					Object.keys(fieldPositionExtras[screensize]).forEach(index => {
						fieldPositionExtrasObj[fieldPositionExtras[screensize][index].i] = fieldPositionExtras[screensize][index];
					});
				}
				let fieldPositionDict = {};
				if (Array.isArray(positionsForSize) && positionsForSize.length) {
					positionsForSize.forEach(positionObj => {
						fieldPositionDict[positionObj.i] = positionObj;
					});
				}

				let gridInfoForSize = fieldPositionDict[gridKey];
				let gridExtras = fieldPositionExtrasObj[gridKey];
				let oldChildGridInfo = child.hasIn(['gridInfo', screensize]) ? child.getIn(['gridInfo', screensize]) : undefined;

				if (gridInfoForSize) {

					// This value accidentally made it into some locations on dev-eng
					// Originally, "originalH" was named "minH," which it turns out is
					// a reserved value in our grid provider
					// So clear out any old values
					delete gridInfoForSize.minH;

					// Here's where we need to set grid autosizing/expansion info
					// but we don't calculate anything at this point

					gridInfoForSize.originalH = gridInfoForSize.h;
					gridInfoForSize.originalY = gridInfoForSize.y;
					gridInfoForSize.autofit = gridExtras && gridExtras.autofit === 'true';
					gridInfoForSize.expands = gridExtras && gridExtras.expands === 'true';

					// We do need to update the expanded values here
					if (gridInfoForSize.autofit) {
						gridInfoForSize.h = Math.max(gridInfoForSize.originalH, oldChildGridInfo && oldChildGridInfo.h ? oldChildGridInfo.h : 0);
					}

					fieldPositionsBySize[screensize].push(gridInfoForSize);

					// Grid information tracking
					let left = gridInfoForSize.x;
					let right = gridInfoForSize.x + gridInfoForSize.w;

					for (let i = left; i < right; i++) {
						columnYTracker[screensize][i] = columnYTracker[screensize][i] || [];
						columnYTracker[screensize][i].push(gridInfoForSize);
					}
				}
				gridInfo[screensize] = gridInfoForSize;
			});
			state.setIn(['renderIds', childRenderId, 'gridInfo'], Immutable.fromJS(gridInfo));
		}
	});

	if (!resizerOverlayEnabled) {
		// We don't want to waste time on
		// unnecessary calculations if the resizer is on

		Object.keys(columnYTracker).forEach(screensize => {
			columnYTracker[screensize].forEach((col, index) => {
				col = col.sort((a, b) => {
					return a.y - b.y;
				});
				columnYTracker[screensize][index] = col;
			});
		});

		Object.keys(columnYTracker).forEach(screensize => {
			// Now that this is all built, reconcile the grid shifts
			GridHeightUtils.reconcileGridShifts(gridLayoutHeights && gridLayoutHeights[screensize] ? gridLayoutHeights[screensize] : 0, columnYTracker[screensize], fieldPositionsBySize[screensize]);
		});
	}
}

/**
 * 
 * Updates the field positions in a grid when the field position
 * of one of the grid's children or when the grid's layout height
 * changes.
 * 
 * @param {*} state 
 * @param {*} fieldPosition 
 * @param {*} fieldPositionExtras 
 * @param {*} componentType 
 * @param {*} componentId 
 */
function reconcileFieldPosition(state, fieldPosition, fieldPositionExtras, componentType, componentId, resizerOverlayEnabled) {
	let matchingComponents = state.get('renderIds').filter((renderMap, key) => {
		let matchesComponentType = renderMap.get('componentType') === componentType;
		let matchesComponentId = renderMap.get('componentId') === componentId;
		return matchesComponentType && matchesComponentId;
	});
	if(matchingComponents) {
		matchingComponents.forEach(component => {
			let gridLayoutHeights = component.get('gridLayoutHeights');
			gridLayoutHeights = gridLayoutHeights ? gridLayoutHeights.toJS() : {};
			let children = component.get('children');
			if(children) {
				if(component.get('repeatingGrid')) {
					children.forEach(childRenderId => {
						// Now update the GRANDCHILDREN
						let child = state.getIn(['renderIds', childRenderId]);
						let grandchildren = child ? child.get('children') : undefined;
						if(grandchildren) {
							let childGridLayoutHeights = child.get('gridLayoutHeights');
							reconcileFieldPositionChildrenHelper(state, grandchildren, fieldPosition, fieldPositionExtras, resizerOverlayEnabled, childGridLayoutHeights);
						}
					})
				} else {
					reconcileFieldPositionChildrenHelper(state, children, fieldPosition, fieldPositionExtras, resizerOverlayEnabled, gridLayoutHeights);
				}
			}
		});
	}
}

/**
 * Similar to updateChildFields, but it just accepts the entire grid for ease.
 * @param {Immutable} state The state, inside the with modification method
 * @param {Immutable} grid The grid being set into the state.
 * @param {number} lastChildCalculation The timestamp of when this was calculated
 */
function updateGridWithChildren(state, grid, lastChildCalculation) {
	let parentRenderId = grid.get('parentRenderId');
	let attachedFields = grid.get('attachedFields');
	let dataRecordId = grid.get('dataRecordId');
	let dataTableSchemaName = grid.get('dataTableSchemaName');
	let componentId = grid.get('componentId');
	let componentType = grid.get('componentType');
	let grandparent = grid.get('grandparent');
	let hasExpanding = grid.get('hasExpanding');
	let spacerHeights = grid.get('spacerHeights');
	let gridLayoutHeights = grid.get('gridLayoutHeights');
	let renderObj = state.getIn(['renderIds', parentRenderId]);
	if(renderObj && !gridLayoutHeights) {
		gridLayoutHeights = renderObj.gridLayoutHeights;
	}

	let prevLastChildCalculation = state.getIn(['renderIds', parentRenderId, 'lastRefresh']);

	let children = [];
	// We want greater than or equal to here so that children get updated appropriately,
	// as this method is used in places without timing issues
	if(!prevLastChildCalculation || (lastChildCalculation >= prevLastChildCalculation)) {

		// Update the render corresponding to the grid itself.

		let toSet = {
			renderId: parentRenderId,
			renderParentId: grandparent,
			dataRecordId,
			dataTableSchemaName,
			lastRefresh: lastChildCalculation,
			componentId: componentId,
			componentType: componentType
		};
		if(grid.has('repeatingGrid')) {
			toSet.repeatingGrid = grid.get('repeatingGrid');
		}
		setRender(state, Immutable.fromJS(toSet));

		// Now update the renders for any children.
		if(attachedFields) {
			// Find the parent grid's render obj to get information about it
			// let parentRenderObj = state.getIn(['renderIds', parentRenderId]);
			// Find all existing children
			// let childRenders = parentRenderObj ? parentRenderObj.get('children') : undefined;
			// We no longer expect repeating grid attachedFields to come in in the "wrong" format which requires this
			/*
			if(false && parentRenderObj && parentRenderObj.get('repeatingGrid')) {
				// @TODO: This is not appropriately handling the attachedFields information coming in from INIT_PAGE
				// It almost works if commented out but then the grandchildren don't show
				// so this seems to need a general rework
				let children = [];
				attachedFields.forEach(attachedField => {
					if(attachedField.get('renderId') === parentRenderId) {
						console.error(new Error('Trying to add render ' + parentRenderId + ' to its own children.'));
					}
					// Avoid duplicates
					if(!children.includes(attachedField.get('renderId')) && attachedField.get('renderId') !== parentRenderId) {
						children.push(attachedField.get('renderId'));
					}
					let fieldId = attachedField.get('fieldId');
					let attachmentId = attachedField.get('attachmentId');
					let attachmentKey = attachedField.get('attachmentKey');
					let gridInfo = attachedField.get('gridInfo');
					// Update the CHILDREN of each child
					if(childRenders) {
						// This is a repeating grid, so each child has its own dataRecordId
						let dataRecordId = attachedField.get('dataRecordId');
						let dataTableSchemaName = attachedField.get('dataTableSchemaName');
						childRenders.forEach(childRenderId => {
							let childRenderObj = state.getIn(['renderIds', childRenderId]);
							let grandchildren = childRenderObj ? childRenderObj.get('children') : undefined;
							let renderIdForGrandchild = undefined;
							if(grandchildren) {
								grandchildren.forEach(grandchildRenderId => {
									let grandchildRenderObj = state.getIn(['renderIds', grandchildRenderId]);
									if(grandchildRenderObj && (!attachmentKey || grandchildRenderObj.get('attachmentKey') === attachmentKey) && (grandchildRenderObj.get('componentId') === componentId || grandchildRenderObj.get('attachmentId') === componentId)) {
										renderIdForGrandchild = grandchildRenderId;
									}
								});
							};
							// Try to find any existing render IDs first, then fall back if missing. (Avoids timing issue leading to "ghost" render IDs in containers with query overrides. Part of 6966)
							renderIdForGrandchild = renderIdForGrandchild || attachedField.get('renderId');
							// children.push(renderId);
							let childToSet = {
								renderId: renderIdForGrandchild,
								renderParentId: childRenderId,
								dataRecordId,
								dataTableSchemaName,
								componentId: fieldId,
								componentType: 'field',
								attachmentId: attachmentId,
								attachmentKey: attachmentKey
							};
							let existingChild = state.getIn(['renderIds', renderIdForGrandchild]);
							if(gridInfo) {
								childToSet.gridInfo = gridInfo;
							}
							if(existingChild && existingChild.lastRefresh) {
								childToSet.lastRefresh = existingChild.get('lastRefresh');
							} else {
								childToSet.lastRefresh = 1;
							}
							setRender(state, Immutable.fromJS(childToSet));
						});
					} else {
						let dataRecordId = attachedField.get('dataRecordId');
						let dataTableSchemaName = attachedField.get('dataTableSchemaName');
						let childRenderId = attachedField.get('renderId');
						let childToSet = {
							renderId: childRenderId,
							renderParentId: parentRenderId,
							dataRecordId,
							dataTableSchemaName,
							componentId: fieldId,
							componentType: 'field',
							attachmentId: attachmentId,
							attachmentKey: attachmentKey
						};

						let existingChild = state.getIn(['renderIds', childRenderId]);

						if(gridInfo) {
							childToSet.gridInfo = gridInfo;
						}

						if(existingChild && existingChild.lastRefresh) {
							childToSet.lastRefresh = Math.max(lastChildCalculation, existingChild.lastRefresh);
						} else {
							childToSet.lastRefresh = 1;
						}

						setRender(state, Immutable.fromJS(childToSet));

					}
				});
				if(children.includes(parentRenderId)) {
					console.error(new Error('Parent render ' + parentRenderId + ' tried to become its own child!'));
					children = children.filter(childRenderId => childRenderId !== parentRenderId);
				}
				state.setIn(['renderIds', parentRenderId, 'children'], Immutable.fromJS(children));
				*/
			// } else {
				attachedFields.forEach(attachedField => {
					let fieldId = attachedField.get('fieldId');
					let attachmentId = attachedField.get('attachmentId');
					let attachmentKey = attachedField.get('attachmentKey');
					// let order = attachedField.get('order');
					let gridInfo = attachedField.get('gridInfo');

					let renderId = attachedField.get('renderId');

					if(renderId === parentRenderId) {
						console.error(new Error('Trying to add render ' + parentRenderId + ' to its own children.'));
					}
					
					if(!children.includes(renderId) && renderId !== parentRenderId) {
						children.push(renderId);
					}

					let existingChild = state.getIn(['renderIds', renderId]);
					if(existingChild && existingChild.has('gridInfo')) {
						let oldGridInfo = existingChild.get('gridInfo');
						// Compare using lastChildCalculation and lastDt
						if(oldGridInfo && gridInfo) {
							gridInfo = gridInfo.withMutations(gridInfo => {
								oldGridInfo.forEach((oldGridInfoForSize, size) => {
									if(!oldGridInfoForSize) {
										console.warn('oldGridInfoForSize missing. gridInfo was', gridInfo.toJS());
									}
									let oldLastDt = oldGridInfoForSize ? oldGridInfoForSize.get('lastDt') : undefined;
									if(oldLastDt && oldLastDt > lastChildCalculation) {
										// Use the old height for the new one
										let height = oldGridInfoForSize.get('h');
										gridInfo.setIn([size, 'h'], height);
										gridInfo.setIn([size, 'lastDt'], oldLastDt);
									}
								});
							});
						}
					}

					let childToSet = {
						renderId,
						renderParentId: parentRenderId,
						dataRecordId,
						dataTableSchemaName,
						componentId: fieldId,
						componentType: 'field',
						attachmentId: attachmentId,
						attachmentKey: attachmentKey
					};

					if(gridInfo) {
						childToSet.gridInfo = gridInfo;
					}

					if(existingChild && existingChild.lastRefresh) {
						childToSet.lastRefresh = Math.max(lastChildCalculation, existingChild.lastRefresh);
					} else {
						childToSet.lastRefresh = lastChildCalculation - 1; // @TODO: This is a workaround and I don't like it
						// childToSet.lastRefresh = 1;
					}

					setRender(state, Immutable.fromJS(childToSet));
				});

				// Update the children (necessary if children were removed in this recalculation)
				// But only if they were recalculated in the first place
				// (Ticket 22533)
				if(attachedFields) {
					if(children.includes(parentRenderId)) {
						console.error(new Error('Parent render ' + parentRenderId + ' tried to become its own child!'));
						children = children.filter(childRenderId => childRenderId !== parentRenderId);
					}
					state.setIn(['renderIds', parentRenderId, 'children'], Immutable.fromJS(children));
				}
			// }
		}

		if(gridLayoutHeights && state.hasIn(['renderIds', parentRenderId])) {
			// We also need gridLayoutHeight here because setRender may not take care of it
			state.setIn(['renderIds', parentRenderId, 'gridLayoutHeights'], Immutable.fromJS(gridLayoutHeights));
		}
		if(hasExpanding && state.hasIn(['renderIds', parentRenderId])) {
			state.setIn(['renderIds', parentRenderId, 'hasExpanding'], hasExpanding);
		}

		if(spacerHeights && state.hasIn(['renderIds', parentRenderId])) {
			state.setIn(['renderIds', parentRenderId, 'spacerHeights'], spacerHeights);
		}

		if(grid.has('gridInfo')) {
			state.setIn(['renderIds', parentRenderId, 'gridInfo'], grid.get('gridInfo'));
		}
		if(grid.has('attachmentKey')) {
			state.setIn(['renderIds', parentRenderId, 'attachmentKey'], grid.get('attachmentKey'));
		}

		let recordSets = grid.get('recordSets');
		if(recordSets) {
			recordSets.forEach(setValue => {
				let setName = setValue.get('setName');
				state.setIn(['renderIds', parentRenderId, 'recordSets'], this._addRecordSetToArray(parentRenderId, setName));
			});
		}
		if(grid.has('gridInfo')) {
			state.setIn(['renderIds', parentRenderId, 'gridInfo'], grid.get('gridInfo'));
		}
		if(grid.has('attachmentKey')) {
			state.setIn(['renderIds', parentRenderId, 'attachmentKey'], grid.get('attachmentKey'));
		}
		if(grid.has('dialogOptions')) {
			state.setIn(['renderIds', parentRenderId, 'options'], grid.get('dialogOptions'));
		}
	}

}

/**
 * Updates the child fields when the parent changes
 */
function updateChildFields(state, parentRenderId, grandparent, componentId, componentType, dataRecordId, dataTableSchemaName, attachedFields, lastChildCalculation, gridLayoutHeights, hasExpanding, spacerHeights, respectExistingGridInfo) {

	let prevLastChildCalculation = state.getIn(['renderIds', parentRenderId, 'lastRefresh']);

	let children = [];

	if(!prevLastChildCalculation || (lastChildCalculation > prevLastChildCalculation)) {
		let toSet = {
			renderId: parentRenderId,
			renderParentId: grandparent,
			dataRecordId,
			dataTableSchemaName,
			lastRefresh: lastChildCalculation,
			componentId: componentId,
			componentType: componentType
		};
		setRender(state, Immutable.fromJS(toSet));

		// If this is a repeating grid we need to handle it a bit differently

		// if(!isNaN(gridLayoutHeights) && (gridLayoutHeights || gridLayoutHeights === 0)) {
		// 	// If gridLayoutHeights is a number, treat it as a default value
		// 	// and update the gridLayoutHeights value with it
		// }

		if(attachedFields) {
			let parentRenderObj = state.getIn(['renderIds', parentRenderId]);
			let childRenders = parentRenderObj ? parentRenderObj.get('children') : undefined;
			if(parentRenderObj && parentRenderObj.get('repeatingGrid')) {
				let children = [];
				attachedFields.forEach(attachedField => {
					if(attachedField.get('renderId') === parentRenderId) {
						console.error(new Error('Trying to add render ' + parentRenderId + ' to its own children.'));
					}
					// Avoid duplicates
					if(!children.includes(attachedField.get('renderId')) && attachedField.get('renderId') !== parentRenderId) {
						children.push(attachedField.get('renderId'));
					}
					let fieldId = attachedField.get('fieldId');
					let attachmentId = attachedField.get('attachmentId');
					let attachmentKey = attachedField.get('attachmentKey');
					let gridInfo = attachedField.get('gridInfo');
					// Update the CHILDREN of each child
					if(childRenders) {
						// This is a repeating grid, so each child has its own dataRecordId
						let dataRecordId = attachedField.get('dataRecordId');
						let dataTableSchemaName = attachedField.get('dataTableSchemaName');
						childRenders.forEach(childRenderId => {
							let childRenderObj = state.getIn(['renderIds', childRenderId]);
							let grandchildren = childRenderObj ? childRenderObj.get('children') : undefined;
							let renderIdForGrandchild = undefined;
							if(grandchildren) {
								grandchildren.forEach(grandchildRenderId => {
									let grandchildRenderObj = state.getIn(['renderIds', grandchildRenderId]);
									if(grandchildRenderObj && (!attachmentKey || grandchildRenderObj.get('attachmentKey') === attachmentKey) && (grandchildRenderObj.get('componentId') === componentId || grandchildRenderObj.get('attachmentId') === componentId)) {
										renderIdForGrandchild = grandchildRenderId;
									}
								});
							};
							// Try to find any existing render IDs first, then fall back if missing. (Avoids timing issue leading to "ghost" render IDs in containers with query overrides. Part of 6966)
							renderIdForGrandchild = renderIdForGrandchild || attachedField.get('renderId');
							// children.push(renderId);
							let childToSet = {
								renderId: renderIdForGrandchild,
								renderParentId: childRenderId,
								dataRecordId,
								dataTableSchemaName,
								componentId: fieldId,
								componentType: 'field',
								attachmentId: attachmentId,
								attachmentKey: attachmentKey
							};
							let existingChild = state.getIn(['renderIds', renderIdForGrandchild]);
							if(gridInfo) {
								// Respect existing gridInfo where possible
								if(respectExistingGridInfo && existingChild && existingChild.has('gridInfo')) {
									gridInfo = gridInfo.withMutations(gridInfo => {
										gridInfo.forEach((infoForSize, screensize) => {
											if(existingChild.hasIn(['gridInfo', screensize])) {
												gridInfo.set(screensize, existingChild.getIn(['gridInfo', screensize]));
											}
										});
									});
								}
								childToSet.gridInfo = gridInfo;
							}
							if(existingChild && existingChild.get('lastRefresh')) {
								childToSet.lastRefresh = existingChild.get('lastRefresh');
							} else {
								childToSet.lastRefresh = lastChildCalculation - 1; // @TODO: this is a workaround to prevent automatically generated lastDts from causing issues, but I want a better solution.
								// childToSet.lastRefresh = 1;
							}
							setRender(state, Immutable.fromJS(childToSet));
						});
					} else {
						let dataRecordId = attachedField.get('dataRecordId');
						let dataTableSchemaName = attachedField.get('dataTableSchemaName');
						let childRenderId = attachedField.get('renderId');
						let childToSet = {
							renderId: childRenderId,
							renderParentId: parentRenderId,
							dataRecordId,
							dataTableSchemaName,
							componentId: fieldId,
							componentType: 'field',
							attachmentId: attachmentId,
							attachmentKey: attachmentKey,
							lastRefresh: lastChildCalculation - 1 // @TODO: Same workaround as elsewhere and I still don't like it
							// lastRefresh: 1
						};

						let existingChild = state.getIn(['renderIds', childRenderId]);

						if(gridInfo) {
							// Respect existing gridInfo where possible
							if(respectExistingGridInfo && existingChild && existingChild.has('gridInfo')) {
								gridInfo = gridInfo.withMutations(gridInfo => {
									gridInfo.forEach((infoForSize, screensize) => {
										if(existingChild.hasIn(['gridInfo', screensize])) {
											gridInfo.set(screensize, existingChild.getIn(['gridInfo', screensize]));
										}
									});
								});
							}
							childToSet.gridInfo = gridInfo;
						}

						setRender(state, Immutable.fromJS(childToSet));

					}
				});
				if(children.includes(parentRenderId)) {
					console.error(new Error('Parent render ' + parentRenderId + ' tried to become its own child!'));
					children = children.filter(childRenderId => childRenderId !== parentRenderId);
				}
				state.setIn(['renderIds', parentRenderId, 'children'], Immutable.fromJS(children));
			} else {
				attachedFields.forEach(attachedField => {
					let fieldId = attachedField.get('fieldId');
					let attachmentId = attachedField.get('attachmentId');
					let attachmentKey = attachedField.get('attachmentKey');
					// let order = attachedField.get('order');
					let gridInfo = attachedField.get('gridInfo');

					let renderId = undefined;
					let oldChildRenderId = childRenders && childRenders.find(childRenderId => {
						let childRenderObj = state.getIn(['renderIds', childRenderId]);
						let renderAttachmentId = childRenderObj ? childRenderObj.get('attachmentId') : undefined;

						// Actually, since the componentId can CHANGE, don't check that componentId is the same
						if(childRenderObj && renderAttachmentId && (renderAttachmentId === fieldId || renderAttachmentId === attachmentId)) {
							return true;
						} else if (childRenderObj && !renderAttachmentId && childRenderObj.get('componentId') === fieldId) {
							return true;
						}
						return false;
					});
					
					// Try to find any existing render IDs first, then fall back if missing. (Avoids timing issue leading to "ghost" render IDs in containers with query overrides. Part of 6966)
					renderId = oldChildRenderId || attachedField.get('renderId');
					
					if(attachedField.get('renderId') === parentRenderId) {
						console.error(new Error('Trying to add render ' + parentRenderId + ' to its own children.'));
					}
					if(!children.includes(renderId) && attachedField.get('renderId') !== parentRenderId) {
						children.push(renderId);
					}
					let oldChild = state.getIn(['renderIds', renderId]);
					if(oldChild && oldChild.has('gridInfo')) {
						let oldGridInfo = oldChild.get('gridInfo');
						// Compare using lastChildCalculation and lastDt
						if(oldGridInfo && gridInfo) {
							gridInfo = gridInfo.withMutations(gridInfo => {
								oldGridInfo.forEach((oldGridInfoForSize, size) => {
									let oldLastDt = oldGridInfoForSize.get('lastDt');
									if(oldLastDt && oldLastDt > lastChildCalculation) {
										// Use the old height for the new one
										let height = oldGridInfoForSize.get('h');
										gridInfo.setIn([size, 'h'], height);
										gridInfo.setIn([size, 'lastDt'], oldLastDt);
									}
								});
							});
						}
					}
					let childToSet = {
						renderId,
						renderParentId: parentRenderId,
						dataRecordId,
						dataTableSchemaName,
						componentId: fieldId,
						componentType: 'field',
						attachmentId: attachmentId,
						attachmentKey: attachmentKey,
					};
					let existingChild = state.getIn(['renderIds', renderId]);
					if(gridInfo) {
						// Respect existing gridInfo where possible
						if(respectExistingGridInfo && existingChild && existingChild.has('gridInfo')) {
							gridInfo = gridInfo.withMutations(gridInfo => {
								gridInfo.forEach((infoForSize, screensize) => {
									if(existingChild.hasIn(['gridInfo', screensize])) {
										gridInfo.set(screensize, existingChild.getIn(['gridInfo', screensize]));
									}
								});
							});
						}
						childToSet.gridInfo = gridInfo;
					}
					if(existingChild && existingChild.lastRefresh) {
						childToSet.lastRefresh = existingChild.lastRefresh;
					} else {
						childToSet.lastRefresh = lastChildCalculation - 1; // @TODO: Same workaround as above and I still don't like it
						// childToSet.lastRefresh = 1;
					}
					setRender(state, Immutable.fromJS(childToSet));
				});

				// Update the children (necessary if children were removed in this recalculation)
				// But only if they were recalculated in the first place
				// (Ticket 22533)
				if(attachedFields) {
					if(children.includes(parentRenderId)) {
						console.error(new Error('Parent render ' + parentRenderId + ' tried to become its own child!'));
						children = children.filter(childRenderId => childRenderId !== parentRenderId);
					}
					state.setIn(['renderIds', parentRenderId, 'children'], Immutable.fromJS(children));
				}
			}
		}
		if(gridLayoutHeights && state.hasIn(['renderIds', parentRenderId])) {
			// We also need gridLayoutHeight here because setRender may not take care of it
			state.setIn(['renderIds', parentRenderId, 'gridLayoutHeights'], Immutable.fromJS(gridLayoutHeights));
		}
		if(hasExpanding && state.hasIn(['renderIds', parentRenderId])) {
			state.setIn(['renderIds', parentRenderId, 'hasExpanding'], hasExpanding);
		}

		if(spacerHeights && state.hasIn(['renderIds', parentRenderId])) {
			state.setIn(['renderIds', parentRenderId, 'spacerHeights'], spacerHeights);
		}
	}
}

/**
* Start with a render ID, and get all children of that render id, recursively
* 
* @param {String} renderParentId
* @param {Boolean}  excludePageChildren Exclude any page children and their children recursively
* @return {Immutable.List} An Immutable List of all render ID's under this renderId
* @memberof RenderStore
*/
function _findAllChildren(state, renderParentId, excludePageChildren) {
   let render = state.getIn(['renderIds', renderParentId]);
   let returnList = Immutable.List();

   if (render && render.has('children')) {
	   returnList = render.get('children');
	   render.get('children').forEach(childRenderId => {
		   // If we're excluding page children, do NOT recurse over any page children
		   // This should allow the pages themselves to be found in findAllChildren
		   // but not their children. (Used for the 8292 fix.)
		   if(excludePageChildren) {
			   let childRenderObj = state.getIn(['renderIds', childRenderId]);
			   if(childRenderObj && childRenderObj.get('componentType') === 'page') {
				   return;
			   }
		   }
		   returnList = returnList.concat(_findAllChildren(state, childRenderId, excludePageChildren));
	   });
   }

   return returnList;
}

/**
 * Delete all descendants of a render entry
 *
 * @param {*} state
 * @param {*} renderId
 */
function deleteChildren(state, parentRenderId) {
	let renderEntry = state.getIn(['renderIds', parentRenderId]);
	if(renderEntry) {
		let children = renderEntry && renderEntry.get('children');
		if(children) {
			children.forEach(childRenderId => {
				if (state.hasIn(['renderIds', childRenderId])) {
					let childEntry = state.getIn(['renderIds', childRenderId]);
					// Find all of this child's children... and delete them.
					let grandchildRenderIds = _findAllChildren(state, childRenderId);
					grandchildRenderIds.forEach(grandchildRenderId => {
						let grandchildDetails = state.getIn(['renderIds', grandchildRenderId]);
						state.deleteIn(['renderIds', grandchildRenderId]);
						let dataRecordId = grandchildDetails.get('dataRecordId') || '';
						if(state.hasIn(['recordIds', dataRecordId, grandchildRenderId])) {
							state.deleteIn(['recordIds', dataRecordId, grandchildRenderId]);
						}
					});
		
					// Delete this render.
					state.deleteIn(['renderIds', childRenderId]);
					if(childEntry && childEntry.get('dataRecordId') && state.hasIn(['recordIds', childEntry.get('dataRecordId'), childRenderId])) {
						state.deleteIn(['recordIds', childEntry.get('dataRecordId'), childRenderId]);
					}
				}
			});
		}
		state.setIn(['renderIds', parentRenderId, 'children'], new Immutable.List());
	}
}

/**
 * 
 * Helper function for SET_RECORD_SET handling
 * @param {Immutable} state The state to update, as within withMutations
 * @param {string} renderId The render ID of the entry being updated
 * @param {string} dataRecordId The incoming data record ID
 * @param {string} dataTsn The incoming dataTsn
 * @param {integer} lastRefresh The last refresh timestamp
 * @param {boolean} protectDataRecordId Whether to protect the data record ID for this and any children.
 * (We do wish to update the lastDt of all children regardless.)
 */
function recursivelySetRecordSet(state, renderId, dataRecordId, dataTsn, lastRefresh, protectDataRecordId) {
	let oldRender = state.getIn(['renderIds', renderId]);
	let componentType = oldRender && oldRender.get('componentType');
	protectDataRecordId = protectDataRecordId || oldRender ? oldRender.get('protectDataRecordId') : false;

	if(oldRender && !oldRender.get('repeatingGrid') && componentType === 'field') {
		// Is this a container field?
		// Because if it is, we need to update its children with the new record information
		if(!protectDataRecordId) {
			state.setIn(['renderIds', renderId, 'dataTableSchemaName'], dataTsn);
			state.setIn(['renderIds', renderId, 'dataRecordId'], dataRecordId);
		}
		// Update the lastRefresh of this and all descendants
		state.setIn(['renderIds', renderId, 'lastRefresh'], lastRefresh);
		// Now recurse over any non-page children
		let children = oldRender.children || [];
		children.forEach(renderId => {
			recursivelySetRecordSet(state, renderId, dataRecordId, dataTsn, lastRefresh, protectDataRecordId);
		});
	}
}

const instance = new RenderStore(AppDispatcher);
export default instance;