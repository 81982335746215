import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import { AdminSettingsConstants, AuthenticationConstants } from '../constants';

// import UIUtils from '../utils/ui-utils';

/**
 * Manages state set by admin tools (such as overlays set in main footer)
 */
class AdminSettingsStore extends ReduceStore {
	/**
	 * getInitialState - initial state for ContextStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		let settingsPanelExpanded = localStorage.getItem('SettingsPanelExpanded');
		return Immutable.fromJS({
			activeOverlays: [],
			stackedModals: [],
			settingsMainTab: 'assistant',
			settingsSubTab: 'main',
			querySettingSchemaName: null,
			isSettingsPanelExpanded: (settingsPanelExpanded === 'true' ||  settingsPanelExpanded === true
				? true
				: false)
		});
	}
	/**
	 * getActiveOverlays - Retrieves the current active panels
	 *
	 * @return {array}  names of active panels
	 */
	getActiveOverlays() {
		let activeOverlays = this.getState().get('activeOverlays');
		return activeOverlays ? activeOverlays.toJS() : [];
	}
	
	/**
	 * getFieldType - retrieves selected field's fieldType
	 *
	 * @return {string}  fieldType
	 */
	getFieldType() {
		return this.getState().get('fieldType');
	}

	/**
	 * getGridKey - retrieves unique key assigned by react grid
	 *
	 * @return {number}  key
	 */
	getGridKey() {
		return this.getState().get('gridKey');
	}

	/**
	 * getIsLeftPanelOpen - retrieves left panel state
	 *
	 * @return {boolean}  isLeftPanelOpen
	 */
	getIsLeftPanelOpen() {
		let returnVal = this.getState().get('isLeftPanelOpen');
		if (returnVal === undefined) {
			returnVal = false;
		}
		return returnVal;
	}
	/**
	 * isMapActive - determines if map is active
	 *
	 * @param  {string} map name of map to test
	 * @return {boolean}
	 */
	getIsDashboardActive(map) {
		return this.getState().get('activeDashboard') === map;
	}

	/**
	 * getActiveDashboard - gets the active map
	 *
	 * @return {string}
	 */
	getActiveDashboard() {
		return this.getState().get('activeDashboard');
	}

	/**
	 * getActiveDashboardTab - gets the active map
	 *
	 * @return {string}
	 */
	getActiveDashboardTab() {
		return this.getState().get('activeDashboardTab');
	}
	
	/**
	 * isOverlayActive - determines if overlay is active
	 *
	 * @param  {string} overlay name of overlay to test
	 * @return {boolean}
	 */
	getIsOverlayActive(overlay) {
		let returnVal = false;
		let activeOverlays = this.getState().get('activeOverlays');
		if (activeOverlays !== undefined) {
			activeOverlays = activeOverlays.toJS();
			if (activeOverlays.includes(overlay)) {
				returnVal = true;
			}
		}
		return returnVal;
	}

	/**
	 * isSettingsPreviewOpen - retrieves settings preview panel state
	 *
	 * @return {boolean}  isSettingsPreviewOpen
	 */
	getIsSettingsPreviewOpen() {
		return this.getState().get('isSettingsPreviewOpen');
	}

	/**
	 * getIsRightPanelOpen - retrieves right panel state
	 *
	 * @return {boolean}  isRightPanelOpen
	 */
	getIsRightPanelOpen() {
		return this.getState().get('isRightPanelOpen');
	}

	getIsSettingsPanelExpanded() {
		return this.getState().get('isSettingsPanelExpanded');
		
	}
	
	/**
	 * getParentRecordId - retrieves selected parentRecordId
	 *
	 * @return {string} parentRecordId
	 */
	getParentRecordId() {
		return this.getState().get('parentRecordId');
	}
	/**
	 * getParentTableSchemaName - retrieves selected parentTableSchemaName
	 *
	 * @return {string}  recordId
	 */
	getParentTableSchemaName() {
		return this.getState().get('parentTableSchemaName');
	}
	/**
	 * getRecordId - retrieves selected recordId
	 *
	 * @return {string}  recordId
	 */
	getRecordId() {
		return this.getState().get('recordId');
	}
	/**
	 * getRenderId - retrieves selected renderId
	 *
	 * @return {string}  recordId
	 */
	getRenderId() {
		return this.getState().get('renderId');
	}
	/**
	 * getAttachmentId - retrieves selected attachmentId
	 *
	 * @return {string}  recordId
	 */
	getAttachmentId() {
		return this.getState().get('attachmentId');
	}

	/**
	 * getSelectedOverlay - retrieves overlay selected via pin click
	 *
	 * @return {string}  name of selected overlay
	 */
	getSelectedOverlay() {
		return this.getState().get('selectedOverlay');
	}

	/**
	 *  Get the Settings Toggle State
	 * 
	 * @returns {string}
	 * @memberof AdminSettingsStore
	 */
	getSettingsListHidden() {
		return this.getState().get('settingsListHidden');
	}

	/**
	 *  Get the Settings Main Tab
	 * 
	 * @returns {string}
	 * @memberof AdminSettingsStore
	 */
	getSettingsTabMain() {
		return this.getState().get('settingsMainTab');
	}

	/**
	 *  Get the Sub Setting Schema Name
	 * 
	 * @returns {string}
	 * @memberof AdminSettingsStore
	 */
	getSubSettingSchemaName() {
		return this.getState().get('subSettingSchemaName');
	}

	/**
	 * Get the Sub Setting Index
	 * 
	 * @returns {string}
	 * @memberof AdminSettingsStore
	 */
	getSubSettingIndex() {
		return this.getState().get('subSettingIndex');
	}
	/**
	 * Get the Mode for the visibility
	 * 
	 * @returns {string}
	 * @memberof AdminSettingsStore
	 */
	getVisibilityControlsAddAndEdit() {
		return this.getState().get('controlsAddAndEdit');
	}
	/**
	 *  Get the Settings Sub Tab
	 * 
	 * @returns {string}
	 * @memberof AdminSettingsStore
	 */
	getSettingsTabSub() {
		return this.getState().get('settingsSubTab');
	}

	/**
	 *  Get the Dashboard for the Settings Panel.
	 * When the Dashboard that we want selected changes, due to activity
	 * in the Settings Panel, or Settings Chooser Dropdown.
	 * 
	 * @returns {string}
	 * @memberof AdminSettingsStore
	 */
	getSettingsPanelDashboard() {
		return this.getState().get('settingsPanelDashboard');
	}

	/**
	 *  Get the Settings Main Tab
	 * 
	 * @returns {string}
	 * @memberof AdminSettingsStore
	 */
	getAutomationTabSub() {
		return this.getState().get('automationSubTab');
	}

	/**
	 * Which query setting is selected.
	 * 
	 * @returns {string}
	 * @memberof AppearanceAssistantStore
	 */
	getQuerySettingSchemaName() {
		return this.getState().get('querySettingSchemaName');
	}

	/**
	 * Which setting is selected in the assistant
	 * 
	 * @returns {string}
	 * @memberof AppearanceAssistantStore
	 */
	getSettingRecordId() {
		return this.getState().get('settingRecordId');
	}

	/**
	 * Which setting is selected in the assistant
	 * 
	 * @returns {string}
	 * @memberof AppearanceAssistantStore
	 */
	getSettingSchemaName() {
		return this.getState().get('settingSchemaName');
	}

	/**
	 * getSizeJS - retrieves resizer dimensions as a JS object
	 *
	 * @return {object}  sizeObj
	 */
	getSizeJS() {
		let sizeObj = this.getState().get('sizeObj');
		return sizeObj ? sizeObj.toJS() : {};
	}

	/**
	 * getSize - retrieves resizer dimensions 
	 *
	 * @return {object}  sizeObj
	 */
	getSize() {
		return this.getState().get('sizeObj');
	}

	/**
	 * getStackedModals - retrieves stack of modal
	 *
	 * @return {Array} of stacked modal which the index represents it's level in UI, 0 is the oldest and N is the most recent opened
	 */
	getStackedModals() {
		let stackedModals = this.getState().get('stackedModals');
		return stackedModals ? stackedModals.toJS() : [];
	}
	
	/**
	 * getTableSchemaName - retrieves selected tableSchemaName
	 *
	 * @return {string}  recordId
	 */
	getTableSchemaName() {
		return this.getState().get('tableSchemaName');
	}
	/**
	 * getCurrentLogicKind - retrieves current Kind (Scheduled Logic or API Config)
	 *
	 * @return {string}  recordId
	 */
	getLogicModalIsDirty() {
		return this.getState().get('logicModalIsDirty');
	}


	/**
	 * Responding to various Store State Updates - the Settings Panel Dashboard 
	 * should change.
	 * @param {object} context 
	 * @param {string} selectedOverlay 
	 * @returns 
	 */
	_adjustSettingsPanelDashboard(context, selectedOverlay) {
		switch(selectedOverlay) {
			case 'appearance':
			case 'page-add':
			case 'theme':
			case 'visibility':
				context = context.set('settingsPanelDashboard', 'presentation');
				break;
			case 'field-add':
			case 'query':
			case 'relationship':
			case 'security':
			case 'table':
				context = context.set('settingsPanelDashboard', 'data');
			break;
			case 'apiconfig':
			case 'automation':
			case 'function':
			case 'scheduledLogic':
			case 'webhooks':
				context = context.set('settingsPanelDashboard', 'logic');
			break;
			case 'compliance':
			case 'discussion':
				context = context.set('settingsPanelDashboard', 'project');
			break;
			default:
			break;
		}
		return context;
	}

	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 */
	reduce(state, action) {
		let context;
		switch (action.get('type')) {
			case AdminSettingsConstants.SETTINGS_MAINTAB_CHANGE:
				context = state.set('settingsMainTab', action.get('settingsMainTab'));
			break;
			case AdminSettingsConstants.SETTING_CHANGE:
				context = state.set('settingSchemaName', action.get('settingSchemaName'));
				context = context.set('settingRecordId', action.get('settingRecordId'));
			break;
			case AdminSettingsConstants.SETTINGS_LIST_HIDE:
				context = state.set('settingsListHidden', action.get('settingsHide'));
			break;
			case AdminSettingsConstants.SETTINGS_PANEL_DASHBOARD_CHANGE:
				context = state.set('settingsPanelDashboard', action.get('settingsPanelDashboard'));
			break;
			case AdminSettingsConstants.SETTINGS_PANEL_EXPAND:
				context = state.set('isSettingsPanelExpanded', action.get('isSettingsPanelExpanded'));
				localStorage.setItem('SettingsPanelExpanded', action.get('isSettingsPanelExpanded'));
			break;
			case AdminSettingsConstants.SUB_SETTING_CHANGE:
				context = state.set('subSettingSchemaName', action.get('subSettingSchemaName'));
				context = context.set('subSettingIndex', action.get('subSettingIndex'));
			break;
			case AdminSettingsConstants.VISIBILITY_CONTROLS_ADD_AND_EDIT:
				context = state.set('controlsAddAndEdit', action.get('controlsAddAndEdit'));
			break;
			case AdminSettingsConstants.SETTINGS_SUBTAB_CHANGE:
				context = state.set('settingsSubTab', action.get('settingsSubTab'));
			break;
			case AdminSettingsConstants.AUTOMATION_SUBTAB_CHANGE:
				context = state.set('automationSubTab', action.get('automationSubTab'));
			break;
			case AdminSettingsConstants.QUERY_SETTING_CHANGE:
				context = state.set('querySettingSchemaName', action.get('settingSchemaName'));
			break;
			case AdminSettingsConstants.OVERLAY_CHANGE_WITH_RECALC:
			case AdminSettingsConstants.OVERLAY_CHANGE:
				let activeOverlays = this.getActiveOverlays();
				let activatedOverlay = action.get('activatedOverlay');

				if (Object.keys(activeOverlays).length > 0) {

					if (activatedOverlay === 'resizer') {
						// check for resizer, which would turn off all overlays
						if (activeOverlays.includes('resizer')) {
							// user has clicked resizer as it's active, so empty activeOverlays
							activeOverlays = [];
						} else {
							// clear all overlays and only include resizer
							activeOverlays = [activatedOverlay];
						}
						context = state.delete('activeOverlays');
						context = context.mergeDeep({ 'activeOverlays': activeOverlays });

					} else {
						if (activeOverlays.includes(activatedOverlay)) {
							// remove overlay if already included and mergeDeep
							let index = activeOverlays.indexOf(activatedOverlay);
							activeOverlays.splice(index, 1);
							context = state.delete('activeOverlays');
							context = context.mergeDeep({ 'activeOverlays': activeOverlays });
						} else {
							// activatedOverlay was not part of list, so add and mergeDeep
							activeOverlays.push(activatedOverlay);
							context = state.mergeDeep({ 'activeOverlays': activeOverlays });
						}
					}

				} else {
					// no activeOverlays, so create new list
					activeOverlays = [activatedOverlay];
					context = state.mergeDeep({ 'activeOverlays': activeOverlays });
				}
				break;
			case AdminSettingsConstants.SHOW_SETTINGS:
				let recordId = action.get('recordId');
				let renderId = action.get('renderId');
				let attachmentId = action.get('attachmentId');
				let tableSchemaName = action.get('tableSchemaName');
				let parentRecordId = action.get('parentRecordId');
				let parentTableSchemaName = action.get('parentTableSchemaName');
				let gridKey = action.get('gridKey');
				let fieldType = action.get('fieldType');
				let selectedOverlay = action.get('selectedOverlay');
				context = state.mergeDeep({
					'recordId': recordId,
					'renderId': renderId,
					'attachmentId': attachmentId,
					'tableSchemaName': tableSchemaName,
					'parentRecordId': parentRecordId,
					'parentTableSchemaName': parentTableSchemaName,
					'gridKey': gridKey,
					'fieldType': fieldType,
					'selectedOverlay': selectedOverlay
				});

				context = this._adjustSettingsPanelDashboard(context, selectedOverlay);
				break;
			case AdminSettingsConstants.CHANGE_SELECTED_SETTINGS_PANEL:
				context = state;
				let selectedSettingsPanel = action.get('selectedSettingsPanel');
				context = context.mergeDeep({
					'selectedOverlay': selectedSettingsPanel
				});
				context = this._adjustSettingsPanelDashboard(context, selectedSettingsPanel)
			break;
			case AdminSettingsConstants.CHANGE_COMPONENT:
				context = state.set('recordId', action.get('recordId'));
				break;
			case AdminSettingsConstants.LEFT_PANEL_CHANGE:
				context = state.mergeDeep({
					'isLeftPanelOpen': action.get('isLeftPanelOpen'),
					'activeDashboard': action.get('activeDashboard')
				});
				break;
			case AdminSettingsConstants.LEFT_PANEL_TAB_CHANGE:
				context = state.mergeDeep({
					'activeDashboardTab': action.get('activeDashboardTab')
				});
				break;
			case AdminSettingsConstants.RIGHT_PANEL_CHANGE:
				let isRightPanelOpen = action.get('isRightPanelOpen');
				context = state.mergeDeep({
					'isRightPanelOpen': isRightPanelOpen
				});
				break;
			case AdminSettingsConstants.SETTINGS_PREVIEW_CHANGE:
				let isSettingsPreviewOpen = action.get('isSettingsPreviewOpen');
				context = state.mergeDeep({
					'isSettingsPreviewOpen': isSettingsPreviewOpen
				});
				break;
			case AdminSettingsConstants.CENTER_COLUMN_RESIZE:
				let sizeObj = action.get('sizeObj');
				context = state.mergeDeep({
					'sizeObj': sizeObj
				});
				break;
			case AdminSettingsConstants.OPEN_MODAL: {
				let stackedModals = state.get('stackedModals'),
					modalId = action.get('modalId'),
					component = action.get('component');

				stackedModals = stackedModals.push({ modalId: modalId, component: component.toJS() });
				context = state.set('stackedModals', stackedModals);
				break;
			}
			case AdminSettingsConstants.CLOSE_MODAL: {
				let stackedModals = state.get('stackedModals'),
					modalId = action.get('modalId');

				if (!modalId) {
					stackedModals = stackedModals.pop();
				} else {
					let index = stackedModals.findIndex((m) => m.modalId === modalId);
					if (index >= 0) {
						stackedModals = stackedModals.splice(index, 1);
					}
				}
				context = state.set('stackedModals', stackedModals);
				break;
			}
			case AuthenticationConstants.AUTH_LOGIN: {
				// Get window or document dimensions
				let size = {
					width: window.innerWidth || document.body.clientWidth,
					height: window.innerHeight || document.body.clientHeight
				};
				
				// If you change these values (which are used on page load..) you also need to change them in resizer-controls.react.js
				context = state.set('sizeObj', Immutable.Map({
					width: Math.round(size.width - 400), // at least needs to be width - 100 to show the tools on the left without page content going under them.
					height: Math.round(size.height - 75)
				}));
			
				break;
			}
			case AuthenticationConstants.AUTH_LOGOUT: {
				// Get window or document dimensions
				let size = {
					width: window.innerWidth || document.body.clientWidth,
					height: window.innerHeight || document.body.clientHeight
				};

				// If you change these values (which are used on page load..) you also need to change them in resizer-controls.react.js
				context = state.set('sizeObj', Immutable.Map({
					width: Math.round(size.width),
					height: Math.round(size.height)
				}));
				
				break;
			}
			default:
				context = state;
				break;
		}
		return context;
	}
}

const instance = new AdminSettingsStore(AppDispatcher);
export default instance;