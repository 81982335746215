import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import { PageConstants } from '../constants/page-constants';
import MetadataBuilder from '@dmclain-citizendeveloper/citdev-module-metadata-builder';
import fetchMetadata from '../utils/fetch-utils';
import ObjectUtils from '../utils/object-utils';

let _instance = null;

/**
 * Actions for the core store that contains page records for the dev environment where we talk to the MDGW
 *
 * @class PageActionsDev
 */
class PageActionsDev {
	/**
	 * Singleton instance of PageActionsDev
	 * @param {Object} options
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if (options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Set the Installation Id and (optionally the endPoint) and setup the Metadata builder
	 * 
	 * @param {string} installationId 
	 * @param {string} endPoint 
	 */
	setInstallationId(installationId, signedMdKey, endPoint) {
		this._metadataBuilder = new MetadataBuilder(installationId, signedMdKey, endPoint);
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw (new Error('PageActionsDev not properly initialized with a dispatcher'));
		}
		if (!this._metadataBuilder) {
			throw (new Error('PageActionsDev not properly initialized with an installationId'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_PULL_ERROR,
			error: error.message
		}));
	}

	/**
	 * Deletes a page from the database AND the store
	 *
	 * @param {string} recordId Page Record ID to delete from the database and the store
	 */
	deleteFromDatabase(recordId) {
		// Local Meta Data Builder
		this._metadataBuilder
			.deleteRecords([{ 'recordId': recordId }], 'page')
			.then(function () {
				// Pass the fields to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: PageConstants.PAGE_DELETE_FROM_STORE,
					recordId: recordId
				}));
			}.bind(this))
			.catch(function (error) { 
				console.error(error); 
				this._onError(error);
			}.bind(this));
	}

	/**
	 * Deletes a page from the database AND the store
	 *
	 * @param {string} recordId Page Record ID to delete from the database and the store in a promisified fashion
	 */
	deleteFromDatabasePromise(recordId) {
		return new Promise((resolve, reject) => {
			try {
				// Local Meta Data Builder
				this._metadataBuilder
					.deleteRecords([{ 'recordId': recordId }], 'page')
					.then(function () {
						// Pass the fields to the store (call reduce with the object below)
						this._dispatcher.dispatch(Immutable.fromJS({
							type: PageConstants.PAGE_DELETE_FROM_STORE,
							recordId: recordId
						}));
						return resolve();
					}.bind(this))
					.catch(function (error) {
						console.error(error);
						this._onError(error);
						return reject(error);
					}.bind(this));
			} catch (err) {
				return reject(err);
			}
		});
	}

	/**
	 * Deletes a page from the store
	 *
	 * @param {string} recordId Page Record ID to delete from the store.
	 */
	deleteFromStore(recordId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_DELETE_FROM_STORE,
			recordId: recordId
		}));
	}

	receiveBroadcast(records) {
		this._checkInit();
		let startTime = +new Date();
		let RenderStore = require('../stores/render-store').default;
		let PageStore = require('../stores/page-store').default;
		// Initial broadcast to make sure everything updates
		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_RECEIVE_BROADCAST,
			records: records,
			lastChildCalculation: startTime
		}));
		if(records && records.length) {
			let RenderActions = require('./render-actions').default;
			records.filter(record => record.properties && !!record.properties.attachedFields).forEach(record => {
				if(record.properties && !!record.properties.attachedFields) {
					let pageObj = Object.assign({}, PageStore.get(record.recordId), record.properties);
					let renderItems = RenderStore.getRenderObjectsForComponent('page', record.recordId);
					if(renderItems) {
						renderItems.forEach(renderItem => {
							let dialogOptions = renderItem.has('dialogOptions')
								? renderItem.get('dialogOptions')
								: undefined;
							if(dialogOptions && dialogOptions.toJS) {
								dialogOptions = dialogOptions.toJS();
							}
							RenderActions.initiatePage(
								pageObj, renderItem.get('dataRecordId'), renderItem.get('dataTableSchemaName'),
								renderItem.get('renderId'), renderItem.get('renderParentId'), dialogOptions
							).catch(console.error);
						});
					}
				}
			});
		}
	}

	/**
	 * Update Metadata Gateway with this page's data.
	 * @param {string} recordId Page Record ID to push from the store to the database
	 */
	pushToDatabase(pageObject) {
		// We can just wrap the Promise object without returning it
		this.pushToDatabasePromise(pageObject);
	}

	/**
	 * Update Metadata Gateway with this page's data.
	 * @param {string} recordId Page Record ID to push from the store to the database
	 * @return {Promise}
	 */
	pushToDatabasePromise(pageObject) {
		return new Promise((resolve, reject) => {
			try {
				this._checkInit();
			} catch (error) {
				return reject(error);
			}

			// make *sure* we have a standard javascript object...
			if (pageObject.toJS) {
				pageObject = pageObject.toJS();
			}

			delete pageObject.componentsObj;

			// Object-valued keys (such as automation) need to be stringified
			Object.keys(pageObject).forEach(key => {
				if(key.startsWith('automation-') && pageObject[key] && typeof pageObject[key] === 'object') {
					pageObject[key] = JSON.stringify(pageObject[key]);
				}
			});

			if (pageObject.automation) {
				// Break the automation out into separate keys for the DB
				Object.keys(pageObject.automation).forEach(key => {
					// If we already have the key, do NOT overwrite it.
					if(!pageObject['automation-' + key]) {
						pageObject['automation-' + key] = pageObject.automation[key] && typeof pageObject.automation[key] === 'object' ?
							JSON.stringify(pageObject.automation[key]) :
							pageObject.automation[key];
					}
				});
				pageObject.automation = null;
				// Leave this in place for now; one thing at a time. Going to start work on the field settings key first
				// to find all locations where it's JSON.parsed and fix them
				// pageObject.automation = JSON.stringify(pageObject.automation);
			}

			pageObject.allowAdd = pageObject.allowAdd ? 'true' : 'false';

			// Local Meta Data Builder
			this._metadataBuilder
				// Update the page
				.updateRecords([pageObject], 'page')
				.then(function () {
					// Pass the tables to the store (call reduce with the object below)
					this._dispatcher.dispatch(Immutable.fromJS({
						type: PageConstants.PAGE_PUSH_TO_DATABASE,
						pageObject: pageObject
					}));
					return resolve(pageObject);
				}.bind(this))
				.catch(function (error) {
					console.error(error); 
					this._onError(error);
					return reject(error);
				}.bind(this));
		});
	}

	/**
	 * Update store page properties
	 * @param {string} recordId Page Record ID to update
	 * @param {Object} recordProperties Properties to update
	 */
	pushToStore(recordId, recordProperties) {
		this._checkInit();
		let startTime = +new Date();
		let oldObj;
		if(recordProperties.attachedFields) {
			let PageStore = require('../stores/page-store').default;
			oldObj = PageStore.get(recordId);
		}		

		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_PUSH_TO_STORE,
			recordId: recordId,
			recordProperties: recordProperties,
			lastChildCalculation: startTime
		}));

		if(recordProperties.attachedFields) {
			// If the attached fields have changed,
			// then we also need to recalculate
			// the affected rendered pages'
			// child fields
			if (oldObj && oldObj.attachedFields !== recordProperties.attachedFields) {
				let RenderStore = require('../stores/render-store').default;
				let RenderActions = require('./render-actions').default;

				let pageObj = Object.assign({}, oldObj, recordProperties);
				let renderItems = RenderStore.getRenderObjectsForComponent('page', recordId);
				
				if(renderItems) {
					renderItems.forEach(renderItem => {
						let dialogOptions = renderItem.has('dialogOptions')
							? renderItem.get('dialogOptions')
							: undefined;
						if(dialogOptions && dialogOptions.toJS) {
							dialogOptions = dialogOptions.toJS();
						}
						RenderActions.initiatePage(
							pageObj, renderItem.get('dataRecordId'), renderItem.get('dataTableSchemaName'),
							renderItem.get('renderId'), renderItem.get('renderParentId'), dialogOptions
						).catch(console.error);
					});
				}
			}
		}
	}

	/**
	 * Updates the automation for a page
	 * @param {string} recordId Page Record ID to update
	 * @param {string} triggerName The automation trigger to update
	 * @param {object} automation The new automation
	 */
	pushAutomationToStore(recordId, triggerName, automation) {
		this._checkInit();

		// Automation needs to be an object
		if(automation && typeof automation === 'string') {
			automation = ObjectUtils.getObjFromJSON(automation);
		}
		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_PUSH_AUTOMATION_TO_STORE,
			recordId: recordId,
			triggerName: triggerName,
			automation: automation
		}));
	}
	
	/**
	 * Updates the child configurations for a page
	 * @param {string} recordId Page Record ID to update 
	 * @param {string} childRecordId The record ID or attachment ID of the child field
	 * @param {string} settingSchemaName The setting to update
	 * @param {string} value The new value
	 */
	pushChildConfigurationToStore(parentRecordId, childRecordId, settingSchemaName, value) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_PUSH_CHILD_CONFIGURATION_TO_STORE,
			parentRecordId,
			childRecordId,
			settingSchemaName,
			value
		}));
	}

	/**
	 * Update store with a page's data.
	 * @param {string} recordId Page's Record ID to pull from the database to the database
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, overwriteStore) {
		this._checkInit();

		if (overwriteStore === undefined) {
			overwriteStore = true;
		}

		// Local Meta Data Builder
		this._metadataBuilder
			// Get the page
			.searchRecords('page', { 'recordId': recordId })
			.then(function (pageArray) {
				if (pageArray.length === 0) {
					pageArray = [{ recordId: recordId, components: '[]' }];
				}
				// Pass the pages to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: PageConstants.PAGE_PULL_FROM_DATABASE,
					pageArray: pageArray,
					overwriteStore: overwriteStore
				}));
			}.bind(this))
			.catch(function (error) {
				console.error(error);
				this._onError(error);
			}.bind(this));
	}

	/**
	 * Update store with all of the page's data.
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabaseAll(overwriteStore) {
		this._checkInit();

		if (overwriteStore === undefined) {
			overwriteStore = true;
		}

		// Local Meta Data Builder
		this._metadataBuilder
			// Get all the page
			.browseRecords('page')
			.then(function (pageArray) {
				// Pass the pages to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: PageConstants.PAGE_PULL_FROM_DATABASE_ALL,
					pageArray: pageArray,
					overwriteStore: overwriteStore
				}));
			}.bind(this))
			.catch(function (error) {
				console.error(error);
				this._onError(error);
			}.bind(this));
	}
	/**
   * Update store cols property
   * @param {string} recordId Page Record ID to update
   * @param {Object} cols cols property
   */
	pushColsToStore(recordId, cols) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_PUSH_COLS_TO_STORE,
			recordId: recordId,
			cols: cols
		}));
	}
	/**
	  * Update store components property
	  * @param {string} recordId Page Record ID to update
	  * @param {Object} components components property
	  */
	pushComponentsToStore(recordId, components) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_PUSH_COMPONENTS_TO_STORE,
			recordId: recordId,
			components: components
		}));
	}
	/**
	 * Update store page layouts
	 * @param {string} recordId Page Record ID to update
	 * @param {Array} layouts object of 'lg', 'md', and 'sm' layouts
	 */
	pushLayoutsToStore(recordId, layouts) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_PUSH_LAYOUTS_TO_STORE,
			recordId: recordId,
			layouts: layouts
		}));
	}
	/**
	 * Update store page template
	 * @param {string} recordId Page Record ID to update
	 * @param {String} template Template name
	 */
	pushTemplateToStore(recordId, template) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_PUSH_TEMPLATE_TO_STORE,
			recordId: recordId,
			template: template
		}));
	}
	/**
	 * pushFieldSettingToStore - saves localSettings values, such as label
	 * position or responsive variant name to store
	 *
	 * @param  {string} recordId 						UUID of the record to get
	 * @param  {string} fieldId        			field id of component variant requested
	 * @param  {string} settingSchemaName 	key of variant (ex: 'lgLabelPosition', 'smViewVariant')
	 * @param  {string} settingValue      	name of component property
	 */
	pushFieldSettingToStore(recordId, fieldId, settingSchemaName, settingValue) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_PUSH_FIELD_SETTING_TO_STORE,
			recordId: recordId,
			fieldId: fieldId,
			settingSchemaName: settingSchemaName,
			settingValue: settingValue
		}));
	}
	/**
	 * pushFieldAutomationToStore - Saves Blockly data to store
	 *
	 * @param  {string} recordId 					UUID of the record to get
	 * @param  {string} fieldId        		field id of component variant requested
	 * @param  {string} automationType  	action key name ('click', 'losefocus', etc.)
	 * @param  {type} automationObject 		object containing blocklyxml and js keys
	 */
	pushFieldAutomationToStore(recordId, fieldId, automationType, automationObject) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_PUSH_FIELD_AUTOMATION_TO_STORE,
			recordId: recordId,
			fieldId: fieldId,
			automationType: automationType,
			automationObject: automationObject
		}));

	}
}

/**
 * Actions for the core store that contains page records for the test/prod environments where we talk to the CDN JSON
 *
 * @class PageActionsProd
 */
class PageActionsProd {
	/**
	 * Singleton instance of PageActionsProd
	 * @param {Object} options
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if (options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Set the Installation Id
	 * 
	 * @param {string} installationId 
	 */
	setInstallationId(installationId) {
		this._installationId = installationId;
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw (new Error('PageActionsProd not properly initialized with a dispatcher'));
		}
		if (!this._installationId) {
			throw (new Error('PageActionsProd not properly initialized with an installationId'));
		}
		if (!this._installationVersion) {
			throw (new Error('PageActionsProd not properly initialized with an installationVersion'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_PULL_ERROR,
			error: error.message
		}));
	}

	/**
	 * Deletes a table from the database AND the store
	 */
	deleteFromDatabase() {
		console.warn('deleteFromDatabase not implemented.');
	}

	/**
	 * Deletes a table from the store
	 * 
	 * @param {string} recordId Table Record ID to delete from the store.
	 */
	deleteFromStore(recordId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_DELETE_FROM_STORE,
			recordId: recordId
		}));
	}

	/**
	 * Update Metadata Gateway with this record's data.
	 */
	pushToDatabase() {
		console.warn('pushToDatabase not implemented.');
	}

	/**
	 * Update store record properties
	 * @param {string} recordId Record to update
	 * @param {Object} recordProperties Properties to update
	 */
	pushToStore(recordId, recordProperties) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: PageConstants.PAGE_PUSH_TO_STORE,
			recordId: recordId,
			recordProperties: recordProperties
		}));
	}

	/**
	 * Update store with a record's data.
	 * @param {string} recordId Record to pull from the database to the database
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, overwriteStore) {
		this._checkInit();

		if (overwriteStore === undefined) {
			overwriteStore = true;
		}

		let installationId = this._installationId,
			installationVersion = this._installationVersion,
			metadataPath = 'https://cdn3.citizendeveloper.com/installations/' +
				installationId + '/' + installationVersion + '/page/' + recordId + '.json';
		fetchMetadata(metadataPath)
			.then((jsonResponse) => {
				// Pass the tables to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: PageConstants.PAGE_PULL_FROM_DATABASE,
					pageArray: [jsonResponse],
					overwriteStore: overwriteStore
				}));
			})
			.catch(function (error) {
				console.error('Unable to retrieve metadata from ', metadataPath, '. Error was: ', error);
			});
	}

	/**
	 * Update store with all of the records' data.
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabaseAll(overwriteStore) {
		this._checkInit();

		if (overwriteStore === undefined) {
			overwriteStore = true;
		}

		let installationId = this._installationId,
			installationVersion = this._installationVersion,
			metadataPath = 'https://cdn3.citizendeveloper.com/installations/' +
				installationId + '/' + installationVersion + '/page/index.json';
		fetchMetadata(metadataPath)
			.then((jsonResponse) => {
				// Pass the tables to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: PageConstants.PAGE_PULL_FROM_DATABASE_ALL,
					pageArray: jsonResponse,
					overwriteStore: overwriteStore
				}));
			})
			.catch(function (error) {
				console.error('Unable to retrieve metadata from ', metadataPath, '. Error was: ', error);
			});
	}
}

let toExport;
if(process.env.CITDEV_ENV === 'development'){

	try {
		if(window.location.href.includes('settings.html') && typeof window.opener.location.href === 'string') {
			toExport = window.opener.PageActions;
		} else {
			const instance = new PageActionsDev({dispatcher: AppDispatcher});
			toExport = instance;
		}
	} catch(error) { 
		const instance = new PageActionsDev({dispatcher: AppDispatcher});
		toExport = instance;
	}
} else {
	toExport = new PageActionsProd({dispatcher: AppDispatcher});
}

export default toExport;