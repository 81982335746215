/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container} from 'flux/utils';

import AutomationPin from './automation';
import AppearancePin from './appearance';
import CompliancePin from './compliance';
import DiscussionPin from './discussion';
import QueryPin from './query';
import SecurityPin from './security';
import VisibilityPinV1 from './visibility-v1';
import VisibilityPin from './visibility';

// Stores
import {AdminSettingsStore} from '../../stores';

/**
 * Container to Appearance, Automation and Query Pin
 *
 * @class PinsContainer
 * @extends {Component}
 */
class PinsContainer extends Component {
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf PinsContainer
	 */
	static getStores() {
		return [AdminSettingsStore];
	}

	/**
	 * Returns the current State of the Pins
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf PinsContainer
	 */
	static calculateState(prevState, props) {
		return {
			'openOverlays': AdminSettingsStore.getActiveOverlays().length,
		};
	}

	/**
	 * Render the pins!
	 * 
	 * @returns 
	 * @memberof PinsContainer
	 */
	render() {
		let spanStyle = {};
		if(this.props.tableSchemaName === 'page') {
			// Start by shifting right one (-26) and then shift left 26 
			// for each open overlay after the first one.

			// If the page has saveControlsTop, move the pin container further to the left
			let startingLeftPos = this.props.saveControlsPinAdjustment ? 0 : 26;
			spanStyle['left'] = (startingLeftPos - ((this.state.openOverlays) * 26)) + 'px';
		}
		return (
			<span style={spanStyle} className={`pin-container 
				${this.props.fieldCloseToTop 
					? 'field-close-to-top' 
					: ''} 
				${this.props.fieldCloseToFieldContainerPin 
					? 'field-close-to-field-container-pin' 
					: ''}
			`}>
				<AppearancePin {...this.props} />
				<QueryPin {...this.props} />
				<AutomationPin {...this.props} />
				<DiscussionPin {...this.props} />
				<VisibilityPinV1 {...this.props} />
				<VisibilityPin {...this.props} />
				<SecurityPin {...this.props} />
				<CompliancePin {...this.props} />
			</span>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	PinsContainer.propTypes = {
		controlsAddAndEdit: PropTypes.bool,
		fieldType: PropTypes.string,
		parentRecordId: PropTypes.string,
		parentTableSchemaName: PropTypes.string,
		recordId: PropTypes.string.isRequired,
		renderId: PropTypes.string,
		subSettingIndex: PropTypes.number,
		subSettingSchemaName: PropTypes.string,
		tableSchemaName: PropTypes.string.isRequired,
	};
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(PinsContainer, {withProps: true});
export default container;
