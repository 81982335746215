import React, {Component} from 'react';
import PropTypes from 'prop-types';
import QueryContainer from '../../components/query-container.react';

// Actions
import InterfaceActions from '../../actions/interface-actions';

import ObjectUtils from '../../utils/object-utils';
import uuid from 'uuid';
import {QueryVO} from '@dmclain-citizendeveloper/citdev-react-query';
const style = {
	modal: {
		display: 'block'
	}
};
/**
 * Component for ExpressionEditor
 *
 * @class ExpressionEditor
 * @extends {Component}
 */
class QueryDialog extends Component {

	constructor(props){
		super(props);
		this.state = {
			dialogHeight: screen.height,
			resetAndCloseValue: props.value
		}

		this.copyQuery = this.copyQuery.bind(this);
		this.pasteQuery = this.pasteQuery.bind(this);
		this.clearQuery = this.clearQuery.bind(this);
		this.onResetAndCloseHandler = this.onResetAndCloseHandler.bind(this);

		this.handleResize = this.handleResize.bind(this);
	}

	/**
	 * onResetAndCloseHandler - Clears the query and closes the dialog
	 * This will reset to the value when the dialog first opens
	 * This will not clear the entire query as it does on Query Settings Chooser
	*/
	onResetAndCloseHandler() {
		let valueObj = ObjectUtils.getObjFromJSON(this.state.resetAndCloseValue);

		this.props.onChange({
			target: {
				value: JSON.stringify(valueObj)
			}
		});

		this.props.onClose();
	}

	/**
	 * Clears the query
	 */
	clearQuery() {
		let newValue = '';
		if (this.props.tableEditable === false) { 
			let filteredValueNode = this.props.value && this.props.value.nodes ? this.props.value.nodes.filter((node) => this.props.value.returnNode === node.nodeId): [];
			let originalNodeTable = filteredValueNode[0] ? filteredValueNode[0].tableSchemaName : '';

			newValue = originalNodeTable ? new QueryVO({queryId: this.props.value.queryId, 
				nodes: filteredValueNode,
				returnNode: this.props.value.returnNode,
				filters: []}) : '';
		}

		this.props.onChange({
			target: {
				value: JSON.stringify(newValue)
			}
		});
	}

	/**
	 * Copies query to citdev clipboard
	 */
	copyQuery(){
		try {
			localStorage.queryClipboard = this.props.value;
			InterfaceActions.notification({ 'level': 'success', 'message': 'Copying query to clipboard...' });
		} catch(err) {
			console.error('Error when copying query: ', err);
			InterfaceActions.notification({ 'level': 'error', 'message': 'Error when copying query!' });
		}
	}
	/**
	 * Pastes query from citdev clipboard to workspace; appends to, not overrides, blocks
	 */
	pasteQuery() {
		let value = localStorage.queryClipboard;
		try {
			let valueObj = ObjectUtils.getObjFromJSON(value);
			if (this.props.tableEditable === false) {
				//validate the table
				let filteredValueNode = this.props.value && this.props.value.nodes ? this.props.value.nodes.filter((node) => this.props.value.returnNode === node.nodeId): [];
				let filertedIncomingNode = valueObj.nodes ? valueObj.nodes.filter((node) => valueObj.returnNode === node.nodeId) : [];
				
				let originalNodeTable = filteredValueNode[0] ? filteredValueNode[0].tableSchemaName : '';
				let incomingNodeTable = filertedIncomingNode[0] ? filertedIncomingNode[0].tableSchemaName : '';

				if (originalNodeTable !== incomingNodeTable) {
					InterfaceActions.notification({ 'level': 'error', 'message': 'Pasting failed! The Table does not match' });
					return false;
				}
			}
			InterfaceActions.notification({ 'level': 'success', 'message': 'Pasting new query. Remember that this will override your entire query!' });
			
			// Give it its own new query ID
			valueObj.queryId = uuid.v4();

			//Trigger a rerender of the blocks
			this.setState({ currentQuery: valueObj, flushBlockly: true }, () => {
				this.props.onChange({
					target: {
						value: JSON.stringify(valueObj)
					}
				});
			});
		} catch(err) {
			InterfaceActions.notification({ 'level': 'error', 'message': 'Attempted to paste invalid value into query.' });
			console.warn('Attempted to paste with invalid data in clipboard. Value was', value);
		}
	}

	/**
	 * handleResize measures the height of the dialog
	 * children of blockly-dialog need this dialogHeight value to adjust the positioning of elements so they are accessible
	 * on smaller screens or when the user adjusts the height manually
	 */
	handleResize() {
		if(!this.isResizing) {
			this.isResizing = true;
			
			setTimeout(() => {
				this.setState({
					dialogHeight: window.innerHeight
				});
				this.isResizing = false;
			}, 250);
		}
	}

	componentDidMount() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.dialogHeight !== window.innerHeight) {
			this.handleResize();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	/**
	 * Render dialog with blockly editor
	 *
	 * @memberOf BlocklyDialog
	 */
	render() {
		let {onChange, title, namedContexts, value, fieldSchemaName} = this.props;
		let { dialogHeight } = this.state;
		
		return(
			<div style={style.modal} className="modal fade show">
				<div id="blockly-dialog-modal" className="blockly-dialog-modal modal-dialog modal-lg query-dialog">
					<div className="modal-content">
						<div className="modal-header align-items-center">
							<h1 className="modal-title align-self-center">{title}</h1>
							<div className='modal-header-buttons'>
								<button
									key="copy"
									className="btn btn-secondary mr-0 dialog-button-copy"
									form="appearance-form"
									aria-label="Copy Query"
									onClick={this.copyQuery}>
									Copy
								</button>
								<button
									key="paste"
									className="btn btn-secondary mx-2 dialog-button-paste"
									form="appearance-form"
									aria-label="Paste Query"
									onClick={this.pasteQuery}>
									Paste
								</button>
								<button
									key="clear"
									className="btn btn-warning mr-0 dialog-button-clear"
									form="appearance-form"
									aria-label="Clear Query"
									onClick={this.clearQuery}>
									Clear
								</button>
								<button
									key="resetAndClose"
									className="btn btn-warning mx-2 dialog-button-reset-and-close"
									form="appearance-form"
									aria-label="Reset and Close"
									onClick={this.onResetAndCloseHandler}>
									Reset and Close
								</button>
								<button
									key="saveAndClose"
									className="btn btn-primary mr-0 dialog-button-save-and-close"
									form="appearance-form"
									aria-label="Save and Close"
									onClick={this.props.onClose}>
									Save and Close
								</button>
							</div>
						</div>
						<div className="modal-body pt-2 pb-0">
							<style>{'.blockly-height{height:400px}'}</style>
							<QueryContainer 
								dialogHeight={dialogHeight}
								value={value} 
								schemaName={fieldSchemaName} 
								onChange={onChange} 
								namedContexts={namedContexts} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
if ('development' === process.env.NODE_ENV) {
	QueryDialog.propTypes = {
		title: PropTypes.string,
		value: PropTypes.string,
		onChange: PropTypes.func,
		onClose: PropTypes.func
	};
}

export default QueryDialog;