// Define action constants
var HelpAndUpgradeConstants = {
	'UPGRADE': 'https://platform.citizendeveloper.com/upgrade?tool=&installationId=INSTALLATIONID',	
	'UPGRADE_API_INCOMING': 'https://platform.citizendeveloper.com/upgrade?tool=api-incoming&installationId=INSTALLATIONID',
	'UPGRADE_API_REST_LOGIC': 'https://platform.citizendeveloper.com/upgrade?tool=api-rest-logic&installationId=INSTALLATIONID',				
	'UPGRADE_CHANGE_MANAGEMENT': 'https://platform.citizendeveloper.com/upgrade?tool=change-management&installationId=INSTALLATIONID',
	'UPGRADE_COMPLIANCE': 'https://platform.citizendeveloper.com/upgrade?tool=compliance&installationId=INSTALLATIONID',
	'UPGRADE_MAX_PAGES': 'https://platform.citizendeveloper.com/upgrade?tool=max-pages&installationId=INSTALLATIONID',	
	'UPGRADE_MAX_TABLES': 'https://platform.citizendeveloper.com/upgrade?tool=max-tables&installationId=INSTALLATIONID',
	'UPGRADE_MAX_RECORDS_PER_TABLE': 'https://platform.citizendeveloper.com/upgrade?tool=max-records&installationId=INSTALLATIONID',			
	'UPGRADE_SCHEDULED_LOGIC': 'https://platform.citizendeveloper.com/upgrade?tool=scheduled-logic&installationId=INSTALLATIONID',
	'UPGRADE_SECURITY': 'https://platform.citizendeveloper.com/upgrade?tool=security&installationId=INSTALLATIONID',		
	'UPGRADE_SECURITY_AUDIT_LOGS': 'https://platform.citizendeveloper.com/upgrade?tool=security-audit-logs&installationId=INSTALLATIONID',
	'UPGRADE_SECURITY_GROUPS': 'https://platform.citizendeveloper.com/upgrade?tool=security-groups&installationId=INSTALLATIONID',
	'UPGRADE_STRIPE': 'https://platform.citizendeveloper.com/upgrade?tool=stripe&installationId=INSTALLATIONID',
	'UPGRADE_VISIBILITY': 'https://platform.citizendeveloper.com/upgrade?tool=visibility&installationId=INSTALLATIONID',

	// element contents NOT decided on - discuss with Noah W.
	'HELP_FIELDTYPE_SHORT_TEXT': 'https://platform.citizendeveloper.com/help?element=fieldtype-shorttext&installationId=INSTALLATIONID',
	'HELP_FIELDTYPE_LONG_TEXT': '',
	
	// https://docs.google.com/spreadsheets/d/1QOO_3UR_UnAZvJ3GTMRajv6qZCBz8kmZuqucG-UIbgM/edit#gid=0
	'HELP_DASHBOARD_COMPLIANCE': 'https://platform.citizendeveloper.com/help?element=dashboardsCompliance&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_DATA': 'https://platform.citizendeveloper.com/help?element=dashboardsData&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_DATA_TABLES': 'https://platform.citizendeveloper.com/help?element=dashboardsDataTables&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_DATA_FIELDS': 'https://platform.citizendeveloper.com/help?element=dashboardsDataFields&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_DATA_RELATIONSHIPS': 'https://platform.citizendeveloper.com/help?element=dashboardsDataRelationships&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_DATA_SECURITY': 'https://platform.citizendeveloper.com/help?element=dashboardsDataSecurity&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_DATA_RECORDS': 'https://platform.citizendeveloper.com/help?element=dashboardsDataRecords&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_PRESENTATION': 'https://platform.citizendeveloper.com/help?element=dashboardsPresentation&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_PRESENTATION_PAGES': 'https://platform.citizendeveloper.com/help?element=dashboardsPresentationPages&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_PRESENTATION_VISIBILITY': 'https://platform.citizendeveloper.com/help?element=dashboardsPresentationVisibility&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_PRESENTATION_THEME': 'https://platform.citizendeveloper.com/help?element=dashboardsPresentationTheme&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_LOGIC': 'https://platform.citizendeveloper.com/help?element=dashboardsLogic&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_LOGIC_FUNCTIONS': 'https://platform.citizendeveloper.com/help?element=dashboardsLogicFunctions&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_LOGIC_SCHEDULED': 'https://platform.citizendeveloper.com/help?element=dashboardsLogicScheduled&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_LOGIC_APIS': 'https://platform.citizendeveloper.com/help?element=dashboardsLogicApi&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_LOGIC_WEBHOOKS': 'https://platform.citizendeveloper.com/help?element=dashboardsLogicWebhooks&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_LOGIC_PAGES': 'https://platform.citizendeveloper.com/help?element=dashboardsLogicPages&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_LOGIC_FIELDS': 'https://platform.citizendeveloper.com/help?element=dashboardsLogicFields&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_PROJECT': 'https://platform.citizendeveloper.com/help?element=dashboardsProject&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_PROJECT_DISCUSSIONS': 'https://platform.citizendeveloper.com/help?element=dashboardsProjectDiscussions&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_PROJECT_CHANGE_MANAGEMENT': 'https://platform.citizendeveloper.com/help?element=dashboardsProjectChangeManagement&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_PROJECT_USERS': 'https://platform.citizendeveloper.com/help?element=dashboardsProjectUsers&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_PROJECT_APPLICATION': 'https://platform.citizendeveloper.com/help?element=dashboardsProjectApplication&installationId=INSTALLATIONID',
	'HELP_DASHBOARD_PROJECT_INSTALLATION': 'https://platform.citizendeveloper.com/help?element=dashboardsProjectInstallation&installationId=INSTALLATIONID',
};

// Max Pages / Max Fields - NLP

module.exports = HelpAndUpgradeConstants;