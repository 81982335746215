import React, { Component } from 'react';
import { Container } from 'flux/utils';
import FieldContainer from './field-container.react';
// import ReactGridLayoutWrapped, {WidthProvider, Responsive} from '@dmclain-citizendeveloper/citdev-react-grid-layout';
import { Responsive } from '@dmclain-citizendeveloper/citdev-react-grid-layout';
import GridItemWrapped from './grid-item.react';
import PinsContainer from './pins/pins-container';
import ActionProcessor from '../utils/action-processor';
import FieldModesStore from '../stores/field-modes-store';
import { BlockUtils, FieldUtils, ObjectUtils } from '../utils';
import GridHeightUtils from '../utils/grid-height-utils';
import { FieldActions, InterfaceActions, PageActions, PageModeActions, RecordActions } from '../actions';
import { AdminSettingsStore, ContextStore, FieldSettingsStore, FieldTypeStore, FieldStore, PageModeStore, PageStore, RecordStore, RenderStore } from '../stores';
import {GridItem as GridItemView, GridLayout as GridLayoutView} from './grid-layout-view.react.js';

const ResponsiveGridLayout = Responsive;
// const ReactGridLayout = Responsive;

// const ResponsiveGridLayout = WidthProvider(Responsive);

// const availableHandles = ["s", "w", "e", "n", "sw", "nw", "se", "ne"];

const GridItem = React.forwardRef(({style, className, ...props}, ref) => {
	return (
	  <div style={{ /* styles */ position: "relative", overflow: "auto", ...style}} className={className} ref={ref}>
		<GridItemWrapped {...props} childStyle={style} />
	  </div>  
	);
});

class SaveButton extends Component {
	/**
	 *Creates an instance of SaveButton.
	 * @param {any} props
	 * @memberof SaveButton
	 */
	constructor(props) {
		super(props);
		this.saveButton = null;
	}

	/**
	 * Toggles the Disable look for the save button
	 *
	 * @memberof SaveButton
	 */
	disableToggle() {
		if (this.saveButton) {
			this.saveButton.classList.toggle('disabled');
		}
	}

	/**
	 * Renders the contents of a Save Button
	 *
	 * @returns
	 * @memberof SaveButton
	 */
	render() {
		return <a ref={(saveButtonRef) => {this.saveButton = saveButtonRef;}} 
			className='btn btn-primary addButton' 
			role='button' 
			onClick={this.props.onSave}>
				{this.props.buttonText}
		</a>
	}
}

SaveButton.displayName = 'SaveButton';

class ToggleModeButton extends Component {
	/**
	 *Creates an instance of ToggleModeButton.
	 * @param {any} props
	 * @memberof ToggleModeButton
	 */
	constructor(props) {
		super(props);
		this.toggleButton = null;
	}

	/**
	 * Toggles the Disable look for the mode button
	 *
	 * @memberof ToggleModeButton
	 */
	disableToggle() {
		if (this.toggleButton) {
			this.toggleButton.classList.toggle('disabled');
		}
	}

	/**
	 * Renders the contents of a Toggle Mode Button
	 *
	 * @returns
	 * @memberof ToggleModeButton
	 */
	render() {
		return <a ref={(toggleButtonRef) => {this.toggleButton = toggleButtonRef;}} 
			className='btn btn-primary' 
			role='button' 
			onClick={this.props.onToggle}>{this.props.buttonText}
		</a>
	}
}

ToggleModeButton.displayName = 'ToggleModeButton';

/**
 * Container that renders page data and child components
 *
 * @class GridComponent
 * @extends {Component}
 */
class GridComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {width: this.props.size ? this.props.size.width : undefined};
		this.getManagingScreenSize = this.getManagingScreenSize.bind(this);
		this.onFieldPositionChange = this.onFieldPositionChange.bind(this);
		this.saveGrid = this.saveGrid.bind(this);
		this.toggleClick = this.toggleClick.bind(this);
		this.cleanRecords = this.cleanRecords.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.clickExpand = this.clickExpand.bind(this);
		this.clickFixedHeight = this.clickFixedHeight.bind(this);
		this.getWidth = this.getWidth.bind(this);

		this.saveButtonTop = null;
		this.saveButtonBottom = null;
		this.toggleButtonTop = null;
		this.toggleButtonBottom = null;
		this.saving = false;
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf GridComponent
	 */
	static getStores() {
		return [PageModeStore, FieldModesStore, FieldStore, AdminSettingsStore, ContextStore, RenderStore];
	}
	/**
	 * Returns the current State of the FieldType Settings
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf GridComponent
	 */
	static calculateState(prevState, props) {
		// let attachedFieldsArr = this.props.attachedFields ? JSON.parse(this.props.attachedFields) : [];
		// attachedFieldsArr = attachedFieldsArr.map(attachedField => {
		// 	attachedField.fieldId = attachedField.recordId;
		// 	let fieldObj = FieldStore.get(attachedField.recordId);
		// 	attachedField.fieldSchemaName = fieldObj.fieldSchemaName;
		// 	return attachedField;
		// });
		// let components = { 'lg': attachedFieldsArr, 'md': attachedFieldsArr, 'sm': attachedFieldsArr };
		// let layouts = this.props.fieldPosition ? this.props.fieldPosition : '{"lg": [], "md": [], "sm": []}';

		let managingScreensize = 'lg';
		
		// Figure out which screensize is managing this screensize.
		if(props.screensizeManagementJSON) {
			let screensizeManagementObj = ObjectUtils.getObjFromJSON(props.screensizeManagementJSON);
			if(screensizeManagementObj[ContextStore.getResponsiveMode()]) {
				managingScreensize = screensizeManagementObj[ContextStore.getResponsiveMode()];
			}
		}

		let currentMode = '',
			availableModes = undefined,
			renderId = props.renderId;

		let pageId = ''
		if (props.tableSchemaName === 'page') {
			pageId = props.recordId ? props.recordId : '';
			currentMode = renderId ? PageModeStore.getCurrentMode(renderId) : '';
			availableModes = renderId ? PageModeStore.getAvailableModes(pageId) : undefined;
			
		} else if (renderId) {
			let mode = FieldModesStore.getMode(renderId);
			currentMode = mode ? mode.currentMode : '';
			availableModes = FieldModesStore.getAvailableModes(renderId);
		}

		let isGridEditable = false;
		if(typeof props.allowGridResize === 'undefined' || props.allowGridResize === true)
		{
			isGridEditable = AdminSettingsStore.getIsOverlayActive('resizer');
		}
		let responsiveMode = ContextStore.getResponsiveMode();

		// Loop over the attachedFieldsArr in order to get the field modes for each one
		// that way, if the modes change, it will trigger a re-render
		// (Ticket 21190)
		let attachedFieldsArr = RenderStore.getAttachedFields(props.renderId);
		attachedFieldsArr.forEach(field => {
			let mode = FieldModesStore.getMode(field.renderId);
			field.currentMode = mode ? mode.currentMode : '';
			field.availableModes = FieldModesStore.getAvailableModes(field.renderId);
		});

		let resizerIsActive = AdminSettingsStore.getIsOverlayActive('resizer');

		let {fieldPositions, spacerHeight, hasExpanding} = RenderStore.getFieldPositions(props.renderId, managingScreensize, resizerIsActive);

		if (prevState && prevState.attachedFieldsArr) {
			if (JSON.stringify(attachedFieldsArr) === JSON.stringify(prevState.attachedFieldsArr)) {
				attachedFieldsArr = prevState.attachedFieldsArr;
			}
		}

		if (prevState && prevState.fieldPositions) {
			if (JSON.stringify(fieldPositions) === JSON.stringify(prevState.fieldPositions)) {
				fieldPositions = prevState.fieldPositions;
			}
		}

		let gridHeightPx = GridHeightUtils.calculateGridLayoutHeight(fieldPositions);

		return {
			isGridEditable: isGridEditable,
			currentMode: currentMode,
			availableModes: availableModes,
			visibilityV1OverlayIsActive: AdminSettingsStore.getIsOverlayActive('visibilityV1'),
			visibilityOverlayIsActive: AdminSettingsStore.getIsOverlayActive('visibility'),
			resizerIsActive: resizerIsActive,
			responsiveMode: responsiveMode,
			pageId: pageId,
			attachedFieldsArr: attachedFieldsArr,
			fieldPositions,
			spacerHeight,
			hasExpanding,
			gridHeightPx
		};
	}

	/**
	 * setCurrentMode - sets current mode of components
	 *
	 * @param  {string} targetMode "view" or "edit"
	 */
	setCurrentMode(targetMode) {
		let renderId = this.props.renderId;
		if (PageModeStore.hasAvailableMode(renderId, targetMode)) {
			PageModeActions.setMode(renderId, targetMode);
		}
	}

	/**
	 * Handler method for clicking the "Expand" control.  Turns off all other 
	 * cases where expand was enabled, and enabled it for the new location.
	 * Updated position extras are then saved in the store.
	 * @param {string} gridKey 
	 * @param {string} fieldPositionExtrasJSON 
	 */
	clickExpand(gridKey, fieldPositionExtrasJSON) {
		let fieldPositions = (fieldPositionExtrasJSON ? ObjectUtils.getObjFromJSON(fieldPositionExtrasJSON) : {});
		let managingScreensize = this.getManagingScreenSize();
		let defaultObj = {
			i:gridKey,
			expands: 'true',
			autofit: 'false'
		};

		let fieldPos = null;
		if(fieldPositions && fieldPositions[managingScreensize]) {
			fieldPos = fieldPositions[managingScreensize];

			// Turn it off everywhere
			let keyFound = false;
			fieldPos.forEach(field => {
				if(field.i === gridKey) {
					field['expands'] = (field['expands'] && field['expands'] === 'true') ? 'false' : 'true';
					keyFound = true;
					if(field.expands === 'true') {
						field.autofit = 'false';
					}
				} else {
					field['expands'] = 'false'
				}
			})

			// Turn it on.
			if(!keyFound) {
				fieldPos.push(defaultObj)
			}
		} else {
			fieldPos = [defaultObj];
		}
		this.onFieldPositionExtrasChange(fieldPos);
	}

		/**
	 * Handler method for clicking the "Autofit" control.
	 * Updated position extras are then saved in the store.
	 * @param {string} gridKey 
	 * @param {string} fieldPositionExtrasJSON 
	 */
		 clickAutoFit(gridKey, fieldPositionExtrasJSON) {
			let fieldPositions = (fieldPositionExtrasJSON ? ObjectUtils.getObjFromJSON(fieldPositionExtrasJSON) : {});
			let managingScreensize = this.getManagingScreenSize();
			let defaultObj = {
				i:gridKey,
				autofit: 'true',
				expands: 'false'
			};
	
			let fieldPos = null;
			if(fieldPositions && fieldPositions[managingScreensize]) {
				fieldPos = fieldPositions[managingScreensize];
	
				let keyFound = false;
				fieldPos.forEach(field => {
					if(field.i === gridKey) {
						field['autofit'] = (field['autofit'] && field['autofit'] === 'true') ? 'false' : 'true';
						if(field.autofit === 'true') {
							field.expands = 'false';
						}
						keyFound = true;
					}
				})
	
				// Turn it on.
				if(!keyFound) {
					fieldPos.push(defaultObj)
				}
			} else {
				fieldPos = [defaultObj];
			}
			this.onFieldPositionExtrasChange(fieldPos);
		}

		/**
	 * Handler method for clicking the "Fixed Height" control.
	 * Updated position extras are then saved in the store.
	 * @param {string} gridKey 
	 * @param {string} fieldPositionExtrasJSON 
	 */
		 clickFixedHeight(gridKey, fieldPositionExtrasJSON) {
			let fieldPositions = (fieldPositionExtrasJSON ? ObjectUtils.getObjFromJSON(fieldPositionExtrasJSON) : {});
			let managingScreensize = this.getManagingScreenSize();
			let defaultObj = {
				i:gridKey,
				autofit: 'false',
				expands: 'false'
			};
	
			let fieldPos = null;
			if(fieldPositions && fieldPositions[managingScreensize]) {
				fieldPos = fieldPositions[managingScreensize];
	
				let keyFound = false;
				fieldPos.forEach(field => {
					if(field.i === gridKey) {
						field.autofit = 'false';
						field.expands = 'false';
						keyFound = true;
					}
				})
	
				// Turn it on.
				if(!keyFound) {
					fieldPos.push(defaultObj)
				}
			} else {
				fieldPos = [defaultObj];
			}
			this.onFieldPositionExtrasChange(fieldPos);
		}

	getWidth() {
		// If the resizer is active and this is in a dialog
		// then if the dialog was opened wider than the page, the resizer may not show
		// Address this by capping the width in large dialogs. (25335)
		if(this.state.resizerIsActive && this.props.size && this.props.size.width && this.props.width) {
			return Math.min(this.props.size.width, this.props.width);
		}
		return this.props.size ? (this.props.size.width || this.props.width) : this.props.width;
	}

	/**
	 * Figure out the screen size that's managing the active one.
	 * 
	 * @returns string Managing Screensize: lg, md, sm
	 * @memberof GridComponent
	 */
	getManagingScreenSize() {
		let managingScreensize = 'lg';
		
		// Figure out which screensize is managing this screensize.
		if(this.props.screensizeManagementJSON) {
			let screensizeManagementObj = ObjectUtils.getObjFromJSON(this.props.screensizeManagementJSON);
			if(screensizeManagementObj[ContextStore.getResponsiveMode()]) {
				managingScreensize = screensizeManagementObj[ContextStore.getResponsiveMode()];
			}
		}

		return managingScreensize;
	}

	/**
	 * onFieldPositionChange - Sends fieldPositions to store.
	 *
	 * @param  {Object} fieldPositions current field positions
	 */
	onFieldPositionChange(fieldPositions) {
		if (this.state.isGridEditable && AdminSettingsStore.getIsOverlayActive('resizer')) {
			let managingScreensize = this.getManagingScreenSize();
			let fieldPositionObj = ObjectUtils.getObjFromJSON(this.props.fieldPositionJSON);
			let attachedFieldsArr = ObjectUtils.getObjFromJSON(this.props.attachedFieldsJSON);
			let recordId = this.props.recordId;

			//Check the field positions and make sure we have on position for each attached field
			//if we do not then do not process the field positions because we are in the "middle of determining the visibility rules"
			if (Array.isArray(attachedFieldsArr) && Array.isArray(fieldPositions) &&
				attachedFieldsArr.length !== fieldPositions.length) {
				console.warn('Length of fieldPositions does not match for %s %s. This may happen when a hidden field becomes visible by turning on the resizer. Attempting to reconcile. attachedFieldsArr, fieldPositions', this.props.tableSchemaName, recordId, attachedFieldsArr, fieldPositions);
				if(attachedFieldsArr.length) {
					let newFieldPositions = [];
					let lastYs = {lg: 0, md: 0, sm: 0};
					attachedFieldsArr.forEach(attachmentObj => {
						let id = attachmentObj.attachmentId || attachmentObj.recordId;
						let matchingPositionObj = fieldPositions.find(positionObj => positionObj.i === id);
						if (matchingPositionObj) {
							newFieldPositions.push(matchingPositionObj);
						} else {
							// See if we have an entry for this in field positions already (as when a hidden field becomes visible)
							let defaultObj = {
								i: id,
								h: 4,
								w: 6,
								x: 0,
								y: lastYs[managingScreensize]
							};
							let toPush = fieldPositionObj[managingScreensize] ? fieldPositionObj[managingScreensize].find(obj => obj.i === id) : undefined;
							if(!toPush) {
								console.warn('No previous positioning information found for attachment ID %s. Generating.', id);
								lastYs[managingScreensize] += 1;
								toPush = defaultObj;
							}
							newFieldPositions.push(toPush);
						}
					});
					fieldPositions = newFieldPositions;
				}
				// return;
			}

			// Updating the managing screensize with the new value
			fieldPositionObj[managingScreensize] = fieldPositions;

			if (this.props.tableSchemaName === 'field') {
				// added set timeout to prevent from simultaneous dispatches
				setTimeout(function () {
					FieldActions.pushSettingToStore(recordId, 'fieldPosition', JSON.stringify(fieldPositionObj));
				}, 1);
			} else {
				// added set timeout to prevent from simultaneous dispatches
				setTimeout(function () {
					PageActions.pushToStore(recordId, { fieldPosition:JSON.stringify(fieldPositionObj) });
				}, 1);
			}
			
		}
	}

	/**
	 * onFieldPositionExtrasChange - Sends fieldPositionExtras to store.
	 *
	 * @param  {Object} fieldPositionExtras current field positions
	 */
	onFieldPositionExtrasChange(fieldPositions) {
		if (this.state.isGridEditable && AdminSettingsStore.getIsOverlayActive('resizer')) {
			let managingScreensize = this.getManagingScreenSize();
			let fieldPositionExtrasObj = ObjectUtils.getObjFromJSON(this.props.fieldPositionExtrasJSON);
			let attachedFieldsArr = ObjectUtils.getObjFromJSON(this.props.attachedFieldsJSON);
			let recordId = this.props.recordId;

			//Check the field positions and make sure we have on position for each attached field
			//if we do not then do not process the field positions because we are in the "middle of determining the visibility rules"
			if (Array.isArray(attachedFieldsArr) && Array.isArray(fieldPositions) &&
				attachedFieldsArr.length !== fieldPositions.length) {
				console.warn('Length of fieldPositionExtras does not match for %s %s. This may happen when a hidden field becomes visible by turning on the resizer. Attempting to reconcile. attachedFieldsArr, fieldPositionExtras', this.props.tableSchemaName, recordId, attachedFieldsArr, fieldPositionExtrasObj);
				if(attachedFieldsArr.length) {
					let newFieldPositions = [];
					let lastYs = {lg: 0, md: 0, sm: 0};
					attachedFieldsArr.forEach(attachmentObj => {
						let id = attachmentObj.attachmentId || attachmentObj.recordId || attachmentObj.fieldId;
						let matchingPositionObj = fieldPositions.find(positionObj => positionObj.i === id);
						if (matchingPositionObj) {
							newFieldPositions.push(matchingPositionObj);
						} else {
							// See if we have an entry for this in field positions already (as when a hidden field becomes visible)
							let defaultObj = {
								i: id,
							};
							let toPush = fieldPositionExtrasObj[managingScreensize] ? fieldPositionExtrasObj[managingScreensize].find(obj => obj.i === id) : undefined;
							if(!toPush) {
								console.warn('No previous positioning information found for attachment ID %s. Generating.', id);
								lastYs[managingScreensize] += 1;
								toPush = defaultObj;
							}
							newFieldPositions.push(toPush);
						}
					});
					fieldPositions = newFieldPositions;
				}
				// return;
			}

			// Updating the managing screensize with the new value
			fieldPositionExtrasObj[managingScreensize] = fieldPositions;

			if (this.props.tableSchemaName === 'field') {
				// added set timeout to prevent from simultaneous dispatches
				setTimeout(function () {
					FieldActions.pushSettingToStore(recordId, 'fieldPositionExtras', JSON.stringify(fieldPositionExtrasObj));
				}, 1);
			} else {
				// added set timeout to prevent from simultaneous dispatches
				setTimeout(function () {
					PageActions.pushToStore(recordId, { fieldPositionExtras:JSON.stringify(fieldPositionExtrasObj) });
				}, 1);
			}
		}
	}

	saveGrid(event) {
		event.preventDefault();
		if (!this.saving) {
			this.saving = true;
			if (this.saveButtonTop) {
				this.saveButtonTop.disableToggle();
			}
			if (this.saveButtonBottom) {
				this.saveButtonBottom.disableToggle();
			}
			if (this.toggleButtonTop) {
				this.toggleButtonTop.disableToggle();
			}
			if (this.toggleButtonBottom) {
				this.toggleButtonBottom.disableToggle();
			}
			InterfaceActions.saveGrid({renderId: this.props.renderId}).then(response => {
				// console.log(`Success Saving Grid: ${response}`);
				this.saving = false;

				// If the save failed, don't toggle the mode or clear anything
				if(response === 'Success Saving Grid') {
					let currentMode = '';
					if (this.props.tableSchemaName === 'page') {
						currentMode = PageModeStore.getCurrentMode(this.props.renderId);
					} else {
						let renderId = this.props.renderId ? this.props.renderId : '';
						if (renderId) {
							let mode = FieldModesStore.getMode(renderId);
							currentMode = mode ? mode.currentMode : '';
						}
					}
	
					this.cleanRecords(currentMode);
				}
				if (this.saveButtonTop) {
					this.saveButtonTop.disableToggle();
				}
				if (this.saveButtonBottom) {
					this.saveButtonBottom.disableToggle();
				}
				if (this.toggleButtonTop) {
					this.toggleButtonTop.disableToggle();
				}
				if (this.toggleButtonBottom) {
					this.toggleButtonBottom.disableToggle();
				}
			}).catch(error => {
				let errorMessage = error && error.message ? error.message : JSON.stringify(error);
				console.error(`Error saving Grid: ${errorMessage}`);
				this.saving = false;
				if (this.saveButtonTop) {
					this.saveButtonTop.disableToggle();
				}
				if (this.saveButtonBottom) {
					this.saveButtonBottom.disableToggle();
				}
				if (this.toggleButtonTop) {
					this.toggleButtonTop.disableToggle();
				}
				if (this.toggleButtonBottom) {
					this.toggleButtonBottom.disableToggle();
				}
			});
		}
	}

	/**
	 * Cleans unsaved values on cancel or save
	 * @param {string} currentMode The current mode when this was run.
	 */
	cleanRecords(currentMode) {
		if (this.props.tableSchemaName === 'page') {
			if (currentMode === 'edit') {
				// Find the records that this page is actually about, as we don't want to clear any others
				let records = RenderStore.getDescendantRecords(this.props.renderId, true, true);
				let recordState = RecordStore.getState();
				recordState.get('records').forEach((table, tableSchemaName) => {
					// Skip tables not included in this grid
					if(!records || !records[tableSchemaName]) {
						return;
					}
					table.forEach((record, recordId) => {
						// Skip records not included in this grid
						if(!records[tableSchemaName][recordId]) {
							return;
						}
						let dirtyFields = {};
						record.forEach(function(field, fieldSchemaName) {
							if (field.get('isDirty')) {
								if (field.get('inConflict')) {
									dirtyFields[fieldSchemaName] = field.get('conflictValue');
								} else {
									dirtyFields[fieldSchemaName] = field.get('originalValue');
								}
							}
						});
						if (Object.keys(dirtyFields).length) {
							RecordActions.updateRecord(tableSchemaName, recordId, dirtyFields);
						}
					});
				});
			}
		} else {
			let renderId = this.props.renderId ? this.props.renderId : '';
			if (renderId) {
				let renderObj = RenderStore.get(renderId);
				if (currentMode === 'edit' && renderObj) {
					let fields = FieldUtils.getAllRenderedChildren(renderId);
					let tableSchemaName = renderObj.dataTableSchemaName;
					let recordId = renderObj.dataRecordId;
					let record = RecordStore.getRecord(tableSchemaName, recordId);
					let dirtyFields = {};
					if(record) {
						fields.forEach((field) => {
							if (record[field.fieldSchemaName] && record[field.fieldSchemaName].isDirty) {
								if (record[field.fieldSchemaName].inConflict) {
									dirtyFields[field.fieldSchemaName] = record[field.fieldSchemaName].conflictValue;
								} else {
									dirtyFields[field.fieldSchemaName] = record[field.fieldSchemaName].originalValue;
								}
							}
						})
					}
					if (Object.keys(dirtyFields).length) {
						RecordActions.updateRecord(tableSchemaName, recordId, dirtyFields);
					}
				}
			}
		}
	}

	/**
	 * Handles the toggle click event
	 * 
	 * @param {any} event 
	 * @memberof GridComponent
	 */
	toggleClick(event) {
		event.preventDefault();
		if (this.saving) {
			return false;
		}

		let currentMode = this.props.tableSchemaName === 'page'
				? PageModeStore.getCurrentMode(this.props.renderId)
				: FieldModesStore.getMode(this.props.renderId);
		if(currentMode && currentMode.currentMode) {
			// FieldModesStore returns it in this format
			currentMode = currentMode.currentMode;
		}
		this.cleanRecords(currentMode);

		// setTimeout added because this would sometimes cause
		// dispatch in a dispatch issues due to component lifecycles
		setTimeout(() => {
			InterfaceActions.toggleCurrentMode(this.props.renderId);
		}, 0);
	}

	handleKeyUp(e) {
		if(e.key === 'Enter'){
			let {renderId} = this.props;
			if(this.props.tableSchemaName === 'page'){
				
				let pageRenderObj = RenderStore.get(renderId) || {};
				while(pageRenderObj && pageRenderObj.componentType && pageRenderObj.componentType !== 'page' && pageRenderObj.renderParentId) {
					pageRenderObj = RenderStore.get(pageRenderObj.renderParentId) || {};
				}
				let {dataRecordId: pageRecordId, dataTableSchemaName: pageTableSchemaName, componentId} = pageRenderObj || {};
				
				let pageId = this.props.recordId ? this.props.recordId : componentId;

				let onEnterUp = pageId ? PageStore.getAutomation(pageId, 'onEnterUp') : undefined;
	
				if(onEnterUp && onEnterUp.js){
					ActionProcessor.processAction({
						actionRecordId: pageId,
						actionTableSchemaName: this.props.tableSchemaName,
						hookId: 'onEnterUp',
						action: onEnterUp.js,
						recordId: pageRecordId,
						renderId: pageRenderObj.renderId,
						tableSchemaName: pageTableSchemaName
					}).catch(error => {
						console.warn('Page on Enter Up Action Processing Error: ' + (error.message ? error.message : error));
					});
				}
			} else {
				let fieldSettings = this.props.attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(this.props.attachmentId, this.props.recordId, this.props.parentRecordId) : FieldSettingsStore.getSettings(this.props.recordId, this.props.parentRecordId); // To find Automation Settings 
				
				//Get the preFieldSave Global:
				let saveField = fieldSettings['automation-onEnterUp'] ? fieldSettings['automation-onEnterUp'] : null,
					saveFieldObj = saveField ? ObjectUtils.getObjFromJSON(saveField) : null;
				
				if(saveFieldObj && saveFieldObj.js){
					ActionProcessor.processAction({
						actionRecordId: this.props.recordId,
						actionTableSchemaName: this.props.tableSchemaName,
						hookId: 'onEnterUp',
						action: saveFieldObj.js,
						renderId: renderId,
						recordId: this.props.recordId,
						tableSchemaName: this.props.tableSchemaName
					}).catch(error => {
						console.warn('Page on Enter Up Action Processing Error: ' + (error.message ? error.message : error));
					});
				}

			}
		}
	}

	/**
	 * Render the grid
	 *
	 * @returns React
	 *
	 * @memberOf GridComponent
	 */
	render() {
		let gridItems = [];
		let isGridEditable = this.state.isGridEditable;
		let visibilityOverlayIsActive = this.state.visibilityOverlayIsActive;
		let resizerIsActive = this.state.resizerIsActive;
		let recordId = this.props.recordId;
		let tableSchemaName = this.props.tableSchemaName;
		let screensizeManagementObj = ObjectUtils.getObjFromJSON(this.props.screensizeManagementJSON);
		let managingScreensize = 'lg';
		let gridLayoutHasOverflowChild = false;
		// let hasExpandingElement = false;
		let width = this.getWidth();
		let gridHeightPx = this.state.gridHeightPx;
		
		if(Object.keys(screensizeManagementObj).length) {
			managingScreensize = screensizeManagementObj[this.state.responsiveMode]
		}

		let rowHeight = 12;
		let {
			fieldPositions, 
			spacerHeight
		} = this.state;

		// Ensure page data isn't empty
		if (this.props.attachedFieldsJSON) {
			let attachedFieldsArr = this.state.attachedFieldsArr;
			let childLabelDataLayout = this.props.childLabelDataLayout;

			// We loop over the attached fields arr from the props in order to identify the field positioning
			// We do this because the attachedFieldsArr from the state doesn't include any ones which don't display
			if(Array.isArray(attachedFieldsArr)) {
				// Create key to uniquely identify looped elements
				let key = 0;

				attachedFieldsArr.forEach((attachedField) => {
					// Some fields may not always display, meaning they won't appear in attachedFieldsArr.
					// We need to use the order for the field in order to determine this, not the index in attachedFieldsArr
					let attachmentId = attachedField.attachmentId;
					let gridKey = attachmentId || attachedField.recordId;
					let fieldId = attachedField.recordId;
					let fieldObj = FieldStore.get(fieldId);
					let renderId = attachedField.renderId;
					let availableModes = attachedField.availableModes;
					let fieldIsExpanding = false;
					let fieldShouldAutofit = false;
					let gridInfo = attachedField && attachedField.gridInfo && attachedField.gridInfo[managingScreensize] ? attachedField.gridInfo[managingScreensize] : undefined;

					// Figure out if *this* field should expand.
					if(gridInfo && gridInfo.expands) {
						fieldIsExpanding = true;
						// hasExpandingElement = true;
					}

					if(gridInfo && gridInfo.autofit) {
						fieldShouldAutofit = true;
					}

					// Generate "Expand" Tool JSX
					let expandToolJSX = null;
					let autosizeToolJSX = null;
					let fixedHeightToolJSX = null;
					if(resizerIsActive) {
						expandToolJSX = <a 
							className={"citdev-vertical-expand-handle" + 
								(fieldIsExpanding ? ' expanded' : '')
							} 
							href="#"
							onClick={(e) => { 
								e.preventDefault(); 
								this.clickExpand(gridKey, this.props.fieldPositionExtrasJSON); 
							}}
							title='Expand this component to reach the bottom of the page.'
							>
							</a>
						autosizeToolJSX = <a 
							href="#"
							className={"citdev-vertical-fit-height-handle" + 
								(fieldShouldAutofit ? ' expanded' : '')
							} 
							onClick={(e) => { 
								e.preventDefault(); 
								this.clickAutoFit(gridKey, this.props.fieldPositionExtrasJSON); 
							}}
							title='Automatically expand this component vertically to fit contents.'
							>
							</a>
						fixedHeightToolJSX = <a 
							href="#"
							className={"citdev-vertical-fixed-height-handle" + 
								(!fieldShouldAutofit && !fieldIsExpanding ? ' expanded' : '')
							} 
							onClick={(e) => { 
								e.preventDefault(); 
								this.clickFixedHeight(gridKey, this.props.fieldPositionExtrasJSON); 
							}}
							title='Show this item as a fixed height regardless of the content size or page height.'
							>
							</a>
					}

					if(fieldObj) {
						let fieldSchemaName = fieldObj.fieldSchemaName;
						let id = "grid-item-" + this.props.renderId + '-' + key++ ;
						let gridItemContentShouldOverflow = false;
						let fieldType = fieldObj.fieldType;
						if (fieldType === '6e5b3bca-88bc-4db8-bd5d-531890660e49'){ // Color Picker
								gridItemContentShouldOverflow = true;
								gridLayoutHasOverflowChild = true;
						}
						let {currentMode} = this.state;
						let variantMode = currentMode === 'view' ? 'view' : 'edit';
						let variantName = currentMode === 'view' ? fieldObj.viewVariant : fieldObj.editVariant;
						variantName = variantName || FieldStore.getDefaultVariantComponentName(fieldId, variantMode, fieldObj.fieldType) || FieldStore.getDefaultVariantComponentName(fieldId, 'view', fieldObj.fieldType);
						let fieldTypeObj = fieldObj ? FieldTypeStore.get(fieldObj.fieldType) : {};
						let variantObj = fieldTypeObj && fieldTypeObj.variants ? (fieldTypeObj.variants.find(variant => variant.reactComponentName === variantName) || fieldTypeObj.variants.find(variant => variant.default && variant.type === variantMode)) || fieldTypeObj.variants[0] : undefined;
						let forceHeight = variantObj ? variantObj.forceHeight === '100%' : false;
						let pageRecord = this.state.pageId ? PageStore.get(this.state.pageId) : '';
						let saveControlsPlacement = pageRecord && pageRecord.saveControlPlacement ? pageRecord.saveControlPlacement : '';

						let saveControlsVisibleTop = this.state.availableModes && this.state.availableModes.includes('edit') ? saveControlsPlacement !== 'bottom' && saveControlsPlacement !== 'hidden' && this.props.showSaveControls && this.props.showSaveControls === 'yes' : false;

						// // Respect the override
						if(window.citDev.fields && window.citDev.fields.components && window.citDev.fields.components[variantName] && typeof window.citDev.fields.components[variantName].isFullHeight !== 'undefined') {
							forceHeight = window.citDev.fields.components[variantName].isFullHeight;
						}

						// Display only if the field has any available modes or if visibility is active
						if((availableModes && availableModes.size) || visibilityOverlayIsActive || resizerIsActive) {
							// Try and find the renderId for our child field.
							if(gridInfo) {
								let resizerHeightType = 'fixed';
								// If we have a gridHeight in the render ID, we need to pull that.
								if(fieldIsExpanding && !resizerIsActive) {
									resizerHeightType = 'expand';
								}

								if(fieldShouldAutofit) {
									resizerHeightType = 'fit';
								}

								if(resizerIsActive) {
									// Get the height and width of the field container based off of the calculated height and stuff
									if(fieldShouldAutofit) {
										gridItems.push(
											<GridItem gridInfo={gridInfo} measureSize={true} fieldId={fieldId} fieldShouldAutofit={fieldShouldAutofit} key={gridKey} id={id} contentShouldOverflow={gridItemContentShouldOverflow} renderId={renderId} responsiveMode={this.state.responsiveMode} currentMode={this.state.currentMode} resizerIsActive={resizerIsActive}>
												<FieldContainer
													resizerHeightType='fit'
													omitSpacer={gridInfo.h !== gridInfo.originalH}
													attachmentId={attachmentId}
													contentShouldOverflow={gridItemContentShouldOverflow}
													dataRecordId={this.props.dataRecordId}
													dataTableSchemaName={this.props.dataTableSchemaName}
													fieldId={fieldId}
													fieldSchemaName={fieldSchemaName}
													key={gridKey + '-container'}
													gridKey={key}
													labelDataLayout={childLabelDataLayout}
													parentRecordId={recordId}
													parentTableSchemaName={tableSchemaName}
													renderId={renderId}
													renderParentId={this.props.renderId}
													resizerIsActive={resizerIsActive}
													validationLastFailed={this.props.validationLastFailed}
													visibilityOverlayIsActive={visibilityOverlayIsActive}
													height={gridInfo.h * rowHeight}
													width={Math.floor(gridInfo.w * ((width || 1) / 12))}
												/>
												{autosizeToolJSX}
												{fixedHeightToolJSX}
												{expandToolJSX}
											</GridItem>
										);
									} else {
										gridItems.push(
											<GridItem gridInfo={gridInfo} fieldId={fieldId} fieldShouldAutofit={fieldShouldAutofit} key={gridKey} id={id} contentShouldOverflow={gridItemContentShouldOverflow} renderId={renderId} responsiveMode={this.state.responsiveMode} currentMode={this.state.currentMode} resizerIsActive={resizerIsActive}>
												<FieldContainer
													resizerHeightType={resizerHeightType}
													attachmentId={attachmentId}
													contentShouldOverflow={gridItemContentShouldOverflow}
													dataRecordId={this.props.dataRecordId}
													dataTableSchemaName={this.props.dataTableSchemaName}
													fieldId={fieldId}
													fieldSchemaName={fieldSchemaName}
													gridKey={key}
													key={gridKey + '-container'}
													labelDataLayout={childLabelDataLayout}
													parentRecordId={recordId}
													parentTableSchemaName={tableSchemaName}
													renderId={renderId}
													renderParentId={this.props.renderId}
													resizerIsActive={resizerIsActive}
													validationLastFailed={this.props.validationLastFailed}
													visibilityOverlayIsActive={visibilityOverlayIsActive}
													height={gridInfo.h * rowHeight}
													width={Math.floor(gridInfo.w * ((width || 1) / 12))}
												/>
												{autosizeToolJSX}
												{fixedHeightToolJSX}
												{expandToolJSX}
											</GridItem>
										);
									}
								} else {
									gridItems.push(<GridItemView forceHeight={forceHeight} {...gridInfo} resizerHeightType={resizerHeightType} managingScreensize={managingScreensize} measureSize={fieldShouldAutofit} renderId={renderId} key={gridKey} id={id} saveControlsVisibleTop={saveControlsVisibleTop}>
										<FieldContainer
											resizerHeightType={resizerHeightType}
											attachmentId={attachmentId}
											contentShouldOverflow={gridItemContentShouldOverflow}
											dataRecordId={this.props.dataRecordId}
											dataTableSchemaName={this.props.dataTableSchemaName}
											fieldId={fieldId}
											fieldSchemaName={fieldSchemaName}
											gridKey={key}
											key={gridKey + '-container'}
											labelDataLayout={childLabelDataLayout}
											parentRecordId={recordId}
											parentTableSchemaName={tableSchemaName}
											renderId={renderId}
											renderParentId={this.props.renderId}
											resizerIsActive={resizerIsActive}
											validationLastFailed={this.props.validationLastFailed}
											visibilityOverlayIsActive={visibilityOverlayIsActive}
											height={gridInfo.h * rowHeight}
											width={Math.floor(gridInfo.w * ((width || 1) / 12))}
										/>
									</GridItemView>)
								}
							} else {
								let parentTitle = BlockUtils.getName(recordId, tableSchemaName)
								console.warn('Could not find field ' + fieldSchemaName + ' (' + fieldId + ') in positioning info for ' + tableSchemaName + ' "' + parentTitle + '" (attachment ID "' + attachmentId + '").  Please detach and re-attach to this container.');
							}
						}
					}
				});
			}
		}

		// let appearancePin = null;
		// let automationPin = null;
		// Determine label of button based on view or edit mode
		let currentMode = this.state.currentMode;
		let buttonClass = 'col-12 text-right ';
		let pageRecord = this.state.pageId ? PageStore.get(this.state.pageId) : '';
		let addButtonText = (pageRecord && pageRecord.addButtonText) ? pageRecord.addButtonText : 'Add';
		let editButtonText = (pageRecord && pageRecord.editButtonText) ? pageRecord.editButtonText : 'Edit';
		let saveButtonText = (pageRecord && pageRecord.saveButtonText) ? pageRecord.saveButtonText : 'Save';
		let pins = null;

		let saveControlsTop = null;
		let saveControlsBottom = null;
		let saveControlsPlacement = pageRecord && pageRecord.saveControlPlacement ? pageRecord.saveControlPlacement : '';
		let saveControlsPinAdjustment = false;
		
		if (this.props.showSaveControls && this.props.showSaveControls === 'yes') {
			if (saveControlsPlacement !== 'bottom' && saveControlsPlacement !== 'hidden') {
				saveControlsPinAdjustment = true;
			}
		}

		//Setup the pins for the page
		if (this.props.tableSchemaName === 'page') {
			pins = <PinsContainer
					attachmentId={this.props.attachmentId}
					recordId={recordId}
					renderId={this.props.renderId}
					tableSchemaName={tableSchemaName}
					parentRecordId=""
					parentTableSchemaName=""
					gridKey=""
					fieldType=""
					saveControlsPinAdjustment={saveControlsPinAdjustment}
				/>
		}
		if (this.props.showSaveControls && this.props.showSaveControls === 'yes') {
			if (saveControlsPlacement !== 'bottom' && saveControlsPlacement !== 'hidden') {
				let saveButton = null;
				if (currentMode !== 'view' && currentMode) {
					saveButton = <SaveButton 
						buttonText={currentMode === 'add' ? addButtonText : saveButtonText}
						onSave={this.saveGrid}
						ref={(saveButtonRef) => {this.saveButtonTop = saveButtonRef}} />
				}
				let toggleModeButton = null;
				if(currentMode && this.state.availableModes.includes('edit'))
				{
					if (currentMode !== 'add' && this.state.availableModes.includes('view')) {
						toggleModeButton = <ToggleModeButton onToggle={this.toggleClick} 
							ref={(toggleButtonRef) => {this.toggleButtonTop = toggleButtonRef}}
							buttonText={currentMode === 'view' ? editButtonText : 'Cancel'} />
					} else if (currentMode !== 'edit') {
						toggleModeButton = <ToggleModeButton onToggle={this.toggleClick} 
							ref={(toggleButtonRef) => {this.toggleButtonTop = toggleButtonRef}}
							buttonText={editButtonText} />
					}
				}
				
				saveControlsTop = <div id={this.props.renderId + '-top'} className="container-fluid save-controls">
						<div className='row'>
							<div className={buttonClass}>
								<div className="container-fluid">
									<div className="pinContainer page" style={{}}>
										{pins}
										{saveButton}
										{toggleModeButton}
									</div>
								</div>
							</div>
						</div>
					</div>
			}

			if ((saveControlsPlacement === 'bottom' || saveControlsPlacement === 'both')) {
				let saveButton = null;
				if (currentMode !== 'view') {
					saveButton = <SaveButton 
						buttonText={currentMode === 'add' ? addButtonText : saveButtonText}
						onSave={this.saveGrid}
						ref={(saveButtonRef) => {this.saveButtonBottom = saveButtonRef}} />
				}

				let toggleModeButton = null;
				if(currentMode && this.state.availableModes.includes('edit')) {
					if (currentMode !== 'add' && this.state.availableModes.includes('view')) {
						toggleModeButton = <ToggleModeButton onToggle={this.toggleClick} 
							ref={(toggleButtonRef) => {this.toggleButtonBottom = toggleButtonRef}}
							buttonText={currentMode === 'view' ? editButtonText : 'Cancel'} />
					} else if (currentMode !== 'edit') {
						toggleModeButton = <ToggleModeButton onToggle={this.toggleClick} 
							ref={(toggleButtonRef) => {this.toggleButtonBottom = toggleButtonRef}}
							buttonText={editButtonText} />
					}
				}
				
				saveControlsBottom = <div id={this.props.renderId + '-bottom'} className="container-fluid save-controls pr-4">
						<div className='row'>
							<div className={buttonClass}>
								<div className="container-fluid">
									<div className="pinContainer page" style={{}}>
										{pins}
										{saveButton}
										{toggleModeButton}
									</div>
								</div>
							</div>
						</div>
					</div>
			}
		}

		
		let gridLayout = null;
		let gridLayoutClass = 'layout';
		if(gridLayoutHasOverflowChild) {
			gridLayoutClass += ' hasOverflowChild';
		}

		if(gridItems.length) {
			let spacerJSX = null;

			// We only want to put in the spacer if the resizer is active
			// Otherwise, the grid handles it in its own way
			if(resizerIsActive && spacerHeight && spacerHeight > 0) {
					// Create the spacer
					spacerJSX = <div className={'citdev-spacer ' + this.props.recordId} key={'spacer' + this.props.renderId} id={'spacer' + this.props.renderId} style={{
						// height: spacerHeight + 'px',
						width:'1px',
						flex: '0 1 auto',
						id: 'spacer' + this.props.renderId,
						// width: '100%',
						// backgroundColor: 'red',
						// overflowY: 'scroll'
					}}></div>
			}

			if(process.env.CITDEV_ENV === 'development') {
				if(resizerIsActive) {

					gridLayout = ([
						<div key="grid" style={{ height: gridHeightPx, flex: '1 1 auto' }}>
						<ResponsiveGridLayout
							autoSize={false}
							key="gridLayout"
							className={gridLayoutClass}
							rowHeight={12}
							compactType={null}
							margin={[0, 0]}
							layouts={{[managingScreensize]: fieldPositions}}
							breakpoints={{ lg: 991, md: 767, sm: 575 }}
							cols={{lg: 12, md: 12, sm: 12, xs: 12, xxs: 12}}
							width={width}
							useCSSTransforms={false}
							isDraggable={isGridEditable && (this.state.isDraggable !== false)}
							isResizable={isGridEditable}
							onLayoutChange={isGridEditable ? this.onFieldPositionChange : undefined}
							resizeHandles={['se']}
							measureBeforeMount={true}
							breakpoint={managingScreensize}
							preventCollision={false}
							// resizeHandles={availableHandles}
						>
							{gridItems}
						</ResponsiveGridLayout></div>,
						spacerJSX]);
				} else {
					gridLayout = ([
						// Temp overriding hasExpanding
						<GridLayoutView key='gridLayout' measureSize={this.state.hasExpanding} renderId={this.props.renderId} managingScreensize={managingScreensize}>
							{gridItems}
						</GridLayoutView>,
						spacerJSX
					]);
				}
				
			} else {
				gridLayout = ([
					<GridLayoutView key='gridLayout' measureSize={this.state.hasExpanding} renderId={this.props.renderId} managingScreensize={managingScreensize}>
						{gridItems}
					</GridLayoutView>,
					spacerJSX
				]);
			}
		}
		
		/**
		 * Because of the CSS for the pins being fixed instead of absolute the style needed a transform to make
		 * it a containing element for fixed position elements.  It needed to be float right to cause the save and 
		 * cancel buttons to be to the right of the row it is contained in
		 */
		let classNames = "grid-component";
		if(tableSchemaName === 'page' && this.props.isDialog !== true) {
			classNames += " bg-panel page"
		}
		return (
			<div className={classNames} onKeyUp={this.handleKeyUp}>
				{saveControlsTop}
				{gridLayout}
				{saveControlsBottom}
			</div>
		);
	}

}

GridComponent.displayName = 'GridComponent';


const SubContainer = Container.create(GridComponent, { withProps: true });

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(SubContainer, { withProps: true });

// Refresh rate has been increased to avoid jankiness with scrollbars when calculating component height for autofit grid components
export default container;