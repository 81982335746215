import { Record } from 'immutable';

/**
 * List of Words to Ignore in the Searchable property of Dictionary Records 
 */
const wordsToIgnore = ['a', 'to', 'the']; 

/**
 * 
 * Reusable Record for all Dictionaries. 
 * It ensures the objects to add contain the same properties
 * Properties to Include: id, label, searchable
 */
let DictionaryRecord = new Record({
	id: null,
	label: null,
	searchable: null
});

DictionaryRecord.wordsToIgnore = wordsToIgnore;
DictionaryRecord.DictionaryRecord = DictionaryRecord;
export {
	DictionaryRecord as default,
	DictionaryRecord,
	wordsToIgnore
};