const CryptoJS = require('crypto-js');

/**
 * Secret Utils
 * 
 * @export
 * @class SecretUtils
 */
export default class SecretUtils {
	/**
	 * Encrypt a string and return the encrypted version
	 * @param {string} string String to encrypt
	 * @returns string
	 */
	static encryptString(string) {
		const passphrase = 'EeQbwuJrRHCsZjYb7De1bKHX6o4J9DFk';
  		return CryptoJS.AES.encrypt(string, passphrase).toString();
	}

	/**
	 * Decrypt a string encrypted by encryptString, and return the original.
	 * @param {string} encryptedString 
	 * @returns string
	 */
	static decryptString(encryptedString) {
		const passphrase = 'EeQbwuJrRHCsZjYb7De1bKHX6o4J9DFk';
		const bytes = CryptoJS.AES.decrypt(encryptedString, passphrase);
		const originalText = bytes.toString(CryptoJS.enc.Utf8);
		return originalText;
	}
}