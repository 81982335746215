/* global citDev */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'flux/utils';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import AssistantResultsStore from '../../../stores/assistant-results-store';
import ContextStore from '../../../stores/context-store';
import FieldSettingsStore from '../../../stores/field-settings-store';
import FieldStore from '../../../stores/field-store';
import FieldTypeStore from '../../../stores/field-type-store';
import PageStore from '../../../stores/page-store';
import PatternStore from '../../../stores/pattern-store';
import TableStore from '../../../stores/table-store';

// Utils
import FieldUtils from '../../../utils/field-utils';
import PageUtils from '../../../utils/page-utils';
import UIUtils from '../../../utils/ui-utils';

// Components
import PopoverHeader from './header';

class QueryPinPover extends Component {
	constructor(props) {
		super(props);
		this.getSettingsList = this.getSettingsList.bind(this);
		this.onSettingClick = this.onSettingClick.bind(this);
	}

	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @returns { array } array of <li> settings elements
	 */
	getSettingsList(settingsArr) {

		let { fieldType } = this.state;

		// iterate over the settings and return an array of list items
		return settingsArr.map((setting, index) => {
			let labelClassNames = 'setting-label';
			if(setting.settingSelected) { 
				labelClassNames += ' selected';
			}
			/*************************
				The following commented out statements are for the setting-icon, which is not currently in use
				Uncomment, when setting-icon is used again
			**************************/

			// let iconClassNames = 'fa';
			
			// switch(setting.quality) {
			// 	case 'custom':
			// 		iconClassNames += ' custom fa-check-square-o';
			// 	break;
			// 	default:
			// 	case 'low':
			// 		iconClassNames += ' high fa-check-circle-o';
			// 	break;
			// 	case 'high':
			// 		iconClassNames += ' low fa-exclamation-triangle';
			// 	break;
			// }

			let componentName = FieldStore.getDefaultVariantComponentName(
				setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
			let componentProps = setting.fieldObj;
			componentProps.value = setting.valueObj.value;
			componentProps.fieldType = fieldType;

			// These props will only be used by the fake table setting
			componentProps.customExpressionResult = setting.customExpressionResult;
			componentProps.renderAsHTML = setting.renderAsHTML;

			return (<li key={index} className={"nav-item d-flex flex-column justify-content-center " + (setting.settingSelected ? 'setting-selected' : '')}>
				<div className="nav-link" onClick={this.onSettingClick.bind(this, setting.fieldId)}>
					{/* Temporarily removed the following div */}
					{/* <div className={"setting-icon " + iconClassNames} /> */}
					{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
					{/* Add this back in when the setting-icon is used again */}
					<div className="d-flex setting-text-container">
						<div className="w-50 setting-label-container">
							<div className={labelClassNames}><h4>{setting.label}</h4></div>
							<div className="setting-pattern-list">{setting.mostRecentPattern}</div>
						</div>
						<div className="w-50 d-flex justify-content-end setting-value-container">
							<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
								<h5 className={`settings-list-value ${componentName}`}>
									{ (componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null) }
								</h5>
							</div>
							{ setting.valueObj.source ? 
								<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}> 
									{ setting.valueObj.source } 
								</div>
							: null
							}
						</div>
					</div>
				</div>
			</li>);
		});
	}
/**
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf AppearancePin
	 */
	static getStores() {
		return [AdminSettingsStore, ContextStore, FieldSettingsStore, FieldStore, FieldTypeStore, PageStore, PatternStore, TableStore];
	}
	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 * @memberof SettingsPanel
	 */
	onSettingClick(settingFieldId, event) {
		event.preventDefault();

		if(!settingFieldId) {
			return;
		}

		let settingFieldObj = FieldStore.get(settingFieldId);
		let settingSchemaName = settingFieldObj.fieldSchemaName;

		// Open Settings tab under right-panel
		AdminSettingsActions.onSettingsTabChangeMain('settings');
		// Open Query settings panel
		AdminSettingsActions.onQuerySettingChange(settingSchemaName);

		UIUtils.openSettingsPanel('query', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName,
			'settings', undefined, undefined, this.props.renderId, this.props.attachmentId);

		//Toggle to select and deselect the Setting
		AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
	}
	/**
	 * @static
	 * @param {any} prevState 
	 * @param {any} props 
	 * @returns 
	 * @memberof QueryPinPover
	 */
	static calculateState(prevState, props) {
		let { recordId, parentRecordId, tableSchemaName, parentTableSchemaName, attachmentId } = props;
		let componentSettings = [];
		let parentSettings = [];
		let settingsSource = attachmentId ? FieldSettingsStore.getSettingsFromAttachmentIdWithSource(attachmentId, recordId, parentRecordId) : FieldSettingsStore.getSettingsWithSource(recordId, parentRecordId);
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let tableSettingLabel = '';
		let tableSettingValueLabel = '';
		let tableSettingValueIcon = '';
		let fieldType = '';
		let componentHeader = '';

		let parentFieldObj = FieldStore.get(parentRecordId) || {};
		let parentFieldTypeObj = FieldTypeStore.get(parentFieldObj.fieldType) || {};

		if(tableSchemaName === 'field') {
			let fieldId = recordId;
			let fieldObj = FieldStore.get(fieldId) || {};
			fieldType = fieldObj.fieldType;
			tableSettingLabel = 'Field\'s Table';
			let tableObj = TableStore.getByTableSchemaName(fieldObj.tableSchemaName);
			if(tableObj) {
				tableSettingValueIcon = tableObj.icon;
				tableSettingValueLabel = tableObj.singularName;
			}

			let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType) || {};
			// set component header
			componentHeader = fieldTypeObj && fieldTypeObj.name + " Settings";

			// set parent header
			// parentHeader = (parentFieldTypeObj && parentFieldTypeObj.name) 
			// 			 ? parentFieldTypeObj.name + " Attachment Settings" 
			// 			 : parentRecordId ? "Page Attachment Settings" : '';

			// Get the field's direct settings
			let fieldTypeSettings = fieldTypeObj.settings || undefined;

			// Get the field's parent's child-settings.
			let parentSettingsArr = [];
			if(parentFieldTypeObj && parentRecordId && parentTableSchemaName){
				switch (parentTableSchemaName) {
					case 'page':{
						parentSettingsArr = PageUtils.getChildSettings();
						break;
					}
					case 'field': {
						parentSettingsArr = FieldUtils.getChildSettings(parentRecordId);
						break;
					}
					default: 
						console.warn('Unsupported Parent Table Schema Name:', parentTableSchemaName);
						break;
				}
			} else {
				parentSettingsArr = FieldUtils.getDefaultSettings();
			}

			if (fieldTypeSettings) {
				fieldTypeSettings.forEach(fieldTypeSetting => {
					let settingId = fieldTypeSetting.recordId;
					let settingObj = FieldStore.get(settingId) || {};

					// If this is NOT a query or relationship selector setting, then jump out of the loop.
					if(settingObj.fieldType !== '45b6601f-02e8-4dcd-acce-9815d094f009' &&
					   settingObj.fieldType !== 'ce0dbfec-e9d9-4dc3-b39a-456eca2b5282') {
							return false;
					}

					let settingSource = settingsSource[settingObj.fieldSchemaName] || {};
					let historyObj = FieldUtils.getSettingHistoryDetails(settingId, fieldId, parentRecordId, parentTableSchemaName);

					// Empty/No Value values.
					let valueObj = {};

					// Check whether value for setting is present
					if(settingSource.value || (!settingSource.value && settingSource.source === 'local')){
						valueObj.value = settingSource.value;
						
						// Work with Value Source
						if(settingSource.source !== 'global') {
							valueObj.source = 'O';
							valueObj.badgeTypeClass = 'badge-secondary';
						}
					}

					componentSettings.push({
						// Basics
						fieldId: settingId,
						fieldObj: settingObj,

						// Label, Value and Sorting..
						label: settingObj.fieldLabel,
						valueObj: valueObj,
						sortOrder: historyObj.order,

						// Used for styling
						quality: historyObj.valueQuality, 
						settingSelected: (selectedSetting === settingObj.fieldSchemaName)
					});
				});
			}
			// Manually adding Field's List to the Component Settings List, adding the following object
			if(tableSettingValueLabel) {
				let valueHTML = tableSettingValueLabel;
				if(tableSettingValueIcon) {
					valueHTML = '<span class="mx-1 align-self-center fa fa-' + tableSettingValueIcon + '"></span> ' + tableSettingValueLabel;
				}
				componentSettings.push({
					fieldObj: {
						// This is an Expression Field
						fieldTypeId: '94dc3a69-8f25-420f-9e36-d0ef19ce7c45'
					},
					valueObj: {
						value: valueHTML
					},
					label: tableSettingLabel,
					customExpressionResult: valueHTML,
					renderAsHTML: 'true',
					sortOrder: 0
				});
			}

			if (parentSettingsArr && parentSettingsArr.length) {
				parentSettingsArr.forEach(fieldTypeSetting => {
					let settingId = fieldTypeSetting.recordId;
					let settingObj = FieldStore.get(settingId) || {};
					let settingSource = settingsSource[settingObj.fieldSchemaName] || {};
					let historyObj = FieldUtils.getSettingHistoryDetails(settingId, fieldId, parentRecordId, parentTableSchemaName);

					// Empty/No Value values.
					let valueObj = {};

					// Check whether value for setting is present
					if(settingSource.value || (!settingSource.value && settingSource.source === 'local')){
						valueObj.value = settingSource.value;

						// Work with Value Source
						if(settingSource.source !== 'global') {
							valueObj.source = 'O';
							valueObj.badgeTypeClass = 'badge-secondary';
						}
					}

					// If this IS not a query and a relationship selector setting, then jump out of the loop.
					if(settingObj.fieldType !== '45b6601f-02e8-4dcd-acce-9815d094f009' &&
					   settingObj.fieldType !== 'ce0dbfec-e9d9-4dc3-b39a-456eca2b5282') {
							return false;
					}

					parentSettings.push({
						// Basics
						fieldId: settingId,
						fieldObj: settingObj,

						// Label, Value and Sorting..
						label: settingObj.fieldLabel,
						valueObj: valueObj,
						sortOrder: historyObj.order,

						// Used for styling
						quality: historyObj.valueQuality, 
						settingSelected: (selectedSetting === settingObj.fieldSchemaName)
					});
				});
			}
		} else if (tableSchemaName === 'page') {
			let pageId = recordId;
			let pageObj = PageStore.get(pageId);
			let pageSettings = PageUtils.getPageSettings();
			componentHeader = 'Page Settings';

			pageSettings.forEach(pageSetting => {
				let settingId = pageSetting.recordId;
				let settingObj = FieldStore.get(settingId) || {};
				let settingsValue = pageObj[settingObj.fieldSchemaName];
				
				// If this is NOT a query setting, then jump out of the loop.
				if(settingObj.fieldType !== '45b6601f-02e8-4dcd-acce-9815d094f009') {
					return false;
				}

				// Empty/No Value values.
				let valueObj = {};

				let settingSource = settingsSource[settingObj.fieldSchemaName] || {};

				// Check whether value for setting is present
				if(settingSource.value || (!settingSource.value && settingSource.source === 'local')){
					valueObj.value = settingsValue;
					if(settingSource.source !== 'global') {
						valueObj.source = 'O';
						valueObj.badgeTypeClass = 'badge-secondary';
					}
				}

				componentSettings.push({
					// Basics
					fieldId: settingId,
					fieldObj: settingObj,

					// Label, Value and Sorting..
					label: settingObj.fieldLabel,
					valueObj: valueObj,
					sortOrder: 0,

					// Used for styling
					settingSelected: (selectedSetting === settingObj.fieldSchemaName)
				});
			});

			let pagesTable = pageObj.tableSchemaName;
			let tableObj = TableStore.getByTableSchemaName(pagesTable);
			if(tableObj) {
				tableSettingValueIcon = tableObj.icon;
				tableSettingValueLabel = tableObj.singularName;
			}

			let valueHTML = tableSettingValueLabel;
			if(tableSettingValueIcon) {
				valueHTML = '<span class="mx-1 align-self-center fa fa-' + tableSettingValueIcon + '"></span> ' + tableSettingValueLabel;
			}
			componentSettings.push({
				fieldObj: {
					// This is an Expression Field
					fieldTypeId: '94dc3a69-8f25-420f-9e36-d0ef19ce7c45'
				},
				valueObj: {
					value: valueHTML
				},
				label: 'Page\'s Table',
				customExpressionResult: valueHTML,
				renderAsHTML: 'true',
				sortOrder: 0
			});
		}

		// Sort the results by setting name
		componentSettings.sort(function(a,b){
			if(a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if(a.label.toLowerCase() < b.label.toLowerCase()) { 
				return -1;
			}
			return 1;
		});

		// Sort the parent results by setting name
		parentSettings.sort(function(a,b){
			if(a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if(a.label.toLowerCase() < b.label.toLowerCase()) { 
				return -1;
			}
			return 1;
		});

		return {
			fieldType: fieldType,
			componentSettings: componentSettings,
			parentSettings: parentSettings,
			componentHeader: componentHeader,
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			parentRecordId: parentRecordId,
			parentTableSchemaName: parentTableSchemaName,
			tableSettingLabel: tableSettingLabel,
			tableSettingValueIcon: tableSettingValueIcon,
			tableSettingValueLabel: tableSettingValueLabel,
			searchResults: AssistantResultsStore.getAllArray(),
		}
	}
	render() {
		let {componentSettings, parentSettings, parentRecordId, 
			parentTableSchemaName, componentHeader, recordId, tableSchemaName} = this.state;

		let settingsList = this.getSettingsList(componentSettings); // component settings
		let parentSettingsList = this.getSettingsList(parentSettings); // parent settings
		
		let componentIcon = (tableSchemaName === 'field' ? FieldUtils.getFieldTypeIcon(recordId) : FieldUtils.getFieldTypeIcon('page'));
		let parentHeaderIcon = '';
		if(parentRecordId && parentTableSchemaName){
			switch (parentTableSchemaName) {
				case 'page':{
					// Setup the parent header icon, from page
					parentHeaderIcon = FieldUtils.getFieldTypeIcon('page');
					break;
				}
				case 'field': {
					// Setup the parent header icon, from Field
					parentHeaderIcon = FieldUtils.getFieldTypeIcon(parentRecordId);
					break;
				}
				default: 
					console.warn('Unsupported Parent Table Schema Name:', parentTableSchemaName);
					break;
			}
		} else {
			parentHeaderIcon = FieldUtils.getFieldTypeIconByFieldTypeId('page');
		}
		
		return (
			<div id="popover-content" className="query-popover cd-tools">
				<PopoverHeader 
					recordId={this.props.recordId} 
					tableSchemaName={this.props.tableSchemaName} 
					iconBase="database" 
					overlayName="Records"/>
				<div className="tab-content row">
					<div className="col-12">
						<ul id="popover-settings" className="nav flex-column">
							{settingsList && settingsList.length
								? <li className="settings-list-header">
									<div className="d-flex">
										<img style={{ marginTop: '2px' }} height="22" width="22" className="mr-2" src={componentIcon} alt="" />
										<h3>{componentHeader}</h3>
									</div>
								</li>
								: null
							}
							{settingsList && settingsList.length
								? settingsList
								: null
							}
							{parentSettingsList && parentSettingsList.length
								? <li className="settings-list-header field-settings-list-header">
									<div className="d-flex">
										<img height="22" width="22" className="mr-2" src={parentHeaderIcon} alt="" />
										<h3>Parent Settings</h3>
									</div>
									</li>
								: null
							}
							{parentSettingsList}
						</ul>
					</div>
				</div>
			</div>
		)
	}
}

if ('development' === process.env.NODE_ENV) {
	QueryPinPover.propTypes = {
	  recordId: PropTypes.string.isRequired,
	  tableSchemaName: PropTypes.string.isRequired,
	  parentRecordId: PropTypes.string,
	  parentTableSchemaName: PropTypes.string,
	};
  }
  
  //Use the Flux Container to wire up the watch for the stores
  const container = Container.create(QueryPinPover, {withProps: true});
  export default container;