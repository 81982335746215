import React, {Component} from 'react';
import {Container} from 'flux/utils';

import ComplianceStore from '../../../stores/compliance-store';
import InterfaceActions from '../../../actions/interface-actions';
import AdminSettingsActions from '../../../actions/admin-settings-actions';
import ComplianceActions from '../../../actions/compliance-actions';
import ComplianceSettingsUI from './compliance-settings-ui.react';


/**
 * Container for PageDialog
 *
 * @class ComplianceModalContainer
 * @extends {Component}
 */
class ComplianceModalContainer extends Component {
  /**
   * Creates instance of ComplianceModalContainer
   *
   * @memberOf ComplianceModalContainer
   */
  constructor(props) {
    super(props);
    this.state = {isSettings: true};
    this._close = this._close.bind(this);
    this._onChange = this._onChange.bind(this);
    this._triggerResize = this._triggerResize.bind(this);
  }

  /**
   * Loads the Stores to watch
   *
   * @static
   * @returns {Array of Object}
   *
   * @memberOf ComplianceModalContainer
   */
  static getStores() {
    return [ComplianceStore];
  }

  /**
   * Returns the current State of the ComplianceModalContainer
   *
   * @static
   * @returns {Object}
   *
   * @memberOf ComplianceModalContainer
   */
  static calculateState() {
	let compliances = ComplianceStore.getAllArray();

    return {
	  'compliances': compliances
    }
  }

  /**
   * Handles event when component will update
   */
  componentWillUpdate(nextProps, nextState) {
    if (!nextState.complianceId && this.state.complianceId) {
      setTimeout(AdminSettingsActions.closeModal.bind(null, this.state.complianceId), 0);
    }
  }

  /**
   * Handles event when component will unmount
   */
  componentWillUnmount() {
    setTimeout(this._close, 0);
  }
  /**
   * Render methos for ComplianceModalContainer
   *
   * @memberOf ComplianceModalContainer
   */
	render() {
		let dialogClassName = 'modal-dialog modal-lg';
		let compliances = this.state.compliances || [];
    let { disabledRetailRestrictions, onUpgradeClick } = this.props;


		return (
			<div className={dialogClassName + " site-map-modal compliance-map-modal"}>
				<div className="modal-content container">
					<div className="modal-header justify-content-end">
						<h1 className="modal-title align-self-center mr-auto">Add Compliance Assistance Tool</h1>
						<button type="button" className="close float-right m-0 align-self-center p-0" data-dismiss="modal" aria-label="Close" onClick={this._close}>&times;</button>
					</div>
					<div style={{ overflowY: 'auto' }} className="modal-body">
						<ComplianceSettingsUI 
              disabledRetailRestrictions={disabledRetailRestrictions} 
              onUpgradeClick={onUpgradeClick} 
              compliances={compliances} 
              onChange={this._onChange} 
            />
					</div>
				</div>
			</div>
		);
	}

  /**
   * private Handles compliance settings close
   *
   * @params {Object} event
   *
   * @memberOf ComplianceModalContainer
   */
  _close() {
    AdminSettingsActions.closeModal(this.props.key);
  }

  /**
   * private Handles compliance changes
   *
   * @params {Object} event
   *
   * @memberOf ComplianceModalContainer
   */
  _onChange(compliances) {
    if(compliances) {
		let updateNotif = InterfaceActions.stickyNotification({message: '(De)activating new compliance. Please wait...', level: 'info'});
		// Get active compliances
		let activeCompliances = [];
		
		compliances.forEach(complianceObj => {
			ComplianceActions.pushToStore(complianceObj.recordId, complianceObj);
			if(complianceObj.isActive === 1) {
				activeCompliances.push(complianceObj.recordId);
			}
		});
		ComplianceActions.pushToDatabasePromise(activeCompliances)
			.then(() => {
				InterfaceActions.clearStickyNotification(updateNotif);
			})
			.catch((err) => {
				InterfaceActions.clearStickyNotification(updateNotif);
				InterfaceActions.notification({message: 'There was an error (de)activating this compliance. Please check your console for more information.', level: 'error'});
				console.error('Error when (de)activating compliance', err);
			});
	}
  }

  /**
   * private Handles compliance settings changes
   *
   * @params {Object} event
   *
   * @memberOf ComplianceModalContainer
   */
  _onSettingsSubmit(event) {
    // @TODO
  }

  /**
   * private Handles field menu click
   *
   * @params {Object} event
   *
   * @memberOf ComplianceModalContainer
   */
  _triggerResize(event) {
    //hack to update react-data-grid metrics
    window.dispatchEvent(new Event('resize'));
  }
}

const container = Container.create(ComplianceModalContainer, {withProps: true});
export default container;
