import BlocklyUtils from './blockly-utils';
import FieldStore from '../stores/field-store';
import FieldTypeStore from '../stores/field-type-store';
import parseXml from '@dmclain-citizendeveloper/citdev-expression-optimizer';

export default {

	/**
	 * Util function meant to generate the automation or expression object for a given key and set of values.
	 * Used primarily by the Pattern Builder to build automation and expressions appropriately.
	 * 
	 * @param {string} action The name of the action (or set of actions) to generate
	 * @param {object} keys Depending on whether an expression or action is desired, the keys used in the generated automation are different
	 * @param {object} parameters Object with the parameters used by the action
	 */
	getAutomation: function(action, keys, parameters) {
		let {xmlKey, jsKey} = keys;
		let toReturn = {};
		let xml = '';
		toReturn[xmlKey] = '';
		switch(action) {
			case 'openPage': {
				parameters.context = parameters.context ? parameters.context : '[{"recordId": "", "tableSchemaName": ""}]';
				parameters.treatment = parameters.treatment ? parameters.treatment : 'replacePage';
				parameters.dialogOptions = Object.assign({
					height: 500,
					width: 800,
				}, parameters.dialogOptions);

				let {pageId, tableSchemaName, context, treatment, dialogOptions: {title, height, width, modal, isResizable, isDraggable}} = parameters;
				
				xml = 
					`<xml xmlns="http://www.w3.org/1999/xhtml">
						<block type="openPage">
							<mutation treatment=` +JSON.stringify(treatment + '') +
								(treatment === 'openDialog' ? 
								' h=' + JSON.stringify(height + '') +
								' w=' + JSON.stringify(width + '') +
								' title=' + JSON.stringify(title + '') +
								' is_draggable=' + JSON.stringify(isDraggable + '') +
								' is_resizable=' + JSON.stringify(isResizable + '') +
								' modal=' + JSON.stringify(modal + '')
							: '') +
								' page_id=' + JSON.stringify(pageId + '') +
								' table=' + JSON.stringify(tableSchemaName + '') + `></mutation>
							<field name="tableSchemaName">` + tableSchemaName + `</field>
							<field name="pageId">` + pageId + `</field>
							<field name="context">` + context + `</field>
							<field name="treatment">` + treatment + '</field>' +
							(treatment === 'openDialog' ?
								'<field name="modal">' + modal + '</field>' +
								'<field name="isResizable">' + isResizable + '</field>' +
								'<field name="isDraggable">' + isDraggable + '</field>' +
								`<value name="title">
									<block type="text">
										<field name="TEXT">` + title + `</field>
									</block>
								</value>` +
								`<value name="height">
									<block type="math_number">
										<field name="NUM">` + height + `</field>
									</block>
								</value>` +
								`<value name="width">
									<block type="math_number">
										<field name="NUM">` + width + `</field>
									</block>
								</value>`
								:
								''
							) +
						`</block>
					</xml>`;
				break;
			}
			case 'expressionValueFromField': {
				let fieldObj = FieldStore.get(parameters.field) || {};

				let partSel = '';
				let {fieldSchemaName, fieldType, recordId: fieldId} = fieldObj;
				let fieldTypeObj = FieldTypeStore.get(fieldType) || {};
				if (fieldTypeObj.parts && fieldTypeObj.parts.length) {
					partSel = fieldTypeObj.parts[0].partSchemaName
				}

				let fieldVal = JSON.stringify({
					fieldId,
					fieldSchemaName,
					fieldTypeId: fieldType
				}).replace(/"/g, '&quot;');

				xml = 
					`<xml xmlns="http://www.w3.org/1999/xhtml">
						<block type="citdev_output_statement" id="cd_output">
							<value name="output">
								<block type="citdev_value_from_field">
									<mutation selectedtable=` + JSON.stringify(parameters.tableSchemaName || '') +
										' selectedcontext=' + JSON.stringify((parameters.context ? parameters.context : '')).replace(/\\"/g, '&quot;') +
										' field="' + fieldVal +
										'" partsel=' + JSON.stringify(partSel || '') +
									`></mutation>
									<field name="context">` + parameters.context + `</field>
									<field name="fieldSchemaName">` + fieldVal + `</field>
								</block>
							</value>
						</block>
					</xml>`;
				break;
			}
			case 'deleteRecord' : {
				xml = 
					`<xml xmlns="http://www.w3.org/1999/xhtml">
						<block type="deleteRecord">
							<value name="recordsToDelete">
								<block type="context_selector">
									<field name="context">namedContexts["recordSets_` + parameters.field + `-selected"]</field>
								</block>
							</value>
							<next>
								<block type="reloadPage">
								</block>
							</next>
						</block>
					</xml>`;
				break;
			}
			case 'deleteRecordAndReloadField' : {
				xml = 
					`<xml xmlns="http://www.w3.org/1999/xhtml">
						<block type="deleteRecordV2">
							<field name="recordsToDelete">namedContexts["recordSets_` + parameters.field + `-selected"]</field>
							<next>
								<block type="record_set_reload">
									<field name="recordSet">{"componentId":"` + parameters.field + `","componentType":"field","recordSetName":"rows","location":"currentPage"}</field>
								</block>
							</next>
						</block>
					</xml>`;
				break;
			}
			case 'closeAndRefresh' : {
				xml = 
					`<xml xmlns="http://www.w3.org/1999/xhtml">
						<block type="closePage">
							<next>
							<block type="reloadPage">
							</block>
						</next>
					</block>
				</xml>`;
				break;
			}
			case 'closeAndRefreshField' : {
				xml = 
					`<xml xmlns="http://www.w3.org/1999/xhtml">
						<block type="record_set_reload">
							<field name="recordSet">{"componentId":"` + parameters.field + `","componentType":"field","recordSetName":"rows","location":"pageBelow"}</field>
							<next>
								<block type="closePage">
								</block>
							</next>
						</block>
					</xml>`;
				break;
			}
			default: {
				console.warn('Unsupported action %s passed into AssistantAutomation.getAutomation', action);
			}
		}
		// We want to use the expression optimizer's parseXml functionality if it's an expression
		let parsedXML = xmlKey === 'workspaceXML' ? parseXml(xml) : BlocklyUtils.parseXml(xml);
		toReturn[xmlKey] = parsedXML.workspaceXML;
		toReturn[jsKey] = parsedXML.generatedJavascript;
		if(xmlKey === 'workspaceXML') {
			toReturn.optimizationScheme = parsedXML.optimizationScheme;
			toReturn.optimizationData = parsedXML.optimizationData;
		}
		return toReturn;
	}	
}