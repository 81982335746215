export default class ObjectUtils {
	/**
	 * Removes Reference of the Object by creating a deep clone copy 
	 * 
	 * @static
	 * @param {any} o 
	 * @returns 
	 * @memberof ObjectUtils
	 */
	static copyObj(o) {
		var newO,
		i;
	
		if (typeof o !== 'object') {
		return o;
		}
		if (!o) {
		return o;
		}
	
		if ('[object Array]' === Object.prototype.toString.apply(o)) {
		newO = [];
		for (i = 0; i < o.length; i += 1) {
			newO[i] = this.copyObj(o[i]);
		}
		return newO;
		}
	
		newO = {};
		for (i in o) {
		if (o.hasOwnProperty(i)) {
			newO[i] = this.copyObj(o[i]);
		}
		}
		return newO;
	}
	/**
	 * Returns an Object from a JSON 
	 * 
	 * @static
	 * @param {string} valueJSON JSON string to be parsed
	 * @returns {any} returns an empty object if the value is falsy or does not parse
	 * @memberof ObjectUtils
	 */
	static getObjFromJSON(valueJSON) {
		if(!valueJSON) {
			return {};
		}

		if(typeof valueJSON === 'object') {
			return valueJSON;
		}

		let valueObject = {};
		//Parse the Value Raw: 
		try {
			valueObject = JSON.parse(valueJSON);
		} catch (e) {
			console.warn('Failed to JSON Parse for value: ', valueJSON);
		}

		return valueObject;
	}
	/**
	 * Returns an array with elements that are in the object
	 * 
	 * @param {object} object Input Object
	 * @returns {Array} Return array
	 */
	static turnObjectIntoArray(object) {
		var returnArray = [];
		// Make an array from the field match object.
		Object.keys(object).forEach(function(objectKey) {
			returnArray.push(object[objectKey]);
		});
		return returnArray;
	}
	/**
	 * Returns an Object with elements from the Array 
	 * 
	 * @static
	 * @param {array} array - array to transform
	 * @param {string} keyName - key to identify each element of the array
	 * @returns 
	 * @memberof ObjectUtils
	 */
	static turnArrayIntoObject(array, keyName) {
		//If we dont get an array or array is Empty 
		if(!Array.isArray(array) || !array.length){
			return;
		}
		if(!keyName){
			console.error('Error: Can not transform Array into Object. keyName is undefined');
			return;
		}

		//Object to Return
		let returnObject = {};

		//Populate the Object with the Array Elements
		array.forEach(element => {
			//The Object Key to identify the current element
			let identifier = element[keyName];
			//Populate the Returning Object with the current element
			returnObject[identifier] = element;
		});

		return returnObject;
	}
}