import React from 'react';
import RenderActions from '../actions/render-actions';
import { withSize } from 'react-sizeme';
import PinsContainer from './pins/pins-container';
import FieldStore from '../stores/field-store';

function SizedComponentWrapped(props) {
	let {forceHeight} = props;
	return <div id={props.renderId + '-size-wrapper'} className={'grid-item-view' + (forceHeight ? ' citdev-field-force-full-height' : '')}>{props.children}</div>;
}

let SizedComponent = withSize({monitorHeight: true, monitorWidth: false, noPlaceholder: true})(SizedComponentWrapped);

function SizedGridWrapped(props) {
	return <div id={props.renderId + '-layout-size-wrapper'} className='grid-component-view react-grid-layout layout'>{props.children}</div>
}

let SizedGrid = withSize({monitorHeight: true, monitorWidth: false, noPlaceholder: true})(SizedGridWrapped);

function GridItem (props) {
	let {x, y, w, h, resizerHeightType, measureSize, forceHeight, renderId, managingScreensize, saveControlsVisibleTop, children, id} = props;

	let style = {
		gridRowStart: y + 1,
		gridRowEnd: y + h + 1,
		gridColumnStart: x + 1,
		gridColumnEnd: x + w + 1,
		overflow: 'auto',
		width: '100%'
		// borderStyle: 'solid'
	};
	if (measureSize) {
		children = <SizedComponent forceHeight={forceHeight} renderId={renderId} onSize={size => {
			if(size.height) {
				// This actually uses outer height because
				// the idea is to fit the entire component's height,
				// labels and all
				RenderActions.setGridItemHeight(renderId, size.height, resizerHeightType, managingScreensize, +new Date());
			}
		}}>{children}</SizedComponent>;
	}

	let fieldId = props.children.props.fieldId;
	let fieldObj = FieldStore.get(fieldId);
	let fieldCloseToTop = y < 3 && !saveControlsVisibleTop; // only add this class if there are no save controls at the top of the screen
	let fieldCloseToFieldContainerPin = x < 3 && y < 3; // This is used for pin placement on fields that may overlap with another pin from a field container

	return (<div className='react-grid-item' style={style}>
		{/* The isMultiTileContainer prop is to prevent the multi tile containers from displaying an additional pin container */}
		{!props.children.props.isMultiTileContainer && 
			<PinsContainer
				fieldCloseToTop={fieldCloseToTop}
				fieldCloseToFieldContainerPin={fieldCloseToFieldContainerPin}
				attachmentId={props.children.props.attachmentId}
				renderId={props.children.props.renderId}
				recordId={fieldId || props.children.props.recordId}
				tableSchemaName="field"
				parentRecordId={props.children.props.parentRecordId}
				parentTableSchemaName={props.children.props.parentTableSchemaName}
				gridKey={props.children.props.gridKey}
				fieldType={fieldObj && fieldObj.fieldType}
			/>
		}
		<div id={id} style={{height: '100%'}}> {/* This div is only in here for reasons of parallel dom setup */}
			<div
				className={"react-grid-item__inner container-fluid citdev-field-force-full-height"}
			>
				{children}
			</div>
		</div>
	</div>);
}

function GridLayout (props) {
	let {measureSize, renderId, managingScreensize, children} = props;
	let toReturn = (<div className='grid-component-view react-grid-layout layout'>
		{children}
	</div>);
	if(measureSize) {
		toReturn = <SizedGrid renderId={renderId} onSize={size => {
			if(size.height) {
				// This actually uses INNER height because it represents
				// the grid layout between the save bars
				RenderActions.setGridLayoutHeight(renderId, size.height, managingScreensize);
			}
		}}>{children}</SizedGrid>;
	}
	
	return toReturn;
}

export {GridItem, GridLayout};