import keymirror from 'keymirror';

/**
 * READ THIS:  If you are adding constants for purposes that update this store then please
 * inform the NLP Team (Yoli and Drew) so they can make appropriate changes to the NLP Dictionaries Store.
 */
export const RelationshipConstants = keymirror({
	RELATIONSHIP_DELETE_FROM_DATABASE: null,
	RELATIONSHIP_DELETE_FROM_STORE: null,
	RELATIONSHIP_PULL_FROM_DATABASE: null,
	RELATIONSHIP_PULL_FROM_DATABASE_ALL: null,
	RELATIONSHIP_PULL_ERROR: null,
	RELATIONSHIP_PUSH_TO_DATABASE: null,
	RELATIONSHIP_PUSH_TO_STORE: null,
	RELATIONSHIP_RECEIVE_BROADCAST: null
});