var keyMirror = require('keymirror');

var RenderConstants = keyMirror({
	'COMPONENT_DISABLE': null,
	'COMPONENT_ENABLE': null,
	'DELETE_RENDER': null,
	'DELETE_RENDER_BULK': null,
	'DELETE_RENDER_RECORDSET': null,
	'SET_RENDER': null,
	'SET_RENDER_BULK':  null,
	'SET_RENDER_RECORD': null,
	'SET_RENDER_RECORD_BULK': null,
	'SET_RENDER_RECORDSET': null,
	'REFRESH_FIELD': null,
	'CALCULATE_ATTACHED_FIELDS': null,
	'FAIL_SAVE_GRID': null,
	'UPDATE_FIELD_HEIGHT': null,
	'UPDATE_GRID_ITEM_HEIGHT': null,
	'UPDATE_GRID_ITEM_HEIGHTS_BULK': null,
	'UPDATE_GRID_LAYOUT_HEIGHT': null,
	'RESET_FIELD_HEIGHTS': null,
	'INIT_PAGE': null,
	'INIT_GRID': null,
	'INIT_REPEATING_GRID': null,
	'RECALC_REPEATING_GRID': null,
	'ATTACHED_FIELDS_CHANGE': null,
	'GRID_UPDATE': null
});

module.exports = RenderConstants;
