import socketFetcher from './socket-fetcher';

class CAPTCHAUtils {
	static recaptchaV2(value) {
		let bodyData = JSON.stringify({
			captchaValue: value
		});
		return new Promise((resolve, reject) => {
			socketFetcher('gw/validate-v2-recaptcha', bodyData).then(response => {
				console.log('response', response);
				if(response.responseCode === 200) {
					return resolve(response.response);
				} else {
					return reject(new Error(response.responseCode + ' response received from ReCAPTCHA V2 endpoint' + (response.error || '')));
				}
			})
		});
	}
}

export default CAPTCHAUtils;