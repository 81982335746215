import React, { Component } from 'react';
import { Container } from 'flux/utils';
import ObjectUtils from '../utils/object-utils';

// Actions
import AdminSettingsActions from '../actions/admin-settings-actions';

// Constants
import Upgrade from '../constants/help-upgrade-constants';

// Stores
import AdminSettingsStore from '../stores/admin-settings-store';
import AuthenticationStore from '../stores/authentication-store';
import ContextStore from '../stores/context-store';
import FieldStore from '../stores/field-store';
import FieldSettingsStore from '../stores/field-settings-store';
import FieldTypeStore from '../stores/field-type-store';
import PageStore from '../stores/page-store';
import PageModeStore from '../stores/page-mode-store';
import MetadataStore from '../stores/metadata-store';
import RelationshipStore from '../stores/relationship-store';
import TableStore from '../stores/table-store';

// Setting Components
import APIConfigLogicSetting from './settings/logic/api-config-logic-setting.react.js';
import APIConfigSetting from './settings/logic/api-config-setting.react.js';
import APIConfigSettingsChooser from './settings/logic/api-config-setting-chooser.react.js';
import AppearanceSetting from '../components/settings/appearance-setting.react';
import AppearanceSettingsChooser from '../components/settings/appearance-settings-chooser.react';
import AutomationSetting from '../components/settings/automation-setting.react';
import AutomationSettingsChooser from '../components/settings/automation-settings-chooser.react';
import ComplianceSetting from '../components/settings/compliance-setting.react';
import ComplianceSettingsChooser from '../components/settings/compliance-settings-chooser.react';
import DiscussionSetting from '../components/settings/discussion-setting.react';
import DiscussionSettingsChooser from '../components/settings/discussion-settings-chooser.react';
import FieldSetting from './settings/data/field-setting.react';
import FieldAddSettingsChooser from './settings/data/field-add-settings-chooser.react';
import FunctionLogicSetting from './settings/logic/function-logic-setting.react.js';
import FunctionSetting from './settings/logic/function-setting.react.js';
import FunctionSettingsChooser from './settings/logic/function-setting-chooser.react.js';
import PageAddSetting from '../components/settings/presentation/page-add-setting.react';
import PageAddSettingsChooser from '../components/settings/presentation/page-add-settings-chooser.react';
import QuerySetting from '../components/settings/query-setting.react';
import QuerySettingsChooser from '../components/settings/query-settings-chooser.react';
import RelationshipTablesSetting from './settings/data/relationship-tables-setting.react.js';
import RelationshipSetting from './settings/data/relationship-setting.react.js';
import RelationshipSettingsChooser from './settings/data/relationship-settings-chooser.react.js';
import ScheduledLogicLogicSetting from './settings/logic/scheduled-logic-logic-setting.react.js';
import ScheduledLogicSetting from './settings/logic/scheduled-logic-setting.react.js';
import ScheduledLogicSettingsChooser from './settings/logic/scheduled-logic-setting-chooser.react.js';
import SecuritySetting from '../components/settings/security-setting.react';
import SecuritySettingsChooser from '../components/settings/security-settings-chooser.react';
import TableSetting from './settings/data/table-setting.react';
import TableSettingsChooser from './settings/data/table-settings-chooser.react';
import ThemeSetting from './settings/presentation/theme-setting.react';
import ThemeSettingsChooser from './settings/presentation/theme-settings-chooser.react';
import VisibilitySettingV1 from '../components/settings/visibility-setting-v1.react';
import VisibilitySettingsChooserV1 from './settings/visibility-settings-chooser-v1.react';
import VisibilitySetting from '../components/settings/visibility-setting.react';
import VisibilitySettingsChooser from './settings/visibility-settings-chooser.react';
import WebhookSetting from '../components/settings/logic/webhook-setting.react.js';
import WebhookSettingChooser from '../components/settings/logic/webhooks-settings-chooser.react';

// Utils
import UIUtils from '../utils/ui-utils';
// import FieldUtils from '../utils/field-utils';

// Dissappointments
import $ from 'jquery';

import { ReactSVG } from 'react-svg';

import Select, { components } from 'react-select';

/**
 * Displays settings panel
 */
class SettingsPanel extends Component {
	/**
	 * Creates an instance of SettingsPanel.
	 * 
	 * @param {any} props 
	 * @memberof SettingsPanel
	 */
	constructor(props) {
		super(props);
		this.onDetachRightPanelTrigger = this.onDetachRightPanelTrigger.bind(this);
		// Expanding the Panel
		this.onChangeExpandedPanel = this.onChangeExpandedPanel.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onSettingsTabClick = this.onSettingsTabClick.bind(this);
		this.isDisabledDueToPlan = this.isDisabledDueToPlan.bind(this);
		this.selectedComponentChanged = this.selectedComponentChanged.bind(this);

		this._getComponentSwitcherDropdown = this._getComponentSwitcherDropdown.bind(this);
		this._getDropdownStyles = this._getDropdownStyles.bind(this);
		this._getSettingComponent = this._getSettingComponent.bind(this);
		this._getSettingsChooserComponent = this._getSettingsChooserComponent.bind(this);

		this.onKeyDown = this.onKeyDown.bind(this);

		this.state ={
			panelChooserValue: ''
		};
	}
	/**
	 * Return the stores we're listening to for changes.
	 *
	 * @return {array}  description
	 */
	static getStores() {
		return [
			AdminSettingsStore, 
			FieldStore, 
			FieldTypeStore, 
			MetadataStore, 
			PageStore, 
			PageModeStore, 
			RelationshipStore, 
			TableStore 
		];
	}

	/**
	 * Calculate our state anytime a store changes.
	 *
	 * @param  {object} prevState
	 * @return {object}
	 */
	static calculateState(prevState) {
		let componentId = AdminSettingsStore.getRecordId();
		let renderId = AdminSettingsStore.getRenderId();
		let attachmentId = AdminSettingsStore.getAttachmentId();
		let componentType = AdminSettingsStore.getTableSchemaName();
		let parentComponentId = AdminSettingsStore.getParentRecordId();
		let parentComponentType = AdminSettingsStore.getParentTableSchemaName();
		// let activeTab = AdminSettingsStore.getSettingsTabMain();
		// We are disabling the Assistant tab for now.
		let activeTab = 'settings';
		let settingSchemaName = AdminSettingsStore.getSettingSchemaName();
		let subSettingSchemaName = AdminSettingsStore.getSubSettingSchemaName();
		let subSettingIndex = AdminSettingsStore.getSubSettingIndex();
		let isPanelExpanded = AdminSettingsStore.getIsSettingsPanelExpanded();

		let fieldObj = FieldStore.get(componentId);
		let fieldSchemaName = (fieldObj ? fieldObj.fieldSchemaName : '');
		let fieldTypeName = '[ No Field Type selected ]';
		if(fieldObj) {
			let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType);
			if(fieldTypeObj) {
				fieldTypeName = fieldTypeObj.name;
			}
		}

		// Get all of the component IDs which can potentially display for this attachment ID
		let oldComponentIds = prevState && prevState.componentIds ? prevState.componentIds : [];

		let parentSettingsObj = (componentType === 'page' 
			? PageStore.get(parentComponentId)
			: FieldSettingsStore.getSettings(parentComponentId));

		let componentIds = [];
		if(parentSettingsObj && parentSettingsObj.attachedFields) {
			let attachedFieldsArr = ObjectUtils.getObjFromJSON(parentSettingsObj.attachedFields);
			let entry = attachedFieldsArr && Array.isArray(attachedFieldsArr) ? attachedFieldsArr.find((attachmentObj) => attachmentObj.attachmentId === attachmentId) : undefined;
			if (entry && entry.recordIds) {
				componentIds = entry.recordIds;
			}
		}
		// Check if the array is actually different so that we know whether we need to update it
		if(oldComponentIds.length === componentIds.length) {
			let updateComponentIds = false;
			for (let i = 0; i < componentIds.length; i++) {
				let oldComponentId = oldComponentIds[i];
				let newComponentId = componentIds[i];
				if (oldComponentId !== newComponentId) {
					updateComponentIds = true;
					break;
				}
			}

			if(!updateComponentIds) {
				componentIds = oldComponentIds;
			}
		}
		let tableName = '';
		if(componentType && fieldObj) {
			let tableObj = TableStore.getByTableSchemaName(fieldObj.tableSchemaName);
			tableName = tableObj.pluralName;
		}

		let headerLabelPrimary = UIUtils.getSettingsHeader(componentId, componentType);
		let settingsHeaderSub1Obj = UIUtils.getSettingsHeaderSub1(componentId, componentType);
		let settingsHeaderSub2Obj = UIUtils.getSettingsHeaderSub2(parentComponentId, parentComponentType);

		return {
			'activeTab' : activeTab,
			'fieldSchemaName' : fieldSchemaName,
			'fieldTypeName' : fieldTypeName,
			'componentId': componentId,
			'componentIds': componentIds,
			'componentType' : componentType,
			'renderId': renderId,
			'attachmentId': attachmentId,
			'settingSchemaName': settingSchemaName,
			'subSettingIndex': subSettingIndex,
			'subSettingSchemaName': subSettingSchemaName,
			'tableName' : tableName,
			'parentComponentId': parentComponentId,
			'parentComponentType': parentComponentType,
			'settingsHidden': AdminSettingsStore.getSettingsListHidden(),
			isPanelExpanded: isPanelExpanded,
			headerLabelPrimary: headerLabelPrimary,
			headerLabelSub1: settingsHeaderSub1Obj.label, 
			headerIconSub1: settingsHeaderSub1Obj.icon, 
			headerLabelSub2: settingsHeaderSub2Obj.label, 
			headerIconSub2: settingsHeaderSub2Obj.icon
		};
	}

	/**
	 * Determine if a restriction is in place due to the plan.
	 * @param {string} restriction Which restiction to check for
	 */
	isDisabledDueToPlan(restriction) {
		return !AuthenticationStore.getRetailRestriction(restriction);
	}

	/**
	 * Change the panel width
	 */
	onChangeExpandedPanel() {
		let panelExpanded = AdminSettingsStore.getIsSettingsPanelExpanded();
		AdminSettingsActions.onSettingsPanelExpand(!panelExpanded);
	}

	/**
	 * Method used when the X is pressed to close the pannel.  
	 * When popped out, we should close the popup, when just open on the right
	 * we should trigger the panel to close.
	 * @param {object} event - The onClick event.
	 */
	onClose(event) {
		if(this.props.onRightPanelTrigger) {
			UIUtils.closeSettingsPanel();
		} else {
			// For if the settings panel is popped out.
			event.preventDefault();
			window.close();
		}
	}

	/**
	 * onDetachRightPanelTrigger - Detach the right panel
	 *
	 * @param  {object} event      event object
	 */
	onDetachRightPanelTrigger(event) {
		event.preventDefault();
		let overlay = AdminSettingsStore.getSelectedOverlay();
		if (overlay !== 'appearance') {
			return false;
		}

		let windowRef = undefined;

		for (let i = 0; i < window.detachedSettingWindows.length; i++) {
			let detachedSettingWindow = window.detachedSettingWindows[i];

			// This one me?
			if (detachedSettingWindow.name === window.name) {
				windowRef = detachedSettingWindow.ref;
			}
		}

		// If we found a window of this name...
		if (windowRef) {
			// Focus it!
			windowRef.focus();
		} else {
			// Open one.
			window.detachedSettingWindows.push({
				ref: window.open('/settings.html?overlay=' + overlay, 'citdev-tools-settings-' + overlay),
				name: 'citdev-tools-settings-' + overlay
			});
		}

		// Close the panel
		AdminSettingsActions.onRightPanelChange(false, false);
	}

	/**
	 * Click on a Settings tab, and update the admin settings store with where we are.
	 * 
	 * @param {any} activeTab 
	 * @param {any} event 
	 * @memberof SettingsPanel
	 */
	onSettingsTabClick(activeTab, event) {
		event.preventDefault();
		AdminSettingsActions.onSettingsTabChangeMain(activeTab);
	}

	/* Capture the Ctrl-S or Ctrl-R for Save and Reset */
	onKeyDown(event) {
		UIUtils.onKeyDown(event);
	}

	/**
	 * componentDidMount - Sets Bootstrap popover properties.
	 */
	componentDidMount() {
		/* Set keydown listener for the Ctrl-S and Ctrl-R Save and Reset buttons */
		window.addEventListener('keydown', this.onKeyDown);

		let { componentId, renderId, attachmentId } = this.state;

		$(this.link).popover({
			'container': $('#right-panel'),
			'animation': false,
			'delay': {
				'hide': 500
			}
		})
		.on('mouseenter', function(event) {
			$(this).popover('show');
			$('.popover')
			.on('mouseenter',function(){
				$(this).popover('show');
				$('.copy-recordid').on('click', function(event) {
                    var tempInput = document.createElement("input");
                    $("body").append(tempInput);
                    tempInput.value = componentId;
                    tempInput.select();
                    navigator.clipboard.writeText(tempInput.value);
                    tempInput.remove();
                })
				$('.copy-renderid').on('click', function(event) {
                    var tempInput = document.createElement("input");
                    $("body").append(tempInput);
                    tempInput.value = renderId;
                    tempInput.select();
                    navigator.clipboard.writeText(tempInput.value);
                    tempInput.remove();
                })
				$('.copy-attachmentid').on('click', function(event) {
                    var tempInput = document.createElement("input");
                    $("body").append(tempInput);
                    tempInput.value = attachmentId;
                    tempInput.select();
                    navigator.clipboard.writeText(tempInput.value);
                    tempInput.remove();
                })
			})
			.on('mouseleave',function(){
				$(this).popover('hide');
			})
		})
		.on('mouseleave',function(){
			if(!$('.popover:hover').length){
				// set delay for hiding popover (allows us to "cut the corners")
				$(this).popover('hide');
			}
		});
	}

	componentWillUnmount() {
		/* Remove the event listener for keydown that was set in componentDidMount */
		window.removeEventListener('keydown', this.onKeyDown);
	}

	selectedComponentChanged(option) {
		AdminSettingsActions.onComponentIdChange(option.value);
	}
	/**
	 * render the component
	 *
	 * @return {JSX}
	 */
	render() {
		let selectedOverlay = this.props.selectedOverlay,
			urlFontawesome = ContextStore.getUrlFontawesome();

		let { 
				componentIds, 
				componentType, 
				headerLabelPrimary, 
				headerLabelSub1, 
				headerIconSub1, 
				headerLabelSub2, 
				headerIconSub2,
				settingsHidden,
				isPanelExpanded,
				attachmentId,
				componentId,
				renderId
		} = this.state;

		// determine settings to display
		let settingsChooser = this._getSettingsChooserComponent(selectedOverlay);
		let settingsComponent = this._getSettingComponent(selectedOverlay)

		// Generate the settings panel chooser, if we need one.
		let settingsPanelChooserDropdown = null;
		if(componentType === 'field' || componentType === 'page') {
			settingsPanelChooserDropdown = UIUtils.getSettingSwitcherDropdown(componentType, componentId);
		}

		// Generate the component chooser, if we need one [ have logic based visibility rules ]
		let componentChooserDropdown = null;
		if(componentIds && componentIds.length > 1) {
			componentChooserDropdown = this._getComponentSwitcherDropdown();
		}

		let popoverContent = UIUtils.getPopoverContent(attachmentId, componentId, componentType, renderId);
		return (
			<div id="right-panel" className={`panel cd-tools ${isPanelExpanded ? 'right-panel-expanded' : ''}`}>
				<div id="component-settings-panel">
					<header className="d-flex right-panel-header p-1 cd-bg-1">
						<div className="d-flex w-100 header-container justify-content-between align-items-center">
							<div style={{ flex: 1 }} className="d-flex heading-label flex-column position-relative">
								<div className="d-flex">
									<h3>{headerLabelPrimary}</h3>
									{( popoverContent.length > 0 
										? <a className="info-button ml-2 mt-2" href="#"
											ref={el => this.link = el}
											tabIndex="0"
											role="button"
											data-toggle="popover"
											data-trigger="focus" 
											data-placement="bottom"
											data-html="true"
											data-content={popoverContent} onClick={(event) => { event.preventDefault(); }}>
											<i className="fa fa-info-circle ml-1" aria-hidden="true"></i>
										</a> : null )}
								</div>
								<div id="setting-preview-field" className="muted d-flex flex-column theme-font-regular">
									<div className="d-flex align-items-center">
										<ReactSVG 
											beforeInjection={svg => {
												svg.setAttribute('style', 'margin-right: 4px; margin-bottom: 4px; height: 14px; width: 14px');
											}}
											src={headerIconSub1} />
										<h4>{headerLabelSub1}</h4>
									</div>
									{(headerIconSub2 || headerLabelSub2 ? 
										<div className="popover-override-setting-parent d-flex align-items-center">
											<img height="14" width="14" className="mr-1" src={headerIconSub2} alt=""/>
											<h4>{headerLabelSub2}</h4>
										</div>
									: null )}
								</div>
								
								{ //Added the || false so we always show it disabled due to the bugs in the appearance popout 
									// (AdminSettingsStore.getSelectedOverlay() === 'appearance' || false ? 
									// <a className="detach-button" href="#" onClick={this.onDetachRightPanelTrigger}>
									// 	<img src={urlFontawesome + '/arrow-up.svg'} className="icon" alt="" />
									// </a> 
									// : 	

									/*********************************************************************************************
									/* Commenting this out - won't be used here, but will need later in User-Story Development */
										// <a className="detach-button disabled" href="#" onClick={(event) => { event.preventDefault(); }}>
										// 	<svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" viewBox="0 0 1792 1792"><path d="M1675 971q0 51-37 90l-75 75q-38 38-91 38-54 0-90-38l-294-293v704q0 52-37.5 84.5t-90.5 32.5h-128q-53 0-90.5-32.5t-37.5-84.5v-704l-294 293q-36 38-90 38t-90-38l-75-75q-38-38-38-90 0-53 38-91l651-651q35-37 90-37 54 0 91 37l651 651q37 39 37 91z" fill="#666"/></svg>
										// </a> 
									//**********************************************************************************************
								// )
								}
							</div>
							<div className="heading-label-info d-flex align-items-center">
								{componentChooserDropdown}
								{/* Expand panel to full screen width */}
								<div className="expand-panel-button-container" onClick={this.onChangeExpandedPanel}>
									<img height="14" width="14" src={ContextStore.getUrlMedia() + "/expand-setting.svg"} alt="" />
								</div>
								<div className="close-button d-flex justify-content-center align-items-center" onClick={this.onClose}>
									<img src={urlFontawesome + '/close.svg'} className="icon" alt="" />
								</div>
							</div>
						</div>
					</header>
					<div id={selectedOverlay + "-panel"} className="row setting-container">
						<div id="setting-list" className={(settingsHidden ? 'toggled pl-0 col-0' : ' col-4')}>
							{settingsPanelChooserDropdown}
							{settingsChooser}
						</div>
						<div id="setting-body" className={`cd-bg-4 ${settingsHidden ? 'toggled px-0' : ' px-0'}`}>
							{settingsComponent}
						</div>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Retrieve the React Component for the settings panel
	 * @param {string} selectedOverlay Which overlay is selected
	 
	 * @return {JSX} React Component for the settings chooser.
	 */
	_getSettingsChooserComponent(selectedOverlay) {
		/*
		* @param {string} subSettingSchemaName For Settings Choosers that are 
		* 		actually about different records, like Security and Compliance, what 
		* 		is their sub-records schema name? 
		* @param {string} subSettingIndex  For Settings Choosers that are 
		* 		actually about different records, like Security and Compliance, what 
		* 		is their sub-records record id?  This represents different things to 
		* 		different components, which should be resolved in the 2.0 UX updates.
		*/
		let {  subSettingIndex, subSettingSchemaName } = this.state;

		switch (selectedOverlay) {
			case 'apiconfig':
				return (<APIConfigSettingsChooser 
							onUpgradeClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_API_INCOMING); }}
							disabledRetailRestrictions={this.isDisabledDueToPlan('enableIncomingAPI')}
						/>);
			case 'appearance':
				return (<AppearanceSettingsChooser />);
			case 'automation':
				return (<AutomationSettingsChooser />);
			case 'compliance':
				return (<ComplianceSettingsChooser 
							targetTableSchemaName={subSettingSchemaName}
							onUpgradeClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_COMPLIANCE); }}
							disabledRetailRestrictions={this.isDisabledDueToPlan('enableCompliance')}
						/>);
			case 'discussion':
				return (<DiscussionSettingsChooser 
							targetTableSchemaName={subSettingSchemaName}
						/>);
			case 'field-add':
				return (<FieldAddSettingsChooser 
						// onUpgradeClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_SCHEDULED_LOGIC); }}
						// disabledRetailRestrictions={this.isDisabledDueToPlan('enableScheduledLogic')}
						/>);
			case 'function':
				return (<FunctionSettingsChooser />);
			case 'page-add':
				return (<PageAddSettingsChooser />);
			case 'query':
				return (<QuerySettingsChooser />);
			case 'relationship':
				return (<RelationshipSettingsChooser />);
			case 'scheduledLogic':
				return (<ScheduledLogicSettingsChooser 
							onUpgradeClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_SCHEDULED_LOGIC); }}
							disabledRetailRestrictions={this.isDisabledDueToPlan('enableScheduledLogic')}
						/>);
			case 'security':
				return (<SecuritySettingsChooser 
							targetTableSchemaName={subSettingSchemaName}
							onUpgradeClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_SECURITY); }}
							disabledRetailRestrictions={this.isDisabledDueToPlan('enableSecurity')}
							/>);
			case 'table':
				return (<TableSettingsChooser 
							// onUpgradeClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_SCHEDULED_LOGIC); }}
							// disabledRetailRestrictions={this.isDisabledDueToPlan('enableScheduledLogic')}
						/>);
			case 'theme':
				return (<ThemeSettingsChooser 
							// onUpgradeClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_SCHEDULED_LOGIC); }}
							// disabledRetailRestrictions={this.isDisabledDueToPlan('enableScheduledLogic')}
						/>);
			case 'visibilityV1':
				return (<VisibilitySettingsChooserV1 />);
			case 'visibility':
				return (<VisibilitySettingsChooser
							subSettingSchemaName={subSettingSchemaName}
							subSettingIndex={subSettingIndex}
							attachmentId={this.state.attachmentId}
							onUpgradeClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_VISIBILITY); }}
							disabledRetailRestrictions={this.isDisabledDueToPlan('enableVisibility')}
						/>);
			case 'webhooks': 
				return (<WebhookSettingChooser />);
			default: {
				console.warn('Selected overlay %s not yet supported', selectedOverlay);
			}
		}
	}

	/**
	 * Retrieve the React Component for the settings panel
	 * @param {string} selectedOverlay Which overlay is selected
	 * @return {JSX} React Component for the setting.
	 */
	_getSettingComponent(selectedOverlay) {
		/*
		* @param {string} subSettingSchemaName For Settings Choosers that are 
		* 		actually about different records, like Security and Compliance, what 
		* 		is their sub-records schema name? 
		* @param {string} subSettingIndex  For Settings Choosers that are 
		* 		actually about different records, like Security and Compliance, what 
		* 		is their sub-records record id?  This represents different things to 
		* 		different components, which should be resolved in the 2.0 UX updates.
		* @param {string} settingSchemaName Which setting are we displaying?
		*/
		let {  
			attachmentId,
			renderId ,
			subSettingIndex, 
			subSettingSchemaName, 
			settingSchemaName, 
		} = this.state;
		switch (selectedOverlay) {
			case 'apiconfig':
				if(settingSchemaName === 'logic') {
					return (<APIConfigLogicSetting />);
				} else {
					return (<APIConfigSetting />);
				}
			case 'appearance':
				return (<AppearanceSetting />);
			case 'automation':
				return (<AutomationSetting 
							onUpgradeClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_API_REST_LOGIC); }}
							disabledRetailRestrictions={this.isDisabledDueToPlan('enableRestAPILogic')}
						/>);
			case 'compliance':
				return (<ComplianceSetting
							targetTableSchemaName={subSettingSchemaName}
							securityGroupRecordId={subSettingIndex}
							onUpgradeClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_COMPLIANCE); }}
							disabledRetailRestrictions={this.isDisabledDueToPlan('enableCompliance')}
						/>);
			case 'discussion':
				return (<DiscussionSetting
							targetTableSchemaName={subSettingSchemaName}
							securityGroupRecordId={subSettingIndex}
						/>);
			case 'field-add':
				return (<FieldSetting />);
			case 'function':
				if(settingSchemaName === 'logic') {
					return (<FunctionLogicSetting />);
				} else {
					return (<FunctionSetting />);
				}
			case 'page-add':
				return (<PageAddSetting />);
			case 'query':
				return (<QuerySetting />);

			case 'relationship':
				if(settingSchemaName === 'relation-tables') {
					return (<RelationshipTablesSetting />);
				} else {
					return (<RelationshipSetting />);
				}
				
			case 'scheduledLogic':
				if(settingSchemaName === 'logic') {
					return (<ScheduledLogicLogicSetting />);
				} else {
					return (<ScheduledLogicSetting />);
				}
			case 'security':
				return (<SecuritySetting
							targetTableSchemaName={subSettingSchemaName}
							securityGroupRecordId={subSettingIndex}
							onUpgradeClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_SECURITY); }}
							disabledRetailRestrictions={this.isDisabledDueToPlan('enableSecurity')}
						/>);
			case 'table':
				return (<TableSetting />);
			case 'theme':
				return (<ThemeSetting />);
			case 'visibilityV1':
				return (<VisibilitySettingV1
							subSettingSchemaName={subSettingSchemaName}
							subSettingIndex={subSettingIndex}
						/>);
			case 'visibility':
				return (<VisibilitySetting
							isContentTab={subSettingSchemaName === 'attachedFields'}
							subSettingSchemaName={subSettingSchemaName}
							subSettingIndex={subSettingIndex}
							renderId={renderId}
							attachmentId={attachmentId}
							onUpgradeClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_VISIBILITY); }}
							disabledRetailRestrictions={this.isDisabledDueToPlan('enableVisibility')}
						/>);
			case 'webhooks': 
				return (<WebhookSetting
							onUpgradeClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_STRIPE); }}
							disabledRetailRestrictions={this.isDisabledDueToPlan('enableStripe')}
						/>);
			default: {
				console.warn('Selected overlay %s not yet supported', selectedOverlay);
			}
		}
	}

	_getDropdownStyles() {
		/* Setup the custom styles for each of the components in the React Select  */
		return {
			container: (base, state) => ({
				...base,
				paddingRight: '.7rem',
				paddingLeft: '.7rem',
				marginTop: '1rem'
			}),
			control: (base, state) => ({
				...base,
				backgroundColor: 'transparent',
				color: '#fff',
				borderRadius: '5px',
				borderWidth: '2px',
				borderColor: 'var(--theme-grey-3)',
				boxShadow: 'var(--theme-grey-3)',
				"&:hover": {
					borderColor: 'var(--theme-grey-3)',
					cursor: 'pointer'
				}
			}),
			indicatorSeparator: (base, state) => ({
				...base,
				backgroundColor: 'transparent'
			}),
			menu: (base, state) => ({
				...base,
				padding: '0 1px 1px',
				width: 'calc(100% - 1.4rem)',
				backgroundColor: 'var(--theme-grey-3)',
				borderRadius: '5px',
				border: '2px solid var(--theme-grey-3)',
				boxShadow: 'none'
			}),
			menuList: (base, state) => ({
				...base,
				padding: 0,
				maxHeight: 'none',
				color: 'var(--theme-text-1)',
				borderRadius: '5px'
			}),
			noOptionsMessage: (base, state) => ({
				...base,
				backgroundColor: 'var(--theme-level-4)',
				fontSize: '1rem !important',
				color: 'var(--theme-text-1) !important',
			}),
			option: (base, state) => ({
				...base,
				padding: 0,
				backgroundColor: state.value ===state.isSelected ? 'var(--theme-level-1-hilite)' : 'var(--theme-level-4)',
				"&:hover": {
					backgroundColor: state.isSelected ? 'var(--theme-level-1-hilite)' : 'var(--theme-level-3-hilite)',
					cursor: 'pointer'
				}
			})
		};
	}

	_getComponentSwitcherDropdown() {
		let { componentIds } = this.state;

		// From react Select's Components
		const { Option } = components;

		/* Setup the Option with the name of the Setting page being displayed */
		const ComponentSwitcherOption = props => {
			return (
				<Option className={'fieldSwitcherSelectOption'} {...props}>
				</Option>
			);
		}

		const ComponentSwitcherValueContainer = ({ children, getValue, ...props}) => {
			return (
				<div className='panelChooserSelected' style={{ display: 'flex', alignItems: 'center' }}>
					<div className='panelChooserImgContainer'>
						<img
							style={{ padding: '10px', width: 40, height: 40, marginTop: '1px', backgroundColor: '#b760d4' }}
							src={ContextStore.getUrlMedia() + "/icon-visibility.svg"}
							alt={"Visibility Field Switcher"}
						/>
					</div>
					<div style={{ color: 'var(--theme-text-1)', fontSize: '1rem' }}>Switch to other field in this location ({ this.state.componentIds.length })</div>
					{/* Since we are overriding the ValueContainer (which includes the input), we have to put */}
					{/* the input back in - the original ValueContainer included a div and an input. children[1] is the input */}
					{ children[1] }
				</div>
			)
		}

		// setup field switcher options to display for field-selector in upper right header area
		let componentSwitcherOptions = componentIds.map(componentId => {
			return(
			   {
				value: componentId,
				key: componentId,
				label: UIUtils.getSettingsHeader(componentId, 'field')
			   }
			)
		});

		let dropdownStyles = this._getDropdownStyles();

		// DM: I think value should be set here as well...?
		return (<Select
				components={{ Option: ComponentSwitcherOption, ValueContainer: ComponentSwitcherValueContainer }}
				defaultValue={componentSwitcherOptions[0]}
				id='fieldSwitcher'
				isSearchable={false}
				onChange={this.selectedComponentChanged}
				options={componentSwitcherOptions}
				styles={dropdownStyles}
				/>);
	}
}
const container = Container.create(SettingsPanel);
export default container;