import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import { FieldModesConstants, PageModeConstants, RenderConstants } from '../constants';
import {PageConstants} from '../constants/page-constants';
import {FieldConstants} from '../constants/field-constants';
import RenderStore from './render-store';
import PageModeStore from './page-mode-store';
import FieldSettingsStore from './field-settings-store';

/**
 * Store to contain the current fieldType configurationJson and settings values
 *
 * @class FieldModesStore
 * @extends {ReduceStore}
 */
class FieldModesStore extends ReduceStore {
	/**
	 * Initial state for FieldModesStore
	 *
	 * @param {Object} event
	 *
	 * @memberOf FieldModesStore
	 */
	getInitialState() {
		return Immutable.fromJS({});
	}

	/**
	 * Get current mode
	 *
	 * @param {string} renderId 
	 * @returns {String} settings as hash of props
	 *
	 * @memberOf FieldModesStore
	 */
	getMode(renderId) {
		let mode = this.getState().get(renderId);
		if (mode !== undefined) {
			return mode ? mode.toJS() : undefined;
		}
		return undefined;                                                                                                                                                 
	}   
	/**
	 * hasAvailableMode - determines if target mode is listed in component's availableModes
	 *
	 * @param  {string} renderId
	 * @param  {string} mode    target mode
	 * @return {boolean}
	 */
	hasAvailableMode(renderId, mode) {                                                                                                                                        
		let hasMode = false;                                                                                                                                              
		let modeObj = this.getState().get(renderId);                                                                                                                      
		if (modeObj !== undefined) {                                                                                                                                      
				let availableModes = modeObj.get('availableModes');                                                                                                       
				hasMode = availableModes.includes(mode);                                                                                                                  
		}                                                                                                                                                                 
																																										  
		return hasMode;                                                                                                                                                   
	} 
	/**
	 * getAvailableModes - Gets availableModes
	 *
	 * @param {string} renderId Page to look for the field's modes on
	 * @return {string}	Available modes for this field on this page.
	 */
	getAvailableModes(renderId) {                                                                                                                                             
		let availableModes = undefined;                                                                                                                                   
		let mode = this.getState().get(renderId);                                                                                                                         
		if (mode !== undefined) {  
			availableModes = mode.get('availableModes');                                                                                                              
		}

		return availableModes;
	}
	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 *
	 * @memberOf FieldModesStore
	 */
	reduce(state, action) {
		switch (action.get('type')) {


			case PageModeConstants.SET_PAGE_MODE: {
				// Waiting for the render store, which waits for PageModeStore to load Data 
				AppDispatcher.waitFor([RenderStore.getDispatchToken()]);

				let renderId = action.get('renderId');
				let newMode = PageModeStore.getCurrentMode(renderId);
				if (!newMode) {
					return state;
				}

				// console.log('new mode', newMode);
				if (newMode === 'add') {
					newMode = 'edit';
				}

				let newState = state.withMutations((state) => {
					setFromRenderStore(renderId, newMode, state);

					if(action.has('grids')) {
						let grids = action.get('grids');
						grids.forEach(grid => {
							let attachedFields = grid.get('attachedFields') || [];
							attachedFields.forEach(attachedField => {
								let renderId = attachedField.get('renderId');
								let newAvailableModes = attachedField.get('availableModes');
		
								let mode = state.get(renderId);
								let currentMode = null;
								if (mode) {
									currentMode = mode.get('currentMode');
								} else {
									mode = Immutable.Map();
								}
								let updatedMode = mode.set('availableModes', newAvailableModes);
		
								if(!currentMode || !newAvailableModes.includes(currentMode)) {
									// Get our parents current mode.
									let renderObj = RenderStore.get(renderId);
									let newAvailableModesArr = newAvailableModes ? newAvailableModes.toJS() : [];
									if(newAvailableModesArr && newAvailableModesArr.includes('add')) {
										// Change add to edit
										let index = newAvailableModesArr.findIndex(v => v === 'add');
										if(index > -1) {
											newAvailableModesArr[index] = 'edit';
										}
									}
									if (renderObj && renderObj.componentType === 'field') {
										let parentRenderObj = null;
										if (renderObj.renderParentId) {
											parentRenderObj = RenderStore.get(renderObj.renderParentId);
										}
										let parentMode = null;
										if (parentRenderObj) {
											if (parentRenderObj.componentType === 'page') {
												parentMode = PageModeStore.getCurrentMode(renderObj.renderParentId);
											} else {
												parentMode = state.hasIn([renderObj.renderParentId, 'currentMode']) ? state.getIn([renderObj.renderParentId, 'currentMode']) : null;
											}
										}

										if (parentMode === 'add') {
											parentMode = 'edit';
										}
										
										if(parentMode && newAvailableModesArr.includes(parentMode)){
											updatedMode = updatedMode.set('currentMode', parentMode);	
										} else {
											updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
										}
									} else {
										updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
									}
								}

								state.set(renderId, updatedMode);
							});
						})
					}
				});

				return newState;
			}
			
			case RenderConstants.DELETE_RENDER: {
				let renderId = action.get('renderId');
				let reassignPageChildren = action.get('reassignPageChildren');
				let childIds = RenderStore.findAllChildren(renderId, reassignPageChildren);
				return state.withMutations((state) => {
					state.delete(renderId);
					
					childIds.forEach((childRenderId) => {
						let childDetails = RenderStore.get(childRenderId);
						let componentType = childDetails.componentType;
						let shouldReassign = reassignPageChildren && componentType === 'page';

						// Only delete the non-page children or delete page children if they are not being reassigned
						// (This is a part of the fix for ticket 8292)
						if(!shouldReassign) {
							state.delete(childRenderId);
						}
					});
				});
			}

			case RenderConstants.SET_RENDER_BULK: {
				AppDispatcher.waitFor([RenderStore.getDispatchToken(), PageModeStore.getDispatchToken()]);
				return state.withMutations((state) => {
					let parentModes = {}
					action.get('renders').forEach((render) => {
						let renderId = render.get('renderId');
						let renderObj = RenderStore.get(renderId);
						if (renderObj.componentType === 'field') {
							let availableModes = render.get('availableModes');
							if (!availableModes && renderObj.renderParentId && 
								parentModes[renderObj.renderParentId] && 
								parentModes[renderObj.renderParentId][renderObj.componentId]) {
							} else {
								let parentRenderObj = null;
								if (renderObj.renderParentId) {
									parentRenderObj = RenderStore.get(renderObj.renderParentId);
								}
								let parentMode = state.hasIn([renderId, 'currentMode']) ? state.getIn([renderId, 'currentMode']) : null;
								if(!availableModes) {
									// Use the parent available modes if none provided here
									availableModes = state.hasIn([renderId, 'availableModes']) ? state.getIn([renderId, 'availableModes']) : null;
									if (parentRenderObj) {
										let fieldSettings = renderObj.attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(renderObj.attachmentId, renderObj.componentId, parentRenderObj.componentId) : FieldSettingsStore.getSettings(renderObj.componentId, parentRenderObj.componentId);
										if(!fieldSettings || ((!fieldSettings.visibilityRules || fieldSettings.visibilityRules === '{}') && (!fieldSettings.visibility || fieldSettings.visibility === '{}'))) {
											if (parentRenderObj.componentType === 'page') {
												parentMode = PageModeStore.getCurrentMode(renderObj.renderParentId);
												if (parentMode === 'add') {
													parentMode = 'edit';
												}
												availableModes = PageModeStore.getAvailableModes(parentRenderObj.componentId);
												availableModes = !availableModes ? '' : availableModes.filter(parentMode => {
													return parentMode !== 'add';
												}).join(',');
											} else {
												parentMode = this.getMode(renderObj.renderParentId).currentMode;
												availableModes = this.getAvailableModes(renderObj.renderParentId);
											}
										}
									}
								}

								if (!parentModes[renderObj.renderParentId]) {
									parentModes[renderObj.renderParentId] = {};
								}

								parentModes[renderObj.renderParentId][renderObj.componentId] = {
									currentMode: parentMode, 
									availableModes: availableModes
								};
							}
							//By default match the modes of the parent
							state.set(renderId, Immutable.Map(parentModes[renderObj.renderParentId][renderObj.componentId]));
						}
					})
				})
			}

			case RenderConstants.SET_RENDER: {
				//Waiting for RenderStore to load Data 
				AppDispatcher.waitFor([RenderStore.getDispatchToken(), PageModeStore.getDispatchToken()]);
				let renderId = action.get('renderId');

				// If we already have this renderID, then ignore the incoming information.
				if(state.has(renderId)) {
					return state;
				}

				let renderObj = RenderStore.get(renderId);
				if (renderObj.componentType === 'field') {
					let parentRenderObj = null;
					if (renderObj.renderParentId) {
						parentRenderObj = RenderStore.get(renderObj.renderParentId);
					}
					let parentMode = null;
					let parentAvailableModes = null;
					if (parentRenderObj) {
						let fieldSettings = renderObj.attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(renderObj.attachmentId, renderObj.componentId, parentRenderObj.componentId) : FieldSettingsStore.getSettings(renderObj.componentId, parentRenderObj.componentId);
						if(!fieldSettings || ((!fieldSettings.visibilityRules || fieldSettings.visibilityRules === '{}') && (!fieldSettings.visibility || fieldSettings.visibility === '{}'))) {
							if (parentRenderObj.componentType === 'page') {
								parentMode = PageModeStore.getCurrentMode(renderObj.renderParentId);
								if (parentMode === 'add') {
									parentMode = 'edit';
								}
								parentAvailableModes = PageModeStore.getAvailableModes(parentRenderObj.componentId);
								parentAvailableModes = !parentAvailableModes ? [] : parentAvailableModes.filter(parentMode => {
									return parentMode !== 'add';
								})
							} else {
								let parentModes = this.getMode(renderObj.renderParentId);
								parentMode = parentModes ? parentModes.currentMode : null;
								parentAvailableModes = this.getAvailableModes(renderObj.renderParentId);
							}
						}
					}

					if (parentMode === 'add') {
						parentMode = 'edit';
					}

					//By default match the modes of the parent
					return state.set(renderId, Immutable.Map({currentMode: parentMode, availableModes: parentAvailableModes}));
				} else {
					return state;
				}
			}

			case FieldModesConstants.SET_MODE: {
				AppDispatcher.waitFor([RenderStore.getDispatchToken()]);
				let renderId = action.get('renderId'),
				mode = state.get(renderId),
				updatedMode = mode.set('currentMode', action.get('mode'));
				let newState = state.withMutations(state => {

					state.set(renderId, updatedMode);

					setFromRenderStore(renderId, action.get('mode'), state);

					if(action.has('grids')) {
						let grids = action.get('grids');
						grids.forEach(grid => {
							let attachedFields = grid.get('attachedFields') || [];
							attachedFields.forEach(attachedField => {
								let renderId = attachedField.get('renderId');
								let newAvailableModes = attachedField.get('availableModes');
		
								let mode = state.get(renderId);
								let currentMode = null;
								if (mode) {
									currentMode = mode.get('currentMode');
								} else {
									mode = Immutable.Map();
								}
								let updatedMode = mode.set('availableModes', newAvailableModes);
		
								if(!currentMode || !newAvailableModes.includes(currentMode)) {
									// Get our parents current mode.
									let renderObj = RenderStore.get(renderId);
									let newAvailableModesArr = newAvailableModes ? newAvailableModes.toJS() : [];
									if(newAvailableModesArr && newAvailableModesArr.includes('add')) {
										// Change add to edit
										let index = newAvailableModesArr.findIndex(v => v === 'add');
										if(index > -1) {
											newAvailableModesArr[index] = 'edit';
										}
									}
									if (renderObj && renderObj.componentType === 'field') {
										let parentRenderObj = null;
										if (renderObj.renderParentId) {
											parentRenderObj = RenderStore.get(renderObj.renderParentId);
										}
										let parentMode = null;
										if (parentRenderObj) {
											if (parentRenderObj.componentType === 'page') {
												parentMode = PageModeStore.getCurrentMode(renderObj.renderParentId);
											} else {
												parentMode = state.hasIn([renderObj.renderParentId, 'currentMode']) ? state.getIn([renderObj.renderParentId, 'currentMode']) : null;
											}
										}

										if (parentMode === 'add') {
											parentMode = 'edit';
										}
										
										if(parentMode && newAvailableModesArr.includes(parentMode)){
											updatedMode = updatedMode.set('currentMode', parentMode);	
										} else {
											updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
										}
									} else {
										updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
									}
								}

								state.set(renderId, updatedMode);
							});
						})
					}
				});
				return newState;
			}

			case FieldModesConstants.SET_AVAILABLE_MODES: {
				let renderId = action.get('renderId');
				let mode = state.get(renderId);
				let currentMode = null;
				
				if (mode) {
					currentMode = mode.get('currentMode');
				} else {
					mode = Immutable.Map();
				}
				let newAvailableModes  = action.get('availableModes');
				let updatedMode = mode.set('availableModes', newAvailableModes);

				if(!currentMode || !newAvailableModes.includes(currentMode)) {
					// Get our parents current mode.
					let renderObj = RenderStore.get(renderId);
					let newAvailableModesArr = newAvailableModes ? newAvailableModes.toJS() : [];
					if(newAvailableModesArr && newAvailableModesArr.includes('add')) {
						// Change add to edit
						let index = newAvailableModesArr.findIndex(v => v === 'add');
						if(index > -1) {
							newAvailableModesArr[index] = 'edit';
						}
					}
					if (renderObj && renderObj.componentType === 'field') {
						let parentRenderObj = null;
						if (renderObj.renderParentId) {
							parentRenderObj = RenderStore.get(renderObj.renderParentId);
						}
						let parentMode = null;
						if (parentRenderObj) {
							if (parentRenderObj.componentType === 'page') {
								parentMode = PageModeStore.getCurrentMode(renderObj.renderParentId);
							} else {
								parentMode = state.hasIn([renderObj.renderParentId, 'currentMode']) ? state.getIn([renderObj.renderParentId, 'currentMode']) : null;
							}
						}

						if (parentMode === 'add') {
							parentMode = 'edit';
						}
						
						if(parentMode && newAvailableModesArr.includes(parentMode)){
							updatedMode = updatedMode.set('currentMode', parentMode);	
						} else {
							updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
						}
					} else {
						updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
					}
				}
				let newState = state.set(renderId, updatedMode);
				return newState;
			}


			case RenderConstants.INIT_PAGE:
			case RenderConstants.INIT_GRID: {
				AppDispatcher.waitFor([RenderStore.getDispatchToken()]);
				return state.withMutations(state => {
					if(action.has('grids')) {
						let grids = action.get('grids');
						grids.forEach(grid => {
							let attachedFields = grid.get('attachedFields') || [];
							attachedFields.forEach(attachedField => {
								let renderId = attachedField.get('renderId');
								let newAvailableModes = attachedField.get('availableModes');
		
								let mode = state.get(renderId);
								let currentMode = null;
								if (mode) {
									currentMode = mode.get('currentMode');
								} else {
									mode = Immutable.Map();
								}
								let updatedMode = mode.set('availableModes', newAvailableModes);
		
								if(!currentMode || !newAvailableModes.includes(currentMode)) {
									// Get our parents current mode.
									let renderObj = RenderStore.get(renderId);
									let newAvailableModesArr = newAvailableModes ? newAvailableModes.toJS() : [];
									if(newAvailableModesArr && newAvailableModesArr.includes('add')) {
										// Change add to edit
										let index = newAvailableModesArr.findIndex(v => v === 'add');
										if(index > -1) {
											newAvailableModesArr[index] = 'edit';
										}
									}
									if (renderObj && renderObj.componentType === 'field') {
										let parentRenderObj = null;
										if (renderObj.renderParentId) {
											parentRenderObj = RenderStore.get(renderObj.renderParentId);
										}
										let parentMode = null;
										if (parentRenderObj) {
											if (parentRenderObj.componentType === 'page') {
												parentMode = PageModeStore.getCurrentMode(renderObj.renderParentId);
											} else {
												parentMode = state.hasIn([renderObj.renderParentId, 'currentMode']) ? state.getIn([renderObj.renderParentId, 'currentMode']) : null;
											}
										}

										if (parentMode === 'add') {
											parentMode = 'edit';
										}
										
										if(parentMode && newAvailableModesArr.includes(parentMode)){
											updatedMode = updatedMode.set('currentMode', parentMode);	
										} else {
											updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
										}
									} else {
										updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
									}
								}

								state.set(renderId, updatedMode);
							});
						})
					}
				});
				
				
			}

			case RenderConstants.INIT_REPEATING_GRID: {
				AppDispatcher.waitFor([RenderStore.getDispatchToken()]);
				return state.withMutations(state => {
					if(action.has('grids')) {
						let grids = action.get('grids');
						grids.forEach(grid => {
							let renderId = grid.get('parentRenderId');
							let newAvailableModes = grid.get('availableModes');
							if(!newAvailableModes) {
								console.warn('Missing newAvailableModes for grid', grid.toJS());
								newAvailableModes = Immutable.fromJS([]);
							}
							let mode = state.get(renderId);
							let currentMode = null;
							if (mode) {
								currentMode = mode.get('currentMode');
							} else {
								mode = Immutable.Map();
							}
							let updatedMode = mode.set('availableModes', newAvailableModes);

							if(!currentMode || !newAvailableModes.includes(currentMode)) {
								// Get our parents current mode.
								let renderObj = RenderStore.get(renderId);
								let newAvailableModesArr = newAvailableModes ? newAvailableModes.toJS() : [];
								if(newAvailableModesArr && newAvailableModesArr.includes('add')) {
									// Change add to edit
									let index = newAvailableModesArr.findIndex(v => v === 'add');
									if(index > -1) {
										newAvailableModesArr[index] = 'edit';
									}
								}
								if (renderObj && renderObj.componentType === 'field') {
									let parentRenderObj = null;
									if (renderObj.renderParentId) {
										parentRenderObj = RenderStore.get(renderObj.renderParentId);
									}
									let parentMode = null;
									if (parentRenderObj) {
										if (parentRenderObj.componentType === 'page') {
											parentMode = PageModeStore.getCurrentMode(renderObj.renderParentId);
										} else {
											parentMode = state.hasIn([renderObj.renderParentId, 'currentMode']) ? state.getIn([renderObj.renderParentId, 'currentMode']) : null;
										}
									}

									if (parentMode === 'add') {
										parentMode = 'edit';
									}
									
									if(parentMode && newAvailableModesArr.includes(parentMode)){
										updatedMode = updatedMode.set('currentMode', parentMode);	
									} else {
										updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
									}
								} else {
									updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
								}
							}
	
							state.set(renderId, updatedMode);
						});
					}
				});
			}

			case RenderConstants.CALCULATE_ATTACHED_FIELDS: {
				AppDispatcher.waitFor([RenderStore.getDispatchToken()]);
				let attachedFields = action.get('attachedFields');
				let newState = state.withMutations(state => {
					attachedFields.forEach(attachedField => {
						let renderId = attachedField.get('renderId');
						let newAvailableModes = attachedField.get('availableModes');

						let mode = state.get(renderId);
						let currentMode = null;
						if (mode) {
							currentMode = mode.get('currentMode');
						} else {
							mode = Immutable.Map();
						}
						let updatedMode = mode.set('availableModes', newAvailableModes);

						if(!currentMode || !newAvailableModes.includes(currentMode)) {
							// Get our parents current mode.
							let renderObj = RenderStore.get(renderId);
							let newAvailableModesArr = newAvailableModes ? newAvailableModes.toJS() : [];
							if(newAvailableModesArr && newAvailableModesArr.includes('add')) {
								// Change add to edit
								let index = newAvailableModesArr.findIndex(v => v === 'add');
								if(index > -1) {
									newAvailableModesArr[index] = 'edit';
								}
							}
							if (renderObj && renderObj.componentType === 'field') {
								let parentRenderObj = null;
								if (renderObj.renderParentId) {
									parentRenderObj = RenderStore.get(renderObj.renderParentId);
								}
								let parentMode = null;
								if (parentRenderObj) {
									if (parentRenderObj.componentType === 'page') {
										parentMode = PageModeStore.getCurrentMode(renderObj.renderParentId);
									} else {
										parentMode = state.hasIn([renderObj.renderParentId, 'currentMode']) ? state.getIn([renderObj.renderParentId, 'currentMode']) : null;
									}
								}

								if (parentMode === 'add') {
									parentMode = 'edit';
								}
								
								if(parentMode && newAvailableModesArr.includes(parentMode)){
									updatedMode = updatedMode.set('currentMode', parentMode);	
								} else {
									updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
								}
							} else {
								updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
							}
						}

						state.set(renderId, updatedMode);
					});
				});
				return newState;
			}

			case RenderConstants.GRID_UPDATE: {
				AppDispatcher.waitFor([RenderStore.getDispatchToken()]);
                let newState = state.withMutations(state => {

                    if(action.has('grids')) {
                        let grids = action.get('grids');
                        grids.forEach(grid => {
							let attachedFields = grid.get('attachedFields');

							if(attachedFields) {
								attachedFields.forEach(attachedField => {
									let renderId = attachedField.get('renderId');
									let newAvailableModes = attachedField.get('availableModes');
			
									let mode = state.get(renderId);
									let currentMode = null;
									if (mode) {
										currentMode = mode.get('currentMode');
									} else {
										mode = Immutable.Map();
									}
									let updatedMode = mode.set('availableModes', newAvailableModes);
			
									// Always respect the parent's current mode first and then show a different mode if the parent mode is unavailable
									// Get our parent's current mode.
									let renderObj = RenderStore.get(renderId);
									let newAvailableModesArr = newAvailableModes ? newAvailableModes.toJS() : [];
									if(newAvailableModesArr && newAvailableModesArr.includes('add')) {
										// Change add to edit
										let index = newAvailableModesArr.findIndex(v => v === 'add');
										if(index > -1) {
											newAvailableModesArr[index] = 'edit';
										}
									}
									if (renderObj && renderObj.componentType === 'field') {
										let parentRenderObj = null;
										if (renderObj.renderParentId) {
											parentRenderObj = RenderStore.get(renderObj.renderParentId);
										}
										let parentMode = null;
										if (parentRenderObj) {
											if (parentRenderObj.componentType === 'page') {
												parentMode = PageModeStore.getCurrentMode(renderObj.renderParentId);
											} else {
												parentMode = state.hasIn([renderObj.renderParentId, 'currentMode']) ? state.getIn([renderObj.renderParentId, 'currentMode']) : null;
											}
										}
		
										if (parentMode === 'add') {
											parentMode = 'edit';
										}
										
										if(parentMode && newAvailableModesArr.includes(parentMode)){
											updatedMode = updatedMode.set('currentMode', parentMode);	
										} else if(currentMode && newAvailableModesArr.includes(currentMode)) {
											updatedMode = updatedMode.set('currentMode', currentMode);
										} else {
											updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
										}
									} else {
										updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
									}
			
									state.set(renderId, updatedMode);
								});
							}
                        });
                    }
                });
                return newState;
			}

			case RenderConstants.REFRESH_FIELD: {
                AppDispatcher.waitFor([RenderStore.getDispatchToken()]);
                let newState = state.withMutations(state => {

                    if(action.has('grids')) {
                        let grids = action.get('grids');
                        grids.forEach(grid => {
							let attachedFields = grid.get('attachedFields');

							if(attachedFields) {
								attachedFields.forEach(attachedField => {
									let renderId = attachedField.get('renderId');
									let newAvailableModes = attachedField.get('availableModes');
			
									let mode = state.get(renderId);
									let currentMode = null;
									if (mode) {
										currentMode = mode.get('currentMode');
									} else {
										mode = Immutable.Map();
									}
									let updatedMode = mode.set('availableModes', newAvailableModes);
			
									// Always respect the parent's current mode first and then show a different mode if the parent mode is unavailable
									// Get our parent's current mode.
									let renderObj = RenderStore.get(renderId);
									let newAvailableModesArr = newAvailableModes ? newAvailableModes.toJS() : [];
									if(newAvailableModesArr && newAvailableModesArr.includes('add')) {
										// Change add to edit
										let index = newAvailableModesArr.findIndex(v => v === 'add');
										if(index > -1) {
											newAvailableModesArr[index] = 'edit';
										}
									}
									if (renderObj && renderObj.componentType === 'field') {
										let parentRenderObj = null;
										if (renderObj.renderParentId) {
											parentRenderObj = RenderStore.get(renderObj.renderParentId);
										}
										let parentMode = null;
										if (parentRenderObj) {
											if (parentRenderObj.componentType === 'page') {
												parentMode = PageModeStore.getCurrentMode(renderObj.renderParentId);
											} else {
												parentMode = state.hasIn([renderObj.renderParentId, 'currentMode']) ? state.getIn([renderObj.renderParentId, 'currentMode']) : null;
											}
										}
		
										if (parentMode === 'add') {
											parentMode = 'edit';
										}
										
										if(parentMode && newAvailableModesArr.includes(parentMode)){
											updatedMode = updatedMode.set('currentMode', parentMode);	
										} else if(currentMode && newAvailableModesArr.includes(currentMode)) {
											updatedMode = updatedMode.set('currentMode', currentMode);
										} else {
											updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
										}
									} else {
										updatedMode = updatedMode.set('currentMode', newAvailableModesArr[0]);
									}
			
									state.set(renderId, updatedMode);
								});
							}
                        });
                    }
                });
                return newState;
            }

			case FieldConstants.FIELD_PUSH_TO_STORE: {
				// Wait for the render store
				AppDispatcher.waitFor([RenderStore.getDispatchToken()]);

				// Now if we've changed the attached fields (only the attached fields; field positions and field position extras shouldn't matter)
				// then we need to update all children of the relevant container

				let recordId = action.get('recordId');
				let recordProperties = action.get('recordProperties');
				if(recordProperties && (recordProperties.has('fieldPosition') || recordProperties.has('fieldPositionExtras'))) {
					let newState = state;

					let containerRenders = RenderStore.getRenderObjectsForComponent('field', recordId);
					if(containerRenders) {
						containerRenders.forEach(field => {
							let renderId = field.get('renderId');
							let newMode = newState.hasIn([renderId, 'currentMode']) ? newState.getIn([renderId, 'currentMode']) : undefined;
							if (!newMode) {
								return state;
							}

							// console.log('new mode', newMode);
							if (newMode === 'add') {
								newMode = 'edit';
							}

							newState = setFromRenderStore(renderId, newMode, newState);
						});
					}
					return newState;
				} else {
					return state;
				}
			}

			// We also need to consider broadcasts here
			case PageConstants.PAGE_RECEIVE_BROADCAST: {
				AppDispatcher.waitFor([RenderStore.getDispatchToken(), PageModeStore.getDispatchToken()]);
				let records = action.get('records');
				let newState = state;
				if(records) {
					records.forEach(record => {
						let recordId = record.get('recordId');
						let containerRenders = RenderStore.getRenderObjectsForComponent('page', recordId);
						if(containerRenders) {
							containerRenders.forEach(field => {
								let renderId = field.get('renderId');
								let newMode = PageModeStore.getCurrentMode(renderId);
								if (!newMode) {
									return;
								}
		
								// console.log('new mode', newMode);
								if (newMode === 'add') {
									newMode = 'edit';
								}
		
								newState = setFromRenderStore(renderId, newMode, newState);
							});
						}
					});
				}
				return newState;
			}

			case FieldConstants.FIELD_RECEIVE_BROADCAST: {
				AppDispatcher.waitFor([RenderStore.getDispatchToken()]);
				let records = action.get('records');
				let newState = state;
				if(records) {
					records.forEach(record => {
						let recordId = record.get('recordId');
						let containerRenders = RenderStore.getRenderObjectsForComponent('field', recordId);
						if(containerRenders) {
							containerRenders.forEach(field => {
								let renderId = field.get('renderId');
								let newMode = newState.hasIn([renderId, 'currentMode']) ? newState.getIn([renderId, 'currentMode']) : undefined;
								if (!newMode) {
									return;
								}
		
								// console.log('new mode', newMode);
								if (newMode === 'add') {
									newMode = 'edit';
								}
		
								newState = setFromRenderStore(renderId, newMode, newState);
							});
						}
					});
				}
				return newState;
			}


			case RenderConstants.ATTACHED_FIELDS_CHANGE: {
				let componentType = action.get('componentType');
				// Wait for the render store
				AppDispatcher.waitFor(componentType === 'page' ? [RenderStore.getDispatchToken(), PageModeStore.getDispatchToken()] : [RenderStore.getDispatchToken()]);

				// Now if we've changed the attached fields (only the attached fields; field positions and field position extras shouldn't matter)
				// then we need to update all children of the relevant container

				let recordId = action.get('recordId');
				let newState = state;
				let containerRenders = RenderStore.getRenderObjectsForComponent(componentType, recordId);
				if(containerRenders) {
					containerRenders.forEach(field => {
						let renderId = field.get('renderId');
						let newMode = componentType === 'page' ? PageModeStore.getCurrentMode(renderId) : newState.hasIn([renderId, 'currentMode']) ? newState.getIn([renderId, 'currentMode']) : undefined;
						if (!newMode) {
							return;
						}

						// console.log('new mode', newMode);
						if (newMode === 'add') {
							newMode = 'edit';
						}

						newState = setFromRenderStore(renderId, newMode, newState);
					});
				}
				return newState;
			}

			default: {
				return state;
			}
		}
	}
}

/**
 * Sets the mode for any child fields of a field
 * 
 * @param {any} components 
 * @param {any} newMode 
 */
function setFromRenderStore(renderParentId, newMode, state) {
	let renderObj = RenderStore.get(renderParentId);
	if (renderObj && renderObj.children) {

		// Modifying state with mutations as we will loop through page data
		let newState = state.withMutations(function (state) {
			// loop through components and extract fieldId and availableModes for each component
			renderObj.children.forEach(renderId => {
				// save to state under fieldId
				let childRenderObj = RenderStore.get(renderId);
				if (childRenderObj && childRenderObj.componentType !== 'page')
				{
					if (!state.has(renderId)) {
						let newObj = Immutable.fromJS({
							'availableModes': ['view', 'edit']
						});
						if (newObj.get('availableModes').includes(newMode)) {
							newObj = newObj.set('currentMode', newMode);
						} else {
							newObj = newObj.set('currentMode', 'view');
						}
						// save to state under fieldId
						state.set(renderId, newObj);
						setFromRenderStore(renderId, newObj.get('currentMode'), state);
					} else if (state.hasIn([renderId, 'availableModes'])) {
						let availableModes = state.getIn([renderId, 'availableModes']);
						// Protect from availableModes being null
						if(availableModes && availableModes.includes(newMode)) {
							state.setIn([renderId, 'currentMode'], newMode);
							setFromRenderStore(renderId, newMode, state);
						}
					}
				}
			});
		});
		return newState;
	} else {
		return state;
	}
}

/**
 * Process a page's modes.. once the page AND field stores are loaded.
 * 
 * @param {string} pageId 
 * @param {object} state
 * @return {object} Modified, new state
 */
function _processPageModes(pageId, state) {
	let newState = state;

	// let pageObj = PageStore.get(pageId),
	// 	attachedFieldsArr = ObjectUtils.getObjFromJSON(pageObj.attachedFields);

	// if(Array.isArray(attachedFieldsArr)) {
	// 	newState = state.withMutations(function (state) {
	// 		// loop through components and extract fieldId and availableModes for each component
	// 		attachedFieldsArr.forEach(attachedFields => {
	// 			let fieldId = attachedFields.recordId;
	// 			let newObj = Immutable.fromJS({
	// 				'availableModes': 'view,edit',
	// 				'currentMode': 'view'
	// 			});
	// 			// save to state under fieldId
	// 			state.setIn([pageId, fieldId], newObj);
	// 			loadFromFieldSettings(fieldId, state);
	// 		});
	// 	});
	// }
	return newState;
}

const instance = new FieldModesStore(AppDispatcher);
export default instance;