var keyMirror = require('keymirror');

// Define action constants
var ConsumableCreditsConstants = keyMirror({
	'CONSUME_CREDIT': null,
	'CONSUME_CREDIT_ERROR': null,
	'CONSUMABLE_CREDIT_LOAD': null,
	'CONSUME_CREDIT_LIST_ERROR': null,
	'SET_RESOURCE_STATUS': null,
});
module.exports = ConsumableCreditsConstants;
