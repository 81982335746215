import React, {Component} from 'react';
import QueryDialog from './query-dialog.react';
import { ReactSVG } from 'react-svg';
import ContextStore from '../../stores/context-store';

/**
 * @class QueryButton
 * @extends {Component}
 */
class QueryButton extends Component {
	/**
	 * Creates an instance of QueryEditor
	 * 
	 * @param {Object} props
	 * @constructor
	 * 
	 * @memberof QueryEditor
	 */
	constructor(props) {
		super(props);
		this.state = {dialog: false, value: ''};
		this.toggleDialog = this.toggleDialog.bind(this);
		this.onChange = this.onChange.bind(this);
	}

	/**
	 * Toggles dialog to show
	 *
	 * @param {Boolean} true shows dialog, false hides
	 *
	 * @memberof QueryEditor
	 */
	toggleDialog(show, event) {
		this.setState({dialog: show});
	}

	/**
	 * Handles BlocklyDialog xml change
	 *
	 * @memberof ExpressionEditor
	 */
	onChange(event) {
		let {onChange, fieldSchemaName} = this.props;
		let eventObj = {
			target: {
				value: event.target.value,
				id: fieldSchemaName
			}
		};

		if (onChange) {
			onChange(eventObj);
		}
	}
	/**
	 * Render the QueryEditor
	 * 
	 * @returns React
	 * 
	 * @memberof QueryEditor
	 */
	render() {
		let {label, fieldSchemaName, value, namedContexts, isDisabled} = this.props,
			{dialog} = this.state;

		let citDev = window.citDev;

		let tableAndIcon = citDev.fieldComponents.query.getReturnTableAndIcon(this.props.value);
		let tableDiv = (<div className='text-muted ml-2 mt-1'>No Value</div>);
		
		if(tableAndIcon.tablePluralName) {
			let iconDiv = null;
			if(tableAndIcon.tableIconName) { 
				iconDiv = <div className={"mx-1 fa fa-" + tableAndIcon.tableIconName + " align-self-center"}></div>;
			}
		    tableDiv = (<div className="d-flex mt-1">{iconDiv} <h4>{tableAndIcon.tablePluralName}</h4></div>);
		}

		return(
			<div className={"queryButton d-flex mb-2 align-items-center"+(isDisabled ? ' isDisabled' : '')+(!!value && value !== '""' ? ' hasValue' : '')}>
				<button 
					className={`btn d-flex align-items-center justify-content-between mb-0 mt-1`}
					disabled={!!isDisabled}
					type="button" 
					title='Open query editor' 
					onClick={this.toggleDialog.bind(this, true)}>
					<span className='sr-only'>Open query editor</span>
					Query
						<ReactSVG
							className="ml-2 pb-1"
							src={ContextStore.getUrlMedia() + "/query-builder.svg"}
							alt="Expression icon" />
				</button>
				{tableDiv}
				{/* <button class="form-control col-4" type="button" title="Open query editor">
					<span class="fa fa-database"></span>
					<span class="sr-only">Open query editor</span>
				</button> */}

				{dialog ? 
					<QueryDialog 
						fieldSchemaName={fieldSchemaName || ''} 
						onChange={this.onChange} 
						onClose={this.toggleDialog.bind(this, false)} 
						value={value} 
						title={label}
						namedContexts={namedContexts}
						/> 
					: null}
				{dialog ? <div className="modal-backdrop fade show" /> : null}
			</div>
		);
	}
}
export default QueryButton;