import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from './select.react';
import _ from 'lodash';
import FormBuilder from '../../utils/form-builder';
import FieldStore from '../../stores/field-store';
import {UIUtils} from '../../utils';

require ('../../styles/components/_child-field-table.scss');

/**
 * Table of child fields - for one screen size.
 * 
 * @class AttachmentChildrenTable
 * @extends {Component}
 */
class AttachmentChildrenTable extends Component {
	/**
	 * Create instance of fieldTypeChooserContainer
	 *
	 * @memberof AttachmentChildrenTable
	 */
	constructor(props) {
		super(props);
		this.screensizeHeaderNames = {
			'lg': 'Desktop',
			'md': 'Tablet',
			'sm': 'Mobile'
		};
		this._getFieldSetting = this._getFieldSetting.bind(this);
		this._onChildSettingValueChange = this._onChildSettingValueChange.bind(this);
		this._onScreenSizeManagementChange = this._onScreenSizeManagementChange.bind(this);
		this._renderAttachmentChildrenTable = this._renderAttachmentChildrenTable.bind(this);
		this.updateAppearance = this.updateAppearance.bind(this);
		this.getScreensizeManageOptions = this.getScreensizeManageOptions.bind(this);
	}
	/**
	 * 
	 * @param {String} screensize 
	 * @returns  Object for the Manage Dropdown for the specific screensize 
	 * 
	 * @memberof AttachmentChildrenTable
	 */
	getScreensizeManageOptions(screensize){
		let screensizeManageOptions =  {
			'lg': [
				{ name: 'Myself', value: 'lg'}, 
				{ name: 'Tablet', value: 'md'}, 
				{ name: 'Mobile', value: 'sm'}],	
			'md': [
				{ name: 'Myself', value: 'md'}, 
				{ name: 'Desktop', value: 'lg'}, 
				{ name: 'Mobile', value: 'sm'}],	
			'sm': [
				{ name: 'Myself', value: 'sm'}, 
				{ name: 'Desktop', value: 'lg'}, 
				{ name: 'Tablet', value: 'md'}],	
		};

		return screensizeManageOptions[screensize]
	}

	/**
	 * Pull a setting for a field.  If it's JSON, then parse it!
	 * 
	 * @param {string} fieldId What Field to get the setting from.
	 * @param {string} settingName What setting to get
	 * @param {boolean} settingIsJSON Whether to JSON.parse the value before returning
	 * @returns {mixed} string or object.
	 * 
	 * @memberof AttachmentChildrenTable
	 */
	_getFieldSetting(fieldId, settingName, settingIsJSON) {
		let fieldSettings = FieldStore.getSettings(fieldId);
		if(fieldSettings) {
			// Pull the columns config and stringify
			let settingValue = fieldSettings[settingName];
			if(settingIsJSON && settingValue) {
				settingValue = JSON.parse(settingValue);
			}
			return settingValue;
		}
		return null;
	}
	/**
	 * Change a setting value for a child field.
	 * 
	 * @param {string} childFieldId Field ID of the child Field (IE, the List's Column, or : Note Body)
	 * @param {string} settingSchemaName Schema Name of the child field setting we're changing (IE, Resizable)
	 * @param {string} settingValue New value for this setting.
	 * @param {Function} onChange Function to run with the new value, on change.
	 * 
	 * @memberof AttachmentChildrenTable
	 */
	_onChildSettingValueChange(childFieldId, settingSchemaName, settingValue) {
		let value = this.props.value;
		if(value) {
			// make sure this screensize is managing itself...
			if(Array.isArray(value)) {
				let myColumnConfig = value.find(childFieldConfig => {
					if(childFieldConfig.fieldId === childFieldId) {
						return true;
					}
					return false;
				});
				// If we found this child field...
				if(myColumnConfig) {
					//Update it's setting's value
					myColumnConfig[settingSchemaName] = settingValue;

					// Push to store.
					this.props.onChange({ target: {
						screenSize: this.props.screenSize,
						value: value
					}});
				} else {
					console.warn('Unable to find my column config');
				}
			} else {
				console.warn('Value is not an array in _onChildSettingValueChange?');
			}
		} else {
			console.warn('value is empty in _onChildSettingValueChange');
		}
	}

	/**
	 * Handle when screen size management changes.
	 * 
	 * @param {string} newManageValue strings lg, md, or sm to signify that this screensize config is changing to be managed this way.
	 * @param {Function} onChange Function to run with the new value, on change.
	 * 
	 * @memberof AttachmentChildrenTable
	 */
	_onScreenSizeManagementChange(newManageValue, onChange) {
		let {screenSize} = this.props,
			value = this.props.value;

		// If we are not managing ourselves, set the value for this screensize to wherever the managment will be.
		if(newManageValue !== screenSize) {
			value = newManageValue;
		} else {
			// If we're managing ourselves, then setup an empty array
			value = [];
		}
		// Push to store.
		this.props.onChange({ target: {
			screenSize: this.props.screenSize,
			value: value
		}});
	}
	/**
	 * 
	 * 
	 * @param {any} recordId 
	 * @param {any} tableSchemaName 
	 * 
	 * @memberof AttachmentChildrenTable
	 */
	updateAppearance(childFieldId, fieldSchemaName, event){
		event.preventDefault();
		UIUtils.openSettingsPanel('appearance',
			childFieldId, 
			fieldSchemaName,
			this.props.recordId,
			this.props.tableSchemaName
		);
	}
	/**
	 * Render the JSX for the Child Fields Table, if our value is NOT a string.
	 * 
	 * @param {mixed} value Our table's Value.. either a string, or an array
	 * @param {Object} childColumnSettings Definition of the columns of our table
	 * @param {Function} onChange Function to run with the new value, on change.
	 * @todo Split Header Row into a functions
	 * @todo Split Body Rows into a function
	 * @todo Split empty/add row into a function
	 * @memberof AttachmentChildrenTable
	 */
	_renderAttachmentChildrenTable(value, childColumnSettings, onChange) {
		let rowColIndex = 0;

		// Check to see if our screensize config is an array...
		if(Array.isArray(value)) { 
			// Loop over the child columns and generate our table's header.
			let headerColumns = [];
			headerColumns.push(<th key={rowColIndex} className="text-center">Field</th>);
			rowColIndex++;
			childColumnSettings.forEach(childColumnSetting => {
				headerColumns.push(<th key={rowColIndex} className="text-center">{childColumnSetting.label}</th>);
				rowColIndex++;
			});
			headerColumns.push(<th key={rowColIndex} className="text-center"></th>);
			rowColIndex++;

			// Loop over the value and generate one row for each object in the array.
			let bodyRows = [];
			value.forEach(childField => {
				var rowColumns = [],
					childFieldId = childField.fieldId,
					childFieldLabel = '[ No Field Label ]',
					childFieldSettings = FieldStore.getSettings(childFieldId);
				//Check we find the fieldType first...
				if(childFieldSettings){
					childFieldLabel = childFieldSettings.fieldLabel;
				}	
	
					let showChildFieldInAppearance = this.updateAppearance.bind(this, childFieldId, 'field');
				// First Column is only rendering the Field Label as a link to the Field 
				rowColumns.push(
					<td className="text-center" key={rowColIndex}>
				 		<a href="#" onClick={showChildFieldInAppearance}>{childFieldLabel}</a>
				 	</td>
				);
				rowColIndex++;
				
				// Loop over each child column setting and add it's column...
				childColumnSettings.forEach(childColumnSetting => {
					let componentName = _.upperFirst(childColumnSetting.dataType),
						component = FormBuilder.componentFor(componentName),
						// Create child props from an empty object {}, merged with childColumnsettings, merged with our own object.
						childProps = Object.assign({}, childColumnSetting, {
							onChange: function(event){
								let newValue = event.target.value;
								this._onChildSettingValueChange(childFieldId, childColumnSetting.fieldSchemaName, newValue);
							}.bind(this),
							value: childField[childColumnSetting.fieldSchemaName],
							fieldTypeId: childField['fieldTypeId']
						});
					rowColumns.push((
						<td className="text-center" key={rowColIndex}>
							{React.createElement(component, childProps, null)}
						</td>
					));
					rowColIndex++;
				});

				rowColumns.push(<td key={rowColIndex} className="text-center">
						<button type="button" className="close" aria-label="close" onClick={() => this.props.onChildFieldDelete(childFieldId)}>
							<i className="fa fa-minus" aria-hidden="false"></i>
						</button>
					</td>);
				rowColIndex++;

				bodyRows.push((
					<tr key={rowColIndex}>{rowColumns}</tr>
				));
				rowColIndex++;
			});

			// Add an empty row for a new value
			// Add the field selector column (FIRST!)
			var componentName = 'FieldSelector',
				component = FormBuilder.componentFor(componentName),
				fieldSelectorProps = {
					value: '',
					showEmptyOption: true,
					recordId: this.props.recordId,
					screenSize: this.props.screenSize,
					tableSchemaName: this.props.tableSchemaName,
					onChange: function(event) {
						this.props.onChildFieldAdd(event.target.value)
					}.bind(this)
				};

			// Push the field selector on as the only cell, with a colSpan second cell to push all the way across (+1 !)
			bodyRows.push(
				<tr key={rowColIndex}>
					<td className="text-center" key={rowColIndex}>
						{React.createElement(component, fieldSelectorProps, null)}
					</td>
					<td colSpan={(childColumnSettings.length + 1)}></td>
				</tr>
			);
			rowColIndex++;
			
			return (
				<div className="row">
					<table className="table table-striped">
					<thead>
						<tr>
							{headerColumns}
						</tr>
					</thead>
					<tbody>
						{bodyRows}
					</tbody>
					</table>
				</div>
			);
		} else {
			return null;
		}
	}

	/**
	 * Render the Child Field Table, for a screen size
	 * 
	 * @returns React
	 * 
	 * @memberof AttachmentChildrenTable
	 */
	render() {
		let {onChange, screenSize, value, disableScreenSizesArray} = this.props,
			headerTitle = this.screensizeHeaderNames[screenSize] + ' screen size settings',
			// Assume that we are managing ourselves:
			manageSelectValue = screenSize,
			childColumnSettings = this.props.childSettings;

		let childFieldSettingsTable = this._renderAttachmentChildrenTable(value, childColumnSettings, onChange);	

		// Look up the screen size options for *this* screensize
		// let screensizeManageOptions = JSON.parse(JSON.stringify(this.screensizeManageOptions[screenSize]));
		let screensizeManageOptions = this.getScreensizeManageOptions(screenSize);
		Object.keys(screensizeManageOptions).forEach(optionKey => {
			let option = screensizeManageOptions[optionKey];
			disableScreenSizesArray.forEach(disabledScreenSize => {
				if(option.value === disabledScreenSize && option.value !== screenSize) {
					option.disabled = true;
				}
			})
		});

		// Check to see if our screensize config is an array...
		if(!Array.isArray(value)) { 
			// If it is NOT, then we must be managed by another screensize.. and we are that screen size's value (md or sm for instance)
			manageSelectValue = value;
		}

		// Setup the props for the manager select
		let selectProps = {
			selectOptions: screensizeManageOptions,
			value: manageSelectValue,
			onChange: function(event){
				this._onScreenSizeManagementChange(event.target.value, onChange);
			}.bind(this)
		};

		return (
			<div className="container-fluid table-wrapper">{/* Header Div */}
				<div className="row">
					<div className="col-8">
						<h5>{headerTitle}</h5>
					</div>
					<div className="col-4 no-padding">
						<div className="container no-padding">
							<div className="row">
								<div className="ml-auto col-3 manage-title no-padding">
									<strong>Manage:</strong>
								</div>
								<div className="col-5 no-padding">
										<Select {...selectProps}/>
								</div>
							</div>
						</div>
					</div>
				</div>
				{childFieldSettingsTable}
			</div>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	AttachmentChildrenTable.propTypes = {
		fieldId: PropTypes.string,
		fieldSchemaName: PropTypes.string,
		childSettings: PropTypes.array,
		screenSize: PropTypes.string,
		onChange: PropTypes.func,
		onChildFieldAdd: PropTypes.func,
		onChildFieldDelete: PropTypes.func,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		tableSchemaName: PropTypes.string,
		recordId: PropTypes.string,
		disableScreenSizesArray: PropTypes.array
	};
}

export default AttachmentChildrenTable;