import React from 'react';
import PropTypes from 'prop-types';
import {compact} from 'lodash';
import BaseSettingForm from './base-setting-form.react';
import FormBuilder from '../../utils/form-builder';

/**
 * Component for Name
 * 
 * @class Operators
 * @extends {Component}
 */
export default class Operators extends BaseSettingForm {
	/**
	 * Creates instance of Operators
	 * 
	 * @returns React
	 * 
	 * @memberof Operators
	 */
	constructor(props) {
		super(props);
		this.state = {value: null};
		this._onChange = this._onChange.bind(this);
		this._renderOperator = this._renderOperator.bind(this);
	}

	/**
	 * Render the Operators
	 * 
	 * @returns React
	 * 
	 * @memberof Operators
	 */
	render() {
		let {operators} = this.props;
		if (!operators || !operators.length) {
			return null;
		}
		return(
			<div className="form-control">
				{operators.map(this._renderOperator)}
			</div>
		);
	}

	/**
	 * private handles changeEvent and bubbles up
	 * 
	 * @memberof Operators
	 */
	_onChange(e) {
		let {onChange, fieldSchemaName, value} = this.props,
			operators = compact(value ? value.split(',') : []),
			{target:{id,checked}} = e,
			changeEvent = {
				target: {
					id: fieldSchemaName,
				}
			};

		if (!operators.includes(id) && checked) {
			operators.push(id);
			changeEvent.target.value = operators.join(',');
		} else if (operators.includes(id) && !checked) {
			let i = operators.indexOf(id);
			operators.splice(i, 1);
			changeEvent.target.value = operators.join(',');
		}

		if (onChange) {
			onChange(changeEvent);
		}
	}

	/**
	 * private Renders the Operator checkbox
	 * 
	 * @returns React
	 * 
	 * @memberof Operators
	 */
	_renderOperator(operator, index) {
		let {value} = this.props,
			operators = value ? value.split(',') : [],
			checked = operators.includes(operator),
			labelString = FormBuilder.operatorLabel(operator);
		return (
			<label key={index} className="form-check-label col-sm-4">
				<input id={operator} type="checkbox" className="form-check-input" checked={checked} onChange={this._onChange} />
				<div>
					{labelString}
				</div>
			</label>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	Operators.propTypes = Object.assign({}, BaseSettingForm.propTypes, {
		operators: PropTypes.array.isRequired
	});
}
