import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AdminSettingsActions } from '../../../actions';
import { MetadataStore } from '../../../stores';

import { split as SplitEditor } from "react-ace";

// var BraceDiff = require('brace-diff');
// import 'brace/mode/jsx';
// import 'brace/theme/monokai';
// import 'brace/ext/searchbox';

/**
 * Component for CodeReview
 * 
 * @class CodeReview
 * @extends { Component }
 */
export default class CodeReview extends Component {
	/**
	 * Creates an instance of CodeReview
	 * 
	 * @param {Object} props
	 * @constructor
	 */
	constructor(props) {
		super(props);
		this.onClose = this.onClose.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
	}
	/**
	 * @static
	 * @returns {Array of Object}
	 */
	static getStores() {
		return [ MetadataStore ];
	}

	/**
	 * Close the modal
	 */
	onClose() {
		AdminSettingsActions.closeModal('codeReview');
	}
	/**
	 * When value changes it saves and updates the changes in the variant modal
	 * 
	 */
	onSave() {
		// let { onChange, onSave } = this.props;
		// let braceDiff = new BraceDiff({
		// 	mode: "ace/mode/jsx",
		// 	theme: "ace/theme/monokai",
		// 	left: {
		// 		editable: false,
		// 	},
		// 	right: {
		// 		copyLinkEnabled: false,
		// 	}
		//   });

		// let editors = braceDiff.getEditors();

		/* gets the updated value from the right editor */
		// let updatedNewCode = editors.right.getValue();

		/* Updates Changes in the variant-modal */
		// onChange( { target: { value: updatedNewCode } } );
		// onSave(false);
		// this.onClose();
	}
	/**
	 * 
	 */
	onKeyDown(event) {
		// Check if keys pressed are ctrl+s
		if(event.ctrlKey && event.key === 's') {
			event.preventDefault();
			this.onSave();
		}
		// when on MAC
		if(event.metaKey && event.key === 's') {
			event.preventDefault();
			this.onSave();
		}
	}
	/**
	 * loads code editors
	 */
	// componentDidMount() {
		// let { originalCode, newCode } = this.props;
		
		// let braceDiff = new BraceDiff({
		// 	mode: "ace/mode/jsx",
		// 	theme: "ace/theme/monokai",
		// 	left: {
		// 		editable: false,
		// 	},
		// 	right: {
		// 		copyLinkEnabled: false,
		// 	}
		//   });
		// let editors = braceDiff.getEditors();

		// editors.left.setValue(originalCode, 1);
		// editors.right.setValue(newCode, 1);
	// }

	/**
	 * Render the CodeReview
	 * 
	 */
	render() {
		let { originalCode, newCode } = this.props;
		return(
			<div className="code-review-modal modal-dialog modal-lg show">
				<div className="modal-content container">
					<div className="modal-header justify-content-end cd-bg-2 align-items-center">
						<h1 className="modal-title align-self-center mr-auto">
							Code Review
						</h1>
						{/* Save button */}
						<button 
							key="submit" 
							className="btn btn-primary" 
							aria-label="Save" 
							onClick={this.onSave}>
							Save
						</button>
						{/* Close */}
						<button 
							type="button" 
							className="close float-right m-0 align-self-center p-0" 
							aria-label="Close"
							onClick={this.onClose} 
						>
							&times;
						</button>
					</div>
					<div className="modal-body container d-flex flex-column">
						<div className="row mb-2">
							<h4 className="col-6 text-center">Original Variant Code</h4>
							<h4 className="col-6 text-center">New Variant Code</h4>
						</div>
						<div id="flex-container" className="row">
						<SplitEditor
							mode="javascript"
							theme="solarized_dark"
							splits={2}
							orientation="beside"
							value={[originalCode, newCode]}
							name="codeReviewDiv"
							editorProps={{ $blockScrolling: true }}
						/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	CodeReview.displayName = 'CodeReview';
	CodeReview.propTypes = {
		originalCode: PropTypes.string,
		newCode: PropTypes.string,
		onChange: PropTypes.func,
		onSave: PropTypes.func
	};
}