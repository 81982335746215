import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import {LocalContextConstants} from '../constants';
import ExpressionProcessor from '../utils/expression-processor';
import ContextStore from '../stores/context-store';
import LocalContextStore from '../stores/local-context-store';

const LocalContextActions = {
	/**
	 * Processes a local (page > field or field > field) setting for a recordID
	 * 
	 * @param {string} renderId field Container Id for this field
	 * @param {string} recordId field recordId
	 * @param {string} tableSchemaName field TableSchemaName
	 * @param {object} settings { expressionSettingFieldSchemaName: expressionSettingUnProcessedValue }
	 */
	processLocalContext(renderId, recordId, tableSchemaName, settings) {
		// @TODO: Update this to use and respect the calculation start time, rather than dealing with this getValuesAreLoading nonsense
		
		// Instantiate the local context store first
		// @TODO: Do we actually need this? Does it do anything?
		if (!LocalContextStore.getValuesAreLoading(renderId)) {
			AppDispatcher.dispatch(Immutable.fromJS({
				type: LocalContextConstants.PROCESS_LOCAL_CONTEXT,
				renderId: renderId,
				settings: settings
			}));

		}
		// Because multiple expression settings are rare and expressions are usually fast, we wait until they have all resolved to dispatch.
		// This helps cut down on timing issues
		let startTime = +new Date();

		let expressionPromises = Object.keys(settings).map(settingKey => {
			try {
				let expressionObj = JSON.parse(settings[settingKey]);
				let expressionPromise = Promise.resolve();
				if(expressionObj
					&& expressionObj.optimizationScheme === 'static'
					&& expressionObj.optimizationData
					&& expressionObj.optimizationData.value
				) {
					// Just resolve static values instead of bothering the expression processor
					expressionPromise = Promise.resolve(expressionObj.optimizationData.value);
				} else {
					expressionPromise = ExpressionProcessor.processExpression({
						expression: expressionObj.generatedJavascript,
						recordId: recordId,
						renderId: renderId,
						tableSchemaName: tableSchemaName,
						installationId: ContextStore.getInstallationId(),
						// If we're passed in a record ID and TSN, use that for our starting context. (Quick-fix for 6882)
						namedContexts: (recordId && tableSchemaName) ? {
							startingContext: [{
								recordId,
								tableSchemaName
							}]
						} : undefined
					});
				}
				return Promise.all([settingKey, expressionPromise]).catch(error => {
					console.error('Error processing expression %s for %s %s', settingKey, tableSchemaName, recordId, error);
					console.error('Expression was', expressionObj.generatedJavascript);
					return [settingKey, undefined];
				});
			} catch (e) {
				console.warn('Unable to parse JSON for expression processing: ', settings[settingKey]);
				return Promise.all([settingKey, undefined]);
			}
		});
		Promise.all(expressionPromises)
			.then(results => {
				let expressionResultObj = {};
				results.forEach(([settingKey, expressionResult]) => {
					expressionResultObj[settingKey] = expressionResult;
				});
				AppDispatcher.dispatch(Immutable.fromJS({
					type: LocalContextConstants.LOCAL_CONTEXT_UPDATE,
					renderId: renderId,
					settings: expressionResultObj,
					lastDt: startTime
				}));
			})
			.catch(error => {
				console.error('General error handler called when processing expression settings for %s %s: ', tableSchemaName, recordId, error);
			});
	},
	/**
	 * Updates the values for the RenderId's settings 
	 * @param {string} renderId 
	 * @param {object} settings 
	 */
	update(renderId, settings) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: LocalContextConstants.LOCAL_CONTEXT_UPDATE,
			renderId: renderId,
			settings: settings
		}));
	},

	/**
	 * Updates the Local Context Store in a Bulk Manor
	 * 
	 * @param {Object[]} updates 
	 * @param {string} updates[].renderId
	 * @param {Object} updates[].settings
	 */
	updateBulk(updates) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: LocalContextConstants.LOCAL_CONTEXT_UPDATE_BULK,
			updates: updates
		}));
	}
};

export default LocalContextActions;