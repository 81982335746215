import AssistantNLP from './assistant-nlp-utils.js';


/**
 * 
 * Creates a NlpProcessor from inputted tokens in order to iterate over tokens
 * and break them down into a more workable set of discrete actions on fields.
 * 
 * @param {array} tokens The tokens array returned from a Google NL call
 * @param {object} quotedValuesObj  Any quoted values keyed by the value they were replaced with
 */
function NlpProcessorObj (data, quotedValuesObj) {

	// Instantiate variables
	let toReturn = [],
		sentences = data && data.sentences ? data.sentences.map(sentence => sentence.text).join(' ') : '',
		tokens = data ? data.tokens : [],
		len = tokens.length,
		roots = [],
		token;
	
	// Iterate over each token
	for (let i = 0; i < len; i++) {
		token = tokens[i];

		// Go through and replace any quoted values that have been swapped out for processing.
		if (quotedValuesObj && quotedValuesObj[token.text.content]) {
			let newText = quotedValuesObj[token.text.content];
			token.text.content = token.lemma = newText;
			token.isQuotedVal = true;
		}

		// Adds the index to the token for quicker processing later
		token.index = i;

		// Add any roots found to the roots array to iterate over later
		if (token.dependencyEdge.label === 'ROOT') {
			roots.push(token);
		}
	}

	// Iterate over each token again now that the appropriate processing has been done on each one
	for (let j = 0; j < len; j++) {
		token = tokens[j];

		// Tries to find the noun to which any pronoun is referring and link it if a candidate is found
		if (token.partOfSpeech.tag === 'PRON') {
			let ancestor = tokens[token.index];
			while (ancestor && ancestor.partOfSpeech.tag !== 'NOUN') {
				if (ancestor.dependencyEdge.label === 'ROOT') {
					ancestor = null;
					break;
				}
				ancestor = tokens[ancestor.dependencyEdge.label];
			}
			if (!ancestor) {
				// Mark that this is referring to the default context ('Make this red', etc.)
				token.noun = null;
			} else {

				// Link the noun to which this pronoun probably refers
				tokens[ancestor.index].pronouns = tokens[ancestor.index].pronouns ? tokens[ancestor.index].pronouns : [];
				tokens[ancestor.index].pronouns.push(token.index);
				token.noun = ancestor.index;
			}
		}
	}

	// Initialize structuredResults as an empty array, bind tokens and roots to this to use within util functions
	this.structuredResults = [];
	this.tokens = tokens;
	this.sentences = sentences;
	this.roots = roots;


	/**
	 * 
	 * Function to find all children of a token which have 'adjective' roles in the sentence.
	 * (It is not sufficient to only find "adjective" parts of speech because some adjectives may be ID's as nouns by Google NL)
	 * 
	 * @param {object} tokenChildren The children of the token to be filtered according to the adjective roles
	 * @returns {array} The concatenated array of all adjective role children
	 */
	this.adjectiveBuilder = function(tokenChildren) {
		if (!tokenChildren) {
			return [];
		}
		return (tokenChildren['NUM'] || []).concat(tokenChildren['ADVMOD'] || []).concat(tokenChildren['AMOD'] || []).concat(tokenChildren['NEG'] || []).concat(tokenChildren['ACOMP'] || []).concat((tokenChildren['CCOMP'] || []))
			.concat((tokenChildren['PRT'] || [])).concat((tokenChildren['VMOD'] || []))
			.concat((tokenChildren['POSS'] || []));
	};


	
	/**
	 * 
	 * Iterates over a token to build adjective sets appropriately.
	 * 
	 * @param {array} tokens The tokens array returned from a Google NL call
	 * @param {object} tokenChildren  The children of the token as arrays within an object keyed by the dependency role
	 * 
	 * @returns {object} The formatted object broken down by adjectives, objectUnit, quotedObjs and other appropriate properties
	 */
	this.adjectiveProcessor = function(token, tokenChildren) {

		// Initialize variables used later
		let adjectives = [], object = {}, nns = [], preps = [], quotedObjs = [];
		
		if(token.isQuotedVal) {
			quotedObjs.push(token.lemma);
		}

		// In the event that there are any NSUBJ children, set the object to it.
		(tokenChildren['NSUBJ'] || []).forEach((tokenTwo) => {
			let result = this.tokenProcessor(tokenTwo),
				len = result ? result.length : 0;
			for (let i = 0; i < len; i++) {
				if (result[i]) {
					object = result[i];
				}
			}
		});

		// For any adjective children, add them to the objectAdjectives array
		this.adjectiveBuilder(tokenChildren).forEach((tokenTwo, indexTwo) => {
			if(tokenTwo.isQuotedVal) {
				let newVal = this.buildFullPhrase(tokenTwo);
				quotedObjs.push(newVal);
			}
			let result = this.tokenProcessor(tokenTwo),
				len = result ? result.length : 0;
			for (let i = 0; i < len; i++) {
				if (result[i].objectAdjectives) {
					adjectives = adjectives.concat(result[i].objectAdjectives);
				}
			}
		});

		// Look over any conjunctions and process appropriately based on whether any objects or adjectives are found
		(tokenChildren['CONJ'] || []).forEach((tokenTwo, indexTwo) => {
			let result = this.tokenProcessor(tokenTwo),
				len = result ? result.length : 0;
			for (let i = 0; i < len; i++) {
				if (result[i].objectAdjectives) {
					adjectives = adjectives.concat(result[i].objectAdjectives || []);
				}
				if (result[i].objectUnit) {
					nns.push(result[i].objectUnit);
				}
			}
		});

		// Look over any NN children and push to the nns array
		(tokenChildren['NN'] || []).forEach((tokenTwo, indexTwo) => {
			let result = this.tokenProcessor(tokenTwo),
				len = result ? result.length : 0;
			for (let i = 0; i < len; i++) {
				if (result[i].objectUnit) {
					nns.push(result[i].objectUnit);
				}
			}
		});

		// ATTR role words tend to fulfill quoted value roles, so add them to quotedObjs
		(tokenChildren['ATTR'] || []).forEach((tokenTwo) => {
			if (!quotedObjs) {
				quotedObjs = [];
			}
			let newVal = this.buildFullPhrase(tokenTwo);
			quotedObjs.push(newVal);
		});

		// Look over any prepositions and add to preposition array
		(tokenChildren['PREP'] || []).forEach((tokenTwo) => {
			let results = this.tokenProcessor(tokenTwo);
			// If it's applying to multiple entities
			if (results) { 
				preps = preps.concat(results);
			}
		});

		// Concat any adjectives from the object variable to adjectives array
		adjectives = adjectives.concat(object.objectAdjectives || []);

		// The nns array should typically include token.text but may be modified from it. Possessives are dealt with as a special unit.

		let ps = tokenChildren['PS'] && tokenChildren['PS'][0] && tokenChildren['PS'][0].text.content;

		let nnToPush = ps ?
			token.text.content + ps :
			token.lemma;
		
		// Add the token.lemma with any modifications to nns
		nns.push(nnToPush);

		// Join the nn array and push it as an adjective
		adjectives.push(nns.join(' '));

		// Return a single array of one object including any objectUnit, objectAdjective and quotedObjs defined above
		toReturn = [{objectUnit: object.objectUnit, objectAdjectives: adjectives, quotedObjs}];

		// Add a det (the/a/etc.) to toReturn's object only if one exists
		if (object.det) {
			toReturn[0].det = object.det
		}

		// Return the object array built in toReturn
		return toReturn;
	}.bind(this);

	/**
	 * 
	 * Builds the full phrase as a string given a token. (For example, finds the full string of a preposition's POBJ to use in a quoted value)
	 * 
	 * @param {array} token The token being used as the 'parent' for the fullPhrase
	 * 
	 * @returns {string} The full string of the token and all dependent tokens, in order with formatting
	 */
	this.buildFullPhrase = function(token) {
		let tokenChildren = this.findChildren(token, token.index);
		let min = token.index, max = token.index;
		Object.keys(tokenChildren).forEach((childType) => {
			tokenChildren[childType].forEach((child) => {
				if (child.index > max) {
					max = child.index;
				}
				if (child.index < min) {
					min = child.index;
				}
			})
		});
		let phraseObjs = this.tokens.slice(min, max + 1), toReturn = '', len = phraseObjs.length;
		for (let i = 0; i < len; i++) {
			let phraseObj = phraseObjs[i];
			let padding = (phraseObj && (phraseObj.partOfSpeech.tag === 'PUNCT' || phraseObj.partOfSpeech.tag === 'PRT')) ?
				'' :
				(!i || (phraseObjs[i-1] && phraseObjs[i-1].partOfSpeech.tag === 'PUNCT')) ? '' : ' ';
			toReturn += padding + phraseObj.text.content;
		}
		return toReturn;
	}.bind(this);


	/**
	 * 
	 * Finds the minimum and maximum indices of the children of a token. Recursive.
	 * 
	 * @param {object} token The token being used as the 'parent' for the fullPhrase
	 * 
	 * @returns {object} An object tracking the min and max for the token.
	 */
	this.findMaxAndMin = function(token) {
		let tokenChildren = this.findChildren(token, token.index);
		let min = token.index, max = token.index;
		Object.keys(tokenChildren).forEach((childType) => {
			tokenChildren[childType].forEach((child) => {
				let {min: childMin, max: childMax} = this.findMaxAndMin(child);
				if (childMax > max) {
					max = childMax;
				}
				if (childMin < min) {
					min = childMin;
				}
			})
		});
		return {max, min};
	}.bind(this);

	/**
	 * 
	 * Builds the full phrases separated by conjunctions as an arrray given a token.
	 * 
	 * @param {array} token The token being used as the 'parent' for the fullPhrase
	 * 
	 * @returns {array} An array where each index is the full string of the token and all dependent tokens, in order with formatting
	 */
	this.buildFullPhraseArray = function(token) {
		// let tokenChildren = this.findChildren(token, token.index);
		let min = token.index + 1, {max} = this.findMaxAndMin(token);
		// Object.keys(tokenChildren).forEach((childType) => {
		// 	tokenChildren[childType].forEach((child) => {
		// 		if (child.index > max) {
		// 			max = child.index;
		// 		}
		// 		// if (child.index < min) {
		// 		// 	min = child.index;
		// 		// }
		// 	})
		// });
		let phraseObjs = this.tokens.slice(min, max + 1), toReturn = [], currentString = '', len = phraseObjs.length;
		for (let i = 0; i < len; i++) {
			let phraseObj = phraseObjs[i];
			if((phraseObj.dependencyEdge.label === 'P' || phraseObj.dependencyEdge.label === 'CC') && currentString) {
				toReturn.push(currentString);
				currentString = '';
			} else {
				let padding = (phraseObj && (phraseObj.partOfSpeech.tag === 'PUNCT' || phraseObj.partOfSpeech.tag === 'PRT')) ?
					'' :
					(!i || (phraseObjs[i-1] && phraseObjs[i-1].partOfSpeech.tag === 'PUNCT')) ? '' : ' ';
				currentString += padding + phraseObj.text.content;	
			}
		}
		if (currentString) {
			toReturn.push(currentString);
		}
		return toReturn;
	}.bind(this);

	/**
	 * 
	 * Iterates over a token to build 'entity' function sets (nouns/pronouns) appropriately.
	 * 
	 * @param {array} tokens The tokens array returned from a Google NL call
	 * @param {object} tokenChildren  The children of the token as arrays within an object keyed by the dependency role
	 * 
	 * @returns {object} The formatted object broken down by adjectives, objectUnit, quotedObjs and other appropriate properties
	 */
	this.entityProcessor = function(token, tokenChildren) {
		
		// Initialize variables
		let toReturn = {};
		let objectUnitArr = [];
		let objectArr = [];
		let objectAdjectives = [];
		let quotedObjs = [];
		let preps = [];
		let det;

		// Finds any DET children ('a', 'the', etc.)
		(tokenChildren['DET'] || []).forEach((tokenTwo, indexTwo) => {
			det = tokenTwo.lemma;
		});

		// Find all adjective type children and any quotedObjs attached to them
		this.adjectiveBuilder(tokenChildren).forEach((tokenTwo, indexTwo) => {
			let result = this.tokenProcessor(tokenTwo),
				len = result ? result.length : 0;
			for (let i = 0; i < len; i++) {
				if (result[i].objectAdjectives) {
					objectAdjectives = objectAdjectives.concat(result[i].objectAdjectives);
				}
				if (result[i].quotedObjs) {
					quotedObjs = quotedObjs.concat(result[i].quotedObjs);
				}
				if(result[i].preps) {
					preps = preps.concat(result[i].preps);
				}
			}
		});

		// Find any modifying nouns (e.g., 'phone' in 'phone number') and add to objectUnitArr
		// Also sees whether the attached nouns should result in multiple entities
		// (E.G. 'make an international phone number and address field' -> ' 'make an international phone number field and make an international address field')
		(tokenChildren['NN'] || []).forEach((tokenTwo, indexTwo) => {
			let result = this.tokenProcessor(tokenTwo);
			let len = result ? result.length : 0;
			for (let i = 0; i < len; i++) {
				if (result[i].objectUnit) {
					objectUnitArr.push(result[i].objectUnit);
				}
			}
		});

		let nnMapper = (nn) => {
			return {det: det, objectUnit: nn + ' ' + token.lemma, objectAdjectives: objectAdjectives.slice()};
		};

		// Looks over RCMOD children to add in any adjectives or object units as needed
		(tokenChildren['RCMOD'] || []).forEach((tokenTwo, indexTwo) => {
			let results = this.tokenProcessor(tokenTwo),
				len = results ? results.length : 0;
			for (let i = 0; i < len; i++) {
				if (results[i].acomps) {
					objectAdjectives = objectAdjectives.concat(results[i].acomps);
				}
				if (results[i].nns) {
					let toConcat = results[i].nns.map(nnMapper);
					// objectUnitArr = objectUnitArr.concat(toConcat);
					objectArr = objectArr.concat(toConcat);
				}
			}
		});

		if (objectUnitArr.length) {
			objectUnitArr.push(token.lemma);
			objectArr.push({
				objectUnit: objectUnitArr.join(' '),
				objectAdjectives: objectAdjectives.slice(),
				det: det
			});
		}

		// Looks over any conjunctions to apply to the Object Unit appropriately
		// Each token modifies nn and should be concatenated into all nn arrays.
		(tokenChildren['CONJ'] || []).forEach((tokenTwo, indexTwo) => {
			if(!objectArr.length) {
				objectArr.push({
					objectUnit: token.lemma,
					objectAdjectives: objectAdjectives.slice(),
					det: det
				});
			}
			let result = this.tokenProcessor(tokenTwo),
				len = result ? result.length : 0;
			for (let i = 0; i < len; i++) {
				if (result[i].objectUnit) {
					let toPush = result[i];
					if(!toPush.det) {
						toPush.objectAdjectives = objectAdjectives.concat(toPush.objectAdjectives);
						toPush.det = det;
					}
					objectArr.push(toPush);
					// objectUnitArr.push(result[i].objectUnit);
				}
			}
		});

		// Looks over XCOMP children to find further object units
		(tokenChildren['XCOMP'] || []).forEach((tokenTwo, indexTwo) => {
			let result = this.tokenProcessor(tokenTwo),
				len = result ? result.length : 0;
			for (let i = 0; i < len; i++) {
				if (result[i].objectUnit) {
					objectArr.push(result[i]);
					// objectUnitArr.push(result[i].objectUnit);
				}
			}
		});

		// Looks over PREP children to build any modifying prepositions
		(tokenChildren['PREP'] || []).forEach((tokenTwo) => {
			let result = this.tokenProcessor(tokenTwo);
			preps = preps.concat(result);
		});
		
		// Initializes objectUnit as token.lemma
		let objectUnit = token.lemma;

		// If token.lemma is classified as any value but a string
		// (E.G., if it's a color), pushes it into quotedObjs and clears objectUnit
		// This is done because Google NL will identify "red" in "make it red" as a noun, etc.
		if (AssistantNLP.classifyValue(objectUnit) !== 'string') {
			quotedObjs.push(objectUnit);
			objectUnit = '';
		}

		// If this is a quoted value, push into quoted values
		// (Does not remove it from objectUnit as above b/c it may potentially be appropriate in either.)
		if(token.isQuotedVal) {
			quotedObjs.push(objectUnit);
		}


		if (objectArr.length) {
			toReturn = [];
			objectArr.forEach((objectUnit) => {
				if (preps && preps.length) {
					objectUnit.preps = preps;
				}
				if (quotedObjs && quotedObjs.length) {
					objectUnit.quotedObjs = quotedObjs;
				}
				toReturn.push(objectUnit);	
			});
		} else {
			// Otherwise, make a toReturn object wrapping the values found by recursing over this.
			toReturn = preps && preps.length ? [{objectUnit, objectAdjectives, det, preps}] : [{objectUnit: token.lemma, objectAdjectives, det}];
			if (quotedObjs && quotedObjs.length) {
				toReturn[0].quotedObjs = quotedObjs;
			}
		}
		return toReturn;
	}.bind(this);

	/**
	 * 
	 * Iterates over a token to build any appropriate actions that it may dictate and push to structuredResults as appropriate
	 * 
	 * @param {array} tokens The tokens array returned from a Google NL call
	 * @param {object} tokenChildren  The children of the token as arrays within an object keyed by the dependency role
	 * 
	 * @returns {object} The formatted object broken down by adjectives, objectUnit, quotedObjs and other appropriate properties
	 */
	this.verbProcessor = function(token, tokenChildren) {
		
		// Initialize the variables
		let toReturn = {};
		toReturn.action = token.lemma;
		toReturn.objects = [];
		let preps = [], advmods = [];
		let operation = AssistantNLP.getOperation(token.lemma);
		
		// This is used for better keyword matching when Attaching or Detaching, as "Attach" and "Detach" operations generally contain only the name of a field
		// And so should not be parsed grammatically.
		if(operation && (operation['Attach'] || operation['Detach'])) {
			toReturn.attachOrDetachChildren = this.buildFullPhraseArray(token);
		}

		// DEP children are children of the token that Google can't otherwise figure out. This means a quotedObj in some cases and an object in others.
		// Figures out whether to create a toReturn value with quotedObjs or add values to toReturn.objects as appropriate
		(tokenChildren['DEP'] || []).forEach((tokenTwo) => {
			// We want to update this somehow to handle cases where it's a possible setting value
			if (token.dependencyEdge.label === 'XCOMP' || (tokenChildren['DOBJ'] && tokenChildren['DOBJ'].length)) {
				if (!toReturn.quotedObjs) {
					toReturn.quotedObjs = [];
				}
				toReturn.quotedObjs.push(this.buildFullPhrase(tokenTwo));
			} else {
				// Maybe only if this is an XCOMP?
				let results = this.tokenProcessor(tokenTwo);
				// If it's applying to multiple entities
				if (results) { 
					toReturn.objects = toReturn.objects.concat(results);
				}
			}
		});

		// ATTR children typically take the role of quoted values passed into a pattern. Adds to quotedObjs
		(tokenChildren['ATTR'] || []).forEach((tokenTwo) => {
			if (!toReturn.quotedObjs) {
				toReturn.quotedObjs = [];
			}
			toReturn.quotedObjs.push(this.buildFullPhrase(tokenTwo));
		});

		// DOBJ children may be a setting value (in which case they go in quotedObjs) or may be the object being acted on (like a field, etc.)
		(tokenChildren['DOBJ'] || []).forEach((tokenTwo) => {
			// We want to update this somehow to handle cases where it's a possible setting value
			if (token.dependencyEdge.label === 'XCOMP' && tokenChildren['IOBJ']) {
				if (!toReturn.quotedObjs) {
					toReturn.quotedObjs = [];
				}
				toReturn.quotedObjs.push(this.buildFullPhrase(tokenTwo));
			} else {
				// Maybe only if this is an XCOMP?
				let results = this.tokenProcessor(tokenTwo);
				// If it's applying to multiple entities
				if (results) { 
					toReturn.objects = toReturn.objects.concat(results);
				}
			}
		});

		// If there's an IOBJ, it's usually the 'object' of the pattern (a field, etc.). Concats to toReturn.objects
		(tokenChildren['IOBJ'] || []).forEach((tokenTwo) => {
			let results = this.tokenProcessor(tokenTwo);
			// If it's applying to multiple entities
			if (results) { 
				toReturn.objects = toReturn.objects.concat(results);
			}
		});

		/**
		 * 
		 * Finds any adjective type children and tries to apply to any objects as appropriate
		 * 
		 * @param {array} tokens The tokens array returned from a Google NL call
		 * @param {object} tokenChildren  The children of the token as arrays within an object keyed by the dependency role
		 * 
		 * @returns {object} The formatted object broken down by adjectives, objectUnit, quotedObjs and other appropriate properties
		 */
		this.adjectiveBuilder(tokenChildren).forEach((tokenTwo) => {
				let result = this.tokenProcessor(tokenTwo), len = result.length, oldAdjs = [];
				for (let i = 0; i < len; i++) {
					if (result[i].objectUnit || !toReturn.objects.length) {
						toReturn.objects.push(result[i]);
					} else {
						let newObject = Object.assign({}, toReturn.objects[toReturn.objects.length - 1]);
						oldAdjs = toReturn.objects[toReturn.objects.length - 1].objectAdjectives || [];
						oldAdjs = oldAdjs.concat(result[i].objectAdjectives || []);
						newObject.objectAdjectives = oldAdjs;
						toReturn.objects[toReturn.objects.length - 1] = newObject;
					}
				}
			}
		);

		// Evaluates prepositions and puts them in the preposition box
		(tokenChildren['PREP'] || []).forEach((tokenTwo) => {
			let results = this.tokenProcessor(tokenTwo);
			// If it's applying to multiple entities
			if (results) { 
				preps = preps.concat(results);
			}
		});

		// Looks for ADVMOD children and puts them in the ADVMOD box. Mostly useless rn but may be used to refine results later.
		(tokenChildren['ADVMOD'] || []).forEach((tokenTwo) => {
			let results = this.tokenProcessor(tokenTwo);
			// If it's applying to multiple entities
			if (results) { 
				advmods = advmods.concat(results);
			}
		});

		// Only adds a preps key to toReturn if it's a non-empty array
		if (preps && preps.length) {
			toReturn.preps = preps;
		}

		// Only adds an advmods key to toReturn if it's a non-empty array
		if (advmods && advmods.length) {
			toReturn.advmods = advmods;
		}

		// If this is a quoted value, it was probably a "to" prepositional phrase misidentified as a verb
		if(token.isQuotedVal && tokenChildren['AUX']) {
			return [{objectUnit: '', objectAdjectives: '', quotedObjs: [token.lemma]}];
		} else if ((toReturn.action && toReturn.action.toLowerCase() !== 'want') || toReturn.objects.length){
			//The purpose of this else if condition is to strip out any "I want to" stuff
			this.structuredResults.push(toReturn);
		}

		// Looks over any XCOMP children and adds to either toReturn.objects or object.quotedObjs as appropriate.
		(tokenChildren['XCOMP'] || []).forEach((tokenTwo) => {
			let results = this.tokenProcessor(tokenTwo);
			// If it's applying to multiple entities
			if (results && results.length) { 
				if (results.objectUnit) {
					toReturn.objects = toReturn.objects.concat(results);
				} else {
					toReturn.objects.forEach((object) => {
						if (!object.quotedObjs) {
							object.quotedObjs = [];
						}
						object.quotedObjs = results[0].quotedObjs && results[0].quotedObjs.length ?
							object.quotedObjs.concat(results[0].quotedObjs) :
							object.quotedObjs;
					});
				}
			}
		});

		// Looks over any 'CONJ' children and handles them appropriately. May find more objects, which will be added to toReturn.objects
		(tokenChildren['CONJ'] || []).forEach((tokenTwo) => {
			let results = this.tokenProcessor(tokenTwo);
			// If it's applying to multiple entities
			if (results) { 
				toReturn.objects = toReturn.objects.concat(results);
			}
		});
		
		return;
	}.bind(this);

	/**
	* Meat and potatoes function to which most children iterators defer.
	* Forwards to the verbProcessor, adjectiveProcessor or entityProcessor as appropriate
	* Returns an object appropriate to the role of the token by its dependency
	* 
	* @param {object} token The token being analyzed
	* @returns {object} The structured breakdown of the token and its children
	* 
	*/
	this.tokenProcessor = function(token) {
		if (!token) {
			return;
		}
		let tokenIndex = token.index;
		let tokenChildren = this.findChildren(token, tokenIndex);
		let toReturn = {};
		switch (token.dependencyEdge.label) {
			case 'XCOMP':
			case 'ROOT':
			case 'CONJ':
				// Conjunctions need to be forwarded to different processors based on role
				if (token.partOfSpeech.tag === 'VERB') {
					toReturn = this.verbProcessor(token, tokenChildren);
				} else if (token.partOfSpeech.tag === 'ADJ') {
					toReturn = this.adjectiveProcessor(token, tokenChildren);
				} else if (token.partOfSpeech.tag === 'NOUN') {
					toReturn = this.entityProcessor(token, tokenChildren);
				}
				break;
			case 'DEP':
			case 'DOBJ':
				toReturn = this.entityProcessor(token, tokenChildren);
				break;
			case 'NN':
				toReturn = this.entityProcessor(token, tokenChildren);
				break;
			case 'POBJ':
				toReturn = this.entityProcessor(token, tokenChildren);
				break;
			case 'NEG':
			case 'AMOD':			
			case 'ACOMP':
			case 'CCOMP':
			case 'PRT':
			case 'POSS':
			case 'NUM':
				toReturn = this.adjectiveProcessor(token, tokenChildren);
				break;
			case 'VMOD':
				// VMODs with prepositions should not be treated like adjectives
				// Instead, they should have their prepositions pulled out and be added
				// to those prepositions
				if(tokenChildren['PREP'] && tokenChildren['PREP'].length) {
					toReturn = [{
						preps: tokenChildren['PREP'].map(tokenTwo => {
							return Object.assign({
								action: token.lemma
							}, this.tokenProcessor(tokenTwo));
						})
					}];
				} else {
					toReturn = this.adjectiveProcessor(token, tokenChildren);
				}
				break;
			case 'RCMOD': 
			// RCMODs get special handling to find objectAdjectives and nns	
			let acomps = [], nns = [];
				this.adjectiveBuilder(tokenChildren).forEach((tokenTwo, indexTwo) => {
					let result = this.tokenProcessor(tokenTwo),
						len = result ? result.length : 0;
					for (let i = 0; i < len; i++) {
						if (result[i].objectAdjectives) {
							acomps = acomps.concat(result[i].objectAdjectives);
						}
					}
				});
				(tokenChildren['DOBJ'] || []).forEach((tokenTwo, indexTwo) => {
					let result = this.tokenProcessor(tokenTwo),
						len = result ? result.length : 0;
					for (let i = 0; i < len; i++) {
						if (result[i].objectAdjectives) {
							acomps = acomps.concat(result[i].objectAdjectives);
						}
						if (result[i].objectUnit) {
							nns = nns.concat(result[i].objectUnit);
						}
					}
				});
				(tokenChildren['PREP'] || []).forEach((tokenTwo, indexTwo) => {
					let result = this.tokenProcessor(tokenTwo);
					let textArr = [token.lemma, result.prep].concat(result.pobjs.map((pobj) => pobj.objectUnit));
					acomps.push(textArr.join(' '));
				});
				toReturn = [{acomps: acomps, nns: nns}];
				break;
			case 'NSUBJ':
				// NSUBJ also gets special handling to deal with PRON and DET children and RCMOD stuff
				if (token.partOfSpeech.tag === 'PRON' || token.partOfSpeech.tag === 'DET' ) {
					let objectUnitArr = [];
					let objectAdjectives = [];
					(tokenChildren['AMOD'] || []).forEach((tokenTwo, indexTwo) => {
						objectAdjectives = objectAdjectives.concat(this.tokenProcessor(tokenTwo));
					});
					(tokenChildren['NN'] || []).forEach((tokenTwo, indexTwo) => {
						let result = this.tokenProcessor(tokenTwo);
						if (result.objectUnit) {
							objectUnitArr.push(result.objectUnit);
						}
					});
					(tokenChildren['RCMOD'] || []).forEach((tokenTwo, indexTwo) => {
						let newResults = this.tokenProcessor(tokenTwo);
						if (newResults.acomps) {
							objectAdjectives = objectAdjectives.concat(newResults.acomps);
						}
						if (newResults.nns) {
							newResults.nns.forEach((nn) => {
								if (nn.objectAdjectives) {
									objectAdjectives = objectAdjectives.concat(nn.objectAdjectives);
								}
								objectUnitArr.push(nn.objectUnit);
							});
						}
					});
					if (!token.noun && token.noun !== 0) {
						//Treat this the same as a DOBJ because it doesn't point to a known noun
						objectUnitArr.push(token.lemma);
						
					}
					toReturn = [{objectUnit: objectUnitArr.join(' '), objectAdjectives}];
				} else if (token.partOfSpeech.tag === 'NOUN') {
					toReturn = this.entityProcessor(token, tokenChildren);
				}
				break;
			case 'DET':
				toReturn = token.lemma;
				break;
			case 'PREP':
				// PREPs are unique enough not to really get their own handler function. Just finds the prep and its full text object
				let pobjs = [], fullText = '';
				(tokenChildren['POBJ'] || []).forEach((tokenTwo) => {
					pobjs = pobjs.concat(this.tokenProcessor(tokenTwo));
					fullText = this.buildFullPhrase(tokenTwo);
				});
				toReturn = {prep: token.lemma, pobjs, fullText};
				break;
			case 'ADVMOD':
				toReturn = [token.lemma];
				break;
			case 'ATTR':
				toReturn = this.fullText(token);
				break;
			default:
				// If it's not one of the roles specificall accounted for above, we just shrug and try to forward it based off of part of speech
				if (token.partOfSpeech.tag === 'VERB') {
					toReturn = this.verbProcessor(token, tokenChildren);
				} else if (token.partOfSpeech.tag === 'ADJ' || token.partOfSpeech.tag === 'NUM') {
					toReturn = this.adjectiveProcessor(token, tokenChildren);
				} else if (token.partOfSpeech.tag === 'NOUN') {
					toReturn = this.entityProcessor(token, tokenChildren);
				}
		}
		return toReturn;
	}.bind(this);

	/**
	 * 
	 * Finds the children for a token and returns them
	 * 
	 * @param {array} token The token whose children are being found
	 * @param {object} index The index of the token whose children are being found
	 * 
	 * @returns {object} The child tokens of the token, broken down into arrays by dependency type.
	 */
	this.findChildren = function(token, index) {
		if (!token || !this.tokens) {
			return {};
		}
		let children = {};
		this.tokens.forEach((tokenTwo) => {
			if (tokenTwo.dependencyEdge.headTokenIndex === index) {
				if (!children[tokenTwo.dependencyEdge.label]) {
					children[tokenTwo.dependencyEdge.label] = [];
				}
				children[tokenTwo.dependencyEdge.label].push(tokenTwo);
			}
		});
		return children;
	}.bind(this);

	/**
	 * Shortcut to log out the results of the processing above
	 */
	this.log = function() {
		console.log('this.structuredResults', JSON.stringify(this.structuredResults, null, 4));
	}.bind(this);

	/**
	 * Shortcut to get the results of the processing above as a string
	 */
	this.toString = function() {
		return JSON.stringify(this.structuredResults);
	}.bind(this);

	/**
	 * Gets the results after the processing above
	 */
	this.getResults = function() {
		return this.structuredResults;
	}

	// Runs tokenProcessor over each of the roots
	roots.forEach(this.tokenProcessor);
}
export default NlpProcessorObj;