import io from 'socket.io-client';
import InterfaceActions from '../actions/interface-actions';
import eventEmitter from './socket-response-emitter';
import uuid from 'uuid';

let basePath = 'https://' + window.location.host;
let basePathOverridePrefix = sessionStorage.getItem('defaultAPIGWOverride');
if(basePathOverridePrefix) { 
	basePath = 'https://' + basePathOverridePrefix + '.citizendeveloper.com';
}

// Core Engineering Problems Only!
// @todo DO THIS BETTER! - Use process.env in webpack config instead of the body/defaultAPIGWOverride
if(!basePathOverridePrefix && window.location.hostname === 'localhost') {
	basePath = 'https://dev-eng-sandbox.citizendeveloper.com';
}

let portRegexp = new RegExp(/:\d+$/);
let portMatches = portRegexp.exec(basePath);
if (portMatches) {
	// If the base path has a port number then this is a webpack dev server
	// console.log('Dev Server Detected')
	basePath = basePath.replace(portMatches[0], '');
}

//Pull or generate the window id
let windowId = window.sessionStorage.getItem('citdevWindowId');
//If this key is found then it means we are on a duplicate tab because it copies 
//the session storage without runing the beforeunload
let duplicateTab = window.sessionStorage.getItem('duplicateTab');
if (!windowId || duplicateTab === "true") {
	windowId = uuid.v4();
	window.sessionStorage.setItem('citdevWindowId', windowId);
} 
//Set the duplicate flag to detect duplicate tabs
window.sessionStorage.setItem('duplicateTab', "true");

//Add an unload handler to remove the duplicateTab flag
//this will cause the duplicateTab flag to be removed in the case of reload, back, forward
if (window.addEventListener) {
	window.addEventListener('beforeunload', function () {
		window.sessionStorage.removeItem('duplicateTab');
	});
	// If IE event model is used
} else if (document.attachEvent) {
	// ensure firing before beforeunload
	window.attachEvent('onbeforeunload', function () {
		window.sessionStorage.removeItem('duplicateTab');
	});
}

var	socket = io(basePath, {query: 'socketSource=ui&windowId='+windowId});

// On socket IO connect success
socket.on('connectSuccess', function() {
	// console.log('connection successful')
});

socket.on('installationBroadcast', function(config) {
	if (typeof config === 'string') {
		config = JSON.parse(config);
	}

	if(window.instanceId && config && config.params && config.params.instanceId === window.instanceId) {
		// This one's our own! No need to double-update the store; ignore it.
		return;
	}

	// console.log('config', config);

	// let {PageStore, FieldStore, TableStore, RelationshipStore, FieldTypeStore, PatternStore, LogicFunctionStore, MetadataStore} = require('../stores');
	let {PageActions, FieldActions, TableActions, RelationshipActions, LogicFunctionActions, MetadataActions, ConsumableCreditsAPIActions} = require('../actions');
	switch(config.action) {
		case 'consumableCreditsUpdate': {
			ConsumableCreditsAPIActions.setStatus(config.params.resource, config.params.creditsAvailable);
			break;
		}
		case 'metadataUpsert':{
			// console.log('metadataUpsert', config.params);
			let kind = config.params.kind;
			let records = config.params.records || [];
			// let recordId = record.recordId;
			// let oldRecord;
			switch(kind) {
				case 'page': {
					// oldRecord = PageStore.get(recordId);
					PageActions.receiveBroadcast(records);
					break;
				}
				case 'field': {
					// oldRecord = FieldStore.get(recordId);
					FieldActions.receiveBroadcast(records);
					break;
				}
				case 'table': {
					// oldRecord = TableStore.get(recordId);
					TableActions.receiveBroadcast(records);
					break;
				}
				case 'relationship': {
					// oldRecord = RelationshipStore.get(recordId);
					RelationshipActions.receiveBroadcast(records);
					break;
				}
				case 'fieldType': {
					// This should literally never happen, so I'm not going to handle it
					InterfaceActions.notification({
						level: 'error',
						message: 'Unsupported metadata upsert dispatch: ' + kind
					});
					// oldRecord = FieldTypeStore.get(recordId);
					break;
				}
				case 'pattern': {
					// This should literally never happen, so I'm not going to handle it
					// oldRecord = PatternStore.get(recordId);
					InterfaceActions.notification({
						level: 'error',
						message: 'Unsupported metadata upsert dispatch: ' + kind
					});
					break;
				}
				case 'logicfunctions': {
					LogicFunctionActions.receiveBroadcast(records);
					// oldRecord = LogicFunctionStore.get(recordId);
					break;
				}
				default: {
					MetadataActions.receiveBroadcast(kind, records);
					// oldRecord = MetadataStore.get(kind, recordId);
				}
			}
			break;
		}
		case 'metadataDelete':
			// console.log('metadataDelete', config.params);
			let kind = config.params.kind;
			let records = config.params.records || [];
			records.forEach(record => {
				// let recordId = record.recordId;
				// let oldRecord;
				switch(kind) {
					case 'page': {
						// oldRecord = PageStore.get(recordId);
						PageActions.deleteFromStore(record.recordId);
						break;
					}
					case 'field': {
						// oldRecord = FieldStore.get(recordId);
						FieldActions.deleteFromStore(record.recordId);
						break;
					}
					case 'table': {
						// oldRecord = TableStore.get(recordId);
						TableActions.deleteFromStore(record.recordId);
						break;
					}
					case 'relationship': {
						// oldRecord = RelationshipStore.get(recordId);
						RelationshipActions.deleteFromStore(record.recordId);
						break;
					}
					case 'fieldType': {
						// This should literally never happen, so I'm not going to handle it
						InterfaceActions.notification({
							level: 'error',
							message: 'Unsupported metadata delete dispatch: ' + kind
						});
						// oldRecord = FieldTypeStore.get(recordId);
						break;
					}
					case 'pattern': {
						// This should literally never happen, so I'm not going to handle it
						// oldRecord = PatternStore.get(recordId);
						InterfaceActions.notification({
							level: 'error',
							message: 'Unsupported metadata delete dispatch: ' + kind
						});
						break;
					}
					case 'logicfunctions': {
						LogicFunctionActions.deleteFromStore(record.recordId);
						// oldRecord = LogicFunctionStore.get(recordId);
						break;
					}
					default: {
						MetadataActions.deleteFromStore(record.recordId, kind);
						// oldRecord = MetadataStore.get(kind, recordId);
					}
				}
			});

			break;
		case 'reloadUI':
			//read and make sure force is boolean
			let force = config.params.force && (config.params.force === true || config.params.force === 'true');
			InterfaceActions.reloadUI({'force': force});
			break;
		case 'setMaintenanceMode':
			//reload the browser
			InterfaceActions.maintenanceUI(config.params.message);
			break;
		default:
			console.warn('Unimplemented Installation Broadcast Action');
			break;
	}
});

socket.on('unauthenticated', function() {
	console.log('socket: unauthenticated')
	//Make sure the authenticated flag is removed
	localStorage.removeItem('authenticated');
	localStorage.removeItem('haveAuthCreds');
	localStorage.removeItem('signedMdKey');
	localStorage.removeItem('userId');
	localStorage.removeItem('username');
	localStorage.removeItem('username');
	localStorage.removeItem('userSecurityGroups');
});

socket.on('interfaceAction', function(config) {
	if (typeof config === 'string') {
		config = JSON.parse(config);
	}
	
	Promise.resolve(InterfaceActions[config.action].apply(InterfaceActions, [config.parameters])).then(result => {
		if (config.correlationId && config.replyTo) {
			let message = {'correlationId': config.correlationId, 'result': result};
			socket.emit('sendToQueue', config.replyTo, message);
		}
	}).catch(error => {
		let errorMessage = error && error.message ? error.message : JSON.stringify(error);
		console.error(`Save Error: ${errorMessage}`);
	});
});

// When there is a call service response, emit it!
socket.on('callServiceResponse', function(requestId, response) {
	eventEmitter.emit(requestId, response);
});

//Prints progress % of the file upload to GCS. 
// socket.on('GCSFileUploadProgress', progress => {
// 	console.log('Progress uploading file: ', progress);
// });

// //Prints the error if file upload fails 
// socket.on('GCSFileUploadFailed', (error) => {
// 	console.log('Error uploading file to server', error);
// });


export default socket;