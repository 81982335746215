import AppDispatcher from '../dispatcher/app-dispatcher';
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';
import RecordSetConstants from '../constants/record-set-constants';
import {RecordConstants} from '../constants/record-constants';
import RenderConstants from '../constants/render-constants';
import ListConstants from '../constants/list-constants';
import AdminSettingsConstants from '../constants/admin-settings-constants';
import RenderStore from './render-store';
import FieldStore from './field-store';
import { FieldConstants } from '../constants/field-constants';
import {PageConstants} from '../constants/page-constants';


/**
 * Store to contain the record sets generated by fields on the page
 *
 * @class RecordSetStore
 * @extends {ReduceStore}
 */
class RecordSetStore extends ReduceStore {
	/**
	 * Initial state for RecordSetStore
	 *
	 * @memberof RecordSetStore
	 */
	getInitialState() {
		return Immutable.Map();
	}

	getLastModifiedTime(renderId, setName) {
		let state = this.getState();
		let result = undefined;
		if (state.has(renderId)) {
			let renderRecordSetObj = state.get(renderId);
			if (renderRecordSetObj.has(setName)) {
				let resultObject = renderRecordSetObj.get(setName);
				if (resultObject.has('lastDt')) {
					result = resultObject.get('lastDt');
				}
			}
		}

		return result;
	}

	/**
	 * Gets the Record Set Rows from record sets derived from queries
	 *
	 * @param {string} renderId
	 * @param {string} setName
	 * @returns {Object[]}
	 * @memberof RecordSetStore
	 */
	getRecordSetFull(renderId, setName) {
		let state = this.getState();
		let result = undefined;
		if (state.has(renderId)) {
			let renderRecordSetObj = state.get(renderId);
			if (renderRecordSetObj.has(setName)) {
				result = renderRecordSetObj.get(setName);
			}
		}

		return result;
	}

	/**
	 * Gets the Record Set Rows from record sets derived from queries
	 *
	 * @param {string} renderId
	 * @param {string} setName
	 * @returns {Object[]}
	 * @memberof RecordSetStore
	 */
	getRecordSetRows(renderId, setName) {
		let state = this.getState();
		let result = undefined;
		if (state.has(renderId)) {
			let renderRecordSetObj = state.get(renderId);
			if (renderRecordSetObj.has(setName)) {
				let recordSet = renderRecordSetObj.get(setName);
				if (recordSet.has('rows')) {
					result = recordSet.get('rows');
				}
			}
		}

		return result;
	}

	/**
	 * Get the record set for a given recordId and setName in the Record Variable Format
	 * 
	 * @param {string} renderId
	 * @param {string} setName
	 * @returns {{recordId: string, tableSchemaName: string}[]} Array of Objects
	 *
	 * @memberOf RecordSetStore
	 */
	getRecordSet(renderId, setName) {
		let state = this.getState();
		let result = undefined;
		if (state.has(renderId)) {
			let renderRecordSetObj = state.get(renderId);
			if (renderRecordSetObj.has(setName)) {
				let resultObject = renderRecordSetObj.get(setName);
				result = [];
				if (resultObject.has('rows')) {
					let rows = resultObject.get('rows');
					if (rows && rows.size) {
						let tableSchemaName = rows.get(0).get('tableSchemaName');
						rows.forEach((row, index) => {
							result[index] = {
								'tableSchemaName': tableSchemaName,
								'recordId': row.get('recordId')
							};
						});
					}
				} else if (resultObject.has('recordSetArray')) {
					let tableSchemaName = resultObject.get('tableSchemaName');
					resultObject.get('recordSetArray').forEach(recordId => {
						result.push({
							'tableSchemaName': tableSchemaName,
							'recordId': recordId
						})
					});
				}
			}
		}

		return result;
	}

	/**
	 * Get the record sets for a given recordId
	 *
	 * @param {string} renderId
	 * @returns {Object.<string, {recordId: string, tableSchemaName: string}[]>} Array of Objects keyed by record set name
	 *
	 * @memberOf RecordSetStore
	 */
	getRecordSets(renderId) {
		let state = this.getState();
		let result = undefined;
		if (state.has(renderId)) {
			let recordSetsRaw = state.get(renderId);
			result = {};
			for (let recordSetName of recordSetsRaw.keys()) {
				result[recordSetName] = this.getRecordSet(renderId, recordSetName);
			}
		}

		return result;
	}

	/**
	 * Get the record sets for a given recordId
	 *
	 * @param {string} renderId
	 * @returns {Object.<string, {recordId: string, tableSchemaName: string}[]>} Array of Objects keyed by record set name
	 *
	 * @memberOf RecordSetStore
	 */
	getRecordSetsFull(renderId) {
		let state = this.getState();
		let result = undefined;
		if (state.has(renderId)) {
			let recordSetsRaw = state.get(renderId);
			result = {};
			for (let recordSetName of recordSetsRaw.keys()) {
				result[recordSetName] = this.getRecordSetFull(renderId, recordSetName);
			}
		}

		return result;
	}

	/**
	 * Get the record sets available from fields on a given page for a given recordId
	 *
	 * @param {renderId} The render ID of the record being used for filtering
	 * @param {pageId} The ID of the page used for filtering
	 * @returns {Object} Array of Objects
	 *
	 * @memberOf RecordSetStore
	 */
	getRecordSetsInQuery(renderId, queryObj) {
		let result = {};
		if(!queryObj || !Object.keys(queryObj).length) {
			return result;
		}

		let resultKeys = {};

		if (queryObj.filters) {
			queryObj.filters.forEach((filter) => {
				resultKeys = Object.assign({}, resultKeys,_filterRecursor(filter, resultKeys));
			});
		}

		Object.keys(resultKeys).forEach(setName => {
			// Our filterRecursor already removes non-set record variables (page, currentUser, custom ones, etc.)
			// First try to get the set from the current renderId
			result[setName] = this.getRecordSet(renderId, setName);
			// Now get any sibling or parent record sets if the record set is not on the current render.
			let renderObj = RenderStore.get(renderId) || {};
			let parentObj = renderObj.renderParentId ? RenderStore.get(renderObj.renderParentId) : null;
			while(!result[setName] && parentObj) {
				// If the parent has the record set, grab it
				if (parentObj.recordSets && parentObj.recordSets.indexOf(setName) > -1) {
					result[setName] = this.getRecordSet(parentObj.renderId, setName);
				}

				// If any siblings have the record set, grab it
				if(parentObj.children) {
					let childRenderId = parentObj.children.find(childRenderId => {
						let childObj = RenderStore.get(childRenderId);
						return (childObj && childObj.recordSets && childObj.recordSets.indexOf(setName) > -1)
					});
					if (childRenderId) {
						result[setName] = this.getRecordSet(childRenderId, setName);
					}
				}

				// Move up a level
				parentObj = RenderStore.get(parentObj.renderParentId);
			}
		});

		return result;
	}
	
	
	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 * @ignore
	 *
	 * @memberOf RecordSetStore
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			// Set the record set to a given value
			case RecordSetConstants.SET_RECORD_SET: {
				let renderId = action.get('renderId');
				let setName = action.get('setName');
				let newState = state.setIn([renderId, setName], action.withMutations((action) => {
					action.delete('renderId');
					action.delete('setName');
					action.delete('type');
					if(!action.has('lastDt')) {
						action.set('lastDt', +new Date());
					}
				}));

				return newState;
			}
			case RenderConstants.REFRESH_FIELD:
			case RenderConstants.GRID_UPDATE:
			case AdminSettingsConstants.OVERLAY_CHANGE_WITH_RECALC:
			case RenderConstants.INIT_PAGE: {
				let grids = action.get('grids');
				let lastDt = action.get('lastDt');
				if(!grids) {
					return state;
				}
				return state.withMutations(state => {
					grids.forEach(grid => {
						let renderId = grid.get('parentRenderId');
						let recordSets = grid.get('recordSets');
						if(recordSets) {
							recordSets.forEach(setValue => {
								let setName = setValue.get('setName');
								let uiName = setValue.get('uiName');
								let query = setValue.get('query');
								let tableSchemaName = setValue.get('tableSchemaName');
								let recordId = setValue.get('recordId');
								let fields = setValue.get('fields');
								let rows = setValue.get('rows');
								let recordSetArray = setValue.get('recordSetArray');
								let setIn = setValue.has('rows')
									? Immutable.fromJS({
										lastDt: lastDt || +new Date(),
										tableSchemaName,
										recordId,
										query,
										fields,
										rows,
										uiName
									})
									: Immutable.fromJS({
										lastDt: lastDt || +new Date(),
										tableSchemaName,
										recordId,
										query,
										fields,
										recordSetArray,
										uiName
									});
								state.setIn([renderId, setName], setIn);
							})
						}
					});
				});

			}
			// @TODO: Need to handle case RenderConstants.INIT_PAGE:
			case RenderConstants.INIT_GRID: {
				let query = action.get('query');
				if(query) {
					let renderId = action.get('parentRenderId');
					let setName = action.get('setName');
					let setIn = Immutable.fromJS({
						lastDt: action.get('lastChildCalculation') || +new Date(),
						tableSchemaName: action.get('dataTableSchemaName'),
						recordId: action.get('dataRecordId'),
						query: action.get('query'),
						fields: action.get('fields'),
						rows: action.get('rows'),
						uiName: action.get('uiName')
					});
					return state.setIn([renderId, setName], setIn);
				} else {
					return state;
				}
			}
			case ListConstants.INIT_LIST: {
				return state.mergeDeep(action.get('recordSets'));
			}
			case ListConstants.SET_LIST_ROWS: {
				// We can't just use mergeDeep because it can end up not appropriately removing records from record sets
				let newState = state.withMutations(state => {
					let recordSets = action.get('recordSets');
					recordSets.forEach((entry, renderId) => {
						entry.forEach((recordSet, recordSetName) => {
							let newValue = state.hasIn([renderId, recordSetName]) ? state.getIn([renderId, recordSetName]) : Immutable.Map();
							// the actual value of recordSet is itself of a form like {recordSetName, recordSetArray, renderId, otherAttributeNames, etc}
							// which the incoming record set will not necessarily have.
							// So in order to avoid losing those if not provided in the incoming recordSet, we do a merge instead of an overwrite
							newValue = newValue.merge(recordSet);
							state.setIn([renderId, recordSetName], newValue);
						});
					});
				});
				// let newState = state.mergeDeep(action.get('recordSets'));
				return newState;
			}

			case RecordSetConstants.SET_SELECTED_RECORD_SET: {
				let renderId = action.get('renderId');
				let setName = action.get('setName');
				let recordSetArray = action.get('recordSetArray');
				let tableSchemaName = action.get('setTableSchemaName');
				let uiName = action.get('uiName');
				let lastDt = action.has('lastDt') ? action.get('lastDt') : +new Date();
				let newState = state.setIn([renderId, setName], Immutable.fromJS({
					recordSetArray: recordSetArray,
					tableSchemaName: tableSchemaName,
					uiName: uiName,
					lastDt: lastDt
				}));

				return newState;
			}

			case RecordSetConstants.SET_SELECTED_CONTENT_TAB: {
				let renderId = action.get('renderId');
				let setName = action.get('setName');
				let value = action.get('value');
				let tableSchemaName = action.get('setTableSchemaName');
				let uiName = action.get('uiName');
				let lastDt = action.has('lastDt') ? action.get('lastDt') : +new Date();
				let recordSetArray = [value && value.has('recordId') ? value.get('recordId') : ''];
				let newState = state.setIn([renderId, setName], Immutable.fromJS({
					recordSetArray: recordSetArray,
					tableSchemaName: tableSchemaName,
					uiName: uiName,
					lastDt: lastDt
				}));

				return newState;
			}

			case RecordConstants.RECORD_UPDATE: {
				let tableSchemaName = action.get('tableSchemaName');
				let recordId = action.get('recordId');
				let values = action.get('values');
				let newState = state.withMutations((state) => {			
					values.forEach((value, fieldSchemaName) => {
						let fieldId = FieldStore.getFieldId(fieldSchemaName, tableSchemaName);
						let fieldObj = FieldStore.get(fieldId) || {};
						// If this is a dynamic selection field (only one supported for now)
						if(fieldObj.fieldType === '528c3e72-3a0d-4dc9-8e81-8be6f3c29c5c') {
							let valueObj = null;
							try {
								valueObj = value ? JSON.parse(value) : null;
							} catch (err) {
								console.warn('Error parsing value', value);
							}
							
							let renderList = RenderStore.getRenderObjectsForComponent('field', fieldId, tableSchemaName, recordId);

							if(valueObj) {
								renderList.forEach((renderObj, renderId) => {

									// Set the original record set
									let startingRelatedRecordsJSON = valueObj.startingRelatedRecordsJSON;
									let startingRelatedRecordsArr = [];
									try {
										startingRelatedRecordsArr = startingRelatedRecordsJSON ? JSON.parse(startingRelatedRecordsJSON) : [];
									} catch (err) {
										console.warn('Error parsing startingRelatedRecordsJSON. Value was', startingRelatedRecordsJSON);
									}
									startingRelatedRecordsArr = startingRelatedRecordsArr ? startingRelatedRecordsArr : [];
									let oldTableSchemaName = state.getIn([renderId, fieldId + '-original', 'tableSchemaName']);
									let newValue = {
										tableSchemaName: startingRelatedRecordsArr && startingRelatedRecordsArr[0] ? startingRelatedRecordsArr[0].tableSchemaName : oldTableSchemaName,
										recordSetArray: startingRelatedRecordsArr.map(recordObj => recordObj.recordId),
										uiName: 'Original User Selected Record(s)',
										lastDt: +new Date()
									};
									state.setIn([renderId, fieldId + '-original'], Immutable.Map(newValue));
	
									// Set the selected record set
									let newRecordJSON = valueObj.newRecordJSON;
									let newRecordsArr = [];
									try {
										newRecordsArr = newRecordJSON ? JSON.parse(newRecordJSON) : [];
									} catch (err) {
										console.warn('Error parsing newRecordJSON. Value was', newRecordJSON);
									}
									newRecordsArr = newRecordsArr ? newRecordsArr : [];
									oldTableSchemaName = state.getIn([renderId, fieldId + '-selected', 'tableSchemaName']);
									newValue = {
										tableSchemaName: newRecordsArr && newRecordsArr[0] ? newRecordsArr[0].tableSchemaName : oldTableSchemaName,
										recordSetArray: newRecordsArr.map(recordObj => recordObj.recordId),
										uiName: 'Current User Selected Record(s)',
										lastDt: +new Date()
									};
									state.setIn([renderId, fieldId + '-selected'], Immutable.Map(newValue));
								});
							}

						}
					});
				});
				return newState;
			}

			case RenderConstants.DELETE_RENDER: {
				let renderId = action.get('renderId');
				let reassignPageChildren = action.get('reassignPageChildren');
				let childIds = RenderStore.findAllChildren(renderId, reassignPageChildren);
				return state.withMutations((state) => {
					state.delete(renderId);
					
					childIds.forEach((childRenderId) => {
						let childDetails = RenderStore.get(childRenderId);
						let componentType = childDetails.componentType;
						let shouldReassign = reassignPageChildren && componentType === 'page';

						// Only delete the non-page children or delete page children if they are not being reassigned
						// (This is a part of the fix for ticket 8292)
						if(!shouldReassign) {
							state.delete(childRenderId);
						}
					});
				});
			}
			
			case RecordSetConstants.SET_RECORD_SET_BULK: {
				return state.withMutations(state => {
					let recordSets = action.get('recordSets');
					recordSets.forEach((value, renderId) => {
						value.forEach((recordSetValue, recordSetName) => {
							state.setIn([renderId, recordSetName], recordSetValue);
						});
					})
				});
			}
			
			case RenderConstants.INIT_REPEATING_GRID: {
				let lastDt = action.has('lastDt') ? action.get('lastDt') : +new Date();
				let renderId = action.get('parentRenderId');
				let setName = action.get('setName');
				let rows = action.get('rows');
				let query = action.get('query')

				return state.withMutations(state => {
					state.setIn([renderId, setName, 'lastDt'], lastDt);
					state.setIn([renderId, setName, 'rows'], rows);
					state.setIn([renderId, setName, 'uiName'], action.get('uiName'));
					state.setIn([renderId, setName, 'tableSchemaName'], action.get('tableSchemaName'));
					if(query) {
						state.setIn([renderId, setName, 'query'], query);
					}
				});
			}

			case PageConstants.PAGE_DELETE_FROM_STORE:
			case FieldConstants.FIELD_DELETE_FROM_STORE: {
				AppDispatcher.waitFor([FieldStore.getDispatchToken()]);
				let recordId = action.get('recordId');
				// Find all render store entries which match this
				// Then delete any record sets for that render store
				let matchingRenders = RenderStore.getRenderObjectsForComponent(action.get('type') === PageConstants.PAGE_DELETE_FROM_STORE ? 'page' : 'field', recordId);
				return state.withMutations(state => {
					if(matchingRenders) {
						matchingRenders.forEach((render, renderId) => {
							if(state.has(renderId)) {
								state.delete(renderId);
							}

							// Now also delete any descendants
							let childIds = RenderStore.findAllChildren(renderId, true);
							childIds.forEach((childRenderId) => {
								let childDetails = RenderStore.get(childRenderId);
								if(childDetails.componentType !== 'page') {
									state.delete(childRenderId);
								}
							});
						});
					}
				});
			}

			// @TODO this is temporary until we can use the socket.io stuff to update records.
			case 'ALL_RECORD_DATA_RECEIVED_CHANGE_WITH_PATH_CHANGE': {
				return Immutable.Map();
			}
			
			default: {
				return state;
			}
		}
	}
}

/**
 * Recursive function used to identify record sets needed for a query.
 * 
 * @param {object} filter The filter being examined for potential recordSets
 * @param {object} accumulator An object for which found recordSets are keys.
 */
function _filterRecursor(filter, accumulator) {
	if(filter.type === 'filter') {
		let val = filter.value || '';
		if(typeof val !== 'string') {
			return accumulator;
		}
		let matchedRecordSets = val.match(/recordSets_[a-zA-Z0-9\-_]*/g) || [];
		matchedRecordSets.forEach(recordSetMatch => {
			let recordSetName = recordSetMatch.replace('recordSets_', '');
			accumulator[recordSetName] = true;
		});
	} else if (filter.type === 'group') {
		filter.filters.forEach((filter) => {
			accumulator = Object.assign({},accumulator,_filterRecursor(filter, accumulator));
		});
	}
	return accumulator;
}

const instance = new RecordSetStore(AppDispatcher);
export default instance;