import keymirror from 'keymirror';

/**
 * READ THIS:  If you are adding constants for purposes that update this store then please
 * inform the NLP Team (Amber) so they can make appropriate changes to the NLP Dictionaries Store.
 */
export const TableConstants = keymirror({
	TABLE_DELETE_FROM_DATABASE: null,
	TABLE_DELETE_FROM_STORE: null,
	TABLE_PULL_FROM_DATABASE: null,
	TABLE_PULL_FROM_DATABASE_ALL: null,
	TABLE_PULL_ERROR: null,
	TABLE_PUSH_TO_DATABASE: null,
	TABLE_PUSH_TO_STORE: null,
	TABLE_RECEIVE_BROADCAST: null
});