import React, { Component } from 'react';
import { Container } from 'flux/utils';
import PropTypes from 'prop-types';
import QueryButton from '../forms/query-button.react';
import Number from '../forms/number.react';
import AdminSettingsStore from '../../stores/admin-settings-store';
import ContextStore from '../../stores/context-store';
import PageModeStore from '../../stores/page-mode-store';
import RenderStore from '../../stores/render-store';
import ObjectUtils from '../../utils/object-utils';

class VisibilitySettingsConfigurationV1 extends Component {
	constructor(props){
		super(props)
		this.addNewRule = this.addNewRule.bind(this);
		this.addDefaultRow = this.addDefaultRow.bind(this);
		this.renderRows = this.renderRows.bind(this);
		this.updateRow = this.updateRow.bind(this);
		this.updateNewRow = this.updateNewRow.bind(this);
	}
	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, ContextStore, PageModeStore]; 
	}
	/**
	 * 
	 * @param {type} prevState 
	 */
	static calculateState(prevState, props) {
		let newState = {};
		let controlsAddAndEdit = AdminSettingsStore.getVisibilityControlsAddAndEdit();
		
		// get the page id
		let componentRenderId = AdminSettingsStore.getRenderId();
		let pageRenderObj = RenderStore.getPageRenderObj(componentRenderId);
		let pageId = pageRenderObj ? pageRenderObj.componentId : undefined;

		// look for whether page is in "Add" mode
		let	visHasAddMode = PageModeStore.hasAvailableMode(pageId, 'add');

		if ( !props.visibilityRulesArray || (props.visibilityRulesArray && !props.visibilityRulesArray.length)) {
			newState = 	{
				'sequence': 0,
				'show': true, 
			}
			if(visHasAddMode) {
				newState['add'] = true;
			} else if (!controlsAddAndEdit) {
				newState['edit'] = true;
			}
		} else {
			newState = props.visibilityRulesArray;
		}
		return {
			'newState': newState,
			'controlsAddAndEdit': controlsAddAndEdit,
			'visHasAddMode': visHasAddMode
		}
	}
	/**
	 * Update default row
	 * @param {any} id 
	 * @param {any} event 
	 * @memberof VisibilitySettingsConfiguration
	 */
	updateNewRow(id, index, event){
		event.preventDefault();
		let { visibilityRulesArray, onChange } = this.props;
		let newState = ObjectUtils.copyObj(this.state.newState);
		if(event.target.checked) {
			newState[id] = true;
		} else {
			newState[id] = false;
		}
		visibilityRulesArray.push(newState);
		onChange(visibilityRulesArray)
	}
	/**
	 * Update the values of each row
	 * @param {any} event 
	 * @memberof VisibilitySettingsConfiguration
	 */
	updateRow(id, index, event){
		let {visibilityRulesArray, onChange} = this.props;
		// Since edit, show and add are checkboxes we need to read from "checked" instead of value.
		visibilityRulesArray[index][id] = ((id === 'edit' || id === 'show' || id === 'add') 
			? !!event.target.checked
			: event.target.value);
		onChange(visibilityRulesArray)
	}
	/**
	 * Delete the rule
	 * @param {any} index 
	 * @memberof VisibilitySettingsConfiguration
	 */
	removeRule(ruleNumber){
		let{onChange, visibilityRulesArray } = this.props;
		// Remove rule from the visibility rules array
		visibilityRulesArray.splice(ruleNumber, 1);

		onChange(visibilityRulesArray);
	}
	/**
	 * Add new Global values
	 * @memberof VisibilitySettingsConfiguration
	 */
	addNewRule(event) {
		event.preventDefault();
		let { controlsAddAndEdit, visHasAddMode } = this.state;
		let { visibilityRulesArray, onChange } = this.props;
		let lengthofVisibilityRule = visibilityRulesArray.length;
		let newRuleObj = {};
		let newSequence = -1;
		
		// Determine the newSequence Number, and push the 0th item on if we dont already have any rules.
		if(lengthofVisibilityRule) {
			// check all the sequence numbers
			visibilityRulesArray.forEach(rule => {
				// find the highest sequence number
				if(rule.sequence >= newSequence) {
					// Parsing string to integer and increment by 1
					newSequence = parseInt(rule.sequence, 10) + 1
				}
			});
		} else {
			// Push the Always Rule at the bottom
			visibilityRulesArray.push(this.state.newState);
			// Our new sequence will be 1, because the line above added the 0th element.
			newSequence = 1;
		}

		newRuleObj = {
			'sequence': newSequence, 
			'show': true, 
			'query': '',
		}
		// Add the default new rule with empty query
		if(visHasAddMode ) {
			newRuleObj['add'] = true;
		} else if(!controlsAddAndEdit){
			newRuleObj['edit'] = true;
		}
		visibilityRulesArray.push(newRuleObj);
		
		onChange(visibilityRulesArray);
	}
	/**
	 * Default Row
	 * @param {any} newState 
	 * @returns Default Always row
	 * @memberof VisibilitySettingsConfiguration
	 */
	addDefaultRow(newState){
		let { controlsAddAndEdit, visHasAddMode } = this.state;
		return (<tr key="0">
			<td>
				<div className="form-check">
					<label className="form-check-label">
						{/* <input 
							id="show"
							value={newState.show} 
							type="checkbox" 
							className="form-check-input" 
							checked={newState.show}
							onChange={this.updateNewRow.bind(this,'show', 0)} /> */}
						<span>Show</span>
					</label>
				</div>
				{ visHasAddMode && !controlsAddAndEdit ?
				<div className="form-check">
					<label className="form-check-label">
						{/* <input 
							id="add"
							value={newState.add} 
							type="checkbox" 
							className="form-check-input" 
							checked={newState.add}
							onChange={this.updateNewRow.bind(this,'add', 0)} /> */}
						<span>Add</span>
					</label>
				</div>
				: 
					(!controlsAddAndEdit ) ?
					<div className="form-check">
						<label className="form-check-label">
							{/* <input 
								id="edit"
								value={newState.edit} 
								type="checkbox" 
								className="form-check-input" 
								checked={newState.edit}
								onChange={this.updateNewRow.bind(this,'edit', 0)} /> */}
							<span>Edit</span>
						</label>
					</div>
					: null
				}
			</td>
		
			<td>
				<div className="rule-always text-center align-self-center">
					<span className="text-center">Always</span>
				</div>
			</td>
			<td className="align-self-center">
				<div className="sequence">
					<Number
						fieldSchemaName={'sequence' + newState.sequence}
						value={newState.sequence}
						disabled={!newState.sequence}
					/>
				</div>
			</td>
			<td></td>
		</tr>)
	}
	/**
	 * Render the Row with Rules
	 * 
	 * @memberof VisibilitySettingsConfiguration
	 */
	renderRows() {
		let {newState, controlsAddAndEdit, visHasAddMode} = this.state;
		let {visibilityRulesArray} = this.props;
		// let newIndex = (visibilityRulesArray && visibilityRulesArray.length) ? visibilityRulesArray.length : 0;
		let listRows = [];
		if(!visibilityRulesArray || !visibilityRulesArray.length){
			listRows.push(this.addDefaultRow(newState));
		} else {
			listRows = visibilityRulesArray.map((rule, index) => {
				return (
					<tr key={index + 1}>
						<td>
							<div className="form-check">
								<label className="form-check-label">
									{/* <input 
										id={"show" + newIndex}
										value={rule.show} 
										type="checkbox" 
										className="form-check-input" 
										checked={rule.show}
										onChange={this.updateRow.bind(this,'show', index)} /> */}
									<span>Show</span>
								</label>
							</div>
							
							{visHasAddMode && !controlsAddAndEdit ?
							<div className="form-check">
								<label className="form-check-label">
									{/* <input 
										id={"add" + newIndex}
										value={rule.add} 
										type="checkbox" 
										className="form-check-input" 
										checked={rule.add}
										onChange={this.updateRow.bind(this,'add', index)} /> */}
									<span>Add</span>
								</label>
							</div>
							: 
								(!controlsAddAndEdit ) ?
								<div className="form-check">
									<label className="form-check-label">
										{/* <input 
											id={"edit" + newIndex}
											value={rule.edit} 
											type="checkbox" 
											className="form-check-input" 
											checked={rule.edit}
											onChange={this.updateRow.bind(this,'edit', index)} /> */}
										<span>Edit</span>
									</label>
								</div>
								: null
							}
						</td>
						{rule.sequence === 0 &&
							<td>
								<div className="rule-always text-center align-self-center">
									<span className="text-center">Always</span>
								</div>
							</td>
						}
						{ rule.sequence !== 0 &&
							<td>
								<div className="row">
									<div className="col-8 text-center align-self-center">
										<span>When the Query is true: </span>
									</div>
									<div className="col-4 form-check mb-0 align-self-center">
										<QueryButton
											className="form-control"
											label='Query'
											fieldSchemaName='query'
											value={rule.query}
											id='query'
											onChange={this.updateRow.bind(this,'query', index)}
										/>
									</div>
								</div>
							</td>
						}
						<td className="align-self-center">
							<div className="sequence">
								<Number
									fieldSchemaName={'sequence'+rule.sequence}
									value={rule.sequence}
									disabled={!index}
									min="1"
									onChange={this.updateRow.bind(this,'sequence', index)} />
							</div>
						</td>
						{ rule.sequence !== 0 ?
						<td className="align-self-center">
							<div className="button-icon">
								<button 
									type="button" 
									className="close" 
									aria-label="delete" 
									onClick={this.removeRule.bind(this, index)}>
									<i className="fa fa-minus" aria-hidden="false"></i>
								</button>
							</div>
						</td>
						: <td></td>
						}
					</tr>
				)
			});
		}
		/* Sort Rows in decending order */
		listRows.sort(function(a,b){
			if(a.sequence < b.sequence) { 
				return -1;
			}
			return 1;
		});
		return listRows;
	}
	/**
	 * Renders DOM for visibility settings
	 * 
	 * @returns 
	 * @memberof VisibilitySettingsConfiguration
	 */
	render() {
		return (
			<div className="visibility-settings">
				<div>
					{/* <button 
						className="btn btn-secondary btn-sm"
						onClick={this.addNewRule}>
							Add a Rule
					</button> */}
				</div>
				<table>
					<thead>
					<tr>
						<th className="text-center p-0">Value</th>
						<th className="text-center p-0">Rule</th>
						<th className="text-center p-0">Sequence</th>
						<th className="text-center p-0"></th>
					</tr>
					</thead>
					<tbody>
						{this.renderRows()}
					</tbody>
				</table>
			</div>
		)
	}
}

if ('development' === process.env.NODE_ENV) {
	VisibilitySettingsConfigurationV1.propTypes = {
		visibilityRulesArray: PropTypes.array,
		onChange: PropTypes.func,
	};
}
const container = Container.create(VisibilitySettingsConfigurationV1, {withProps:true});
export default container;