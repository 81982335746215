/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container } from 'flux/utils';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';
import DiscussionActions from '../../../actions/discussion-actions';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import ContextStore from '../../../stores/context-store';
import DiscussionStore from '../../../stores/discussion-store';
import RenderStore from '../../../stores/render-store';

// Utils
import UIUtils from '../../../utils/ui-utils';
import uuid from 'uuid';

// Components
import PopoverHeader from './header';

import { ReactSVG } from 'react-svg';

/**
 * Container to field type wrapper
 *
 * @class FieldTypeWrapperContainer
 * @extends {Component}
 */
class DiscussionPinPopover extends Component {
	constructor(props) {
		super(props);
		this.link = null;
		this.onAddClick = this.onAddClick.bind(this);
		this.onSettingClick = this.onSettingClick.bind(this);
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf DiscussionPin
	 */
	static getStores() {
		return [AdminSettingsStore, DiscussionStore];
	}


	/**
	 * onAddClick - Sets up a new recordId for the security setting UI to use.
	 *
	 * @param {object} event
	 */
	onAddClick(event){
		event.preventDefault();
		event.stopPropagation();

		let newRecordId = uuid.v4();
		let {
			componentId,
			applicationId,
			pageId,
			dataRecordId,
			dataTableSchemaName,
			installationId
		} = this.state;
		let newRecordObj = {
			temporary: true,
			componentId,
			applicationId,
			pageId,
			dataRecordId,
			dataTableSchemaName,
			installationId,
			discussionId: newRecordId,
			status: 'open',
			priority: 'trivial',
			type: 'discussion'
		};
		DiscussionActions.pushToStore(newRecordId, newRecordObj);
		this.onSettingClick(newRecordId, event);
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 * @memberof QuerySettingsChooser
	 */
	onSettingClick(discussionId, event) {
		event.preventDefault();
		event.stopPropagation();

		if(!discussionId) {
			return;
		}

		// Open the settings panel on the right.
		// (targetOverlay, recordId, tableSchemaName, parentRecordId, parentTableSchemaName, mainTab,
		// 	subSettingSchemaName, subSettingIndex, renderId)
		UIUtils.openSettingsPanel('discussion',
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 
			'settings', undefined, undefined, this.props.renderId, this.props.attachmentId
		);

		// Tell the Admin Settings Store what Setting we should be on.
		AdminSettingsActions.onSettingChange(undefined, discussionId);
	}

	/**
	 * Returns the current State of the FieldType Settings
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf DiscussionPin
	 */
	static calculateState(prevState, props) {
		let { renderId } = props;
		let renderObj = RenderStore.get(renderId) || {};
		let { componentId } = renderObj;

		// let componentName = AdminSettingsStore.getTableSchemaName();
		// For now, default to all discussions if no component specified
		let discussions = componentId ? DiscussionStore.getDiscussionsForComponent(componentId) : DiscussionStore.getAllArray();
		
		// let targetTableSchemaName = props.targetTableSchemaName;
		// let tableObj = TableStore.getByTableSchemaName(targetTableSchemaName);

		let pageObj = RenderStore.getPageRenderObj(renderId) || {};
		let {dataRecordId, dataTableSchemaName} = renderObj;
		let pageId = pageObj.componentId;
		let applicationId = ContextStore.getApplicationId();
		let installationId = ContextStore.getInstallationId();

		return {
			discussions,
			componentId,
			applicationId,
			pageId,
			dataRecordId,
			dataTableSchemaName,
			installationId,
		};
	}

	/**
	 * Renders a single discussion as an LI option.
	 * 
	 * @param {Object} discussionObj 
	 * @param {string} discussionObj.isSelected Is this option the selected UI option?
	 * @param {string} discussionObj.discussionId Record Id
	 * @param {number} discussionObj.number Discussion Number.
	 * @param {number} index The Index of this option
	 * @return JSX
	 */
	_renderOption(discussionObj, index) {
		let disabledSuffix = ' ';
		if(discussionObj.status === 'closed') {
			disabledSuffix = ' disabled ';
		}
		let labelClassNames = 'setting-label' + disabledSuffix;
		if(discussionObj.isSelected) { 
			labelClassNames += ' selected';
		}

		// Type Icon
		let typeIcon = null;
		if(discussionObj.type) {
			let faIconName = '';
			switch(discussionObj.type) {
				case 'bug': {
						faIconName = 'bug';
					break;
				}
				case 'change': {
						faIconName = 'plus-circle';
					break;
				}
				default:
				case 'discussion': {
						faIconName = 'comment';
					break;
				}
			}
			typeIcon = <div className={"popover-settings-icon " + disabledSuffix + "fa fa-" + faIconName} />
		}

		// Priority Label
		let priorityLabel = null;
		if(discussionObj.priority) {
			priorityLabel = {
				'critical': 'Critical',
				'major': 'Major',
				'minor': 'Minor',
				'trivial': 'Trivial',
			}[discussionObj.priority];
		}

		// Override the Prority lable with "Closed" if the status is closed.
		if(discussionObj.status === 'closed') {
			priorityLabel = 'Closed';
		}

		return (<li key={index} className="nav-item discussion p-0 d-flex align-items-center">
						{typeIcon}
						<div style={{ flex: 1 }} className="d-flex setting-text-container justify-content-between">
							<div className="settings-list-value">
								<div className="nav-link" onClick={this.onSettingClick.bind(this, discussionObj.discussionId)}>
									<div className={labelClassNames}>
										<h4 className='ml-1'>
											<p style={{ lineHeight: 'normal' }} className='settings-list-value m-0'>{discussionObj.number || discussionObj.number === 0 
												? discussionObj.number + '. ' 
												: ''}{discussionObj.name || '[ No Name Found ]'}
											</p>
										</h4>
									</div>
								</div>
								</div>
							<div className="d-flex align-items-center setting-value-container"><h5>{priorityLabel}</h5></div>
						</div>
				</li>);
	}

	render() {
		let { discussions } = this.state;

		let discussionList = [];
		discussionList.push(discussions.sort((a, b) => {
			// Put closed stuff last.
			if(a.status === 'closed' && b.status !== 'closed') {
				return 1;
			} else if (a.status !== 'closed' && b.status === 'closed') {
				return -1;
			}

			// No number?  Go to the bottom:
			if(a.number === '' || a.number === null || a.number === undefined) return 1;
			if(b.number === '' || b.number === null || b.number === undefined) return -1;
			
			// Same Number?  Don't Swap.
			if(a.number === b.number) return 0;

			// Ascending Numericallly.
			return +a.number < +b.number ? 1 : -1;
		}).map(this._renderOption.bind(this)));
		if(discussions.length > 0) {
			return (
				<div id="popover-content">
					<PopoverHeader 
						recordId={this.props.recordId} 
						tableSchemaName={this.props.tableSchemaName} 
						iconBase="comment" 
						overlayName="Discussion"/>
					<div style={{ height: '100%', overflow: 'hidden' }} className="tab-content row mb-2">
						<div style={{ height: '100%', overflowY: 'auto' }} className="ml-3 mt-3 pr-1 mr-2">
							<ul id="popover-settings" className="nav flex-column NOTEST">
								{discussionList}
							</ul>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div id="popover-content" className='cd-tools'>
					<PopoverHeader 
						recordId={this.props.recordId} 
						tableSchemaName={this.props.tableSchemaName} 
						iconBase="comment" 
						overlayName="Discussion"/>
						<div style={{ height: '100%', overflow: 'hidden' }} className="tab-content row mb-2">
							<div style={{ height: '100%', overflowY: 'auto' }} className="ml-3 pr-1 mr-2">
								<ul id="popover-settings" className="nav flex-column">
									<li className="d-flex settings-list-header field-settings-list-header noMarginAdjustment">
									<ReactSVG 
										beforeInjection={svg => {
											svg.setAttribute('style', 'width: 22px; height: 22px');
										}}
										src={ContextStore.getUrlMedia() + "/icon-discussion.svg"} />
										<h3>Discussions</h3>
										</li>
									<li key={1} className="nav-item discussion">
										{/* Temporarily removed the following div */}
										{/* <div className={"popover-settings-icon setting-icon fa fa-plus-circle"} /> */}
										{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
										{/* Add this back in when the setting-icon is used again */}
										<div className="d-flex w-100 setting-text-container">
											<div className="settings-list-value">
												<div className="nav-link" onClick={this.onAddClick.bind(this)}>
													<div className="setting-label">Click to start a discussion</div>
												</div>
												</div>
											<div style={{ flex: 1 }} className="justify-content-between setting-value-container"></div>
										</div>
									</li>
								</ul>
							</div>
						</div>
				</div>
			);
		}

		
	}
}

if ('development' === process.env.NODE_ENV) {
  DiscussionPinPopover.propTypes = {
    recordId: PropTypes.string.isRequired,
	tableSchemaName: PropTypes.string.isRequired,
    parentRecordId: PropTypes.string,
    parentTableSchemaName: PropTypes.string,
  };
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(DiscussionPinPopover, {withProps: true});
export default container;