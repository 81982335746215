var keyMirror = require('keymirror');

var ListConstants = keyMirror({
	'INIT_LIST': null,
	'SET_LIST_ROWS': null,
	'DELETE_LIST': null,
	'SET_ROW_HEIGHTS': null,
	'SET_REFS': null
});

module.exports = ListConstants;
