/* global citDev */
import React, { Component } from 'react';
import ReactSelect from 'react-select';
import QueryButton from '../forms/query-button.react';

class VisibilitySettingsConfiguration extends Component {

	onKeyPress(mode, { charCode } = event) {
		event.preventDefault();
		// If key is enter or space
		if (charCode === 13 || charCode === 32) {
			this.props.onChange(mode);
		}
	}

	processingChange(mode, viewOption) {
		let value = {};
		let processingMode = viewOption ? viewOption.value : 'never';
		switch (processingMode) {
			default:
			case 'never':
				value[mode+'Processing'] = 'never';
				value[mode+'Query'] = null;
				value[mode] = '0';
				break;
			case 'always':
				value[mode+'Processing'] = 'always';
				value[mode+'Query'] = null;
				value[mode] = '1';
				break;
			case 'query':
				value[mode+'Processing'] = 'query';
				value[mode+'Query'] = null;
				value[mode] = '1';
				break;
		}

		// If we're not showing create and update, we want edit and view to be in lockstep
		if(mode === 'view' && !this.props.showCreateUpdate) {
			switch (processingMode) {
				default:
				case 'never':
					value.editProcessing = 'never';
					value.editQuery = null;
					value.edit = '0';
					break;
				case 'always':
					value.editProcessing = 'always';
					value.editQuery = null;
					value.edit = '1';
					break;
				case 'query':
					value.editProcessing = 'query';
					value.editQuery = null;
					value.edit = '1';
					break;
			}
		}

		this.props.onChange(value);
	}

	queryChange(mode, event) {
		let value = {};
		value[mode+'Query'] = event.target.value === '""' ? null : typeof event.target.value === 'object' ? JSON.stringify(event.target.value) : event.target.value;
		this.props.onChange(value);
	}

	/**
	 * 
	 * @returns - DOM
	 * @memberof VisibilitySettingsConfiguration
	 */
	render() {

		let { 
			view, 
			viewProcessing, 
			viewQuery, 
			edit, 
			editProcessing, 
			editQuery, 
			viewAllowQuery, 
			securityGroupName, 
			visibilityControlsReadOnly, 
			disabledRetailRestrictions,
			onUpgradeClick
		} = this.props;

		let viewVisibilityOptions = [
            {value: 'never', label: 'Never'},
            {value: 'always', label: 'Always'},
		];
		let editVisibilityOptions = [
			{value: 'never', label: 'Never'},
            {value: 'always', label: 'Always'},
            
		];
		if(viewAllowQuery) {
			viewVisibilityOptions.push(
				{value: 'query', label: 'If current Record is found in...', isDisabled: !viewAllowQuery}
			);
			editVisibilityOptions.push(
				{value: 'query', label: 'If current Record is found in...', isDisabled: true}
			);
		}

		if(disabledRetailRestrictions) {
			viewVisibilityOptions.map(viewVisibilityOption => {
				viewVisibilityOption.isDisabled = true;
				return viewVisibilityOption;
			});
			editVisibilityOptions.map(editVisibilityOption => {
				editVisibilityOption.isDisabled = true;
				return editVisibilityOption;
			})
		}

		let labels = {
			'always': 'Always',
			'never': 'Never',
			'query': 'If current Record is found in...'
		}

		viewProcessing = viewProcessing ? viewProcessing : (view === "1" ? 'always' : 'never');
		editProcessing = editProcessing ? editProcessing : (edit === "1" ? 'always' : 'never');

		let viewProcessingFormatted = {value: viewProcessing, label: labels[viewProcessing]};
		let editProcessingFormatted = {value: editProcessing, label: labels[editProcessing]};

		// Add colors which were used before react-select upgrade
		const selectColorStyles = {
			control: styles => ({ ...styles, fontSize: '0.7em', backgroundColor: 'white' }),
			option: (styles, { data, isDisabled, isFocused, isSelected }) => {
				let backgroundColor = 'white';
				if (isFocused) {
					backgroundColor = '#ebf5ff';
				} else if(isSelected) {
					backgroundColor = '#f5faff';
				}
				return {
					...styles,
					fontSize: '0.7em',
					backgroundColor: backgroundColor,
					color: isDisabled ? '#cccccc' : 'black',
					cursor: isDisabled ? 'not-allowed' : 'pointer'
				}
			}
		  };

		return (
			<div>
				<div className="row mb-1">
					<div className="col-8">
						<label className="setting-label">Permit members of the {securityGroupName} Security Group to:</label>
					</div>
					{
						viewAllowQuery 
						?	<div className="col-4">
								<label className="setting-label">Record Set: </label>
							</div>
						: 	<div className="col-4"></div>
					}
				</div>
				<div className="row mb-1">
					<div className="col-2 pr-2">
						<label className="setting-label">View: </label>
					</div>
					<div className="col-4">
						<ReactSelect 
								options={viewVisibilityOptions} 
								value={viewProcessingFormatted} 
								onChange={this.processingChange.bind(this, 'view')}
								styles={selectColorStyles}
							/>
					</div>
					{
						viewAllowQuery ? [
							<div key="first" className="col-2"></div>,
							<div key="second" className="col-4">
							{/* {(disabledRetailRestrictions 
									? <button 
										key="submit" 
										className="btn btn-success btn-lg setting-list-button mr-3 ml-1" 
										form="appearance-form" 
										aria-label="Upgrade to use Visibility"
										style={{ width: '100%'}}
										onClick={onUpgradeClick}
									>
										Upgrade
									</button>
									: <QueryButton
										isDisabled={viewProcessing === 'query' ? false : true}
										className="form-control mb-0"
										label={'Query'}
										fieldSchemaName='query'
										value={viewQuery}
										id='query'
										onChange={this.queryChange.bind(this, 'view')}
									/>
							)} */}
							<QueryButton
								isDisabled={disabledRetailRestrictions || (viewProcessing === 'query' ? false : true)}
								className="form-control mb-0"
								label={'Query'}
								fieldSchemaName='query'
								value={viewQuery}
								id='query'
								onChange={this.queryChange.bind(this, 'view')}
							/>
							</div>
						]
						: 	<div className="col-7"></div>
					}
				</div>
				{this.props.showCreateUpdate ? 
					<div className="row">
						<div className="col-2 pr-2">
							<label className="setting-label">Add/Edit: </label>
						</div>
						<div className="col-4">
						{	
							visibilityControlsReadOnly
								? <div className="hard-group-details">{editProcessingFormatted.label}</div>
								: <ReactSelect 
									options={editVisibilityOptions} 
									value={editProcessingFormatted} 
									onChange={this.processingChange.bind(this, 'edit')}
									styles={selectColorStyles}
									/>
						}
						</div>
						{
							viewAllowQuery ? [
								<div className="col-4" key="first"></div>,
								<div className="col-2" key="second">
									{(disabledRetailRestrictions 
										? <button 
											key="submit" 
											className="btn btn-success btn-lg setting-list-button mr-3 ml-1" 
											form="appearance-form" 
											aria-label="Upgrade to use Visibility"
											style={{ width: '100%'}}
											onClick={onUpgradeClick}
										>
											Upgrade to use Visiblity
										</button>
										: <QueryButton
											isDisabled={editProcessing === 'query' ? false : true}
											className="form-control mb-0"
											label={'Query'}
											fieldSchemaName='query'
											value={editQuery}
											id='query'
											onChange={this.queryChange.bind(this, 'edit')}
										/>)}
								</div>,
							]
							: 	<div className="col-7"></div>
						}
					</div> : null}
			</div>
		)
	}
}
export default VisibilitySettingsConfiguration;