import React from 'react';
import _ from 'lodash';
import BaseSettingForm from './base-setting-form.react';
import BlocklyDialog from './blockly-dialog.react';
import BlocklyUtils from '../../utils/blockly-utils';

let defaultXML = '<xml xmlns="http://www.w3.org/1999/xhtml"><block type="citdev_output_statement" id="cd_output" x="10" y="10"></block></xml>';

/**
 * Component for ExpressionEditor
 * 
 * @class ExpressionEditor
 * @extends {Component}
 */
export default class ExpressionEditor extends BaseSettingForm {
	/**
	 * Creates an instance of ExpressionEditor
	 * 
	 * @param {Object} props
	 * @constructor
	 * 
	 * @memberof ExpressionEditor
	 */
	constructor(props) {
		super(props);
		this.state = {dialog: false, value: ''};
		this.onChange = this.onChange.bind(this);
		this.toggleDialog = this.toggleDialog.bind(this);
	}

	/**
	 * Toggles dialog to show
	 *
	 * @param {Boolean} true shows dialog, false hides
	 *
	 * @memberof ExpressionEditor
	 */
	toggleDialog(show) {
		this.setState({dialog: show});
	}

	/**
	 * Handles BlocklyDialog xml change
	 *
	 * @memberof ExpressionEditor
	 */
	onChange(xml) {
		let {onChange} = this.props;
		let value = null;
		if (xml !== defaultXML) {
			value = JSON.stringify(BlocklyUtils.parseXml(xml));
		}
		let event = {
			target: {
				value: value,
				id: this.props.fieldSchemaName
			}
		};

		if (onChange) {
			onChange(event);
		}
	}
	/**
	 * Render the ExpressionEditor
	 * 
	 * @returns React
	 * 
	 * @memberof ExpressionEditor
	 */
	render() {
		let {label, fieldSchemaName, value} = this.props,
			{dialog} = this.state,
			labelString = _.startCase(label),
			valueObj = null;

		try {
			valueObj = JSON.parse(value);
			if(valueObj.generatedJavascript === '') {
				valueObj = {'workspaceXML': defaultXML};
			}
		} catch (error) {
			valueObj = {'workspaceXML': defaultXML};
		}

		return(
			<div className="form-group row">
				<label className="form-control-label col-sm-3" htmlFor={fieldSchemaName}>{labelString}</label>
				<div className="col-sm-9">
					<button type="button" onClick={this.toggleDialog.bind(this, true)}>&fnof;(x)</button>
					<pre><code>{valueObj.generatedJavascript}</code></pre>

				{dialog ? <BlocklyDialog onChange={this.onChange} onClose={this.toggleDialog.bind(this, false)} title={labelString} value={valueObj.workspaceXML} /> : null}
				{dialog ? <div className="modal-backdrop fade show" /> : null}
				</div>
			</div>
		);
	}
}
