import React,{Component} from 'react';
import PropTypes from 'prop-types';
import Select from './select.react';
import FieldTypeStore from '../../stores/field-type-store';
import FieldStore from '../../stores/field-store';

// import ConfigurationJsonStore from '../../stores/configuration-json-store';

/**
 * Component for ModeVariant chooser
 * 
 * @class ViewVariant
 * @extends {Component}
 */
export default class ViewVariant extends Component {
	/**
	 * Render the selector for Variants - View
	 * 
	 * @returns React
	 * 
	 * @memberof ViewVariant
	 */
	render() {
		let viewVariants = [{'name': 'Select', 'value': ''}];
		let fieldObj = FieldStore.get(this.props.recordId);
		let fieldTypeId = fieldObj.fieldType;

		// Look up our options
		// append selectOptions = [] to the this.props.
		if(window.FieldTypeStore) { 
			// Pull the Variants from the FT Store.
			let fieldTypeObject = FieldTypeStore.get(fieldTypeId);
			if(fieldTypeObject){
				let variants = fieldTypeObject.variants ? fieldTypeObject.variants : [];
				variants.forEach(variant => {
					if(variant.type === 'view') {
						viewVariants.push({
							name: variant.name,
							value: variant.reactComponentName
						});
					}
				});
			}
		} else  {
			console.warn('Error loading View Variants for fieldTypeId: ',fieldTypeId);
		}
		return (
			<Select value={this.props.value} onChange={this.props.onChange} fieldSchemaName={this.props.fieldSchemaName} selectOptions={viewVariants} />
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	ViewVariant.propTypes = {
		configurationJson: PropTypes.object,
		fieldTypeId: PropTypes.string,
		value: PropTypes.any,
		onChange: PropTypes.func, 
		fieldSchemaName: PropTypes.string
	};
}
