/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container} from 'flux/utils';
import { ReactSVG } from 'react-svg';

// Actions
import AdminSettingsActions from '../../actions/admin-settings-actions';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import FieldSettingsStore from '../../stores/field-settings-store';
import RenderStore from '../../stores/render-store';
import ComplianceStore from '../../stores/compliance-store';
import ContextStore from '../../stores/context-store';

// Utils
import { UIUtils } from '../../utils';
import ObjectUtils from '../../utils/object-utils';
import Immutable from 'immutable';


/**
 * Container to CompliancePin
 *
 * @class CompliancePin
 * @extends {Component}
 */
class CompliancePin extends Component {
	constructor(props) {
		super(props);
		this.link = null;
		this.onPinClickHandler = this.onPinClickHandler.bind(this);
		this.onPinDoubleClickHandler = this.onPinDoubleClickHandler.bind(this);
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf CompliancePin
	 */
	static getStores() {
		return [AdminSettingsStore, FieldSettingsStore, RenderStore, ComplianceStore];
	}

	/**
	 * Returns the current state
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf CompliancePin
	 */
	static calculateState(prevState, props) {
		let overlayActive = AdminSettingsStore.getIsOverlayActive('compliance');
		let isDisabled = undefined;
		let isUnused = false;

		if(overlayActive) {
			// If we already have an isDisabled in the state, then use it...
			isDisabled = ( prevState && typeof prevState.isDisabled === 'boolean' 
				? prevState.isDisabled 
				: this.calculateIsDisabled(props.renderId, props.subSettingIndex, props.subSettingSchemaName));
			
			if (isDisabled === false) {
				isUnused = this.calculateIsUnused(props.renderId);
			}
		}

		return {
			'isDisabled': isDisabled,
			'isUnused': isUnused,
			'overlayActive': AdminSettingsStore.getIsOverlayActive('compliance')
		}
	}

	static calculateIsUnused(renderId) {
		let renderObj = RenderStore.get(renderId);
		// If we dont have a renderObj (yet), then we can't calculate.
		if(!renderObj) { 
			return undefined;
		}
		
		if(renderObj.componentType === 'field') {
			let fieldSettingsObj = FieldSettingsStore.getSettings(renderObj.componentId);
			let complianceTags = fieldSettingsObj.complianceTags ? ObjectUtils.getObjFromJSON(fieldSettingsObj.complianceTags) : {};
			let usedCompliances = Object.keys(complianceTags);
			
			// Find whether any tags have been used for any compliances in this field
			return !usedCompliances.find(complianceId => {

				// Don't count inactive compliances
				let complianceObj = ComplianceStore.get(complianceId) || new Immutable.Map();
				if(complianceObj.get('isActive') !== 1) {
					return false;
				} else {
					// If any tags have been set, return true. Else, return false.
					let localTags = complianceTags[complianceId] || {};
					return Object.keys(localTags).find(tagId => !!localTags[tagId]);
				}
			});
		} else {
			// We don't currently support Compliances for pages.
			return true;
		}
	}

	static calculateIsDisabled(renderId) {
		if(renderId) {
			let renderObj = RenderStore.get(renderId);
	
			// If we dont have a renderObj (yet), then we can't calculate.
			if(!renderObj) { 
				return undefined;
			}
	
			if(renderObj.componentType === 'field') {
				return false;
			}
		}

		// Pages don't have compliances, so it'll always be disabled.
		return true;
	}

	componentDidMount() {
		this.clickTimeouts = [];
	}

	/**
	 * onPinClickHandler - Handles pin click. 
	 */
	onPinClickHandler(event) {
		// Stop clicking "through" the pin, like on List column headers
		event.preventDefault();
		event.stopPropagation();

		// Open the settings preview
		// (targetOverlay, 
		//	recordId, tableSchemaName, 
		//	parentRecordId, parentTableSchemaName, 
		//	mainTab, subSettingSchemaName, subSettingIndex, renderId, 
		// 	attachmentId)
		this.clickTimeouts.push(setTimeout(() => {
			UIUtils.openSettingsPreview('compliance', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 
			undefined, undefined, undefined, this.props.renderId, 
			this.props.attachmentId);
			// Time you have between clicks to determine if it was single or double.
		}, 250));
	}

	/**
	 * onPinDoubleClickHandler - Handles pin double click. 
	 */
	onPinDoubleClickHandler(event) {
		this.clickTimeouts.forEach(timeout => {
			clearTimeout(timeout);
		});

		// Stop clicking "through" the pin, like on List column headers
		event.preventDefault();
		event.stopPropagation();

		// Open the settings panel on the right.
		// (targetOverlay, 
		//	recordId, tableSchemaName, 
		//	parentRecordId, parentTableSchemaName, 
		//	mainTab, subSettingSchemaName, subSettingIndex, renderId, 
		// 	attachmentId)
		UIUtils.openSettingsPanel('compliance', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 
			undefined, undefined, undefined, this.props.renderId,
			this.props.attachmentId);

		// Make sure the settings are toggled to visible.
		AdminSettingsActions.onSettingsListHideChange(false);
	}

	render() {
		let { overlayActive, isUnused, isDisabled } = this.state;
		let pinDivClassAppend = (isDisabled 
			? 'disabled' 
			: (isUnused 
				? 'unused' 
				: ''));

		if( overlayActive ) {
			return <div 
						key={this.props.attachmentId || this.props.recordId}
						className={"pin " + pinDivClassAppend}
						ref={el => this.link = el}
						data-trigger="manual"
						data-toggle="popover"
						data-fallback-placement="flip"
						data-html="true"
						data-content=" "
						onClick={(event) => { if(!isDisabled) { this.onPinClickHandler(event) }}}
						onDoubleClick={(event) => { this.onPinDoubleClickHandler(event); }}
					>
						<svg className="compliance-pin pin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
							<path d="M12 2C8.1 2 5 5.1 5 9c0 5.2 7 13 7 13s7-7.8 7-13C19 5.1 15.9 2 12 2z" fill="#BBB"/>
						</svg>
						<ReactSVG
							beforeInjection={svg => {
								svg.setAttribute('viewBox', '0 0 34 34');
								svg.setAttribute('style', 'width: 16px; height: 16px');
							}}
							className="compliance-pin pin-icon"
							src={ContextStore.getUrlMedia() + "/icon-compliance.svg"} />
					</div>

		} else {
			return null;
		}
	}
}

if ('development' === process.env.NODE_ENV) {
  CompliancePin.propTypes = {
    recordId: PropTypes.string.isRequired,
    tableSchemaName: PropTypes.string.isRequired,
    parentRecordId: PropTypes.string,
    parentTableSchemaName: PropTypes.string,
    gridKey: PropTypes.any,
    fieldType: PropTypes.string,
  };
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(CompliancePin, {withProps: true});
export default container;