/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container} from 'flux/utils';
import { ReactSVG } from 'react-svg';

// Actions
import AdminSettingsActions from '../../actions/admin-settings-actions';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import DiscussionStore from '../../stores/discussion-store';
import RenderStore from '../../stores/render-store';
import ContextStore from '../../stores/context-store';

// Utils
import { UIUtils } from '../../utils';


/**
 * Container to DiscussionPin
 *
 * @class DiscussionPin
 * @extends {Component}
 */
class DiscussionPin extends Component {
	constructor(props) {
		super(props);
		this.link = null;
		this.onPinClickHandler = this.onPinClickHandler.bind(this);
		this.onPinDoubleClickHandler = this.onPinDoubleClickHandler.bind(this);
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf DiscussionPin
	 */
	static getStores() {
		return [AdminSettingsStore, DiscussionStore, RenderStore];
	}

	/**
	 * Determine if this pin is Used.
	 * @param {string} renderId Render ID for the component
	 * @param {string} fieldType Field Type of the Field.
	 * @return boolean
	 */
	static calculateIsUnused(renderId) {
		let renderObj = RenderStore.get(renderId);
		if(renderObj) {
			let componentId = renderObj.componentId;
	
			// For now, default to all discussions if no component specified
			let discussions = componentId ? DiscussionStore.getDiscussionsForComponent(componentId) : DiscussionStore.getAllArray();
			return (discussions.length ? false : true);
		}
		
		return true;
	}

	/**
	 * Returns the current state
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf DiscussionPin
	 */
	static calculateState(prevState, props) {
		let overlayActive = AdminSettingsStore.getIsOverlayActive('discussion')
		let isUnused = undefined;

		if(overlayActive) {
			isUnused = this.calculateIsUnused(props.renderId);
		} // End if the overlay is active

		return {
			'isDisabled': false,
			'isUnused': isUnused,
			'overlayActive': overlayActive
		}
	}

	componentDidMount() {
		this.clickTimeouts = [];
	}

	/**
	 * onPinClickHandler - Handles pin click. 
	 */
	onPinClickHandler(event) {
		// Stop clicking "through" the pin, like on List column headers
		event.preventDefault();
		event.stopPropagation();

		// Open the settings preview
		// (targetOverlay, 
		//	recordId, tableSchemaName, 
		//	parentRecordId, parentTableSchemaName, 
		//	mainTab, subSettingSchemaName, subSettingIndex, renderId, 
		// 	attachmentId)
		this.clickTimeouts.push(setTimeout(() => {
			UIUtils.openSettingsPreview('discussion', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 
			undefined, undefined, undefined, this.props.renderId, 
			this.props.attachmentId);
			// Time you have between clicks to determine if it was single or double.
		}, 250));
	}

	/**
	 * onPinDoubleClickHandler - Handles pin double click. 
	 */
	onPinDoubleClickHandler(event) {
		this.clickTimeouts.forEach(timeout => {
			clearTimeout(timeout);
		});

		// Stop clicking "through" the pin, like on List column headers
		event.preventDefault();
		event.stopPropagation();

		// Open the settings panel on the right.
		// (targetOverlay, 
		//	recordId, tableSchemaName, 
		//	parentRecordId, parentTableSchemaName, 
		//	mainTab, subSettingSchemaName, subSettingIndex, renderId, 
		// 	attachmentId)
		UIUtils.openSettingsPanel('discussion', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 
			undefined, undefined, undefined, this.props.renderId,
			this.props.attachmentId);

		// Make sure the settings are toggled to visible.
		AdminSettingsActions.onSettingsListHideChange(false);
	}

	render() {
		let { overlayActive, isUnused, isDisabled } = this.state;
		let pinDivClassAppend = (isDisabled 
			? 'disabled' 
			: (isUnused 
				? 'unused' 
				: ''));
		if( overlayActive ) {
			return <div 
						key={this.props.attachmentId || this.props.recordId}
						className={"pin " + pinDivClassAppend}
						ref={el => this.link = el}
						data-trigger="manual"
						data-toggle="popover"
						data-fallback-placement="flip"
						data-html="true"
						data-content=" "
						onClick={(event) => { if(!isDisabled) { this.onPinClickHandler(event) }}}
						onDoubleClick={(event) => { this.onPinDoubleClickHandler(event); }}
					>
						<svg className="discussion-pin pin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
							<path d="M12 2C8.1 2 5 5.1 5 9c0 5.2 7 13 7 13s7-7.8 7-13C19 5.1 15.9 2 12 2z" fill="#BBB"/>
						</svg>
						<ReactSVG
							beforeInjection={svg => {
								svg.setAttribute('viewBox', '0 0 30 30');
								svg.setAttribute('style', 'width: 16px; height: 16px');
							}}
							className="discussion-pin pin-icon"
							src={ContextStore.getUrlMedia() + "/icon-discussion.svg"} />
					</div>

		} else {
			return null;
		}
	}
}

if ('development' === process.env.NODE_ENV) {
  DiscussionPin.propTypes = {
    recordId: PropTypes.string.isRequired,
    tableSchemaName: PropTypes.string.isRequired,
    parentRecordId: PropTypes.string,
    parentTableSchemaName: PropTypes.string,
    gridKey: PropTypes.any,
    fieldType: PropTypes.string,
  };
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(DiscussionPin, {withProps: true});
export default container;