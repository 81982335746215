import { InterfaceActions, MetadataActions } from '../actions';
import { MetadataStore } from '../stores';

export default class ThemeBuilderUtils {
	/**
	 * saveTheme - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event passed in as a convenience in case this is used later
	 * @param {string} recordId
	 * @param {string} tableSchemaName
	 */
	static saveTheme(event, recordId, tableSchemaName) {
		let valid = true;

		if(valid) {
			// Need to add the updated date time
			let metadataObj = MetadataStore.get(recordId, tableSchemaName);
			metadataObj.lastUpdatedDateTime = JSON.stringify({
				unixTimestamp: (Date.now() / 1000),
				timezone: 'America/New_York'});

			let id = InterfaceActions.stickyNotification({ 'level': 'success', 'message': 'Saving Theme...' });
			// Push to database
			MetadataActions.pushToDatabasePromise(metadataObj, tableSchemaName).then(() => {
						InterfaceActions.clearStickyNotification(id);
						// Do something to put the style in place?

			}).catch(error => {
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save API' });
				console.error('Unable to save API Config:', error);
			})
		}
	}

	/**
	 * resetTheme - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event passed in as a convenience in case this is used later
	 * @param {string} recordId
	 * @param {string} tableSchemaName
	 */
	static resetTheme(event, recordId, tableSchemaName) {
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Resetting Theme...' });
		// Pull from database, therefore resetting it
		MetadataActions.pullFromDatabase(recordId, tableSchemaName);
	}
}