import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactBlocklyComponent from '@dmclain-citizendeveloper/citdev-react-blockly-component';
import ToolboxStore from '../../stores/toolbox-store';
import { InterfaceActions } from '../../actions';
import BlocklyUtils from '../../utils/blockly-utils';
import ObjectUtils from '../../utils/object-utils';
const style = {
	modal: {
		display: 'block'
	}
};
/*global Blockly:true*/
/**
 * Component for ExpressionEditor
 *
 * @class ExpressionEditor
 * @extends {Component}
 */
export default class BlocklyDialog extends Component {
	constructor(props) {
		super(props);
		this.copyWorkspace = this.copyWorkspace.bind(this);
		this.pasteWorkspace = this.pasteWorkspace.bind(this);
		this.clearWorkspace = this.clearWorkspace.bind(this);
		this.resetAndClose = this.resetAndClose.bind(this);
	}
	/**
	 * Copies blocks to citdev clipboard
	 */
	copyWorkspace(){
		try {
			let blocklyValueObj = BlocklyUtils.getWorkspaceInfo(this.blocklyEditor.workspace.workspace, {
				defaultToNull: true,
				includeJs: false // We don't need to bother with the JS for this
			});
			localStorage.expressionClipboard = JSON.stringify(blocklyValueObj);
			InterfaceActions.notification({ 'level': 'success', 'message': 'Copying expression to clipboard...' });
		} catch(err) {
			console.error('Error when copying expression: ', err);
			InterfaceActions.notification({ 'level': 'error', 'message': 'Error when copying expression!' });
		}
	}
	/**
	 * Pastes blocks from citdev clipboard to workspace; appends to, not overrides, blocks
	 */
	pasteWorkspace() {
		let value = localStorage.expressionClipboard;
		try{
			let valueObj = ObjectUtils.getObjFromJSON(value);
			
			let pastedblocklyxml = valueObj.blocklyxml;
			BlocklyUtils.appendToWorkspace(pastedblocklyxml, this.blocklyEditor.workspace.workspace);
			InterfaceActions.notification({ 'level': 'success', 'message': 'Pasting copied expression below the existing expression. Please check the new blocks to make sure they don\'t overlap!' });
			// let oldblocklyxml = this.state.value ? this.state.value.blocklyxml : '';
			
			
			// // Pass the pasted value into the utility function to combine them into one workspace XML string
			// let newxml = BlocklyUtils.combineWorkspaces(pastedblocklyxml, oldblocklyxml);

			// // Make sure that our new code still compiles; the catch will catch it if it's invalid XML
			// if(Blockly.Xml.textToDom(newxml)){
			// 	this.handleXMLChange(newxml);
			// 	InterfaceActions.notification({ 'level': 'success', 'message': 'Pasting new logic below existing logic. Please check your new blocks to make sure they don\'t overlap!' });
			// }
		} catch(err) {
			InterfaceActions.notification({ 'level': 'error', 'message': 'Attempted to paste invalid value into workspace.' });
			console.warn('Attempted to paste with invalid data in clipboard. Value was', value);
			console.warn('Error was', err);
		}
	}

	/**
	 * clearWorkspace will clear all of the blocks currently in the workspace
	 */
	clearWorkspace() {
		if(this.blocklyEditor) {
			this.blocklyEditor.clear();
			InterfaceActions.notification({ 'level': 'success', 'message': 'Clearing workspace expression.' });
		}
	}

	/**
	 * resetAndClose will reset the logic workspace to what it was when the Blockly Dialog was first shown
	 */
	resetAndClose() {
		if(this.blocklyEditor) {
			this.blocklyEditor.reset();
		}
		this.props.onClose();
	}

	componentWillUnmount() {
		if(this.blocklyEditor && this.blocklyEditor.workspace && this.blocklyEditor.workspace.workspace) {
			let {onChange} = this.props;
			let xml = BlocklyUtils.getXmlFromWorkspace(this.blocklyEditor.workspace.workspace);
			onChange(xml);
		}
	}


	/**
	 * Render dialog with blockly editor
	 *
	 * @memberOf BlocklyDialog
	 */
	render() {
		let {onClose, title, value} = this.props;

		let config = {
			workspaceConfiguration: {
				grid: {
					spacing: 20,
					length: 3,
					colour: '#ccc',
					snap: true
				},
				zoom: {
					controls: true,
					wheel: true,
					startScale: 0.9,
					maxScale: 3,
					minScale: 0.3,
					scaleSpeed: 1.2
				}
			},
			toolboxCategories: ToolboxStore.getExpressionToolbox().toJS(),
			wrapperDivClassName: 'blockly-height'
		};
		return(
			<div style={style.modal} className="modal fade show">
				<div id="blockly-dialog-modal" className="blockly-dialog-modal modal-dialog modal-lg expression-dialog">
					<div className="modal-content">
						<div className="modal-header align-items-center">
							<h1 className="modal-title align-self-center">{title}</h1>
							<div className={`modal-header-buttons`}>
								<button 
									key="copy" 
									className="btn btn-secondary mr-0 dialog-button-copy"
									form="appearance-form" 
									aria-label="Copy"
									onClick={this.copyWorkspace}>
									Copy
								</button>
								<button 
									key="paste" 
									className="btn btn-secondary mx-2 dialog-button-paste"
									form="appearance-form" 
									aria-label="Paste"
									onClick={this.pasteWorkspace}>
									Paste
								</button>
								<button
									key="clear"
									className="btn btn-warning mr-0 dialog-button-clear"
									form="appearance-form"
									aria-label="Clear Query"
									onClick={this.clearWorkspace}>
									Clear
								</button>
								<button
									key="resetAndClose"
									className="btn btn-warning mx-2 dialog-button-reset-and-close"
									form="appearance-form"
									aria-label="Reset and Close"
									onClick={this.resetAndClose}>
									Reset and Close
								</button>
								<button
									key="saveAndClose"
									className="btn btn-primary mr-0 dialog-button-save-and-close"
									form="appearance-form"
									aria-label="Save and Close"
									onClick={onClose}>
									Save and Close
								</button>
							</div>
						</div>
						<div className="modal-body py-0">
							<div style={{ height: '100%' }}>
								<style>{'.blockly-height{height: 100%}'}</style>
								<ReactBlocklyComponent.BlocklyEditor
									{...config}
									initialXml={value}
									ref={blocklyEditor => this.blocklyEditor = blocklyEditor}
									// xmlDidChange={onChange}
									namedContexts={this.props.namedContexts}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
if ('development' === process.env.NODE_ENV) {
	BlocklyDialog.propTypes = {
		title: PropTypes.string,
		value: PropTypes.string,
		onChange: PropTypes.func,
		onClose: PropTypes.func,
		namedContexts: PropTypes.string
	};
}