/* global citDev */
import React, {Component} from 'react';
import {Container} from 'flux/utils';
import ProcessingStore from '../../stores/processing-store';
import NotificationBar from './bootstrap-notification-bar';
import ReloadUINotification from './reload-ui-notification';
import MaintenanceUINotification from './maintenance-ui-notification';

/**
 * Container to ProcessingActionContainer
 *
 * @class ProcessingActionContainer
 * @extends {Component}
 */
class ProcessingActionContainer extends Component {
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf VisibilityPin
	 */
	static getStores() {
		return [ProcessingStore];
	}

	/**
	 * Returns the current State of the VisibilityPin Settings
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf VisibilityPin
	 */
	static calculateState(prevState) {
		let notificationShouldDisplay = false;
		ProcessingStore.getRunningActions().forEach((value) => {
			notificationShouldDisplay = notificationShouldDisplay || value;
		});
		let percentage = ProcessingStore.getUploadPercentage();
		if (percentage) {
			notificationShouldDisplay = true;
		}
		let reloadUI = ProcessingStore.getReloadUI();
		let maintenanceUI = ProcessingStore.getMaintenanceUI();
		return {
			notificationShouldDisplay,
			percentage,
			'reloadUI': reloadUI.reloadUI,
			'reloadTime': reloadUI.reloadTime,
			'maintenanceUI': maintenanceUI.maintenanceUI,
			'maintenanceMessage': maintenanceUI.message
		};
	}
	
	render() {
		let messageJSX = null;
		let { notificationShouldDisplay, percentage, reloadUI, reloadTime, maintenanceUI, maintenanceMessage } = this.state;
		let title = null;
		let message = null;
		let type = null;
		let width = 500;
		if (maintenanceUI) {
			messageJSX = <MaintenanceUINotification type={'info'} message={maintenanceMessage} />
		} else if (reloadUI) {
			messageJSX = <ReloadUINotification type={'info'} reloadTime={reloadTime} />
		} else if (notificationShouldDisplay) {
			if(percentage) {
				title = 'Uploading Progress: ' + percentage;
				type = 'info';
				width = 300;
			} else {
				title = 'Logic is processing.';
				type = 'warning';
				message = 'Refreshing or closing this page will cancel it, and may cause data loss.';
				width = 450;
			}
			messageJSX = <NotificationBar type={type} message={message} title={title} width={width}/>;
		}
		return messageJSX;
	}
}

const container = Container.create(ProcessingActionContainer);
export default container;