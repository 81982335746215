import { TableStore } from '../stores';
import uuid from 'uuid';
/* global citDev */

export default class QueryUtils {
	/**
	 * Builds a query based off of the tableSchemaName.
	 * At this time, only builds an unsorted list of all records from that table;
	 * May be updated later to permit including sorts and filters.
	 * 
	 * @param {string} tableSchemaName 
	 */
	static buildQuery(tableSchemaName) {
		let tableObj = TableStore.getByTableSchemaName(tableSchemaName) || null;
		if(!tableObj) {
			return undefined;
		}

		let queryId = uuid.v4();
		let returnNode = uuid.v4();
		let queryObj = {
			"queryId": queryId,
			"returnNode": returnNode,
			"filters": [],
			"nodes": [
				{
					"pluralName": tableObj.pluralName,
					"singularName": tableObj.singularName,
					"icon": tableObj.icon,
					"tableSchemaName": tableSchemaName,
					"roles": "",
					"nodeId": returnNode,
					"displayName": tableObj.pluralName
				}
			],
			"sorts": []
		};
		return JSON.stringify(queryObj);
	}

	/**
	 * Find and return the table schema name that this query returns
	 * @param {string} queryJSON 
	 * @return {string} Query TableSchemaName
	 */
	static getReturnNodeTableSchemaName(queryJSON) {
		let queryObj = {};
		try {
			queryObj = JSON.parse(queryJSON);
		} catch (error) {
			console.warn('Unable to parse query JSON in getReturnNodeTable', queryJSON);
			return false;
		}

		if(queryObj && queryObj.nodes && queryObj.returnNode !== '') {
			let returnNodeId = queryObj.returnNode,
				returnNode = queryObj.nodes.find(node => {
					return node.nodeId === returnNodeId;
				});
			return returnNode.tableSchemaName;
		}

		return false;
	}
}