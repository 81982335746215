import AuthenticationActions from '../actions/authentication-actions';
import socket from '../utils/socket';
import restFetcher from '../utils/rest-fetcher';
import ContextStore from '../stores/context-store';
import InterfaceActions from '../actions/interface-actions';

export default {
	/**
	 * processes the username and password agains the api
	 *
	 * @param {string} username
	 * @param {string} password
	 */
	login: function (username, password) {
		//Socket Id has been replace with windowId but is still
		//sent as a socketId for backwards compatabilty
		restFetcher('gw/auth-v3', JSON.stringify({
			username: username,
			password: password,
			socketId: socket.id,
			windowId: window.sessionStorage.getItem('citdevWindowId'),
		})).then(function (result) {
			if (result.responseCode === 200) {
				
				//Include the username in the response: 
				if(!result.response['username']){
					result.response['username'] = username;
				}
				AuthenticationActions.onAuthLogin(result.response);
				//Retrieve the redirect Path in the session Storage 
				let redirectPath = sessionStorage.getItem('redirectPath');
				let pathState = sessionStorage.getItem('redirectState');

				AuthenticationActions.haveAuthCreds(true);

				//Redirect to the page where we initially meant to Go To: 
				InterfaceActions.replacePage({'url': redirectPath ? redirectPath : '/', 'replaceHistory': true, 'state': pathState ? JSON.parse(pathState) : null});
				
			} else {
				AuthenticationActions.onAuthError();
				// AuthenticationActions.authenticated(false);
				localStorage.removeItem('authenticated');
				
			}
		}).catch(function (error) {
			//emit an auth error action
			AuthenticationActions.onAuthError();
			
			localStorage.removeItem('authenticated');
			
		});
	},

	changeUser: function(userId, username) {
		//Socket Id has been replace with windowId but is still
		//sent as a socketId for backwards compatabilty
		return restFetcher('gw/changeUser-v1', JSON.stringify({
			userId: userId,
			username: username,
			socketId: socket.id,
			windowId: window.sessionStorage.getItem('citdevWindowId'),
		})).then(function (result) {
			if (result.responseCode === 200) {
				
				//Include the username in the response: 
				if(!result.response['username']){
					result.response['username'] = username;
				}
				AuthenticationActions.onAuthLogin(result.response);
				AuthenticationActions.haveAuthCreds(true);

			} else {
				AuthenticationActions.onAuthError();
				// AuthenticationActions.authenticated(false);
				localStorage.removeItem('authenticated');
			}
		}).catch(function (error) {
			//emit an auth error action
			AuthenticationActions.onAuthError();
			localStorage.removeItem('authenticated');
		});
	},

	// Wrap in a promise, accept a param for redirecting.

	logout: function (redirect) {
		return new Promise((resolve, reject) => {
			let basePath = 'https://' + window.location.host;
			let basePathOverridePrefix = sessionStorage.getItem('defaultAPIGWOverride');
			if(basePathOverridePrefix) { 
				basePath = 'https://' + basePathOverridePrefix + '.citizendeveloper.com';
			}

			let portRegexp = new RegExp(/:\d+$/);
			let portMatches = portRegexp.exec(basePath);

			if (portMatches) {
				//if the base path has a port number then this is a webpack dev server
				console.log('Dev Server Detected')
				basePath = basePath.replace(portMatches[0], '');
			}

			fetch( basePath+'/gw/logout-v1', {'method': 'GET',
				'credentials': 'include'
			}).then(function(response) {
				if (response.status >= 200 && response.status < 300) {
					return response
				} else {
					var error = new Error(response.statusText)
					error.response = response
					throw error
				}
			}).then(function(response) {
				return response.json();
			}).then(function(result) {
					//Reset redirect Page to home after logging out
					sessionStorage.setItem('redirectPath', '/');
					
					//Clears the local storage 
					AuthenticationActions.onAuthLogout();
					
					// Purposly not returning this resolve.
					resolve();
					if(redirect) {
						//redirects the user
						let loginPageId = ContextStore.getDefaultLoginPageId();
						let url = '/citdev/login';
						if (loginPageId) {
							url = '/'+loginPageId;
						}
						InterfaceActions.replacePage({'url': url});
					}
			}).catch(error => {
				return reject(error);
			})
		})
	}
};
