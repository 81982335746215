import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Constants
import Help from '../../constants/help-upgrade-constants';

// Actions
import { 
	AdminSettingsActions, 
	FieldActions, 
	InterfaceActions, 
	PageActions,
} from '../../actions';

// Stores
import { 
	AdminSettingsStore, 
	ContextStore, 
	FieldStore,
	PageStore,
	RenderStore,
} from '../../stores';

// Utils
import ObjectUtils from '../../utils/object-utils';
import UIUtils from '../../utils/ui-utils';

/**
 * Displays dashboard header
 */
class PresentationDashboardHeader extends Component {
  /**
   * Creates an instance of ThemeBuilder
   *
   * @memberOf ThemeBuilder
   */
  constructor(props) {
    super(props);
    this._pushChildFields = this._pushChildFields.bind(this);
	this._onResizerCancel = this._onResizerCancel.bind(this);
    this._onResizerSave = this._onResizerSave.bind(this);
  }
	/**
	 * Return the stores we're listening to for changes.
	 *
	 * @return {array}  description
	 */
	static getStores() {
		return [ AdminSettingsStore ];
	}

	/**
	 * Calculate our state anytime a store changes.
	 *
	 * @param  {object} prevState
	 * @return {object}
	 */
	static calculateState(prevState) {
		return {
			'isActive' : (AdminSettingsStore.getActiveDashboard() === 'presentation' ? true : false),
		};
	}

	/**
	 * render the component
	 *
	 * @return {JSX}
	 */
	render() {
		let { isActive } = this.state;

		let { 
			onOverlayEnable, 
			onDashboardSelect,
			isResizerOn,
			isVisibilityOn,
			isAppearanceOn,
			panelDashboard
		} = this.props;

		let resizerControls = <div title="Resizer" className={`cd-dashboard-pin`} 
				onClick={event => {
					event.stopPropagation();
					onOverlayEnable('resizer');
					}
				}>
				<img 
					src={ContextStore.getUrlMedia() + "/icon-resizer.svg"}
					alt="" />
			</div>;
		if(isResizerOn) {
			resizerControls = [
				<div key="Save" className={`save btn btn-primary px-1`} 
					onClick={event => {
						event.stopPropagation();
						this._onResizerSave();
						}
					}>
					<h5>Save</h5>
				</div>,
				<div key="Reset" className={`reset ml-1 btn btn-warning px-1`} 
					onClick={event => {
						event.stopPropagation();
						this._onResizerCancel();
						}
					}>
					<h5>Reset</h5>
				</div>];
		}
		return (
			<div onClick={() => { onDashboardSelect('presentation') }} className={`cd-dashboard-header ${panelDashboard === 'presentation' ? 'presentation' : ''}`}>
				<div className="d-flex justify-content-between">
					<div className="d-flex align-items-center">
						<div className="d-flex cd-dashboard-title">
							<h4 className="cd-dashboard-sign">
								{isActive 
									? <i title="Collapse" className="fa fa-minus" aria-hidden="true"></i> 
									: <i title="Expand" className="fa fa-plus" aria-hidden="true"></i>
								}
							</h4>
							<h4 className="ml-1">Presentation</h4>
						</div>
						<div title="Presentation Help" className="cd-dashboard-info" onClick={() => { UIUtils.onHelpClick(Help.HELP_DASHBOARD_PRESENTATION); }}>
							<i className="fa fa-info-circle" aria-hidden="true"></i>
						</div>
					</div>
					<div className="d-flex cd-dashboard-pin-container">
						{ resizerControls }
						<div title="Visibility" className={`cd-dashboard-pin ml-1 ${isVisibilityOn ? 'visibility' : ''}`} 
							onClick={event => {
								event.stopPropagation();
								onOverlayEnable('visibility');
								}
							}>
							<img 
								src={ContextStore.getUrlMedia() + "/icon-visibility.svg"}
								alt="" />
						</div>
						<div title="Appearance" className={`cd-dashboard-pin ml-1 ${isAppearanceOn ? 'appearance' : ''}`} 
							onClick={event => {
								event.stopPropagation();
								onOverlayEnable('appearance');
								}
							}>
							<img 
								src={ContextStore.getUrlMedia() + "/icon-appearance.svg"}
								alt="" />
						</div>
					</div>
				</div>
			</div>
		);
	}

	/**
	 * Cancel the resizer...
	 */
	_onResizerCancel() {
		// Will have to do for now?
		document.location.reload();
	}

	/**
	 * Save the Resizer
	 */
	_onResizerSave() {
		let pagesToSave = [];

		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Updating Resizer settings...' });

		// Main Page
		pagesToSave.push(ContextStore.getPageId());

		// Add all Dialogs
		let dialogRenders = RenderStore.getDialogRenders();
		dialogRenders.forEach(dialogRender => {
			if(pagesToSave.indexOf(dialogRender.componentId) === -1) {
				pagesToSave.push(dialogRender.componentId);
			}
		});

		// Loop over each page and save it!
		let pageSavePromises = [];
		pagesToSave.forEach(pageToSave => {
			// We only want to SAVE the dirty values
			pageSavePromises.push(PageActions.pushToDatabasePromise(PageStore.get(pageToSave, true)));
			
			// Used to look up attachedFields, so we want the full object
			let pageObj = PageStore.get(pageToSave);

			//look for sub grids
			let attachedFieldsArr = ObjectUtils.getObjFromJSON(pageObj.attachedFields);

			if(Array.isArray(attachedFieldsArr)) {
				attachedFieldsArr.forEach(attachedField => {
					// For now, just save all of the possible child fields
					let recordIds = attachedField.recordIds ? attachedField.recordIds : [attachedField.recordId];
					let found = {};
		
					recordIds.forEach(recordId => {
						if(!found[recordId]) {
							let fieldSettings = FieldStore.getSettings(recordId);
							if (fieldSettings && fieldSettings.attachedFields) {
								FieldActions.pushToDatabase(FieldStore.get(recordId));
								let attachedFieldsArr = (fieldSettings.attachedFields 
									? ObjectUtils.getObjFromJSON(fieldSettings.attachedFields) 
									: undefined);
								if(attachedFieldsArr) {
									this._pushChildFields(attachedFieldsArr);
								}
							}
						}
					});
				});
			}
		});

		Promise.all(pageSavePromises).then(() => {
			// Display notification to user
			InterfaceActions.notification({ 'level': 'success', 'message': 'Resizer update complete.' });
		}).catch(error => {
			InterfaceActions.notification({ 'level': 'error', 'message': 'Error updating resizer, see console.' });
			console.error(error);
		})

		// Some visibility overlays require recalculating child fields
		AdminSettingsActions.onOverlayChangeWithRecalculation('resizer');
	}

	/**
	 * Recursively push child attached fields arrays.
	 * 
	 * @param {Array} attachedFieldsArr 
	 */
	_pushChildFields(attachedFieldsArr) {
		if(Array.isArray(attachedFieldsArr)) {
			attachedFieldsArr.forEach(attachedField => {
				// For now, just save all of the possible child fields
				let recordIds = attachedField.recordIds ? attachedField.recordIds : [attachedField.recordId];
				let found = {};

				recordIds.forEach(recordId => {
					if(!found[recordId]) {
						let fieldSettings = FieldStore.getSettings(recordId);
						if (fieldSettings && fieldSettings.attachedFields) {
							FieldActions.pushToDatabase(FieldStore.get(recordId));
							let attachedFieldsArr = (fieldSettings.attachedFields 
								? ObjectUtils.getObjFromJSON(fieldSettings.attachedFields) 
								: undefined);
							if(attachedFieldsArr) {
								this._pushChildFields(attachedFieldsArr);
							}
						}
					}
				});
			});
		}
	}
}
const container = Container.create(PresentationDashboardHeader);
export default container;