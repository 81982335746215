import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import ConsumableCreditsConstants from '../constants/consumable-credits-constants';
import socketFetcher from '../utils/socket-fetcher';
import uuid from 'uuid';

const ConsumableCreditsActions = {
	/**
	 * Consumes a resource
	 *
	 * @param {string} resource
	 * @param {number} quantity
	 * @param {string} [requestId=uuid] If not passed the request id will be auto generated.  This allows for the tracking of an indivual consume call
	 * @param {boolean} [alwaysConsume=true] Allows a resource to go negative in balance
	 * @returns {string} requestId
	 */
	consume(resource, quantity, requestId, alwaysConsume) {
		requestId = requestId ? requestId : uuid.v4();
		alwaysConsume = typeof alwaysConsume === 'boolean' ? alwaysConsume : true;
		socketFetcher('gw/consumableCreditsConsume-v1', JSON.stringify({ 
			'resource': resource,
			'quantity': quantity,
			'alwaysConsume': alwaysConsume})).then(function (data) {
				if(200 <= data.responseCode && data.responseCode < 300) {
					// If a 200 response is received
					AppDispatcher.dispatch(Immutable.fromJS({
						type: ConsumableCreditsConstants.CONSUME_CREDIT,
						requestId: requestId,
						resource: resource,
						creditsAvailable: data.response.creditsAvailable
					}));
				}  else {
					console.warn('Error getting consumable credits for resource %s. Response received was', resource, data);
					AppDispatcher.dispatch(Immutable.fromJS({
						type: ConsumableCreditsConstants.CONSUME_CREDIT_ERROR,
						resource: resource,
						requestId: requestId,
						message: data.response
					}));
				}
		}).catch(error => {
			AppDispatcher.dispatch(Immutable.fromJS({
				type: ConsumableCreditsConstants.CONSUME_CREDIT_ERROR,
				resource: resource,
				requestId: requestId,
				message: error.message ? error.message : error.toString()
			}));
		});

		return requestId;
	}
};

export default ConsumableCreditsActions;