let GridUtils = {
	/**
	 * Add missing fields to the end of the layouts, and remove any missing from the layouts.
	 * 
	 * @param {object} fieldPositionObj 
	 * @param {string} components 
	 * @returns {object}
	 * 
	 * @memberof PageStore
	 */
	'reconcileFieldPositionsWithAttachedFields': function(fieldPositionObj, attachedFields) {

		// Get Attached Fields
		let attachedFieldsArr = (attachedFields ? JSON.parse(attachedFields) : []);
		if(!attachedFieldsArr.length) {
			return '{}';
		}

		// Lets remove any fields from the fieldPositions that we don't find in the attachedFields
		// Check each screensize
		['lg', 'md', 'sm'].forEach(screenSize => {
			// Loop over it backwards so we can splice
			if(Array.isArray(fieldPositionObj[screenSize])) {
				for(let j = fieldPositionObj[screenSize].length - 1; j >= 0; j--) {
					// Grab the field ID from the first layout (i is the index that has the field ID)
					let fieldId = (fieldPositionObj[screenSize][j] && fieldPositionObj[screenSize][j].i ? fieldPositionObj[screenSize][j].i : undefined);
					// filter compSSObj, looking for this field..
					let foundField = attachedFieldsArr.filter(attachedField => {
						return (attachedField.attachmentId === fieldId || attachedField.recordId === fieldId);
					});
					if(!foundField.length || !fieldId) {
						// If we dont have it, then splice! Also get rid of any junk data... if "i" is missing, it's malformed.
						fieldPositionObj[screenSize].splice(j, 1);
					}
				}
			}
		});

		// Now we need to figure out what the "bottom" of each screen size is...
		let lastYs = {lg: 0, md: 0, sm: 0};
		['lg', 'md', 'sm'].forEach(screenSize => {
			if(Array.isArray(fieldPositionObj[screenSize])) {
				fieldPositionObj[screenSize].forEach(fieldPosition => {
					if(fieldPosition.y >= lastYs[screenSize]) {
						lastYs[screenSize] = (fieldPosition.y + fieldPosition.h); // Highest Y + Objects Height
					}
				});
			}
		});

		attachedFieldsArr.forEach(fieldThatShouldHavePosition => {
			let fieldId = fieldThatShouldHavePosition.attachmentId || fieldThatShouldHavePosition.recordId;
			['lg', 'md', 'sm'].forEach(screenSize => {
				if(Array.isArray(fieldPositionObj[screenSize])) {
					let componentFound = false;
					fieldPositionObj[screenSize].forEach(fieldPosition=> {
						if(fieldPosition.i === fieldId) {
							componentFound = true;
						}
					});
					if(!componentFound) {
						fieldPositionObj[screenSize].push(
							{
								i: fieldId,
								h: 4,
								w: 6,
								x: 0,
								y: lastYs[screenSize]
							}
						);
						lastYs[screenSize] += 1;
					}
				}
			});
		});

		return JSON.stringify(fieldPositionObj);
	},
	/**
	 * Add missing fields to the end of the layouts extra, and remove any missing from the layouts extra.
	 * 
	 * @param {object} fieldPositionExtrasObj 
	 * @param {string} components 
	 * @returns {object}
	 * 
	 * @memberof PageStore
	 */
	'reconcileFieldPositionsExtrasWithAttachedFields': function(fieldPositionExtrasObj, attachedFields) {

		// Get Attached Fields
		let attachedFieldsArr = (attachedFields ? JSON.parse(attachedFields) : []);

		// Lets remove any fields from the fieldPositions that we don't find in the attachedFields
		// Check each screensize
		['lg', 'md', 'sm'].forEach(screenSize => {
			// Loop over it backwards so we can splice
			if(Array.isArray(fieldPositionExtrasObj[screenSize])) {
				for(let j = fieldPositionExtrasObj[screenSize].length - 1; j >= 0; j--) {
					// Grab the field ID from the first layout (i is the index that has the field ID)
					let fieldId = (fieldPositionExtrasObj[screenSize][j] && fieldPositionExtrasObj[screenSize][j].i ? fieldPositionExtrasObj[screenSize][j].i : undefined);
					// filter compSSObj, looking for this field..
					let foundField = attachedFieldsArr.filter(attachedField => {
						return (attachedField.attachmentId === fieldId || attachedField.recordId === fieldId);
					});
					if(!foundField.length || !fieldId) {
						// If we dont have it, then splice! Also get rid of any junk data... if "i" is missing, it's malformed.
						fieldPositionExtrasObj[screenSize].splice(j, 1);
					}
				}
			}
		});

		// Now we need to figure out what the "bottom" of each screen size is...
		let lastYs = {lg: 0, md: 0, sm: 0};
		['lg', 'md', 'sm'].forEach(screenSize => {
			if(Array.isArray(fieldPositionExtrasObj[screenSize])) {
				fieldPositionExtrasObj[screenSize].forEach(fieldPosition => {
					if(fieldPosition.y >= lastYs[screenSize]) {
						lastYs[screenSize] = (fieldPosition.y + fieldPosition.h); // Highest Y + Objects Height
					}
				});
			}
		});

		attachedFieldsArr.forEach(fieldThatShouldHavePosition => {
			let fieldId = fieldThatShouldHavePosition.attachmentId || fieldThatShouldHavePosition.recordId;
			['lg', 'md', 'sm'].forEach(screenSize => {
				if(Array.isArray(fieldPositionExtrasObj[screenSize])) {
					let componentFound = false;
					fieldPositionExtrasObj[screenSize].forEach(fieldPosition=> {
						if(fieldPosition.i === fieldId) {
							componentFound = true;
						}
					});
					if(!componentFound) {
						fieldPositionExtrasObj[screenSize].push(
							{
								i: fieldId,
								h: 4,
								w: 6,
								x: 0,
								y: lastYs[screenSize]
							}
						);
						lastYs[screenSize] += 1;
					}
				}
			});
		});

		return JSON.stringify(fieldPositionExtrasObj);
	},
};

export default GridUtils;