import ContextStore from '../stores/context-store' 

/**
 * restFetcher - Utility class that checks status of fetch response.
 *
 * @param  {string} service   name of the service to append to API
 * @param  {string} fetchBody JSON of params
 * @return {Object}           data
 * @todo check data.responseCode ?
 */
const restFetcher = function(service, fetchBody){
	// console.log('calling rest fetcher:', service, fetchBody);
    return new Promise (function (resolve, reject) {
		let basePath = ContextStore.getBasePath();

		// Add the installation id to every call
		let fetchObject = fetchBody;
		try{
			fetchObject = JSON.parse(fetchBody);
		} catch(err) {
			fetchObject = fetchBody;
		}
		if(!fetchObject.installationId) {
			let installationData = ContextStore.getInstallationData();
			fetchObject.installationId = installationData.id;
		}

		fetchBody = JSON.stringify({encoding: 'base64', encodedBody: btoa(JSON.stringify(fetchObject))});

      fetch(basePath + '/' + service, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
		credentials: 'include',
        body: fetchBody
      }).then(checkStatus)
        .then(parseJSON).then(function(data){
          resolve(data);
      }).catch(function(error){
      	reject(error);
      });
      /**
       * checkStatus - private method that checks the response status
       *
       * @param  {Object} response description
       * @return {Object}          description
       */
      function checkStatus(response) {
        if (response.status >= 200 && response.status < 300) {
          return response
        } else {
          var error = new Error(response.statusText)
          error.response = response
          throw error
        }
      }
      /**
       * parseJSON - parses JSON
       *
       * @param  {Object} response
       * @return {Object} JSON
       */
      function parseJSON(response) {
        return response.json()
      }
    });

}

export default restFetcher;
