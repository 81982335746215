import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Actions
import RecordActions from '../../actions/record-actions';
import FieldActions from '../../actions/field-actions';
import PageActions from '../../actions/page-actions';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import RecordStore from '../../stores/record-store';
import FieldStore from '../../stores/field-store';
import PageStore from '../../stores/page-store';
import RenderStore from '../../stores/render-store';

// Utils
import ObjectUtils from '../../utils/object-utils';

// Helper components
import VisibilitySettingsConfiguration from './visibility-settings-configuration.react'
import VisibilityAutomationSetting from './visibility-logic-setting.react';

class VisibilitySetting extends Component {
	/**
	 * Creates instance of VisibilitySetting
	 *
	 * @memberOf VisibilitySetting
	 */
	constructor(props) {
		super(props);
		this._getVisibilityRules = this._getVisibilityRules.bind(this);
		this._onChange = this._onChange.bind(this);
		this._onGlobalChange = this._onGlobalChange.bind(this);
		this._onLocalValueRowChange = this._onLocalValueRowChange.bind(this);
		this._renderLocalValues = this._renderLocalValues.bind(this);
		this._updateLocalSettingRow = this._updateLocalSettingRow.bind(this);
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, RecordStore, FieldStore, PageStore];
	}

	/**
	 * @static
	 * @param {any} prevState 
	 * @param {any} props 
	 * @returns {Object}
	 * @memberof VisibilitySetting
	 */
	static calculateState(prevState, props) {

		let componentId = AdminSettingsStore.getRecordId();
		let componentType = AdminSettingsStore.getTableSchemaName();
		let parentComponentId = AdminSettingsStore.getParentRecordId();
		let parentComponentType = AdminSettingsStore.getParentTableSchemaName();
		let parentRecordId = AdminSettingsStore.getParentRecordId();
		let parentTableSchemaName = AdminSettingsStore.getParentTableSchemaName();
		
		let { subSettingIndex, subSettingSchemaName } = props;

		if(componentType === 'page') {
			return {
				'isPage': true
			};
		}

		//Calculate the local values
		let localVisibilityRules = {};

		let componentObj = FieldStore.get(componentId);
		let settingConfig = FieldStore.getSettings(componentId);
		let visibilityRules = {};
		if (subSettingSchemaName && settingConfig[subSettingSchemaName]) {
			let subSetting = ObjectUtils.getObjFromJSON(settingConfig[subSettingSchemaName])[subSettingIndex];
			visibilityRules = subSetting && subSetting && subSetting.visibility ? ObjectUtils.getObjFromJSON(subSetting.visibility) : {};
		} else {
			visibilityRules = settingConfig.visibility ? ObjectUtils.getObjFromJSON(settingConfig.visibility) : {};
		}

		// @todo Update this to be empty if there is a subSetting and index
		let childConfiguration = parentComponentType === 'page' ?
			PageStore.getChildConfigurations(parentComponentId, componentId, 'visibility') :
			FieldStore.getChildConfigurations(parentComponentId, componentId, 'visibility');
		if (childConfiguration) {
			localVisibilityRules = childConfiguration;
		}

		let securityGroupName = '';
		let securityGroupDescription = '';
		let securityGroupReadOnly = false;
		let visibilityControlsReadOnly = false;
		let permissions = {};
		let permissionsFound = false;
		let localPermissions = [];

		// Which Security Group are we in?
		let securityGroupRecordId = AdminSettingsStore.getSettingRecordId();

		if (visibilityRules[securityGroupRecordId]) {
			permissions = visibilityRules[securityGroupRecordId];
			permissionsFound = true;
		}

		if (localVisibilityRules[securityGroupRecordId]) {
			localPermissions = localVisibilityRules[securityGroupRecordId];
		}

		// Depending on which group we are... let's DO THIS!
		switch (securityGroupRecordId) {
			case 'authenticated': {
				securityGroupReadOnly = true;
				securityGroupName = 'Authenticated Users';
				securityGroupDescription = 'All users that have been authenticated to use the application.';

				if (!permissionsFound) {
					permissions.view = '1';
					permissions.edit = '1';
					permissions.add = '1';
				}
				break;
			}
			case 'nonauthenticated': {
				securityGroupReadOnly = true;
				securityGroupName = 'Non-Authenticated Users';
				securityGroupDescription = 'Any user who has not yet been authenticated to use the application, e.g. anyone from the Internet.';
				if (!permissionsFound) {
					permissions.view = '1';
					permissions.edit = '1';
					permissions.add = '1';
				}
				break;
			}
			default: {
				let securityGroupRecord = RecordStore.getRecord('securityGroup', securityGroupRecordId);

				if (securityGroupRecord) {
					securityGroupName = (securityGroupRecord.name ? securityGroupRecord.name.value : '');
					securityGroupDescription = (securityGroupRecord.description ?
						securityGroupRecord.description.value : '');
				}

				if (!permissionsFound) {
					permissions.view = '0';
					permissions.edit = '0';
					permissions.add = '0';
				}
			}
		}

		//Content Drop-Down, Content Tabs and Navigation Tabs
		let showCreateUpdate = [
			'bb5bedc3-44d1-4e4c-9c40-561a675173b1',
			'846b747e-25a0-40df-8115-af4a00a1cab5',
			'cd0ee38e-d63f-44d2-b02b-44376fcc7c2e'
		].indexOf(componentObj.fieldType) === -1 && !subSettingSchemaName;

		//Content Drop-Down, Content Tabs and Navigation Tabs
		let viewAllowQuery = [
			'bb5bedc3-44d1-4e4c-9c40-561a675173b1',
			'846b747e-25a0-40df-8115-af4a00a1cab5',
			'cd0ee38e-d63f-44d2-b02b-44376fcc7c2e'
		].indexOf(componentObj.fieldType) >= 0 && !!subSettingSchemaName;

		return {
			securityGroupRecordId,
			securityGroupName,
			securityGroupDescription,
			securityGroupReadOnly,
			visibilityControlsReadOnly,
			permissions,
			localPermissions,
			componentId,
			parentComponentId,
			parentComponentType,
			subSettingSchemaName,
			subSettingIndex,
			nextLocalIndex: localPermissions.length,
			showCreateUpdate,
			viewAllowQuery,
			parentTableSchemaName,
			parentRecordId
		}
	}

	/**
	 * Handles change event from a Security Group field
	 *
	 * @param {string} key Which attribute to change
	 * @param {Object} event What the value should be.
	 *
	 * @memberOf VisibilitySettings
	 */
	_onChange(key, event) {
		let securityGroupRecordId = AdminSettingsStore.getSettingRecordId();
		let newValue = event.target.value;

		let newRecordValues = {};
		newRecordValues[key] = newValue;

		// Load the new record into the record store.
		RecordActions.updateRecord('securityGroup', securityGroupRecordId, newRecordValues);
	}

	/**
	 * 
	 * @param {*} newPermissions 
	 */
	_onGlobalChange(value) {

		let { subSettingSchemaName, subSettingIndex } = this.props;

		// Find where this value goes...
		let securityGroupRecordId = AdminSettingsStore.getSettingRecordId();

		// Get current table permissions
		let visibilityPermissions = this._getVisibilityRules();

		// If this group has never had permissions for this table before...
		if (!visibilityPermissions[securityGroupRecordId]) {
			visibilityPermissions[securityGroupRecordId] = {};
			// And the group is authenticated
			if (securityGroupRecordId === 'authenticated' || securityGroupRecordId === 'nonauthenticated') {
				// Set all to ON
				visibilityPermissions[securityGroupRecordId].view = '1';
				visibilityPermissions[securityGroupRecordId].viewProcessing = 'always';
				visibilityPermissions[securityGroupRecordId].viewQuery = null;
				visibilityPermissions[securityGroupRecordId].edit = '1';
				visibilityPermissions[securityGroupRecordId].editProcessing = 'always';
				visibilityPermissions[securityGroupRecordId].editQuery = null;
				visibilityPermissions[securityGroupRecordId].add = '1';
				visibilityPermissions[securityGroupRecordId].addProcessing = 'always';
				visibilityPermissions[securityGroupRecordId].addQuery = null;
			} else {
				// Set all to OFF
				visibilityPermissions[securityGroupRecordId].view = '0';
				visibilityPermissions[securityGroupRecordId].viewProcessing = 'never';
				visibilityPermissions[securityGroupRecordId].viewQuery = null;
				visibilityPermissions[securityGroupRecordId].edit = '0';
				visibilityPermissions[securityGroupRecordId].editProcessing = 'never';
				visibilityPermissions[securityGroupRecordId].editQuery = null;
				visibilityPermissions[securityGroupRecordId].add = '0';
				visibilityPermissions[securityGroupRecordId].addProcessing = 'never';
				visibilityPermissions[securityGroupRecordId].addQuery = null;
			}
		}

		// Set specific permissions that we're changing.
		if (typeof value === 'string') {
			let key = value;
			let newVal = 
				(visibilityPermissions[securityGroupRecordId][key] && visibilityPermissions[securityGroupRecordId][key] === '1')
				? '0'
				: '1';
			if(key === 'view') {
				visibilityPermissions[securityGroupRecordId]['view'] = newVal;
			} else {
				visibilityPermissions[securityGroupRecordId]['add'] = newVal;
				visibilityPermissions[securityGroupRecordId]['edit'] = newVal;
			}
		} else {
			Object.assign(visibilityPermissions[securityGroupRecordId], value);
			if (typeof visibilityPermissions[securityGroupRecordId].edit === 'string') {
				visibilityPermissions[securityGroupRecordId].add = visibilityPermissions[securityGroupRecordId].edit;
				if (visibilityPermissions[securityGroupRecordId].editProcessing) {
					visibilityPermissions[securityGroupRecordId].addProcessing = visibilityPermissions[securityGroupRecordId].editProcessing
				} else {
					delete visibilityPermissions[securityGroupRecordId].addProcessing;
				}
				if (visibilityPermissions[securityGroupRecordId].editQuery) {
					visibilityPermissions[securityGroupRecordId].addQuery = visibilityPermissions[securityGroupRecordId].editQuery;
				} else {
					delete visibilityPermissions[securityGroupRecordId].addQuery;
				}
			}
		}

		// See if we should just delete the authenticated key?
		if (securityGroupRecordId === 'authenticated' || securityGroupRecordId === 'nonauthenticated') {
			if (visibilityPermissions[securityGroupRecordId].view === '1' &&
				(!visibilityPermissions[securityGroupRecordId].viewProcessing || 
					visibilityPermissions[securityGroupRecordId].viewProcessing === 'always') &&
				visibilityPermissions[securityGroupRecordId].edit === '1' &&
				(!visibilityPermissions[securityGroupRecordId].editProcessing || 
					visibilityPermissions[securityGroupRecordId].editProcessing === 'always') &&
				visibilityPermissions[securityGroupRecordId].add === '1' &&
				(!visibilityPermissions[securityGroupRecordId].addProcessing || 
					visibilityPermissions[securityGroupRecordId].addProcessing === 'always')) {
				delete visibilityPermissions[securityGroupRecordId];
			}
		} else {
			if (visibilityPermissions[securityGroupRecordId].view === '0' &&
				(!visibilityPermissions[securityGroupRecordId].viewProcessing || 
					visibilityPermissions[securityGroupRecordId].viewProcessing === 'never') &&
				visibilityPermissions[securityGroupRecordId].edit === '0' &&
				(!visibilityPermissions[securityGroupRecordId].editProcessing || 
					visibilityPermissions[securityGroupRecordId].editProcessing === 'never') &&
				visibilityPermissions[securityGroupRecordId].add === '0' &&
				(!visibilityPermissions[securityGroupRecordId].addProcessing || 
					visibilityPermissions[securityGroupRecordId].addProcessing === 'never')) {
				delete visibilityPermissions[securityGroupRecordId];
			}
		}

		// If we have security permissions, then stringify them - otherwise delete them.
		if(subSettingSchemaName && subSettingSchemaName !== 'listColumns') {
			let settingConfig = FieldStore.getSettings(this.state.componentId);
			let subSettingConfig = settingConfig[subSettingSchemaName] ? ObjectUtils.getObjFromJSON(settingConfig[subSettingSchemaName]) : {};
			subSettingConfig[subSettingIndex] = subSettingConfig[subSettingIndex] ? subSettingConfig[subSettingIndex] : {};
			subSettingConfig[subSettingIndex].visibility = JSON.stringify(visibilityPermissions);
			FieldActions.pushSettingToStore(this.state.componentId, subSettingSchemaName, JSON.stringify(subSettingConfig));
		} else {
			let newVisibilityPermissions = JSON.stringify(visibilityPermissions);
			FieldActions.pushSettingToStore(this.state.componentId, 'visibility', newVisibilityPermissions);
		}
	}

	/**
	 * Update existing local value values
	 * @param {any} selectedSetting 
	 * @param {any} index 
	 * @param {any} currentObj 
	 * @memberof VisibilitySetting
	 */
	_onLocalValueRowChange(index, newVal) {
		let newRulesObj = ObjectUtils.copyObj(this.state.localPermissions[index]);		
		if (typeof newVal === 'string') {
			let key = newVal;
			let value = newRulesObj && newRulesObj.value ? newRulesObj.value : {};
			if(key === 'view') {
				value[key] = value[key] === '1' ? '0' : '1';
			} else {
				let newVal = (value['edit'] && value['edit'] === '1' ? '0' : '1');
				value['add'] = newVal;
				value['edit'] = newVal;
			}
			newRulesObj.value = value;
		} else {
			Object.assign(newRulesObj.value ? newRulesObj.value : {}, newVal);
			if (typeof newRulesObj.value.edit === 'string') {
				newRulesObj.value.add = newRulesObj.value.edit;
				newRulesObj.value.addProcessing = newRulesObj.value.editProcessing;
				newRulesObj.value.addQuery = newRulesObj.value.editQuery;
			}
		}
		newRulesObj.action = 'update';
		newRulesObj.index = index;
		this._updateLocalSettingRow({
			'target': {
				'value': newRulesObj
			}
		});
	}

	_getVisibilityRules() {
		let { componentId } = this.state;
		let { subSettingIndex, subSettingSchemaName } = this.props;
		let settingConfig = FieldStore.getSettings(componentId);
		let visibilityRules = {};
		if (subSettingSchemaName && settingConfig[subSettingSchemaName]) {
			let subSetting = ObjectUtils.getObjFromJSON(settingConfig[subSettingSchemaName]);
			visibilityRules = subSetting && subSetting[subSettingIndex] && subSetting[subSettingIndex].visibility ? ObjectUtils.getObjFromJSON(subSetting[subSettingIndex].visibility) : {};
		} else {
			visibilityRules = settingConfig.visibility ? ObjectUtils.getObjFromJSON(settingConfig.visibility) : {};
		}
		return visibilityRules;
	}

	/**
	 * Delete the entire row
	 * 
	 * @param {any} index 
	 * @param {any} event 
	 * @memberof VisibilitySetting
	 */
	_deleteLocalSetting(index, event) {
		event.preventDefault();
		let value = {
			action: 'delete',
			index: index
		};
		this._updateLocalSettingRow({
			'target': {
				'value': value
			}
		});
	}

	/**
	 * Update screen sizes for local settings that have been already added
	 * @param {any} index 
	 * @param {string} screensize 
	 * @param {any} event 
	 * @memberof VisibilitySetting
	 */
	updateExistingScreenSizes(index, screensize) {
		
		// Clear the other local index's that have this screensize.
		let { localPermissions } = this.state;

		localPermissions.forEach((localPermission, localPermissionIndex) => {
			// Only handle the other ones
			if(localPermissionIndex !== index) {
				if(localPermission.screenSizes.includes(screensize)) {
					localPermission.screenSizes.splice(
						localPermission.screenSizes.indexOf(screensize), 1);
					
					// Set the operation parameters
					localPermission.action = 'update';
					localPermission.index = localPermissionIndex;

					this._updateLocalSettingRow({ 'target': { 'value': localPermission } });		
				}
			}
		})

		// Now handle our current index
		let newRulesObj = localPermissions[index];

		// Adjust this index's use of this screensize.
		if (!newRulesObj.screenSizes.includes(screensize)) {
			newRulesObj.screenSizes.push(screensize);
		} else {
			newRulesObj.screenSizes.splice(
				newRulesObj.screenSizes.indexOf(screensize), 1);
		}
		newRulesObj.action = 'update';
		newRulesObj.index = index;

		// Do the update.
		this._updateLocalSettingRow({
			'target': {
				'value': newRulesObj
			}
		});
	}

	_renderLocalValues() {
		let { disabledRetailRestrictions, onUpgradeClick } = this.props;
		let { securityGroupName } = this.state;
		let {
			localPermissions, 
			showCreateUpdate, 
			viewAllowQuery
		} = this.state;

		let rows = [];
		rows = localPermissions.map((visibilityRules, index) => {
			let value = visibilityRules.value ?
				visibilityRules.value : {
					view: '1',
					edit: '1',
					add: '1'
				};
			let {view, edit, add} = value;
			
			return (
				<div className="setting-local" key={index}>
					<div className="setting-controls">
						<div className="override-add">
							<button 
								className={"d-flex align-items-center btn btn-link " + (!!disabledRetailRestrictions ? 'cd-btn-disabled' : '')} 
								onClick={(disabledRetailRestrictions ? onUpgradeClick : this._deleteLocalSetting.bind(this, index))} >
								<i className="fa fa-minus-circle pr-2" aria-hidden="false"></i>
								<h4>Remove Override</h4>
							</button>
						</div>
					</div>
					<div className="setting-wrapper">
						<div className="localOverrideScreenSizeSelector">
							<div
								onClick={(disabledRetailRestrictions 
									? onUpgradeClick
									: this.updateExistingScreenSizes.bind(this, index, 'sm'))} 
								onKeyPress={(disabledRetailRestrictions 
									? onUpgradeClick
									: this.updateExistingScreenSizes.bind(this, index, 'sm'))}
								className={"screensizeOption rounded-left " + 
									(!!(visibilityRules.screenSizes.includes('sm')) ? 'selected ' : '') + 
									(!!disabledRetailRestrictions ? 'disabled' : '')
								}>
								<div className={"screensizeOptionIcon fa fa-mobile"}></div>
							</div>
							<div
								onClick={(disabledRetailRestrictions 
									? onUpgradeClick
									: this.updateExistingScreenSizes.bind(this, index, 'md'))} 
								onKeyPress={(disabledRetailRestrictions 
									? onUpgradeClick
									: this.updateExistingScreenSizes.bind(this, index, 'md'))}
								className={"screensizeOption " + 
									(!!(visibilityRules.screenSizes.includes('md')) ? 'selected ' : '') + 
									(!!disabledRetailRestrictions ? 'disabled' : '')
								}>
								<div className={"screensizeOptionIcon fa fa-tablet"}></div>
							</div>
							<div
								onClick={(disabledRetailRestrictions 
									? onUpgradeClick
									: this.updateExistingScreenSizes.bind(this, index, 'lg'))} 
								onKeyPress={(disabledRetailRestrictions 
									? onUpgradeClick
									: this.updateExistingScreenSizes.bind(this, index, 'lg'))}
								className={"screensizeOption rounded-right " + 
									(!!(visibilityRules.screenSizes.includes('lg')) ? 'selected ' : '') + 
									(!!disabledRetailRestrictions ? 'disabled' : '')
								}>
								<div className={"screensizeOptionIcon fa fa-desktop"}></div>
							</div>
							<div className="ml-2 muted theme-font-regular">Select device type(s) to apply override to.</div>
						</div>
						<VisibilitySettingsConfiguration
							view={view || '0'}
							edit={edit || '0'}
							add={add || '0'}
							onChange={this._onLocalValueRowChange.bind(this, index)}
							index={index}
							showCreateUpdate={showCreateUpdate}
							viewAllowQuery={viewAllowQuery}
							disabledRetailRestrictions={disabledRetailRestrictions}
							securityGroupName={securityGroupName}
						/>
					</div>
				</div>
			)
		});

		// Always push the default row at the end
		if(localPermissions.length < 3) {
			rows.push(<div className="setting-local add" key={'add'}>
				<div className="setting-controls">
					<div className="override-add">
						<button 
							className={ 
								"d-flex align-items-center btn btn-link " + 
								(!!disabledRetailRestrictions ? 'cd-btn-disabled' : '')
							} 
							onClick={(!!disabledRetailRestrictions 
								? onUpgradeClick 
								: this.addLocalSetting.bind(this))} 
						>
							<i className="fa fa-plus-circle pr-2" aria-hidden="false"></i>
							<h4>Override {securityGroupName} Visibility</h4>
						</button>
					</div>
				</div>
			</div>);
		}

		return rows;
	}

	/**
	 * Add the row under local settings
	 * @param {any} event 
	 * @memberof VisibilitySetting
	 */
	addLocalSetting(event) {
		event.preventDefault();
		this._updateLocalSettingRow({
			'target': {
				'value': {
					action: 'add',
					index: this.state.nextLocalIndex,
					value: {}, 
					screenSizes: []
				}
			}
		});
	}

	/**
	 * Update the Visibility Rule Local values to the store
	 * 
	 * @param {any} event 
	 * @memberof VisibilitySetting
	 */
	_updateLocalSettingRow(event) {
		let { componentId, parentComponentId, parentComponentType } = this.state;
		let securityGroupRecordId = AdminSettingsStore.getSettingRecordId();
		let value = event.target.value;

		let visibilityChildConfigurations = parentComponentType === 'page' ?
			PageStore.getChildConfigurations(parentComponentId, componentId, 'visibility') :
			FieldStore.getChildConfigurations(parentComponentId, componentId, 'visibility');
		visibilityChildConfigurations = visibilityChildConfigurations || {};
		let childConfiguration = visibilityChildConfigurations && visibilityChildConfigurations[securityGroupRecordId] ?
			visibilityChildConfigurations[securityGroupRecordId] :
			[];

		if (value.action === 'delete') {
			if (value.index > -1 && value.index < childConfiguration.length) {
				childConfiguration.splice(value.index, 1);
			}
		} else {
			let valueObj = value.value;
			let newValue = {
				value: valueObj,
				screenSizes: value.screenSizes
			};
			if (value.index < childConfiguration.length) {
				childConfiguration[value.index] = newValue;
			} else {
				childConfiguration.push(newValue);
			}
		}

		visibilityChildConfigurations[securityGroupRecordId] = childConfiguration;
		
		switch (parentComponentType) {
			case 'page': {

				PageActions.pushChildConfigurationToStore(parentComponentId, componentId, 'visibility', visibilityChildConfigurations);
				break;
			}

			case 'field': {

				FieldActions.pushChildConfigurationToStore(parentComponentId, componentId, 'visibility', visibilityChildConfigurations);

				break;
			}

			default: {
				console.warn('Unsupported Parent Component Type Name', parentComponentType);
			}
		}
	}

	/**
	 * @returns - DOM for selected security group
	 * @memberof VisibilitySetting
	 */
	render() {
		let {
			securityGroupName,
			securityGroupDescription,
			securityGroupRecordId,
			securityGroupReadOnly,
			visibilityControlsReadOnly,
			permissions,
			parentComponentId,
			subSettingSchemaName,
			showCreateUpdate,
			viewAllowQuery,
			isPage
		} = this.state;
		let {
			disabledRetailRestrictions,
			onUpgradeClick
		} = this.props;
		let hideLocal = subSettingSchemaName;

		let {renderId} = this.props;
		let renderObj = RenderStore.get(renderId) || {};
		let fieldId = renderObj.componentId;
		// We don't support pages
		let fieldObj = renderObj.componentType === 'field' ? FieldStore.get(fieldId) : undefined;
		let tableSchemaName = fieldObj ? fieldObj.tableSchemaName : '';

		if(isPage) {
			return (
				<div className="select-setting">
					<div className="select-setting-text-wrap">
						Select a setting to configure on the left.
					</div>
				</div>);
		}
		else if (!securityGroupRecordId) {
			return (
				<div className="select-setting">
					<div className="select-setting-text-wrap">
						Select a Security Group or "Add New" on the left.
					</div>
				</div>);
		} else if (securityGroupRecordId === 'logic') {
			return (
				<div id="visibility-setting">
					<div className="visibility-setting-container">
						<VisibilityAutomationSetting
							isContentTab={this.props.isContentTab}
							tableSchemaName={tableSchemaName}
							fieldId={fieldId}
							attachmentId={this.props.attachmentId}
							subSettingSchemaName={this.props.subSettingSchemaName}
							subSettingIndex={this.props.subSettingIndex}
							visibilityControlsReadOnly={visibilityControlsReadOnly}
							disabledRetailRestrictions={disabledRetailRestrictions}
							onUpgradeClick={onUpgradeClick}
						/>
					</div>
				</div>
			);
		} else {
			return (
				<div id="visibility-setting">
					<div className="visibility-setting-container mx-3 d-flex">
						<div className="setting-global">
							<div className="row">
								<div className="col-4">
									<h4 htmlFor="securityName" className="setting-label mb-2">Security Group Name:</h4>
								</div>
								<div className="col-8">
									<h4 htmlFor="securityCreate" className="setting-label mb-2">Description:</h4>
								</div>
							</div>
							<div className="row">
								<div className="col-4">
									{
										securityGroupReadOnly
											? <div className="hard-group-details">{securityGroupName}</div>
											: <input className="form-control" type="text"
												id="securityName" placeholder="Security Group"
												onChange={this._onChange.bind(this, 'name')}
												disabled={disabledRetailRestrictions}
												value={securityGroupName || ''} />
									}
								</div>
								<div className="col-8">
									{
										securityGroupReadOnly
											? <div className="hard-group-details">{securityGroupDescription}</div>
											: <input className="form-control" type="text"
												id="securityDescription" placeholder="Security Group Description"
												onChange={this._onChange.bind(this, 'description')}
												disabled={disabledRetailRestrictions}
												value={securityGroupDescription || ''} />
									}
								</div>
							</div>
						</div>
						<h3 className="my-1">Default Setting</h3>
						<div className="setting-global mt-0">
							<div className="setting-wrapper">
									<VisibilitySettingsConfiguration 
										view={permissions.view} 
										viewProcessing={permissions.viewProcessing}
										viewQuery={permissions.viewQuery}
										edit={permissions.edit} 
										editProcessing={permissions.editProcessing} 
										editQuery={permissions.editQuery} 
										add={permissions.add} 
										addProcessing={permissions.addProcessing} 
										addQuery={permissions.addQuery} 
										onChange={this._onGlobalChange}
										showCreateUpdate={showCreateUpdate} 
										viewAllowQuery={viewAllowQuery}
										securityGroupName={securityGroupName}
										visibilityControlsReadOnly={visibilityControlsReadOnly}
										disabledRetailRestrictions={disabledRetailRestrictions}
										onUpgradeClick={onUpgradeClick}
									/>
							</div>
						</div>
						<div style={{ overflowY: 'auto' }}>
							{( !hideLocal ? <h3 className="mb-2">Parent Overrides:</h3> : '' )}
							{parentComponentId && !hideLocal ?
								this._renderLocalValues()
								: null
							}
						</div>
					</div>
				</div>);
		}
	}
}

const container = Container.create(VisibilitySetting, { withProps: true });
export default container;