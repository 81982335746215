import AppDispatcher from '../dispatcher/app-dispatcher';
import DiscussionConstants from '../constants/discussion-constants';
import Immutable from 'immutable';

let _instance = null;

class DiscussionActions {
	/**
	 * Singleton instance of DiscussionActions
	 * 
	 * @param {Object} options 
	 */
	constructor(options) {
		/**
		 * Singleton instance of DiscussionActions
		 */
		if(_instance) {
			return _instance;
		}

		//function binding
		this.configure = this.configure.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);
		this.pullFromDatabaseAll = this.pullFromDatabaseAll.bind(this);
		this.pushToDatabasePromise = this.pushToDatabasePromise.bind(this);
		this.pushToStore = this.pushToStore.bind(this);
		this.deleteFromStore = this.deleteFromStore.bind(this);

		// instance and dispatcher
		_instance = this;

		// path information
		if(options && options.mdgwMode) {
			let basePath = options.mdgwMode === 'stage' ? 'test-platform' : 'platform';
			this._basePath = 'https://' + basePath + '.citizendeveloper.com';
		}
	}

	/**
	 * Set the MDGW Mode and set up the basePath.
	 * 
	 * @param {string} installationId 
	 */
	configure(mdgwMode, appId) {
		let basePath = mdgwMode === 'stage' ? 'test-platform' : 'platform';
		this._basePath = 'https://' + basePath + '.citizendeveloper.com';
		this._appId = appId;
	}

    /**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._basePath) {
			throw(new Error('DiscussionActions not properly initialized with a MDGW mode. Please reference DiscussionActions.configure for more information.'));
		}
		if (!this._appId) {
			throw(new Error('DiscussionActions not properly initialized with an application ID. Please reference DiscussionActions.configure for more information.'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: DiscussionConstants.DISCUSSION_PULL_ERROR,
			error: error.message
		}));
	}

	_checkStatus(res) {
		if (res.status >= 200 && res.status < 300) {
			return res;
		  } else {
			let err = new Error(res.statusText);
			err.response = res;
			throw err;
		  }
	}

	pushToDatabasePromise(discussionObject) {
		return new Promise((resolve, reject) => {
			try {
				this._checkInit();
			} catch (error) {
				return reject(error);
			}

			// make sure we have a standard JS object
			if(discussionObject && discussionObject.toJS) {
				discussionObject = discussionObject.toJS();
			}

			let temporaryId = '';
			if(discussionObject.temporary) {
				// Because we can't provide record IDs ourselves but need discussion IDs, discussions within the store only may have temporary IDs
				// If this happens, we need to be able to differentiate between the two and clean up temporary discussions if necessary
				temporaryId = discussionObject.discussionId;
				delete discussionObject.discussionId;
			}

			// We don't need comments hanging around
			delete discussionObject.comments;

			// We do want to save any WIP comments to the DB
			let wipComment = discussionObject.wipComment;
			delete discussionObject.wipComment;

			discussionObject.applicationId = window.ContextStore.getApplicationId();

			fetch(this._basePath + '/discussions' + (discussionObject.discussionId ? '/' + discussionObject.discussionId : ''), {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json; charset=UTF-8',
					// @TODO: Add in the application auth key
				},
				body: JSON.stringify({
					discussionObject: JSON.stringify(discussionObject)
				})
			})
			.then(this._checkStatus)
			.then((response) => {
				return response.json();
			})
			.then(discussionObject => {
				if(wipComment && wipComment.comment) {
					return fetch(this._basePath + '/discussionComments', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json; charset=UTF-8',
							// @TODO: Add in the application auth key
						},
						body: JSON.stringify({
							appId: this._appId,
							discussionId: discussionObject.discussionId,
							discussionCommentObject: JSON.stringify(wipComment)
						})
					})
					.then(this._checkStatus)
					.then((response) => {
						return response.json();
					})
					.then((commentObject) => {
						wipComment = commentObject;
						discussionObject.commentCount = +discussionObject.commentCount + 1;
						return discussionObject;
					});
				} else {
					return discussionObject;
				}
			})
			.then(discussionObject => {
				if(temporaryId) {
					discussionObject.temporaryId = temporaryId;
				}
				let dispatchObj = {
					type: DiscussionConstants.DISCUSSION_PUSH_TO_DATABASE,
					discussionId: discussionObject.discussionId,
					discussionProperties: discussionObject
				};
				if(wipComment) {
					dispatchObj.newComment = wipComment;
				}
				AppDispatcher.dispatch(Immutable.fromJS(dispatchObj));
				return resolve(discussionObject);
			})
			.catch((error) => { 
				console.error(error); 
				this._onError(error) ;
			});
		});
	}

	pushToStore(discussionId, discussionProperties) {
		this._checkInit();
		discussionProperties.isDirty = true;
		discussionProperties.discussionId = discussionId;
		AppDispatcher.dispatch(Immutable.fromJS({
			type: DiscussionConstants.DISCUSSION_PUSH_TO_STORE,
			discussionId: discussionId,
			discussionProperties: discussionProperties
		}));
	}

	deleteFromDatabase(discussionId) {
		// @TODO: Implement. Or not? We may not need this.
	}

	deleteFromDatabasePromise(discussionId) {
		// @TODO: Implement. Or not? We may not need this.
	}

	deleteFromStore(discussionId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: DiscussionConstants.DISCUSSION_DELETE_FROM_STORE,
			discussionId: discussionId
		}));
	}

	pullFromDatabase(discussionId, overwriteStore) {
		
		if(!discussionId) {
			return;
		}

		this._checkInit();

		fetch(this._basePath + '/discussions?appId=' + this._appId + '&discussionId=' + discussionId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				// @TODO: Add in the application auth key
			}
		})
		.then(this._checkStatus)
		.then((response) => {
			return response.json();
		})
		.then((discussions) => {
			AppDispatcher.dispatch(Immutable.fromJS({
				type: DiscussionConstants.DISCUSSION_PULL_FROM_DATABASE,
				discussion: discussions[0],
				discussionId: discussionId,
				overwriteStore: overwriteStore
			}));
		})
		.catch((error) => { 
			console.error(error); 
			this._onError(error) ;
		});
	}

	pullFromDatabaseAll(overwriteStore) {
		this._checkInit();

		fetch(this._basePath + '/discussions?appId=' + this._appId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				// @TODO: Add in the application auth key
			}
		})
		.then(this._checkStatus)
		.then((response) => {
			return response.json();
		})
		.then((discussions) => {
			AppDispatcher.dispatch(Immutable.fromJS({
				type: DiscussionConstants.DISCUSSION_PULL_FROM_DATABASE_ALL,
				discussions: discussions,
				overwriteStore: overwriteStore
			}));
		})
		.catch((error) => { 
			console.error(error); 
			this._onError(error) ;
		});
	}

	pullCommentsFromDatabase(discussionId, overwriteStore) {
		if(!discussionId) {
			return;
		}

		this._checkInit();

		fetch(this._basePath + '/discussionComments?appId=' + this._appId + '&discussionId=' + discussionId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				// @TODO: Add in the application auth key
			}
		})
		.then(this._checkStatus)
		.then((response) => {
			return response.json();
		})
		.then((discussionComments) => {
			AppDispatcher.dispatch(Immutable.fromJS({
				type: DiscussionConstants.DISCUSSION_COMMENT_PULL_FROM_DATABASE_ALL,
				discussionComments: discussionComments,
				discussionId: discussionId,
				overwriteStore: overwriteStore
			}));
		})
		.catch((error) => { 
			console.error(error); 
			this._onError(error) ;
		});
	}

	pushCommentToStore(discussionId, discussionCommentProperties) {
		this._checkInit();
		AppDispatcher.dispatch(Immutable.fromJS({
			type: DiscussionConstants.DISCUSSION_COMMENT_PUSH_TO_STORE,
			discussionId: discussionId,
			discussionCommentProperties: discussionCommentProperties
		}));
	}

	pushCommentToDatabase(discussionId, discussionCommentObj) {
		return new Promise((resolve, reject) => {
			try {
				this._checkInit();
			} catch (error) {
				return reject(error);
			}

			// make sure we have a standard JS object
			if(discussionCommentObj && discussionCommentObj.toJS) {
				discussionCommentObj = discussionCommentObj.toJS();
			}

			fetch(this._basePath + '/discussionComments', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json; charset=UTF-8',
					// @TODO: Add in the application auth key
				},
				body: JSON.stringify({
					appId: this._appId,
					discussionId: discussionId,
					discussionCommentObject: JSON.stringify(discussionCommentObj)
				})
			})
			.then(this._checkStatus)
			.then((response) => {
				return response.json();
			})
			.then(discussionCommentProperties => {
				AppDispatcher.dispatch(Immutable.fromJS({
					type: DiscussionConstants.DISCUSSION_COMMENT_PUSH_TO_DATABASE,
					discussionId: discussionId,
					recordId: discussionCommentProperties.recordId,
					discussionCommentProperties: discussionCommentProperties
				}));
				return resolve(discussionCommentProperties);
			})
			.catch((error) => { 
				console.error(error); 
				this._onError(error) ;
			});
		});
	}

};

const instance = new DiscussionActions();
export default instance;