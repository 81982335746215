import React from 'react';
import _ from 'lodash';
import BaseSettingForm from './base-setting-form.react';
/**
 * Component for Name
 * 
 * @class Name
 * @extends {Component}
 */
export default class Name extends BaseSettingForm {
	/**
	 * Render the Name
	 * 
	 * @returns React
	 * 
	 * @memberof Name
	 */
	render() {
		let {onChange, fieldSchemaName, value} = this.props;

		return(
			<input id={fieldSchemaName} className="form-control" type="text" value={value} onChange={onChange} />
		);
	}
}
