import React from 'react';
import PropTypes from 'prop-types';

/**
 * Dummy component that wraps input for bootstrap html structure
 */
const FormLabelWrapper = (props) => {
  let {children, field, label} = props;

  return (
    <div className="mb-2" key={field}>
		<div className="form-group d-flex align-items-center">
			<label className="mr-2" htmlFor={field}>{label}</label>
			<div style={{ flex: 1 }}>
				{children}
			</div>
		</div>
    </div>
  );
};

if ('development' === process.env.NODE_ENV) {
  FormLabelWrapper.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any.isRequired,
    inputClassName: PropTypes.string,
    field: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }
}

export default FormLabelWrapper;
