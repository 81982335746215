export const PageRolesConstants = {
	ADD: 'add',
	// ADMIN: 'admin',
	// DASHBOARD: 'dashboard',
	SEARCH: 'search',
	VIEW_AND_EDIT: 'viewandedit',
};

export const PageRolesLabels = {
	'add': 'Add',
	// 'admin': 'Admin',
	// 'dashboard': 'Dashboard',
	'search': 'Search',
	'viewandedit': 'View/Edit'
}

export default PageRolesConstants;
