/*global ss:true*/
import socket from './socket';
import ContextStore from '../stores/context-store';
import uuid from 'uuid';
let ProcessingActions = require('../actions/processing-actions').default;
let InterfaceActions = require('../actions/interface-actions').default;
let { EventEmitter } = require('fbemitter');

let remoteFileEmitter = new EventEmitter();
//Receive the GCS URL for the file:
socket.on('RemoteFileUploadURL', function(url, responseId) {
	remoteFileEmitter.emit(responseId, url);
	ProcessingActions.removePercentage();
});

socket.on('RemoteFileUploadFailed', function(error, responseId) {
	ProcessingActions.removePercentage();
	console.error('error uploading file', error);
	if(error.status === 401) {
		InterfaceActions.notification({level: 'error', message: 'Error uploading file: 401 Unauthorized'});
	} else {
		InterfaceActions.notification({level: 'error', message: 'Error uploading file: ' + error.status + ' ' + error.message});
	}
	
});

socket.on('RemoteFileUploadProgress', function(percentage) {
	ProcessingActions.addPercentage(percentage);
});

class RemoteFileStorage {

	/**
	 * Guesses the mime type for a file based on its file name
	 * @param {string} fileName Name of the file, ex. test.csv
	 * @return {string} Mimetype that it guessed.
	 */
	static mimeLookup(fileName) {
		var mime = require('mime');
		let toReturn = mime.getType(fileName);
		return toReturn;
	}

	 /**
		 * Streams files to the backend and updates the FAField's value
		 * @param {string} recordId What record to store your file on.
		 * @param {string} tableSchemaName What table to store your file on.
		 * @param {string} fieldId Field ID of the field to store your file in.
		 * @param {string} fileMode public | private - how to store the file.
		 * @param {string} filename What do you want the file named?
		 * @param {string} contentType File's content type.  Ex: text/plain (see https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types)
		 * @param {string} base64EncodedFileContents File's contents -> Base 64 encoded
		 * @return A Promise that resolves when the Upload is complete.
		 */
	static uploadFile(recordId, tableSchemaName, fieldId, fileMode, fileName, contentType, base64EncodedFileContents){
		//Creates an instance of socket.io-stream
		let stream = ss.createStream();
		
		//Transform data into a Blob
		return new Promise ((resolve, reject) => {
			if(!contentType) {
				contentType = this.mimeLookup(fileName);
			}

			//Transform the encoded data into a blob
			RemoteFileStorage.b64toBlob(base64EncodedFileContents, contentType, 512).then(data => {

				// Calculate the size.. (remove the padding (/=/g,""))
				let fileSize = base64EncodedFileContents.replace(/=/g,"").length * 0.75;
				if(fileSize > 6000000) {
					// If file size is bigger than 6MB
					let requestId = uuid.v4();
					let fileAsBlob = data;
					let options = {
							name: fileName,
							size: fileSize,
							fileMode: fileMode,
							recordId: recordId,
							fieldId: fieldId,
							installationId: ContextStore.getInstallationId(),
							contentType: contentType,
							requestId: requestId
						}
	
					// Emit file Upload
					ss(socket).emit('RemoteFileUpload', stream, options);
					
					//Stream the blob to the backend
					ss.createBlobReadStream(fileAsBlob).pipe(stream);
			
					remoteFileEmitter.once(requestId, function(url) {
						resolve({
							gcsURL: url,
							fileSize: fileSize
						});
					});
				} else {
					// Upload smaller files directly against our upload endpoint
					let formData = new FormData();
					formData.append("file", data, fileName);
					formData.append("recordId", recordId);
					formData.append("fieldId", fieldId);
					formData.append("contentType", contentType);
					formData.append("fileMode", fileMode);
					formData.append('installationId', ContextStore.getInstallationId());
					const options = {
						method: 'POST',
						body: formData,
						credentials: 'include'
					};

					return fetch(window.location.protocol + '//' + window.location.hostname + '/gw-upload', options).then(response => {
						if(response.status === 200) {
							response.json()
								.then(results => {
									return resolve({
										gcsURL: results.data && results.data.url ? results.data.url : '',
										fileSize: fileSize
									});
								})
								.catch(reject);
						} else {
							return reject(new Error(response.status + ' Error received in gw-upload: ' + response.statusText));
						}
					});
				}
			}).catch(error => {
				console.error('error: ', error);
				reject(error);
			}); 
		});
	}

	/**
	 * Turns a base 64 data into a Blob
	 * Original Function: https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript  
	 * 
	 * @static
	 * @param {any} b64Data 
	 * @param {any} contentType 
	 * @param {number} [sliceSize=512] 
	 * @returns 
	 * @memberof GCSStorage
	 */
	static b64toBlob(b64Data, contentType, sliceSize){
		return new Promise((resolve, reject) => {

			if(!b64Data || !contentType){
				reject();
			}

			//Decode the Data 
			const byteCharacters = atob(b64Data);
			const byteArrays = [];
			

			// Each character's code point (charCode) will be the value of the byte.
			for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			  const slice = byteCharacters.slice(offset, offset + sliceSize);
			  
			  const byteNumbers = new Array(slice.length);
			  for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			  }
			  
			// You can convert this array of byte values into a real typed byte array by passing it to the Uint8Array constructor.
			  const byteArray = new Uint8Array(byteNumbers);
			  
			  byteArrays.push(byteArray);
			}
			
			//Convert to Blob
			const blob = new Blob(byteArrays, {type: contentType});
			
			resolve(blob);
		});
	}

	static sanitizeFilename(filename) {

		if(!filename) {
			throw new Error('Missing filename.');
		}
		if(typeof filename !== 'string') {
			throw new Error('Filename is not a string.');
		}
		// for reference, valid characters are: `0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz-._~!()*`
		// Replace any character which isn't a valid character with an underscore
		filename = filename.replace(/[^0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz\-._~!()]/g, '_');

		// Some names are forbidden by one or more of our file hosts. Change them if so.
		[
			'LPT5',
			'LPT6',
			'LPT7',
			'LPT8',
			'LPT9',
			'COM1',
			'COM2',
			'COM3',
			'COM4',
			'COM5',
			'COM6',
			'COM7',
			'COM8',
			'COM9',
			'PRN',
			'AUX',
			'NUL',
			'CON',
			'CLOCK$',
			'.',
			'..'
		].forEach(disallowed => {
			if(filename === disallowed) {
				filename = '_' + filename;
			}
		});
		if(filename.startsWith('.well-known/acme-challenge')) {
			filename = '_' + filename;
		}

		return filename;
	}
}
export default RemoteFileStorage;
