import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Constants
import Help from '../../constants/help-upgrade-constants';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import ContextStore from '../../stores/context-store';

// Utils
import UIUtils from '../../utils/ui-utils';

/**
 * Displays dashboard header
 */
class LogicDashboardHeader extends Component {
	/**
	 * Return the stores we're listening to for changes.
	 *
	 * @return {array}  description
	 */
	static getStores() {
		return [ AdminSettingsStore ];
	}

	/**
	 * Calculate our state anytime a store changes.
	 *
	 * @param  {object} prevState
	 * @return {object}
	 */
	static calculateState(prevState) {
		return {
			'isActive' : (AdminSettingsStore.getActiveDashboard() === 'logic' ? true : false),
			'selectedOverlay': AdminSettingsStore.getSelectedOverlay()
		};
	}

	/**
	 * render the component
	 *
	 * @return {JSX}
	 */
	render() {
		let { isActive } = this.state;

		let { 
			onOverlayEnable, 
			onDashboardSelect,
			isLogicOn,
			panelDashboard
		} = this.props;

		return (
			<div onClick={() => { onDashboardSelect('logic') }} className={`cd-dashboard-header ${panelDashboard === 'logic' ? 'logic' : ''}`}>
				<div className="d-flex justify-content-between">
					<div className="d-flex align-items-center">
						<div className="d-flex cd-dashboard-title">
							<h4 className="cd-dashboard-sign">
								{isActive 
									? <i title="Collapse" className="fa fa-minus" aria-hidden="true"></i> 
									: <i title="Expand" className="fa fa-plus" aria-hidden="true"></i>
								}
							</h4>
							<h4 className="ml-1">Logic</h4>
						</div>
						<div title="Logic Help" className="cd-dashboard-info" 
							onClick={event => {
								event.stopPropagation();
								UIUtils.onHelpClick(Help.HELP_DASHBOARD_LOGIC);
								}
							}>
							<i className="fa fa-info-circle" aria-hidden="true"></i>
						</div>
					</div>
					<div className="d-flex cd-dashboard-pin-container">
						<div title="Logic" className={`cd-dashboard-pin ${isLogicOn ? 'logic' : ''}`} 
							onClick={event => {
								event.stopPropagation();
								onOverlayEnable('automation')
								}
							}>
							<img 
								src={ContextStore.getUrlMedia() + "/icon-logic.svg"} 
								alt="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const container = Container.create(LogicDashboardHeader);
export default container;