import React, { Component } from 'react';
import { Container } from 'flux/utils';
import AdminSettingsStore from '../../stores/admin-settings-store';
import ContextStore from '../../stores/context-store';
import FieldStore from '../../stores/field-store';
import TableStore from '../../stores/table-store';
import FieldSettingsStore from '../../stores/field-settings-store';
import FieldTypeStore from '../../stores/field-type-store';
import PageStore from '../../stores/page-store';
import ToolboxStore from '../../stores/toolbox-store';
import RenderStore from '../../stores/render-store';
import ReactBlocklyComponent from '@dmclain-citizendeveloper/citdev-react-blockly-component';
import FieldActions from '../../actions/field-actions';
import InterfaceActions from '../../actions/interface-actions';
import PageActions from '../../actions/page-actions';
import ObjectUtils from '../../utils/object-utils';
import BlocklyUtils from '../../utils/blockly-utils';
import ConversionUtils from '../../utils/conversion-utils';
import Switch from '../forms/switch.react';
import LogicFunctionActions from '../../actions/logic-function-actions';
import AdminSettingsActions from '../../actions/admin-settings-actions';

let localStorage = window.localStorage;

/*global Blockly:true*/

class VisibilityAutomationSetting extends Component {
	constructor(props) {
		super(props);
		this.resizeBlockly = this.resizeBlockly.bind(this);
		this.handleXMLChange = this.handleXMLChange.bind(this);
		this.onSaveHandler = this.onSaveHandler.bind(this);
		this.onResetHandler = this.onResetHandler.bind(this);
		this.copyWorkspace = this.copyWorkspace.bind(this);
		this.pasteWorkspace = this.pasteWorkspace.bind(this);
		this.handleRunOnBackendChange = this.handleRunOnBackendChange.bind(this);
		this.handleMemoryLevelChange = this.handleMemoryLevelChange.bind(this);
		this.saveToStore = this.saveToStore.bind(this);
		this.convertAttachedFields = this.convertAttachedFields.bind(this);
	}
	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, PageStore, FieldStore, FieldTypeStore, ToolboxStore, ContextStore];
	}
	/**
	 * static - description
	 *
	 * @param  {object} prevState description
	 * @param  {object} props     description
	 * @return {type}           description
	 */
	static calculateState(prevState, props) {
		let recordId = AdminSettingsStore.getRecordId();
		let tableSchemaName = AdminSettingsStore.getTableSchemaName();
		let parentRecordId = AdminSettingsStore.getParentRecordId();
		let parentTableSchemaName = AdminSettingsStore.getParentTableSchemaName();
		let triggerKey = props.subSettingSchemaName ?
			[props.attachmentId, props.subSettingSchemaName, props.subSettingIndex, 'visibility'].join('-') :
			(props.attachmentId + '-visibility');
		let triggerAutomationJSON = null;
		let triggerAutomationObj = null;
		let pageRenderObj = RenderStore.getPageRenderObj(AdminSettingsStore.getRenderId());
		let pageTSN = pageRenderObj ? pageRenderObj.dataTableSchemaName : '';
		let startingTSN;

		let field = FieldStore.get(AdminSettingsStore.getRecordId());

		if (AdminSettingsStore && FieldStore && AdminSettingsStore.getTableSchemaName() === 'field') {
			startingTSN = field ? field.tableSchemaName : pageTSN;
		} else {
			startingTSN = pageTSN;
		}

		// Now get the pageTSN and startingTSN proper names for use in the labels
		let startingTSNObj = TableStore.getByTableSchemaName(startingTSN);
		let pageTSNObj = TableStore.getByTableSchemaName(pageTSN);

		let namedContexts = {
			pagePageRecord: ['Page, Page Record' + (pageTSN ? ' (' + (pageTSNObj ? pageTSNObj.pluralName || pageTSNObj.singularName || pageTSN : pageTSN)  + ')' : ''), 'namedContexts["pagePageRecord"]', pageTSN],
			pageCurrentRecord: ['Page, Current Record' + (startingTSN ? ' (' + (startingTSNObj ? startingTSNObj.pluralName || startingTSNObj.singularName || startingTSN : startingTSN)  + ')' : ''), 'namedContexts["pageCurrentRecord"]', startingTSN]
		};

		if(parentRecordId) {
			let parentObj = parentTableSchemaName === 'page' ? PageStore.get(parentRecordId) : FieldStore.get(parentRecordId);
			triggerAutomationJSON = parentObj[triggerKey];
		} else {
			triggerAutomationJSON = field[triggerKey];
		}


		if (triggerAutomationJSON) {
			try {
				triggerAutomationObj = JSON.parse(triggerAutomationJSON);
			} catch (e) {
				console.warn('Error parsing automation json:', triggerAutomationJSON);
			}
		}

		
		let visibilityToolbox = ToolboxStore.getVisibilityToolboxJS();
		// Swap the block available in the visibility drawer if this logic is for an individual tab
		if(props.isContentTab && visibilityToolbox) {
			let visibilityDrawer = visibilityToolbox.find(drawer => drawer.name === 'Visibility') || {blocks: []};
			visibilityDrawer.blocks = [{
				'type': 'citdev_show_tab_option',
				'sequence': 0
			}];
		}

		return {
			'value': triggerAutomationObj ? triggerAutomationObj : {},
			'recordId': recordId,
			'tableSchemaName': tableSchemaName,
			'parentRecordId': parentRecordId,
			'parentTableSchemaName': parentTableSchemaName,
			'visibilityToolbox': visibilityToolbox,
			'namedContexts': JSON.stringify(namedContexts),
			'settingsHidden': AdminSettingsStore.getSettingsListHidden()
		};
	}
	/**
	 * handleXMLChange - Saves XML to store when change has been made in Blockly
	 *
	 * @param  {string} triggerName     name of trigger
	 * @param  {string} blocklyxml  returned data from Blockly
	 */
	handleXMLChange(blocklyxml) {
		// convert XML to JS
		// let blocklyValueObj = BlocklyUtils.parseActionXml(blocklyxml, {
		// 	checkRunOnBackend: true,
		// 	runOnBackend: this.state.value && this.state.value.runOnBackend ? this.state.value.runOnBackend : false,
		// 	defaultToNull: true
		// });

		let blocklyValueObj = (blocklyxml && blocklyxml !== '<xml xmlns="http://www.w3.org/1999/xhtml"></xml>') ? {
			blocklyxml: blocklyxml,
			runOnBackend: (this.state.value && this.state.value.runOnBackend ? true : false) || (blocklyxml && blocklyxml.includes('cd_force_backend="true"')),
			memUse: (this.state.value && this.state.value.memUse ? this.state.value.memUse : 'l')
		} : null;

		this.saveToStore(blocklyValueObj);
	}

	/**
	 * Handles toggling whether the action should run on the backend or not
	 * 
	 * @param {object} event The click event
	 */
	handleRunOnBackendChange(event) {

		let newValue = event.target.value;
		let blocklyxml = this.state.value && this.state.value.blocklyxml
		let runOnBackend = newValue || blocklyxml.includes('cd_force_backend="true"');
		let blocklyValueObj = Object.assign({
			blocklyxml: '',
			js: ''
		}, this.state.value);
		blocklyValueObj.runOnBackend = runOnBackend;

		this.saveToStore(blocklyValueObj);
	}

	/**
	 * Handles toggling whether the action is memory-intensive or not
	 * 
	 * @param {object} event The click event
	 */
	handleMemoryLevelChange(event) {
		let memUse = event.target.value ? 'h' : 'l';
		let blocklyValueObj = Object.assign({
			blocklyxml: '',
			js: ''
		}, this.state.value);
		blocklyValueObj.memUse = memUse;

		this.saveToStore(blocklyValueObj);
	}

	/**
	 * Handles appropriately updating the store with the new value information
	 * 
	 * @param {object} blocklyValueObj 
	 */
	saveToStore(blocklyValueObj, params) {

		if(!blocklyValueObj) {
			return;
		}

		let triggerKey = this.props.subSettingSchemaName ? [this.props.attachmentId, this.props.subSettingSchemaName, this.props.subSettingIndex, 'visibility'].join('-') : (this.props.attachmentId + '-visibility');

		if(this.props.isContentTab) {
			let fieldId = this.state.recordId;
			let fieldObj = FieldStore.get(fieldId);
			fieldObj[triggerKey] = JSON.stringify(blocklyValueObj);
			FieldActions.pushToStore(fieldId, fieldObj);
		} else {
			// Check to make sure that we're in the correct format. If we're not, then we need to update our attachedField entry appropriately
			let parentComponentType = this.state.parentTableSchemaName;
			let parentComponentId = this.state.parentRecordId;
	
			let parentObj = parentComponentType === 'field' ? FieldSettingsStore.getSettings(parentComponentId) : PageStore.get(parentComponentId);
	
			if (parentObj) {
				if (this.blocklyEditor && params && params.generateRecordIds) {
					let workspace = this.blocklyEditor.workspace.workspace;
	
					// Generate the record IDs
					let returnBlocks = workspace.getBlocksByType('citdev_show_field') || [];
					let recordIdDict = {};
					returnBlocks.forEach(block => recordIdDict[ObjectUtils.getObjFromJSON(block.getFieldValue('targetField')).fieldId] = true);
					let recordIds = Object.keys(recordIdDict);
	
					let parentAttachedFields = parentObj.attachedFields ? ObjectUtils.getObjFromJSON(parentObj.attachedFields) : [];
					if (parentAttachedFields) {
						let attachedFieldObj = parentAttachedFields.find(({ attachmentId }) => attachmentId === this.props.attachmentId);
						if (attachedFieldObj) {
							attachedFieldObj.recordIds = recordIds;
						}
						parentObj.attachedFields = JSON.stringify(parentAttachedFields);
					}
				}
			}
	
			if (parentComponentType === 'field') {
				parentObj[triggerKey] = JSON.stringify(blocklyValueObj);
				FieldActions.pushToStore(parentComponentId, parentObj);
			} else if (parentComponentType === 'page') {
				parentObj[triggerKey] = JSON.stringify(blocklyValueObj);
				PageActions.pushToStore(parentComponentId, parentObj);
			} else {
				console.error('Error: unsupported parent component type %s', parentComponentType);
			}
		}

	}

	/**
	 * Returns the code that needs to be run by the action processor
	 * 
	 * @param {any} workspace 
	 */
	workspaceToCode(workspace) {
		Blockly.JavaScript.init(workspace);
		return Blockly.JavaScript.workspaceToCode(workspace);
	}

	/**
	 * resizeBlockly - Resizes blockly instances to full width by triggering resize() from ref.
	 * A setTimeout of 400 milliseconds is used to allow the CSS transition to complete
	 * Prior to calculation of new width
	 *
	 * @param  {string} (optional) blocklyRefId of ref to Blockly refs. Will pull state value when left undefined
	 */
	resizeBlockly() {
		if (this.blocklyEditor) {
			setTimeout(this.blocklyEditor.resize, 500);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// If we don't have an attachmentId, convert our parent's attached fields
		// if(!this.props.attachmentId && this.state.parentRecordId && this.state.parentTableSchemaName) {
		// 	setTimeout(() => {
		// 		ConversionUtils.convertAttachedFields(this.state.parentRecordId, this.state.parentTableSchemaName);
		// 	});
		// }
		this.resizeBlockly();

		// If our initialXml has changed, then we need to reset our workspace
		if(
			this.blocklyEditor &&
			(
				(!this.state.value && prevState && prevState.value) ||
				(prevState && prevState.value && this.state.value && prevState.value.blocklyxml !== this.state.value.blocklyxml)
			)
		) {
			this.blocklyEditor.reset();
		}
	}

	/**
	 * Calling resizeBlockly during this lifecycle phase to ensure
	 * Blockly resizes when opens right panel for the first time
	 */
	componentDidMount() {
		this.resizeBlockly();
	}
	/**
	 * Update the JS in the store when the component is unmounted.
	 */
	componentWillUnmount() {
		// let blocklyxml = this.state && this.state.value && this.state.value.blocklyxml ? this.state.value.blocklyxml : '';

		// let blocklyValueObj = BlocklyUtils.parseActionXml(blocklyxml, {
		// 	checkRunOnBackend: true,
		// 	runOnBackend: this.state.value && this.state.value.runOnBackend ? this.state.value.runOnBackend : false,
		// 	defaultToNull: true,
		// 	memUse: this.state.value && this.state.value.memUse ? this.state.value.memUse : 'l',
		// 	includeJs: true
		// });

		// this.saveToStore(blocklyValueObj, {generateRecordIds: true});

		if(this.blocklyEditor && this.blocklyEditor.workspace && this.blocklyEditor.workspace.workspace) {
			let blocklyValueObj = BlocklyUtils.getWorkspaceInfo(this.blocklyEditor.workspace.workspace, {
				checkRunOnBackend: true,
				runOnBackend: this.props.value && this.props.value.runOnBackend ? this.props.value.runOnBackend : false,
				defaultToNull: true,
				memUse: this.props.value && this.props.value.memUse ? this.props.value.memUse : 'l',
				includeJs: true
			});
			this.saveToStore(blocklyValueObj, {generateRecordIds: true});
		} else {
			console.warn('Missing value. this.blocklyEditor is', this.blocklyEditor);
		}

	}
	/**
	 * onSaveHandler - Triggers to send Blockly data in store to database via API
	 */
	onSaveHandler() {
		// Display notification to user

		if(this.blocklyEditor && this.blocklyEditor.workspace && this.blocklyEditor.workspace.workspace) {
			setTimeout(() => {
	
				let blocklyValueObj = BlocklyUtils.getWorkspaceInfo(this.blocklyEditor.workspace.workspace, {
					checkRunOnBackend: true,
					runOnBackend: this.props.value && this.props.value.runOnBackend ? this.props.value.runOnBackend : false,
					defaultToNull: true,
					memUse: this.props.value && this.props.value.memUse ? this.props.value.memUse : 'l',
					includeJs: true
				});
				this.saveToStore(blocklyValueObj, {generateRecordIds: true});
	

				// Now save to the DB
				if(this.props.isContentTab) {
					// If this is custom content tab visibility, we want to save the field itself
					let fieldId = this.state.recordId;
					let parentSaveNotif = InterfaceActions.stickyNotification({ 'level': 'info', 'message': 'Updating custom visibility logic...' });
					FieldActions.pushToDatabasePromise(FieldStore.get(fieldId, true))
							.then(() => {
								InterfaceActions.clearStickyNotification(parentSaveNotif);
							})
							.catch(error => {
								console.error('Error saving custom visibility logic', error);
								InterfaceActions.notification({ 'level': 'error', 'message': 'Error saving custom visibility logic.  Try again.' });
							});
				} else {
					// Otherwise, we want to save the parent object
					let parentComponentType = this.state.parentTableSchemaName;
					let parentComponentId = this.state.parentRecordId;
					let parentSaveNotif = InterfaceActions.stickyNotification({ 'level': 'info', 'message': 'Updating custom visibility logic...' });
					if (parentComponentType === 'field') {
						FieldActions.pushToDatabasePromise(FieldStore.get(parentComponentId, true))
							.then(() => {
								InterfaceActions.clearStickyNotification(parentSaveNotif);
							})
							.catch(error => {
								console.error('Error saving custom visibility logic', error);
								InterfaceActions.notification({ 'level': 'error', 'message': 'Error saving custom visibility logic.  Try again.' });
							});
					} else if (parentComponentType === 'page') {
						PageActions.pushToDatabasePromise(PageStore.get(parentComponentId, true))
							.then(() => {
								InterfaceActions.clearStickyNotification(parentSaveNotif);
							})
							.catch(error => {
								console.error('Error saving custom visibility logic', error);
								InterfaceActions.notification({ 'level': 'error', 'message': 'Error saving custom visibility logic.  Try again.' });
							});
					} else {
						InterfaceActions.clearStickyNotification(parentSaveNotif);
						console.error('Error: unsupported parent component type %s', parentComponentType);
					}
				}
				

			}, 1000);
		}

	}

	/**
	 * onResetHandler - Calls API to retrieve data to reset value in store
	 */
	onResetHandler() {

		// Reset all of the logic functions used to the value from the database
		// (Yes, this may mean undoing a logic function change if changed in another trigger but not saved. That's the way it's going to have to be. Don't do that.)
		let logicFunctionIds = this.state.value.logicFunctionsUsed;

		// Handle converting logicFunctionsUsed into an array
		let logicFunctionIdsArr = [];
		if (logicFunctionIds) {
			if (Array.isArray(logicFunctionIds)) {
				logicFunctionIdsArr = logicFunctionIds;
			} else {
				logicFunctionIdsArr = logicFunctionIds.split(',');
			}
		}
		if (logicFunctionIdsArr && logicFunctionIdsArr.length) {
			InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting all functions used within this trigger to their saved values...' });
			logicFunctionIdsArr.forEach(logicFunctionId => {
				LogicFunctionActions.pullFromDatabase(logicFunctionId);
			});
		}

		if (this.state.tableSchemaName === 'field') {
			InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting Automation...' });
			FieldActions.pullFromDatabase(this.state.recordId);
		} else if (this.state.tableSchemaName === 'page') {
			InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting Automation...' });
			PageActions.pullFromDatabase(this.state.recordId);
		}

		if (this.state.parentTableSchemaName === 'field') {
			FieldActions.pullFromDatabase(this.state.parentRecordId);
		} else if (this.state.parentTableSchemaName === 'page') {
			PageActions.pullFromDatabase(this.state.parentRecordId);
		}

		if(this.blocklyEditor) {
			this.blocklyEditor.reset();
		}
	}
	/**
	 * Copies blocks to citdev clipboard
	 */
	copyWorkspace(){
		try {
			let blocklyValueObj = BlocklyUtils.getWorkspaceInfo(this.blocklyEditor.workspace.workspace, {
				defaultToNull: true,
				includeJs: false // We don't need to bother with the JS for this
			});
			localStorage.visibilityClipboard = JSON.stringify(blocklyValueObj);
			InterfaceActions.notification({ 'level': 'success', 'message': 'Copying logic to clipboard...' });
		} catch(err) {
			console.error('Error when copying logic: ', err);
			InterfaceActions.notification({ 'level': 'error', 'message': 'Error when copying logic!' });
		}
	}
	/**
	 * Pastes blocks from citdev clipboard to workspace; appends to, not overrides, blocks
	 */
	pasteWorkspace() {
		let value = localStorage.visibilityClipboard;
		try{
			let valueObj = ObjectUtils.getObjFromJSON(value);
			
			let pastedblocklyxml = valueObj.blocklyxml;
			BlocklyUtils.appendToWorkspace(pastedblocklyxml, this.blocklyEditor.workspace.workspace);
			InterfaceActions.notification({ 'level': 'success', 'message': 'Pasting new logic below existing logic. Please check your new blocks to make sure they don\'t overlap!' });
			// let oldblocklyxml = this.state.value ? this.state.value.blocklyxml : '';
			
			
			// // Pass the pasted value into the utility function to combine them into one workspace XML string
			// let newxml = BlocklyUtils.combineWorkspaces(pastedblocklyxml, oldblocklyxml);

			// // Make sure that our new code still compiles; the catch will catch it if it's invalid XML
			// if(Blockly.Xml.textToDom(newxml)){
			// 	this.handleXMLChange(newxml);
			// 	InterfaceActions.notification({ 'level': 'success', 'message': 'Pasting new logic below existing logic. Please check your new blocks to make sure they don\'t overlap!' });
			// }
		} catch(err) {
			InterfaceActions.notification({ 'level': 'error', 'message': 'Attempted to paste invalid value into workspace.' });
			console.warn('Attempted to paste with invalid data in clipboard. Value was', value);
			console.warn('Error was', err);
		}
	}

	convertAttachedFields() {
		// If we don't have an attachmentId, convert our parent's attached fields
		if (!this.props.attachmentId && this.state.parentRecordId && this.state.parentTableSchemaName) {
			ConversionUtils.convertAttachedFields(this.state.parentRecordId, this.state.parentTableSchemaName);
		}
	}

	/**
	 * render
	 *
	 * @return {JSX}
	 */
	render() {
		let { attachmentId, disabledRetailRestrictions, onUpgradeClick } = this.props;
		let { settingsHidden } = this.state;

		if (!attachmentId) {
			return (
				<div className="automation-setting-container pt-0 cd-bg-3 pr-1 pl-1">
					<div className="col-md-offset-3 col-md-6" style={{padding: "15px 0 0 0"}}>
						<button className="btn btn-primary" onClick={this.convertAttachedFields}>Enable Custom Visibility</button>
					</div>
					<div>
						Click above to enable this feature.  Your page will refresh when ready.
					</div>
				</div>);
		}
		let workspaceConfiguration = {
			grid: {
				spacing: 20,
				length: 3,
				colour: '#ccc',
				snap: true
			},
			zoom: {
				controls: true,
				wheehandleRunOnBackendChangel: true,
				startScale: 0.9,
				maxScale: 3,
				minScale: 0.3,
				scaleSpeed: 1.2
			}
		};

		let headerControls = [
			<div className="trigger-setting d-flex align-items-center" key="customVisibilityRunOnBackend">
				<label>
					<h4 className="d-flex align-items-center" title="Controls whether or not to run this action on the backend. This is good for long-running processes or vital processes which may be interrupted by the user navigating away from the page. Some action blocks are backend-only actions which will force this setting to be set.">Run on Backend
					<Switch
							onChange={this.handleRunOnBackendChange}
							value={this.state.value && this.state.value.runOnBackend ? true : false}
							id={'toggle-' + this.props.renderId}
							describedBy={'label-' + this.props.renderId}
							fieldSchemaName={'toggle-' + this.props.renderId}
						/>
					</h4>
				</label>
			</div>,
			<div className="trigger-setting d-flex align-items-center" key="customVisibilityHighMem">
				<label>
					<h4 className="d-flex align-items-center" title="Controls whether or not this is marked as a high-memory action. High memory actions may include complex imports or actions which run over large record sets. For best performance, we recommend starting with lower memory requirements and increasing the memory requirements ONLY if your action experiences memory issues.">Process with high memory
					<Switch
							onChange={this.handleMemoryLevelChange}
							value={this.state.value && this.state.value.memUse === 'h' ? true : false}
							id={'highmemtoggle-' + this.props.renderId}
							describedBy={'highmemlabel-' + this.props.renderId}
							fieldSchemaName={'highmemtoggle-' + this.props.renderId}
						/>
					</h4>
				</label>
			</div>];
		
		let saveControls = (disabledRetailRestrictions 
			? [
				<button 
					key="submit" 
					className="btn btn-success btn-lg setting-list-button mr-3 ml-1" 
					form="appearance-form" 
					aria-label="Upgrade to use Visibility"
					style={{ width: '100%'}}
					onClick={(event) => {
						onUpgradeClick(event);
						AdminSettingsActions.onSettingsListHideChange(false);
					}}>
					Upgrade to use Visiblity
				</button>
				]
			: [
				<button
					key="copy"
					className="btn btn-secondary"
					form="appearance-form"
					aria-label="Copy"
					onClick={this.copyWorkspace}>
					Copy
				</button>,
				<button
					key="paste"
					className="btn btn-secondary ml-2"
					form="appearance-form"
					aria-label="Paste"
					onClick={this.pasteWorkspace}>
					Paste
				</button>,
				<button
					key="reset"
					className="btn btn-warning mx-2"
					form="appearance-form"
					aria-label="Reset"
					onClick={this.onResetHandler}>
					Reset
				</button>,
				<button
					key="submit"
					className="btn btn-primary"
					form="appearance-form"
					aria-label="Save"
					onClick={this.onSaveHandler}>
					Save
				</button>
				]);

		let header = (<h2 className="d-flex w-100 justify-content-between align-items-center py-3 px-2">
			<div className='d-flex align-items-center'>
				<button 
					className="btn btn-back pl-0"
					title="Triggers" 
					form="appearance-form" 
					onClick={() => {AdminSettingsActions.onSettingsListHideChange(!settingsHidden);}}>
					<img height="26" width="26" src={ContextStore.getUrlMedia() + "/expand-settings-list.svg"} alt="" />
				</button>
				<h3>Custom Visibility</h3> 
			</div>
			{headerControls}
			<div className="btn-wrapper d-flex align-items-center">
				{saveControls}
			</div>
		</h2>);

		let visibilityToolbox = this.state.visibilityToolbox;

		let tableSchemaName = this.props.tableSchemaName;
		let fieldObj = FieldStore.get(this.props.fieldId) || {};
		let fieldInfo = {
			fieldId: fieldObj.recordId,
			fieldSchemaName: fieldObj.fieldSchemaName,
			fieldTypeId: fieldObj.fieldType
		};

		let defaultXml = '';
		
		if(this.props.isContentTab) {
			defaultXml = `<xml xmlns="http://www.w3.org/1999/xhtml"><block type="citdev_show_tab_option" id="KuajVlgU8DZ!*uj|n%xV" x="110" y="50"><value name="enableView"><block type="logic_boolean" id="v!0$cJr]kR^wxe+ekTub"><field name="BOOL">FALSE</field></block></value></block></xml>`;
		} else {
			defaultXml = `<xml xmlns="http://www.w3.org/1999/xhtml"><block type="citdev_show_field" id="KuzjVlgU8DZ!*uj|n%xV" x="110" y="50"><mutation selectedtable="` +
				tableSchemaName +
				`"></mutation><field name="tableSchemaName">` +
				tableSchemaName +
				`</field><field name="targetField">` +
				JSON.stringify(fieldInfo) +
				`</field><value name="enableView"><block type="logic_boolean" id="v!0$cJr]kR^wxe+ekTub"><field name="BOOL">FALSE</field></block></value><value name="enableEdit"><block type="logic_boolean" id="sviW6%SZXe1d|r,=9h^K"><field name="BOOL">FALSE</field></block></value></block></xml>`;
		}
		return (
			<div className="automation-setting-container pt-0 cd-bg-3 pr-1 pl-1">
				{header}
				<ReactBlocklyComponent.BlocklyEditor
					ref={(blocklyEditor) => { this.blocklyEditor = blocklyEditor; }}
					className="react-blockly-component"
					workspaceConfiguration={workspaceConfiguration}
					toolboxCategories={visibilityToolbox}
					initialXml={this.state.value.blocklyxml || defaultXml}
					wrapperDivClassName="automation-settings--blockly px-1"
					// xmlDidChange={this.handleXMLChange}
					namedContexts={this.state.namedContexts}
					triggerName={'custom-visibility'}
				/>
			</div>
		);
	};
}

const container = Container.create(VisibilityAutomationSetting, { withProps: true });
export default container;