import AppDispatcher from '../dispatcher/app-dispatcher';
import ObjectUtils from './object-utils';
import BrowserStorageStore from '../stores/browser-storage-store';
import RecordActions from '../actions/record-actions';
import RecordStore from '../stores/record-store';
import {RecordConstants} from '../constants/record-constants';
import FieldStore from '../stores/field-store';
import FieldTypeStore from '../stores/field-type-store';
import socketFetcher from './socket-fetcher';
import uuid from 'uuid';
import UAParser from 'ua-parser-js';

/**
 * 
 * @param {string} path The path to the desired result, separated by a period. (E.g. ua or browser.name)
 * @returns 
 */
function getUserAgent(path) {
	let parser = new UAParser(); // Because this is on the FE, we do not need to manually pass in the user-agent
	let parserResults = parser.getResult();
	let paths = path && path.split('.');
	let i = 0;
	while(paths[i]) {
		parserResults = parserResults[paths[i]];
		i++;
	}
	return parserResults;
}

/**
 * Calls a service, delegating expression processor calls to the local EP
 * 
 * @param {*} installationId 
 * @param {*} renderId 
 * @param {*} serviceName 
 * @param {*} params 
 * @param {*} serviceTimeout 
 */
function callService(installationId, renderId, serviceName, params, serviceTimeout) {
	let ExpressionProcessor = require('./expression-processor').default;
	return new Promise(function (resolve, reject) {
		if (serviceName === 'expression-processor-v1' ||
			serviceName === 'expression-processor-v2' ||
			serviceName === 'expression-processor-v3') {
			params.renderId = renderId;
			//Use the front end instead of the backend
			ExpressionProcessor.processExpression(params)
				.then(function (result) {
					resolve({ 'result': result });
				})
				.catch(error => reject(error));
		} else {
			let qpRegExp = new RegExp('^query-processor-v(\\d+)?$');
			//If the query processor is being called and it is version 2 or greater then append the -expression to use
			//the expression version of the query processor to avoid processing loops / deadlocks
			if (qpRegExp.test(serviceName)) {
				let match = qpRegExp.exec(serviceName);
				let version = parseInt(match[1], 10);
				if (version >= 2) {
					serviceName += '-frontend';
				}
			}

			socketFetcher('gw/callService-v1', JSON.stringify({
				'installationId': installationId,
				'serviceName': serviceName,
				'params': params,
				'timeout': serviceTimeout
			})).then(function (result) {
				if (result.responseCode === 200) {
					resolve(result.response)
				} else {
					reject(result.response);
				}
			})
		}
	});
};

/**
 * Gets the values for all designated fields in a record set
 * 
 * @param {*} fields 
 * @param {*} recordSet 
 * @param {*} recordSets 
 */
function getFieldValuesBulk(fields, recordSet, recordSets) {
	if (!fields) {
		console.error('getFieldValuesBulk called with no fields');
		return Promise.reject(new Error('getFieldValuesBulk called with no fields'));
	}

	if (!recordSet || !recordSet.length) {
		return Promise.resolve([]);
	}

	// Try to get the TSN from the record set
	let tableSchemaName = recordSet[0] && recordSet[0].tableSchemaName ? recordSet[0].tableSchemaName : '';

	if (!tableSchemaName) {
		console.error('getFieldValueBulk called with no tableSchemaName provided either explicitly or implicitly');
		return Promise.reject(new Error('getFieldValueBulk called with no tableSchemaName'));
	}

	// Due to query length limitations, we limit the number of records that we can use per record browse call
	// So if we have a million records, we split it up into 40 record browse calls
	// (which is still a lot fewer than the record-read calls we'd have if using getFieldValue)
	// Calculating using (1000000 - 'SELECT `root.'.length - 128 - '` FROM `'.length - 129 - ' WHERE `root.recordId` IN ['.length - 1) / 38
	// we could actually go up to 26307, but this is a round number that leaves a bit of room for error
	// Lowered to 1000 after running into issues with return size in grpc
	let maxRecords = 1000;
	let i = 0;
	let len = recordSet.length;
	let lookupPromises = [];
	while (i < len) {
		// Get the end index of the record subset
		let end = Math.min(i + maxRecords, len);
		let recordSubset = recordSet.slice(i, end);
		let nodeId = 'return-node';

		// We need our results to be sorted in the appropriate order according to the records.
		// I'm doing this within this loop in hopes that it will result in garbage cleanup cleaning up this
		// variable once it's done so that we don't have to load a potentially very large object into memory
		let indexLookup = {};
		recordSubset.forEach(({ recordId }, index) => {
			indexLookup[recordId] = index;
		});

		let results = [];

		let query = {
			queryId: 'getFieldsBulk-FE-PU-1234-abcd-123412341234',
			returnNode: nodeId,
			nodes: [
				{
					nodeId,
					tableSchemaName
				}
			],
			sorts: [],
			filters: [{
				type: 'filter',
				operation: 'recordIs',
				filteredNode: nodeId,
				fieldSchemaName: 'recordIs',
				value: recordSubset
			}]
		};
		// Now actually make the record browse call
		// (Notably, this is the ONLY place in the action processor right now where we call record browse)
		// @TODO: Why are we stringifying this, anyway? It's wasteful here and I'd prefer not to if we don't need to.
		let lookupPromise = new Promise((resolve, reject) => {
			socketFetcher('gw/recordBrowse-v4', JSON.stringify({
				tableSchemaName,
				fields,
				query,
				// Potentially used in permission queries
				recordSets: recordSets || {},
				// @TODO: Likewise, add this in
				browserStorage: {}
			}))
				.then(({ responseCode, response, error }) => {
					// If our response is a 200 response, it was successful
					if (responseCode >= 200 && responseCode < 300) {
						// Unfortunately, due to the way that record-browse returns, we have no choice but to do this
						Object.keys(response.records[tableSchemaName]).forEach(recordId => {
							let record = response.records[tableSchemaName][recordId];
							fields.forEach(field => {
								let { fieldSchemaName, fieldPart } = field;
								if(field.fieldTypeId && !field.fieldType) {
									field.fieldType = field.fieldTypeId;
								}
								let value = record[fieldSchemaName];
								// If this is a multipart field, we need to get the part from it
								if (fieldPart && value) {
									let fieldValueObj = typeof value === 'string' ? ObjectUtils.getObjFromJSON(value) : value;
									value = typeof fieldValueObj[fieldPart] === 'undefined' ? null : fieldValueObj[fieldPart];
								}
								// Make sure the results are sorted appropriately
								results[indexLookup[recordId]] = results[indexLookup[recordId]] || {
									recordId
								};
								results[indexLookup[recordId]][fieldSchemaName] = value;
							});
						});

						let correlationId = uuid.v4();

						let token = AppDispatcher.register((action) => {
							if (action.get('type') === RecordConstants.RECORDS_LOADED) {
								if (action.get('correlationId') === correlationId) {
									AppDispatcher.waitFor([RecordStore.getDispatchToken()]);
									AppDispatcher.unregister(token);
									// Load our response into the record store for later use.
									return resolve(results);
								}
							}
						});
						RecordActions.onDataLoaded(response.records, false, correlationId);
						
					} else if (responseCode < 500) {
						console.warn('%s response received from record browse in front end getFieldValueBulk: ', responseCode, response || error);
						resolve([]);
					} else {
						let err = new Error(responseCode + ' error received from recordBrowse in front end getFieldValue bulk: ' + (response || error));
						return reject(err);
					}
				})
				.catch(reject);
		});
		lookupPromises.push(lookupPromise);
		i = end;
	}

	return new Promise((resolve, reject) => {
		Promise.all(lookupPromises)
			.then(results => {
				// Concatenate them all into one array
				let flattenedResults = [];
				results.forEach(resultSet => {
					flattenedResults = flattenedResults.concat(resultSet);
				});
				return resolve(flattenedResults);
			})
			.catch(reject);
	});
}

/**
 * Finds the fields used by a filter
 * 
 * @param {*} usesFields 
 * @param {*} filters 
 * @param {*} returnTable 
 */
function fieldsUsedInFilters(usesFields, filters, returnTable) {
	if (filters) {
		filters.forEach(filter => {
			if (filter.type === 'filter' && filter.operation === 'field' && filter.fieldSchemaName && ['recordIs', 'recordIsNot'].indexOf(filter.fieldSchemaName) === -1) {
				usesFields[filter.fieldSchemaName] = getFieldInfo(returnTable, filter.fieldSchemaName);
				// Update the filter while we're in here and save us some heartbreak
				if (usesFields[filter.fieldSchemaName] && usesFields[filter.fieldSchemaName].part) {
					filter.part = usesFields[filter.fieldSchemaName].part;
					filter.fieldSchemaName = usesFields[filter.fieldSchemaName].fieldSchemaName;
				}
			} else if (filter.type === 'group' && filter.filters) {
				fieldsUsedInFilters(usesFields, filter.filters, returnTable);
			}
		});
	}

	return usesFields;
}

/**
 * Processes a query
 * @param {*} installationId 
 * @param {*} renderId 
 * @param {*} runTimeVariables 
 * @param {*} query 
 * @param {*} recordSets 
 * @param {*} extraParams 
 */
function processQueryV2(installationId, renderId, runTimeVariables, query, recordSets = {}, extraParams = {}, fields) {

	// Removing all escaping slashes from query so as to catch cases like 'namedContexts[\"pagePageRecord\"], etc.
	let unescapedQuery = query ? query.replace(/\\/g, '') : '';
	let hasPageInterfaceValues = (unescapedQuery.includes('namedContexts["pagePageRecord"]'));
	let hasCurrentRecordInterfaceValues = (unescapedQuery.includes('namedContexts["pageCurrentRecord"]'));
	let recordStoreRecords = {};
	let queryObj = {};

	// Get some of the parameters to include in the various calls
	let browserStorage = BrowserStorageStore.getStateJS();

	if (query) {
		queryObj = typeof query === 'string' ? ObjectUtils.getObjFromJSON(query) : query;
	}
	let source = queryObj.source;

	// If the source is filtering a record set, delegate it
	if (source === 'recordSet') {

		let returnNodeId = queryObj.returnNode;
		let returnNode = queryObj.nodes.find(node => node.nodeId === returnNodeId);
		let recordSetName = returnNode.recordSet;
		let recordSet = recordSets[recordSetName] || [];
		let returnTable = recordSet[0] && recordSet[0].tableSchemaName ? 
			recordSet[0].tableSchemaName :
			'';
		if(!returnTable) {
			return Promise.resolve([]);
		}

		let usesFields = {};

		let { filters, sorts, limit, offset } = queryObj;

		// We need to process the expressions for any of these filters in here
		let filterPromises = [];

		// Process any expressions
		if (filters) {
			extraParams.renderId = renderId;
			filterPromises = queryObj.filters.map((filter) => {
				return expressionRecursor(filter, extraParams, recordSets, installationId);
			});
		}

		return new Promise((resolve, reject) => {

			Promise.all(filterPromises)
				.then(newFilters => {
					filters = newFilters;

					// Because we can't add any joined nodes on record set filters,
					// we can assume all filters and sorts apply to this

					// Find all fields used by the filters
					usesFields = fieldsUsedInFilters(usesFields, filters, returnTable);

					// Find all fields used by the sorts
					if (sorts && sorts.length) {
						sorts.forEach(sort => {
							// Process any sorts on multipart fields
							let {fieldSchemaName} = sort;
							let fieldInfo = getFieldInfo(returnTable, fieldSchemaName);
							if(fieldInfo && fieldInfo.part) {
								sort.part = fieldInfo.part;
								sort.fieldSchemaName = fieldInfo.fieldSchemaName;
							}
							usesFields[sort.fieldSchemaName] = fieldInfo;
						});
					}

					let fieldsArr = Object.keys(usesFields).map(fsn => {
						let val = usesFields[fsn];
						if(val && val.part) {
							// Ignore this; we just want to get the entire multipart field
							val = Object.assign({}, val);
							delete val.part;
						}
						return val;
					});
					// This may result in duplicates but the utility method handles them, so it's not worth filtering out
					fieldsArr = fields ? fieldsArr.concat(fields) : fieldsArr;
					return getFieldValuesBulk(fieldsArr, recordSet, recordSets);
				})
				.then((results) => {
					// Now we need to filter the results by the filters
					if (filters && filters.length) {
						// This implicitly ANDs them, which is as desired
						filters.forEach(filter => {
							sanitizeFilter(filter);
							results = results.filter(value => evaluateFilter(filter, value, recordSets));
						});
					}
					// Then sort them
					if (sorts && sorts.length) {
						results.sort(recursiveSort.bind(this, sorts));
					}

					// Then limit and offset them
					if (limit) {
						offset = offset || 0;
						return results.slice(offset, limit + offset);
					} else if (offset) {
						return results && results.length ? results.slice(offset, results.length) : results;
					} else {
						return results;
					}
				})
				.then(records => {
					// This is the format we expect these values to be in
					// It's kind of a waste and we should consider adding more flexibility
					let recordsArr = [];
					let recordsForStore = {
						[returnTable]: {

						}
					};
					records.forEach(record => {
						recordsArr.push(record.recordId);
						recordsForStore[returnTable][record.recordId] = record;
					});
					let correlationId = uuid.v4();
					

					let token = AppDispatcher.register((action) => {
						if (action.get('type') === RecordConstants.RECORDS_LOADED) {
							if (action.get('correlationId') === correlationId) {
								AppDispatcher.waitFor([RecordStore.getDispatchToken()]);
								AppDispatcher.unregister(token);
								return resolve({
									code: 200,
									records: recordsArr
								});
							}
						}
					});
					RecordActions.onDataLoaded(recordsForStore, false, correlationId);
				})
				.catch(error => {
					console.error('Error in filtering existing record set', error);
					return reject({
						error: error && error.message ? error.message : (error + ''),
						code: 500
					});
				});
		});
		// Gets the values for each of them
	} else {
		let FieldComponents = require('./field-components').default;
		if (hasPageInterfaceValues) {
			let tsn = recordSets && recordSets['page'] && recordSets['page'][0] ?
				recordSets['page'][0].tableSchemaName :
				'';
			let recordId = recordSets && recordSets['page'] && recordSets['page'][0] ?
				recordSets['page'][0].recordId :
				'';
			if (tsn && recordId) {
				recordStoreRecords[tsn] = {
					[recordId]: RecordStore.getRecord(tsn, recordId)
				};
			}
		}
		if (hasCurrentRecordInterfaceValues) {
			let tsn = recordSets && recordSets['startingContext'] && recordSets['startingContext'][0] ?
				recordSets['startingContext'][0].tableSchemaName :
				'';
			let recordId = recordSets && recordSets['startingContext'] && recordSets['startingContext'][0] ?
				recordSets['startingContext'][0].recordId :
				'';
			if (tsn && recordId) {
				recordStoreRecords[tsn] = {
					[recordId]: RecordStore.getRecord(tsn, recordId)
				};
			}
		}
		if (recordSets && query) {
			// Always include the basics
			let newNamedContexts = {
				application: recordSets.application,
				currentUser: recordSets.currentUser,
				installation: recordSets.installation,
				page: recordSets.page,
				startingContext: recordSets.startingContext
			};
			let queryString = typeof query === 'string' ? query : JSON.stringify(query);
			Object.keys(recordSets).forEach(recordSetName => {
				// Lazy query string checking for now; we can improve this if we end getting too many false positives
				if (queryString.includes(recordSetName)) {
					newNamedContexts[recordSetName] = recordSets[recordSetName];
				}
			});
			recordSets = newNamedContexts;
		}

		if(fields && fields.length) {
			let tableSchemaName = FieldComponents.query.getReturnTable(query);
			// Make sure this is in the format that record-browse expects
			fields.forEach(field => {
				if(field.fieldId && !field.recordId) {
					field.recordId = field.fieldId;
				}
				if(field.fieldTypeId && !field.fieldType) {
					field.fieldType = field.fieldTypeId;
				}
			});
			return callService(installationId, renderId, 'record-browse-v2', Object.assign({
				query: query,
				tableSchemaName,
				fields: fields,
				browserStorage: browserStorage,
				runTimeVariables: runTimeVariables || {},
				context: {
					namedContexts: recordSets,
					user: recordSets && recordSets['currentUser'] && recordSets['currentUser'][0] ?
						recordSets['currentUser'][0].recordId :
						'',
					installationId: recordSets && recordSets['installation'] && recordSets['installation'][0] ?
						recordSets['installation'][0].recordId :
						'',
					page: recordSets && recordSets['page'] && recordSets['page'][0] ?
						recordSets['page'][0].recordId :
						'',
					application: recordSets && recordSets['application'] && recordSets['application'][0] ?
						recordSets['application'][0].recordId :
						''
				},
				recordSets: recordSets,
				recordStoreRecords: recordStoreRecords
			}, extraParams)).then(response => {
				if(response && response.records) {

					return new Promise((resolve) => {
						let correlationId = uuid.v4();
						let token = AppDispatcher.register((action) => {
							if (action.get('type') === RecordConstants.RECORDS_LOADED) {
								if (action.get('correlationId') === correlationId) {
									AppDispatcher.waitFor([RecordStore.getDispatchToken()]);
									AppDispatcher.unregister(token);
									let records = response.records[tableSchemaName];
									let toResolve = Object.keys(records).map((recordId) => recordId);
									return resolve({
										code: 200,
										records: toResolve
									});
								}
							}
						});
						RecordActions.onDataLoaded(response.records, false, correlationId);
					});
				} else {
					throw new Error(response.error);
				}
			});
		} else {
			return callService(installationId, renderId, 'query-processor-v2', Object.assign({
				query: query,
				tableSchemaName: FieldComponents.query.getReturnTable(query),
				browserStorage: browserStorage,
				runTimeVariables: runTimeVariables || {},
				context: {
					namedContexts: recordSets,
					user: recordSets && recordSets['currentUser'] && recordSets['currentUser'][0] ?
						recordSets['currentUser'][0].recordId :
						'',
					installationId: recordSets && recordSets['installation'] && recordSets['installation'][0] ?
						recordSets['installation'][0].recordId :
						'',
					page: recordSets && recordSets['page'] && recordSets['page'][0] ?
						recordSets['page'][0].recordId :
						'',
					application: recordSets && recordSets['application'] && recordSets['application'][0] ?
						recordSets['application'][0].recordId :
						''
				},
				recordStoreRecords: recordStoreRecords
			}, extraParams)).then(results => {
				return results;
			});
		}
	}
}

/**
 * Sorts a record set according to the list of sorts
 * @param {*} sorts 
 * @param {*} a 
 * @param {*} b 
 */
function recursiveSort(sorts, a, b) {
	if (!sorts || !sorts.length) {
		return 0;
	}
	let { fieldSchemaName, direction, part } = sorts[0];

	// @TODO: In the query converter, we throw errors if sorts are missing
	direction = direction ? direction.toUpperCase() : '';

	let aVal = a[fieldSchemaName];
	let bVal = b[fieldSchemaName];

	if(part) {
		aVal = aVal ? ObjectUtils.getObjFromJSON(aVal)[part] : undefined;
		bVal = bVal ? ObjectUtils.getObjFromJSON(bVal)[part] : undefined;
	}

	if (!isNaN(aVal) && !isNaN(bVal)) {
		// Numeric sorting
		aVal = +aVal;
		bVal = +bVal;
		if (aVal === bVal) {
			return recursiveSort(sorts.slice(1, sorts.length), a, b);
		}
		// @TODO: Check these
		if (direction === 'ASC') {
			return aVal > bVal ? 1 : -1;
		} else {
			return aVal > bVal ? -1 : 1;
		}
	} else {
		// String sorting
		aVal = '' + aVal;
		bVal = '' + bVal;
		return direction === 'ASC' ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
	}

}

/**
 * Takes the record and the filter in order to figure out what
 * value to use when evaluating the filter. Split out because
 * the processing for most fields is very similar.
 * 
 * @param {*} filter 
 * @param {*} evaluated 
 */
function convertRecordValue(filter, record) {
	// Leave the record value alone for group filters or missing filters
	if(!filter || filter.type !== 'filter') {
		return record;
	}
	if(['recordIs', 'recordIsNot'].indexOf(filter.operation) > -1) {
		return record.recordId;
	}
	if(filter.operation === 'field' && filter.fieldSchemaName && filter.part) {
		// Process multipart fields
		let val = record[filter.fieldSchemaName];
		let valObj = val ? ObjectUtils.getObjFromJSON(val) : {};
		return valObj ? valObj[filter.part] : undefined;
	} else if (filter.operation === 'field' && filter.fieldSchemaName) {
		// Process regular field values
		return record[filter.fieldSchemaName];
	}
	return record;
}

/**
 * Convert the filter's keys to standardized casing
 * @param {object} filter 
 */
function sanitizeFilter(filter) {
	if (!filter) {
		return;
	}

	if (filter.operator) {
		filter.operator = filter.operator.toUpperCase();
	}
	if (filter.operation === 'field' && ['IS', 'ISNOT', 'STARTSWITH', 'ENDSWITH'].includes(filter.operator.toUpperCase())) {
		filter.value = (filter.value !== null && typeof filter.value !== 'undefined') ? ('' + filter.value).toLowerCase() : '';
	}
	if (filter.operation === 'field' && ['CONTAINSALL', 'CONTAINSANY'].includes(filter.operator.toUpperCase())) {
		if(typeof filter.value === 'string') {
			filter.value = (filter.value !== null && typeof filter.value !== 'undefined') ? ('' + filter.value).toLowerCase() : '';
		} else if (filter.value && Array.isArray(filter.value)) {
			filter.value = filter.value.map(value => {
				return value !== null && typeof value !== 'undefined' ? ('' + value).toLowerCase() : '';
			});
		}
	}
}

/**
 * Gets whether this is a field or a part of a 
 * multipart field and returns the fsn and the
 * part schema name (if applicable)
 * 
 * @param {*} tableSchemaName 
 * @param {*} fieldSchemaName 
 */
function getFieldInfo(tableSchemaName, fieldSchemaName) {
	// Skip record is
	if (fieldSchemaName === 'recordIs' || fieldSchemaName === 'recordIsNot') {
		return undefined;
	}

	let field = FieldStore.getByFieldSchemaName(fieldSchemaName, tableSchemaName);
	if(field && field.recordId) {
		return {
			fieldId: field.recordId,
			fieldType: field.fieldType,
			fieldSchemaName: fieldSchemaName
		};
	} else {
		// Okay, we can't find this field. Maybe there's a multipart field with that FSN on the table?
		let candidates = FieldStore.getByTableSchemaName(tableSchemaName);
		let toReturn;
		candidates.find(candidate => {
			if(candidate && candidate.fieldType) {
				let fieldType = FieldTypeStore.get(candidate.fieldType);
				if((fieldType.dataType === 'multipart' || fieldType.dataType === 'file') && fieldType.parts) {
					let part = fieldType.parts.find(part => fieldSchemaName === candidate.fieldSchemaName + '_' + part.partSchemaName);
					if(part) {
						toReturn = {
							fieldId: candidate.recordId,
							fieldType: candidate.fieldType,
							fieldSchemaName: candidate.fieldSchemaName,
							part: part.partSchemaName
						}
						return true;
					}
				}
			}
			return false;
		});
		return toReturn;
	}

	// If we have it
}

/**
 * Evaluates whether a record matches a filter
 * @param {*} filter 
 * @param {*} value 
 */
function evaluateFilter(filter, evaluated, recordSets) {
	if (!filter) {
		return true;
	}

	if (filter.type === 'filter') {
		// We assume that expressions have been processed by this point
		

		let { operation, operator } = filter;
		if (operation === 'recordIs') {
			// Handle record is filters
			if (!filter.value || !evaluated) {
				return false;
			}

			evaluated = convertRecordValue(filter, evaluated);

			let value = recordSets && recordSets[filter.value] ?
				recordSets[filter.value] :
				filter.value;

			if (value && Array.isArray(value)) {
				value = value.map(function (recordVal) {
					if (typeof recordVal === 'object' && recordVal !== null) {
						if (recordVal.recordId) {
							return recordVal.recordId;
						} else {
							return '';
						}
					} else if (!isNaN(recordVal)) {
						return '' + recordVal;
					} else {
						return '';
					}
				});

				return value.includes(evaluated);
			} else {
				return value === evaluated;
			}
		} else if (operation === 'recordIsNot') {
			// Handle record is not filters
			if (!filter.value || !evaluated) {
				return false;
			}

			let value = recordSets && recordSets[filter.value] ?
				recordSets[filter.value] :
				filter.value;

			evaluated = convertRecordValue(filter, evaluated);

			if (value && Array.isArray(value)) {
				value = value.map(function (recordVal) {
					if (typeof recordVal === 'object' && recordVal !== null) {
						if (recordVal.recordId) {
							return recordVal.recordId;
						} else {
							return '';
						}
					} else if (!isNaN(recordVal)) {
						return '' + recordVal;
					} else {
						return '';
					}
				});

				return !value.includes(evaluated);
			} else {
				return value !== evaluated;
			}
		} else if (operation === 'field') {
			operator = operator ? operator.toUpperCase() : '';
			switch (operator) {
				case 'ISCHECKED': {
					// @TODO: Is this how we store these values internally? Check again
					return convertRecordValue(filter, evaluated) === 'true';
				}
				case 'ISNOTCHECKED': {
					// @TODO: Is this how we store these values internally? Check again
					return convertRecordValue(filter, evaluated) !== 'true';
				}
				case 'IS': {
					// Assume that we've already lowercased value for performance
					let convertedValue = ('' + convertRecordValue(filter, evaluated)).toLowerCase();
					return convertedValue === filter.value;
				}
				case 'ISNOT': {
					// Assume that we've already lowercased value for performance
					return ('' + convertRecordValue(filter, evaluated)).toLowerCase() !== filter.value;
				}
				case 'IS_CS': {
					return convertRecordValue(filter, evaluated) === filter.value;
				}
				case 'ISNOT_CS': {
					return convertRecordValue(filter, evaluated) !== filter.value;
				}
				case 'HASVALUE': {
					evaluated = convertRecordValue(filter, evaluated);
					return !!evaluated || evaluated === 0 || evaluated === false;
				}
				case 'HASNOVALUE': {
					evaluated = convertRecordValue(filter, evaluated);
					return !evaluated && evaluated !== 0 && evaluated !== false;
				}
				case 'LESSTHAN': {
					evaluated = convertRecordValue(filter, evaluated);
					return evaluated < filter.value && (evaluated || evaluated === 0);
				}
				case 'LESSTHANOREQUALTO': {
					evaluated = convertRecordValue(filter, evaluated);
					return evaluated <= filter.value && (evaluated || evaluated === 0);
				}
				case 'GREATERTHAN': {
					evaluated = convertRecordValue(filter, evaluated);
					return evaluated > filter.value && (evaluated || evaluated === 0);
				}
				case 'GREATERTHANOREQUALTO': {
					evaluated = convertRecordValue(filter, evaluated);
					return evaluated >= filter.value && (evaluated || evaluated === 0);
				}
				case 'STARTSWITH': {
					// Assume that we've already lowercased value for performance
					evaluated = convertRecordValue(filter, evaluated) || '';
					evaluated = evaluated.toLowerCase();
					let toReturn = evaluated.startsWith(filter.value);
					return toReturn;
				}
				case 'ENDSWITH': {
					return (convertRecordValue(filter, evaluated) + '').endsWith(filter.value);
				}
				case 'CONTAINSALL': {
					let contains = true;
					if (!filter.value || !evaluated) {
						return contains;
					}
					evaluated = ('' + convertRecordValue(filter, evaluated)).toLowerCase();
					if(typeof filter.value === 'string') {
						filter.value = filter.value.split(' ');
					}
					filter.value.forEach(value => {
						value = value.trim();
						contains = contains && (evaluated.includes(value));
					});
					return contains;
				}
				case 'CONTAINSANY': {
					let contains = false;
					// @TODO: Double-check what we do if evaluated is missing in the DB
					if (!filter.value || !evaluated) {
						return contains;
					}
					evaluated = ('' + convertRecordValue(filter, evaluated)).toLowerCase();
					if(typeof filter.value === 'string') {
						filter.value = filter.value.split(' ');
					}
					filter.value.forEach(value => {
						value = value.trim();
						contains = contains || (evaluated.includes(value));
					});
					return contains;
				}
				case 'CONTAINSEXACTPHRASE': {
					return (convertRecordValue(filter, evaluated) + '').toLowerCase().includes(filter.value);
				}
				case 'DOESNOTCONTAIN': {
					return !(convertRecordValue(filter, evaluated) + '').toLowerCase().includes(filter.value);
				}
				default:
					throw new Error('Unsupported operator ' + operator)
			}
		} else {
			throw new Error('Unsupported operation ' + operation);
		}

	} else if (filter.type === 'group' && filter.filters) {
		let logic = ('' + filter.logic).toUpperCase();
		let returnValue = logic === 'AND' ? true : false;
		filter.filters.forEach(filter => {
			sanitizeFilter(filter);
			if(logic === 'AND') {
				returnValue = returnValue && evaluateFilter(filter, evaluated, recordSets);
			} else {
				returnValue = returnValue || evaluateFilter(filter, evaluated, recordSets);
			}
		});
		return returnValue;
	}
}

/**
 * Recurses over filters in order to evaluate any expressions appropriately
 * Returns a Promise which resolves to the new filter value.
 * @param {*object} filter 
 * 
 * @returns {Promise}
 */
function expressionRecursor(filter, extraParams, recordSets, installationId) {
	// If this is a filter operation and it's either a field operation or has a provided recordId
	if (filter.type === 'filter' && (filter.operation === 'field' || filter.context === 'provideId')) {
		let value = filter.value;
		try {
			value = JSON.parse(value);
		} catch (err) {
			value = filter.value;
		}
		if (value && value.expression) {
			// Process expressions
			delete value.xml;
			let expression = '';
			if (extraParams.variables) {
				Object.keys(extraParams.variables).forEach(variable => {
					expression += 'var ' + variable + ' = ' + JSON.stringify(extraParams.variables[variable]) + ';\n';
				});
			}
			expression += value.expression;
			value = callService(installationId, extraParams.renderId,
				'expression-processor-v3',
				{
					expression: expression,
					namedContexts: recordSets,
					browserStorage: extraParams.browserStorage ? extraParams.browserStorage : {},
					apiData: extraParams.apiData ? extraParams.apiData : {},
					runTimeVariables: extraParams.runTimeVariables ? extraParams.runTimeVariables : {},
					recordStoreRecords: extraParams.recordStoreRecords ? extraParams.recordStoreRecords : {}
				});
		} else if (value && ((typeof value.expression !== 'undefined' && value.expression !== null) || value.xml)) {
			// Handles a case we have run into a few times where an expression block will be plugged into a query without generating an expression value
			console.warn('CitDev Query Converter (Spanner) Warning: Missing expression. Proceeding with empty value. Expression information is', JSON.stringify(value));
			value = Promise.resolve({ result: '' });
		} else {
			// Handle other values appropriately
			value = Promise.resolve({ result: value });
		}
		return value.then(function (result) {
			filter.value = result.result;
			return filter;
		});
	} else if (filter.type === 'group') {
		// Recurse over filters within the group
		let filterPromises = filter.filters.map((filter) => {
			return expressionRecursor(filter, extraParams, recordSets, installationId);
		});
		return Promise.all(filterPromises).then(function (results) {
			filter.filters = results;
			return filter;
		});
	} else {
		// Return the filter
		return Promise.resolve(filter);
	}
}

export default {
	processQueryV2,
	callService,
	getUserAgent
};