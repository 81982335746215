import React from 'react';
import AttachmentChildrenTable from './attachment-children-table.react';
import PropTypes from 'prop-types';
import BaseSettingForm from './base-setting-form.react';
import {PageUtils, FieldUtils} from '../../utils';

/**
 * Container for child fields tables - Large, Medium, Small.
 * 
 * @class AttachmentChildrenContainer
 * @extends {Component}
 */
class AttachmentChildrenContainer extends BaseSettingForm  {
	/**
	 * Create instance of AttachmentChildrenContainer
	 *
	 * @memberof AttachmentChildrenContainer
	 */
	constructor(props) {
		super(props);
		this._getValueObj = this._getValueObj.bind(this);
		this._onChildFieldAdd = this._onChildFieldAdd.bind(this);
		this._onChildFieldDelete = this._onChildFieldDelete.bind(this);
		this._onScreenSizeObjectValueChange = this._onScreenSizeObjectValueChange.bind(this);
	}

	/**
	 * Get the value object, regardless of if the actual value passed in is empty or not.
	 * 
	 * @returns {object}
	 * 
	 * @memberof AttachmentChildrenContainer
	 */
	_getValueObj() {
		let valueRaw = this.props.value,
			value = { lg: [], md: 'lg', sm: 'lg', all: [] };
		if (valueRaw) {
			try {
				value = JSON.parse(valueRaw);
				if(!value['lg']) {
					value = { lg: [], md: 'lg', sm: 'lg', all: [] };
				}
			} catch (e) {
				console.warn('Failed to JSON Parse fields container value: ', valueRaw);
			}
		}
		return value;
	}
	/**
	 * Attach a new field to the Parent in Context
	 * 
	 * @param {string} newChildId
	 * @memberof AttachmentChildrenContainer
	 */
	_onChildFieldAdd(newChildId) {
		let { recordId, tableSchemaName } = this.props;
		if(tableSchemaName === 'page'){
			PageUtils.attachChild(recordId, newChildId);
		} else if(tableSchemaName === 'field'){
		  	FieldUtils.attachChild(recordId, newChildId);
		}
	}
	/**
	 * Detach the selected Field from its Parent
	 * 
	 * @param {string} childFieldId - recordId of the Child to detach 
	 * @memberof AttachmentChildrenContainer
	 */
	_onChildFieldDelete(childFieldId) {
		let { recordId, tableSchemaName } = this.props;
		if(tableSchemaName === 'page'){
			PageUtils.detachChild(recordId, childFieldId, false);
		} else if(tableSchemaName === 'field'){
		  	FieldUtils.detachChild(recordId, childFieldId, false);
		}
		
	}

	_onScreenSizeObjectValueChange(event) {
		let screenSize = event.target.screenSize;
		if(screenSize) {
			let value = this.props.value,
				updatedValue = event.target.value,
				valueObj = { lg: [], md: [], sm: [], all: []};
			if(typeof value === 'string') {
				try {
					valueObj = JSON.parse(value);
				} catch (e) {
				}
			}
			let oldValue = valueObj[screenSize];
			if(Array.isArray(updatedValue) && !updatedValue.length) {
				// If our value is an array, but there is nothing in it.. then copy from another array.
				// Priority is the array we WERE managed by.
				if(Array.isArray(valueObj[oldValue])) {
					updatedValue = valueObj[oldValue];
				} else {
					// Otherwise, lets go find an array...
					['lg', 'md', 'sm', 'all'].forEach(loopSS => {
						if(Array.isArray(valueObj[loopSS])) {
							updatedValue = valueObj[loopSS];
						}
					});
				}
			}
			valueObj[screenSize] = updatedValue;
			this.props.onChange({ 
				target: { 
					id: this.props.fieldSchemaName, 
					value: JSON.stringify(valueObj)
				}});
		}
	}


	/**
	 * Render the FieldType Chooser
	 * 
	 * @returns React
	 * 
	 * @memberof AttachmentChildrenContainer
	 */
	render() {
		let value = this._getValueObj(),
		disabledOptionsArray = [];
		//Pass the disabled options for the dropdowns of each ChildField Table as a prop
		disabledOptionsArray = Object.keys(value).filter(screenSizeKey => {
			return !Array.isArray(value[screenSizeKey]);

		});
		return (
			<div>
				<AttachmentChildrenTable childSettings={this.props.childSettings} configurationJson={this.props.configurationJson} 
					onChange={this._onScreenSizeObjectValueChange} 
					onChildFieldAdd={this._onChildFieldAdd} 
					onChildFieldDelete={this._onChildFieldDelete}
					screenSize="lg" value={value['lg']} fieldSchemaName={this.props.fieldSchemaName}
					tableSchemaName={this.props.tableSchemaName} recordId={this.props.recordId} disableScreenSizesArray={disabledOptionsArray}/>
				<AttachmentChildrenTable childSettings={this.props.childSettings} configurationJson={this.props.configurationJson} 
					onChange={this._onScreenSizeObjectValueChange} 
					onChildFieldAdd={this._onChildFieldAdd} 
					onChildFieldDelete={this._onChildFieldDelete}
					screenSize="md" value={value['md']} fieldSchemaName={this.props.fieldSchemaName} 
					tableSchemaName={this.props.tableSchemaName} recordId={this.props.recordId} disableScreenSizesArray={disabledOptionsArray}/>
				<AttachmentChildrenTable childSettings={this.props.childSettings} configurationJson={this.props.configurationJson} 
					onChange={this._onScreenSizeObjectValueChange} 
					onChildFieldAdd={this._onChildFieldAdd} 
					onChildFieldDelete={this._onChildFieldDelete}
					screenSize="sm" value={value['sm']} fieldSchemaName={this.props.fieldSchemaName} 
					tableSchemaName={this.props.tableSchemaName} recordId={this.props.recordId} disableScreenSizesArray={disabledOptionsArray}/>
			</div>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	AttachmentChildrenContainer.propTypes = Object.assign({}, BaseSettingForm.propTypes, {
		childSettings: PropTypes.array,
		value: PropTypes.string,
		onChange: PropTypes.func,
		recordId: PropTypes.string,
		fieldSchemaName: PropTypes.string,
		tableSchemaName: PropTypes.string
	});
}

export default AttachmentChildrenContainer;