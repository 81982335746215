import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Components
import PagesComponent from './presentation/pages.react';
import ThemeComponent from './presentation/theme.react';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';

/**
 * Displays dashboard tabs
 */
class PresentationDashboardTabs extends Component {
	/**
	 * Return the stores we're listening to for changes.
	 *
	 * @return {array}  description
	 */
	static getStores() {
		return [ AdminSettingsStore ];
	}

	/**
	 * Calculate our state anytime a store changes.
	 *
	 * @param  {object} prevState
	 * @return {object}
	 */
	static calculateState(prevState) {
		return {
			'selectedTab' : AdminSettingsStore.getActiveDashboardTab(),
		};
	}

	/**
	 * render the component
	 *
	 * @return {JSX}
	 */
	render() {
		let { 
			onTabSelect,
		} = this.props;
		let { 
			selectedTab,
		} = this.state;

		let activeTabComponent = null;
		switch(selectedTab) {
			default:
			case 'presentation-pages': {
				activeTabComponent = <PagesComponent key="tab-content"/>;
				break;
			}
			case 'presentation-visibility': {
					// Intentionally left blank for now
				break;
			}
			case 'presentation-theme': {
				activeTabComponent = <ThemeComponent key="tab-content" />
			}
		}

		return (
			<div style={{ flex: 1, overflow: 'hidden' }} className="d-flex flex-column">
				<div className="cd-dashboard-tabs presentation" key='tabs'>
					<div className="header-tab-item-container presentation">
						<h5 className={`header-tab-item ${selectedTab === 'presentation-pages' || selectedTab === undefined ? 'selected-tab' : ''}`} onClick={() => onTabSelect('presentation-pages')}>Pages</h5>
						{ /* <a href="#" onClick={() => onTabSelect('presentation-pages')}>Visibility</a> |  */}
						<h5 className={`header-tab-item ${selectedTab === 'presentation-theme' ? 'selected-tab' : ''}`} onClick={() => onTabSelect('presentation-theme')}>Theme</h5>
					</div>
				</div>
				{activeTabComponent}
			</div>
		);
	}
}
const container = Container.create(PresentationDashboardTabs);
export default container;