import React, { Component } from 'react';
import { Container } from 'flux/utils';
import AdminSettingsStore from '../../stores/admin-settings-store';
import AuthenticationStore from '../../stores/authentication-store';
import ContextStore from '../../stores/context-store';
import AdminSettingsActions from '../../actions/admin-settings-actions';
import FieldStore from '../../stores/field-store';
import FieldTypeStore from '../../stores/field-type-store';
import PageStore from '../../stores/page-store';
import TableStore from '../../stores/table-store';
import ToolboxStore from '../../stores/toolbox-store';
import RenderStore from '../../stores/render-store';
import ReactBlocklyComponent from '@dmclain-citizendeveloper/citdev-react-blockly-component';
import FieldActions from '../../actions/field-actions';
import InterfaceActions from '../../actions/interface-actions';
import PageActions from '../../actions/page-actions';
import ObjectUtils from '../../utils/object-utils';
import BlocklyUtils from '../../utils/blockly-utils';
import Switch from '../forms/switch.react';
import LogicFunctionActions from '../../actions/logic-function-actions';

let localStorage = window.localStorage;

/*global Blockly:true*/

class AutomationSetting extends Component {
	constructor(props) {
		super(props);
		this.state = {
			permitSave: true
		};
		this.resizeBlockly = this.resizeBlockly.bind(this);
		this.handleXMLChange = this.handleXMLChange.bind(this);
		this.onSaveHandler = this.onSaveHandler.bind(this);
		this.onResetHandler = this.onResetHandler.bind(this);
		this.copyWorkspace = this.copyWorkspace.bind(this);
		this.pasteWorkspace = this.pasteWorkspace.bind(this);
		this.handleRunOnBackendChange = this.handleRunOnBackendChange.bind(this);
		this.handleMemoryLevelChange = this.handleMemoryLevelChange.bind(this);
		this.saveToStore = this.saveToStore.bind(this);
		this.getComponentCanSave = this.getComponentCanSave.bind(this);
		this.workspaceDidChange = this.workspaceDidChange.bind(this);
	}
	/**
	 * handleXMLChange - Saves XML to store when change has been made in Blockly
	 *
	 * @param  {string} triggerName     name of trigger
	 * @param  {string} blocklyxml  returned data from Blockly
	 */
	handleXMLChange(blocklyxml) {
		// convert XML to JS
		// let blocklyValueObj = BlocklyUtils.parseActionXml(blocklyxml, {
		// 	checkRunOnBackend: true,
		// 	runOnBackend: this.state.value && this.state.value.runOnBackend ? this.state.value.runOnBackend : false,
		// 	defaultToNull: true
		// });

		let blocklyValueObj = (blocklyxml && blocklyxml !== '<xml xmlns="http://www.w3.org/1999/xhtml"></xml>') ? {
			blocklyxml: blocklyxml,
			runOnBackend: (this.props.value && this.props.value.runOnBackend ? true : false) || (blocklyxml && blocklyxml.includes('cd_force_backend="true"')),
			memUse: (this.props.value && this.props.value.memUse ? this.props.value.memUse : 'l')
		} : null;

		this.saveToStore(blocklyValueObj);
	}

	/**
	 * Handles toggling whether the action should run on the backend or not
	 * 
	 * @param {object} event The click event
	 */
	handleRunOnBackendChange(event) {
		
		let newValue = event.target.value;
		let blocklyxml = this.props.value && this.props.value.blocklyxml
		let runOnBackend = newValue || blocklyxml.includes('cd_force_backend="true"');
		let blocklyValueObj = Object.assign({
			blocklyxml: '',
			js: ''
		}, this.props.value);
		blocklyValueObj.runOnBackend = runOnBackend;

		this.saveToStore(blocklyValueObj);
	}

	/**
	 * Handles toggling whether the action is memory-intensive or not
	 * 
	 * @param {object} event The click event
	 */
	handleMemoryLevelChange(event) {
		let memUse = event.target.value ? 'h' : 'l';
		let blocklyValueObj = Object.assign({
			blocklyxml: '',
			js: ''
		}, this.props.value);
		blocklyValueObj.memUse = memUse;

		this.saveToStore(blocklyValueObj);
	}

	/**
	 * Handles appropriately updating the store with the new value information
	 * 
	 * @param {object} blocklyValueObj 
	 */
	saveToStore(blocklyValueObj) {
		let triggerName = this.props.trigger;
		let triggerSettingName = 'automation-' + triggerName;
		// If we have no trigger name yet, skip pushing to store.
		if(!triggerName) {
			console.warn('Missing triggerName; not pushing to store. If your automation seems not to be saving, send this message to the engineers.');
			return;
		}
		switch (this.props.componentType) {
			case 'field':
				let fieldId = this.props.componentId;
				if(this.props.globalTriggers[triggerName]) {
					// Save onClick on the field directly
					FieldActions.pushSettingToStore(fieldId, triggerSettingName, JSON.stringify(blocklyValueObj));
				} else if(this.props.localTriggers[triggerName]) {
					if(this.props.parentComponentType === 'page') {
						PageActions.pushChildConfigurationToStore(this.props.parentComponentId, fieldId, triggerSettingName, blocklyValueObj ? JSON.stringify(blocklyValueObj) : null);
					} else if (this.props.parentComponentType === 'field') { 
						FieldActions.pushChildConfigurationToStore(this.props.parentComponentId, fieldId, triggerSettingName, blocklyValueObj ? JSON.stringify(blocklyValueObj) : null);
					}
				}
				break;
			case 'page':
				if(blocklyValueObj) {
					PageActions.pushAutomationToStore(this.props.componentId, triggerName, blocklyValueObj);
				} else {
					PageActions.pushAutomationToStore(this.props.componentId, triggerName, null);
				}
				break;
			default:
				console.error('Invalid Table Schema Name');
				break;
		}
	}

	/**
	 * Returns the code that needs to be run by the action processor
	 * 
	 * @param {any} workspace 
	 */
	workspaceToCode(workspace) {
		Blockly.JavaScript.init(workspace);
		return Blockly.JavaScript.workspaceToCode(workspace);
	}

	/**
	 * resizeBlockly - Resizes blockly instances to full width by triggering resize() from ref.
	 * A setTimeout of 400 milliseconds is used to allow the CSS transition to complete
	 * Prior to calculation of new width
	 *
	 * @param  {string} (optional) blocklyRefId of ref to Blockly refs. Will pull state value when left undefined
	 */
	resizeBlockly() {
		if (this.blocklyEditor) {
			setTimeout(this.blocklyEditor.resize, 500);
		}
	}

	getComponentCanSave() {
		let permitSave = true;
		if(!this.props.hasRestAPILogic && this.props.value.blocklyxml) {
			// Check to see if our XML contains API logic and make this workspace read-only if so.
			// I think we can leave logic functions which already contain this alone 
			try {
				let workspace = BlocklyUtils.getWorkspaceFromXml(this.props.value.blocklyxml);
				let restApiBlocks = workspace.getBlocksByType('webServices');
				if(restApiBlocks && restApiBlocks.length) {
					permitSave = false;
				}
			} catch(err) {
				console.error('Error getting workspace information.', err);
				permitSave = false;
			}
		}
		return permitSave;
	}

	/**
	 * Calling resizeBlockly during this lifecycle phase to ensure
	 * Blockly resizes when user closes and reopens right panel with same
	 * Blockly component displaying
	 */
	componentDidUpdate(prevProps, prevState) {
		// Resize blockly
		// @TODO: Check if we need to resize in more limited circumstances
		this.resizeBlockly();

		// If our initialXml has changed, then we need to reset our workspace
		if(
			this.blocklyEditor &&
			(
				(!this.props.value && prevProps && prevProps.value) ||
				(prevProps && prevProps.value && this.props.value && prevProps.value.blocklyxml !== this.props.value.blocklyxml)
			)
		) {
			this.blocklyEditor.reset();

			let permitSave = this.getComponentCanSave();
			if(permitSave !== !!prevState.permitSave) {
				setTimeout(() => {
					this.setState({permitSave});
				}, 0);
			}
		}
	}

	/**
	 * Calling resizeBlockly during this lifecycle phase to ensure
	 * Blockly resizes when opens right panel for the first time
	 */
	componentDidMount() {
		this.resizeBlockly();
	}
	/**
	 * Update the JS in the store when the component is unmounted.
	 */
	componentWillUnmount() {
		// let blocklyxml = this.state && this.state.value && this.state.value.blocklyxml ? this.state.value.blocklyxml : '';

		// let blocklyValueObj = BlocklyUtils.parseActionXml(blocklyxml, {
		// 	checkRunOnBackend: true,
		// 	runOnBackend: this.state.value && this.state.value.runOnBackend ? this.state.value.runOnBackend : false,
		// 	defaultToNull: true,
		// 	memUse: this.state.value && this.state.value.memUse ? this.state.value.memUse : 'l',
		// 	includeJs: true
		// });

		if(this.blocklyEditor && this.blocklyEditor.workspace && this.blocklyEditor.workspace.workspace) {
			let blocklyValueObj = BlocklyUtils.getWorkspaceInfo(this.blocklyEditor.workspace.workspace, {
				checkRunOnBackend: true,
				runOnBackend: this.props.value && this.props.value.runOnBackend ? this.props.value.runOnBackend : false,
				defaultToNull: true,
				memUse: this.props.value && this.props.value.memUse ? this.props.value.memUse : 'l',
				includeJs: true
			});
			this.saveToStore(blocklyValueObj);
		} else {
			console.warn('Missing value. this.blocklyEditor is', this.blocklyEditor);
		}
	}

	/**
	 * onSaveHandler - Triggers to send Blockly data in store to database via API
	 */
	onSaveHandler() {
		// Display notification to user
		// let saveNotif = InterfaceActions.stickyNotification({ 'level': 'info', 'message': 'Saving logic...' });

		
		if(this.blocklyEditor && this.blocklyEditor.workspace && this.blocklyEditor.workspace.workspace) {
			let workspace = this.blocklyEditor.workspace.workspace;
			workspace.saving = true;
			setTimeout(() => {
				
				// Handle converting logicFunctionsUsed into an array
				// Regenerate the JS
				let params = {
					checkRunOnBackend: true,
					runOnBackend: this.props.value && this.props.value.runOnBackend ? this.props.value.runOnBackend : false,
					defaultToNull: true,
					memUse: this.props.value && this.props.value.memUse ? this.props.value.memUse : 'l',
					includeJs: true
				};
	
				
				BlocklyUtils.saveAutomationFromWorkspaceV2(workspace, params, (blocklyValueObj) => {
					let savePromises = [];
					this.saveToStore(blocklyValueObj);
					// Save Parent
					if (AdminSettingsStore.getParentTableSchemaName() === 'field') {
						// let parentSaveNotif = InterfaceActions.stickyNotification({ 'level': 'info', 'message': 'Saving local logic...' });
						savePromises.push(FieldActions.pushToDatabasePromise(FieldStore.get(AdminSettingsStore.getParentRecordId(), true))
							.catch(error => {
								console.error('Error saving logic for Field Parent', AdminSettingsStore.getParentRecordId(), error);
								InterfaceActions.notification({ 'level': 'error', 'message': 'Error saving local field logic.  Try again.' });
							}));
					} else if (AdminSettingsStore.getParentTableSchemaName() === 'page') {
						// let parentSaveNotif = InterfaceActions.stickyNotification({ 'level': 'info', 'message': 'Saving local logic...' });
						let toSave = PageStore.get(AdminSettingsStore.getParentRecordId(), true);
						savePromises.push(PageActions.pushToDatabasePromise(toSave)
							.catch(error => {
								console.error('Error saving local logic for Page Parent', AdminSettingsStore.getParentRecordId(), error);
								InterfaceActions.notification({ 'level': 'error', 'message': 'Error saving local page logic.  Try again.' });
							}));
					}
			
					// Save Child
					if (AdminSettingsStore.getTableSchemaName() === 'field') {
						let toSave = FieldStore.get(AdminSettingsStore.getRecordId(), true);
						savePromises.push(FieldActions.pushToDatabasePromise(toSave)
							.catch(error => {
								console.error('Error saving logic for Field', AdminSettingsStore.getRecordId(), error);
								InterfaceActions.notification({ 'level': 'error', 'message': 'Error saving logic.  Try again.' });
							}));
					} else if (AdminSettingsStore.getTableSchemaName() === 'page') {
						let toSave = PageStore.get(AdminSettingsStore.getRecordId(), true);
						savePromises.push(PageActions.pushToDatabasePromise(toSave)
							.catch(error => {
								console.error('Error saving local logic for Page Parent', AdminSettingsStore.getRecordId(), error);
								InterfaceActions.notification({ 'level': 'error', 'message': 'Error saving local page logic.  Try again.' });
							}));
					}
	
					return Promise.all(savePromises);
				}).catch(console.error);
			}, 1000);
		}

	}

	/**
	 * onResetHandler - Calls API to retrieve data to reset value in store
	 */
	onResetHandler() {

		// Reset all of the logic functions used to the value from the database
		// (Yes, this may mean undoing a logic function change if changed in another trigger but not saved. That's the way it's going to have to be. Don't do that.)
		let logicFunctionIds = this.props.value.logicFunctionsUsed;
		
		// Handle converting logicFunctionsUsed into an array
		let logicFunctionIdsArr =[]; 
		if (logicFunctionIds) {
			if(Array.isArray(logicFunctionIds)) {
				logicFunctionIdsArr = logicFunctionIds;
			} else {
				logicFunctionIdsArr = logicFunctionIds.split(',');
			}
		}
		if (logicFunctionIdsArr && logicFunctionIdsArr.length) {
			InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting all functions used within this trigger to their saved values...' });
			logicFunctionIdsArr.forEach(logicFunctionId => {
				LogicFunctionActions.pullFromDatabase(logicFunctionId);
			});
		}

		if (this.props.componentType === 'field') {
			InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting Logic...' });
			FieldActions.pullFromDatabase(this.props.componentId);
		} else if (this.props.componentType === 'page') {
			InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting Logic...' });
			PageActions.pullFromDatabase(this.props.componentId);
		}

		if (this.props.parentComponentType === 'field') {
			FieldActions.pullFromDatabase(this.props.parentComponentId);
		} else if (this.props.parentComponentType === 'page') {
			PageActions.pullFromDatabase(this.props.parentComponentId);
		}

		if(this.blocklyEditor) {
			this.blocklyEditor.reset();
		}
	}
	workspaceDidChange() {
		// We are not actually doing work on change, but
		// we do want to force the value to be marked as dirty once
		// we start messing around
		// This will be automatically cleaned on save or component unmount
		if(this.props.value && !this.props.value.forceDirty) {
			let newValue = Object.assign({
				forceDirty: true
			}, this.props.value);
			this.saveToStore(newValue);
		}
		if(!this.props.hasRestAPILogic) {
			let workspace = this.blocklyEditor.workspace.workspace;
			let restApiBlocks = workspace.getBlocksByType('webServices');
			let permitSave = !(restApiBlocks && restApiBlocks.length);
			if(!!this.state.permitSave !== permitSave) {
				this.setState({permitSave});
			}
		}
	}
	/**
	 * Copies blocks to citdev clipboard
	 */
	copyWorkspace(){
		try {
			let blocklyValueObj = BlocklyUtils.getWorkspaceInfo(this.blocklyEditor.workspace.workspace, {
				defaultToNull: true,
				includeJs: false // We don't need to bother with the JS for this
			});
			localStorage.logicClipboard = JSON.stringify(blocklyValueObj);
			InterfaceActions.notification({ 'level': 'success', 'message': 'Copying logic to clipboard...' });
		} catch(err) {
			console.error('Error when copying logic: ', err);
			InterfaceActions.notification({ 'level': 'error', 'message': 'Error when copying logic!' });
		}
	}
	/**
	 * Pastes blocks from citdev clipboard to workspace; appends to, not overrides, blocks
	 */
	pasteWorkspace() {
		let value = localStorage.logicClipboard;
		try{
			let valueObj = ObjectUtils.getObjFromJSON(value);
			
			let pastedblocklyxml = valueObj.blocklyxml;
			BlocklyUtils.appendToWorkspace(pastedblocklyxml, this.blocklyEditor.workspace.workspace);
			InterfaceActions.notification({ 'level': 'success', 'message': 'Pasting new logic below existing logic. Please check your new blocks to make sure they don\'t overlap!' });
			// let oldblocklyxml = this.state.value ? this.state.value.blocklyxml : '';
			
			
			// // Pass the pasted value into the utility function to combine them into one workspace XML string
			// let newxml = BlocklyUtils.combineWorkspaces(pastedblocklyxml, oldblocklyxml);

			// // Make sure that our new code still compiles; the catch will catch it if it's invalid XML
			// if(Blockly.Xml.textToDom(newxml)){
			// 	this.handleXMLChange(newxml);
			// 	InterfaceActions.notification({ 'level': 'success', 'message': 'Pasting new logic below existing logic. Please check your new blocks to make sure they don\'t overlap!' });
			// }
		} catch(err) {
			InterfaceActions.notification({ 'level': 'error', 'message': 'Attempted to paste invalid value into workspace.' });
			console.warn('Attempted to paste with invalid data in clipboard. Value was', value);
			console.warn('Error was', err);
		}
	}
	/**
	 * render
	 *
	 * @return {JSX}
	 */
	render() {
		let { trigger, runTimeVariables, settingsHidden } = this.props;

		if (!trigger) {
			return <div className="select-setting">
				<div className="select-setting-text-wrap">
					Select a Logic Event to configure on the left.
				</div>
			</div>
		}

		const workspaceConfiguration = {
			grid: {
				spacing: 20,
				length: 3,
				colour: '#ccc',
				snap: true
			},
			zoom: {
				controls: true,
				wheel: true,
				startScale: 0.9,
				maxScale: 3,
				minScale: 0.3,
				scaleSpeed: 1.2
			}
		};

		let permitSave = this.state.permitSave;

		let header = null;
		if (this.props.globalTriggers[trigger]) {
			let headerControls = (settingsHidden
				? [
					<div key='backend' className="trigger-setting d-flex">
						<label>
							<h4 className="d-flex flex-column align-items-center justify-content-between mr-3" title="Controls whether or not to run this action on the backend. This is good for long-running processes or vital processes which may be interrupted by the user navigating away from the page. Some action blocks are backend-only actions which will force this setting to be set.">Run on Backend
							<Switch
								onChange={this.handleRunOnBackendChange}
								value={this.props.value && this.props.value.runOnBackend ? true : false}
								id={'toggle-' + this.props.renderId}
								describedBy={'label-' + this.props.renderId}
								fieldSchemaName={'toggle-' + this.props.renderId}
							/>
							</h4>
						</label>
					</div>,
					<div key='highmem' className="trigger-setting d-flex">
						<label>
							<h4 className="d-flex flex-column align-items-center" title="Controls whether or not this is marked as a high-memory action. High memory actions may include complex imports or actions which run over large record sets. For best performance, we recommend starting with lower memory requirements and increasing the memory requirements ONLY if your action experiences memory issues.">Process with high memory
							<Switch
								onChange={this.handleMemoryLevelChange}
								value={this.props.value && this.props.value.memUse === 'h' ? true : false}
								id={'highmemtoggle-' + this.props.renderId}
								describedBy={'highmemlabel-' + this.props.renderId}
								fieldSchemaName={'highmemtoggle-' + this.props.renderId}
							/>
							</h4>
						</label>
					</div>]
				: null );
			header = (<h2 className="d-flex w-100 justify-content-between align-items-center py-3">
				<div className='d-flex align-items-center justify-content-between mr-2' style={{ flex: 1 }}>
					<div className='d-flex align-items-center'>
						{/* Show collapse only when setting has been selected */} 
						{settingsHidden ?
							<button 
								className="btn btn-back pl-0"
								title="Triggers" 
								form="appearance-form" 
								onClick={() => { AdminSettingsActions.onSettingsListHideChange(false); }}>
								<img height="26" width="26" src={ContextStore.getUrlMedia() + "/expand-settings-list.svg"} alt="" />
							</button>
						: null }
						<h3>{this.props.triggerFriendlyNames[trigger]}</h3> 
						{ /*<small className="setting-subheader"> (reflect this value for all instances)</small> */}
					</div>
					{headerControls}
					<div className="btn-wrapper d-flex align-items-center justify-content-around">
						<button 
							key="copy" 
							className="btn btn-secondary btn-flex" 
							form="appearance-form" 
							aria-label="Copy"
							onClick={this.copyWorkspace}>
							Copy
						</button>
						<button 
							key="paste" 
							className="btn btn-secondary btn-flex ml-2" 
							form="appearance-form" 
							aria-label="Paste"
							onClick={this.pasteWorkspace}>
							Paste
						</button>
						<button 
							key="reset" 
							className="btn btn-warning btn-flex mx-2" 
							form="appearance-form" 
							aria-label="Reset" 
							onClick={this.onResetHandler}>
							Reset
						</button>
						<button 
							key="submit" 
							className="btn btn-primary btn-flex" 
							form="appearance-form" 
							aria-label="Save"
							disabled={!permitSave}
							onClick={this.onSaveHandler}>
							Save
						</button>
					</div>
				</div>
			</h2>);
		} else if (this.props.localTriggers[trigger]) {
			let headerControls = (settingsHidden
				? [
					<div className="trigger-setting d-flex" key="backEnd">
						<label>
							<h4 className="d-flex flex-column align-items-center justify-content-between mr-3" title="Controls whether or not to run this action on the backend. This is good for long-running processes or vital processes which may be interrupted by the user navigating away from the page. Some action blocks are backend-only actions which will force this setting to be set.">Run on Backend
							<Switch
								onChange={this.handleRunOnBackendChange}
								value={this.props.value && this.props.value.runOnBackend ? true : false}
								id={'toggle-' + this.props.renderId}
								describedBy={'label-' + this.props.renderId}
								fieldSchemaName={'toggle-' + this.props.renderId}
							/>
							</h4>
						</label>
					</div>,
					<div className="trigger-setting d-flex"  key="highMem">
						<label>
							<h4 className="d-flex flex-column align-items-center" title="Controls whether or not this is marked as a high-memory action. High memory actions may include complex imports or actions which run over large record sets. For best performance, we recommend starting with lower memory requirements and increasing the memory requirements ONLY if your action experiences memory issues.">Process with high memory
							<Switch
								onChange={this.handleMemoryLevelChange}
								value={this.props.value && this.props.value.memUse === 'h' ? true : false}
								id={'highmemtoggle-' + this.props.renderId}
								describedBy={'highmemlabel-' + this.props.renderId}
								fieldSchemaName={'highmemtoggle-' + this.props.renderId}
							/>
							</h4>
						</label>
					</div>]
				: null );
				header = (<h2 className="d-flex w-100 justify-content-between align-items-center py-3">
				<div className='d-flex align-items-center justify-content-between mr-2' style={{ flex: 1 }}>
					<div className='d-flex align-items-center'>
						{/* Show collapse only when setting has been selected */} 
						{settingsHidden ?
							<button 
								className="btn btn-back pl-0" 
								title="Triggers"
								form="appearance-form" 
								onClick={() => { AdminSettingsActions.onSettingsListHideChange(false); }}>
								<img height="26" width="26" src={ContextStore.getUrlMedia() + "/expand-settings-list.svg"} alt="" />
							</button>
						: null }
						<h3>{this.props.triggerFriendlyNames[trigger]}</h3> 
						{/*<small className="setting-subheader"> (reflect this value only for this instance)</small> */}
					</div>
					{headerControls}
					<div className="btn-wrapper">
						<button 
							key="copy" 
							className="btn btn-secondary" 
							form="appearance-form" 
							aria-label="Copy"
							onClick={this.copyWorkspace}>
							Copy
						</button>
						<button 
							key="paste" 
							className="btn btn-secondary ml-2" 
							form="appearance-form" 
							aria-label="Paste"
							onClick={this.pasteWorkspace}>
							Paste
						</button>
						<button 
							key="reset" 
							className="btn btn-warning mx-2"
							form="appearance-form" 
							aria-label="Reset" 
							onClick={this.onResetHandler}>
							Reset
						</button>
						<button 
							key="submit" 
							className="btn btn-primary" 
							form="appearance-form" 
							aria-label="Save"
							disabled={!permitSave}
							onClick={this.onSaveHandler}>
							Save
						</button>
					</div>
				</div>
			</h2>);
		}
		
		let actionToolbox = {};
		if (trigger === 'prePageSave' || trigger === 'preFieldSave' || trigger === 'validate' || trigger === 'onEnterUp') {
			actionToolbox = JSON.parse(JSON.stringify(this.props.actionToolbox));
			let validationDrawer = actionToolbox.find(drawer => {
				return drawer.name === 'Navigation';
			});
			if(validationDrawer && validationDrawer.blocks) {
				validationDrawer.blocks.push({type: 'validationFailed'});  // @TODO : Set this one from FT BUILDER
			}
		} else {
			actionToolbox = this.props.actionToolbox;
		}
		// loop through automation to create tabs and tabs
		return (
			<div className={`automation-setting-container pt-0 cd-bg-3 pr-3 ${settingsHidden ? 'pl-3' : ''}`}>
				{header}
				<ReactBlocklyComponent.BlocklyEditor
					ref={(blocklyEditor) => { this.blocklyEditor = blocklyEditor; }}
					className="react-blockly-component"
					workspaceConfiguration={workspaceConfiguration}
					toolboxCategories={actionToolbox}
					initialXml={this.props.value.blocklyxml}
					wrapperDivClassName="automation-settings--blockly"
					// xmlDidChange={this.handleXMLChange}
					namedContexts={this.props.namedContexts}
					triggerName={this.props.trigger}
					runTimeVariables={runTimeVariables}
					workspaceDidChange={this.workspaceDidChange}
				/>
			</div>
		);
	};
}

class AutomationSettingWrapper extends Component {

	static getStores() {
		return [AdminSettingsStore, FieldStore, PageStore, AuthenticationStore];
	}

	static calculateState(prevState, props) {
		let componentId = AdminSettingsStore.getRecordId();
		let componentType = AdminSettingsStore.getTableSchemaName();
		let parentComponentId = AdminSettingsStore.getParentRecordId();
		let parentComponentType = AdminSettingsStore.getParentTableSchemaName();
		let trigger = AdminSettingsStore.getSettingSchemaName();
		let triggerSettingName = 'automation-' + trigger; // The actual name of the saved automation setting
		let triggerAutomationObj = null;
		let renderId = AdminSettingsStore.getRenderId();
		let renderObj = RenderStore.get(renderId);
		let pageRenderObj = RenderStore.getPageRenderObj(renderId);
		let pageTSN = pageRenderObj ? pageRenderObj.dataTableSchemaName : '';
		let startingTSN;
		let hasRestAPILogic = AuthenticationStore.getHasRestAPILogic();

		// These don't change once the store is populated, but we can't calculate them as consts at the top due to requirements
		let triggerFriendlyNames = prevState && prevState.triggerFriendlyNames ?
			prevState.triggerFriendlyNames :
			undefined;
		let localTriggers = prevState && prevState.localTriggers ?
			prevState.localTriggers :
			undefined;
		let globalTriggers = prevState && prevState.globalTriggers ?
			prevState.globalTriggers :
			undefined;
		let rtVarLookup = prevState && prevState.rtVarLookup ? prevState.rtVarLookup : undefined;

		if(FieldTypeStore.allPulledFromDatabase() && (!triggerFriendlyNames || !globalTriggers || !localTriggers)) {
			// If something is missing, calculate
			rtVarLookup = rtVarLookup || {};
			triggerFriendlyNames = {
				onBlur: 'Lose Focus',
				onBlurChange: 'Lose Focus and Change',
				onClick: 'Click',
				onFocus: 'Gain Focus',
				onEnterUp: 'On Enter Up', // @TODO : Set this one from FT BUILDER
				onMouseOut: 'Mouse Out',
				onMouseOver: 'Mouse Over',
				validate: 'Validate (Local)', // @TODO : Set this one from FT BUILDER
				preFieldSave: 'Validate (Global)',
				onPageLoad: 'After Page Loads',
				prePageSave: 'Page Validates',
				postPageSave: 'After Page Saves',
			};

			localTriggers = {
				onBlur: true,
				onBlurChange: true,
				onFocus: true,
				onMouseOut: true,
				onMouseOver: true,
				validate: true,
			};

			globalTriggers = {
				onClick: true,
				prePageSave: true,
				postPageSave: true,
				onPageLoad: true,
				preFieldSave: true,
				onEnterUp: true
			};

			FieldTypeStore.getAllArray().forEach(ft => {
				if(ft.customActionTriggers && ft.customActionTriggers !== '[]') {
					let customActionTriggers = ObjectUtils.getObjFromJSON(ft.customActionTriggers);
					customActionTriggers.forEach(customTrigger => {
						let codeName = customTrigger.codeName;
						let friendlyName =  customTrigger.friendlyName + '  (Global)';
						triggerFriendlyNames[codeName] = friendlyName;
						//Add them to Global Triggers 
						globalTriggers[codeName] = true;
						// Add them to the rtVarLookup
						let triggerCategory = customTrigger.runTimeVariableCategory; 
						let customRunTimeVariables = customTrigger.runTimeVariables ?  customTrigger.runTimeVariables : [];
						if(customRunTimeVariables && customRunTimeVariables.length) {
							rtVarLookup[ft.recordId] = {
								[triggerCategory]: []
							};
							customRunTimeVariables.forEach(RTVar => {
								rtVarLookup[ft.recordId][triggerCategory].push({
									'key': RTVar.value,
									'label': RTVar.name
								});
							});
						}
					});
				}
			});
		}
		
		//RunTime Variables 
		// @TODO: Can/should we pull this from the previous state in some fashion?
		let runTimeVariables = props.runTimeVariables ? 
			typeof props.runTimeVariables === 'string' ?
				JSON.parse(props.runTimeVariables) :
				props.runTimeVariables 
			: {}; //TODO verify this is not a string, else parse it

		if(componentType === 'field' && trigger) {
			let field = FieldStore.get(componentId);
			// This should be pulled from the data TSN of the current render first and then changed only if that doesn't exist
			// This way, it will always match the currentRecord
			if(renderObj && renderObj.dataTableSchemaName) {
				startingTSN = renderObj.dataTableSchemaName;
			} else if(AdminSettingsStore && FieldStore && AdminSettingsStore.getTableSchemaName() === 'field'){
				startingTSN = field ? field.tableSchemaName : pageTSN;
			}
			
			if(field && field[triggerSettingName]) {
				triggerAutomationObj = ObjectUtils.getObjFromJSON(field[triggerSettingName]);
			}
			let fieldType = field ? field.fieldType : null;
			if(field && fieldType && rtVarLookup[fieldType]) {
				// We don't want to mutate the props directly, but we also don't want a new one every time
				// Determine when this is actually appropriate
				runTimeVariables = Object.assign({}, rtVarLookup[fieldType]);
			}
		} else if (trigger) {
			triggerAutomationObj =  PageStore.getAutomation(componentId, trigger);
			startingTSN = pageTSN;
		}

		//Add the global runtime variables for pages and fields
		if (componentType === 'field' || componentType === 'page') {
			runTimeVariables.TargetUrl = [
				{'key': 'href', 'label' : 'Full URL'},
				{'key': 'protocol', 'label' : 'Protocol'},
				{'key': 'hostname', 'label' : 'Hostname'},
				{'key': 'port', 'label' : 'Port'},
				{'key': 'path', 'label' : 'Path'},
				{'key': 'query', 'label' : 'Query'}
			];
		}

		// Now get the pageTSN and startingTSN proper names for use in the labels
		let startingTSNObj = TableStore.getByTableSchemaName(startingTSN);
		let pageTSNObj = TableStore.getByTableSchemaName(pageTSN);

		if (!triggerAutomationObj && parentComponentId && parentComponentType) {

			if(parentComponentType === 'field') {
				let triggerAutomationJSON = FieldStore.getChildConfigurations(parentComponentId, componentId, triggerSettingName);
				triggerAutomationObj = triggerAutomationJSON ? ObjectUtils.getObjFromJSON(triggerAutomationJSON) : triggerAutomationObj;
			} else if (parentComponentType === 'page') {
				let triggerAutomationJSON = PageStore.getChildConfigurations(parentComponentId, componentId, triggerSettingName);
				triggerAutomationObj = triggerAutomationJSON ? ObjectUtils.getObjFromJSON(triggerAutomationJSON) : triggerAutomationObj;
			}
		}

		// Named Contexts (e.g. record sets)
		let namedContexts = {
			pagePageRecord: ['Page, Page Record' + (pageTSN ? ' (' + (pageTSNObj ? pageTSNObj.pluralName || pageTSNObj.singularName || pageTSN : pageTSN)  + ')' : ''), 'namedContexts["pagePageRecord"]', pageTSN],
			pageCurrentRecord: ['Page, Current Record' + (startingTSN ? ' (' + (startingTSNObj ? startingTSNObj.pluralName || startingTSNObj.singularName || startingTSN : startingTSN)  + ')' : ''), 'namedContexts["pageCurrentRecord"]', startingTSN]
		};

		// @TODO: Is this _really_ the best way to do tis?
		//Stringify runTimeVariables
		runTimeVariables = JSON.stringify(runTimeVariables);

		// @TODO: Do we need anything else?
		return {
			componentId,
			componentType,
			parentComponentId,
			parentComponentType,
			trigger,
			runTimeVariables,
			rtVarLookup,
			value: triggerAutomationObj ? triggerAutomationObj : {},
			actionToolbox: ToolboxStore.getActionToolboxJS(),
			namedContexts: (trigger !== 'onPageLoad' && trigger !== 'postPageSave') ? JSON.stringify(namedContexts) : '{}',
			triggerFriendlyNames,
			localTriggers,
			globalTriggers,
			hasRestAPILogic,
			settingsHidden: AdminSettingsStore.getSettingsListHidden()
		};
	}

	render() {
		if(!this.state.trigger) {
			return (<div className="select-setting">
				<div className="select-setting-text-wrap">
					Select a Logic Trigger to configure on the left.
				</div>
			</div>);
		}
		let componentProps = Object.assign({}, this.props, this.state);
		return <AutomationSetting key={this.state.trigger} {...componentProps} />
	}
}

const container = Container.create(AutomationSettingWrapper, {withProps: true});
export default container;