/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container} from 'flux/utils';
import { ReactSVG } from 'react-svg';

// Actions
import AdminSettingsActions from '../../actions/admin-settings-actions';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import FieldSettingsStore from '../../stores/field-settings-store';
import RenderStore from '../../stores/render-store';
import ContextStore from '../../stores/context-store';

// Utils
import ObjectUtils from '../../utils/object-utils';
import UIUtils from '../../utils/ui-utils';

import { FieldStore, PageStore } from '../../stores';

/**
 * Container to VisibilityPin
 *
 * @class VisibilityPin
 * @extends {Component}
 */
class VisibilityPin extends Component {
	constructor(props) {
		super(props);
		this.link = null;
		this.onPinClickHandler = this.onPinClickHandler.bind(this);
		this.onPinDoubleClickHandler = this.onPinDoubleClickHandler.bind(this);
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf VisibilityPin
	 */
	static getStores() {
		return [AdminSettingsStore, FieldSettingsStore, RenderStore];
	}

	/**
	 * Determine if this pin is Disabled.
	 * @param {string} renderId Render ID for the component
	 * @param {string} subSettingIndex SubSetting if the renderId is not provided
	 * @return boolean
	 */
	static calculateIsDisabled(renderId, subSettingIndex) {
		if(renderId) {
			let renderObj = RenderStore.get(renderId);

			// If we dont have a renderObj (yet), then we can't calculate.
			if(!renderObj) { 
				return undefined;
			}
	
			if(renderObj.componentType === 'field') {
				return false;
			}
		} else if(typeof subSettingIndex === 'number') {
			return false;
		}

		// Pages don't have visibility, so it'll always be disabled.
		return true;
	}

	/**
	 * Determine if this pin is Used.
	 * @param {string} renderId Render ID for the component
	 * @param {string} subSettingIndex SubSetting if the renderId is not provided
	 * @param {string} subSettingSchemaName Schema Name of the setting that contains the sub setting config
	 * @return boolean
	 */
	static calculateIsUnused(renderId, subSettingIndex, subSettingSchemaName) {
		let renderObj = RenderStore.get(renderId);
		// If we dont have a renderObj (yet), then we can't calculate.
		if(!renderObj) { 
			return undefined;
		}
		
		if(renderObj.componentType === 'field') {
			// Get our parent
			let parentRenderObj = RenderStore.get(renderObj.renderParentId);
			if(parentRenderObj) {
				let fieldSettingsObj = renderObj.attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(renderObj.attachmentId, renderObj.componentId, parentRenderObj.componentId) : FieldSettingsStore.getSettings(renderObj.componentId, parentRenderObj.componentId);
				// If we have custom visibility logic...
				let parentObj = parentRenderObj.componentType === 'field' ? FieldStore.get(parentRenderObj.componentId) : PageStore.get(parentRenderObj.componentId);
				let visibilityKey = subSettingSchemaName ? [renderObj.attachmentId, subSettingSchemaName, subSettingIndex, 'visibility'].join('-') : (renderObj.attachmentId + '-visibility');
				if(parentObj && parentObj[visibilityKey]) {
					return false;
				}

				// If we have a subSettingIndex and are the parent...
				if(typeof subSettingIndex === 'number' && fieldSettingsObj && fieldSettingsObj[subSettingSchemaName]) {
					let subSettingConfigs = ObjectUtils.getObjFromJSON(fieldSettingsObj[subSettingSchemaName]);
					let subSettingConfig  = subSettingConfigs[subSettingIndex];
					if(subSettingConfig && subSettingConfig.visibility) {
						let visRules = subSettingConfig.visibility;
						if(visRules && visRules.length) {
							return false;
						}
					}

					// Also check on custom tab visibility logic
					if(subSettingConfig && subSettingConfig.attachmentId && fieldSettingsObj[subSettingConfig.attachmentId + '-' + subSettingSchemaName + '-' + subSettingIndex + '-visibility']) {
						return false;
					}
					
				} else {
					if (fieldSettingsObj && (fieldSettingsObj.visibility && 
						(
							(typeof fieldSettingsObj.visibility === 'string' && fieldSettingsObj.visibility !== '{}') ||
							(Array.isArray(fieldSettingsObj.visibility) && fieldSettingsObj.visibility.length) ||
							(typeof fieldSettingsObj.visibility === 'object' && Object.keys(fieldSettingsObj.visibility).length)
						))
					) {
						return false;
					}
				}
			} else {
				return undefined;
			}
		}
		return true;
	}

	/**
	 * Returns the current State of the VisibilityPin Settings
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf VisibilityPin
	 */
	static calculateState(prevState, props) {
		let overlayActive = AdminSettingsStore.getIsOverlayActive('visibility');
		let isDisabled = undefined;
		let isUnused = undefined;

		if(overlayActive) {
			// If we already have an isDisabled in the state, then use it...
			isDisabled = ( prevState && typeof prevState.isDisabled === 'boolean' 
				? prevState.isDisabled 
				: this.calculateIsDisabled(props.renderId, props.subSettingIndex, props.subSettingSchemaName));
			
			if (isDisabled === false) {
				isUnused = this.calculateIsUnused(props.renderId, props.subSettingIndex, props.subSettingSchemaName);
			}
		}

		return {
			// Only calculate this once...
			'isDisabled': isDisabled,
			'isUnused': isUnused,
			'overlayActive': overlayActive,
		}
	}

	componentDidMount() {
		this.clickTimeouts = [];
	}

	/**
	 * onPinClickHandler - Handles pin click. 
	 */
	onPinClickHandler(event) {
		// Stop clicking "through" the pin, like on List column headers
		event.preventDefault();
		event.stopPropagation();

		// Open the settings preview
		// (targetOverlay, 
		//	recordId, tableSchemaName, 
		//	parentRecordId, parentTableSchemaName, 
		//	mainTab, subSettingSchemaName, subSettingIndex, renderId, 
		// 	attachmentId)
		this.clickTimeouts.push(setTimeout(() => {
			UIUtils.openSettingsPreview('visibility', 
				this.props.recordId, this.props.tableSchemaName, 
				this.props.parentRecordId, this.props.parentTableSchemaName, 
				'settings', this.props.subSettingSchemaName, this.props.subSettingIndex, this.props.renderId, 
				this.props.attachmentId);
			// Time you have between clicks to determine if it was single or double.
		}, 250));
	}

	/**
	 * onPinDoubleClickHandler - Handles pin double click. 
	 */
	onPinDoubleClickHandler(event) {
		this.clickTimeouts.forEach(timeout => {
			clearTimeout(timeout);
		});

		// Stop clicking "through" the pin, like on List column headers
		event.preventDefault();
		event.stopPropagation();

		// Open the settings panel on the right.
		// (targetOverlay, 
		//	recordId, tableSchemaName, 
		//	parentRecordId, parentTableSchemaName, 
		//	mainTab, subSettingSchemaName, subSettingIndex, renderId, 
		// 	attachmentId)
		UIUtils.openSettingsPanel('visibility', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 
			'settings', this.props.subSettingSchemaName, this.props.subSettingIndex, this.props.renderId,
			this.props.attachmentId);

		// Make sure the settings are toggled to visible.
		AdminSettingsActions.onSettingsListHideChange(false);
	}

	render() {
		let { overlayActive, isUnused, isDisabled } = this.state;
		let pinDivClassAppend = this.props.className + ' ' + (isDisabled 
			? 'disabled' 
			: (isUnused 
				? 'unused' 
				: ''));

		if(this.props.subSettingIndex !== AdminSettingsStore.getSubSettingIndex()) {
			pinDivClassAppend += ' de-select';
		}

		if( overlayActive ) {
			return <div 
						key={this.props.attachmentId || this.props.recordId}
						className={"pin " + pinDivClassAppend}
						ref={el => this.link = el}
						data-trigger="manual"
						data-toggle="popover"
						data-fallback-placement="flip"
						data-html="true"
						data-content=" "
						onClick={(event) => { if(!isDisabled) { this.onPinClickHandler(event) }}}
						onDoubleClick={(event) => { this.onPinDoubleClickHandler(event); }}
					>
							<svg className="visibility-pin pin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
								<path d="M12 2C8.1 2 5 5.1 5 9c0 5.2 7 13 7 13s7-7.8 7-13C19 5.1 15.9 2 12 2z" fill="#BBB"/>
							</svg>
							<ReactSVG
								beforeInjection={svg => {
									svg.setAttribute('viewBox', '0 0 30 30');
									svg.setAttribute('style', 'width: 16px; height: 16px');
								}}
								className="visibility-pin pin-icon"
								src={ContextStore.getUrlMedia() + "/icon-visibility.svg"} />
					</div>
		} else {
			return null;
		}
	}
}

if ('development' === process.env.NODE_ENV) {
  VisibilityPin.propTypes = {
	className: PropTypes.string,
	controlsAddAndEdit: PropTypes.bool,
    parentRecordId: PropTypes.string,
    parentTableSchemaName: PropTypes.string,
	recordId: PropTypes.string.isRequired,
	renderId: PropTypes.string.isRequired,
	subSettingIndex: PropTypes.number,
	subSettingSchemaName: PropTypes.string,
    tableSchemaName: PropTypes.string.isRequired,
  };
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(VisibilityPin, {withProps: true});
export default container;