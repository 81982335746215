import React from 'react';
import _ from 'lodash';
import BaseSettingForm from './base-setting-form.react';
require ('../../styles/components/_switch.scss');
/**
 * Component for Name
 * 
 * @class Switch
 * @extends {Component}
 */
export default class Switch extends BaseSettingForm {
	/**
	 * Constructor for Swithc instance
	 * 
	 * @returns React
	 * 
	 * @memberof Switch
	 */
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
	}

	/**
	 * Handler for on change event for the checkbox input
	 *
	 * @memberof Switch
	 */
	onChange(event) {
		if(!this.props.disabled) {
			let {onChange} = this.props,
				{target: {checked}} = event,
				changeEvent = {
					target:{
						id: event.target.id,
						value: checked
					}
				};
			if (onChange) {
				onChange(changeEvent);
			}
		}
	}

	/**
 	 * private Handles onKeyPress
 	 *
 	 * @memberof Query
 	 */
	onKeyPress(event){
		if(event.charCode === 32 || event.charCode === 13) {
			event.preventDefault();
			let {onChange, value} = this.props,
				checked = !value,
				changeEvent = {
					target:{
						id: event.target.id,
						value: checked
					}
				};
			if (onChange) {
				onChange(changeEvent);
			}
		}
	}

	/**
	 * Render the Switch
	 * 
	 * @returns React
	 * 
	 * @memberof Switch
	 */
	render() {
		let checkProps = {};
		let checked = !!this.props.value;
		if (checked) {
			checkProps['checked'] = true;
		}

		return(
			<label className="switch">
				<input id={this.props.id} type="checkbox" {...checkProps} onChange={this.onChange}/>
				<div className="slider" role='checkbox' aria-describedby={this.props.describedBy} aria-checked={checked}  tabIndex='0' onKeyPress={this.onKeyPress}/>
			</label>
		);
	}
}

