import TableUtils from './table-utils';
import FieldUtils from './field-utils';
import PageUtils from './page-utils';
import pluralize from 'pluralize';
import uuid from 'uuid';
import TableActions from '../actions/table-actions';
import TableStore from '../stores/table-store';
import FieldActions from '../actions/field-actions';
import FieldStore from '../stores/field-store';
import FieldSettingsStore from '../stores/field-settings-store';
import PageActions from '../actions/page-actions';
import PageStore from '../stores/page-store';
import ContextStore from '../stores/context-store';
import AuthenticationStore from '../stores/authentication-store';
import InterfaceActions from '../actions/interface-actions';
import AssistantAutomation from './assistant-automation';
import AssistantPages from './assistant-pages';
import socketFetcher from './socket-fetcher';
import PatternStore from '../stores/pattern-store';
import ObjectUtils from './object-utils';

export default {

	/**
	 * Creates table
	 * 
	 * @param {object} searchResult Object with parameters
	 * @param {boolean} searchResult.methodInfo.withCRUD Whether or not to create CRUD interfaces with the table
	 * @param {string} searchResult.methodInfo.NLPInput The input for the method
	 */
	processCreate(searchResult) {

		let tableCount = TableStore.getAllArray().length;
		let maxTableCount = AuthenticationStore.getMaxTables();
		if(maxTableCount && tableCount >= maxTableCount) {
			InterfaceActions.notification({'message': 'Table creation failed: you are over your maximum table count.', 'level': 'error'});
			return Promise.reject('Max table count exceeded.');
		}

		let method = searchResult.methodInfo.method;
		let patternId = searchResult && searchResult.methodInfo && searchResult.methodInfo.patternId ? searchResult.methodInfo.patternId : 'patternlessTableCreate';
		let withCRUD = searchResult.methodInfo.CRUD === 'CRUD';

		if(withCRUD) {
			// Check the page count.
			let pageCount = PageStore.getAllArray().length;
			let maxPageCount = AuthenticationStore.getMaxPages();
			if(maxPageCount && pageCount > maxPageCount - 3) {
				InterfaceActions.notification({'message': 'Page creation failed: this pattern would take you over your maximum page count.', 'level': 'error'});
				return Promise.reject('Max page count exceeded.');
			}
		}

		let NLPInput = searchResult.methodInfo.NLPInput;
		let fields = [];
		let recordId = uuid.v4();
		let newTableObj = Object.assign({
			recordId
		}, _getTableInfo(NLPInput));

		// If this is pattern-based, some table information should be obtained from the pattern
		if (method === 'pattern') {
			let patternObj = PatternStore.get(patternId) || {};
			let tableSettingsJSON = patternObj.tableSettings;
			let tableSettings = {};
			try {
				tableSettings = tableSettingsJSON ? JSON.parse(tableSettingsJSON) : {};
				newTableObj.roles = tableSettings.roles;
				newTableObj.icon = tableSettings.icon;
				fields = tableSettings.fields;
			} catch (err) {
				console.warn('Error parsing tableSettingsJSON for pattern %s. tableSettings value was', patternId, tableSettings);
			}
		} else if (withCRUD) {
			newTableObj.roles = 'primaryTable';
		}

		let { tableSchemaName, singularName } = newTableObj;

		let accountedForSchemaNames = [];

		// Create a name field by default if no other fields are provided.
		fields = fields && fields.length ? fields.map(field => {
			let settingsObj = field.settings;
			field.recordId = uuid.v4();
			field.fieldSchemaName = FieldUtils.validateFieldSchemaName(tableSchemaName + (settingsObj.fieldLabel && settingsObj.fieldLabel.value ? settingsObj.fieldLabel.value : ''), tableSchemaName, null, null, accountedForSchemaNames).validSchemaName;
			accountedForSchemaNames.push(field.fieldSchemaName);
			field.tableSchemaName = tableSchemaName;
			return field;
		}) : [
				{
					recordId: uuid.v4(),
					fieldSchemaName: tableSchemaName + 'Name',
					tableSchemaName: tableSchemaName,
					fieldType: 'd965b6d9-8dd0-440c-a31c-f40bf72accea',
					settings: {
						fieldLabel: {
							action: 'setToPattern',
							fieldSchemaName: 'fieldLabel',
							value: singularName + ' Name',
							chanceOfChange: 'low'
						},
						requiredForSave: {
							action: 'setToPattern',
							fieldSchemaName: 'requiredForSave',
							value: 'yes',
							chanceOfChange: 'low'
						},
						roles: {
							action: 'setToPattern',
							fieldSchemaName: 'roles',
							value: 'name,recordDetails',
							chanceOfChange: 'low'
						}
					}
				},
			];

		TableActions.pushToStore(recordId, newTableObj);

		let notifications = {};
		notifications.creationNotification = InterfaceActions.stickyNotification({ 'message': 'Creating table ' + newTableObj.singularName + '. This may take a few seconds; please wait...', 'level': 'info' });

		searchResult.currentTableInfo = {
			tableSchemaName: newTableObj.tableSchemaName,
			recordId: newTableObj.recordId
		};

		//Update the actual database with these settings and return it
		searchResult.currentComponentInfo = {
			componentType: 'table',
			componentSubtype: 'table',
			componentId: recordId
		};

		let generateSampleRecord = true;
		return TableActions.pushToDatabasePromise(TableStore.get(recordId))
			.then(() => {
				InterfaceActions.clearStickyNotification(notifications.creationNotification);
				return this.createTableExtras(newTableObj, fields, notifications, generateSampleRecord, withCRUD, patternId);
			}).then(() => {
				return searchResult;
			});
	},

	/**
	 * Helper method to create the extras for a table
	 * @param {object} newTableObj Object with the table's information. Corresponds to the tableObj in the store
	 * @param {array} fields Array of fields to add. If none are provided, will create a name field by default.
	 * @param {object} notifications Object whose values are sticky notifications. Generated if not provided.
	 * @param {boolean} generateSampleRecord Whether or not to generate a sample record
	 * @param {boolean} generateInterfaces Whether or not to generate CRUD interface pages
	 * @param {string} patternId Optional: the pattern ID to attribute any fields with pattern values to.
	 * @returns 
	 */
	createTableExtras(newTableObj, fields, notifications, generateSampleRecord, generateInterfaces, patternId) {
		let { tableSchemaName } = newTableObj;

		notifications = notifications || {};

		return new Promise((resolve, reject) => {
			this.createTableFields(newTableObj, fields)
				.then(() => {
					InterfaceActions.clearStickyNotification(notifications.fieldNotification);
					notifications.refreshNotification = InterfaceActions.stickyNotification({ 'message': 'Field creation complete! Updating permissions for ' + newTableObj.singularName + ' table...', 'level': 'info' });
					// We don't add any parameters of our own to this call, but need to support the automatic appending of session
					return this.refreshPermissions();
				})
				.then((itWorked) => {
					InterfaceActions.clearStickyNotification(notifications.refreshNotification);
					if(!itWorked) {
						InterfaceActions.notification({'message': 'Skipping generating sample record...', 'level': 'warning'});
						generateSampleRecord = false;
					} else {
						let fieldIdForSample = FieldUtils.getFieldIdByRole(tableSchemaName, 'name');
						let fieldForSample = FieldStore.get(fieldIdForSample) || {};
						return this.generateSampleRecord(newTableObj, [{
							fieldId: fieldIdForSample,
							fieldSchemaName: fieldForSample.fieldSchemaName,
							fieldType: fieldForSample.fieldType,
							value: 'Sample Record'
						}]);
					}
				})
				.then(() => {
					if (!generateInterfaces) {
						return;
					} else {
						return this.addAdvancedTableInterfaces(newTableObj);
					}
				})
				.then(resolve)
				.catch(reject);
		});
	},

	/**
	 * Function which creates all advanced CRUD interface pages and fields for a table
	 * 
	 * @param {object} newTableObj Object with table information
	 * 
	 * @returns {Promise}
	 */
	addAdvancedTableInterfaces(newTableObj) {
		let { tableSchemaName, singularName, pluralName, icon } = newTableObj;
		let notifications = {};
		// To be processed in order, as the search page requires the view/edit and add pages
		let patternsForCRUD = [
			// View and Edit Page
			'd2e39185-616b-4954-b55f-3acf5c378bb0',
			// Add Page
			'0f565b5f-8efb-4461-a396-519e68c32e4d',
			// Search Page
			'398dc71a-e20f-4904-a1c9-a1977b1dde83'
		];

		let searchResultTemplate = {
			currentTableInfo: {
				tableSchemaName
			},
			singularName,
			pluralName,
			icon
		};

		let pageCreationPatterns = patternsForCRUD.reduce((promise, patternId) => {
			return promise.then((searchResult) => {
				searchResult = searchResult ? searchResult : searchResultTemplate;
				return AssistantPages.processCreate(Object.assign({}, searchResultTemplate));
			}).then((searchResult) => {
				return AssistantPages.processPattern(Object.assign({}, searchResult, {
					methodInfo: {
						patternId
					}
				}));
			}).catch(console.error);
		}, new Promise((resolve, reject) => {
			notifications.pageCreationNotification = InterfaceActions.stickyNotification({ 'message': 'Creating CRUD interfaces for table ' + newTableObj.singularName + '. This may take a few seconds; please wait...', 'level': 'info' });
			return resolve();
		}));

		let searchPageId = '';
		return pageCreationPatterns
			.then(searchResult => {
				// Update the add page to close the dialog and refresh the List on the Search page instead
				// (Necessary to circumvent a chicken/egg problem; may be solved better at a later point)
				let addPageId = PageUtils.getPageIdByRole(tableSchemaName, 'add');
				searchPageId = PageUtils.getPageIdByRole(tableSchemaName, 'search');
				let addPageObj = PageStore.get(addPageId) || {};
				let searchPageObj = PageStore.get(searchPageId) || {};
				let list = findListOnPage(searchPageObj);

				addPageObj['automation-postPageSave'] = AssistantAutomation.getAutomation('closeAndRefreshField', {
					xmlKey: 'blocklyxml',
					jsKey: 'js'
				}, {
						field: list
					});

				PageActions.pushToStore(addPageId, addPageObj);
				return Promise.all([PageActions.pushToDatabasePromise(PageStore.get(addPageId)), _addPageToPrimaryNav(newTableObj, searchPageId)]).then(() => {
					return searchResult;
				});
			})
			.then((searchResult) => {
				InterfaceActions.clearStickyNotification(notifications.pageCreationNotification);
				InterfaceActions.notification({ 'message': 'Finished creating CRUD pages on table!', 'level': 'success' });
				if (searchPageId) {
					InterfaceActions.notification({ 'message': 'Opening search page...', 'level': 'success' });
					InterfaceActions.replacePage({
						pageId: searchPageId,
						tableSchemaName,
						uiTreatment: 'replacePage'
					});
					// UIUtils.openSettingsPanel('appearance', searchPageId, 'page');
				}
				return searchResult;
			});
	},

	/**
	 * Adds only a basic search page for a table
	 * @param {object} newTableObj Object with table information
	 * @returns 
	 */
	addBasicSearchPage(newTableObj) {
		// Basic search page
		let patternId = 'a72fb370-5396-4c10-8f46-1742dad2d378';

		// Faux searchResult info for pattern processor
		let { tableSchemaName, singularName, pluralName, icon } = newTableObj;

		let searchResult = {
			currentTableInfo: {
				tableSchemaName
			},
			currentComponentInfo: {
				componentId: uuid.v4(),
				componentType: 'page',
				componentSubtype: 'page'
			},
			methodInfo: {
				patternId
			},
			singularName,
			pluralName,
			icon
		};
		
			return AssistantPages.processCreate(Object.assign({}, searchResult))
				.then((newSearchResult) => {
					searchResult = newSearchResult ? newSearchResult : searchResult;
					return AssistantPages.processPattern(Object.assign({}, searchResult));
				})
			.then(() => {
				let searchPageId = PageUtils.getPageIdByRole(tableSchemaName, 'search');
				if (searchPageId) {
					InterfaceActions.notification({ 'message': 'Opening search page...', 'level': 'success' });
					InterfaceActions.replacePage({
						pageId: searchPageId,
						tableSchemaName,
						uiTreatment: 'replacePage'
					});
					// UIUtils.openSettingsPanel('appearance', searchPageId, 'page');
				}
			})
			.catch(console.error);
	},

	/**
	 * Creates fields on a new table.
	 * If fields are not provided, will create a name field by default.
	 * 
	 * @param {object} newTableObj Object with table information
	 * @param {array} fields Array of field objects
	 * @returns 
	 */
	createTableFields(newTableObj, fields) {
		let { tableSchemaName, singularName } = newTableObj;


		// Create a name field by default if no other fields are provided.

		let defaultFsn = tableSchemaName + 'Name';
		let fsn = defaultFsn;
		let count = 2;
		let field = FieldStore.getByFieldSchemaName(fsn, tableSchemaName);
		while(field && field.recordId) {
			fsn = defaultFsn + count;
			count += 1;
			field = FieldStore.getByFieldSchemaName(fsn, tableSchemaName);
		}

		fields = fields ? fields :  [
			{
				recordId: uuid.v4(),
				fieldSchemaName: fsn,
				tableSchemaName: tableSchemaName,
				fieldType: 'd965b6d9-8dd0-440c-a31c-f40bf72accea',
				settings: {
					fieldLabel: {
						fieldSchemaName: 'fieldLabel',
						value: singularName + ' Name',
						chanceOfChange: 'low'
					},
					requiredForSave: {
						fieldSchemaName: 'requiredForSave',
						value: 'yes',
						chanceOfChange: 'low'
					},
					roles: {
						fieldSchemaName: 'roles',
						value: 'name,recordDetails',
						chanceOfChange: 'low'
					}
				}
			},
		];


		// If an empty array was passed, deliberately skip creating any fields
		if(!fields.length) {
			return Promise.resolve(fields);
		}

		let creationMessage = InterfaceActions.stickyNotification({
			'message': 'Creating fields for table '
				+ newTableObj.singularName
				+ '. This may take a few seconds; please wait...', 'level': 'info'
			}
		);
		let fieldPromises = fields.map(fieldObj => {
			let { recordId, fieldSchemaName, tableSchemaName, fieldType } = fieldObj;
			let fullSettingsObj = fieldObj.settings;
			let field = {
				recordId,
				fieldSchemaName,
				tableSchemaName,
				fieldType
			};
			FieldActions.pushToStore(field.recordId, field);

			Object.keys(fullSettingsObj).forEach(settingSchemaName => {
				let settingObj = fullSettingsObj[settingSchemaName];
				let { action, value, chanceOfChange, fieldSchemaName, patternId } = settingObj;
				value = value && value.replace ?
					// Replace shortcut values in value
					value
						.replace(/<<TableSingularName>>/g, newTableObj.singularName)
						.replace(/<<TablePluralName>>/g, newTableObj.pluralName)
						.replace(/<<TableSchemaName>>/g, newTableObj.schemaName) :
					value;
				FieldActions.pushSettingToStore(recordId, fieldSchemaName, value);
				if (action === 'setToPattern') {
					// Update the history
					FieldActions.appendSettingHistory(recordId, settingSchemaName, value,
						AuthenticationStore.getUserId(), AuthenticationStore.getUsername(), patternId, chanceOfChange);
				}
			});
			// Since these are new fields, we don't need to bother with cleanliness checks
			return FieldActions.pushToDatabasePromise(FieldStore.get(field.recordId));
		});

		return Promise.all(fieldPromises)
			.then(() => {
				InterfaceActions.clearStickyNotification(creationMessage);
				return fields;
			})
	},

	/**
	 * Creates sample data for a table
	 * 
	 * @param {object} newTableObj Table object with tableSchemaName and singularName from the table in it.
	 * @param {array} fields Array of fields into which to put data.
	 */
	generateSampleRecord(newTableObj, fields) {
		let {tableSchemaName, singularName} = newTableObj;
		let returnObj;
		return new Promise((resolve) => {
			let sampleRecordNotification = InterfaceActions.stickyNotification({'message': 'Generating sample ' + singularName + ' record. This may take a few seconds; please wait...', 'level': 'info'});
			let contextObj = ContextStore.getState();
			let bodyData = JSON.stringify({
				currentContextObj: contextObj,
				tableSchemaName: tableSchemaName,
				recordId: uuid.v4(),
				installationId: contextObj.get('installationId'),
				fields: fields
			});
	
			socketFetcher('gw/recordAdd-v4', bodyData).then(function (data) {
				if (data.responseCode === 200) {
					let recordIds = data.response && data.response[tableSchemaName] ? Object.keys(data.response[tableSchemaName]) : null;
					let recordId = recordIds && recordIds[0] ? recordIds[0] : null;
					returnObj = {};
					let fieldsObj = {};
					fields.forEach(field => {
						fieldsObj[field.fieldSchemaName] = field.value;
					});
					returnObj[recordId] = fieldsObj;
				} else {
					throw new Error(data.response);
				}
			})
			.catch(function (error) {
				InterfaceActions.notification({ 'message': 'Unable to generate sample record for table ' + singularName + '. You must log out and in again to create or delete records on this table. Check your console for more information.', 'level': 'warning' });
				console.error('Error generating sample record: ', error);
			})
			.then(() => {
				InterfaceActions.clearStickyNotification(sampleRecordNotification);
				resolve(returnObj);
			});
	
		});
	},

	/**
	 * Deletes the given table
	 * 
	 * @param {object} searchResult Object with parameters
	 * @param {string} searchResult.currentComponentInfo.componentId - The ID of the table to delete
	 * @param {string} searchResult.componentInfo.componentName - The display name of the table being deleted
	 * @param {string} searchResult.currentTableInfo.tableSchemaName - The schema name of the table being deleted.
	 */
	processDelete(searchResult) {
		let tableSchemaName = searchResult.currentTableInfo.tableSchemaName;
		return TableUtils.deleteTableWithDependencies(tableSchemaName);
	},

	/**
	 * Hits the refresh permissions endpoint to update the services.
	 * @returns Promise<resolves(Boolean)>
	 */
	refreshPermissions() {
		return new Promise((resolve, reject) => {
			socketFetcher('gw/refresh-permissions-v1', '{}')
				.then((data) => {
					if(data.responseCode !== 200) {
						InterfaceActions.notification({ 'message': 'Unable to update permissions. You must log out and in again to create or delete records on this table. Check your console for more information.', 'level': 'warning' });
						console.warn('Issue updating permissions', data.response);
						return resolve(false);
					} else {
						return resolve(true);
					}
				})
				.catch(reject);
		})
	}
}

/**
 * Takes in the name of a table and attempts to get a singular name, plural name,
 * schema name and a random icon.
 * 
 * @param {string} word 
 */
function _getTableInfo(word) {
	let singular = word ? pluralize.singular(word) : '(What do you want to track?)';
	let plural = word ? pluralize.plural(word) : '(What do you want to track?)';
	let validatedTSN = TableUtils.validateTableSchemaName(word ? plural : 'record', '') || {};
	let tableSchemaName = validatedTSN.validSchemaName || plural;
	const iconList = [
		'microchip', 'user-o', 'bank', 'briefcase', 'car', 'calendar', 'cogs', 'coffee', 'cog', 'credit-card', 'cube', 'envelope-o',
		'film', 'fire', 'gift', 'group', 'globe', 'laptop', 'map-o', 'plane', 'rocket', 'ship', 'sitemap', 'snowflake-o', 'soccer-ball-o',
		'space-shuttle', 'sticky-note-o', 'tags', 'tag', 'television', 'ticket', 'tint', 'tree', 'trophy', 'user-secret'
	];
	let randMult = Math.random();
	return {
		singularName: _capitalize(singular),
		pluralName: _capitalize(plural),
		tableSchemaName,
		icon: iconList[Math.round(randMult * iconList.length)]
	};
}

/**
 * Capitalizes a word
 * 
 * @param {string} word 
 */
function _capitalize(word) {
	if (!word) {
		return '';
	}

	// Capitalize the first letter of each word
	return word
		.toLowerCase()
		.split(' ')
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' ');
}

/**
 * Deprecated: Function which creates all CRUD interface pages and fields for a table
 * 
 * @param {object} newTableObj Object with table information
 * 
 * @returns {Promise}
 */
function _deprecatedAddTableCRUD(newTableObj) {
	let { tableSchemaName, singularName, pluralName, icon } = newTableObj;

	/*
			A note regarding this entire section: I debated for some time as to whether it was best to
			manually encode some of the more complex information (for example, the attachedFields) within this
			or to create the fields and pages and then use one of the various helper functions to attach the fields to the page.
			I ultimately decided on manual encoding, for the following reasons:

			1. In lieu of any real in-depth automation or query building functionality, I had to manually encode a large portion of these fields as well.
			2. Using the automatic attachment functionality would require multiple calls to the database. While the main
			slowdown in this functionality is due to having to create field(s) which store data, multiple calls to the database to update the
			same field are inefficient.
			3. This pairs well with choosing IDs for all of the fields and pages at the beginning,
			which simplifies automation and the general interrelationship of the various fields and pages.

			Consequently, I went with this design, even though it does require a lot of 'hardcoding'. Perhaps this will be updated
			at some point as more helper functions emerge, but for now, I think it will be sufficiently robust for our purposes.
			*/
	let recordIds = {
		addPageId: uuid.v4(),
		viewPageId: uuid.v4(),
		searchPageId: uuid.v4(),
		nameFieldId: uuid.v4(),
		nameAttachmentId: uuid.v4(),
		containerFieldId: uuid.v4(),
		containerAttachmentId: uuid.v4(),
		addLinkId: uuid.v4(),
		addLinkAttachmentId: uuid.v4(),
		deleteLinkId: uuid.v4(),
		deleteLinkAttachmentId: uuid.v4(),
		viewLinkId: uuid.v4(),
		viewLinkAttachmentId: uuid.v4(),
		searchListId: uuid.v4(),
		searchListAttachmentId: uuid.v4(),
	};
	let returnNode = uuid.v4();
	let pages = [
		// Add Page
		{
			recordId: recordIds.addPageId,
			availableModes: 'add',
			name: 'Add ' + singularName,
			roles: 'add',
			saveControlPlacement: 'both',
			attachedFields: JSON.stringify([
				{
					attachmentId: recordIds.nameAttachmentId,
					recordIds: [recordIds.nameFieldId]
				}
			]),
			fieldPosition: JSON.stringify({
				lg: [{
					h: 7,
					i: recordIds.nameAttachmentId,
					w: 6,
					x: 0,
					y: 0
				}],
				md: [{
					h: 1,
					i: recordIds.nameAttachmentId,
					w: 6,
					x: 0,
					y: 1
				}],
				sm: [{
					h: 1,
					i: recordIds.nameAttachmentId,
					w: 6,
					x: 0,
					y: 1
				}]
			}),
			tableSchemaName,
			'automation-postPageSave': AssistantAutomation.getAutomation('openPage',
				{
					xmlKey: 'blocklyxml',
					jsKey: 'js'
				},
				{
					tableSchemaName,
					pageId: recordIds.viewPageId,
					context: 'namedContexts["startingContext"]'
				})
		},
		// View/edit Page
		{
			recordId: recordIds.viewPageId,
			availableModes: 'edit,view',
			name: 'Edit ' + singularName,
			roles: 'viewandedit',
			saveControlPlacement: 'both',
			tableSchemaName,
			attachedFields: JSON.stringify([
				{
					attachmentId: recordIds.nameAttachmentId,
					recordIds: [recordIds.nameFieldId]
				}
			]),
			fieldPosition: JSON.stringify({
				lg: [{
					h: 7,
					i: recordIds.nameAttachmentId,
					w: 6,
					x: 0,
					y: 0
				}],
				md: [{
					h: 1,
					i: recordIds.nameAttachmentId,
					w: 6,
					x: 0,
					y: 1
				}],
				sm: [{
					h: 1,
					i: recordIds.nameAttachmentId,
					w: 6,
					x: 0,
					y: 1
				}]
			})
		},
		// Search Page
		{
			recordId: recordIds.searchPageId,
			availableModes: 'view',
			name: 'Search ' + pluralName,
			roles: 'search,admin',
			tableSchemaName,
			attachedFields: JSON.stringify([
				{
					attachmentId: recordIds.containerAttachmentId,
					recordIds: [recordIds.containerFieldId]
				}
			]),
			fieldPosition: JSON.stringify({
				lg: [{
					h: 63,
					i: recordIds.containerAttachmentId,
					w: 12,
					x: 0,
					y: 0,
					moved: false,
					static: false
				}],
				md: [{
					h: 1,
					i: recordIds.containerAttachmentId,
					w: 6,
					x: 0,
					y: 0
				}],
				sm: [{
					h: 1,
					i: recordIds.containerAttachmentId,
					w: 6,
					x: 0,
					y: 0
				}]
			})
		}
	];
	let pagePromises = pages.map(page => {
		PageActions.pushToStore(page.recordId, page);
		return PageActions.pushToDatabasePromise(PageStore.get(page.recordId));
	});
	let fields = [
		// Name field
		{
			recordId: recordIds.nameFieldId,
			fieldSchemaName: singularName.toLowerCase() + 'Name',
			tableSchemaName: tableSchemaName,
			fieldType: 'd965b6d9-8dd0-440c-a31c-f40bf72accea',
			settings: JSON.stringify({
				fieldLabel: singularName + ' Name',
				requiredForSave: 'yes'
			})
		},
		// Date Created field
		/*{
			recordId: uuid.v4(),
			fieldSchemaName: singularName.toLowerCase() + 'DateCreated',
			tableSchemaName: tableSchemaName,
			fieldType: '2b3b1810-1cfb-4de6-a8a6-f41305efc102',
			settings: JSON.stringify({
				fieldLabel: 'Date Created',
				viewVariant: 'dateTimeView',
				editVariant: 'dateTimeEdit'
			})
		},*/
		// Container field
		{
			recordId: recordIds.containerFieldId,
			fieldSchemaName: singularName.toLowerCase() + 'Container',
			tableSchemaName: tableSchemaName,
			fieldType: '7ebd9251-675c-4129-95e3-6b8e31c135a2',
			settings: JSON.stringify({
				fieldLabel: 'List of ' + pluralName,
				labelPosition: 'hidden',
				attachedFields: JSON.stringify([
					{
						attachmentId: recordIds.addLinkAttachmentId,
						recordIds: [recordIds.addLinkId]
					},
					{
						attachmentId: recordIds.searchListAttachmentId,
						recordIds: [recordIds.searchListId]
					},
					{
						attachmentId: recordIds.deleteLinkAttachmentId,
						recordIds: [recordIds.deleteLinkId]
					}
				]),
				fieldPosition: JSON.stringify({
					lg: [
						{
							w: 3,
							h: 4,
							x: 0,
							y: 0,
							i: recordIds.addLinkAttachmentId,
							moved: false,
							static: false
						},
						{
							w: 12,
							h: 52,
							x: 0,
							y: 5,
							i: recordIds.searchListAttachmentId,
							moved: false,
							static: false
						},
						{
							w: 3,
							h: 4,
							x: 0,
							y: 56,
							i: recordIds.deleteLinkAttachmentId,
							moved: false,
							static: false
						}
					]
				})
			}),
		},
		// Add a [table name] link
		{
			recordId: recordIds.addLinkId,
			fieldSchemaName: singularName.toLowerCase() + 'Add',
			tableSchemaName: tableSchemaName,
			fieldType: '4fb3fe77-76ea-4327-85dd-5da41d59c403',
			settings: JSON.stringify({
				fieldLabel: 'Add ' + singularName,
				labelPosition: 'hidden',
				viewVariant: 'buttonView',
				editVariant: 'buttonEdit',
				linkText: JSON.stringify(AssistantAutomation.getAutomation('linkText',
					{
						xmlKey: 'workspaceXML',
						jsKey: 'generatedJavascript'
					}, {
						expressionText: 'Add ' + singularName
					})),
				'automation-onClick': JSON.stringify(AssistantAutomation.getAutomation('openPage',
					{
						xmlKey: 'blocklyxml',
						jsKey: 'js'
					},
					{
						tableSchemaName,
						pageId: recordIds.addPageId,
						context: '[{"recordId": "", "tableSchemaName": ""}]'
					}))
			})
		},
		// View a [table name] link
		{
			recordId: recordIds.viewLinkId,
			fieldSchemaName: singularName.toLowerCase() + 'View',
			tableSchemaName: tableSchemaName,
			fieldType: '4fb3fe77-76ea-4327-85dd-5da41d59c403',
			settings: JSON.stringify({
				fieldLabel: 'View ' + singularName,
				labelPosition: 'hidden',
				viewVariant: 'linkView',
				editVariant: 'linkEdit',
				linkText: JSON.stringify(AssistantAutomation.getAutomation('expressionValueFromField',
					{
						xmlKey: 'workspaceXML',
						jsKey: 'generatedJavascript'
					}, {
						expressionText: 'View ' + singularName
					})),
				'automation-onClick': JSON.stringify(AssistantAutomation.getAutomation('openPage',
					{
						xmlKey: 'blocklyxml',
						jsKey: 'js'
					},
					{
						tableSchemaName,
						pageId: recordIds.viewPageId,
						context: 'namedContexts["startingContext"]'
					}))
				// No automation-onClick here because we don't know the page info yet.
			})
		},
		// Delete [table name] link
		{
			recordId: recordIds.deleteLinkId,
			fieldSchemaName: singularName.toLowerCase() + 'Delete',
			tableSchemaName: tableSchemaName,
			fieldType: '4fb3fe77-76ea-4327-85dd-5da41d59c403',
			settings: JSON.stringify({
				fieldLabel: 'Delete ' + singularName,
				labelPosition: 'hidden',
				viewVariant: 'buttonView',
				editVariant: 'buttonEdit',
				linkText: JSON.stringify(AssistantAutomation.getAutomation('linkText',
					{
						xmlKey: 'workspaceXML',
						jsKey: 'generatedJavascript'
					}, {
						expressionText: 'Delete ' + singularName
					})),
				'automation-onClick': JSON.stringify(AssistantAutomation.getAutomation('deleteRecord',
					{
						xmlKey: 'blocklyxml',
						jsKey: 'js'
					},
					{
						tableSchemaName,
						listField: recordIds.searchListId
					}))
			})
		},
		// Search [table name] list
		{
			recordId: recordIds.searchListId,
			fieldSchemaName: singularName.toLowerCase() + 'List',
			tableSchemaName: tableSchemaName,
			fieldType: '9b782b83-4962-4bd6-993c-f72096e02610',
			settings: JSON.stringify({
				fieldLabel: 'List of ' + pluralName,
				labelPosition: 'top',
				rowSelection: 'multiple',
				query: JSON.stringify({
					queryId: uuid.v4(),
					filters: [],
					returnNode: returnNode,
					nodes: [
						{
							displayName: pluralName,
							icon: icon,
							nodeId: returnNode,
							pluralName,
							singularName,
							tableSchemaName
						}
					]
				}),
				attachedFields: JSON.stringify([
					{
						attachmentId: recordIds.viewLinkAttachmentId,
						recordIds: [recordIds.viewLinkId]
					},
					{
						attachmentId: recordIds.nameAttachmentId,
						recordIds: [recordIds.nameFieldId]
					}
				])
			})
		}
	];
	let notifications = {};
	let fieldPromises = fields.map(field => {
		FieldActions.pushToStore(field.recordId, field);
		return FieldActions.pushToDatabasePromise(FieldStore.get(field.recordId));
	});
	notifications.pageNotification = InterfaceActions.stickyNotification({ 'message': 'Creating pages for table ' + singularName + '. This may take a few seconds; please wait...', 'level': 'info' });
	return Promise.all(pagePromises).then(pageObjects => {
		InterfaceActions.clearStickyNotification(notifications.pageNotification);
		notifications.fieldNotification = InterfaceActions.stickyNotification({ 'message': 'Done creating pages. Now Creating fields for table ' + singularName + '. This may take a few seconds; please wait...', 'level': 'info' });
		return Promise.all(fieldPromises)
	}).then(fieldObjects => {
		// let [nameField, containerField, addLink, viewLink, searchList] = fieldObjects;
		InterfaceActions.clearStickyNotification(notifications.fieldNotification);
		InterfaceActions.notification({ 'message': 'Finished creating fields on table!', 'level': 'success' });
		notifications.sampleRecordNotification = InterfaceActions.stickyNotification({ 'message': 'Generating sample record. This may take a few seconds; please wait...', 'level': 'info' });
		return this.generateSampleRecord(tableSchemaName, [{
			fieldId: recordIds.nameFieldId,
			fieldSchemaName: singularName.toLowerCase() + 'Name',
			fieldType: 'd965b6d9-8dd0-440c-a31c-f40bf72accea',
			value: 'Sample Record'
		}]);
	}).then(recordObj => {
		InterfaceActions.clearStickyNotification(notifications.sampleRecordNotification);
		InterfaceActions.notification({ 'message': 'Finished creating sample record! Opening search page...', 'level': 'success' });
		PageUtils.loadPage(recordIds.searchPageId);
	});
}

/**
 * Finds the first list field attached to a page or field
 * 
 * @param {object} recordObj The page or settings object on which to look for an attached list field
 */
function findListOnPage(recordObj) {
	if (!recordObj || !recordObj.attachedFields) {
		return '';
	}
	let attachedFields = [];
	try {
		attachedFields = JSON.parse(recordObj.attachedFields);
	} catch (error) {
		return '';
	}
	let toReturn = '';
	attachedFields.forEach((fieldInfo) => {
		let fieldIds = fieldInfo.recordIds ? fieldInfo.recordIds : [fieldInfo.recordId];
		fieldIds.forEach(fieldId => {
			let fieldObj = FieldStore.get(fieldId) || {};
			// If this is a list field
			if (fieldObj.fieldType === '9b782b83-4962-4bd6-993c-f72096e02610') {
				toReturn = toReturn ? toReturn : fieldId;
			} else if (fieldObj.fieldType === '7ebd9251-675c-4129-95e3-6b8e31c135a2') {
				// Recurse over children of container field
				let fieldSettings = FieldSettingsStore.getSettings(fieldId) || {};
				if (fieldSettings.attachedFields) {
					toReturn = toReturn ? toReturn : findListOnPage(fieldSettings);
				}
			}
		});
	});

	return toReturn;
}

/**
 * Adds a page on a table to the primary navigation fields in an app
 * @param {object} newTableObj Object with table information
 * @param {string} pageToAdd ID of the page to add
 * @returns 
 */
function _addPageToPrimaryNav(newTableObj, pageToAdd) {
	let {tableSchemaName, pluralName, singularName, icon} = newTableObj;
	let tabOptionTemplate = {
		displayedName: pluralName || singularName || tableSchemaName,
		icon,
		page: pageToAdd,
		type: 'tab',
		subTabs: []
	};
	let primaryNavs = FieldStore.getAllArray().filter(({roles}) => roles && roles.includes('primaryNavigation'));
	let updatePromises = primaryNavs.map(primaryNav => {
		let tabOptions = primaryNav.tabOptions ? ObjectUtils.getObjFromJSON(primaryNav.tabOptions) : [];
		let tabOption = Object.assign({order: tabOptions.length}, tabOptionTemplate);
		tabOptions.push(tabOption);
		primaryNav.tabOptions = JSON.stringify(tabOptions);
		FieldActions.pushToStore(primaryNav.recordId, primaryNav);
		return FieldActions.pushToDatabasePromise(FieldStore.get(primaryNav.recordId, true));
	});
	return Promise.all(updatePromises);
}