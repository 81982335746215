import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import { FieldTypeConstants } from '../constants/field-type-constants';

/**
 * Core store that contains field type records
 *
 * @class FieldTypeStore
 * @extends {ReduceStore}
 */
class FieldTypeStore extends ReduceStore {
	/**
	 * getInitialState - initial state for TablesStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.Map({
			allPulledFromDatabase: false,
			records: Immutable.Map()
		});
	}

	/**
	 * Called every time an action is dispatched (for any store)
	 *
	 * @param {Object} state - current state of this store
	 * @param {Object} action - action that's coming in
	 * @returns {Object} new state after the action has been processed.
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			case FieldTypeConstants.FIELD_TYPE_PULL_FROM_DATABASE_ALL: {
				// Set the records sub-key in the state to : 
				// start with what we got for the new tables, and then merge in what we have in the state.
				//   let newstate = state.set('records', Immutable.fromJS(tables).mergeDeep(state.get('records')));
				let newstate = state.set('records', action.get('fieldTypeObject'));
				newstate = newstate.set('allPulledFromDatabase', true);
				return newstate;
			}
			case FieldTypeConstants.FIELD_TYPE_PULL_ERROR: {
				console.error('Field Type Store Error: ' + action.get('error'));
				return state;
			}
			default: {
				return state;
			}
		}
	}

	/**
	 * Gets the entire store as an object
	 *
	 * @returns {Object} current store as an object
	 */
	getAll() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			return this.getState().get('records').toJS();
		} else {
			// console.warn('pullFromDatabaseAll not run, did you mean get()?');
			return undefined;
		}
	}

	/**
	 * Gets the entire store as an array
	 *
	 * @returns {Array} current store as an array
	 */
	getAllArray() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			return this.getState().get('records').toList().toJS();
		} else {
			// console.warn('pullFromDatabaseAll not run, did you mean get()?');
			return undefined;
		}
	}

	/**
	 * Gets an individual record from the store
	 * @param {string} recordId UUID of the record to get
	 * @returns {Object} current store as an object
	 */
	get(recordId) {
		if (this.getState().get('records').has(recordId)) {
			return this.getState().get('records').get(recordId).toJS();
		} else {
			return undefined;
		}
	}

	/**
	 * Get whether all have been pulled from the database or not yet.
	 * 
	 * @returns {boolean}
	 */
	allPulledFromDatabase() {
		return this.getState().get('allPulledFromDatabase');
	}
}

const instance = new FieldTypeStore(AppDispatcher);

/**
 * Gets an array of query settings on this field.  Optional parentFieldType
 * will add query settings from settingsForChildFields.
 * 
 * @param {string} fieldType Primary Field Type to check against
 * @param {string} parentFieldType That field's parent's field type.
 * @return {Array} Returns an array of objects with recordId and 
 * 	fieldSchemaName for each query setting on the field type/given to 
 * 	child fields on the parent provided.
 * 
 * @todo Cache-able
 */
instance.getQuerySettings = function (fieldType, parentFieldType) {
	let fieldTypeObj = instance.get(fieldType);

	if (!fieldTypeObj) {
		return [];
	}

	let querySettings = [];
	if (Array.isArray(fieldTypeObj.settings)) {
		fieldTypeObj.settings.forEach(fieldTypeSetting => {
			let fieldTypeSettingObj = window.FieldStore.get(fieldTypeSetting['recordId']);
			// Query Field Type
			if (fieldTypeSettingObj) {
				if (fieldTypeSettingObj.fieldType === '45b6601f-02e8-4dcd-acce-9815d094f009') {
					querySettings.push({
						recordId: fieldTypeSetting['recordId'],
						fieldSchemaName: fieldTypeSettingObj.fieldSchemaName
					});
				}
			} // End if we couldnt find the setting's field obj.
		}); // End looping over all settings
	} // End if settings are an array

	if (parentFieldType) {
		let parentFieldTypeObj = instance.get(parentFieldType);
		if (Array.isArray(parentFieldTypeObj.settingsForChildFields)) {
			parentFieldTypeObj.settingsForChildFields.forEach(fieldTypeSetting => {
				let fieldTypeSettingObj = window.FieldStore.get(fieldTypeSetting['recordId']);
				// Query Field Type
				if (fieldTypeSettingObj) {
					if (fieldTypeSettingObj.fieldType === '45b6601f-02e8-4dcd-acce-9815d094f009') {
						querySettings.push({
							recordId: fieldTypeSetting['recordId'],
							fieldSchemaName: fieldTypeSettingObj.fieldSchemaName
						});
					}
				} // End if we couldnt find the setting's field obj.
			});
		} // End if there is no settingsForChildField
	}
	return querySettings;
}
export default instance;
