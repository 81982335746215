import AppDispatcher from '../dispatcher/app-dispatcher';
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';
import {LocalContextConstants} from '../constants';
import RenderConstants from '../constants/render-constants';
import AdminSettingsConstants from '../constants/admin-settings-constants';
import RenderStore from './render-store';
// import UIUtils from '../utils/ui-utils';

/**
 * Store to contain the current values for local (page > field, field > field) contextual (for recordID X) values
 *
 * @class LocalContextStore
 * @extends {ReduceStore}
 */
class LocalContextStore extends ReduceStore {
	/**
	 * Initial state for LocalContextStore
	 *
	 * @param {Object} event
	 *
	 * @memberOf LocalContextStore
	 */
	getInitialState() {
		return Immutable.Map();
	}

	/**
	 * Get a local context value
	 * 
	 * @param {string} renderId 
	 * @param {string} settingName 
	 * @returns {object}
	 * 
	 * @memberof LocalContextStore
	 */
	getValue(renderId, settingName) {
		return this.getState().getIn([renderId, 'settings', settingName]);
	}

	/**
	 * Returns true if this renderId is already registered in the Store
	 * 
	 * @param {string} renderId 
	 * @returns {boolean}
	 * @memberof LocalContextStore
	 */
	getValuesAreLoading(renderId) {
		return (this.getState().getIn([renderId]) ? true : false);
	}

	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 *
	 * @memberOf LocalContextStore
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			// Initialize the value. (@TODO Do we actually need this?)
			case LocalContextConstants.PROCESS_LOCAL_CONTEXT: {
				let settings = action.get('settings').toJS();
				Object.keys(settings).forEach(settingsKey => {
					settings[settingsKey] = null;
				});
				return state.mergeIn([action.get('renderId')], Immutable.fromJS({lastDt: +new Date(), settings}));
			}
			case LocalContextConstants.LOCAL_CONTEXT_UPDATE: {
				let renderId = action.get('renderId');
				let lastDt = action.get('lastDt');
				let prevVersion = state.get('renderId');
				let prevDt = prevVersion && prevVersion.has('lastDt') ? prevVersion.get('lastDt') : undefined;
				// If this is newer than our last attempt
				if(!prevDt || prevDt <= lastDt) {
					return state.withMutations(state => {
						// Update the last DT and settings
						state.mergeIn([renderId, 'settings'], action.get('settings'));
						state.setIn([renderId, 'lastDt'], lastDt);
					})
				} else {
					// Discard any outdated updates
					return state;
				}
			}

			case LocalContextConstants.LOCAL_CONTEXT_UPDATE_BULK: {
				return state.withMutations((state) => {
					action.get('updates').forEach((update) => {
						state.mergeIn([update.get('renderId'), 'settings'], update.get('settings'));
					})
				})
			}

			case RenderConstants.DELETE_RENDER: {
				let renderId = action.get('renderId');
				let reassignPageChildren = action.get('reassignPageChildren');
				let childIds = RenderStore.findAllChildren(renderId, reassignPageChildren);
				return state.withMutations((state) => {
					state.delete(renderId);
					
					childIds.forEach((childRenderId) => {
						let childDetails = RenderStore.get(childRenderId);
						let componentType = childDetails.componentType;
						let shouldReassign = reassignPageChildren && componentType === 'page';

						// Only delete the non-page children or delete page children if they are not being reassigned
						// (This is a part of the fix for ticket 8292)
						if(!shouldReassign) {
							state.delete(childRenderId);
						}
					});
				});
			}

			case RenderConstants.REFRESH_FIELD:
			case RenderConstants.GRID_UPDATE:
			case AdminSettingsConstants.OVERLAY_CHANGE_WITH_RECALC:
			case RenderConstants.INIT_PAGE: {
				return state.withMutations(state => {
					if(action.has('grids')) {
						let grids = action.get('grids');
						let lastChildCalculation = action.get('lastChildCalculation');
						grids.forEach(grid => {
							if(grid.has('localContextInfo')) {
								let localContextInfo = grid.get('localContextInfo');
								let renderId = grid.get('parentRenderId'); // @TODO: Make sure this matches other grids + the incoming grids match this
								if(state.hasIn([renderId, 'settings'])) {
									state.mergeIn([renderId, 'settings'], localContextInfo);
								} else {
									state.setIn([renderId, 'settings'], localContextInfo);
								}
								state.setIn([renderId, 'lastDt'], state.hasIn(renderId, 'lastDt') ? Math.max(lastChildCalculation, state.getIn(renderId, 'lastDt')) : lastChildCalculation);
							}
							if(grid.has('attachedFields')) {
								grid.get('attachedFields').forEach((attachedField) => {
									let settings = attachedField.get('settings');
									if(settings) {
										let renderId = attachedField.get('renderId');
										if(state.hasIn([renderId, 'settings'])) {
											state.mergeIn([renderId, 'settings'], settings);
										} else {
											state.setIn([renderId, 'settings'], settings);
										}
										state.setIn([renderId, 'lastDt'], state.hasIn(renderId, 'lastDt') ? Math.max(lastChildCalculation, state.getIn(renderId, 'lastDt')) : lastChildCalculation);
									}
								});
							}
						});
					}
				});
			}

			default: {
				return state;
			}
		}
	}
}

const instance = new LocalContextStore(AppDispatcher);
export default instance;