import uuid from 'uuid';
import RelationshipActions from '../actions/relationship-actions';
import RelationshipStore from '../stores/relationship-store';
import TableStore from '../stores/table-store';
import RelationUtils from '../utils/relation-utils';
import InterfaceActions from '../actions/interface-actions';

export default {
	processCreate (searchResult) {
		let lhsTableId = searchResult.methodInfo.lhsTable;
		let lhsCardinality = searchResult.methodInfo.lhsCardinality;
		let rhsTableId = searchResult.methodInfo.rhsTable;
		let rhsCardinality = searchResult.methodInfo.rhsCardinality;
		let recordId = uuid.v4();
		let newRelationshipObj = Object.assign({
			recordId
		}, getRelationshipObj(lhsTableId, lhsCardinality, rhsTableId, rhsCardinality));

		searchResult.currentComponentInfo = {
			componentType: 'relationship',
			componentSubtype: 'relationship',
			componentId: recordId
		};

		let creationNotification = InterfaceActions.stickyNotification({'message': 'Creating relationship ' + newRelationshipObj.relationSchemaName + '. This may take a few seconds; please wait...', 'level': 'info'});
		RelationshipActions.pushToStore(recordId, newRelationshipObj);
		return RelationshipActions.pushToDatabasePromise(RelationshipStore.get(recordId))
			.then(() => {
				InterfaceActions.clearStickyNotification(creationNotification);
				InterfaceActions.notification({'message': 'Relationship creation complete!', 'level': 'success'});	
				return searchResult;
			});
	},

	processDelete(searchResult) {
		let componentId = searchResult.currentComponentInfo.componentId;
		let componentName = searchResult.currentComponentInfo.componentName;
		//Notify user the delete Process Sucess
		let deleteNotification = InterfaceActions.stickyNotification({ 'level': 'info', 'message': 'Deleting ' + componentName + '...' });

		// Delete the field.
		return RelationshipActions.deleteFromDatabasePromise(componentId).then((results) => {
			InterfaceActions.clearStickyNotification(deleteNotification);
			return results;
		});
	}
}

function getRelationshipObj(lhsTableId, lhsCardinality, rhsTableId, rhsCardinality) {
	let lhsTable = TableStore.get(lhsTableId) || {};
	let lhsTableSchemaName = lhsTable.tableSchemaName;
	let lhsTableName = lhsCardinality === 'one' ? lhsTable.singularName : lhsTable.pluralName;
	// let lhsSingularName = lhsTable.singularName || lhsTable.tableSchemaName;
	// let lhsPluralName = lhsTable.pluralName || lhsTable.tableSchemaName;
	let rhsTable = TableStore.get(rhsTableId) || {};
	let rhsTableSchemaName = rhsTable.tableSchemaName;
	let rhsTableName = rhsCardinality === 'one' ? rhsTable.singularName : rhsTable.pluralName;
	// let rhsSingularName = rhsTable.singularName || rhsTable.tableSchemaName;
	// let rhsPluralName = rhsTable.pluralName || rhsTable.tableSchemaName;
	let starterName = lhsTableName[0].toLowerCase() + lhsTableName.slice(1) + 'To' + rhsTableName;
	let relationName = RelationUtils.validateRelationSchemaName(starterName, '', null, []).validSchemaName;
	let ltorLabel = lhsTable.singularName + '\'s ' + (rhsCardinality === 'one' ? rhsTable.singularName : rhsTable.pluralName);
	let rtolLabel = rhsTable.singularName + '\'s ' + (lhsCardinality === 'one' ? lhsTable.singularName : lhsTable.pluralName);

	let relationshipObj = {
		// rhsCardinality refers to the # of right-hand records from the LHS, which is lCardinality
		lCardinality: rhsCardinality === 'one' ? '1' : 'm',
		lTableSchemaName: lhsTableSchemaName,
		ltorLabel,
		// lhsCardinality refers to the # of left-hand records from the RHS, which is rCardinality
		rCardinality: lhsCardinality === 'one' ? '1' : 'm',
		rTableSchemaName: rhsTableSchemaName,
		relationSchemaName: relationName,
		rtolLabel
	};

	return relationshipObj;
}