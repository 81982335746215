import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Actions
import { AdminSettingsActions } from '../../../actions';
import MapActions from '../../../actions/map-actions';

// Constants
import GroupFilters from '../../../constants/group-filters';
import Help from '../../../constants/help-upgrade-constants';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import DiscussionStore from '../../../stores/discussion-store';
import FieldStore from '../../../stores/field-store';
import MapStore from '../../../stores/map-store';
import TableStore from '../../../stores/table-store';
import ContextStore from '../../../stores/context-store';

// Utils
import PageUtils from '../../../utils/page-utils';
import UIUtils from '../../../utils/ui-utils';
import MapUtils from '../../../utils/map-utils';

// Order matters here, we use it for the final result.
let typeDetails = {
	'discussion': { name: 'Discussion', icon: 'fa-comment' },
	'bug': { name: 'Bug', icon: 'fa-bug' },
	'change': { name: 'Change', icon: 'fa-plus-circle' },
	'emptytype': { name: 'No Type Set', icon: 'fa-ban' },
};

// Order matters here, we use it for the final result.
let priorityDetails = {
	'critical': { name: 'Critical' },
	'major': { name: 'Major' },
	'minor': { name: 'Minor' },
	'trivial': { name: 'Trivial' },
	'emptypriority': { name: 'No Priority Set' },
};

// Order matters here, we use it for the final result.
let statusDetails = {
	'open': { name: 'Open' },
	'closed': { name: 'Closed' },
};

/**
 * Container for Discussion Map
 *
 * @class DiscussionMapContainer
 * @extends {Component}
 */
class DiscussionMapContainer extends Component {
	/**
	 * Creates an instance of DiscussionMapContainer
	 *
	 * @memberOf DiscussionMapContainer
	 */
	constructor(props) {
		super(props);
		this._renderGroup = this._renderGroup.bind(this);
		this._onChangeGroupBy = this._onChangeGroupBy.bind(this);
		this._onChangeSearch = this._onChangeSearch.bind(this);
		this._onDiscussionOpen = this._onDiscussionOpen.bind(this);
		this._onLoadPage = this._onLoadPage.bind(this);
		this._onToggleShow = this._onToggleShow.bind(this);
		this._onToggleAllOpen = this._onToggleAllOpen.bind(this);
		this._onToggleAllClosed = this._onToggleAllClosed.bind(this);
	}


	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf DiscussionMapContainer
	 */
	static getStores() {
		return [AdminSettingsStore, DiscussionStore, MapStore];
	}

	/**
	 * Returns the current State of the DiscussionMapContainer
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf DiscussionMapContainer
	 */
	static calculateState(prevState) {
		let groupBy = (MapStore.getGroupBy() ? MapStore.getGroupBy() : 'type');
		let discussions = DiscussionStore.getAllArray();
		let search = MapStore.getSearch();
		
		if (prevState
			&& prevState.discussions === discussions
			&& prevState.groupBy === groupBy
			&& prevState.search === search) {
			return false;
		}

		let groupToggleStatus = {};
		groupToggleStatus[GroupFilters.STATUS] = MapStore.getGroupToggleStatusGroupBy(AdminSettingsStore.getActiveDashboard(),GroupFilters.STATUS);
		groupToggleStatus[GroupFilters.TYPE] = MapStore.getGroupToggleStatusGroupBy(AdminSettingsStore.getActiveDashboard(),GroupFilters.TYPE);
		groupToggleStatus[GroupFilters.PRIORITY] = MapStore.getGroupToggleStatusGroupBy(AdminSettingsStore.getActiveDashboard(),GroupFilters.PRIORITY);
		groupToggleStatus[GroupFilters.TABLE_NAME] = MapStore.getGroupToggleStatusGroupBy(AdminSettingsStore.getActiveDashboard(),GroupFilters.TABLE_NAME);

		return {
			discussions: discussions,
			groupBy: groupBy,
			search: search,
			groupToggleStatus: groupToggleStatus,
			groupedDiscussions: this._performGroupBy(discussions, groupBy, search)
		};
	}

	/**
	 * Using the group by set in the UI, group the discussions and return
	 * an object with the groups as keys and discussion array as value.
	 * @param {Array} discussions Discussions to group
	 * @param {String} groupBy How to group the discussions
	 * @param {String} search What search to apply, if any.
	 * @return Object
	 */
	static _performGroupBy(discussions, groupBy, search) {
		let groups = {};

		if (!discussions) {
			return groups;
		}

		// Filter out the discussions that dont have the search term.
		discussions = discussions.filter(discussion => {
			if (search && search.length > 0) {
				return (discussion.name.toLowerCase().includes(search.toLowerCase()));
			} else {
				return true;
			}
			// Sort the discussions - by Closed/Open, then Numerically.
		}).sort((a, b) => {
			// Put closed stuff last.
			if (a.status === 'closed' && b.status !== 'closed') {
				return 1;
			} else if (a.status !== 'closed' && b.status === 'closed') {
				return -1;
			}

			// No number?  Go to the bottom:
			if (a.number === '' || a.number === null || a.number === undefined) return 1;
			if (b.number === '' || b.number === null || b.number === undefined) return -1;

			// Same Number?  Don't Swap.
			if (a.number === b.number) return 0;

			// Ascending Numericallly.
			return +a.number < +b.number ? 1 : -1;
		});

		// Prepare the final return
		let returnGroups = {};

		if (groupBy === GroupFilters.TYPE || groupBy === GroupFilters.PRIORITY || groupBy === GroupFilters.STATUS) {
			// Figure out which set of details to use...
			let details = typeDetails;
			if (groupBy === GroupFilters.PRIORITY) {
				details = priorityDetails;
			} else if (groupBy === GroupFilters.STATUS) {
				details = statusDetails;
			}

			// Put the discussions into their groups
			discussions.forEach(discussion => {
				let groupVal = (discussion[groupBy] ? discussion[groupBy] : 'empty' + groupBy)

				if (!groups[groupVal]) {
					groups[groupVal] = [];
				}

				groups[groupVal].push(discussion);
			});

			// Put the groups into the return object in their source order.
			Object.keys(details).forEach(keyName => {
				returnGroups[keyName] = groups[keyName];
			})
			if (groups['empty']) {
				returnGroups['empty'] = groups['empty'];
			}
		} else {
			returnGroups = MapUtils.groupBy(discussions, GroupFilters.TABLE_NAME);
		}

		// If we have a search...
		if(search.length > 0) {
			let groupToggleStatus = { };
			Object.keys(returnGroups).forEach(group => {
				groupToggleStatus[group] = true;
			})
			// @TODO REMOVE!
			/*
			In order to remove this, all of the performGroupBy stuff needs to be moved
			to the MapSTORE - and done in response to the OnDashboardChange 
			[ which should be moved there from the AdminSettingsStore ],
			onSearchChange, and onGroupByChange Actions. If we did this, then these
			components would become very `dumb` rendering components - as they are 
			meant to be.
			*/
			setTimeout(() => {
				MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), groupBy, groupToggleStatus);
			},0);
		}

		return returnGroups;
	}

	/**
	 * Render method for DiscussionMapContainer
	 *
	 * @memberOf DiscussionMapContainer
	 */
	render() {
		// Pull vales from the state
		let { groupBy, search, groupedDiscussions } = this.state;

		// Loop over the groupedDiscussions Object, and call renderGroup
		// for each Group found.
		let discussionList = [];
		Object.keys(groupedDiscussions).forEach(groupName => {
			let group = this._renderGroup(groupName, groupedDiscussions[groupName]);
			if(group){
				discussionList.push(group);
			}
		});

		let noSearchFound = null;
		if(discussionList.length === 0 && search.length) {
			noSearchFound = <li className="no-search-found" style={{ color: 'white'}}><h4>No Results for '{search}' found.</h4></li>
		}

		return (
			<div id="discussion-map__content" className="map">
				<div className="cd-search-container">
					<div className="d-flex">
						<div style={{ flex: 1 }} className="mr-1">
							<select className="form-control select-group-by" value={groupBy} onChange={this._onChangeGroupBy}>
								<option value={GroupFilters.TYPE}>Group By Type</option>
								<option value={GroupFilters.PRIORITY}>Group By Priority</option>
								<option value={GroupFilters.STATUS}>Group By Status</option>
								<option value={GroupFilters.TABLE_NAME}>Group By Table</option>
							</select>
						</div>
						<div style={{ flex: 1 }} className="ml-1">
							<input placeholder="Search" className="form-control discussion-search-input" type="text" value={search} onChange={this._onChangeSearch} />
						</div>
					</div>
				</div>
				<div className="section-header" key="expand-collapse-tools">
					<div className="d-flex justify-content-between align-items-center">
						<div className="d-flex">
							<div title="Expand All" onClick={this._onToggleAllOpen}>
								<i className="fa fa-plus"></i>
							</div>
							<div>|</div>
							<div title="Collapse All" onClick={this._onToggleAllClosed}>
								<i className="fa fa-minus"></i>
							</div>
						</div>
						<div className="d-flex align-items-center">
							<h5 className="bold">Discussion</h5>
							<div className="d-flex">
								<div title="Discussion Help" className="info-icon ml-2" onClick={() => { UIUtils.onHelpClick(Help.HELP_DASHBOARD_PROJECT_DISCUSSIONS); }}>
									<i className="fa fa-info-circle mr-1"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="list-content-wrapper">
					<ol>
						{discussionList}
						{noSearchFound}
					</ol>
				</div>
			</div>
		);
	}

	/**
	 * Return JSX for one "Group" of Discussions, and it's header.
	 * @param groupName string Name of the Group of Discussions
	 * @param discussions Array Array of Discussions in this group.
	 * @return JSX
	 */
	_renderGroup(groupName, discussions) {
		let { groupBy, groupToggleStatus } = this.state;
		// Skip the group if it's empty
		if (!Array.isArray(discussions) || discussions.length <= 0) {
			return null;
		}

		let discussionList = [];

		// Grab the selected Discussion
		let selectedDiscussion = AdminSettingsStore.getSettingRecordId();;

		// Loop over my discussions...
		discussions.forEach((discussion, index) => {

			// See if this is the selected one
			let youAreHereIndicator = (discussion.discussionId === selectedDiscussion
				? <span><i className="fa fa-circle selection-marker" aria-hidden="true"></i><span className="sr-only">(Current)</span></span>
				: <span><i className="fa selection-marker" aria-hidden="true"></i></span>);

			// See if we should be disabled.
			let disabledSuffix = ' ';
			if (discussion.status === 'closed') {
				disabledSuffix = ' disabled ';
			}

			discussionList.push(
				<li key={index} className="table-name-item">
					<div className="d-flex justify-content-between">
						<h5 
							className={`page-load-link' + ${disabledSuffix} ${discussion.status}`}
							data-toggle="modal"
							data-target="#page-dialog"
							onClick={() => {
								this._onLoadPage(discussion.pageId, discussion.dataTableSchemaName, discussion.dataRecordId)
							}}>
							{discussion.number}. {discussion.name}
						</h5>
						<div className="d-flex">
							{youAreHereIndicator}
							<span className={`commentCount notlink + ${disabledSuffix} ${discussion.status}`}
								title={discussion.commentCount + " comments on this discussion"}>
								{discussion.commentCount}
							</span>
							<span className="link ml-2" onClick={() => {
								this._onDiscussionOpen(discussion.discussionId, discussion.componentId)
								}} title="Update Discussion">
								<img height="16" width="16" src={ContextStore.getUrlMedia() + "/icon-discussion.svg"} alt="" />
							</span>
						</div>
					</div>
				</li>
			);
		});

		// If this group is a type or priority, wrap it...
		let groupIconJSX = null;
		let groupNameJSX;
		switch (groupBy) {
			case GroupFilters.PRIORITY:
				groupNameJSX = priorityDetails[groupName].name;
				break;
			case GroupFilters.TYPE:
				groupIconJSX = <span className={"site-map-table-icon fa " + typeDetails[groupName].icon}></span>
				groupNameJSX = typeDetails[groupName].name
				break;
			case GroupFilters.STATUS:
				groupNameJSX = statusDetails[groupName].name
				break;
			case GroupFilters.TABLE_NAME:
				groupNameJSX = groupName;
				if (discussions.length) {
					let tableSchemaName = discussions[0].dataTableSchemaName;
					if (tableSchemaName) {
						let tableObj = TableStore.getByTableSchemaName(tableSchemaName);
						if (tableObj.icon) {
							groupIconJSX = <span className={"site-map-table-icon fa " + tableObj.icon}></span>
						}
					}
				}
				break;
			default:
				console.warn('Group by:', groupBy, 'not supported');
			break;
		}

		let show = (groupToggleStatus && groupToggleStatus[groupBy] ? groupToggleStatus[groupBy][groupName] : false);

		return (
			<li key={groupName} className="role-group-item">
				<div className="role-group-name d-flex" onClick={this._onToggleShow.bind(this, groupBy, groupName, !show)}>
					<div className="mr-1">
						{show 
							? <i title="Collapse" className="fa fa-minus"></i>
							: <i title="Expand" className="fa fa-plus"></i>
						}
					</div>
					<div className="d-flex justify-content-between w-100">
						<div className="d-flex align-items-center">
							{groupIconJSX}
							<h4 className="bold">{groupNameJSX}</h4>
						</div>
						<h4 className="bold">({discussionList.length})</h4>
					</div>
				</div>
				<ol id={groupName} className={"collapse pl-4 pt-2 pr-0 " + (show ? 'show ' : '') + " groupby-list"}>
					{discussionList}
				</ol>
			</li>
		);
	}

	/**
	 * private Handles change to group by dropdown
	 */
	_onChangeGroupBy(event) {
		let groupBy = event.target.value;
		MapActions.groupBy(groupBy);
	}

	/**
	 * Handles typing into the search box.
	 * @param {object} event Change event
	 */
	_onChangeSearch(event) {
		let search = event.target.value;
		MapActions.search(search);
	}

	/**
	 * Handles click to open Right Hand Side Panel Appearance 
	 * @param {String} discussionId Discussion ID to open
	 * @param {String} componentId Which Component to open about
	 */
	_onDiscussionOpen(discussionId, componentId) {
		let componentTSN = 'page';
		if (FieldStore.get(componentId)) {
			componentTSN = 'field';
		}
		UIUtils.openSettingsPanel('discussion', componentId, componentTSN);
		// Unhide the settings, always
		AdminSettingsActions.onSettingsListHideChange(false);
		AdminSettingsActions.onSettingChange('discussion', discussionId);
	}

	/**
	 * Gets a recordId for the tableSchemaName passed in the given Page and  
	 * Calls private loadPage to Reload the Page with the Selected Page  
	 * 
	 * @param {String} pageId - Page to load
	 * @param {String} dataTableSchemaName - Table Schema Name of the record to load the page about
	 * @param {string} dataRecordId - which record to load the page about.
	 * 
	 * @memberof DiscussionMapContainer
	 */
	_onLoadPage(pageId, dataTableSchemaName, dataRecordId) {
		PageUtils.loadPage(pageId, dataTableSchemaName, dataRecordId)
		if (!AdminSettingsStore.getIsOverlayActive('discussion')) {
			AdminSettingsActions.onOverlayChange('discussion');
		}
	}

	/**
	 * Toggle one group.
	 */
	_onToggleShow(groupBy, groupLabel, show) {
		MapActions.groupToggle(AdminSettingsStore.getActiveDashboard(), groupBy, groupLabel, show);
	}

	/**
	 * Open all of the groups
	 */
	_onToggleAllOpen() {
		let { groupedDiscussions, groupToggleStatus, groupBy } = this.state;
		Object.keys(groupedDiscussions).forEach(group => {
			groupToggleStatus[groupBy][group] = true;
		})
		MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), groupBy, groupToggleStatus[groupBy]);
	}

	/**
	 * Close all of the groups
	 */
	_onToggleAllClosed() {
		let { groupToggleStatus, groupBy } = this.state;
		let toggleStatusGroupBy = groupToggleStatus[groupBy];
		Object.keys(toggleStatusGroupBy).forEach(key => {
			groupToggleStatus[groupBy][key] = false;
		})
		MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), groupBy, groupToggleStatus[groupBy]);
	}
}

const container = Container.create(DiscussionMapContainer);
export default container;
