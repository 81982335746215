import React from 'react';
import AttachmentParentTable from './attachment-parent-table.react';
import PropTypes from 'prop-types';
import BaseSettingForm from './base-setting-form.react';

/**
 * Container for child fields tables - Large, Medium, Small.
 * 
 * @class AttachmentParentContainer
 * @extends {Component}
 */
class AttachmentParentContainer extends BaseSettingForm  {
	/**
	 * Create instance of AttachmentParentContainer
	 *
	 * @memberof AttachmentParentContainer
	 */
	constructor(props) {
		super(props);
		this._getValueObj = this._getValueObj.bind(this);
		this._onScreenSizeObjectValueChange = this._onScreenSizeObjectValueChange.bind(this);
	}

	/**
	 * Get the value object, regardless of if the actual value passed in is empty or not.
	 * 
	 * @returns {object}
	 * 
	 * @memberof AttachmentParentContainer
	 */
	_getValueObj() {
		let valueRaw = this.props.value,
			value = { lg: [], md: 'lg', sm: 'lg', all: [] };
		try {
			value = JSON.parse(valueRaw);
			if(!value['lg']) {
				value = { lg: [], md: 'lg', sm: 'lg', all: [] };
			}
		} catch (e) {
			console.warn('Failed to JSON Parse fields container value: ', valueRaw);
		}
		return value;
	}
	/**
	 * Updates changes made to the components of an specific ScreenSize 
	 * 
	 * @param {object} event 
	 * @memberof AttachmentParentContainer
	 */
	_onScreenSizeObjectValueChange(event) {
		let screenSize = event.target.screenSize;
		if(screenSize) {
			let value = this.props.value,
				updatedValue = event.target.value,
				valueObj = { lg: [], md: [], sm: [], all: []};
			if(typeof value === 'string') {
				try {
					valueObj = JSON.parse(value);
				} catch (e) {
					console.warn('Error parsing Page Components JSON: ', value);
				}
			}
			let oldValue = valueObj[screenSize];
			if(Array.isArray(updatedValue) && !updatedValue.length) {
				// If our value is an array, but there is nothing in it.. then copy from another array.
				// Priority is the array we WERE managed by.
				if(Array.isArray(valueObj[oldValue])) {
					updatedValue = valueObj[oldValue];
				} else {
					// Otherwise, lets go find an array...
					['lg', 'md', 'sm', 'all'].forEach(loopSS => {
						if(Array.isArray(valueObj[loopSS])) {
							updatedValue = valueObj[loopSS];
						}
					});
				}
			}
			valueObj[screenSize] = updatedValue;
			this.props.onChange({ 
				target: { 
					id: this.props.fieldSchemaName, 
					value: JSON.stringify(valueObj)
				}});
		}
	}
	/**
	 * Render the FieldType Chooser
	 * 
	 * @returns React
	 * 
	 * @memberof AttachmentParentContainer
	 */
	render() {
		let value = this._getValueObj();
		return (
			<div>
				<AttachmentParentTable childSettings={this.props.childSettings} configurationJson={this.props.configurationJson} 
					onChange={this._onScreenSizeObjectValueChange} 
					screenSize="lg" value={value['lg']} fieldSchemaName={this.props.fieldSchemaName}
					tableSchemaName={this.props.tableSchemaName} recordId={this.props.recordId} 
					parentTableSchemaName={this.props.parentTableSchemaName} parentRecordId={this.props.parentRecordId}/>
				<AttachmentParentTable childSettings={this.props.childSettings} configurationJson={this.props.configurationJson} 
					onChange={this._onScreenSizeObjectValueChange} 
					screenSize="md" value={value['md']} fieldSchemaName={this.props.fieldSchemaName} 
					tableSchemaName={this.props.tableSchemaName} recordId={this.props.recordId} 
					parentTableSchemaName={this.props.parentTableSchemaName} parentRecordId={this.props.parentRecordId}/>
				<AttachmentParentTable childSettings={this.props.childSettings} configurationJson={this.props.configurationJson} 
					onChange={this._onScreenSizeObjectValueChange} 
					screenSize="sm" value={value['sm']} fieldSchemaName={this.props.fieldSchemaName} 
					tableSchemaName={this.props.tableSchemaName} recordId={this.props.recordId} 
					parentTableSchemaName={this.props.parentTableSchemaName} parentRecordId={this.props.parentRecordId}/>
			</div>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	AttachmentParentContainer.propTypes = Object.assign({}, BaseSettingForm.propTypes, {
		childSettings: PropTypes.array,
		value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
		onChange: PropTypes.func,
		recordId: PropTypes.string,
		tableSchemaName: PropTypes.string,
		parentRecordId: PropTypes.string,
		setting: PropTypes.object,
		parentTableSchemaName: PropTypes.string
	});
}

export default AttachmentParentContainer;