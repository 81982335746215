import React, {Component} from 'react';
// import {Container} from 'flux/utils';

import InterfaceActions from '../../../actions/interface-actions';
import AdminSettingsActions from '../../../actions/admin-settings-actions';
// import ComplianceActions from '../../../actions/compliance-actions';


/**
 * Container for PageDialog
 *
 * @class EnableModalContainer
 * @extends {Component}
 */
class EnableModalContainer extends Component {
  /**
   * Creates instance of EnableModalContainer
   *
   * @memberOf EnableModalContainer
   */
  constructor(props) {
	super(props);
	this.state = {checked: false};
    this._close = this._close.bind(this);
	this._triggerResize = this._triggerResize.bind(this);
	this._confirm = this._confirm.bind(this);
	this._toggleCheck = this._toggleCheck.bind(this);
  }

  /**
   * Handles event when component will update
   */
  componentWillUpdate(nextProps, nextState) {
    if (!nextState.complianceId && this.state.complianceId) {
      setTimeout(AdminSettingsActions.closeModal.bind(null, this.state.complianceId), 0);
    }
  }

  /**
   * Handles event when component will unmount
   */
  componentWillUnmount() {
    setTimeout(this._close, 0);
  }
  /**
   * Render methos for EnableModalContainer
   *
   * @memberOf EnableModalContainer
   */
	render() {
		let dialogClassName = 'modal-dialog modal-lg';


		return (
			<div className={dialogClassName + " site-map-modal compliance-toggle-modal"}>
				<div className="modal-content container">
					<div className="modal-body mh-100 d-flex flex-column cd-bg-3">
						<form style={{ flex: 1 }} id="compliance-form-confirm" className="d-flex flex-column">
							<div className="cd-bg-5 rounded p-2">
								<div className="column">
									<div className="form-text">You are turning on the Compliance Assistance Tool for {this.props.complianceName}, which will provide a set of tools to assist you in tracking certain compliance information. Please check the box below, hereby acknowledging that these tools are merely a supplement to, and specifically not a substitute for, your normal compliance practices and standards.</div>
								</div>
							</div>
							<div style={{ flex: 1 }} className="enable-confirmation-outer-wrapper d-flex align-items-center">
								<div className="column confirmation-inner-wrapper enable-confirmation-inner-wrapper">
									<input className="mr-1" type='checkbox' checked={this.state.checked} onChange={this._toggleCheck} />
									<div>I have read the above and acknowledge that this is not a substitute for my normal compliance practices.</div>
								</div>
							</div>
							<div className="d-flex justify-content-end">
								<button style={{ width: '95px' }} type='button' className='btn btn-secondary mr-2' onClick={this._close}>Cancel</button>
								<button style={{ width: '95px' }} type='button' className='btn btn-primary' onClick={this._confirm}>Continue</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}

	_toggleCheck() {
		this.setState({checked: !this.state.checked});
	}

	_confirm() {
		if(this.state.checked) {
			this._close();
			let {onConfirm} = this.props;
			if(onConfirm) {
				onConfirm();
			}
		} else {
			InterfaceActions.notification({level: 'warning', message: 'You must confirm that you have read and accept the conditions.'});
		}
	}

  /**
   * private Handles compliance settings close
   *
   * @params {Object} event
   *
   * @memberOf EnableModalContainer
   */
  _close() {
    AdminSettingsActions.closeModal(this.props.modalId);
  }

  /**
   * private Handles field menu click
   *
   * @params {Object} event
   *
   * @memberOf EnableModalContainer
   */
  _triggerResize(event) {
    //hack to update react-data-grid metrics
    window.dispatchEvent(new Event('resize'));
  }
}

export default EnableModalContainer;
