import AppDispatcher from '../dispatcher/app-dispatcher';
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';
import ContextConstants from '../constants/context-constants';
import AdminSettingsConstants from '../constants/admin-settings-constants';
/**
 * Store to contain context, including:
 * pageId (String) - current page (in URL)
 * tableSchemaName (String) - current table being referenced (in URL),
 * recordId (String) - current record
 * sessionId (String) - unique user session
 * responsiveMode (String) - 'lg', 'md', or 'sm', used for rendering responsive interface
 * installationId (String) - unique id supplied in body's data attributes
 * installationVersion (String) - version id supplied in body's data attributes
 *
 * @class ContextStore
 * @extends {ReduceStore}
 */
class ContextStore extends ReduceStore {
	
	/**
	 * getInitialState - initial state for ContextStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.Map();
	}
	
	/** Getters **/

	/**
	 * Retrieve the key for if all the record data has been received.
	 * 
	 * @returns {boolean}
	 */
	getAllRecordDataReceived() {
		return this.getState().get('allRecordDataReceived');
	}
	
	/**
	 * getApplicationId - returns the applicationId
	 *
	 * @return {string}  Application ID
	 */
	getApplicationId(){
		return this.getState().get('applicationId');
	}
	
	/**
	 * getBasePath - returns the URL's Base Path
	 *
	 * @return {string}  Base Path
	 */
	getBasePath(){
		return this.getState().get('basePath');
	}

	/**
	 * Returns the default home page id
	 * 
	 * @returns 
	 * @memberof ContextStore
	 */
	getDefaultHomePageId() {
		return this.getState().get('defaultHomePageId');
	}

	/**
	 * Returns the default login page id
	 * 
	 * @returns 
	 * @memberof ContextStore
	 */
	getDefaultLoginPageId() {
		return this.getState().get('defaultLoginPageId');
	}
	
	/**
	 * getInstallationData - returns object containing installation id and version from body data attributes.
	 *
	 * @return {object}  object containing installation id and version
	 */
	getInstallationData(){
		let id = this.getState().get('installationId');
		let version = this.getState().get('installationVersion');

		return {
			'id': id,
			'version': version
		};
	}

	/**
	 * getInstallationId - returns the installationId
	 *
	 * @return {string}  installation id
	 */
	getInstallationId(){
		return this.getState().get('installationId');
	}

	/**
	 * getInstallationVersion - returns the installation version
	 *
	 * @return {string}  returns the installation version
	 */
	getInstallationVersion(){
		return this.getState().get('installationVersion');
	}
	
	/**
	 * getInstallationRole - returns the installation role
	 *
	 * @return {string}  returns the installation role
	 */
	getInstallationRole(){
		return this.getState().get('installationRole');
	}

	/**
	 * getMDGWMode - returns the mdgwMode
	 *
	 * @return {string}  Metadata Gateway Mode (stage|prod)
	 */
	getMDGWMode(){
		return this.getState().get('mdgwMode');
	}

	/**
	 * getPageId - retrieves page id
	 *
	 * @return {String}  pageId
	 */
	getPageId(){
		return this.getState().get('pageId');
	}

	/**
	 * getRecordId - retrieves record id
	 *
	 * @return {String}  recordId
	 */
	getRecordId(){
		return this.getState().get('recordId');
	}

	/**
	 * getPageRenderId - retrieves the page render id
	 *
	 * @return {String}  renderId
	 */
	getPageRenderId(){
		return this.getState().get('pageRenderId');
	}

	/**
	 * getResponsiveMode - retrieves responsive mode. Values are based on React Grid Data.
	 *
	 * @return {String}  responsiveMode - 'lg', 'md', or 'tablet'
	 */
	getResponsiveMode(){
		return this.getState().get('responsiveMode');
	}

	/**
	 * getTableSchemaName - retrieves tableSchemaName
	 *
	 * @return {String}  tableSchemaName
	 */
	getTableSchemaName(){
		return this.getState().get('tableSchemaName');
	}
	
	/**
	 * getUrlBlockly - retrieves path to blockly assets
	 *
	 * @return {string}  url to blockly assets
	 */
	getUrlBlockly(){
		return this.getState().get('urlBlockly');
	}

	/**  
	 * getUrlCommunity - retrieves path to community assets
	 *
	 * @return {string}  url to community assets
	 */
	getUrlCommunity(){
		return this.getState().get('urlCommunity');
	}

	/**  
	 * getUrlEngine - retrieves path to engine assets
	 *
	 * @return {string}  url to engine assets
	 */
	getUrlEngine(){
		return this.getState().get('urlEngine');
	}

	/**
	 * getUrlExpression - retrieves path to url expressions
	 *
	 * @return {string}  url to url expressions
	 */
	getUrlExpression(){
		return this.getState().get('urlExpression');
	}

	/**
	 * getUrlFontawesome - returns path to fontawesome SVG files
	 *
	 * @return {string}  url to fontawesome SVG files
	 */
	getUrlFontawesome(){
		return this.getState().get('urlFontawesome');
	}
	
	/**
	 * getUrlMedia - returns path to media files
	 *
	 * @return {string}  url to media
	 */
	getUrlMedia(){
		return this.getState().get('urlMedia');
	}

	/**  
	 * Console.logs versions of all core assets
	 * @return null
	 */
	getVersions(){
		let blocklyVersion = this.getState().get('urlBlockly');
		blocklyVersion=blocklyVersion.substring(blocklyVersion.indexOf('/v')+1,blocklyVersion.length);
		console.log('Blockly Version',blocklyVersion);
		let engineVersion = this.getState().get('urlEngine');
		engineVersion=engineVersion.substring(engineVersion.indexOf('/v')+1,engineVersion.length);
		console.log('Engine Version',engineVersion);
		let communityVersion = this.getState().get('urlCommunity');
		communityVersion=communityVersion.substring(communityVersion.indexOf('/v')+1,communityVersion.length);
		console.log('Community Version',communityVersion);
	}

	/**  
	 * Retrieves versions of all core assets, and platform
	 * @return { Object }
	 */
	getVersionObject(){
		let blocklyVersion = this.getState().get('urlBlockly');
		blocklyVersion=blocklyVersion.substring(blocklyVersion.indexOf('/v')+2,blocklyVersion.length);
		let engineVersion = this.getState().get('urlEngine');
		engineVersion=engineVersion.substring(engineVersion.indexOf('/v')+2,engineVersion.length);
		let communityVersion = this.getState().get('urlCommunity');
		communityVersion=communityVersion.substring(communityVersion.indexOf('/v')+2,communityVersion.length);
		return {
			engine: engineVersion,
			blockly: blocklyVersion,
			community: communityVersion,
			platform: engineVersion + '.' + blocklyVersion + '.' + communityVersion
		};
	}

	/**
	 * getRunningActions - returns path to media files
	 *
	 * @return {Array} Array of running actions
	 */
	getRunningActions(){
		let state = this.getState();
		return state.has('actionIds') ? state.get('actionIds') : Immutable.Map();
	}

	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 */
	reduce(state, action) {
		let context;

		switch (action.get('type')) {
			case ContextConstants.APPLICATION_INIT: {
				let applicationId = action.get('applicationId');
				let defaultHomePageId = action.get('defaultHomePageId');
				let defaultLoginPageId = action.get('defaultLoginPageId');
				let installationId = action.get('installationId');
				let installationVersion = action.get('installationVersion');
				let installationRole = action.get('installationRole');
				let mdgwMode = action.get('mdgwMode');
				let urlMedia = action.get('urlMedia');
				let urlFontawesome = action.get('urlFontawesome');
				let urlExpression = action.get('urlExpression');
				let urlBlockly = action.get('urlBlockly');
				let urlEngine = action.get('urlEngine');
				let urlCommunity = action.get('urlCommunity');
				let basePath = action.get('basePath');
				context = state.merge({
					'applicationId' : applicationId,
					'basePath': basePath,
					'defaultHomePageId': defaultHomePageId,
					'defaultLoginPageId': defaultLoginPageId,
					'installationId' : installationId,
					'installationVersion': installationVersion,
					'installationRole': installationRole,
					'mdgwMode': mdgwMode,
					'urlMedia': urlMedia,
					'urlFontawesome': urlFontawesome,
					'urlExpression': urlExpression,
					'urlBlockly': urlBlockly,
					'urlCommunity': urlCommunity,
					'urlEngine': urlEngine
				});
				break;
			}

			case ContextConstants.CONTEXT_CHANGE: {
				let pageId = action.get('pageId');
				let tableSchemaName = action.get('tableSchemaName');
				let recordId = action.get('recordId');

				context = state.merge({
					'pageId' : pageId,
					'tableSchemaName' : tableSchemaName,
					'recordId' : recordId
				});
				break;
			}

			case AdminSettingsConstants.CENTER_COLUMN_RESIZE:
			case ContextConstants.RESPONSIVE_MODE_CHANGE: {
				let responsiveMode = action.get('responsiveMode');
				context = state.set('responsiveMode', responsiveMode);
				break;	
			}

			case ContextConstants.ALL_RECORD_DATA_RECEIVED_CHANGE: {
				let allRecordDataReceived = action.get('allRecordDataReceived');

				context = state.set('allRecordDataReceived', allRecordDataReceived);
				break;	
			}

			case ContextConstants.SET_PAGE_RENDER_ID: {
				let pageRenderId = action.get('renderId');
				context = state.set('pageRenderId', pageRenderId);
				break;
			}
				
			case ContextConstants.ALL_RECORD_DATA_RECEIVED_CHANGE_WITH_PATH_CHANGE: {
				
				let allRecordDataReceived = action.get('allRecordDataReceived');
				let pageId = action.get('pageId');
				let tableSchemaName = action.get('tableSchemaName');
				let recordId = action.get('recordId');

				context = state.merge({
					'pageId' : pageId,
					'tableSchemaName' : tableSchemaName,
					'recordId' : recordId,
					'allRecordDataReceived' : allRecordDataReceived
				});
				break;	
			}

			case ContextConstants.ADD_RUNNING_ACTION: {
				let actionId = action.get('actionId');
				context = state.withMutations(function(state){
					if(!state.has('actionIds')) {
						state.set('actionIds', Immutable.Map());
					}
					state.setIn(['actionIds', actionId], false);
				});
				break;
			}

			case ContextConstants.UPDATE_DISPLAY_NOTIFICATION: {
				let actionId = action.get('actionId');
				let shouldDisplayNotification = action.get('shouldDisplayNotification');
				context = state.withMutations(function(state){
					if(!state.has('actionIds')) {
						state.set('actionIds', Immutable.Map());
					}
					state.setIn(['actionIds', actionId], shouldDisplayNotification);
				});
				break;
			}

			case ContextConstants.REMOVE_RUNNING_ACTION: {
				let actionId = action.get('actionId');
				let recalculateDisplayNotification = action.get('recalculateDisplayNotification');
				context = state.withMutations(function(state){
					if(!state.has('actionIds')) {
						state.set('actionIds', Immutable.Map());
					}
					state.deleteIn(['actionIds', actionId]);
					if(recalculateDisplayNotification) {
						state.set('displayNotification', !!state.get('actionIds').size);
					}
				});
				break;
			}

			default:
				context = state;
				break;
		}
		return context;
	}
}
const instance = new ContextStore(AppDispatcher);
export default instance;