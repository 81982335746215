/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container} from 'flux/utils';
import VisibilityPinPopoverV1 from './popover/visibility-v1';

// Actions
import AdminSettingsActions from '../../actions/admin-settings-actions';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import FieldSettingsStore from '../../stores/field-settings-store';
import RenderStore from '../../stores/render-store';

// Utils
import ObjectUtils from '../../utils/object-utils';
import UIUtils from '../../utils/ui-utils';

// Disappointments.
import $ from 'jquery';

/**
 * Container to VisibilityPin
 *
 * @class VisibilityPin
 * @extends {Component}
 */
class VisibilityPinV1 extends Component {
	constructor(props) {
		super(props);
		this.onPinClickHandler = this.onPinClickHandler.bind(this);
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf VisibilityPin
	 */
	static getStores() {
		return [AdminSettingsStore, FieldSettingsStore, RenderStore];
	}

	/**
	 * Determine if this pin is Disabled.
	 * @param {string} renderId Render ID for the component
	 * @param {string} subSettingIndex SubSetting if the renderId is not provided
	 * @return boolean
	 */
	static calculateIsDisabled(renderId, subSettingIndex) {
		if(renderId) {
			let renderObj = RenderStore.get(renderId);
	
			// If we dont have a renderObj (yet), then we can't calculate.
			if(!renderObj) { 
				return undefined;
			}
	
			if(renderObj.componentType === 'field') {
				return false;
			}
		} else if(typeof subSettingIndex === 'number') {
			return false;
		}

		// Pages don't have visibility, so it'll always be disabled.
		return true;
	}

	/**
	 * Determine if this pin is Used.
	 * @param {string} renderId Render ID for the component
	 * @param {string} subSettingIndex SubSetting if the renderId is not provided
	 * @param {string} subSettingSchemaName Schema Name of the setting that contains the sub setting config
	 * @return boolean
	 */
	static calculateIsUnused(renderId, subSettingIndex, subSettingSchemaName) {
		let renderObj = RenderStore.get(renderId);
		// If we dont have a renderObj (yet), then we can't calculate.
		if(!renderObj) { 
			return undefined;
		}
		
		if(renderObj.componentType === 'field') {
			// Get our parent
			let parentRenderObj = RenderStore.get(renderObj.renderParentId);
			if(parentRenderObj) {
				let fieldSettingsObj = FieldSettingsStore.getSettings(renderObj.componentId, parentRenderObj.componentId);

				// If we have a subSettingIndex and are the parent...
				if(typeof subSettingIndex === 'number' && fieldSettingsObj && fieldSettingsObj[subSettingSchemaName]) {
					let subSettingConfigs = ObjectUtils.getObjFromJSON(fieldSettingsObj[subSettingSchemaName]);
					let subSettingConfig  = subSettingConfigs[subSettingIndex];
					if(subSettingConfig && subSettingConfig.visibilityRules) {
						let visRules = subSettingConfig.visibilityRules;
						if(visRules && visRules.length) {
							return false;
						}
					}
				} else {
					if(fieldSettingsObj && fieldSettingsObj.visibilityRules && 
						(fieldSettingsObj.visibilityRules.length || typeof fieldSettingsObj.visibilityRules === 'object')) {
						return false;
					}
				}
			} else {
				return undefined;
			}
		}
		return true;
	}

	/**
	 * Returns the current State of the VisibilityPin Settings
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf VisibilityPin
	 */
	static calculateState(prevState, props) {
		let overlayActive = AdminSettingsStore.getIsOverlayActive('visibilityV1');
		let isDisabled = undefined;
		let isUnused = undefined;

		if(overlayActive) {
			// If we already have an isDisabled in the state, then use it...
			isDisabled = ( prevState && typeof prevState.isDisabled === 'boolean' 
				? prevState.isDisabled 
				: this.calculateIsDisabled(props.renderId, props.subSettingIndex, props.subSettingSchemaName));
			
			if (isDisabled === false) {
				isUnused = this.calculateIsUnused(props.renderId, props.subSettingIndex, props.subSettingSchemaName);
			}
		}

		return {
			// Only calculate this once...
			'isDisabled': isDisabled,
			'isUnused': isUnused,
			'overlayActive': overlayActive,
		}
	}

	/**
	 * onPinClickHandler - Handles pin click. 
	 */
	onPinClickHandler(event) {
		// Stop clicking "through" the pin, like on List column headers
		event.preventDefault();
		event.stopPropagation();

		// Hide the current popover.
		$('.popover').popover('hide');

		// Set the "controls add and edit" on the Admin Settings Store.
		AdminSettingsActions.onVisibilityControlsAddAndEdit(this.props.controlsAddAndEdit);

		// Open the settings panel on the right.
		// (targetOverlay, recordId, tableSchemaName, parentRecordId, parentTableSchemaName, mainTab,
		// 	subSettingSchemaName, subSettingIndex, renderId)
		UIUtils.openSettingsPanel('visibilityV1',
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 'settings',
			this.props.subSettingSchemaName, this.props.subSettingIndex, this.props.renderId
		);

		// Make sure the settings are toggled to visible.
		AdminSettingsActions.onSettingsListHideChange(false);
	}

	componentDidMount() {
		if (this.state.overlayActive && !this.state.isDisabled) {
			this.initializePin();
		}
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (!this.state.isDisabled && (
			this.props.recordId !== prevProps.recordId || 
			this.props.tableSchemaName !== prevProps.tableSchemaName ||  
			this.props.parentRecordId !== prevProps.parentRecordId || 
			this.props.parentTableSchemaName !== prevProps.parentTableSchemaName || 
			this.props.renderId !== prevProps.renderId || 
			(this.state.overlayActive && !prevState.overlayActive))
		)
		{
			this.initializePin();
		}
	}

	/**
	 * Initialize the popover for the pin
	 */
	initializePin() {
		let {  recordId, tableSchemaName, parentRecordId, parentTableSchemaName, 
			subSettingIndex, subSettingSchemaName, renderId } = this.props;

		// get popover content
		UIUtils.getPopoverDataContent( VisibilityPinPopoverV1, this.link,
			recordId, tableSchemaName, parentRecordId, parentTableSchemaName,
			subSettingIndex, subSettingSchemaName, renderId);
	}

	render() {
		let { overlayActive, isUnused, isDisabled } = this.state;
		/* isUnused adds the 'disabled' class because we are moving away from the old visibility pins */
		let pinDivClassAppend = this.props.className + ' ' + (isDisabled 
			? 'disabled' 
			: (isUnused 
				? 'disabled' 
				: ''));

		if(this.props.subSettingIndex !== AdminSettingsStore.getSubSettingIndex()) {
			pinDivClassAppend += ' de-select';
		}

		if( overlayActive ) {
			return <div 
						className={"pin pin-visibility " + pinDivClassAppend}
						ref={el => this.link = el}
						data-trigger="manual"
						data-toggle="popover"
						data-fallback-placement="flip"
						data-html="true"
						data-content=" "
						onClick={(event) => { if(!isUnused) { this.onPinClickHandler(event) }}}
					>
							<svg className="visibility-pin pin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
								<path d="M12 2C8.1 2 5 5.1 5 9c0 5.2 7 13 7 13s7-7.8 7-13C19 5.1 15.9 2 12 2z" fill="#BBB"/>
							</svg>
							<span className={"visibility fa fa-eye"} alt="Visibility"/>
					</div>
		} else {
			return null;
		}
	}
}

if ('development' === process.env.NODE_ENV) {
  VisibilityPinV1.propTypes = {
	className: PropTypes.string,
	controlsAddAndEdit: PropTypes.bool,
    parentRecordId: PropTypes.string,
    parentTableSchemaName: PropTypes.string,
    recordId: PropTypes.string.isRequired,
	subSettingIndex: PropTypes.number,
	subSettingSchemaName: PropTypes.string,
    tableSchemaName: PropTypes.string.isRequired,
  };
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(VisibilityPinV1, {withProps: true});
export default container;