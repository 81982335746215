import React, { Component } from 'react';
import RenderStore from '../stores/render-store';
import { Container } from 'flux/utils';

/**
 * We have had some timing issues (see ticket 10039) involving the visibility calculation
 * for child fields running before the render object exists in the store. In some cases, this
 * has caused fields not to display, and has also caused visibility pins not to receive their 
 * attachment ID, usually due to the recalculation involved in the visibility overlay. However,
 * the calculateState and componentDidUpdate methods of GridComponent itself contain too much work to 
 * have that component directly listen to the Render Store. As such, as a temporary measure,
 * we have wrapped the Grid Component inside a Render Store listener which listens to the Render Store
 * and only to the Render Store, and which is configured to, basically, serve as a glorified wrapper with no
 * extra re-renders once it has joined the Render Store, regardless of how many times the
 * Render Store updates.
 */
 class RenderStoreWrapper extends Component {
	/**
	 * Generic constructor; exists purely to instantiate the state
	 * 
	 * @param {object} props The component's props
	 */
	constructor(props) {
		super(props);
		this.state = {
			loaded: false
		};
	}

	/**
	 * 
	 */
	static getStores() {
		return [RenderStore];
	}

	/**
	 * Listens to the Render Store and tells us whether
	 * this grid component exists within it or not.
	 * 
	 * @param {object} prevState The previous state
	 * @param {object} props The component's props
	 */
	static calculateState(prevState, props) {
		let loaded = !!RenderStore.get(props.renderId);
		return {
			loaded
		}
	}

	/**
	 * Returns a loading message if we are still populating the Render Store and
	 * renders the Grid component otherwise.
	 */
	render() {
		if(!this.state.loaded) {
			return <div key={this.props.renderId}>Loading...</div>;
		} else {
			return <GridItem key={this.props.renderId} {...this.props} />;
		}
	}
}

RenderStoreWrapper.displayName = 'RenderStoreWrapper';

/**
 * Wrapper class used to hide overflow of FieldContainer.
 * Retrieves passed height from react-grid-layout and scrolls overflow.
 */
class GridItem extends Component {

  render() {
    // DM/RB - position:relative removed from here because it would only actually affect
    // the grab handle in the SE Corner and the expansion controls in the NE corner - 
    // and we actually DONT want them position'd relative, as they scroll off the component
    // that way.
    let myStyle = {padding: '0'};

    let newProps = Object.assign({}, this.props);
	delete newProps.contentShouldOverflow;
	newProps.style = newProps.childStyle;
	if(newProps.style && (newProps.style.width === 0 || newProps.style.width === '0px')) {
		console.warn('Zero or missing width in style for %s. Field may not display appropriately. Please report any instances of this message.', newProps.renderId, newProps.style);
	}
	if(newProps.style && newProps.style.position === 'absolute') {
		delete newProps.style.position;
	}
	// Delete the props that don't belong in a div
	delete newProps.childStyle;
	delete newProps.renderId;
	delete newProps.responsiveMode;
	delete newProps.currentMode;
	delete newProps.fieldShouldAutofit;
	delete newProps.gridInfo;
	delete newProps.fieldId;
	delete newProps.resizerIsActive;

    // DO NOT REMOVE THE SPREAD OPERATOR FROM THIS DIV!
    return (
		<div {...newProps}>
			<div 
				className="react-grid-item__inner container-fluid citdev-field-force-full-height"
				style={myStyle} 
				//   onFocus={this.onFocus}
				//   onBlur={this.onBlur}
				>
					{this.props.children}
			</div>
	  </div>
    );
  };
}

GridItem.displayName = 'CitDevGridItem';

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(RenderStoreWrapper, { withProps: true });

// We probably don't need this but to be safe
export {container};

// Refresh rate has been increased to avoid jankiness with scrollbars when calculating component height for autofit grid components
export default GridItem;
