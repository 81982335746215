/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';
// import PropTypes from 'prop-types';
import ReactDraggable from 'react-draggable';

// Popover Elements
import AppearancePinPopover from './popover/appearance';
import AutomationPinPopover from './popover/automation';
import CompliancePinPopover from './popover/compliance';
import DiscussionPinPopover from './popover/discussion';
import QueryPinPopover from './popover/query';
import SecurityPinPopover from './popover/security';
import VisibilityPinPopover from './popover/visibility';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';

// Utils
import UIUtils from '../../utils/ui-utils';

/**
 * Container to field type wrapper
 *
 * @class FieldTypeWrapperContainer
 * @extends {Component}
 */
class SettingsPreview extends Component {
	constructor(props) {
		super(props);
		this.onStop = this.onStop.bind(this);
		this.nodeRef = React.createRef(null);

		this.handleResize = this.handleResize.bind(this);

		this.state = {
			draggableBounds: {
				top: 0,
				left: -175,
				right: window.innerWidth - 175,
				bottom: window.innerHeight - 120
			}
		}
	}

	/**
	 * Return the stores we're listening to for changes.
	 *
	 * @return {array}  description
	 */
	static getStores() {
		return [ AdminSettingsStore ];
	}

	/**
	 * Calculate our state anytime a store changes.
	 *
	 * @param  {object} prevState
	 * @return {object}
	 */
	static calculateState(prevState) {
		let recordId = AdminSettingsStore.getRecordId(); 
		let renderId = AdminSettingsStore.getRenderId(); 
		let attachmentId = AdminSettingsStore.getAttachmentId();
		let tableSchemaName = AdminSettingsStore.getTableSchemaName();
		let parentRecordId = AdminSettingsStore.getParentRecordId();
		let parentTableSchemaName = AdminSettingsStore.getParentTableSchemaName();
		let subSettingIndex = AdminSettingsStore.getSubSettingIndex();
		let subSettingSchemaName = AdminSettingsStore.getSubSettingSchemaName();

		let selectedOverlay = AdminSettingsStore.getSelectedOverlay();

		let settingsHeaderSub1Obj = UIUtils.getSettingsHeaderSub1(recordId, tableSchemaName);
		let settingsHeaderSub2Obj = UIUtils.getSettingsHeaderSub2(parentRecordId, parentTableSchemaName);

		return {
			recordId: recordId,
			renderId: renderId,
			attachmentId: attachmentId,
			tableSchemaName: tableSchemaName,
			parentRecordId: parentRecordId,
			parentTableSchemaName: parentTableSchemaName,
			subSettingIndex: subSettingIndex,
			subSettingSchemaName: subSettingSchemaName,
			selectedOverlay: selectedOverlay,
			headerLabelSub1: settingsHeaderSub1Obj.label, 
			headerIconSub1: settingsHeaderSub1Obj.icon, 
			headerLabelSub2: settingsHeaderSub2Obj.label, 
			headerIconSub2: settingsHeaderSub2Obj.icon
		};
	}

	/**
	 * Method to ensure the settings preview stays within the bounds of the screen
	 */
	handleResize() {
		if(!this.isResizing) {
			this.isResizing = true;

			setTimeout(() => {
				if(this.nodeRef && this.nodeRef.current) {
					let settingPreviewRect = null;

					// get current position of the settings preview
					settingPreviewRect = this.nodeRef.current.getBoundingClientRect();
					let adjustX = settingPreviewRect.x;
					let adjustY = settingPreviewRect.y;

					// after resizing the window, if the settings preview is off the screen, put it back on the screen
					if((adjustX + 175) > window.innerWidth) {
						adjustX = window.innerWidth - 175;
					}
					if((adjustY + 60) > window.innerHeight) {
						adjustY = window.innerHeight - 120;
					}

					// save new position values
					localStorage.setItem('settingsPreviewLocation', JSON.stringify({x: adjustX, y: adjustY}));

					// windowResized is used by the position prop in the ReactDraggable component
					// this is how we reposition the settings preview
					// in componentDidUpdate, windowResized is changed to false immediately
					// If the position prop keeps a value, the user will no longer be able to drag and drop the settings preview
					this.setState({
						windowResized: true,
						resizePos: {x: adjustX, y: adjustY}
					})
				}
				this.isResizing = false;
			}, 250);
		}
	}
	
	/**
	 * Method to handle saving the position of the settings preview panel in local storage
	 * 
	*/
	onStop(DraggableEvent, DraggableData) {
		if(this.nodeRef && this.nodeRef.current) {
			// get the current position of the panel and save to local storage
			localStorage.setItem('settingsPreviewLocation', JSON.stringify({x: DraggableData.lastX, y: DraggableData.lastY}));
		}
	}

	componentDidMount() {
		// listener for window resizing that might put the settings preview off the screen
		window.addEventListener('resize', this.handleResize);
	}

	componentDidUpdate(prevProps, prevState) {
		// if the window has been resized, set new draggableBounds
		// the settings preview may have been repositioned and, if so, the position
		// prop will have also been used
		// need to set to 'false' so that the user can drag and drop the settings preview after this repositioning on window resize
		if(prevState.windowResized !== this.state.windowResized) {
			this.setState({
				windowResized: false,
				draggableBounds: {
					...prevState.draggableBounds,
					right: window.innerWidth - 175,
					bottom: window.innerHeight - 120
				}
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	render() {
		let { 
			recordId,
			renderId,
			attachmentId,
			tableSchemaName,
			parentRecordId,
			parentTableSchemaName,
			subSettingIndex,
			subSettingSchemaName,
			draggableBounds,
			resizePos,
			windowResized,
			selectedOverlay,
			headerLabelSub1, 
			headerIconSub1, 
			headerLabelSub2, 
			headerIconSub2,
		} = this.state;

		// get the settings preview location x, y coordinates
		let settingsPreviewLocation = localStorage.getItem('settingsPreviewLocation');

		// set the default position of the panel, if one has not been set place the panel just to the right of the left panel
		let defaultPosition = settingsPreviewLocation ? JSON.parse(settingsPreviewLocation) : {x: 340, y: 100};

		// Define the props we're going to send into the inner component.
		let innerComponentProps = {
			recordId: recordId, 
			renderId: renderId, 
			attachmentId: attachmentId,
			tableSchemaName: tableSchemaName,
			parentRecordId: parentRecordId,
			parentTableSchemaName: parentTableSchemaName,
			subSettingIndex: subSettingIndex,
			subSettingSchemaName: subSettingSchemaName,
			headerLabelSub1: headerLabelSub1, 
			headerIconSub1: headerIconSub1, 
			headerLabelSub2: headerLabelSub2, 
			headerIconSub2: headerIconSub2,
		};

		let innerComponent = '';
		switch(selectedOverlay) {
			case 'appearance':
				innerComponent = AppearancePinPopover;
			break;
			case 'query':
				innerComponent = QueryPinPopover;
			break;
			case 'automation':
				innerComponent = AutomationPinPopover;
			break;
			case 'discussion':
				innerComponent = DiscussionPinPopover;
			break;
			case 'visibility':
				innerComponent = VisibilityPinPopover;
			break;
			case 'security':
				innerComponent = SecurityPinPopover;
			break;
			case 'compliance':
				innerComponent = CompliancePinPopover;
			break;
			default:
				console.warn('Invalid Overlay in Settings Preview:', selectedOverlay);
			break;
		}
		let innerComponentJSX = React.createElement(innerComponent, innerComponentProps, null);

		return (
			<ReactDraggable
				bounds={draggableBounds}
				nodeRef={this.nodeRef}
				// move draggable by clicking on the header
				handle={'.popover-draggable-handle'}
				// If the window is zoomed in, maintain the correct x, y deltas
				scale={1}
				defaultPosition={ defaultPosition }
				onStop={this.onStop}
				// position is used to reposition after a window resize - see componentDidUpdate and handleResize methods for more notes
				position={windowResized ? resizePos : null}>
				<div ref={this.nodeRef} id="settings-preview" className="cd-tools cd-settings-preview">
					<div style={{ overflow: 'hidden',  height: '100%' }}>
						{innerComponentJSX}
					</div>
				</div>
			</ReactDraggable>
		);
	}
}

const container = Container.create(SettingsPreview);
export default container;