import React, { Component } from 'react';
import { Container } from 'flux/utils';

// import moment from 'moment';
import Immutable from 'immutable';

// Actions
// import ComplianceActions from '../../actions/compliance-actions';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import AuthenticationStore from '../../stores/authentication-store';
import ComplianceStore from '../../stores/compliance-store';
import FieldStore from '../../stores/field-store';

// Utils
import FieldComponentUtils from '../../utils/field-components';
import ObjectUtils from '../../utils/object-utils';
// import MapUtils from '../../utils/map-utils';
import { FieldActions } from '../../actions';


class ComplianceSetting extends Component {
	/**
	 * Creates instance of ComplianceSetting
	 *
	 * @memberOf ComplianceSetting
	 */
	constructor(props) {
		super(props);
		this._renderTag = this._renderTag.bind(this);
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, ComplianceStore, AuthenticationStore, FieldStore];
	}

	/**
	 * @static
	 * @param {any} prevState 
	 * @param {any} props 
	 * @returns {Object}
	 * @memberof ComplianceSetting
	 */
	static calculateState(prevState, props) {
		let newState = Object.assign({}, prevState);
		// Which Discussion are we in?
		let complianceId = AdminSettingsStore.getSettingRecordId();
		let complianceObj = ComplianceStore.get(complianceId) || Immutable.Map();
		let complianceEnabled = complianceObj.get('isActive') === 1;

		newState.complianceId = complianceId;
		newState.complianceObj = complianceObj;
		newState.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 850;
		newState.complianceEnabled = complianceEnabled;

		
		let availableTags = complianceObj.has('tags') ? complianceObj.get('tags').toJS() : [];
		
		if(complianceId) {
			let componentId = AdminSettingsStore.getRecordId();
			newState.componentId = componentId;
			
			let componentObj = FieldStore.get(componentId);
			let tags = componentObj.complianceTags ? ObjectUtils.getObjFromJSON(componentObj.complianceTags) : {};
			let complianceTags = tags[complianceId] ? tags[complianceId] : {};
			
			if(!prevState || prevState.tagsJSON !== componentObj.complianceTags) {
				// This is necessary to strip tags which no longer exist
				let newTags = {};
				availableTags.forEach(tag => {
					let tagId = tag.recordId;
					newTags[tagId] = complianceTags[tagId] ? complianceTags[tagId] : false;
				});
				tags[complianceId] = newTags;
				newState.tags = tags;
				newState.tagsJSON = JSON.stringify(tags);
			}
		}
		return newState;
	}

	_renderRadioButton(name, selected, [value, label, icon], index) {
		let iconJSX = null;
		if(icon !== undefined) {
			iconJSX = <span className={"radioIcon fa " + icon}></span>
		}
		return (
			<span key={index} className="radioOption">
				<input type='radio' value={value} name={name} id={name + index} checked={(value === selected ? 'checked' : '')} />
				<label htmlFor={name + index}>{iconJSX}{label}</label>
			</span>
			);
	}

	_onTagChange(recordId) {
		let {tags, componentId, complianceId} = this.state;
		let newTags = Object.assign({}, tags);

		if(!newTags[complianceId]) {
			newTags[complianceId] = {};
		}

		newTags[complianceId][recordId] = !newTags[complianceId][recordId];

		FieldActions.pushSettingToStore(componentId, 'complianceTags', JSON.stringify(newTags));
	}

	_renderTag(tag, index) {
		let {tags, complianceId, complianceEnabled} = this.state;
		let { disabledRetailRestrictions } = this.props;
		let complianceTags = tags && complianceId && tags[complianceId] ? tags[complianceId] : {};
		let tagId = tag.get('recordId');
		let tagName = tag.get('name');
		let tagDescription = tag.get('description');
		let checked = !!complianceTags[tagId];

		return (
			<div className="row mx-0" key={index}>
				<div className="col-1">
					{
						complianceEnabled && !disabledRetailRestrictions ?
						<input type='checkbox' checked={checked} onChange={this._onTagChange.bind(this, tagId)} /> :
						<input disabled='disabled' type='checkbox' checked={checked} />
					}
				</div>
				<div className="col-4 pl-0">
					<div className="compliance-tag-name">{tagName}</div>
				</div>
				<div className="col-7 compliance-tag-description-container">
					<div className="compliance-tag-description" dangerouslySetInnerHTML={ { __html: FieldComponentUtils.UIUtils.sanitizeHtml(tagDescription) } }></div>
				</div>
			</div>);
	}
	/**
	 * @returns - DOM for selected security group
	 * @memberof ComplianceSetting
	 */
	render() {
		let { 
			complianceId,
			complianceObj
		} = this.state;

		if (!complianceId) {
			return (
				<div className="select-setting">
					<div className="select-setting-text-wrap">
						Select a Compliance or "Add New" on the left.
					</div>
				</div>);
		} else {
			let tags = null;
			if(complianceObj) {
				tags = complianceObj.get('tags');
			}

			let categories = [];
			let categoryTags = {};

			tags.forEach(tag => {
				let categoryName = tag.get('categoryName');
				if(!categories.includes(categoryName)) {
					categories.push(categoryName);
				}
				if(!categoryTags[categoryName]) {
					categoryTags[categoryName] = [];
				}
				categoryTags[categoryName].push(tag);
			})
	
			if(categories.length > 0) {
				let categoriesSorted = categories.sort((a, b) => {
					return a.localeCompare(b, 'en', {'sensitivity': 'base'});
				});

				let categoryJSXArray = [];
				categoriesSorted.forEach((categoryName) => {
					// Re-enable to label uncategorized tags
					// categoryName = categoryName || '[Uncategorized]';
					categoryJSXArray.push(<div key={categoryName + 'wrapper'} className="categoryWrapper">
						<div key={categoryName} className="categoryNameWrapper">{categoryName}</div>
						{categoryTags[categoryName].sort((tagA, tagB) => {
							let tagNameA = tagA.get('name');
							let tagNameB = tagB.get('name');
							return tagNameA.localeCompare(tagNameB, 'en', {'sensitivity': 'base'});
						}).map(this._renderTag)}
					</div>);
				});
				
				return (
					<div className="compliance-setting-container">
						<div className="setting-global m-3">
							<div className="row">
								<div className="col-6">
									<label htmlFor="compliance-description" className="setting-label mb-1">Description</label>
								</div>
							</div>
							<div className="row">
								<div className="col-12">
									<div id='compliance-description' className="compliance-description" dangerouslySetInnerHTML={ { __html: FieldComponentUtils.UIUtils.sanitizeHtml(complianceObj.get('description')) } }></div>
								</div>
							</div>
						</div>
						<div className="optionList m-3">
							<div className="row mx-0">
								<div className="col-5 pl-0">
									<label className="setting-label">Tag</label>
								</div>
								<div className="col-7">
									<label className="setting-label">Tag Description</label>
								</div>
							</div>
							{categoryJSXArray}
							<small className="form-text text-muted my-2">
								Disclaimer: CitizenDeveloper provides multiple tools to help you stay compliant with your laws. Using these tools is optional and does not make you compliant, but instead is a resource in your first steps of the process. CitizenDeveloper does not offer legal advice and recommends you contact your legal counsel to find out how compliances affect you and how they should be handled by you.
							</small>
						</div>
					</div>
				);
			} else {
				return (
					<div className="select-setting">
						<div className="select-setting-text-wrap">
							No Tags found for this Compliance.
						</div>
					</div>);
			}
		} 
	}
}

const container = Container.create(ComplianceSetting, {withProps:true});
export default container;