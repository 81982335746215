/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Actions
import AdminSettingsActions from '../../actions/admin-settings-actions';
import InterfaceActions from '../../actions/interface-actions';
import RecordActions from '../../actions/record-actions';
import TableActions from '../../actions/table-actions';

// APIs
import RecordsAPI from '../../apis/records-api';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import ContextStore from '../../stores/context-store';
import RecordStore from '../../stores/record-store';
import TableStore from '../../stores/table-store';

// Utils
import ObjectUtils from '../../utils/object-utils';
import SecurityGroupUtils from '../../utils/security-group-utils';
// import socketFetcher from '../../utils/socket-fetcher';
import uuid from 'uuid';

import { ReactSVG } from 'react-svg';

class SecuritySettingsChooser extends Component {
	/**
	 * Creates instance of SecuritySettingsChooser
	 *
	 * @memberOf SecuritySettingsChooser
	 */
	constructor(props) {
		super(props);
		this._renderDisabledOption = this._renderDisabledOption.bind(this);
		this._renderOption = this._renderOption.bind(this);
		this.onAddClick = this.onAddClick.bind(this);
		this.onDeleteClick = this.onDeleteClick.bind(this);
		this.onResetClick = this.onResetClick.bind(this);
		this.onSaveClick = this.onSaveClick.bind(this);
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, RecordStore, TableStore];
	}
	
	/**
	 * onAddClick - Sets up a new recordId for the security setting UI to use.
	 *
	 * @param {object} event
	 */
	onAddClick(event){
		let newRecordId = uuid.v4();
		AdminSettingsActions.onSettingChange('', newRecordId);

		let newRecordObj = {};
		newRecordObj[newRecordId] = {};
		// Load the new record into the record store.
		RecordActions.onDataLoaded({
			securityGroup: newRecordObj
		});
	}

	/**
	 * onSaveClick - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	onSaveClick(event){
		// InterfaceActions.notification({level: 'warning', message: 'Reminder: In order for changes in Security Group membership or permissions to affect your application, please log out and log in.'});

		// Display notification to user
		let generalSaveNotification = 
			InterfaceActions.stickyNotification({level: 'info', message: 'Updating Security settings. This may take a few seconds, please wait...'});
		SecurityGroupUtils.cleanSecurityGroups()
			.then(() => {
				// Save the current table being edited to the DB (in case it wasn't covered by the table deletion promises)
				let targetTableSchemaName = this.props.targetTableSchemaName;
				let tableObj = TableStore.getByTableSchemaName(targetTableSchemaName, true);
				return TableActions.pushToDatabasePromise(tableObj);
			})
			.then(() => {
				InterfaceActions.clearStickyNotification(generalSaveNotification);

			})
			.catch((error) => {
				InterfaceActions.clearStickyNotification(generalSaveNotification);
				InterfaceActions.notification({level: 'error', message: 'Permission (Table) save error.  See your console for details.'});
				console.error(error);
			});
	}

	/**
	 * Marks the passed in security group ID as deleted.
	 *
	 * @param  {object} event
	 */
	onDeleteClick(securityGroupRecordId, event){
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Security Group Deleted.' });

		// Clear the selected Security Group
		AdminSettingsActions.onSettingChange('');
		
		// Mark it as deleted.
		let newRecordValues = {};
		newRecordValues['deleted'] = true;

		// Load the new record into the record store.
		RecordActions.updateRecord('securityGroup', securityGroupRecordId, newRecordValues);
	}

	/**
	 * onResetClick - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event
	 */
	onResetClick(event){
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Resetting Security Group(s) and Permissions...' });

		// Reset the Groups
		let securityGroupRecords = RecordStore.getRecords('securityGroup');
		Object.keys(securityGroupRecords).forEach(securityGroupRecordId => {
			let secGrpRecObj = securityGroupRecords[securityGroupRecordId];
			if(secGrpRecObj.deleted){
				RecordActions.updateRecord('securityGroup', securityGroupRecordId, { deleted : null});
			}
		})
		RecordsAPI.getAllRecordsByTable(ContextStore.getState(), 'securityGroup');

		// Reset the Table
		let targetTableSchemaName = this.props.targetTableSchemaName;
		let tableObj = TableStore.getByTableSchemaName(targetTableSchemaName);
		TableActions.pullFromDatabase(tableObj.recordId, true);
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 * @memberof QuerySettingsChooser
	 */
	onSettingClick(securityGroupRecordId, event) {
		event.preventDefault();

		if(!securityGroupRecordId) {
			return;
		}

		// Toggle to select and deselect the Setting
		if(AdminSettingsStore.getSettingRecordId() === securityGroupRecordId) {
			AdminSettingsActions.onSettingChange('');
		} else {
			AdminSettingsActions.onSettingChange(undefined, securityGroupRecordId);
		}
	}

	/**
	 * 
	 * @static
	 * @param {any} prevState 
	 * @returns {Object} - Query Setting list
	 * @memberof QuerySettingsChooser
	 */
	static calculateState(prevState, props) {
		let selectedSecurityGroupRecordId = AdminSettingsStore.getSettingRecordId();
		let targetTableSchemaName = props.targetTableSchemaName;
		let tableObj = TableStore.getByTableSchemaName(targetTableSchemaName);
		let tablePermissions = {};
		if(tableObj && tableObj.securityPermissions) {
			tablePermissions = ObjectUtils.getObjFromJSON(tableObj.securityPermissions);
		}

		// Generate User State Array
		let userStateArr = [];

		if(!tablePermissions['authenticated']) {
			tablePermissions['authenticated'] = {};
			tablePermissions['authenticated'].create = '1';
			tablePermissions['authenticated'].read = '1';
			tablePermissions['authenticated'].update = '1';
			tablePermissions['authenticated'].delete = '1';		
		}

		if(!tablePermissions['nonauthenticated']) {
			tablePermissions['nonauthenticated'] = {};
			tablePermissions['nonauthenticated'].create = '0';
			tablePermissions['nonauthenticated'].read = '0';
			tablePermissions['nonauthenticated'].update = '0';
			tablePermissions['nonauthenticated'].delete = '0';		
		}

		// Authenticated Users
		userStateArr.push({
			securityGroupRecordId: 'authenticated',
			label: 'All Authenticated Users',
			isSelected: (selectedSecurityGroupRecordId === 'authenticated'),
			type: 'builtIn',
			permissions: tablePermissions['authenticated']
		});

		// Unauthenticated Users
		userStateArr.push({
			securityGroupRecordId: 'nonauthenticated',
			label: 'All Non-Authenticated Users',
			isSelected: (selectedSecurityGroupRecordId === 'nonauthenticated'),
			type: 'builtIn',
			permissions: tablePermissions['nonauthenticated']
		});

		// Lookup and populate componentSettings with additional Security Groups.
		let securityGroupArr = []; 
		let securityGroups = RecordStore.getRecords('securityGroup');
		if(securityGroups !== null) {
			Object.keys(securityGroups).forEach(securityGroupRecordId => {
				let securityGroup = securityGroups[securityGroupRecordId];
				if(!securityGroup.deleted || (securityGroup.deleted && securityGroup.deleted.value !== true)) {
					securityGroupArr.push({
						securityGroupRecordId: securityGroupRecordId,
						label: (securityGroup.name ? securityGroup.name.value : '[ No Name ]'),
						isSelected: (selectedSecurityGroupRecordId === securityGroupRecordId),
						type: 'defined',
						permissions: (tablePermissions[securityGroupRecordId]
							? tablePermissions[securityGroupRecordId] : {})
					});
				}
			});
		} else {
			RecordsAPI.getAllRecordsByTable(ContextStore.getState(), 'securityGroup');
			securityGroupArr.push({
				label: 'Loading...',
				isSelected: false,
				type: 'defined'
			});
		}

		// Sort the results by setting name
		securityGroupArr.sort(function(a,b){
			let aLabel = a && a.label ? a.label.toLowerCase() : '';
			let bLabel = b && b.label ? b.label.toLowerCase() : '';
			if(aLabel === bLabel) {
				return 0;
			}
			if(aLabel < bLabel) { 
				return -1;
			}
			return 1;
		});

		return {
			securityGroupArr: securityGroupArr,
			userStateArr: userStateArr,
			logicInfo: {
				isSelected: false
			}
		}
	}

	/**
	 * Renders a single security group as a disabled LI option.
	 * 
	 * @param {Object} securityGroup 
	 * @param {string} securityGroup.securityGroupRecordId Record ID of the Security Group.
	 * @param {string} securityGroup.label Label for the option.
	 * @param {boolean} securityGroup.isSelected Is this option the selected UI option?
	 * @param {string} securityGroup.type builtIn | logic | defined
	 * @param {Object} securityGroup.permissions Optional - if empty there will be no C.R.U.D. displayed
	 * @param {string} securityGroup.permissions.create 1 | 0 - If Create is enabled.
	 * @param {string} securityGroup.permissions.read 1 | 0 - If Read is enabled.
	 * @param {string} securityGroup.permissions.update 1 | 0 - If Update is enabled.
	 * @param {string} securityGroup.permissions.delete 1 | 0 - If Delete is enabled.
	 * @param {number} index The Index of this option
	 * @return JSX
	 */
	_renderDisabledOption(securityGroup, index) {
		let labelClassNames = 'setting-label disabled';
		if(securityGroup.isSelected) { 
			labelClassNames += ' selected';
		}
		// let iconClassNames = 'disabled fa ';
		
		// switch(securityGroup.type) {
		// 	case 'builtIn':
		// 		iconClassNames += 'fa-user';
		// 	break;
		// 	case 'logic':
		// 		iconClassNames += 'fa-puzzle-piece';
		// 	break;
		// 	default:
		// 	case 'defined':
		// 		iconClassNames += 'fa-users';
		// 	break;
		// }

		return (<li key={index} className="nav-item">
					<div className="nav-link" onClick={this.onSettingClick.bind(this, securityGroup.securityGroupRecordId)}>
						{/* Temporarily removed the following div */}
						{/* <div className={"setting-icon " + iconClassNames} /> */}
						{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
						{/* Add this back in when the setting-icon is used again */}
						<div className="d-flex setting-text-container">
							<div style={{ flex: 1 }} className="setting-label setting-label-container">
								<div className={labelClassNames}><h4>{securityGroup.label}</h4></div>
							</div>
							<div style={{ minWidth: '25%' }} className="d-flex justify-content-between setting-value-container">
								<div className="setting-pattern-list" style={{ textAlign:'center', width: '100%' }}>
								</div>
							</div>
						</div>
					</div>
				</li>);
	}
	/**
	 * Renders a single security group as an LI option.
	 * 
	 * @param {Object} securityGroup 
	 * @param {string} securityGroup.securityGroupRecordId Record ID of the Security Group.
	 * @param {string} securityGroup.label Label for the option.
	 * @param {boolean} securityGroup.isSelected Is this option the selected UI option?
	 * @param {string} securityGroup.type builtIn | logic | defined
	 * @param {Object} securityGroup.permissions Optional - if empty there will be no C.R.U.D. displayed
	 * @param {string} securityGroup.permissions.create 1 | 0 - If Create is enabled.
	 * @param {string} securityGroup.permissions.read 1 | 0 - If Read is enabled.
	 * @param {string} securityGroup.permissions.update 1 | 0 - If Update is enabled.
	 * @param {string} securityGroup.permissions.delete 1 | 0 - If Delete is enabled.
	 * @param {number} index The Index of this option
	 * @return JSX
	 */
	_renderOption(securityGroup, index) {
		let labelClassNames = 'setting-label';
		if(securityGroup.isSelected) { 
			labelClassNames += ' selected';
		}

		let { disabledRetailRestrictions, onUpgradeClick } = this.props;

		/*************************
		    The following commented out statements are for the setting-icon, which is not currently in use
			Uncomment, when setting-icon is used again
		**************************/

		// let iconClassNames = 'fa ';
		
		// switch(securityGroup.type) {
		// 	case 'builtIn':
		// 		iconClassNames += 'fa-user';
		// 	break;
		// 	case 'logic':
		// 		iconClassNames += 'fa-puzzle-piece';
		// 	break;
		// 	default:
		// 	case 'defined':
		// 		iconClassNames += 'fa-users';
		// 	break;
		// }

		let deleteButton = null;
		if(securityGroup.type === 'defined') {
			deleteButton = <div className="fa fa-trash settings-icon-delete"
				onClick={( disabledRetailRestrictions ? onUpgradeClick : this.onDeleteClick.bind(this, securityGroup.securityGroupRecordId)) }></div>;
		}

		return (<li key={index} className="nav-item">
					<div className="nav-link">
						{/* Temporarily removed the following div */}
						{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
						{/* Add this back in when the setting-icon is used again */}

						{/* <div className={"setting-icon " + iconClassNames} /> */}
						<div className="d-flex setting-text-container">
							<div style={{ flex: 1 }} className="setting-label-container" onClick={this.onSettingClick.bind(this, securityGroup.securityGroupRecordId)}>
								<div className={labelClassNames}><h4>{securityGroup.label}</h4></div>
							</div>
							<div style={{ minWidth: '25%' }} className="d-flex justify-content-between setting-value-container">
								<div className="setting-pattern-list" style={{ textAlign:'center', width: '100%' }} onClick={this.onSettingClick.bind(this, securityGroup.securityGroupRecordId)}>
									{(securityGroup.permissions 
										? <div className='security-group-values'>
											<span className={(securityGroup.permissions['create'] === '1' ? 'selectedCRUDOption' : '')}>C.&nbsp;</span>
											<span className={(securityGroup.permissions['read']   === '1' ? 'selectedCRUDOption' : '')}>R.&nbsp;</span>
											<span className={(securityGroup.permissions['update'] === '1' ? 'selectedCRUDOption' : '')}>U.&nbsp;</span>
											<span className={(securityGroup.permissions['delete'] === '1' ? 'selectedCRUDOption' : '')}>D.</span>
										</div>
										: null)}
									</div>
								</div>
								{deleteButton}
							</div>
						</div>
				</li>);
	}

	/**
	 * 
	 * @returns - DOM of list of Query
	 * @memberof QuerySettingsChooser
	 */
	render() {
		// add this back in when using Custom Security Logic
		// let { logicInfo } = this.state;
		let { securityGroupArr, userStateArr } = this.state;
		let { disabledRetailRestrictions, onUpgradeClick } = this.props;
		let settingsList = [];

		// Process the Security Group Array
		settingsList.push(
			<li key="primary-header" className="settings-list-header">
				<div className="d-flex noMarginAdjustment">
					<ReactSVG 
						beforeInjection={svg => {
							svg.setAttribute('style', 'width: 22px; height: 22px');
						}}
						src={ContextStore.getUrlMedia() + "/icon-visibility.svg"} />
					<h3 style={{ lineHeight: '1.1' }}>Permissions by Security Group:</h3>
				</div>
			</li>);
		securityGroupArr.forEach(securityGroup => {
			settingsList.push(this._renderOption(securityGroup, settingsList.length));
		});
		settingsList.push(
			<li key={settingsList.length} className="nav-header d-flex">
				<button 
					key="add" 
					className={"btn btn-success " + (disabledRetailRestrictions ? 'cd-btn-disabled' : '')}
					form="appearance-form" 
					style={{
						marginTop: '10px',
						marginBottom: '15px'
					}}
					aria-label="Add Security Group"
					onClick={( disabledRetailRestrictions ? onUpgradeClick : this.onAddClick)}>
						Add Security Group
				</button>
			</li>);

		// Hard-Coded Security "Groups"
		settingsList.push(
			<li key="other-header" className="settings-list-header">
				<div className="d-flex">
					<ReactSVG 
						beforeInjection={svg => {
							svg.setAttribute('style', 'width: 22px; height: 22px');
						}}
						src={ContextStore.getUrlMedia() + "/icon-visibility.svg"} />
					<h3>Other Permissions:</h3>
				</div>
			</li>);
		userStateArr.forEach(securityGroup => {
			settingsList.push(this._renderOption(securityGroup, settingsList.length));
		});

		// Temporarily commenting the following out until it is used

		// settingsList.push(this._renderDisabledOption({
		// 	securityGroupRecordId: 'logic',
		// 	label: 'Custom Security Logic',
		// 	isSelected: logicInfo.isSelected,
		// 	type: 'logic'
		// }, settingsList.length));

		let buttons = null;
		if(disabledRetailRestrictions) {
			buttons = (<div key="button" className="btn-wrapper">
				<button 
					// key="submit" 
					className="btn btn-success btn-lg setting-list-button mr-3 ml-1" 
					form="appearance-form" 
					aria-label="Upgrade to use Security"
					style={{ width: '100%'}}
					onClick={onUpgradeClick}>
					Upgrade to use Security
				</button>
			</div>);
		} else {
			buttons = (<div key="button" className="btn-wrapper mx-2">
				<button 
					id={'securitySave'}
					title="Save (Ctrl-S)"
					key="submit" 
					className="btn btn-primary btn-lg setting-list-button w-50 mr-1" 
					form="appearance-form" 
					aria-label="Save"
					onClick={this.onSaveClick}>
					Save
				</button>
				<button 
					id={'securityReset'}
					title="Reset (Ctrl-R)"
					key="reset" 
					className="btn btn-warning btn-lg setting-list-button w-50 ml-1" 
					form="appearance-form" 
					aria-label="Reset" 
					onClick={this.onResetClick}>
					Reset
				</button>
			</div>);
		}

		return [
			<div key="list" className='settings-list-wrapper'>
				<ul key="list" className="nav flex-column">{settingsList}</ul>
			</div>,
			buttons];
	}
}
const container = Container.create(SecuritySettingsChooser, { withProps: true });
export default container;