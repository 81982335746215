import React, { Component } from 'react';
class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, info) {
		// You can also log the error to an error reporting service
		console.error(error, info);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (<div className="field-error parent">
				<div className="field-error child">
					Field could not be rendered.  Check your console for more information.
				</div>
			</div>);
		}
		return this.props.children;
	}
}

export default ErrorBoundary;