import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import {FieldModesConstants} from '../constants';
// import UIUtils from '../utils/ui-utils';
import GridHeightUtils from '../utils/grid-height-utils';

const ModeActions = {
	/**
	 * Sets the selected mode
	 * 
	 * @param {string} renderId render unique id to set the mode for
	 * @param {string} mode 'view' or 'edit'
	 */
	setMode(renderId, mode) {

		let startTime = +new Date();
		let RenderStore = require('../stores/render-store').default;
		let PageStore = require('../stores/page-store').default;
		let FieldStore = require('../stores/field-store').default;
		let PageModeStore = require('../stores/page-mode-store').default;
		let FieldModesStore = require('../stores/field-modes-store').default;
		let FieldSettingsStore = require('../stores/field-settings-store').default;
		let AdminSettingsStore = require('../stores/admin-settings-store').default;
		let activeOverlays = AdminSettingsStore.getActiveOverlays();

		let renderItem = RenderStore.getImmutable(renderId);
		// We need to recalculate the field being toggled and its children, as its mode is changing
		// and thus so might the children being displayed.
		let gridPromise = Promise.resolve([]);
		if(renderItem) {
			let componentType = renderItem.get('componentType');
			let componentId = renderItem.get('componentId');

			// This should always be field, but just in case
			let componentObj = componentType === 'page' ?
				PageStore.get(componentId) :
				FieldStore.get(componentId);

			if(componentType === 'field') {

				let attachmentId = renderItem.get('attachmentId');
				let parentRender = RenderStore.getImmutable(renderItem.get('renderParentId'));
				let pageRenderObj = RenderStore.getPageRenderObj(renderItem.get('renderId')) || {};

				let parentFieldPosition;
				let parentFieldPositionExtras;
				let parentAttachedFields;

				// If we're editing/saving/cancelling the child of a repeating grid,
				// it needs special handling because the parent/child setup is
				// different from everywhere else.
				if(parentRender && parentRender.get('repeatingGrid')) {
					let gridLayoutHeights = renderItem.get('gridLayoutHeights');
					if(gridLayoutHeights && gridLayoutHeights.toJS) {
						gridLayoutHeights = gridLayoutHeights.toJS();
					}
					let gridInfo = renderItem.get('gridInfo');
					if(gridInfo && gridInfo.toJS) {
						gridInfo = gridInfo.toJS();
					}
					let grid = {
						attachmentId,
						attachmentKey: renderItem.get('attachmentKey'),
						renderId,
						renderParentId: renderItem.get('renderParentId'),
						componentId,
						dataRecordId: renderItem.get('dataRecordId'),
						dataTableSchemaName: renderItem.get('dataTableSchemaName'),
						attachedFields: componentObj.attachedFields ? JSON.parse(componentObj.attachedFields) : [],
						gridLayoutHeights: gridLayoutHeights,
						fieldPosition: componentObj.fieldPosition,
						fieldPositionExtras: componentObj.fieldPositionExtras,
						gridInfo
					};

					gridPromise = GridHeightUtils.recalcRepeatedField(grid, pageRenderObj, activeOverlays, mode)
				} else {
					// For normal fields, get the parent information because we need to recalculate this
					// attachment ID "slot" in case the displayed field will change.
					// Beyond that, we need to get parent and grandparent information so that
					// local overrides will apply.
					if(parentRender) {
						let parentComponentType = parentRender.get('componentType');
						let parentComponentId = parentRender.get('componentId');
						// Local settings for the field being refreshed
						if(renderItem.get('componentType' === 'field')) {
							let localSettings = attachmentId
								? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, componentId, parentComponentId)
								: FieldSettingsStore.getSettings(componentId, parentComponentId);
							Object.assign(componentObj, localSettings);
						}

						if(parentComponentType === 'field') {
							let parentFieldObj = FieldStore.get(parentComponentId);
							// I think we actually need to check local settings on the parentFieldObj here
							let grandparentRender = RenderStore.getImmutable(parentRender.get('renderParentId'));
							if(grandparentRender) {
								let grandparentComponentId = grandparentRender.get('componentId');
								let parentAttachmentId = parentRender.get('attachmentId');
								let parentSettings = parentAttachmentId
									? FieldSettingsStore.getSettingsFromAttachmentId(parentAttachmentId, parentComponentId, grandparentComponentId)
									: FieldSettingsStore.getSettings(parentComponentId, grandparentComponentId);
								Object.assign(parentFieldObj, parentSettings);
							}
							parentFieldPosition = parentFieldObj && parentFieldObj.fieldPosition ? JSON.parse(parentFieldObj.fieldPosition) : {};
							parentFieldPositionExtras = parentFieldObj && parentFieldObj.fieldPositionExtras ? JSON.parse(parentFieldObj.fieldPositionExtras) : {};
							parentAttachedFields = parentFieldObj && parentFieldObj.attachedFields ? JSON.parse(parentFieldObj.attachedFields) : [];
						} else {
							// Pages shouldn't even be in here, but just in case.
							let PageStore = require('../stores/page-store').default;
							let pageObj = PageStore.get(parentRender.get('componentId'));
							parentFieldPosition = pageObj && pageObj.fieldPosition ? JSON.parse(pageObj.fieldPosition) : {};
							parentFieldPositionExtras = pageObj && pageObj.fieldPositionExtras ? JSON.parse(pageObj.fieldPositionExtras) : {};
							parentAttachedFields = pageObj && pageObj.attachedFields ? JSON.parse(pageObj.attachedFields) : [];
						}
					}

					// Build the parent and child grid information 
					// and then re-use our logic specifically for refreshing a field
					let fieldPosition = componentObj && componentObj.fieldPosition ? JSON.parse(componentObj.fieldPosition) : {};
					let fieldPositionExtras = componentObj && componentObj.fieldPositionExtras ? JSON.parse(componentObj.fieldPositionExtras) : {};
					let attachedFields = componentObj && componentObj.attachedFields ? JSON.parse(componentObj.attachedFields) : [];


					let dataRecordId = renderItem.get('dataRecordId');
					let dataTableSchemaName = renderItem.get('dataTableSchemaName');

					let availableModes = FieldModesStore.getAvailableModes(renderId);
					let parentAvailableModes = parentRender && parentRender.get('componentType') === 'page'
						? PageModeStore.getAvailableModes(parentRender.get('componentId')) :
						(parentRender ? FieldModesStore.getAvailableModes(parentRender.get('renderId')) : []);

					
					// Get the relevant attached fields
					let parentAttachedFieldResults = RenderStore.getAttachedFields(renderItem.get('renderParentId')) || [];
					if(parentAttachedFieldResults) {
						// Put these in the same format as that produced by FieldComponentUtils.visibility.runAttachedFieldVisibility
						parentAttachedFieldResults.forEach((att, index) => {
							att.fieldId = att.recordId;
							let modes = FieldModesStore.getMode(att.renderId);
							if(modes) {
								att.availableModes = modes.availableModes;
								att.currentMode = modes.currentMode;
							}
							att.order = index;
						});
					}
					let gridInfo = renderItem.get('gridInfo');
					let parentGrid = {
						attachedFields: parentAttachedFields,
						fieldPosition: parentFieldPosition,
						fieldPositionExtras: parentFieldPositionExtras,
						attachmentId: parentRender ? parentRender.get('attachmentId') : '',
						parentRenderId: parentRender ? parentRender.get('renderId') : '',
						grandparent: parentRender ? parentRender.get('renderParentId') : '',
						dataRecordId: parentRender ? parentRender.get('dataRecordId') : '',
						dataTableSchemaName: parentRender ? parentRender.get('dataTableSchemaName') : '',
						componentId: parentRender ? parentRender.get('componentId') : '',
						componentType: parentRender ? parentRender.get('componentType') : '',
						availableModes: parentAvailableModes && parentAvailableModes.toJS ? parentAvailableModes.toJS() : parentAvailableModes,
						gridInfo: parentRender && parentRender.get('gridInfo') ? parentRender.get('gridInfo').toJS() : {},
						gridLayoutHeights: parentRender && parentRender.get('gridLayoutHeights') ? parentRender.get('gridLayoutHeights').toJS() : {},
						attachedFieldsResults: parentAttachedFieldResults
					};
					let childGrid = {
						attachmentId: renderItem.get('attachmentId'),
						attachmentKey: renderItem.get('attachmentKey'),
						renderId: renderItem.get('renderId'),
						renderParentId: renderItem.get('renderParentId'),
						componentId: renderItem.get('componentId'),
						componentType: renderItem.get('componentType'),
						dataRecordId,
						dataTableSchemaName,
						fieldPosition: fieldPosition,
						fieldPositionExtras: fieldPositionExtras,
						attachedFields: attachedFields,
						gridLayoutHeights: renderItem.get('gridLayoutHeights'),
						gridInfo: gridInfo && gridInfo.toJS ? gridInfo.toJS() : gridInfo,
						modes: availableModes && availableModes.toJS ? availableModes.toJS() : availableModes
					}

					gridPromise = GridHeightUtils.recalcSingleField(parentGrid, childGrid, pageRenderObj, activeOverlays, mode);
				}

			} else {
				// Pages shouldn't even be in here, but in case they are, we have a special method just for pages
				gridPromise = GridHeightUtils.initiatePage(
					componentObj, renderItem.get('dataRecordId'),
					renderItem.get('dataTableSchemaName'), renderId, renderItem.get('renderParentId'),
					activeOverlays, mode
				);
			}
		}

		gridPromise
			.then(grids => {
				AppDispatcher.dispatch(Immutable.fromJS({
					type: FieldModesConstants.SET_MODE,
					renderId: renderId,
					mode: mode,
					lastChildCalculation: startTime,
					grids: grids
				}));
			})
			.catch(error => {
				console.error('Error recalculating attached fields when changing page mode.', error);
			});
	},
	/**
	 * Sets the available modes for a field instance given a render id
	 * 
	 * @param {string} renderId
	 * @param {string[]} availableModes
	 */
	setAvailableModes(renderId, availableModes) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: FieldModesConstants.SET_AVAILABLE_MODES,
			renderId: renderId,
			availableModes: availableModes
		}));
	}
};

export default ModeActions;