import React, { Component } from 'react';
import PropTypes from 'prop-types';

//Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';

// Constants
import Upgrade from '../../../constants/help-upgrade-constants';

// Components
import EnableModal from './enable-modal.react';
import DisableModal from './disable-modal.react';

// Utils
import FieldComponentUtils from '../../../utils/field-components';
import UIUtils from '../../../utils/ui-utils';
import uuid from 'uuid';

/**
 * Class for tab settings
 */
export default class ComplianceSettingsUI extends Component {

	/**
	 * Renders ComplianceSettingsUI
	 */
	render() {
		let { disabledRetailRestrictions } = this.props;
		let compliances = this.props.compliances ? this.props.compliances : [];
		return (
			<div>
				<table width="100%">
					<thead>
						<tr>
							<th style={{width: '220px'}}>Compliance Assistance Tool</th>
							<th>Description</th>
							<th style={{width: '100px'}}></th>
						</tr>
					</thead>
					<tbody>
						{compliances.map((complianceObj, index) => {
							let checked = complianceObj.isActive === 1;
							return (
								<tr key={complianceObj.recordId}>
									<td>{complianceObj.name}</td>
									<td dangerouslySetInnerHTML={{ __html: FieldComponentUtils.UIUtils.sanitizeHtml(complianceObj.description) }} ></td>
									<td>
										<button 
										className={'btn ' + (checked ? "styledCheckbox selected " : "styledCheckbox ")
														+ (disabledRetailRestrictions ? 'cd-btn-disabled' : '')}
										onClick={(disabledRetailRestrictions 
											? () => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_COMPLIANCE) }
											: this._openConfirmModal.bind(this, checked, complianceObj, index))}
										>
											{ checked ? 'Enabled' : 'Disabled' }
											<span className={checked ? 'fa fa-check-square-o' : 'fa fa-square-o'}></span>
										</button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
				<small className="form-text text-muted">
					Disclaimer: CitizenDeveloper provides multiple tools to help you stay compliant with your laws. Using these tools is optional and does not make you compliant, but instead is a resource in your first steps of the process. CitizenDeveloper does not offer legal advice and recommends you contact your legal counsel to find out how compliances affect you and how they should be handled by you.
				</small>
			</div>
		);
	}

	_onChange(index) {
		let compliances = this.props.compliances ? this.props.compliances : [];
		let complianceObj = compliances[index];
		if (complianceObj) {
			complianceObj.isActive = complianceObj.isActive === 1 ? 0 : 1;
		}
		this.props.onChange(compliances);
	}

	_openConfirmModal(checked, complianceObj, index) {
		let modalId = uuid.v4();
		if(checked) {
			AdminSettingsActions.openModal(modalId, <DisableModal key={modalId} modalId={modalId} complianceName={complianceObj.name} onConfirm={this._onChange.bind(this, index)} />);
		} else {
			AdminSettingsActions.openModal(modalId, <EnableModal key={modalId} modalId={modalId} complianceName={complianceObj.name} onConfirm={this._onChange.bind(this, index)} />);
		}
	}
}

if ('development' === process.env.NODE_ENV) {
	ComplianceSettingsUI.propTypes = {
		page: PropTypes.object,
		onChange: PropTypes.func,
		onSubmit: PropTypes.func
	};
}