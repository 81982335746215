/**
 * Download all the files that makeup the metadata
 *
 * @param {string} metadataPath
 * @param {object} params
 * @param {object} files
 */
function fetchSplitMetadata(metadataPath, params, files) {
	return new Promise((resolve, reject) => {
			Promise.all(files.map((path) => {
				let pathURL = new URL(path, metadataPath);
				return fetchMetadata(pathURL.toString(), params);
			}))
			.then((objects) => {
				resolve(Object.assign({}, ...objects));
			})
			.catch(reject);
	});
}

/**
 * 
 * @param {*} metadataPath 
 * @param {*} params 
 * @param {*} retries 
 */
export default function fetchMetadata(metadataPath, params, retries) {
	retries = retries ? retries : 0;

	return new Promise(function (resolve, reject) {
		fetch(metadataPath, params)
			.then(function (response) {
				if (response.status === 403 || response.status === 404) {
					// 403 errors are currently coming back from the CDN when the file isn't found
					// 404 is here in case they choose... more... wisely.
					// In either case, just return an empty array
					return resolve([]);
				} else if (response.status >= 200 && response.status < 500) {
					response
						.text()
						.then((text) => {
							if(!text) {
								return {};
							}
							if (!text.startsWith('{') && !text.startsWith('[')) {
								// Value is base64 encoded and first needs decoding
								text = atob(text);
							}
							return JSON.parse(text);
						})
						.then((json) => {
							if (json.files) {
								return fetchSplitMetadata(metadataPath, params, json.files);
							} else {
								return json;
							}
						})
						.then((json) => {
							resolve(json);
						})
						.catch(() => {
							// Suppress warning: no records whose IDs started with that character were found. Move along, move along.
							return resolve([]);
						});
				} else if (response.status >= 500 &&  response.status < 600 && retries < 5) {
					// Retry exponentially, +/- a random amount between 0 and 3 seconds if retrying in more than 2 seconds
					let retryTime = retries > 1 ? Math.pow(2, retries) + (Math.random() * 6 - 3) : Math.pow(2, retries);
					return new Promise(function (resolve, reject) {
						setTimeout(function () {
							fetchMetadata(metadataPath, params, retries + 1).then(resolve).catch(reject);
						}, Math.floor(retryTime * 1000));
					}).then(resolve).catch(reject);
				} else {
					response.text().then(responseText => {
						return reject(new Error('Error fetching records- ' + responseText +
							' Response was: ' + response.status + ' (' + response.statusText + ')'));
					});
				}
			})
			.catch(function (error) {
				if (retries < 5) {
					// Retry exponentially, +/- a random amount between 0 and 3 seconds if retrying in more than 2 seconds
					let retryTime = retries > 1 ? Math.pow(2, retries) + (Math.random() * 6 - 3) : Math.pow(2, retries);
					return new Promise(function (resolve, reject) {
						setTimeout(function () {
							fetchMetadata(metadataPath, params, retries + 1).then(resolve).catch(reject);
						}, Math.floor(retryTime * 1000));
					}).then(resolve).catch(reject);
				} else {
					reject(error);
				}
			});
	});
}