import React, {Component} from 'react';
import PropTypes from 'prop-types';
/**
 * Component for error componponent
 */
export default class ErrorField extends Component {
  /**
   * Renders message for errors
   */
  render() {
    let {message} = this.props;
    return (
      <div className="field-error parent">
        <div className="field-error child">
          { message || "Field could not be rendered." }
        </div>
      </div>);
  }
}

if ('development' === process.env.NODE_ENV) {
	ErrorField.propTypes = {
    message: PropTypes.string
  };
}
