import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import BaseSettingForm from './base-setting-form.react';
// import DataTypes from '../../constants/data-types';
/**
 * Component for Select - Shows a drop down of options, with Name:Value from a Select Options component
 * 
 * @class Select
 * @extends {Component}
 */
export default class Select extends BaseSettingForm {
	/**
	 * Creates an instance of Select
	 * 
	 * @param {Object} props
	 * @constructor
	 * 
	 * @memberof Select
	 */
	constructor(props) {
		super(props);
		this.renderOption = this.renderOption.bind(this);
	}

	/**
	 * Renders Select option
	 *
	 * @param {String} option option to render, has .name and .value
	 * @param {Number} index
	 * @return React
	 *
	 * @memberof Select
	 */
	renderOption(option, index) {
		let {ommit} = this.props;
		if(ommit && ommit.includes(option.value)) {
			return null;
		}
		if(option.disabled || this.props.disabled){
			return  <option key={this.props.fieldSchemaName +'-'+ index} value={option.value} disabled>{option.name}</option>;
		}
		return <option key={this.props.fieldSchemaName +'-'+ index} value={option.value}>{option.name}</option>;
	}
	/**
	 * Render the Select
	 * 
	 * @returns React
	 * 
	 * @memberof Select
	 */
	render() {
		let {onChange, fieldSchemaName, value, required, selectOptions} = this.props,
			modifiedOptions = _.values(selectOptions),
			defaultOpt = _.find(modifiedOptions, (option) => {return !!option.default}) || {};
		return(
			<select id={fieldSchemaName} required={required} className="form-control" value={value ? value : defaultOpt.value || ''} onChange={onChange}>
				{modifiedOptions.map(this.renderOption)}
			</select>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	Select.propTypes = Object.assign({}, BaseSettingForm.propTypes, {
		value: PropTypes.any,
		required: PropTypes.bool,
		onChange: PropTypes.func, 
		fieldSchemaName: PropTypes.string, 
		selectOptions: PropTypes.array,
		ommit: PropTypes.array
	});
}
