import AppDispatcher from '../dispatcher/app-dispatcher';
import ProcessingConstants from '../constants/processing-constants';
import Immutable from 'immutable';

const ProcessingActions = {

	/**
	 * Adds an action into the list of currently running actions
	 * 
	 * @param {string} actionId 
	 */
	addActionId(actionId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ProcessingConstants.ADD_RUNNING_ACTION_ID,
			'actionId': actionId
		}));
	},

	/**
	 * Updates the Notification Flag for a Running action
	 *
	 * @param {string} actionId
	 * @param {boolean} shouldDisplayNotification
	 */
	updateShouldDisplayNotification(actionId, shouldDisplayNotification) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ProcessingConstants.UPDATE_ACTION_DISPLAY_NOTIFICATION,
			'actionId': actionId,
			'shouldDisplayNotification': shouldDisplayNotification
		}));
	},

	/**
	 * Removes an action from the list of currently running actions
	 * 
	 * @param {string} actionId 
	 */
	removeActionId(actionId) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ProcessingConstants.REMOVE_RUNNING_ACTION_ID,
			'actionId': actionId,
		}));
	},

	/**
	 * Sets the percentage of an upload
	 *
	 * @param {string|number} percentage
	 */
	addPercentage(percentage) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ProcessingConstants.SET_RUNNING_PERCENTAGE,
			'percentage': percentage
		}));

	},

	/**
	 * Removes the percentage of an upload
	 *
	 */
	removePercentage() {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ProcessingConstants.CLEAR_RUNNING_PERCENTAGE
		}));
		
	},

	/**
	 * Shows the reload application UI
	 *
	 * @param {string|number} percentage
	 */
	showReloadUI(force) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ProcessingConstants.SHOW_RELOAD_UI,
			'force': force
		}));
	},

	/**
	 * Shows the maintenance mode UI
	 *
	 * @param {string|number} percentage
	 */
	maintenanceUI(message) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': ProcessingConstants.SHOW_MAINTENANCE_UI,
			'message': message
		}));
	}
};

export default ProcessingActions;