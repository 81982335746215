import AppDispatcher from '../dispatcher/app-dispatcher';

import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import { PageConstants } from '../constants/page-constants';
import GridUtils from '../utils/grid';

/**
 * Core store that contains page records
 *
 * @class PageStore
 * @extends {ReduceStore}
 */
class PageStore extends ReduceStore {
	/**
	 * getInitialState - initial state for PagesStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.Map({
			allPulledFromDatabase: false,
			records: Immutable.Map()
		});
	}

	/**
	 * Called every time an action is dispatched (for any store)
	 *
	 * @param {Object} state - current state of this store
	 * @param {Object} action - action that's coming in
	 * @returns {Object} new state after the action has been processed.
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			case PageConstants.PAGE_DELETE_FROM_DATABASE: {
				// Deal with "cleaning" any dirty flags in the future.
				return state;
			}
			case PageConstants.PAGE_DELETE_FROM_STORE: {
				// Delete the record in the state['records']['recordId'] spot
				return state.deleteIn(['records', action.get('recordId')]);
			}
			case PageConstants.PAGE_PUSH_TO_DATABASE: {
				let pageObject = action.get('pageObject');
				let newState = state;
				// Clean up any dirty values which have now been saved
				if(pageObject) {
					let recordId = pageObject.get('recordId');
					newState = state.withMutations(state => {
						let oldRecord = state.getIn(['records', recordId]) || Immutable.Map();
						oldRecord = oldRecord.withMutations(oldRecord => {
							pageObject.forEach((newSettingValue, settingKey) => {
								let oldValue = oldRecord.get(settingKey) || Immutable.Map();
								let newValue = oldValue.withMutations(oldValue => {
									oldValue.set('value', newSettingValue);
									oldValue.set('isDirty', false);
									oldValue.set('originalValue', null);
								});
								oldRecord.set(settingKey, newValue);
							});
						});
						// I believe that we want to merge deep in here as well
						state.mergeDeepIn(['records', recordId], oldRecord);
					});
				}
				return newState;
			}
			case PageConstants.PAGE_PUSH_TO_STORE: {
				let recordId = action.get('recordId');
				let recordProperties = action.get('recordProperties');
				let forceClean = action.get('forceClean');



				// @todo This can be removed, and placed in the attachedFields field type
				// including the reconcileFieldPositionsWithAttachedFields function.
				// For an example, check out the screen size management field type.
				if (recordProperties.has('attachedFields')) {
					let fieldPositionJSON = '';
					let fieldPositionObj = undefined;

					if (recordProperties.has('fieldPosition')) {
						// If we've passed in a new fieldPosition object
						fieldPositionJSON = recordProperties.get('fieldPosition');
					} else if (state.hasIn(['records', recordId])) {
						// If we have this record in our state already...
						let positionFromState = state.hasIn(['records', recordId, 'fieldPosition', 'value']) ?
							state.getIn(['records', recordId, 'fieldPosition', 'value']) :
							undefined;
						if (positionFromState) {
							fieldPositionJSON = positionFromState;
						}
					}

					// Convert JSON to Object
					try {
						fieldPositionObj = JSON.parse(fieldPositionJSON);
					} catch (e) {
						console.warn('Unable to parse layouts JSON: ', fieldPositionJSON);
					}

					if (!fieldPositionObj || !fieldPositionObj['lg']) {
						fieldPositionObj = { lg: [], md: [], sm: [] };
					}

					// If so, lets update the fieldposition
					recordProperties = recordProperties.set('fieldPosition', GridUtils.reconcileFieldPositionsWithAttachedFields(fieldPositionObj, recordProperties.get('attachedFields')));
				}

				// Merge the recordProperties into the state['records']['recordId'] object... and return it.
				// Merge the recordProperties into the state['records']['recordId'] object... and return it.
				return recordId && recordProperties ? state.withMutations(state => {
					let oldRecord = state.getIn(['records', recordId]) || Immutable.Map();
					let newRecord = oldRecord.withMutations(newRecord => {
						recordProperties.forEach((newSettingValue, settingKey) => {
							if(settingKey === 'automation') {
								// @TODO: Should we do some type of setup about not overwriting existing settings?
								
								newSettingValue.forEach((triggerVal, triggerName) => {
									let oldValue = oldRecord.hasIn(['automation-' + triggerName, 'value']) ?
										oldRecord.getIn(['automation-' + triggerName, 'value']) :
										null;
									let isDirty = forceClean ? false : compareAutomation(oldValue, triggerVal);
									// Ensure triggerVal is a string
									triggerVal = triggerVal && typeof triggerVal === 'object' ?
										JSON.stringify(triggerVal) :
										triggerVal;
									newRecord.set('automation-' + triggerName, Immutable.fromJS({
										value: triggerVal,
										isDirty: isDirty,
										originalValue: isDirty ? oldValue : null
									}));
								});
								newRecord.set('automation', Immutable.fromJS({
									value: null,
									isDirty: false,
									originalValue: null
								}));
							} else {
								if (oldRecord.has(settingKey)) {
									let oldValue = oldRecord.has(settingKey) ? oldRecord.get(settingKey) : Immutable.Map();
									let newValue = oldValue.withMutations(oldValue => {
										if (forceClean) {
											oldValue.set('value', newSettingValue);
											oldValue.set('isDirty', false);
											oldValue.set('originalValue', null);
										} else {
											// If we're not already dirty and the original value hasn't been set, then set it
											if (!oldValue.get('isDirty') && oldValue.get('originalValue') === null) {
												oldValue.set('originalValue', oldValue.get('value'));
											}
	
											if (oldValue.get('originalValue') === newSettingValue) {
												// If the new value is the original value, then revert it and clear the isDirty flag
												oldValue.set('value', newSettingValue);
												oldValue.set('isDirty', false);
												oldValue.set('originalValue', null);
											} else {
												// Otherwise, just set the new value and mark it as dirty
												oldValue.set('value', newSettingValue);
												oldValue.set('isDirty', true);
											}
										}
									});
									newRecord.set(settingKey, newValue);
								} else {
									newRecord.set(settingKey, Immutable.fromJS({
										value: newSettingValue,
										isDirty: forceClean ? false : true,
										inConflict: false,
										conflictValue: null,
										originalValue: forceClean ? null : ''
									}));
								}
							}
						});
					});
					// Merge the recordProperties into the state['records']['recordId'] object... and return it.
					// We want mergeDeepIn and not set in order to preserve old values
					state.mergeDeepIn(['records', recordId], newRecord);
					return state;
				}) : state;
			}
			case PageConstants.PAGE_PUSH_AUTOMATION_TO_STORE: {
				let recordId = action.get('recordId');
				let triggerName = action.get('triggerName');
				let automation = action.get('automation');

				let oldValue = state.hasIn(['records', recordId, 'automation-' + triggerName]) ?
					state.getIn(['records', recordId, 'automation-' + triggerName]) :
					Immutable.Map();
				let isDirty = compareAutomation(oldValue, automation);
				// Make sure automation is a string for consistency
				automation = automation && typeof automation === 'object' ?
					JSON.stringify(automation) :
					automation;
				return state.withMutations(state => {
					state.setIn(['records', recordId, 'automation-' + triggerName], Immutable.fromJS({
						value: automation,
						isDirty: isDirty,
						oldValue: isDirty ? oldValue : null
					}));
				});
			}
			case PageConstants.PAGE_PUSH_CHILD_CONFIGURATION_TO_STORE: {
				// @TODO: Update this once we split child configurations
				let parentRecordId = action.get('parentRecordId');
				let childRecordId = action.get('childRecordId');
				let settingSchemaName = action.get('settingSchemaName');
				let value = action.get('value');
				return state.withMutations(state => {
					if(childRecordId) {
						let childConfigurations = state.hasIn(['records', parentRecordId, 'childConfigurations', 'value']) ?
							state.getIn(['records', parentRecordId, 'childConfigurations', 'value']) :
							'';
						let childConfigurationsObj = {};
						try {
							childConfigurationsObj = childConfigurations ? JSON.parse(childConfigurations) : childConfigurationsObj;
						} catch(err) {
							console.error('Unable to parse child configurations in parent record %s. Value was %s', parentRecordId, childConfigurations);
						}
						if(!childConfigurationsObj[childRecordId]) {
							childConfigurationsObj[childRecordId] = {};
						}
						// We want to delete null and undefined values; they're not valid overrides
						if(value !== null && typeof value !== 'undefined') {
							childConfigurationsObj[childRecordId][settingSchemaName] = value;
						} else {
							delete childConfigurationsObj[childRecordId][settingSchemaName];
						}
						let newChildConfigurations = JSON.stringify(childConfigurationsObj);
						let isDirty = newChildConfigurations === childConfigurations ? false : true;
						state.setIn(['records', parentRecordId, 'childConfigurations', 'value'], newChildConfigurations);
						state.setIn(['records', parentRecordId, 'childConfigurations', 'isDirty'], isDirty);
						if(isDirty) {
							state.setIn(['records', parentRecordId, 'childConfigurations', 'oldValue'], childConfigurations);
						} else {
							state.setIn(['records', parentRecordId, 'childConfigurations', 'oldValue'], null);
						}
					}
				});
			}
			case PageConstants.PAGE_RECEIVE_BROADCAST: {
				// This is similar to pulling from the database, but needs to consider conflict work
				let records = action.get('records');
				return state.withMutations(newState => {
					records.forEach((record) => {
						let recordId = record.get('recordId');
						let oldRecord = newState.getIn(['records', recordId]) || Immutable.Map();
						let newRecord = oldRecord.withMutations(newRecord => {
							record.get('properties').forEach((value, key) => {

								// JSON.parse any automation triggers in the DB
								if(key.startsWith('automation-') && value) {
									try {
										// In some cases, the automation has already been turned into an object for us
										value = typeof value === 'string' ? JSON.parse(value) : value;
										value = Immutable.fromJS(value);
									} catch(err) {
										console.error('Error: unable to parse JSON automation for trigger %s. Value was', key, value);
									}
								}
								// Update the value
								
								let isDirty = oldRecord.hasIn([key, 'isDirty']) ? oldRecord.getIn([key, 'isDirty']) : false;
								if(!isDirty) {
									// If it's not dirty, then just update the value
									newRecord.setIn([key, 'value'], value);
									newRecord.setIn([key, 'isDirty'], false);
									newRecord.setIn([key, 'originalValue'], null);
								} else {
									let dirtyValue = oldRecord.getIn([key, 'value']);
									let hasChanged = dirtyValue !== value;
									if(key.startsWith('automation-')) {
										hasChanged = compareAutomation(dirtyValue, value);
									}
									if(hasChanged) {
										// @TODO: How do we want to handle dirty values from an end user perspective?
										// Warn the user for now, I guess?
										let InterfaceActions = require('../actions/interface-actions').default;
										let quickLookup = {
											"name": "Page Name",
											'fieldPosition': 'Attached Field Positioning',
											'attachedFields': 'Attached Fields',
											'childConfigurations': 'Local Overrides',
											"displayedName": "Displayed Page Name",
											"roles": "Roles",
											"allowAdding": "Allow Adding Records",
											"screensizeManagement": "Field Positions by Screen Size",
											"allowPublicAccess": "Publicly available",
											"availableModes": "Available Modes",
											"addButtonText": "Add Button Text",
											"editButtonText": "Edit Button Text",
											"saveButtonText": "Save Button Text",
											"saveControlPlacement": "Save Control Placement",
											"requiredForSave": "Required",
											'automation-onBlur': 'Lose Focus',
											'automation-onBlurChange': 'Lose Focus and Change',
											'automation-onClick': 'Click',
											'automation-onFocus': 'Gain Focus',
											'automation-onEnterUp': 'On Enter Up',
											'automation-onMouseOut': 'Mouse Out',
											'automation-onMouseOver': 'Mouse Over',
											'automation-validate': 'Validate (Local)',
											'automation-preFieldSave': 'Validate (Global)',
											'automation-onPageLoad': 'After Page Loads',
											'automation-prePageSave': 'Page Validates',
											'automation-postPageSave': 'After Page Saves',
											'unsavedChangesWarning': 'Unsaved Changes Warning'
										};
										let label = quickLookup[key];
										if(!label && key && key.endsWith('-visibility')) {
											label = 'Custom Visibility Logic';
										} else if (!label) {
											label = key;
										}
										InterfaceActions.stickyNotification({
											level: 'warning',
											title: 'Changed: ' + oldRecord.getIn(['name', 'value']) + ' Page / ' + label + ' Setting',
											message: 'Your modified setting has been changed by another citizen developer. Save your work to overwrite their changes, or reload to accept their update.',
											id: 'page-' + recordId + '-' + key + '-conflict'
										});
										// Update the original value, since that reflects the DB now
										newRecord.setIn([key, 'originalValue'], value);
									} else {
										// The value in the DB is now actually the same as the "dirty" value, so we can just clean the value in the store
										newRecord.setIn([key, 'isDirty'], false);
										newRecord.setIn([key, 'originalValue'], null);
									}
								}
							});
						});
						newState.setIn(['records', recordId], newRecord);
					})
				});
			}
			case PageConstants.PAGE_PULL_FROM_DATABASE: {
				let overwriteStore = action.get('overwriteStore');
				let pageArray = action.get('pageArray');

				return state.withMutations(state => {
					let recordMap = getRecordMap(pageArray);
					if(overwriteStore) {
						state.mergeIn(['records'], recordMap);
					} else {
						// Start with the new records, then merge in what we have in the state already to avoid overwriting
						state.set('records', recordMap.mergeDeep(state.get('records')));
					}
				});
			}
			case PageConstants.PAGE_PULL_FROM_DATABASE_ALL: {
				let overwriteStore = action.get('overwriteStore');
				let pageArray = action.get('pageArray');

				return state.withMutations(state => {
					let recordMap = getRecordMap(pageArray);
					if(overwriteStore) {
						state.set('records', recordMap);
					} else {
						// Start with the new records, then merge in what we have in the state already to avoid overwriting
						state.set('records', recordMap.mergeDeep(state.get('records')));
					}
					state.set('allPulledFromDatabase', true);
				});
			}
			case PageConstants.PAGE_PUSH_COLS_TO_STORE: {
				// As near as I can tell, this never even gets called
				return state.withMutations(state => {
					let recordId = action.get('recordId');
					updateStore(state, ['records', recordId, 'cols'], action.get('cols'), action.get('forceClean'));
				});
			}
			case PageConstants.PAGE_PUSH_LAYOUTS_TO_STORE: {
				return state.withMutations(state => {
					let recordId = action.get('recordId');
					updateStore(state, ['records', recordId, 'layouts'], action.get('layouts'), action.get('forceClean'));
				});
			}
			case PageConstants.PAGE_PUSH_TEMPLATE_TO_STORE: {
				return state.withMutations(state => {
					let recordId = action.get('recordId');
					updateStore(state, ['records', recordId, 'template'], action.get('template'), action.get('forceClean'));
				});
			}
			case PageConstants.PAGE_PUSH_COMPONENTS_TO_STORE: {
				return state.withMutations(state => {
					let recordId = action.get('recordId');
					updateStore(state, ['records', recordId, 'components'], action.get('components'), action.get('forceClean'));
				});
			}
			case PageConstants.PAGE_PUSH_FIELD_SETTING_TO_STORE: {
				let recordId = action.get('recordId');
				let fieldId = action.get('fieldId');
				let settingSchemaName = action.get('settingSchemaName');
				let settingValue = action.get('settingValue');

				// save copy of store with new variant
				let oldValue = state.getIn(['records', recordId, 'components', 'value', fieldId, 'localSettings', settingSchemaName]);
				return state.withMutations(state => {
					state.setIn(['records', recordId, 'components', 'value', fieldId, 'localSettings', settingSchemaName], settingValue);
					if(oldValue !== settingValue) {
						// If it's not already dirty, then the value in it should become the old value
						let wasDirty = state.getIn(['records', recordId, 'components', 'isDirty']);
						if(!wasDirty) {
							state.setIn(['records', recordId, 'components', 'originalValue'], state.getIn(['records', recordId, 'components', 'value']));
						}
						// If the value has changed, update accordingly
						state.setIn(['records', recordId, 'components', 'isDirty'], true);
					}
				});
			}
			case PageConstants.PAGE_PUSH_FIELD_AUTOMATION_TO_STORE: {
				let recordId = action.get('recordId');
				let fieldId = action.get('fieldId');
				let automationType = action.get('automationType');
				let automationObject = action.get('automationObject');
				automationObject = automationObject.toJS();

				// save to store
				return state.mergeDeepIn(['records', recordId, 'automation', fieldId,  automationType], Immutable.Map([['blocklyxml', automationObject.blocklyxml], ['js', automationObject.js]]));
			}
			case PageConstants.PAGE_PULL_ERROR: {
				console.error('Page Store Error: ' + action.get('error'));
				return state;
			}
			default:
				return state;
		}
	}

	/**
	 * Gets the entire store as an object
	 *
	 * @returns {Object} current store as an object
	 */
	getAll() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			let records = this.getState().get('records');
			let recordsObj = {};
			records.forEach((record, recordId) => {
				let recordObj = {};
				record.forEach((settingVal, settingKey) => {
					let val = settingVal.get('value');
					// *grumbles about automation trigger*
					if(val && val.toJS) {
						val = val.toJS();
					}
					recordObj[settingKey] = val;
				});
				recordsObj[recordId] = recordObj;
			});
			return recordsObj;
		} else {
			return undefined;
		}
	}

	/**
	 * Gets the entire store as an array
	 *
	 * @returns {Array} current store as an array
	 */
	getAllArray() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			let records = this.getState().get('records');
			let recordsArr = [];

			records.forEach((record) => {
				let recordObj = {};
				record.forEach((settingVal, settingKey) => {
					let val = settingVal.get('value');
					// *grumbles about automation trigger*
					if(val && val.toJS) {
						val = val.toJS();
					}
					recordObj[settingKey] = val;
				});
				recordsArr.push(recordObj);
			});
			return recordsArr;
		} else {
			return undefined;
		}
	}

	/**
	 * Returns the count of tables in the intallation
	 * @returns number
	 */
	getCount() {
		return this.getAllArray().length;
	}

	/**
	 * Gets an individual record from the store
	 * @param {string} recordId UUID of the record to get
	 * @returns {Object} current store as an object
	 */
	get(recordId, justDirty) {
		if (this.getState().hasIn(['records', recordId])) {
			let rec = this.getState().getIn(['records', recordId]);
			let toReturn = {
				recordId
			};
			rec.forEach((settingVal, settingKey) => {
				let val = settingVal.get('value');
				let isDirty = settingVal.get('isDirty');
				// *grumbles about automation trigger*
				if(val && val.toJS) {
					val = val.toJS();
				}
				if(justDirty ? isDirty : true) {
					toReturn[settingKey] = val;
				}
			});
			// If the attached fields are being updated, then we need to make sure that the field positioning is as well
			if(justDirty && toReturn.attachedFields) {
				toReturn.fieldPosition = rec.getIn(['fieldPosition', 'value']);
			}
			// If we're blanking out the automation because an old value is being converted, we better make sure that all of the automation gets saved even if it's not "dirty"
			if(justDirty && typeof toReturn.automation !== 'undefined') {
				rec.forEach((settingVal, settingKey) => {
					if(settingKey.startsWith('automation-')) {
						let val = settingVal.get('value');
						// *grumbles about automation trigger*
						if(val && val.toJS) {
							val = val.toJS();
						}
						toReturn[settingKey] = val;
					}
				});
			}
			return toReturn;
		} else {
			return undefined;
		}
	}

	/**
	 * Get all the pages for a particular table by schema name
	 * 
	 * @param {string} tableSchemaName 
	 * @returns {Array} Pages
	 */
	getArrayByTableSchemaName(tableSchemaName) {
		let pages = this.getAllArray();
		return pages.filter(function (page) {
			return (page.tableSchemaName === tableSchemaName);
		});
	}

	/**
	 * getVariant - Gets name of variant component
	 *
	 * @param  {string} recordId            current page
	 * @param  {string} fieldId           field id of component variant requested
	 * @param  {string} responsiveMode    'lg', 'md', or 'sm'
	 * @param  {string} mode              "view" or "edit"
	 * @return {string}                    name of component
	 */
	getVariant(recordId, fieldId, responsiveMode, mode) {
		let component = this.getComponent(recordId, fieldId, responsiveMode);
		return component[mode + 'Variant'];
	}

	/**
	 * getComponentsObj - retrieves list of components in page
	 *
	 * @param {string} recordId UUID of the record to get
	 * @return {Array} list of component objects in page
	 */
	getComponentsObj(recordId) {
		let returnVal;
		let page = this.get(recordId);
		
		if (page !== undefined) {
			returnVal = page.componentsObj;
		}
		return returnVal;
	}
	/**
	 * getComponents - retrieves list of components in page
	 *
	 * @param {string} recordId UUID of the record to get
	 * @param  {string} fieldId field id of component variant requested
	 * @return {Object} component object
	 */
	getComponent(recordId, fieldId, screenSize) {
		let returnVal = false;
		let page = this.get(recordId);
		if(!screenSize) { 
			screenSize = 'lg';
		}
		if (page !== undefined && page.componentsObj && page.componentsObj[screenSize]) {
			returnVal = page.componentsObj[screenSize][fieldId];
		}

		return returnVal;
	}
	/**
	 * getLayouts - retrieves the grid layout JSON data.
	 *
	 * @param {string} recordId UUID of the record to get
	 * @return {Object} JSON object containing lg, md, and sm grid data
	 */
	getLayouts(recordId) {
		let returnVal;
		let page = this.get(recordId);
		if (page !== undefined && page.layouts) {
			returnVal = JSON.parse(page.layouts);
		}
		return returnVal;
	}
	/**
	 * getTemplate - retrieves template for page
	 *
	 * @param {string} recordId UUID of the record to get
	 * @return {string} name of template
	 */
	getTemplate(recordId) {
		let returnVal;
		let page = this.get(recordId);
		if (page !== undefined) {
			returnVal = page.template;
		}
		return returnVal;
	}

	/**
	 * 
	 * @param {*} recordId 
	 * @param {*} triggerName 
	 */
	getAutomation(recordId, triggerName) {
		let page = this.get(recordId);
		if (triggerName && page && page['automation-' + triggerName]) {
			// All locations which call this function expect this to already be parsed
			let automationObj = page['automation-' + triggerName];
			try {
				automationObj = automationObj && typeof automationObj === 'string' ?
					JSON.parse(automationObj) :
					automationObj;
			} catch(err) {
				console.warn('Unable to parse automationObj for trigger %s. Value was', triggerName, automationObj);
			}
			return automationObj;
		} else if (!triggerName && page) {
			let automation = {};
			let hasValue = false;
			Object.keys(page).forEach(trigger => {
				if(trigger.startsWith('automation-')) {
					automation[trigger.replace('automation-', '')] = page[trigger];
					hasValue = true;
				}
			});
			return hasValue ? automation : undefined;
		} else {
			return undefined;
		}
	}

	/**
	 * 
	 * @param {string} recordId Field Record ID to get the child configuration for.
	 * @param {string} childRecordId Optional. The record or attachment ID for which to find the local settings
	 * @param {string} settingSchemaName Optional. The specific setting to get
	 */
	getChildConfigurations(recordId, childRecordId, settingSchemaName) {
		let page = this.get(recordId);
		if(page) {

			let childConfigurations = page.childConfigurations;
			let childConfigurationsObj = {};
			try {
				childConfigurationsObj = childConfigurations ? JSON.parse(childConfigurations) : childConfigurationsObj;
			} catch(err) {
				console.error('Unable to parse child configurations in parent record %s. Value was %s', recordId, childConfigurations);
			}
			
			let toReturn = childConfigurationsObj;
			if(childRecordId) {
				if(settingSchemaName) {
					toReturn = childConfigurationsObj[childRecordId] ? childConfigurationsObj[childRecordId][settingSchemaName] : undefined;
				} else {
					toReturn = childConfigurationsObj[childRecordId];
				}
			}

			return toReturn;

			// All of this will be uncommented once we have the child configurations properly chunked up
			// let toReturn = {};
			// let sw = 'child-';
			// if(childRecordId) {
			// 	sw += childRecordId + '-';
			// 	if(settingSchemaName) {
			// 		sw += settingSchemaName;
			// 	}
			// }
			// Object.keys(page).forEach(key => {
			// 	if(key.startsWith(sw)) {
			// 		toReturn[key] = page[key];
			// 	}
			// });
			// return toReturn;
		} else {
			return undefined;
		}
	}

	/**
	 * Get whether all have been pulled from the database or not yet.
	 *
	 * @returns {boolean}
	 */
	allPulledFromDatabase() {
		return this.getState().get('allPulledFromDatabase');
	}
}

/**
 * Used to update the store
 * {Immutable.Map} state The state.
 * @param {array} path The path to the key to set
 * @param {string} newSettingValue The new setting value
 * @param {boolean} forceClean Whether or not to force cleaning
 */
function updateStore(state, path, newSettingValue, forceClean) {
	let oldValue = state.hasIn(path) ?
		state.getIn(path) :
		Immutable.Map();

	let newValue = oldValue.withMutations(oldValue => {
		if (forceClean) {
			oldValue.set('value', newSettingValue);
			oldValue.set('isDirty', false);
			oldValue.set('originalValue', null);
		} else {
			// If we're not already dirty and the original value hasn't been set, then set it
			if (!oldValue.get('isDirty') && oldValue.get('originalValue') === null) {
				oldValue.set('originalValue', oldValue.get('value'));
			}

			if (oldValue.get('originalValue') === newSettingValue) {
				// If the new value is the original value, then revert it and clear the isDirty flag
				oldValue.set('value', newSettingValue);
				oldValue.set('isDirty', false);
				oldValue.set('originalValue', null);
			} else {
				// Otherwise, just set the new value and mark it as dirty
				oldValue.set('value', newSettingValue);
				oldValue.set('isDirty', true);
			}
		}
	});

	state.setIn(path, newValue);
}

/**
 * Gets the record map with which to update the state based on a record from the DB
 * @param {Immutable.Map} state The state.
 * @param {Immutable.List} fieldArray The "array" of fields.
 */
function getRecordMap(pageArray) {
	let recordMap = Immutable.Map().withMutations(recordMap => {
		pageArray.forEach(metaRecord => {
			let recordId = metaRecord.get('recordId');
			let automation = metaRecord.get('automation');
			let allowAdd = metaRecord.get('allowAdd');

			automation = automation ? JSON.parse(automation) : {};

			
			let newRecord = Immutable.Map().withMutations(newRecord => {

				// Break automation up into keys
				if(automation) {
					Object.keys(automation).forEach(triggerName => {
						let triggerVal = automation[triggerName];
						newRecord.set('automation-' + triggerName, Immutable.fromJS({
							value: triggerVal,
							isDirty: false,
							originalValue: null
						}));
					});
				}

				metaRecord.forEach((settingVal, settingKey) => {
					// JSON.parse any automation triggers in the DB
					if(settingKey.startsWith('automation-') && settingVal) {
						try {
							// In some cases, the automation has already been turned into an object for us
							settingVal = typeof value === 'string' ? JSON.parse(settingVal) : settingVal;
						} catch(err) {
							console.error('Error: unable to parse JSON automation for trigger %s. Value was', settingKey, settingVal);
						}
					}
					if(settingKey !== 'automation') {
						// Treat as forceClean = true
						newRecord.set(settingKey, Immutable.fromJS({
							value: settingVal,
							isDirty: false,
							originalValue: null
						}));
					}
				});

				// @TODO: Is this how we want to handle this?
				// newRecord.set('automation', Immutable.fromJS({
				// 	value: automation,
				// 	isDirty: false,
				// 	originalValue: null
				// }));
				newRecord.set('allowAdd', Immutable.fromJS({
					value: allowAdd === 'true',
					isDirty: false,
					originalValue: null
				}));
			});
			recordMap.set(recordId, newRecord);
		});
	});
	return recordMap;
}

/**
 * 
 * @param {*} oldValue 
 * @param {*} triggerVal 
 */
function compareAutomation(oldValue, triggerVal) {
	if(!triggerVal) {
		// If we have an old value but not a new one, it must be dirty
		return true;
	} else {
		// Okay, we have both old and new values; compare their keys

		// One may have a key the other doesn't, so get the keys from both
		let keysDict = {};
		oldValue && oldValue.forEach && oldValue.forEach((val, key) => {
			keysDict[key] = true;
		});
		if(oldValue && (!oldValue.forEach || !oldValue.get)) {
			console.warn('Unexpected oldValue form:', oldValue);
		}
		triggerVal.forEach((val, key) => {
			keysDict[key] = true;
		});
		let isDirty = false;
		Object.keys(keysDict).forEach(key => {
			let oldVal = oldValue.get(key);
			let newVal = triggerVal.get(key);
			// If any of the keys are different, isDirty is true
			if(oldVal !== newVal) {
				isDirty = true;
			}
		});
		return isDirty;
	}
}

const instance = new PageStore(AppDispatcher);
export default instance;