import { Component } from 'react';
import { AuthenticationStore } from '../stores';
import $ from 'jquery';

/**
 * Component for Comet Chat
 */
export default class CometChatContainer extends Component {
	constructor(props) {
		super(props);
		this.isValidCometChatId = this.isValidCometChatId.bind(this);
	}

	/**
	 * Renders nothing
	 */
	render() {
		return null;
	}

	/**
	 * When this component mounts, write the comet chat script tag into the head.
	 */
	componentDidMount() {
		/* 
		@todos:
			* Add setting to installation for whether CometChat is enabled or not (and dont do anything if NOT.)
			* Add setting to installation for the code to use for CometChat, for the script and link lines below.
			* Add setting to installation for the API Key for Cometchat, and then update the Logic to process those actions on the BE.
			  * Needs new field type, Secure Key.
			* Add setting to installation for the FSN to use for the User's Username, process getting it.
			* Add setting to installation for the FSN to use for the Avatar, and process getting it.
			* ^^ Do these things happen in Calc State of the Page-Contents, and pass in as props?  Or here in this component?
			* 
			* Resolve comet chat overlapping the page resizer settings/CitDev controls.
		*/
		if (!window.jqcc &&
			AuthenticationStore.getUserId() && localStorage.getItem('haveAuthCreds') &&
			this.isValidCometChatId(this.props.cometChatId)) {
			$('head').append(
				'<script>' +
					// 'var chat_id = "2d4e8ecd-e6bd-45c4-9f10-d4307e7d37dd";' +
					// 'var chat_name = "' + AuthenticationStore.getUsername() + '";' +
					'var chat_id = "' + AuthenticationStore.getUserId() + '";' +
					// 'var chat_avatar = "";' +
					// 'var chat_link = "";' +
				'</script>')
				.append('<script type="text/javascript" charSet="utf-8" src="//fast.cometondemand.net/' + this.props.cometChatId + '.js"></script>')
				.append('<link type="text/css" rel="stylesheet" media="all" href="//fast.cometondemand.net/' + this.props.cometChatId + '.css" />')
				.append(
					'<style>' +
					'textarea.cometchat_textarea { margin:0 !important }' +
					'.cometchat_tab { z-index:5001 !important }' +
					/* CometChat button position fix. Added by Amber for ticket 3646 */
					'#cometchat {' +
						'position:fixed;' +
					'}' +
					/* CometChat button position fix done */
					'</style>');
		}
	}

	// @todo When CometChat support tells me how to turn this thing on and off, 
	// we will likely need to do so below:
	// componentWillUnmount() {

	// }

	isValidCometChatId(cometChatId) {
		if(!cometChatId) {
			return false;
		}
		if(cometChatId === 'null') {
			return false;
		}
		return cometChatId.match(/^[a-zA-Z0-9_]+$/);
	}
}