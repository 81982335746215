/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';
import {
	AdminSettingsActions,
	InterfaceActions,
	// LogicFunctionActions
} from '../../../actions';
import { 
	AdminSettingsStore, 
	ContextStore, 
	FieldStore, 
	LogicFunctionStore 
} from '../../../stores';

// Utils
import LogicUtils from '../../../utils/logic-utils';
import BlocklyUtils from '../../../utils/blockly-utils';

import { UIUtils } from '../../../utils';

/**
 * 
 */
class FunctionSettingsChooser extends Component {
	/**
	 * Creates instance of FunctionSettingsChooser
	 */
	constructor(props) {
		super(props);
		this._getSettingsList = this._getSettingsList.bind(this);
		this._onDeleteHandler = this._onDeleteHandler.bind(this);
	}
	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, FieldStore, LogicFunctionStore];
	}

	/**
	 * Calculate the current state.
	 * @static
	 * @param {any} prevState 
	 * @returns {Object} State!
	 */
	static calculateState(prevState, props) {
		let recordId = AdminSettingsStore.getRecordId();
		let tableSchemaName = AdminSettingsStore.getTableSchemaName();
		let componentObj = LogicFunctionStore.get(recordId) || {};
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let componentSettings = [];

		// Default Settings
		let settings =
			[
				{ recordId: '23e30e42-f3a9-48ec-b49f-ca0521c08afa',
				  sortOrder: 1, required: true  }, // Name
				{ recordId: 'ae1b6cd8-ba3d-4a3f-953d-95718ef662a2',
				  sortOrder: 2, required: false  }, // Description
				{ recordId: 'ccf62353-1a1a-48a5-b5af-6c5e119b7197',
				  sortOrder: 3  }, // Parmeters
				{ recordId: '86dbf401-e404-4418-970a-4fdcbdbf329a',
				  sortOrder: 4  }, // Logic
			];

		let hasMissingRequiredSettings = false;

		settings.forEach(setting => {
			let settingId = setting.recordId;
			let settingObj = FieldStore.get(settingId) || {};
			let settingSchemaName = settingObj.fieldSchemaName;

			// Empty/No Value values.
			let valueObj = {};
			if (componentObj[settingSchemaName]) {
				valueObj.value = componentObj[settingSchemaName];
			}

			// Check to see if this setting is required, and has no value
			if(setting.required && (!valueObj.value || valueObj.length === 0)) {
				hasMissingRequiredSettings = true;
			}

			componentSettings.push({
				// Basics
				fieldId: settingId,
				fieldObj: settingObj,

				// Label, Value and Sorting..
				label: settingObj.fieldLabel,
				valueObj: valueObj,
				sortOrder: setting.sortOrder,

				// Used for styling
				settingSelected: (selectedSetting === settingSchemaName),
				required: setting.required
			});
		});

		// Sort the results by setting name
		componentSettings.sort(function (a, b) {
			if (a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			return 1;
		});

		return {
			componentSettings: componentSettings,
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			hasMissingRequiredSettings: hasMissingRequiredSettings,
			settingsHidden: AdminSettingsStore.getSettingsListHidden(),
		}
	}

	/**
	 * Render the compenent
	 * @returns JSX
	 */
	render() {
		let { componentSettings, hasMissingRequiredSettings } = this.state;

		if (!componentSettings.length) {
			return (
				<div key="settings" className='settings-list-wrapper'>
					<div style={{ marginTop: '8px' }} className='no-settings-found'>No Settings Found</div>
				</div>
			);
		}

		let settingsList = this._getSettingsList(componentSettings); // component settings

		let buttons = [
			<button key="submit" className="btn btn-primary btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Save" title="Save (Ctrl-S)" onClick={LogicUtils.saveFunction}>Save</button>,
			<button key="reset" className="btn btn-warning btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Reset" onClick={LogicUtils.resetFunction}>Reset</button>,
			<button key="delete" className="btn btn-danger btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Delete" onClick={this._onDeleteHandler}>Delete</button>
		];

		let requiredMarker = null;
		if(hasMissingRequiredSettings) {
			requiredMarker = <div className="required-marker mx-4 d-flex"><i className="fa fa-circle d-flex align-items-center" aria-hidden="true"></i> <h4 className="ml-2">Required</h4></div>;
		}

		let componentIcon = ContextStore.getUrlMedia() + '/icon-logic.svg';

		return [
			<div key="settings" className='settings-list-wrapper'>
				<ul key="list" className="nav flex-column">
					<li className="settings-list-header">
						<div className="d-flex">
							<img height="22" width="22" style={{ marginTop:'0.40rem' }} className="mr-2" src={componentIcon} alt="" />
							<h3>Function Settings</h3>
						</div>
					</li>
					{ settingsList }
					{ requiredMarker }
				</ul>
			</div>,
			<div key="buttons" className="btn-wrapper appearance-btn-wrapper">
				{buttons}				
			</div>
		];
	}

	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @returns { array } array of <li> settings elements
	 */
	_getSettingsList(settingsArr) {

		let { fieldType, recordId, tableSchemaName } = this.state;

		// iterate over the settings and return an array of list items
		return settingsArr.map(setting => {
			let labelClassNames = 'setting-label';
			if (setting.settingSelected) {
				labelClassNames += ' selected';
			}

			let componentName = FieldStore.getDefaultVariantComponentName(
				setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
			let componentProps = setting.fieldObj;
			componentProps.value = setting.valueObj.value;
			componentProps.fieldType = fieldType;

			componentProps.dataRecordId = recordId;
			componentProps.dataTtableSchemaName = tableSchemaName;

			// These props will only be used by the fake table setting
			componentProps.customExpressionResult = setting.customExpressionResult;
			componentProps.renderAsHTML = setting.renderAsHTML;

			let valueDisplay = <h5 className={`settings-list-value ${componentName}`}>
					{(componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null)}
				</h5>;
			// Checkbox for the value for logic
			if(setting.fieldId === '86dbf401-e404-4418-970a-4fdcbdbf329a') {
				valueDisplay = componentProps.value && componentProps.value.includes('js') 
					? <span className='fa fa-check'><span className='sr-only'>(Has Logic)</span></span> 
					: null;
			}

			let requiredMarker = null;
			if(setting.required && (!setting.valueObj.value || setting.valueObj.value.length === 0)) {
				requiredMarker = <div style={{ left: '-14px', top: '2px' }} className="required-marker position-absolute"><i className="fa fa-circle fa-1" aria-hidden="true"></i></div>;
			}

			return (<li key={setting.fieldId} className={"nav-item d-flex flex-column justify-content-center " + (setting.settingSelected ? 'setting-selected' : '')}>
				<div className="nav-link" onClick={this._onSettingClick.bind(this, setting.fieldId)}>
					{/* Temporarily removed the following div */}
					{/* <div className={"setting-icon " + iconClassNames} /> */}
					{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
					{/* Add this back in when the setting-icon is used again */}
					<div className="d-flex setting-text-container">
						<div className="w-50 setting-label-container">
							<div className={labelClassNames}>{requiredMarker}<h4>{setting.label}</h4></div>
							<div className="setting-pattern-list">{setting.mostRecentPattern}</div>
						</div>
						<div className="w-50 d-flex justify-content-end setting-value-container">
							<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
								{valueDisplay}
							</div>
							{setting.valueObj.source ?
								<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}>
									{setting.valueObj.source}
								</div>
								: null
							}
						</div>
					</div>
				</div>
			</li>);
		});
	}

	/**
	 * _onDeleteHandler - Deletes the object from the store and the database.
	 *
	 * @param  {object} event
	 */
	_onDeleteHandler(event) {
		let { recordId } = this.state;

		let confirmRemove = confirm('Are you sure you want to delete this Function?');
		if(confirmRemove) {
			BlocklyUtils.deleteFunctionInstances([recordId])
			.then(() => {
				UIUtils.closeSettingsPanel();
			})
			.catch(error => {
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to delete API' });
				console.error('Unable to delete API:', error);
			});
		}
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 */
	_onSettingClick(settingFieldId, event) {
		event.preventDefault();

		let settingFieldObj = FieldStore.get(settingFieldId);
		let settingSchemaName = settingFieldObj.fieldSchemaName;

		// Toggle to select and deselect the Setting
		if(settingSchemaName === 'logic') {
			// If logic is already selected
			if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
				// Expand the Settings Panel
				AdminSettingsActions.onSettingsListHideChange(true);
			} else {
				// Hide the Settings Panel
				AdminSettingsActions.onSettingsListHideChange(true);
				AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
			}
		} else {
			// Expand the Settings Panel
			AdminSettingsActions.onSettingsListHideChange(false);
			if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
				AdminSettingsActions.onSettingChange('', '');
			} else {
				AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
			}
		}
	}
}
const container = Container.create(FunctionSettingsChooser);
export default container;