/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Constants
import { WebHookTriggers, WebHookTriggerNames } from '../../../constants/webhook-triggers';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import MetadataStore from '../../../stores/metadata-store';
import ContextStore from '../../../stores/context-store';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';

class WebhooksSettingsChooser extends Component {
	/**
	 * Creates instance of WebhooksSettingsChooser
	 *
	 * @memberOf WebhooksSettingsChooser
	 */
	constructor(props) {
		// @TODO
		super(props);
		this.state = {}; // Not really needed but temp here to shut up eslint
		this._renderEndpoint = this._renderEndpoint.bind(this);
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	 static getStores() {
		return [AdminSettingsStore, MetadataStore];
	}

	static calculateState(prevState, props) {
		let newState = Object.assign({}, prevState);
		// @TODO
		newState.trigger = AdminSettingsStore.getSettingSchemaName();

		let triggersList = WebHookTriggers[WebHookTriggerNames.STRIPE];
		triggersList.forEach((trigger) => {
			let {schemaName} = trigger;
			let value = schemaName ? MetadataStore.get(schemaName, 'webhook') : {};
			trigger.hasValue = value && value.blocklyxml ? true : false;
			trigger.isSelected = newState.trigger === schemaName;
		});

		if(!prevState || JSON.stringify(prevState.triggersList) !== JSON.stringify(triggersList)) {
			newState.triggersList = triggersList;
		}

		return newState;
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingSchemaName
	 * @param {any} event 
	 * @memberof SettingsPanel
	 */
	 _onSettingClick(settingSchemaName, event) {
		event.preventDefault();
		//Toggle to select and deselect the Setting
		AdminSettingsActions.onSettingChange(settingSchemaName);
		
		// Flip the hidden toggle on every setting:
		AdminSettingsActions.onSettingsListHideChange(!AdminSettingsStore.getSettingsListHidden());
	}

	_renderEndpoint({schemaName, label, isSelected, hasValue}) {
		let labelClassNames = 'setting-label';
		if(isSelected) { 
			labelClassNames += ' selected';
		}
		// let iconClassNames = 'fa fa-puzzle-piece';
		let valueDisplay = hasValue ? <span className='fa fa-check'><span className='sr-only'>(Has Logic)</span></span> : null;

		return (<li key={schemaName} className="nav-item">
			<div className="nav-link" onClick={this._onSettingClick.bind(this, schemaName)}>
				{/* Temporarily removed the following div */}
				{/* <div className={"setting-icon " + iconClassNames} /> */}
				{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
				{/* Add this back in when the setting-icon is used again */}
				<div className="d-flex setting-text-container">
					<div className="w-75 setting-label-container">
						<div className={labelClassNames}><h4>{label}</h4></div>
					</div>
					<div className="w-25 d-flex justify-content-between setting-value-container">
						<div className="setting-pattern-list setting-value" style={{ textAlign:'center', width: '100%' }}>
							{valueDisplay}
						</div>
					</div>
				</div>
			</div>
		</li>);
	}

	/**
	 * 
	 * @returns - DOM of list of Query
	 * @memberof WebhooksSettingsChooser
	 */
	render() {

		let endpointList = <div>Loading...</div>;
		if(this.state.triggersList) {
			endpointList = this.state.triggersList.map(this._renderEndpoint);
		}

		let componentIcon = ContextStore.getUrlMedia() + "/icon-logic.svg";
		return (
			<div key="settingsList" className='settings-list-wrapper'>
				<ul className="nav flex-column">
					<li className="settings-list-header">
						<div className="d-flex">
							<img height="22" width="22" style={{ marginTop:'0.40rem' }} className="mr-2" src={componentIcon} alt="" />
							<h3>Webhook Triggers</h3>
						</div>
					</li>
					{endpointList}
				</ul>
			</div>
			);
	}
}
const container = Container.create(WebhooksSettingsChooser, { withProps: true });
export default container;