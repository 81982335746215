import AuthenticationStore from '../stores/authentication-store';
import FieldActions from '../actions/field-actions';

export default {
	/**
	 * Updates the field object settings history and dispatches any changes to the store
	 * 
	 * @param {any} fieldObject 
	 * @memberof AppearanceSettings
	 */
	'updateSettingsHistory': function(fieldObject) {
		if (fieldObject.settings) {
			try {
				let settings = JSON.parse(fieldObject.settings);
				let settingsHistory = {};
				if (fieldObject.settingsHistory) {
					settingsHistory = JSON.parse(fieldObject.settingsHistory);
				}
				Object.keys(settings).forEach(function(settingSchemaName) {
					// Remove any automation settings from the history before setting it.
					if(!settingSchemaName.includes('automation-'))
					{
						let settingHistory = settingsHistory[settingSchemaName] ? 
							settingsHistory[settingSchemaName] : [];
						let currentHistoryObj = settingHistory.length ? 
							settingHistory[settingHistory.length - 1] : {};
						if (currentHistoryObj.value !== settings[settingSchemaName]) {
							let newHistory = {
								value: settings[settingSchemaName],
								timestamp: +new Date(),
								userId: AuthenticationStore.getUserId(),
								userName: AuthenticationStore.getUsername(),
								valueQuality: "custom"
							};
							settingHistory.push(newHistory);
							FieldActions.appendSettingHistory(fieldObject.recordId, settingSchemaName, newHistory.value,
								newHistory.userId, newHistory.userName, null, newHistory.valueQuality);
							settingsHistory[settingSchemaName] = settingHistory;
						}
					}
				});
				fieldObject.settingsHistory = JSON.stringify(settingsHistory);
			} catch (error) {
				console.error('Setting History failed to update');
			}
		}
	}
}