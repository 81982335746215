import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import AppearanceAssistantConstants from '../constants/appearance-assistant-constants';
import AdminSettingsConstants from '../constants/admin-settings-constants';
// import UIUtils from '../utils/ui-utils';

/**
 * Manages state for appearance assistant
 */
class AppearanceAssistantStore extends ReduceStore {
	/**
	 * getInitialState - initial state for ContextStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.fromJS({
			input: '',
			settingSchemaName: ''
		});
	}
	
	/**
	 * Input typed into the assistant NLP interface
	 * 
	 * @returns {string}
	 * @memberof AppearanceAssistantStore
	 */
	getInput() {
		return this.getState().get('input');
	}

	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 */
	reduce(state, action) {
		let newstate = state;
		switch (action.get('type')) {
			case AppearanceAssistantConstants.INPUT_CHANGE:
				newstate = state.mergeDeep({
					'input': action.get('input')
				});
			break;
			case AdminSettingsConstants.RIGHT_PANEL_CHANGE:
				let isRightPanelOpen = action.get('isRightPanelOpen');
				if(!isRightPanelOpen) {
					newstate = state.mergeDeep({
						'settingSchemaName': '',
						'input': ''
					});
				}
				break;
			default:
			break;
		}
		return newstate;
	}
}

const instance = new AppearanceAssistantStore(AppDispatcher);
export default instance;