var keyMirror = require('keymirror');

// Define action constants
var ProcessingConstants = keyMirror({
	'ADD_RUNNING_ACTION_ID': null,
	'REMOVE_RUNNING_ACTION_ID': null,
	'UPDATE_ACTION_DISPLAY_NOTIFICATION': null,
	'SET_RUNNING_PERCENTAGE': null,
	'CLEAR_RUNNING_PERCENTAGE': null,
	'SHOW_RELOAD_UI': null,
	'SHOW_MAINTENANCE_UI': null
});
module.exports = ProcessingConstants;
