/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';

import { 
	AdminSettingsActions, 
	FieldActions, 
	InterfaceActions, 
	PageActions 
} from '../../actions';

import { 
	AdminSettingsStore, 
	ContextStore, 
	FieldSettingsStore, 
	FieldStore, 
	FieldTypeStore, 
	PageStore, 
	PatternStore,
	TableStore
} from '../../stores';

// Utils
import FieldUtils from '../../utils/field-utils';
import PageUtils from '../../utils/page-utils';
import UIUtils from '../../utils/ui-utils';

const forbidden = {
	// Using IDs for this because fields on different tables with this schema name could be created
	'ebc2aa94-784f-4ad4-b7ca-bd4ac8df4f05': 'Default Page',
	// 'defaultpage': true,
	'aad0e76b-5e01-41b0-a840-8f54c5474841': 'Login Page',
	// 'loginpage': true,
	'9696918f-991d-4f99-a3c8-11cc14b96177': 'Fav Icon',
	// 'favicon': true,
	'ea0fddb1-b38b-4fd3-a698-5c09273aa7cb': 'Security Group Name',
	'1257d483-9bd7-4900-8df6-21bba13f8c57': 'Security Group Description'
}

/**
 * 
 */
class QuerySettingsChooser extends Component {
	/**
	 * Creates instance of QuerySettingsChooser
	 *
	 * @memberOf QuerySettingsChooser
	 */
	constructor(props) {
		super(props);
		this.getSettingsList = this.getSettingsList.bind(this);
		this.onDeleteHandler = this.onDeleteHandler.bind(this)
		this.onResetHandler = this.onResetHandler.bind(this)
		this.onSaveHandler = this.onSaveHandler.bind(this)
	}
	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, FieldSettingsStore, FieldStore, FieldTypeStore, PageStore, PatternStore];
	}
	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 * @memberof QuerySettingsChooser
	 */
	onSettingClick(settingFieldId, event) {
		event.preventDefault();

		if(!settingFieldId) {
			return;
		}

		let settingFieldObj = FieldStore.get(settingFieldId);
		let settingSchemaName = settingFieldObj.fieldSchemaName;

		// Ensure the Settings are visible
		AdminSettingsActions.onSettingsListHideChange(false);

		// Toggle to select and deselect the Setting
		if(AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
			AdminSettingsActions.onSettingChange('', '');
		} else {
			AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
		}
	}

	// 
	/**
	 * 
	 * @static
	 * @param {any} prevState 
	 * @returns {Object} - Query Setting list
	 * @memberof QuerySettingsChooser
	 */
	static calculateState(prevState, props) {
		let parentSettings = [];
		let componentSettings = [];
		let recordId = AdminSettingsStore.getRecordId();
		let parentRecordId = AdminSettingsStore.getParentRecordId();
		let tableSchemaName = AdminSettingsStore.getTableSchemaName();
		let parentTableSchemaName = AdminSettingsStore.getParentTableSchemaName();
		let settingsSource = FieldSettingsStore.getSettingsWithSource(recordId, parentRecordId);
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		// let componentType = AdminSettingsStore.getTableSchemaName();
		// if(componentType === 'page') {
		// 	return {
		// 		isPage: true
		// 	}
		// }
		// If we're a field, then capture our field type for components later.
		// If not (we're a page) this will just be empty
		let fieldType = '';



		let tableSettingLabel = '';
		let tableSettingValueLabel = '';
		let tableSettingValueIcon = '';
		let componentHeader = '';

		if(tableSchemaName === 'field') {
			let fieldId = recordId;
			let fieldObj = FieldStore.get(fieldId) || {};
			fieldType = fieldObj.fieldType;
			let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType) || {};

			// set component header
			componentHeader = fieldTypeObj && fieldTypeObj.name + " Settings";

			tableSettingLabel = 'Field\'s Table';
			let tableObj = TableStore.getByTableSchemaName(fieldObj.tableSchemaName);
			if(tableObj) {
				tableSettingValueIcon = tableObj.icon;
				tableSettingValueLabel = tableObj.singularName;
			}

			// Get the field's direct settings
			let fieldTypeSettings = fieldTypeObj.settings || undefined;

			// Get the field's parent's child-settings.
			let parentSettingsArr = [];
			if(parentRecordId && parentTableSchemaName){
				switch (parentTableSchemaName) {
					case 'page':{
						parentSettingsArr = PageUtils.getChildSettings();
						break;
					}
					case 'field': {
						parentSettingsArr = FieldUtils.getChildSettings(parentRecordId);
						break;
					}
					default: 
						console.warn('Unsupported Parent Table Schema Name:', parentTableSchemaName);
						break;
				}
			} else {
				parentSettingsArr = FieldUtils.getDefaultSettings();
			}

			if (fieldTypeSettings) {
				fieldTypeSettings.forEach(fieldTypeSetting => {
					let settingId = fieldTypeSetting.recordId;
					let settingObj = FieldStore.get(settingId) || {};

					// If this is NOT a query and relationship selector setting, then jump out of the loop.
					if(settingObj.fieldType !== '45b6601f-02e8-4dcd-acce-9815d094f009' &&
					   settingObj.fieldType !== 'ce0dbfec-e9d9-4dc3-b39a-456eca2b5282') {
							return false;
					}

					let settingSource = settingsSource[settingObj.fieldSchemaName] || {};
					let historyObj = FieldUtils.getSettingHistoryDetails(settingId, fieldId, parentRecordId, parentTableSchemaName);

					// Empty/No Value values.
					let valueObj = {};

					// Check whether value for setting is present
					if(settingSource.value || (!settingSource.value && settingSource.source === 'local')){
						valueObj.value = settingSource.value;
						
						// Work with Value Source
						if(settingSource.source !== 'global') {
							valueObj.source = 'O';
							valueObj.badgeTypeClass = 'badge-secondary';
						}
					}

					componentSettings.push({
						// Basics
						fieldId: settingId,
						fieldObj: settingObj,

						// Label, Value and Sorting..
						label: settingObj.fieldLabel,
						valueObj: valueObj,
						sortOrder: historyObj.order,

						// Used for styling
						quality: historyObj.valueQuality, 
						settingSelected: (selectedSetting === settingObj.fieldSchemaName)
					});
				});
			}

			// Manually adding Field's List to the Component Settings List, adding the following object
			if(tableSettingValueLabel) {
				let valueHTML = tableSettingValueLabel;
				if(tableSettingValueIcon) {
					valueHTML = '<span class="mx-1 align-self-center fa fa-' + tableSettingValueIcon + '"></span> ' + tableSettingValueLabel;
				}
				componentSettings.push({
					fieldObj: {
						// This is an Expression Field
						fieldTypeId: '94dc3a69-8f25-420f-9e36-d0ef19ce7c45'
					},
					valueObj: {
						value: valueHTML
					},
					label: tableSettingLabel,
					customExpressionResult: valueHTML,
					renderAsHTML: 'true',
					sortOrder: 0
				});
			}
			if (parentSettingsArr && parentSettingsArr.length) {
				parentSettingsArr.forEach(fieldTypeSetting => {
					let settingId = fieldTypeSetting.recordId;
					let settingObj = FieldStore.get(settingId) || {};
					let settingSource = settingsSource[settingObj.fieldSchemaName] || {};
					let historyObj = FieldUtils.getSettingHistoryDetails(settingId, fieldId, parentRecordId, parentTableSchemaName);

					// Empty/No Value values.
					let valueObj = {};

					// Check whether value for setting is present
					if(settingSource.value || (!settingSource.value && settingSource.source === 'local')){
						valueObj.value = settingSource.value;

						// Work with Value Source
						if(settingSource.source !== 'global') {
							valueObj.source = 'O';
							valueObj.badgeTypeClass = 'badge-secondary';
						}
					}

					// If this IS not a query and a relationship selector setting, then jump out of the loop.
					if(settingObj.fieldType !== '45b6601f-02e8-4dcd-acce-9815d094f009' &&
					   settingObj.fieldType !== 'ce0dbfec-e9d9-4dc3-b39a-456eca2b5282') {
							return false;
					}

					parentSettings.push({
						// Basics
						fieldId: settingId,
						fieldObj: settingObj,

						// Label, Value and Sorting..
						label: settingObj.fieldLabel,
						valueObj: valueObj,
						sortOrder: historyObj.order,

						// Used for styling
						quality: historyObj.valueQuality, 
						settingSelected: (selectedSetting === settingObj.fieldSchemaName)
					});
				});
			}
		} else if (tableSchemaName === 'page') {
			let pageId = recordId;
			let pageObj = PageStore.get(pageId);
			let pageSettings = PageUtils.getPageSettings();
			componentHeader = 'Page Settings';

			pageSettings.forEach(pageSetting => {
				let settingId = pageSetting.recordId;
				let settingObj = FieldStore.get(settingId) || {};
				let settingsValue = pageObj[settingObj.fieldSchemaName];
				
				// If this is NOT a query setting, then jump out of the loop.
				if(settingObj.fieldType !== '45b6601f-02e8-4dcd-acce-9815d094f009') {
					return false;
				}

				// Empty/No Value values.
				let valueObj = {};

				let settingSource = settingsSource[settingObj.fieldSchemaName] || {};

				// Check whether value for setting is present
				if(settingSource.value || (!settingSource.value && settingSource.source === 'local')){
					valueObj.value = settingsValue;
					if(settingSource.source !== 'global') {
						valueObj.source = 'O';
						valueObj.badgeTypeClass = 'badge-secondary';
					}
				}

				componentSettings.push({
					// Basics
					fieldId: settingId,
					fieldObj: settingObj,

					// Label, Value and Sorting..
					label: settingObj.fieldLabel,
					valueObj: valueObj,
					sortOrder: 0,

					// Used for styling
					settingSelected: (selectedSetting === settingObj.fieldSchemaName)
				});
			});

			let pagesTable = pageObj.tableSchemaName;
			let tableObj = TableStore.getByTableSchemaName(pagesTable);
			if(tableObj) {
				tableSettingValueIcon = tableObj.icon;
				tableSettingValueLabel = tableObj.singularName;
			}

			let valueHTML = tableSettingValueLabel;
			if(tableSettingValueIcon) {
				valueHTML = '<span class="mx-1 align-self-center fa fa-' + tableSettingValueIcon + '"></span> ' + tableSettingValueLabel;
			}
			componentSettings.push({
				fieldObj: {
					// This is an Expression Field
					fieldTypeId: '94dc3a69-8f25-420f-9e36-d0ef19ce7c45'
				},
				valueObj: {
					value: valueHTML
				},
				label: 'Page\'s Table',
				customExpressionResult: valueHTML,
				renderAsHTML: 'true',
				sortOrder: 0
			});
		}

		// Sort the results by setting name
		componentSettings.sort(function(a,b){
			if(a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if(a.label.toLowerCase() < b.label.toLowerCase()) { 
				return -1;
			}
			return 1;
		});

		// Sort the parent results by setting name
		parentSettings.sort(function(a,b){
			if(a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if(a.label.toLowerCase() < b.label.toLowerCase()) { 
				return -1;
			}
			return 1;
		});

		return {
			componentSettings: componentSettings,
			parentSettings: parentSettings,
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			fieldType: fieldType,
			parentRecordId: parentRecordId,
			parentTableSchemaName: parentTableSchemaName,
			componentHeader: componentHeader,
		}
	}
	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @returns { array } array of <li> settings elements
	 */
		getSettingsList(settingsArr) {

			let { fieldType } = this.state;
	
			// iterate over the settings and return an array of list items
			return settingsArr.map((setting, index) => {
				let labelClassNames = 'setting-label';
				if(setting.settingSelected) { 
					labelClassNames += ' selected';
				}
				/*************************
					The following commented out statements are for the setting-icon, which is not currently in use
					Uncomment, when setting-icon is used again
				**************************/
	
				// let iconClassNames = 'fa';
				
				// switch(setting.quality) {
				// 	case 'custom':
				// 		iconClassNames += ' custom fa-check-square-o';
				// 	break;
				// 	default:
				// 	case 'low':
				// 		iconClassNames += ' high fa-check-circle-o';
				// 	break;
				// 	case 'high':
				// 		iconClassNames += ' low fa-exclamation-triangle';
				// 	break;
				// }
	
				let componentName = FieldStore.getDefaultVariantComponentName(
					setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
				let componentProps = setting.fieldObj;
				componentProps.value = setting.valueObj.value;
				componentProps.fieldType = fieldType;

				// These props will only be used by the fake table setting
				componentProps.customExpressionResult = setting.customExpressionResult;
				componentProps.renderAsHTML = setting.renderAsHTML;

				return (<li key={'setting-'+index} className={"nav-item d-flex flex-column justify-content-center " + (setting.settingSelected ? 'setting-selected' : '')}>
					<div className="nav-link" onClick={this.onSettingClick.bind(this, setting.fieldId)}>
						{/* Temporarily removed the following div */}
						{/* <div className={"setting-icon " + iconClassNames} /> */}
						{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
						{/* Add this back in when the setting-icon is used again */}
						<div className="d-flex setting-text-container">
							<div className="w-50 setting-label-container">
								<div className={labelClassNames}><h4>{setting.label}</h4></div>
								<div className="setting-pattern-list">{setting.mostRecentPattern}</div>
							</div>
							<div className="w-50 d-flex justify-content-end setting-value-container">
								<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
									<h5 className={`settings-list-value ${componentName}`}>
										{ (componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null) }
									</h5>
								</div>
								{ setting.valueObj.source ? 
									<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}> 
										{ setting.valueObj.source } 
									</div>
								: null
								}
							</div>
						</div>
					</div>
				</li>);
			});
		}
	/**
	 * onDeleteHandler - Calls API to Delete a field
	 * 
	 * @memberof AppearanceSettingsChooser
	 */
	onDeleteHandler() {
		//Get the Record to Delete (from the DataBase)
		let { recordId } = this.state;
		//If it is a Field to Delete: 
		if (this.state.tableSchemaName === 'field') {
			let fieldObj = FieldStore.get(recordId);
			// let fieldSettings = FieldStore.getSettings(recordId);
			// let fieldLabel = fieldSettings.fieldLabel;
			// if(!fieldSettings.fieldLabel) {
			// 	fieldLabel = '[ No Field Label found ]'
			// }

			let confirmRemove = confirm('Are you sure you want to delete this Field?');
			if (confirmRemove) {
				//First Detach Field from Every Parent this Field is attached and update the Database
				FieldUtils.detachChildFromAllParents(recordId, fieldObj.tableSchemaName, true);

				//Finally, delete the Field from the Table 
				FieldActions.deleteFromDatabase(recordId, fieldObj.tableSchemaName);
				
				//Confirm Message is Delete
				InterfaceActions.notification({ 'level': 'success', 'message': 'Deleting Field...' });

				//Close Panel after deleting
				UIUtils.closeSettingsPanel();

				// Delete from the Store.
				FieldActions.deleteFromStore(recordId);
			}
		} else if (this.state.tableSchemaName === 'page') {
			// let pageObj = PageStore.get(this.state.recordId);
			// let pageName = pageObj.name;
			// if(!pageName) {
			// 	pageName = '[ No Page Name found ]'
			// }
			let confirmRemove = confirm('Are you sure you want to delete this Page?');
			if (confirmRemove) {
				//Deletes the Page  
				PageActions.deleteFromDatabase(recordId);

				//Confirm Message is Delete
				InterfaceActions.notification({ 'level': 'success', 'message': 'Deleting Page...' });
				
				if (this.state.recordId === ContextStore.getPageId()) {
					location.href = '/';
				} else {
					//Close Panel after deleting
					UIUtils.closeSettingsPanel();
					PageActions.deleteFromStore(recordId);
				}
			}
		}
	}

	/**
	 * onSaveHandler - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	onSaveHandler(event){
		let { recordId, parentRecordId, parentTableSchemaName } = this.state;
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Updating Settings...' });
		FieldActions.pushToDatabase(FieldStore.get(recordId));

		if (parentRecordId && parentTableSchemaName === 'field') {
			FieldActions.pushToDatabase(FieldStore.get(parentRecordId));
		} else if (parentRecordId && parentTableSchemaName === 'page') {
			PageActions.pushToDatabase(PageStore.get(parentRecordId));
		}
	}
	/**
	 * onResetHandler - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event
	 */
	onResetHandler(event){
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Resetting Settings...' });
		FieldActions.pullFromDatabase(this.state.recordId);
	}
	/**
	 * 
	 * @returns - DOM of list of Query
	 * @memberof QuerySettingsChooser
	 */
	render() {
		let {componentSettings, parentSettings, parentRecordId, 
			parentTableSchemaName, componentHeader, recordId, tableSchemaName} = this.state;

		if(!componentSettings.length && !parentSettings.length) {
			return (
				<div key="settings" className='settings-list-wrapper'>
					<div style={{ marginTop: '8px' }} className='no-settings-found'>No Settings Found</div>
				</div>
			);
		}

		let settingsList = this.getSettingsList(componentSettings); // component settings
		let parentSettingsList = this.getSettingsList(parentSettings); // parent settings

		let componentIcon = (tableSchemaName === 'field' ? FieldUtils.getFieldTypeIcon(recordId) : FieldUtils.getFieldTypeIcon('page'));
		let parentHeaderIcon = '';
		if(parentRecordId && parentTableSchemaName){
			switch (parentTableSchemaName) {
				case 'page':{
					// Setup the parent header icon, from page
					parentHeaderIcon = FieldUtils.getFieldTypeIcon('page');
					break;
				}
				case 'field': {
					// Setup the parent header icon, from Field
					parentHeaderIcon = FieldUtils.getFieldTypeIcon(parentRecordId);
					break;
				}
				default: 
					console.warn('Unsupported Parent Table Schema Name:', parentTableSchemaName);
					break;
			}
		} else {
			parentHeaderIcon = FieldUtils.getFieldTypeIconByFieldTypeId('page');
		}

		/* The ids for the save and reset buttons are set in a specific way, so that Ctrl-S and Ctrl-R (UIUtils.onkeyDown method) can find these buttons */
		return [
			<div key="settings" className='settings-list-wrapper'>
				<ul key="list" className="nav flex-column">
					{settingsList && settingsList.length
						? <li className='settings-list-header d-flex'>
							<img height="22" width="22" className="mr-2" src={componentIcon} alt="" />
							<h3>{componentHeader}</h3>
						</li>
						: null
					}
					{settingsList && settingsList.length
						? settingsList
						: <div style={{ marginTop: 0 }} className='no-settings-found'>No Settings Found</div>
					}
					{/* not all fields have child attachment settings
						don't show the parent name if there are no child attachment settings */}
					{parentSettingsList && parentSettingsList.length
						?<li className="settings-list-header field-settings-list-header">
							<div className="d-flex align-items-center">
								<img height="22" width="22" className="mr-2" src={parentHeaderIcon} alt="" />
								<h3>Parent Settings</h3>
							</div>
						</li>
						: null
					}
					{parentSettingsList}
				</ul>
			</div>,
			<div key="buttons" className="btn-wrapper appearance-btn-wrapper">
				<button id={'querySave'} key="submit" className="btn btn-primary btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Save" title="Save (Ctrl-S)" onClick={this.onSaveHandler}>Save</button>
				<button key="queryReset" className="btn btn-warning btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Reset" title="Reset (Ctrl-R)" onClick={this.onResetHandler}>Reset</button>
				{forbidden[recordId]
					? null 
					: <button key="delete" className="btn btn-danger btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Delete" onClick={this.onDeleteHandler}>Delete</button>
				}
			</div>
		];
	}
}
const container = Container.create(QuerySettingsChooser);
export default container;