import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Actions
import RecordActions from '../../actions/record-actions';
import TableActions from '../../actions/table-actions';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import RecordStore from '../../stores/record-store';
import TableStore from '../../stores/table-store';

// Utils
import ObjectUtils from '../../utils/object-utils';

class SecuritySetting extends Component {
	/**
	 * Creates instance of SecuritySetting
	 *
	 * @memberOf SecuritySetting
	 */
	constructor(props) {
		super(props);
		this._onChange = this._onChange.bind(this);
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, RecordStore, TableStore];
	}

	/**
	 * @static
	 * @param {any} prevState 
	 * @param {any} props 
	 * @returns {Object}
	 * @memberof SecuritySetting
	 */
	static calculateState(prevState, props) {
		let securityGroupName = '';
		let securityGroupDescription = '';
		let securityGroupReadOnly = false;
		let permissions = {};
		let permissionsFound = false;

		// Which Security Group are we in?
		let securityGroupRecordId = AdminSettingsStore.getSettingRecordId();

		// Which Table are we acting on?
		let targetTableSchemaName = props.targetTableSchemaName;
		let tableObj = TableStore.getByTableSchemaName(targetTableSchemaName);

		// Are there permissions for this table?
		if(tableObj && tableObj['securityPermissions']) {
			let tablePermissions = ObjectUtils.getObjFromJSON(tableObj['securityPermissions']);
			// For this group?
			if(tablePermissions[securityGroupRecordId]) {
				permissions = tablePermissions[securityGroupRecordId];
				permissionsFound = true;
			}
		}

		// Depending on the group we are.. lets DO THIS!
		switch(securityGroupRecordId) {
			case 'authenticated': {
				securityGroupReadOnly = true;
				securityGroupName = 'Authenticated Users';
				securityGroupDescription = 'All users that have been authenticated to use the application.';

				if(!permissionsFound) {
					permissions.create = '1';
					permissions.read = '1';
					permissions.update = '1';
					permissions.delete = '1';		
				}
				break;
			}
			case 'nonauthenticated': {
				securityGroupReadOnly = true;
				securityGroupName = 'Non-Authenticated Users';
				securityGroupDescription = 'Any user who has not yet been authenticated to use the application, e.g. anyone from the Internet.';
				if(!permissionsFound) {
					permissions.create = '0';
					permissions.read = '0';
					permissions.update = '0';
					permissions.delete = '0';
				}
				break;
			}
			default: {
				let securityGroupRecord = RecordStore.getRecord('securityGroup', securityGroupRecordId);
	
				if (securityGroupRecord) {
					securityGroupName = (securityGroupRecord.name ? securityGroupRecord.name.value : '');
					securityGroupDescription = (securityGroupRecord.description ? 
						securityGroupRecord.description.value : '');
				}

				if(!permissionsFound) {
					permissions.create = '0';
					permissions.read = '0';
					permissions.update = '0';
					permissions.delete = '0';
				}
			}
		}
		
		return {
			securityGroupRecordId: securityGroupRecordId,
			securityGroupName: securityGroupName,
			securityGroupDescription: securityGroupDescription,
			securityGroupReadOnly: securityGroupReadOnly,
			permissions: permissions
		}
	}

	/**
	 * Handles change event from a Security Group field
	 *
	 * @param {string} key Which attribute to change
	 * @param {Object} event What the value should be.
	 *
	 * @memberOf SecuritySettings
	 */
	_onChange(key, event) {
		let securityGroupRecordId = AdminSettingsStore.getSettingRecordId();
		let newValue = event.target.value;

		let newRecordValues = {};
		newRecordValues[key] = newValue;

		// Load the new record into the record store.
		RecordActions.updateRecord('securityGroup', securityGroupRecordId, newRecordValues);
	}

	/**
	 * Handles change event from a Security Group field
	 *
	 * @param {string} key Which attribute to change
	 * @param {Object} event Key press vent 
	 *
	 * @memberOf SecuritySettings
	 */
	onKeyPress(key, {charCode} = event) {
		event.preventDefault();
		// If key is enter or space
		if (charCode === 13 || charCode === 32) {
			this._onPermissionChange(key);
		}
	}

	/**
	 * Handles change event from a permissions checkbox
	 *
	 * @param {string} key Which Checkbox to change
	 * @param {Object} event What the value should be.
	 */
	_onPermissionChange(key) {
		// Find where this value goes...
		let securityGroupRecordId = AdminSettingsStore.getSettingRecordId();
		let targetTableSchemaName = this.props.targetTableSchemaName;
		let tableObj = TableStore.getByTableSchemaName(targetTableSchemaName);

		// Get current table permissions
		let tablePermissions = {};
		if(tableObj.securityPermissions) {
			tablePermissions = ObjectUtils.getObjFromJSON(tableObj.securityPermissions);
		}

		// If this group has never had permissions for this table before...
		if(!tablePermissions[securityGroupRecordId]) {
			tablePermissions[securityGroupRecordId] = {};
			// And the group is authenticated
			if(securityGroupRecordId === 'authenticated') {
				// Set all to ON
				tablePermissions[securityGroupRecordId].create = '1';
				tablePermissions[securityGroupRecordId].read = '1';
				tablePermissions[securityGroupRecordId].update = '1';
				tablePermissions[securityGroupRecordId].delete = '1';	
			} else {
				// Set all to OFF
				tablePermissions[securityGroupRecordId].create = '0';
				tablePermissions[securityGroupRecordId].read = '0';
				tablePermissions[securityGroupRecordId].update = '0';
				tablePermissions[securityGroupRecordId].delete = '0';	
			}
		}

		// Set specific permissions that we're changing.
		tablePermissions[securityGroupRecordId][key] = 
			(tablePermissions[securityGroupRecordId][key] && tablePermissions[securityGroupRecordId][key] === '1') 
				? '0' 
				: '1';

		// See if we should just delete the authenticated key?
		if(securityGroupRecordId === 'authenticated') {
			if(tablePermissions[securityGroupRecordId]['create'] === '1' && 
				tablePermissions[securityGroupRecordId]['read'] === '1' && 
				tablePermissions[securityGroupRecordId]['update'] === '1' && 
				tablePermissions[securityGroupRecordId]['delete'] === '1') {
					delete tablePermissions[securityGroupRecordId];
				}
		} else {
			if(tablePermissions[securityGroupRecordId]['create'] === '0' && 
				tablePermissions[securityGroupRecordId]['read'] === '0' && 
				tablePermissions[securityGroupRecordId]['update'] === '0' && 
				tablePermissions[securityGroupRecordId]['delete'] === '0') {
					delete tablePermissions[securityGroupRecordId];
				}
		}

		// If we have security permissions, then stringify them - other wise delete them.
		tableObj.securityPermissions = JSON.stringify(tablePermissions);
		TableActions.pushToStore(tableObj.recordId, tableObj);
	}

	/**
	 * @returns - DOM for selected security group
	 * @memberof SecuritySetting
	 */
	render() {
		let { 
			securityGroupRecordId, 
			securityGroupName, 
			securityGroupDescription, 
			securityGroupReadOnly,
			permissions 
		} = this.state;

		let { disabledRetailRestrictions } = this.props;

		let pointerStyle = {cursor: 'pointer'};

		let tableObj = TableStore.getByTableSchemaName(this.props.targetTableSchemaName);
		let tableContent = null;
		if(tableObj) {
			tableContent = (
				<div role="list" className="row">
					<span role="listitem" className="table-wrapper" style={{ maxWidth:'inherit', border:'none'}}>
						<span className={"table-icon fa fa-3x fa-"+tableObj.icon}></span>
						<small className="table-name d-block" style={{ overflowWrap: "break-word" }}>{tableObj.pluralName}</small>
					</span>
				</div>);
		}

		if (!securityGroupRecordId) {
			return (
				<div className="select-setting">
					<div className="select-setting-text-wrap">
						Select a Security Group or "Add New" on the left.
					</div>
				</div>);
		} else {
			return (
				<div id="security-setting">
					<div className="security-setting-container">
						<div className="setting-global mx-3">
							<div className="row m-0">
								<div className="col-4">
									<label htmlFor="securityName" className="setting-label">Security Group Name:</label>
								</div>
								<div className="col-8">
									<label htmlFor="securityCreate" className="setting-label">Description:</label>
								</div>
							</div>
							<div className="row m-0">
								<div className="col-4">
									{
										securityGroupReadOnly 
										? <div className="hard-group-details">{securityGroupName}</div>
										: <input className="form-control" type="text"  
											id="securityName" placeholder="Security Group"
											onChange={this._onChange.bind(this, 'name')}
											disabled={disabledRetailRestrictions}
											value={securityGroupName} />
									}
								</div>
								<div className="col-8">
								{
									securityGroupReadOnly 
									? <div className="hard-group-details">{securityGroupDescription}</div>
									: <input className="form-control" type="text"  
										id="securityName" placeholder="Security Group Description"
										onChange={this._onChange.bind(this, 'description')}
										disabled={disabledRetailRestrictions}
										value={securityGroupDescription} />
								}
								</div>
							</div>
							<div className="row mt-2 ml-3 mr-3">
								<div className="col-12 permissions-container">
									<div className="row">
										<div className="col-12 pl-0">
											<label className="setting-label mb-1">Permit members of the {securityGroupName} Security Group permission to:</label>
										</div>
										<div className="col-5" style={{ paddingLeft: '0px' }}>
											<div className="container d-flex flex-column">
												<div
													tabIndex='0'
													style={pointerStyle}
													onClick={(!disabledRetailRestrictions ? this._onPermissionChange.bind(this, 'create') : null)} 
													onKeyPress={(!disabledRetailRestrictions ? this.onKeyPress.bind(this, 'create') : null)}
													className={"permissionOption " + (!!(permissions.create === "1") ? 'selected' : '')}>
													Create
													<div className={"permissionOptionBullet fa " + (!!(permissions.create === "1") ? 'fa-check-square-o selected' : 'fa-square-o')}></div>
												</div>
												<div
													tabIndex='0'
													style={pointerStyle}
													onClick={(!disabledRetailRestrictions ? this._onPermissionChange.bind(this, 'read') : null)} 
													onKeyPress={(!disabledRetailRestrictions ? this.onKeyPress.bind(this, 'read') : null )}
													className={"permissionOption " + (!!(permissions.read === "1") ? 'selected' : '')}>
													Read
													<div className={"permissionOptionBullet fa " + (!!(permissions.read === "1") ? 'fa-check-square-o selected' : 'fa-square-o')}></div>
												</div>
												<div
													tabIndex='0'
													style={pointerStyle}
													onClick={(!disabledRetailRestrictions ? this._onPermissionChange.bind(this, 'update') : null)} 
													onKeyPress={(!disabledRetailRestrictions ? this.onKeyPress.bind(this, 'update') : null)}
													className={"permissionOption " + (!!(permissions.update === "1") ? 'selected' : '')}>
													Update
													<div className={"permissionOptionBullet fa " + (!!(permissions.update === "1") ? 'fa-check-square-o selected' : 'fa-square-o')}></div>
												</div>
												<div
													tabIndex='0'
													style={pointerStyle}
													onClick={(!disabledRetailRestrictions ? this._onPermissionChange.bind(this, 'delete') : null)} 
													onKeyPress={(!disabledRetailRestrictions ? this.onKeyPress.bind(this, 'delete') : null)}
													className={"permissionOption " + (!!(permissions.delete === "1") ? 'selected' : '')}>
													Delete
													<div className={"permissionOptionBullet fa " + (!!(permissions.delete === "1") ? 'fa-check-square-o selected' : 'fa-square-o')}></div>
												</div>
											</div>
										</div>
										<div className="col-7 d-flex justify-content-center align-items-center">
											{ tableContent }
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>);
		} 
	}
}

const container = Container.create(SecuritySetting, {withProps:true});
export default container;