import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'flux/utils';
import Select from './select.react';
import FieldStore from '../../stores/field-store';
import TableStore from '../../stores/table-store';
import PageStore from '../../stores/page-store';
import BaseSettingForm from './base-setting-form.react';
import {FieldUtils, PageUtils} from '../../utils';

/**
 * Component for Field Selector
 * 
 * @class FieldSelector
 * @extends {Component}
 */
class FieldSelector extends BaseSettingForm {
	/**
	 * Loads the Stores to watch
	 * 
	 * @static
	 * @returns {Array of Object}
	 * 
	 * @memberof FieldContainer
	 */
	static getStores() {
		return [FieldStore, TableStore, PageStore];
	}

	/**
	 * Returns the current State of the FieldType Settings
	 * 
	 * @static
	 * @returns {Object}
	 * 
	 * @memberof FieldContainer
	 */
	static calculateState(prevState, props) {
		// Lookup the TSN to use to filter.
		let tableSchemaNameForFiltering = undefined;

		//Find out the Context to get the fields to Attach
		let storeToPullDataFrom = props.tableSchemaName,
			recordId = props.recordId,
			fieldsToAttach = [];
		if(storeToPullDataFrom === 'page'){

			if(PageStore.allPulledFromDatabase()){
				let pageObj = PageStore.get(recordId);

				tableSchemaNameForFiltering = pageObj.tableSchemaName; 
				fieldsToAttach = PageUtils.getPossibleChildren(recordId, tableSchemaNameForFiltering);

			}
		} else if(storeToPullDataFrom === 'field'){
			fieldsToAttach = FieldUtils.getPossibleChildren(recordId);
		}

		// Sort the Options by Names
		fieldsToAttach.sort(function(a, b) { 
			if ( a.name < b.name) {
				return -1;
			} else if ( a.name > b.name) {
				return 1;
			} else {
				return 0;
			}
		});

		//Add a new 'Select Option to the top of the field Array'
		let selectAFieldOption = {name: 'Select a Field', value:'noOption'};
		fieldsToAttach.unshift(selectAFieldOption);

		return {
			'fieldsToAtach' : fieldsToAttach
		};
	}


	/**
	 * Render the selector for Fields
	 * 
	 * @returns React
	 * 
	 * @memberof FieldSelector
	 */
	render() {
		let fieldsToAtach = this.state.fieldsToAtach;
		return (
			<Select value={this.props.value} onChange={this.props.onChange} fieldSchemaName={this.props.fieldSchemaName} selectOptions={fieldsToAtach} />
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	FieldSelector.propTypes = {
		value: PropTypes.any,
		tableSchemaName: PropTypes.string,
		screenSize: PropTypes.string
	};
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(FieldSelector, {withProps: true});
export default container;