import _ from 'lodash';
import Forms from '../components/forms';

/**
 * Helper to get correct component form for fieldName
 */
export default class FormBuilder {
  /**
   * Retrieves component form for fieldName
   *
   * @static
   * @param {String} fieldDataType Datatype of field setting to look for form component
   * @returns {Component} React component for respective fieldName
   *
   * @memberOf FormBuilder
   */
  static componentFor(fieldDataType) {
    let componentName = _.upperFirst(fieldDataType),
      component = Forms[componentName];

    if(!component) {
      component = Forms.BaseSettingForm;
    }

    return component;
  }
}
