/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container } from 'flux/utils';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';

// APIs
import RecordsAPI from '../../../apis/records-api';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import ContextStore from '../../../stores/context-store';
import FieldStore from '../../../stores/field-store';
import PageStore from '../../../stores/page-store';
import RecordStore from '../../../stores/record-store';
import TableStore from '../../../stores/table-store';

// Utils
import ObjectUtils from '../../../utils/object-utils';
import UIUtils from '../../../utils/ui-utils';

// Components
import PopoverHeader from './header';

import { ReactSVG } from 'react-svg';

/**
 * Popover content for the Security Pin
 *
 * @class SecurityPinPopover
 * @extends {Component}
 */
class SecurityPinPopover extends Component {
	constructor(props) {
		super(props);
		this.link = null;
		this.onSettingClick = this.onSettingClick.bind(this);
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf SecurityPinPopover
	 */
	static getStores() {
		return [AdminSettingsStore, TableStore, RecordStore];
	}

	/**
	 * Handler for clicking on a setting in the popover content
	 * 
	 * @param {any} securityGroupRecordId 
	 * @param {any} event 
	 * @memberof SecurityPinPopover
	 */
	onSettingClick(securityGroupRecordId, event) {
		event.preventDefault();

		if(!securityGroupRecordId) {
			return;
		}

		// Open Settings tab under right-panel
		AdminSettingsActions.onSettingsTabChangeMain('settings');

		UIUtils.openSettingsPanel('security',
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 'settings',
			this.state.targetTableSchemaName, null, this.props.renderId, this.props.attachmentId
		);

		AdminSettingsActions.onSettingChange(undefined, securityGroupRecordId);
	}

	/**
	 * Returns the current State
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf SecurityPinPopover
	 */
	static calculateState(prevState, props) {
		let tableObj = {};
		let tablePermissions = {};
		let targetTableSchemaName = '';

		if(props.tableSchemaName === 'field') {
			let fieldObj = FieldStore.get(props.recordId);
			targetTableSchemaName = fieldObj.tableSchemaName;
		} else {
			// Assume we're a page...
			let pageObj = PageStore.get(props.recordId);
			targetTableSchemaName = pageObj.tableSchemaName;
		}
		
		tableObj = TableStore.getByTableSchemaName(targetTableSchemaName);
		if(tableObj && tableObj.securityPermissions) {
			tablePermissions = ObjectUtils.getObjFromJSON(tableObj.securityPermissions);
		}

		// Generate User State Array
		let userStateArr = [];

		if(!tablePermissions['authenticated']) {
			tablePermissions['authenticated'] = {};
			tablePermissions['authenticated'].create = '1';
			tablePermissions['authenticated'].read = '1';
			tablePermissions['authenticated'].update = '1';
			tablePermissions['authenticated'].delete = '1';		
		}

		if(!tablePermissions['nonauthenticated']) {
			tablePermissions['nonauthenticated'] = {};
			tablePermissions['nonauthenticated'].create = '0';
			tablePermissions['nonauthenticated'].read = '0';
			tablePermissions['nonauthenticated'].update = '0';
			tablePermissions['nonauthenticated'].delete = '0';		
		}

		// Authenticated Users
		userStateArr.push({
			securityGroupRecordId: 'authenticated',
			label: 'All Authenticated Users',
			type: 'builtIn',
			permissions: tablePermissions['authenticated']
		});

		// Unauthenticated Users
		userStateArr.push({
			securityGroupRecordId: 'nonauthenticated',
			label: 'All Non-Authenticated Users',
			type: 'builtIn',
			permissions: tablePermissions['nonauthenticated']
		});

		// Lookup and populate componentSettings with additional Security Groups.
		let securityGroupArr = []; 
		let securityGroups = RecordStore.getRecords('securityGroup');
		if(securityGroups !== null) {
			if(securityGroups) {
				Object.keys(securityGroups).forEach(securityGroupRecordId => {
					let securityGroup = securityGroups[securityGroupRecordId];
					if(!securityGroup.deleted || (securityGroup.deleted && securityGroup.deleted.value !== true)) {
						securityGroupArr.push({
							securityGroupRecordId: securityGroupRecordId,
							label: (securityGroup.name ? securityGroup.name.value : '[ No Name ]'),
							type: 'defined',
							permissions: (tablePermissions[securityGroupRecordId]
								? tablePermissions[securityGroupRecordId] : {})
						});
					}
				});
			}
		} else {
			RecordsAPI.getAllRecordsByTable(ContextStore.getState(), 'securityGroup');
			securityGroupArr.push({
				label: 'Loading...',
				isSelected: false,
				type: 'defined'
			});
		}

		// Sort the results by setting name
		securityGroupArr.sort(function(a,b){
			if(a.label.toLowerCase() < b.label.toLowerCase()) { 
				return -1;
			}
			return 1;
		});

		return {
			securityGroupArr: securityGroupArr,
			userStateArr: userStateArr,
			logicInfo: {
				isSelected: false
			},
			targetTableSchemaName: targetTableSchemaName,
		}
	}

	/**
	 * Renders a single security group as a disabled LI option.
	 * 
	 * @param {Object} securityGroup 
	 * @param {string} securityGroup.securityGroupRecordId Record ID of the Security Group.
	 * @param {string} securityGroup.label Label for the option.
	 * @param {boolean} securityGroup.isSelected Is this option the selected UI option?
	 * @param {string} securityGroup.type builtIn | logic | defined
	 * @param {Object} securityGroup.permissions Optional - if empty there will be no C.R.U.D. displayed
	 * @param {string} securityGroup.permissions.create 1 | 0 - If Create is enabled.
	 * @param {string} securityGroup.permissions.read 1 | 0 - If Read is enabled.
	 * @param {string} securityGroup.permissions.update 1 | 0 - If Update is enabled.
	 * @param {string} securityGroup.permissions.delete 1 | 0 - If Delete is enabled.
	 * @param {number} index The Index of this option
	 * @return JSX
	 */
	_renderDisabledOption(securityGroup, index) {
		let labelClassNames = 'setting-label disabled';
		let iconClassNames = 'setting-icon disabled fa ';
		
		switch(securityGroup.type) {
			case 'builtIn':
				iconClassNames += 'fa-user';
			break;
			case 'logic':
				iconClassNames += 'fa-puzzle-piece';
			break;
			default:
			case 'defined':
				iconClassNames += 'fa-users';
			break;
		}

		return (<li key={index} className="nav-item">
			<div className={iconClassNames} />
			<div className="d-flex setting-text-container">
				<div className="w-75 setting-label settings-list-value">
					<div className={labelClassNames}>{securityGroup.label}</div>
				</div>
				<div className="w-25 d-flex justify-content-between setting-value-container">
					<div className="text-right setting-pattern-list setting-value align-self-center">
					</div>
				</div>
			</div>
		</li>);
	}
	/**
	 * Renders a single security group as an LI option.
	 * 
	 * @param {Object} securityGroup 
	 * @param {string} securityGroup.securityGroupRecordId Record ID of the Security Group.
	 * @param {string} securityGroup.label Label for the option.
	 * @param {boolean} securityGroup.isSelected Is this option the selected UI option?
	 * @param {string} securityGroup.type builtIn | logic | defined
	 * @param {Object} securityGroup.permissions Optional - if empty there will be no C.R.U.D. displayed
	 * @param {string} securityGroup.permissions.create 1 | 0 - If Create is enabled.
	 * @param {string} securityGroup.permissions.read 1 | 0 - If Read is enabled.
	 * @param {string} securityGroup.permissions.update 1 | 0 - If Update is enabled.
	 * @param {string} securityGroup.permissions.delete 1 | 0 - If Delete is enabled.
	 * @param {number} index The Index of this option
	 * @return JSX
	 */
	_renderOption(securityGroup, index) {
		let labelClassNames = 'setting-label';
		/*************************
			The following commented out statements are for the setting-icon, which is not currently in use
			Uncomment, when setting-icon is used again
		**************************/

		// let iconClassNames = 'setting-icon fa ';
		
		// switch(securityGroup.type) {
		// 	case 'builtIn':
		// 		iconClassNames += 'fa-user';
		// 	break;
		// 	case 'logic':
		// 		iconClassNames += 'fa-puzzle-piece';
		// 	break;
		// 	default:
		// 	case 'defined':
		// 		iconClassNames += 'fa-users';
		// 	break;
		// }

		return (<li key={index} className="nav-item">
			<div className="nav-link" onClick={this.onSettingClick.bind(this, securityGroup.securityGroupRecordId)}>
				{/* Temporarily removed the following div */}
				{/* <div className={iconClassNames} /> */}
				{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
				{/* Add this back in when the setting-icon is used again */}
				<div className="d-flex setting-text-container">
					<div className="w-75 settings-list-value">
						<div className={labelClassNames}>{securityGroup.label}</div>
					</div>
					<div className="w-25 d-flex justify-content-between setting-value-container">
						<div className="text-right setting-pattern-list setting-value align-self-center">
							{(securityGroup.permissions 
								? <div>
									<span className={(securityGroup.permissions['create'] === '1' ? 'selectedCRUDOption' : '')}>C.&nbsp;</span>
									<span className={(securityGroup.permissions['read']   === '1' ? 'selectedCRUDOption' : '')}>R.&nbsp;</span>
									<span className={(securityGroup.permissions['update'] === '1' ? 'selectedCRUDOption' : '')}>U.&nbsp;</span>
									<span className={(securityGroup.permissions['delete'] === '1' ? 'selectedCRUDOption' : '')}>D.</span>
								</div>
								: null)}
						</div>
					</div>
				</div>
			</div>
		</li>);
	}

	render() {
		// add this back in when using Custom Security Logic
		// let { logicInfo } = this.state;
		let {securityGroupArr, userStateArr} = this.state;
		let settingsList = [];

		// Process the Security Group Array
		settingsList.push(<li key={settingsList.length} className="settings-list-header nav-header d-flex">
			<ReactSVG 
				beforeInjection={svg => {
					svg.setAttribute('style', 'width: 22px; height: 22px');
				}}
				src={ContextStore.getUrlMedia() + "/icon-security.svg"} />
			<h3 style={{ lineHeight: 1.1 }}>Security Group permissions provided to Users...</h3>
			</li>);
		securityGroupArr.forEach(securityGroup => {
			settingsList.push(this._renderOption(securityGroup, settingsList.length));
		});

		// Hard-Coded Security "Groups"
		settingsList.push(<li key={settingsList.length} className="settings-list-header nav-header d-flex">
			<ReactSVG 
				beforeInjection={svg => {
					svg.setAttribute('style', 'width: 22px; height: 22px');
				}}
				src={ContextStore.getUrlMedia() + "/icon-security.svg"} />
			<h3>User also receives permissions from...</h3>
			</li>);
		userStateArr.forEach(securityGroup => {
			settingsList.push(this._renderOption(securityGroup, settingsList.length));
		});

		// Temporarily commenting the following out until it is used

		// settingsList.push(this._renderDisabledOption({
		// 	securityGroupRecordId: 'logic',
		// 	label: 'Custom Security Logic',
		// 	isSelected: logicInfo.isSelected,
		// 	type: 'logic'
		// }, settingsList.length));

		return (
			<div style={{ height: '100%', overflow: 'hidden' }} id="popover-content" className="security-popover cd-tools">
				<PopoverHeader 
					recordId={this.props.recordId} 
					tableSchemaName={this.props.tableSchemaName} 
					iconBase="shield" 
					overlayName="Record Security"/>
				<div style={{ height: '100%', overflow: 'hidden' }} className="tab-content row mb-2">
					<div style={{ height: '100%', overflowY: 'auto' }} className="ml-3 pr-1 mr-2">
						<ul id="popover-settings" className="nav flex-column">
							{settingsList}
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	SecurityPinPopover.propTypes = {
    recordId: PropTypes.string.isRequired,
	tableSchemaName: PropTypes.string.isRequired,
    parentRecordId: PropTypes.string,
	parentTableSchemaName: PropTypes.string,
	subSettingIndex: PropTypes.number,
	subSettingSchemaName: PropTypes.string,
    // fieldType: PropTypes.string,
  };
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(SecurityPinPopover, {withProps: true});
export default container;