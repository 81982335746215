import AuthenticationStore from '../stores/authentication-store';
import AdminSettingsStore from '../stores/admin-settings-store';
import FieldActions from '../actions/field-actions';
import FieldStore from '../stores/field-store';
import FieldTypeStore from '../stores/field-type-store';
import FieldSettingsStore from '../stores/field-settings-store';
import InterfaceActions from '../actions/interface-actions';
import NLPBuilderDictionariesStore from '../stores/nlp-builder-dictionaries-store';
import PatternStore from '../stores/pattern-store';
import AssistantNLP from './assistant-nlp-utils';
import AssistantSearchUtils from './assistant-search-utils';
import BlockUtils from './block-utils';
import FieldUtils from './field-utils';
import UIUtils from './ui-utils';
import ObjectUtils from './object-utils';
import uuid from 'uuid';

export default {
	/**
	 * Process a Field pattern to "Attach" one field
	 * 
	 * @param {object} searchResult Object packed full of params!
	 * @param {string} searchResult.currentParentInfo.componentId Field to attach fields TO
	 * @param {string} searchResult.currentComponentInfo.componentId Field to attach
	 * @param {boolean} searchResult.closeSettingsPanel Whether or not to close the settings panel after attachment
	 * @param 
	 */
	processAttach(searchResult){
		let parentRecordId = searchResult.currentParentInfo && searchResult.currentParentInfo.componentId ? searchResult.currentParentInfo.componentId : '';
		let componentId = searchResult.currentComponentInfo && searchResult.currentComponentInfo.componentId ? searchResult.currentComponentInfo.componentId : '';
		let closeSettingsPanel = searchResult.closeSettingsPanel;
	
		let fieldSettings = FieldSettingsStore.getSettings(componentId) || {};
		let fieldLabel = fieldSettings.fieldLabel;

		//Notify it is being detached 
		InterfaceActions.notification({ 'level': 'info', 'message': 'Attaching ' + fieldLabel + ' to Field...' });

		// close the Panel
		if(closeSettingsPanel) {
			UIUtils.closeSettingsPanel();
		} else {
			//AssistantSearchUtils.refreshSearch();
		}

		//Attach Field: 
		return FieldUtils.attachChildPromise(parentRecordId, componentId);
	},

	/**
	 * Process a Field pattern to "Attach", pulling all children from another field
	 * 
	 * @param {object} searchResult  -Object packed full of params! 
	 * @param {string} searchResult.sourceFieldName  - Field from which fields will be borrow
	 * @param {string} searchResult.sourceFieldId  - Field Id from which fields will be borrow
	 * @param {string} searchResult.targetFieldId  - Field to attach the other Field's field
	 */
	processAttachAllFromAnotherField({sourceFieldName, sourceFieldId, targetFieldId}){
		// Get the children from the source field
		let fieldChildren = FieldUtils.getChildren(sourceFieldId, true);

		fieldChildren.forEach(fieldChild => {
			// Attach Each to the target field
			FieldUtils.attachChild(targetFieldId, fieldChild.fieldId, false);
		});

		FieldActions.pushToDatabase(FieldStore.get(targetFieldId));
		
		//Notify it is being attached
		InterfaceActions.notification({ 'level': 'info', 'message': 'Attaching Fields from ' + sourceFieldName + '...' });

		// Refresh the search
		//AssistantSearchUtils.refreshSearch();
	},

	/**
	 * Process a Field pattern as "Create"
	 * 
	 * @param {object} searchResult -Object packed full of params! 
	 * @param {string} searchResult.currentTableInfo.tableSchemaName - New Field's TableSchemaName 
	 * @param {string} searchResult.currentComponentInfo.componentSubtype - New Field's fieldType 
	 * @param {string} searchResult.methodInfo - Information needed for processing
	 * @param {string} searchResult.methodInfo.patternId - Pattern to run to create field
	 * @returns {string} recordId - New Field's recordId 
	 */
	processCreate(searchResult) {
		let fieldType = searchResult.currentComponentInfo.componentSubtype;
		let methodInfo = searchResult;
		let tableSchemaName = searchResult.currentTableInfo.tableSchemaName;
		if(!fieldType && methodInfo) {
			let patternId = methodInfo.patternId;
			let patternObj = PatternStore.get(patternId) || {};
			let settings = ObjectUtils.getObjFromJSON(patternObj.settings);
			fieldType = settings.fieldType || '';
		}
		let recordId = uuid.v4(),
			fieldTypeObj = FieldTypeStore.get(fieldType),
			fieldTypeName = (fieldTypeObj && fieldTypeObj.name) ? fieldTypeObj.name :  recordId,
			validSchemaName = FieldUtils.validateFieldSchemaName(fieldTypeName, tableSchemaName, recordId).validSchemaName,
			newFieldObject = {
				recordId: recordId,
				tableSchemaName: tableSchemaName,
				fieldSchemaName: validSchemaName,
				fieldType: fieldType
			};
		
		//Send the record To the store
		FieldActions.pushToStore(recordId, newFieldObject);

		let creationNotification = InterfaceActions.stickyNotification({'message': 'Creating field ' + validSchemaName + ' on ' + tableSchemaName + '. This may take a few seconds; please wait...', 'level': 'info'});
		
		//Return the recordId
		// return recordId;

		//Update the actual database with these settings and return it
		searchResult.currentComponentInfo = {
			componentType: 'field',
			componentSubtype: fieldType,
			componentId: recordId
		};
		return FieldActions.pushToDatabasePromise(FieldStore.get(recordId)).then(() => {
			InterfaceActions.clearStickyNotification(creationNotification);
			InterfaceActions.notification({'message': 'Field creation complete!', 'level': 'success'});	
			// UIUtils.openSettingsPanel('appearance', 
			// 	recordId, 'field', 
			// 	AdminSettingsStore.getParentRecordId(), AdminSettingsStore.getParentTableSchemaName());

			// InterfaceActions.notification({'message': 'Switching overlay to new field...', 'level': 'info'});

			return searchResult;
		});
	},

	/**
	 * Process a Field pattern as "Update"
	 * 
	 * @param {object} searchResult -Object packed full of params! 
	 * @param {string} searchResult.currentTableInfo.tableSchemaName - Field's TableSchemaName 
	 * @param {string} searchResult.methodInfo - Information needed for processing
	 * @param {string} searchResult.methodInfo.method - Is this update done via pattern or direct setting update?
	 * @param {string} searchResult.methodInfo.NLPInput - NLP value, used for the pattern or setting as appropriate
	 * @param {string} searchResult.methodInfo.patternId - Pattern to run to update field, if pattern
	 * @param {string} searchResult.methodInfo.settingSchemaName - Setting to update, if patternless
	 * @returns boolean
	 */
	processUpdate(searchResult) {
		let fieldRecordId = searchResult.currentComponentInfo.componentId;
		let method = searchResult.methodInfo.method;
		let NLPInput = searchResult.methodInfo.NLPInput;

		if(method === 'patternless') {
			let settingSchemaName = searchResult.methodInfo.settingSchemaName;
			// let settingObj = FieldStore.get("27fdf34c-252d-4196-bbd8-33486e18db64");
			// Update the store
			FieldActions.pushSettingToStore(fieldRecordId, settingSchemaName, NLPInput);
	
			// Update the history
			FieldActions.appendSettingHistory(fieldRecordId, settingSchemaName, NLPInput, 
				AuthenticationStore.getUserId(), AuthenticationStore.getUsername(), 'patternlessSettingUpdate', 'custom');
		} else {
			let patternId = searchResult.methodInfo.patternId;
			let patternObj = PatternStore.get(patternId);
	
			// Make sure we could find the pattern object.
			if(!patternObj) {
				console.warn('Aborting: Unable to locate pattern from search result:', searchResult);
				return false;
			}
	
			// We have to run against a field record.
			if(!fieldRecordId) {
				console.warn('Aborting: No field record ID specified');
				return false;
			}
	
			// Load the pattern settings actions/parameters
			let patternSettingsJSON = patternObj['settings'],
				patternSettingsObj = {};
			try {
				patternSettingsObj = JSON.parse(patternSettingsJSON);
			} catch (exception) {
				console.warn('unable to parse pattern settings JSON: ', patternSettingsJSON, 'for', patternId);
				return false;
			}
	
			// For Field Pattern, the settings object will have a fields key with each setting we want to update
			patternSettingsObj.fields.forEach(patternSetting => {
				let action = patternSetting.action,
					settingSchemaName = patternSetting.fieldSchemaName,
					overwriteCustom = patternSetting.overwriteCustom,
					newValue = patternSetting.value,
					valueQuality = patternSetting.chanceOfChange,
					overwriteValue = true;
	
				// If there is to be no change for this setting or it's supposed to be "user input", bail now.
				if(action === 'noChange') {
					return false;
				}
	
				// If we're clearing, then the new value should be ''.
				if(action === 'clear') {
					newValue = '';
				}
	
				// If we're pulling from the NLP, then the new value should be the NLPInput.
				if(action === 'setToNLP') {
					newValue = NLPInput;
				}
	
				// If we're supposed to over write a value that was set by another Pattern...
				if(overwriteCustom !== 'true') {
					// Find out what the quality is of the current value (read the most recent history)
					let fieldSettingHistory = FieldStore.getSettingHistory(fieldRecordId, settingSchemaName);
					// If we have a history...
					if(fieldSettingHistory && fieldSettingHistory.length > 0) {
						// Take the most recent entry
						let recentFSH = fieldSettingHistory.pop();
						// And if the valueQuality is custom (a User entered it...)
						if(recentFSH.valueQuality === "custom" && recentFSH.value !== '') { 
							overwriteValue = false;
						}
					}
				}
	
				// Are we supposed to write?
				if(overwriteValue) { 
					// Update the store
					FieldActions.pushSettingToStore(fieldRecordId, settingSchemaName, newValue);
	
					// Update the history
					FieldActions.appendSettingHistory(fieldRecordId, settingSchemaName, newValue, 
						AuthenticationStore.getUserId(), AuthenticationStore.getUsername(), patternId, valueQuality);
				}
			});
		}


		// Commit to the database.
		let updateNotification = InterfaceActions.stickyNotification({'message': 'Updating field...', 'level': 'info'});
		let updatedField = FieldStore.get(fieldRecordId, true);
		let updatePromise = FieldActions.pushToDatabasePromise(updatedField).then(() => {
			InterfaceActions.clearStickyNotification(updateNotification);
			InterfaceActions.notification({'message': 'Update successful!', 'level': 'success'});	
			return searchResult;
		});
		return updatePromise;
	},

	/**
	 * Update a field with pattern-like logic, using the settings passed into settingsArray
	 * 
	 * @param {string} fieldRecordId Field ID to set the settings on
	 * @param {any} patternId Pattern ID to use for the settings' history
	 * @param {any} settingsArray Array of settings, each one with fieldSchemaName, value, chanceOfChange
	 */
	processUpdateWithSettings(fieldRecordId, patternId, settingsArray) {
		let	patternObj = PatternStore.get(patternId);

		// Make sure we could find the pattern object.
		if(!patternObj) {
			console.warn('Aborting: Unable to locate pattern from pattern ID:', patternId);
			return Promise.resolve(false);
		}

		// We have to run against a field record.
		if(!fieldRecordId) {
			console.warn('Aborting: No field ID specified');
			return Promise.resolve(false);
		}

		settingsArray.forEach(setting => {
			let newValue = setting.value,
				valueQuality = setting.chanceOfChange,
				settingSchemaName = setting.fieldSchemaName,
				excludeFromHistory = setting.excludeFromHistory;

			// Update the store
			FieldActions.pushSettingToStore(fieldRecordId, settingSchemaName, newValue);

			// Update the history (only if not excluded from the history, which we do with some long settings)
			if(!excludeFromHistory) {
				FieldActions.appendSettingHistory(fieldRecordId, settingSchemaName, newValue, 
					AuthenticationStore.getUserId(), AuthenticationStore.getUsername(), patternId, valueQuality);
			}
		});

		// Commit to the database.
		InterfaceActions.notification({'message': 'Updating field...', 'level': 'info'});
		return FieldActions.pushToDatabasePromise(FieldStore.get(fieldRecordId, true)).then(results => {
			return results;
		});
	},
	
	/**
	 * Process a Field as "Delete"
	 * @param {object} searchResult - Object with all the params 
	 * @param {string} searchResult.componentInfo.componentId - Field Id to delete  
	 * @param {string} searchResult.componentInfo.componentName - Field Label to delete  
	 * @param {string} searchResult.currentTableInfo.tableSchemaName - tableName to delete Field from   
	 */
	processDelete(searchResult) {
		let componentId = searchResult.currentComponentInfo.componentId;
		let componentName = searchResult.currentComponentInfo.componentName;
		let tableSchemaName = searchResult.currentTableInfo.tableSchemaName;

		//Notify user the delete Process Sucess
		InterfaceActions.notification({ 'level': 'info', 'message': 'Deleting ' + componentName + '...' });
		
		//Close panel if we are in the same fieldId
		if(AdminSettingsStore.getRecordId() === componentId) {
			UIUtils.closeSettingsPanel();
		}

		// Delete the field.
		return FieldActions.deleteFromDatabasePromise(componentId, tableSchemaName);
	},
	/**
	 * Process a Field pattern as "Detach"
	 * 
	 * @param {object} searchResult Object packed full of params!
	 * @param {string} searchResult.currentParentInfo.componentId Field to detach fields FROM
	 * @param {string} searchResult.currentComponentInfo.componentId Field to detach
	 * @param {boolean} searchResult.closeSettingsPanel - Should close panel after detaching?
	 * 
	 * @todo Add and test calls to this function
	 */
	processDetach(searchResult){
		let parentRecordId = searchResult.currentParentInfo && searchResult.currentParentInfo.componentId ? searchResult.currentParentInfo.componentId : '';
		let componentId = searchResult.currentComponentInfo && searchResult.currentComponentInfo.componentId ? searchResult.currentComponentInfo.componentId : '';
		let closeSettingsPanel = searchResult.closeSettingsPanel;

		if(componentId !== 'all'){
			let fieldSettings = FieldSettingsStore.getSettings(componentId) || {};
			let fieldLabel = fieldSettings.fieldLabel;
			//Detach the child field
			FieldUtils.detachChild(parentRecordId, componentId, true);
			
			//Notify it is being detached 
			InterfaceActions.notification({ 'level': 'info', 'message': 'Detaching ' + fieldLabel + '...' });
		} else {
			FieldUtils.detachAllChildren(parentRecordId, true);
			//Notify it is being detached
			InterfaceActions.notification({ 'level': 'info', 'message': 'Detaching all fields...' });
		}

		//close the Panel
		if(closeSettingsPanel) {
			UIUtils.closeSettingsPanel();
		} else {
			//AssistantSearchUtils.refreshSearch();
		}
	},

	/**
	 * Gets Attach Search Results for Field 
	 * 
	 * @param {string} recordId 
	 * @param {string} tableSchemaName 
	 * @param {string} fieldLabel 
	 * @param {string} fieldTypeId 
	 * @param {string} parentRecordId 
	 * @param {string} parentTableSchemaName 
	 * @param {string} parentLabel 
	 * @param {object} inputParsed 
	 * @returns search Results 
	 */
	attachResults(recordId, tableSchemaName, fieldLabel, fieldTypeId, parentRecordId, parentTableSchemaName, parentLabel, inputParsed){
		let searchResults = [];
		
		// Get the Possible Children for this Field (eg. List)
		let attachChildrenArray = FieldUtils.getPossibleChildren(recordId);
	
		// Turn them into Search Results
		searchResults = AssistantSearchUtils.getAttachChildrenResults(tableSchemaName, fieldLabel, recordId, attachChildrenArray, inputParsed, true);
			
		// Get the possible Siblings for this field
		let attachSibilingsArray = FieldUtils.getPossibleSiblings(parentTableSchemaName, parentRecordId, inputParsed);
		
		// Turn them into Search Results 
		let attachSibilingsResults = AssistantSearchUtils.getAttachChildrenResults(parentTableSchemaName, parentLabel, parentRecordId, attachSibilingsArray, inputParsed);

		// Combine the siblings and children
		searchResults = searchResults.concat(attachSibilingsResults);

		// List other fields of this same type, for this same default table and allow copying all children from those other fields.
		if(attachChildrenArray.length > 0) {
			let listFieldObj = FieldStore.get(recordId);
			FieldStore.getByFieldType(fieldTypeId).forEach(otherListField => {
				if(otherListField.recordId === recordId) {
					return false;
				}

				if(otherListField.tableSchemaName !== listFieldObj.tableSchemaName) {
					return false;
				}
				
				//Get the Other List Name: 
				let otherFieldLabel = BlockUtils.getName(otherListField.recordId, tableSchemaName);

				//Initialize the Scoring:
				let score = 1,
					scoreDescription = [`+1 - Attach to current Field.`];
					
				//Add score for Operation 
				if(inputParsed['operation']['Attach']){
					// Add +2 to score when matching operation; 
					scoreDescription.push(`+2 - Operation Match: Attach`);
					score += 2;
				}

				//Add scoring for Matches representing All: 
				if(inputParsed && Array.isArray(inputParsed.inputArray)){
					let inputArray = inputParsed.inputArray;
					inputArray.forEach(word =>{
						let keywords = otherFieldLabel.split(' ');
							//include general keywords: 
							keywords = keywords.concat(['all', 'from', 'other']);
						keywords.forEach(nameWord => {
							if(word.toLowerCase().trim() === nameWord.toLowerCase()){
								//Add +1 to score for keywords of this other List 
								scoreDescription.push(`+1 - Other Field Match '${nameWord}'`);
								score += 1;
							}
						})
					})
				} //End of Finding matches for this child Name in the input 

				// Show this as a result.
				searchResults.push({
					resultId: 'attachall-' + otherListField.recordId + '-' + recordId,
					label: `Attach Fields from the [ ${otherFieldLabel} ] Field to this Field.`,
					score: score,
					scoreDescription: scoreDescription,
					sourceFieldName: otherFieldLabel,
					sourceFieldId: otherListField.recordId,
					targetFieldId: recordId,
					type: 'attachAllFromAnotherField'
				}); //End of Pushing Results to Search results Array
			});
		}
		return searchResults;
	},

	/**
	 * 
	 *  Gets Create Search Results for Field 
	 * 
	 * @static
	 * @param {string} parentRecordId 
	 * @param {string} parentTableSchemaName 
	 * @param {string} settingSchemaName 
	 * @param {string} NLPValue 
	 * @param {object} inputParsed 
	 * @returns - Search Results 
	 * @memberof FieldSearchResults
	 */
	createResults(parentRecordId,parentTableSchemaName, settingSchemaName, NLPValue, inputParsed){
		let searchResults = [];
		//Get patterns by Operation === 'create'
		let patternsArray = NLPBuilderDictionariesStore.getPatternsByKeyAndBlockType('operation', 'create', 'field');

		// Turn them into Search Results
		searchResults = AssistantSearchUtils.getCreateFieldResults(patternsArray, parentRecordId, settingSchemaName, NLPValue, inputParsed, parentTableSchemaName);
		
		return searchResults;
	},
	/**
	 *  Gets Detach Search Results for Field  
	 * 
	 * @static
	 * @param {string} recordId 
	 * @param {string} fieldLabel 
	 * @param {string} fieldTypeId 
	 * @param {string} tableSchemaName 
	 * @param {string} parentRecordId 
	 * @param {object} inputParsed 
	 * @returns 
	 * @memberof FieldSearchResults
	 */
	detachResults(recordId, fieldLabel, fieldTypeId, tableSchemaName, parentRecordId, inputParsed){
		let searchResults = [];
		
		//Get this field's Children (fields attached to this List, Container, etc)
		let	detachChildrenArray = FieldUtils.getChildren(recordId, true);
		// Turn them into search results
		searchResults = AssistantSearchUtils.getDetachChildrenResults(tableSchemaName, fieldLabel, recordId, detachChildrenArray, inputParsed, fieldTypeId);

		//Get this field's Parents (Pages and Lists this field is attached to)
		let	detachParentsArray = FieldUtils.getParents(recordId); 

		// Turn them into search results
		let detachParentsResults = AssistantSearchUtils.getDetachParentsResults(fieldLabel, recordId, parentRecordId,  detachParentsArray, inputParsed);

		// Combine the children and parents 
		searchResults = searchResults.concat(detachParentsResults);

		return searchResults;
	},
	/**
	 * Gets Delete Search Results for Field  
	 * 
	 * @static
	 * @param {string} recordId 
	 * @param {object} inputParsed 
	 * @returns 
	 * @memberof FieldSearchResults
	 */
	deleteResults(recordId, inputParsed){
		let searchResults = [];

		//Get the field Matches
		let fieldMatches = [{
			id: recordId,
			label: BlockUtils.getName(recordId, 'field'),
			matchCount: 0
		}];
		
		//Turn them into search Results 
		searchResults = AssistantSearchUtils.getDeleteFieldsResults(fieldMatches, inputParsed, recordId);

		return searchResults;
	},
	/**
	 * Gets Update Search Results for Field  
	 * 
	 * @static
	 * @param {string} recordId 
	 * @param {string} fieldLabel 
	 * @param {string} settingSchemaName 
	 * @param {string} fieldTypeObj 
	 * @param {string} fieldTypeId 
	 * @param {string} NLPValue 
	 * @param {object} inputParsed 
	 * @returns 
	 * @memberof FieldSearchResults
	 */
	updateResults(recordId, fieldLabel, settingSchemaName, fieldTypeObj, fieldTypeId, NLPValue, inputParsed){
		let searchResults = [];
		// Look up the patterns for this field's field type.
		let patternMatches = NLPBuilderDictionariesStore.getFieldPatternsByFieldTypeId(fieldTypeId);
		// patternMatches = AssistantNLP.scoreMatches(patternMatches, inputParsed.inputArray);
		
		// Loop over them.
		patternMatches.forEach(pattern => {
			if(pattern.type !== 'field' || pattern.operation !== 'update') { 
				// If it's not a field pattern or an update pattern, then skip it
				return;
			}

			// ** Scoring
			// Grab the initial score for this pattern from the match count.
			let score = (pattern.matchCount ? pattern.matchCount : 0),
				scoreDescription = [ '+' + score + ' - pattern keywords matches' ],
				percentMatch = 0;

			//Add score for Operation 
			if(inputParsed['operation']['Update']){
				// Add +2 to score when matching operation; 
				scoreDescription.push(`+2 - Operation Match: Update`);
				score += 2;
			}
			
			// If we have a selected setting, and this pattern works on that setting.
			if(settingSchemaName && pattern.settingSynonyms && pattern.settingSynonyms.includes(settingSchemaName)) { 
				scoreDescription.push('+1 - Setting Match (' + settingSchemaName + ')');
				score += 1;
			}

			//Add scoring for keywords (single and multi keywords included):
			let input = (inputParsed && inputParsed.inputString) ? inputParsed.inputString : '',
				keywordArray = pattern.searchable;
			
			//Get Matches for Keywords 
			let scoreKeywords = AssistantSearchUtils.getKeywordMatches(input, keywordArray);

			// If any keywords matches found...
			if(scoreKeywords){
				//Include results to the scoring variables 
				scoreDescription = scoreDescription.concat(scoreKeywords.scoreDescription);
				score += scoreKeywords.score;
				percentMatch = scoreKeywords.percentMatch;
			}

			// Show this pattern for each field that's matched.
				let myFieldObj = FieldStore.get(recordId);

				// If this field is not of the right type
				if (recordId && recordId.startsWith('New ')) {
					// This is a new field being updated and we'll have to deal with it appropriately later.
				} else if(!myFieldObj || myFieldObj.fieldType !== fieldTypeId) {
					return;
				}

				let pushResultToArray = true;
				// If our pattern cares about NLP "" values...
				if(pattern.NLPValueSettingSchemaName) {

					// Let's find the setting that we'll be filling in.
					let fieldTypeSetting = null;
				
					fieldTypeObj.settings.forEach(setting => {
						// Each setting of the fieldtype contains ONLY a recordId. Look for the object in the FieldStore 
						 let fieldObj = FieldStore.get(setting.recordId);
						
						 //Now match the fieldObj fieldSchemaName to the pattern's NLP Setting Schema Name
						 if(fieldObj.fieldSchemaName === pattern.NLPValueSettingSchemaName) { 
							let fieldSettings = FieldStore.getSettings(setting.recordId);
							fieldTypeSetting = fieldSettings;
						} 
					})
					
					// If we found the matching Setting
					if(fieldTypeSetting) {

						// Check out NLP value classification.
						let valueClassification = AssistantNLP.classifyValue(NLPValue);
					
						// If our value's classification is the same as our pattern's desired one..
						// OR the NLP Input value is blank..
						if(!NLPValue || NLPValue.length === 0 || valueClassification !== pattern.NLPValueSettingClassification){
							// Put Score us without a valid NLP Value, and log an error.
							pattern.label = 'Set ' + fieldTypeSetting.fieldLabel + ' to "".';
						} else if(valueClassification === pattern.NLPValueSettingClassification && NLPValue.length > 0){
							// then score us for having a valid NLP Value.
							pattern.label = 'Set ' + fieldTypeSetting.fieldLabel + ' to "' + NLPValue + '".';
							scoreDescription.push('+1 - NLP Input required, and provided');
							score += 1;    
						}
						
					} else {
						// If the Setting is not on the fieldtype 
						pushResultToArray = false;
					}
				}

			if(pushResultToArray){
				// Show this as a result.
				searchResults.push({
					resultId: 'update-' + pattern.id,
					label: pattern.label,
					patternId: pattern.id,
					score: score,
					scoreDescription: scoreDescription,
					fieldId: recordId,
					fieldLabel: fieldLabel,
					type: 'update',
					NLPInput: NLPValue,
					percentMatch: percentMatch,
					searchable: pattern.searchable
				});
			}
		});

		return searchResults;
	}
};
