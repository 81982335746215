var keyMirror = require('keymirror');

var RecordSetConstants = keyMirror({
	'SET_RECORD_SET': null,
	'SET_RECORD_SET_BULK': null,
	'SET_SELECTED_RECORD_SET': null,
	'SET_SELECTED_CONTENT_TAB': null
});

module.exports = RecordSetConstants;
