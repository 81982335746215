import { ContextStore } from '../stores';

export default {
	/**
	 * Lookup the installation's role, development testing or production
	 * 
	 * @return {string}
	 */
	getRole: function() {
		return ContextStore.getInstallationRole();
	}
};
