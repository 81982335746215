import React, {Component} from 'react';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';
import InterfaceActions from '../../../actions/interface-actions';

// Apis
import AuthenticationApi from '../../../apis/authentication-api';
import socketFetcher from '../../../utils/socket-fetcher';

// Constants
import Upgrade from '../../../constants/help-upgrade-constants';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import AuthenticationStore from '../../../stores/authentication-store';
import FieldStore from '../../../stores/field-store';
import ContextStore from '../../../stores/context-store';

// Utils
import UIUtils from '../../../utils/ui-utils';
import { ReactSVG } from 'react-svg';


/**
 * Renders interface of width/height input fields and desktop/tablet/mobile buttons.
 */
class ImpersonationControls extends Component {
  /**
   * Creates ResizerControls
   *
   * @param {Object} props
   * @constructor
   *
   * @memberOf ResizerControls
   */
  constructor(props) {
    super(props);

    this.state = {
      usersLoading: false,
      usersLoaded: false,
      fieldsLoaded: FieldStore.allPulledFromDatabase(),
      planName: AuthenticationStore.getPlanName(),
      username: AuthenticationStore.getUsername(),
      userId: AuthenticationStore.getUserId(),
      originalUserId: AuthenticationStore.getOriginalUserId(),
      originalUsername: AuthenticationStore.getOriginalUsername(),
    };

    this.processLogout = this.processLogout.bind(this);
    this.processImpersonate = this.processImpersonate.bind(this);
    this.retrieveUsers = this.retrieveUsers.bind(this);
  }

  static getStores() {
		return [ FieldStore, AuthenticationStore ];
	}

  /**
   * When the component mounts, look up the users and groups.
   */
  componentDidMount() {
    let { fieldsLoaded, usersLoaded, usersLoading } = this.state;
    if(fieldsLoaded && !usersLoaded && !usersLoading) {
      this.setState({
        usersLoaded: false,
        usersLoading: true
      })
      this.retrieveUsers().then(users => {
        let newState = {
          usersLoading: false,
          usersLoaded: true,
          users: users
        }
        this.setState(newState);
      }).catch(error => {
        console.error('Error loading users in impersonation component:', error);
      });
    }
  }

  componentDidUpdate() {
    let { fieldsLoaded, usersLoaded, usersLoading } = this.state;
    if(fieldsLoaded && !usersLoaded && !usersLoading) {
      this.setState({
        usersLoaded: false,
        usersLoading: true
      })

      this.retrieveUsers().then(users => {
        let newState = {
          usersLoading: false,
          usersLoaded: true,
          users: users
        }
        this.setState(newState);
      }).catch(error => {
        console.error('Error loading users in impersonation component:', error);
      });
    }
  }


  /**
   * Retrieve the users with username, email, recordID and security groups as an array
   * @return {Promise} User Array
   */
  retrieveUsers() {
    return new Promise((resolve, reject) => {
      let users = [];
      let lookupPromises = [];

      // Prepare the request to get the users.
      let userRequestBody = {
        tableSchemaName: 'users',
        fields: [
          {
              "fieldSchemaName":"username",
              "fieldType":"d965b6d9-8dd0-440c-a31c-f40bf72accea",
              "fieldId":"98ca346e-2721-4083-b24d-38bead4f3ba8",
          }
        ]
      }

      // Find any user field with the type, email address.
      let emailField = FieldStore.getByTableSchemaName('users')
        .find(field => { 
          return (field.fieldType === '9e370d09-f21b-4950-a31b-d5ba18aa33ae' ? true : false);
        });
      // If there is one, get it!
      if(emailField && emailField[0]) {
        userRequestBody.fields.push(emailField[0]);
      }

      // Prepare the request to get the security groups, tied to the users.
      let securityGroupRequestBody = {
        "tableSchemaName": "securityGroup",
        "fields": [
          {
            "fieldSchemaName": "name",
            "fieldType": "d965b6d9-8dd0-440c-a31c-f40bf72accea",
            "fieldId": "ea0fddb1-b38b-4fd3-a698-5c09273aa7cb"
          }
        ],
        "query": JSON.stringify({
            "queryId": "4c833e10-c822-4e3a-98de-33931604f9de",
            "returnNode": "4b963298-0ee9-41e9-82ce-9803484cb3b6",
            "filters": [
              {
                "type": "filter",
                "operation": "recordIs",
                "filteredNode": "1a60f7b6-a5d1-4577-a131-bc35ae0cd85b",
                "fieldSchemaName": "recordIs",
                "value": "startingContext"
              }
            ],
            "nodes": [
              {
                "tableSchemaName": "securityGroup",
                "nodeId": "4b963298-0ee9-41e9-82ce-9803484cb3b6"
              },
              {
                "tableSchemaName": "users",
                "nodeId": "1a60f7b6-a5d1-4577-a131-bc35ae0cd85b",
                "filters": [
                  {
                    "type": "filter",
                    "operation": "relation",
                    "recordId": "e2dc036a-8cac-4861-9e4f-1bccafd85c30",
                    "filteredNode": "1a60f7b6-a5d1-4577-a131-bc35ae0cd85b",
                    "relatedNode": "4b963298-0ee9-41e9-82ce-9803484cb3b6",
                    "relationSchemaName": "userssecuritygroups",
                    "direction": "ltor",
                    "lCardinality": "m",
                    "rCardinality": "m",
                    "requirement": "must"
                  }
                ]
              }
            ],
            "sorts": []
          }),
        "currentContextQuery":JSON.stringify({
            "flushBlockly": true,
            "filters": [],
            "nodes": [
              {
                "nodeId": "c28432f2-9c4a-459c-85da-0fd10839b9a7",
                "tableSchemaName": "users",
                "displayName": "Users"
              }
            ],
            "queryId": "78fc2771-26c7-434d-89f4-7db66bfd708e",
            "returnNode": "c28432f2-9c4a-459c-85da-0fd10839b9a7",
          }),
          "recordSets": {"startingContext":null}
        };

      // Begin both requests.
      lookupPromises.push(socketFetcher('gw/recordBrowse-v4', JSON.stringify(userRequestBody)));
      lookupPromises.push(socketFetcher('gw/recordBrowseBulk-v2', JSON.stringify(securityGroupRequestBody)));

      // Process the results.
      Promise.all(lookupPromises).then(responses => {
        let userRequestResponse = responses[0];
        let securityGroupResponse = responses[1];

        if(userRequestResponse.responseCode === 200 && securityGroupResponse.responseCode === 200 ) {
          if(userRequestResponse.response && userRequestResponse.response.records && userRequestResponse.response.records.users && 
            securityGroupResponse.response && securityGroupResponse.response.records && securityGroupResponse.response.records.securityGroup) {
            Object.keys(userRequestResponse.response.records.users).forEach(userRecordId => {
              let userResponse = userRequestResponse.response.records.users[userRecordId];
              let user = {
                recordId: userRecordId,
                userName: userResponse.username,
                securityGroups: '',
                email: ''
              };
              if(emailField && emailField[0]) {
                if(userResponse[emailField[0].fieldSchemaName]) {
                  user.email = userResponse[emailField[0].fieldSchemaName];
                }
              }
              // If this user has any security groups..
              if(securityGroupResponse.response.contextMap[userRecordId]) {
                securityGroupResponse.response.contextMap[userRecordId].forEach(securityGroupRecordId => {
                  if(user.securityGroups.length) {
                    user.securityGroups += ', '
                  }
                  user.securityGroups += securityGroupResponse.response.records.securityGroup[securityGroupRecordId].name
                })
              }
              users.push(user);
            });
          }
        }
        users.sort((a, b) => {
          let aUserName = a.userName;
          let bUserName = b.userName;
          if(aUserName === bUserName) {
            return 0;
          } else {
            return aUserName < bUserName ? -1 : 1;
          }
        })
        return resolve(users);
      }).catch(error => {
        return reject(error);
      })
    });
  }

  /**
   * Impersonate the user!
   * @param {object} event The onClick Event, to prevent the default of.
   * @param {string} userId Record ID of the User to impersonate
   * @param {string} username Username of the user we're updating to
   */
  processImpersonate(event, userId, username) {
    event.preventDefault();
    InterfaceActions.stickyNotification({ 
      level: 'success', 
      title: 'Impersonating User...',
      message: username,
      id: 'impersonation'
    });
    AuthenticationApi.changeUser(userId, username).then((results) => {
      this.setState({ username: username, userId: userId });
      InterfaceActions.clearStickyNotification('impersonation');
    });
  }

	/**
	 * Click Handler for the logout button.
	 * 
	 * @param {object} event The onClick Event, to prevent the default of.
	 * @memberof MainFooter
	 */
   processLogout(event) {
		event.preventDefault();
		AuthenticationApi.logout(true).then(() => {
			// check whether left panel is open on logged out
			if (AdminSettingsStore.getIsLeftPanelOpen()) {
				// if it is then close the panel
				AdminSettingsActions.onLeftPanelChange(false);
			}
			// check whether right panel is open on logged out
			if (AdminSettingsStore.getIsRightPanelOpen()) {
				// if it is then close the panel
				AdminSettingsActions.onRightPanelChange(false);
			}
			// Get the current state 
			let currentState = AdminSettingsStore.getState().toJS();
			// check if there are active overlays;
			let activeOverlays = currentState.activeOverlays ? currentState.activeOverlays : [];
			if(activeOverlays.length) {
				// if overlays are active after logging out
				// then close all of them
				activeOverlays.forEach((overlay) => {
					AdminSettingsActions.onOverlayChange(overlay);
				});
			}
			// Do we need this?
		}).catch(error => {
			// Do we need this?
		});
	}

  /**
   * render - ResizerControls
   *
   * @return {JSX}
   */
  render() {
	  let state = this.state;

    // InterfaceActions.clearStickyNotification('impersonation'); 
    if(state.usersLoaded) {
      return this._renderWithUsers();
    } else {
      return this._renderWithoutUsers();
    }
  }

  /**
   * Helper Function version of render when users have returned
   * @returns JSX
   */
  _renderWithUsers() {
    let { users, planName, username, originalUserId, originalUsername } = this.state;

    // Calculate the userJSX
    let userJSX = <div>Error: No Users Found</div>;

    // See if we have processed users successfully...
    let newUserJSX = [];
    users.forEach((user, index) => {
		if(user.userName !== originalUsername) {
			let emailJSX = (user.email ? <div className="impersonateEmail">{ user.email }</div> : null );
			let secGroupJSX = (user.securityGroups ? <div className="impersonateSecurityGroups">{ user.securityGroups }</div> : null );
			newUserJSX.push(
			<div 
				style={{ cursor: 'pointer' }}
				onClick={(e) => {
					e.stopPropagation();
					this.processImpersonate(e, user.recordId, user.userName); 
				}} 
				key={index} className="d-flex justify-content-between user-info">
				<div className="d-flex flex-column user-details">
					<h5 className="bold">{ user.userName }</h5>
					<h5>{ emailJSX }{ secGroupJSX } </h5>
				</div>
				{( user.userName === username 
				? <img width='16' height='16' src={ContextStore.getUrlMedia() + "/icon-incognito.svg"} alt="" />
				: <h5 
					title="Switch to this user"
					key={user.recordId}>switch</h5>
				)}
			</div>
			);
		}
    });

    // If we have newUserJSX, then re-assign.
    if(newUserJSX.length) {
      userJSX = newUserJSX;
    }

    return (
		<div id="impersonation-controls">
	    	{/* <div className="btn-group dropdown"> */}
	    	<div className="dropdown">
				<div className="impersonation-down-arrow"></div>
				<div title="Manage User and Installation" className="dropdown-toggle form-inline" data-toggle="dropdown">
					{
						this.state.originalUsername === this.state.username 
							? <ReactSVG src={ContextStore.getUrlMedia() + "/icon-user.svg"} />
							: <ReactSVG 
								beforeInjection={svg => {
									svg.setAttribute('style', 'width: 26px; height: 26px');
								}}
								src={ContextStore.getUrlMedia() + "/icon-incognito.svg"} />
					}
				</div>
				<div className="dropdown-menu">
					<div className="d-flex flex-column" style={{ height: '100%' }}>
						<div className="impersonate-user-header d-flex justify-content-between">
							<div className="d-flex w-100">
								<img src={ContextStore.getUrlMedia() + "/icon-upgrade-caculator.svg"}  alt="" />
								<div className="ml-1">
									<h4>CURRENT PLAN</h4>
									<h4 className="plan-name">{ planName }</h4>
								</div>
							</div>
							<div title="Upgrade your application!" className="btn impersonation-btn-upgrade" onClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE) }}>
								Upgrade
							</div>
						</div>
						<div className="dropdown-divider my-3"></div>
						<div className="d-flex flex-column" 
							style={{ flex: 1, overflow: 'hidden', marginRight: '-10px' }}>
							<h4>Impersonate User</h4>
							<div className="dropdown-menu-body mt-3" 
								style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden', paddingRight: '10px' }}>
								{ userJSX }
							</div>
							<div className="dropdown-divider"></div>
							<div
								style={{ cursor: 'pointer' }}
								onClick={(e) => {
									e.stopPropagation();
									this.processImpersonate(e, originalUserId, originalUsername); 
								}}
							 	title="You are logged in as this user" 
								className="d-flex align-items-center justify-content-between logged-in-user-container">
								<div className="user-details">
									<h4 className="bold">Logged in User</h4>
									<h5 className="font-weight-normal">{ originalUsername }</h5>
								</div>
								<div 
									className="bold">
										{this.state.originalUsername === this.state.username 
											? <img width='16' height='16' src={ContextStore.getUrlMedia() + "/icon-user.svg"} alt="" />
											: <h5 title="Switch to this user">switch</h5>
										}
								</div>
							</div>
							<div className="dropdown-divider mb-0"></div>
							<div title="Logout" className="logout d-flex align-items-center" 
								onClick={this.processLogout}>
									<ReactSVG 
										beforeInjection={svg => {
											svg.setAttribute('viewBox', '0 0 34 34');
											svg.setAttribute('style', 'width: 22px; height: 22px');
										}}
										src={ContextStore.getUrlMedia() + "/icon-logout.svg"} />
									<h4>Logout</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>);
	}

  /**
   * Helper Function version of render when users have not yet returned
   * @returns JSX
   */
  _renderWithoutUsers() {
	let { planName, originalUsername } = this.state;
    return (
		<div id="impersonation-controls">
		{/* <div className="btn-group dropdown"> */}
		<div className="dropdown">
			<div className="impersonation-down-arrow"></div>
			<div title="Manage User and Installation" className="dropdown-toggle form-inline" data-toggle="dropdown">
				{
					this.state.originalUsername === this.state.username 
						? <ReactSVG src={ContextStore.getUrlMedia() + "/icon-user.svg"} />
						: <ReactSVG 
							beforeInjection={svg => {
								svg.setAttribute('style', 'width: 26px; height: 26px');
							}}
							src={ContextStore.getUrlMedia() + "/icon-incognito.svg"} />
				}
			</div>
			<div className="dropdown-menu without-users">
				<div className="d-flex flex-column" style={{ height: '100%' }}>
					<div className="impersonate-user-header d-flex justify-content-between">
						<div className="d-flex w-100">
							<img src={ContextStore.getUrlMedia() + "/icon-upgrade-caculator.svg"} alt="" />
							<div className="ml-1">
								<h4>CURRENT PLAN</h4>
								<h4 className="plan-name">{ planName }</h4>
							</div>
						</div>
						<div title="Upgrade your application!" className="btn impersonation-btn-upgrade" onClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE) }}>
							Upgrade
						</div>
					</div>
					<div className="d-flex flex-column" style={{ flex: 1, overflow: 'hidden', marginRight: '-10px' }}>
						<div className="dropdown-divider"></div>
						<div title="You are logged in as this user" className="d-flex align-items-center justify-content-between logged-in-user-container">
							<div className="user-details">
								<h4 className="bold">Logged in User</h4>
								<h5 className="font-weight-normal">{ originalUsername }</h5>
							</div>
							<div 
								className="bold" >
									{this.state.originalUsername === this.state.username 
										? <img width='16' height='16' src={ContextStore.getUrlMedia() + "/icon-user.svg"} alt="" />
										: <h6 title="Switch to this user" className="bold">switch</h6>
									}
							</div>
						</div>
						<div className="dropdown-divider mb-0"></div>
						<div title="Logout" className="logout d-flex align-items-center" 
							onClick={this.processLogout}>
								<ReactSVG 
									beforeInjection={svg => {
										svg.setAttribute('viewBox', '0 0 34 34');
										svg.setAttribute('style', 'width: 22px; height: 22px');
									}}
									src={ContextStore.getUrlMedia() + "/icon-logout.svg"} />
								<h4>Logout</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>);
  }

}

export default ImpersonationControls;