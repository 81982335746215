import AppDispatcher from '../dispatcher/app-dispatcher';
import ComplianceConstants from '../constants/compliance-constants';
import Immutable from 'immutable';

let _instance = null;

class ComplianceActions {
	/**
	 * Singleton instance of ComplianceActions
	 * 
	 * @param {Object} options 
	 */
	constructor(options) {
		/**
		 * Singleton instance of ComplianceActions
		 */
		if(_instance) {
			return _instance;
		}

		//function binding
		this.configure = this.configure.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);
		this.pullFromDatabaseAll = this.pullFromDatabaseAll.bind(this);
		this.pushToDatabasePromise = this.pushToDatabasePromise.bind(this);
		this.pushToStore = this.pushToStore.bind(this);

		// instance and dispatcher
		_instance = this;

		// path information
		if(options && options.mdgwMode) {
			let basePath = options.mdgwMode === 'stage' ? 'test-platform' : 'platform';
			this._basePath = 'https://' + basePath + '.citizendeveloper.com';
		}
	}

	/**
	 * Set the MDGW Mode and set up the basePath.
	 * 
	 * @param {string} installationId 
	 */
	configure(mdgwMode, appId) {
		let basePath = mdgwMode === 'stage' ? 'test-platform' : 'platform';
		this._basePath = 'https://' + basePath + '.citizendeveloper.com';
		this._appId = appId;
	}

    /**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._basePath) {
			throw(new Error('ComplianceActions not properly initialized with a MDGW mode. Please reference ComplianceActions.configure for more information.'));
		}
		if (!this._appId) {
			throw(new Error('ComplianceActions not properly initialized with an application ID. Please reference ComplianceActions.configure for more information.'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: ComplianceConstants.COMPLIANCE_PULL_ERROR,
			error: error.message
		}));
	}

	_checkStatus(res) {
		if (res.status >= 200 && res.status < 300) {
			return res;
		  } else {
			let err = new Error(res.statusText);
			err.response = res;
			throw err;
		  }
	}

	pushToDatabasePromise(newCompliances) {
		return new Promise((resolve, reject) => {
			fetch(this._basePath + '/compliances', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json; charset=UTF-8',
					// @TODO: Add in the application auth key
				},
				body: JSON.stringify({
					appId: this._appId,
					complianceIds: newCompliances.join(',')
				})
			})
			.then(this._checkStatus)
			.then(() => {
				return resolve();
			})
			.catch(reject);
		});
	}

	pushToStore(recordId, complianceProperties) {
		this._checkInit();
		// complianceProperties.isDirty = true;
		complianceProperties.recordId = recordId;
		AppDispatcher.dispatch(Immutable.fromJS({
			type: ComplianceConstants.COMPLIANCE_PUSH_TO_STORE,
			recordId: recordId,
			complianceProperties: complianceProperties
		}));
	}

	pullFromDatabase(complianceId, overwriteStore) {
		
		if(!complianceId) {
			return;
		}

		this._checkInit();

		fetch(this._basePath + '/compliances?appId=' + this._appId + '&complianceId=' + complianceId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				// @TODO: Add in the application auth key
			}
		})
		.then(this._checkStatus)
		.then((response) => {
			return response.json();
		})
		.then((compliances) => {
			AppDispatcher.dispatch(Immutable.fromJS({
				type: ComplianceConstants.COMPLIANCE_PULL_FROM_DATABASE,
				compliance: compliances[0],
				complianceId: complianceId,
				overwriteStore: overwriteStore
			}));
		})
		.catch((error) => { 
			console.error(error); 
			this._onError(error) ;
		});
	}

	pullFromDatabaseAll(overwriteStore) {
		this._checkInit();

		fetch(this._basePath + '/compliances?appId=' + this._appId, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8',
				// @TODO: Add in the application auth key
			}
		})
		.then(this._checkStatus)
		.then((response) => {
			return response.json();
		})
		.then((compliances) => {
			AppDispatcher.dispatch(Immutable.fromJS({
				type: ComplianceConstants.COMPLIANCE_PULL_FROM_DATABASE_ALL,
				compliances: compliances,
				overwriteStore: overwriteStore
			}));
		})
		.catch((error) => { 
			console.error(error); 
			this._onError(error) ;
		});
	}
};

const instance = new ComplianceActions();
export default instance;