import React,{Component} from 'react';
import PropTypes from 'prop-types';

/**
 * Component for BaseSettingForm
 * 
 * @class BaseSettingForm
 * @extends {Component}
 */
export default class BaseSettingForm extends Component {
	/**
	 * Render the BaseSettingForm
	 * 
	 * @returns React
	 * 
	 * @memberof BaseSettingForm
	 */
	render() {
		let {onChange, fieldSchemaName, value} = this.props;
		value = value ? value : '';
		return(
				<input id={fieldSchemaName} className="form-control" type="text" value={value} onChange={onChange} />
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	BaseSettingForm.propTypes = {
		fieldSchemaName: PropTypes.string.isRequired,
		value: PropTypes.any,
		onChange: PropTypes.func
	};
}
