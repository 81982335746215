/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container } from 'flux/utils';
import AppearanceAssistant from '../../assistants/appearance-assistant.react';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';
import AppearanceAssistantActions from '../../../actions/appearance-assistant-actions';

// Utils
import AssistantSearchUtils from '../../../utils/assistant-search-utils';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import ContextStore from '../../../stores/context-store';
import FieldStore from '../../../stores/field-store';
import FieldTypeStore from '../../../stores/field-type-store';
import PageStore from '../../../stores/page-store';
import TableStore from '../../../stores/table-store';
import UIUtils from '../../../utils/ui-utils';

// Dissappointments
import $ from 'jquery';

/**
 * Container to field type wrapper
 *
 * @class FieldTypeWrapperContainer
 * @extends {Component}
 */
class PopoverHeader extends Component {
	constructor(props) {
		super(props);
		this.link = null;
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf AppearancePin
	 */
	static getStores() {
		return [AdminSettingsStore, FieldStore, PageStore];
	}

	/**
	 * Returns the current State of the FieldType Settings
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf AppearancePin
	 */
	static calculateState(prevState, props) {
		let { recordId} = props;

		let componentId = AdminSettingsStore.getRecordId();
		let renderId = AdminSettingsStore.getRenderId();
		let attachmentId = AdminSettingsStore.getAttachmentId();
		let componentType = AdminSettingsStore.getTableSchemaName();
		let parentComponentId = AdminSettingsStore.getParentRecordId();
		let parentComponentType = AdminSettingsStore.getParentTableSchemaName();
		let settingSchemaName = AdminSettingsStore.getSettingSchemaName();

		let headerLabelPrimary = UIUtils.getSettingsHeader(componentId, componentType);
		let settingsHeaderSub1Obj = UIUtils.getSettingsHeaderSub1(componentId, componentType);
		let settingsHeaderSub2Obj = UIUtils.getSettingsHeaderSub2(parentComponentId, parentComponentType);

		// let fieldObj = FieldStore.get(componentId);
		let fieldObj = FieldStore.get(recordId);
		let fieldSchemaName = '';
		if(fieldObj) {
			fieldSchemaName = fieldObj.fieldSchemaName;
		}
		let fieldTypeName = '[ No Field Type selected ]';
		if(fieldObj) {
			let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType);
			if(fieldTypeObj) {
				fieldTypeName = fieldTypeObj.name;
			}
		}

		let tableName = '';
		if(componentType && fieldObj) {
			let tableObj = TableStore.getByTableSchemaName(fieldObj.tableSchemaName);
			tableName = tableObj.singularName;
		}

		return {
			fieldTypeName: fieldTypeName,
			'componentId': componentId,
			'renderId': renderId,
			'attachmentId': attachmentId,
			'componentType': componentType,
			'parentComponentId': parentComponentId,
			'parentComponentType': parentComponentType,
			'fieldSchemaName': fieldSchemaName,
			'settingSchemaName': settingSchemaName,
			'tableName': tableName,
			headerLabelPrimary: headerLabelPrimary,
			headerLabelSub1: settingsHeaderSub1Obj.label, 
			headerIconSub1: settingsHeaderSub1Obj.icon, 
			headerLabelSub2: settingsHeaderSub2Obj.label, 
			headerIconSub2: settingsHeaderSub2Obj.icon
		}
	}

	/**
	 * Method used when the X is pressed to close the panel.  
	 * @param {object} event - The onClick event.
	 */
	onClose(event) {
		event.preventDefault();

		// Clear the NLP input and search results
		AppearanceAssistantActions.onInputChange('');
		AssistantSearchUtils.runAssistantSearch({}, {}, '');

		// Clear the Settings Preview Panel
		AdminSettingsActions.onSettingsPreviewChange(false);
	}

	componentDidMount() {
		let { componentId, renderId, attachmentId } = this.state;

		$(this.link).popover({
			'container': 'body',
			'animation': false,
			'delay': {
				'hide': 500
			}
		})
		.on('mouseenter', function(event) {
			$(this).popover('show');
			$('.popover')
			.on('mouseenter',function(){
				$(this).popover('show');
				$('.copy-recordid').on('click', function(event) {
                    var tempInput = document.createElement("input");
                    $("body").append(tempInput);
                    tempInput.value = componentId;
					navigator.clipboard.writeText(tempInput.value);
                    tempInput.remove();
                })
				$('.copy-renderid').on('click', function(event) {
                    var tempInput = document.createElement("input");
                    $("body").append(tempInput);
                    tempInput.value = renderId;
					navigator.clipboard.writeText(tempInput.value);
                    tempInput.remove();
                })
				$('.copy-attachmentid').on('click', function(event) {
                    var tempInput = document.createElement("input");
                    $("body").append(tempInput);
                    tempInput.value = attachmentId;
					navigator.clipboard.writeText(tempInput.value);
                    tempInput.remove();
                })
			})
			.on('mouseleave',function(){
				$(this).popover('hide');
			})
		})
		.on('mouseleave',function(){
			if(!$('.popover:hover').length){
				// set delay for hiding popover (allows us to "cut the corners")
				$(this).popover('hide');
			}
		});
	}
	
	render() {
		let {
			componentType, 
			headerLabelPrimary, 
			headerLabelSub1, 
			headerIconSub1, 
			headerLabelSub2, 
			headerIconSub2,
			componentId,
			attachmentId,
			renderId
		} = this.state;
		let urlFontawesome = ContextStore.getUrlFontawesome();

		// Generate the settings panel chooser, if we need one.
		let settingsPanelChooserDropdown = null;
		if(componentType === 'field' || componentType === 'page') {
			settingsPanelChooserDropdown = UIUtils.getSettingSwitcherDropdown(componentType, componentId);
		}

		return (
			<header className="popover-header p-0">
				<div className="d-flex popover-draggable-handle" style={{ cursor: 'move' }}>
					<div style={{ flex: 1 }} className="d-flex flex-column px-2 py-1">
						<div id="setting-preview-label" className="w-100 settings-list-value setting-preview-label-line-clamp">
							<h3>{headerLabelPrimary}</h3>
						</div>
						<div id="setting-preview-field" className="muted d-flex flex-column theme-font-regular">
							<div className="d-flex">
								<img height="14" width="14" style={{ marginTop: '2px' }} className="mr-1" src={headerIconSub1} alt=""/>
								<h4>{headerLabelSub1}</h4>
							</div>
							{(headerIconSub2 || headerLabelSub2 ? 
								<div className="popover-override-setting-parent d-flex">
									<img style={{ marginTop: '2px' }} height="14" width="14" className="mr-1" src={headerIconSub2} alt=""/>
									<h4>{headerLabelSub2}</h4>
								</div>
							: null )}
							{/* { overrideSettingParentName 
								? 
									<div className="popover-override-setting-parent d-flex">
										<h4>Parent: {overrideSettingParentName}</h4>
									</div>
								: null
							} */}
						</div>
					</div>
					{componentId && 
						<div id="info-icon" style={{ marginTop: '3px' }} className="mr-3">
							<a className="info-button" href="#"
								ref={el => this.link = el}
								tabIndex="0"
								role="button"
								data-toggle="popover"
								data-trigger="focus" 
								data-placement="bottom"
								data-html="true"
								data-content={UIUtils.getPopoverContent(attachmentId, componentId, componentType, renderId)} onClick={(event) => { event.preventDefault(); }}>
								<i className="fa fa-info-circle" aria-hidden="true"></i>
							</a>
						</div>
					}
					<div style={{ marginTop: '6px' }} className="mr-2">
						<a className="close-button d-flex justify-content-center align-items-center" href="#" onClick={this.onClose}>
							<img src={urlFontawesome + '/close.svg'} className="icon" alt="" />
						</a>
					</div>
				</div>
				<div className="popover-header-controls">
					{settingsPanelChooserDropdown}
					<div className='mx-2 mt-3' id='popover-nlp-builder'>
						<AppearanceAssistant
							componentId={this.props.recordId}
							componentType={this.props.tableSchemaName}
							parentComponentId={this.props.parentRecordId}
							parentComponentType={this.props.parentTableSchemaName}
							contentsClass='popover-scrollable'
						/>
					</div>
				</div>
			</header>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	PopoverHeader.propTypes = {
		recordId: PropTypes.string.isRequired,
		tableSchemaName: PropTypes.string.isRequired,
		iconBase: PropTypes.string.isRequired,
		overlayName: PropTypes.string.isRequired,
  };
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(PopoverHeader, {withProps: true});
export default container;