import BaseSettingForm from './base-setting-form.react';
import BooleanSelect from './boolean-select.react';
import CodeEditor from './code-editor.react';
//import ConfigurationJson from './configuration-json.react';
import AttachmentChildrenContainer from './attachment-children-container.react';
import AttachmentParentContainer from './attachment-parent-container.react';
import DataTypeSelect from './data-type-select.react';
import EditVariant from './edit-variant.react';
import ExpressionEditor from './expression-editor.react';
import FieldSelector from './field-selector.react';
import LongText from './long-text.react';
import Name from './name.react';
import Number from './number.react';
import Operators from './operators.react';
import Select from './select.react';
import ShortText from './short-text.react';
import Switch from './switch.react';
import SelectOptions from './select-options.react';
import ViewVariant from './view-variant.react';
// import TableSelector from '../maps/table-selector.react';

export {
	BooleanSelect as AvailableOnRender,
	BooleanSelect as AllowAdd,
	BaseSettingForm,
	AttachmentChildrenContainer as ChildFields,
	BooleanSelect as ChildSettings,
	CodeEditor,
	//ConfigurationJson: ConfigurationJson,
	DataTypeSelect as DataType,
	DataTypeSelect as Datatype,
	BooleanSelect as Default,
	EditVariant, //@TODO Include  
	ExpressionEditor as Expression,
	FieldSelector,
	BooleanSelect as Filterable,
	LongText,
	BaseSettingForm as MaxWidth,
	Name,
	CodeEditor as NotStoredValueCode,
	Number,
	Operators,
	// Query: Query, //@TODO Include
	CodeEditor as ReactCode,
	Select,
	SelectOptions,	
	ShortText,
	BooleanSelect as Sortable,
	BooleanSelect as Stored,
	BooleanSelect as SortDefault,
	DataTypeSelect as StorageDataType,
	CodeEditor as StorageValidationCode,
	AttachmentParentContainer,
	Switch,
	// TableSelector,
	CodeEditor as ValueChangeCode,
	ViewVariant
};


export default {
	AvailableOnRender: BooleanSelect,
	AllowAdd: BooleanSelect,
	BaseSettingForm: BaseSettingForm,
	ChildFields: AttachmentChildrenContainer,
	ChildSettings: BooleanSelect,
	CodeEditor: CodeEditor,
	//ConfigurationJson: ConfigurationJson,
	DataType: DataTypeSelect,
	Datatype: DataTypeSelect,
	Default: BooleanSelect,
	EditVariant: EditVariant, //@TODO Include  
	Expression: ExpressionEditor,
	FieldSelector: FieldSelector,
	Filterable: BooleanSelect,
	LongText: LongText,
	MaxWidth: BaseSettingForm,
	Name: Name,
	NotStoredValueCode: CodeEditor,
	Number: Number,
	Operators: Operators,
	// Query: Query, //@TODO Include
	ReactCode: CodeEditor,
	Select: Select,
	SelectOptions: SelectOptions,	
	ShortText: ShortText,
	Sortable: BooleanSelect,
	Stored: BooleanSelect,
	SortDefault: BooleanSelect,
	StorageDataType: DataTypeSelect,
	StorageValidationCode: CodeEditor,
	AttachmentParentContainer: AttachmentParentContainer,
	Switch: Switch,
	// TableSelector: TableSelector,
	ValueChangeCode: CodeEditor,
	ViewVariant: ViewVariant
};