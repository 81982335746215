import React, { Component } from 'react';
import { Container } from 'flux/utils';

import moment from 'moment';
import Immutable from 'immutable';

// Actions
import DiscussionActions from '../../actions/discussion-actions';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import AuthenticationStore from '../../stores/authentication-store';
import DiscussionStore from '../../stores/discussion-store';

// Utils
import FieldComponentUtils from '../../utils/field-components';
import InterfaceActions from '../../actions/interface-actions';

class DiscussionSetting extends Component {
	/**
	 * Creates instance of DiscussionSetting
	 *
	 * @memberOf DiscussionSetting
	 */
	constructor(props) {
		super(props);
		this._renderCommentSection = this._renderCommentSection.bind(this);
		this._onWipCommentChange = this._onWipCommentChange.bind(this);
		this._onCommentAdd = this._onCommentAdd.bind(this);
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, DiscussionStore, AuthenticationStore];
	}

	/**
	 * @static
	 * @param {any} prevState 
	 * @param {any} props 
	 * @returns {Object}
	 * @memberof DiscussionSetting
	 */
	static calculateState(prevState, props) {
		// Which Discussion are we in?
		let discussionId = AdminSettingsStore.getSettingRecordId();

		let discussionObj = DiscussionStore.get(discussionId) || Immutable.Map();
		// let {componentId, applicationId, name, number, type, priority, lastUpdatedDT, commentCount, pageId, dataRecordId, dataTableSchemaName, installationId, status} = discussionObj
		let comments = DiscussionStore.getDiscussionComments(discussionId);
		let wipComment = DiscussionStore.getWipDiscussionComment(discussionId);
		let username = AuthenticationStore.getUsername();

		return {
			discussionId,
			discussionObj,
			comments,
			wipComment,
			username,
			height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 850
		};
	}

	componentDidMount() {
		if(!this.state.comments && this.state.discussionId) {
			DiscussionActions.pullCommentsFromDatabase(this.state.discussionId);
		}
	}

	componentDidUpdate() {
		if(!this.state.comments && this.state.discussionId) {
			DiscussionActions.pullCommentsFromDatabase(this.state.discussionId);
		}
	}

	/**
	 * Handles change event from a permissions checkbox
	 *
	 * @param {string} key Which Checkbox to change
	 * @param {Object} event What the value should be.
	 */
	_onDiscussionChange(key, event) {
		let newValue = event.target.value;
		let {discussionId, discussionObj} = this.state;

		let value = discussionObj.set(key, newValue);

		DiscussionActions.pushToStore(discussionId, value.toJS());
	}

	_onWipCommentChange(event) {
		if(event.target.value && event.target.value.length > 0) {
			let wipComment = this.state.wipComment ? this.state.wipComment.toJS() : {};
	
			if(!wipComment.username) {
				wipComment.username = this.state.username;
			}
	
			wipComment.comment = event.target.value;
	
			DiscussionActions.pushCommentToStore(this.state.discussionId, wipComment);
		} else {
			DiscussionActions.pushCommentToStore(this.state.discussionId, {});
		}
	}

	_onCommentAdd() {
		let wipComment = this.state.wipComment ? this.state.wipComment.toJS() : {};
		let username = this.state.username;
		wipComment.username = username;
		if(!wipComment.comment || wipComment.comment.length === 0) {
			InterfaceActions.notification({
				message: 'Cannot add an empty comment.',
				title: 'No comment found.',
				level: 'warning',
			});
			return;
		} else {
			DiscussionActions.pushCommentToDatabase(this.state.discussionId, wipComment)
		}
	}

	_renderRadioButton(name, selected, [value, label, icon], index) {
		let iconJSX = null;
		if(icon !== undefined) {
			iconJSX = <span className={"radioIcon fa " + icon}></span>
		}
		return (
			<span key={index} className="radioOption">
				<input className="mr-2" type='radio' value={value} name={name} id={name + index} checked={(value === selected ? 'checked' : '')} />
				<label htmlFor={name + index}>{iconJSX}{label}</label>
			</span>
			);
	}

	_renderCommentSection(isTemporary) {
		let types = {
			'discussion': 'fa-comment',
			'bug': 'fa-bug',
			'change': 'fa-plus-circle'
		};
		let priorities = {
			'critical': ' Critical',
			'major': ' Major',
			'minor': ' Minor',
			'trivial': ' Trivial',
		};
		let commentList =  (<div key="commentList" className="commentList">{
			this.state.comments.size 
				? 	this.state.comments.sort((commentA, commentB) => {
						// Comments which as of yet have no date first
						if (!commentA.get('date') && commentA.get('date' !== 0)) {
							return -1;
						}
						if (!commentB.get('date') && commentB.get('date' !== 0)) {
								return -1;
							}
						if (+commentA.get('date') === +commentB.get('date')) {
									return 0;
								}
						// Most recent first
						return +commentA.get('date') > +commentB.get('date') ? -1 : 1;
						})
					.toList()
					.map((comment, index) => {
						return (
							<span key={index}>
								<div className="commentContainer">
									<div className="commentHead">
										{comment.get('username')}, {moment(comment.get('date'), 'X').format('MM/DD/YYYY hh:mm A Z')}, 
										<span className={"ml-1 fa " + types[this.state.discussionObj.get('type')]}></span>
										{priorities[this.state.discussionObj.get('priority')]}
									</div>
									<div className="commentBody" dangerouslySetInnerHTML={ { __html: FieldComponentUtils.UIUtils.sanitizeHtml(comment.get('comment')) } }></div>
								</div>
							</span>
						);
					}) 
				: null }
		</div>);

		return ([
					commentList
				]);
	}

	/**
	 * @returns - DOM for selected security group
	 * @memberof DiscussionSetting
	 */
	render() {
		let { 
			discussionId,
			discussionObj,
		} = this.state;

		let types = [
			['discussion', 'Discussion', 'fa-comment'],
			['bug', 'Bug', 'fa-bug'],
			['change', 'Change', 'fa-plus-circle']
		];
		let priorities = [
			['critical', 'Critical'],
			['major', 'Major'],
			['minor', 'Minor'],
			['trivial', 'Trivial'],
		];

		let statuses = [
			['open', 'Open'],
			['closed', 'Closed']
		];

		if (!discussionId) {
			return (
				<div className="select-setting">
					<div className="select-setting-text-wrap">
						Select a Discussion or "Add New" on the left.
					</div>
				</div>);
		} else {
			return (
				<div className="discussion-settings">
					<div className="discussion-settings-container mx-3">
						<div className="setting-global">
							<div className="row mb-1">
								{/* <div className="col-2">
									<label htmlFor="id" className="setting-label">ID</label>
								</div> */}
								<div className="col-12">
									<label htmlFor="name" className="setting-label">Name</label>
								</div>
							</div>
							<div className="row justify-content-between">
								{/* <div className="col-2">
									<input className="w-100" disabled value={discussionObj.get('id') || ''} onChange={this._onDiscussionChange.bind(this, 'id')}/>
								</div> */}
								<div className="col-12">
									<input className="w-100 pl-2" value={discussionObj.get('name') || ''} onChange={this._onDiscussionChange.bind(this, 'name')}/>
								</div>
							</div>
							<small className="form-text text-muted mt-2">
								This discussion will also be represented as a task in Platform. Replying to this discussion will automatically
								create a new comment on that Platform task. Please don't share sensitive information.
							</small>
						</div>
						<div className="discussionCollapseDiv">
							<div className="setting-controls d-flex align-items-center">
								<i className="fa pr-2 collapsed align-items-center" aria-hidden="false" data-toggle="collapse" data-target="#updateDiscussion"></i>
								<h4 className="w-100 collapsed align-items-center" data-toggle="collapse" data-target="#updateDiscussion">
									Update Discussion
								</h4>
							</div>
							<div id="updateDiscussion" className="collapse">
								<div key="newComment" className="row newCommentWrapper">
									<div className="col-12" style={{height: '205px'}}>
										<window.citDevFieldTypes.longRichTextEdit 
											onChange={this._onWipCommentChange} value={this.state.wipComment && this.state.wipComment.get('comment') ? this.state.wipComment.get('comment') : ''} 
											renderId="discussionComment"
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-2">
										<h4 htmlFor="priority" className="setting-label">Priority</h4>
									</div>
									<div className="col-3 px-2">
										<h4 htmlFor="type" className="setting-label">Type</h4>
									</div>
									<div className="col-2 p-0">
										<h4 htmlFor="status" className="setting-label">Status</h4>
									</div>
									<button
										tabIndex='0'
										className='discussion-save-button btn btn-primary btn-lg setting-list-button'
										onClick={this._onCommentAdd}
										onKeyPress={(event) => {
											if (event.charCode === 32 || event.charCode === 13) {
												event.preventDefault();
												this._onCommentAdd();
											}
										}}><div className="">Add Discussion</div>
											<span className='sr-only'>Add Discussion</span>
									</button>
								</div>
								<div className="row">
									<div className="col-2">
										<fieldset className="d-flex flex-column" role='radiogroup' onChange={this._onDiscussionChange.bind(this, 'priority' || '')}>
											{priorities.map(this._renderRadioButton.bind(this, 'priorities', discussionObj.get('priority') || ''))}
										</fieldset>
									</div>
									<div className="col-3 pl-2">
										<fieldset className="d-flex flex-column" role='radiogroup' onChange={this._onDiscussionChange.bind(this, 'type')}>
											{types.map(this._renderRadioButton.bind(this, 'types', discussionObj.get('type') || ''))}
										</fieldset>
									</div>
									<div className="col-2 p-0">
										<fieldset className="d-flex flex-column" role='radiogroup' onChange={this._onDiscussionChange.bind(this, 'status')}>
											{statuses.map(this._renderRadioButton.bind(this, 'statuses', discussionObj.get('status') || ''))}
										</fieldset>
									</div>
								</div>
							</div>
						</div>
						{this.state.comments ? this._renderCommentSection((discussionObj.get('temporary'))) : 'Please wait, comments loading...'}
					</div>
				</div>
			);
		} 
	}
}

const container = Container.create(DiscussionSetting, {withProps:true});
export default container;