/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';

import { 
	AdminSettingsActions, 
	PageActions,
	InterfaceActions,
} from '../../../actions';

import { 
	AdminSettingsStore, 
	FieldStore,
	PageStore
} from '../../../stores';

import { 
	FieldUtils,
	PageUtils,
	UIUtils 
} from '../../../utils';

class PageAddSettingsChooser extends Component {
	/**
	 * Creates instance of PageAddSettingsChooser
	 */
	constructor(props) {
		super(props);
		this._getSettingsList = this._getSettingsList.bind(this);
		this._onDeleteHandler = this._onDeleteHandler.bind(this)
		this._onSaveHandler = this._onSaveHandler.bind(this)
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, PageStore];
	}

	/**
	 * Calculate the current state of this component
	 * @static
	 * @param {Object} prevState 
	 * @param {Object} prevState 
	 * @returns {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId = AdminSettingsStore.getRecordId();
		let tableSchemaName = 'field';
		let pageObj = PageStore.get(recordId) || {};
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let componentSettings = [];
		let pageSettings = PageUtils.getAddPageSettings();

		let hasMissingRequiredSettings = false;
			
		pageSettings.forEach(pageSetting => {
			let settingId = pageSetting.recordId;
			let settingObj = FieldStore.get(settingId) || {};
			let settingSchemaName = settingObj.fieldSchemaName;

			// Empty/No Value values.
			let valueObj = {};
			if (pageObj[settingSchemaName]) {
				valueObj.value = pageObj[settingSchemaName];
			}

			// Check to see if this setting is required, and has no value
			if(pageSetting.required && (!valueObj.value || valueObj.length === 0)) {
				hasMissingRequiredSettings = true;
			}

			componentSettings.push({
				// Basics
				fieldId: settingId,
				fieldObj: settingObj,

				// Label, Value and Sorting..
				label: settingObj.fieldLabel,
				valueObj: valueObj,
				sortOrder: pageSetting.sortOrder,

				// Used for styling
				settingSelected: (selectedSetting === settingSchemaName),
				required: pageSetting.required
			});
		});

		// Sort the results by setting name
		componentSettings.sort(function (a, b) {
			if (a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			return 1;
		});

		return {
			componentSettings: componentSettings,
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			settingsHidden: AdminSettingsStore.getSettingsListHidden(),
			hasMissingRequiredSettings: hasMissingRequiredSettings
		}
	}

	/**
	 * Render the component
	 * @returns JSX
	 */
	render() {
		let { componentSettings, hasMissingRequiredSettings } = this.state;

		if (!componentSettings.length) {
			return (
				<div key="settings" className='settings-list-wrapper'>
					<div style={{ marginTop: '8px' }} className='no-settings-found'>No Settings Found</div>
				</div>
			);
		}

		let settingsList = this._getSettingsList(componentSettings); // component settings

		/* The ids for these buttons are set in a specific way, so that Ctrl-S and Ctrl-R (UIUtils.onkeyDown method) can find these buttons */
		let buttons = [
			<button id={'page-addSave'} key="submit" className="btn btn-primary btn-lg setting-list-button mr-0 d-flex justify-content-center w-50 mr-1" form="appearance-form" aria-label="Save" title="Save (Ctrl-S)" onClick={this._onSaveHandler}>Save</button>,
			<button id={'page-addReset'} key="delete" className="btn btn-danger btn-lg setting-list-button mr-0 d-flex justify-content-center w-50 ml-1" form="appearance-form" aria-label="Delete" onClick={this._onDeleteHandler}>Delete</button>
		];

		let requiredMarker = null;
		if(hasMissingRequiredSettings) {
			requiredMarker = <div className="required-marker mx-4 d-flex"><i className="fa fa-circle d-flex align-items-center" aria-hidden="true"></i> <h4 className="ml-2">Required</h4></div>;
		}

		let iconPath = FieldUtils.getFieldTypeIconByFieldTypeId('page');

		return [
			<div key="settings" className='settings-list-wrapper'>
				<ul key="list" className="nav flex-column">
					<li className='settings-list-header'>
						<div className="d-flex">
							<img height="22" width="22" className="mr-2" src={iconPath} alt="" style={{ marginTop: '0.4rem'}} />
							<h3>Page Settings</h3>
						</div>
					</li>
					{settingsList && settingsList.length
						? settingsList
						: <div style={{ marginTop: 0 }} className='no-settings-found'>No Settings Found</div>
					}
					{ requiredMarker }
				</ul>
			</div>,
			<div key="buttons" className="btn-wrapper appearance-btn-wrapper mx-2">
				{buttons}
			</div>
		];
	}

	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @returns { array } array of <li> settings elements
	 */
	_getSettingsList(settingsArr) {

		let { fieldType } = this.state;

		// iterate over the settings and return an array of list items
		return settingsArr.map(setting => {
			let labelClassNames = 'setting-label';
			if (setting.settingSelected) {
				labelClassNames += ' selected';
			}

			let componentName = '';
			if(setting && setting.fieldObj && setting.fieldObj.viewVariant) {
				componentName = setting.fieldObj.viewVariant;
			} else {
				componentName = FieldStore.getDefaultVariantComponentName(
					setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
			}

			let componentProps = setting.fieldObj;
			componentProps.value = setting.valueObj.value;
			componentProps.fieldType = fieldType;

			let valueDisplay = <h5 className={`settings-list-value ${componentName}`}>
					{(componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null)}
				</h5>;

			let requiredMarker = null;
			if(setting.required && (!setting.valueObj.value || setting.valueObj.value.length === 0)) {
				requiredMarker = <div style={{ left: '-14px', top: '2px' }} className="required-marker position-absolute"><i className="fa fa-circle fa-1" aria-hidden="true"></i></div>;
			}

			return (<li key={setting.fieldId} className={"nav-item d-flex flex-column justify-content-center " + (setting.settingSelected ? 'setting-selected' : '')}>
				<div className="nav-link" onClick={this._onSettingClick.bind(this, setting.fieldId)}>
					{/* Temporarily removed the following div */}
					{/* <div className={"setting-icon " + iconClassNames} /> */}
					{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
					{/* Add this back in when the setting-icon is used again */}
					<div className="d-flex setting-text-container">
						<div className="w-50 setting-label-container">
							<div className={labelClassNames}>{requiredMarker}<h4>{setting.label}</h4></div>
							<div className="setting-pattern-list">{setting.mostRecentPattern}</div>
						</div>
						<div className="w-50 d-flex justify-content-end setting-value-container">
							<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
								{valueDisplay}
							</div>
							{setting.valueObj.source ?
								<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}>
									{setting.valueObj.source}
								</div>
								: null
							}
						</div>
					</div>
				</div>
			</li>);
		});
	}

	/**
	 * _onDeleteHandler - Deletes the object from the store and the database.
	 *
	 * @param  {object} event
	 */
	_onDeleteHandler(event) {
		event.preventDefault();
		let { recordId } = this.state;
		let confirmRemove = confirm('Are you sure you want to delete this Page?');
		if(confirmRemove) {
			// Display notification to user
			let id = InterfaceActions.stickyNotification({ 'level': 'warning', 'message': 'Deleting Page...' });
			
			// Push to database
			InterfaceActions.clearStickyNotification(id);
			UIUtils.closeSettingsPanel();
			PageActions.deleteFromStore(recordId);
		}
	}

	/**
	 * _onSaveHandler - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	_onSaveHandler(event) {
		let { recordId } = this.state;
		let pageObj = PageStore.get(recordId);
		let valid = true;
		let targetSettingName = '';
		let targetSettingId = '';

		if(!valid) {
			AdminSettingsActions.onSettingChange(targetSettingName, targetSettingId);
		} else {
			delete pageObj.new;

			// Trim all of the values...
			Object.keys(pageObj).forEach(settingName => {
				pageObj[settingName] = pageObj[settingName].trim();
			})

			let id = InterfaceActions.stickyNotification({ 'level': 'success', 'message': 'Updating Page...' });
			// Push to database
			PageActions.pushToDatabasePromise(pageObj).then(() => {
				// Push the fact we're no longer new...
				PageActions.pullFromDatabase(recordId, true);

				// Clear the Sticky about saving.
				InterfaceActions.clearStickyNotification(id);
				UIUtils.openSettingsPanel('appearance',
					recordId, 'page', undefined, undefined);

				// Select FieldLabel
				AdminSettingsActions.onSettingChange('name', 'e547119d-0f76-4ab1-844e-9f44f740d3e8');

				// Unhide the settings, always
				AdminSettingsActions.onSettingsListHideChange(false);
			}).catch(error => {
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to update Page.' });
				console.error('Unable to save Page:', error, pageObj);
			})
		}
	}


	/**
	 * Click on a Setting and update the admin settings store.
	 * 
	 * @param {string} settingFieldId
	 * @param {Object} event 
	 */
	_onSettingClick(settingFieldId, event) {
		event.preventDefault();

		let settingFieldObj = FieldStore.get(settingFieldId);
		let settingSchemaName = settingFieldObj.fieldSchemaName;

		// Expand the Settings Panel
		AdminSettingsActions.onSettingsListHideChange(false);
		if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
			AdminSettingsActions.onSettingChange('', '');
		} else {
			AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
		}
	}
}
const container = Container.create(PageAddSettingsChooser);
export default container;