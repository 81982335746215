import React, { Component } from 'react';
import App from '../app';
import PageContents from '../components/page-contents.react';

import ProcessingActionContainer from '../components/notifications/processing-action-container';

/**
 *
 */
class Page extends Component {
	/**
	 * render - renders wrapper
	 *
	   * @returns JSX
	 */
	render() {
		return <App>
			<ProcessingActionContainer />
			<PageContents {...this.props} />
		</App>;
	}
}
export default Page;
