import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import {FieldTypeConstants} from '../constants/field-type-constants';
import fetchMetadata from '../utils/fetch-utils';

let _instance = null;

/**
 * Actions for the core store that contains field type records
 *
 * @class FieldTypeActions
 */
class FieldTypeActions {
	/**
	 * Singleton instance of FieldTypeActions
	 * @param {Object} options
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setFieldtypeConfigPath = this.setFieldtypeConfigPath.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.fieldtypeConfigPath) {
			this.setFieldtypeConfigPath(options.fieldtypeConfigPath);
		}
	}

	/**
	 * Set the Field Type Config Path
	 * 
	 * @param {string} fieldtypeConfigPath 
	 */
	setFieldtypeConfigPath(fieldtypeConfigPath) {
		this._fieldtypeConfigPath = fieldtypeConfigPath;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw(new Error('FieldTypeActions not properly initialized with a dispatcher'));
		}
		if (!this._fieldtypeConfigPath) {
			throw(new Error('FieldTypeActions not properly initialized with a fieldtypeConfigPath'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: FieldTypeConstants.FIELDTYPE_PULL_ERROR,
			error: error.message
		}));
	}

	/**
	 * Update store with all of the field type data.
	 */
	pullFromDatabaseAll() {
		this._checkInit();

		fetchMetadata(this._fieldtypeConfigPath, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8'
			}
		})
			.then(function(fieldTypeObject){
				this._dispatcher.dispatch(Immutable.fromJS({
					type: FieldTypeConstants.FIELD_TYPE_PULL_FROM_DATABASE_ALL,
					fieldTypeObject: fieldTypeObject
				}));
			}.bind(this))
			.catch(function(error){ 
				console.error(error); 
				this._onError(error) ;
			}.bind(this));
	}
}

const instance = new FieldTypeActions({dispatcher: AppDispatcher});
export default instance;	