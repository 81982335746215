import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import { PatternConstants } from '../constants/pattern-constants';
import fetchMetadata from '../utils/fetch-utils';

let _instance = null;

/**
 * Actions for the core store that contains pattern records
 *
 * @class PatternActions
 */
class PatternActions {
	/**
	 * Singleton instance of PatternActions
	 * @param {Object} options
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setPatternConfigPath = this.setPatternConfigPath.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.patternConfigPath) {
			this.setPatternConfigPath(options.patternConfigPath);
		}
	}

	/**
	 * Set the Pattern Config Path
	 * 
	 * @param {string} patternConfigPath 
	 */
	setPatternConfigPath(patternConfigPath) {
		this._patternConfigPath = patternConfigPath;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw (new Error('PatternActions not properly initialized with a dispatcher'));
		}
		if (!this._patternConfigPath) {
			throw (new Error('PatternActions not properly initialized with a patternConfigPath'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: PatternConstants.PATTERN_PULL_ERROR,
			error: error.message
		}));
	}

	/**
	 * Update store with all of the pattern data.
	 */
	pullFromDatabaseAll() {
		this._checkInit();

		fetchMetadata(this._patternConfigPath, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; charset=UTF-8'
			}
		})
			.then((patternObject) => {
				this._dispatcher.dispatch(Immutable.fromJS({
					type: PatternConstants.PATTERN_PULL_FROM_DATABASE_ALL,
					patternObject: patternObject
				}));
			})
			.catch(function (error) { 
				console.error(error); 
				this._onError(error);
			}.bind(this));
	}
}


const instance = new PatternActions({dispatcher: AppDispatcher});
export default instance;