/* global citDev */
import React, {Component} from 'react';

/**
 * Container to MaintenanceUINotification
 *
 * @class MaintenanceUINotification
 * @extends {Component}
 */
class MaintenanceUINotification extends Component {
	/**
	 *Creates an instance of MaintenanceUINotification.
	 * @memberof MaintenanceUINotification
	 */
	constructor() {
		super();
		this.timeout = null;
	}

	/**
	 * Reload window
	 *
	 * @param {*} e
	 * @memberof MaintenanceUINotification
	 */
	reloadWindow(e) {
		e.preventDefault();
		window.location.reload();
	}

	/**
	 * Renders the reload notification ui
	 *
	 * @returns
	 * @memberof MaintenanceUINotification
	 */
	render() {
		let {message} = this.props;
		let reloadTime = 15;
		let timeRemaining = this.state ? this.state.timeRemaining : false;
		if (!timeRemaining && timeRemaining !== 0) {
			timeRemaining = reloadTime;
		}

		let reloadControl = null;
		let notificationClass = 'notification ';
		if (timeRemaining > 0) {
			this.timeout = setTimeout(() => {
				this.setState({'timeRemaining': timeRemaining - 1});
			}, 1000);
			notificationClass += ' notification-warning'
			reloadControl = <div>
				<div>{message}</div>
				<span>The application will reload in {timeRemaining} seconds.</span><br />
				<button className="invert" onClick={this.reloadWindow}>Reload</button>
			</div>;
		} else if (reloadTime && timeRemaining <= 0) {
			notificationClass += ' notification-warning'
			reloadControl = <span>Reloading...</span>
			setTimeout(() => {
				window.location.reload();
			}, 0);
		} else {
			notificationClass += ' notification-info'
			reloadControl = <button className="invert" onClick={this.reloadWindow}>Reload</button>
		}

		return (
			<div id='top-notification-bar' style={{width: '500px'}} className="notification-container notification-container-center d-flex justify-content-center notification-container-empty">
				<div aria-live='polite' aria-relevant='all' role='status' className={notificationClass}>
					<h4  className="title text-left">Maintenance Underway.</h4>
					<div className="message text-left">
						{reloadControl}
					</div>
				</div>
			</div>
		);
	}
}

export default MaintenanceUINotification;