import React, {Component} from 'react';
// import {Container} from 'flux/utils';

// import InterfaceActions from '../../../actions/interface-actions';
import AdminSettingsActions from '../../../actions/admin-settings-actions';
// import ComplianceActions from '../../../actions/compliance-actions';


/**
 * Container for PageDialog
 *
 * @class ConfirmModalContainer
 * @extends {Component}
 */
class ConfirmModalContainer extends Component {
  /**
   * Creates instance of ConfirmModalContainer
   *
   * @memberOf ConfirmModalContainer
   */
  constructor(props) {
	super(props);
    this._close = this._close.bind(this);
	this._triggerResize = this._triggerResize.bind(this);
	this._confirm = this._confirm.bind(this);
	this._toggleCheck = this._toggleCheck.bind(this);
  }

  /**
   * Handles event when component will update
   */
  componentWillUpdate(nextProps, nextState) {
    if (!nextState.complianceId && this.state.complianceId) {
      setTimeout(AdminSettingsActions.closeModal.bind(null, this.state.complianceId), 0);
    }
  }

  /**
   * Handles event when component will unmount
   */
  componentWillUnmount() {
    setTimeout(this._close, 0);
  }
  /**
   * Render methos for ConfirmModalContainer
   *
   * @memberOf ConfirmModalContainer
   */
	render() {
		let dialogClassName = 'modal-dialog modal-lg';
		return (
			<div className={dialogClassName + " site-map-modal compliance-map-modal compliance-toggle-modal"}>
				<div className="modal-content container">
					<div className="modal-body mh-100 d-flex flex-column cd-bg-3">
						<div className="disable-modal-header">
							<div className="column">
								<div className="form-text">You are turning off the Compliance Assistance Tool for {this.props.complianceName}. If disabled, all of its previous settings will be removed. This cannot be undone.</div>
							</div>
						</div>
						<div style={{ flex: 1 }} className="d-flex align-items-center disable-confirmation-outer-wrapper">
							<div className="column confirmation-inner-wrapper disable-confirmation-inner-wrapper">
								<div className="fa fa-exclamation-triangle"></div>
								<div className="description">
									Are you sure you want to disable the Compliance Assistance Tool for {this.props.complianceName}?
								</div>
							</div>
						</div>
						<div>
							<div className="d-flex justify-content-end">
								<button style={{ width: '95px' }} type='button' className='btn btn-secondary mr-2' onClick={this._close}>Cancel</button>
								<button style={{ width: '95px' }} type='button' className='btn btn-primary' onClick={this._confirm}>Continue</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	_toggleCheck() {
		this.setState({checked: !this.state.checked});
	}

	_confirm() {
		this._close();
		let {onConfirm} = this.props;
		if(onConfirm) {
			onConfirm();
		}
	}

  /**
   * private Handles compliance settings close
   *
   * @params {Object} event
   *
   * @memberOf ConfirmModalContainer
   */
  _close() {
    AdminSettingsActions.closeModal(this.props.modalId);
  }

  /**
   * private Handles field menu click
   *
   * @params {Object} event
   *
   * @memberOf ConfirmModalContainer
   */
  _triggerResize(event) {
    //hack to update react-data-grid metrics
    window.dispatchEvent(new Event('resize'));
  }
}

export default ConfirmModalContainer;
