import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import { LogicFunctionConstants } from '../constants/logic-function-constants';
import MetadataBuilder from '@dmclain-citizendeveloper/citdev-module-metadata-builder';
import fetchMetadata from '../utils/fetch-utils';

let _instance = null;

/**
 * Actions for the core store that contains logicFunction records for the dev environment where we talk to the MDGW
 *
 * @class LogicFunctionActionsDev
 */
class LogicFunctionActionsDev {
	/**
	 * Singleton instance of LogicFunctionActionsDev
	 * @param {Object} options
	 * @param {string} options.installationId The installationId being used
	 * @param {string} options.installationVersion The installation version being used
	 * @param {Object} options.dispatcher The dispatcher for the store
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if (options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Set the Installation Id and (optionally the endPoint) and setup the Metadata builder
	 * 
	 * @param {string} installationId The ID of the installation being used
	 * @param {string} signedMdKey The signed key used to connect to the MDGW
	 * @param {string} endPoint The MDGW endpoint
	 */
	setInstallationId(installationId, signedMdKey, endPoint) {
		this._installationId = installationId;
		this._metadataBuilder = new MetadataBuilder(installationId, signedMdKey, endPoint);
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw (new Error('LogicFunctionActionsDev not properly initialized with a dispatcher'));
		}
		if (!this._metadataBuilder) {
			throw (new Error('LogicFunctionActionsDev not properly initialized with an installationId'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_PULL_ERROR,
			error: error.message
		}));
	}

	receiveBroadcast(records) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_RECEIVE_BROADCAST,
			records: records
		}));
	}

	/**
	 * Deletes a logicFunction from the database AND the store
	 * 
	 * @param {string} recordId LogicFunction Record ID to delete from the database and the store
	 */
	deleteFromDatabase(recordId) {

		// We need the LogicFunction Store
		let LogicFunctionStore = undefined,
			logicFunctionObj = undefined;
		try {
			LogicFunctionStore = window.LogicFunctionStore;
			logicFunctionObj = LogicFunctionStore.get(recordId);
		} catch (error) {
			console.error('Unable to attach to the LogicFunctionStore from the window in LogicFunctionActionsActions.deleteFromDatabase.');
		}

		// Local Meta Data Builder
		this._metadataBuilder
			// Get the field(s)
			.deleteRecords([logicFunctionObj], 'logicfunctions')
			.then(function () {
				// Pass the fields to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: LogicFunctionConstants.LOGIC_FUNCTION_DELETE_FROM_STORE,
					recordId: recordId
				}));
			}.bind(this))
			.catch(function (error) {
				console.error(error);
				this._onError(error);
			}.bind(this));
	}

	/**
	 * Deletes a logicFunction from the database AND the store in a promisified fashion
	 * 
	 * @param {string} recordId LogicFunction Record ID to delete from the database and the store
	 */
	deleteFromDatabasePromise(recordId) {

		return new Promise((resolve, reject) => {
			try {
				// We need the LogicFunction Store
				let LogicFunctionStore = undefined,
					logicFunctionObj = undefined;
				try {
					LogicFunctionStore = window.LogicFunctionStore;
					logicFunctionObj = LogicFunctionStore.get(recordId);
				} catch (error) {
					console.error('Unable to attach to the LogicFunctionStore from the window in LogicFunctionActions.deleteFromDatabase.');
				}

				// Local Meta Data Builder
				this._metadataBuilder
					// Get the field(s)
					.deleteRecords([logicFunctionObj], 'logicfunctions')
					.then(function () {
						// Pass the fields to the store (call reduce with the object below)
						this._dispatcher.dispatch(Immutable.fromJS({
							type: LogicFunctionConstants.LOGIC_FUNCTION_DELETE_FROM_STORE,
							recordId: recordId
						}));
						return resolve();
					}.bind(this))
					.catch(function (error) {
						console.error(error);
						this._onError(error);
						return reject(error);
					}.bind(this));
			} catch (err) {
				return reject(err);
			}
		});
	}

	/**
	 * Deletes a logicFunction from the store
	 * 
	 * @param {string} recordId LogicFunction Record ID to delete from the store.
	 */
	deleteFromStore(recordId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_DELETE_FROM_STORE,
			recordId: recordId
		}));
	}

	/**
	 * Update Metadata Gateway with this record's data.
	 * @param {object} logicFunctionObject Record to push from the store to the database
	 */
	pushToDatabase(logicFunctionObject) {
		this._checkInit();

		// make *sure* we have a standard javascript object...
		if (logicFunctionObject.toJS) {
			logicFunctionObject = logicFunctionObject.toJS();
		}

		// Local Meta Data Builder
		this._metadataBuilder
			// Get all the logicFunctions
			.updateRecords([logicFunctionObject], 'logicfunctions')
			.then(function () {
				// Pass the logicFunctions to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: LogicFunctionConstants.LOGIC_FUNCTION_PUSH_TO_DATABASE,
					logicFunctionObject: logicFunctionObject
				}));
			}.bind(this))
			.catch(function (error) {
				console.error(error);
				this._onError(error);
			}.bind(this));
	}

	/**
	 * Update Metadata Gateway with this record's data.
	 * @param {Object} logicFunctionObject Record to push from the store to the database
	 * @returns {Promise}
	 */
	pushToDatabasePromise(logicFunctionObject) {
		return new Promise((resolve, reject) => {

			try {
				this._checkInit();
			} catch (error) {
				return reject(error);
			}

			// make *sure* we have a standard javascript object...
			if (logicFunctionObject.toJS) {
				logicFunctionObject = logicFunctionObject.toJS();
			}

			// Local Meta Data Builder
			this._metadataBuilder
				// Get all the logicFunctions
				.updateRecords([logicFunctionObject], 'logicfunctions')
				.then(function () {
					// Pass the logicFunctions to the store (call reduce with the object below)
					this._dispatcher.dispatch(Immutable.fromJS({
						type: LogicFunctionConstants.LOGIC_FUNCTION_PUSH_TO_DATABASE,
						logicFunctionObject: logicFunctionObject
					}));
					return resolve(logicFunctionObject);
				}.bind(this))
				.catch(function (error) {
					console.error(error);
					this._onError(error);
					return reject(error);
				}.bind(this));
		});
	}

	/**
	 * Update store record properties
	 * @param {string} recordId Record to update
	 * @param {Object} recordProperties Properties to update
	 */
	pushToStore(recordId, recordProperties) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_PUSH_TO_STORE,
			recordId: recordId,
			recordProperties: recordProperties
		}));
	}

	/**
	 * Update store with a record's data.
	 * @param {string} recordId Record to pull from the database to the database
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, overwriteStore) {
		this._checkInit();

		if (overwriteStore === undefined) {
			overwriteStore = true;
		}

		// Local Meta Data Builder
		this._metadataBuilder
			// Get all the logicFunctions
			.searchRecords('logicfunctions', { 'recordId': recordId })
			.then(function (logicFunctionArray) {
				// Pass the logicFunctions to the store (call reduce with the object below)
				if (Array.isArray(logicFunctionArray)) {
					this._dispatcher.dispatch(Immutable.fromJS({
						type: LogicFunctionConstants.LOGIC_FUNCTION_PULL_FROM_DATABASE,
						logicFunctionArray: logicFunctionArray,
						overwriteStore: overwriteStore
					}));
				}
			}.bind(this))
			.catch(function (error) {
				console.error(error);
				this._onError(error);
			}.bind(this));
	}

	/**
	 * Update store with all of the records' data.
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabaseAll(overwriteStore) {
		this._checkInit();

		if (overwriteStore === undefined) {
			overwriteStore = true;
		}
		// Local Meta Data Builder
		this._metadataBuilder
			// Get all the logicFunctions
			.browseRecords('logicfunctions')
			.then(function (logicFunctionArray) {
				// Pass the logicFunctions to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: LogicFunctionConstants.LOGIC_FUNCTION_PULL_FROM_DATABASE_ALL,
					logicFunctionArray: logicFunctionArray,
					overwriteStore: overwriteStore
				}));
			}.bind(this))
			.catch(function (error) {
				console.error(error);
				this._onError(error);
			}.bind(this));
	}

	/**
	 * 
	 * @param {string} workspaceId The ID of the workspace being expanded
	 * @param {string} functionId The ID of the function being expanded
	 * @param {string} blockId The ID of the block being expanded
	 */
	expandFunctionBlock(workspaceId, functionId, blockId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_EXPAND_BLOCK,
			workspaceId: workspaceId,
			functionId: functionId,
			blockId: blockId
		}));
	}

	/**
	 * 
	 * @param {string} workspaceId The ID of the workspace being expanded
	 * @param {string} functionId The ID of the function being expanded
	 * @param {string} blockId The ID of the block being expanded
	 */
	collapseFunctionBlock(workspaceId, functionId, blockId, functionProperties) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_COLLAPSE_BLOCK,
			workspaceId: workspaceId,
			functionId: functionId,
			blockId: blockId,
			functionProperties: functionProperties
		}));
	}

	/**
	 * 
	 * @param {*} workspaceId 
	 * @param {*} oldFunctionId
	 * @param {*} newFunctionId 
	 * @param {*} blockId 
	 */
	replaceFunctionInBlock(workspaceId, oldFunctionId, newFunctionId, blockId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_REPLACE_BLOCK,
			workspaceId: workspaceId,
			oldFunctionId: oldFunctionId,
			newFunctionId: newFunctionId,
			blockId: blockId
		}));
	}

	/**
	 * 
	 * @param {string} workspaceId The ID of the workspace from which the block is being disposed
	 * @param {string} functionId The ID of the function for which the block is being disposed
	 * @param {string} blockId The ID of the block for which the block is being disposed
	 */
	disposeFunctionBlock(workspaceId, functionId, blockId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_DISPOSE_BLOCK,
			workspaceId: workspaceId,
			functionId: functionId,
			blockId: blockId
		}));
	}
}

/**
 * Actions for the core store that contains logicFunction records for the test/prod environments where we talk to the CDN JSON
 *
 * @class LogicFunctionActionsProd
 */
class LogicFunctionActionsProd {
	/**
	 * Singleton instance of LogicFunctionActionsProd
	 * @param {Object} options
	 * @param {string} options.installationId The installationId being used
	 * @param {string} options.installationVersion The installation version being used
	 * @param {Object} options.dispatcher The dispatcher for the store
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if (options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Set the Installation Id
	 * 
	 * @param {string} installationId 
	 */
	setInstallationId(installationId) {
		this._installationId = installationId;
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw (new Error('LogicFunctionActions not properly initialized with a dispatcher'));
		}
		if (!this._installationId) {
			throw (new Error('LogicFunctionActions not properly initialized with an installationId'));
		}
		if (!this._installationVersion) {
			throw (new Error('LogicFunctionActions not properly initialized with an installationVersion'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_PULL_ERROR,
			error: error.message
		}));
	}

	/**
	 * Deletes a logicFunction from the database AND the store
	 */
	deleteFromDatabase() {
		console.warn('deleteFromDatabase not implemented.');
	}

	/**
	 * Deletes a logicFunction from the store
	 * 
	 * @param {string} recordId LogicFunction Record ID to delete from the store.
	 */
	deleteFromStore(recordId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_DELETE_FROM_STORE,
			recordId: recordId
		}));
	}

	/**
	 * Update Metadata Gateway with this record's data.
	 */
	pushToDatabase() {
		console.warn('pushToDatabase not implemented.');
	}

	/**
	 * Update store record properties
	 * @param {string} recordId Record to update
	 * @param {Object} recordProperties Properties to update
	 */
	pushToStore(recordId, recordProperties) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_PUSH_TO_STORE,
			recordId: recordId,
			recordProperties: recordProperties
		}));
	}

	/**
	 * Update store with a record's data.
	 * @param {string} recordId Record to pull from the database to the database
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, overwriteStore) {
		this._checkInit();

		if (overwriteStore === undefined) {
			overwriteStore = true;
		}

		let installationId = this._installationId,
			installationVersion = this._installationVersion,
			metadataPath = 'https://cdn3.citizendeveloper.com/installations/' +
				installationId + '/' + installationVersion + '/logicfunctions/' + recordId + '.json';
		fetchMetadata(metadataPath)
			.then((jsonResponse) => {
				//fetchMetadata returns an empty array when it can't find the result so we need to convert
				//it to an empty object in those rare cases
				jsonResponse = Array.isArray(jsonResponse) ? {} : jsonResponse;
				// Pass the logicFunctions to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: LogicFunctionConstants.LOGIC_FUNCTION_PULL_FROM_DATABASE,
					logicFunctionArray: [jsonResponse],
					overwriteStore: overwriteStore
				}));
			})
			.catch(function (error) {
				console.error('Unable to retrieve metadata from ', metadataPath, '. Error was: ', error);
			});
	}

	/**
	 * Update store with all of the records' data.
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabaseAll(overwriteStore) {
		this._checkInit();

		if (overwriteStore === undefined) {
			overwriteStore = true;
		}

		let installationId = this._installationId,
			installationVersion = this._installationVersion,
			metadataPath = 'https://cdn3.citizendeveloper.com/installations/' +
				installationId + '/' + installationVersion + '/logicfunctions/index.json';
		fetchMetadata(metadataPath).then((jsonResponse) => {
			// Pass the logicFunctions to the store (call reduce with the object below)
			this._dispatcher.dispatch(Immutable.fromJS({
				type: LogicFunctionConstants.LOGIC_FUNCTION_PULL_FROM_DATABASE_ALL,
				logicFunctionArray: jsonResponse,
				overwriteStore: overwriteStore
			}));
		}).catch(function (error) {
			console.error('Unable to retrieve metadata from ', metadataPath, '. Error was: ', error);
		});
	}

	/**
	 * 
	 * @param {string} workspaceId The ID of the workspace being expanded
	 * @param {string} functionId The ID of the function being expanded
	 * @param {string} blockId The ID of the block being expanded
	 */
	expandFunctionBlock(workspaceId, functionId, blockId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_EXPAND_BLOCK,
			workspaceId: workspaceId,
			functionId: functionId,
			blockId: blockId
		}));
	}

	/**
	 * 
	 * @param {string} workspaceId The ID of the workspace being expanded
	 * @param {string} functionId The ID of the function being expanded
	 * @param {string} blockId The ID of the block being expanded
	 */
	collapseFunctionBlock(workspaceId, functionId, blockId, functionProperties) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_COLLAPSE_BLOCK,
			workspaceId: workspaceId,
			functionId: functionId,
			blockId: blockId,
			functionProperties: functionProperties
		}));
	}

	/**
	 * 
	 * @param {*} workspaceId 
	 * @param {*} oldFunctionId
	 * @param {*} newFunctionId 
	 * @param {*} blockId 
	 */
	replaceFunctionInBlock(workspaceId, oldFunctionId, newFunctionId, blockId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_REPLACE_BLOCK,
			workspaceId: workspaceId,
			oldFunctionId: oldFunctionId,
			newFunctionId: newFunctionId,
			blockId: blockId
		}));
	}

	/**
	 * 
	 * @param {string} workspaceId The ID of the workspace from which the block is being disposed
	 * @param {string} functionId The ID of the function for which the block is being disposed
	 * @param {string} blockId The ID of the block for which the block is being disposed
	 */
	disposeFunctionBlock(workspaceId, functionId, blockId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: LogicFunctionConstants.LOGIC_FUNCTION_DISPOSE_BLOCK,
			workspaceId: workspaceId,
			functionId: functionId,
			blockId: blockId
		}));
	}
}

let toExport;
if(process.env.CITDEV_ENV === 'development'){
	try {
		if(window.location.href.includes('settings.html') && typeof window.opener.location.href === 'string') {
			toExport = window.opener.TableActions;
		} else {
			const instance = new LogicFunctionActionsDev({dispatcher: AppDispatcher});
			toExport = instance;
		}
	} catch(error) { 
		const instance = new LogicFunctionActionsDev({dispatcher: AppDispatcher});
		toExport = instance;
	}
} else {
	toExport = new LogicFunctionActionsProd({dispatcher: AppDispatcher});
}

export default toExport;