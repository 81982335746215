/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container } from 'flux/utils';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import FieldSettingsStore from '../../../stores/field-settings-store';
import FieldStore from '../../../stores/field-store';
import FieldTypeStore from '../../../stores/field-type-store';
import PageStore from '../../../stores/page-store';
import PatternStore from '../../../stores/pattern-store';

// Utils
import ObjectUtils from '../../../utils/object-utils';
import UIUtils from '../../../utils/ui-utils';

// Components
import PopoverHeader from './header';

// Disappointments
import $ from 'jquery';

/**
 * Container to field type wrapper
 *
 * @class FieldTypeWrapperContainer
 * @extends {Component}
 */
class VisibilityPinPopover extends Component {
	constructor(props) {
		super(props);
		this.link = null;
		this.onSettingClick = this.onSettingClick.bind(this);
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf VisibilityPinPopover
	 */
	static getStores() {
		return [AdminSettingsStore, FieldSettingsStore, FieldStore, FieldTypeStore, PageStore, PatternStore];
	}
	/**
	 * 
	 * 
	 * @param {any} settingSchemaName 
	 * @param {any} event 
	 * @memberof VisibilityPinPopover
	 */
	onSettingClick(settingSchemaName, event) {
		event.preventDefault();
		// Open Settings tab under right-panel
		AdminSettingsActions.onSettingsTabChangeMain('settings');
		// looks for Add or Edit mode for the visibility settings options
		AdminSettingsActions.onVisibilityControlsAddAndEdit(this.props.controlsAddAndEdit);

		UIUtils.openSettingsPanel('visibilityV1',
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 'settings',
			this.props.subSettingSchemaName, this.props.subSettingIndex, this.props.renderId
		);
		// hide the popover when a setting has been selected
		$('.popover').popover('hide');
		AdminSettingsActions.onSettingChange(settingSchemaName);
	}

	/**
	 * Returns the current State of the FieldType Settings
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf VisibilityPinPopover
	 */
	static calculateState(prevState, props) {
		let { recordId, parentRecordId, subSettingIndex, subSettingSchemaName } = props;
		let allUsersSource = null;

		// Determine if we're on a sub-setting, and if so then show a suffix.
		let allUsersContent = null;
		let fieldSettings = FieldSettingsStore.getSettingsWithSource(recordId, parentRecordId);
		if(fieldSettings[subSettingSchemaName]) {
			let subSettingConfigs = ObjectUtils.getObjFromJSON(fieldSettings[subSettingSchemaName].value);
			if(subSettingConfigs && subSettingConfigs[subSettingIndex]) {
				let subSettingConfig = subSettingConfigs[subSettingIndex];

				// Now figure out our setting value (rules count)
				if(subSettingConfig.visibilityRules && subSettingConfig.visibilityRules.length) {
					allUsersSource = <div className="setting-scope badge badge-pill badge-dark align-self-center">G</div>;
					let visibilityRulesObj = ObjectUtils.getObjFromJSON(subSettingConfig.visibilityRules);
					let allUsersVis = visibilityRulesObj['allUsers'];
					allUsersContent = allUsersVis.length + ' Rules';
		
					// take care of the plural.
					if(allUsersVis.length === 1) {
						allUsersContent = '1 Rule';
					}
				}	
			}
		// Not a subsetting?  Thats cool.. figure out the setting value (rules count)
		} else if(fieldSettings.visibilityRules && 
			(fieldSettings.visibilityRules.value.length || typeof fieldSettings.visibilityRules.value === 'object')) {
			if(fieldSettings.visibilityRules.source === 'global') {
				allUsersSource = <div className="setting-scope badge badge-pill badge-dark align-self-center">G</div>;
			} else {
				allUsersSource = <div className="setting-scope badge badge-pill badge-secondary align-self-center">L</div>;
			}
			let visibilityRulesObj = ObjectUtils.getObjFromJSON(fieldSettings.visibilityRules.value);
			let allUsersVis = visibilityRulesObj['allUsers'];
			allUsersContent = allUsersVis.length + ' Rules';

			// take care of the plural.
			if(allUsersVis.length === 1) {
				allUsersContent = '1 Rule';
			}
		}

		return {
			allUsersContent: allUsersContent,
			allUsersSource: allUsersSource
		}
	}
	
	render() {
		let {allUsersContent, allUsersSource} = this.state;

		return (
			<div id="popover-content" className="visibility-popover">
				<PopoverHeader 
					recordId={this.props.recordId} 
					tableSchemaName={this.props.tableSchemaName} 
					iconBase="eye" 
					overlayName="Visibility"/>
				<div className="tab-content row">
					<div className="col-12">
						<ul id="popover-settings" className="nav flex-column">
							<li className="nav-item">
								<div className="nav-link" onClick={this.onSettingClick.bind(this, 'allUsers')}>
									{/* Temporarily removed the following div */}
									{/* <div className="popover-settings-icon setting-icon high fa fa-check-circle-o" /> */}
									{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
									{/* Add this back in when the setting-icon is used again */}
									<div className="d-flex w-100 setting-text-container">
										<div className="w-50 setting-label">
											All Users
										</div>
										<div className="w-50 d-flex justify-content-between setting-value-container">
											<div className="text-right setting-pattern-list setting-value align-self-center">{allUsersContent}</div>
											{allUsersSource}
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
  VisibilityPinPopover.propTypes = {
    recordId: PropTypes.string.isRequired,
    renderId: PropTypes.string.isRequired,
	tableSchemaName: PropTypes.string.isRequired,
    parentRecordId: PropTypes.string,
	parentTableSchemaName: PropTypes.string,
	subSettingIndex: PropTypes.number,
	subSettingSchemaName: PropTypes.string,
	controlsAddAndEdit: PropTypes.bool,
  };
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(VisibilityPinPopover, {withProps: true});
export default container;