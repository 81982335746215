import RecordStore from '../stores/record-store';
import TableStore from '../stores/table-store';
import TableActions from '../actions/table-actions';
import FieldStore from '../stores/field-store';
import FieldActions from '../actions/field-actions';
import PageStore from '../stores/page-store';
import PageActions from '../actions/page-actions';
import ContextStore from '../stores/context-store';
import InterfaceActions from '../actions/interface-actions';

import ObjectUtils from './object-utils';
import socketFetcher from './socket-fetcher';


export default class SecurityGroupUtils {
	/**
	 * Deletes all security groups marked in the store as deleted, removes them
	 * from all security and visibility rules, and saves
	 * all security groups to the database
	 * 
	 * @returns {Promise}
	 * @memberof SecurityGroupUtils
	 */
	static cleanSecurityGroups() {
		// Notify the end user that security groups are being deleted
		// Find all Sec Groups that are to be deleted..
		let securityGroupRecords = RecordStore.getRecords('securityGroup');
		let securityGroupsBeingDeleted = [];
		Object.keys(securityGroupRecords).forEach(securityGroupRecordId => {
			let secGroupRecord = securityGroupRecords[securityGroupRecordId];
			if (secGroupRecord.deleted && secGroupRecord.deleted.value === true) {
				delete securityGroupRecords[securityGroupRecordId];
				securityGroupsBeingDeleted.push(securityGroupRecordId);
			}
		});

		let savePromises = [];

		// Delete them from all Tables' permission lists
		TableStore.getAllArray().forEach(tableObj => {
			let pushTable = false;

			// Check these security groups...
			securityGroupsBeingDeleted.forEach(securityGroupRecordId => {
				// If the table has permissions and this group is among them...
				if (tableObj.securityPermissions && tableObj.securityPermissions.includes(securityGroupRecordId)) {
					// Objectify the permissions, remove the one we're on, and stringify it
					let permissionsObj = ObjectUtils.getObjFromJSON(tableObj.securityPermissions);
					delete permissionsObj[securityGroupRecordId];
					tableObj.securityPermissions = JSON.stringify(permissionsObj);
					pushTable = true;
				}
			});


			if (pushTable) {
				// Update the store with the new table object
				TableActions.pushToStore(tableObj.recordId, tableObj);
				// Then push this updated table to the database.
				let toPush = TableActions.pushToDatabasePromise(tableObj);
				savePromises.push(toPush);
			}
		});

		// Delete them from all Fields' visibility rules
		FieldStore.getAllArray().forEach(fieldObj => {
			let pushField = false;
			let fieldSettings = FieldStore.getSettings(fieldObj.recordId) || {};
			let childConfigurations = FieldStore.getChildConfigurations(fieldObj.recordId);
			securityGroupsBeingDeleted.forEach(securityGroupRecordId => {
				// Delete security group from any field visibility rules
				if (fieldSettings.visibility && fieldSettings.visibility.includes(securityGroupRecordId)) {
					// Objectify the permissions, remove the one we're on, and stringify it
					let permissionsObj = ObjectUtils.getObjFromJSON(fieldSettings.visibility);
					delete permissionsObj[securityGroupRecordId];
					fieldSettings.visibility = JSON.stringify(permissionsObj);
					pushField = true;
				}

				// Delete security group from any child configurations
				if(childConfigurations) {
					Object.keys(childConfigurations).forEach(childId => {
						let childSettings = childConfigurations[childId];
						if(childSettings && childSettings.visibility && childSettings.visibility[securityGroupRecordId]) {
							// Objectify the permissions, remove the one we're on, and stringify it
							let childPermissionsObj = ObjectUtils.getObjFromJSON(childSettings.visibility);
							delete childPermissionsObj[securityGroupRecordId];
							childSettings.visibility = childPermissionsObj;
							pushField = true;
						}
					});
				}
				
				// Delete security group from any fields' tab configurations.
				// Names used: attachedFields, tabOptions. No others are used, so we can hardcode for now
				// But we should discuss if we need a better way later
				['attachedFields', 'tabOptions'].forEach(subSettingName => {
					if(fieldSettings[subSettingName]) {
						let subSetting = ObjectUtils.getObjFromJSON(fieldSettings[subSettingName]);
						if(Array.isArray(subSetting)) {
							subSetting.forEach(individualSetting => {
								if(individualSetting && individualSetting.visibility && individualSetting.visibility.includes(securityGroupRecordId)) {
									let individualSettingPermissionsObj = ObjectUtils.getObjFromJSON(individualSetting.visibility);
									delete individualSettingPermissionsObj[securityGroupRecordId];
									individualSetting.visibility = JSON.stringify(individualSettingPermissionsObj);
									pushField = true;
								}
							});
						} else if(subSetting.visibility && subSetting.visibility.includes(securityGroupRecordId)) {
							// Objectify the permissions, remove the one we're on, and stringify it
							let subSettingPermissionsObj = ObjectUtils.getObjFromJSON(subSetting.visibility);
							delete subSettingPermissionsObj[securityGroupRecordId];
							subSetting.visibility = JSON.stringify(subSettingPermissionsObj);
							pushField = true;
						}
						fieldSettings[subSettingName] = JSON.stringify(subSetting);
					}
				});
			});
			// @TODO: This is backwards compatible and can stay for now, but at some point we may need to update this for child configuration splitting
			fieldSettings.childConfigurations = JSON.stringify(childConfigurations);
			Object.assign(fieldObj, fieldSettings);
			if (pushField) {
				// Update the store with the new field object
				FieldActions.pushToStore(fieldObj.recordId, fieldObj);
				// Then push this updated field to the database.
				savePromises.push(FieldActions.pushToDatabasePromise(FieldStore.get(fieldObj.recordId, true)));
			}
		});

		// Delete them from all Pages' visibility rules
		PageStore.getAllArray().forEach(pageObj => {
			let pushPage = false;
			securityGroupsBeingDeleted.forEach(securityGroupRecordId => {
				// Delete security group from any field visibility rules
				if (pageObj.visibility && pageObj.visibility.includes(securityGroupRecordId)) {
					// Objectify the permissions, remove the one we're on, and stringify it
					let permissionsObj = ObjectUtils.getObjFromJSON(pageObj.visibility);
					delete permissionsObj[securityGroupRecordId];
					pageObj.visibility = JSON.stringify(permissionsObj);
					pushPage = true;
				}

				// Delete security group from any child configurations
				let childConfigurations = PageStore.getChildConfigurations(pageObj.recordId);
				if(childConfigurations) {
					Object.keys(childConfigurations).forEach(childId => {
						let childSettings = childConfigurations[childId];
						if(childSettings && childSettings.visibility && childSettings.visibility[securityGroupRecordId]) {
							// Objectify the permissions, remove the one we're on, and stringify it
							let childPermissionsObj = childSettings.visibility;
							delete childPermissionsObj[securityGroupRecordId];
							childSettings.visibility = childPermissionsObj;
							pushPage = true;
						}
					});

					// @TODO: This is backwards compatible and can stay for now, but at some point we may need to update this for child configuration splitting
					pageObj.childConfigurations = JSON.stringify(childConfigurations);
				}
			});
			if (pushPage) {
				// Update the store with the new field object
				PageActions.pushToStore(pageObj.recordId, pageObj);
				// Then push this updated field to the database.
				savePromises.push(PageActions.pushToDatabasePromise(PageStore.get(pageObj.recordId, true)));
			}
		});

		let groupSaveNotification =
			InterfaceActions.stickyNotification({ level: 'warning', message: 'Saving Security Group(s).' });

		return new Promise((resolve, reject) => {
			Promise.all(savePromises)
				.then(() => {
					// Find Groups that are dirty and need saved.
					let groupPromises = [];
	
					// Delete the groups that need to go
					securityGroupsBeingDeleted.forEach(securityGroupRecordId => {
						groupPromises.push(socketFetcher('gw/securityGroupRecordDelete-v1', {
							recordId: securityGroupRecordId
						}));
					})
	
					// Update the rest, if they changed.
					Object.keys(securityGroupRecords).forEach(securityGroupRecordId => {
						let secGroupRecord = securityGroupRecords[securityGroupRecordId];
						// If we have a name... and...
						if (secGroupRecord.name && (
							// The name is dirty OR we have a description and the description is dirty...
							secGroupRecord.name.isDirty || (secGroupRecord.description && secGroupRecord.description.isDirty)
						)) {
							let fieldsArr = [{
								fieldId: 'ea0fddb1-b38b-4fd3-a698-5c09273aa7cb',
								fieldType: 'd965b6d9-8dd0-440c-a31c-f40bf72accea', // Short Text
								fieldSchemaName: 'name',
								value: secGroupRecord.name.value
							}];
							if (secGroupRecord.description && secGroupRecord.description.isDirty) {
								fieldsArr.push({
									fieldId: '1257d483-9bd7-4900-8df6-21bba13f8c57',
									fieldType: '2e8c9ff8-b6a5-4f32-98ba-24fd8794c6a7', // Long Text
									fieldSchemaName: 'description',
									value: secGroupRecord.description.value
								});
							}
							groupPromises.push(socketFetcher('gw/securityGroupRecordUpsert-v1', {
								tableSchemaName: 'securityGroup',
								recordId: securityGroupRecordId,
								fields: fieldsArr,
								currentContextObj: ContextStore.getState()
							}));
						}
					});
	
					return Promise.all(groupPromises);
				})
				.then((groupPromiseResults) => {
					let hasErrors = false;
					groupPromiseResults.forEach(({response, responseCode}) => {
						if(responseCode !== 200) {
							hasErrors = true;
							console.error('%s Error in saving security groups:', responseCode, response);
						}
					});
					if(hasErrors) {
						InterfaceActions.notification({level: 'error', message: 'Security Group(s) save error. Check your console for more information'});
					}
					InterfaceActions.clearStickyNotification(groupSaveNotification);
					return resolve();
				})
				.then(() => {
					// We don't add any parameters of our own to this call, but need to support the automatic appending of session
					let sessionUpdateNotification = InterfaceActions.stickyNotification({ 'message': 'Updating table permissions across active sessions...', 'level': 'info' });
					return new Promise((resolve, reject) => {
						socketFetcher('gw/refresh-permissions-v1', '{}')
							.then(data => {
								InterfaceActions.clearStickyNotification(sessionUpdateNotification);
								if(data.responseCode !== 200) {
									InterfaceActions.notification({ 'message': 'Unable to update permissions for table. You must log out and in again to create or delete records on this table. Check your console for more information.', 'level': 'warning' });
									console.warn('Issue updating permissions', data.response);
								}
								return resolve();
							})
							.catch((err) => {
								console.warn('Error updating permissions', err);
								return resolve();
							});
					});
				})
				.catch(error => {
					InterfaceActions.clearStickyNotification(groupSaveNotification);
					InterfaceActions.notification({level: 'error', message: 'Security Group(s) save error.'});
					// console.error(error);
					return reject(error);
				});
		});
	}
}