/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Actions
import AdminSettingsActions from '../../actions/admin-settings-actions';
import FieldActions from '../../actions/field-actions';
import InterfaceActions from '../../actions/interface-actions';
import PageActions from '../../actions/page-actions';

// Constants
import Upgrade from '../../constants/help-upgrade-constants';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import AuthenticationStore from '../../stores/authentication-store';
import ContextStore from '../../stores/context-store';
import FieldSettingsStore from '../../stores/field-settings-store';
import FieldStore from '../../stores/field-store';
import FieldTypeStore from '../../stores/field-type-store';
import PageStore from '../../stores/page-store';

// Utils
import FieldUtils from '../../utils/field-utils';
import PageUtils from '../../utils/page-utils';
import SettingsHistoryUtils from '../../utils/settings-history';
import UIUtils from '../../utils/ui-utils';

const forbidden = {
	// Using IDs for this because fields on different tables with this schema name could be created
	'ebc2aa94-784f-4ad4-b7ca-bd4ac8df4f05': 'Default Page',
	// 'defaultpage': true,
	'aad0e76b-5e01-41b0-a840-8f54c5474841': 'Login Page',
	// 'loginpage': true,
	'9696918f-991d-4f99-a3c8-11cc14b96177': 'Fav Icon',
	// 'favicon': true,
	'ea0fddb1-b38b-4fd3-a698-5c09273aa7cb': 'Security Group Name',
	'1257d483-9bd7-4900-8df6-21bba13f8c57': 'Security Group Description'
}

/**
 * Renderer for the appearance assistant
 * 
 * @class AppearanceAssistant
 * @extends {Component}
 */
class AppearanceSettingsChooser extends Component {
	/**
	 * Creates an instance of AppearanceAssistant.
	 * @param {any} props 
	 * 
	 * @memberOf AppearanceAssistant
	 */
	constructor(props) {
		super(props);
		this.onSettingClick = this.onSettingClick.bind(this);
		this.onSaveHandler = this.onSaveHandler.bind(this);
		this.onResetHandler = this.onResetHandler.bind(this);
		this.onDeleteHandler = this.onDeleteHandler.bind(this);

		// method to get list settings of the parent and component
		this.getSettingsList = this.getSettingsList.bind(this);
	}

	/**
	 * 
	 * 
	 * @static
	 * @returns - the stores that are being watch by NLPBuilder component
	 * 
	 * @memberOf NLPBuilder
	 */
	static getStores() {
		return [AdminSettingsStore, AuthenticationStore, FieldSettingsStore, FieldStore, FieldTypeStore, PageStore];
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 * @memberof SettingsPanel
	 */
	onSettingClick(settingFieldId, event) {
		event.preventDefault();

		let settingFieldObj = FieldStore.get(settingFieldId);
		let settingSchemaName = settingFieldObj.fieldSchemaName;

		// Ensure the Settings are shown
		AdminSettingsActions.onSettingsListHideChange(false);

		//Toggle to select and deselect the Setting
		if(AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
			AdminSettingsActions.onSettingChange('', '');
		} else {
			AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
		}
	}
	/**
	 * onSaveHandler - retrieves settings object and calls API to save data
	 * 
	 * @memberof AppearanceSettingsChooser
	 */
	onSaveHandler() {
		let { recordId, parentRecordId, tableSchemaName, parentTableSchemaName } = this.state;
		let valid = true;
		if (tableSchemaName === 'field') {
			let fieldObject = FieldStore.get(recordId);
			SettingsHistoryUtils.updateSettingsHistory(fieldObject);
			FieldActions.pushToDatabase(fieldObject);
			InterfaceActions.notification({ 'level': 'success', 'message': 'Updating Settings...' });
		} else if (tableSchemaName === 'page') {
			let pageObj = PageStore.get(recordId);

			// If the page is new, and there is no tableschemaname, then warn 
			// about it and send them to the setting.
			if(pageObj.new && 
				(!pageObj.tableSchemaName || pageObj.tableSchemaName.length === 0)) {
				valid = false;
				InterfaceActions.notification({ 'level': 'error', 'message': 'Table is required for a new Page' });
				AdminSettingsActions.onSettingChange('tableSchemaName', 'aeb2dc2c-3153-4a27-8820-cd3e1379d29c');
			}
			if(valid) {
				// Delete the new flag, if its still there...
				delete pageObj.new;

				// Push it to the database
				PageActions.pushToDatabase(pageObj);
				InterfaceActions.notification({ 'level': 'success', 'message': 'Updating Settings...' });
			}
		}

		if(valid) {
			if (parentRecordId && parentTableSchemaName === 'field') {
				FieldActions.pushToDatabase(FieldStore.get(parentRecordId));
			} else if (parentRecordId && parentTableSchemaName === 'page') {
				PageActions.pushToDatabase(PageStore.get(parentRecordId));
			}
		}
	}
	/**
	 * onResetHandler - Calls API to retreive data to reset value in store
	 * 
	 * @memberof AppearanceSettingsChooser
	 */
	onResetHandler() {
		let { recordId, parentRecordId, tableSchemaName, parentTableSchemaName } = this.state;
		if (tableSchemaName === 'field') {
			InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting Field...' });
			FieldActions.pullFromDatabase(recordId);
		} else if (tableSchemaName === 'page') {
			InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting Page...' });
			PageActions.pullFromDatabase(recordId);
		}
		
		if (parentRecordId && parentTableSchemaName === 'field') {
			FieldActions.pullFromDatabase(parentRecordId);
		} else if (parentRecordId && parentTableSchemaName === 'page') {
			PageActions.pullFromDatabase(parentRecordId);
		}
	}
	/**
	 * onDeleteHandler - Calls API to Delete a field
	 * 
	 * @memberof AppearanceSettingsChooser
	 */
	onDeleteHandler() {
		//Get the Record to Delete (from the DataBase)
		let {recordId} = this.state;
		//If it is a Field to Delete: 
		if (this.state.tableSchemaName === 'field') {
			let fieldObj = FieldStore.get(recordId);
			// let fieldSettings = FieldStore.getSettings(recordId);
			// let fieldLabel = fieldSettings.fieldLabel;
			// if(!fieldSettings.fieldLabel) {
			// 	fieldLabel = '[ No Field Label found ]'
			// }

			let confirmRemove = confirm('Are you sure you want to delete this Field?');
			if (confirmRemove) {
				if(FieldUtils.allowFieldDeletion(recordId, fieldObj.tableSchemaName)) {
					//First Detach Field from Every Parent this Field is attached and update the Database
					FieldUtils.detachChildFromAllParents(recordId, fieldObj.tableSchemaName, true);

					//Finally, delete the Field from the Table 
					FieldActions.deleteFromDatabase(recordId, fieldObj.tableSchemaName);

					//Confirm Message is Delete
					InterfaceActions.notification({ 'level': 'success', 'message': 'Deleting Field...' });

					//Close Panel after deleting
					UIUtils.closeSettingsPanel();

					// Delete from the Store.
					FieldActions.deleteFromStore(recordId);
				} else {
					InterfaceActions.notification({ 
						'level': 'error', 
						'message': 'Unable to delete field. Tables must have at least one field that stores data.' });
				}
			}
		} else if (this.state.tableSchemaName === 'page') {
			// let pageObj = PageStore.get(this.state.recordId);
			// let pageName = pageObj.name;
			// if(!pageName) {
			// 	pageName = '[ No Page Name found ]'
			// }
			let confirmRemove = confirm('Are you sure you want to delete this Page?');
			if (confirmRemove) {
				//Deletes the Page  
				PageActions.deleteFromDatabase(recordId);

				//Confirm Message is Delete
				InterfaceActions.notification({ 'level': 'success', 'message': 'Deleting Page...' });
				
				if (this.state.recordId === ContextStore.getPageId()) {
					location.href = '/';
				} else {
					//Close Panel after deleting
					UIUtils.closeSettingsPanel();
					PageActions.deleteFromStore(recordId);
				}
			}
		}
	}

	/**
	 * 
	 * 
	 * @static
	 * @param {any} prevState 
	 * @returns {Object}
	 * 
	 * @memberOf NLPBuilder
	 */
	static calculateState(prevState) {
		let componentSettings = [];
		let parentSettings = [];
		let attachmentId = AdminSettingsStore.getAttachmentId();
		let componentId = AdminSettingsStore.getRecordId();
		let componentType = AdminSettingsStore.getTableSchemaName();
		let parentRecordId = AdminSettingsStore.getParentRecordId();
		let parentTableSchemaName = AdminSettingsStore.getParentTableSchemaName();
		let settingsSource = attachmentId ? FieldSettingsStore.getSettingsFromAttachmentIdWithSource(attachmentId, componentId, parentRecordId) : FieldSettingsStore.getSettingsWithSource(componentId, parentRecordId);
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();

		let hasRequiredSettings = false;

		// If we're a field, then capture our field type for components later.
		// If not (we're a page) this will just be empty
		let fieldType = '';

		let componentHeader = '';
		// set parent header Icon
		let parentHeaderIcon = null;

		if(componentType === 'field') {
			let fieldId = componentId;
			let fieldObj = FieldStore.get(fieldId) || {};
			fieldType = fieldObj.fieldType;
			let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType) || {};

			// Find out about our parent
			let parentFieldObj = FieldStore.get(parentRecordId) || {};
			let parentFieldTypeObj = FieldTypeStore.get(parentFieldObj.fieldType) || {};

			// set component header
			componentHeader = fieldTypeObj && fieldTypeObj.name + " Settings";

			// Get the field's direct settings
			let fieldTypeSettings = fieldTypeObj.settings || [];

			// Get the field's parent's child-settings.
			let parentSettingsArr = [];

			if(parentFieldTypeObj && parentRecordId && parentTableSchemaName){
				switch (parentTableSchemaName) {
					case 'page':{
						parentSettingsArr = PageUtils.getChildSettings();

						// Setup the parent header icon, from page
						parentHeaderIcon = FieldUtils.getFieldTypeIcon('page');
						break;
					}
					case 'field': {
						parentSettingsArr = FieldUtils.getChildSettings(parentRecordId);
						
						// Setup the parent header icon, from Field
						parentHeaderIcon = FieldUtils.getFieldTypeIcon(parentRecordId);
						break;
					}
					default: 
						console.warn('Unsupported Parent Table Schema Name:', parentTableSchemaName);
						break;
				}
			} else {
				// parentSettingsArr = FieldUtils.getDefaultSettings();
				// parentHeaderIcon = FieldUtils.getFieldTypeIconByFieldTypeId('page');
			}

			if (fieldTypeSettings && fieldTypeSettings.length) {
				fieldTypeSettings.forEach(fieldTypeSetting => {
					let settingId = fieldTypeSetting.recordId;
					let settingObj = FieldStore.get(settingId) || {};

					// If this IS a query or relationship selector setting, then jump out of the loop.
					if(settingObj.fieldType === '45b6601f-02e8-4dcd-acce-9815d094f009' ||
					   settingObj.fieldType === 'ce0dbfec-e9d9-4dc3-b39a-456eca2b5282') {
							return false;
					}

					let settingSource = settingsSource[settingObj.fieldSchemaName] || {};
					let historyObj = FieldUtils.getSettingHistoryDetails(settingId, fieldId, parentRecordId, parentTableSchemaName);

					// Empty/No Value values.
					let valueObj = {};

					// Check whether value for setting is present or if the local setting was selected, even if no value was entered/chosen
					if(settingSource.value || (!settingSource.value && settingSource.source === 'local')){
						valueObj.value = settingSource.value;
					
						// Work with Value Source
						if(settingSource.source !== 'global') {
							valueObj.source = 'O';
							valueObj.badgeTypeClass = 'badge-secondary';
						}
					}

					componentSettings.push({
						// Basics
						fieldId: settingId,
						fieldObj: settingObj,

						// Label, Value and Sorting..
						label: settingObj.fieldLabel,
						valueObj: valueObj,
						sortOrder: historyObj.order,

						// Used for styling
						quality: historyObj.valueQuality, 
						settingSelected: (selectedSetting === settingObj.fieldSchemaName)
					});
				});
			}

			if (parentSettingsArr && parentSettingsArr.length) {
				parentSettingsArr.forEach(fieldTypeSetting => {
					let settingId = fieldTypeSetting.recordId;
					let settingObj = FieldStore.get(settingId) || {};
					let settingSource = settingsSource[settingObj.fieldSchemaName] || {};
					let historyObj = FieldUtils.getSettingHistoryDetails(settingId, fieldId, parentRecordId, parentTableSchemaName);

					// Empty/No Value values.
					let valueObj = {};

					// Check whether value for setting is present
					if(settingSource.value || (!settingSource.value && settingSource.source === 'local')){
						valueObj.value = settingSource.value;

						// Work with Value Source
						if(settingSource.source !== 'global') {
							valueObj.source = 'O';
							valueObj.badgeTypeClass = 'badge-secondary';
						}
					}

					// If this IS a query or relationship selector setting, then jump out of the loop.
					if(settingObj.fieldType === '45b6601f-02e8-4dcd-acce-9815d094f009' ||
					   settingObj.fieldType === 'ce0dbfec-e9d9-4dc3-b39a-456eca2b5282') {
							return false;
					}

					parentSettings.push({
						// Basics
						fieldId: settingId,
						fieldObj: settingObj,

						// Label, Value and Sorting..
						label: settingObj.fieldLabel,
						valueObj: valueObj,
						sortOrder: historyObj.order,

						// Used for styling
						quality: historyObj.valueQuality, 
						settingSelected: (selectedSetting === settingObj.fieldSchemaName)
					});
				});
			}
		} else if (componentType === 'page') {
			let pageId = componentId;
			let pageObj = PageStore.get(pageId);
			let pageSettings = PageUtils.getPageSettings();

			componentHeader = 'Page Settings';

			pageSettings.forEach(pageSetting => {
				let settingId = pageSetting.recordId;
				let settingObj = FieldStore.get(settingId) || {};
				let settingsValue = pageObj[settingObj.fieldSchemaName];
				
				// Empty/No Value values.
				let valueObj = {};

				// Check whether value for setting is present
				if(settingsValue){
					valueObj.value = settingsValue;
				}


				// Should this be required?
				// Only if the page is new and its the Table setting - for now.
				let required = false;
				if(pageObj.new && settingObj.recordId === 'aeb2dc2c-3153-4a27-8820-cd3e1379d29c') {
					required = true;
					hasRequiredSettings = true;
				}

				componentSettings.push({
					// Basics
					fieldId: settingId,
					fieldObj: settingObj,

					// Label, Value and Sorting..
					label: settingObj.fieldLabel,
					valueObj: valueObj,
					sortOrder: 0,

					// Used for styling
					settingSelected: (selectedSetting === settingObj.fieldSchemaName),
					required: required
				});
			});
		}

		// Sort the field results by setting name
		componentSettings.sort(function(a,b){
			if(a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if(a.label.toLowerCase() < b.label.toLowerCase()) { 
				return -1;
			}
			return 1;
		});

		// Sort the parent results by setting name
		parentSettings.sort(function(a,b){
			if(a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if(a.label.toLowerCase() < b.label.toLowerCase()) { 
				return -1;
			}
			return 1;
		});

		return {
			componentSettings: componentSettings,
			parentSettings: parentSettings,
			recordId: componentId,
			tableSchemaName: componentType,
			fieldType: fieldType,
			parentRecordId: parentRecordId,
			parentTableSchemaName: parentTableSchemaName,
			componentHeader: componentHeader,
			parentHeaderIcon: parentHeaderIcon,
			hasRequiredSettings: hasRequiredSettings
		}
	}

	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @returns { array } array of <li> settings elements
	 */
	getSettingsList(settingsArr) {

		let { fieldType } = this.state;

		// iterate over the settings and return an array of list items
		return settingsArr.map(setting => {
			let labelClassNames = 'setting-label';
			if(setting.settingSelected) { 
				labelClassNames += ' selected';
			}
			/*************************
			    The following commented out statements are for the setting-icon, which is not currently in use
				Uncomment, when setting-icon is used again
			**************************/

			// let iconClassNames = 'fa';
			
			// switch(setting.quality) {
			// 	case 'custom':
			// 		iconClassNames += ' custom fa-check-square-o';
			// 	break;
			// 	default:
			// 	case 'low':
			// 		iconClassNames += ' high fa-check-circle-o';
			// 	break;
			// 	case 'high':
			// 		iconClassNames += ' low fa-exclamation-triangle';
			// 	break;
			// }

			/* @TODO
				There are many fields that do not have the correct view variant assigned to it. 'Placeholder Overrides'
				is one of them.  Here, we are only handling the checkMarkView variant - will need to go through the Google
				DataStore and make the changes to each view variant.
			*/
			let componentName = '';
			if(setting.fieldObj && setting.fieldObj.viewVariant.length && setting.fieldObj.viewVariant === 'checkMarkView') {
				componentName = setting.fieldObj.viewVariant;
			}
			else {
				componentName = FieldStore.getDefaultVariantComponentName(
					setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
			}
			let componentProps = setting.fieldObj;
			componentProps.value = setting.valueObj.value;
			componentProps.fieldType = fieldType;

			let requiredMarker = null;
			if(setting.required) {
				requiredMarker = <div style={{ left: '-14px', top: '2px' }} className="required-marker position-absolute"><i className="fa fa-circle fa-1" aria-hidden="true"></i></div>;
			}

			return (<li key={setting.fieldId} className={"nav-item d-flex flex-column justify-content-center " + (setting.settingSelected ? 'setting-selected' : '')}>
				<div className="nav-link" onClick={this.onSettingClick.bind(this, setting.fieldId)}>
					{/* Temporarily removed the following div */}
					{/* <div className={"setting-icon " + iconClassNames} /> */}
					{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
					{/* Add this back in when the setting-icon is used again */}
					<div className="d-flex setting-text-container">
						<div className="w-50 setting-label-container">
							<div className={labelClassNames + ' d-flex align-items-center position-relative'}>
								{requiredMarker}<h4>{setting.label}</h4>
							</div>
							<div className="setting-pattern-list">{setting.mostRecentPattern}</div>
						</div>
						<div className="w-50 d-flex justify-content-end setting-value-container">
							<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
								<h5 className={`settings-list-value ${componentName}`}>{ (componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null) }</h5>
							</div>
							{ setting.valueObj.source ? 
								<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}> 
									{ setting.valueObj.source } 
								</div>
							: null
							}
						</div>
					</div>
				</div>
			</li>);
		});
	}

	/**
	 * 
	 * 
	 * @returns 
	 * @memberof AppearanceSettingsChooser
	 */
	render() {
		let {
			componentSettings, 
			parentSettings, 
			componentHeader, 
			parentHeaderIcon, 
			recordId, 
			tableSchemaName, 
			hasRequiredSettings
		} = this.state;

		let settingsList = this.getSettingsList(componentSettings); // component settings
		let parentSettingsList = this.getSettingsList(parentSettings); // parent settings

		/* The ids for these buttons are set in a specific way, so that Ctrl-S and Ctrl-R (UIUtils.onkeyDown method) can find these buttons */
		let buttons = [
			<button id={'appearanceSave'} key="submit" className="btn btn-primary btn-lg setting-list-button mr-0" form="appearance-form" aria-label="Save" title="Save (Ctrl-S)" onClick={this.onSaveHandler}>Save</button>,
			<button id={'appearanceReset'} key="reset" className="btn btn-warning btn-lg setting-list-button mr-0" form="appearance-form" aria-label="Reset" title="Reset (Ctrl-R)" onClick={this.onResetHandler}>Reset</button>
		]
		if(!forbidden[recordId]) {
			buttons.push(<button key="delete" className="btn btn-danger btn-lg setting-list-button mr-0" form="appearance-form" aria-label="Delete" onClick={this.onDeleteHandler}>Delete</button>);
		}

		if(tableSchemaName === 'page' && AuthenticationStore.getMaxPages() !== 0 && PageStore.getCount() >= AuthenticationStore.getMaxPages()) { 
			buttons = [<button 
              key="save" 
              className="btn btn-success btn-md setting-list-button mr-3 ml-1" 
              form="appearance-form" 
              aria-label="Upgrade to increase allowed Pages"
              style={{ width: '400px'}}
              onClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_MAX_PAGES) } } >
              Upgrade to increase allowed Pages
            </button>];
		}

		let requiredMarker = null;
		if(hasRequiredSettings) {
			requiredMarker = <div className="required-marker mx-4 d-flex"><i className="fa fa-circle d-flex align-items-center" aria-hidden="true"></i> <h4 className="ml-2">Required</h4></div>;
		}

		let componentIcon = (tableSchemaName === 'field' ? FieldUtils.getFieldTypeIcon(recordId) : FieldUtils.getFieldTypeIcon('page'));

		return [
				<div key="settings" className='settings-list-wrapper'>
					<ul key="list" className="nav flex-column">
						{/* not all fields have child attachment settings
						    don't show the parent name if there are no child attachment settings */}
						{settingsList && settingsList.length
							? <li className="settings-list-header">
								<div className="d-flex">
										<img height="22" width="22" className="mr-2" src={componentIcon} alt="" />
										<h3>{componentHeader}</h3>
									</div>
							</li>
							: null
						}
						{settingsList && settingsList.length
							? settingsList
							: null
						}
						{parentSettingsList && parentSettingsList.length
							? <li className="settings-list-header field-settings-list-header">
									<div className="d-flex align-items-center">
										<img height="22" width="22" className="mr-2" src={parentHeaderIcon} alt="" />
										<h3>Parent Settings</h3>
									</div>
								</li>
							: null
						}
						{parentSettingsList}
						{!parentSettingsList && !settingsList && <div className='no-settings-found'>No Settings Found</div>}
            			{requiredMarker}
					</ul>
				</div>,
				<div key="buttons" className="btn-wrapper appearance-btn-wrapper">
					{ buttons }
				</div>
		];
	};
}

const container = Container.create(AppearanceSettingsChooser);
export default container;
