import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container} from 'flux/utils';
import {QueryEditor} from '@dmclain-citizendeveloper/citdev-react-query';
import _ from 'lodash';
import TableStore from '../stores/table-store';
import FieldTypeStore from '../stores/field-type-store';
import FieldStore from '../stores/field-store';
import RelationshipStore from '../stores/relationship-store';
import ObjectUtils from '../utils/object-utils';


/**
 * Container for query builder
 */
class QueryContainer extends Component {
	/**
	 * Creates instance of QueryContainer
	 *
	 * @memberof QueryContainer
	 */
	constructor(props) {
		super(props);
		this.state = {
			flushBlockly: false
		};
		this._onChange = this._onChange.bind(this);
	}

	/**
	 * Required static method for flux container
	 *
	 * @memberof QueryContainer
	 */
	static getStores() {
		return [TableStore, FieldTypeStore, RelationshipStore, FieldStore];
	}

	/**
	 * Required static method for flux container
	 *
	 * @memberof QueryContainer
	 */
	static calculateState(prevState, props) {

		var tables = TableStore.getAllArray() || [], relationships = {}, fields = {};

		tables.forEach(table => {
			relationships[table.tableSchemaName] = _.values(RelationshipStore.getByTableSchemaName(table.tableSchemaName) || {});
			fields[table.tableSchemaName] = FieldStore.getByTableSchemaName(table.tableSchemaName);
		});

		// Because this is managed by a Blockly dialog which is not React-based, the initial value prop is appropriate for the first time, 
		// but subsequently should be pulled from the state.
		let value = props.value;

		// Parse the value into the current query object
		let currentQueryObject = prevState && prevState.currentQuery ? prevState.currentQuery : ObjectUtils.getObjFromJSON(value);

		return {
			'currentQuery': currentQueryObject,
			'fields': fields,
			'fieldTypes': FieldTypeStore.getAllArray() || [],
			'tables': TableStore.getAllArray() || [],
			'relationships': relationships
			// 'error': state.get('error')
		};
	}

	/**
	 * State getter for Blockly dynamic configurations
	 *
	 * @memberof QueryContainer
	 */
	getState() {
		// console.log('state', this.state);
		return this.state;
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.value !== this.props.value) {
			this.setState({
				currentQuery: ObjectUtils.getObjFromJSON(this.props.value)
			});
		}
	}

	/**
	 * render component QueryContainer
	 *
	 * @memberof QueryContainer
	 */
	render() {
		let {currentQuery, error, fields, fieldTypes, relationships, tables} = this.state,
		{namedContexts, triggerName, runTimeVariables, variables, dialogHeight} = this.props;
		if (error) {
			console.warn(error);
		}

		return <QueryEditor
					dialogHeight={dialogHeight}
					value={currentQuery || null}
					onChange={this._onChange}
					relationships={relationships}
					fieldTypes={fieldTypes}
					fields={fields}
					tables={tables}
					namedContexts={namedContexts}
					triggerName={triggerName}
					runTimeVariables={runTimeVariables}
					variables={variables}
					flushBlockly={this.state.flushBlockly}
					 />;
	}

	/**
	 * Handles change event from QueryEditor
	 * 
	 * @param {Object} queryObj 
	 * 
	 * @memberof QueryContainer
	 */
	_onChange(queryObj) {
		this.setState({ currentQuery: queryObj, flushBlockly: false }, () => {
			this.props.onChange({
				target: {
					value: JSON.stringify(queryObj)
				}
			});
		});
	}

}

if ('development' === process.env.NODE_ENV) {
	QueryContainer.propTypes = {
		schemaName: PropTypes.string.isRequired,
		onChange: PropTypes.func,
		namedContexts: PropTypes.string,
	};
}

const container = Container.create(QueryContainer, {withProps: true});
export default container;
