import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import {RelationshipConstants} from '../constants/relationship-constants';
import MetadataBuilder from '@dmclain-citizendeveloper/citdev-module-metadata-builder';
import fetchMetadata from '../utils/fetch-utils';

let _instance = null;

/**
 * Actions for the core store that contains relationship records for the dev environment where we talk to the MDGW
 *
 * @class RelationshipActionsDev
 */
class RelationshipActionsDev {
	/**
	 * Singleton instance of RelationshipActionsDev
	 * @param {Object} options
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if(options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Set the Installation Id and (optionally the endPoint) and setup the Metadata builder
	 * 
	 * @param {string} installationId 
	 * @param {string} endPoint 
	 */
	setInstallationId(installationId, signedMdKey, endPoint) {
		this._metadataBuilder = new MetadataBuilder(installationId, signedMdKey, endPoint);
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw(new Error('RelationshipActionsDev not properly initialized with a dispatcher'));
		}
		if (!this._metadataBuilder) {
			throw(new Error('RelationshipActionsDev not properly initialized with an installationId'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: RelationshipConstants.RELATIONSHIP_PULL_ERROR,
			error: error.message
		}));
	}

	receiveBroadcast(records) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: RelationshipConstants.RELATIONSHIP_RECEIVE_BROADCAST,
			records: records
		}));
	}

	/**
	 * Deletes a relationship from the database AND the store
	 * 
	 * @param {string} recordId Relationship Record ID to delete from the database and the store
	 */
	deleteFromDatabase(recordId) {
		this.deleteFromDatabasePromise(recordId);
	}

	/**
	 * Deletes a relationship from the database AND the store in a promisified fashion
	 * 
	 * @param {string} recordId Relationship Record ID to delete from the database and the store
	 */
	deleteFromDatabasePromise(recordId) {
		return new Promise((resolve, reject) => {
			try {
				// We need the Relationship Store
				let RelationshipStore = undefined,
					relationshipObj = undefined;
				try {
					RelationshipStore = window.RelationshipStore;
					relationshipObj = RelationshipStore.get(recordId);
				} catch (error) {
					console.error('Unable to attach to the FieldStore from the window in FieldActions.deleteFromDatabase.');
				}

				// Local Meta Data Builder
				this._metadataBuilder
					// Get the field(s)
					.deleteRelationships([relationshipObj])
					.then(function () {
						// Pass the fields to the store (call reduce with the object below)
						this._dispatcher.dispatch(Immutable.fromJS({
							type: RelationshipConstants.RELATIONSHIP_DELETE_FROM_STORE,
							recordId: recordId
						}));
						return resolve();
					}.bind(this))
					.catch(function (error) {
						console.error(error);
						this._onError(error);
						return reject(error);
					}.bind(this));
			} catch (err) {
				return reject(err);
			}
		});
	}

	/**
	 * Deletes a relationship from the store
	 * 
	 * @param {string} recordId Relationship Record ID to delete from the store.
	 */
	deleteFromStore(recordId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: RelationshipConstants.RELATIONSHIP_DELETE_FROM_STORE,
			recordId: recordId
		}));
	}

	/**
	 * Update Metadata Gateway with this relationship's data.
	 * @param {string} recordId Relationship Record ID to push from the store to the database
	 */
	pushToDatabase(relationshipObject) {
		this._checkInit();

		// make *sure* we have a standard javascript object...
		if(relationshipObject.toJS) {
			relationshipObject = relationshipObject.toJS();
		}

		// Local Meta Data Builder
		this._metadataBuilder
			// Udpate the relationship
			.updateRelationship(
				relationshipObject.relationSchemaName, 
				relationshipObject.lTableSchemaName, 
				relationshipObject.ltorLabel, 
				relationshipObject.lCardinality, 
				relationshipObject.rTableSchemaName, 
				relationshipObject.rtolLabel, 
				relationshipObject.rCardinality, 
				relationshipObject.recordId)
			.then(function(){
				// Pass the relationships to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: RelationshipConstants.RELATIONSHIP_PUSH_TO_DATABASE,
					relationshipObject: relationshipObject
				}));
			}.bind(this))
			.catch(function(error){ 
				console.error(error); 
				this._onError(error);
			}.bind(this));
	}

	/**
	 * Update Metadata Gateway with this relationship's data.
	 * @param {string} recordId Relationship Record ID to push from the store to the database
	 * @returns {Promise}
	 */
	pushToDatabasePromise(relationshipObject) {
		return new Promise((resolve, reject) => {
			try {
				this._checkInit();
			} catch (error) {
				return reject(error);
			}
	
			// make *sure* we have a standard javascript object...
			if(relationshipObject.toJS) {
				relationshipObject = relationshipObject.toJS();
			}
	
			// Local Meta Data Builder
			this._metadataBuilder
				// Udpate the relationship
				.updateRelationship(
					relationshipObject.relationSchemaName, 
					relationshipObject.lTableSchemaName, 
					relationshipObject.ltorLabel, 
					relationshipObject.lCardinality, 
					relationshipObject.rTableSchemaName, 
					relationshipObject.rtolLabel, 
					relationshipObject.rCardinality, 
					relationshipObject.recordId,
					relationshipObject.auditLog
				)
				.then(function(){
					// Pass the relationships to the store (call reduce with the object below)
					this._dispatcher.dispatch(Immutable.fromJS({
						type: RelationshipConstants.RELATIONSHIP_PUSH_TO_DATABASE,
						relationshipObject: relationshipObject
					}));
					return resolve(relationshipObject);
				}.bind(this))
				.catch(function(error){ 
					console.error(error); 
					this._onError(error);
					return reject(error);
				}.bind(this));
		});
	}

	/**
	 * Changes the Schemaname of a table
	 *
	 * @param {*} recordId
	 * @param {*} newRelationSchemaName
	 * @returns
	 * @memberof TableActionsDev
	 */
	 changeSchemaName(relationInfo) {
		return new Promise((resolve, reject) => {
			this._metadataBuilder
				.updateSchemaName([relationInfo], 'relationship')
				.then(function(){
					// Pass the tables to the store (call reduce with the object below)
					let relationshipObject = {recordId: relationInfo.recordId, relationSchemaName: relationInfo.newRelationSchemaName};
					this._dispatcher.dispatch(Immutable.fromJS({
						type: RelationshipConstants.RELATIONSHIP_PUSH_TO_DATABASE,
						relationshipObject: relationshipObject
					}));
					return resolve(relationshipObject);
				}.bind(this))
				.catch(function(error){ 
					console.error(error);
					this._onError(error);
					return reject(error);
				}.bind(this));
		});
	}

	/**
	 * Update store record properties
	 * @param {string} recordId Record to update
	 * @param {Object} recordProperties Properties to update
	 */
	pushToStore(recordId, recordProperties) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: RelationshipConstants.RELATIONSHIP_PUSH_TO_STORE,
			recordId: recordId,
			recordProperties: recordProperties
		}));
	}

	/**
	 * Update store with a record's data.
	 * @param {string} recordId Record to pull from the database to the database
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		// Local Meta Data Builder
		this._metadataBuilder
			// Get all the relationships
			.searchRelationships({'recordId': recordId})
			.then(function(relationshipArray){
				// Pass the relationships to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: RelationshipConstants.RELATIONSHIP_PULL_FROM_DATABASE,
					relationshipArray: relationshipArray,
					overwriteStore: overwriteStore
				}));
			}.bind(this))
			.catch(function(error){ 
				console.error(error); 
				this._onError(error);
			}.bind(this));
	}

	/**
	 * Update store with all of the records' data.
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabaseAll(overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		// Local Meta Data Builder
		this._metadataBuilder
			// Get all the relationships
			.browseRelationships()
			.then(function(relationshipArray){
				// Pass the relationships to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: RelationshipConstants.RELATIONSHIP_PULL_FROM_DATABASE_ALL,
					relationshipArray: relationshipArray,
					overwriteStore: overwriteStore
				}));
			}.bind(this))
			.catch(function(error){ 
				console.error(error); 
				this._onError(error);
			}.bind(this));
	}
}

/**
 * Actions for the core store that contains relationship records for the test/prod environments where we talk to the CDN JSON
 *
 * @class RelationshipActionsProd
 */
class RelationshipActionsProd {
	/**
	 * Singleton instance of RelationshipActionsProd
	 * @param {Object} options
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if(options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Set the Installation Id
	 * 
	 * @param {string} installationId 
	 */
	setInstallationId(installationId) {
		this._installationId = installationId;
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw(new Error('RelationshipActionsProd not properly initialized with a dispatcher'));
		}
		if (!this._installationId) {
			throw(new Error('RelationshipActionsProd not properly initialized with an installationId'));
		}
		if (!this._installationVersion) {
			throw(new Error('RelationshipActionsProd not properly initialized with an installationVersion'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: RelationshipConstants.RELATIONSHIP_PULL_ERROR,
			error: error.message
		}));
	}

	/**
	 * Deletes a table from the database AND the store
	 */
	deleteFromDatabase() {
		console.warn('deleteFromDatabase not implemented.');
	}

	/**
	 * Deletes a table from the store
	 * 
	 * @param {string} recordId Table Record ID to delete from the store.
	 */
	deleteFromStore(recordId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: RelationshipConstants.RELATIONSHIP_DELETE_FROM_STORE,
			recordId: recordId
		}));
	}

	/**
	 * Update Metadata Gateway with this record's data.
	 */
	pushToDatabase() {
		console.warn('pushToDatabase not implemented.');
	}

	/**
	 * Update store record properties
	 * @param {string} recordId Record to update
	 * @param {Object} recordProperties Properties to update
	 */
	pushToStore(recordId, recordProperties) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: RelationshipConstants.RELATIONSHIP_PUSH_TO_STORE,
			recordId: recordId,
			recordProperties: recordProperties
		}));
	}

	/**
	 * Update store with a record's data.
	 * @param {string} recordId Record to pull from the database to the database
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		let installationId = this._installationId,
			installationVersion = this._installationVersion,
			metadataPath = 'https://cdn3.citizendeveloper.com/installations/' + 
				installationId + '/' + installationVersion + '/relationship/' + recordId + '.json';
		fetchMetadata(metadataPath)
			.then((jsonResponse) => {
				// Pass the tables to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: RelationshipConstants.RELATIONSHIP_PULL_FROM_DATABASE,
					relationshipArray: [jsonResponse],
					overwriteStore: overwriteStore
				}));
			})
			.catch(function(error){
				console.error('Unable to retrieve metadata from ', metadataPath, '. Error was: ', error);
			});
	}

	/**
	 * Update store with all of the records' data.
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabaseAll(overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		let installationId = this._installationId,
			installationVersion = this._installationVersion,
			metadataPath = 'https://cdn3.citizendeveloper.com/installations/' + 
				installationId + '/' + installationVersion + '/relationship/index.json';
		fetchMetadata(metadataPath)
			.then((jsonResponse) => {
				// Pass the tables to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: RelationshipConstants.RELATIONSHIP_PULL_FROM_DATABASE_ALL,
					relationshipArray: jsonResponse,
					overwriteStore: overwriteStore
				}));
			})
			.catch(function(error){
				console.error('Unable to retrieve metadata from ', metadataPath, '. Error was: ', error);
			});
	}
}

let toExport;
if(process.env.CITDEV_ENV === 'development'){

	try {
		if(window.location.href.includes('settings.html') && typeof window.opener.location.href === 'string') {
			toExport = window.opener.RelationshipActions;
		} else {
			const instance = new RelationshipActionsDev({dispatcher: AppDispatcher});
			toExport = instance;
		}
	} catch(error) { 
		const instance = new RelationshipActionsDev({dispatcher: AppDispatcher});
		toExport = instance;
	}
} else {
	toExport = new RelationshipActionsProd({dispatcher: AppDispatcher});
}

export default toExport;