import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Constants
import Help from '../../constants/help-upgrade-constants';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import ContextStore from '../../stores/context-store';

// Utils
import UIUtils from '../../utils/ui-utils';

/**
 * Displays dashboard header
 */
class DataDashboardHeader extends Component {
	/**
	 * Return the stores we're listening to for changes.
	 *
	 * @return {array}  description
	 */
	static getStores() {
		return [ AdminSettingsStore ];
	}

	/**
	 * Calculate our state anytime a store changes.
	 *
	 * @param  {object} prevState
	 * @return {object}
	 */
	static calculateState(prevState) {
		return {
			'isActive' : (AdminSettingsStore.getActiveDashboard() === 'data' ? true : false),
		};
	}

	/**
	 * render the component
	 *
	 * @return {JSX}
	 */
	render() {
		let { isActive } = this.state;
		// 'automation', 'appearance', 'query', 'discussion', 'visibility', 'security', 'compliance'

		let { 
			onOverlayEnable, 
			onDashboardSelect,
			isSecurityOn,
			isRecordsOn,
			panelDashboard
		} = this.props;

		let urlFontawesome = ContextStore.getUrlFontawesome();

		return (
			<div onClick={() => { onDashboardSelect('data') }} className={`cd-dashboard-header ${panelDashboard === 'data' ? 'data' : ''}`}>
				<div className="d-flex justify-content-between">
					<div className="d-flex align-items-center">
						<div className="d-flex cd-dashboard-title">
							<h4 className="cd-dashboard-sign">
								{isActive 
									? <i title="Collapse" className="fa fa-minus" aria-hidden="true"></i> 
									: <i title="Expand" className="fa fa-plus" aria-hidden="true"></i>
								}
							</h4>
							<h4 className="ml-1">Data</h4>
						</div>
						<div title="Data Help" className="cd-dashboard-info" 
							onClick={event => {
								event.stopPropagation();
								UIUtils.onHelpClick(Help.HELP_DASHBOARD_DATA);
							}
							}>
							<i className="fa fa-info-circle" aria-hidden="true"></i>
						</div>
					</div>
					<div title="Security" className="d-flex cd-dashboard-pin-container">
						<div className={`cd-dashboard-pin ${isSecurityOn ? 'security' : ''}`} 
							onClick={event => {
								event.stopPropagation();
								onOverlayEnable('security');
								}
							}>
							<img 
								src={ContextStore.getUrlMedia() + "/icon-security.svg"} 
								alt="" />
						</div>
						<div title="Records" className={`cd-dashboard-pin ml-1 ${isRecordsOn ? 'query' : ''}`} 
							onClick={event => {
								event.stopPropagation();
								onOverlayEnable('query');
								}
							}>
							<img src={urlFontawesome + '/database.svg'} className="icon" alt="" />
						</div>
					</div>
				</div>
			</div>
		);
	}
}
const container = Container.create(DataDashboardHeader);
export default container;