/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Container} from 'flux/utils';

// Actions
import AdminSettingsActions from '../../actions/admin-settings-actions';

//Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import FieldStore from '../../stores/field-store';
import PageStore from '../../stores/page-store';
import TableStore from '../../stores/table-store';

// Utils
import { UIUtils} from '../../utils';


/**
 * Container to SecurityPin
 *
 * @class SecurityPin
 * @extends {Component}
 */
class SecurityPin extends Component {
	constructor(props) {
		super(props);
		this.link = null;
		this.onPinClickHandler = this.onPinClickHandler.bind(this);
		this.onPinDoubleClickHandler = this.onPinDoubleClickHandler.bind(this);
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf SecurityPin
	 */
	static getStores() {
		return [AdminSettingsStore];
	}

	/**
	 * Returns the current State of the SecurityPin Settings
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf SecurityPin
	 */
	static calculateState(prevState, props) {
		let isUnused = true;
		let isDisabled = false;
		
		let targetTableSchemaName = '';
		if(props.tableSchemaName === 'field') {
			let fieldObj = FieldStore.get(props.recordId);
			targetTableSchemaName = fieldObj ? fieldObj.tableSchemaName : '';
		} else {
			// Assume we're a page...
			let pageObj = PageStore.get(props.recordId);
			targetTableSchemaName = pageObj ? pageObj.tableSchemaName : '';
		}

		let tableObj = TableStore.getByTableSchemaName(targetTableSchemaName);
		if(tableObj && tableObj.securityPermissions && tableObj.securityPermissions !== '{}') {
			isUnused = false;
		}

		return {
			isDisabled: isDisabled,
			isUnused: isUnused,
			overlayActive: AdminSettingsStore.getIsOverlayActive('security'),
			targetTableSchemaName: targetTableSchemaName
		}
	}

	componentDidMount() {
		this.clickTimeouts = [];
	}

	/**
	 * onPinClickHandler - Handles pin click. 
	 */
	onPinClickHandler(event) {
		// Stop clicking "through" the pin, like on List column headers
		event.preventDefault();
		event.stopPropagation();

		// Open the settings preview
		// (targetOverlay, 
		//	recordId, tableSchemaName, 
		//	parentRecordId, parentTableSchemaName, 
		//	mainTab, subSettingSchemaName, subSettingIndex, renderId, 
		// 	attachmentId)
		this.clickTimeouts.push(setTimeout(() => {
			UIUtils.openSettingsPreview('security', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 
			undefined, undefined, undefined, this.props.renderId, 
			this.props.attachmentId);
			// Time you have between clicks to determine if it was single or double.
		}, 250));
	}

	/**
	 * onPinDoubleClickHandler - Handles pin double click. 
	 */
	onPinDoubleClickHandler(event) {
		this.clickTimeouts.forEach(timeout => {
			clearTimeout(timeout);
		});

		// Stop clicking "through" the pin, like on List column headers
		event.preventDefault();
		event.stopPropagation();

		// Open the settings panel on the right.
		// (targetOverlay, 
		//	recordId, tableSchemaName, 
		//	parentRecordId, parentTableSchemaName, 
		//	mainTab, subSettingSchemaName, subSettingIndex, renderId, 
		// 	attachmentId)
		
		UIUtils.openSettingsPanel('security', 
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 
			'settings', this.state.targetTableSchemaName, undefined, this.props.renderId,
			this.props.attachmentId);

		// Make sure the settings are toggled to visible.
		AdminSettingsActions.onSettingsListHideChange(false);
	}

	render() {
		let { overlayActive, isUnused, isDisabled } = this.state;
		let pinDivClassAppend = (isDisabled 
			? 'disabled' 
			: (isUnused 
				? 'unused' 
				: ''));

		let tableObj = null;
		let targetTableSchemaName = null;
		let icon = 'fa-shield';
		if(this.props.tableSchemaName === 'field') {
			let fieldObj = FieldStore.get(this.props.recordId);
			tableObj = TableStore.getByTableSchemaName(fieldObj.tableSchemaName);
		} else {
			// Assume we're a page...
			let pageObj = PageStore.get(this.props.recordId);
			tableObj = TableStore.getByTableSchemaName(pageObj.tableSchemaName);
		}
		if(tableObj && tableObj.icon && tableObj.icon.length) {
			icon = 'fa-' + tableObj.icon;
			targetTableSchemaName = tableObj.tableSchemaName;
		}

		if( overlayActive ) {
			return <div 
						key={this.props.attachmentId || this.props.recordId}
						className={"pin " + pinDivClassAppend}
						ref={el => this.link = el}
						data-trigger="manual"
						data-toggle="popover"
						data-fallback-placement="flip"
						data-html="true"
						data-content=" "
						onClick={(event) => { if(!isDisabled) { this.onPinClickHandler(event, targetTableSchemaName) }}}
						onDoubleClick={(event) => { this.onPinDoubleClickHandler(event); }}
					>
							<svg className="security pin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
								<path d="M12 2C8.1 2 5 5.1 5 9c0 5.2 7 13 7 13s7-7.8 7-13C19 5.1 15.9 2 12 2z" fill="#BBB"/>
							</svg>
							<span className={"security fa " + icon + ""} alt="Security"/>
						</div>
		} else {
			return null;
		}
	}
}

if ('development' === process.env.NODE_ENV) {
  SecurityPin.propTypes = {
    recordId: PropTypes.string.isRequired,
    tableSchemaName: PropTypes.string.isRequired,
    parentRecordId: PropTypes.string,
    parentTableSchemaName: PropTypes.string,
  };
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(SecurityPin, {withProps: true});
export default container;