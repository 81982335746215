import keymirror from 'keymirror';

/**
 * READ THIS:  If you are adding constants for purposes that update this store then please
 * inform the NLP Team (Yoli and Drew) so they can make appropriate changes to the NLP Dictionaries Store.
 */
export const PageConstants = keymirror({
	PAGE_DELETE_FROM_DATABASE: null,
	PAGE_DELETE_FROM_STORE: null,
	PAGE_PULL_FROM_DATABASE: null,
	PAGE_PULL_FROM_DATABASE_ALL: null,
	PAGE_PULL_ERROR: null,
	PAGE_PUSH_COLS_TO_STORE: null,
	PAGE_PUSH_COMPONENTS_TO_STORE: null,
	PAGE_PUSH_FIELD_AUTOMATION_TO_STORE: null,
	PAGE_PUSH_FIELD_SETTING_TO_STORE: null,
	PAGE_PUSH_LAYOUTS_TO_STORE: null,
	PAGE_PUSH_TEMPLATE_TO_STORE: null,
	PAGE_PUSH_TO_DATABASE: null,
	PAGE_PUSH_TO_STORE: null,
	PAGE_PUSH_AUTOMATION_TO_STORE: null,
	PAGE_PUSH_CHILD_CONFIGURATION_TO_STORE: null,
	PAGE_RECEIVE_BROADCAST: null
});
