import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import ToolboxConstants from '../constants/toolbox-constants';

const ToolboxActions = {
	/**
	 * Reads the expression toolbox from the CDN
	 * 
	 * @param {string} fieldType id
	 * @param {string} fieldType name
	 * @param {any} value of fieldType
	 */
	loadExpressionToolbox(communityURL) {
		let expressionToolboxURL = communityURL + '/expression-toolbox.json';
		fetch(expressionToolboxURL).then(function (response) {
			if (response.status === 200) {
				response.json().then(function (resultsObj) {
					AppDispatcher.dispatch(Immutable.fromJS({
						type: ToolboxConstants.EXPRESSION_TOOLBOX_LOAD,
						expressionToolbox: resultsObj
					}));
				}).catch(function () {
					console.error('Error reading expression toolbox, invalid JSON');
				});
			} else {
				console.error('Error reading expression toolbox, error was : ' + response.status + ' - ' + response.statusText);
			}
		}).catch(function (error) {
			console.error('Error reading expression toolbox from ' + expressionToolboxURL);
		});
	},

	/**
	 * Reads the action toolbox from the CDN
	 * 
	 * @param {any} communityURL 
	 */
	loadActionToolbox(communityURL) {
		let actionToolboxURL = communityURL + '/action-toolbox.json';
		fetch(actionToolboxURL).then(function (response) {
			if (response.status === 200) {
				response.json().then(function (resultsObj) {
					AppDispatcher.dispatch(Immutable.fromJS({
						type: ToolboxConstants.ACTION_TOOLBOX_LOAD,
						actionToolbox: resultsObj
					}));
				}).catch(function () {
					console.error('Error reading action toolbox, invalid JSON');
				});
			} else {
				console.error('Error reading action toolbox, error was : ' + response.status + ' - ' + response.statusText);
			}
		}).catch(function (error) {
			console.error('Error reading action toolbox from ' + actionToolboxURL);
		});
	},

	/**
	 * Reads the visibility toolbox from the CDN
	 * 
	 * @param {any} communityURL 
	 */
	loadVisibilityToolbox(communityURL) {
		let visibilityToolboxURL = communityURL + '/visibility-toolbox.json';
		fetch(visibilityToolboxURL).then(function (response) {
			if (response.status === 200) {
				response.json().then(function (resultsObj) {
					AppDispatcher.dispatch(Immutable.fromJS({
						type: ToolboxConstants.VISIBILITY_TOOLBOX_LOAD,
						visibilityToolbox: resultsObj
					}));
				}).catch(function () {
					console.error('Error reading visibility toolbox, invalid JSON');
				});
			} else {
				console.error('Error reading visibility toolbox, error was : ' + response.status + ' - ' + response.statusText);
			}
		}).catch(function (error) {
			console.error('Error reading visibility toolbox from ' + visibilityToolboxURL);
		});
	}
};

export default ToolboxActions;