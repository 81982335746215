/* global citDev */
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
// import { withSize } from 'react-sizeme'
import PropTypes from 'prop-types';
import {Container} from 'flux/utils';
import FieldActions from '../actions/field-actions';
import PageActions from '../actions/page-actions';
// import LocalContextActions from '../actions/local-context-actions';
import RecordActions from '../actions/record-actions';
import AdminSettingsStore from '../stores/admin-settings-store';
import RenderStore from '../stores/render-store';
import ContextStore from '../stores/context-store';
import FieldModesStore from '../stores/field-modes-store';
import FieldSettingsStore from '../stores/field-settings-store';
import FieldStore from '../stores/field-store';
import FieldTypeStore from '../stores/field-type-store';
import LocalContextStore from '../stores/local-context-store';
import RecordStore from '../stores/record-store';
import BrowserStorageActions from '../actions/browser-storage-actions';
import BrowserStorageStore from '../stores/browser-storage-store';
import ActionProcessor from '../utils/action-processor';
// import PinsContainer from './pins/pins-container';
import ErrorField from './error-field.react';
import ObjectUtils from '../utils/object-utils';
import FieldComponents from '../utils/field-components';
import ErrorBoundary from './error-boundary.react';

/**
 * Container to field type wrapper
 *
 * @class FieldTypeWrapperContainer
 * @extends {Component}
 */
class FieldContainer extends Component {
	/**
	 * Creates an instance of FieldTypeWrapperContainer.
	 *
	 * @param {Object} props
	 * @constructor
	 *
	 * @memberOf FieldTypeWrapperContainer
	 */
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onSettingChange = this.onSettingChange.bind(this);
		this.onSettingChangeBound = this.onSettingChange.bind(this, props.fieldId);
		this.getAutomation = this.getAutomation.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.calculateExpressions = this.calculateExpressions.bind(this);
		this.state = {};

		/* Refs */
		this.fieldComponent = null;

		//Sets the Node to Focus on via Refs
		this.setFieldComponent = (node) => {
			this.fieldComponent = node;
		};
	}

	

	/**
   * @static getStores - Loads the Stores to watch
   *
   * @returns {array}
   */
	static getStores() {
		return [FieldSettingsStore, FieldModesStore, RecordStore, ContextStore, LocalContextStore, BrowserStorageStore, AdminSettingsStore, RenderStore];
	}
	/**
	 * @static calculateState - processes state for render
	 *
	 * @param  {object} prevState
	 * @param  {object} props
	 * @return {object}
	 */
	static calculateState(prevState, props) {
		// Generate a permanent Id for the rendering lifecycle of each field.
		// Check prevState, then props, then generate one if we don't find one.
		let renderId = props.renderId;
		if(!renderId) {
			console.warn('Render ID not Set in Field Container');
			renderId = '';
		}

		let fieldId = props.fieldId;
		let visibilityOverlayIsActive = props.visibilityOverlayIsActive;
		let resizerIsActive = props.resizerIsActive;
		let dataTableSchemaName = '';
		let dataRecordId = '';
		let lastRefresh = RenderStore.getLastRefresh(renderId);
		let disabled = RenderStore.getIsDisabled(renderId);

		// Get the time of the last validation failure
		let validationLastFailed = RenderStore.getValidationLastFailed(props.renderId);
		let parentValidationLastFailed = props.renderParentId ?  RenderStore.getValidationLastFailed(props.renderParentId) : undefined;
		let propsValidationLastFailed = props.validationLastFailed;

		// Get the most recent validation failure if somehow we have a nested grid situation going on with multiple failures
		if(validationLastFailed || parentValidationLastFailed || propsValidationLastFailed) {
			let max = validationLastFailed || 0;
			if(parentValidationLastFailed) {
				max = Math.max(max, parentValidationLastFailed);
			}
			if(propsValidationLastFailed) {
				max = Math.max(max, propsValidationLastFailed);
			}
			validationLastFailed = max;
		}

		let renderValues = FieldComponents.render.getRecordData(props.renderId);
		dataTableSchemaName = renderValues.dataTableSchemaName;
		dataRecordId = renderValues.dataRecordId;

		if(props.overrideRecord) {
			dataTableSchemaName = props.dataTableSchemaName;
			dataRecordId = props.dataRecordId;
		}

		let installationData = ContextStore.getInstallationData();
		let parentComponentId = props.parentRecordId;
		let parentComponentType = props.parentTableSchemaName;
		let fieldSchemaName = props.fieldSchemaName;
		let value = dataRecordId ? 
			RecordStore.getValueByFieldSchemaName(dataTableSchemaName, dataRecordId, fieldSchemaName) : 
			BrowserStorageStore.getValueByFieldSchemaName(fieldSchemaName);
		let fieldType = '';
		let fieldObj = FieldStore.get(fieldId);
		if (fieldObj) {
			fieldType = fieldObj.fieldType;
		}

		let fieldDataLoaded = dataRecordId ? false : true;
		if(!fieldDataLoaded) {
			let recordData = RecordStore.getRecord(dataTableSchemaName, dataRecordId);
			// If the field has an entry, even if the value is null, it will be truthy here
			if(recordData && recordData[fieldSchemaName]) {
				fieldDataLoaded = true;
			}
		}

		let componentName = '',
			currentMode = '',
			labelPosition = '',
			fieldLabel = '',
			descriptiveText = '',
			descriptiveTextDisplay = '',
			descriptiveTextPosition = '',
			descriptiveTextTooltipIcon = '';
		let mode = FieldModesStore.getMode(renderId);
		let fieldSettingsObj = {};
		if(props.attachmentId) {
			fieldSettingsObj = FieldSettingsStore.getSettingsFromAttachmentId(props.attachmentId, fieldId, parentComponentId);
		 }else {
			fieldSettingsObj = FieldSettingsStore.getSettings(fieldId, parentComponentId);
		 }
		if(fieldSettingsObj) {
			labelPosition = fieldSettingsObj.labelPosition;
			fieldLabel = fieldSettingsObj.fieldLabel;
			descriptiveText = fieldSettingsObj.descriptiveText;
			descriptiveTextPosition = fieldSettingsObj.descriptiveTextPosition;
			descriptiveTextDisplay = fieldSettingsObj.descriptiveTextDisplay;
			descriptiveTextTooltipIcon = fieldSettingsObj.descriptiveTextTooltipIcon;

			// Determine which mode/variant to render
			if(mode) {
				currentMode = mode ? mode.currentMode : undefined;
				if(!currentMode && (visibilityOverlayIsActive || resizerIsActive)) {
					currentMode = 'view';
				}

				// Get the selected variant for the mode in question.
				componentName = fieldSettingsObj[currentMode+'Variant'];
				
				if(!componentName || 
					!citDev[componentName]){ //If variant Component no longer exists 
					
					// Get the default variant for the mode in question
					componentName = FieldStore.getDefaultVariantComponentName(fieldId, currentMode, fieldType);

					if(!componentName) {
						// In add or edit mode, at this point, we need to look for the view variant instead of the edit variant.
						if(currentMode !== 'view') {
							// Try the one that is set...
							componentName = fieldSettingsObj['viewVariant'];

							// Lookup the default component name for this mode.
							if(!componentName) {
								componentName = FieldStore.getDefaultVariantComponentName(fieldId, 'view', fieldType);
							}
						}
					}
				}
			}
		} else {
			fieldSettingsObj = {};
		}
 
		// Automation - Global
		let automation = {
			'onClick': '',
			'onMouseOver': '',
			'onMouseOut': '',
			'onFocus': '',
			'onBlur': '', 
			'onBlurChange': ''
		};

		
		// Process expression props
		// look up the settings from the field type
		let fieldTypeObj = FieldTypeStore.get(fieldType);

		let dataType = null;
		let partCount = 0;
		if(fieldTypeObj) {
			let customActionTriggers = fieldTypeObj.customActionTriggers ? ObjectUtils.getObjFromJSON(fieldTypeObj.customActionTriggers) : [];
			//Add them to the list of Automation Obj, so it is added to the field Props 
			customActionTriggers.forEach(customTrigger => {
				automation[customTrigger.codeName] = '';
			});

			let fieldTypeSettings = fieldTypeObj.settings;
			if(Array.isArray(fieldTypeSettings)) {
				fieldTypeSettings.forEach(fieldTypeSetting => {
					let fieldTypeSettingObj = FieldStore.get(fieldTypeSetting['recordId']);
					if (fieldTypeSettingObj) {
						let settingFieldSchemaName = fieldTypeSettingObj.fieldSchemaName;
						// Expression Field Type
						if(fieldTypeSettingObj.fieldType === 'd7183192-d4d0-42e9-a1f6-78f3984cef8c' && 
							fieldSettingsObj[settingFieldSchemaName]) {
							let value = LocalContextStore.getValue(renderId, settingFieldSchemaName);
							// If we have a value, just use it. We'll deal with any recalculation in componentDidUpdate
							if(typeof value !== 'undefined') {
								fieldSettingsObj[settingFieldSchemaName + 'Result'] = value;
							}
						}
					}
				});
			}

			dataType = fieldTypeObj.dataType;
			partCount = fieldTypeObj.parts ? fieldTypeObj.parts.length : 0;
			
		}
		
		//set the parentRecordId and parentTableSchemaName for the child to this field
		fieldSettingsObj.fieldId = fieldId;
		fieldSettingsObj.parentComponentId = parentComponentId;
		fieldSettingsObj.parentComponentType = parentComponentType;
		//parentRecordId and parentTableSchemaName are being depricated so we need to set
		//them in addition to the new parentComponentId and parentComponentType
		fieldSettingsObj.parentRecordId = parentComponentId;
		fieldSettingsObj.parentTableSchemaName = parentComponentType;
		fieldSettingsObj.lastRefresh = lastRefresh;

		// Pass along any validation failures
		fieldSettingsObj.validationLastFailed = validationLastFailed;

		// Pass along whether this field's data is still loading or not
		fieldSettingsObj.fieldDataLoaded = fieldDataLoaded;

		let updatedState =  {
			'value': value,
			'componentName': componentName,
			'componentProps': JSON.stringify(fieldSettingsObj),
			'labelPosition': labelPosition,
			'currentMode': currentMode,
			'fieldType': fieldType,
			'fieldId': fieldId,
			'fieldLabel': fieldLabel,
			'dataRecordId': dataRecordId,
			'dataTableSchemaName': dataTableSchemaName,
			'installationId': installationData.installationId,
			'fieldDataType': dataType,
			'fieldPartCount': partCount,
			// 'visibilityOverlayIsActive': AdminSettingsStore.getIsOverlayActive('visibility'),
			'visibilityOverlayIsActive': visibilityOverlayIsActive,
			'lastRefresh': lastRefresh,
			'disabled': disabled,
			'fieldDataLoaded': fieldDataLoaded,
			'descriptiveText': descriptiveText,
			'descriptiveTextDisplay': descriptiveTextDisplay,
			'descriptiveTextPosition': descriptiveTextPosition,
			'descriptiveTextTooltipIcon': descriptiveTextTooltipIcon
		};

		if (!prevState || !prevState.automation || prevState.fieldType !== updatedState.fieldType) {
			updatedState.automation = automation;
		}

		return updatedState;
	}

	/**
	 * Processes an Action when an event fires
	 * 
	 * @param {string} installationId 
	 * @param {string} dataTableSchemaName 
	 * @param {string} dataRecordId 
	 * @param {string} action 
	 * @param {boolean} preventDefault 
	 * @param {string} actionRecordId
	 * @param {string} actionTableSchemaName
	 * @param {string} actionParentRecordId
	 * @param {string} actionParentTableSchemaName
	 * @param {string} hookId
	 * @param {Object} event 
	 * @param {any} runTimeVariables
	 * 
	 * @memberof FieldContainer
	 */
	onActionProcess(installationId, dataTableSchemaName, 
		dataRecordId, renderId, action, preventDefault, 
		actionComponentId, actionComponentType, 
		actionParentComponentId, actionParentComponentType, hookId, event, runTimeVariables) {
		if (action) {
			if (event && preventDefault) {
				event.preventDefault();
			}

			return ActionProcessor.processAction({
				'installationId': installationId,
				'tableSchemaName': dataTableSchemaName,
				'actionRecordId': actionComponentId,
				'actionTableSchemaName': actionComponentType,
				'actionParentRecordId': actionParentComponentId,
				'actionParentTableSchemaName': actionParentComponentType,
				'hookId': hookId,
				'recordId': dataRecordId,
				'renderId': renderId,
				'runTimeVariables': runTimeVariables,
				'action': action}).catch(error => {
					console.warn('Action Processing Error: ' + (error.message ? error.message : error));
				});
		} else {
			return Promise.resolve({'message': 'Complete', 'cookies': []});
		}

	}
	/**
	 * Handler of component changes
	 *
	 * @params {Object} event bubbled from onChange event
	 *
	 * @memberOf FieldContainer
	 */
	onChange(event) {
		if (this.state.dataRecordId) {
			let fieldValues = {};
			fieldValues[this.props.fieldSchemaName] = event.target.value;
			RecordActions.updateRecord(this.state.dataTableSchemaName,
				this.state.dataRecordId, fieldValues);
		} else {
			BrowserStorageActions.setValue(this.props.fieldSchemaName, event.target.value)
		}
	}
	/**
	 * Handler of component setting changes
	 *
	 * @params {string} settingSchemaName What setting to change
	 * @params {string} value What value to set it to
	 *
	 */
	onSettingChange(fieldId, settingSchemaName, value) {
		let localOrGlobal = FieldSettingsStore.getSettingSourceFromAttachmentId(this.props.attachmentId, this.props.parentRecordId);
		if(localOrGlobal === 'g') {
			FieldActions.pushSettingToStore(fieldId, settingSchemaName, value);
		} else if (this.props.parentTableSchemaName === 'field') {
			FieldActions.pushChildConfigurationToStore(this.props.parentRecordId, this.props.attachmentId || this.props.fieldId, settingSchemaName, value);
		} else if (this.props.parentTableSchemaName === 'page') {
			PageActions.pushChildConfigurationToStore(this.props.parentRecordId, this.props.attachmentId || this.props.fieldId, settingSchemaName, value);
		} else {
			console.warn('Tried to push setting to store but function doesn\'t support parent component type %s', this.props.parentTableSchemaName);
		}
	}
	
	/**
	 * Get an object that holds the automation for this field
	 * 
	 * @param {object} fieldSettings 
	 * @returns 
	 * @memberof FieldContainer
	 */
	getAutomation(fieldSettings) {
		let automation = this.state.automation;

		let actionJSON = fieldSettings['automation-onClick'];
		if(actionJSON){
			try {
				let actionObj = JSON.parse(actionJSON);
				automation.onClick = actionObj ? actionObj.js : '';
			} catch (e) {
				console.warn('Error parsing action JSON: ', actionJSON);
				console.warn('Error was', e);
			}
		};

		Object.keys(automation).forEach(trigger => {
			if(fieldSettings['automation-' + trigger]) {
				try {
					let triggerObj = JSON.parse(fieldSettings['automation-' + trigger]);
					if(triggerObj) {
						automation[trigger] = triggerObj.js;
					}
				} catch (e) {
					console.warn('Unable to JSON parse: ', fieldSettings['automation-' + trigger]);
				}
				}
		});

		// If the resizer is active, then dont run the onClick actions!
		if(this.props && this.props.resizerIsActive) {
			automation['onClick'] = '';
		}
		return automation;
	}

	componentDidMount() {
		setTimeout(() => {
			this.calculateExpressions();
		}, 0);
	}

	calculateExpressions() {
		let {fieldType, componentProps} = this.state;
		let {
			renderId,
			dataRecordId,
			// dataTableSchemaName
		} = this.props;
		let renderObj = RenderStore.get(renderId);
		if(renderObj && renderObj.dataRecordId !== dataRecordId) {
			// Fixes an issue with expressions getting run with the wrong data record IDs. Part of the solution for 30474 - expressions not getting updating value
			dataRecordId = renderObj.dataRecordId;
			// dataTableSchemaName = renderObj.dataTableSchemaName;
		}
		let fieldTypeObj = FieldTypeStore.get(fieldType);
		let fieldSettingsObj = componentProps ? ObjectUtils.getObjFromJSON(componentProps) : {};
		if(fieldTypeObj) {
			let fieldTypeSettings = fieldTypeObj.settings;
			let expressionSettings = {};
			if(Array.isArray(fieldTypeSettings)) {
				fieldTypeSettings.forEach(fieldTypeSetting => {
					let fieldTypeSettingObj = FieldStore.get(fieldTypeSetting['recordId']);
					if (fieldTypeSettingObj) {
						let settingFieldSchemaName = fieldTypeSettingObj.fieldSchemaName;
						// Expression Field Type
						if(fieldTypeSettingObj.fieldType === 'd7183192-d4d0-42e9-a1f6-78f3984cef8c' && 
							fieldSettingsObj[settingFieldSchemaName]) {
							expressionSettings[settingFieldSchemaName] = fieldSettingsObj[settingFieldSchemaName];
						}
					}
				});
			}
			if(Object.keys(expressionSettings).length) {
				// LocalContextActions.processLocalContext(renderId, dataRecordId, dataTableSchemaName, expressionSettings);
				if(this.props.recalculateHeight) {
					this.props.recalculateHeight();
				}
				if(this.props.recalculateParentHeight) {
					this.props.recalculateParentHeight();
				}
			}
		}

	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.fieldId !== this.props.fieldId || !this.onSettingChangeBound) {
			this.onSettingChangeBound = this.onSettingChange.bind(this, this.props.fieldId);
		}

		// if(this.props.recalculateHeight && (!prevProps.size || !prevProps.size || (prevProps.size && prevProps.size.height && this.props.size && this.props.size.height && prevProps.size.height !== this.props.size.height))) {
		// 	this.props.recalculateHeight();
		// }

		// @TODO: Also run this if the actual setting values change. (Unless we should just update lastRefresh on our fields when we update the field store?)
		// Refresh if the data record ID changes or if the last refresh date updated
		if(
			!prevState ||
			!prevProps ||
			(prevProps.dataRecordId !== this.props.dataRecordId) ||
			(!prevState.lastRefresh && this.state.lastRefresh) || prevState.lastRefresh < this.state.lastRefresh ||
			prevProps.fieldId !== this.props.fieldId
		) {
			// Recalculate expressions if our last refresh changed
			setTimeout(() => {
				this.calculateExpressions();
			}, 0);
		}
	}

	/**
	 * Programmatically Adding z-Index Style
	 */
	onFocus(){
		// Does this field Require Overflow? 
		if(this.props.contentShouldOverflow && this.fieldComponent){
			//Add Style to zindex Item
			this.fieldComponent.style.zIndex = 3;
		}
	}
	/**
	 * Programmatically Removing z-index Style
	 */
	onBlur() {
		// Does this field Require Overflow? 
		if(this.props.contentShouldOverflow && this.fieldComponent){
			//Remove zIndex
			this.fieldComponent.style.zIndex = 1;
		}
	}
	/**
	 * Render the wrapper container
	 *
	 * @returns React
	 *
	 * @memberOf FieldTypeWrapperContainer
	 */
	 render() {
		let {componentProps, componentName, fieldId, fieldLabel, value, 
			labelPosition, currentMode, dataTableSchemaName, dataRecordId, 
			fieldDataType, fieldPartCount, disabled,
			descriptiveText, descriptiveTextDisplay, descriptiveTextTooltipIcon, descriptiveTextPosition} = this.state;

		let ReactToolTip = window.ReactToolTip;

		let renderId = this.props.renderId;
		let labelDataLayout = this.props.labelDataLayout;
		componentProps = JSON.parse(componentProps);
		let {height, width, size} = this.props;
		if(!size && (height || width)) {
			size = {height, width};
		}

		// @TODO: Backlog: go through all the components and standardize
		// how they get their heights and widths so that
		// we don't have to go through this nonsense
		componentProps.height = height;
		componentProps.clientHeight = height;
		componentProps.width = width;
		componentProps.clientWidth = width;
		componentProps.size = size;

		componentProps.visibilityOverlayIsActive = this.state.visibilityOverlayIsActive;

		// Include the TSN and Record Id to be passed to the child, so it can 
		// use it in its own record lookups, or to pass to it's children
		componentProps.dataTableSchemaName = dataTableSchemaName;
		componentProps.dataRecordId = dataRecordId;

		let automation = this.getAutomation(componentProps);
		
			//overriding eventual props onChange and defaultValue
		componentProps.onChange = this.onChange;
		componentProps.value = null;
		if (value !== null) {
			if ((fieldDataType === 'multipart' || fieldDataType === 'file') && fieldPartCount && value.has('value')) {
				// They have on a few occasions managed to get value.value to be an empty string (ticket 22223 - "field value parse error")
				// so we now also check to ensure the value exists first
				try {
					let toParse = value.get('value');
					componentProps.value = toParse ? JSON.parse(toParse) : componentProps.value;
				} catch (error) {
					console.error('field value parse error', value.get('value'));
				}
			} else {
				componentProps.value = value.get('value');
			}
		}

		componentProps.onSettingChange = this.onSettingChangeBound || this.onSettingChange.bind(this, this.props.fieldId);

		if (value && value.error) {
			return <ErrorField message={value.error}/>
		}

		//Pass the Id to the component 
		componentProps.renderId = renderId;
		componentProps.renderParentId = this.props.renderParentId;

		Object.keys(automation).forEach(trigger => {
			if(automation[trigger]) {
				componentProps[trigger] = this.onActionProcess.bind(this, 
					this.state.installationId, this.state.dataTableSchemaName, 
					this.state.dataRecordId, renderId, automation[trigger], true,
					this.props.fieldId, 'field', 
					this.props.parentRecordId, this.props.parentTableSchemaName,
					trigger
				);
			}
		});

			// Determine label position
			let labelParentClass = '';
			let labelClass = 'fieldLabel ';
			let valueClass = 'fieldComponent p-0 ';
			let labelPositionClass = '';
			let componentClassName = 'form-group field field-mode-' + currentMode;
			switch(labelPosition){
			default:
			case 'top':
				labelClass += 'm-0';
				// valueClass += 'col-12';
				labelPositionClass = 'labelPositionTop';
			break;
			case 'left':
				{
					let arrangementInfo = labelDataLayout ? labelDataLayout : '6-6';
					let arrangementInfoArr = arrangementInfo.split('-');
					labelParentClass += 'col-' + arrangementInfoArr[0];
					valueClass += 'col-' + arrangementInfoArr[1];
					labelPositionClass = 'labelPositionLeft';
				break;
			}
			case 'hidden':
				labelClass += 'sr-only';
				valueClass += 'col-12';
				componentClassName += ' nolabel';
				labelPositionClass = 'labelPositionHidden';
			break;
			}

		let fieldObj = FieldStore.get(fieldId);
		if(fieldObj) {
			componentClassName += 
				' fieldType-' + fieldObj.fieldType + 
				' componentName-' + componentName + 
				' fieldId-' + fieldObj.recordId +
				' p-0';
		}

		if(this.props.resizerHeightType) {
			if(this.props.resizerHeightType === 'expand') {
				componentClassName += ' resizerHeight-Expand';
			} else if(this.props.resizerHeightType === 'fit') {
				componentClassName += ' resizerHeight-Fit';
			} else {
				componentClassName += ' resizerHeight-Fixed';
			}
		}

		if(componentProps && componentProps.validationLastFailed) {
			componentClassName += ' validation-failed';
		}

		let isRequired = (componentProps.requiredForSave === 'yes' ? true : false),
				labelSpanRequiredScreenReader = null;
		if(isRequired) {
			labelSpanRequiredScreenReader = <span className={"sr-only"}> Required</span>;
			labelClass += ' required';
		}

		// Descriptive Text
		let urlFontawesome = ContextStore.getUrlFontawesome();

		let descriptiveTextIconName = descriptiveTextTooltipIcon
			? descriptiveTextTooltipIcon + '.svg' 
			: "info.svg";

		let descriptiveTextIcon = <img src={urlFontawesome + "/" + descriptiveTextIconName} id="descriptive-text-icon" className={'descriptive-text-icon'} alt='' />
		
		// Make sure any html entered has opening and closing brackets
		// Without replacing, html comes over as a string using &lt; and &gt; instead of brackets
		descriptiveText = citDev.fieldComponents.UIUtils.sanitizeHtml(descriptiveText)
			.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

		// boolean to determine whether to show the Descriptive Text
		let showTooltipComponent = (descriptiveText !== 'undefined' && descriptiveText !== '')
			&& (descriptiveTextDisplay === 'always'
			|| (currentMode === 'edit' && (descriptiveTextDisplay === 'editOnly' || descriptiveTextDisplay === ''))
			|| (currentMode === 'view' && descriptiveTextDisplay === 'readOnly'));

		let reactTooltipComponent = showTooltipComponent
		
			&& <div>
					<a data-html={true} data-tip={descriptiveText} data-for={'react-tooltip-' + renderId} 
						className={'descriptive-text-link ' 
						+ (descriptiveTextPosition === 'rightOfInput' ? 'descriptive-text-right-of-input' : '')}
					>
						<div className="descriptive-text-icon-circle text-center">
							{descriptiveTextIcon}
						</div>
					</a>
					{ReactDOM.createPortal(
					<ReactToolTip 
						id={'react-tooltip-' + renderId}
						effect='solid' backgroundColor='#2A3439'
						overridePosition={ (
							{ left, top },
							currentEvent, currentTarget, node) => {
								const targetRect = currentTarget.getBoundingClientRect();

								if(targetRect) {
									let targetTop, targetLeft, targetWidth, doc;
									targetTop = targetRect.top;
									targetLeft = targetRect.left;
									targetWidth = currentTarget.clientWidth;
									doc = document.documentElement;

									left = Math.min(doc.clientWidth - node.clientWidth, left);

									if(top <= 0) {
										// reposition the top value if it's off screen (Ticket 26328)
										top = targetTop;

										if(left < 0) {
											/* this corrects the placement of the tooltip by checking if the original
											   placement is off the LEFT side of the screen
											   if it is, remove the old CSS class that places the arrow
											   if the class doesn't exist, it will be ignored - no error thrown! */
											node.classList.remove('place-top', 'place-left', 'place-bottom', 'place-right');
											
											// display the left arrow as we are positioning the tooltip to the right of the anchor point
											node.classList.add('descriptive-text-place-right');
											
											// Set the new left value
											left = targetLeft + (targetWidth * 1.5);
										}
										else if((left + node.clientWidth + (targetWidth * 1.5)) >= doc.clientWidth) {
											/* this corrects the placement of the tooltip by checking if the original
											   placement is off the RIGHT side of the screen
											   if it is, remove the old CSS classes that places the arrow */
											node.classList.remove('place-top', 'place-left', 'place-bottom', 'place-right');
											node.classList.add('descriptive-text-place-left');
											
											// Set the new left value
											left = targetLeft - node.clientWidth - (targetWidth / 1.5)
										}
									}
									else {
										top = Math.min(doc.clientHeight - node.clientHeight, top);
										left = Math.max(0, left);
										top = Math.max(0, top);
									}
								}

								return { top, left }
							}
						}
					dangerouslySetInnerHTML={{ __html: descriptiveText || '' }}></ReactToolTip>, document.getElementById('root'))}
				</div>

		// Use current mode to determine if label or div is needed
		let labelOutput;
		if(currentMode === 'edit'){
			labelOutput =  <label style={{flex: 1}} ref={label => this.label = label} id={'label-' + this.props.renderId} className={labelClass}>{fieldLabel}{labelSpanRequiredScreenReader}</label>
		} else{
			labelOutput =  <div style={{flex: 1}} ref={label => this.label = label} id={'label-' + this.props.renderId} className={labelClass}>{fieldLabel}{labelSpanRequiredScreenReader}</div>
		}

		componentProps.id = renderId;

		if(this.props.height) {
			componentProps.height = this.props.height;
		}

		if(this.props.width) {
			componentProps.width = this.props.width;
		}

		if (disabled === true) {
			componentProps.disabled = true;
		}

		if(this.props.onDragStart) {
			componentProps.onDragStart = this.props.onDragStart;
		}

		if(this.props.onDragStop) {
			componentProps.onDragStop = this.props.onDragStop;
		}

		if(this.props.recalculateHeight) {
			componentProps.recalculateHeight = this.props.recalculateHeight;
		}

		if(this.props.recalculateParentHeight) {
			componentProps.recalculateParentHeight = this.props.recalculateParentHeight;
		}

		if(this.props.omitSpacer) {
			componentProps.omitSpacer = this.props.omitSpacer;
		}

		// @TODO - Create a default component to render in these cases that asks the Cit Dev to choose a new Variant, and that their
		// old variant no longer exists.
		let componentElement = null;
		if(!citDev[componentName]) {
			if(componentName) {
				console.error('Attempting to render variant component that does not exist: ', componentName);
			}
		} else {
			componentElement = React.createElement(citDev[componentName], componentProps, null)
		}

		const mainElement = descriptiveTextPosition === 'rightOfInput' ? <div id={'right-of-input-wrapper'} style={{display: 'flex'}}>{componentElement} {reactTooltipComponent}</div> : componentElement;

		return(
			<ErrorBoundary>
				{/* <PinsContainer
					attachmentId={this.props.attachmentId}
					renderId={renderId}
					recordId={fieldId}
					tableSchemaName="field"
					parentRecordId={this.props.parentRecordId}
					parentTableSchemaName={this.props.parentTableSchemaName}
					gridKey={this.props.gridKey}
					fieldType={this.state.fieldType}
				/> */}
				<div id={'renders-' + renderId} style={{ display: 'flex', alignItems: 'top', position: 'relative' }} className={componentClassName} data-grid={this.props.dataGrid} ref={div => this.div = div /* Used for autofit height calculation */}>
					<div className={'col-12 fieldLabelAndDataWrapper ' + labelPositionClass}>
						<div className={labelParentClass} style={{ display: 'flex', maxWidth: '100%', position: 'relative' }}>
							{labelOutput}
							{descriptiveTextPosition === 'rightOfLabel' && reactTooltipComponent}
						</div>
						<div
							ref={this.setFieldComponent}
							onFocus={this.onFocus}
							onBlur={this.onBlur}
							className={valueClass}
						>
							{mainElement}
							{(descriptiveTextPosition === '' || descriptiveTextPosition === 'underInput') && showTooltipComponent && this.props.renderId && <small id={'description-' + this.props.renderId} className='form-text text-muted' dangerouslySetInnerHTML={{ __html: descriptiveText || '' }}></small>}
						</div>
					</div>
				</div>
			</ErrorBoundary>
		)
	 };
}

if ('development' === process.env.NODE_ENV) {
	FieldContainer.propTypes = {
		fieldId: PropTypes.string.isRequired
	};
}

FieldContainer.displayName = 'FieldContainer';

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(FieldContainer, {withProps: true});

// const SizedContainer = withSize({monitorHeight:true, refreshMode:'debounce', refreshRate: 128})(container);

export default container;

// export {SizedContainer};
