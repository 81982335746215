/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';

import { 
	AdminSettingsActions, 
	InterfaceActions, 
	TableActions 
} from '../../../actions';

import { 
	AdminSettingsStore, 
	FieldStore,
	RelationshipStore, 
	TableStore,
	ContextStore
} from '../../../stores';

import { 
	ObjectUtils,
	UIUtils,
	TableUtils
} from '../../../utils';

import AssistantTablesUtils from '../../../utils/assistant-tables';

class TableSettingsChooser extends Component {
	/**
	 * Creates instance of TableSettingsChooser
	 */
	constructor(props) {
		super(props);
		this._getSettingsList = this._getSettingsList.bind(this);
		this._onDeleteHandler = this._onDeleteHandler.bind(this)
		this._onResetHandler = this._onResetHandler.bind(this)
		this._onSaveHandler = this._onSaveHandler.bind(this)
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, FieldStore, TableStore];
	}

	/**
	 * Calculate the current state of this component
	 * @static
	 * @param {Object} prevState 
	 * @param {Object} prevState 
	 * @returns {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId = AdminSettingsStore.getRecordId();
		let tableSchemaName = 'table';
		let tableObj = TableStore.get(recordId) || {};
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let componentSettings = [];
		let isNew = tableObj.new;

		let tableSettings =
			[
				{ recordId: 'e97b9453-08e2-4ca8-94fb-36d8867f4fc4',
				  sortOrder: 0, required: true }, // Plural Name
				{ recordId: 'e3d1fd88-fa9d-4d56-a2ea-c4824d3104ef',
				  sortOrder: 1, required: true  }, // Singular Name
				{ recordId: 'e1ee07bc-36ff-4aaf-b5c1-8b840f4130f3',
				  sortOrder: 2 }, // Icon *
				{ recordId: 'dbcad96b-fcb2-4bfd-8432-60f691615fd3',
				  sortOrder: 3, required: true  }, // Technical Name
				{ recordId: '4da637de-90f8-4329-a8ee-91f98f86b12c',
				  sortOrder: 4  }, // Generate CRUD Interfaces
				{ recordId: '7cd9ca76-f62d-4022-acad-5ba3a674b514',
				  sortOrder: 5  }, // Role(s)
			];

			
		// If this table is NOT new, then add Fields and Relationships
		if(!isNew) {
			tableSettings.push({ recordId: 'ae4703f6-115d-463f-85ce-48557a8f9472',
				sortOrder: 6  }); // Audit Logging

			// If Audit Logging is on, then add the field selector
			if(tableObj.auditLog === 'true') {
				tableSettings.push({ recordId: '64dc7521-b93c-4ecd-831d-4dab540e924b',
					sortOrder: 7  }); // Audit Log - Title Field Selector *
			}
		}

		let hasMissingRequiredSettings = false;

		tableSettings.forEach(tableSetting => {
			let settingId = tableSetting.recordId;
			let settingObj = FieldStore.get(settingId) || {};
			let settingSchemaName = settingObj.fieldSchemaName;

			// Empty/No Value values.
			let valueObj = {};
			if (tableObj[settingSchemaName]) {
				valueObj.value = tableObj[settingSchemaName];
			}

			// Check to see if this setting is required, and has no value
			if(tableSetting.required && (!valueObj.value || valueObj.length === 0)) {
				hasMissingRequiredSettings = true;
			}

			// Audit Field Selector - needs the table schema name
			if(settingId === '64dc7521-b93c-4ecd-831d-4dab540e924b') {
				settingObj.selectFromTableSchemaName = tableObj.tableSchemaName;
			}

			componentSettings.push({
				// Basics
				fieldId: settingId,
				fieldObj: settingObj,

				// Label, Value and Sorting..
				label: settingObj.fieldLabel,
				valueObj: valueObj,
				sortOrder: tableSetting.sortOrder,

				// Used for styling
				settingSelected: (selectedSetting === settingSchemaName),
				required: tableSetting.required
			});
		});

		// Sort the results by setting name
		componentSettings.sort(function (a, b) {
			if (a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			return 1;
		});

		return {
			componentSettings: componentSettings,
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			settingsHidden: AdminSettingsStore.getSettingsListHidden(),
			hasMissingRequiredSettings: hasMissingRequiredSettings,
			isNew: isNew
		}
	}

	/**
	 * Render the component
	 * @returns JSX
	 */
	render() {
		let { componentSettings, hasMissingRequiredSettings } = this.state;

		if (!componentSettings.length) {
			return (
				<div key="settings" className='settings-list-wrapper'>
					<div style={{ marginTop: '8px' }} className='no-settings-found'>No Settings Found</div>
				</div>
			);
		}

		let settingsList = this._getSettingsList(componentSettings); // component settings

		/* The ids for these buttons are set in a specific way, so that Ctrl-S and Ctrl-R (UIUtils.onkeyDown method) can find these buttons */
		let buttons = [
			<button id={'tableSave'} key="submit" className="btn btn-primary btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Save" title="Save (Ctrl-S)" onClick={this._onSaveHandler}>Save</button>,
			<button id={'tableReset'} key="reset" className="btn btn-warning btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Reset" onClick={this._onResetHandler}>Reset</button>,
			<button key="delete" className="btn btn-danger btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Delete" onClick={this._onDeleteHandler}>Delete</button>
		];

		let requiredMarker = null;
		if(hasMissingRequiredSettings) {
			requiredMarker = <div className="required-marker mx-4 d-flex"><i className="fa fa-circle d-flex align-items-center" aria-hidden="true"></i> <h4 className="ml-2">Required</h4></div>;
		}

		if(this.props.disabledRetailRestrictions) { 
			buttons = [<button 
				key="submit" 
				className="btn btn-success btn-lg setting-list-button mr-3 ml-1" 
				aria-label="Increase allowed Tables"
				style={{ width: '100%'}}
				onClick={this.props.onUpgradeClick}>
				Increase allowed Tables
			</button>]
		}


		let componentIcon = ContextStore.getUrlFontawesome() + "/database.svg";
		return [
			<div key="settings" className='settings-list-wrapper'>
				<ul key="list" className="nav flex-column">
					{settingsList && settingsList.length
						? 	<li className="settings-list-header">
								<div className="d-flex align-items-center">
									<img height="22" width="22" className="mr-2" src={componentIcon} alt="" />
									<h3>Table Settings</h3>
								</div>
							</li>
						: null
					}
					{settingsList && settingsList.length
						? settingsList
						: <div style={{ marginTop: 0 }} className='no-settings-found'>No Settings Found</div>
					}
					{ requiredMarker }
				</ul>
			</div>,
			<div key="buttons" className="btn-wrapper appearance-btn-wrapper">
				{buttons}
			</div>
		];
	}

	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @returns { array } array of <li> settings elements
	 */
	_getSettingsList(settingsArr) {

		let { fieldType } = this.state;

		// iterate over the settings and return an array of list items
		return settingsArr.map(setting => {
			let labelClassNames = 'setting-label';
			if (setting.settingSelected) {
				labelClassNames += ' selected';
			}

			let componentName = '';
			if(setting && setting.fieldObj && setting.fieldObj.viewVariant) {
				componentName = setting.fieldObj.viewVariant;
			} else {
				componentName = FieldStore.getDefaultVariantComponentName(
					setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
			}

			let componentProps = setting.fieldObj;
			componentProps.value = setting.valueObj.value;
			componentProps.fieldType = fieldType;

			// These props will only be used by the fake table setting
			componentProps.customExpressionResult = setting.customExpressionResult;
			componentProps.renderAsHTML = setting.renderAsHTML;

			let valueDisplay = <h5 className={`settings-list-value ${componentName}`}>
					{(componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null)}
				</h5>;

			let requiredMarker = null;
			if(setting.required && (!setting.valueObj.value || setting.valueObj.value.length === 0)) {
				requiredMarker = <div style={{ left: '-14px', top: '2px' }} className="required-marker position-absolute"><i className="fa fa-circle fa-1" aria-hidden="true"></i></div>;
			}

			return (<li key={setting.fieldId} className={"nav-item d-flex flex-column justify-content-center " + (setting.settingSelected ? 'setting-selected' : '')}>
				<div className="nav-link" onClick={this._onSettingClick.bind(this, setting.fieldId)}>
					{/* Temporarily removed the following div */}
					{/* <div className={"setting-icon " + iconClassNames} /> */}
					{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
					{/* Add this back in when the setting-icon is used again */}
					<div className="d-flex setting-text-container">
						<div className="w-50 setting-label-container">
							<div className={labelClassNames}>{requiredMarker}<h4>{setting.label}</h4></div>
							<div className="setting-pattern-list">{setting.mostRecentPattern}</div>
						</div>
						<div className="w-50 d-flex justify-content-end setting-value-container">
							<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
								{valueDisplay}
							</div>
							{setting.valueObj.source ?
								<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}>
									{setting.valueObj.source}
								</div>
								: null
							}
						</div>
					</div>
				</div>
			</li>);
		});
	}

	/**
	 * _onDeleteHandler - Deletes the object from the store and the database.
	 *
	 * @param  {object} event
	 */
	_onDeleteHandler(event) {
		event.preventDefault();
		let { recordId, isNew } = this.state;

		let confirmRemove = confirm('Are you sure you want to delete this Table?');
		if(confirmRemove) {
			
			// // Push to database
			if(isNew) {
				// Display notification to user
				let id = InterfaceActions.stickyNotification({ 'level': 'warning', 'message': 'Deleting Table...' });
				InterfaceActions.clearStickyNotification(id);
				UIUtils.closeSettingsPanel();
				TableActions.deleteFromStore(recordId);
			} else {
				let tableObj = TableStore.get(recordId);
				TableUtils.deleteTableWithDependencies(tableObj.tableSchemaName)
					.then(() => {
						UIUtils.closeSettingsPanel();
						TableActions.deleteFromStore(recordId);
					})
					.catch(console.error);
			}
		}
	}

	/**
	 * _onResetHandler - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event
	 */
	_onResetHandler(event) {
		event.preventDefault();
		let { recordId } = this.state;
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Resetting Table...' });
		// Pull from database, therefore resetting it
		TableActions.pullFromDatabase(recordId);
	}

	/**
	 * _onSaveHandler - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	_onSaveHandler(event) {
		let { recordId } = this.state;
		let tableObj = TableStore.get(recordId);
		let valid = true;
		let targetSettingName = '';
		let targetSettingId = '';

		if(!tableObj.pluralName || tableObj.pluralName.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Plural Name is required for a Table.' });
			if(!targetSettingName) {
				targetSettingName = 'pluralName';
				targetSettingId = 'e97b9453-08e2-4ca8-94fb-36d8867f4fc4';
			}
		}
		if(!tableObj.singularName || tableObj.singularName.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Singular Name is required for a Table.' });
			if(!targetSettingName) {
				targetSettingName = 'singularName';
				targetSettingId = 'e3d1fd88-fa9d-4d56-a2ea-c4824d3104ef';
			}
		}
		if(!tableObj.tableSchemaName || tableObj.tableSchemaName.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Technical Name is required for a Table.' });
			if(!targetSettingName) {
				targetSettingName = 'tableSchemaName';
				targetSettingId = 'dbcad96b-fcb2-4bfd-8432-60f691615fd3';
			}
		} else {
			// Validate the technical name
			let tableSchemaName = tableObj.tableSchemaName;

			// starting with a-z or A-Z, and containing letters a-z, A-Z, numbers and _ characters
			// Thanks ChatGPT!!
			const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/;

			// A max of 60 characters
			if (tableSchemaName.length >= 60) {
				valid = false;
				InterfaceActions.notification({ 'level': 'error', 'message': 'Technical Name too long, must be a max of 60 characters.' });
				if(!targetSettingName) {
					targetSettingName = 'tableSchemaName';
					targetSettingId = 'dbcad96b-fcb2-4bfd-8432-60f691615fd3';
				}
			} else if(!regex.test(tableSchemaName)) {
				valid = false;
				InterfaceActions.notification({ 'level': 'error', 'message': 'Technical Name contains invalid characters.' });
				if(!targetSettingName) {
					targetSettingName = 'tableSchemaName';
					targetSettingId = 'dbcad96b-fcb2-4bfd-8432-60f691615fd3';
				}
			}

			// Make sure its unique
			let existingTables = TableStore.getAllArray();
			// If we found a matching table, and its NOT us...
			existingTables.forEach(existingTable => {
				if(existingTable.recordId && // If the table has a record ID...
					existingTable.recordId !== recordId && // and it does NOT match our new record ID...
					existingTable.tableSchemaName === tableSchemaName) { // but its Schema Name DOES match ours...
					valid = false;
					InterfaceActions.notification({ 'level': 'error', 'message': 'Another Table with this Technical Name already exists: ' + existingTable.pluralName});
					if(!targetSettingName) {
						targetSettingName = 'tableSchemaName';
						targetSettingId = 'dbcad96b-fcb2-4bfd-8432-60f691615fd3';
					}
				}
			});

			// If we found a matching relationship...
			let existingRelationship = RelationshipStore.getByRelationSchemaName(tableSchemaName);
			if(existingRelationship && existingRelationship.relationshipSchemaName) {
				valid = false;
				InterfaceActions.notification({ 'level': 'error', 'message': 'A Relationship with this Technical Name already exists: ' + existingRelationship.ltorLabel});
				if(!targetSettingName) {
					targetSettingName = 'tableSchemaName';
					targetSettingId = 'dbcad96b-fcb2-4bfd-8432-60f691615fd3';
				}
			}
		}
		if(!valid) {
			AdminSettingsActions.onSettingChange(targetSettingName, targetSettingId);
		}
		else {
			let tableObj = TableStore.get(recordId);

			// Read the crud interfaces value before we strip it.
			let isNew = tableObj.new;
			let crudObj = ObjectUtils.getObjFromJSON(tableObj.crudInterfaces);

			// Strip the values we shouldnt store.
			delete tableObj.new;
			delete tableObj.crudInterfaces;

			// Trim all of the values...
			Object.keys(tableObj).forEach(settingName => {
				tableObj[settingName] = (tableObj[settingName] ? tableObj[settingName].trim() : tableObj[settingName]);
			})

			// Set the message.
			let message = 'Creating Table...';
			if(!isNew) {
				message = 'Updating Table...';
			}
			let id = InterfaceActions.stickyNotification({ 'level': 'success', 'message': message });

			// Push to database - Will strip crudInterfaces
			TableActions.pushToDatabasePromise(tableObj).then(() => {
				if(tableObj) {
					// If this is a new table, update the permissions after creation
					let permissionPromise = Promise.resolve(true);
					if(isNew) {
						permissionPromise = AssistantTablesUtils.refreshPermissions();
					}
					permissionPromise.then(() => {
						if(crudObj['createShortText'] && crudObj['sampleRecord']) {
							AssistantTablesUtils.createTableFields(tableObj).then(response => {
								let fields = [];
								let fieldsbyTSN = FieldStore.getByTableSchemaName(tableObj.tableSchemaName);
								let roleFieldArr = fieldsbyTSN.filter(f => { 
									return f.roles && f.roles.includes('name'); 
								});
	
								// This array should include the new field, and any others with 'Name' set on them.
								if(roleFieldArr.length >= 1) {
									roleFieldArr.forEach(f => {
										fields.push({
											recordId: f.recordId,
											fieldType: f.fieldType,
											fieldSchemaName: f.fieldSchemaName,
											value: 'Sample Record'
										});
									})
								}
	
								return AssistantTablesUtils.generateSampleRecord(tableObj, fields)
							});
						} else if(crudObj['sampleRecord'] && !crudObj['createShortText']) {
							let fields = [];
							let roleFieldArr = [];
							let fieldsbyTSN = FieldStore.getByTableSchemaName(tableObj.tableSchemaName);
	
							// Find the fields with the Name role on it...
							roleFieldArr = fieldsbyTSN.filter(f => { 
								return f.roles && f.roles.includes('name'); 
							});
							
							//  If there aren't any, find the first short text fields with roles...
							if(roleFieldArr.length === 0) {
								roleFieldArr = fieldsbyTSN.filter(f => { 
									return f.roles && f.roles.length > 0 && f.fieldType === 'd965b6d9-8dd0-440c-a31c-f40bf72accea'; 
								});
							}
							
							// If there aren't any, find the short text fields in general...
							if(roleFieldArr.length === 0) {
								roleFieldArr = fieldsbyTSN.filter(f => { 
									return f.fieldType === 'd965b6d9-8dd0-440c-a31c-f40bf72accea'; 
								});
							}
							
							// If there aren't any.. then no fields.
							if(roleFieldArr.length === 0) {
								InterfaceActions.notification({ 'level': 'warning', 'message': 'Unable to create a record on a table with no Name fields, or Short Text Fields.' });
								// AssistantTablesUtils.generateSampleRecord(tableObj)
							} else {
								if(roleFieldArr.length >= 1) {
									roleFieldArr.forEach(f => {
										// Add the value to the field...
										fields.push(Object.assign(f, {value:'Sample Record'}));
									})
								}
								AssistantTablesUtils.generateSampleRecord(tableObj, fields);
							}
	
							// After the records is added - Reload recordsets?
						} else if(crudObj.createShortText) {
							AssistantTablesUtils.createTableFields(tableObj);
						}
						if(crudObj['searchBasic']) {
							AssistantTablesUtils.addBasicSearchPage(tableObj)
						}
						if(crudObj['searchAdvanced']) {
							AssistantTablesUtils.addAdvancedTableInterfaces(tableObj)
						}
					});
				}
				
				// Reset, now that we're no longer new!
				TableActions.pullFromDatabase(recordId);

				// Clear the Sticky about saving.
				InterfaceActions.clearStickyNotification(id);
			}).catch(error => {
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to update Table.' });
				console.error('Unable to update Table:', error);
			})
		}
	}


	/**
	 * Click on a Setting and update the admin settings store.
	 * 
	 * @param {string} settingFieldId
	 * @param {Object} event 
	 */
	_onSettingClick(settingFieldId, event) {
		event.preventDefault();

		let settingFieldObj = FieldStore.get(settingFieldId);
		let settingSchemaName = settingFieldObj.fieldSchemaName;

		// Expand the Settings Panel
		AdminSettingsActions.onSettingsListHideChange(false);
		if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
			AdminSettingsActions.onSettingChange('', '');
		} else {
			AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
		}
	}
}
const container = Container.create(TableSettingsChooser);
export default container;