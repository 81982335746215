import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Components
import FieldComponentsContainer from './engineering/field-components.react';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';

/**
 * Displays dashboard tabs
 */
class EngineeringDashboardTabs extends Component {
	/**
	 * Return the stores we're listening to for changes.
	 *
	 * @return {array}  description
	 */
	static getStores() {
		return [ AdminSettingsStore ];
	}

	/**
	 * Calculate our state anytime a store changes.
	 *
	 * @param  {object} prevState
	 * @return {object}
	 */
	static calculateState(prevState) {
		return {
			'selectedTab' : AdminSettingsStore.getActiveDashboardTab(),
		};
	}

	/**
	 * render the component
	 *
	 * @return {JSX}
	 */
	render() {
		let { 
			onTabSelect,
		} = this.props;
		let { 
			selectedTab,
		} = this.state;

		let activeTabComponent = null;
		switch(selectedTab) {
			default:
			case 'engineering-field-components': {
				activeTabComponent = <FieldComponentsContainer key="tab-content"/>;

				break;
			}
		}

		return ([
			<div className="cd-dashboard-tabs engineering container" key="tabs">
				<a href="#" onClick={() => onTabSelect('engineering-field-components')}>Field Components</a>
			</div>,
			activeTabComponent
		]);
	}
}
const container = Container.create(EngineeringDashboardTabs);
export default container;