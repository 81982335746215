import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import ConsumableCreditsConstants from '../constants/consumable-credits-constants';
import socketFetcher from '../utils/socket-fetcher';

const ConsumableCreditsAPIActions = {
	/**
	 * Gets the inial list of resouces for an installation
	 */
	getResources() {
		socketFetcher('gw/consumableCreditsList-v1', {}).then(function (data) {
				if(200 <= data.responseCode && data.responseCode < 300) {
					// If a 200 response is received
					let resources = data.response;
					Object.keys(resources).forEach(resource => {
						//If the resource is false on initial load try and consume to get
						//it to refresh
						if (!resources[resource]) {
							socketFetcher('gw/consumableCreditsConsume-v1', JSON.stringify({ 
								'resource': resource,
								'quantity': 1,
								'alwaysConsume': false}))
								.catch((error) => {
									console.error('Error Consuming Credits for '+resource, error);
								})
						}
					})
					AppDispatcher.dispatch(Immutable.fromJS({
						type: ConsumableCreditsConstants.CONSUMABLE_CREDIT_LOAD,
						resources: data.response,
					}));
				}  else {
					console.warn('Error getting consumable credits list. Response received was', data);
					AppDispatcher.dispatch(Immutable.fromJS({
						type: ConsumableCreditsConstants.CONSUME_CREDIT_LIST_ERROR,
						message: data.response
					}));
				}
		}).catch(error => {
			AppDispatcher.dispatch(Immutable.fromJS({
				type: ConsumableCreditsConstants.CONSUME_CREDIT_LIST_ERROR,
				message: error.message ? error.message : error.toString()
			}));
		});
	},

	/**
	 * Updates an resource from the socket emits
	 *
	 * @param {string} resource
	 * @param {boolean} creditsAvailable
	 */
	setStatus(resource, creditsAvailable) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: ConsumableCreditsConstants.SET_RESOURCE_STATUS,
			resource: resource,
			creditsAvailable: creditsAvailable
		}));
	}
};

export default ConsumableCreditsAPIActions;