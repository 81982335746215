import React, { Component } from 'react';
import { Container } from 'flux/utils';

//Actions
import AdminSettingsActions from '../../actions/admin-settings-actions';
import InterfaceActions from '../../actions/interface-actions';

// API's
import AuthenticationApi from '../../apis/authentication-api';

//Components
import ImpersonationControls from './footer/impersonation-controls.react';
import ResizerControls from './footer/resizer-controls.react';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import AuthenticationStore from '../../stores/authentication-store';
import ContextStore from '../../stores/context-store';

/**
 * Footer for Citizen Developer dashboard.
 */
class DashboardFooter extends Component {
	/**
	 * static - Get stores to watch
	 */
	static getStores() {
		return [ AdminSettingsStore, AuthenticationStore ];
	}
	/**
	 * static - description
	 *
	 * @param  {type} prevState description
	 * @param  {type} props     description
	 * @return {type}           description
	 */
	static calculateState(prevState, props) {

		// WHAT?!
		let toReturn = AdminSettingsStore.getState().toJS();

		return toReturn;
	}

	/**
	 * Click Handler for the logout button.
	 * 
	 * @param {object} event 
	 * @memberof MainFooter
	 */
   processLogout(event) {
		event.preventDefault();
		AuthenticationApi.logout(true).then(() => {
			// check whether left panel is open on logged out
			if (AdminSettingsStore.getIsLeftPanelOpen()) {
				// if it is then close the panel
				AdminSettingsActions.onLeftPanelChange(false);
			}
			// check whether right panel is open on logged out
			if (AdminSettingsStore.getIsRightPanelOpen()) {
				// if it is then close the panel
				AdminSettingsActions.onRightPanelChange(false);
			}
			// Get the current state 
			let currentState = AdminSettingsStore.getState().toJS();
			// check if there are active overlays;
			let activeOverlays = currentState.activeOverlays ? currentState.activeOverlays : [];
			if(activeOverlays.length) {
				// if overlays are active after logging out
				// then close all of them
				activeOverlays.forEach((overlay) => {
					AdminSettingsActions.onOverlayChange(overlay);
				});
			}
			// Do we need this?
		}).catch(error => {
			// Do we need this?
		});
	}

	/**
	 * Render the main footer.
	 * 
	 * @returns JSX
	 * @memberof MainFooter
	 */
	render() {
		if(AuthenticationStore.getHasDevTools()) {
			return this._renderWithTools();
		} else {
			return this._renderWithNoTools();
		}
	}

	/**
	 * Render the footer if you dont get tools.
	 * @returns JSX
	 * @todo Implement
	 */
	_renderWithNoTools() {
		let urlMedia = ContextStore.getUrlMedia();
		let output = (
			<footer id="main-footer" className="fixed-bottom">
				<div className="container-fluid">
					<div className="row">

						{/* USER STATUS */}
						<div id="user-status" className="col-5">
							<div className="row">
								<div style={{
									width: '60px',
									marginLeft: '5px'
								}}>
									<img src={urlMedia + '/citizen-developer.svg'} id="logo" alt="CitizenDeveloper" onClick={() => { InterfaceActions.replacePage({url: '/' + ContextStore.getDefaultHomePageId()}); }}/>
								</div>
								<div id="user-status__name">
									<div>Application made by <b>citizen developers</b>.</div>
									<div><a href="#" onClick={this.processLogout}>Logout</a></div>
								</div>
							</div>
						</div>
						{/* USER STATUS */}

						{/* OVERLAYS NAV */}
						<nav id="overlays-nav" className="col-7 disabledToolsMessage">
							Development tools can be enabled at <a href="https://platform.citizendeveloper.com" target="_blank">platform.citizendeveloper.com</a>.
							<br />
							Contact your account administrator to request access.
						</nav>
						{/* /OVERLAYS NAV */}
					</div>
				</div>
			</footer>
		)
		return output;
	}

	_renderWithTools() {
		// define button classes
		let urlMedia = ContextStore.getUrlMedia();
		let impersonationControls = <ImpersonationControls />

		let output = (
			<footer id="cd-dashboard-footer">
				<div className="d-flex justify-content-between align-items-center">
					<div className="collapse-tools-container">
						<img title="Load the home page." src={urlMedia + '/citizen-developer.svg'} 
							id="logo" alt="CitizenDeveloper" 
							onClick={() => { InterfaceActions.replacePage({url: '/' + ContextStore.getDefaultHomePageId()}); }}/>
						{/* <h4>Collapse Tools</h4> */}
					</div>
					<div className="resizer-controls">
						<ResizerControls />
					</div>
					<div className="impersonation-controls">
						{impersonationControls}
					</div>
				</div>
			</footer>
		)
		return output;
	}
}

const container = Container.create(DashboardFooter);
export default container;
