/* global citDev */
import React, {Component} from 'react';

/**
 * Container to NotificationBar
 *
 * @class NotificationBar
 * @extends {Component}
 */
class NotificationBar extends Component {
	render() {
		let {type, title, message, width} = this.props;
		let notificationClass = 'notification notification-' + type;
		let titleJSX = null;
		let messageJSX = null;
		let divWidth = (width ? width : 500);

		if(title) {
			titleJSX = <h4 className="title text-left">{ title }</h4>
		}
		if(message) {
			messageJSX = <div className="message text-left"><div><span>{ message }</span><br /></div></div>
		}

		return (
			<div id='top-notification-bar' className="notification-container notification-container-center d-flex justify-content-center notification-container-empty" style={{width: divWidth + 'px'}}>
				<div aria-live='polite' aria-relevant='all' role='status' className={notificationClass}>
					{ titleJSX }
					{ messageJSX }
				</div>
			</div>
		);
	}
}

export default NotificationBar;