import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';
import MapActions from '../../../actions/map-actions';

// Consants
import GroupFilters from '../../../constants/group-filters';
import Help from '../../../constants/help-upgrade-constants';

//Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import MapStore from '../../../stores/map-store';
import FieldStore from '../../../stores/field-store';
// import FieldTypeStore from '../../../stores/field-store';
import TableStore from '../../../stores/table-store';
import ContextStore from '../../../stores/context-store';

// Utils
import MapUtils from '../../../utils/map-utils';
import FieldUtils from '../../../utils/field-utils';
import UIUtils from '../../../utils/ui-utils';

import { ReactSVG } from 'react-svg';

/**
 * Listing of Fields for Data Dashboard
 *
 * @class FieldsDataDashboardTabContainer
 * @extends {Component}
 */
class FieldsDataDashboardTabContainer extends Component {
  /**
   * Creates an instance of FieldsDataDashboardTabContainer
   */
  constructor(props) {
    super(props);

    this._renderGroupBy = this._renderGroupBy.bind(this);
    this._onAddField = this._onAddField.bind(this);
    this._onChangeGroupBy = this._onChangeGroupBy.bind(this);
    this._onChangeSearch = this._onChangeSearch.bind(this);
    this._onEditField = this._onEditField.bind(this);
    this._onToggleShow = this._onToggleShow.bind(this);
    this._onToggleAllOpen = this._onToggleAllOpen.bind(this);
    this._onToggleAllClosed = this._onToggleAllClosed.bind(this);
  }

  /**
  * Loads the Stores to watch
  *
  * @static
  * @returns {Array of Object}
  */
  static getStores() {
    return [MapStore, FieldStore, AdminSettingsStore];
  }

  /**
   * Returns the current State
   *
   * @static
   * @returns {Object}
   */
  static calculateState(prevState) {
    let groupBy = (MapStore.getGroupBy() ? MapStore.getGroupBy() : GroupFilters.TABLE_NAME);
    let fields = FieldStore.getAllArray();
    let search = MapStore.getSearch();

    if (prevState
      && prevState.fields === fields
      && prevState.groupBy === groupBy
      && prevState.search === search) {
      return false;
    }

    // Determine the previous search
    let prevSearch = (prevState && prevState.search ? prevState.search : '')

    return {
      fields: fields,
      groupBy: groupBy,
      search: search,
      groupedFields: this._performGroupBy(fields, groupBy, search, prevSearch),
      groupToggleStatus: MapStore.getGroupToggleStatusGroupBy(AdminSettingsStore.getActiveDashboard(), 'fields'),
      currentFieldId: AdminSettingsStore.getRecordId()
    };
  }

  /**
   * Using the group by set in the UI, group the fields and return
   * an object with the groups as keys and functions array as value.
   * @param {Array} fields fields to group
   * @param {String} groupBy How to group the fields
   * @param {String} search What search to apply, if any.
   * @return Object
   * 
   * @Todo Implement
   */
  static _performGroupBy(fields, groupBy, search, prevSearch) {
    let groups = {};

    if (!fields) {
      return groups;
    }

    let currentFieldId = '';
    if (AdminSettingsStore.getTableSchemaName() === 'field') {
      currentFieldId = AdminSettingsStore.getRecordId();
    }

    // Filter out the fields that dont have the search term
    fields = fields.filter(field => {
      if (field.recordId === currentFieldId) {
        return true;
      } else if (search && search.length > 0) {
        // Fields Name
        let filterableString = (field.fieldLabel) ? field.fieldLabel.toLowerCase() : ' ';
        filterableString += (field.searchSuffix) ? field.searchSuffix.toLowerCase() : ' ';
        return (filterableString.toLowerCase().includes(search.toLowerCase()));
      } else {
        return true;
      }
      // Sort the discussions - by Closed/Open, then Numerically.
    });

    // Prepare the final return
    let returnGroups = MapUtils.groupBy(fields, groupBy, 'fieldLabel',
      '[ No Field Label found ]');

    Object.keys(returnGroups).forEach(groupName => {
      if(groupName === 'No table set') {
        returnGroups[groupName] = returnGroups[groupName].filter(field => {
          return field.new;
        });
        if(returnGroups[groupName] && !returnGroups[groupName].length) {
          delete returnGroups[groupName];
        }
      }
    })

    let groupToggleStatus = {};

    // Open the Group that has the active field in it.
    if (AdminSettingsStore.getTableSchemaName() === 'field') {
      let fieldObj = FieldStore.get(AdminSettingsStore.getRecordId());
      if (fieldObj.tableSchemaName) {
        let tableObj = TableStore.getByTableSchemaName(fieldObj.tableSchemaName);
        if (tableObj.pluralName) {
          groupToggleStatus[tableObj.pluralName] = true
        }
      }
    }

    // If we have a search...
    if (search.length > 0) {
      Object.keys(returnGroups).forEach((group, index) => {
        if (index <= 2) { // Only open the top 5
          groupToggleStatus[group] = true;
        }
      })
      // If we HAD a search, but no longer do... close them up!
    } else if (search.length === 0 && prevSearch.length > 0) {
      Object.keys(returnGroups).forEach((group, index) => {
        groupToggleStatus[group] = false;
      })
    }

    // @TODO REMOVE!
    /*
    In order to remove this, all of the performGroupBy stuff needs to be moved
    to the MapSTORE - and done in response to the OnDashboardChange 
    [ which should be moved there from the AdminSettingsStore ],
    onSearchChange, and onGroupByChange Actions. If we did this, then these
    components would become very `dumb` rendering components - as they are 
    meant to be.
     */
    if (Object.keys(groupToggleStatus).length) {
      setTimeout(() => {
        MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'fields', groupToggleStatus);
      }, 0);
    }

    return returnGroups;
  }

  /**
   * Render method
   */
  render() {
    let { groupBy, groupedFields, search } = this.state;

    // Loop over the groupedFields Object, and call renderGroup
    // for each Group found.
    let fieldList = [];
    Object.keys(groupedFields).forEach(groupName => {
      let group = this._renderGroupBy(groupedFields[groupName], groupName);
      if (group) {
        fieldList.push(group);
      }
    });

    let noSearchFound = null;
    if (fieldList.length === 0 && search.length) {
      noSearchFound = <li className="no-search-found" style={{ color: 'white' }}><h4>No Results for '{search}' found.</h4></li>
    }

    return (
      <div id="data-map__content" className="map">
        <div className="cd-search-container">
          <div className="d-flex">
            <div style={{ flex: 1 }} className="mr-1">
              <select className="form-control select-group-by" value={groupBy} onChange={this._onChangeGroupBy}>
                <option value={GroupFilters.TABLE_NAME}>Group By Table</option>
                <option value={GroupFilters.FIELD_TYPE}>Group By Field Type</option>
              </select>
            </div>
            <div title="Search Label, Type Name or Table" style={{ flex: 1 }} className="ml-1">
              <input placeholder="Search" className="form-control discussion-search-input" type="text" value={search} onChange={this._onChangeSearch} />
            </div>
          </div>
        </div>
        <div className="section-header" key="expand-collapse-tools">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <div title="Expand All" onClick={this._onToggleAllOpen}>
                <i className="fa fa-plus"></i>
              </div>
              <div>|</div>
              <div title="Collapse All" onClick={this._onToggleAllClosed}>
                <i className="fa fa-minus"></i>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <h5 className="bold">Fields</h5>
              <div title="Field Help" className="info-icon ml-2" onClick={() => { UIUtils.onHelpClick(Help.HELP_DASHBOARD_DATA_FIELDS); }}>
                <i className="fa fa-info-circle mr-1"></i> |
              </div>
              <div title="Create Field" data-toggle="modal" onClick={this._onAddField.bind(this, '')} >
                <i className="fa fa-plus-circle ml-1 mb-1"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="list-content-wrapper" key="fields">
          <ol>
            {fieldList}
            {noSearchFound}
          </ol>
        </div>
      </div>
    );
  }

  /**
   * private Render on group of fields - from the list of ALL fields.
   * @param {Array} fields All of the fields
   * @param {string} groupBy The value to show these fields as grouped by - in this case, the table name.
   */
  _renderGroupBy(fields, groupName) {
    let { groupToggleStatus, currentFieldId, groupBy } = this.state;

    // Figure out if this group contains the current page.
    let fieldThatIsCurrent = fields.filter(field => {
      return field.recordId === currentFieldId;
    })

    // Basic JSX for the youAreHereIndicator
    let youAreHereIndicator = (
      <span>
        <i className="fa fa-circle fa-1 selection-marker" aria-hidden="true" />
        <span className="sr-only">(Current)</span>
      </span>
    );

    // Add a Group of fields for this Table.
    let show = groupToggleStatus[groupName];
    if (fieldThatIsCurrent.length && show === undefined) {
      show = true;
    }

    let fieldList = [];

    if (show) {
      // Generate the LI list for all fields..
      fields.forEach(field => {
        let iconJSX = null;
        switch (groupBy) {
          case GroupFilters.TABLE_NAME: // Set the Icon to the Field Type Icon
            let fieldTypeIcon = FieldUtils.getFieldTypeIconByFieldTypeId(field.fieldType);
            iconJSX = <img height="10" width="10" className="mr-2" src={fieldTypeIcon} alt="" />
            break;
          case GroupFilters.FIELD_TYPE: // Set the Icon to the Table Icon
            let tableObj = TableStore.getByTableSchemaName(field.tableSchemaName);
            if(tableObj && tableObj.icon) {
              iconJSX = <span className={"fa mr-2 fa-" + tableObj.icon}></span>;
            }
            break;
          default:
            console.warn('Group By', groupBy, 'not supported for icon in group by');
            break;
        }
        if (!field.recordId) {
          return;
        }
        fieldList.push(
          <li key={field.recordId} className="table-name-item">
            <div className="d-flex justify-content-between">
              <h5 className="d-flex">
                  {iconJSX}
                  {field.fieldLabel}
              </h5>
              <div className="d-flex">
                {(field.recordId === currentFieldId ? youAreHereIndicator : null)}
                <span onClick={this._onEditField.bind(this, field.recordId)} title="Update Field">
                  <ReactSVG
                    beforeInjection={svg => {
                      svg.setAttribute('viewBox', '0 0 34 34');
                      svg.setAttribute('style', 'width: 16px; height: 16px');
                    }}
                    src={ContextStore.getUrlMedia() + "/icon-appearance.svg"} />
                </span>
              </div>
            </div>
          </li>
        );
      }); // End loop over all the fields.
    }

    let tableSchemaName = '';
    let tableIcon = '';
    if (fields.length) {
      tableSchemaName = fields[0].tableSchemaName;
      tableIcon = TableStore.getByTableSchemaName(tableSchemaName).icon;
    }

    // Determine the JSX for the Group Icon
    let groupIconJSX = null;
    let fieldType = '';
    switch (groupBy) {
      case GroupFilters.TABLE_NAME:
        // This is a group by table - so lets get the TSN
        if (tableIcon) {
          groupIconJSX = <span className={"fa fa-" + tableIcon}></span>;
        }
        break;
      case GroupFilters.FIELD_TYPE:
        fieldType = fields[0].fieldType;
        let fieldTypeIcon = FieldUtils.getFieldTypeIconByFieldTypeId(fieldType);
        groupIconJSX = <img height="10" width="10" src={fieldTypeIcon} alt="" />
        break;
      default:
        console.warn('No Icon Definition for Group By:', groupBy);
        break;
    }

    // If this is hte group that has the current field in it, and there is NO 
    // indicator for if the user wants this group open or not... open it.
    return (
      <li key={groupName} className="role-group-item">
        <div className="role-group-name d-flex" onClick={this._onToggleShow.bind(this, groupName, !show)}>
          <div className="mr-1">
            {show
              ? <i title="Collapse" className="fa fa-minus"></i>
              : <i title="Expand" className="fa fa-plus"></i>
            }
          </div>
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              {groupIconJSX}
              <h4 className="bold ml-1">{groupName}</h4>
            </div>
            <div className="d-flex">
              {(fieldThatIsCurrent.length ? youAreHereIndicator : null)}
              <h4 className="bold">({fields.length})</h4>
              {(groupBy === GroupFilters.TABLE_NAME ?
                <div title="Create Field" onClick={this._onAddField.bind(this, tableSchemaName, undefined)} >
                  <i style={{ color: 'var(--theme-text-1)' }} className="fa fa-plus-circle ml-1"></i>
                </div>
                : null)}
              {(groupBy === GroupFilters.FIELD_TYPE ?
                <div title="Create Field" onClick={this._onAddField.bind(this, undefined, fieldType)} >
                  <i style={{ color: 'var(--theme-text-1)' }} className="fa fa-plus-circle ml-1"></i>
                </div>
                : null)}
            </div>
          </div>
        </div>
        <ol id={groupName} className={'collapse pl-4 pt-2 pr-0 ' + (show ? 'show ' : '') + 'groupby-list'}>
          {fieldList}
        </ol>
      </li>);
  } // end _renderGroupBy

  /**
   * Handle adding a field.
   */
  _onAddField(tableSchemaName, fieldType) {
    FieldUtils.addNewField(tableSchemaName, fieldType);
    // Unhide the settings, always
    AdminSettingsActions.onSettingsListHideChange(false);
  }

  /**
   * private Handles change to group by dropdown
   */
  _onChangeGroupBy(event) {
    let groupBy = event.target.value;
    MapActions.groupBy(groupBy);
  }

  /**
   * Handles typing into the search box.
   * @param {object} event Change event
   */
  _onChangeSearch(event) {
    let search = event.target.value;
    MapActions.search(search);
    this.lastSearchKeystroke = Date.now();
  }

  /**
   * Edit this field in the Details Pane
   * @param {string} fieldId 
   * @param {string} tableSchemaName 
   */
  _onEditField(fieldId) {
    let fieldObj = FieldStore.get(fieldId);

    if(fieldObj.new) {
      UIUtils.openSettingsPanel('field-add', fieldId, 'field');
    } else {
      UIUtils.openSettingsPanel('appearance', fieldId, 'field');
    }
    
    // Unhide the settings, always
    AdminSettingsActions.onSettingsListHideChange(false);
  }

  /**
   * Toggle one group.
   */
  _onToggleShow(groupLabel, show) {
    MapActions.groupToggle(AdminSettingsStore.getActiveDashboard(), 'fields', groupLabel, show);
  }

  /**
   * Open all of the groups
   */
  _onToggleAllOpen() {
    let { groupedFields, groupToggleStatus } = this.state;
    Object.keys(groupedFields).forEach(group => {
      groupToggleStatus[group] = true;
    })
    MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'fields', groupToggleStatus);
  }

  /**
   * Close all of the groups
   */
  _onToggleAllClosed() {
    let { groupToggleStatus } = this.state;
    Object.keys(groupToggleStatus).forEach(key => {
      groupToggleStatus[key] = false;
    })
    MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'fields', groupToggleStatus);
  }
}

const container = Container.create(FieldsDataDashboardTabContainer);
export default container;
