import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import ComplianceConstants from '../constants/compliance-constants';
import FieldStore from '../stores/field-store';
import ObjectUtils from '../utils/object-utils';

/**
 * Manages compliance information
 */
class ComplianceStore extends ReduceStore {
	/**
	 * getInitialState - initial state for ContextStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.fromJS({
			compliances: {},
			allPulledFromDatabase: false
		});
	}

	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			case ComplianceConstants.COMPLIANCE_PUSH_TO_DATABASE: {
				// Because push to database may result in a new compliance Id
				return state.withMutations(newState => {
					let complianceProperties = action.get('complianceProperties');
					let complianceId = action.get('complianceId');
					// Delete the temporary entry now that this exists in the DB
					if(complianceProperties.has('temporaryId')) {
						let tempId = complianceProperties.get('temporaryId');
						newState.deleteIn(['compliances', tempId]);
						complianceProperties = complianceProperties.delete('temporaryId');
					}
					if(action.has('newComment')) {
						newState.mergeDeepIn(['compliances', complianceId, 'comments', action.getIn(['newComment', 'recordId'])], action.get('newComment'));
					}
					newState.deleteIn(['compliances', complianceId, 'wipComment']);
					newState.mergeDeepIn(['compliances', complianceId], complianceProperties);
				});
			}
			case ComplianceConstants.COMPLIANCE_PUSH_TO_STORE: {
				return state.mergeDeepIn(['compliances', action.get('recordId')], action.get('complianceProperties'));
			}
			case ComplianceConstants.COMPLIANCE_DELETE_FROM_STORE: {
				return state.deleteIn(['compliances', action.get('complianceId')]);
			}
			case ComplianceConstants.COMPLIANCE_PULL_FROM_DATABASE: {
				let newState = state;
				let overwriteStore = action.get('overwriteStore');
				let complianceId = action.get('complianceId');
				let compliance = action.get('compliance');
				if(overwriteStore) {
					// Just overwrite the store with what we pulled
					newState = state.setIn(['compliances', complianceId], compliance);
				} else {
					newState = state.setIn(['compliances', complianceId], compliance.mergeDeep(state.getIn(['compliances', complianceId])));
				}
				newState = newState.set('allPulledFromDatabase', true);
				return newState;
			}
			case ComplianceConstants.COMPLIANCE_PULL_FROM_DATABASE_ALL: {
				let newState = state;
				let overwriteStore = action.get('overwriteStore');
				let compliancesList = action.get('compliances');
				// Convert the List ('array') to a Map ('object')
				let compliancesMap = Immutable.Map(compliancesList.map(d => [d.get('recordId'), d]));
				if(overwriteStore) {
					// Just overwrite the store with what we pulled
					newState = state.set('compliances', compliancesMap);
				} else {
					newState = state.set('compliances', compliancesMap.mergeDeep(state.get('compliances')));
				}
				newState = newState.set('allPulledFromDatabase', true);
				return newState;
			}
			default:
				return state;
		}
	}

	/**
	 * Gets whether everything has been pulled from the DB or not
	 */
	allPulledFromDatabase() {
		return this.getState().get('allPulledFromDatabase');
	}

	/**
	 * Gets the entire store as an object
	 *
	 * @returns {Object} current store as an object
	 */
	getAll() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			return this.getState().get('compliances').toJS();
		} else {
			return undefined;
		}
	}

	/**
	 * Gets the entire store as an array
	 *
	 * @returns {Array} current store as an array
	 */
	getAllArray() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			return this.getState().get('compliances').toList().toJS();
		} else {
			return undefined;
		}
	}

	/**
	 * Gets an individual record from the store
	 * @param {string} complianceId UUID of the record to get
	 * @returns {Object} current store as an object
	 */
	get(complianceId) {
		if (this.getState().get('compliances').has(complianceId)) {
			return this.getState().get('compliances').get(complianceId);
		} else {
			return undefined;
		}
	}

	/**
	 * Generate an Object of the structure:
	 * {
	 * 		complianceId: {
	 * 			tagId: [ fieldId, fieldId ]
	 * 		}
	 * }
	 */
	getFieldsByComplianceAndTag() {
		let fields = FieldStore.getAllArray();
		let compliances = {};

		// Loop over every field
		fields.forEach(field => {
			let settings = ObjectUtils.getObjFromJSON(field.settings);

			// Get its tags
			if(settings.complianceTags) {
				let complianceTagsObj = ObjectUtils.getObjFromJSON(settings.complianceTags);

				// Loop over the tags, which is an object of compliance : tag : boolean
				Object.keys(complianceTagsObj).forEach(complianceId => {
					if(!compliances[complianceId]) {
						compliances[complianceId] = {};
					}

					// At the complianceId level, we have tagId : boolean
					Object.keys(complianceTagsObj[complianceId]).forEach(tagId => {
						if(complianceTagsObj[complianceId][tagId]) {
							if(!compliances[complianceId][tagId]) {
								compliances[complianceId][tagId] = [];
							}
							compliances[complianceId][tagId].push(field.recordId);
						}
					})
				})
			}
		})
		return compliances;
	}

	/**
	 * Generate an Object of the structure:
	 * {
	 * 		complianceId: {
	 * 			fieldId: [ tagId, tagId ]
	 * 		}
	 * }
	 */
	getTagsByComplianceAndField() {
		let fields = FieldStore.getAllArray();
		let compliances = {};

		// Loop over every field
		fields.forEach(field => {
			let settings = ObjectUtils.getObjFromJSON(field.settings);

			// Get its tags
			if(settings.complianceTags) {
				let complianceTagsObj = ObjectUtils.getObjFromJSON(settings.complianceTags);

				// Loop over the tags, which is an object of compliance : tag : boolean
				Object.keys(complianceTagsObj).forEach(complianceId => {
					if(!compliances[complianceId]) {
						compliances[complianceId] = {};
					}
					// At the complianceId level, we have tagId : boolean
					Object.keys(complianceTagsObj[complianceId]).forEach(tagId => {
						if(complianceTagsObj[complianceId][tagId]) {
							if(!compliances[complianceId][field.recordId]) {
								compliances[complianceId][field.recordId] = [];
							}
							compliances[complianceId][field.recordId].push(tagId);
						}
					})
				})
			}
		})
		return compliances;
	}

	/**
	 * Given a complianceId and tagId, lookup the tags name and return it.
	 * @param {string} complianceId Compliance to get the tag from.
	 * @param {string} tagId Which tag to the get the name for
	 * @return {string} Name of the tag.
	 */
	getTagName(complianceId, tagId) {
		let tagName = 'No Tag Name';
		if (this.getState().get('compliances').has(complianceId)) {
			let compliance = this.getState().get('compliances').get(complianceId);

			if(compliance.has('tags')) {
				compliance.get('tags').forEach(tag => {
					if(tag.get('recordId') === tagId) {
						tagName = tag.get('name');
					}
				})
			}
		}
		return tagName;
	}
}

const instance = new ComplianceStore(AppDispatcher);
export default instance;