/* global citDev */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Container } from 'flux/utils';

// Actions
import AdminSettingsActions from '../../../actions/admin-settings-actions';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import ContextStore from '../../../stores/context-store';
import ComplianceStore from '../../../stores/compliance-store';
import FieldStore from '../../../stores/field-store';
import RenderStore from '../../../stores/render-store';

// Utils
import UIUtils from '../../../utils/ui-utils';

// Components
import PopoverHeader from './header';

/**
 * Container to field type wrapper
 *
 * @class FieldTypeWrapperContainer
 * @extends {Component}
 */
class CompliancePinPopover extends Component {
	constructor(props) {
		super(props);
		this.link = null;
		// this.onAddClick = this.onAddClick.bind(this);
		this.onSettingClick = this.onSettingClick.bind(this);
	}
	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf DiscussionPin
	 */
	static getStores() {
		return [AdminSettingsStore, FieldStore, ComplianceStore];
	}


	/**
	 * onAddClick - Sets up a new recordId for the security setting UI to use.
	 *
	 * @param {object} event
	 */
	// onAddClick(event){
	// 	event.preventDefault();
	// 	event.stopPropagation();

	// 	let newRecordId = uuid.v4();
	// 	let {
	// 		componentId,
	// 		applicationId,
	// 		pageId,
	// 		dataRecordId,
	// 		dataTableSchemaName,
	// 		installationId
	// 	} = this.state;
	// 	let newRecordObj = {
	// 		temporary: true,
	// 		componentId,
	// 		applicationId,
	// 		pageId,
	// 		dataRecordId,
	// 		dataTableSchemaName,
	// 		installationId,
	// 		discussionId: newRecordId,
	// 		status: 'open',
	// 		priority: 'trivial',
	// 		type: 'discussion'
	// 	};
	// 	DiscussionActions.pushToStore(newRecordId, newRecordObj);
	// 	this.onSettingClick(newRecordId, event);
	// }

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 * @memberof QuerySettingsChooser
	 */
	onSettingClick(discussionId, event) {
		event.preventDefault();
		event.stopPropagation();

		if(!discussionId) {
			return;
		}

		// Open the settings panel on the right.
		// (targetOverlay, recordId, tableSchemaName, parentRecordId, parentTableSchemaName, mainTab,
		// 	subSettingSchemaName, subSettingIndex, renderId)
		UIUtils.openSettingsPanel('compliance',
			this.props.recordId, this.props.tableSchemaName, 
			this.props.parentRecordId, this.props.parentTableSchemaName, 
			'settings', undefined, undefined, this.props.renderId, this.props.attachmentId
		);

		// Tell the Admin Settings Store what Setting we should be on.
		AdminSettingsActions.onSettingChange(undefined, discussionId);
	}

	/**
	 * Returns the current State of the FieldType Settings
	 *
	 * @static
	 * @returns {Object}
	 *
	 * @memberOf DiscussionPin
	 */
	static calculateState(prevState, props) {
		let { renderId } = props;
		let renderObj = RenderStore.get(renderId) || {};
		let { componentId } = renderObj;

		let pageObj = RenderStore.getPageRenderObj(renderId) || {};
		let {dataRecordId, dataTableSchemaName} = renderObj;
		let pageId = pageObj ? pageObj.componentId : undefined;
		let applicationId = ContextStore.getApplicationId();
		let installationId = ContextStore.getInstallationId();

		let compliances = ComplianceStore.getAllArray();
		let fieldSettings = FieldStore.getSettings(componentId);

		let componentType = AdminSettingsStore.getTableSchemaName();
		
		if(componentType === 'page') {
			return {
				isPage: true
			}
		}

		return {
			compliances,
			componentId,
			applicationId,
			pageId,
			dataRecordId,
			dataTableSchemaName,
			installationId,
			complianceTags: (fieldSettings && fieldSettings.complianceTags ? JSON.parse(fieldSettings.complianceTags) : {}),
		};
	}

	/**
	 * Renders a single discussion as an LI option.
	 * 
	 * @param {Object} discussionObj 
	 * @param {string} discussionObj.isSelected Is this option the selected UI option?
	 * @param {string} discussionObj.discussionId Record Id
	 * @param {number} discussionObj.number Discussion Number.
	 * @param {number} index The Index of this option
	 * @return JSX
	 */
	_renderOption(complianceObj, index) {
		
		if(!complianceObj.isActive) {
			return (null);
		}

		let { complianceTags } = this.state;
		let labelClassNames = 'setting-label';

		if(complianceObj.recordId === AdminSettingsStore.getSettingRecordId()) { 
			labelClassNames += ' selected';
		}

		return (<li key={index} className="nav-item compliance pt-0">
					<div className="d-flex setting-text-container">
						<div className="w-50 settings-list-value">
							<div className="nav-link" onClick={this.onSettingClick.bind(this, complianceObj.recordId)}>
								<div className={labelClassNames}>{complianceObj.name || '[ No Name Found ]'}</div>
							</div>
						</div>
						<div className="w-50 setting-value-container d-flex align-items-center" style={{ textAlign: 'right' }}>
							<div className="setting-value" style={{ width: '100%', paddingRight: '5px' }}>
								{ Object.keys(complianceTags).map((index) => {
									let compliance = complianceTags[index];
									let tagSpans = [];
									complianceObj.tags.forEach((tag) => {
										if(compliance[tag.recordId]) {
											tagSpans.push(tag.name);
										}
										
									});
									return <span key={index}>{tagSpans.join(', ')}</span>;
								})}
							</div>
						</div>
					</div>
				</li>);
	}

	render() {
		let { compliances, isPage } = this.state;
		let settingsList = [];

		if(isPage) {
			return (
				<div style={{ height: '100%', overflow: 'hidden' }} id="popover-content" className="security-popover cd-tools">
					<PopoverHeader 
						recordId={this.props.recordId} 
						tableSchemaName={this.props.tableSchemaName} 
						iconBase="eye-slash" 
						overlayName="Visibility"/>
					<div style={{ height: '100%', overflow: 'hidden' }} className="tab-content row mb-2">
						<div style={{ height: '100%', overflowY: 'auto' }} className="ml-3 pr-1 mr-2">
							<div style={{ marginTop: '8px' }} className='no-settings-found'>No Settings Found</div>
						</div>
					</div>
				</div>
			);
		}

		settingsList.push(compliances.sort((a, b) => {
			// Put closed stuff last.
			if(a.isActive !== 1 && b.isActive === 1) {
				return 1;
			} else if (a.isActive === 1 && b.isActive !== 1) {
				return -1;
			}
			
			// Same name?  Don't Swap.
			if(a.name === b.name) return 0;

			// Ascending Alphabetically.
			return a.name < b.name ? -1 : 1;
		}).map(this._renderOption.bind(this)));

		if(settingsList.length > 0) {
			return (
				<div id="popover-content" className="cd-tools">
					<PopoverHeader 
						recordId={this.props.recordId} 
						tableSchemaName={this.props.tableSchemaName} 
						iconBase="balance-scale" 
						overlayName="Compliances"/>
					<div style={{ height: '100%', overflow: 'hidden' }} className="tab-content row mb-2">
						<div style={{ height: '100%', overflowY: 'auto' }} className="ml-3 pr-1 mr-2">
							<ul id="popover-settings" className="nav flex-column NOTEST">
								<li className="settings-list-header field-settings-list-header"><h3>Compliances</h3></li>
								{settingsList}
							</ul>
						</div>
					</div>
				</div>
			);
		}
	}
}

if ('development' === process.env.NODE_ENV) {
	CompliancePinPopover.propTypes = {
		recordId: PropTypes.string.isRequired,
		tableSchemaName: PropTypes.string.isRequired,
		parentRecordId: PropTypes.string,
		parentTableSchemaName: PropTypes.string,
	};
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(CompliancePinPopover, {withProps: true});
export default container;