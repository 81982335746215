var keyMirror = require('keymirror');

var WebHookTriggerNames = keyMirror({
  'STRIPE': null,
});

let WebHookTriggers = {};
WebHookTriggers[WebHookTriggerNames.STRIPE] = [
	{
		"schemaName": 'stripe-checkout.session.async_payment_failed',
		"label": 'Delayed Payment Failed'
	},
	{
		"schemaName": 'stripe-checkout.session.async_payment_succeeded',
		"label": 'Delayed Payment Succeeded'
	},
	{
		"schemaName": "stripe-invoice.paid",
		"label": "Invoice Paid"
	},
	{
		"schemaName": "stripe-invoice.payment_failed",
		"label": "Invoice Payment Failed"
	},
	{
		"schemaName": "stripe-invoice.payment_action_required",
		"label": "Invoice Payment Action Required"
	},
	{
		"schemaName": "stripe-invoice.finalization_failed",
		"label": "Invoice Finalization Failed"
	},
	{
		"schemaName": 'stripe-payment_intent.succeeded',
		"label": 'Payment Succeeded'
	},
	{
		"schemaName": 'stripe-setup_intent.succeeded',
		"label": 'Setup Succeeded'
	},
	{
		"schemaName": 'stripe-subscription_schedule.aborted',
		"label": 'Subscription Schedule Aborted'
	}
];

export { WebHookTriggerNames };
export { WebHookTriggers };