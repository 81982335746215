import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { AdminSettingsActions, InterfaceActions, LogicFunctionActions } from '../../../actions';
import { AdminSettingsStore, FieldStore, LogicFunctionStore, ToolboxStore, ContextStore } from '../../../stores';

// Components;
import ReactBlocklyComponent from '@dmclain-citizendeveloper/citdev-react-blockly-component';

// Utils
import { 
	BlocklyUtils,
	// LogicUtils, 
	ObjectUtils,
} from '../../../utils';

/**
 * Right panel displaying Scheduled Logic settings
 */
class LogicFunctionSetting extends Component {
	constructor(props) {
		super(props);
		// @TODO: We don't use URI Parameters or runtime variables
		// but we do use regular parameters as variables which must be
		// created. Add that in.
		this._onCopyWorkspace = this._onCopyWorkspace.bind(this);
		// this._onKeyDown = this._onKeyDown.bind(this);
		this._onPasteWorkspace = this._onPasteWorkspace.bind(this);
		this._onResizeBlockly = this._onResizeBlockly.bind(this);
		this._workspaceDidChange = this._workspaceDidChange.bind(this);
		this._onSaveHandler = this._onSaveHandler.bind(this);
		this._onResetHandler = this._onResetHandler.bind(this);
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, FieldStore, LogicFunctionStore, ToolboxStore];
	}

	/**
	 * Calculate the current state of the component
	 * @static
	 *
	 * @param  {Object} prevState Previous State
	 * @param  {Object} props     Previous Props
	 * @return {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId = AdminSettingsStore.getRecordId();
		let settingRecordId = AdminSettingsStore.getSettingRecordId();
		let settingObj = FieldStore.get(settingRecordId);

		let logicFunctionObj = LogicFunctionStore.get(recordId);
		let fullRecord = LogicFunctionStore.getFull(recordId);
		let changedRecord = LogicFunctionStore.get(recordId, true);

		if(changedRecord && changedRecord.params && (!prevState || changedRecord.params !== prevState.params)) {
			// If the params have changed and this is our first time processing it...
			let params = fullRecord.get('params');
			let oldParams = params.get('originalValue');
			let newParams = params.get('value');
			let blocklyxml = BlocklyUtils.replaceParams(oldParams, newParams, logicFunctionObj.blocklyxml);
			logicFunctionObj.blocklyxml = blocklyxml;
		}

		let actionToolbox = (prevState ? prevState.actionToolbox : undefined);

		let newToolbox = ToolboxStore.getActionToolboxJS();
		if ((!prevState || !prevState.actionToolbox) && newToolbox && Object.keys(newToolbox).length) {
			actionToolbox = newToolbox;
		}


		let newState = Object.assign({
			recordId: recordId,
			actionToolbox: actionToolbox,
			settingsHidden: AdminSettingsStore.getSettingsListHidden(),
			label: settingObj && settingObj.fieldLabel ? settingObj.fieldLabel : 'Function Logic'
		}, logicFunctionObj);

		return newState;
	}

	/**
	 * Calling resizeBlockly during this lifecycle phase to ensure
	 * Blockly resizes when user closes and reopens right panel with same
	 * Blockly component displaying
	 */
	componentDidUpdate(prevProps, prevState) {
		// Resize blockly
		// @TODO: Check if we need to resize in more limited circumstances
		this._onResizeBlockly();
	}

	/**
	 * Calling resizeBlockly during this lifecycle phase to ensure
	 * Blockly resizes when opens right panel for the first time
	 */
	componentDidMount() {
		this._onResizeBlockly();
	}

	/**
	 * Render the component
	 * @return JSX
	 */
	render() {
		let { blocklyxml, params, settingsHidden, label } = this.state;

		params = params && typeof params === 'string' ? JSON.parse(params) : params;
		let variables = params ? params.map(({blocklyId, label}) => {
			return {
				name: label,
				id: blocklyId
			}
		}) : undefined;

		variables = variables ? JSON.stringify(variables) : undefined; // The double-parse is inefficient but what the props currently require


		if(blocklyxml && !blocklyxml.startsWith('<xml')) {
			blocklyxml = '<xml>' + blocklyxml + '</xml>';
		}

		const workspaceConfiguration = {
			grid: {
				spacing: 20,
				length: 3,
				colour: '#ccc',
				snap: true
			},
			zoom: {
				controls: true,
				wheel: true,
				startScale: 0.9,
				maxScale: 3,
				minScale: 0.3,
				scaleSpeed: 1.2
			}
		};

		let toolBox = ToolboxStore.getActionToolboxAPIJS();

		return (
			<div className={`automation-setting-container pt-0 cd-bg-3 pr-3 ${settingsHidden ? 'pl-3' : ''}`}>
				<h2 className="d-flex w-100 justify-content-between align-items-center py-3">
					<div className='d-flex align-items-center justify-content-between mr-2' style={{ flex: 1 }}>
						<div className='d-flex align-items-center'>
							<h3 className='my-1'>
								{/* Show collapse only when setting has been selected */}
								{settingsHidden ?
									<button
										className="btn btn-back"
										title="Triggers"
										form="appearance-form"
										onClick={() => { AdminSettingsActions.onSettingsListHideChange(false); }}>
										<img height="26" width="26" src={ContextStore.getUrlMedia() + "/expand-settings-list.svg"} alt="" />
									</button>
									: null}
								{label}
							</h3>
						</div>
						<div className="btn-wrapper d-flex align-items-center justify-content-around">
							<button
								key="save"
								className="btn btn-primary ml-2"
								form="appearance-form"
								aria-label="Save"
								onClick={this._onSaveHandler}>
								Save
							</button>
							<button
								key="reset"
								className="btn btn-warning ml-2"
								form="appearance-form"
								aria-label="Reset"
								onClick={this._onResetHandler}>
								Reset
							</button>
							<button
								key="copy"
								className="btn btn-secondary ml-2"
								form="appearance-form"
								aria-label="Copy"
								onClick={this._onCopyWorkspace}>
								Copy
							</button>
							<button
								key="paste"
								className="btn btn-secondary ml-2"
								form="appearance-form"
								aria-label="Paste"
								onClick={this._onPasteWorkspace}>
								Paste
							</button>
						</div>
					</div>
				</h2>
				<div className={"automation-settings--blockly"}>
					<ReactBlocklyComponent.BlocklyEditor
						variables={variables}
						initialXml={blocklyxml}
						ref={(blocklyEditor) => { this.blocklyEditor = blocklyEditor; }}
						workspaceConfiguration={workspaceConfiguration}
						wrapperDivClassName='automation-settings--blockly'
						toolboxCategories={toolBox}
						workspaceDidChange={this._workspaceDidChange}
						onImportXmlError={console.error}
					/>
				</div>
			</div>);
	}

	/**
		 * Copies blocks to citdev clipboard
		 */
	_onCopyWorkspace() {
		try {
			let blocklyValueObj = BlocklyUtils.getWorkspaceInfo(this.blocklyEditor.workspace.workspace, {
				defaultToNull: true,
				kind: 'logicFunctions',
				includeJs: false // We don't need to bother with the JS for this
			});
			localStorage.logicClipboard = JSON.stringify(blocklyValueObj);
			InterfaceActions.notification({ 'level': 'success', 'message': 'Copying logic to clipboard...' });
		} catch (err) {
			console.error('Error when copying logic: ', err);
			InterfaceActions.notification({ 'level': 'error', 'message': 'Error when copying logic!' });
		}
	}

	/**
	 * Pastes blocks from citdev clipboard to workspace; appends to, not overrides, blocks
	 */
	_onPasteWorkspace() {
		let value = localStorage.logicClipboard;
		try {
			let valueObj = ObjectUtils.getObjFromJSON(value);

			let pastedblocklyxml = valueObj.blocklyxml;
			BlocklyUtils.appendToWorkspace(pastedblocklyxml, this.blocklyEditor.workspace.workspace);
			InterfaceActions.notification({ 'level': 'success', 'message': 'Pasting new logic below existing logic. Please check your new blocks to make sure they don\'t overlap!' });
			// let oldblocklyxml = this.state.value ? this.state.value.blocklyxml : '';


			// // Pass the pasted value into the utility function to combine them into one workspace XML string
			// let newxml = BlocklyUtils.combineWorkspaces(pastedblocklyxml, oldblocklyxml);

			// // Make sure that our new code still compiles; the catch will catch it if it's invalid XML
			// if(Blockly.Xml.textToDom(newxml)){
			// 	this.handleXMLChange(newxml);
			// 	InterfaceActions.notification({ 'level': 'success', 'message': 'Pasting new logic below existing logic. Please check your new blocks to make sure they don\'t overlap!' });
			// }
		} catch (err) {
			InterfaceActions.notification({ 'level': 'error', 'message': 'Attempted to paste invalid value into workspace.' });
			console.warn('Attempted to paste with invalid data in clipboard. Value was', value);
			console.warn('Error was', err);
		}
	}

	/**
	 * resizeBlockly - Resizes blockly instances to full width by triggering resize() from ref.
	 * A setTimeout of 400 milliseconds is used to allow the CSS transition to complete
	 * Prior to calculation of new width
	 *
	 * @param  {string} (optional) blocklyRefId of ref to Blockly refs. Will pull state value when left undefined
	 */
	_onResizeBlockly() {
		if (this.blocklyEditor) {
			setTimeout(this.blocklyEditor.resize, 500);
		}
	}

	_workspaceDidChange() {
		// We are not actually doing work on change, but
		// we do want to force the value to be marked as dirty once
		// we start messing around
		// This will be automatically cleaned on save or component unmount
		if(!this.state.forceDirty) {
			let newValue = Object.assign({
				forceDirty: true
			}, this.state);
			delete newValue.trigger; // Value from the state not needed for this
			delete newValue.actionToolbox;
			this._saveToStore(newValue);
		}
	}

	_saveToStore(blocklyValueObj) {
		let recordId = this.state.recordId;
		LogicFunctionActions.pushToStore(recordId, blocklyValueObj);
	}

	/**
	 * onSaveHandler - Triggers to send Blockly data in store to database via API
	 */
	_onSaveHandler() {
		// Display notification to user
		// let saveNotif = InterfaceActions.stickyNotification({ 'level': 'info', 'message': 'Saving logic...' });

		
		if(this.blocklyEditor && this.blocklyEditor.workspace && this.blocklyEditor.workspace.workspace) {
			let workspace = this.blocklyEditor.workspace.workspace;
			workspace.saving = true;
			setTimeout(() => {

				
				// Handle converting logicFunctionsUsed into an array
				// Regenerate the JS
				let params = {
					// defaultToNull: true,
					includeJs: true,
					kind: 'logicFunctions',
					recordId: this.state.recordId,
					title: this.state.name,
					paramsArr: this.state.params ?
						JSON.parse(this.state.params) :
						[]
				};
	
				
				// @TODO: We need to either update this or make a new util to handle logic function workspaces
				BlocklyUtils.saveAutomationFromLogicWorkspace(workspace, params, (blocklyValueObj) => {
					this._saveToStore(blocklyValueObj);
					// let parentSaveNotif = InterfaceActions.stickyNotification({ 'level': 'info', 'message': 'Saving local logic...' });
					let toSave = LogicFunctionStore.get(this.state.recordId, true);
					return LogicFunctionActions.pushToDatabasePromise(toSave);
				}).catch(console.error);
			}, 1000);
		}

	}

	/**
	 * onResetHandler - Calls API to retrieve data to reset value in store
	 */
	_onResetHandler() {

		// Reset all of the logic functions used to the value from the database
		// (Yes, this may mean undoing a logic function change if changed in another trigger but not saved. That's the way it's going to have to be. Don't do that.)
		let logicFunctionIds = this.state.logicFunctionsUsed;
		
		// Handle converting logicFunctionsUsed into an array
		let logicFunctionIdsArr =[]; 
		if (logicFunctionIds) {
			if(Array.isArray(logicFunctionIds)) {
				logicFunctionIdsArr = logicFunctionIds;
			} else {
				logicFunctionIdsArr = logicFunctionIds.split(',');
			}
		}
		if (logicFunctionIdsArr && logicFunctionIdsArr.length) {
			InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting all functions used within this logic function to their saved values...' });
			logicFunctionIdsArr.forEach(logicFunctionId => {
				LogicFunctionActions.pullFromDatabase(logicFunctionId);
			});
		}

		InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting Logic Function...' });
		LogicFunctionActions.pullFromDatabase(this.state.recordId);

		if(this.blocklyEditor) {
			this.blocklyEditor.reset();
		}
	}

}
const container = Container.create(LogicFunctionSetting, { withProps: true });
export default container;
