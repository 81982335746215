import { ContextStore, RecordSetStore, AuthenticationStore, RenderStore, RecordStore } from '../stores';
import ObjectUtils from './object-utils';
export default class RecordVariableUtils{
	static buildNamedContexts(renderId, queryJSON, actionjs, dataTableSchemaName, dataRecordId) {

		let renderObj = RenderStore.get(renderId) || {};
		let {dataRecordId: startingRecordId, dataTableSchemaName: startingTSN} = renderObj;

		if(dataTableSchemaName) {
			startingTSN = dataTableSchemaName;
		}

		if(typeof dataRecordId !== 'undefined') {
			startingRecordId = dataRecordId;
		}

		let pageRecordId = null;
		let pageTableSchemaName = null;
		let pageRenderObj = null;
		if (renderId) {
			pageRenderObj = RenderStore.getPageRenderObj(renderId);
			pageRecordId = pageRenderObj ? pageRenderObj.dataRecordId : null;
			pageTableSchemaName = pageRenderObj ? pageRenderObj.dataTableSchemaName : null;
		} else {
			pageRecordId = ContextStore.getRecordId() || null;
			pageTableSchemaName = ContextStore.getTableSchemaName() || null;
		}
		let namedContextsObj = {
			'startingContext': [{
				'recordId': startingRecordId,
				'tableSchemaName': startingTSN
			}],
			'page': [{
				'recordId': pageRecordId,
				'tableSchemaName': pageTableSchemaName
			}],
			'application': [{
				'recordId': ContextStore.getApplicationId(),
				'tableSchemaName': 'applications'
			}],
			'installation': [{
				'recordId': ContextStore.getInstallationId(),
				'tableSchemaName': 'installations'
			}],
			'currentUser': [{
				'recordId': AuthenticationStore.getUserId(),
				'tableSchemaName': 'users'
			}],
			'pagePageRecord': [{
				'recordId': pageRecordId,
				'tableSchemaName': pageTableSchemaName
			}],
			'pageCurrentRecord': [{
				'recordId': startingRecordId,
				'tableSchemaName': startingTSN
			}]
		};
		
		if(pageRenderObj && pageRenderObj.componentType === 'page' && pageRenderObj.renderParentId) {
			let parentPage = typeof RenderStore !== 'undefined' && RenderStore ? RenderStore.get(pageRenderObj.renderParentId) : null;
			while (typeof RenderStore !== 'undefined' && RenderStore && parentPage && parentPage.componentType !== 'page' && parentPage.renderParentId) {
				parentPage = RenderStore.get(parentPage.renderParentId);
			}
			if (parentPage) {
				namedContextsObj['pageBelow'] = [{
					'recordId': parentPage.dataRecordId,
					'tableSchemaName': parentPage.dataTableSchemaName
				}];
			}
		}

		let recordSets = {};
		if(queryJSON) {
			let queryObj = ObjectUtils.getObjFromJSON(queryJSON);
			let recordSetRaw = RecordSetStore ? RecordSetStore.getRecordSetsInQuery(renderId, queryObj) : {};
			if(recordSetRaw) {
				Object.keys(recordSetRaw).forEach(recordSetName => {
					recordSets['recordSets_' + recordSetName] = recordSetRaw[recordSetName];
				});
			}
		} else {
			let onlyIncludeRecordsIn = null;
			if (actionjs) {
				onlyIncludeRecordsIn = actionjs.match(/recordSets_[a-zA-Z0-9\-_]*/g) || [];
			}
			let parentObj = RenderStore.get(renderId);
			while (parentObj && parentObj.renderParentId) {
				recordSets = Object.assign(RecordVariableUtils.recordSetRecursor({}, parentObj.renderId, onlyIncludeRecordsIn), recordSets);
				parentObj = RenderStore.get(parentObj.renderParentId);
			}
			if (parentObj) {
				recordSets = Object.assign(RecordVariableUtils.recordSetRecursor({}, parentObj.renderId, onlyIncludeRecordsIn), recordSets);
			}
		}

		namedContextsObj = Object.assign(namedContextsObj, recordSets);
		
		return namedContextsObj;
	}

	static getRecordStoreRecords(renderId, actionjs) {
		let renderObj = RenderStore.get(renderId) || {};
		let {dataRecordId: startingRecordId, dataTableSchemaName: startingTSN} = renderObj;
		let pageRecordId = null;
		let pageTableSchemaName = null;
		let pageRenderObj = null;
		if (renderId) {
			pageRenderObj = RenderStore.getPageRenderObj(renderId);
			pageRecordId = pageRenderObj ? pageRenderObj.dataRecordId : null;
			pageTableSchemaName = pageRenderObj ? pageRenderObj.dataTableSchemaName : null;
		} else {
			pageRecordId = ContextStore.getRecordId() || null;
			pageTableSchemaName = ContextStore.getTableSchemaName() || null;
		}
		let recordStoreRecords = {
		};


		let hasCurrentRecord = false;
		let hasPageRecord = false;
		let currRecordFields = {};
		let pageFields = {};

		// Get the interface values needed and any fields needed from them
		let regex = new RegExp(/\/\* intfce:([a-zA-Z0-9-_]*) fsn:([a-zA-Z0-9-_]*) \*\//g);
		let match = regex.exec(actionjs);
		while (match) {
			let intfc = match[1];
			let fsn = match[2];
			if(intfc === 'pageCurrentRecord') {
				hasCurrentRecord = true;
				currRecordFields[fsn] = true;
			} else if (intfc === 'pagePageRecord') {
				hasPageRecord = true;
				pageFields[fsn] = true;
			}
			match = regex.exec(actionjs);
		}

		if(hasCurrentRecord && startingTSN && startingRecordId) {
			recordStoreRecords[startingTSN] = {
				[startingRecordId]: {}
			};
			let currentRecord = RecordStore.getRecord(startingTSN, startingRecordId) || {};
			// Get any dirty values in the current record used in the action. (Ignore non-dirty ones)
			Object.keys(currRecordFields).forEach(fieldSchemaName => {
				let fieldValueInfo = currentRecord[fieldSchemaName];
				if(fieldValueInfo && fieldValueInfo.isDirty) {
					recordStoreRecords[startingTSN][startingRecordId][fieldSchemaName] = fieldValueInfo.value;
				}
			});
		}

		if(hasPageRecord && pageTableSchemaName && pageRecordId) {
			if(!recordStoreRecords[pageTableSchemaName]) {
				recordStoreRecords[pageTableSchemaName] = {};
			}
			if(!recordStoreRecords[pageTableSchemaName][pageRecordId]) {
				recordStoreRecords[pageTableSchemaName][pageRecordId] = {};
			}
			let pageRecord = RecordStore.getRecord(pageTableSchemaName, pageRecordId) || {};

			// Get any dirty values in the page record used in the action. (Ignore non-dirty ones)
			Object.keys(pageFields).forEach(fieldSchemaName => {
				let fieldValueInfo = pageRecord[fieldSchemaName];
				if(fieldValueInfo && fieldValueInfo.isDirty) {
					recordStoreRecords[pageTableSchemaName][pageRecordId][fieldSchemaName] = fieldValueInfo.value;
				}
			});
		}

		return recordStoreRecords;
	}

	/**
	 * Finds all of the record sets within a field/page or its children and adds them to the aggregator in namedContexts form appropriately.
	 * 
	 * @param {object} aggregator 
	 * @param {string} renderId 
	 */
	static recordSetRecursor(aggregator, renderId, onlyIncludeRecordsIn) {
		let renderObj = RenderStore.get(renderId);
		if (!renderObj) {
			return aggregator;
		}
		if (renderObj.recordSets && renderObj.recordSets.length) {
			renderObj.recordSets.forEach(recordSet => {
				let fullRecordSetName = 'recordSets_' + recordSet;
				if(!onlyIncludeRecordsIn || onlyIncludeRecordsIn.includes(fullRecordSetName)) {
					aggregator[fullRecordSetName] = RecordSetStore.getRecordSet(renderId, recordSet);
				}
			});
		}
	
		if(renderObj.children && renderObj.children.length) {
			renderObj.children.forEach(childRenderId => {
				aggregator = Object.assign({}, aggregator, RecordVariableUtils.recordSetRecursor(aggregator, childRenderId, onlyIncludeRecordsIn));
			});
		}
	
		return aggregator
	}
}