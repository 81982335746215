import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import {RecordConstants} from '../constants/record-constants';
import RenderConstants from '../constants/render-constants';
import AdminSettingsConstants from '../constants/admin-settings-constants';
import RecordSetConstants from '../constants/record-set-constants';

// import UIUtils from '../utils/ui-utils';

/**
 * Creates an Immutable.Map based Store
 * 
 * @class RecordStore
 * @extends {ReduceStore}
 */
class RecordStore extends ReduceStore {
	/**
	 * Set the initial state to an empty Immutable.Map
	 * 
	 * @returns {Immutable.Map}
	 * 
	 * @memberOf RecordStore
	 */
	getInitialState() {
		return Immutable.fromJS({
			records: {},
			recordCounts: {}
		});
	}

	/**
	 * Returns a single record
	 *
	 * @param {string} tableSchemaName
	 * @param {string} recordId
	 * @returns {Object|null}
	 *
	 * @memberOf RecordStore
	 */
	getRecord(tableSchemaName, recordId) {
		let state = this.getState();
		if (!state.hasIn(['records', tableSchemaName])) {
			return null;
		}
		let record = this.getState().getIn(['records', tableSchemaName, recordId]);
		if(record !== undefined){
			return record.toJS();
		} else {
			return null;
		}
	}

	/**
	 * Determines whether the record is new (e.g. a record in the
	 * process of being added) or not
	 *
	 * @param {*} tableSchemaName
	 * @param {*} recordId
	 * @return {*} 
	 * @memberof RecordStore
	 */
	isRecordNew(tableSchemaName, recordId) {
		let state = this.getState();
		// If the record doesn't even exist, it's probably "new" for our purposes
		return state.hasIn(['records', tableSchemaName, recordId, 'recordId', 'isDirty']) ? state.getIn(['records', tableSchemaName, recordId, 'recordId', 'isDirty']) : true;
	}

	/**
   * Returns data with key of fieldSchemaName in record
   *
   * @param  {string} tableSchemaName
   * @param  {string} recordId
   * @param  {string} fieldSchemaName
   * @return {Object|null} returnObj
   */
	getValueByFieldSchemaName(tableSchemaName, recordId, fieldSchemaName){
		let state = this.getState();

		return state.hasIn(['records', tableSchemaName, recordId, fieldSchemaName])
			? state.getIn(['records', tableSchemaName, recordId, fieldSchemaName])
			: null;
	}

	/**
	 * Returns a all the records with the specified tableSchemaName
	 *
	 * @param {string} tableSchemaName
	 * @param {string} recordId
	 * @returns {Object|null}
	 *
	 * @memberOf RecordStore
	 */
	getRecords(tableSchemaName) {
		let state = this.getState();
		if (!state.hasIn(['records', tableSchemaName])) {
			return null;
		}
		return this.getState().getIn(['records', tableSchemaName]).toJS();
	}

	getRecordCount(tableSchemaName) {
		let state = this.getState();
		if (!state.hasIn(['recordCounts', tableSchemaName])) {
			return null;
		}
		return this.getState().getIn(['recordCounts', tableSchemaName]);
	}

	getRecordCounts() {
		let state = this.getState();
		return state.get('recordCounts').toJS();
	}

	/**
	 * Checks to see if a tableSchemaName has been loaded in the store
	 * 
	 * @param {string} tableSchemaName
	 * @returns {boolean}
	 * 
	 * @memberOf RecordStore
	 */
	hasTableSchemaName(tableSchemaName) {
		return this.getState().hasIn(['records', tableSchemaName]);
	}

	/**
	 * Checks to see if a specific record has been loaded in the store
	 * 
	 * @param {string} tableSchemaName
	 * @param {string} recordId
	 * @returns {boolean}
	 * 
	 * @memberOf RecordStore
	 */
	hasRecord(tableSchemaName, recordId) {
		return this.getState().hasIn(['records', tableSchemaName, recordId]);
	}

	/**
	 * Determines whether a specific field in a specific record is dirty or not
	 * @param {string} tableSchemaName 
	 * @param {string} recordId 
	 * @param {object} field 
	 * @returns {boolean}
	 */
	fieldIsDirty(tableSchemaName, recordId, field) {
		if(!tableSchemaName || !recordId || !field) {
			return false;
		}
		let {fieldSchemaName, fieldTypeId} = field;
		// Ignore invalid fields and ccontent dropdowns
		if(!fieldSchemaName || fieldTypeId === 'bb5bedc3-44d1-4e4c-9c40-561a675173b1') {
			return false;
		}
		let state = this.getState();
		if(state.hasIn(['records', tableSchemaName, recordId, fieldSchemaName])) {
			return state.getIn(['records', tableSchemaName, recordId, fieldSchemaName, 'isDirty']);
		} else {
			return false;
		}
	}

	/**
	 * Determines whether a specific record is dirty or not
	 * @param {string} tableSchemaName 
	 * @param {string} recordId 
	 * @returns {boolean}
	 * 
	 * @memberOf RecordStore
	 */
	isDirty(tableSchemaName, recordId) {
		if(!tableSchemaName || !recordId) {
			return false;
		}
		let state = this.getState();
		let FieldStore = require('./field-store').default;
		if(state.hasIn(['records', tableSchemaName, recordId])) {
			let value = state.getIn(['records', tableSchemaName, recordId]);
			let isDirty = !!value.find((val, fieldSchemaName) => {
				if(fieldSchemaName !== 'recordId') {
					let field = FieldStore.getByFieldSchemaName(fieldSchemaName, tableSchemaName);
					// Skip content dropdowns, as requested by Client Services as part of ticket "15656 - Unsaved Changes Warning"
					if (field && field.fieldType === 'bb5bedc3-44d1-4e4c-9c40-561a675173b1') {
						return false;
					}
				}
				return val.get('isDirty');
			});
			return isDirty;
		} else {
			return false;
		}
	}

	/**
	 * Responds to displatched Actions with an Immutable map as the payload
	 * 
	 * @param {Immutable.Map} state
	 * @param {Immutable.Map} action
	 * @returns {Immutable.Map} newState
	 * 
	 * @memberOf RecordStore
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			case RecordConstants.RECORDS_LOADED: {
				let forceClean = action.get('forceClean') ? true : false;
				return state.withMutations(function(state) {
					action.get('records').forEach(function (incomingTableRecords, tsn) {
						if (state.hasIn(['records', tsn])) {
							state.setIn(['records', tsn], state.getIn(['records', tsn]).withMutations(function(currentTableRecords) {
								//Loop over the incoming fields and either merge them with the records or add new records
								incomingTableRecords.forEach(function(incomingRecord, recordId){
									if (currentTableRecords.has(recordId)) {
										currentTableRecords.set(recordId, currentTableRecords.get(recordId).withMutations(function (currentRecord) {
											//Loop over the incoming records fields and merge them with the exising record
											incomingRecord.forEach(function(incomingFieldValue, fieldId) {
												//If the field exists and has already been changed then we are in the conflict state
												//else we need to update the state to the new value
												if (incomingFieldValue && typeof incomingFieldValue === 'object') {
													//This is an error
													var errorMessage = 'Could not load field value.';
													if (incomingFieldValue.get('uiMessage')) {
														errorMessage = incomingFieldValue.get('uiMessage');
													} else if (incomingFieldValue.get('message')) {
														errorMessage = incomingFieldValue.get('message');
													}
													if (currentRecord.has(fieldId)) {
														currentRecord.setIn([fieldId, 'error'], errorMessage);
													} else {
														currentRecord.set(fieldId, new Immutable.Map({
															'value': undefined,
															'isDirty': false,
															'inConflict': false,
															'conflictValue': null,
															'originalValue': null,
															'error': errorMessage}));
													}
												} else {
													//This is an incoming field value

													/**
													 * We are in conflict if:
													 * We are not forcing a clean load AND
													 * The current record already has this field AND
													 * The current record value is dirty AND
													 * the value from the current record is not the same as the incoming value
													 */
													if (!forceClean && 
														currentRecord.has(fieldId) && currentRecord.get(fieldId).get('isDirty') && 
														currentRecord.get(fieldId).get('value') !== incomingFieldValue) {
														currentRecord.set(fieldId, new Immutable.Map({
															'value': currentRecord.get(fieldId).get('value'),
															'isDirty': true,
															'inConflict': true,
															'conflictValue': incomingFieldValue,
															'originalValue': currentRecord.get(fieldId).get('originalValue')}));
													} else {
														currentRecord.set(fieldId, new Immutable.Map({
															'value': incomingFieldValue,
															'isDirty': false,
															'inConflict': false,
															'conflictValue': null,
															'originalValue': null,
															'error': null}));
													}
												}
											});
										}));
									} else {
										//Set the record into the current fieldtype  records by Loop over the incoming records fields 
										//and creating new maps that contain all the field properties
										currentTableRecords.set(recordId, incomingRecord.withMutations(function(record) {
											record.forEach(function(field, fieldId) {
												//We need to check the value for errors
												if (field && typeof field === 'object') {
													//This is an error
													var errorMessage = 'Could not load field value.';
													if (field.get('uiMessage')) {
														errorMessage = field.get('uiMessage');
													} else if (field.get('message')) {
														errorMessage = field.get('message');
													}
													record.set(fieldId, new Immutable.Map({
														'value': undefined,
														'isDirty': false,
														'inConflict': false,
														'conflictValue': null,
														'originalValue': null,
														'error': errorMessage}));
												} else {
													record.set(fieldId, new Immutable.Map({
														'value': field,
														'isDirty': false,
														'inConflict': false,
														'conflictValue': null,
														'originalValue': null}));
												}
											});
										}));
									}
								});

							}));
						} else {
							//If the field type was never set in the record store loop over all the incoming records and add them
							state.setIn(['records', tsn], incomingTableRecords.map(function(record) {
								return record.withMutations(function (record) {
									record.forEach(function(field, fieldId) {
										if (field && typeof field === 'object') {
											//This is an error
											var errorMessage = 'Could not load field value.';
											if (field.get('uiMessage')) {
												errorMessage = field.get('uiMessage');
											} else if (field.get('message')) {
												errorMessage = field.get('message');
											}
											record.set(fieldId, new Immutable.Map({
												'value': undefined,
												'isDirty': false,
												'inConflict': false,
												'conflictValue': null,
												'originalValue': null,
												'error': errorMessage}));
										} else {
											record.set(fieldId, new Immutable.Map({
												'value': field,
												'isDirty': false,
												'inConflict': false,
												'conflictValue': null,
												'originalValue': null}));
										}
									});
								});
							}));
						}
					});
				});
			}
			case RecordConstants.SET_SELECTED_RECORD_SET: {
				let tableSchemaName = action.get('dataTableSchemaName');
				let recordId = action.get('dataRecordId');
				if (recordId && state.hasIn(['records', tableSchemaName]) &&
					state.getIn(['records', tableSchemaName]).has(recordId)) {
					let setTableSchemaName = action.get('setTableSchemaName');
					let recordSetArray = action.get('recordSetArray');
					let fieldId = action.get('fieldSchemaName');
					// First, check if we're dirty, in which case we need to keep the original originalValue
					let isDirty = state.getIn(['records', tableSchemaName, recordId, fieldId, 'isDirty']);
					let oldValue;
					if(isDirty) {
						oldValue = state.hasIn(['records', tableSchemaName, recordId, fieldId, 'originalValue']) ? state.getIn(['records', tableSchemaName, recordId, fieldId, 'originalValue']) : '';
					} else {
						oldValue = state.hasIn(['records', tableSchemaName, recordId, fieldId, 'value']) ? state.getIn(['records', tableSchemaName, recordId, fieldId, 'value']) : '';
					}

					let oldValueObj = {};
					try {
						oldValueObj = oldValue ? JSON.parse(oldValue) : {};
					} catch(err) {
						console.warn('Error parsing old value in citdev-flux-record. Value was', oldValue);
					}
					let startingRelatedRecordsJSON = action.get('startingRelatedRecordsJSON') || oldValueObj.startingRelatedRecordsJSON || '[]';
					let newRecord = recordSetArray.map(recordId => {
						return {recordId: recordId, tableSchemaName: setTableSchemaName};
					});
					let newRecordJSON = JSON.stringify(newRecord);
					let newValue = JSON.stringify({
						startingRelatedRecordsJSON,
						newRecordJSON
					});
					return state.withMutations(function (state) {
						let records = state.getIn(['records', tableSchemaName]).withMutations(function (records) {
							records.set(recordId, records.get(recordId).withMutations((record) => {
								record.set(fieldId, new Immutable.Map({
									'value': newValue,
									'isDirty': true,
									'inConflict': false,
									'conflictValue': null,
									'originalValue': oldValue}));
							}));
						});
						state.setIn(['records', tableSchemaName], records);
					});
				} else {
					return state;
				}
			}

			case RenderConstants.REFRESH_FIELD:
			case RenderConstants.GRID_UPDATE:
			case AdminSettingsConstants.OVERLAY_CHANGE_WITH_RECALC:
			case RenderConstants.INIT_PAGE: {
				let grids = action.get('grids');
				if(!grids) {
					return state;
				}
				return state.withMutations(state => {
					grids.forEach(grid => {
						let dataRecordId = grid.get('dataRecordId');
						let dataTableSchemaName = grid.get('dataTableSchemaName');
						let recordFields = grid.get('recordFields');
						if(recordFields) {
							recordFields.forEach((value, fsn) => {
								let oldField = state.hasIn(['records', dataTableSchemaName, dataRecordId, fsn])
									? state.getIn(['records', dataTableSchemaName, dataRecordId, fsn])
									: undefined;
								//IF we are not already dirty and the original value has not
								//been set then set it
								if (!oldField) {
									state.setIn(['records', dataTableSchemaName, dataRecordId, fsn], Immutable.fromJS({
										value,
										isDirty: false,
										originalValue: null,
										inConflict: false,
										conflictValue: null,
									}));
								} else if (oldField.get('originalValue') === value) {
									//If the new value is the original value then
									//revert it back and clear the isDirty flag
									state.setIn(['records', dataTableSchemaName, dataRecordId, fsn, 'value'], value);
									state.setIn(['records', dataTableSchemaName, dataRecordId, fsn, 'isDirty'], false);
									state.setIn(['records', dataTableSchemaName, dataRecordId, 'originalValue'], null);
								} else {
									state.setIn(['records', dataTableSchemaName, dataRecordId, fsn, 'value'], value);
									state.setIn(['records', dataTableSchemaName, dataRecordId, fsn, 'isDirty'], true);
								}
								
							})
						}
					});
				});

			}

			case RecordSetConstants.SET_SELECTED_CONTENT_TAB: {

				let dataTableSchemaName = action.get('dataTableSchemaName');
				let dataRecordId = action.get('dataRecordId');
				let fieldSchemaName = action.get('fieldSchemaName');

				let value = action.get('value');
				let valueJSON = value ? JSON.stringify({
					fieldId: value.get('fieldId'),
					recordId: value.get('recordId'),
					tableSchemaName: value.get('tableSchemaName')
				}) : null;
				
				if (dataRecordId && dataTableSchemaName && state.hasIn(['records', dataTableSchemaName, dataRecordId])) {
					// First, check if we're dirty, in which case we need to keep the original originalValue
					let alreadyDirty = state.getIn(['records', dataTableSchemaName, dataRecordId, fieldSchemaName, 'isDirty']);
					let recordIsNew = state.hasIn(['records', dataTableSchemaName, dataRecordId, 'recordId', 'isDirty'])
						? state.getIn(['records', dataTableSchemaName, dataRecordId, 'recordId', 'isDirty'])
						: true;
					let oldValue = alreadyDirty 
						? state.getIn(['records', dataTableSchemaName, dataRecordId, fieldSchemaName, 'originalValue'])
						: state.getIn(['records', dataTableSchemaName, dataRecordId, fieldSchemaName, 'value']);


					return state.withMutations(function (state) {
						let records = state.getIn(['records', dataTableSchemaName]).withMutations(function (records) {
							records.set(dataRecordId, records.get(dataRecordId).withMutations((record) => {
								record.set(fieldSchemaName, new Immutable.Map({
									'value': valueJSON,
									'isDirty': oldValue !== valueJSON || recordIsNew, // Default to this being dirty if it's a new record
									'inConflict': false,
									'conflictValue': null,
									'originalValue': (oldValue === valueJSON || recordIsNew) ? null : oldValue}));
							}));
						});
						state.setIn(['records', dataTableSchemaName], records);
					});
				} else {
					return state;
				}
			}

			case RecordConstants.RECORD_UPDATE: {

				let tableSchemaName = action.get('tableSchemaName');
				let recordId = action.get('recordId');
				let forceClean = action.get('forceClean');
				//We can not update a record that didn't exist in the store
				if (state.hasIn(['records', tableSchemaName, recordId])) {
					return state.withMutations(function(state) {
						let records = state.getIn(['records', tableSchemaName]).withMutations(function(records) {
							records.set(recordId, records.get(recordId).withMutations(function(record) {
								action.get('values').forEach(function(newValue, fieldId) {
									if (typeof newValue === 'object' && newValue !== null) {
										newValue = JSON.stringify(newValue);
									}
									//If the field has a value then mark the field as dirty and set the originalValue
									if (record.has(fieldId)) {
										record.set(fieldId, record.get(fieldId).withMutations(function(field){
											if(forceClean) {
												field.set('value', newValue);
												field.set('isDirty', false);
												field.set('originalValue', null);
											} else {
												//IF we are not already dirty and the original value has not
												//been set then set it
												if (!field.get('isDirty') && 
												field.get('originalValue') === null) {
													field.set('originalValue', field.get('value'));
												}
												
												//If the new value is the original value then
												//revert it back and clear the isDirty flag
												if (field.get('originalValue') === newValue) {
													field.set('value', newValue);
													field.set('isDirty', false);
													field.set('originalValue', null);
												} else {
													field.set('value', newValue);
													field.set('isDirty', true);
												}
											}
										}));
									} else {
										record.set(fieldId, new Immutable.Map({
											'value': newValue,
											'isDirty': forceClean ? false : true,
											'inConflict': false,
											'conflictValue': null,
											'originalValue': forceClean ? null : ''}));
									}
								});

							}));

						});
						state.setIn(['records', tableSchemaName], records);
					});
				} else {
					return state;
				}
			}

			case RecordConstants.NEW_RECORDS_CREATED: {
				return state.withMutations((state) => {
					action.get('records').forEach((records, tsn) => {
						records.forEach((record, recordId) => {
							// This should always be a new record, so don't check for existing ones
							let newRecord = new Immutable.Map().withMutations(newRecord => {
								record.forEach((field, fieldId) => {
									//We need to check the value for errors
									if (field && typeof field === 'object') {
										//This is an error
										var errorMessage = 'Could not load field value.';
										if (field.get('uiMessage')) {
											errorMessage = field.get('uiMessage');
										} else if (field.get('message')) {
											errorMessage = field.get('message');
										}
										newRecord.set(fieldId, new Immutable.Map({
											'value': undefined,
											'isDirty': false,
											'inConflict': false,
											'conflictValue': null,
											'originalValue': null,
											'error': errorMessage}));
									} else {
										newRecord.set(fieldId, new Immutable.Map({
											'value': field,
											// Don't mark empty fields as dirty
											'isDirty': (field === null || typeof field === 'undefined') ? false : true,
											'inConflict': false,
											'conflictValue': null,
											'originalValue': null}));
									}
								});
							});

							state.setIn(['records', tsn, recordId], newRecord);
						});
					});
				});
			}

			case RecordConstants.GET_TABLE_COUNT: {
				let tableCounts = action.get('tableCounts');
				return state.mergeIn(['recordCounts'], tableCounts);
			}

			// Clear the whole record store, but not the counts
			// @TODO this is temporary until we can use the socket.io stuff to update records.
			case 'ALL_RECORD_DATA_RECEIVED_CHANGE_WITH_PATH_CHANGE':
			case RecordConstants.RECORDS_RESET: {
				if(state.hasIn(['records', 'securityGroup'])) {
					return state.set('records', Immutable.fromJS({securityGroup: state.getIn(['records', 'securityGroup'])}));
				} else {
					return state.set('records', Immutable.Map());
				}
			}
			default: {
				return state;
			}
		}
	}
}

const instance = new RecordStore(AppDispatcher);
export default instance;
