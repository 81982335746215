/*global citDev:true */
import React,{Component} from 'react';
import PropTypes from 'prop-types';
import { FieldStore } from '../stores';
import uuid from 'uuid';

/**
 * Component that renders the proper FieldTypeSetting
 *
 * @class FieldTypeSettingUI
 * @extends {Component}
 */
export default class FieldTypeSettingsUI extends Component {

	constructor(props) {
		super(props);
		this.state = {
			id: uuid.v4(),
			renderId: uuid.v4()
		};
	}

	componentDidUpdate(prevProps) {
		// If the setting record ID has changed, update the ID and renderId in the state
		if(prevProps && (!prevProps.setting || !prevProps.setting.recordId || prevProps.setting.recordId !== this.props.setting.recordId)) {
			setTimeout(() => {
				this.setState({
					id: uuid.v4(),
					renderId: uuid.v4()
				});
			});
		}
	}

	/**
	 * Render the FieldTypeSetting
	 *
	 * @returns React
	 *
	 * @memberOf FieldTypeSettingUI
	 */
	render() {
		let {setting, value, onChange, fieldType, namedContexts, label, dataRecordId, dataTableSchemaName} = this.props;
			
		// remove descriptive text if it's to be skipped.
		if(this.props.skipDescriptiveText) {
			setting['descriptiveText'] = '';
		}
		
		let componentProps = Object.assign({}, setting, 
			{
				onChange: onChange, 
				value: value, 
				fieldType: fieldType, 
				id: this.state.id, 
				renderId: this.state.renderId, 
				namedContexts: namedContexts, 
				label: label,
				dataRecordId: dataRecordId,
				dataTableSchemaName: dataTableSchemaName
			});

		let componentName = setting.editVariant;
		
		if(!componentName){
			componentName = FieldStore.getDefaultVariantComponentName(null, 'edit', fieldType);
		}

		if (!componentName || !citDev[componentName]) {
			return null
		} else {
			return React.createElement(citDev[componentName], componentProps, null);

		}
	}
}
if ('development' === process.env.NODE_ENV) {
	FieldTypeSettingsUI.propTypes = {
		setting: PropTypes.object.isRequired,
		showParts: PropTypes.bool,
		value: PropTypes.any,
		onChange: PropTypes.func,
		recordId: PropTypes.string,
		fieldType: PropTypes.string,
		tableSchemaName: PropTypes.string,
		skipDescriptiveText: PropTypes.bool
	};
}
