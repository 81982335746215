import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import {PatternConstants} from '../constants/pattern-constants';

/**
 * Core store that contains pattern records
 *
 * @class PatternStore
 * @extends {ReduceStore}
 */
class PatternStore extends ReduceStore {
	/**
	 * getInitialState - initial state for PatternStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.Map({
			allPulledFromDatabase: false,
			records: Immutable.Map()
		});
	}

	/**
	 * Called every time an action is dispatched (for any store)
	 *
	 * @param {Object} state - current state of this store
	 * @param {Object} action - action that's coming in
	 * @returns {Object} new state after the action has been processed.
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			case PatternConstants.PATTERN_PULL_FROM_DATABASE_ALL: {
				// Set the records sub-key in the state to : 
				// start with what we got for the new patterns, and then merge in what we have in the state.
				//   let newstate = state.set('records', Immutable.fromJS(tables).mergeDeep(state.get('records')));
				let newstate = state.set('records', action.get('patternObject'));
				newstate = newstate.set('allPulledFromDatabase', true);
				return newstate;
			}
			case PatternConstants.PATTERN_PULL_ERROR: {
				console.error('Pattern Store Error: ' + action.get('error'));
				return state;
			}
			default: {
				return state;
			}
		}
	}

	/**
	 * Gets the entire store as an object
	 *
	 * @returns {Object} current store as an object
	 */
	getAll() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			return this.getState().get('records').toJS();
		} else {
			// console.warn('pullFromDatabaseAll not run, did you mean get()?');
			return undefined;
		}
	}

	/**
	 * Gets the entire store as an array
	 *
	 * @returns {Array} current store as an array
	 */
	getAllArray() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			return this.getState().get('records').toList().toJS();
		} else {
			// console.warn('pullFromDatabaseAll not run, did you mean get()?');
			return undefined;
		}
	}

	/**
	 * Gets an individual record from the store
	 * @param {string} recordId UUID of the record to get
	 * @returns {Object} current store as an object
	 */
	get(recordId) {
		if (this.getState().get('records').has(recordId)) {
			return this.getState().get('records').get(recordId).toJS();
		} else {
			return undefined;
		}
	}

	/**
	 * Get whether all have been pulled from the database or not yet.
	 * 
	 * @returns {boolean}
	 */
	allPulledFromDatabase() {
		return this.getState().get('allPulledFromDatabase');
	}
}

const instance = new PatternStore(AppDispatcher);
export default instance;