import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
// import AssistantNLP from '../utils/assistant-nlp-utils';
// import AssistantFields from '../utils/assistant-fields';
// import AssistantForms from '../utils/assistant-forms';
// import AssistantPages from '../utils/assistant-pages';
// import BlockUtils from '../utils/block-utils';
import AssistantResultsConstants from '../constants/assistant-results-constants';
// import FieldStore from '../stores/field-store';
// import FieldTypeStore from '../stores/field-type-store';
// import PageStore from '../stores/page-store';
// import AssistantResultsStore from '../stores/assistant-results-store';
// import AdminSettingsStore from '../stores/admin-settings-store';
// import {UIUtils} from '../utils/ui-utils';

let _instance = null;

/*global PatternStore */

/**
 * Actions for the UI store that contains Assistant Search Result records.
 *
 * @class AssistantResultsActions
 */
const AssistantResultsActions = {
	// Legacy function; no longer in use, but preserved for now. Commented out to remove circular reference issue.
	patternSearch(input, settingSchemaName, recordId, tableSchemaName, parentRecordId, parentTableSchemaName, parentLabel) {
		// let inputParsed = AssistantNLP.processInput(input),
		// 	NLPValue = (inputParsed.quotedValues ? inputParsed.quotedValues[0] : null),
		// 	inputArray = inputParsed.inputArray,
		// 	searchResults = [];
	
		// if(tableSchemaName === 'field') {
		// 	let fieldObj = FieldStore.get(recordId),
		// 		fieldLabel = BlockUtils.getName(recordId, tableSchemaName);
		// 	if(!fieldObj) { 
		// 		return false;
		// 	}

		// 	let fieldTypeId = fieldObj.fieldType,
		// 		fieldTypeObj = FieldTypeStore.get(fieldTypeId);

		// 	if(inputArray.length >= 1 || settingSchemaName){

		// 		//Return all the Results at once: 
		// 		searchResults = AssistantFields.attachResults(recordId, tableSchemaName, fieldLabel, fieldTypeId, parentRecordId, parentTableSchemaName, parentLabel, inputParsed);
		// 		searchResults = searchResults.concat(AssistantFields.createResults(parentRecordId,parentTableSchemaName, settingSchemaName, NLPValue, inputParsed));
		// 		searchResults = searchResults.concat(AssistantFields.detachResults(recordId, fieldLabel, fieldTypeId, tableSchemaName, parentRecordId, inputParsed));
		// 		searchResults = searchResults.concat(AssistantFields.deleteResults(recordId, inputParsed));
		// 		searchResults = searchResults.concat(AssistantFields.updateResults(recordId, fieldLabel, settingSchemaName, fieldTypeObj, fieldTypeId, NLPValue, inputParsed));
		// 	}
		// } else if(tableSchemaName === 'page'){

		// 	let pageObj = PageStore.get(recordId),
		// 		pageName = (pageObj && pageObj.name) ? pageObj.name : '[ No Page Name ]',
		// 		pageTableSchemaName = (pageObj && pageObj.tableSchemaName) ? pageObj.tableSchemaName : undefined;

		// 	if(inputArray.length >= 1){

		// 		//Return all the Results at once: 
		// 		searchResults = AssistantPages.attachResults(recordId, pageName, tableSchemaName, inputParsed);
		// 		searchResults = searchResults.concat(AssistantPages.createResults(recordId, tableSchemaName, parentTableSchemaName, settingSchemaName, NLPValue, inputParsed));
		// 		searchResults = searchResults.concat(AssistantPages.detachResults(recordId, tableSchemaName, pageName, inputParsed));
		// 		searchResults = searchResults.concat(AssistantPages.deleteResults(recordId, pageTableSchemaName, inputParsed));
		// 		searchResults = searchResults.concat(AssistantForms.createResults(recordId, inputParsed));
		// 	}
		// }

		// AppDispatcher.dispatch(Immutable.fromJS({
		// 	type: AssistantResultsConstants.ASSISTANT_UPSERT_RESULTS,
		// 	results: searchResults,
		// 	clearResults: true
		// }));
	},

	/**
	 * Run a Google NLP API Search against the Input that's in the Appearance Assistant Store and upsert results
	 * No longer in use; maintained for legacy purposes, will be removed in later stages.
	 * 
	 * @param {string} input The input being analyzed
	 * @param {string} settingSchemaName The name of any setting schema name being upscored
	 * @param {string} recordId The ID of the record from the current context
	 * @param {string} tableSchemaName The schema name of the table from the current context
	 * @param {string} parentRecordId The ID of the parent record
	 * @param {string} parentTableSchemaName The table schema name of the parent record
	 * @param {string} parentLabel  The label of the parent record
	 */
	googleNLPAPISearch(input) {

		// let settingSchemaName = AdminSettingsStore.getSettingSchemaName(),
		// 	recordId = AdminSettingsStore.getRecordId(),
		// 	tableSchemaName = AdminSettingsStore.getTableSchemaName(),
		// 	parentRecordId = AdminSettingsStore.getParentRecordId(),
		// 	parentTableSchemaName = AdminSettingsStore.getParentTableSchemaName(),
		// 	parentLabel = BlockUtils.getName(parentRecordId, parentTableSchemaName);

		// if(!input) {
		// 	return;
		// }

		// // Process the input and get the patterns array from it
		// AssistantNLP.processInputNlp(input)
		// .then((nlpResult) => {

		// 	// If there's no NLP result, return null
		// 	if (!nlpResult) {
		// 		return null;
		// 	}

		// 	// Pull the patterns and connectionId values out of nlpResult
		// 	var {patterns, connectionId} = nlpResult;

		// 	// If this is not the most recent connection, return null
		// 	if (AssistantResultsStore.getMostRecentConnection() !== connectionId) {
		// 		return null;
		// 	}

		// 	// Get the fieldObj and fieldLabel and clone the original record ID in case we change recordId
		// 	let fieldObj = FieldStore.get(recordId),
		// 		fieldLabel = BlockUtils.getName(recordId, tableSchemaName),
		// 		origRecordId = recordId;
			
		// 	// If there's no fieldObj, instantiate it to an empty object
		// 	if (!fieldObj) {
		// 		fieldObj = {};
		// 	}

		// 	// Get the fieldTypeID of the fieldObj and the corresponding field type object. Instantiate toReturn to an empty array
		// 	let fieldTypeId = fieldObj.fieldType,
		// 		fieldTypeObj = FieldTypeStore.get(fieldTypeId),
		// 		toReturn = [];

		// 	// For each proto-pattern, find the best matching pattern
		// 	patterns.forEach((inputParsed, index) => {
		// 		let NLPValue = (inputParsed.quotedValues ? inputParsed.quotedValues[0] : null),
		// 			inputArray = inputParsed.inputArray;
				
		// 		// The 'field' keyword is just consistently breaking basically all the things and is in timeout for now. 
		// 		// It can go play with the other kids once all patterns which affect fields match this keyword.
		// 		inputArray = inputArray.filter(input => input && input.toLowerCase() !== 'field');
		// 		if (inputArray.length) {
		// 			inputParsed.inputArray = inputArray;
		// 			inputParsed.inputString = inputArray.join(' ');
		// 		}

		// 		// Initialize searchResults to an empty array
		// 		var searchResults = [];

		// 		// Use the AssistantFields matches based on the specified operation if tableSchemaName is field and concatenate to searchResults
		// 		if(tableSchemaName === 'field') {
		// 			if (inputParsed['operation'] && inputParsed['operation']['Attach']) {
		// 				searchResults = searchResults.concat(AssistantFields.attachResults(recordId, tableSchemaName, fieldLabel, fieldTypeId, parentRecordId, parentTableSchemaName, parentLabel, inputParsed));
		// 			}
		// 			if (inputParsed['operation'] && inputParsed['operation']['Create']) {
		// 				searchResults = searchResults.concat(AssistantFields.createResults(parentRecordId,parentTableSchemaName, settingSchemaName, NLPValue, inputParsed));
		// 			}
		// 			if (inputParsed['operation'] && inputParsed['operation']['Detach']) {
		// 				searchResults = searchResults.concat(AssistantFields.detachResults(recordId, fieldLabel, fieldTypeId, tableSchemaName, parentRecordId, inputParsed));
		// 			}
		// 			if (inputParsed['operation'] && inputParsed['operation']['Delete']) {
		// 				searchResults = searchResults.concat(AssistantFields.deleteResults(recordId, inputParsed));
		// 			}
		// 			if (inputParsed['operation'] && inputParsed['operation']['Update']) {
		// 				searchResults = searchResults.concat(AssistantFields.updateResults(recordId, fieldLabel, settingSchemaName, fieldTypeObj, fieldTypeId, NLPValue, inputParsed));
		// 			}
		// 		} else if(tableSchemaName === 'page'){

		// 			// Use the AssistantPages and possibly AssistantForms matches based on the specified operation if tableSchemaName is page and concatenate to searchResults

		// 			// Instantiate the page variables passed in to the util functions
		// 			let pageObj = PageStore.get(recordId),
		// 				pageName = (pageObj && pageObj.name) ? pageObj.name : '[ No Page Name ]',
		// 				pageTableSchemaName = (pageObj && pageObj.tableSchemaName) ? pageObj.tableSchemaName : undefined;

		// 			if (inputParsed['operation'] && inputParsed['operation']['Attach']) {
		// 				searchResults = searchResults.concat(AssistantPages.attachResults(recordId, pageName, tableSchemaName, inputParsed));
		// 			}
		// 			if (inputParsed['operation'] && inputParsed['operation']['Create']) {
		// 				searchResults = searchResults.concat(AssistantPages.createResults(origRecordId, tableSchemaName, parentTableSchemaName, settingSchemaName, NLPValue, inputParsed));
		// 				searchResults = searchResults.concat(AssistantForms.createResults(origRecordId, inputParsed));
		// 			}
		// 			if (inputParsed['operation'] && inputParsed['operation']['Detach']) {
		// 				searchResults = searchResults.concat(AssistantPages.detachResults(recordId, tableSchemaName, pageName, inputParsed));
		// 			}
		// 			if (inputParsed['operation'] && inputParsed['operation']['Delete']) {
		// 				searchResults = searchResults.concat(AssistantPages.deleteResults(recordId, pageTableSchemaName, inputParsed));
		// 			}
		// 			if (inputParsed['operation'] && inputParsed['operation']['Update']) {
		// 				searchResults = searchResults.concat(AssistantFields.updateResults(recordId, fieldLabel, settingSchemaName, fieldTypeObj, fieldTypeId, NLPValue, inputParsed));
		// 			}
		// 		}
		// 		// Sort the search results by their score.
		// 		searchResults.sort((a,b) => {
		// 			if (a.score < b.score) return 1;
		// 			if (b.score < a.score) return -1;
		// 			var len = inputArray.length;
		// 			// Prioritize leftmost keyword matches where there's a tie in score
		// 			for (var i = 0; i < len; i++) {
		// 				if (a.searchable && a.searchable.indexOf(inputArray[i]) > -1 &&
		// 					b.searchable && b.searchable.indexOf(inputArray[i]) === -1) {
		// 					return -1;
		// 				}
		// 				if (b.searchable && b.searchable.indexOf(inputArray[i]) > -1 &&
		// 					a.searchable && a.searchable.indexOf(inputArray[i]) === -1) {
		// 					return 1;
		// 				}
		// 			}
		// 			return 0;
		// 		});


		// 		// var topScore = 0, newSearchResults = [], topResult = null;

		// 		// Get the best guess matched pattern for this proto-pattern
		// 		var topResult = null;
		// 		if (searchResults[0]) {
		// 			// topScore = searchResults[0].score;
		// 			// newSearchResults = searchResults.filter(searchResult => searchResult.score === topScore);
		// 			// if (newSearchResults.length === 1) {
		// 			// 	topResult = newSearchResults[0];
		// 			// } else {
		// 			// 	// We may want to do further processing on this later
		// 			// 	// But for now we can just take the first one
		// 			// 	topResult = newSearchResults[0];
		// 			// }
		// 			topResult = searchResults[0];
		// 		}

		// 		// If there's a topResult, push it into the toReturn array
		// 		if (topResult) {
		// 			toReturn.push(topResult);
		// 		}

		// 		// Get the patternStore information regarding the top match pattern
		// 		var patternObj = searchResults[0] ? PatternStore.get(searchResults[0].patternId) : null;
				
		// 		// If this was a create operation, we want to override the field type for subsequent patterns, since we expect them to run on this
		// 		// (We may have to account for cases wherein two create operations are run and then an update operation is run on the first one)
		// 		if (patternObj && patternObj.operation === 'create') {
		// 			var settings = JSON.parse(patternObj.settings);
		// 			fieldTypeId = settings['fieldType'];
		// 			fieldTypeObj = FieldTypeStore.get(fieldTypeId);
		// 			recordId = 'New ' + fieldTypeId;
		// 		}
		// 	});

		// 	// Instantiate the variables that will be used for the new combo pattern
		// 	var scoreDescriptions = [], resultId = 'joint-', labelArr = [], score = 0, childResults = [];
			
		// 	// For each pattern matched within toReturn, push its information into the corresponding areas for the new combo pattern
		// 	toReturn.forEach((pattern) => {

		// 		childResults.push(pattern);
		// 		resultId += pattern.patternId ? (pattern.patternId + ';') : '';
		// 		scoreDescriptions = scoreDescriptions.concat(pattern.scoreDescription || []);
		// 		labelArr.push(pattern.label || '');
		// 		score += pattern.score || 0;
				
		// 		// Increment its score by one and notate that it's because it's the NLP's best guess
		// 		pattern.scoreDescription = (pattern.scoreDescription || []).concat(['+1 - Intent Analyzer']);
		// 		pattern.score++;
		// 	});

		// 	// If there is more than one pattern matched as the best guess, create a new combo pattern with the information ID'd above and add it into toReturn
		// 	if (toReturn.length > 1) {
		// 		var jointPattern = {
		// 			resultId,
		// 			label: labelArr.join(', and then '),
		// 			score,
		// 			scoreDescription: scoreDescriptions,
		// 			type: 'combo',
		// 			operation: 'combo',
		// 			childResults
		// 		};
		// 		toReturn.unshift(jointPattern);
		// 	}

		// 	// Upsert the patterns in toReturn
		// 	AppDispatcher.dispatch(Immutable.fromJS({
		// 		type: AssistantResultsConstants.ASSISTANT_UPSERT_RESULTS,
		// 		results: toReturn,
		// 		overrideProperties: true
		// 	}));
		// 	// console.log('I thought you said: ', toReturn.map((pattern) => {return pattern ? pattern.label : ''}).join(', and then '));
		// 	// return searchResults;
		// })
		// .catch(console.error);

		
	},

	/**
	 * Updates the analyzer results in the store
	 * 
	 * @param {object} analyzerResults The new analyzer results
	 */
	updateAnalyzerResults(analyzerResults) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: AssistantResultsConstants.ANALYZER_UPSERT_RESULTS,
			analyzerResults: analyzerResults
		}));
	},

	/**
	 * Updates the assistant search results in the store
	 * 
	 * @param {array} results Array of results
	 */
	updateAssistantResults(results) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: AssistantResultsConstants.ASSISTANT_UPSERT_RESULTS,
			clearResults: true,
			// results: [{type: 'attach', resultId: 'a', label: 'Dummy Result', scoreDescription: '', score: 0}]
			results: results
		}));
	},

	/**
	 * Update the most recent connection within the state
	 * 
	 * @param {string} connectionId The ID of the most recent connection
	 */
	onMostRecentConnectionChange(connectionId) {
		// console.log('Running onMostRecentConnectionChange with input', connectionId);
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AssistantResultsConstants.NEW_CONNECTION,
			'mostRecentConnection': connectionId
		}));
	},
};

export default AssistantResultsActions;