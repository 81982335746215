import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import ConsumableCreditsConstants from '../constants/consumable-credits-constants';

/**
 * Manages Consumable Credit information
 */
class ConsumableCreditsStore extends ReduceStore {
	/**
	 * getInitialState - initial state for ConsumableCreditsStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.fromJS({
			resources: {},
			requests: {},
			errors: {},
			allPulledFromDatabase: false
		});
	}

	/**
	 * Updates state store
	 *
	 * @param {Object} state The current state of the Store
	 * @param {Object} action
	 * @returns {Object} updated state
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			case ConsumableCreditsConstants.CONSUME_CREDIT: {
				let creditsAvailable = action.get('creditsAvailable');
				return state.withMutations((state) => {
					state.setIn(['requests', action.get('requestId')], creditsAvailable)
						.setIn(['resources', action.get('resource')], creditsAvailable);
				});
			}
			case ConsumableCreditsConstants.CONSUME_CREDIT_ERROR: {
				let updatedState = state.setIn(['requests', action.get('requestId')], false);
				return updatedState.setIn(['errors', action.get('requestId')], action.get('message'));
			}
			case ConsumableCreditsConstants.CONSUMABLE_CREDIT_LOAD: {
				let updatedState = state.mergeIn(['resources'], action.get('resources'));
				return updatedState.set('allPulledFromDatabase', true);
			}
			case ConsumableCreditsConstants.CONSUME_CREDIT_LIST_ERROR: {
				return state;
			}
			case ConsumableCreditsConstants.SET_RESOURCE_STATUS: {
				let resource = action.get('resource');
				return state.setIn(['resources',resource], action.get('creditsAvailable'));
			}
			default:
				return state;
		}
	}

	/**
	 * Gets whether everything has been pulled from the DB or not
	 */
	allPulledFromDatabase() {
		return this.getState().get('allPulledFromDatabase');
	}

	/**
	 * Gets the entire store as an object
	 *
	 * @returns {Object} current store as an object
	 */
	getAll() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			return this.getState().get('resources');
		} else {
			return undefined;
		}
	}

	/**
	 * Gets an individual resorce from the store
	 * @param {string} resource The consumable credit short name
	 * @returns {boolean} current state of the resource
	 */
	get(resource) {
		if (this.getState().get('resources').has(resource)) {
			return this.getState().get('resources').get(resource);
		} else {
			return true;
		}
	}

	/**
	 * Gets the status of a request from the store
	 * @param {string} requestId The requestId passed to or returned from the consume call
	 * @returns {boolean|undefined} current state of the request
	 */
	getRequestStatus(requestId) {
		if (this.getState().get('requests').has(requestId)) {
			return this.getState().get('requests').get(requestId);
		} else {
			return undefined;
		}
	}
}

const instance = new ConsumableCreditsStore(AppDispatcher);
export default instance;
