import FieldStore from '../stores/field-store';
import PageStore from '../stores/page-store';

export class BlockUtils {
	/**
	 * Get the name of the recordId 
	 * 
	 * @static
	 * @param {string} recordId  - of the block 
	 * @param {string} tableSchemaName - Optional of the Block 
	 * @return {string} blockName 
	 * @memberof BlockUtils
	 */
	static getName(recordId, tableSchemaName){
		let blockObj = {},
			blockName = '';
		
		if(recordId){
			if(!tableSchemaName){
				console.warn('Can not get Name of Block: ', recordId, 'with tableSchemaName type of: ', tableSchemaName)
				return undefined;
			} else if(tableSchemaName === 'page'){
				//Grab the Name of the Parent 
				blockObj = PageStore.get(recordId);
				blockName = (blockObj.name ? blockObj.name : '[ No Page Name ]');
			} else if(tableSchemaName === 'field'){
				//Get the Sibilings Results 
				blockObj = FieldStore.getSettings(recordId);
				blockName = (blockObj && blockObj.fieldLabel) ? blockObj.fieldLabel : '[ No Field Name ]';
			}
		}

		return blockName;
	}

	/**
	 * Ensure the X, Y, H and W of the coordinates passed in valid.
	 * 		
	 * @static
	 * @param {object} coordinates 
	 * @param {object} coordinates.height Height, enforced at H >= 1, H < 20 ( a component over 20 high is probably invalid...)
	 * @param {object} coordinates.width Width, enforced at W >= 1, W <= 12
	 * @param {object} coordinates.x X Position, enforced at X >= 0, X < 12 - Width
	 * @param {object} coordinates.y y Position, enforced at Y >= 0
	 * @returns 
	 * @memberof BlockUtils
	 */
	static getValidCoordinates(coordinates) {

		// Dont allow height over 20 rows, or less then 1
		if(coordinates.height > 80) {
			coordinates.height = 80;
		}
		if(coordinates.height <= 0) {
			coordinates.height = 4;
		}
		
		// Dont allow width over 12 columns, or less then 1
		if(coordinates.width > 12) {
			coordinates.width = 12;
		}
		if(coordinates.width <= 0) {
			coordinates.width = 1;
		}

		// Dont allow x over 12 - width columns, or less then 0
		if(coordinates.x > 12 - coordinates.width) {
			coordinates.x = 12 - coordinates.width;
		}
		if(coordinates.x < 0) {
			coordinates.x = 0;
		}

		// Dont allow y less then 0
		if(coordinates.y < 0) {
			coordinates.y = 0;
		}

		return coordinates;
	}

	/**
	 * Validates SchemaName of new Blocks 
	 * 
	 * @static
	 * @param {string} schemaName - users'  
	 * @returns {object} - { valid: true/false, validSchemaName: schemaName, errors: []};
	 * @memberof BlockUtils
	 */
	static validateSchemaName(schemaName){
		let returnObj = {
			isValidAndUnique: true, 
			errors: []
		};
		//Must not be empty
		if(!schemaName){
			returnObj['isValidAndUnique'] = false;
			returnObj['validSchemaName'] = schemaName;
			returnObj['errors'].push('Schema Name is required');
			
			//Bail out
			return returnObj;
		}
		
		// Must not contain spaces: 
		schemaName = schemaName.trim();
		
		//Update the valid schemaName
		returnObj['validSchemaName'] = schemaName
	
		//Length < 128 charcters 
		if(schemaName.length > 128) {
			schemaName = schemaName.substring(0, 127);

			returnObj['isValidAndUnique'] = false;
			returnObj['validSchemaName'] = schemaName;
			returnObj['errors'].push('Schema Name is too long');
		}

		//Characters allowed include: a-z, A-Z, 0-9, _ 
		if(!schemaName.match(/^[a-z,A-Z][a-z,A-Z,0-9,_]*$/)) {
			
			if(!schemaName.match(/^[a-z,A-Z]/)) {
				//Should not start with a number 
				let firstCharacter = schemaName.substring(0,1);
				
				if(!isNaN(parseFloat(firstCharacter))) {
					firstCharacter = String.fromCharCode(97 + Number(firstCharacter));
					
					returnObj['errors'].push('Schema Name starts with a number');

				} else {
					firstCharacter = 'a';

					returnObj['errors'].push('Schema Name starts with an invalid character');
				}
				
				schemaName = firstCharacter + schemaName;

				returnObj['isValidAndUnique'] = false;
				returnObj['validSchemaName'] = schemaName;
			}
			
			//Characters allowed include: a-z, A-Z, 0-9, _ 
			if(schemaName.match(/[^a-z0-9_]/ig)) {
				schemaName = schemaName.replace(/[^a-z0-9_]/ig, "_");

				returnObj['isValidAndUnique'] = false;
				returnObj['validSchemaName'] = schemaName;
				returnObj['errors'].push('Schema Name contains invalid characters');
			}
		}
		
		return returnObj;
	}
}

export default BlockUtils;
