import keymirror from 'keymirror';

/**
 * READ THIS:  If you are adding constants for purposes that update this store then please
 * inform the Logic Team (Amber, Brian and Drew) so they can make appropriate changes to the Logic Function Store.
 */
export const LogicFunctionConstants = keymirror({
	LOGIC_FUNCTION_DELETE_FROM_DATABASE: null,
	LOGIC_FUNCTION_DELETE_FROM_STORE: null,
	LOGIC_FUNCTION_PULL_FROM_DATABASE: null,
	LOGIC_FUNCTION_PULL_FROM_DATABASE_ALL: null,
	LOGIC_FUNCTION_PULL_ERROR: null,
	LOGIC_FUNCTION_PUSH_TO_DATABASE: null,
	LOGIC_FUNCTION_PUSH_TO_STORE: null,
	LOGIC_FUNCTION_EXPAND_BLOCK: null,
	LOGIC_FUNCTION_COLLAPSE_BLOCK: null,
	LOGIC_FUNCTION_REPLACE_BLOCK: null,
	LOGIC_FUNCTION_DISPOSE_BLOCK: null,
	LOGIC_FUNCTION_RECEIVE_BROADCAST: null,
});