/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { AdminSettingsActions } from '../../../actions';
import { AdminSettingsStore, ContextStore, FieldStore, MetadataStore } from '../../../stores';

import ThemeBuilderUtils from '../../../utils/theme-builder-utils';


/**
 * 
 */
class ThemeSettingsChooser extends Component {
	/**
	 * Creates instance of ThemeSettingsChooser
	 */
	constructor(props) {
		super(props);
		this._getSettingsList = this._getSettingsList.bind(this);
	}
	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, ContextStore, FieldStore, MetadataStore];
	}

	/**
	 * Calculate the current state.
	 * @static
	 * @param {any} prevState 
	 * @returns {Object} State!
	 */
	static calculateState(prevState, props) {
		let recordId = ContextStore.getApplicationId();
		let tableSchemaName = 'applications';
		let componentObj = MetadataStore.get(recordId, tableSchemaName) || {};
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let componentSettings = [];

		// Default Settings
		let themeSettings =
			[
				{ recordId: 'a9f933b3-4e25-4ec7-8bc5-0ed830e81328',
					  }, // Description
				{ recordId: '05b49177-6ef8-4dbc-8260-bb8c907a1831',
					  }, // CSS
				{ recordId: 'd6a6b3b9-db73-453e-a262-a375e80f3173',
					  }, // Last Updated DT
			];

		let hasMissingRequiredSettings = false;

		themeSettings.forEach(apiSetting => {
			let settingId = apiSetting.recordId;
			let settingObj = FieldStore.get(settingId) || {};
			let settingSchemaName = settingObj.fieldSchemaName;

			// Empty/No Value values.
			let valueObj = {};
			if (componentObj[settingSchemaName]) {
				valueObj.value = componentObj[settingSchemaName];
			}

			// Check to see if this setting is required, and has no value
			if(apiSetting.required && (!valueObj.value || valueObj.length === 0)) {
				hasMissingRequiredSettings = true;
			}

			componentSettings.push({
				// Basics
				fieldId: settingId,
				fieldObj: settingObj,

				// Label, Value and Sorting..
				label: settingObj.fieldLabel,
				valueObj: valueObj,
				sortOrder: apiSetting.sortOrder,

				// Used for styling
				settingSelected: (selectedSetting === settingSchemaName),
				required: apiSetting.required
			});
		});

		// Sort the results by setting name
		componentSettings.sort(function (a, b) {
			if (a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			return 1;
		});

		return {
			componentSettings: componentSettings,
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			hasMissingRequiredSettings: hasMissingRequiredSettings,
		}
	}

	/**
	 * Render the compenent
	 * @returns JSX
	 */
	render() {
		let { 
			componentSettings, 
			hasMissingRequiredSettings,
			recordId, tableSchemaName
		} = this.state;

		if (!componentSettings.length) {
			return (
				<div key="settings" className='settings-list-wrapper'>
					<div style={{ marginTop: '8px' }} className='no-settings-found'>No Settings Found</div>
				</div>
			);
		}

		let settingsList = this._getSettingsList(componentSettings); // component settings

		let buttons = [
			<button 
				id={'themeSave'}
				key="submit" 
				className="btn btn-primary btn-lg mr-1 w-50 d-flex justify-content-center" 
				form="appearance-form" 
				aria-label="Save" 
				title="Save (Ctrl-S)" 
				onClick={(event) => {ThemeBuilderUtils.saveTheme(event, recordId, tableSchemaName)}}>
					Save
			</button>,
			<button 
				id={'themeReset'}
				key="reset" 
				className="btn btn-warning btn-lg ml-1 mr-2 w-50 d-flex justify-content-center" 
				form="appearance-form" 
				aria-label="Reset" 
				onClick={(event) => {ThemeBuilderUtils.resetTheme(event, recordId, tableSchemaName)}}>
					Reset
			</button>,
		];

		let requiredMarker = null;
		if(hasMissingRequiredSettings) {
			requiredMarker = <div className="required-marker mx-4 d-flex"><i className="fa fa-circle d-flex align-items-center" aria-hidden="true"></i> <h4 className="ml-2">Required</h4></div>;
		}

		let iconPath = ContextStore.getUrlMedia() + "/icon-theme.svg";

		return [
			<div key="settings" className='settings-list-wrapper'>
				<ul key="list" className="nav flex-column">
					{settingsList && settingsList.length
						? <li className='settings-list-header'>
							<div className="d-flex">
								<img height="22" width="22" className="mr-2" src={iconPath} alt="" style={{ marginTop: '0.4rem'}} />
								<h3>Theme Settings</h3>
							</div>
						</li>
						: null
					}
					{settingsList && settingsList.length
						? settingsList
						: <div style={{ marginTop: 0 }} className='no-settings-found'>No Settings Found</div>
					}
					{ requiredMarker }
				</ul>
			</div>,
			<div key="buttons" className="btn-wrapper appearance-btn-wrapper justify-content-start ml-2">
				{buttons}				
			</div>
		];
	}

	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @returns { array } array of <li> settings elements
	 */
	_getSettingsList(settingsArr) {

		let { fieldType, recordId, tableSchemaName } = this.state;

		// iterate over the settings and return an array of list items
		return settingsArr.map(setting => {
			let labelClassNames = 'setting-label';
			if (setting.settingSelected) {
				labelClassNames += ' selected';
			}

			let componentName = setting.fieldObj.viewVariant;
			if(!componentName) {
				componentName = FieldStore.getDefaultVariantComponentName(
					setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
			}
			let componentProps = setting.fieldObj;
			componentProps.value = setting.valueObj.value;
			componentProps.fieldType = fieldType;

			componentProps.dataRecordId = recordId;
			componentProps.dataTtableSchemaName = tableSchemaName;

			// These props will only be used by the fake table setting
			componentProps.customExpressionResult = setting.customExpressionResult;
			componentProps.renderAsHTML = setting.renderAsHTML;

			// For the Date/Time, parse it!
			if(setting.fieldObj.fieldSchemaName === 'lastUpdatedDateTime') {
				if(componentProps.value && componentProps.value.includes('{')) {
					componentProps.value = JSON.parse(componentProps.value);
				} else {
					componentProps.value = '';
				}
			}

			let valueDisplay = <h5 className={`settings-list-value ${componentName}`}>
					{(componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null)}
				</h5>;
			// Checkbox for the value for logic
			if(setting.fieldId === 'd05b9c32-7b7b-4080-9901-a29259812a6c') {
				valueDisplay = componentProps.value && componentProps.value.includes('js') 
					? <span className='fa fa-check'><span className='sr-only'>(Has Logic)</span></span> 
					: null;
			}

			let requiredMarker = null;
			if(setting.required && (!setting.valueObj.value || setting.valueObj.value.length === 0)) {
				requiredMarker = <div style={{ left: '-14px', top: '2px' }} className="required-marker position-absolute"><i className="fa fa-circle fa-1" aria-hidden="true"></i></div>;
			}

			return (<li key={setting.fieldId} className={"nav-item d-flex flex-column justify-content-center " + (setting.settingSelected ? 'setting-selected' : '')}>
				<div className="nav-link" onClick={this._onSettingClick.bind(this, setting.fieldId)}>
					{/* Temporarily removed the following div */}
					{/* <div className={"setting-icon " + iconClassNames} /> */}
					{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
					{/* Add this back in when the setting-icon is used again */}
					<div className="d-flex setting-text-container">
						<div className="w-50 setting-label-container">
							<div className={labelClassNames}>{requiredMarker}<h4>{setting.label}</h4></div>
							<div className="setting-pattern-list">{setting.mostRecentPattern}</div>
						</div>
						<div className="w-50 d-flex justify-content-end setting-value-container">
							<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
								{valueDisplay}
							</div>
							{setting.valueObj.source ?
								<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}>
									{setting.valueObj.source}
								</div>
								: null
							}
						</div>
					</div>
				</div>
			</li>);
		});
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 */
	_onSettingClick(settingFieldId, event) {
		event.preventDefault();

		let settingFieldObj = FieldStore.get(settingFieldId);
		let settingSchemaName = settingFieldObj.fieldSchemaName;

		// Toggle to select and deselect the Setting
		if(settingSchemaName === 'css') {
			// If logic is already selected
			if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
				// Expand the Settings Panel
				AdminSettingsActions.onSettingsListHideChange(true);
			} else {
				// Hide the Settings Panel
				AdminSettingsActions.onSettingsListHideChange(true);
				AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
			}
		} else {
			// Expand the Settings Panel
			AdminSettingsActions.onSettingsListHideChange(false);
			if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
				AdminSettingsActions.onSettingChange('', '');
			} else {
				AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
			}
		}
	}
}
const container = Container.create(ThemeSettingsChooser);
export default container;