import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Components
import TablesContainer from './data/tables.react';
import FieldsContainer from './data/fields.react';
import RelationshipsContainer from './data/relationships.react';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';

/**
 * Displays dashboard tabs
 */
class DataDashboardTabs extends Component {
	/**
	 * Return the stores we're listening to for changes.
	 *
	 * @return {array}  description
	 */
	static getStores() {
		return [ AdminSettingsStore ];
	}

	/**
	 * Calculate our state anytime a store changes.
	 *
	 * @param  {object} prevState
	 * @return {object}
	 */
	static calculateState(prevState) {
		return {
			'selectedTab' : AdminSettingsStore.getActiveDashboardTab(),
		};
	}

	/**
	 * render the component
	 *
	 * @return {JSX}
	 */
	render() {
		let { 
			onTabSelect,
		} = this.props;
		let { 
			selectedTab,
		} = this.state;

		let activeTabComponent = null;
		switch(selectedTab) {
			default:
			case 'data-tables': {
				activeTabComponent = <TablesContainer key="tab-content" />;
				break;
			}
			case 'data-fields': {
				activeTabComponent = <FieldsContainer key="tab-content" />;
				break;
			}
			case 'data-relationships': {
				activeTabComponent = <RelationshipsContainer key="tab-content" />;
				break;
			}
			case 'data-security': {
				// Intentionally left blank for now
				break;
			}
			case 'data-records': {
					// Intentionally left blank for now
				break;
			}
		}

		return (
			<div style={{ flex: 1, overflowY: 'hidden' }} className="d-flex flex-column">
				<div className="cd-dashboard-tabs data" key="tabs">
					<div className="header-tab-item-container data">
						<h5 className={`header-tab-item ${selectedTab === 'data-tables' || selectedTab === undefined ? 'selected-tab' : ''}`} onClick={() => onTabSelect('data-tables')}>Tables</h5>
						<h5 className={`header-tab-item ${selectedTab === 'data-fields' || selectedTab === undefined ? 'selected-tab' : ''}`} onClick={() => onTabSelect('data-fields')}>Fields</h5>
						<h5 className={`header-tab-item ${selectedTab === 'data-relationships' || selectedTab === undefined ? 'selected-tab' : ''}`} onClick={() => onTabSelect('data-relationships')}>Relationships</h5>
					{/*	
						<a href="#" onClick={() => onTabSelect('data-security')}>Security</a> |
						<a href="#" onClick={() => onTabSelect('data-records')}>Records</a> */}
					</div>
				</div>
				{activeTabComponent}
			</div>
		);
	}
}
const container = Container.create(DataDashboardTabs);
export default container;