import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Components
import FunctionsByTableContainer from './logic/functions.react';
import ScheduledContainer from './logic/scheduled.react';
import APIsByNameContainer from './logic/apis.react';
import WebHooksContainer from './logic/webhooks.react';
import PagesContainer from './logic/pages.react';
import FieldsContainer from './logic/fields.react';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';

/**
 * Displays dashboard tabs
 */
class LogicDashboardTabs extends Component {
	/**
	 * Return the stores we're listening to for changes.
	 *
	 * @return {array}  description
	 */
	static getStores() {
		return [ AdminSettingsStore ];
	}

	/**
	 * Calculate our state anytime a store changes.
	 *
	 * @param  {object} prevState
	 * @return {object}
	 */
	static calculateState(prevState) {
		return {
			'selectedTab' : AdminSettingsStore.getActiveDashboardTab(),
		};
	}

	/**
	 * render the component
	 *
	 * @return {JSX}
	 */
	render() {
		let { 
			onTabSelect,
		} = this.props;
		let { 
			selectedTab,
		} = this.state;

		let activeTabComponent = null;
		switch(selectedTab) {
			default:
			case 'logic-functions-by-table': {
				activeTabComponent = <FunctionsByTableContainer key="tab-content" />;
				break;
			}
			case 'logic-scheduled': {
				activeTabComponent = <ScheduledContainer key="tab-content" />;
				break;
			}
			case 'logic-apis-by-name': {
				activeTabComponent = <APIsByNameContainer key="tab-content" />;
				break;
			}
			case 'logic-webhooks': {
				activeTabComponent = <WebHooksContainer key="tab-content" />;
				break;
			}
			case 'logic-pages': {
				activeTabComponent = <PagesContainer key="tab-content" />;
				break;
			}
			case 'logic-fields': {
				activeTabComponent = <FieldsContainer key="tab-content" />;
				break;
			}
		}

		return (
			<div style={{ flex: 1, overflowY: 'hidden' }} className="d-flex flex-column">
				<div className="cd-dashboard-tabs logic" key="tabs">
					<div className="header-tab-item-container logic">
						<h5 className={`header-tab-item ${selectedTab === 'logic-functions-by-table' || selectedTab === undefined ? 'selected-tab' : ''}`} onClick={() => onTabSelect('logic-functions-by-table')}>Functions</h5>
						<h5 className={`header-tab-item ${selectedTab === 'logic-scheduled' ? 'selected-tab' : ''}`} onClick={() => onTabSelect('logic-scheduled')}>Schedules</h5>
						<h5 className={`header-tab-item ${selectedTab === 'logic-apis-by-name' ? 'selected-tab' : ''}`} onClick={() => onTabSelect('logic-apis-by-name')}>APIs</h5>
						<h5 className={`header-tab-item ${selectedTab === 'logic-webhooks' ? 'selected-tab' : ''}`} onClick={() => onTabSelect('logic-webhooks')}>Webhooks</h5>
					</div>
					<div className="header-tab-item-container logic">
						<h5 className={`header-tab-item ${selectedTab === 'logic-pages' ? 'selected-tab' : ''}`} onClick={() => onTabSelect('logic-pages')}>Pages</h5>
						<h5 className={`header-tab-item ${selectedTab === 'logic-fields' ? 'selected-tab' : ''}`} onClick={() => onTabSelect('logic-fields')}>Fields</h5>
					</div>
				</div>
				{activeTabComponent}
			</div>
		);
	}
}
const container = Container.create(LogicDashboardTabs);
export default container;