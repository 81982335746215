import keymirror from 'keymirror';

export const MetadataConstants = keymirror({
	METADATA_DELETE_FROM_DATABASE: null,
	METADATA_DELETE_FROM_STORE: null,
	METADATA_PULL_FROM_DATABASE: null,
	METADATA_PULL_FROM_DATABASE_ALL: null,
	METADATA_PULL_ERROR: null,
	METADATA_PUSH_TO_DATABASE: null,
	METADATA_PUSH_TO_STORE: null,
	METADATA_RECEIVE_BROADCAST: null
});