import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Components
import CompliancesContainer from './project/compliances.react';
import DiscussionsContainer from './project/discussions.react';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';

/**
 * Displays dashboard tabs
 */
class ProjectDashboardTabs extends Component {
	/**
	 * Return the stores we're listening to for changes.
	 *
	 * @return {array}  description
	 */
	static getStores() {
		return [ AdminSettingsStore ];
	}

	/**
	 * Calculate our state anytime a store changes.
	 *
	 * @param  {object} prevState
	 * @return {object}
	 */
	static calculateState(prevState) {
		return {
			'selectedTab' : AdminSettingsStore.getActiveDashboardTab(),
		};
	}

	/**
	 * render the component
	 *
	 * @return {JSX}
	 */
	render() {
		let { 
			onTabSelect,
		} = this.props;
		let { 
			selectedTab,
		} = this.state;

		let activeTabComponent = null;
		switch(selectedTab) {
			default:
			case 'project-discussions': {
				activeTabComponent = <DiscussionsContainer key="tab-content"/>;
				break;
			}
			case 'project-compliances': {
				activeTabComponent = <CompliancesContainer key="tab-content"/>;
				break;
			}
			case 'project-change-management': {
				// Intentionally left blank for now
				break;
			}
			case 'project-users': {
				// Intentionally left blank for now
				break;
			}
			case 'project-installation': {
				// Intentionally left blank for now
				break;
			}
			case 'project-application': {
					// Intentionally left blank for now
				break;
			}
		}

		return (
			<div style={{ flex: 1, overflow: 'hidden' }} className="d-flex flex-column">
				<div className="cd-dashboard-tabs project" key='tabs'>
					<div className="header-tab-item-container project">
						<h5 className={`header-tab-item ${selectedTab === 'project-discussions' || selectedTab === undefined ? 'selected-tab' : ''}`} onClick={() => onTabSelect('project-discussions')}>Discussions</h5>
						{/* <h5 className={`header-tab-item ${selectedTab === 'project-compliances' ? 'selected-tab' : ''}`} onClick={() => onTabSelect('project-compliances')}>Compliances</h5> */}
						{/*<a href="#" onClick={() => onTabSelect('project-change-management')}>Change Management</a> |
						<a href="#" onClick={() => onTabSelect('project-users')}>Users</a> |
						<a href="#" onClick={() => onTabSelect('project-installation')}>Installation</a> |
						<a href="#" onClick={() => onTabSelect('project-application')}>Application</a> */}
					</div>
				</div>
				{activeTabComponent}
			</div>
		);
	}
}
const container = Container.create(ProjectDashboardTabs);
export default container;