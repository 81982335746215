import BlockUtils from './block-utils';
import FieldUtils from './field-utils';
import RelationshipStore from '../stores/relationship-store';
import RelationshipActions from '../actions/relationship-actions';
import TableStore from '../stores/table-store';
import TableActions from '../actions/table-actions';
import FieldStore from '../stores/field-store';
import FieldActions from '../actions/field-actions';
import PageStore from '../stores/page-store';
import PageActions from '../actions/page-actions';
import RenderStore from '../stores/render-store';
import ContextStore from '../stores/context-store';
import AdminSettingsStore from '../stores/admin-settings-store';
import InterfaceActions from '../actions/interface-actions';
import UIUtils from './ui-utils';

export default class TableUtils {
	/**
	 * Validates TableSchemaName of new Tables 
	 * 
	 * @static
	 * @param {String} schemaName - users' name for the block
	 * @param {String} currentRecordid - The ID of the current record being added
	 * @param {Array} errors - Any errors already existing
	 * @param {Array} additionalInvalidNames - Any additional names to consider invalid (used to power validation through MDGW)
	 * @returns {object} - { isUnique: true/false, validSchemaName: schemaName, errors: []};
	 * @returns {Array} - Optional - Should only be used to power the recursion when calling itself and gather the found errors every time  
	 * @memberof TableUtils
	 */
	static validateTableSchemaName(schemaName, currentRecordId, errors, additionalInvalidNames) {
		
		//General Check up for Schema Names 
		let returnObj = BlockUtils.validateSchemaName(schemaName);
		
		//Update the SchemaName with the General Validation for Blocks
		schemaName = returnObj['validSchemaName'];
		
		//Push the Errors from previous recursive calls the new Errors found: 
		if(errors){
			//Dont repeat same error messages
			for(let i = 0; i < errors.length; i++){
				if(!returnObj['errors'].includes(errors[i]))
				returnObj['errors'].push(errors[i]);	
			}
		}
		//Should be unique 
		let tableRecords = TableStore.getAllArray(); 
		let relationRecords = RelationshipStore.getAllArray();

		// Concatenate all the records
		let allRecords = []
		allRecords = allRecords.concat(relationRecords, tableRecords);

		let schemaNamesAlreadyUsed = [];
		if(additionalInvalidNames && Array.isArray(additionalInvalidNames)) {
			schemaNamesAlreadyUsed = schemaNamesAlreadyUsed.map(schemaName => schemaName.toLowerCase()).concat(additionalInvalidNames);
		}
		
		//Look for the Table's Fields and loop if any matches to what we are returning, error it 
		allRecords.forEach(record => {
			// do not include the current schemaName in the schemaNamesAlreadyUsed array
			if(record.recordId !== currentRecordId){
				// push tableSchemaName and relationSchemaName in the schemaNamesAlreadyUsed arrray
				if(record.tableSchemaName ){
					schemaNamesAlreadyUsed.push(record.tableSchemaName.toLowerCase());
				} else if(record.relationSchemaName){
					schemaNamesAlreadyUsed.push(record.relationSchemaName.toLowerCase());
				} 
			}
		});
		
		let digit = 1;
		
		//Should be unique 
		if(schemaNamesAlreadyUsed.includes(schemaName.toLowerCase())) {
			while(schemaNamesAlreadyUsed.includes(schemaName.toLowerCase() + digit)) {
				digit++;
			}
				//Split digits at the end of the string 
			let nextDigit = schemaName.match(/\d+$/);
		
			if(!Array.isArray(nextDigit)){
				nextDigit = 0;
			} else {
				nextDigit = nextDigit[0];
			}
	
			try {
				nextDigit = JSON.parse(nextDigit);
			} catch(error) {
				console.error(error.message);
			}
	
			//Remove the digits at the end of the string 
			let newSchemaName = schemaName.replace(/\d+$/, '');
	
			//add digit to current serial number 
			nextDigit += digit;
	
			//Join string and new Digit 
			newSchemaName += nextDigit;
	
			returnObj['isValidAndUnique'] = false;
			returnObj['validSchemaName'] = newSchemaName;
			returnObj['errors'].push('Technical Name already exits');

		}  else {
			//Breaks the Recursion when All the Tests pass 
			returnObj['isValidAndUnique'] = true;
		}

		//Run the recursion: 
		if(!returnObj['isValidAndUnique']){
			//If we are still not unique then...run itself again
			return this.validateTableSchemaName(returnObj['validSchemaName'], currentRecordId, returnObj['errors'])
		}

		return returnObj;
	}

	/**
	 * Helper function to delete a table by its tableSchemaName and all associated
	 * relationships, pages and fields, with user notification.
	 * 
	 * @param {string} tableSchemaName The name of the table being deleted
	 */
	static deleteTableWithDependencies(tableSchemaName) {

		let tableObj = TableStore.getByTableSchemaName(tableSchemaName) || {};
		let componentId = tableObj.recordId;
		let componentName = tableObj.singularName;

		//Confirm Message is Delete
		let id = InterfaceActions.stickyNotification({ 'level': 'warning', 'message': 'Deleting Table...' });
		// Get all the fields from the table and delete them
		let fields = FieldStore.getByTableSchemaName(tableSchemaName) || [];
		let relationships = RelationshipStore.getByTableSchemaName(tableSchemaName) || {};
		let relationshipsArr = Object.keys(relationships).map(relationId => relationships[relationId]);
		let panelId = AdminSettingsStore.getRecordId();
		let notifications = {};
		notifications.fieldDeletionNotification = InterfaceActions.stickyNotification({ 'message': 'Deleting fields for table ' + componentName + '. This may take a few seconds; please wait...', 'level': 'info' });
		// Because multiple fields may (and usually are) attached to the same parent,
		// We push any parents not on the table to this array so that we can make
		// One database call for each once each field has been iterated over.
		let parentObjects = {};
		let fieldDeletionPromises = [];
		fields.forEach(field => {
			// Store the parents to be updated later
			let parents = FieldUtils.getParents(field.recordId, tableSchemaName) || [];
			parents.forEach(parentAttachment => {				
				let parentObj = parentAttachment.tableSchemaName === 'field' ? FieldStore.get(parentAttachment.recordId) : PageStore.get(parentAttachment.recordId);
				if (parentObj && parentObj.tableSchemaName !== tableSchemaName) {
					parentObjects[parentObj.recordId] = parentAttachment.tableSchemaName;
				}
			});

			// Detach the field from any parents in the store
			FieldUtils.detachChildFromAllParents(field.recordId, tableSchemaName, false);
			
			// If we're deleting the field that the settings panel is about, close it
			if (panelId === field.recordId) {
				UIUtils.closeSettingsPanel();
			}
			// Delete the field.
			fieldDeletionPromises.push(FieldActions.deleteFromDatabasePromise(field.recordId, tableSchemaName));
		});

		// Update the database for any parent objects from which the deleted fields have been detached
		let detachmentPromises = Object.keys(parentObjects).map(recordId => {
			let componentType = parentObjects[recordId];
			return componentType === 'field' ?
				FieldActions.pushToDatabasePromise(FieldStore.get(recordId, true)) :
				PageActions.pushToDatabasePromise(PageStore.get(recordId, true));
		});

		return Promise.all(detachmentPromises)
			.then(() => {
				return Promise.all(fieldDeletionPromises);
			})
			.then(() => {
				InterfaceActions.clearStickyNotification(notifications.fieldDeletionNotification);
				InterfaceActions.notification({ 'message': 'Field deletion complete!', 'level': 'success' });
				notifications.pageDeletionNotification = InterfaceActions.stickyNotification({ 'message': 'Deleting pages for table ' + componentName + '. This may take a few seconds; please wait...', 'level': 'info' });
				// Get all the pages from the table and delete them
				let pages = PageStore.getArrayByTableSchemaName(tableSchemaName) || [];
				let currentPageId = RenderStore.get(ContextStore.getPageRenderId().componentId);
				let pageDeletionPromises = pages.map(page => {

					// If we're deleting the page we're on, redirect elsewhere.
					if (page.recordId === currentPageId) {
						location.href = '/';
					}
					// If we're deleting the page that the settings panel is about, close it
					if (panelId === page.recordId) {
						//Close Panel after deleting
						UIUtils.closeSettingsPanel();
					}
					//Deletes the Page  
					return PageActions.deleteFromDatabasePromise(page.recordId);
				});
				return Promise.all(pageDeletionPromises);
			})
			.then(() => {
				InterfaceActions.clearStickyNotification(notifications.pageDeletionNotification);
				InterfaceActions.notification({ 'message': 'Page deletion complete!', 'level': 'success' });
				notifications.relationshipDeletionNotification = InterfaceActions.stickyNotification({ 'message': 'Deleting relationships for table ' + componentName + '. This may take a few seconds; please wait...', 'level': 'info' });
				let relationshipDeletionPromises = relationshipsArr.map(relationship => {
					return RelationshipActions.deleteFromDatabasePromise(relationship.recordId);
				});
				return Promise.all(relationshipDeletionPromises);
			})
			.then(() => {
				InterfaceActions.clearStickyNotification(notifications.relationshipDeletionNotification);
				InterfaceActions.notification({ 'message': 'Relationship deletion complete!', 'level': 'success' });
				notifications.tableDeletionNotification = InterfaceActions.stickyNotification({ 'message': 'Deleting table ' + componentName + '. This may take a few seconds; please wait...', 'level': 'info' });
				//Deletes the Table itself
				return TableActions.deleteFromDatabasePromise(componentId);
			})
			.then(() => {
				InterfaceActions.clearStickyNotification(notifications.tableDeletionNotification);
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'message': 'Table deletion complete!', 'level': 'success' });
			})
			.catch(error => {
				InterfaceActions.clearStickyNotification(notifications.tableDeletionNotification);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to delete Table' });
				console.error('Unable to delete Table:', error);
			});
	}
}