import ObjectUtils from './object-utils';
import socket from './socket';
import uuid from 'uuid';
import eventEmitter from './socket-response-emitter';
import ContextStore from '../stores/context-store';

/**
 * socketFetcher - Utility class that checks status of fetch response.
 *
 * @param  {string} service name of the service to call/fetch
 * @param  {string} parameters JSON of params to send
 * @return {Object}           data
 * @todo check data.responseCode ?
 */
const socketFetcher = function(service, parameters){
    return new Promise (function (resolve, reject) {

		// Generate a requestId
		let requestId = uuid.v4();

		// Wait for the requestId to be emitted
		eventEmitter.once(requestId, function(response) {
			// Grab the response object and return it.
			let responseObj = ObjectUtils.getObjFromJSON(response);
			return resolve(responseObj);
		});
		socket.emit('callService', service, requestId, parameters, ContextStore.getBasePath().substring(8));
    });
}
export default socketFetcher;