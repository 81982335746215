import keymirror from 'keymirror';

/**
 * READ THIS:  If you are adding constants for purposes that update this store then please
 * inform the NLP Team (Amber) so they can make appropriate changes to the NLP Dictionaries Store.
 */
export const FieldConstants = keymirror({
	FIELD_DELETE_FROM_DATABASE: null,
	FIELD_DELETE_FROM_STORE: null,
	FIELD_HISTORY_APPEND_TO_STORE: null,
	FIELD_PULL_FROM_DATABASE: null,
	FIELD_PULL_FROM_DATABASE_ALL: null,
	FIELD_PULL_ERROR: null,
	FIELD_PUSH_TO_DATABASE: null,
	FIELD_PUSH_SETTING_TO_STORE: null,
	FIELD_PUSH_TO_STORE: null,
	FIELD_PUSH_CHILD_CONFIGURATION_TO_STORE: null,
	FIELD_RECEIVE_BROADCAST: null
});