import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Actions
import {
	AdminSettingsActions,
	InterfaceActions,
	LogicFunctionActions,
	MetadataActions
} from '../../../actions';

// Components
import ReactBlocklyComponent from '@dmclain-citizendeveloper/citdev-react-blockly-component';

// Constants
import Upgrade from '../../../constants/help-upgrade-constants';

// Stores
import {
	AdminSettingsStore,
	MetadataStore,
	ToolboxStore,
	ContextStore
} from '../../../stores';

// Utils
import ObjectUtils from '../../../utils/object-utils';
import BlocklyUtils from '../../../utils/blockly-utils';
import UIUtils from '../../../utils/ui-utils';


let triggersLookup = {
	'stripe-invoice.paid': {
		schemaName: 'stripe-invoice.paid',
		label: 'Invoice Paid',
		runTimeVariables: [
			{
				label: 'Customer ID',
				key: 'customerId'
			},
			{
				label: 'Invoice ID',
				key: 'id'
			},
			{
				label: 'Subscription ID',
				key: 'subscription'
			},
			{
				label: 'Full Invoice Object',
				key: 'fullInvoiceObject'
			}
		]
	},
	'stripe-invoice.payment_failed': {
		schemaName: 'stripe-invoice.payment_failed',
		label: 'Invoice Payment Failed',
		runTimeVariables: [
			{
				label: 'Customer ID',
				key: 'customer'
			},
			{
				label: 'Invoice ID',
				key: 'id'
			},
			{
				label: 'Subscription ID',
				key: 'subscription'
			},
			{
				label: 'Last Finalization Error',
				key: 'last_finalization_error'
			},
			{
				label: 'Full Invoice Object',
				key: 'fullInvoiceObject'
			}
		]
	},
	'stripe-invoice.payment_action_required': {
		schemaName: 'stripe-invoice.payment_action_required',
		label: 'Invoice Payment Action Required',
		runTimeVariables: [
			{
				label: 'Customer ID',
				key: 'customer'
			},
			{
				label: 'Invoice ID',
				key: 'id'
			},
			{
				label: 'Subscription ID',
				key: 'subscription'
			},
			{
				label: 'Last Finalization Error',
				key: 'last_finalization_error'
			},
			{
				label: 'Full Invoice Object',
				key: 'fullInvoiceObject'
			}
		]
	},
	'stripe-invoice.finalization_failed': {
		schemaName: 'stripe-invoice.finalization_failed',
		label: 'Invoice Finalization Failed',
		runTimeVariables: [
			{
				label: 'Customer ID',
				key: 'customer'
			},
			{
				label: 'Invoice ID',
				key: 'id'
			},
			{
				label: 'Subscription ID',
				key: 'subscription'
			},
			{
				label: 'Last Finalization Error',
				key: 'last_finalization_error'
			},
			{
				label: 'Full Invoice Object',
				key: 'fullInvoiceObject'
			}
		]
	},
	'stripe-checkout.session.async_payment_succeeded': {
		schemaName: 'stripe-checkout.session.async_payment_succeeded',
		label: 'Delayed Payment Succeeded',
		runTimeVariables: [
			{
				label: 'Payment Method ID',
				key: 'payment_method'
			},
			{
				label: 'Payment Status',
				key: 'payment_status'
			},
			{
				label: 'Session ID',
				key: 'id'
			},
			{
				label: 'Full Session Object',
				key: 'fullSessionObject'
			}
		]
	},
	'stripe-checkout.session.async_payment_failed': {
		schemaName: 'stripe-checkout.session.async_payment_failed',
		label: 'Delayed Payment Failed',
		runTimeVariables: [
			{
				label: 'Payment Method ID',
				key: 'payment_method'
			},
			{
				label: 'Payment Status',
				key: 'payment_status'
			},
			{
				label: 'Session ID',
				key: 'id'
			},
			{
				label: 'Full Session Object',
				key: 'fullSessionObject'
			}
		]
	},
	'stripe-payment_intent.succeeded': {
		schemaName: 'stripe-payment_intent.succeeded',
		label: 'Payment Succeeded',
		runTimeVariables: [
			{
				label: 'Payment Method ID',
				key: 'payment_method'
			},
			{
				label: 'Payment Status',
				key: 'payment_status'
			},
			{
				label: 'Session ID',
				key: 'id'
			},
			{
				label: 'Full Session Object',
				key: 'fullSessionObject'
			}
		]
	},
	'stripe-setup_intent.succeeded': {
		schemaName: 'stripe-setup_intent.succeeded',
		label: 'Setup Succeeded',
		runTimeVariables: [
			{
				label: 'Customer ID',
				key: 'customer'
			},
			{
				label: 'Payment Method ID',
				key: 'payment_method'
			},
			{
				label: 'Setup ID',
				key: 'id'
			},
			{
				label: 'Status',
				key: 'status'
			},
			{
				label: 'Full Setup Object',
				key: 'fullSetupObject'
			}
		]
	},
	'stripe-subscription_schedule.aborted': {
		schemaName: 'stripe-subscription_schedule.aborted',
		label: 'Subscription Schedule Aborted',
		runTimeVariables: [
			{
				label: 'Subscription Schedule ID',
				key: 'id'
			},
			{
				label: 'Full Subscription Schedule Object',
				key: 'fullScheduleObject'
			}
		]
	}
};

class WebhookSetting extends Component {
	/**
	 * Creates instance of WebhookSetting
	 *
	 * @memberOf WebhookSetting
	 */
	constructor(props) {
		// @TODO
		super(props);
		this.state = {};
		this._workspaceDidChange = this._workspaceDidChange.bind(this);
		this._saveToStore = this._saveToStore.bind(this);
		this._resizeBlockly = this._resizeBlockly.bind(this);
		this._onSaveHandler = this._onSaveHandler.bind(this);
		this._copyWorkspace = this._copyWorkspace.bind(this);
		this._pasteWorkspace = this._pasteWorkspace.bind(this);
		this._onResetHandler = this._onResetHandler.bind(this);
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	 static getStores() {
		return [AdminSettingsStore, MetadataStore];
	}

	static calculateState(prevState, props) {
		let newState = {
			actionToolbox: prevState ? prevState.actionToolbox : undefined,
			blocklyxml: '',
			js: '',
			logicFunctionsUsed: '',
			logicFunctionsUsedDirectly: '',
			memUse: '',
		};
		// Only preserving the actionToolbox
		newState.actionToolbox = prevState ? prevState.actionToolbox : undefined;
		// @TODO
		newState.trigger = AdminSettingsStore.getSettingSchemaName();
		newState.recordId = newState.trigger;

		let actionToolbox = ToolboxStore.getActionToolboxJS();
		if((!prevState || !prevState.actionToolbox) && actionToolbox && Object.keys(actionToolbox).length) {
			newState.actionToolbox = actionToolbox;
		}

		let value = newState.trigger ? MetadataStore.get(newState.trigger, 'webhook') : {};
		if(typeof value === 'object') {
			delete value.settingsHidden;
		}
		Object.assign(newState, value);
		newState.settingsHidden = AdminSettingsStore.getSettingsListHidden();

		return newState;
	}

	_workspaceDidChange() {
		// We are not actually doing work on change, but
		// we do want to force the value to be marked as dirty once
		// we start messing around
		// This will be automatically cleaned on save or component unmount
		if(!this.state.forceDirty) {
			let newValue = Object.assign({
				forceDirty: true
			}, this.state);
			delete newValue.trigger; // Value from the state not needed for this
			delete newValue.actionToolbox;
			this._saveToStore(newValue);
		}
	}

	_saveToStore(blocklyValueObj) {
		let trigger = this.state.trigger;
		MetadataActions.pushToStore(trigger, 'webhook', blocklyValueObj);
	}

	/**
	 * resizeBlockly - Resizes blockly instances to full width by triggering resize() from ref.
	 * A setTimeout of 400 milliseconds is used to allow the CSS transition to complete
	 * Prior to calculation of new width
	 *
	 * @param  {string} (optional) blocklyRefId of ref to Blockly refs. Will pull state value when left undefined
	 */
	 _resizeBlockly() {
		if (this.blocklyEditor) {
			setTimeout(this.blocklyEditor.resize, 500);
		}
	}

	/**
	 * onSaveHandler - Triggers to send Blockly data in store to database via API
	 */
	 _onSaveHandler() {
		// Display notification to user
		// let saveNotif = InterfaceActions.stickyNotification({ 'level': 'info', 'message': 'Saving logic...' });

		
		if(this.blocklyEditor && this.blocklyEditor.workspace && this.blocklyEditor.workspace.workspace) {
			let workspace = this.blocklyEditor.workspace.workspace;
			workspace.saving = true;
			setTimeout(() => {
				
				// Handle converting logicFunctionsUsed into an array
				// Regenerate the JS
				let params = {
					defaultToNull: true,
					memUse: this.props.value && this.props.value.memUse ? this.props.value.memUse : 'l',
					includeJs: true
				};
	
				
				BlocklyUtils.saveAutomationFromWorkspaceV2(workspace, params, (blocklyValueObj) => {
					this._saveToStore(blocklyValueObj);
					// let parentSaveNotif = InterfaceActions.stickyNotification({ 'level': 'info', 'message': 'Saving local logic...' });
					return MetadataActions.pushToDatabasePromise(MetadataStore.get(this.state.trigger, 'webhook', true), 'webhook');
				}).catch(console.error);
			}, 1000);
		}

	}

	/**
	 * Copies blocks to citdev clipboard
	 */
	 _copyWorkspace(){
		try {
			let blocklyValueObj = BlocklyUtils.getWorkspaceInfo(this.blocklyEditor.workspace.workspace, {
				defaultToNull: true,
				includeJs: false // We don't need to bother with the JS for this
			});
			localStorage.logicClipboard = JSON.stringify(blocklyValueObj);
			InterfaceActions.notification({ 'level': 'success', 'message': 'Copying logic to clipboard...' });
		} catch(err) {
			console.error('Error when copying logic: ', err);
			InterfaceActions.notification({ 'level': 'error', 'message': 'Error when copying logic!' });
		}
	}
	/**
	 * Pastes blocks from citdev clipboard to workspace; appends to, not overrides, blocks
	 */
	_pasteWorkspace() {
		let value = localStorage.logicClipboard;
		try{
			let valueObj = ObjectUtils.getObjFromJSON(value);
			
			let pastedblocklyxml = valueObj.blocklyxml;
			BlocklyUtils.appendToWorkspace(pastedblocklyxml, this.blocklyEditor.workspace.workspace);
			InterfaceActions.notification({ 'level': 'success', 'message': 'Pasting new logic below existing logic. Please check your new blocks to make sure they don\'t overlap!' });
		} catch(err) {
			InterfaceActions.notification({ 'level': 'error', 'message': 'Attempted to paste invalid value into workspace.' });
			console.warn('Attempted to paste with invalid data in clipboard. Value was', value);
			console.warn('Error was', err);
		}
	}

	/**
	 * onResetHandler - Calls API to retrieve data to reset value in store
	 */
	 _onResetHandler() {

		// Reset all of the logic functions used to the value from the database
		// (Yes, this may mean undoing a logic function change if changed in another trigger but not saved. That's the way it's going to have to be. Don't do that.)
		let logicFunctionIds = this.state.logicFunctionsUsed;
		
		// Handle converting logicFunctionsUsed into an array
		let logicFunctionIdsArr =[]; 
		if (logicFunctionIds) {
			if(Array.isArray(logicFunctionIds)) {
				logicFunctionIdsArr = logicFunctionIds;
			} else {
				logicFunctionIdsArr = logicFunctionIds.split(',');
			}
		}
		if (logicFunctionIdsArr && logicFunctionIdsArr.length) {
			InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting all functions used within this trigger to their saved values...' });
			logicFunctionIdsArr.forEach(logicFunctionId => {
				LogicFunctionActions.pullFromDatabase(logicFunctionId);
			});
		}

		InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting Webhook...' });
		MetadataActions.pullFromDatabase(this.state.trigger, 'webhook');

		if(this.blocklyEditor) {
			this.blocklyEditor.reset();
		}
	}

	/**
	 * Calling resizeBlockly during this lifecycle phase to ensure
	 * Blockly resizes when user closes and reopens right panel with same
	 * Blockly component displaying
	 */
	 componentDidUpdate(prevProps, prevState) {
		// Resize blockly
		// @TODO: Check if we need to resize in more limited circumstances
		this._resizeBlockly();

		// If our initialXml has changed, then we need to reset our workspace
		if(
			this.blocklyEditor &&
			(
				this.state.blocklyxml !== prevState.blocklyxml ||
				this.state.trigger !== prevState.trigger
			)
		) {
			this.blocklyEditor.reset();
		}
	}

	/**
	 * Calling resizeBlockly during this lifecycle phase to ensure
	 * Blockly resizes when opens right panel for the first time
	 */
	 componentDidMount() {
		this._resizeBlockly();
	}

	/**
	 * @returns - DOM for selected security group
	 * @memberof WebhookSetting
	 */
	render() {
		let {
			trigger, settingsHidden
		} = this.state;
		let {
			disabledRetailRestrictions
		} = this.props;
		if(!trigger) {
			return (
				<div className="select-setting">
					<div className="select-setting-text-wrap">
						Select a Webhook Trigger to configure on the left.
					</div>
				</div>
			);
		}

		let triggerInfo = triggersLookup[trigger];

		// Make the header
		// let headerControls = (settingsHidden
		// 	? <div className="trigger-setting d-flex">
		// 		<label>
		// 			<h4 title="Controls whether or not this is marked as a high-memory action. High memory actions may include complex imports or actions which run over large record sets. For best performance, we recommend starting with lower memory requirements and increasing the memory requirements ONLY if your action experiences memory issues.">Process with high memory
		// 			<Switch
		// 				onChange={() => console.log('TODO')}
		// 				value={this.state.memUse === 'h'}
		// 				id={'highmemtoggle-' + this.props.renderId}
		// 				describedBy={'highmemlabel-' + this.props.renderId}
		// 				fieldSchemaName={'highmemtoggle-' + this.props.renderId}
		// 			/>
		// 			</h4>
		// 		</label>
		// 	</div>
		// 	: null );

		// Commented out the High Mem toggle above because.. it doesnt work in the wild.
		let headerControls = null;

		/* The ids for the save and reset buttons are set in a specific way, so that Ctrl-S and Ctrl-R (UIUtils.onkeyDown method) can find these buttons */
		let buttons = [];
		if(disabledRetailRestrictions) {
			buttons.push(<button 
				key="submit" 
				className="btn btn-success btn-md setting-list-button mr-3 ml-1" 
				form="appearance-form" 
				aria-label={'Upgrade to use Stripe Webhooks'}
				style={{ width: '375px'}}
				onClick={() => { UIUtils.onUpgradeClick(Upgrade.UPGRADE_STRIPE); }}>
				Upgrade to use Stripe Webhooks
			</button>)
		} else {
			buttons.push(<button 
				key="copy" 
				className="btn btn-secondary" 
				form="appearance-form" 
				aria-label="Copy"
				onClick={this._copyWorkspace}>
				Copy
			</button>);
			buttons.push(<button 
				key="paste" 
				className="btn btn-secondary ml-2" 
				form="appearance-form" 
				aria-label="Paste"
				onClick={this._pasteWorkspace}>
				Paste
			</button>);
			buttons.push(<button
				id={'webhooksReset'} 
				key="reset" 
				className="btn btn-warning mx-2" 
				form="appearance-form" 
				aria-label="Reset" 
				onClick={this._onResetHandler}>
				Reset
			</button>);
			buttons.push(<button 
				id={'webhooksSave'}
				key="submit" 
				className="btn btn-primary" 
				form="appearance-form" 
				aria-label="Save"
				onClick={this._onSaveHandler}>
				Save
			</button>);
		}

		let header = (
			<h2 className="d-flex w-100 justify-content-between align-items-center py-3">
					<div className='d-flex align-items-center'>
						{/* Show collapse only when setting has been selected */} 
						{settingsHidden ?
							<button 
								className="btn btn-back"
								title="Triggers" 
								form="appearance-form"
								onClick={() => {AdminSettingsActions.onSettingsListHideChange(false);}}>
								<img height="26" width="26" src={ContextStore.getUrlMedia() + "/expand-settings-list.svg"} alt="" />
							</button>
						: null }
						<h2>{triggerInfo ? triggerInfo.label : trigger}</h2> 
					</div>
					{headerControls}
					<div className="btn-wrapper">
						{ buttons }
					</div>
				</h2>
			);

		// Workspace controls
		const workspaceConfiguration = {
			grid: {
				spacing: 20,
				length: 3,
				colour: '#ccc',
				snap: true
			},
			zoom: {
				controls: true,
				wheel: true,
				startScale: 0.9,
				maxScale: 3,
				minScale: 0.3,
				scaleSpeed: 1.2
			}
		};

		let actionToolbox = this.state.actionToolbox;
		let initialXml = this.state.blocklyxml ? this.state.blocklyxml : '';

		return (
			<div className="automation-setting-container pt-0 pr-3 pl-3">
				{header}
				<ReactBlocklyComponent.BlocklyEditor
					ref={(blocklyEditor) => { this.blocklyEditor = blocklyEditor; }}
					className="react-blockly-component"
					workspaceConfiguration={workspaceConfiguration}
					toolboxCategories={actionToolbox}
					initialXml={initialXml}
					wrapperDivClassName="automation-settings--blockly"
					// xmlDidChange={this.handleXMLChange}
					namedContexts={'{}'}
					triggerName={trigger}
					runTimeVariables={triggerInfo ? JSON.stringify({'webhookVariables': triggerInfo.runTimeVariables}) : undefined} // @TODO: runtime variables
					workspaceDidChange={this._workspaceDidChange}
				/>
			</div>
		);
	}
}

const container = Container.create(WebhookSetting, { withProps: true });
export default container;