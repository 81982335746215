var keyMirror = require('keymirror');

// Define action constants
var AdminSettingsConstants = keyMirror({
	'AUTOMATION_SUBTAB_CHANGE': null,
	'CENTER_COLUMN_RESIZE': null,
	'CHANGE_COMPONENT': null,
	'CHANGE_SELECTED_SETTINGS_PANEL': null,
	'CLOSE_MODAL': null,
	'LEFT_PANEL_CHANGE': null,
	'LEFT_PANEL_TAB_CHANGE': null,
	'VISIBILITY_CONTROLS_ADD_AND_EDIT': null,
	'OPEN_MODAL': null,
	'OVERLAY_CHANGE': null,
	'OVERLAY_CHANGE_WITH_RECALC': null,
	'QUERY_SETTING_CHANGE': null,
	'RIGHT_PANEL_CHANGE': null,
	'SETTING_CHANGE': null,
	'SETTINGS_LIST_HIDE': null,
	'SETTINGS_MAINTAB_CHANGE': null,
	'SETTINGS_PANEL_DASHBOARD_CHANGE': null,
	'SETTINGS_PANEL_EXPAND': null,
	'SETTINGS_PREVIEW_CHANGE': null,
	'SETTINGS_SUBTAB_CHANGE': null,
	'SHOW_SETTINGS': null,
	'SUB_SETTING_CHANGE': null
});
module.exports = AdminSettingsConstants;
