/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';
// import Immutable from 'immutable';

import ComplianceModalContainer from './compliance/compliance-modal-container.react';

// Actions
import AdminSettingsActions from '../../actions/admin-settings-actions';
import InterfaceActions from '../../actions/interface-actions';
// import ComplianceActions from '../../actions/compliance-actions';

// Stores
import AdminSettingsStore from '../../stores/admin-settings-store';
import FieldStore from '../../stores/field-store';
import ContextStore from '../../stores/context-store';
import ComplianceStore from '../../stores/compliance-store';
import RenderStore from '../../stores/render-store';

// Utils
import uuid from 'uuid';
import FieldActions from '../../actions/field-actions';

class ComplianceSettingsChooser extends Component {
	/**
	 * Creates instance of ComplianceSettingsChooser
	 *
	 * @memberOf ComplianceSettingsChooser
	 */
	constructor(props) {
		super(props);
		this._renderOption = this._renderOption.bind(this);
		this.onAddClick = this.onAddClick.bind(this);
		this.onResetClick = this.onResetClick.bind(this);
		this.onSaveClick = this.onSaveClick.bind(this);
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, RenderStore, ComplianceStore, FieldStore];
	}
	
	/**
	 * onAddClick - Sets up a new recordId for the security setting UI to use.
	 *
	 * @param {object} event
	 */
	onAddClick(event){
		let modalId = uuid.v4();
		let { 
			disabledRetailRestrictions, 
			onUpgradeClick 
		} = this.props;
		AdminSettingsActions.openModal(modalId, 
			<ComplianceModalContainer 
				key={modalId} 
				disabledRetailRestrictions={disabledRetailRestrictions}
				onUpgradeClick={onUpgradeClick}
				/>);
	}

	/**
	 * onSaveClick - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	onSaveClick(event) {
		// @TODO
		// Display notification to user
		let saveNotification = InterfaceActions.stickyNotification({level: 'info', message: 'Updating all applied tags. This may take a few seconds, please wait...'});
		let {componentId} = this.state;
		FieldActions.pushToDatabasePromise(FieldStore.get(componentId))
			.then(() => {
				InterfaceActions.clearStickyNotification(saveNotification);
			})
			.catch(err => {
				console.error('Error saving field %s', componentId, err);
				InterfaceActions.notification({level: 'error', message: 'Field update error.  See your console for details.'});
				InterfaceActions.clearStickyNotification(saveNotification);
			});
	}

	/**
	 * onResetClick - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event
	 */
	onResetClick(event){
		let {componentId} = this.state;
		InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting Field...' });
		FieldActions.pullFromDatabase(componentId, true);
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingFieldId
	 * @param {any} event 
	 * @memberof QuerySettingsChooser
	 */
	onSettingClick(complianceId, event) {
		event.preventDefault();

		if(!complianceId) {
			return;
		}

		// Ensure the Settings are shown
		AdminSettingsActions.onSettingsListHideChange(false);

		// Toggle to select and deselect the Setting
		if(AdminSettingsStore.getSettingRecordId() === complianceId) {
			AdminSettingsActions.onSettingChange('');
		} else {
			AdminSettingsActions.onSettingChange(undefined, complianceId);
		}
	}

	/**
	 * 
	 * @static
	 * @param {any} prevState 
	 * @returns {Object} - Query Setting list
	 * @memberof QuerySettingsChooser
	 */
	static calculateState(prevState, props) {

		let renderId = AdminSettingsStore.getRenderId();
		let renderObj = RenderStore.get(renderId) || {};
		let pageObj = RenderStore.getPageRenderObj(renderId) || {};
		let componentId = AdminSettingsStore.getRecordId();
		let {dataRecordId, dataTableSchemaName} = renderObj;
		let pageId = pageObj.componentId;
		let applicationId = ContextStore.getApplicationId();
		let installationId = ContextStore.getInstallationId();
		let componentType = AdminSettingsStore.getTableSchemaName();

		// For now, default to all discussions if no component specified
		let compliances = ComplianceStore.getAllArray();
		let fieldSettings = FieldStore.getSettings(componentId);

		if(componentType === 'page') {
			return {
				isPage: true
			}
		}

		return {
			compliances,
			componentId,
			applicationId,
			pageId,
			dataRecordId,
			dataTableSchemaName,
			installationId,
			complianceTags: (fieldSettings.complianceTags ? JSON.parse(fieldSettings.complianceTags) : {}),
		};
	}

/**
	 * Renders a single discussion as an LI option.
	 * 
	 * @param {Object} complianceObj 
	 * @param {string} complianceObj.isSelected Is this option the selected UI option?
	 * @param {string} complianceObj.recordId Record Id
	 * @param {string} complianceObj.name The name of the compliance
	 * @param {string} complianceObj.description Description
	 * @param {number} complianceObj.isActive Is the compliance active or not? (1 or 0)
	 * @param {number} index The Index of this option
	 * @return JSX
	 */
	_renderOption(complianceObj, index) {
		
		if(!complianceObj.isActive) {
			return (null);
		}

		let { complianceTags } = this.state;
		let labelClassNames = 'setting-label';

		if(complianceObj.recordId === AdminSettingsStore.getSettingRecordId()) { 
			labelClassNames += ' selected';
		}

		return (<li key={index} className="nav-item compliance">
					<div className="nav-link" onClick={this.onSettingClick.bind(this, complianceObj.recordId)}>
						<div className="d-flex setting-text-container">
							<div className="w-50 setting-label-container">
								<div className={labelClassNames}><h4>{complianceObj.name || '[ No Name Found ]'}</h4></div>
							</div>
							<div className="w-50 setting-value-container" style={{ textAlign: 'right' }}>
								<div className="setting-value" style={{ width: '100%', paddingRight: '5px' }}>
									{ Object.keys(complianceTags).map((index) => {
										let compliance = complianceTags[index];
										let tagSpans = [];
										complianceObj.tags.forEach((tag) => {
											if(compliance[tag.recordId]) {
												tagSpans.push(tag.name);
											}
											
										});
										return <span key={index}>{tagSpans.join(', ')}</span>;
									})}
								</div>
							</div>
						</div>
					</div>
				</li>);
	}

	/**
	 * 
	 * @returns - DOM of list of Query
	 * @memberof QuerySettingsChooser
	 */
	render() {
		let { 
			disabledRetailRestrictions, 
			onUpgradeClick 
		} = this.props;
		let { isPage } = this.state;
		let settingsList = [];

		if(isPage) {
			return <div key="settings" className='settings-list-wrapper'>
					<div style={{ marginTop: 0 }} className='no-settings-found'>No Settings Found</div>
				</div>;
		}

		settingsList.push(this.state.compliances.sort((a, b) => {
			// Put closed stuff last.
			if(a.isActive !== 1 && b.isActive === 1) {
				return 1;
			} else if (a.isActive === 1 && b.isActive !== 1) {
				return -1;
			}
			
			// Same name?  Don't Swap.
			if(a.name === b.name) return 0;

			// Ascending Alphabetically.
			return a.name < b.name ? -1 : 1;
		}).map(this._renderOption.bind(this)));

		let buttons;
		if(disabledRetailRestrictions) {
			buttons = (<div key="button" className="btn-wrapper">
				<button 
					// key="submit" 
					className="btn btn-success btn-lg setting-list-button mr-3 ml-1" 
					form="appearance-form" 
					aria-label="Upgrade to use Compliance"
					style={{ width: '100%'}}
					onClick={onUpgradeClick}>
					Upgrade to use Compliance
				</button>
			</div>);
		} else {
			buttons = (<div key="buttons" className="btn-wrapper">
				<button 
					key="submit" 
					className="btn btn-primary btn-lg setting-list-button mr-3 ml-1" 
					form="appearance-form" 
					aria-label="Save"
					onClick={this.onSaveClick}>
					Save
				</button>
				<button 
					key="reset" 
					className="btn btn-warning btn-lg setting-list-button"
					form="appearance-form" 
					aria-label="Reset" 
					onClick={this.onResetClick}>
					Reset
				</button>
			</div>);
		}

		return [
			<div key="list" className='settings-list-wrapper mt-0'>
				<ul key="list" className="nav flex-column">
					<li className="settings-list-header field-settings-list-header"><h3>Compliances</h3></li>
					{settingsList}
				</ul>
				<div className="mt-3">
					<button 
						key="add" 
						className={"btn btn-success ml-4 " + (disabledRetailRestrictions ? 'cd-btn-disabled' : '')}
						form="appearance-form"
						aria-label="Manage Compliances"
						id="addCompliance"
						onClick={this.onAddClick}>
							Manage Compliances
					</button>
				</div>
			</div>,
			buttons];
	}
}
const container = Container.create(ComplianceSettingsChooser, { withProps: true });
export default container;