import React from 'react';
import _ from 'lodash';
import BaseSettingForm from './base-setting-form.react';
/**
 * Component for LongText
 * 
 * @class LongText
 * @extends {Component}
 */
export default class LongText extends BaseSettingForm {
	/**
	 * Render the LongText
	 * 
	 * @returns React
	 * 
	 * @memberof LongText
	 */
	render() {
		let {onChange, fieldSchemaName, maxWidth, value} = this.props;
		let style = maxWidth ? {'maxWidth': maxWidth } : {} ;
		return(
			<div>
				<textarea style={style} id={fieldSchemaName} className="form-control" onChange={onChange} rows="10" value={value}/>
			</div>
		);
	}
}
