import { 
	AdminSettingsActions,
	InterfaceActions, 
	LogicFunctionActions, 
	MetadataActions 
} from '../actions';

import { 
	AdminSettingsStore,
	LogicFunctionStore,
	MetadataStore 
} from '../stores';

import {
	ActionProcessor,
	BlocklyUtils,
	ObjectUtils
} from '../utils';

import ContextStore from '../stores/context-store';

export default class LogicUtils {
	/**
	 * saveEndPointLogic - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	static saveEndPointLogic(event) {
		let recordId = AdminSettingsStore.getRecordId();
		let apiObj = MetadataStore.get(recordId, 'apiconfig');
		let valid = true;

		if(!apiObj.method || apiObj.method.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Method is required for an API.' });
			AdminSettingsActions.onSettingChange('method', 'b3c3f007-6eff-4ddb-8428-5e5a64e40888');
		}
		if(!apiObj.uri || apiObj.uri.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'URL is required for an API.' });
			AdminSettingsActions.onSettingChange('uri', 'b3716b9e-9eb2-42bf-ab4f-4adeb0782f71');
		}

		if(valid) {
			let id = InterfaceActions.stickyNotification({ 'level': 'success', 'message': 'Saving API...' });
			
			// If the logic did change...
			let dirtyApiObj = MetadataStore.get(recordId, 'apiconfig', true);
			let logicObj = ObjectUtils.getObjFromJSON(dirtyApiObj.logic);
			if(logicObj && logicObj.blocklyxml) {
				delete logicObj.forceDirty;
				// Regenerate the JS
				let params = {
					defaultToNull: true,
					memUse: logicObj.memUse ? logicObj.memUse : 'l',
					includeJs: true
				};
				
				BlocklyUtils.saveAutomationFromWorkspaceV2(BlocklyUtils.getWorkspaceFromXml(logicObj.blocklyxml), params, (blocklyValueObj) => {
					apiObj.logic = JSON.stringify(blocklyValueObj);
					MetadataActions.pushToStore(recordId, 'apiconfig', apiObj);

					// Push to database
					MetadataActions.pushToDatabasePromise(apiObj, 'apiconfig').then(() => {
						let updateNotification = InterfaceActions.stickyNotification({
							'message': 'Updating API endpoints...',
							'level': 'info'
						});
						return new Promise(function(resolve, reject) {
							fetch(ContextStore.getBasePath() + '/gw/updateRouter', {
								method: 'GET',
								headers: {
									'Content-Type': 'application/json; charset=UTF-8'
								}
							}).then(function (response) {
								InterfaceActions.clearStickyNotification(updateNotification);
								InterfaceActions.clearStickyNotification(id);
								resolve();
							}).catch(function (error) {
								InterfaceActions.notification({
									level: 'error',
									message: 'API endpoint update failed. ' +
										'Review the console for more information. ' +
										'You may navigate to /gw/updateRouter to update your router manually.'
								});
								console.error('API endpoint update failed:', error);
								reject(error);
							});
						});	
					}).catch(error => {
						InterfaceActions.clearStickyNotification(id);
						InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save API' });
						console.error('Unable to save API Config:', error);
					})
				});
			// Logic did NOT change.
			} else {
				// Push to database
				MetadataActions.pushToDatabasePromise(apiObj, 'apiconfig').then(() => {
					let updateNotification = InterfaceActions.stickyNotification({
						'message': 'Updating API endpoints...',
						'level': 'info'
					});
					return new Promise(function(resolve, reject) {
						fetch(ContextStore.getBasePath() + '/gw/updateRouter', {
							method: 'GET',
							headers: {
								'Content-Type': 'application/json; charset=UTF-8'
							}
						}).then(function (response) {
							InterfaceActions.clearStickyNotification(updateNotification);
							InterfaceActions.clearStickyNotification(id);
							resolve();
						}).catch(function (error) {
							InterfaceActions.notification({
								level: 'error',
								message: 'API endpoint update failed. ' +
									'Review the console for more information. ' +
									'You may navigate to /gw/updateRouter to update your router manually.'
							});
							console.error('API endpoint update failed:', error);
							reject(error);
						});
					});
				}).catch(error => {
					InterfaceActions.clearStickyNotification(id);
					InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save API' });
					console.error('Unable to save API Config:', error);
				})
			}
		}
	}

	/**
	 * resetEndpointLogic - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event
	 */
	static resetEndpointLogic(event) {
		event.preventDefault();
		let recordId = AdminSettingsStore.getRecordId();
		let currentObj = MetadataStore.get(recordId, 'apiconfig');
		if(currentObj && currentObj.logic) {
			let logicObj = ObjectUtils.getObjFromJSON(currentObj.logic);
			// Handle converting logicFunctionsUsed into an array
			let logicFunctionIdsArr =[]; 
			let logicFunctionIds = logicObj.logicFunctionsUsed;
			if (logicFunctionIds) {
				if(Array.isArray(logicFunctionIds)) {
					logicFunctionIdsArr = logicFunctionIds;
				} else {
					logicFunctionIdsArr = logicFunctionIds.split(',');
				}
			}
			if (logicFunctionIdsArr && logicFunctionIdsArr.length) {
				InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting all functions used within this trigger to their saved values...' });
				logicFunctionIdsArr.forEach(logicFunctionId => {
					LogicFunctionActions.pullFromDatabase(logicFunctionId);
				});
			}
		}

		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Resetting API...' });
		AdminSettingsActions.onSettingChange('', ''); // Temporary workaround pending further reset work

		// Open the Settings Panel
		AdminSettingsActions.onSettingsListHideChange(false);

		// Pull from database, therefore resetting it
		MetadataActions.pullFromDatabase(recordId, 'apiconfig');
	}

	/**
	 * saveFunction - Saves a Function
	 *
	 * @param  {object} event passed in as a convenience in case this is used later
	 * @todo Amber to fix up the functions in functions code below.
	 */
	static saveFunction(event) {
		event.preventDefault();

		let recordId = AdminSettingsStore.getRecordId();
		let id = InterfaceActions.stickyNotification({ 'level': 'success', 'message': 'Saving Function...' });
		let functionRecord = LogicFunctionStore.get(recordId);

		let updatePromise = Promise.resolve();

		// We need to evaluate any logic functions which may have changed
		let changedRecord = LogicFunctionStore.get(recordId, true);
		if(changedRecord && changedRecord.params) {
			// If the params have changed, we need to update the logic function workspace
			// and also update all locations of this logic function
			let fullRecord = LogicFunctionStore.getFull(recordId);
			let params = fullRecord.get('params');
			let oldParams = params.get('originalValue');
			let newParams = params.get('value');
			let blocklyxml = BlocklyUtils.replaceParams(oldParams, newParams, functionRecord.blocklyxml);
			changedRecord.blocklyxml = blocklyxml;
			functionRecord.blocklyxml = blocklyxml;
			LogicFunctionActions.pushToStore(recordId, functionRecord);
		}
		if(changedRecord && (changedRecord.blocklyxml || changedRecord.params)) {
			delete changedRecord.forceDirty;
			// Regenerate the JS
			let params = {
				includeJs: true,
				kind: 'logicFunctions',
				recordId: recordId,
				title: functionRecord.name,
				paramsArr: functionRecord.params ?
					JSON.parse(functionRecord.params) :
					[]
			};
			
			updatePromise = BlocklyUtils.saveAutomationFromLogicWorkspace(BlocklyUtils.getWorkspaceFromXml(changedRecord.blocklyxml), params, (blocklyValueObj) => {

				LogicFunctionActions.pushToStore(recordId, blocklyValueObj);
				// let toSave = LogicFunctionStore.get(this.state.recordId, true);
				// return LogicFunctionActions.pushToDatabasePromise(toSave);
				return Promise.resolve();
			});
		}

		// Actually save the logic function
		updatePromise
			.then(() => {
				return LogicFunctionActions.pushToDatabasePromise(functionRecord);
			})
			.then(() => {
				InterfaceActions.clearStickyNotification(id);
			})
			.catch(error => {
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save Function' });
				console.error('Unable to save Function:', error);
			});
	}

	/**
	 * resetFunction - Resets the current function
	 *
	 * @param  {object} event Dom event
	 */
	static resetFunction(event) {
		event.preventDefault();

		let recordId = AdminSettingsStore.getRecordId();
		let logicFunctionObj = LogicFunctionStore.getAllArray();

		// Reset all of the logic functions used to the value from the database
		// (Yes, this may mean undoing a logic function change if changed in another trigger but not saved. That's the way it's going to have to be. Don't do that.)
		let logicFunctionIds = logicFunctionObj.logicFunctionsUsed;
		
		// Handle converting logicFunctionsUsed into an array
		let logicFunctionIdsArr =[]; 
		if (logicFunctionIds) {
			if(Array.isArray(logicFunctionIds)) {
				logicFunctionIdsArr = logicFunctionIds;
			} else {
				logicFunctionIdsArr = logicFunctionIds.split(',');
			}
		}
		if (logicFunctionIdsArr && logicFunctionIdsArr.length) {
			InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting all functions used within this logic function to their saved values...' });
			logicFunctionIdsArr.forEach(logicFunctionId => {
				LogicFunctionActions.pullFromDatabase(logicFunctionId);
			});
		}

		InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting Logic Function...' });
		LogicFunctionActions.pullFromDatabase(recordId);

	} // end resetScheduledLogic


	/**
	 * saveScheduledLogic - Saves the current scheduled logic
	 *
	 * @param  {object} event passed in as a convenience in case this is used later
	 */
	static saveScheduledLogic(event) {
		event.preventDefault();

		let recordId = AdminSettingsStore.getRecordId();
		let id = InterfaceActions.stickyNotification({ 'level': 'success', 'message': 'Saving Schedule...' });
		let metaRecord = MetadataStore.get(recordId, 'scheduledLogic');

		// We need to evaluate any logic functions which may have changed
		let changedRecord = MetadataStore.get(recordId, 'scheduledLogic', true);
		let logicObj = ObjectUtils.getObjFromJSON(changedRecord.logic);
		if(logicObj && logicObj.blocklyxml) {
			delete logicObj.forceDirty;
			// Regenerate the JS
			let params = {
				defaultToNull: true,
				memUse: logicObj.memUse ? logicObj.memUse : 'l',
				includeJs: true
			};
			
			BlocklyUtils.saveAutomationFromWorkspaceV2(BlocklyUtils.getWorkspaceFromXml(logicObj.blocklyxml), params, (blocklyValueObj) => {
				metaRecord.logic = JSON.stringify(blocklyValueObj);
				MetadataActions.pushToStore(recordId, 'scheduledLogic', metaRecord);
			});
		}
		// Only changed something not involving logic; just push to database
		MetadataActions.pushToDatabasePromise(metaRecord, 'scheduledLogic').then(() => {
			InterfaceActions.clearStickyNotification(id);
		}).catch(error => {
			InterfaceActions.clearStickyNotification(id);
			InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save Schedule' });
			console.error('Unable to save Schedule:', error);
		});
	}

	/**
	 * resetScheduledLogic - Resets the current scheduled logic
	 *
	 * @param  {object} event Dom event
	 */
	static resetScheduledLogic(event) {
		event.preventDefault();

		let recordId = AdminSettingsStore.getRecordId();
		let currentObj = MetadataStore.get(recordId, 'scheduledLogic');
		if(currentObj && currentObj.logic) {
			let logicObj = ObjectUtils.getObjFromJSON(currentObj.logic);
			// Handle converting logicFunctionsUsed into an array
			let logicFunctionIdsArr =[]; 
			let logicFunctionIds = logicObj.logicFunctionsUsed;
			if (logicFunctionIds) {
				if(Array.isArray(logicFunctionIds)) {
					logicFunctionIdsArr = logicFunctionIds;
				} else {
					logicFunctionIdsArr = logicFunctionIds.split(',');
				}
			}
			if (logicFunctionIdsArr && logicFunctionIdsArr.length) {
				InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting all functions used within this trigger to their saved values...' });
				logicFunctionIdsArr.forEach(logicFunctionId => {
					LogicFunctionActions.pullFromDatabase(logicFunctionId);
				});
			}
		}
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Resetting Schedule...' });

		// Temporary workaround pending further reset work
		AdminSettingsActions.onSettingChange('', '');

		// Open the Settings Panel
		AdminSettingsActions.onSettingsListHideChange(false);

		// Pull from database, therefore resetting it
		MetadataActions.pullFromDatabase(recordId, 'scheduledLogic');
	} // end resetScheduledLogic

	/**
	 * runScheduledLogic - Runs the current scheduled logic
	 *
	 * @param  {object} event passed in as a convenience in case this is used later
	 */
	static runScheduledLogic(event) {
		event.preventDefault();

		let recordId = AdminSettingsStore.getRecordId();
		let isDirty = false;
		let recordObj = MetadataStore.getFull(recordId, 'scheduledLogic');
		if(recordObj) {
			recordObj.forEach((value, key) => {
				// @TODO: Should we limit this so it's only marked as dirty if the logic is different?
				isDirty = isDirty || value.get('isDirty');
			});
		}

		if(isDirty) {
			InterfaceActions.notification({message: 'This logic has unsaved changes. Processing logic from the last saved version.', level: 'warning'});
		}
		let actionRequest = {
			'actionRecordId': recordId,
			'actionTableSchemaName': 'scheduledLogic',
			'hookId': 'scheduledLogic'
		}

		InterfaceActions.notification({message: 'Scheduled Action Running...'});
		ActionProcessor.processAction(actionRequest).then(result => {
			if (result.message === "Complete") {
				InterfaceActions.notification({
					message: 'Scheduled Action Complete',
					level: 'success'
				});
			} else {
				InterfaceActions.notification({
					message: 'Scheduled Action Failed',
					level: 'error'
				});
			}
		}).catch(error => {
			console.error(error);
		});
	} // end runScheduledLogic
}
