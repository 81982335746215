import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { map } from 'lodash';

// Actions
import { AdminSettingsActions } from '../../../actions';
import MapActions from '../../../actions/map-actions';

// Constants
import GroupFilters from '../../../constants/group-filters';
import Help from '../../../constants/help-upgrade-constants';

// Stores
import AdminSettingsStore from '../../../stores/admin-settings-store';
import ComplianceStore from '../../../stores/compliance-store';
import MapStore from '../../../stores/map-store';

// Utils
import UIUtils from '../../../utils/ui-utils';
import MapUtils from '../../../utils/map-utils';

/**
 * Listing of Compliances for Compliance Dashboard
 *
 * @class ComplianceDashboardTabContainer
 * @extends {Component}
 */
class ComplianceDashboardTabContainer extends Component {
	/**
	 * Creates an instance of ComplianceDashboardTabContainer
	 */
	constructor(props) {
		super(props);
		this._renderGroupBy = this._renderGroupBy.bind(this);
		this._onAddCompliances = this._onAddCompliances.bind(this);
		this._onChangeSearch = this._onChangeSearch.bind(this);
		this._onChangeGroupBy = this._onChangeGroupBy.bind(this);
		this._onOpenField = this._onOpenField.bind(this);
		this._onToggleShow = this._onToggleShow.bind(this);
		this._onToggleAllOpen = this._onToggleAllOpen.bind(this);
		this._onToggleAllClosed = this._onToggleAllClosed.bind(this);
	}

	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 */
	static getStores() {
		return [AdminSettingsStore, ComplianceStore, MapStore];
	}

	/**
	 * Returns the current State
	 *
	 * @static
	 * @returns {Object}
	 */
	static calculateState(prevState) {
		let compliances = ComplianceStore.getAllArray();
		let groupBy = (MapStore.getGroupBy() ? MapStore.getGroupBy() : GroupFilters.COMPLIANCE);
		let search = MapStore.getSearch();

		if (prevState
			&& prevState.compliances === compliances
			&& prevState.groupBy === groupBy
			&& prevState.search === search) {
			return false;
		}

		return {
			compliances: compliances,
			groupBy: groupBy,
			search: search,
			groupedCompliances: this._performGroupBy(compliances, groupBy, search),
			groupToggleStatus: MapStore.getGroupToggleStatusGroupBy(AdminSettingsStore.getActiveDashboard(), 'compliances'),
		}
	}

	/**
	 * Using the group by set in the UI, group the compliances and return
	 * an object with the groups as keys and functions array as value.
	 * @param {Array} pages Pages to group
	 * @param {String} search What search to apply, if any.
	 * @return Object
	 * 
	 * @Todo Implement
	 */
	static _performGroupBy(compliances, groupBy, search) {
		let groups = {};

		if (!compliances) {
			return groups;
		}

		// Filter out the compliances that dont have the search term
		compliances = compliances.filter(compliance => {
			if (search && search.length > 0) {
				let filterableString = (compliance.name) ? compliance.name.toLowerCase() : '';
				compliance.tags.forEach(tag => {
					filterableString += " " + tag.name;
				})
				// Add Tables
				// Add Fields
				return (filterableString.toLowerCase().includes(search.toLowerCase()));
			} else {
				return true;
			}
			// Sort the discussions - by Closed/Open, then Numerically.
		}).sort((a, b) => {
			// Ascending Alphabetically.
			return b.name < a.name ? 1 : -1;
		});

		// Prepare the final return
		let returnGroups = MapUtils.groupBy(compliances, groupBy);

		// If we have a search...
		if (search.length > 0) {
			let groupToggleStatus = {};
			Object.keys(returnGroups).forEach(group => {
				groupToggleStatus[group] = true;
			})
			MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'compliances', groupToggleStatus);
		}

		return returnGroups;
	}

	/**
	 * Render method
	 */
	render() {
		let { groupedCompliances, search } = this.state;

		let noSearchFound = null;
		if(Object.keys(groupedCompliances).length === 0 && search.length) {
			noSearchFound = <li className="no-search-found" style={{ color: 'white'}}><h4>No Results for '{search}' found.</h4></li>
		}

		return (
			<div id="data-map__content" className="map">
				<div title="Search by compliance, tag, table or field name" className="cd-search-container">
					<input className="form-control compliance-search-input" placeholder="Search" type="text" value={search} onChange={this._onChangeSearch} />
				</div>

				<div className="section-header" key="expand-collapse-tools">
					<div className="d-flex justify-content-end align-items-center">
						<h5 className="bold">Compliances</h5>
						<div className="d-flex">
							<div className="info-icon ml-2" onClick={() => { UIUtils.onHelpClick(Help.HELP_DASHBOARD_COMPLIANCE); }}>
								<i className="fa fa-info-circle mr-1"></i> | 
							</div>
							<div data-toggle="modal" onClick={this._onAddCompliances} >
								<i className="fa fa-plus-circle ml-1"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="list-content-wrapper" key="pages">
					<ol>
						{map(groupedCompliances, this._renderGroupBy)}
						{ noSearchFound }
					</ol>
				</div>
			</div>
		);
	}

	/**
	 * Return JSX for one "Group" of Compliance's, and it's header.
	 * @param group object Group to show
	 * @return JSX
	 */
	_renderGroupBy(tags, groupName) {
		let { groupToggleStatus } = this.state;
		let tagList = [];

		if(!tags) {
			return null;
		}

		tags.forEach((tag, index) => {
			tagList.push(
				<li key={index} className="element-item">
					<div className="col-6 element-label py-2">
						<div className={"page-load-link"}
							href="#" data-toggle="modal"
							data-target="#page-dialog"
							onClick={() => {
								// this._onOpenField(tag.fieldId, group.complianceId)
							}}>
							<h5>{tag.name}</h5>
						</div>
					</div>
						{/* <div className="col-6">
							<ol className="subElementList">
								{element.subElements.map((subElement, index) => {
									return <li key={index} className="sub-element-label"><span className="notlink">- {subElement.label}</span></li>;
								})}
							</ol>
						</div> */}
				</li>
			);
		});

		let show = groupToggleStatus[groupName];

		return (
			<li key={groupName} className="role-group-item">
				<div className="role-group-name d-flex" onClick={this._onToggleShow.bind(this, groupName, !show)}>
					<div className="mr-1">
						{show 
							? <i className="fa fa-minus"></i>
							: <i className="fa fa-plus"></i>
						}
					</div>
					<div className="d-flex">
						<h4 className={"site-map-table-icon fa fa-balance-scale"}></h4>
						<h4>{groupName}</h4>
					</div>
				</div>
				{/* <div className="col-1 text-center"> */}
					{/* <span className="fa fa-pencil link" onClick={() => { */}
						{/* // this._openManageComplianceModal(group.complianceId);
						// this._openComplianceModal(group.complianceId);  */}
					{/* }} title="Edit Compliance"></span> */}
				{/* </div> */}
				<ol id={groupName} className={'collapse pl-4 pt-2 pr-0 ' + (show ? 'show ' : '') + 'groupby-list'}>
					{tagList}
				</ol>
			</li>
		);
	}

	/**
	 * private Handles Opening the Compliances list.
	 * @TODO Implement
	 */
	_onAddCompliances() {
	}

	/**
	 * private Handles change to group by dropdown
	 */
	_onChangeGroupBy(event) {
		MapActions.groupBy(event.target.value);
	}

	/**
	 * private Handles change to group by dropdown
	 */
	_onChangeSearch(event) {
		MapActions.search(event.target.value);
	}

	/**
	 */
	_onOpenField(fieldId, complianceId) {
		let parentRecordId = undefined;
		let parentTableSchemaName = undefined;
		let renderId = undefined;
		let mainTab = undefined;
		let subsettingSchemaName = undefined;

		UIUtils.openSettingsPanel('compliance',
			fieldId, 'field',
			parentRecordId, parentTableSchemaName, 'settings',
			mainTab, subsettingSchemaName, renderId
		);

		AdminSettingsActions.onSettingChange(undefined, complianceId);
	}

	/**
	 * Toggle one group.
	 */
	_onToggleShow(groupLabel, show) {
		MapActions.groupToggle(AdminSettingsStore.getActiveDashboard(), 'compliances', groupLabel, show);
	}

	/**
	 * Open all of the groups
	 */
	_onToggleAllOpen() {
		let { groupedCompliances, groupToggleStatus } = this.state;
		Object.keys(groupedCompliances).forEach(group => {
			groupToggleStatus[group] = true;
		})
		MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'compliances', groupToggleStatus);
	}

	/**
	 * Close all of the groups
	 */
	_onToggleAllClosed() {
		let { groupToggleStatus } = this.state;
		Object.keys(groupToggleStatus).forEach(key => {
			groupToggleStatus[key] = false;
		})
		MapActions.groupToggleBulk(AdminSettingsStore.getActiveDashboard(), 'compliances', groupToggleStatus);
	}
}

const container = Container.create(ComplianceDashboardTabContainer);
export default container;
