/**
 * Helper class to manipulate dictionaries Data
 */
export default class DictionariesUtils {
	/**
	 * takes Multiple params as 1 array of params
	 * 
	 * @static
	 * @param {Array} theArgs 
	 * @memberof DictionariesUtils
	 */
	static removeDuplicateWordsInString(...theArgs) {
				
		let flattenArray = [];

		//Flaten the Array of Arrays ['string one', 'string two']
		theArgs.forEach((string) =>{
			string.split(' ').forEach( word => {
				//Remove whiteSpaces 
				if(word !== ''){
					flattenArray.push(word.toLowerCase());
				}
			})
		});

		//Remove duplicated elements from Array: 
		let finalString = flattenArray.filter((item, index, inputArray) => {
			return inputArray.indexOf(item) === index;
		}).join(' ');				

		return finalString;	
	}
	/**
	 * Takes a string and returns a clean array
	 * 
	 * @static
	 * @param {string} myString 
	 * @returns - array format of the string
	 * @memberof DictionariesUtils
	 */
	static getKeywordsArray(myString){
		let myArray = [];

		//Split string on each ,
		myString.split(',').forEach( word => {
			//Ignore empty words  
			if(word !== ''){
				//Trim, lowerCase and push to final array
				myArray.push(word.toLowerCase().trim());
			}
		})

		//Remove Duplicates
		myArray = myArray.filter((item, index, inputArray) => {
			return inputArray.indexOf(item) === index;
		});	

		return myArray;

	}
}
