import React from 'react';
import _ from 'lodash';
import BaseSettingForm from './base-setting-form.react';
/**
 * Component for BooleanSelect
 * 
 * @class BooleanSelect
 * @extends {Component}
 */
export default class BooleanSelect extends BaseSettingForm {
	/**
	 * Creates an instance of BooleanSelect
	 * 
	 * @param {Object} props
	 * @constructor
	 * 
	 * @memberof BooleanSelect
	 */
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
	}

	/**
	 * onChange wrapper to override string values to boolean
	 * 
	 * @returns React
	 * 
	 * @memberof BooleanSelect
	 */
	onChange(event) {
		let {onChange} = this.props,
			{target:{value}} = event,
			changeEvent = {
				target:{
					id: event.target.id,
					value: (value === 'true')
				}
			};
		if(typeof onChange === 'function') {
			onChange(changeEvent);
		}
	}
	/**
	 * Render the BooleanSelect
	 * 
	 * @returns React
	 * 
	 * @memberof BooleanSelect
	 */
	render() {
		let {fieldSchemaName, value} = this.props;
		return(
			<div className="">
				<select id={fieldSchemaName} className="form-control" value={value} onChange={this.onChange} >
					<option value={false}>No</option>
					<option value={true}>Yes</option>
				</select>
			</div>
		);
	}
}
