import AppDispatcher from '../dispatcher/app-dispatcher'
import TemplateConstants from '../constants/template-constants'
import Immutable from 'immutable'
// import UIUtils from '../utils';

const TemplateActions = {

    onDataLoaded(templateId, data) {
      AppDispatcher.dispatch(Immutable.fromJS({
        'type': TemplateConstants.TEMPLATE_LOADED,
        'templateId': templateId,
        'data': data
      }))
    },
    onError(templateId){
      AppDispatcher.dispatch(Immutable.fromJS({
        'type': TemplateConstants.TEMPLATE_DATA_ERROR,
        'templateId': templateId
      }))
    }
}

export default TemplateActions;