import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import { FieldConstants } from '../constants/field-constants';
import MetadataBuilder from '@dmclain-citizendeveloper/citdev-module-metadata-builder';
import fetchMetadata from '../utils/fetch-utils';

let _instance = null;

/**
 * Actions for the core store that contains field records for the dev environment where we talk to the MDGW
 *
 * @class FieldActionsDev
 */
class FieldActionsDev {
	/**
	 * Singleton instance of FieldActionsDev
	 * @param {Object} options
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if (options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Appends to the setting history for the specified settingSchemaName
	 * 
	 * @param {string} fieldRecordId 
	 * @param {string} settingSchemaName 
	 * @param {any} settingValue 
	 * @param {string} userId 
	 * @param {string} userName 
	 * @param {string} patternId 
	 * @param {string} valueQuality 
	 * @memberof FieldActionsDev
	 */
	appendSettingHistory(fieldRecordId, settingSchemaName, settingValue, userId, userName, patternId, valueQuality) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: FieldConstants.FIELD_HISTORY_APPEND_TO_STORE,
			recordId: fieldRecordId,
			settingSchemaName: settingSchemaName,
			settingValue: settingValue,
			userId: userId,
			userName: userName,
			patternId: patternId,
			valueQuality: valueQuality
		}));
	}

	/**
	 * Set the Installation Id and (optionally the endPoint) and setup the Metadata builder
	 * 
	 * @param {string} installationId 
	 * @param {string} endPoint 
	 */
	setInstallationId(installationId, signedMdKey, endPoint) {
		this._metadataBuilder = new MetadataBuilder(installationId, signedMdKey, endPoint);
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw (new Error('FieldActionsDev not properly initialized with a dispatcher'));
		}
		if (!this._metadataBuilder) {
			throw (new Error('FieldActionsDev not properly initialized with an installationId'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: FieldConstants.FIELD_PULL_ERROR,
			error: error.message
		}));
	}

	receiveBroadcast(records) {
		this._checkInit();
		

		let startTime = +new Date();

		// Initial broadcast to make sure everything updates
		this._dispatcher.dispatch(Immutable.fromJS({
			type: FieldConstants.FIELD_RECEIVE_BROADCAST,
			records: records,
			lastChildCalculation: startTime
		}));
		if(records && records.length) {
			let RenderActions = require('./render-actions').default;

			records.forEach(record => {
				if(record.properties && record.properties.attachedFields) {
					// Refresh the field
					RenderActions.refreshField(record.recordId);
				}
			});
		}
	}

	/**
	 * Update Metadata Gateway with this field's data.
	 * @param {Object} fieldObject Field's record ID to push from the store to the database
	 */
	pushToDatabase(fieldObject) {
		// We can just wrap the Promise object without returning it
		this.pushToDatabasePromise(fieldObject);
	}

	/**
	 * Update Metadata Gateway with this field's data.
	 * @param {Object} fieldObject Field's record ID to push from the store to the database
	 * @return {Promise}
	 */
	pushToDatabasePromise(fieldObject) {
		return new Promise((resolve, reject) => {
			try {
				this._checkInit();
			} catch (error) {
				return reject(error);
			}

			// make *sure* we have a standard javascript object...
			if (fieldObject.toJS) {
				fieldObject = fieldObject.toJS();
			}

			if (fieldObject.settings) {
				// Break the settings out into separate keys for the DB

				let settingsObj = {};
				try {
					settingsObj = typeof fieldObject.settings === 'string' ? JSON.parse(fieldObject.settings) : fieldObject.settings;
				} catch (err) {
					console.error('FieldActions.pushToDatabasePromise received settings key which could not be parsed. Value was', fieldObject.settings);
				}
				Object.keys(settingsObj).forEach(key => {
					// In this case we actually do want to overwrite the key if both the settings and the new object have it
					// This is because we have junk data in a few places, such as childConfigurations
					fieldObject[key] = settingsObj[key];
				});
				delete fieldObject.settings;
			}

			// Don't include settingsHistory; we no longer use it
			delete fieldObject.settingsHistory;

			// Don't include the search suffix, we want to regen every load.
			delete fieldObject.searchSuffix;

			// We need the FieldType Store
			let FieldTypeStore = undefined,
				fieldTypeObj = undefined;
			try {
				FieldTypeStore = window.FieldTypeStore;
				fieldTypeObj = FieldTypeStore.get(fieldObject.fieldType);
			} catch (error) {
				let message = 'Aborting pushToDatabase: Unable to attach to the FieldTypeStore from the window, or FieldType not found.';
				console.error(message);
				this._onError(error);
				return reject(message);
			}

			if (fieldTypeObj) {
				fieldObject.fieldTypeJSON = JSON.stringify(fieldTypeObj);

				// Local Meta Data Builder
				this._metadataBuilder
					// Get all the fields
					.updateFields([fieldObject])
					.then(function () {
						// Pass the field to the store (call reduce with the object below)
						this._dispatcher.dispatch(Immutable.fromJS({
							type: FieldConstants.FIELD_PUSH_TO_DATABASE,
							fieldObject: fieldObject
						}));
						return resolve(fieldObject);
					}.bind(this))
					.catch(function (error) {
						console.error(error);
						this._onError(error);
						return reject(error);
					}.bind(this));
			} else {
				return reject('Field Type Object not found for ' + fieldObject.fieldType);
			}
		});
	}

	/**
	 * Changes the Schemaname of a table
	 *
	 * @param {*} recordId
	 * @param {*} newTableSchemaName
	 * @returns
	 * @memberof TableActionsDev
	 */
	 changeSchemaName(recordId, fieldSchemaName, newFieldSchemaName, fieldTypeObj) {
		return new Promise((resolve, reject) => {

			let fieldObj = {'recordId': recordId, 'oldSchemaName': fieldSchemaName, 'newSchemaName': newFieldSchemaName};
			if(fieldTypeObj) {
				fieldObj.fieldTypeJSON = JSON.stringify(fieldTypeObj);
			}
			this._metadataBuilder
				.updateSchemaName([fieldObj], 'field')
				.then(function(){
					// Pass the tables to the store (call reduce with the object below)
					let fieldObject = {recordId: recordId, fieldSchemaName: newFieldSchemaName};
					this._dispatcher.dispatch(Immutable.fromJS({
						type: FieldConstants.FIELD_PUSH_TO_DATABASE,
						fieldObject: fieldObject
					}));
					return resolve(fieldObject);
				}.bind(this))
				.catch(function(error){ 
					console.error(error);
					this._onError(error);
					return reject(error);
				}.bind(this));
		});
	}

	/**
	 * Changes the Schemaname of a table
	 *
	 * @param {*} recordId
	 * @param {*} newTableSchemaName
	 * @returns
	 * @memberof TableActionsDev
	 */
	 changeSchemaNames(records) {
		return new Promise((resolve, reject) => {
			records.forEach(record => {
				if(record.fieldTypeObj) {
					record.fieldTypeJSON = JSON.stringify(record.fieldTypeObj);
					delete record.fieldTypeObj;
				}
			});
			this._metadataBuilder
				.updateSchemaName(records, 'field')
				.then(function(){
					// Pass the tables to the store (call reduce with the object below)
					let fields = [];
					records.forEach((record) => {
						let fieldObject = {recordId: record.recordId, fieldSchemaName: record.newSchemaName};
						fields.push(fieldObject);
						this._dispatcher.dispatch(Immutable.fromJS({
							type: FieldConstants.FIELD_PUSH_TO_DATABASE,
							fieldObject: fieldObject
						}));
					})
					return resolve(fields);
				}.bind(this))
				.catch(function(error){ 
					console.error(error);
					this._onError(error);
					return reject(error);
				}.bind(this));
		});
	}

	/**
	 * Update one setting's value
	 * 
	 * @param {string} recordId  Field's Record ID to update
	 * @param {any} settingSchemaName Setting's Field Schema Name
	 * @param {any} settingValue Setting's new value
	 * 
	 * @memberOf FieldActions
	 */
	pushSettingToStore(recordId, settingSchemaName, settingValue) {
		this._checkInit();

		let startTime = +new Date();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: FieldConstants.FIELD_PUSH_SETTING_TO_STORE,
			recordId: recordId,
			lastChildCalculation: startTime,
			settingSchemaName: settingSchemaName,
			settingValue: settingValue
		}));


		// If we're changing the grid width or height, we need to recalculate the repeating grid
		// @TODO: This currently only uses gridItemWidth because the grid component double-dispatches
		// on position change and that can cause issues.
		// Changing only the gridItemHeight via the setting is very rare
		// so I have elected not to here.
		if(settingSchemaName === 'attachedFields' || settingSchemaName === 'gridItemWidth') {
			let RenderActions = require('./render-actions').default;
			RenderActions.refreshField(recordId);
		}
	}

	/**
	 * 
	 * @param {*} recordId 
	 * @param {*} triggerName 
	 * @param {*} automation 
	 */
	pushChildConfigurationToStore(parentRecordId, childRecordId, settingSchemaName, value) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: FieldConstants.FIELD_PUSH_CHILD_CONFIGURATION_TO_STORE,
			parentRecordId,
			childRecordId,
			settingSchemaName,
			value
		}));
	}

	/**
	 * Update store field properties
	 * @param {string} recordId Field's Record ID to update
	 * @param {Object} recordProperties Properties to update
	 */
	pushToStore(recordId, recordProperties) {
		this._checkInit();
		let oldObj;
		if (recordProperties.attachedFields) {
			let FieldStore = require('../stores/field-store').default;
			oldObj = FieldStore.get(recordId);
		}
		let startTime = +new Date();
		this._dispatcher.dispatch(Immutable.fromJS({
			type: FieldConstants.FIELD_PUSH_TO_STORE,
			lastChildCalculation: startTime,
			recordId: recordId,
			recordProperties: recordProperties
		}));


		// If the attachedFields are changing,
		// we should recalculate the appropriate
		// render store entries as well
		if (recordProperties.attachedFields) {
			if (oldObj && oldObj.attachedFields !== recordProperties.attachedFields) {
				let RenderActions = require('./render-actions').default;
				RenderActions.refreshField(recordId);
			}
		}
	}

	/**
	 * Deletes a field from the database AND the store
	 * 
	 * @param {string} recordId Field Record ID to delete from the database and the store
	 * @param {string} tableSchemaName Field Table Schema Name to delete from the database and the store
	 */
	deleteFromDatabase(recordId, tableSchemaName) {

		// We need the Field Store
		let FieldStore = undefined,
			fieldObj = undefined;
		try {
			FieldStore = window.FieldStore;
			fieldObj = FieldStore.get(recordId);
		} catch (error) {
			console.error('Unable to attach to the FieldStore from the window in FieldActions.deleteFromDatabase.');
		}

		// We need the FT Store
		let FieldTypeStore = undefined,
			fieldTypeObj = undefined;
		try {
			FieldTypeStore = window.FieldTypeStore;
			fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType);
		} catch (error) {
			console.error('Unable to attach to the FieldTypeStore from the window in FieldActions.deleteFromDatabase.');
		}

		// Lookup the field's type, and so it's field type JSON from the stores.
		let fieldTypeJSON = '';
		if (fieldTypeObj && fieldObj) {
			fieldTypeJSON = JSON.stringify(fieldTypeObj);

			// Local Meta Data Builder
			this._metadataBuilder
				// Get the field(s)
				.deleteFields([{
					recordId: recordId,
					tableSchemaName: tableSchemaName,
					fieldSchemaName: fieldObj.fieldSchemaName,
					fieldTypeJSON: fieldTypeJSON
				}
				])
				.then(function () {
					// Pass the fields to the store (call reduce with the object below)
					this._dispatcher.dispatch(Immutable.fromJS({
						type: FieldConstants.FIELD_DELETE_FROM_STORE,
						recordId: recordId
					}));
				}.bind(this))
				.catch(function (error) {
					console.error(error);
					this._onError(error);
				}.bind(this));
		}
	}

	/**
	 * Deletes a field from the database AND the store in a promisified fashion
	 * 
	 * @param {string} recordId Field Record ID to delete from the database and the store
	 * @param {string} tableSchemaName Field Table Schema Name to delete from the database and the store
	 */
	deleteFromDatabasePromise(recordId, tableSchemaName) {

		return new Promise((resolve, reject) => {
			try {
				// We need the Field Store
				let FieldStore = undefined,
					fieldObj = undefined;
				try {
					FieldStore = window.FieldStore;
					fieldObj = FieldStore.get(recordId);
				} catch (error) {
					console.error('Unable to attach to the FieldStore from the window in FieldActions.deleteFromDatabase.');
				}

				// We need the FT Store
				let FieldTypeStore = undefined,
					fieldTypeObj = undefined;
				try {
					FieldTypeStore = window.FieldTypeStore;
					fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType);
				} catch (error) {
					console.error('Unable to attach to the FieldTypeStore from the window in FieldActions.deleteFromDatabase.');
				}

				// Lookup the field's type, and so it's field type JSON from the stores.
				let fieldTypeJSON = '';
				if (fieldTypeObj && fieldObj) {
					fieldTypeJSON = JSON.stringify(fieldTypeObj);

					// Local Meta Data Builder
					this._metadataBuilder
						// Get the field(s)
						.deleteFields([{
							recordId: recordId,
							tableSchemaName: tableSchemaName,
							fieldSchemaName: fieldObj.fieldSchemaName,
							fieldTypeJSON: fieldTypeJSON
						}
						])
						.then(function () {
							// Pass the fields to the store (call reduce with the object below)
							this._dispatcher.dispatch(Immutable.fromJS({
								type: FieldConstants.FIELD_DELETE_FROM_STORE,
								recordId: recordId
							}));
							return resolve();
						}.bind(this))
						.catch(function (error) {
							console.error(error);
							this._onError(error);
							return reject(error);
						}.bind(this));
				}
			} catch (err) {
				return reject(err);
			}

		});

	}

	/**
	 * Deletes a field from the store
	 * 
	 * @param {string} recordId Field Record ID to delete from the store.
	 */
	deleteFromStore(recordId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: FieldConstants.FIELD_DELETE_FROM_STORE,
			recordId: recordId
		}));
	}

	/**
	 * Update store with a Fields's data.
	 * @param {string} recordId Field's Record ID to pull from the database to the database
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, overwriteStore) {
		this.pullFromDatabasePromise(recordId, overwriteStore);
	}

	/**
	 * Update store with a Fields's data.
	 * @param {string} recordId Field's Record ID to pull from the database to the database
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabasePromise(recordId, overwriteStore) {
		return new Promise((resolve, reject) => {
			this._checkInit();
	
			if (overwriteStore === undefined) {
				overwriteStore = true;
			}
	
			// Local Meta Data Builder
			this._metadataBuilder
				// Get the field(s)
				.searchRecords('field', { 'recordId': recordId })
				.then(function (fieldArray) {
					if (!Array.isArray(fieldArray) || fieldArray.length === 0) {
						fieldArray = [{
							recordId: recordId
						}];
					}
	
					// Pass the fields to the store (call reduce with the object below)
					this._dispatcher.dispatch(Immutable.fromJS({
						type: FieldConstants.FIELD_PULL_FROM_DATABASE,
						fieldArray: fieldArray,
						overwriteStore: overwriteStore
					}));
					return resolve(true);
				}.bind(this))
				.catch(function (error) {
					console.error(error);
					this._onError(error);
					return reject(error);
				}.bind(this));
		})
	}

	/**
	 * Update store with all of the records' data.
	 * 
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 * @param {string} communityUrl Community base path for the field type settings fields to add to the MD results.
	 */
	pullFromDatabaseAll(overwriteStore, communityUrl) {
		this._checkInit();

		if (overwriteStore === undefined) {
			overwriteStore = true;
		}

		let self = this;

		// Local Meta Data Builder
		self._metadataBuilder.browseRecords('field')
			.then(function (fieldArray) {
				if (communityUrl) {
					return fetchMetadata(communityUrl + '/fieldtype-settings-configuration.json', {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json; charset=UTF-8'
						}
					})
						.then(fieldtypeSettingsObject => {
							Object.keys(fieldtypeSettingsObject).forEach(fieldtypeSettingId => {
								let config = {};
								try {
									config = JSON.parse(fieldtypeSettingsObject[fieldtypeSettingId]);
								} catch (e) {
									console.warn('unable to parse field type setting config:', fieldtypeSettingsObject[fieldtypeSettingId]);
								}
								fieldArray.push({
									tableSchemaName: 'fields',
									settingsHistory: '{}',
									recordId: fieldtypeSettingId,
									settings: config['settings'],
									fieldSchemaName: config['fieldSchemaName'],
									fieldType: config['fieldType']
								});
							});

							let action = Immutable.fromJS({
								type: FieldConstants.FIELD_PULL_FROM_DATABASE_ALL,
								fieldArray: fieldArray,
								overwriteStore: overwriteStore
							});

							// Pass the fields to the store WITH the field type settings.
							self._dispatcher.dispatch(action);
						})
						.catch(function (error) {
							console.warn('Error getting fields or field types:', error);

							// Pass the fields to the store without the field type settings, since we couldnt decode them
							self._dispatcher.dispatch(Immutable.fromJS({
								type: FieldConstants.FIELD_PULL_FROM_DATABASE_ALL,
								fieldArray: fieldArray,
								overwriteStore: overwriteStore
							}));
						})
						.catch(error => {
							console.error('Final error in getting fields:', error);
						});
				} else {
					// Pass the fields to the store without the field type settings, since no community url was defined
					self._dispatcher.dispatch(Immutable.fromJS({
						type: FieldConstants.FIELD_PULL_FROM_DATABASE_ALL,
						fieldArray: fieldArray,
						overwriteStore: overwriteStore
					}));
				}
			})
			.catch(function (error) {
				console.error(error);
				self._onError(error);
			});
	}
}

/**
 * Actions for the core store that contains field records for the test/prod environments where we talk to the CDN JSON
 *
 * @class FieldActionsProd
 */
class FieldActionsProd {
	/**
		 * Singleton instance of FieldActionsProd
		 * @param {Object} options
		 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if (options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Set the Installation Id
	 * 
	 * @param {string} installationId 
	 */
	setInstallationId(installationId) {
		this._installationId = installationId;
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Update one setting's value
	 * 
	 * @param {string} recordId  Field's Record ID to update
	 * @param {any} settingSchemaName Setting's Field Schema Name
	 * @param {any} settingValue Setting's new value
	 * 
	 * @memberOf FieldActions
	 */
	pushSettingToStore(recordId, settingSchemaName, settingValue) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: FieldConstants.FIELD_PUSH_SETTING_TO_STORE,
			recordId: recordId,
			settingSchemaName: settingSchemaName,
			settingValue: settingValue
		}));
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw (new Error('FieldActionsProd not properly initialized with a dispatcher'));
		}
		if (!this._installationId) {
			throw (new Error('FieldActionsProd not properly initialized with an installationId'));
		}
		if (!this._installationVersion) {
			throw (new Error('FieldActionsProd not properly initialized with an installationVersion'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: FieldConstants.FIELD_PULL_ERROR,
			error: error.message
		}));
	}

	/**
	 * Deletes a table from the database AND the store
	 * 
	 * @param {string} recordId Table Record ID to delete from the database and the store
	 */
	deleteFromDatabase() {
		console.warn('deleteFromDatabase not implemented.');
	}

	/**
	 * Deletes a table from the store
	 * 
	 * @param {string} recordId Table Record ID to delete from the store.
	 */
	deleteFromStore(recordId) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: FieldConstants.FIELD_DELETE_FROM_STORE,
			recordId: recordId
		}));
	}

	/**
	 * Update Metadata Gateway with this record's data.
	 * @param {string} recordId Record to push from the store to the database
	 */
	pushToDatabase() {
		console.warn('pushToDatabase not implemented.');
	}

	/**
	 * Update store record properties
	 * @param {string} recordId Record to update
	 * @param {Object} recordProperties Properties to update
	 */
	pushToStore(recordId, recordProperties) {
		this._checkInit();
		let startTime = +new Date();
		let recalcPromise = Promise.resolve();
		// If the attachedFields are changing,
		// we should recalculate the appropriate
		// render store entries as well
		if(recordProperties.attachedFields) {
			let GridHeightUtils = require('../utils/grid-height-utils').default;
			let AdminSettingsStore = require('../stores/admin-settings-store').default;
			let RenderStore = require('../stores/render-store').default;
			let FieldStore = require('../stores/field-store').default;
			let FieldModesStore = require('../stores/field-modes-store').default;
			let fieldObj = Object.assign({}, FieldStore.get(recordId), recordProperties);
			let attachedFields = JSON.parse(fieldObj.attachedFields);
			let fieldPosition = fieldObj.fieldPosition ? JSON.parse(fieldObj.fieldPosition) : {};
			let fieldPositionExtras = fieldObj.fieldPositionExtras ? JSON.parse(fieldObj.fieldPositionExtras) : {};
			let activeOverlays = AdminSettingsStore.getActiveOverlays();
			let toRecalc = [];
			let renderItems = RenderStore.getRenderObjectsForComponent('field', recordId);
			if(renderItems) {
				renderItems.forEach(renderItem => {
					let availableModes = FieldModesStore.getAvailableModes(renderItem.get('renderId'));
					toRecalc.push({
						renderId: renderItem.get('renderId'),
						renderParentId: renderItem.get('renderParentId'),
						componentId: renderItem.get('componentId'),
						componentType: renderItem.get('componentType'),
						dataRecordId: renderItem.get('dataRecordId'),
						dataTableSchemaName: renderItem.get('dataTableSchemaName'),
						fieldPosition: fieldPosition,
						fieldPositionExtras: fieldPositionExtras,
						attachedFields: attachedFields,
						gridLayoutHeights: renderItem.get('gridLayoutHeights'),
						modes: availableModes && availableModes.toJS ? availableModes.toJS() : availableModes

					});
				});
				recalcPromise = GridHeightUtils.recalcAttachedFields(toRecalc, activeOverlays, []);
			}
		}

		recalcPromise
			.then(grids => {
				this._dispatcher.dispatch(Immutable.fromJS({
					type: FieldConstants.FIELD_PUSH_TO_STORE,
					recordId: recordId,
					grids: grids,
					lastChildCalculation: startTime,
					recordProperties: recordProperties
				}));
			})
			.catch(console.error);
	}

	/**
	 * Update store with a record's data.
	 * @param {string} recordId Record to pull from the database to the database
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, overwriteStore) {
		this.pullFromDatabase(recordId, overwriteStore);
	}

	/**
	 * Update store with a record's data and return when its done
	 * @param {string} recordId Record to pull from the database to the database
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabasePromise(recordId, overwriteStore) {
		return new Promise((resolve, reject) => {
			this._checkInit();
	
			if (overwriteStore === undefined) {
				overwriteStore = true;
			}
	
			let installationId = this._installationId,
				installationVersion = this._installationVersion,
				metadataPath = 'https://cdn3.citizendeveloper.com/installations/' +
					installationId + '/' + installationVersion + '/field/' + recordId + '.json';
			fetchMetadata(metadataPath)
				.then((jsonResponse) => {
					// Pass the tables to the store (call reduce with the object below)
					this._dispatcher.dispatch(Immutable.fromJS({
						type: FieldConstants.FIELD_PULL_FROM_DATABASE,
						fieldArray: [jsonResponse],
						overwriteStore: overwriteStore
					}));
					return resolve(true);
				})
				.catch(function (error) {
					console.error('Unable to retrieve metadata from ', metadataPath, '. Error was: ', error);
					return reject(error);
				});
		});
	}

	/**
	 * Update store with all of the records' data.
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabaseAll(overwriteStore, communityUrl) {
		this._checkInit();

		if (overwriteStore === undefined) {
			overwriteStore = true;
		}

		let self = this;

		let installationId = this._installationId,
			installationVersion = this._installationVersion,
			metadataPath = 'https://cdn3.citizendeveloper.com/installations/' +
				installationId + '/' + installationVersion + '/field/index.json';
		fetchMetadata(metadataPath)
			.then((fieldObj) => {
				if (communityUrl) {
					fetchMetadata(communityUrl + '/fieldtype-settings-configuration.json', {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json; charset=UTF-8'
						}
					})
						.then(fieldtypeSettingsObject => {
							Object.keys(fieldtypeSettingsObject).forEach(fieldtypeSettingId => {
								let config = {};
								try {
									config = JSON.parse(fieldtypeSettingsObject[fieldtypeSettingId]);
								} catch (e) {
									console.warn('unable to parse field type setting config:', fieldtypeSettingsObject[fieldtypeSettingId]);
								}
								fieldObj[fieldtypeSettingId] = {
									tableSchemaName: 'fields',
									settingsHistory: '{}',
									recordId: fieldtypeSettingId,
									settings: config['settings'],
									fieldSchemaName: config['fieldSchemaName'],
									fieldType: config['fieldType']
								};
							});

							// Pass the fields to the store WITH the field type settings.
							self._dispatcher.dispatch(Immutable.fromJS({
								type: FieldConstants.FIELD_PULL_FROM_DATABASE_ALL,
								fieldArray: fieldObj,
								overwriteStore: overwriteStore
							}));
						})
						.catch(function (error) {
							console.warn('Error retrieving fields or field types:', error);

							// Pass the fields to the store without the field type settings, since we couldnt decode them
							self._dispatcher.dispatch(Immutable.fromJS({
								type: FieldConstants.FIELD_PULL_FROM_DATABASE_ALL,
								fieldArray: fieldObj,
								overwriteStore: overwriteStore
							}));
						});
				} else {
					// Pass the fields to the store without the field type settings, since no community url was defined
					self._dispatcher.dispatch(Immutable.fromJS({
						type: FieldConstants.FIELD_PULL_FROM_DATABASE_ALL,
						fieldArray: fieldObj,
						overwriteStore: overwriteStore
					}));
				}
			})
			.catch(function (error) {
				console.error('Unable to retrieve metadata from ', metadataPath, '. Error was: ', error);
			});
	}
}
// module.exports = { FieldActionsProd: FieldActionsProd, FieldActionsDev: FieldActionsDev };

let toExport;
if (process.env.CITDEV_ENV === 'development') {
	try {
		if (window.location.href.includes('settings.html') && typeof window.opener.location.href === 'string') {
			toExport = window.opener.FieldActions;
		} else {
			const instance = new FieldActionsDev({ dispatcher: AppDispatcher });
			toExport = instance;
		}
	} catch (error) {
		const instance = new FieldActionsDev({ dispatcher: AppDispatcher });
		toExport = instance;
	}
} else {
	toExport = new FieldActionsProd({ dispatcher: AppDispatcher });
}

export default toExport;