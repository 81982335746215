import React, { Component } from 'react';
import { Container } from 'flux/utils';

//Actions
import { AdminSettingsActions, FieldActions, InterfaceActions, PageActions } from '../../actions';

//Stores
import { AdminSettingsStore, FieldSettingsStore, FieldStore, FieldTypeStore, PageStore } from '../../stores';

// Utils
import ObjectUtils from '../../utils/object-utils';

/**
 * Renderer for the appearance assistant
 */
class VisibilitySettingsChooserV1 extends Component {
	/**
	 * Creates an instance of AppearanceAssistant.
	 * @param {any} props 
	 */
	constructor(props) {
		super(props);
		this.onSettingClick = this.onSettingClick.bind(this);
		this.onSaveHandler = this.onSaveHandler.bind(this);
		this.onResetHandler = this.onResetHandler.bind(this);
	}
	/**
	 * @static
	 * @returns - the stores that are being watch by NLPBuilder component
	 * 
	 * @memberOf NLPBuilder
	 */
	static getStores() {
		return [AdminSettingsStore, FieldSettingsStore, FieldStore, FieldTypeStore, PageStore];
	}
	/**
	 * Saves the Visibility Rules to the Database.
	 * @memberof VisibilitySettingsChooser
	 */
	onSaveHandler(event){
		event.preventDefault();
		let {recordId, parentRecordId, parentTableSchemaName, tableSchemaName} = this.state;
		//Check Form Validity 
		let formIsValid = document.getElementById('visibility-form').checkValidity();
		if(formIsValid) {
			if(tableSchemaName === 'field') {
				let fieldObject = FieldStore.get(recordId);
				FieldActions.pushToDatabase(fieldObject);
				InterfaceActions.notification({ 'level': 'success', 'message': 'Updating Visibility Rules...' });
			} else if (tableSchemaName === 'page') {
				let pageObject = PageStore.get(recordId);
				PageActions.pushToDatabase(pageObject);
				InterfaceActions.notification({'level': 'success', 'message': 'Updating Visibility Rules...'})
			}
	
			if (parentTableSchemaName === 'field') {
				let parentFieldObject = FieldStore.get(parentRecordId);
				FieldActions.pushToDatabase(parentFieldObject);
			} else if (parentTableSchemaName === 'page') {
				let parentPageObject = PageStore.get(parentRecordId);
				PageActions.pushToDatabase(parentPageObject);
			}
		} else {
			InterfaceActions.notification({'level': 'fail', 'message': 'Invalid Sequence'})
		}
	}
	/**
	 * 
	 * @param {any} event 
	 * @memberof AppearanceSettingsChooser
	 */
	onResetHandler(event) {
		event.preventDefault();
		let {recordId, parentRecordId, parentTableSchemaName,tableSchemaName} = this.state;
		if (tableSchemaName === 'field') {
			InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting Visibility Rules...' });
			FieldActions.pullFromDatabase(recordId);
		} else if (tableSchemaName === 'page') {
			InterfaceActions.notification({ 'level': 'warning', 'message': 'Resetting Visibility Rules...' });
			PageActions.pullFromDatabase(recordId);
		}
		
		if (parentTableSchemaName === 'field') {
			FieldActions.pullFromDatabase(parentRecordId);
		} else if (parentTableSchemaName === 'page') {
			PageActions.pullFromDatabase(parentRecordId);
		}
	}
	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingSchemaName
	 * @param {any} event 
	 * @memberof SettingsPanel
	 */
	onSettingClick(settingSchemaName, event) {
		event.preventDefault();
		// Toggle to select and deselect the Setting
		if(AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
			AdminSettingsActions.onSettingChange('');
		} else {
			AdminSettingsActions.onSettingChange(settingSchemaName);
		}
		
		// I have no idea why this was in here; commented out in case there's a reason.
		// AssistantSearchUtils.runAssistantSearch();
		
		// let selectedTab = AdminSettingsStore.getSettingsTabMain();
		// if (selectedTab === 'assistant') {
		// 	AssistantSearchUtils.selectSearch();
		// }
	}
	/**
	 * 
	 * @static
	 * @param {any} prevState 
	 * @returns 
	 * @memberof VisibilitySettingsChooser
	 */
	static calculateState(prevState) {
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let tableSchemaName = AdminSettingsStore.getTableSchemaName();
		let parentTableSchemaName = AdminSettingsStore.getParentTableSchemaName();
		let parentRecordId = AdminSettingsStore.getParentRecordId();
		let recordId = AdminSettingsStore.getRecordId();
		let subSettingSchemaName = AdminSettingsStore.getSubSettingSchemaName();
		let subSettingIndex = AdminSettingsStore.getSubSettingIndex();

		// calculate the All Users value.
		let allUsersContent = null;
		let allUsersSource = null;
		let fieldSettings = FieldSettingsStore.getSettingsWithSource(recordId, parentRecordId);
		if(fieldSettings[subSettingSchemaName]) {
			let subSettingConfigs = ObjectUtils.getObjFromJSON(fieldSettings[subSettingSchemaName].value);
			if(subSettingConfigs && subSettingConfigs[subSettingIndex]) {
				let subSettingConfig = subSettingConfigs[subSettingIndex];

				// Now figure out our setting value (rules count)
				if(subSettingConfig.visibilityRules && subSettingConfig.visibilityRules.length) {
					allUsersSource = <div className="setting-scope badge badge-pill badge-dark align-self-center">G</div>;
					let visibilityRulesObj = ObjectUtils.getObjFromJSON(subSettingConfig.visibilityRules);
					let allUsersVis = visibilityRulesObj['allUsers'];
					allUsersContent = allUsersVis.length + ' Rules';
		
					// take care of the plural.
					if(allUsersVis.length === 1) {
						allUsersContent = '1 Rule';
					}
				}	
			}
		// Not a subsetting?  Thats cool.. figure out the setting value (rules count)
		} else if(fieldSettings.visibilityRules && 
			(fieldSettings.visibilityRules.value.length || typeof fieldSettings.visibilityRules.value === 'object')) {
			if(fieldSettings.visibilityRules.source === 'global') {
				allUsersSource = <div className="setting-scope badge badge-pill badge-dark align-self-center">G</div>;
			} else {
				allUsersSource = <div className="setting-scope badge badge-pill badge-secondary align-self-center">L</div>;
			}
			let visibilityRulesObj = ObjectUtils.getObjFromJSON(fieldSettings.visibilityRules.value);
			let allUsersVis = visibilityRulesObj['allUsers'];
			allUsersContent = allUsersVis.length + ' Rules';

			// take care of the plural.
			if(allUsersVis.length === 1) {
				allUsersContent = '1 Rule';
			}
		}

		return {
			'allUsersContent': allUsersContent,
			'allUsersSource': allUsersSource,
			'parentRecordId': parentRecordId,
			'parentTableSchemaName': parentTableSchemaName,
			'recordId': recordId,
			'selectedSetting': selectedSetting,
			'tableSchemaName': tableSchemaName,
		}
	}
	/**
	 * @returns 
	 * @memberof VisibilitySettingsChooser
	 */
	render() {
		// let iconClassNames = 'fa custom fa-check-circle-o';
		let {selectedSetting, allUsersContent, allUsersSource} = this.state;
		let labelClassName = '';
		if(selectedSetting) {
			labelClassName = ' selected'
		}

		return [
			<div key="list" className='settings-list-wrapper'>
				<ul className="nav flex-column">
					{/*settingsList*/}
					<li className="nav-item">
						<div className="nav-link" onClick={this.onSettingClick.bind(this, "allUsers")}>
							{/* Temporarily removed the following div */}
							{/* <div className={"setting-icon " + iconClassNames} /> */}
							{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
							{/* Add this back in when the setting-icon is used again */}
							<div className="d-flex setting-text-container">
								<div className="w-50 setting-label-container">
									<div className={"all-users" + labelClassName}>
										All Users
									</div>
								</div>
								<div className="w-50 d-flex justify-content-between setting-value-container">
									<div className="text-right setting-value">
										{ allUsersContent }
									</div>
									{allUsersSource}
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>];
	};
}

const container = Container.create(VisibilitySettingsChooserV1);
export default container;