import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { ContextStore, TableStore } from '../../../../stores';
import { MapUtils } from '../../../../utils';
import TableIcon from './table-icon.react';

/**
 * Comppnet to format relationship direction
 */
export default class RelationshipDirectionFormatter extends Component {
  /**
   * Creates instance for RelationshipDirectionFormatter
   */
  constructor(props) {
    super(props);
    this._buildCardinality = this._buildCardinality.bind(this);
  }

  /**
   * Renders RelationshipDirectionFormatter
   */
  render() {
    let {value: {relation, direction}} = this.props,
        lTable = TableStore.getByTableSchemaName(relation.lTableSchemaName),
        rTable = TableStore.getByTableSchemaName(relation.rTableSchemaName),
        left, cardinality, right;

    if ('ltor' === direction) {
      left = <TableIcon label={`One ${lTable.singularName}`} iconClass={lTable.icon} className="d-flex flex-sm-column align-items-center" />;
      cardinality = this._buildCardinality(relation.lCardinality, relation.rCardinality);
      right = <TableIcon label={MapUtils.getTableRelationLabel(rTable, relation.lCardinality, 'r')} iconClass={rTable.icon} className="d-flex flex-sm-column align-items-center" />;
    } else {
      left = <TableIcon label={`One ${rTable.singularName}`} iconClass={rTable.icon} className="d-flex flex-sm-column align-items-center" />;
      cardinality = this._buildCardinality(relation.rCardinality, relation.lCardinality);
      right = <TableIcon label={MapUtils.getTableRelationLabel(lTable, relation.rCardinality, 'r')} iconClass={lTable.icon} className="d-flex flex-sm-column align-items-center" />;
    }

    return (
      <div className="relationship d-flex flex-sm-row justify-content-between">
        {left}
        {cardinality}
        {right}
      </div>
    );
  }

  /**
   * privbate Builds table icon with cardinality label
   */
  _buildCardinality(firstCardinality, secondCardinality) {
    let label = firstCardinality === "m" ? 'has many' : 'has only one';
    
    let renames = {
			'1': 'one',
			'm': 'many'
		}

    let imgSrc = ContextStore.getUrlMedia() + '/relation-cardinality/' + renames[secondCardinality] + '-to-' + renames[firstCardinality] + '.svg';

    return (
      <div className="d-flex flex-sm-column align-items-center">
        <label>{label}</label>
        {firstCardinality ? <img src={imgSrc} style={{ height: '32px' }} alt="relationship cardinality"/> : null}
      </div>
    );
  }
}

if ('development' === process.env.NODE_ENV) {
  RelationshipDirectionFormatter.propTypes = {
    value: PropTypes.object.isRequired
  }
}
