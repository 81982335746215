import AppDispatcher from '../dispatcher/app-dispatcher';
import Immutable from 'immutable';
import PageModeConstants from '../constants/page-mode-constants';
// import {UIUtils} from '../utils';
import GridHeightUtils from '../utils/grid-height-utils';


const PageModeActions = {
	/**
	 * Sets the current mode for the page
	 * 
	 * @param {string} pageId id
	 * @param {string} mode
	 */
	setMode(renderId, mode) {
		let startTime = +new Date();
		let RenderStore = require('../stores/render-store').default;
		let PageStore = require('../stores/page-store').default;
		let FieldStore = require('../stores/field-store').default;
		let AdminSettingsStore = require('../stores/admin-settings-store').default;
		let activeOverlays = AdminSettingsStore.getActiveOverlays();

		let renderObj = RenderStore.get(renderId);
		// let toRecalc = [];
		let gridPromise = Promise.resolve([]);
		if(renderObj) {
			// This should always be page, but just in case
			let componentObj = renderObj.componentType === 'page' ?
				PageStore.get(renderObj.componentId) :
				FieldStore.get(renderObj.componentId);
			// toRecalc = [{
			// 	renderParentId: renderObj.renderParentId,
			// 	renderId,
			// 	componentId: renderObj.componentId,
			// 	componentType: renderObj.componentType,
			// 	dataRecordId: renderObj.dataRecordId,
			// 	dataTableSchemaName: renderObj.dataTableSchemaName,
			// 	attachedFields: componentObj && componentObj.attachedFields ? JSON.parse(componentObj.attachedFields) : [],
			// 	fieldPosition: componentObj && componentObj.fieldPosition ? JSON.parse(componentObj.fieldPosition) : {},
			// 	fieldPositionExtras: componentObj && componentObj.fieldPositionExtras ? JSON.parse(componentObj.fieldPositionExtras) : {},
			// 	gridLayoutHeights: renderObj.gridLayoutHeights,
			// 	availableModes: componentObj.availableModes ? componentObj.availableModes.split(',') : ['view', 'edit']
			// }];

			gridPromise = GridHeightUtils.initiatePage(componentObj, renderObj.dataRecordId, renderObj.dataTableSchemaName, renderId, renderObj.renderParentId, activeOverlays, mode);
		}

		// GridHeightUtils.recalcAttachedFields(toRecalc, activeOverlays, [], false, mode === 'edit', true)
		gridPromise
			.then(grids => {
				AppDispatcher.dispatch(Immutable.fromJS({
					type: PageModeConstants.SET_PAGE_MODE,
					renderId: renderId,
					mode: mode,
					lastChildCalculation: startTime,
					grids: grids
				}));
			})
			.catch(error => {
				console.error('Error recalculating attached fields when changing page mode.', error);
			});
	}
};

export default PageModeActions;