var keyMirror = require('keymirror');

var FieldModeConstants = keyMirror({
	'INIT_MODE': null,
	'INIT_SINGLE_MODE': null,
	'SET_MODE': null,
	'SET_MODE_VARIANT': null,
	'SET_AVAILABLE_MODES': null
});

module.exports = FieldModeConstants;
