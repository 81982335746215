import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import ProcessingConstants from '../constants/processing-constants';
/**
 * Store to contain context, including:
 * pageId (String) - current page (in URL)
 * tableSchemaName (String) - current table being referenced (in URL),
 * recordId (String) - current record
 * sessionId (String) - unique user session
 * responsiveMode (String) - 'lg', 'md', or 'sm', used for rendering responsive interface
 * installationId (String) - unique id supplied in body's data attributes
 * installationVersion (String) - version id supplied in body's data attributes
 *
 * @class ContextStore
 * @extends {ReduceStore}
 */
class ProcessingStore extends ReduceStore {
	
	/**
	 * getInitialState - initial state for ContextStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.fromJS({'actionIds': {}});
	}

	/**
	 * getRunningActions - returns path to media files
	 *
	 * @return {Array} Array of running actions
	 */
	getRunningActions() {
		return this.getState().get('actionIds');
	}

	/**
	 * Get the upload percentage of any files that are uploading.
	 */
	getUploadPercentage() {
		return this.getState().get('percentage');
	}

	getReloadUI() {
		let state = this.getState();
		return state.get('showReloadUI') ? {
			reloadUI: state.get('showReloadUI'),
			reloadTime: state.get('reloadTime')
		} : false;
	}

	getMaintenanceUI() {
		let state = this.getState();
		return state.get('showMaintenanceUI') ? {
			maintenanceUI: state.get('showMaintenanceUI'),
			message: state.get('message')
		} : false;
	}

	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 */
	reduce(state, action) {
		let newState;
		switch (action.get('type')) {
			case ProcessingConstants.ADD_RUNNING_ACTION_ID: {
				let actionId = action.get('actionId');
				newState = state.withMutations(function(state){
					if(!state.has('actionIds')) {
						state.set('actionIds', Immutable.Map());
					}
					state.setIn(['actionIds', actionId], false);
				});
				break;
			}

			case ProcessingConstants.UPDATE_ACTION_DISPLAY_NOTIFICATION: {
				let actionId = action.get('actionId');
				let shouldDisplayNotification = action.get('shouldDisplayNotification');
				newState = state.withMutations(function(state){
					if(!state.has('actionIds')) {
						state.set('actionIds', Immutable.Map());
					}
					state.setIn(['actionIds', actionId], shouldDisplayNotification);
				});
				break;
			}

			case ProcessingConstants.REMOVE_RUNNING_ACTION_ID: {
				let actionId = action.get('actionId');
				let recalculateDisplayNotification = action.get('recalculateDisplayNotification');
				newState = state.withMutations(function(state){
					if(!state.has('actionIds')) {
						state.set('actionIds', Immutable.Map());
					}
					state.deleteIn(['actionIds', actionId]);
					if(recalculateDisplayNotification) {
						state.set('displayNotification', !!state.get('actionIds').size);
					}
				});
				break;
			}

			case ProcessingConstants.SET_RUNNING_PERCENTAGE: {
				let runningPercentage = action.get('percentage');
				newState = state.set('percentage', runningPercentage);
				break;
			}

			case ProcessingConstants.CLEAR_RUNNING_PERCENTAGE: {
				newState = state.delete('percentage');
				break;
			}

			case ProcessingConstants.SHOW_RELOAD_UI: {
				let force = action.get('force');
				newState = state.set('showReloadUI', true);
				if (force) {
					newState = newState.set('reloadTime', 60);
				} else {
					newState = newState.delete('reloadTime');
				}
				break;
			}

			case ProcessingConstants.SHOW_MAINTENANCE_UI: {
				newState = state.set('showMaintenanceUI', true);
				newState = newState.set('message', action.get('message'));
				break;
			}
			
			default:
				newState = state;
				break;
		}
		return newState;
	}
}

const instance = new ProcessingStore(AppDispatcher);
export default instance;
