import AppDispatcher from '../dispatcher/app-dispatcher'
import {ReduceStore} from 'flux/utils'
import Immutable from 'immutable'
import TemplateConstants from '../constants/template-constants'
// import UIUtils from '../utils/ui-utils';

/**
* Stores template structure of header, left, right, and footer for pages.
*/
class TemplateStore extends ReduceStore {
	/**
	 * getInitialState - initial state
	 *
	 * @return {Immutable.Map}
	 */
	getInitialState() {
		return Immutable.Map();
	};
	/**
	 * getTemplate - retrieves
	 *
	 * @param  {number} templateId description
	 * @return {type}            	description
	 */
	getTemplate(templateId) {
		let returnData;
		// determine if record data exists, otherwise, send empty object
		if(this.getState().size > 0){
			returnData = this.getState().get(templateId);
		}
		if(returnData === undefined){
			returnData = null;
		}
		return returnData;
	}
	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 */
	reduce(state, action) {
		let returnData;
		let templateId = action.get('templateId');
		let data = action.get('data');

		switch (action.get('type')) {
			case TemplateConstants.TEMPLATE_LOADED:

				// Page has not been downloaded yet
				if(state.get(templateId) === undefined){
					state = state.set(templateId, data);
				}
				returnData = state;
			break;
			default:
				returnData = state;
		}
		return returnData;
		}
}

const instance = new TemplateStore(AppDispatcher);
export default instance;