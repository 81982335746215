import React, { Component } from 'react';
import { Container } from 'flux/utils';

// Constants
import Help from '../../../constants/help-upgrade-constants';

import { AdminSettingsActions } from '../../../actions';

// Stores
import ContextStore from '../../../stores/context-store';
import MetadataStore from '../../../stores/metadata-store';

// Utils
import UIUtils from '../../../utils/ui-utils';

/**
 * Testing component used for changing user session
 */
class ThemeBuilder extends Component {
  /**
   * Creates an instance of ThemeBuilder
   *
   * @memberOf ThemeBuilder
   */
  constructor(props) {
    super(props);
    this._onClickHandler = this._onClickHandler.bind(this);
  }
  /**
   * static - description
   *
   * @return {type}  description
   */
  static getStores() {
    return [ContextStore, MetadataStore];
  }
  /**
   * static - description
   *
   * @param  {type} prevState description
   * @param  {type} props     description
   * @return {type}           description
   */
  static calculateState(prevState, props) {
    let recordId = ContextStore.getApplicationId();
    let tableSchemaName = 'applications';
    let description = '';
    let lastDT = '';

    // Pull the value from the MD Store
    let themeObj = MetadataStore.get(recordId, tableSchemaName);
    if (themeObj) {
      description = themeObj['description'];
      lastDT = themeObj['lastUpdatedDateTime'];
    }

    return {
      description: description,
      lastDT: lastDT,
    };
  }
  _onClickHandler() {
    UIUtils.openSettingsPanel('theme',
      ContextStore.getApplicationId(), 'applications',
      undefined, undefined
    );
    // Hide the settings, we're going to css!
    AdminSettingsActions.onSettingsListHideChange(true);
	  AdminSettingsActions.onSettingChange('css', '05b49177-6ef8-4dbc-8260-bb8c907a1831');
  }
  /**
   * Render the wrapper container
   *
   * @returns React
   *
   * @memberOf Component
   */
  render() {
    let { 
      description, 
      // lastDT,
    } = this.state;
    return (
      <div id="theme-builder" className="map mt-0">
        <div className="section-header" key="expand-collapse-tools">
          {/* <div className="d-flex align-items-center justify-content-start">
            <h5 className="bold">{ lastDT }</h5>
          </div> */}
          <div className="d-flex align-items-center justify-content-end">
            <h5 className="bold">Theme</h5>
            <div title="Theme Help" className="info-icon ml-2" onClick={() => { UIUtils.onHelpClick(Help.HELP_DASHBOARD_PRESENTATION_THEME); }}>
              <i className="fa fa-info-circle mr-1"></i>
            </div>
          </div>
        </div>
        <div id="theme-body" className="mx-3">
          <div
            onClick={this._onClickHandler}
            className="btn btn-primary d-flex justify-content-center w-100 align-items-center py-2"
            data-toggle="modal"
            data-target="#theme-builder-modal">
            <h4 className="pr-2">Edit Theme</h4>
            <img style={{ marginTop: '1px' }} src={ContextStore.getUrlMedia() + "/icon-query-and-expression.svg"} alt="" />
          </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: description || '' }} className="mx-3 pt-2" style={{ color: 'white', overflowX: 'auto' }}></div>
      </div>
    );
  };
}
const container = Container.create(ThemeBuilder);
export default container;
