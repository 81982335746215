import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import { LogicFunctionConstants } from '../constants/logic-function-constants';

/**
 * Core store that contains logic function records
 *
 * @class LogicFunctionStore
 * @extends {ReduceStore}
 */
class LogicFunctionStore extends ReduceStore {
	/**
	 * getInitialState - initial state for LogicFunctionsStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.Map({
			allPulledFromDatabase: false,
			records: Immutable.Map(),
			workspaces: Immutable.Map()
		});
	}

	/**
	 * Called every time an action is dispatched (for any store)
	 *
	 * @param {Object} state - current state of this store
	 * @param {Object} action - action that's coming in
	 * @returns {Object} new state after the action has been processed.
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			case LogicFunctionConstants.LOGIC_FUNCTION_DELETE_FROM_DATABASE: {
				// Deal with "cleaning" any dirty flags in the future.
				return state;
			}
			case LogicFunctionConstants.LOGIC_FUNCTION_DELETE_FROM_STORE: {
				// Delete the record in the state['records']['recordId'] spot
				return state.deleteIn(['records', action.get('recordId')]);
			}
			case LogicFunctionConstants.LOGIC_FUNCTION_PUSH_TO_DATABASE: {
				let logicFunctionObject = action.get('logicFunctionObject');
				let newState = state;
				// Clean up any dirty values which have now been saved
				if(logicFunctionObject) {
					let recordId = logicFunctionObject.get('recordId');
					newState = state.withMutations(state => {
						let oldRecord = state.getIn(['records', recordId]) || Immutable.Map();
						oldRecord = oldRecord.withMutations(oldRecord => {
							logicFunctionObject.forEach((newSettingValue, settingKey) => {
								let oldValue = oldRecord.get(settingKey) || Immutable.Map();
								let newValue = oldValue.withMutations(oldValue => {
									oldValue.set('value', newSettingValue);
									oldValue.set('isDirty', false);
									oldValue.set('originalValue', null);
								});
								oldRecord.set(settingKey, newValue);
							});
						});
						// I believe that we want to merge deep in here as well
						state.mergeDeepIn(['records', recordId], oldRecord);
					});
				}
				return newState;
			}
			case LogicFunctionConstants.LOGIC_FUNCTION_PUSH_TO_STORE: {
				let recordId = action.get('recordId');
				let recordProperties = action.get('recordProperties');
				let forceClean = action.get('forceClean');

				// Merge the recordProperties into the state['records']['recordId'] object... and return it.
				return recordId && recordProperties ? state.withMutations(state => {
					let oldRecord = state.getIn(['records', recordId]) || Immutable.Map();
					let newRecord = oldRecord.withMutations(newRecord => {
						recordProperties.forEach((newSettingValue, settingKey) => {
							if(oldRecord.has(settingKey)) {
								let oldValue = oldRecord.has(settingKey) ? oldRecord.get(settingKey) : Immutable.Map();
								let newValue = oldValue.withMutations(oldValue => {
									if(forceClean) {
										oldValue.set('value', newSettingValue);
										oldValue.set('isDirty', false);
										oldValue.set('originalValue', null);
									} else {
										// If we're not already dirty and the original value hasn't been set, then set it
										if(!oldValue.get('isDirty') && oldValue.get('originalValue') === null) {
											oldValue.set('originalValue', oldValue.get('value'));
										}
	
										if(oldValue.get('originalValue') === newSettingValue) {
											// If the new value is the original value, then revert it and clear the isDirty flag
											oldValue.set('value', newSettingValue);
											oldValue.set('isDirty', false);
											oldValue.set('originalValue', null);
										} else {
											// Otherwise, just set the new value and mark it as dirty
											oldValue.set('value', newSettingValue);
											oldValue.set('isDirty', true);
										}
									}
								});
								newRecord.set(settingKey, newValue);
							} else {
								newRecord.set(settingKey, Immutable.fromJS({
									value: newSettingValue,
									isDirty: forceClean ? false : true,
									inConflict: false,
									conflictValue: null,
									originalValue: forceClean ? null : ''
								}));
							}
						});
					});
					// Merge the recordProperties into the state['records']['recordId'] object... and return it.
					// We want mergeDeepIn and not set in order to preserve old values
					state.mergeDeepIn(['records', recordId], newRecord);
					return state;
				}) : state;
			}
			case LogicFunctionConstants.LOGIC_FUNCTION_RECEIVE_BROADCAST: {
				// This is similar to pulling from the database, but needs to consider conflict work
				let records = action.get('records');
				return state.withMutations(newState => {
					records.forEach((record) => {
						let recordId = record.get('recordId');
						let oldRecord = newState.getIn(['records', recordId]) || Immutable.Map();
						let newRecord = oldRecord.withMutations(newRecord => {
							record.get('properties').forEach((value, key) => {
								// Update the value
								
								let isDirty = oldRecord.hasIn([key, 'isDirty']) ? oldRecord.getIn([key, 'isDirty']) : false;
								if(!isDirty) {
									// If it's not dirty, then just update the value
									newRecord.setIn([key, 'value'], value);
									newRecord.setIn([key, 'isDirty'], false);
									newRecord.setIn([key, 'originalValue'], null);
								} else {
									let dirtyValue = oldRecord.getIn([key, 'value']);
									if(dirtyValue !== value) {
										// @TODO: How do we want to handle dirty values from an end user perspective?
										// Warn the user for now, I guess?
										let InterfaceActions = require('../actions/interface-actions').default;
										let quickLookup = {
											"params": 'Parameters',
											"varsUsed": 'Variables Used',
											"forceBackend": 'Force Run on Backend',
											"hasReturn": 'Has a Return Value',
											"name": 'Name',
											"logicFunctionsUsed": 'Uses Logic Functions',
											"description": 'Description',
											"blocklyxml": 'Blockly XML',
											"workspaceXML": 'Workspace XML'
										};
										let label = quickLookup[key] || key;
										InterfaceActions.stickyNotification({
											level: 'warning',
											title: 'Changed: ' + oldRecord.getIn(['name', 'value']) + ' Logic Function / ' + label + ' Setting',
											message: 'Your modified setting has been changed by another citizen developer. Save your work to overwrite their changes, or reload to accept their update.',
											id: 'logic-function-' + recordId + '-' + key + '-conflict'
										});
										// Update the original value, since that reflects the DB now
										newRecord.setIn([key, 'originalValue'], value);
									} else {
										// The value in the DB is now actually the same as the "dirty" value, so we can just clean the value in the store
										newRecord.setIn([key, 'isDirty'], false);
										newRecord.setIn([key, 'originalValue'], null);
									}
								}
							});
						});
						newState.setIn(['records', recordId], newRecord);
					})
				});
			}
			case LogicFunctionConstants.LOGIC_FUNCTION_PULL_FROM_DATABASE: {
				let overwriteStore = action.get('overwriteStore');
				return state.withMutations(state => {
					let recordMap = Immutable.Map().withMutations(recordMap => {
						action.get('logicFunctionArray').forEach(metaRecord => {
							let recordId = metaRecord.get('recordId');
							let newRecord = Immutable.Map().withMutations(newRecord => {
								metaRecord.forEach((settingVal, settingKey) => {
									// Treat as forceClean = true
									newRecord.set(settingKey, Immutable.fromJS({
										value: settingVal,
										isDirty: false,
										originalValue: null
									}));
								});
							});
							recordMap.set(recordId, newRecord);
						});
					});
					if(overwriteStore) {
						state.mergeIn(['records'], recordMap);
					} else {
						// Start with the new records, then merge in what we have in the state already to avoid overwriting
						state.set('records', recordMap.mergeDeep(state.get('records')));
					}
				});
			}
			case LogicFunctionConstants.LOGIC_FUNCTION_PULL_FROM_DATABASE_ALL: {
				let overwriteStore = action.get('overwriteStore');
				return state.withMutations(state => {
					let recordMap = Immutable.Map().withMutations(recordMap => {
						action.get('logicFunctionArray').forEach(metaRecord => {
							let recordId = metaRecord.get('recordId');
							let newRecord = Immutable.Map().withMutations(newRecord => {
								metaRecord.forEach((settingVal, settingKey) => {
									// Treat as forceClean = true
									newRecord.set(settingKey, Immutable.fromJS({
										value: settingVal,
										isDirty: false,
										originalValue: null
									}));
								});
							});
							recordMap.set(recordId, newRecord);
						});
					});
					if(overwriteStore) {
						state.set('records', recordMap);
					} else {
						// Start with the new records, then merge in what we have in the state already to avoid overwriting
						state.set('records', recordMap.mergeDeep(state.get('records')));
					}
					state.set('allPulledFromDatabase', true);
				});
			}
			case LogicFunctionConstants.LOGIC_FUNCTION_PULL_ERROR: {
				console.error('LogicFunction Store Error: ' + action.get('error'));
				return state;
			}
			case LogicFunctionConstants.LOGIC_FUNCTION_EXPAND_BLOCK: {
				// @TODO
				let workspaceId = action.get('workspaceId');
				let functionId = action.get('functionId');
				let blockId = action.get('blockId');
				let newState = state.withMutations((newState) => {
					if (!newState.hasIn(['workspaces', workspaceId])) {
						newState.setIn(['workspaces', workspaceId], Immutable.Map());
					}

					if (!newState.hasIn(['workspaces', workspaceId, functionId])) {
						newState.setIn(['workspaces', workspaceId, functionId], Immutable.Map({ expanded: null, blocks: Immutable.Map() }));
					}

					let expandedBlock = newState.getIn(['workspaces', workspaceId, functionId, 'expanded']);
					if (expandedBlock && expandedBlock !== blockId) {
						newState.setIn(['workspaces', workspaceId, functionId, 'blocks', blockId], 'collapsed');
					} else if (expandedBlock && expandedBlock === blockId) {
						// Do nothing, we don't care about this
					} else {
						// Expanded the block
						newState.setIn(['workspaces', workspaceId, functionId, 'expanded'], blockId);
						// Other stuff here for expanding the block
						newState.setIn(['workspaces', workspaceId, functionId, 'blocks', blockId], 'expanded');
					}
				});
				return newState;
			}
			case LogicFunctionConstants.LOGIC_FUNCTION_COLLAPSE_BLOCK: {
				let workspaceId = action.get('workspaceId');
				let functionId = action.get('functionId');
				let blockId = action.get('blockId');
				let functionProperties = action.get('functionProperties');
				let newState = state.withMutations((newState) => {
					if (!newState.hasIn(['workspaces', workspaceId])) {
						newState.setIn(['workspaces', workspaceId], Immutable.Map());
					}

					if (!newState.hasIn(['workspaces', workspaceId, functionId])) {
						newState.setIn(['workspaces', workspaceId, functionId], Immutable.Map({ expanded: null, blocks: Immutable.Map() }));
					}

					let expandedBlock = newState.getIn(['workspaces', workspaceId, functionId, 'expanded']);
					if (expandedBlock && expandedBlock === blockId) {
						newState.setIn(['workspaces', workspaceId, functionId, 'expanded'], null);
					}
					newState.setIn(['workspaces', workspaceId, functionId, 'blocks', blockId], 'collapsed');
					if (functionProperties) {
						newState.mergeDeepIn(['records', functionId], functionProperties);
					}
				});
				return newState;
			}

			case LogicFunctionConstants.LOGIC_FUNCTION_REPLACE_BLOCK: {
				let workspaceId = action.get('workspaceId');
				let oldFunctionId = action.get('oldFunctionId');
				let newFunctionId = action.get('newFunctionId');
				let blockId = action.get('blockId');

				let newState = state.withMutations((newState) => {
					if (!newState.hasIn(['workspaces', workspaceId])) {
						newState.setIn(['workspaces', workspaceId], Immutable.Map());
					}

					if (oldFunctionId && newState.hasIn(['workspaces', workspaceId]) && newState.hasIn(['workspaces', workspaceId, oldFunctionId])) {
						let expandedBlock = newState.getIn(['workspaces', workspaceId, oldFunctionId, 'expanded']);
						if (expandedBlock && expandedBlock === blockId) {
							newState.setIn(['workspaces', workspaceId, oldFunctionId, 'expanded'], null);
						}
						newState.deleteIn(['workspaces', workspaceId, oldFunctionId, 'blocks', blockId]);
					}

					if (!newState.hasIn(['workspaces', workspaceId, newFunctionId])) {
						newState.setIn(['workspaces', workspaceId, newFunctionId], Immutable.Map({ expanded: null, blocks: Immutable.Map() }));
					}

					newState.setIn(['workspaces', workspaceId, newFunctionId, 'blocks', blockId], 'collapsed');
				});
				return newState;
			}

			case LogicFunctionConstants.LOGIC_FUNCTION_DISPOSE_BLOCK: {
				let workspaceId = action.get('workspaceId');
				let functionId = action.get('functionId');
				let blockId = action.get('blockId');
				let newState = state.withMutations((newState) => {
					if (newState.hasIn(['workspaces', workspaceId]) && newState.hasIn(['workspaces', workspaceId, functionId])) {
						let expandedBlock = newState.getIn(['workspaces', workspaceId, functionId, 'expanded']);
						if (expandedBlock && expandedBlock === blockId) {
							newState.setIn(['workspaces', workspaceId, functionId, 'expanded'], null);
						}

						newState.deleteIn(['workspaces', workspaceId, functionId, 'blocks', blockId]);

					}
				});
				return newState;
			}

			default: {
				return state;
			}
		}
	}

	/**
	 * Gets the entire store as an object
	 *
	 * @returns {Object} current store as an object
	 */
	getAll() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			let records = this.getState().get('records');
			let recordsObj = {};
			records.forEach((record, recordId) => {
				let recordObj = {};
				record.forEach((settingVal, settingKey) => {
					let val = settingVal.get('value');
					recordObj[settingKey] = val;
				});
				recordsObj[recordId] = recordObj;
			});
			return recordsObj;
		} else {
			return undefined;
		}
	}

	/**
	 * Gets the entire store as an array
	 *
	 * @returns {Array} current store as an array
	 */
	getAllArray() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			let records = this.getState().get('records');
			let recordsArr = [];

			records.forEach((record) => {
				let recordObj = {};
				record.forEach((settingVal, settingKey) => {
					let val = settingVal.get('value');
					recordObj[settingKey] = val;
				});
				recordsArr.push(recordObj);
			});
			return recordsArr;
		} else {
			return undefined;
		}
	}

	/**
	 * Gets an individual record from the store
	 * @param {string} recordId UUID of the record to get
	 * @returns {Object} current store as an object for that record
	 */
	get(recordId, justDirty) {
		let state = this.getState();
		if (state.hasIn(['records', recordId])) {
			let rec = state.getIn(['records', recordId]);
			let toReturn = {
				recordId
			};
			rec.forEach((settingVal, settingKey) => {
				let val = settingVal.get('value');
				let isDirty = settingVal.get('isDirty');
				if(justDirty ? isDirty : true) {
					toReturn[settingKey] = val;
				}
			});
			return toReturn;
		} else {
			return undefined;
		}
	}

	/**
	 * Gets the full information for a logic function record
	 * @param {string} recordId UUID of the record to get
	 * @param {string} kind Kind of metadata to retrieve
	 */
	getFull(recordId) {
		let state = this.getState();
		return state.hasIn(['records', recordId]) ?
			state.getIn(['records', recordId]) :
			undefined;
	}

	/**
	 * Get an individual logic function from the store by name
	 * 
	 * @param {string} logicFunctionName
	 * @returns {Object}  current store as an object for that record
	 */
	getByLogicFunctionName(logicFunctionName, justDirty) {
		if (this.getState().get('records')) {
			let logicFunctionToReturn = this.getState().get('records').find(function (record) {
				let recordName = record.hasIn(['name', 'value']) ? record.getIn(['name', 'value']) : '';
				return recordName === logicFunctionName;
			});
			if(!logicFunctionToReturn) {
				return undefined;
			}
			let recordId = logicFunctionToReturn.get('recordId');
			let toReturn = {
				recordId
			};
			logicFunctionToReturn.forEach((settingVal, settingKey) => {
				let val = settingVal.get('value');
				let isDirty = settingVal.get('isDirty');
				if(justDirty ? isDirty : true) {
					toReturn[settingKey] = val;
				}
			});
			return toReturn;
		} else {
			return undefined;
		}
	}

	/**
	 * Get whether all have been pulled from the database or not yet.
	 * 
	 * @returns {boolean}
	 */
	allPulledFromDatabase() {
		return this.getState().get('allPulledFromDatabase');
	}

	/**
	 * Function to find which block, if any, has a logic function expanded
	 * 
	 * @param {string} workspaceId The ID of the workspace to look at
	 * @param {string} functionId The ID of the function whose expanded block to find
	 */
	getCurrentlyExpandedBlock(workspaceId, functionId) {
		let state = this.getState();
		if (state.hasIn(['workspaces', workspaceId, functionId])) {
			return state.getIn(['workspaces', workspaceId, functionId, 'expanded']);
		} else {
			return null;
		}
	}

	/**
	 * Function to look up the expansion status of a block
	 * 
	 * @param {string} workspaceId The ID of the workspace to look at
	 * @param {string} functionId The ID of the function whose blocks to look at
	 * @param {string} blockId The ID of the block whose status to check
	 */
	getBlockStatus(workspaceId, functionId, blockId) {
		let state = this.getState();
		if (state.hasIn(['workspaces', workspaceId, functionId]) && state.hasIn(['workspaces', workspaceId, functionId, 'blocks']) && state.hasIn(['workspaces', workspaceId, functionId, 'blocks', blockId])) {
			return state.getIn(['workspaces', workspaceId, functionId, 'blocks', blockId]);
		} else {
			return undefined;
		}
	}
}

const instance = new LogicFunctionStore(AppDispatcher);
export default instance;