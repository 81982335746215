import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { AdminSettingsActions } from '../../../actions';
import AdminSettingsStore from '../../../stores/admin-settings-store';
import PatternStore from '../../../stores/pattern-store';
import AppearanceAssistantActions from '../../../actions/appearance-assistant-actions';
import AppearanceAssistantStore from '../../../stores/appearance-assistant-store';
import AssistantResultsStore from '../../../stores/assistant-results-store';
import AssistantFields from '../../../utils/assistant-fields';
import AssistantForms from '../../../utils/assistant-forms';
import AssistantPages from '../../../utils/assistant-pages';
import AssistantTables from '../../../utils/assistant-tables';
import AssistantRelationships from '../../../utils/assistant-relationships';
import AssistantSearchUtils from '../../../utils/assistant-search-utils';
import FieldUtils from '../../../utils/field-utils';
import ObjectUtils from '../../../utils/object-utils';
import PageUtils from '../../../utils/page-utils';
import AuthenticationStore from '../../../stores/authentication-store';
import TableStore from '../../../stores/table-store';
import PageStore from '../../../stores/page-store';
import InterfaceActions from '../../../actions/interface-actions';
import AnalyzerTabs from './analyzer-tabs.react';
import MicrophoneComponent from '../microphone/microphone-component.react';

let assistantIconSVG = require('../../../images/assistant-blue-and-gray.svg');

/**
 * Renderer for the appearance assistant search, including search bar, results and analyzer tab
 * 
 * @class AppearanceAssistantSearch
 * @extends {Component}
 */
class AppearanceAssistantSearch extends Component {

	constructor(props) {
		super(props);
		this.onKeyPress = this.onKeyPress.bind(this);
		this.handleAudioData = this.handleAudioData.bind(this);
		this.runSearch = this.runSearch.bind(this);
		this.state = {};
	}

	/**
	 * Watch stores and re-run calculate state.
	 * 
	 * @static
	 * @returns - the stores that are being watched
	 * 
	 * @memberOf AppearanceAssistantSearch
	 */
	static getStores() {
		return [AppearanceAssistantStore, AssistantResultsStore];
	}

	/**
	 * Calculate this components state.
	 * 
	 * @param {any} prevState previous state
	 * @returns {Object} new state
	 * 
	 * @memberOf AppearanceAssistantSearch
	 */
	static calculateState(prevState) {
		return {
			input: AppearanceAssistantStore.getInput(),
			searchResults: AssistantResultsStore.getAllArray(),
			analyzerResults: AssistantResultsStore.getAnalyzerResults(),
			processing: prevState ? prevState.processing : false
		}
	}

	/**
	 * Run patterns/patternless operations from information about the current and previously run patterns/patternless operations
	 * 
	 * @param {object} previousSearchResult An object containing information about the pattern(s)/patternless operation(s) run previously.
	 * @param {object} analyzerSearchResult An object containing information about the pattern(s)/patternless operation(s) to run
	 * @returns {Promise <Object>} Promise which resolves to an object that can be passed in as a previousSearchResult or analyzerSearchResult
	 * 
	 * @memberOf AppearanceAssistantSearch
	 */
	processSearchResults(previousSearchResult, analyzerSearchResult) {
		// The idea of the iterative processSearchResults is that any information
		// Not specified in analyzerSearchResult will be retrieved from the previous
		// run of processSearchResults, if there is one.
		// This provides a framework for, for example, a field to be created and then updated immediately,
		// Or for a table to be created and then fields added to it, etc.
		// This is, more or less, the backbone that makes the assistant's combinatorial functionality work
		let searchResult = Object.assign({}, previousSearchResult, analyzerSearchResult);
		
		// Look at the searchResult's specified operation to determine the next steps
		switch(searchResult.operation) {
			case 'combo' : {
				// 'combo' results are top-level collections of patterns/patternless operations to run
				// They do not follow the same format as searchResults for other patterns/patternless operations
				InterfaceActions.notification({ 'level': 'info', 'message': 'Beginning Processing...' });
				// An array of searchResult objects over which to run processSearchResults
				let childResults = searchResult.childResults || [];

				let tooManyTables = false;
				let tooManyPages = false;
				let tableCount = TableStore.getAllArray().length;
				let pageCount = PageStore.getAllArray().length;
				let maxTableCount = AuthenticationStore.getMaxTables();
				let maxPageCount = AuthenticationStore.getMaxPages();
				childResults.forEach(childResult => {
					// Check if any of the table or page logic will take the user over their maximum count
					if(childResult.operation === 'create') {
						let currentComponentType = childResult.currentComponentInfo && childResult.currentComponentInfo.componentType;
						if(currentComponentType === 'table') {
							// Table check
							if(maxTableCount && tableCount >= maxTableCount) {
								tooManyTables = true;
							} else {
								tableCount += 1;
							}
						} else if(currentComponentType === 'page') {
							// Page check
							if(maxPageCount && pageCount >= maxPageCount) {
								tooManyPages = true;
							} else {
								pageCount += 1;
							}
						}
					}
				});

				if(tooManyTables) {
					InterfaceActions.notification({'message': 'Table creation failed: this pattern would take you over your maximum page count.', 'level': 'error'});
					return Promise.reject('Max table count exceeded.');
				}

				if(tooManyPages) {
					InterfaceActions.notification({'message': 'Page creation failed: this pattern would take you over your maximum page count.', 'level': 'error'});
					return Promise.reject('Max page count exceeded.');
				}

				// Run processSearchResults over each item in order and save it into a Promise.
				// Array.reduce is used to run Promises in order, as many require the previous operation to have finished
				let comboPromise = childResults.reduce((promise, childResult) => {
                    return promise.then(previousSearchResult => {
                        return this.processSearchResults(previousSearchResult, childResult);
                    });
				}, Promise.resolve({}));

				return comboPromise.then(() => {
					// Once all of the individual childResults have finished running, notify the user
					InterfaceActions.notification({ 'level': 'success', 'message': 'All pattern processing completed successfully!' });
				}).catch((err) => {
					// Notify the user of any errors
					console.error('Error when processing combo patterns', err);
					InterfaceActions.notification({ 'level': 'error', 'message': 'Error when processing patterns. Please check your console for more information.' });
				});
			}
			case 'attach': {
				if(searchResult.currentParentInfo && searchResult.currentParentInfo.componentType === 'page') {
					return AssistantPages.processAttach(searchResult).then(() => {
						return searchResult;
					});
				} else {
					AssistantFields.processAttach(searchResult).then(() => {
						return searchResult;
					});
				}
				break;
			}
			case 'attachAllFromAnotherPage': {
				AssistantPages.processAttachAllFromAnotherPage(searchResult);
				break;
			}
			case 'attachAllFromAnotherField': {
				AssistantFields.processAttachAllFromAnotherField(searchResult);
				break;
			}
			case 'update': {
				
				// Get relevant information from searchResult
				let currentComponentType = searchResult.currentComponentInfo.componentType;

				// Forward on to the proper update processor
				if(currentComponentType === 'field') {
					return AssistantFields.processUpdate(searchResult).then(() => {
						return searchResult;
					});
				} else if (currentComponentType === 'page') {
					return AssistantPages.processUpdate(searchResult).then(() => {
						return searchResult;
					});
				} else {
					// Table updates not supported after discussion
					console.warn('Updates for component type %s not yet supported', currentComponentType);
					break;
				}
			}
			case 'create': {
				// Ensure that searchResult has empty objects for the important object properties,
				// as values will be read from and assigned to them later
				searchResult = Object.assign({
					methodInfo: {},
					currentTableInfo: {},
					currentParentInfo: {},
					currentComponentInfo: {},
				}, searchResult);

				// Get the requisite information from the searchResult
				let patternId = searchResult.methodInfo.patternId;
				let tableSchemaName = searchResult.currentTableInfo.tableSchemaName;
				let parentComponentType = searchResult.currentParentInfo.componentType;
				let parentComponentId = searchResult.currentParentInfo.componentId;
				let currentComponentType = searchResult.currentComponentInfo.componentType;
				let currentComponentSubtype = searchResult.currentComponentInfo.componentSubtype;
				
				if(currentComponentType === 'table') {
					// Tables are created patternlessly through special handling
					return AssistantTables.processCreate(searchResult).then((newSearchResult) => {
						return newSearchResult;
					});
				} else if (currentComponentType === 'relationship') {
					// Relationships are created patternlessly through special handling
					return AssistantRelationships.processCreate(searchResult).then((newSearchResult) => {
						return newSearchResult;
					});
				} else {
					// Everything else is pattern-based
					
					// If we're adding a field, we're adding it to the current page by default if not provided
					parentComponentType = parentComponentType ? parentComponentType : 'page';
	
					// Make sure that there's a TSN for fields if not provided already.
					// First attempt to look up TSN from parent component record, rather than from ContextStore
					if(!tableSchemaName && parentComponentType === 'page') {
						tableSchemaName = PageUtils.getDefaultChildTableSchemaName(parentComponentId);
					} else if (!tableSchemaName && parentComponentType === 'field') {
						tableSchemaName = FieldUtils.getDefaultChildTableSchemaName(parentComponentId);
					}
	
					// Update searchResult's tableSchemaName
					searchResult.currentTableInfo = {tableSchemaName};
	
					// While both fields and forms are field-type components, form patterns are special.
					// This means that just using parentComponentType will not work
					// Find the type of pattern and then operate on it accordingly.	
					let patternObj = PatternStore.get(patternId) || {};
					let patternType = patternObj.type;
					
					// Get the type of field being created from the pattern if not known already
					if(!currentComponentSubtype && patternType === 'field') {
						let settings = ObjectUtils.getObjFromJSON(patternObj.settings);
						searchResult.currentComponentInfo.componentType = 'field';
						searchResult.currentComponentInfo.componentSubtype = settings.fieldType || '';
					} 
					if (patternType === 'field') {
						//Process the new Field 
						
						return AssistantFields.processCreate(searchResult)
							.then((newSearchResult) => {
								return AssistantFields.processUpdate(newSearchResult);
							}).then((newSearchResult) => {
								searchResult = newSearchResult;
								// Attach the field to the parent component
								if(parentComponentType === 'page') {
									return AssistantPages.processAttach(searchResult);
								} else if (parentComponentType === 'field') {
									return AssistantFields.processAttach(searchResult);
								}
							}).then(() => {
								return searchResult;
							});
					} else if (patternType === 'form') {
						let parentNextYPosition = 0;
	
						// Get parentNextYPosition information based on parent
						if(parentComponentType === 'page') {
							parentNextYPosition = PageUtils.getNextYPosition(parentComponentId);
						} else if(parentComponentType === 'field'){ 
							parentNextYPosition = FieldUtils.getNextYPosition(parentComponentId);
						}
	
						searchResult.parentNextYPosition = parentNextYPosition;
	
						AssistantForms.processCreate(searchResult);
						break;
					} else if (patternType === 'page') {
						return AssistantPages.processCreate(searchResult).then(newSearchResult => {
							searchResult = newSearchResult;
							return AssistantPages.processPattern(searchResult);
						}).then(() => {
							// Navigate to the page and open the panel
							PageUtils.loadPage(searchResult.currentComponentInfo.componentId, searchResult.currentTableInfo.tableSchemaName);
							// UIUtils.openSettingsPanel('appearance', searchResult.currentComponentInfo.componentId, 'page');
							return searchResult;
						});
					} else {
						console.warn('Unsupported patternType', patternType);
						break;
					}

				}

			}
			case 'deleteFieldFromTable':
			case 'delete': {
				let tableSchemaName = searchResult.currentTableInfo.tableSchemaName;
				let componentName = searchResult.currentComponentInfo.componentName;
				let currentComponentType = searchResult.currentComponentInfo.componentType;
				let componentId = searchResult.currentComponentInfo.componentId;
				let confirmRemove = confirm('Are you sure you want to delete ' + currentComponentType + ': ' + componentName);
				if (confirmRemove) {
					
					// Forward on to the appropriate delete handler
					if(currentComponentType === 'field') {
						if(FieldUtils.allowFieldDeletion(componentId, tableSchemaName)) {
							//Detach the field from all its parents: 
							FieldUtils.detachChildFromAllParents(componentId, tableSchemaName, true);
							//Then Delete it from the Table
							return AssistantFields.processDelete(searchResult).then(() => {
								AdminSettingsActions.onSettingsPreviewChange(false);
								return searchResult;
							});
						} else {
							InterfaceActions.notification({ 
								'level': 'error', 
								'message': 'Unable to delete field. Tables must have at least one field that stores data.' });
							return Promise.reject('Unable to delete field. Tables must have at least one field that stores data.');
						}
					} else if(currentComponentType === 'page') {
						return AssistantPages.processDelete(searchResult).then(() => {
							return searchResult;
						});
					} else if (currentComponentType === 'table') {
						return AssistantTables.processDelete(searchResult).then(() => {
							return searchResult;
						});
					} else if (currentComponentType === 'relationship') {
						return AssistantRelationships.processDelete(searchResult).then(() => {
							return searchResult;
						});
					} else {
						console.warn('Delete operations not yet supported for componentType', currentComponentType);
					}
				}
				break;
			}
			case 'deletePageFromTable': {
				let confirmRemove = confirm('Are you sure you want to delete page: ' + searchResult.pageName);
				if (confirmRemove) {

					//Delete it from the Table
					AssistantPages.processDelete(searchResult);
				}
				break;
			}
			case 'detach': {

				// Close the settings panel if it's about the field being attached
				let closeSettingsPanel = (searchResult.currentComponentInfo && searchResult.currentComponentInfo.componentId === AdminSettingsStore.getRecordId());
				
				// Forward on to the correct detach handler based on the parent
				if(searchResult.currentParentInfo && searchResult.currentParentInfo.componentType === 'page') {
					AssistantPages.processDetach(Object.assign({}, searchResult, {closeSettingsPanel}));
				} else {
					AssistantFields.processDetach(Object.assign({}, searchResult, {closeSettingsPanel}));
				}
				break;
			}
			default:
				console.warn('Invalid search result operation:', searchResult.operation);
		}

		return Promise.resolve(searchResult);
	}

	/**
	 * Handle clicking on an NLP Search result
	 * 
	 * @param {object} event 
	 * @param {array} searchResult 
	 * @returns boolean
	 * 
	 * @memberof AppearanceAssistantSearch
	 */
	onClickResult(event, searchResult) {
		event.preventDefault();
		this.setState({processing: true});

		// clear the input and results
		this.clearInput();

		this.processSearchResults(searchResult)
			.then(() => {
				this.setState({processing: false});
			})
			.catch(console.error);

		// AssistantSearchUtils.selectSearch();
		return false;
	}
	
	/**
	 * onInputChange - handles text input
	 *
	 * @param  {object} event description
	 * @return {type}       description
	 */
	onInputChange(event) {
		// AssistantSearchUtils.runAssistantSearch(event.target.value);
		let oldValue = AppearanceAssistantStore.getInput();
		AppearanceAssistantActions.onInputChange(event.target.value);
		// If the value is being cleared, clear the results list
		if(oldValue && !event.target.value) {
			AssistantSearchUtils.runAssistantSearch({}, {}, '');
		}
	}

	handleAudioData(data) {
		let newValue = data && data.results && data.results[0] && data.results[0].alternatives && data.results[0].alternatives[0] && data.results[0].alternatives[0].transcript ?
			data.results[0].alternatives[0].transcript :
			'';
		let oldValue = this.state.input;
	
		if(newValue !== oldValue) {
			AppearanceAssistantActions.onInputChange(newValue);
		}
	}

	/**
	 * Clears the input
	 */
	clearInput() {
		AppearanceAssistantActions.onInputChange('');
		AssistantSearchUtils.runAssistantSearch({}, {}, '');
	}

	/**
	 * onKeyPress -- Manually runs NLP input whenever you hit "enter" within the search bar. Used primarily for debugging.
	 * 
	 * @param  {object} event The keyPress event
	 */
	onKeyPress({charCode} = event) {
		if (charCode === 13) {

			this.runSearch();
		}
	}

	/**
	 * Actually runs the NLP search, from input to updating the stores with the results.
	 */
	runSearch() {
		let currentComponent = {
			componentType: this.props.componentType,
			componentId: this.props.componentId
		}, parentComponent = {
			componentType: this.props.parentComponentType,
			componentId: this.props.parentComponentId
		};

		// Get the input to run the value with
		let input = AppearanceAssistantStore.getInput();
		AssistantSearchUtils.runAssistantSearch(currentComponent, parentComponent, input);
		// this.setState({showAnalyzer: true});

	}

	/**
	 * Toggles whether the Analyzer is displayed or not
	 */
	toggleAnalyzer() {
		this.setState({showAnalyzer: !this.state.showAnalyzer});
	}

	/**
	 * Processes the label for a pattern combo for processing.
	 * Aside from wrapping this in the appropriate span tags,
	 * the main purpose of this function is to enable color previews
	 * in color setting updates without requiring the use of 
	 * dangerouslySetInnerHTML. This works by accepting
	 * hex codes of the form [[#hexcde]] (e.g, '[[#ffff]]') and
	 * replacing them with a span which will show a preview circle with
	 * that color.
	 * 
	 * Example:
	 * 	Input: Set Background Color on Add Task to "alice blue [[#F0F8FF]]"
	 * 	Output: <div className="result-label">
	 * 				Set Background Color on Add Task to "alice blue
	 * 				<span className="fa fa-circle" style={{ "color": "#F0F8FF" }}></span>
	 * 			</div>
	 * 
	 * @param {string} label 
	 */
	processLabel(label) {
		if(!label) {
			return (<div className="result-label"></div>);
		}
		// Split label into array where each value is either a non-color-code or a color code
		let labelArray = label.split(/(\[\[#[0-9a-f]{3}(?:[0-9a-f]{3})]]?)/gi);
		let labelReact = (
			<div className="result-label">{
				// Each value in the split array is either of the form [[#hexcde]] or does not contain that at all.
				labelArray.map(text => {
					// regEx to determine if this is a [[#hexcde]] value or not
					let isHexCode = text && /^\[\[#[0-9a-f]{3}(?:[0-9a-f]{3})]]?$/i.test(text);
					if (!isHexCode) {
						// Just return the plain text if it's not the special color code
						return text;
					} else {
						// Strip wrapping brackets
						let hexCode = text.replace(/[[\]]/g, '');
						// Display preview circle with the hex code
						return (<span className="fa fa-circle" style={{ "color": hexCode }}></span>);
					}
				})
			}</div>
		);

		return labelReact;
	}

	/**
	 * render - NLPBuilder
	 *
	 * @return {JSX}
	 */
	render() {
		let searchResults = this.state.searchResults;
		let analyzerResults = this.state.analyzerResults;
		let className = this.state.showAnalyzer ? 'col-6' : 'col-12'

		// let componentSettings = this.state.componentSettings,
		// 	settingsList = [],
		let	results = [];

		searchResults.forEach(searchResult => {
			// let scoreDescriptionJSX = (searchResult.scoreDescription ? searchResult.scoreDescription.join('\n') : '');
			let classNames = 'list-group-item flex-column align-items-start';
			if(searchResult.type === 'combo') {
				classNames += ' combinationResult';
			}

			// Preprocess the label for display
			let labelReact = this.processLabel(searchResult.label);

			results.push(
				<div key={searchResult.resultId}>
					<a disabled={this.state ? this.state.processing : false} href="#" className={classNames} 
						onClick={this.state && !this.state.processing ? (event) => {this.onClickResult(event, searchResult);} : () => {}}>
						<div className="d-flex w-100 justify-content-between">
							{labelReact}
							{/* <div className="result-score" title={scoreDescriptionJSX}>{searchResult.score}</div> */}
						</div>
					</a>
				</div>
			);
		});

		let button = this.state.input ?
			(<span style={{cursor: 'pointer', lineHeight: '18px'}} onClick={this.clearInput} className="input-group-prepend fa fa-times"></span>) :
			(<img alt='' role='presentation' style={{maxWidth: '3.5em'}} className="input-group-prepend" src={assistantIconSVG} />);
		
		return (
			<div className="row" id="appearance-assistant-search">
				<div className="input-group col-12 justify-content-between">
						{button}
						<input type="text"
							className="col px-0 rounded-0"
							// className="form-control"
							name="appearance-assistant-input"
							id="appearance-assistant-input" 
							value={this.state.input}
							onKeyPress={this.onKeyPress}
							onChange={this.onInputChange.bind(this)}
							placeholder="How can I help?"
						/>
						<MicrophoneComponent
							className='input-group-append align-items-center'
							audioBitsPerSecond='4096'
							mimeType='audio/wav'
							onUpdate={this.handleAudioData}
							onUtteranceEnd={this.runSearch}
						/>
						{
							/*<span className="fa fa-gears appearance-assistant-analyzer-button" onClick={this.toggleAnalyzer.bind(this)}>
								<span className="sr-only">Analyzer</span>
							</span> */
						}
				</div>
				<div className="row">
					{
						results.length ?
						(<div className={this.props.contentsClass}>
							<div className={"assistant-results-wrapper " + className}>
								{/* <div className="list-group"> */}
								<div className="list-group">
									{results}
								</div>
							</div>
						</div>) :
						null
					}
				{
					this.state.showAnalyzer ?
					<div className="assistant-results-wrapper col-6 ">
						{/* <AnalyzerTabs content={analyzerResults} /> */}
							<AnalyzerTabs 
								content={analyzerResults && JSON.stringify(analyzerResults) !== '{}' 
										? JSON.stringify(analyzerResults) 
										: '{}'
									}
							/>
					</div> :
					null
				}
				</div>
			</div>
		);
	};
}
// export default AppearanceAssistantSearch;
const container = Container.create(AppearanceAssistantSearch, { withProps: true });
export default container;
