import React, {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * Component for Table label and Icon
 */
export default class TableIcon extends Component {
  /**
   * Renders TableIcon
   */
  render() {
    let {className, iconClass, label, required, onClick} = this.props,
        //cdn = ContextStore.getUrlFontawesome(),
        //icon = table ? <img src={`${cdn}/${table.icon}.svg`} className='lg-icon icon' alt="table icon"/> : null,
        icon = iconClass ? <span className={`fa fa-3x fa-${iconClass}`} /> : null;

    return (
      <div id={label.replace(/\s/g,'')} required={required} className={className} onClick={onClick}>
        <label>{label}</label>
        {icon}
      </div>
    );
  }
}

if ('development' === process.env.NODE_ENV) {
  TableIcon.propTypes = {
    className: PropTypes.string,
    iconClass: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
    onClick: PropTypes.func
  }
}
