import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import DiscussionConstants from '../constants/discussion-constants';
// import UIUtils from '../utils/ui-utils';

/**
 * Manages discussion information
 */
class DiscussionStore extends ReduceStore {
	/**
	 * getInitialState - initial state for ContextStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.fromJS({
			discussions: {},
			allPulledFromDatabase: false
		});
	}

	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			case DiscussionConstants.DISCUSSION_PUSH_TO_DATABASE: {
				// Because push to database may result in a new discussion Id
				return state.withMutations(newState => {
					let discussionProperties = action.get('discussionProperties');
					let discussionId = action.get('discussionId');
					// Delete the temporary entry now that this exists in the DB
					if(discussionProperties.has('temporaryId')) {
						let tempId = discussionProperties.get('temporaryId');
						newState.deleteIn(['discussions', tempId]);
						discussionProperties = discussionProperties.delete('temporaryId');
					}
					if(action.has('newComment')) {
						newState.mergeDeepIn(['discussions', discussionId, 'comments', action.getIn(['newComment', 'recordId'])], action.get('newComment'));
					}
					newState.deleteIn(['discussions', discussionId, 'wipComment']);
					newState.mergeDeepIn(['discussions', discussionId], discussionProperties);
				});
			}
			case DiscussionConstants.DISCUSSION_PUSH_TO_STORE: {
				return state.mergeDeepIn(['discussions', action.get('discussionId')], action.get('discussionProperties'));
			}
			case DiscussionConstants.DISCUSSION_DELETE_FROM_STORE: {
				return state.deleteIn(['discussions', action.get('discussionId')]);
			}
			case DiscussionConstants.DISCUSSION_PULL_FROM_DATABASE: {
				let newState = state;
				let overwriteStore = action.get('overwriteStore');
				let discussionId = action.get('discussionId');
				let discussion = action.get('discussion');
				if(overwriteStore) {
					// Just overwrite the store with what we pulled
					newState = state.setIn(['discussions', discussionId], discussion);
				} else {
					newState = state.setIn(['discussions', discussionId], discussion.mergeDeep(state.getIn(['discussions', discussionId])));
				}
				newState = newState.set('allPulledFromDatabase', true);
				return newState;
			}
			case DiscussionConstants.DISCUSSION_PULL_FROM_DATABASE_ALL: {
				let newState = state;
				let overwriteStore = action.get('overwriteStore');
				let discussionsList = action.get('discussions');
				// Convert the List ('array') to a Map ('object')
				let discussionsMap = Immutable.Map(discussionsList.map(d => [d.get('discussionId'), d]));
				if(overwriteStore) {
					// Just overwrite the store with what we pulled
					newState = state.set('discussions', discussionsMap);
				} else {
					newState = state.set('discussions', discussionsMap.mergeDeep(state.get('discussions')));
				}
				newState = newState.set('allPulledFromDatabase', true);
				return newState;
			}
			case DiscussionConstants.DISCUSSION_COMMENT_PULL_FROM_DATABASE_ALL: {
				let newState = state;
				let overwriteStore = action.get('overwriteStore');
				let discussionId = action.get('discussionId');
				let discussionComments = action.get('discussionComments');
				let discussionsCommentsMap = Immutable.Map(discussionComments.map(d => [d.get('recordId'), d]));
				if(overwriteStore) {
					// Just overwrite the store with what we pulled
					newState = state.setIn(['discussions', discussionId, 'comments'], discussionsCommentsMap);
				} else {
					newState = state.setIn(['discussions', discussionId, 'comments'], discussionsCommentsMap.mergeDeep(state.getIn(['discussions', discussionId, 'comments'])));
				}
				return newState;
			}
			case DiscussionConstants.DISCUSSION_COMMENT_PUSH_TO_STORE: {
				return state.setIn(['discussions', action.get('discussionId'), 'wipComment'], action.get('discussionCommentProperties'));
			}
			case DiscussionConstants.DISCUSSION_COMMENT_PUSH_TO_DATABASE: {
				// Because push to database may result in a new discussion Id
				return state.withMutations(newState => {
					let discussionId = action.get('discussionId');
					let discussionCommentProperties = action.get('discussionCommentProperties');
					// Delete the temporary entry now that this exists in the DB
					if(!newState.hasIn(['discussions', discussionId, 'comments'])) {
						newState.setIn(['discussions', discussionId, 'comments'], Immutable.Map());
					}
					// Clear wipComment
					newState.deleteIn(['discussions', discussionId, 'wipComment']);
					newState.mergeDeepIn(['discussions', discussionId, 'comments', action.get('recordId')], discussionCommentProperties);
					newState.setIn(['discussions', discussionId, 'commentCount'], +newState.getIn(['discussions', discussionId, 'commentCount']) + 1);
				});
			}
			default:
				return state;
		}
	}

	/**
	 * Gets whether everything has been pulled from the DB or not
	 */
	allPulledFromDatabase() {
		return this.getState().get('allPulledFromDatabase');
	}

	/**
	 * Gets the entire store as an object
	 *
	 * @returns {Object} current store as an object
	 */
	getAll() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			return this.getState().get('discussions').toJS();
		} else {
			// console.warn('pullFromDatabaseAll not run, did you mean get()?');
			return undefined;
		}
	}

	/**
	 * Gets the entire store as an array
	 *
	 * @returns {Array} current store as an array
	 */
	getAllArray() {
		if (this.getState().get('allPulledFromDatabase') === true) {
			return this.getState().get('discussions').toList().toJS();
		} else {
			// console.warn('pullFromDatabaseAll not run, did you mean get()?');
			return undefined;
		}
	}

	/**
	 * Gets an individual record from the store
	 * @param {string} discussionId UUID of the record to get
	 * @returns {Object} current store as an object
	 */
	get(discussionId) {
		if (this.getState().get('discussions').has(discussionId)) {
			return this.getState().get('discussions').get(discussionId);
		} else {
			return undefined;
		}
	}

	getDiscussionsForComponent(componentId) {
		if (this.getState().get('allPulledFromDatabase') === true) {
			let discussions = this.getState().get('discussions').toList();
			return discussions.filter((discussion) => {
				return discussion.get('componentId') === componentId;
			}).toJS()
		} else {
			// console.warn('pullFromDatabaseAll not run, did you mean get()?');
			return undefined;
		}
	}

	getDiscussionComments(discussionId) {
		if (!this.getState().get('allPulledFromDatabase')) {
			return undefined;
		} else {
			return this.getState().getIn(['discussions', discussionId, 'comments']);
		}
	}

	getWipDiscussionComment(discussionId) {
		if (!this.getState().get('allPulledFromDatabase')) {
			return undefined;
		} else {
			return this.getState().getIn(['discussions', discussionId, 'wipComment']);
		}
	}
}

const instance = new DiscussionStore(AppDispatcher);
export default instance;