/*global Blockly*/
import AuthenticationStore from '../stores/authentication-store';
import FieldStore from '../stores/field-store';
import FieldActions from '../actions/field-actions';
import FieldTypeStore from '../stores/field-type-store';
import FieldSettingsStore from '../stores/field-settings-store';
import PageStore from '../stores/page-store';
import PageActions from '../actions/page-actions';
import MetadataStore from '../stores/metadata-store';
import MetadataActions from '../actions/metadata-actions';
import LogicFunctionStore from '../stores/logic-function-store';
import ContextStore from '../stores/context-store';
import ContextActions from '../actions/context-actions';
import LogicFunctionActions from '../actions/logic-function-actions';
import InterfaceActions from '../actions/interface-actions';
import uuid from 'uuid';
import async from 'async';
import { ObjectUtils } from '.';

/**
 * Util class for blockly
 * 
 * @namespace BlocklyUtils
 */
const BlocklyUtils = {

	// High-level functions: used to generate information about a workspace

	/**
	 * Parser to build object with XML and JS code for blockly
	 *
	 * @memberof BlocklyUtils
	 */
	parseXml(xml) {
		// Non-SVG workspaces can have a problem if Blockly.theme_ has yet to be set.
		if (Blockly.setTheme && Blockly.Themes && Blockly.Themes.CitDevFiveColor && !Blockly.theme_) {
			Blockly.theme_ = Blockly.Themes.CitDevFiveColor;
		}

		let workspace = new Blockly.Workspace(),
			generatedCode;
		Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(xml), workspace);
		Blockly.JavaScript.init(workspace);
		generatedCode = Blockly.JavaScript.workspaceToCode(workspace);

		return {
			workspaceXML: xml,
			generatedJavascript: generatedCode
		};
	},

	/**
	 * Parser to build object with XML and JS code for blockly, optimized for logic functions
	 *
	 * @memberof BlocklyUtils
	 */
	parseFunctionXml(xml, params, functionId, title, preventRestAPI) {
		let tempWorkspace = this.getWorkspaceFromXml(xml);
		let paramsArr = [];
		if(params) {
			paramsArr = ObjectUtils.getObjFromJSON(params);
		}
		if(preventRestAPI) {
			// Check to see if we have a REST API block. If we do, forbid it.
			let restApiBlocks = tempWorkspace.getBlocksByType('webServices');
			if(restApiBlocks && restApiBlocks.length) {
				throw new Error('Function ' + title + ' contains blocks not available to free users.');
			}
		}
		let js = Blockly.JavaScript.functionWorkspaceToCode(
			tempWorkspace, paramsArr, functionId, title);
		return {
			xml: xml,
			js: js
		}
	},

	/**
	 * Expanded parser to build object with XML and JS code for blockly. This supports
	 * additional parameters and is specificaly meant for parsing actions, not just expressions.
	 * 
	 * @param {string} blocklyxml The Blockly XML text being converted
	 * @param {object} params An object with parameters which can control keys on/the type of the output
	 * @param {boolean} params.defaultToNull Return null (or don't) if no blocks are on the workspace
	 * @param {boolean} params.checkRunOnBackend Check whether to run this on the backend or not (not necessary for API and scheduled logic triggers)
	 * @param {boolean} params.runOnBackend The runOnBackend value from the workspace
	 * 
	 * @returns {object} An object with blocklyxml, blocklyjs, and logicFunctionsUsed keys. May also include runOnBackend.
	 * @memberof BlocklyUtils
	 */
	parseActionXml(blocklyxml, params) {

		let tempWorkspace = this.getWorkspaceFromXml(blocklyxml);

		return this.getWorkspaceInfo(tempWorkspace, params);
	},

	/**
	 * Gets workspace information. Reused in a number of places, including to get information for
	 * workspaces updated via function updates, functions themselves being updated, and, inside parseActionXml,
	 * for code reconstituted from XML in logic workspaces
	 * 
	 * @param {object} workspace
	 * @param {object} params
	 * @param {boolean} params.defaultToNull Return null (or don't) if no blocks are on the workspace
	 * @param {boolean} params.checkRunOnBackend Check whether to run this on the backend or not (not necessary for API and scheduled logic triggers)
	 * @param {boolean} params.runOnBackend The runOnBackend value from the workspace
	 * @param {string} params.kind The record kind the workspace is saved against
	 * @param {boolean} params.memUse The memUse value
	 * @memberof BlocklyUtils
	 */
	getWorkspaceInfo: function (workspace, params, preventRestAPI) {
		if (!workspace) {
			return {};
		}
		let workspaceHasBlocks = !!workspace.getAllBlocks().length;

		// Return null if there is no workspace and we want to default to null
		if (params && params.defaultToNull && !workspaceHasBlocks) {
			return null;
		}

		let toReturn = {};

		// Get the XML
		try {
			toReturn.blocklyxml = this.getXmlFromWorkspace(workspace, params && params.kind);
		} catch (err) {
			console.error('Error getting XML from workspace', err);
			throw err;
		}

		// Get the JS
		if (params.includeJs) {
			try {
				toReturn.js = this.getJsFromWorkspace(workspace, preventRestAPI, params);
			} catch (err) {
				throw err;
			}
		}

		// Get the functions used
		let logicFunctionsUsedArr = params.kind === 'logicFunctions' ?
			this.functionsUsedInLogicWorkspace(workspace, {}) :
			this.functionsUsedInWorkspace(workspace);
		toReturn.logicFunctionsUsed = logicFunctionsUsedArr && logicFunctionsUsedArr.length ? logicFunctionsUsedArr.join(',') : '';
		let logicFunctionsUsedDirectly = this.functionsDirectlyInLogicWorkspace(workspace);
		toReturn.logicFunctionsUsedDirectly = logicFunctionsUsedDirectly && logicFunctionsUsedDirectly.length ? logicFunctionsUsedDirectly.join(',') : '';

		// Get whether or not to run on the BE
		if (params && params.checkRunOnBackend) {
			// Check if the XML contains the flag to force it to run on the backend
			let runOnBackend = (toReturn.blocklyxml && toReturn.blocklyxml.includes('cd_force_backend="true"')) || (params.runOnBackend) || (logicFunctionsUsedArr && logicFunctionsUsedArr.map(id => LogicFunctionStore.get(id)).includes(f => f && (f.forceBackend + '') === 'true'));
			// let runOnBackend = (toReturn.blocklyxml && toReturn.blocklyxml.includes('cd_force_backend="true"')) || (params.runOnBackend);
			toReturn.runOnBackend = runOnBackend;
		}

		// Get the memory use parameter
		if (params && params.memUse) {
			toReturn.memUse = params.memUse;
		}

		return toReturn;
	},

	/**
	 * 
	 * @param {*} logicFunctionsUsedArr 
	 * @param {*} workspace 
	 * @param {*} params 
	 * @param {*} callback 
	 * @param {*} triggerType 
	 * @returns 
	 */
	processFunctionUpdates(logicFunctionsUsedArr, workspace, params, callback, triggerType, preventRestAPI) {
		// Find all locations to update and do so
		// We only need to update places which directly reference this function,
		// as only the parameters are changing
		let locationsToUpdate = this.findAllLocations(logicFunctionsUsedArr);
		// let updatedParentFunctions = Object.keys(locationsToUpdate.logicFunctions);

		// Get the workspace information

		let newWorkspaceInfo = this.getWorkspaceInfo(workspace, params, preventRestAPI);

		// We are no longer finding the parent functions; all parent functions are just getting saved later
		let allUpdatedFunctions = logicFunctionsUsedArr;
		// Track any functions which have been updated, as we'll need to save them to the DB later.
		// let allUpdatedFunctions = updatedParentFunctions.concat(logicFunctionsUsedArr.filter(functionId => {
		// 	return updatedParentFunctions.indexOf(functionId) === -1;
		// }));


		let allFunctionsUpdatedPromise = Promise.resolve();
		if (allUpdatedFunctions && allUpdatedFunctions.length) {
			let updateCount = 0;
			let id = uuid.v4();
			let functionUpdateNotification = InterfaceActions.stickyNotification({
				'title': 'Function parameters changed. Updating and saving all impacted functions.',
				'message': updateCount + '/' + allUpdatedFunctions.length + '. Please wait...',
				'level': 'info',
				'id': id
			});

			let functionUpdatePromises = allUpdatedFunctions.map(functionId => {
				let logicFunctionRecord = LogicFunctionStore.get(functionId, true);
				// The record ID is automatically included, so the length is more than 1 if there are any dirty values to save
				let updatePromise = Promise.resolve();
				// If the function parameters have changed, we need to generate the function JS again
				if (logicFunctionRecord && Object.keys(logicFunctionRecord).length > 1) {
					if(logicFunctionRecord.params || preventRestAPI) {
						try {
							// Regenerate the JS
							let fullLogicRecord = LogicFunctionStore.get(functionId);
							// @TODO: This needs to be updated to throw an error if it contains forbidden blocks and to catch that case if it does
							logicFunctionRecord.js = this.parseFunctionXml(fullLogicRecord.blocklyxml, logicFunctionRecord.params, fullLogicRecord.recordId, fullLogicRecord.name, preventRestAPI).js;
						} catch(err) {
							return Promise.reject(err);
						}
					}
					logicFunctionRecord.recordId = functionId;
					updatePromise = LogicFunctionActions.pushToDatabasePromise(logicFunctionRecord);
				}
				return new Promise((resolve, reject) => {
					updatePromise
						.then(() => {
							updateCount++;
							functionUpdateNotification = InterfaceActions.stickyNotification({
								'title': 'Updating and saving all functions on this workspace to the database',
								'message': updateCount + '/' + allUpdatedFunctions.length + '. Please wait...',
								'level': 'info',
								'id': id
							});
							resolve();
						})
						.catch(reject);
				});
			});

			allFunctionsUpdatedPromise = new Promise((resolve, reject) => {
				Promise.all(functionUpdatePromises)
					.then(() => {
						InterfaceActions.clearStickyNotification(functionUpdateNotification);
						resolve();
					})
					.catch((error) => {
						console.error('Error when saving functions to the database', error);
						InterfaceActions.clearStickyNotification(functionUpdateNotification);
						InterfaceActions.stickyNotification({
							level: 'error',
							message: 'Error when saving functions to the database. Please check your console for more information.'
						});
						reject(error);
					});
			});
		}

		return new Promise((resolve, reject) => {
			allFunctionsUpdatedPromise
				.then(() => {
					let saveToDbPromise = new Promise((resolve, reject) => {
						let savePromise = callback ?
							callback(newWorkspaceInfo) :
							Promise.resolve();

						savePromise
							.then(() => {
								// Avoid disposing the workspace while the workspace is being used
								if(workspace && workspace.needsDisposal) {
									workspace.dispose();
								}
								if(workspace) {
									workspace.saving = false;
								}
								resolve();
							})
							.catch((error) => {
								console.error('Error when saving this logic to the database', error);
								InterfaceActions.stickyNotification({
									level: 'error',
									message: 'Error when saving this logic to the database. Please check your console for more information.'
								});
								reject(error);
							});
					});
					return saveToDbPromise;
				})
				.then(() => {
					let otherWorkspacesToUpdatePromise = Promise.resolve();

					// Now we need to find all other metadata types to update
					if (locationsToUpdate) {
						let siblingUpdatePromises = this.regenerateFunctionInstancesInComponents(locationsToUpdate);
						otherWorkspacesToUpdatePromise = new Promise((resolve, reject) => {
							siblingUpdatePromises
								.then(() => {
									// Regenerate API triggers if any API endpoints are updated and the 'parent' trigger will not do that already
									if (triggerType !== 'apiconfig' && Object.keys(locationsToUpdate.apiActions).length) {
										let updateNotification = InterfaceActions.stickyNotification({
											'title': 'Function detected in API triggers.',
											'message': 'Updating API endpoints...',
											'level': 'warning'
										});
										return new Promise(function (resolve, reject) {
											fetch(ContextStore.getBasePath() + '/gw/updateRouter', {
												method: 'GET',
												headers: {
													'Content-Type': 'application/json; charset=UTF-8'
												}
											}).then(function (response) {
												InterfaceActions.clearStickyNotification(updateNotification);
												resolve();
											}).catch(function (error) {
												InterfaceActions.notification({
													level: 'error',
													message: 'API endpoint update failed. ' +
														'Review the console for more information. ' +
														'You may navigate to /gw/updateRouter to update your router manually.'
												});
												console.error('API endpoint update failed:', error);
												reject(error);
											});
										});
									} else {
										return null;
									}
								})
								.then(resolve)
								.catch(error => {
									console.error('Error when saving functions to the database', error);
									InterfaceActions.stickyNotification({
										level: 'error',
										message: 'Error when saving functions to the database. Please check your console for more information.'
									});
									reject(error);
								});
						});
					}
					return otherWorkspacesToUpdatePromise;
				})
				// Promise.all([saveToDbPromise, allFunctionsUpdatedPromise, otherWorkspacesToUpdatePromise])
				.then(resolve)
				.catch(reject)
		});
	},

	/**
	 * Similar to saveAutomationFromWorkspaceV2 but for logic function workspaces
	 * @param {*} workspace 
	 * @param {*} params 
	 * @param {*} callback 
	 * @returns 
	 */
	saveAutomationFromLogicWorkspace(workspace, params, callback) {
		// If this is a free user, we need to check whether this has any REST API blocks and prevent saving if it is.
		let preventRestAPI = !AuthenticationStore.getHasRestAPILogic();
		let umbrellaSaveNotification = InterfaceActions.stickyNotification({
			'title': 'Saving workspace.',
			'message': 'Please wait...',
			'level': 'info'
		});

		let logicFunctionId = params.recordId;

		let actionId = uuid.v4();
		ContextActions.addActionId(actionId);

		// We need to make sure we've updated this logic function's workspace in the store first
		// in case of a change in params, logicFunctionsUsed, etc.
		let newWorkspaceInfo;
		try {
			newWorkspaceInfo = this.getWorkspaceInfo(workspace, params, preventRestAPI);
			LogicFunctionActions.pushToStore(logicFunctionId, newWorkspaceInfo);
		} catch(err) {
			console.error('Error getting workspace information for %s', logicFunctionId, err);
		}

		// Get any functions used. Because of nested functions which may be dirty,
		// we want to consider ALL functions, not just the ones on the workspace
		let logicFunctionsUsedArr = this.functionsUsedInLogicWorkspace(workspace);
		if(logicFunctionsUsedArr.indexOf(logicFunctionId) === -1) {
			logicFunctionsUsedArr.push(logicFunctionId);
		}

		let dirtyFunctions = [];
		let functionsRequiringRegeneration = [];
		let functionsRequiringUsageRecalculations = [];
		logicFunctionsUsedArr.forEach(functionId => {
			let dirtyRecord = LogicFunctionStore.get(functionId, true);
			// These functions are dirty and should be saved
			if(dirtyRecord && Object.keys(dirtyRecord).length > 1) {
				dirtyFunctions.push(functionId);
			}
			// These function parameters have changed and will require recalculating the
			// js for each direct location
			if(dirtyRecord && dirtyRecord.params) {
				functionsRequiringRegeneration.push(functionId);
			}

			// These functions have changed the logic functions they used
			// and all locations, direct or indirect,
			// must be updated to get the new logicFunctionsUsed
			// If the current logic function is dirty, just check this anyway
			if(dirtyRecord && (dirtyRecord.recordId === logicFunctionId || dirtyRecord.logicFunctionsUsed || (dirtyRecord.forceBackend + '' === 'true'))) {
				functionsRequiringUsageRecalculations.push(functionId);
			}
		});

		// Find all locations of functionsRequiringUsageRecalculations

		let siblingWorkspaces = this.findSiblingsToRegen(functionsRequiringUsageRecalculations);
		let functionsToUpdate = this.findAllFunctionsToUpdate(functionsRequiringUsageRecalculations, true);

		// Don't waste time with the functions we're already saving
		functionsToUpdate = functionsToUpdate.filter(funct => dirtyFunctions.indexOf(funct) === -1);
		// Now we need to update these locations in the store and mark them to save later
		// We also need to avoid double-saving if the appropriate trigger appears
		// in multiple locations

		// Okay, the way this logic should work:
		// * Update the sibling + function locations in the store
		// * Identify all metadata which will need to be saved
		// * If we're doing a full regeneration, pass that into processFunctionUpdates
		// and update that function to avoid duplicate updates
		// * Otherwise, update it here as a part of updatePromise 

		let functionNotification;
		let id = uuid.v4();
		let updateCount = 0;
		if(dirtyFunctions && dirtyFunctions.length > 1) { // This is already a logic function, so only show this if the more than one is being updated
			functionNotification = InterfaceActions.stickyNotification({
				'title': 'Updating and saving all functions on this workspace to the database',
				'message': updateCount + '/' + (dirtyFunctions.length + functionsToUpdate.length) + '. Please wait...',
				'level': 'info',
				'id': id
			});
		}
		let updatePromise;
		if(functionsRequiringRegeneration.length || preventRestAPI) {
			// Run the full regeneration, because the parameters have changed
			let { triggerType } = params;
			updatePromise = this.processFunctionUpdates(logicFunctionsUsedArr, workspace, params, callback, triggerType, preventRestAPI);
		} else {
			// Just save the workspace and function
			let functionSavePromises = dirtyFunctions.map(functionId => {
				updateCount += 1;
				return LogicFunctionActions.pushToDatabasePromise(LogicFunctionStore.get(functionId, true));
			});

			// If we have any other functionsToUpdate, add them in here
			functionsToUpdate.forEach(recordId => {
				let record = LogicFunctionStore.get(recordId);
				updateCount += 1;
				let newLogicFunctionsUsed = {};
				let logicFunctionsUsedDirectly = [];
				if(record && record.logicFunctionsUsedDirectly) {
					logicFunctionsUsedDirectly = Array.isArray(record.logicFunctionsUsedDirectly) ? record.logicFunctionsUsedDirectly : record.logicFunctionsUsedDirectly.split(',');
				}
				logicFunctionsUsedDirectly.forEach(recordId => {
					newLogicFunctionsUsed = this.findDescendantFunctions(recordId, newLogicFunctionsUsed);
				});
				record.logicFunctionsUsed = Object.keys(newLogicFunctionsUsed).join(',');
				record.forceBackend = (record.forceBackend + '' === 'true') 
					? record.forceBackend
					: this.getForceBackend(Object.keys(newLogicFunctionsUsed));
				LogicFunctionActions.pushToStore(recordId, record);
				functionSavePromises.push(LogicFunctionActions.pushToDatabasePromise(LogicFunctionStore.get(recordId, true)));
			});

			// Now add in any other location updates if we need them
			let siblingWorkspaceUpdates = [];
			Object.keys(siblingWorkspaces).forEach(dataType => {
				// First, loop over each component
				Object.keys(siblingWorkspaces[dataType]).forEach(recordId => {
					// Now for each workspace in this, get the new logicFunctionsUsed
					// Then update the store and push the promise
					// Make sure to skip the update for the current workspace (How?)
					let recordInfo = siblingWorkspaces[dataType][recordId];

					let recordObj = {};

					switch (dataType) {
						case 'fields': {
							recordObj = FieldStore.get(recordId) || {};
							break;
						}
						case 'pages': {
							recordObj = PageStore.get(recordId) || {};
							break;
						}
						case 'scheduledActions': {
							recordObj = MetadataStore.get(recordId, 'scheduledLogic') || {};
							break;
						}
						case 'apiActions': {
							recordObj = MetadataStore.get(recordId, 'apiconfig') || {};
							break;
						}
						default:
							{
								console.warn('Invalid componentType "%s" passed into saveAutomationFromWorkspaceV2', dataType);
								return Promise.resolve();
							}
					}

					let triggerNames = Object.keys(recordInfo);
					triggerNames.forEach(triggerName => {
						if (triggerName === 'childConfigurations' && ['pages', 'fields'].indexOf(dataType) > -1) {
			
							let updates = [];
							Object.keys(recordInfo[triggerName]).forEach(childFieldId => {
								Object.keys(recordInfo[triggerName][childFieldId]).forEach(childTriggerName => {
									let automationObj = recordInfo[triggerName][childFieldId][childTriggerName];
									if (automationObj && automationObj.logicFunctionsUsedDirectly) {
										let newLogicFunctionsUsed = {};
										let logicFunctionsUsedDirectly = Array.isArray(automationObj.logicFunctionsUsedDirectly) ? automationObj.logicFunctionsUsedDirectly : automationObj.logicFunctionsUsedDirectly.split(',');;
										logicFunctionsUsedDirectly.forEach(recordId => {
											newLogicFunctionsUsed = this.findDescendantFunctions(recordId, newLogicFunctionsUsed);
										})
										automationObj.logicFunctionsUsed = Object.keys(newLogicFunctionsUsed).join(',');
										automationObj.runOnBackend = (automationObj.runOnBackend + '' === 'true') 
											? automationObj.runOnBackend
											: this.getForceBackend(Object.keys(newLogicFunctionsUsed));
										// oldChildConfigurationObj[childFieldId][childTriggerName] = JSON.stringify(automationObj);
										updates.push([recordId, childFieldId, childTriggerName, JSON.stringify(automationObj)]);
									}
								});
							});

							if (dataType === 'fields') {
								updates.forEach(update => {
									FieldActions.pushChildConfigurationToStore(...update);
								});
							} else if (dataType === 'pages') {
								updates.forEach(update => {
									PageActions.pushChildConfigurationToStore(...update);
								});
							}
						} else if (triggerName !== 'childConfigurations') {
							let automationObj = recordInfo[triggerName];
							let newLogicFunctionsUsed = {};
							automationObj.logicFunctionsUsedDirectly = automationObj.logicFunctionsUsedDirectly || '';
							let logicFunctionsUsedDirectly = Array.isArray(automationObj.logicFunctionsUsedDirectly) ? automationObj.logicFunctionsUsedDirectly : automationObj.logicFunctionsUsedDirectly.split(',');;
							logicFunctionsUsedDirectly.forEach(recordId => {
								newLogicFunctionsUsed = this.findDescendantFunctions(recordId, newLogicFunctionsUsed);
							});
							automationObj.logicFunctionsUsed = Object.keys(newLogicFunctionsUsed).join(',');
							automationObj.runOnBackend = (automationObj.runOnBackend + '' === 'true') 
								? automationObj.runOnBackend
								: this.getForceBackend(Object.keys(newLogicFunctionsUsed));
							if (dataType === 'fields') {
								FieldActions.pushSettingToStore(recordId, triggerName, JSON.stringify(automationObj));
							} else if (dataType === 'pages') {
								PageActions.pushAutomationToStore(recordId, triggerName, automationObj);
							} else {
								recordObj[triggerName] = JSON.stringify(automationObj);
							}
						}
					});

					switch (dataType) {
						case 'fields': {
							// FieldActions.pushToStore(recordId, recordObj);
							siblingWorkspaceUpdates.push(FieldActions.pushToDatabasePromise(FieldStore.get(recordId, true)));
							break;
						}
						case 'pages': {
							// PageActions.pushToStore(recordId, recordObj);
							siblingWorkspaceUpdates.push(PageActions.pushToDatabasePromise(PageStore.get(recordId, true)));
							break;
						}
						case 'scheduledActions': {
							MetadataActions.pushToStore(recordId, 'scheduledLogic', recordObj);
							siblingWorkspaceUpdates.push(MetadataActions.pushToDatabasePromise(MetadataStore.get(recordId, 'scheduledLogic', true), 'scheduledLogic'));
							break;
						}
						case 'apiActions': {
							MetadataActions.pushToStore(recordId, 'apiconfig', recordObj);
							siblingWorkspaceUpdates.push(MetadataActions.pushToDatabasePromise(MetadataStore.get(recordId, 'apiconfig', true), 'apiconfig'));
							break;
						}
						default:
							{
								console.warn('Invalid componentType "%s" passed into saveAutomationFromWorkspaceV2', dataType);
								return Promise.resolve();
							}
					}

				});
			});

			let savePromise = Promise.resolve();
			try {
				// Redoing this in case the above work has changed anything on the workspace
				let newWorkspaceInfo = this.getWorkspaceInfo(workspace, params, preventRestAPI);
				savePromise = callback ?
					callback(newWorkspaceInfo) :
					Promise.resolve();
			} catch(err) {
				savePromise = Promise.reject(err);
			}
				
			let functionUpdatePromise = Promise.all(functionSavePromises);
			updatePromise = savePromise.then(() => functionUpdatePromise).then(() => Promise.all(siblingWorkspaceUpdates));
		}

		return new Promise((resolve, reject) => {
			updatePromise
				.then(() => {
					// Avoid disposing the workspace while the workspace is being used
					if(workspace && workspace.needsDisposal) {
						workspace.dispose();
					}
					if(workspace) {
						workspace.saving = false;
					}
					InterfaceActions.clearStickyNotification(umbrellaSaveNotification);
					if(functionNotification) {
						InterfaceActions.clearStickyNotification(functionNotification);
					}
					resolve();
				})
				.catch(error => {
					// Avoid disposing the workspace while the workspace is being used
					if(workspace && workspace.needsDisposal) {
						workspace.dispose();
					}
					if(workspace) {
						workspace.saving = false;
					}
					console.error('Error saving workspace', error);
					InterfaceActions.clearStickyNotification(umbrellaSaveNotification);
					InterfaceActions.clearStickyNotification(functionNotification);
					InterfaceActions.stickyNotification({
						level: 'error',
						message: 'Error when saving: ' + (error && error.message ? error.message : error) + '. Please check your console for more information.'
					});
					reject(error);
				});
		});
	},

	/**
	 * Saves generated code and XML from a workspace directly, instead of the conversion to and from
	 * blockly xml. (Helps improve performance.)
	 * 
	 * @param {string} blocklyxml The XML of the workspace being saved
	 * @param {object} params Parameters controlling how this function runs.
	 * @param {string} params.triggerType The type of the trigger being saved
	 * @param {saveWorkspaceCallback} callback Function which runs on the workspace info generated for this
	 * and saves it in a Promisified fashion. (Allows this code to be easily reused between field/page and scheduled action/API endpoint triggers)
	 * 
	 * @memberof BlocklyUtils
	 */
	saveAutomationFromWorkspaceV2(workspace, params, callback) {
		// If this is a free user, we need to check whether this has any REST API blocks and prevent saving if it is.
		let preventRestAPI = !AuthenticationStore.getHasRestAPILogic();
		let umbrellaSaveNotification = InterfaceActions.stickyNotification({
			'title': 'Saving workspace.',
			'message': 'Please wait...',
			'level': 'info'
		});

		let actionId = uuid.v4();
		ContextActions.addActionId(actionId);

		// let { triggerType } = params;

		// Get any functions used. Because of nested functions which may be dirty,
		// we want to consider ALL functions, not just the ones on the workspace
		let logicFunctionsUsedArr = this.functionsUsedInWorkspace(workspace);

		let dirtyFunctions = [];
		let functionsRequiringRegeneration = [];
		let functionsRequiringUsageRecalculations = [];
		logicFunctionsUsedArr.forEach(functionId => {
			let dirtyRecord = LogicFunctionStore.get(functionId, true);
			// These functions are dirty and should be saved
			if(dirtyRecord && Object.keys(dirtyRecord).length > 1) {
				dirtyFunctions.push(functionId);
			}
			// These function parameters have changed and will require recalculating the
			// js for each direct location
			if(dirtyRecord && dirtyRecord.params) {
				functionsRequiringRegeneration.push(functionId);
			}

			// These functions have changed the logic functions they used
			// and all locations, direct or indirect,
			// must be updated to get the new logicFunctionsUsed
			if(dirtyRecord && (dirtyRecord.logicFunctionsUsed || (dirtyRecord.forceBackend + '' === 'true'))) {
				functionsRequiringUsageRecalculations.push(functionId);
			}
		});

		// Find all locations of functionsRequiringUsageRecalculations

		let siblingWorkspaces = this.findSiblingsToRegen(functionsRequiringUsageRecalculations);
		let functionsToUpdate = this.findAllFunctionsToUpdate(functionsRequiringUsageRecalculations, true);

		// Don't waste time with the functions we're already saving
		functionsToUpdate = functionsToUpdate.filter(funct => dirtyFunctions.indexOf(funct) === -1);
		// Now we need to update these locations in the store and mark them to save later
		// We also need to avoid double-saving if the appropriate trigger appears
		// in multiple locations

		// Okay, the way this logic should work:
		// * Update the sibling + function locations in the store
		// * Identify all metadata which will need to be saved
		// * If we're doing a full regeneration, pass that into processFunctionUpdates
		// and update that function to avoid duplicate updates
		// * Otherwise, update it here as a part of updatePromise 

		let functionNotification;
		let id = uuid.v4();
		let updateCount = 0;
		if(dirtyFunctions && dirtyFunctions.length) {
			functionNotification = InterfaceActions.stickyNotification({
				'title': 'Updating and saving all functions on this workspace to the database',
				'message': updateCount + '/' + (dirtyFunctions.length + functionsToUpdate.length) + '. Please wait...',
				'level': 'info',
				'id': id
			});
		}
		let updatePromise;
		if(functionsRequiringRegeneration.length || preventRestAPI) {
			// Run the full regeneration, because the parameters have changed
			let { triggerType } = params;
			updatePromise = this.processFunctionUpdates(logicFunctionsUsedArr, workspace, params, callback, triggerType, preventRestAPI);
		} else {
			// Just save the workspace and function
			let functionSavePromises = dirtyFunctions.map(functionId => {
				updateCount += 1;
				return LogicFunctionActions.pushToDatabasePromise(LogicFunctionStore.get(functionId, true));
			});

			// If we have any other functionsToUpdate, add them in here
			functionsToUpdate.forEach(recordId => {
				let record = LogicFunctionStore.get(recordId);
				updateCount += 1;
				let newLogicFunctionsUsed = {};
				let logicFunctionsUsedDirectly = [];
				if(record && record.logicFunctionsUsedDirectly) {
					logicFunctionsUsedDirectly = Array.isArray(record.logicFunctionsUsedDirectly) ? record.logicFunctionsUsedDirectly : record.logicFunctionsUsedDirectly.split(',');
				}
				logicFunctionsUsedDirectly.forEach(recordId => {
					newLogicFunctionsUsed = this.findDescendantFunctions(recordId, newLogicFunctionsUsed);
				});
				record.logicFunctionsUsed = Object.keys(newLogicFunctionsUsed).join(',');
				record.forceBackend = (record.forceBackend + '' === 'true') 
					? record.forceBackend
					: this.getForceBackend(Object.keys(newLogicFunctionsUsed));
				LogicFunctionActions.pushToStore(recordId, record);
				functionSavePromises.push(LogicFunctionActions.pushToDatabasePromise(LogicFunctionStore.get(recordId, true)));
			});

			// Now add in any other location updates if we need them
			let siblingWorkspaceUpdates = [];
			Object.keys(siblingWorkspaces).forEach(dataType => {
				// First, loop over each component
				Object.keys(siblingWorkspaces[dataType]).forEach(recordId => {
					// Now for each workspace in this, get the new logicFunctionsUsed
					// Then update the store and push the promise
					// Make sure to skip the update for the current workspace (How?)
					let recordInfo = siblingWorkspaces[dataType][recordId];

					let recordObj = {};

					switch (dataType) {
						case 'fields': {
							recordObj = FieldStore.get(recordId) || {};
							break;
						}
						case 'pages': {
							recordObj = PageStore.get(recordId) || {};
							break;
						}
						case 'scheduledActions': {
							recordObj = MetadataStore.get(recordId, 'scheduledLogic') || {};
							break;
						}
						case 'apiActions': {
							recordObj = MetadataStore.get(recordId, 'apiconfig') || {};
							break;
						}
						default:
							{
								console.warn('Invalid componentType "%s" passed into saveAutomationFromWorkspaceV2', dataType);
								return Promise.resolve();
							}
					}

					let triggerNames = Object.keys(recordInfo);
					triggerNames.forEach(triggerName => {
						if (triggerName === 'childConfigurations' && ['pages', 'fields'].indexOf(dataType) > -1) {
			
							let updates = [];
							Object.keys(recordInfo[triggerName]).forEach(childFieldId => {
								Object.keys(recordInfo[triggerName][childFieldId]).forEach(childTriggerName => {
									let automationObj = recordInfo[triggerName][childFieldId][childTriggerName];
									if (automationObj && automationObj.logicFunctionsUsedDirectly) {
										let newLogicFunctionsUsed = {};
										let logicFunctionsUsedDirectly = Array.isArray(automationObj.logicFunctionsUsedDirectly) ? automationObj.logicFunctionsUsedDirectly : automationObj.logicFunctionsUsedDirectly.split(',');;
										logicFunctionsUsedDirectly.forEach(recordId => {
											newLogicFunctionsUsed = this.findDescendantFunctions(recordId, newLogicFunctionsUsed);
										})
										automationObj.logicFunctionsUsed = Object.keys(newLogicFunctionsUsed).join(',');
										automationObj.runOnBackend = (automationObj.runOnBackend + '' === 'true') 
											? automationObj.runOnBackend
											: this.getForceBackend(Object.keys(newLogicFunctionsUsed));
										// oldChildConfigurationObj[childFieldId][childTriggerName] = JSON.stringify(automationObj);
										updates.push([recordId, childFieldId, childTriggerName, JSON.stringify(automationObj)]);
									}
								});
							});

							if (dataType === 'fields') {
								updates.forEach(update => {
									FieldActions.pushChildConfigurationToStore(...update);
								});
							} else if (dataType === 'pages') {
								updates.forEach(update => {
									PageActions.pushChildConfigurationToStore(...update);
								});
							}
						} else if (triggerName !== 'childConfigurations') {
							let automationObj = recordInfo[triggerName];
							let newLogicFunctionsUsed = {};
							automationObj.logicFunctionsUsedDirectly = automationObj.logicFunctionsUsedDirectly || '';
							let logicFunctionsUsedDirectly = Array.isArray(automationObj.logicFunctionsUsedDirectly) ? automationObj.logicFunctionsUsedDirectly : automationObj.logicFunctionsUsedDirectly.split(',');;
							logicFunctionsUsedDirectly.forEach(recordId => {
								newLogicFunctionsUsed = this.findDescendantFunctions(recordId, newLogicFunctionsUsed);
							});
							automationObj.logicFunctionsUsed = Object.keys(newLogicFunctionsUsed).join(',');
							automationObj.runOnBackend = (automationObj.runOnBackend + '' === 'true') 
								? automationObj.runOnBackend
								: this.getForceBackend(Object.keys(newLogicFunctionsUsed));
							if (dataType === 'fields') {
								FieldActions.pushSettingToStore(recordId, triggerName, JSON.stringify(automationObj));
							} else if (dataType === 'pages') {
								PageActions.pushAutomationToStore(recordId, triggerName, automationObj);
							} else {
								recordObj[triggerName] = JSON.stringify(automationObj);
							}
						}
					});

					switch (dataType) {
						case 'fields': {
							// FieldActions.pushToStore(recordId, recordObj);
							siblingWorkspaceUpdates.push(FieldActions.pushToDatabasePromise(FieldStore.get(recordId, true)));
							break;
						}
						case 'pages': {
							// PageActions.pushToStore(recordId, recordObj);
							siblingWorkspaceUpdates.push(PageActions.pushToDatabasePromise(PageStore.get(recordId, true)));
							break;
						}
						case 'scheduledActions': {
							MetadataActions.pushToStore(recordId, 'scheduledLogic', recordObj);
							siblingWorkspaceUpdates.push(MetadataActions.pushToDatabasePromise(MetadataStore.get(recordId, 'scheduledLogic', true), 'scheduledLogic'));
							break;
						}
						case 'apiActions': {
							MetadataActions.pushToStore(recordId, 'apiconfig', recordObj);
							siblingWorkspaceUpdates.push(MetadataActions.pushToDatabasePromise(MetadataStore.get(recordId, 'apiconfig', true), 'apiconfig'));
							break;
						}
						default:
							{
								console.warn('Invalid componentType "%s" passed into saveAutomationFromWorkspaceV2', dataType);
								return Promise.resolve();
							}
					}

				});
			});
	
			let savePromise = Promise.resolve();
			try {
				let newWorkspaceInfo = this.getWorkspaceInfo(workspace, params, preventRestAPI);
				savePromise = callback ?
					callback(newWorkspaceInfo) :
					Promise.resolve();
			} catch(err) {
				savePromise = Promise.reject(err);
			}
				
			let functionUpdatePromise = Promise.all(functionSavePromises);
			updatePromise = Promise.all([savePromise, functionUpdatePromise]).then(() => Promise.all(siblingWorkspaceUpdates));
		}

		return new Promise((resolve, reject) => {
			updatePromise
				.then(() => {
					// Avoid disposing the workspace while the workspace is being used
					if(workspace && workspace.needsDisposal) {
						workspace.dispose();
					}
					if(workspace) {
						workspace.saving = false;
					}
					InterfaceActions.clearStickyNotification(umbrellaSaveNotification);
					if(functionNotification) {
						InterfaceActions.clearStickyNotification(functionNotification);
					}
					resolve();
				})
				.catch(error => {
					// Avoid disposing the workspace while the workspace is being used
					if(workspace && workspace.needsDisposal) {
						workspace.dispose();
					}
					if(workspace) {
						workspace.saving = false;
					}
					console.error('Error saving workspace', error);
					InterfaceActions.clearStickyNotification(umbrellaSaveNotification);
					InterfaceActions.clearStickyNotification(functionNotification);
					InterfaceActions.stickyNotification({
						level: 'error',
						message: 'Error when saving: ' + (error && error.message ? error.message : error) + '. Please check your console for more information.'
					});
					reject(error);
				});
		});
	},

	// XML/Workspace conversion and JS generation
	/**
	 * 
	 * Converts XML to Blockly workspace.
	 * 
	 * @param {string} blocklyxml XML of a Blockly workspace
	 * 
	 * @returns {Blockly.Workspace} The workspace generated
	 * @memberof BlocklyUtils
	 */
	getWorkspaceFromXml(blocklyxml) {
		let workspace = new Blockly.Workspace();
		if (!blocklyxml) {
			return workspace;
		}

		// Non-SVG workspaces can have a problem if Blockly.theme_ has yet to be set.
		if (Blockly.setTheme && Blockly.Themes && Blockly.Themes.CitDevFiveColor && !Blockly.theme_) {
			Blockly.theme_ = Blockly.Themes.CitDevFiveColor;
		}

		// Blockly wants everything to start with <xml> tags now
		blocklyxml = blocklyxml.startsWith('<xml') ? blocklyxml : '<xml>' + blocklyxml + '</xml>';
		Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(blocklyxml), workspace);

		return workspace;
	},

	/**
	 * 
	 * Converts XML to Blockly workspace.
	 * 
	 * @param {string} blocklyxml XML of a Blockly workspace
	 * 
	 * @returns {Blockly.Workspace} The workspace generated
	 * @memberof BlocklyUtils
	 */
	getWorkspaceFromXmlPromise(blocklyxml) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {

				try {
					let workspace = new Blockly.Workspace();
					if (!blocklyxml) {
						return resolve(workspace);
					}

					// Non-SVG workspaces can have a problem if Blockly.theme_ has yet to be set.
					if (Blockly.setTheme && Blockly.Themes && Blockly.Themes.CitDevFiveColor && !Blockly.theme_) {
						Blockly.theme_ = Blockly.Themes.CitDevFiveColor;
					}

					// Blockly wants everything to start with <xml> tags now
					blocklyxml = blocklyxml.startsWith('<xml') ? blocklyxml : '<xml>' + blocklyxml + '</xml>';
					Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(blocklyxml), workspace);

					return resolve(workspace);
				} catch (err) {
					console.warn('Error when getting workspace from XML:', err);
					reject(err);
				}
			}, 0);
		});
	},

	/**
	 * 
	 * Converts XML to Blockly workspace.
	 * 
	 * @param {Blockly.Workspace} workspace Blockly workspace for which to generate XML 
	 * @param {string} kind The kind of metadata for the workspace (used to know which method to use for conversion,
	 * as functions use blockToDom and all others use workspaceToDom)
	 * 
	 * @returns {string} The XML generated
	 * @memberof BlocklyUtils
	 */
	getXmlFromWorkspace(workspace, kind) {
		if (!workspace) {
			return '';
		}

		if (kind === 'logicFunctions') {
			let topBlock = workspace.getTopBlocks()[0];
			if (!topBlock) {
				return '';
			}
			return Blockly.Xml.domToText(Blockly.Xml.blockToDom(topBlock));
		} else {
			return Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(workspace));
		}
	},

	/**
	 * Converts Blockly workspace to JavaScript
	 * 
	 * @param {Blockly.workspace} workspace Blockly workspace to convert to JavaScript
	 * @memberof BlocklyUtils
	 */
	getJsFromWorkspace(workspace, preventRestAPI, options) {
		if(preventRestAPI) {
			// Check to see if we have a REST API block. If we do, forbid it.
			let restApiBlocks = workspace.getBlocksByType('webServices');
			if(restApiBlocks && restApiBlocks.length) {
				throw new Error('Free accounts are not permitted to add or change logic with outbound API calls. Please upgrade your account.');
			}
		}
		Blockly.JavaScript.init(workspace);
		return options && options.kind === 'logicFunctions' ? Blockly.JavaScript.functionWorkspaceToCode(workspace, options.paramsArr, options.recordId, options.title) : Blockly.JavaScript.workspaceToCode(workspace);
	},

	// Update metadata with new function information

	/**
	 * Helper method for regenerateComponentWorkspace. Used to wrap parameters based on datatype, etc.
	 * 
	 * @param {string} dataType The type of the component whose automation is being updated
	 * (page, field, API endpoint or scheduled logic trigger)
	 * @param {object} oldAutomation The old automation being regenerated
	 * @memberof BlocklyUtils
	 */
	regenerateAutomationObj(dataType, oldAutomation) {
		let { blocklyxml, runOnBackend } = oldAutomation;
		let params = (['pages', 'fields'].indexOf(dataType) > -1) ? {
			defaultToNull: true,
			includeJs: true,
			checkRunOnBackend: true,
			runOnBackend: runOnBackend
		} : {
				includeJs: true
			};

		let workspace = this.getWorkspaceFromXml(blocklyxml);
		workspace = this.updateFunctionsInWorkspace(workspace);
		let newAutomation = this.getWorkspaceInfo(workspace, params);
		return Object.assign({}, oldAutomation, newAutomation);
	},

	/**
	 * Helper method for regenerateComponentWorkspace. Used to wrap parameters based on datatype, etc. Promisified version
	 * 
	 * @param {string} dataType The type of the component whose automation is being updated
	 * (page, field, API endpoint or scheduled logic trigger)
	 * @param {object} oldAutomation The old automation being regenerated
	 * @memberof BlocklyUtils
	 */
	regenerateAutomationObjPromise(dataType, oldAutomation) {
		let { blocklyxml, runOnBackend } = oldAutomation;
		let params = (['pages', 'fields'].indexOf(dataType) > -1) ? {
			defaultToNull: true,
			includeJs: true,
			checkRunOnBackend: true,
			runOnBackend: runOnBackend
		} : {
				includeJs: true
			};

		return new Promise((resolve, reject) => {
			this.getWorkspaceFromXmlPromise(blocklyxml)
				.then(workspace => {
					// Backwards compatibility for old functions, though they really should start replacing these already
					workspace = this.updateFunctionsInWorkspace(workspace);
					let newAutomation = this.getWorkspaceInfo(workspace, params);
					return resolve(Object.assign({}, oldAutomation, newAutomation));
				})
				.catch(reject);
		});
	},

	/**
	 * Regenerates the logic and updates the triggers on a component in the store,
	 * but does not save to the DB.
	 * 
	 * @param {string} dataType The type of the component whose information is being updated
	 * (page, field, API endpoint or scheduled logic trigger)
	 * @param {string} recordId The recordId of the component being updated
	 * @param {object} recordInfo Preprocessed information about the record
	 * (used for easier updating and acquisition of information)
	 * @memberof BlocklyUtils
	 */
	regenerateTriggersOnComponent(dataType, recordId, recordInfo) {
		return new Promise((resolve, reject) => {
			let recordObj = {};
			let triggerNames = Object.keys(recordInfo);

			if(dataType === 'logicFunctions') {
				try {
					let automationObj = recordInfo;
					let {blocklyxml, params} = automationObj;
					let workspace = this.getWorkspaceFromXml(blocklyxml);
					automationObj.js = workspace ? Blockly.JavaScript.functionWorkspaceToCode(workspace, params ? JSON.parse(params) : [], recordId, recordObj.name) : '';
					
					LogicFunctionActions.pushToStore(recordId, automationObj);
					resolve();
				} catch (error) {
					console.error('Error regenerating automationObj', error);
					reject(error);
				}
			} else {
				let triggerUpdatePromises = triggerNames.map(triggerName => {
					return new Promise((resolve, reject) => {
						// Update all child configuration automation objects
						if(triggerName === 'childConfigurations' && ['pages', 'fields'].indexOf(dataType) > -1) {
							let updates = [];
	
						let childUpdatePromises = Object.keys(recordInfo[triggerName]).map(childFieldId => {
							let keyUpdatePromises = Object.keys(recordInfo[triggerName][childFieldId]).map(childTriggerName => {
								return new Promise((resolve, reject) => {
									let automationObj = recordInfo[triggerName][childFieldId][childTriggerName];
									// Skip automation object stuff for anything which has no blocklyxml
									// (whether because it's missing or because it's not an automation setting)
									if (automationObj && automationObj.blocklyxml) {
										this.regenerateAutomationObjPromise(dataType, automationObj)
											.then(automationObj => {
												updates.push([recordId, childFieldId, childTriggerName, JSON.stringify(automationObj)]);
												// oldChildConfigurationObj[childFieldId][childTriggerName] = JSON.stringify(automationObj);
												resolve();
											})
											.catch(reject);
									} else {
										resolve();
									}
								});
							});
							return Promise.all(keyUpdatePromises);
						});
	
						Promise.all(childUpdatePromises)
							.then(() => {
								if (dataType === 'fields') {
									updates.forEach(update => {
										FieldActions.pushChildConfigurationToStore(...update);
									});
								} else if (dataType === 'pages') {
									updates.forEach(update => {
										PageActions.pushChildConfigurationToStore(...update);
									});
								}
								resolve();
							})
							.catch(reject);
						} else if (triggerName !== 'childConfigurations') {
							// Update all other automation
							let automationObj = recordInfo[triggerName];
							this.regenerateAutomationObjPromise(dataType, automationObj)
								.then(automationObj => {
									if (dataType === 'fields') {
										FieldActions.pushSettingToStore(recordId, triggerName, JSON.stringify(automationObj));
									} else if (dataType === 'pages') {
										PageActions.pushAutomationToStore(recordId, triggerName, automationObj);
									} else {
										recordObj[triggerName] = JSON.stringify(automationObj);
									}
									resolve();
								})
								.catch(reject);
						}
					});
				});
	
				return Promise.all(triggerUpdatePromises)
					.then(() => {
						if(['fields', 'pages'].indexOf(dataType) === -1) {
							let componentType = (dataType === 'scheduledActions') ? 'scheduledLogic' : 'apiconfig';
							MetadataActions.pushToStore(recordId, componentType, recordObj);
						}
						return resolve(recordObj);
					})
					.catch(reject);
			}

		});
	},

	/**
	 * Function to regenerate a value in a Field, Page, Scheduled Action or API Endpoint trigger
	 * and save it to the store.
	 * 
	 * @param {string} dataType The type of the component whose information is being updated
	 * (page, field, API endpoint or scheduled logic trigger)
	 * @param {string} recordId The recordId of the component being updated
	 * @param {object} recordInfo Preprocessed information about the record
	 * (used for easier updating and acquisition of information)
	 * @memberof BlocklyUtils
	 */
	regenerateComponentWorkspace(dataType, recordId, recordInfo) {

		return this.regenerateTriggersOnComponent(dataType, recordId, recordInfo)
			.then(() => {
				if (dataType === 'fields') {
					let toPush = FieldStore.get(recordId, true);
					return FieldActions.pushToDatabasePromise(toPush);
				} else if (dataType === 'pages') {
					return PageActions.pushToDatabasePromise(PageStore.get(recordId, true));
				} else {
					let componentType = (dataType === 'scheduledActions') ? 'scheduledLogic' : 'apiconfig';
					let metaObj = MetadataStore.get(recordId, componentType, true);
					return MetadataActions.pushToDatabasePromise(metaObj, componentType);
				}
			});
	},

	/**
	 * Function to delete a value in a Field, Page, Scheduled Action or API Endpoint trigger
	 * and save it to the store. Similar to regenerateComponentWorkspace, but deletion-focused
	 * 
	 * @param {string} dataType The type of the component whose information is being updated
	 * (page, field, API endpoint or scheduled logic trigger)
	 * @param {string} recordId The recordId of the component being updated
	 * @param {object} recordInfo Preprocessed information about the record
	 * (used for easier updating and acquisition of information)
	 * @param {array} functionsToDelete The functions to delete
	 * @memberof BlocklyUtils
	 */
	deleteFunctionsInAutomationObj(dataType, oldAutomation, functionsToDelete) {
		let { blocklyxml, runOnBackend } = oldAutomation;
		let params = (['pages', 'fields'].indexOf(dataType) > -1) ? {
			defaultToNull: true,
			includeJs: true,
			checkRunOnBackend: true,
			runOnBackend: runOnBackend
		} : {
				includeJs: true
			};

		let workspace = this.getWorkspaceFromXml(blocklyxml);
		workspace = this.deleteFunctionsInWorkspace(workspace, functionsToDelete);

		let newAutomation = this.getWorkspaceInfo(workspace, params);
		return Object.assign({}, oldAutomation, newAutomation);

	},
	/**
	 * Helper method for deleteFunctionsInAutomationObj. Used to wrap parameters based on datatype, etc.
	 * Similar to regenerateComponentWorkspace, but deletion-focused
	 * 
	 * @param {string} dataType The type of the component whose information is being updated
	 * (page, field, API endpoint or scheduled logic trigger)
	 * @param {string} recordId The recordId of the component being updated
	 * @param {object} recordInfo Preprocessed information about the record
	 * (used for easier updating and acquisition of information)
	 * @memberof BlocklyUtils
	 */
	deleteFunctionsInComponent(dataType, functionsToDelete, recordId, recordInfo) {
		let recordObj = {};

		switch (dataType) {
			case 'fields': {
				recordObj = FieldStore.get(recordId) || {};
				break;
			}
			case 'pages': {
				recordObj = PageStore.get(recordId) || {};
				break;
			}
			case 'scheduledActions': {
				recordObj = MetadataStore.get(recordId, 'scheduledLogic') || {};
				break;
			}
			case 'apiActions': {
				recordObj = MetadataStore.get(recordId, 'apiconfig') || {};
				break;
			}
			default:
				{
					console.warn('Invalid dataType %s passed into regenerateComponentWorkspace', dataType);
					return Promise.resolve();
				}
		}
		let triggerNames = Object.keys(recordInfo);
		triggerNames.forEach(triggerName => {
			if (triggerName === 'childConfigurations' && ['pages', 'fields'].indexOf(dataType) > -1) {

				let updates = [];
				Object.keys(recordInfo[triggerName]).forEach(childFieldId => {
					Object.keys(recordInfo[triggerName][childFieldId]).forEach(childTriggerName => {
						let automationObj = recordInfo[triggerName][childFieldId][childTriggerName];
						if (automationObj && automationObj.blocklyxml) {
							automationObj = this.deleteFunctionsInAutomationObj(dataType, automationObj, functionsToDelete);
							// oldChildConfigurationObj[childFieldId][childTriggerName] = JSON.stringify(automationObj);
							updates.push([recordId, childFieldId, childTriggerName, JSON.stringify(automationObj)]);
						}
					});
				});

				if (dataType === 'fields') {
					updates.forEach(update => {
						FieldActions.pushChildConfigurationToStore(...update);
					});
				} else if (dataType === 'pages') {
					updates.forEach(update => {
						PageActions.pushChildConfigurationToStore(...update);
					});
				}
			} else if (triggerName !== 'childConfigurations') {
				let automationObj = recordInfo[triggerName];
				automationObj = this.deleteFunctionsInAutomationObj(dataType, automationObj, functionsToDelete);
				if (dataType === 'fields') {
					FieldActions.pushSettingToStore(recordId, triggerName, JSON.stringify(automationObj));
				} else if (dataType === 'pages') {
					PageActions.pushAutomationToStore(recordId, triggerName, automationObj);
				} else {
					recordObj[triggerName] = JSON.stringify(automationObj);
				}
			}
		});

		if (dataType === 'fields') {
			let toPush = FieldStore.get(recordId, true);
			return FieldActions.pushToDatabasePromise(toPush);
		} else if (dataType === 'pages') {
			return PageActions.pushToDatabasePromise(PageStore.get(recordId, true));
		} else {
			let componentType = (dataType === 'scheduledActions') ? 'scheduledLogic' : 'apiconfig';
			MetadataActions.pushToStore(recordId, componentType, recordObj);
			return MetadataActions.pushToDatabasePromise(MetadataStore.get(recordId, componentType, true), componentType);
		}
	},

	/**
	 * Helper function to find all metaData types which use any of a given set of functionIds and update the functions in them
	 * 
	 * @param {array} logicFunctionIds Find and update all sibling metadata types using these function IDs
	 * @param {string} triggerType The type of trigger from which this is being run (used to determine whether to update the API endpoint or not)
	 * 
	 * @returns {Promise}
	 * @memberof BlocklyUtils
	 */
	regenerateFunctionInstancesInComponents(siblingsToRegen) {

		// Regenerate all of the Blockly workspaces and update the store
		// (This is throttled due to the blocking and computation-heavy nature of regenerating Blockly workspaces)
		let workspaceRegenPromise = new Promise((resolve, reject) => {

			let updatedCount = 0;
			let id = uuid.v4();
			// let siblingUpdatePromises = [];
			let updates = [];
			Object.keys(siblingsToRegen).forEach(dataType => {
				// If the data type is a logic function
				if(dataType === 'logicFunction') {
				} else {
					let valuesToUpdate = Object.keys(siblingsToRegen[dataType]);
					valuesToUpdate.forEach(recordId => {
						let recordInfo = siblingsToRegen[dataType][recordId];
						updates.push({dataType, recordId, recordInfo});
					});
				}
			});
			let siblingUpdateNotification = InterfaceActions.stickyNotification({
				level: 'info',
				message: 'Updating other function instances: ' + updatedCount + '/' + updates.length + '...',
				id: id
			});

			let queue = async.queue((update, done) => {
				this.regenerateTriggersOnComponent(update.dataType, update.recordId, update.recordInfo)
					.then(() => {
						updatedCount++;
						InterfaceActions.clearStickyNotification(siblingUpdateNotification);
						siblingUpdateNotification = InterfaceActions.stickyNotification({
							level: 'info',
							message: 'Updating other function instances: ' + updatedCount + '/' + updates.length + '...',
							id: id
						});
						done();
					})
					.catch(error => {
						console.error('Error in update', update, error);
						done(error);
					});
			}, 1);

			let hasErred = false;

			updates.forEach((update) => {

				queue.push(update, error => {
					if(error) {
						console.error('Error in queue', error);
						hasErred = true;
						InterfaceActions.clearStickyNotification(siblingUpdateNotification);
						reject(error);
					}
				});

			});

			if(!queue.length() && !queue.running()) {
				if(!hasErred) {
					InterfaceActions.clearStickyNotification(siblingUpdateNotification);
					resolve();
				}
			} else {
				queue.drain = () => {
					if(!hasErred) {
						InterfaceActions.clearStickyNotification(siblingUpdateNotification);
						resolve();
					}
				}
			}
		});

		// Now actually save this to the database
		let dbUpdateNotification;
		return new Promise((resolve, reject) => {
			workspaceRegenPromise
				.then(() => {
					let id = uuid.v4();
					dbUpdateNotification = InterfaceActions.stickyNotification({
						level: 'info',
						message: 'Saving other function instances to the database...',
						id: id
					});
					let updatePromises = [];
					Object.keys(siblingsToRegen).forEach(dataType => {
						let valuesToUpdate = Object.keys(siblingsToRegen[dataType]);
						valuesToUpdate.forEach(recordId => {
							if (dataType === 'fields') {
								let toPush = FieldStore.get(recordId, true);
								updatePromises.push(FieldActions.pushToDatabasePromise(toPush));
							} else if (dataType === 'pages') {
								updatePromises.push(PageActions.pushToDatabasePromise(PageStore.get(recordId, true)));
							} else if (dataType === 'logicFunctions') {
								updatePromises.push(LogicFunctionActions.pushToDatabasePromise(LogicFunctionStore.get(recordId, true)));
							} else {
								let componentType = (dataType === 'scheduledActions') ? 'scheduledLogic' : 'apiconfig';
								let metaObj = MetadataStore.get(recordId, componentType, true);
								updatePromises.push(MetadataActions.pushToDatabasePromise(metaObj, componentType));
							}
						});
					});
					return Promise.all(updatePromises);
				})
				.then(() => {
					InterfaceActions.clearStickyNotification(dbUpdateNotification);
					resolve();
				})
				.catch(reject);
		})
	},

	/**
	 * Helper function to find all metaData types with logic triggers which use any of a given set of functionIds and delete the functions in them
	 * 
	 * @param {array} functionsToDelete Functions to be deleted
	 * 
	 * @returns {Promise}
	 * @memberof BlocklyUtils
	 */
	deleteFunctionInstances(functionsToDelete) {
		let actionId = uuid.v4();
		ContextActions.addActionId(actionId);

		if (!functionsToDelete || !functionsToDelete.length) {
			return Promise.resolve();
		}
		let functionsRegenNotification = InterfaceActions.stickyNotification({
			'title': 'Deleting ' + (functionsToDelete && functionsToDelete.length > 1 ? 'functions' : 'function') + ' from other functions.',
			'message': 'Please wait...',
			'level': 'info'
		});
		let deleteInFunctionsObj = this.findAllParentFunctions(functionsToDelete, {});
		let deleteInFunctions = Object.keys(deleteInFunctionsObj);

		// Delete the function in the store
		deleteInFunctions.forEach(this.deleteFunctionsFromFunction.bind(this, functionsToDelete));

		// Now find the parents of all of the affected functions and update them too.
		// (We don't need to include the children)
		let allAffectedFunctions = this.findAllFunctionsToUpdate(deleteInFunctions, true);

		// Update the store for all affected functions
		allAffectedFunctions.forEach(this.updateFunction.bind(this));

		InterfaceActions.clearStickyNotification(functionsRegenNotification);
		let allFunctionsUpdatedPromise = Promise.resolve();
		if (allAffectedFunctions && allAffectedFunctions.length) {
			let functionsSavingNotification = InterfaceActions.stickyNotification({
				'title': 'Saving updated ' + (functionsToDelete && functionsToDelete.length > 1 ? 'functions' : 'function') + ' to the database.',
				'message': 'Please wait...',
				'level': 'info'
			});
			let functionUpdatePromises = allAffectedFunctions.map(functionId => {
				let logicFunctionRecord = LogicFunctionStore.get(functionId, true);
				// The record ID is automatically included, so the length is more than 1 if there are any dirty values to save
				if (logicFunctionRecord && Object.keys(logicFunctionRecord).length > 1) {
					logicFunctionRecord.recordId = functionId;
					return LogicFunctionActions.pushToDatabasePromise(logicFunctionRecord);
				} else {
					return Promise.resolve();
				}
			});

			allFunctionsUpdatedPromise = new Promise((resolve, reject) => {
				Promise.all(functionUpdatePromises)
					.then(() => {
						InterfaceActions.clearStickyNotification(functionsSavingNotification);
						resolve();
					})
					.catch((error) => {
						console.error('Error when saving functions to the database', error);
						InterfaceActions.clearStickyNotification(functionsSavingNotification);
						InterfaceActions.stickyNotification({
							level: 'error',
							message: 'Error when saving functions to the database. Please check your console for more information.'
						});
						reject(error);
					});
			});
		}

		let siblingsToRegen = this.findSiblingsToRegen(allAffectedFunctions);

		let siblingUpdatePromises = this.deleteFunctionInstancesInComponents(siblingsToRegen, functionsToDelete);
		let otherWorkspacesToUpdatePromise = new Promise((resolve, reject) => {
			siblingUpdatePromises.then(() => {
				// Regenerate API triggers if any API endpoints are updated and the 'parent' trigger will not do that already
				if (Object.keys(siblingsToRegen.apiActions).length) {
					let updateNotification = InterfaceActions.stickyNotification({
						'title': 'Function detected in API triggers.',
						'message': 'Updating API endpoints...',
						'level': 'warning'
					});
					return new Promise(function (resolve, reject) {
						fetch(ContextStore.getBasePath() + '/gw/updateRouter', {
							method: 'GET',
							headers: {
								'Content-Type': 'application/json; charset=UTF-8'
							}
						}).then(function (response) {
							InterfaceActions.clearStickyNotification(updateNotification);
							// InterfaceActions.notification({
							// 	level: 'success',
							// 	message: 'API endpoint updated with changes'
							// });
							resolve();
						}).catch(function (error) {
							InterfaceActions.notification({
								level: 'error',
								message: 'API endpoint update failed. ' +
									'Review the console for more information. ' +
									'You may navigate to /gw/updateRouter to update your router manually.'
							});
							console.error('API endpoint update failed:', error);
							reject(error);
						});
					});
				} else {
					return null;
				}
			})
				.then(() => {
					resolve();
				})
				.catch(error => {
					console.error('Error when saving other locations to the database', error);
					InterfaceActions.stickyNotification({
						level: 'error',
						message: 'Error when saving other locations to the database. Please check your console for more information.'
					});
					reject(error);
				});
		});

		return new Promise((resolve, reject) => {
			Promise.all([allFunctionsUpdatedPromise, otherWorkspacesToUpdatePromise])
				.then(() => {
					let functionDeletionNotification = InterfaceActions.stickyNotification({ level: 'info', message: 'Deleting ' + (functionsToDelete.length > 1 ? 'functions' : 'function') + '...' });
					let deletionPromises = functionsToDelete.map(logicFunctionId => {
						return LogicFunctionActions.deleteFromDatabasePromise(logicFunctionId).catch(error => {
							LogicFunctionActions.deleteFromStore(logicFunctionId);
							console.error('Error deleting function %s', logicFunctionId, error);
						});
					});
					return Promise.all(deletionPromises).then(() => {
						InterfaceActions.clearStickyNotification(functionDeletionNotification);
					});
				})
				.then(() => {
					ContextActions.removeActionId(actionId);
					resolve();
				})
				.catch(error => {
					ContextActions.removeActionId(actionId);
					reject(error);
				});
		});
	},

	/**
	 * Updates a function (e.g. regenerates any functions within it and saves it to the store)
	 * 
	 * @param {string} functionId The function being updated
	 * @memberof BlocklyUtils
	 */
	updateFunction(functionId) {
		let functionRecord = LogicFunctionStore.get(functionId) || {};
		let { blocklyxml, params } = functionRecord;
		let workspace = this.getWorkspaceFromXml(blocklyxml);
		workspace = this.updateFunctionsInWorkspace(workspace);
		let newInformation = this.getWorkspaceInfo(workspace, {
			includeJs: false,
			kind: 'logicFunctions'
		});
		newInformation.js = workspace ? Blockly.JavaScript.functionWorkspaceToCode(workspace, JSON.parse(params), functionId, functionRecord.name) : '';
		Object.assign(functionRecord, newInformation);
		LogicFunctionActions.pushToStore(functionId, functionRecord);
		return functionId;
	},

	/**
	 * Deletes one or more functions wtihin a function
	 * (e.g. collapses and disables all references to the functions being deleted
	 * and saves the function to the store)
	 * 
	 * @param {string} functionId The function being updated
	 * @memberof BlocklyUtils
	 */
	deleteFunctionsFromFunction(functionsToDelete, functionId) {
		let functionRecord = LogicFunctionStore.get(functionId) || {};
		let { blocklyxml, params } = functionRecord;
		let workspace = this.getWorkspaceFromXml(blocklyxml);

		workspace = this.deleteFunctionsInWorkspace(workspace, functionsToDelete);

		// Now get the new automation information
		let newInformation = this.getWorkspaceInfo(workspace, {
			includeJs: false,
			kind: 'logicFunctions'
		});

		newInformation.js = workspace ? Blockly.JavaScript.functionWorkspaceToCode(workspace, params ? JSON.parse(params) : [], functionId, functionRecord.name) : '';
		Object.assign(functionRecord, newInformation);
		LogicFunctionActions.pushToStore(functionId, functionRecord);
		return functionId;
	},

	/**
	 * Function to delete all functions within a set of components
	 * 
	 * @param {object} siblingsToRegen Sibling components in which to delete functions
	 * @param {array} functionsToDelete Functions to delete in component workspaces
	 * @memberof BlocklyUtils
	 */
	deleteFunctionInstancesInComponents(siblingsToRegen, functionsToDelete) {

		let updatedCount = 0;
		let id = uuid.v4();
		let siblingUpdatePromises = [];
		let updates = [];

		Object.keys(siblingsToRegen).forEach(dataType => {
			let valuesToUpdate = Object.keys(siblingsToRegen[dataType]);
			valuesToUpdate.forEach(recordId => {
				let recordInfo = siblingsToRegen[dataType][recordId];
				updates.push([dataType, functionsToDelete, recordId, recordInfo]);
			});
		});

		let siblingUpdateNotification = InterfaceActions.stickyNotification({
			level: 'info',
			message: 'Updating other function instances: ' + updatedCount + '/' + updates.length + '...',
			id: id
		});

		updates.forEach(update => {
			let toPush = new Promise((resolve, reject) => {
				this.deleteFunctionsInComponent(...update)
					.then(() => {
						updatedCount++;
						siblingUpdateNotification = InterfaceActions.stickyNotification({
							level: 'info',
							message: 'Updating other function instances: ' + updatedCount + '/' + updates.length + '...',
							id: id
						});
						resolve();
					})
					.catch(reject);
			});
			siblingUpdatePromises.push(toPush);
		});

		// Object.keys(siblingsToRegen).forEach(dataType => {
		// 	let valuesToUpdate = Object.keys(siblingsToRegen[dataType]);
		// 	valuesToUpdate.forEach(recordId => {
		// 		let recordInfo = siblingsToRegen[dataType][recordId];
		// 		siblingUpdatePromises.push(this.deleteFunctionsInComponent(dataType, functionsToDelete, recordId, recordInfo));
		// 	});
		// });

		return new Promise((resolve, reject) => {
			Promise.all(siblingUpdatePromises)
				.then(() => {
					InterfaceActions.clearStickyNotification(siblingUpdateNotification);
					resolve();
				})
				.catch(error => {
					InterfaceActions.clearStickyNotification(siblingUpdateNotification);
					reject(error);
				});
		});
	},

	/**
	 * Helper function to find the functions used within non-logic workspaces.
	 * 
	 * We expect this will only run after all functions have had their child functions updated,
	 * so to save time, we read collapsed function information from the function store.
	 * 
	 * @param {object} workspace
	 * @memberof BlocklyUtils
	 */
	functionsUsedInWorkspace(workspace) {
		let toReturn = [];
		if (!workspace) {
			return toReturn;
		}

		// Find initial functions used in workspace
		let aggregator = {};
		let blocks = workspace.getAllBlocks();
		blocks.forEach(block => {
			if ((block.type === 'function' || block.type === 'function_v2') && block.functionId && !aggregator[block.functionId]) {
				let functionRecord = LogicFunctionStore.get(block.functionId);
				aggregator[block.functionId] = functionRecord;
			}
		});

		// The above does not account for child functions of collapsed functions; use a utility method to find them.
		let logicFunctionsUsed = Object.keys(aggregator);
		logicFunctionsUsed.forEach(functionId => {
			aggregator = this.readLogicFunctionsUsed(functionId, aggregator);
		});

		// Return all functions used
		return Object.keys(aggregator);
	},

	/**
	 * 
	 * @param {*} workspace 
	 */
	functionsDirectlyInLogicWorkspace(workspace) {
		let toReturn = [];
		if (!workspace) {
			return toReturn;
		}

		let logicFunctionsObj = {};
		let blocks = workspace.getAllBlocks();
		blocks.forEach(block => {
			if ((block.type === 'function' || block.type === 'function_v2') && block.functionId && !logicFunctionsObj[block.functionId]) {
				let functionRecord = LogicFunctionStore.get(block.functionId);
				logicFunctionsObj[block.functionId] = functionRecord;
			}
		});

		// Find initial functions used in workspace
		let logicFunctionsUsed = Object.keys(logicFunctionsObj);

		return logicFunctionsUsed;
	},

	/**
	 * Helper function to find the functions used within logic workspaces.
	 * 
	 * Because this is running as functions are having their child functions updated,
	 * we cannot trust the function store, and so regenerate the functions used for every
	 * child function within a collapsed function block.
	 * 
	 * @param {Blockly.Workspace} Blockly Workspace
	 * @param {object} aggregator Tracks which functions have been calculated already
	 * @memberof BlocklyUtils
	 */
	functionsUsedInLogicWorkspace(workspace, aggregator) {
		aggregator = aggregator ? aggregator : {};
		let toReturn = [];
		if (!workspace) {
			return toReturn;
		}

		// Find initial functions used in workspace
		let logicFunctionsUsed = this.functionsDirectlyInLogicWorkspace(workspace);

		logicFunctionsUsed.forEach(functionId => {
			// Don't recalculate functions
			let childFunctions = this.calculateChildFunctions(functionId, aggregator);
			logicFunctionsUsed = logicFunctionsUsed.concat(childFunctions.filter(childFunction => logicFunctionsUsed.indexOf(childFunction) === -1));
		});

		// Return all functions used
		return logicFunctionsUsed;
	},

	/**
	 * Helper function which uses nested recursion with functionsUsedInLogicWorkspace to find the child functions
	 * within a function by its ID and whether it has been calculated already.
	 * @param {string} functionId The function whose children to find
	 * @param {object} aggregator Tracks which functions have been calculated already
	 * @memberof BlocklyUtils
	 */
	calculateChildFunctions(functionId, aggregator) {
		if (!aggregator[functionId]) {
			// Handle functions which call themselves by providing a dummy value to start
			aggregator[functionId] = [];
			let functionRecord = LogicFunctionStore.get(functionId) || {};
			let functionWorkspace = this.getWorkspaceFromXml(functionRecord.blocklyxml);
			aggregator[functionId] = this.functionsUsedInLogicWorkspace(functionWorkspace, aggregator);
		}
		return aggregator[functionId];
	},

	/**
	 * 
	 * Helper function to find the variables used within the block.
	 * 
	 * (With the new Blockly paradigm, we must track variable models used in a function
	 * so as to convert their IDs to that of the corresponding variable in another workspace
	 * if need be.)
	 * 
	 * @param {object} varsUsed Dictionary of variables used. Passed in repeatedly as an aggregator.
	 * @param {Blockly.Block} childBlock Blockly Block whose used variables to find.
	 * @memberof BlocklyUtils
	 */
	varsUsedInBlock(varsUsed, childBlock) {
		varsUsed = varsUsed ? varsUsed : {};
		let childVarsUsed = childBlock.getVarModels();
		if (childVarsUsed) {
			childVarsUsed.forEach(childVar => {
				if (!varsUsed[childVar.name]) {
					varsUsed[childVar.name] = childVar.getId();
				}
			});
		}

		return varsUsed;
	},

	/**
	 * 
	 * Helper function to find the variables used within a workspace.
	 * 
	 * (With the new Blockly paradigm, we must track variable models used in a function
	 * so as to convert their IDs to that of the corresponding variable in another workspace
	 * if need be.)
	 * 
	 * Similar to varsUsedInBlock, except that it wraps it to call over all blocks in a workspace.
	 * 
	 * @param {Blockly.Workspace} workspace Blockly Workspace whose used variables to find.
	 * @memberof BlocklyUtils
	 */
	varsUsedInWorkspace(workspace) {
		let varsUsed = {};
		let blocks = workspace.getAllBlocks();
		blocks.forEach(childBlock => {
			varsUsed = this.varsUsedInBlock(varsUsed, childBlock);
		});

		return varsUsed;
	},

	/**
	 * Helper function to update the Logic Function Store with expanded functions in the workspace.
	 * 
	 * @param {Blockly.Workspace} workspace Blockly Workspace from whose expanded function blocks to update the store
	 * @memberof BlocklyUtils
	 */
	updateFunctionsFromWorkspace(workspace) {
		let toReturn = [];
		if (!workspace) {
			return toReturn;
		}

		workspace.getAllBlocks().forEach(block => {
			if ((block.type === 'function') && block.functionId && block.mode === 'edit') {
				let functionId = block.functionId;
				// If the function is expanded anywhere, get its new XML and override the function record with it.

				// Get the function record to update
				let functionRecord = LogicFunctionStore.get(functionId) || { recordId: functionId };

				// Get the new name of the function
				let newFunctionName = block.getFieldValue('functionName');
				if (newFunctionName) {
					functionRecord.name = newFunctionName;
				}

				// Get the new description for the function
				functionRecord.description = block.getFieldValue('functionDescription');

				// Get the new XML content for the function
				let contentTopBlock = block.getInputTargetBlock('CONTENT');
				let newBlocklyXML = '';

				if (contentTopBlock) {

					// Get the new XML
					let blockDom = Blockly.Xml.blockToDom(contentTopBlock, true);
					newBlocklyXML = Blockly.Xml.domToText(blockDom);
					let varsUsed = {};
					let logicFunctionsUsed = {};
					let childBlocks = contentTopBlock.getDescendants() || [];
					childBlocks.forEach(childBlock => {

						// Get variables used.
						varsUsed = this.varsUsedInBlock(varsUsed, childBlock);

						// Store any child functions as children of this function
						if (childBlock.id !== block.id && childBlock.type === 'function') {
							let childFunctionId = childBlock.functionId;
							if (childFunctionId && childFunctionId !== functionId && !logicFunctionsUsed[childFunctionId]) {
								logicFunctionsUsed[childFunctionId] = true;
							}
							// If the child function block is collapsed, also include its child functions here
							// @TODO: See if this is actually necessary, as our child + parent function getters already account for recursing up/down ancestry trees
							// if(childBlock.mode === 'view') {
							// 	let childFunctionRecord = LogicFunctionStore.get(childFunctionId) || {};
							// 	if(childFunctionRecord.logicFunctionsUsed) {
							// 		childFunctionRecord.logicFunctionsUsed.split(',').forEach(grandchildFunctionId => {
							// 			logicFunctionsUsed[grandchildFunctionId] = true;
							// 		});
							// 	}
							// }
						}
					});

					let logicFunctionsUsedArr = Object.keys(logicFunctionsUsed);

					if (logicFunctionsUsedArr.length) {
						functionRecord.logicFunctionsUsed = logicFunctionsUsedArr.join(',');
						functionRecord.logicFunctionsUsedDirectly = BlocklyUtils.functionsDirectlyInLogicWorkspace(workspace);
					} else {
						functionRecord.logicFunctionsUsed = '';
						functionRecord.logicFunctionsUsedDirectly = '';
					}
					functionRecord.varsUsed = JSON.stringify(varsUsed);
				}
				functionRecord.blocklyxml = newBlocklyXML;


				// Update the store with all of the new stuff.
				LogicFunctionActions.pushToStore(functionId, functionRecord);

				// As we had to update this function, we should include it in the list of functions updated.
				toReturn.push(functionId);
			}
		});

		return toReturn;
	},

	/**
	 * Helper function to update the workspace's expanded functions with values from the Logic Function Store
	 * 
	 * @param {Blockly.Workspace} workspace Blockly Workspace whose expanded function blocks to update wtih values the store
	 * @memberof BlocklyUtils
	 */
	updateFunctionsInWorkspace(workspace) {
		if (!workspace) {
			return workspace;
		}

		// Find all logic functions used in this workspace.
		// (Child functions of collapsed functions will not be caught, but that is okay for now, as we are only looking for expanded functions which need replaced.)
		// We don't need to worry about ordering, as expanded function XML means that all functions will be updated appropriately
		workspace.getAllBlocks(true).forEach(block => {
			// We may have replaced a block inside of a function if we already replaced the parent function's child blocks.
			// If that's the case, we want to skip it.
			if (block && block.workspace === workspace && block.type === 'function' && block.functionId && block.mode === 'edit') {
				// Collapse blocks; we're not really concerned about handholding anymore

				try {
					block.setMode('view')
				} catch (err) {
					console.error('Error collapsing block', err);
				}

				// let functionId = block.functionId;
				// // Get the function record information
				// let functionRecord = LogicFunctionStore.get(functionId) || { recordId: functionId };

				// // Get specific properties from the function record
				// let { name: newFunctionName, description, blocklyxml: newBlocklyXML, varsUsed } = functionRecord;

				// // Set the name and description
				// if(block.getField('functionName')) {
				// 	block.setFieldValue(newFunctionName, 'functionName');
				// }
				// if(block.getField('functionDescription')) {
				// 	block.setFieldValue(description, 'functionDescription');
				// }

				// // Dispose of any functions 
				// let holder = block.getInputTargetBlock('CONTENT');
				// while (holder) {
				// 	let temp = holder;
				// 	holder = holder.getNextBlock();
				// 	temp.unplug(true);
				// 	temp.dispose();
				// }

				// // Replace the blocks with the new XML, if available.
				// if(newBlocklyXML) {
				// 	// Blockly wants everything to start with <xml> tags now
				// 	newBlocklyXML = newBlocklyXML.startsWith('<xml') ? newBlocklyXML : '<xml>' + newBlocklyXML + '</xml>';

				// 	if(workspace.getVariable) {
				// 		// New Blockly assigns variable IDs, which may conflict with other workspaces, because of course.ContextStore
				// 		// So we replace all instances of our variables which have other IDs before loading this up into our workspace.
				// 		varsUsed = ObjectUtils.getObjFromJSON(varsUsed);
				// 		Object.keys(varsUsed).forEach(varName => {
				// 			let dup = workspace.getVariable(varName);
				// 			if(dup && dup.getId() !== varsUsed[varName]) {
				// 				let dupid = dup.getId();
				// 				// JavaScript's RegExp function does not sanitize automatically, so in order to replace this string globally,
				// 				// we must use this RegEx in order to sanitize the variable ID.
				// 				let toReplace = varsUsed[varName].replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&');
				// 				newBlocklyXML = newBlocklyXML.replace(new RegExp(toReplace, 'g'), dupid);
				// 			}
				// 		});

				// 		let xmlDom = Blockly.Xml.textToDom(newBlocklyXML);

				// 		// Work around an issue with new Blockly's domToBlock function which does not accept <xml> values
				// 		let newBlock = xmlDom && xmlDom.childNodes ? Blockly.Xml.domToBlock(xmlDom.childNodes[0], workspace) : null;
				// 		if(newBlock && newBlock.previousConnection && block.getInput('CONTENT')) {
				// 			newBlock.previousConnection.connect(block.getInput('CONTENT').connection);
				// 		} else if (newBlock) {
				// 			// If we made a new block but can't connect it, clean it up.
				// 			newBlock.dispose();
				// 		}
				// 	}
				// }
			}
		});

		// Return workspace by itself, as it may be further used for something
		return workspace;
	},

	/**
	 * Helper function to remove all references to functions being deleted in a Blockly workspace
	 * 
	 * @param {Blockly.Workspace} workspace Workspace in which to delete functions
	 * @param {array} logicFunctionIds Logic functions to delete
	 * @memberof BlocklyUtils
	 */
	deleteFunctionsInWorkspace(workspace, logicFunctionIds) {
		logicFunctionIds = logicFunctionIds ? logicFunctionIds : [];

		workspace.getAllBlocks().forEach(block => {
			if (block.type === 'function' && block.functionId && logicFunctionIds.indexOf(block.functionId) > -1) {
				// Collapse the block
				if (block.mode !== 'view') {
					block.setMode('view');
				}

				// Clear out the function value
				block.onFunctionChange('');
				if (block.getField('functionName')) {
					block.setFieldValue('', 'functionName');
				}
				if (block.getField('functionDescription')) {
					block.setFieldValue('', 'functionDescription');
				}

				// Disable the block
				block.setDisabled(true);
			} else if (block.type === 'function_v2' && block.functionId && logicFunctionIds.indexOf(block.functionId) > -1) {
				// Clear out the function value				
				block.updateFunction('', false);
				if (block.getField('functionName')) {
					block.setFieldValue('', 'functionName');
				}

				// Disable the block
				block.setDisabled(true);
			}
		});

		// Make sure to update the functions in the workspace after deletion
		workspace = this.updateFunctionsInWorkspace(workspace);
		return workspace;
	},

	/**
	 * Helper function to generate an aggregator used for finding parent and/or child functions
	 * 
	 * @param {array} functions Functions array to loop over and generate aggregator
	 * @memberof BlocklyUtils
	 */
	generateAggregator(functions) {
		let aggregator = {};
		functions.forEach(functionId => {
			let functionRecord = LogicFunctionStore.get(functionId);
			aggregator[functionId] = functionRecord;
		});

		return aggregator;
	},
	/**
	 * 
	 * Find logic functions used within a logic function, iterating down through all children, grandchildren, etc.
	 * 
	 * @param {string} functionId The functionId whose used logic functions to find
	 * @param {object} [aggregator] Aggregator to track logic functions already accounted for.
	 * @memberof BlocklyUtils
	 */
	readLogicFunctionsUsed(functionId, aggregator) {
		aggregator = aggregator ? aggregator : {};
		let toRecurseOver = [];
		let functionRecord = LogicFunctionStore.get(functionId);
		if (functionRecord && functionRecord.logicFunctionsUsed) {
			let logicFunctionsUsedArr = functionRecord.logicFunctionsUsed.split(',');
			logicFunctionsUsedArr.forEach(childFunctionId => {
				let childFunctionRecord = LogicFunctionStore.get(childFunctionId);
				if (!aggregator[childFunctionId] && childFunctionId !== functionId) {
					aggregator[childFunctionId] = childFunctionRecord;
					toRecurseOver.push(childFunctionId);
				}
			});

			toRecurseOver.forEach(recursedFunction => {
				aggregator = this.readLogicFunctionsUsed(recursedFunction, aggregator);
			});
		}
		return aggregator;
	},


	/**
	 * Helper function to find all logic functions used by any of a set of logic functions
	 * 
	 * @param {array} functions Array of functions whose used logic functions to find
	 * @memberof BlocklyUtils
	 */
	readAllLogicFunctionsUsed(functions) {
		if (!functions) {
			return {};
		}
		let aggregator = {};
		functions.forEach(functionId => {
			aggregator = this.readLogicFunctionsUsed(functionId, aggregator);
		});
		return aggregator;
	},

	/**
	 * 
	 * Find logic functions which use a logic function, iterating down through all parents, grandparents, etc.
	 * 
	 * @param {string} functionId The functionId whose parent logic functions to find
	 * @param {object} aggregator Aggregator to track logic functions already accounted for.
	 * @memberof BlocklyUtils
	 */
	findParentFunctions(functionId, aggregator) {
		aggregator = aggregator ? aggregator : {};
		let toRecurseOver = [];
		LogicFunctionStore.getAllArray().forEach(functionObj => {
			let isParentFunction = (functionObj.logicFunctionsUsed ? functionObj.logicFunctionsUsed.includes(functionId) : false);
			if (isParentFunction && !aggregator[functionObj.recordId]) {
				aggregator[functionObj.recordId] = functionObj;
				toRecurseOver.push(functionObj.recordId);
			}
		});
		toRecurseOver.forEach(recursedFunction => {
			aggregator = this.findParentFunctions(recursedFunction, aggregator);
		});
		return aggregator;
	},

	/**
	 * Helper function to find all locations which directly use
	 * any functions which require regeneration due to their
	 * parameters having changed.
	 * 
	 * @param {array} logicFunctionIds An array of logic function IDs
	 */
	findAllLocations(logicFunctionIds) {
		let locations = {
			logicFunctions: {},
			pages: {},
			fields: {},
			scheduledActions: {},
			apiActions: {}

		};
		// First, find all functions whose logicFunctionsUsedDirectly include this value
		LogicFunctionStore.getAllArray().forEach(funct => {
			if(!funct.logicFunctionsUsedDirectly) {
				return false;
			}
			let logicFunctionUsed = logicFunctionIds.find(f => funct.logicFunctionsUsedDirectly.includes(f));
			if(!!logicFunctionUsed) {
				// This format is slightly different than the others,
				// as the js is stored directly on the function instead of
				// nested within another trigger, such as logic
				// Anywhere which references this will need to account 
				// for this
				locations.logicFunctions[funct.recordId] = funct;
			}
		});

		// Find all fields
		// Find all fields which need to be updated
		FieldStore.getAllArray().forEach(fieldObj => {
			let fieldType = fieldObj.fieldType;
			let fieldTypeObj = FieldTypeStore.get(fieldType) || {};
			let { supportedActionTriggers, customActionTriggers } = fieldTypeObj;
			let availableActionTriggers = [];
			if (supportedActionTriggers) {
				availableActionTriggers = availableActionTriggers.concat(supportedActionTriggers.split(',').map(triggerName => 'automation-' + triggerName));
			}
			if (customActionTriggers) {
				try {
					let customActionTriggersArr = JSON.parse(customActionTriggers);
					availableActionTriggers = availableActionTriggers.concat(customActionTriggersArr.map(triggerObj => 'automation-' + triggerObj.codeName));
				} catch (error) {
					console.warn('Error parsing customActionTriggers for fieldType', fieldType, 'value was', customActionTriggers);
				}
			}
			let settings = FieldSettingsStore.getSettings(fieldObj.recordId);
			Object.assign(settings, fieldObj);
			delete settings.settings;

			let triggersObj = this.getDirectAutomationToUpdate(settings, availableActionTriggers, logicFunctionIds);

			if (Object.keys(triggersObj).length) {
				locations.fields[fieldObj.recordId] = triggersObj;
			}

		});

		// Find all pages which need to be updated
		PageStore.getAllArray().forEach(pageObj => {
			let automation = PageStore.getAutomation(pageObj.recordId);
			let availableActionTriggers = [];
			if (automation) {
				availableActionTriggers = Object.keys(automation);
			}

			// Format this in the format expected by the helper function
			let pageSettings = Object.assign({
				// @TODO: Is this actually still necessary after the other updates?
				childConfigurations: PageStore.getChildConfigurations(pageObj.recordId)
			}, automation)

			let triggersObj = this.getDirectAutomationToUpdate(pageSettings, availableActionTriggers, logicFunctionIds);
			if (Object.keys(triggersObj).length) {
				locations.pages[pageObj.recordId] = triggersObj;
			}
		});

		// Find all API Triggers which need to be updated
		MetadataStore.getAllArray('apiconfig').forEach(apiObj => {
			let triggersObj = this.getDirectAutomationToUpdate(apiObj, ['logic'], logicFunctionIds);
			if (Object.keys(triggersObj).length) {
				locations.apiActions[apiObj.recordId] = triggersObj;
			}
		});

		// Find all Scheduled Actions which need to be updated
		MetadataStore.getAllArray('scheduledLogic').forEach(scheduledObj => {
			let triggersObj = this.getDirectAutomationToUpdate(scheduledObj, ['logic'], logicFunctionIds);
			if (Object.keys(triggersObj).length) {
				locations.scheduledActions[scheduledObj.recordId] = triggersObj;
			}
		});

		return locations;
	},

	/**
	 * Helper function to find all logic functions which use any of a set of logic functions
	 * 
	 * @param {array} functions Array of functions to find the parents of
	 * @memberof BlocklyUtils
	 */
	findAllParentFunctions(functions) {
		if (!functions) {
			return {};
		}
		let aggregator = {};
		functions.forEach(functionId => {
			aggregator = this.findParentFunctions(functionId, aggregator);
		});
		return aggregator;
	},

	/**
	 * Helper function to find all functions to update as a result of a set of functions being
	 * updated in a workspace. May or may not include descendant functions; will always include
	 * functions in functionArr and its ancestors.
	 * 
	 * @param {array} functionArr Functions to find the ancestors and descendants of
	 * @param {boolean} excludeChildren Whether or not to include children of these functions
	 * @memberof BlocklyUtils
	 */
	findAllFunctionsToUpdate(functionArr, excludeChildren) {
		if (!functionArr || !functionArr.length) {
			return [];
		}
		let aggregator = this.generateAggregator(functionArr);
		if (!excludeChildren) {
			Object.assign(aggregator, this.readAllLogicFunctionsUsed(functionArr, aggregator));
		}
		Object.assign(aggregator, this.findAllParentFunctions(functionArr, aggregator));
		return Object.keys(aggregator);
	},

	/**
	 * Helper function to find all metaData types which use any of a given set of functionIds.
	 * 
	 * @param {array} logicFunctionIds Find all sibling metadata types using these function IDs
	 * @memberof BlocklyUtils
	 */
	findSiblingsToRegen(logicFunctionIds) {

		logicFunctionIds = logicFunctionIds ? logicFunctionIds : [];

		// All other fields, pages, functions, scheduled actions and API actions whose code to regenerate + which triggers
		// To make matters extra spicy fun, we must ALSO update any blocks left expanded with the new functionality 
		let siblingsToRegen = {
			pages: {},
			fields: {},
			scheduledActions: {},
			apiActions: {}
		};

		if (!logicFunctionIds || !logicFunctionIds.length) {
			return siblingsToRegen;
		}

		// Find all fields which need to be updated
		FieldStore.getAllArray().forEach(fieldObj => {


			let fieldType = fieldObj.fieldType;
			let fieldTypeObj = FieldTypeStore.get(fieldType) || {};
			let { supportedActionTriggers, customActionTriggers } = fieldTypeObj;
			let availableActionTriggers = [];
			if (supportedActionTriggers) {
				availableActionTriggers = availableActionTriggers.concat(supportedActionTriggers.split(',').map(triggerName => 'automation-' + triggerName));
			}
			if (customActionTriggers) {
				try {
					let customActionTriggersArr = JSON.parse(customActionTriggers);
					availableActionTriggers = availableActionTriggers.concat(customActionTriggersArr.map(triggerObj => 'automation-' + triggerObj.codeName));
				} catch (error) {
					console.warn('Error parsing customActionTriggers for fieldType', fieldType, 'value was', customActionTriggers);
				}
			}
			let settings = FieldSettingsStore.getSettings(fieldObj.recordId);
			Object.assign(settings, fieldObj);
			delete settings.settings;

			let triggersObj = this.getAutomationToUpdate(settings, availableActionTriggers, logicFunctionIds);

			if (Object.keys(triggersObj).length) {
				siblingsToRegen.fields[fieldObj.recordId] = triggersObj;
			}

		});

		// Find all pages which need to be updated
		PageStore.getAllArray().forEach(pageObj => {
			let automation = PageStore.getAutomation(pageObj.recordId);
			let availableActionTriggers = [];
			if (automation) {
				availableActionTriggers = Object.keys(automation);
			}

			// Format this in the format expected by the helper function
			let pageSettings = Object.assign({
				// @TODO: Is this actually still necessary after the other updates?
				childConfigurations: PageStore.getChildConfigurations(pageObj.recordId)
			}, automation)

			let triggersObj = this.getAutomationToUpdate(pageSettings, availableActionTriggers, logicFunctionIds);
			if (Object.keys(triggersObj).length) {
				siblingsToRegen.pages[pageObj.recordId] = triggersObj;
			}
		});

		// Find all API Triggers which need to be updated
		MetadataStore.getAllArray('apiconfig').forEach(apiObj => {
			let triggersObj = this.getAutomationToUpdate(apiObj, ['logic'], logicFunctionIds);
			if (Object.keys(triggersObj).length) {
				siblingsToRegen.apiActions[apiObj.recordId] = triggersObj;
			}
		});

		// Find all Scheduled Actions which need to be updated
		MetadataStore.getAllArray('scheduledLogic').forEach(scheduledObj => {
			let triggersObj = this.getAutomationToUpdate(scheduledObj, ['logic'], logicFunctionIds);
			if (Object.keys(triggersObj).length) {
				siblingsToRegen.scheduledActions[scheduledObj.recordId] = triggersObj;
			}
		});

		// siblingsToRegen.logicFunctions = this.findParentFunctions(logicFunctionIds, siblingsToRegen.logicFunctions);

		return siblingsToRegen;
	},

	/**
	 * Helper function to determine which triggers (including child settings) should be updated
	 * for a given set of settings.
	 * 
	 * @param {object} settings The settings of the component whose triggers are being considered
	 * @param {array} availableActionTriggers The available action triggers for the component
	 * @param {array} logicFunctionIds The functions being updated
	 * 
	 * @returns {object} Object with key/value pairs of trigger names and values from each setting
	 * @memberof BlocklyUtils
	 */
	getAutomationToUpdate(settings, availableActionTriggers, logicFunctionIds) {

		logicFunctionIds = logicFunctionIds ? logicFunctionIds : [];

		let toReturn = {};
		availableActionTriggers.forEach(triggerName => {
			let triggerValue = settings[triggerName];
			if (triggerValue) {
				try {
					// Some automation is saved as a string and some as an object. Only attempt to parse if it's a string.
					let automationObj = (typeof triggerValue === 'string' || triggerValue instanceof String) ? JSON.parse(triggerValue) : triggerValue;
					// Handle converting logicFunctionsUsed into an array
					let logicFunctionsUsed = automationObj && automationObj.logicFunctionsUsed ? automationObj.logicFunctionsUsed : '';
					let logicFunctionsUsedArr = [];
					if (logicFunctionsUsed) {
						if (Array.isArray(logicFunctionsUsed)) {
							logicFunctionsUsedArr = logicFunctionsUsed;
						} else {
							logicFunctionsUsedArr = logicFunctionsUsed.split(',');
						}
					}
					if (logicFunctionsUsedArr && logicFunctionsUsedArr.length) {
						logicFunctionIds.forEach(logicFunctionId => {
							if (logicFunctionsUsedArr.indexOf(logicFunctionId) > -1) {
								toReturn[triggerName] = automationObj;
							}
						});
					}
				} catch (error) {
					console.warn('Error parsing automation for trigger %s. Automation was', triggerName, triggerValue);
					console.warn('error', error);
				}
			}
			// @TODO: Search over all triggers for the field and see if any of them have values which include any of the logic functions being updated.
			// If so, push it into siblingsToRegen under the fields section, of the form fieldId: {triggerName: true}
		});

		if (settings.childConfigurations) {
			let allChildrenAutomationObj = {};
			try {
				let configObj = settings.childConfigurations;
				Object.keys(configObj).forEach(childFieldId => {
					let childSettings = configObj[childFieldId];
					let childFieldObj = FieldStore.get(childFieldId) || {};
					let childFieldTypeId = childFieldObj.fieldType;
					let childFieldTypeObj = FieldTypeStore.get(childFieldTypeId) || {};
					let availableChildActionTriggers = [];
					let { supportedActionTriggers: childSupportedActionTriggers, customActionTriggers: childCustomActionTriggers } = childFieldTypeObj;
					if (childSupportedActionTriggers) {
						availableChildActionTriggers = availableChildActionTriggers.concat(childSupportedActionTriggers.split(',').map(triggerName => 'automation-' + triggerName));
					}
					if (childCustomActionTriggers) {
						try {
							let childCustomActionTriggersArr = JSON.parse(childCustomActionTriggers);
							availableChildActionTriggers = availableActionTriggers.concat(childCustomActionTriggersArr.map(triggerObj => 'automation-' + triggerObj.codeName));
						} catch (error) {
							console.warn('Error parsing customActionTriggers for fieldType', childFieldTypeId, 'value was', childCustomActionTriggers);
						}
					}

					let childAutomationObj = this.getAutomationToUpdate(childSettings, availableChildActionTriggers, logicFunctionIds)


					if (Object.keys(childAutomationObj).length) {
						allChildrenAutomationObj[childFieldId] = childAutomationObj;
					}
				});

				if (Object.keys(allChildrenAutomationObj).length) {
					toReturn.childConfigurations = allChildrenAutomationObj;
				}
			} catch (error) {
				console.warn('Error parsing automation for childConfigurations. Automation was', settings.childConfigurations);
			}
		}

		return toReturn;
	},

	/**
	 * Helper function to determine which triggers (including child settings) should be updated
	 * for a given set of settings.
	 * 
	 * @param {object} settings The settings of the component whose triggers are being considered
	 * @param {array} availableActionTriggers The available action triggers for the component
	 * @param {array} logicFunctionIds The functions being updated
	 * 
	 * @returns {object} Object with key/value pairs of trigger names and values from each setting
	 * @memberof BlocklyUtils
	 */
	getDirectAutomationToUpdate(settings, availableActionTriggers, logicFunctionIds) {

		logicFunctionIds = logicFunctionIds ? logicFunctionIds : [];

		let toReturn = {};
		availableActionTriggers.forEach(triggerName => {
			let triggerValue = settings[triggerName];
			if (triggerValue) {
				try {
					// Some automation is saved as a string and some as an object. Only attempt to parse if it's a string.
					let automationObj = (typeof triggerValue === 'string' || triggerValue instanceof String) ? JSON.parse(triggerValue) : triggerValue;
					// Handle converting logicFunctionsUsed into an array
					let logicFunctionsUsed = automationObj && automationObj.logicFunctionsUsedDirectly ? automationObj.logicFunctionsUsedDirectly : '';
					let logicFunctionsUsedArr = [];
					if (logicFunctionsUsed) {
						if (Array.isArray(logicFunctionsUsed)) {
							logicFunctionsUsedArr = logicFunctionsUsed;
						} else {
							logicFunctionsUsedArr = logicFunctionsUsed.split(',');
						}
					}
					if (logicFunctionsUsedArr && logicFunctionsUsedArr.length) {
						logicFunctionIds.forEach(logicFunctionId => {
							if (logicFunctionsUsedArr.indexOf(logicFunctionId) > -1) {
								toReturn[triggerName] = automationObj;
							}
						});
					}
				} catch (error) {
					console.warn('Error parsing automation for trigger %s. Automation was', triggerName, triggerValue);
					console.warn('error', error);
				}
			}
			// @TODO: Search over all triggers for the field and see if any of them have values which include any of the logic functions being updated.
			// If so, push it into siblingsToRegen under the fields section, of the form fieldId: {triggerName: true}
		});

		if (settings.childConfigurations) {
			let allChildrenAutomationObj = {};
			try {
				let configObj = settings.childConfigurations;
				Object.keys(configObj).forEach(childFieldId => {
					let childSettings = configObj[childFieldId];
					let childFieldObj = FieldStore.get(childFieldId) || {};
					let childFieldTypeId = childFieldObj.fieldType;
					let childFieldTypeObj = FieldTypeStore.get(childFieldTypeId) || {};
					let availableChildActionTriggers = [];
					let { supportedActionTriggers: childSupportedActionTriggers, customActionTriggers: childCustomActionTriggers } = childFieldTypeObj;
					if (childSupportedActionTriggers) {
						availableChildActionTriggers = availableChildActionTriggers.concat(childSupportedActionTriggers.split(',').map(triggerName => 'automation-' + triggerName));
					}
					if (childCustomActionTriggers) {
						try {
							let childCustomActionTriggersArr = JSON.parse(childCustomActionTriggers);
							availableChildActionTriggers = availableActionTriggers.concat(childCustomActionTriggersArr.map(triggerObj => 'automation-' + triggerObj.codeName));
						} catch (error) {
							console.warn('Error parsing customActionTriggers for fieldType', childFieldTypeId, 'value was', childCustomActionTriggers);
						}
					}

					let childAutomationObj = this.getDirectAutomationToUpdate(childSettings, availableChildActionTriggers, logicFunctionIds)


					if (Object.keys(childAutomationObj).length) {
						allChildrenAutomationObj[childFieldId] = childAutomationObj;
					}
				});

				if (Object.keys(allChildrenAutomationObj).length) {
					toReturn.childConfigurations = allChildrenAutomationObj;
				}
			} catch (error) {
				console.warn('Error parsing automation for childConfigurations. Automation was', settings.childConfigurations);
			}
		}

		return toReturn;
	},

	findDescendantFunctions(functionId, aggregator) {
		aggregator = aggregator || {};
		let functionRecord = LogicFunctionStore.get(functionId) || {};
		// Skip ones we've already processed
		if(aggregator[functionId]) {
			return aggregator;
		}
		aggregator[functionId] = true;
		if(functionRecord.logicFunctionsUsedDirectly) {
			let logicFunctionsUsedDirectly = Array.isArray(functionRecord.logicFunctionsUsedDirectly) ? functionRecord.logicFunctionsUsedDirectly : functionRecord.logicFunctionsUsedDirectly.split(',');
			logicFunctionsUsedDirectly.forEach(childId => this.findDescendantFunctions(childId, aggregator));
		}
		return aggregator;
	},

	/**
	 * Determines whether any function in a list forces running on the backend.
	 * @param {array} functionIds 
	 * @returns 
	 */
	getForceBackend(functionIds) {
		let functs = functionIds.map(id => LogicFunctionStore.get(id) || {});
		let mustRunOnBackend = functs.find(f => (f.forceBackend + '') === 'true');
		return mustRunOnBackend ? true : false;
	},

	/**
	 * Helper function to combine two Blockly Workspaces into one. Used for pasting.
	 * 
	 * @param {string} pastedblocklyxml Blockly XML being transplanted
	 * @param {string} oldblocklyxml Blockly XML of workspace code is being transplanted into
	 * @memberof BlocklyUtils
	 */
	combineWorkspaces(pastedblocklyxml, oldblocklyxml) {
		// Attempts to parse the pasted Blockly XML if it exists. If there is none, quietly ignore it; it was likely a blank workspace value.
		// Invalid configurations will fail on textToDom and be caught by the catch
		let pastedxmldom = pastedblocklyxml ? Blockly.Xml.textToDom(pastedblocklyxml) : null;
		let pastedxmlworkspace = new Blockly.Workspace();
		let pastedvariablemodels = [];
		if (pastedxmldom) {
			try {
				// Non-SVG workspaces can have a problem if Blockly.theme_ has yet to be set.
				if (Blockly.setTheme && Blockly.Themes && Blockly.Themes.CitDevFiveColor && !Blockly.theme_) {
					Blockly.theme_ = Blockly.Themes.CitDevFiveColor;
				}
				Blockly.Xml.domToWorkspace(pastedxmldom, pastedxmlworkspace);
				pastedvariablemodels = pastedxmlworkspace.getAllVariables();
			} catch (error) {
				console.warn('Error converting oldxmldom to workspace. This ordinarily shouldn\'t happen, so something has gone very wrong. XML was', oldblocklyxml);
				console.warn('Error was', error);
			}
		}

		// We really want the inner blocks of any workspace in order to splice them together; we get the pasted DOM block's inner HTML if it exists
		let pastedinnerhtml = pastedxmldom ? pastedxmldom.innerHTML : '';
		let [pastedvariablesstring, pastedvariables] = pastedinnerhtml.match(/<variables [^>]*>(.*)<\/variables>/) || ['', ''];
		if (pastedvariablesstring) {
			pastedinnerhtml = pastedinnerhtml.replace(pastedvariablesstring, '');
		}
		pastedvariables = pastedvariables ? pastedvariables : '';

		let oldxmldom = oldblocklyxml ? Blockly.Xml.textToDom(oldblocklyxml) : null;
		let oldxmlworkspace = new Blockly.Workspace();
		let oldvariablemodels = [];
		let variableDict = {};
		if (oldxmldom) {
			try {
				// Non-SVG workspaces can have a problem if Blockly.theme_ has yet to be set.
				if (Blockly.setTheme && Blockly.Themes && Blockly.Themes.CitDevFiveColor && !Blockly.theme_) {
					Blockly.theme_ = Blockly.Themes.CitDevFiveColor;
				}
				Blockly.Xml.domToWorkspace(oldxmldom, oldxmlworkspace);
				oldvariablemodels = oldxmlworkspace.getAllVariables();
			} catch (error) {
				console.warn('Error converting oldxmldom to workspace. This ordinarily shouldn\'t happen, so something has gone very wrong. XML was', oldblocklyxml);
				console.warn('Error was', error);
			}
		}

		oldvariablemodels.forEach(variableModel => {
			let id = variableModel.getId();
			let name = variableModel.name;
			if (!variableDict[name]) {
				variableDict[name] = {
					id: id,
					name: name
				}
			}
		});

		pastedvariablemodels.forEach(pastedvariablemodel => {
			let oldvariable = oldxmlworkspace.getVariable(pastedvariablemodel.name);
			if (oldvariable) {
				let oldid = oldvariable.getId();
				let pastedid = pastedvariablemodel.getId().replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&');
				pastedvariables = pastedvariables.replace(new RegExp(pastedid, 'g'), oldid);
				pastedinnerhtml = pastedinnerhtml.replace(new RegExp(pastedid, 'g'), oldid);
			}
		});


		// If we have blocks in the workspace, get them using innerHTML
		let oldinnerhtml = oldxmldom ? oldxmldom.innerHTML : '';
		let [oldvariablesstring, oldvariables] = oldinnerhtml.match(/<variables xmlns="http:\/\/www\.w3\.org\/1999\/xhtml">(.*)<\/variables>/) || ['', ''];
		if (oldvariablesstring) {
			oldinnerhtml = oldinnerhtml.replace(oldvariablesstring, '');
		}
		oldvariables = oldvariables ? oldvariables : '';

		// Splice together the old and new blocks within a parent XML wrapper (as Blockly does)
		let newxml = '<xml xmlns="http://www.w3.org/1999/xhtml">' +
			((pastedvariables || oldvariables) ?
				'<variables xmlns="http://www.w3.org/1999/xhtml">' + pastedvariables + oldvariables + '</variables>' :
				'') +
			pastedinnerhtml + oldinnerhtml + '</xml>';

		return newxml;
	},

	/**
	 * 
	 * @param {*} pastedblocklyxml 
	 * @param {*} oldxmlworkspace 
	 */
	appendToWorkspace(pastedblocklyxml, oldxmlworkspace) {
		// Attempts to parse the pasted Blockly XML if it exists. If there is none, quietly ignore it; it was likely a blank workspace value.
		// Invalid configurations will fail on textToDom and be caught by the catch
		let pastedxmldom = pastedblocklyxml ? Blockly.Xml.textToDom(pastedblocklyxml) : null;
		let pastedxmlworkspace = new Blockly.Workspace();
		let pastedvariablemodels = [];
		if (pastedxmldom) {
			try {
				// Non-SVG workspaces can have a problem if Blockly.theme_ has yet to be set.
				if (Blockly.setTheme && Blockly.Themes && Blockly.Themes.CitDevFiveColor && !Blockly.theme_) {
					Blockly.theme_ = Blockly.Themes.CitDevFiveColor;
				}
				Blockly.Xml.domToWorkspace(pastedxmldom, pastedxmlworkspace);
				pastedvariablemodels = pastedxmlworkspace.getAllVariables();
			} catch (error) {
				console.warn('Error converting pastedblocklyxml to workspace. This ordinarily shouldn\'t happen, so something has gone very wrong. XML was', pastedblocklyxml);
				console.warn('Error was', error);
			}
		}

		// We really want the inner blocks of any workspace in order to splice them together; we get the pasted DOM block's inner HTML if it exists
		let pastedinnerhtml = pastedxmldom ? pastedxmldom.innerHTML : '';
		let [pastedvariablesstring] = pastedinnerhtml.match(/<variables [^>]*>(.*)<\/variables>/) || ['', ''];
		if (pastedvariablesstring) {
			pastedinnerhtml = pastedinnerhtml.replace(pastedvariablesstring, '');
		}

		let oldvariablemodels = [];
		let variableDict = {};
		oldvariablemodels = oldxmlworkspace.getAllVariables();

		oldvariablemodels.forEach(variableModel => {
			let id = variableModel.getId();
			let name = variableModel.name;
			if (!variableDict[name]) {
				variableDict[name] = {
					id: id,
					name: name
				}
			}
		});

		let updateDOM = false;
		pastedvariablemodels.forEach(pastedvariablemodel => {
			let oldvariable = oldxmlworkspace.getVariable(pastedvariablemodel.name);
			if (oldvariable) {
				updateDOM = true;
				let oldid = oldvariable.getId();
				let pastedid = pastedvariablemodel.getId().replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&');
				// pastedvariables = pastedvariables.replace(new RegExp(pastedid, 'g'), oldid);
				pastedinnerhtml = pastedinnerhtml.replace(new RegExp(pastedid, 'g'), oldid);
				// pastedxmldom = pastedxmldom.replace(new RegExp(pastedid, 'g'), oldid);
			}
		});

		if (updateDOM) {
			pastedxmldom = pastedinnerhtml ? Blockly.Xml.textToDom('<xml>' + pastedinnerhtml + '</xml>') : null;
			// try {
			// 	// Non-SVG workspaces can have a problem if Blockly.theme_ has yet to be set.
			// 	if (Blockly.setTheme && Blockly.Themes && Blockly.Themes.CitDevFiveColor && !Blockly.theme_) {
			// 		Blockly.theme_ = Blockly.Themes.CitDevFiveColor;
			// 	}
			// 	Blockly.Xml.domToWorkspace(pastedxmldom, pastedxmlworkspace);
			// } catch (error) {
			// 	console.warn('Error converting pastedblocklyxml to workspace. This ordinarily shouldn\'t happen, so something has gone very wrong. XML was', pastedblocklyxml);
			// 	console.warn('Error was', error);
			// }
		}

		Blockly.Xml.appendDomToWorkspace(pastedxmldom, oldxmlworkspace);
	},
	/**
	 * Update the parameters in a logic function workspace
	 * 
	 * @param {string} oldParams The old params value
	 * @param {string} newParams The new params value
	 * @param {string} blocklyxml The logic function workspace XML 
	 * @returns 
	 */
	replaceParams(oldParams, newParams, blocklyxml) {
		let oldParamsArr = oldParams ? JSON.parse(oldParams) : [];
		let newParamsArr = newParams ? JSON.parse(newParams) : [];


		let paramsLookup = {};

		oldParamsArr.forEach(param => {
			paramsLookup[param.id] = paramsLookup[param.id] || {};
			paramsLookup[param.id].oldVariableName = param.variableName;
		});

		newParamsArr.forEach(param => {
			paramsLookup[param.id] = paramsLookup[param.id] || {};
			paramsLookup[param.id].variableName = param.variableName;
		});

		let paramsToRename = [];
		Object.keys(paramsLookup).forEach(id => {
			let paramObj = paramsLookup[id];
			if (paramObj.variableName && paramObj.oldVariableName && paramObj.oldVariableName !== paramObj.variableName) {
				paramsToRename.push({
					newName: paramObj.variableName,
					oldName: paramObj.oldVariableName
				});
			}
		});

		// Don't waste work loading up the function workspace unless we need to rename any variables.
		if (paramsToRename.length && blocklyxml) {
			try {
				let dummyWorkspace = new Blockly.Workspace();
				Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(blocklyxml), dummyWorkspace);
				paramsToRename.forEach(function (paramInfo) {
					let oldName = paramInfo.oldName;
					let newName = paramInfo.newName;
					let varToRename = dummyWorkspace.getVariable(oldName);
					if (varToRename) {
						dummyWorkspace.renameVariableById(varToRename.getId(), newName);
					}
				});
				blocklyxml = Blockly.Xml.domToText(Blockly.Xml.workspaceToDom(dummyWorkspace));
			} catch (err) {
				console.error('Error attempting to rename parameters for function. Error was', err);
			}
		}

		return blocklyxml;
	}
}

export default BlocklyUtils;
