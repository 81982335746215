import AppDispatcher from '../dispatcher/app-dispatcher'
import BrowserStorageConstants from '../constants/browser-storage-constants'
import Immutable from 'immutable'
// import UIUtils from '../utils/ui-utils';

const BrowserStorageActions = {
	setValue(key, value) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': BrowserStorageConstants.BROWSER_STORAGE_SET_VALUE,
			'key': key,
			'value': value,
		}));
	},

	clearValue(key) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': BrowserStorageConstants.BROWSER_STORAGE_CLEAR_VALUE,
			'key': key,
		}))
	}
}

export default BrowserStorageActions;