import AppDispatcher from '../dispatcher/app-dispatcher'
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import BrowserStorageConstants from '../constants/browser-storage-constants';
import RecordSetConstants from '../constants/record-set-constants';
import ObjectUtils from '../utils/object-utils';
// import UIUtils from '../utils/ui-utils';

/**
 * Store to contain user authentication
 *
 * @class BrowserStorageStore
 * @extends {ReduceStore}
 */
class BrowserStorageStore extends ReduceStore {
	/**
	 * getInitialState - initial state for BrowserStorageStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		let storageObj = {};
		if (sessionStorage) {
			for (let index = 0; index < sessionStorage.length; index++) {
				let key = sessionStorage.key(index);
				if (key.includes('citDevBrowserStorage-')) {
					storageObj[key.substring(21)] = {'value': sessionStorage.getItem(key)};
				}
			}
		}

		return Immutable.fromJS(storageObj);
	}

	/**
	 * Returns the user Id
	 * 
	 * @returns {string} userId
	 */
	getValueByFieldSchemaName(fieldSchemaName) {
		let state = this.getState();
		if (state.has(fieldSchemaName)) {
			return state.get(fieldSchemaName);
		} else {
			return null;
		}
	}

	/**
	 * Gets the State as a JS Object
	 *
	 * @returns {Object}
	 * @memberof BrowserStorageStore
	 */
	getStateJS() {
		let browserStorage = this.getState().toJS();
		Object.keys(browserStorage).forEach(key => {
			browserStorage[key] = browserStorage[key].value ? browserStorage[key].value : null;
		});

		return browserStorage;
	}

	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 */
	reduce(state, action) {
		let type = action.get('type');

		switch (type) {
			case BrowserStorageConstants.BROWSER_STORAGE_SET_VALUE: {
				let value = action.get('value');
				let key = action.get('key');
				if (typeof value === 'object') {
					value = JSON.stringify(value);
				}
				sessionStorage.setItem('citDevBrowserStorage-' + key, value);
				return state.setIn([key,'value'], value);
			}
			case RecordSetConstants.SET_SELECTED_RECORD_SET: {
				let dataRecordId = action.get('dataRecordId');
				if(!dataRecordId) {
					let setTableSchemaName = action.get('setTableSchemaName');
					let recordSetArray = action.get('recordSetArray');
					let key = action.get('fieldSchemaName');
					let oldValue = state.has(key) ? state.get(key) : '';
					let oldValueObj = oldValue ? ObjectUtils.getObjFromJSON(oldValue) : {};
					let startingRelatedRecordsJSON = action.get('startingRelatedRecordsJSON') || oldValueObj.startingRelatedRecordsJSON || '[]';
					let newRecord = recordSetArray.map(recordId => {
						return {recordId: recordId, tableSchemaName: setTableSchemaName};
					});
					let newRecordJSON = JSON.stringify(newRecord);
					let value = JSON.stringify({
						startingRelatedRecordsJSON,
						newRecordJSON
					});
					sessionStorage.setItem('citDevBrowserStorage-' + key, value);
					return state.setIn([key, 'value'], value);
				} else {
					return state;
				}
			}

			case RecordSetConstants.SET_SELECTED_CONTENT_TAB: {

				let dataRecordId = action.get('dataRecordId');
				let fieldSchemaName = action.get('fieldSchemaName');

				let value = action.get('value');
				let valueJSON = value ? JSON.stringify({
					fieldId: value.get('fieldId'),
					recordId: value.get('recordId'),
					tableSchemaName: value.get('tableSchemaName')
				}) : null;
				
				if (!dataRecordId) {
					// First, check if we're dirty, in which case we need to keep the original originalValue
					sessionStorage.setItem('citDevBrowserStorage-' + fieldSchemaName, valueJSON);
					return state.setIn([fieldSchemaName, 'value'], valueJSON);
				} else {
					return state;
				}
			}

			case BrowserStorageConstants.BROWSER_STORAGE_CLEAR_VALUE: {
				let key = action.get('key');
				sessionStorage.removeItem('citDevBrowserStorage-' + key)
				return state.delete(key);
			}
			default: {
				return state;
			}
		}
	}
}

const instance = new BrowserStorageStore(AppDispatcher);
export default instance;