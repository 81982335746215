import {TableConstants} from '../constants/table-constants';
import {FieldConstants} from '../constants/field-constants';
import {FieldTypeConstants} from '../constants/field-type-constants';;
import { PageConstants } from '../constants/page-constants';
import {RelationshipConstants} from '../constants/relationship-constants';;
import {PatternConstants} from '../constants/pattern-constants';;
import { wordsToIgnore, DictionaryRecord } from '../constants/nlp-builder-dictionaries-constants';
import  DictionariesUtils from '../utils/dictionaries-utils';
import AppDispatcher from '../dispatcher/app-dispatcher';
import {ReduceStore} from 'flux/utils';
import Immutable, { Map, List } from 'immutable';
import TableStore from './table-store';
import FieldStore from './field-store';
import PatternStore from './pattern-store';
import FieldTypeStore from './field-type-store';
import PageStore from './page-store';
import RelationshipStore from './relationship-store';
import ObjectUtils from '../utils/object-utils';

/**
 * Store to contain all the Dictionaries avaiable for nlp-processor
 * 
 * @class NLPBuilderDictionariesStore
 * @extends {ReduceStore}
 */
class NLPBuilderDictionariesStore extends ReduceStore {
	/**
	 * getInitialState - initial State for Dictionaries 
	 * 
	 * @returns {Immutable.Map} - Returns an Immutable map of dictionaries  
	 * 
	 * @memberOf NLPBuilderDictionariesStore
	 */
	getInitialState(){
		return Immutable.fromJS({
			'tablesDictionary': [],
			'fieldsDictionary': [],
			'fieldTypesDictionary': [],
			'fieldSettingsDictionary': [],
			'pagesDictionary': [],
			'patternsDictionary': [],
			'relationshipsDictionary': []
		});
	}
	/**
	 * Updates state store 
	 * 
	 * @param {Immutable} state state
	 * @param {Object} action  
	 * @returns {Immutable.Map} - Updated state
	 * @ignore
	 * 
	 * @memberOf NLPBuilderDictionariesStore
	 */
	reduce(state, action){
		let type = action.get('type');

		switch(type){
			case PatternConstants.PATTERN_PULL_FROM_DATABASE_ALL:
			{
				//Waiting for PatternStore and FieldTypeStore to load Data 
				AppDispatcher.waitFor([PatternStore.getDispatchToken()]);

				let patternList = undefined;

				// Do we have Field Types and Fields ? 
				if(FieldTypeStore.allPulledFromDatabase() && FieldStore.allPulledFromDatabase()) { 
					// If so, then let's load the pattern list.
					patternList = _getPatternList();
				}
					//Create the newState to Return with the updated Arrays
					if(patternList) {
						// If we got a pattern list, then set it in to the state.
						return state.set('patternsDictionary', Immutable.fromJS(patternList));
					} else {
						return state;
					}
			}
			case TableConstants.TABLE_DELETE_FROM_DATABASE:
			case TableConstants.TABLE_DELETE_FROM_STORE:
			case TableConstants.TABLE_PULL_FROM_DATABASE:
			case TableConstants.TABLE_PULL_FROM_DATABASE_ALL:
			case TableConstants.TABLE_PUSH_TO_STORE: 
			{
				//Waiting for TableStore to load Data 
				AppDispatcher.waitFor([TableStore.getDispatchToken()]);
				//Once the other Store is updated, Tables are ready to be processed. 
				let tablesListRaw = Immutable.fromJS(TableStore.getAllArray());
				
				//CREATE Dictionary - Tables
				let tableList = List();

				//Is the tables DB populated?
				if(!tablesListRaw){
					// console.log('Cannot populate Tables Dictionary. TableStore is empty');
					return state;
				} 

				//Process the data from DB to the Dictionary format 
				tablesListRaw.forEach(tableRaw => {

					//Builds a new Record, and takes care of validating the data coming from store
					let newSingularRecord = _DictionaryRecord(tableRaw, 'recordId', 'singularName', 'singularName')

					//Add it to the List of Tables
					tableList = tableList.push(newSingularRecord);

					//Builds a new Record, and takes care of validating the data coming from store
					let newPluralRecord = _DictionaryRecord(tableRaw, 'recordId', 'pluralName', 'pluralName')

					//Add it to the List of Tables
					tableList = tableList.push(newPluralRecord);
				});
				
				//Create the newState to Return with the updated Array
				let newState = state.set('tablesDictionary', tableList);
				return newState;
			}

			case FieldConstants.FIELD_PULL_FROM_DATABASE_ALL:
			{
				//Waiting for FieldStore to load Data 
				AppDispatcher.waitFor([FieldStore.getDispatchToken()]);

				let patternList = undefined;
				// Do we have Field Types and Patterns Loaded? 
				if(PatternStore.allPulledFromDatabase() && FieldTypeStore.allPulledFromDatabase()) { 
					// Grab the pattern list.
					patternList = _getPatternList();
				}

				//Once the other Store is updated, Fields are ready to be processed. 
				let fieldListArr = FieldStore.getAllArray();
				
				//Is the fields DB populated?
				if(!fieldListArr){
					// console.log('Cannot populate Fields Dictionary. FieldStore is empty');
					return state;
				} 

				//CREATE Dictionary - Fields
				let fieldList = List();

				//Process the data from DB to the Dictionary format 
				fieldListArr.forEach(fieldObj =>{

					//Validation for Record Values:
					let recordId = fieldObj.recordId ? fieldObj.recordId : '';
					let recordLabel = fieldObj.fieldLabel ? fieldObj.fieldLabel : '';
					let recordSearchable = fieldObj.fieldLabel ? fieldObj.fieldLabel : '';

					//Create a new Field Record 
					let newFieldRecord = new _SettingsRecord(
						recordId,
						recordLabel,
						recordSearchable
					);
					//Add it to the List of fieldList
					fieldList = fieldList.push(newFieldRecord);
				});

				//Create the newState to Return with the updated Arrays 
				if(patternList) {
					//Create the newState to Return with the updated Array
					return state.set('fieldsDictionary', fieldList)
								.set('patternsDictionary', Immutable.fromJS(patternList));

				} else {
					// If we just got Fields, then load them.
					return state.set('fieldsDictionary', fieldList);
				}
			}

			case FieldConstants.FIELD_DELETE_FROM_DATABASE:
			case FieldConstants.FIELD_DELETE_FROM_STORE:
			{
				//Waiting for FieldStore to load Data 
				AppDispatcher.waitFor([FieldStore.getDispatchToken()]);
				
				// Figure out which field we're working on
				let fieldId = action.get('recordId');

				if(!fieldId) {
					return state;
				}

				// Loop over the fieldsDirectinary, find this field, and update it.
				return state.set('fieldsDictionary', state.get('fieldsDictionary').filter(fieldDictionaryRow => {
					if(fieldDictionaryRow.get('id') === fieldId) {
						return false;
					}
					return true;
				}));
			}

			case FieldConstants.FIELD_PUSH_TO_STORE: {
				//Waiting for FieldStore to load Data 
				AppDispatcher.waitFor([FieldStore.getDispatchToken()]);

				// Figure out which field we're working on
				let fieldId = action.get('recordId'),
					fieldSettingsObj = action.get('recordProperties').toJS();

				if(!fieldId || !fieldSettingsObj || fieldSettingsObj['fieldLabel']) {
					return state;
				}
				 
				// Loop over the fieldsDirectinary, find this field, and update it.
				return state.set('fieldsDictionary', state.get('fieldsDictionary').push(
					new _SettingsRecord(
						fieldId,
						fieldSettingsObj['fieldLabel'],
						fieldSettingsObj['fieldLabel']
					)));
			}

			case FieldConstants.FIELD_PULL_FROM_DATABASE:
			{
				//Waiting for FieldStore to load Data 
				AppDispatcher.waitFor([FieldStore.getDispatchToken()]);
				
				let fieldArr = action.get('fieldArray').toJS(),
					fieldList = state.get('fieldsDictionary');

				fieldArr.forEach(fieldUpdate => {
					let fieldId = fieldUpdate.recordId,
						fieldSettingsJSON = fieldUpdate.settings,
						fieldSettingsObj = ObjectUtils.getObjFromJSON(fieldSettingsJSON),
						foundIndex = null;
					if(fieldSettingsObj && fieldSettingsObj.fieldLabel) {
						let fieldLabel = fieldSettingsObj.fieldLabel;
						fieldList.forEach((fieldListElement, index) => {
							if(fieldListElement.get('id') === fieldId) {
								foundIndex = index;
							}
						});

						if(foundIndex !== null) {
							fieldList = fieldList.set(foundIndex, 
								new _SettingsRecord(fieldId, fieldLabel, fieldLabel));
						} else {
							fieldList = fieldList.push(
								new _SettingsRecord(fieldId, fieldLabel, fieldLabel));
						}
					}
				});

				return state.set('fieldsDictionary', fieldList);
			}

			case FieldConstants.FIELD_PUSH_SETTING_TO_STORE:
			{
				//Waiting for FieldStore to load Data 
				AppDispatcher.waitFor([FieldStore.getDispatchToken()]);

				// Figure out which field we're working on
				let fieldId = action.get('recordId');
				let settingSchemaName = action.get('settingSchemaName');
				let settingValue = action.get('settingValue');

				if(!fieldId || !settingSchemaName || settingSchemaName !== 'fieldLabel') {
					return state;
				}
				 
				// Loop over the fieldsDirectinary, find this field, and update it.
				return state.set('fieldsDictionary', state.get('fieldsDictionary').map(fieldDictionaryRow => {
					if(fieldDictionaryRow['id'] === fieldId) {
						fieldDictionaryRow = fieldDictionaryRow.set('searchable', settingValue);
						fieldDictionaryRow = fieldDictionaryRow.set('label', settingValue);
					}
					return fieldDictionaryRow;
				}));
			}
			case FieldTypeConstants.FIELD_TYPE_PULL_FROM_DATABASE_ALL: {
				//Waiting for FieldTypeStore to load Data 
				AppDispatcher.waitFor([FieldTypeStore.getDispatchToken()]);

				let patternList = undefined;
				// Do we have Fields and Patterns Loaded? 
				if(PatternStore.allPulledFromDatabase() && FieldStore.allPulledFromDatabase()) { 
					// Grab the pattern list.
					patternList = _getPatternList();
				}

				//Once the other Store is updated, FieldTypes are ready to be processed. 				
				let fieldTypesRaw = Immutable.fromJS(FieldTypeStore.getAllArray());
				
				//CREATE Dictionary - fieldTypes	
				let fieldTypeList =  List();	

				//CREATE Dictionary - FieldSettings
				let fieldsSettingsList =  List();

				//Create a Settings Object Holding all the settings for all fields 
				let fieldsSettingsObj = Map();
				
				//Process the data from DB to the Dictionary format 
				fieldTypesRaw.forEach(fieldTypeObj => {

					//Builds a new Record, and takes care of validating the data coming from store
					let newFieldTypeRecord = _DictionaryRecord(fieldTypeObj, 'recordId', 'name', 'name');

					//Add it to the List of FieldTypes
					fieldTypeList = fieldTypeList.push(newFieldTypeRecord);

					//Get the nested setting Array for this field
					let fieldTypeSettings = fieldTypeObj.get('settings');

					//Is the fieldType Settings DB populated?
					if(!fieldTypeSettings === 0){
						// console.log('Cannot populate FieldType Settings Dictionary. Settings Object is empty for fieldType: ');
						return state;
					}
					
					//Process the settings from the FieldType into the Dictionary format
					if(Array.isArray(fieldTypeSettings)) {
						fieldTypeSettings.forEach(settingObj =>{
					
							//Get the fieldSchemaName to find it in the temporary FieldSettingsListObject 
							let newKey = settingObj.get('fieldSchemaName');
							
							//If the FieldsSetting is not yet included in the Field setting Object list, add it 
							if(!fieldsSettingsObj.get(newKey)) {

								//Builds a new Record, and takes care of validating the data coming from store
								let objectToSave = _DictionaryRecord(settingObj, 'fieldSchemaName', 'label', 'label');
								
								//Add it/over Write it to the fieldsSettingsObj List
								fieldsSettingsObj = fieldsSettingsObj.set(newKey, objectToSave);
							}
						}); 
					}
				});

				//Transform the FieldsSettingsList into an Array
				Object.keys(fieldsSettingsObj.toJS()).forEach(function(objectKey) {			
					fieldsSettingsList = fieldsSettingsList.push(fieldsSettingsObj.get(objectKey));
				});
				
				//Create the newState to Return with the updated Arrays 
				if(patternList) {
					// If we got patterns AND Fields, then load all three.
					return state.set('fieldTypesDictionary', fieldTypeList)
								.set('fieldSettingsDictionary', fieldsSettingsList)
								.set('patternsDictionary', Immutable.fromJS(patternList));

				} else {
					// If we just got Fields, then load them.
					return state.set('fieldTypesDictionary', fieldTypeList)
								.set('fieldSettingsDictionary', fieldsSettingsList);
				}
			}
			case PageConstants.PAGE_DELETE_FROM_DATABASE:
			case PageConstants.PAGE_DELETE_FROM_STORE:
			case PageConstants.PAGE_PULL_FROM_DATABASE:
			case PageConstants.PAGE_PULL_FROM_DATABASE_ALL: {
				//Waiting for PageStore to load Data 
				AppDispatcher.waitFor([PageStore.getDispatchToken()]);
				//Once the other Store is updated, Pages are ready to be processed. 								
				let	pagesRaw = Immutable.fromJS(PageStore.getAllArray());
				
				//Is the page DB populated?
				if(!pagesRaw){
					// console.log('Cannot populate Pages Dictionary. PageStore is empty');
					return state;
				}

				//CREATE Dictionary - Pages	
				let pagesList =  List();	
				
				pagesRaw.forEach(pageObj =>{
					//Builds a new Record, and takes care of validating the data coming from store
					let newPageRecord = _DictionaryRecord(pageObj, 'recordId', 'name', 'name');

					pagesList = pagesList.push(newPageRecord);
				});

				//Create the newState to Return with the updated Array			
				let newState = state.set('pagesDictionary', pagesList);
				return newState; 
			}
			case  RelationshipConstants.RELATIONSHIP_DELETE_FROM_DATABASE:
			case  RelationshipConstants.RELATIONSHIP_DELETE_FROM_STORE:
			case  RelationshipConstants.RELATIONSHIP_PULL_FROM_DATABASE:
			case  RelationshipConstants.RELATIONSHIP_PUSH_TO_STORE:
			case  RelationshipConstants.RELATIONSHIP_PULL_FROM_DATABASE_ALL: {
				//Waiting for RelationShipStore to load Data 
				AppDispatcher.waitFor([RelationshipStore.getDispatchToken()]);
				//Once the other Store is updated, Relationships are ready to be processed. 								
				let	relationshipRaw = Immutable.fromJS(RelationshipStore.getAllArray());
				
				//Is the page DB populated?
				if(!relationshipRaw){
					// console.log('Cannot populate Relationships Dictionary. RelationshipStore is empty');
					return state;
				}

				//CREATE Dictionary - Relationships	
				let relationshipsList =  List();

				relationshipRaw.forEach(relObj =>{

					//Builds a new Record, and takes care of validating the data coming from store
					let newRelationshipRecord = _DictionaryRecord(relObj, 'recordId', 'label', 'label');

					relationshipsList = relationshipsList.push(newRelationshipRecord);
				});

				//Create the newState to Return with the updated Array		
				let newState = state.set('relationshipsDictionary', relationshipsList);
			
				return newState; 
			}
			default: {
				return state;
			}
		}
	}
	/**
	 * Gets the avialable Dictionaries in the Store
	 * 
	 * @returns {Object} - Object of arrays containing all the dictionaries
	 *  
	 * @memberOf NLPBuilderDictionariesStore
	 */
	getAllDictionaries(){
		let state = this.getState();
		return state.toJS();
	}
	/**
	 * Gets the avialable Dictionary for Tables 
	 * 
	 * @returns {Array} - Array of tables with properities: id/label/searchable
	 * 
	 * @memberOf NLPBuilderDictionariesStore
	 */
	getTables(){
		let state = this.getState();
		return state.get('tablesDictionary').toJS();
	}
	/**
	 * Gets the avialable Dictionary for Fields 
	 * 
	 * @returns {Array} - Array of fields with properities: id/label/searchable
	 * 
	 * @memberOf NLPBuilderDictionariesStore
	 */
	getFields(){
		let state = this.getState();
		return state.get('fieldsDictionary').toJS();
	}
	/**
	 * Gets the available Dictionary for FieldTypes
	 * 
	 * @returns {Array} - Array of fieldTypes with properities: id/label/searchable
	 * 
	 * @memberOf NLPBuilderDictionariesStore
	 */
	getFieldTypes(){
		let state = this.getState();
		return state.get('fieldTypesDictionary').toJS();
	}
	/**
	 * Gets the available Dictionary for FieldSettings 
	 * 
	 * @returns {Array} - Array of FieldSettings with properities: id/label/searchable
	 * 
	 * @memberOf NLPBuilderDictionariesStore
	 */
	getFieldTypeSettings(){
		let state = this.getState();
		return state.get('fieldSettingsDictionary').toJS();
	}
	/**
	 * Gets the available Dictionary for Pages 
	 * 
	 * @returns {Array} - Array of Pages with properities: id/label/searchable
	 * 
	 * @memberOf NLPBuilderDictionariesStore
	 */
	getPages(){
		let state = this.getState();
		return state.get('pagesDictionary').toJS();
	}
	/**
	 * Gets the available Dictionary for Patterns 
	 * 
	 * @returns {Array} - Array of Patterns with properities: id/label/searchable
	 * 
	 * @memberOf NLPBuilderDictionariesStore
	 */
	getPatterns(){
		let state = this.getState();
		return state.get('patternsDictionary').toJS();
	}
	/**
	 * Gets the available Dictionary for Patterns filtered its FieldType
	 * 
	 * @param {string} fieldTypeId 
	 * @returns {Object[]}
	 * @memberof NLPBuilderDictionariesStore
	 */
	getFieldPatternsByFieldTypeId(fieldTypeId) {
		let state = this.getState(),
			patternsDict = state.get('patternsDictionary').toJS(),
			patterns = [];
		patternsDict.forEach(patternsDictEntry => {
			if(patternsDictEntry.type === 'field' && patternsDictEntry.fieldType === fieldTypeId) {
				patterns.push(patternsDictEntry);
			}
		});
		return patterns;
	}
	/**
	 * Gets Patterns of any type, filtered by key and optionally by blockType
	 * 
	 * @param {string} key 
	 * @param {string} value 
	 * @param {string} blockType - Optional (field/Form)
	 * @returns {Object[]}
	 * @memberof NLPBuilderDictionariesStore
	 */
	getPatternsByKeyAndBlockType(key, value, blockType){

		//Get all the Patterns 
		let state = this.getState(),
		patterns = state.get('patternsDictionary').toJS();

		//Restrcited by blockType? 
		if(blockType) {
			patterns =  patterns.filter(patternsDictEntry => {
				return patternsDictEntry.type === blockType;
			});
		}

		//Return the patterns by Key Type and expected Value
		patterns = patterns.filter(patternsDictEntry => {
			return patternsDictEntry[key] === value;
		});

		return patterns;
	}
	/**
	 * Gets the available Dictionary for Relationships  
	 * 
	 * @returns {Array} - Array of Relationships with properities: id/label/searchable
	 * 
	 * @memberOf NLPBuilderDictionariesStore
	 */
	getRelationships(){
		let state = this.getState();
		return state.get('relationshipsDictionary').toJS();
	}
}

/**
 * Returns an instance of DictionaryRecord that contains properties: id/label/searchable
 * 
 * @export 
 * @param {String} id -the id of the record
 * @param {String} label - the label of the record 
 * @param {String} searchable - searchable term of the record, at the moment is the same as label
 * @returns {Immutable} - a new Immutable Record for dictionaries.
 */
function _DictionaryRecord(metadata, id, label, searchable){

	//Step 1: Build Record variables and Validate values
	let recordId = metadata.get(id) ? metadata.get(id) : '';
	let recordLabel = metadata.get(label) ? metadata.get(label) : '';
	let recordSearchable = metadata.get(label) ? _removeWordsToIgnore(metadata.get(label)) : '';

	//Step 2: Return a new Dicitonary Record 
	return new DictionaryRecord({
		id: recordId,
		label: recordLabel,
		searchable: recordSearchable
	});
}

/**
 * Process Pattern Store.  This is done in a child function because the Pattern or Field reduce functions
 * could run second, and this way the logic is in one place.
 * 
 * @returns {Array}
 */
function _getPatternList() {
	let patternsArr = PatternStore.getAllArray();
	
	//Is the pattern DB populated?
	if(!patternsArr){
		// console.log('Cannot populate Patterns Dictionary. PatternStore is empty');
		return [];
	}

	//CREATE Dictionary
	let patternList = [];	
	//Process the data from DB to the Dictionary format 
	patternsArr.forEach(patternObj => {
		let patternType = patternObj.type,
			newPattern = {};

			switch(patternType) {
			case 'field': {
				if(!patternObj.recordId || !patternObj.displayName) { 
					break;
				}

				let patternSettingsObj = ObjectUtils.getObjFromJSON(patternObj.settings);
				
				newPattern.id = patternObj.recordId;
				newPattern.label = patternObj.displayName ? patternObj.displayName : '';
				newPattern.searchable =  (patternObj.keywords ? DictionariesUtils.getKeywordsArray(patternObj.keywords) : []);
				newPattern.type = 'field';
				newPattern.subType = patternSettingsObj.fieldType;
				newPattern.operation = (patternObj && patternObj.operation) ? patternObj.operation : '';
				newPattern.settingSynonyms = [];
				newPattern.NLPValueSettingSchemaName = false;

				let patternSettings = {};
				// Get the pattern's settings
				try {
					patternSettings = JSON.parse(patternObj.settings);
				} catch (e) { 
					console.warn('Invalid Field Pattern Settings JSON:', patternObj.settings);
					break;
				}

				// Lookup the settings and field type for our pattern.
				let	fieldSettings = patternSettings.fields,
					fieldType = patternSettings.fieldType;

				// If there is no field type defined, then skip!
				if(!fieldType) {
					break;
				}

				newPattern.fieldType = fieldType;

				// Get the Field Type details for this patterns' field type.
				let fieldTypeObj = FieldTypeStore.get(fieldType),
					fieldTypeSettings = [];

				// If we have the object...
				if(fieldTypeObj) {

					// Grab the Field Type's settings.
					fieldTypeSettings = fieldTypeObj.settings;

					// Loop over all of this pattern's fields.
					if(Array.isArray(fieldSettings) && Array.isArray(fieldTypeSettings)) {
					
						fieldSettings.forEach(fieldSetting => {
							// Add this setting to the synonyms if it's being affected by this pattern.
							if(fieldSetting.action !== 'noChange') {
								newPattern.settingSynonyms.push(fieldSetting.fieldSchemaName);
							}

							// If this setting is setToNLP...
							if(!newPattern.NLPValueSettingSchemaName && fieldSetting.action === 'setToNLP') {
								
								// Grab the setting's schema name
								newPattern.NLPValueSettingSchemaName = fieldSetting.fieldSchemaName;

								// @todo Test
								let fieldtypeSettingRecordId = FieldStore.getFieldId(fieldSetting.fieldSchemaName, 'fields');
								
								if(fieldtypeSettingRecordId){

									let settingDetails = FieldStore.get(FieldStore.getFieldId(fieldSetting.fieldSchemaName, 'fields'));

									// If we found it..
									if(settingDetails) {
										// and it's fieldType is number
										if(settingDetails.fieldType === "867b0b3b-f076-4fbc-b979-f1c5e7cb495f") {
											
											// Make sure we get a number
											newPattern.NLPValueSettingClassification = 'number';
											
										} else if(settingDetails.fieldType === "6e5b3bca-88bc-4db8-bd5d-531890660e49") {
											
											// Make sure we get a color
											newPattern.NLPValueSettingClassification = 'color';
										} else {
											// Otherwise, accept a string.
											newPattern.NLPValueSettingClassification = 'string';
										}
									} else {
										// We didnt find this setting (anymore), denote it somehow.
										newPattern.NLPValueSettingClassification = 'Not Found';
									}
								}
							}
						});
					}
				}

				break;
			}
			case 'form': {
				if(!patternObj.recordId || !patternObj.displayName) { 
					break;
				}

				newPattern.id = patternObj.recordId;
				newPattern.label = patternObj.displayName ? patternObj.displayName : '';
				newPattern.searchable = (patternObj.keywords ? DictionariesUtils.getKeywordsArray(patternObj.keywords) : []);
				newPattern.type = 'form';
				newPattern.subType = 'form';
				newPattern.operation = (patternObj && patternObj.operation) ? patternObj.operation : '';

				break;
			}
			case 'page' : {
				newPattern.id = patternObj.recordId;
				newPattern.label = patternObj.displayName ? patternObj.displayName : '';
				newPattern.searchable = (patternObj.keywords ? DictionariesUtils.getKeywordsArray(patternObj.keywords) : []);
				newPattern.type = 'page';
				newPattern.subType = 'page';
				newPattern.operation = (patternObj && patternObj.operation) ? patternObj.operation : '';
				break;
			}
			case 'table' : {
				newPattern.id = patternObj.recordId;
				newPattern.label = patternObj.displayName ? patternObj.displayName : '';
				newPattern.searchable = (patternObj.keywords ? DictionariesUtils.getKeywordsArray(patternObj.keywords) : []);
				newPattern.type = 'table';
				newPattern.subType = 'table';
				newPattern.operation = (patternObj && patternObj.operation) ? patternObj.operation : '';
				break;
			}
			default: {
				console.warn('Skipping pattern: unsupported pattern type from:', patternObj);
			}
		}

		// Collect the patterns
		patternList.push(newPattern);
	});

	// Return them.
	return patternList;
}

/**
 * Removes the wordsToIgnore from the expected Searchable term of the Dictionary Record 
 * 
 * @param {String} searchable - string to which the wordsToIgnore are going to be removed
 */
function _removeWordsToIgnore(searchable){
	//STEP 1: Convert Searchable String to Array, to loop over each word 
	if(!searchable) {
		return '';
	}
	let searchableArray = searchable.split(' ');
	//STEP 2: Compare each word of the Searchable Array against the words to Ignore
	for(let i = searchableArray.length; i >= 0; i--) {
		let searchWord = searchableArray[i];
		if(wordsToIgnore.includes(searchWord)) {
			searchableArray.splice(i, 1);
		}
	}
	//STEP 4: Convert the array back to a string and return it; 
	return searchableArray.join(' '); 
}

/**
 * Returns an instance of DictionaryRecord for Settings containing properties: id/label/searchable
 * 
 * @export 
 * @param {String} id -the id of the record
 * @param {String} label - the label of the record 
 * @param {String} searchable - searchable term of the record, at the moment is the same as label
 * @returns {Immutable} - a new Immutable Record for dictionaries.
 */
function _SettingsRecord(id, label, searchable){

	//Step 1: Validate values
	let recordId = id ? id : '';
	let recordLabel = label ? label : '';
	let recordSearchable = searchable ? _removeWordsToIgnore(searchable) : _removeWordsToIgnore(label);
	
	//Step 2: Return a new Dicitonary Record 
	return new DictionaryRecord({
		id: recordId,
		label: recordLabel,
		searchable: recordSearchable
	});
}

const instance = new NLPBuilderDictionariesStore(AppDispatcher);
export default instance;