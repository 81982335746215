import AppDispatcher from '../dispatcher/app-dispatcher';
import {ReduceStore} from 'flux/utils';
import Immutable from 'immutable';
import ToolboxConstants from '../constants/toolbox-constants';
// import UIUtils from '../utils/ui-utils';

/**
 * Store to contain the blockly toolbox objects
 *
 * @class ToolboxStore
 * @extends {ReduceStore}
 */
class ToolboxStore extends ReduceStore {
	/**
	 * Initial state for ToolboxStore
	 *
	 * @memberOf ToolboxStore
	 */
	getInitialState() {
		return Immutable.fromJS({'actionToolbox': [], 'expressionToolbox': [], 'visibilityToolbox': []});
	}

	/**
	 * Get the action toolbox as a javascript object
	 *
	 * @returns {Object} Object of Categories
	 *
	 * @memberOf ToolboxStore
	 */
	getActionToolboxJS() {
		return this.getState().get('actionToolbox').toJS();
	}

	/**
	 * Get the action toolbox
	 *
	 * @returns {Object} Object of Categories
	 *
	 * @memberOf ToolboxStore
	 */
	getActionToolbox() {
		return this.getState().get('actionToolbox');
	}

	/**
	 * Get the Action Toolbox for API's
	 * @returns Toolbox Object
	 */
	getActionToolboxAPIJS() {
		let toolbox = this.getActionToolboxJS();
		toolbox.push({
			name: 'Response',
			style: 'response_category',
			blocks: [
				{'type': 'citdev_api_response_header'},
				{'type': 'citdev_api_response_code'},
				{'type': 'citdev_api_response'}
			]
		});
		return toolbox;
	}
	
	/**
	 * Get the expression toolbox
	 *
	 * @returns {Immutable.List} Immutable List of Categories
	 *
	 * @memberOf ToolboxStore
	 */
	getExpressionToolbox() {
		return this.getState().get('expressionToolbox');
	}

	/**
	 * Get the visibility toolbox
	 *
	 * @returns {Object} Object of Categories
	 *
	 * @memberOf ToolboxStore
	 */
	getVisibilityToolboxJS() {
		return this.getState().get('visibilityToolbox').toJS();
	}
	/**
	 * Get the visibility toolbox
	 *
	 * @returns {Object} Object of Categories
	 *
	 * @memberOf ToolboxStore
	 */
	getVisibilityToolbox() {
		return this.getState().get('visibilityToolbox');
	}
 
	
	/**
	 * Updates state store
	 *
	 * @param {Object} Current state
	 * @param {Object} action
	 * @returns {Object} updated state
	 *
	 * @memberOf ToolboxStore
	 */
	reduce(state, action) {
		switch (action.get('type')) {
			// When a page is pulled from the database.. set it's modes.
			case ToolboxConstants.ACTION_TOOLBOX_LOAD: {

				let newState = state.set('actionToolbox', action.get('actionToolbox'));

				return newState;
			}
			case ToolboxConstants.EXPRESSION_TOOLBOX_LOAD: {
				
				let newState = state.set('expressionToolbox', action.get('expressionToolbox'));

				return newState;
			}
			case ToolboxConstants.VISIBILITY_TOOLBOX_LOAD: {
				
				let newState = state.set('visibilityToolbox', action.get('visibilityToolbox'));

				return newState;
			}
			
			default: {
				return state;
			}
		}
	}
}

const instance = new ToolboxStore(AppDispatcher);
export default instance;