/* global citDev */
import RenderStore from '../stores/render-store';
import PageStore from '../stores/page-store';
import ContextStore from '../stores/context-store';
import AuthenticationStore from '../stores/authentication-store';
import FieldStore from '../stores/field-store';
import FieldTypeStore from '../stores/field-type-store';
import FieldSettingsStore from '../stores/field-settings-store';
import FieldModesStore from '../stores/field-modes-store';
import ObjectUtils from '../utils/object-utils';
// import FieldComponentUtils from '../utils/field-components';
import uuid from 'uuid';

const GridHeightUtils = {
	/**
	 * Recursively recalculate the attached fields and their positions for an input of grids
	 * @param {array} toRecalc Array of objects to recalculate
	 * @param {array} activeOverlays The overlays which are currently actve
	 * @param {array} grids The array for the grids to be updated and returned
	 * @returns 
	 */
	recalcAttachedFields(toRecalc, activeOverlays, grids, rerunQueries, omitRepeating, resetHeights, mode, ignoreEmptyChildren) {
		// We need to determine if any of these are repeating grid fields
		// and recalculate them appropriately if so
		// let rowHeight = 12;
		grids = grids || [];
		let FieldComponentUtils = require('../utils/field-components').default;
		return new Promise((resolve, reject) => {
			// Base case
			if(!toRecalc || !toRecalc.length) {
				return resolve(grids);
			}
			try {
				let recalcPromises = toRecalc.map((item, index) => {
					let {
						attachedFields,
						attachmentId,
						attachmentKey,
						renderId: parentRenderId,
						renderParentId: grandparent,
						componentId,
						componentType,
						dataRecordId,
						dataTableSchemaName,
						fieldPosition,
						fieldPositionExtras,
						gridLayoutHeights,
						availableModes,
						gridInfo
					} = item;

					let renderObj = RenderStore.get(parentRenderId);
					let parentRenderObj = RenderStore.get(grandparent);
					let parentComponentId = parentRenderObj && parentRenderObj.componentId
						? parentRenderObj.componentId
						: undefined;
					let componentObj = componentType === 'field' ? FieldStore.get(componentId) : PageStore.get(componentId);
					if(componentType === 'field') {
						let componentSettings = attachmentId
							? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, componentId, parentComponentId)
							: FieldSettingsStore.getSettings(componentId, parentComponentId);
						Object.assign(componentObj, componentSettings);
					}

					// If we're refreshing a field
					// then we're skipping any empty children
					// so that it doesn't populate anything in the render store that shouldn't already be there
					// (Ticket 26576 - Required Error for Field with Value)/
					if(ignoreEmptyChildren && (!renderObj || !renderObj.children || !renderObj.children.length)) {
						attachedFields = [];
					}

					if(!omitRepeating && renderObj && renderObj.repeatingGrid) {
						// We need to get some information from the component object
						return GridHeightUtils.recalcRepeatingGrid({
							attachmentId,
							renderId: parentRenderId,
							renderParentId: grandparent,
							componentId,
							componentType,
							dataRecordId,
							dataTableSchemaName,
							gridLayoutHeights, // We don't need this but it does need to be on the object in grids
							gridItemHeight: componentObj.gridItemHeight,
							gridItemWidth: componentObj.gridItemWidth,
							availableModes: FieldModesStore.getAvailableModes(parentRenderId),
							attachedFields: componentObj.attachedFields ? JSON.parse(componentObj.attachedFields) : [],
							fieldPosition: componentObj.fieldPosition ? JSON.parse(componentObj.fieldPosition) : {},
							fieldPositionExtras: componentObj.fieldPositionExtras ? JSON.parse(componentObj.fieldPositionExtras) : {},
							query: componentObj.query
						}, activeOverlays, rerunQueries, ignoreEmptyChildren)
							.then(({grids, fields, rows}) => {
								let grid = grids[0];
								if(!omitRepeating) {
									grid.grids = grids.slice(1);
									grid.fields = fields;
									grid.rows = rows;
								}
								return grid;
							});
					} else {

						// First rerun any record sets for the item
						// These should always be available as stored record sets to refresh
						// We also need to run the query if there is one AND it's a container
						// We don't need to run other query settings here.
						// (Temp hardcoded, awaiting a flexible solution)
						// @TODO: We can also run expression settings in here when we have more time
						// it might help load some of our many-button pages faster, too.
						// @TODO: I'm eventually envisioning additions to each field type variant
						// to add in logic for special handling here
						// that we can then precompile and look up
						// in order to avoid any unnecessary
						// dispatches in dispatches in componentDidUpdate

						let queryPromise = Promise.resolve({rows: [{
							recordId: dataRecordId,
							tableSchemaName: dataTableSchemaName
						}]});
						let fieldDict = {};
						let lookupFields = [];
						// We need to skip this for the immediate children of repeating grids
						let queryShouldRun = rerunQueries && (!parentRenderObj || !parentRenderObj.repeatingGrid) && componentType === 'field' && componentObj.fieldType === '7ebd9251-675c-4129-95e3-6b8e31c135a2' && componentObj.query;
						if(queryShouldRun) {
							attachedFields.forEach(field => {
								let recordIds = field.recordIds ? field.recordIds : [field.recordId];
								recordIds.forEach(recordId => {
									if(!fieldDict[recordId]) {
										let fieldObj = FieldStore.get(recordId) || {};
										fieldObj.fieldId = recordId;
										fieldDict[recordId] = fieldObj;
									}
								});
							});
							lookupFields = Object.keys(fieldDict).map(recordId => fieldDict[recordId]);
							queryPromise = FieldComponentUtils.query.processQueryV2(componentObj.query, lookupFields, parentRenderId, parentRenderObj && parentRenderObj.dataRecordId ? parentRenderObj.dataRecordId : dataRecordId, parentRenderObj && parentRenderObj.dataTableSchemaName ? parentRenderObj.dataTableSchemaName : dataTableSchemaName);
						} else if (dataRecordId && dataTableSchemaName) {
							let RecordStore = require('../stores/record-store').default;
							let FieldTypeStore = require('../stores/field-type-store').default;
							// let RecordsAPI = require('../apis/records-api').default;
							//We should make sure the other fields are looked up at this point as well. (Ticket 24285)
							// Only look up values we don't already have
			
							attachedFields.forEach(field => {
								let recordIds = field.recordIds ? field.recordIds : [field.recordId];
								recordIds.forEach(recordId => {
									let fieldHasData = FieldStore.getHasData(recordId);
									let recordData = RecordStore.getRecord(dataTableSchemaName, dataRecordId);
			
									if(!fieldDict[recordId]) {
										let fieldObj = FieldStore.get(recordId) || {};
										if(!fieldHasData) {
											// Also get dynamic selection fields
											let fieldTypeObj = fieldObj ? FieldTypeStore.get(fieldObj.fieldType) : {};
											fieldHasData = fieldTypeObj && fieldTypeObj.dataType === 'relationship';
										}
										// @TODO: Does/can this handle empty field values?
										// If our field requires data && we don't yet have ANY data... || we have data, we just don't have THIS piece of data..
										if (fieldHasData && (!recordData || typeof recordData[fieldObj.fieldSchemaName] === 'undefined')) {
											fieldObj.fieldId = recordId;
											fieldDict[recordId] = fieldObj;
										}
									}
								});
							});
			
							// @TODO: I'd prefer to do some kind of bulk lookup for this and dispatch it
							// alongside everything else when we have the time
							lookupFields = Object.keys(fieldDict).map(recordId => fieldDict[recordId]);
							// if(lookupFields.length) {
							// 	let fieldSchemaNamesToGet = lookupFields.map(({fieldSchemaName}) => fieldSchemaName);
							// 	RecordsAPI.getRecord(dataTableSchemaName, dataRecordId, fieldSchemaNamesToGet);
							// }
						}

						return queryPromise
							.then(queryResult => {
								if(queryResult && queryResult.rows && queryResult.rows[0]) {
									dataRecordId = queryResult.rows[0].recordId;
									dataTableSchemaName = queryResult.rows[0].tableSchemaName;
								} else {
									dataRecordId = '';
									dataTableSchemaName = FieldComponentUtils.query.getReturnTable(componentObj.query);
								}
								if(queryShouldRun) {
									item.actionRows = (queryResult && queryResult.rows) || []
								}
								item.dataRecordId = dataRecordId;
								item.dataTableSchemaName = dataTableSchemaName;
								// We also need to update the attachedField value matching this grid if it exists
								// since otherwise that can overwrite it
								let parentGrid = grids.find(grid => grid.parentRenderId === grandparent);
								if(parentGrid && parentGrid.attachedFields) {
									let attachedField = parentGrid.attachedFields.find(field => field.attachmentId === attachmentId);
									if(attachedField) {
										let matchingRenderObj = RenderStore.findChildRenderIdFromAttachment(parentGrid.renderId, attachedField.fieldId, attachedField.attachmentId)
										if(!matchingRenderObj || !matchingRenderObj.protectDataRecordId) {
											attachedField.dataRecordId = dataRecordId;
											attachedField.dataTableSchemaName = dataTableSchemaName;
										}
										if(matchingRenderObj && matchingRenderObj.attachmentKey && !attachedField.attachmentKey) {
											// Preserve attachmentKeys
											attachedField.attachmentKey = matchingRenderObj.attachmentKey;
										}
									}
								}
								// We do not use modes here because it turns out that is not desired behavior
								return FieldComponentUtils.visibility.runAttachedFieldVisibility(attachedFields, ['view', 'edit'], parentRenderId, componentId, dataRecordId, dataTableSchemaName, undefined, componentType, activeOverlays, mode);
							})
							.then(newAttachedFields => {
								let fieldPositionsBySize = {};
								let columnYTracker = {};
								let hasExpanding = {};
								let spacerHeights = {};
	
								if(!gridLayoutHeights) {
									// Get the RenderStore value
									let renderObj = RenderStore.get(parentRenderId);
									gridLayoutHeights = renderObj && renderObj.gridLayoutHeights ? renderObj.gridLayoutHeights : undefined;
								}

								newAttachedFields.forEach(attachedField => {
									let gridInfo = {};
									let attachmentId = attachedField.attachmentId;
									let renderId = attachedField.renderId;
									let renderObj = RenderStore.get(renderId) || {};
									if(renderObj.protectDataRecordId) {
										attachedField.dataRecordId = renderObj.dataRecordId;
										attachedField.dataTableSchemaName = renderObj.dataTableSchemaName;
									}
									if(renderObj.attachmentKey && !attachedField.attachmentKey) {
										// Preserve attachmentKeys
										attachedField.attachmentKey = renderObj.attachmentKey;
									}
									// The format of newAttachedFields uses fieldId instead of recordId
									let gridKey = attachmentId || attachedField.recordId || attachedField.fieldId;
	
									Object.keys(fieldPosition).forEach(screensize => {
										columnYTracker[screensize] = columnYTracker[screensize] || new Array(12);
										fieldPositionsBySize[screensize] = fieldPositionsBySize[screensize] || [];
	
										gridInfo[screensize] = {};
										let positionsForSize = fieldPosition[screensize];
										let fieldPositionExtrasObj = {};
										if(fieldPositionExtras && fieldPositionExtras[screensize]) {
											Object.keys(fieldPositionExtras[screensize]).forEach(index => {
												fieldPositionExtrasObj[fieldPositionExtras[screensize][index].i] = fieldPositionExtras[screensize][index];
											});
										}
										let fieldPositionDict = {};
										if(Array.isArray(positionsForSize) && positionsForSize.length) {
											positionsForSize.forEach(positionObj => {
												fieldPositionDict[positionObj.i] = positionObj;
											});
										}
			
										let gridInfoForSize = fieldPositionDict[gridKey];
										let gridExtras = fieldPositionExtrasObj[gridKey];
										if(gridInfoForSize) {
	
											// This value accidentally made it into some locations on dev-eng
											// Originally, "originalH" was named "minH," which it turns out is
											// a reserved value in our grid provider
											// So clear out any old values
											delete gridInfoForSize.minH;
											
											// Here's where we need to set grid autosizing/expansion info
											// but we don't calculate anything at this point
			
											gridInfoForSize.originalH = gridInfoForSize.h;
											gridInfoForSize.originalY = gridInfoForSize.y;
											gridInfoForSize.autofit = gridExtras && gridExtras.autofit === 'true';
											gridInfoForSize.expands = gridExtras && gridExtras.expands === 'true';
	
											let renderGridInfo = renderObj && renderObj.gridInfo && renderObj.gridInfo[screensize] ? renderObj.gridInfo[screensize] : {};
	
											// Because this is initializing and nothing's measured yet, I don't think we track
											// field position pushing here either
	
											if(gridInfoForSize.autofit) {
												gridInfoForSize.h = resetHeights ? gridInfoForSize.originalH : Math.max(gridInfoForSize.originalH, renderGridInfo.h || 0);
											}
	
											fieldPositionsBySize[screensize].push(gridInfoForSize);
	
											// Grid information tracking
											let left = gridInfoForSize.x;
											let right = gridInfoForSize.x + gridInfoForSize.w;
	
											for (let i = left; i < right; i++) {
												columnYTracker[screensize][i] = columnYTracker[screensize][i] || [];
												columnYTracker[screensize][i].push(gridInfoForSize);
											}
										}
										gridInfo[screensize] = gridInfoForSize;
									});
									attachedField.gridInfo = gridInfo;
								});
	
								Object.keys(columnYTracker).forEach(screensize => {
									columnYTracker[screensize].forEach((col, index) => {
										col = col.sort((a, b) => {
											return a.y - b.y;
										});
										columnYTracker[screensize][index] = col;
									});
								});
	
								Object.keys(columnYTracker).forEach(screensize => {
									// Now that this is all built, reconcile the grid shifts
									let {hasExpanding: hasExpandingForSize, columnSpacers} = GridHeightUtils.reconcileGridShifts(gridLayoutHeights && gridLayoutHeights[screensize] ? gridLayoutHeights[screensize] : 0, columnYTracker[screensize], fieldPositionsBySize[screensize]);
									hasExpanding[screensize] = hasExpandingForSize;
									spacerHeights[screensize] = columnSpacers;
								});
	
								// Grid entry for this field
								let toPush = {
									attachmentId: attachmentId,
									attachmentKey: attachmentKey,
									parentRenderId: parentRenderId,
									grandparent: grandparent,
									dataRecordId,
									dataTableSchemaName,
									componentId,
									componentType,
									attachedFields: newAttachedFields,
									gridLayoutHeights: gridLayoutHeights,
									hasExpanding: hasExpanding,
									spacerHeights: spacerHeights,
									availableModes: availableModes // @TODO: Is this right?
								};
								if(item.actionRows) {
									toPush.actionRows = item.actionRows;
								}
								if(gridInfo) {
									toPush.gridInfo = gridInfo;
								}
								return toPush;
							});
					}
				});

				Promise.all(recalcPromises)
					.then((newGrids) => {
						// Loop over newGrids and update the existing grids appropriately
						let endingGrids = [];
						newGrids.forEach(grid => {
							if(!grid) {
								return;
							}
							// Children of repeating grids go at the end
							if(grid.grids && !omitRepeating) {
								endingGrids = endingGrids.concat(grid.grids);
							}
							delete grid.grids;
							// Avoid duplicates
							if(grids.findIndex(exGrid => exGrid.parentRenderId === grid.parentRenderId) === -1) {
								grids.push(grid);
							}
						});
						let nextGridLayer = GridHeightUtils.getNextGridLayer(toRecalc, grids, omitRepeating);

						// Avoid duplicates
						endingGrids.forEach(grid => {
							if(grids.findIndex(exGrid => exGrid.parentRenderId === grid.parentRenderId) === -1) {
								grids.push(grid);
							}
						});
						// The promises themselves push into the grids object
						// so we don't need to worry about them here
						// @TODO: How do we make sure that the grids have the right order, though? Does that matter?
						
						return GridHeightUtils.recalcAttachedFields(nextGridLayer, activeOverlays, grids, rerunQueries, omitRepeating, resetHeights, mode, ignoreEmptyChildren);
					})
					.then((grids) => {
						resolve(grids);
					})
					.catch(reject);
			} catch(err) {
				return reject(err);
			}
		});
	},
	/**
	 * Calculates the repeated grids and positions for a 
	 * repeating grid.
	 * 
	 * @param {object} parentGrid The parent repeating grid's information
	 * @param {array} rows The record data used to create each repeated grid
	 * @returns 
	 */
	recalcRepeatingGrid(parentGrid, activeOverlays, rerunQueries,ignoreEmptyChildren) {
		return new Promise((resolve, reject) => {
			// let rowHeight = 12;
			let {
				renderId: parentRenderId,
				renderParentId: grandparent,
				componentId,
				componentType,
				dataRecordId,
				dataTableSchemaName,
				gridLayoutHeights, // We don't need this but it does need to be on the object in grids
				gridItemHeight,
				gridItemWidth,
				availableModes,
				attachedFields,
				fieldPosition,
				fieldPositionExtras,
				attachmentId,
				query
			} = parentGrid;

			let parentRenderObj = RenderStore.get(parentRenderId);
			let children = parentRenderObj && parentRenderObj.children ? parentRenderObj.children : [];

			gridItemHeight = gridItemHeight && !isNaN(gridItemHeight) ? +gridItemHeight : 4;
			gridItemWidth = gridItemWidth && !isNaN(gridItemWidth) ? +gridItemWidth : 12;

			// Record set information
			let queryFields = {};
			let recordFields = {};
			let fields = {};

			attachedFields.forEach(field => {
				let fieldIds = field.recordIds ? field.recordIds : [field.recordId];
				let fieldSettings = field.attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(field.attachmentId, undefined, componentId) : FieldSettingsStore.getSettings(field.recordId, componentId);
				fieldIds.forEach(fieldId => {
					let fieldObj = FieldStore.get(fieldId);
					if (fieldObj) {
						let field = {
							fieldSchemaName: fieldObj.fieldSchemaName,
							fieldType: fieldObj.fieldType,
							recordId: fieldObj.recordId
						};
						recordFields[field.recordId] = field;

						if(query) {
							let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType);
							let fieldTypeSettings = fieldTypeObj ? fieldTypeObj.settings : [];
		
							//Loop over the SETTING and if one is an EXPRESSION, and check for single field optimizations
							fieldTypeSettings.forEach(fieldTypeSetting => {
								let settingId = fieldTypeSetting.recordId;
		
								let settingObj = FieldStore.get(settingId),
									settingFieldSchemaName = settingObj ? settingObj.fieldSchemaName : '';
		
								if (settingObj && settingObj.fieldType &&
									settingObj.fieldType === 'd7183192-d4d0-42e9-a1f6-78f3984cef8c' && // Expression Setting
									settingFieldSchemaName &&
									fieldSettings[settingFieldSchemaName]) {
		
		
									let expressionObj = ObjectUtils.getObjFromJSON(fieldSettings[settingFieldSchemaName]);
									if (expressionObj && expressionObj.optimizationScheme &&
										expressionObj.optimizationScheme === 'singleField' &&
										expressionObj.optimizationData &&
										expressionObj.optimizationData.fieldData) {
		
										queryFields[expressionObj.optimizationData.fieldData.fieldId] = {
											fieldSchemaName: expressionObj.optimizationData.fieldData.fieldSchemaName,
											fieldType: expressionObj.optimizationData.fieldData.fieldTypeId,
											fieldId: expressionObj.optimizationData.fieldData.fieldId
										};
									}
								}
							});
						}
					}
				});
			});

			let rows;
			let recordSetPromise = Promise.resolve({
				rows: [{ recordId: dataRecordId, tableSchemaName: dataTableSchemaName }]
			});

			if(query) {
				let FieldComponentUtils = require('../utils/field-components').default;
				let fieldsObj = Object.assign(recordFields, queryFields);
				fields = Object.keys(fieldsObj).map(fieldId => fieldsObj[fieldId]);
				// The "current record" in this query corresponds to the PARENT'S current record
				let parentRenderEntry = RenderStore.get(grandparent);
				recordSetPromise = FieldComponentUtils.query.processQueryV2(query, fields, parentRenderId, parentRenderEntry ? parentRenderEntry.dataRecordId : undefined, parentRenderEntry ? parentRenderEntry.TableSchemaName : undefined);
			}

			recordSetPromise
				.then(queryResult => {
					rows = queryResult && queryResult.rows ? queryResult.rows : [];

					let grids = [{
						parentRenderId,
						grandparent,
						componentId,
						componentType,
						dataRecordId,
						dataTableSchemaName,
						gridLayoutHeights,
						availableModes,
						repeatingGrid: true,
						attachmentId
					}];
		
					let currentGridRow = 0;
					let itemsInCurrentGridRow = 0;

					

					// Calculate these grids
					let toRecalc = rows.map((record, idx) => {

						// @TODO: This can actually be overridden by screensize:
						// we need to update gridItemHeight and gridItemWidth to cover
						// all screensizes for which it has a value
						// and then update each one appropriately
						// But I think this can be backlogged for now
						// because it will be recalculated already in most instances
						// when the screensize changes
						let defaultGridInfo = {
							originalH: +gridItemHeight,
							h: +gridItemHeight,
							w: +gridItemWidth,
							x: itemsInCurrentGridRow * gridItemWidth,
							y: currentGridRow * gridItemHeight,
							originalY: currentGridRow * gridItemHeight,
							i: record.recordId + '=>' + componentId,
							isResizable: idx === 0
						};
						let renderId = RenderStore.findChildRenderId(parentRenderId, componentId, record.recordId + '=>' + componentId);
						let renderObj = renderId ? RenderStore.get(renderId) : undefined;
						renderObj = renderObj || {
							renderId: uuid.v4(),
							gridInfo: {
								lg: defaultGridInfo,
								md: defaultGridInfo,
								sm: defaultGridInfo
							}
						}
						// Make sure these values are always set
						renderObj.componentId = componentId;
						renderObj.componentType = 'field';
						renderObj.dataRecordId = record.recordId;
						renderObj.dataTableSchemaName = record.tableSchemaName;
						renderObj.renderParentId = parentRenderId;
						renderObj.attachmentKey = record.recordId + '=>' + componentId;
						renderObj.availableModes = availableModes;
		
						if(!renderObj.gridInfo) {
							renderObj.gridInfo = {
								lg: defaultGridInfo,
								md: defaultGridInfo,
								sm: defaultGridInfo
							};
						}
		
						// We need to respect changes in gridItemWidth and gridItemHeight
						['lg', 'md', 'sm'].forEach(size => {
							if(renderObj.gridInfo[size].originalH !== gridItemHeight || renderObj.gridInfo[size].originalY !== defaultGridInfo.originalY) {
								// We've changed the height and need to update it
								let diff = gridItemHeight - renderObj.gridInfo[size].originalH;
								renderObj.gridInfo[size].originalH = +gridItemHeight;
								renderObj.gridInfo[size].h += diff;
								renderObj.gridInfo[size].originalY = defaultGridInfo.originalY;
								renderObj.gridInfo[size].y = defaultGridInfo.originalY + diff;
							}
							renderObj.gridInfo[size].w = +gridItemWidth;
							renderObj.gridInfo[size].x = +defaultGridInfo.x;
							/*
							originalH: gridItemHeight,
							h: gridItemHeight,
							w: gridItemWidth,
							x: itemsInCurrentGridRow * gridItemWidth,
							y: currentGridRow * gridItemHeight,
							originalY: currentGridRow * gridItemHeight
							*/
						});

						// Loop over to figure out the necessary grid positions
						itemsInCurrentGridRow++;

						if ((gridItemWidth * (itemsInCurrentGridRow + 1)) > 12) {
							currentGridRow++;
							itemsInCurrentGridRow = 0;
						}


						return {
							attachedFields,
							attachmentId: renderObj.attachmentId,
							attachmentKey: renderObj.attachmentKey,
							renderId: renderObj.renderId,
							renderParentId: renderObj.renderParentId,
							componentId,
							componentType,
							dataRecordId: renderObj.dataRecordId,
							dataTableSchemaName: renderObj.dataTableSchemaName,
							fieldPosition,
							fieldPositionExtras,
							gridLayoutHeights,
							availableModes,
							gridInfo: renderObj.gridInfo
						};
					});

					children.forEach(childRenderId => {
						// If this child is not present in the new grids, remove it from here
						// as well as from the grids
						let isPresent = !!toRecalc.find(({renderId}) => renderId === childRenderId);
						if(!isPresent) {
							let matchingGridIndex = grids.findIndex(({parentRenderId}) => parentRenderId === childRenderId);
							if(matchingGridIndex !== -1) {
								// Preserve reference equality.
								for (let i = 0; i < grids.length; i++) {
									if(i < matchingGridIndex) {
										continue;
									}
									// Shrink the gap
									grids[i] = grids[i + 1];
								}
								// Clear the last empty entry
								delete grids[grids.length - 1];
								// Splice it out of the grid
								// While we mutate grid in-place, it should be all right to redefine it here
								// as the objects are the same and we return it at the end
								grids = grids.splice(matchingGridIndex, 1);
							}
						}
					});
					// Do not confuse record rows with grid rows

					return GridHeightUtils.recalcAttachedFields(toRecalc, activeOverlays, grids, false, undefined, undefined, undefined, ignoreEmptyChildren);
				})
				.then(grids => {
					// Find the grids which correspond to the children
					// and then push them into attachedFields.
					// Make sure not to get any grandchildren
					let childGrids = [];
					grids.forEach((grid, index) => {
						// Skip the first one
						if(!index) {
							return;
						}
						// Is this grid a child of our parent
						if(grid.grandparent === parentRenderId) {
							childGrids.push({
								attachmentId: grid.attachmentId,
								attachmentKey: grid.dataRecordId + '=>' + grid.componentId,
								availableModes: grid.availableModes,
								dataRecordId: grid.dataRecordId,
								dataTableSchemaName: grid.dataTableSchemaName,
								fieldId: grid.componentId,
								gridInfo: grid.gridInfo,
								renderId: grid.parentRenderId,
								order: childGrids.length,
								protectDataRecordId: true
							});
						}
					})
					grids[0].attachedFields = childGrids;
					return resolve({grids, rows, fields});
				})
				.catch(reject);
		});
	},

	/**
	 * Recalculates a repeating grid given the queryResults.
	 * @param {object} parentGrid The parent grind information
	 * @param {array} activeOverlays The overlays active when this runs
	 * @param {object} queryResult The queryResult object from processing a query
	 * @returns 
	 */
	recalcRepeatingGridFromRows(parentGrid, activeOverlays, queryResult, responsiveMode) {


		let {
			renderId: parentRenderId,
			renderParentId: grandparent,
			componentId,
			componentType,
			dataRecordId,
			dataTableSchemaName,
			gridLayoutHeights, // We don't need this but it does need to be on the object in grids
			gridItemHeight,
			gridItemWidth,
			availableModes,
			attachedFields,
			fieldPosition,
			fieldPositionExtras,
			attachmentId
		} = parentGrid;

		let rows = queryResult && queryResult.rows ? queryResult.rows : [];

		let grids = [{
			parentRenderId,
			grandparent,
			componentId,
			componentType,
			dataRecordId,
			dataTableSchemaName,
			gridLayoutHeights,
			availableModes: ['view', 'edit'], // @TODO: This needs to be tested against expected available mode behavior
			repeatingGrid: true,
			attachmentId
		}];

		let currentGridRow = 0;
		let itemsInCurrentGridRow = 0;

		return new Promise((resolve, reject) => {
			// Calculate these grids
			let childGrids = rows.map((record, idx) => {

				let defaultGridInfo = {
					originalH: +gridItemHeight,
					h: +gridItemHeight,
					w: +gridItemWidth,
					x: itemsInCurrentGridRow * gridItemWidth,
					y: currentGridRow * gridItemHeight,
					originalY: currentGridRow * gridItemHeight,
					i: record.recordId + '=>' + componentId,
					isResizable: idx === 0
				};
				let renderId = RenderStore.findChildRenderId(parentRenderId, componentId, record.recordId + '=>' + componentId);
				let renderObj = renderId ? RenderStore.get(renderId) : undefined;
				renderObj = renderObj || {
					renderId: uuid.v4(),
					gridInfo: {
						lg: defaultGridInfo,
						md: defaultGridInfo,
						sm: defaultGridInfo
					}
				}
				// Make sure these values are always set
				renderObj.componentId = componentId;
				renderObj.componentType = 'field';
				renderObj.dataRecordId = record.recordId;
				renderObj.dataTableSchemaName = record.tableSchemaName;
				renderObj.renderParentId = parentRenderId;
				renderObj.attachmentKey = record.recordId + '=>' + componentId;
				renderObj.availableModes = availableModes;

				if(!renderObj.gridInfo) {
					renderObj.gridInfo = {
						lg: defaultGridInfo,
						md: defaultGridInfo,
						sm: defaultGridInfo
					};
				}

				// We need to respect changes in gridItemWidth and gridItemHeight
				['lg', 'md', 'sm'].forEach(size => {
					if(renderObj.gridInfo[size].originalH !== gridItemHeight || renderObj.gridInfo[size].originalY !== defaultGridInfo.originalY) {
						// We've changed the height and need to update it
						let diff = gridItemHeight - renderObj.gridInfo[size].originalH;
						renderObj.gridInfo[size].originalH = +gridItemHeight;
						renderObj.gridInfo[size].h += diff;
						renderObj.gridInfo[size].originalY = defaultGridInfo.originalY;
						renderObj.gridInfo[size].y = defaultGridInfo.originalY + diff;
					}
					renderObj.gridInfo[size].w = +gridItemWidth;
					renderObj.gridInfo[size].x = +defaultGridInfo.x;
					/*
					originalH: gridItemHeight,
					h: gridItemHeight,
					w: gridItemWidth,
					x: itemsInCurrentGridRow * gridItemWidth,
					y: currentGridRow * gridItemHeight,
					originalY: currentGridRow * gridItemHeight
					*/
				});

				// Loop over to figure out the necessary grid positions
				itemsInCurrentGridRow++;

				if ((gridItemWidth * (itemsInCurrentGridRow + 1)) > 12) {
					currentGridRow++;
					itemsInCurrentGridRow = 0;
				}


				let parentComponent = {
					attachedFields,
					attachmentId: renderObj.attachmentId,
					attachmentKey: renderObj.attachmentKey,
					parentRenderId: renderObj.renderId,
					grandparent: renderObj.renderParentId,
					componentId,
					componentType,
					dataRecordId: renderObj.dataRecordId,
					dataTableSchemaName: renderObj.dataTableSchemaName,
					fieldPosition,
					fieldPositionExtras,
					gridLayoutHeights,
					availableModes: ['view', 'edit'],
					gridInfo: renderObj.gridInfo
				};

				let query = {
					results: [{
						recordId: parentComponent.dataRecordId,
						tableSchemaName: parentComponent.dataTableSchemaName,
					}],
					fields: [],
					expressions: {},
					queries: [],
					queriesLookup: {}
				};
				// let grids = [];
				GridHeightUtils.getPossibleAttachedFields(componentId, componentType, parentComponent.attachedFields, query, responsiveMode);
				parentComponent.query = query;
				return parentComponent;
			});

			childGrids.forEach(grid => grids.push(grid));

			let childAttachedFields = [];
			grids.forEach((grid, index) => {
				// Skip the first one
				if(!index) {
					return;
				}
				// Is this grid a child of our parent
				if(grid.grandparent === parentRenderId) {
					childAttachedFields.push({
						attachmentId: grid.attachmentId,
						attachmentKey: grid.dataRecordId + '=>' + grid.componentId,
						availableModes: grid.availableModes,
						dataRecordId: grid.dataRecordId,
						dataTableSchemaName: grid.dataTableSchemaName,
						fieldId: grid.componentId,
						gridInfo: grid.gridInfo,
						renderId: grid.parentRenderId,
						order: childGrids.length,
						protectDataRecordId: true
					});
				}
			})
			grids[0].attachedFields = childAttachedFields;

			grids[0].grids = childGrids;
			
			return resolve(grids);
		})

	},
	/**
	 * Recalculate a single child of a repeating grid, including its query and visibility, without recalculating the entire parent
	 * @param {object} parentGrid 
	 * @param {object} grid 
	 * @param {array} activeOverlays 
	 * @returns 
	 */
	recalcRepeatedField(grid, pageGrid, activeOverlays, mode, responsiveMode) {

		let FieldComponents = require('../utils/field-components').default;

		let availableModes = ['view', 'edit'];

		let {
			attachmentId,
			attachmentKey,
			renderId: parentRenderId,
			renderParentId: grandparent,
			componentId,
			dataRecordId, // The child's data record ID will not change, so we keep it from here
			dataTableSchemaName,
			attachedFields: gridAttachedFields,
			gridLayoutHeights,
			fieldPosition,
			fieldPositionExtras,
			gridInfo
			// componentType
		} = grid;

		let {
			dataRecordId: pageDataRecordId,
			dataTableSchemaName: pageDataTableSchemaName
		} = pageGrid;


		let grids = [];

		return new Promise((resolve, reject) => {
			FieldComponents.visibility.runAttachedFieldVisibility(
				gridAttachedFields, ['view', 'edit'], parentRenderId,
				componentId, dataRecordId, dataTableSchemaName,
				undefined, 'field', activeOverlays
			)
				.then(newAttachedFields => {
					let toPush = GridHeightUtils.processSimpleAttachedFields(
						{
							componentId: componentId,
							parentComponentId: componentId, // @TODO: Should this be this or the actual parent?
							componentType: 'field',
							renderId: parentRenderId,
							renderParentId: grandparent,
							attachmentId: attachmentId,
							attachmentKey: attachmentKey,
							availableModes: ['view', 'edit'],
							attachedFields: newAttachedFields,
							gridLayoutHeights: gridLayoutHeights,
							fieldPosition: fieldPosition ? JSON.parse(fieldPosition) : {},
							fieldPositionExtras: fieldPositionExtras ? JSON.parse(fieldPositionExtras) : {},
							gridInfo: gridInfo
						},
						newAttachedFields,
						[], // @TODO: Are any expressionResults appropriate here?
						dataRecordId,
						dataTableSchemaName
					);
					grids.push(toPush);
					let childGrids = [];
					// Now we also need to recursively process our children
					// First, loop over the children and identify if they have children or other processing to do
					// Next, build the parentGridInfo object for the corresponding children
					// and recurse
					newAttachedFields.forEach(field => {
						let { attachmentId, fieldId } = field;
						// Once processed, the newAttachedFields have a single field ID
						// @TODO: Should this be changed to pre-process all possible attached fields in a location?
						let fieldSettingsObj = attachmentId
							? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, fieldId, componentId, responsiveMode)
							: FieldSettingsStore.getSettings(fieldId, componentId);
						// Because the recursor checks field types, we really just need
						// to check if this field has any potential children
						if (fieldSettingsObj && fieldSettingsObj.attachedFields) {
							let queryForChild = {
								// "Query" for the page's record
								results: [{ recordId: dataRecordId, tableSchemaName: dataTableSchemaName }],
								fields: [],
								expressions: {},
								queries: [],
								queriesLookup: {}
							};
							// @TODO: Are there any other ways for fields to have children? Or is this sufficient?
							let childGridInfo = {
								componentId: fieldId,
								attachmentId,
								componentType: 'field',
								parentComponentId: componentId,
								parentComponentType: 'field',
								renderId: field.renderId,
								renderParentId: parentRenderId,
								attachedFields: fieldSettingsObj.attachedFields ? JSON.parse(fieldSettingsObj.attachedFields) : [],
								fieldPosition: fieldSettingsObj.fieldPosition ? JSON.parse(fieldSettingsObj.fieldPosition) : {},
								fieldPositionExtras: fieldSettingsObj.fieldPositionExtras ? JSON.parse(fieldSettingsObj.fieldPositionExtras) : {},
								availableModes: field.availableModes ? field.availableModes.filter(mode => availableModes.includes(mode)) : [],
								query: queryForChild
							};
							childGrids.push(childGridInfo);
						}
					});
					return Promise.all(childGrids.map(childGridInfo => GridHeightUtils.processGridsRecursively(
						childGridInfo.query, childGridInfo, grids,
						[{ recordId: dataRecordId, tableSchemaName: dataTableSchemaName }], [{recordId: pageDataRecordId, tableSchemaName: pageDataTableSchemaName}],
						activeOverlays, mode, responsiveMode
					)));
				})
				.then(() => {
					return resolve(grids);
				})
				.catch(reject);
		});
	},
	/**
	 * Recalculate a single field, including its query and visibility, without recalculating the entire parent
	 * @param {object} parentGrid 
	 * @param {object} childGrid 
	 * @param {array} activeOverlays 
	 * @returns 
	 */
	recalcSingleField(parentGrid, childGrid, pageGrid, activeOverlays, mode, responsiveMode) {

		let {
			attachedFields,
			attachedFieldsResults,
			fieldPosition,
			fieldPositionExtras,
			grandparent: greatGrandparent,
			componentId: parentComponentId,
			componentType: parentComponentType,
			dataRecordId, // We want to use the PARENT grid's context info, b/c the child's might change on refresh
			dataTableSchemaName
		} = parentGrid;

		let {
			attachmentId,
			renderId: parentRenderId,
			renderParentId: grandparent,
			componentId,
			gridInfo
			// componentType
		} = childGrid;

		let {
			dataRecordId: pageDataRecordId,
			dataTableSchemaName: pageDataTableSchemaName
		} = pageGrid;

		// Include page below record context (31268 - Cannot Query for Page Record Below From Add Page)
		let extraRecordContext = {};
		let pageBelow = pageGrid.renderParentId
			? RenderStore.getPageRenderObj(pageGrid.renderParentId)
			: undefined;
		if(pageBelow) {
			extraRecordContext.pageBelow = [{
				recordId: pageBelow.dataRecordId,
				tableSchemaName: pageBelow.dataTableSchemaName
			}];
		}
		let attachedFieldsArr = attachedFields && typeof attachedFields === 'string'
			? JSON.parse(attachedFields)
			: (attachedFields || []);


		let matchingAttachedField = attachedFieldsArr.find(field => field.attachmentId === attachmentId || field.recordId === componentId);

		if(!matchingAttachedField) {
			throw new Error('Attempted to refresh field which was not in attached child fields. If you are seeing this message, please report it to support@citizendeveloper.com.');
		}

		let parentQuery = {
			results: [{ recordId: dataRecordId, tableSchemaName: dataTableSchemaName }],
			fields: [],
			expressions: {},
			queries: [],
			queriesLookup: {}
		};

		let modifiedParentGridInfo = {
			query: parentQuery,
			componentId: parentComponentId,
			componentType: parentComponentType,
			availableModes: ['view', 'edit'],
			renderId: grandparent,
			renderParentId: greatGrandparent,
			attachedFields: [matchingAttachedField],
			fieldPosition,
			fieldPositionExtras,
			attachmentId: parentGrid.attachmentId,
			attachmentKey: parentGrid.attachmentKey
		};

		let greatGrandparentRender = RenderStore.get(greatGrandparent);
		let grandparentRender = RenderStore.get(grandparent);

		if(greatGrandparentRender && greatGrandparentRender.repeatingGrid && grandparentRender && !grandparentRender.repeatingGrid) {
			modifiedParentGridInfo.variantNameOverride = 'fieldContainerView';
		}

		let grids = [];

		GridHeightUtils.getPossibleAttachedFields(parentComponentId, parentComponentType, matchingAttachedField ? [matchingAttachedField] : [], parentQuery, responsiveMode);

		return new Promise((resolve, reject) => {
			GridHeightUtils.processGridsRecursively(
				parentQuery, modifiedParentGridInfo, grids,
				parentQuery.results, [{recordId: pageDataRecordId, tableSchemaName: pageDataTableSchemaName}],
				activeOverlays, mode, undefined, extraRecordContext
			)
				.then(grids => {
					// Find the grid corresponding to the parent and fix the attachedFields
					let parentGrid = grids.find(grid => grid.parentRenderId === grandparent);
	
					if(parentGrid) {
						let newAttachedField = parentGrid.attachedFields[0];
						// We only passed one field in, so we only expect the one back
						// Now insert it into the calculated attached fields for the parent
						// and sort to the appropriate order
						let index = attachedFieldsResults.findIndex(field => field.attachmentId === newAttachedField.attachmentId || field.fieldId === newAttachedField.fieldId || field.recordId === newAttachedField.recordId);
	
						if(index !== -1) {
							newAttachedField.renderId = attachedFieldsResults[index].renderId; // Preserve render IDs
							attachedFieldsResults[index] = newAttachedField;
						} else {
							// From the other code I do not believe this needs to be sorted in the same order as the source attached fields
							attachedFieldsResults.push(newAttachedField);
						}
						parentGrid.attachedFields = attachedFieldsResults;
					}

					let currentGrid = grids.find(grid => grid.parentRenderId === parentRenderId);
					if(currentGrid && gridInfo) {
						currentGrid.gridInfo = gridInfo;
					}
	
					return resolve(grids);
				})
				.catch(reject);
		});
	},
	/**
	 * Gets all grid children from the previous set of grids in order to
	 * recalculate from top down or bottom to top as needed.
	 * 
	 * @param {array} toRecalc Array of parent grids
	 * @returns 
	 */
	getNextGridLayer(toRecalc, grids, omitRepeating) {
		// @TODO: The child component ID might change according to the attachedFields of the parent
		// so we need to find the one that matches each of the children and then override it if the value exists

		let nextLayer = {};
		toRecalc.forEach(obj => {
			let parentRenderId = obj.renderId;
			let matchingGridItem = grids ? grids.find(grid => grid.attachmentId === obj.attachmentId && grid.parentRenderId === obj.renderId) : undefined;
			let attachedFields = matchingGridItem ? matchingGridItem.attachedFields : undefined;
			let renderObj = RenderStore.get(parentRenderId);
			if(!renderObj || !renderObj.children || renderObj.repeatingGrid) {
				return;
			}
			let parentRenderObj = renderObj.renderParentId ? RenderStore.get(renderObj.renderParentId) : undefined;
			let parentComponentId = parentRenderObj ? parentRenderObj.componentId : undefined;
			let componentType = renderObj.componentType;
			let componentObj = componentType === 'field'
				? FieldStore.get(renderObj.componentId)
				: PageStore.get(renderObj.componentId);
			if(componentType === 'field') {
				let componentSettings = renderObj.attachmentId
					? FieldSettingsStore.getSettingsFromAttachmentId(renderObj.attachmentId, renderObj.componentId, parentComponentId)
					: FieldSettingsStore.getSettings(renderObj.componentId, parentComponentId);
				Object.assign(componentObj, componentSettings);
			}
			// Skip lists
			if(componentObj && componentObj.fieldType === '9b782b83-4962-4bd6-993c-f72096e02610') {
				return;
			}
			renderObj.children.forEach(childRenderId => {
				let childRenderObj = RenderStore.get(childRenderId);
				// skip any page children; we only want to consider field children
				if(!childRenderObj || childRenderObj.componentType === 'page') {
					return;
				}
				let matchingAttachedField = childRenderObj && childRenderObj.attachmentId && attachedFields ? attachedFields.find(attachedField => (attachedField.attachmentId === childRenderObj.attachmentId || attachedField.recordId === childRenderObj.componentId)) : undefined;
				let componentId = (matchingAttachedField && matchingAttachedField.fieldId) || childRenderObj.componentId;
				let childFieldObj = FieldStore.get(componentId);
				// Skip lists
				if(childFieldObj && childFieldObj.fieldType === '9b782b83-4962-4bd6-993c-f72096e02610') {
					return;
				}
				// @TODO: This is a rare use case but I guess this needs to be per size in some fashion?
				// I think that can be backlogged, though.
				let childFieldSettings = childRenderObj.attachmentId ?
					FieldSettingsStore.getSettingsFromAttachmentId(childRenderObj.attachmentId, componentId, obj.componentId) :
					FieldSettingsStore.getSettings(componentId, obj.componentId);
				Object.assign(childFieldObj, childFieldSettings);
				// Only consider children which themselves have fields to recalculate
				// since otherwise why bother?
				// Also, if the field is no longer present in the parent, we need to get rid of it
				if(!childFieldObj || !childFieldObj.fieldPosition || (matchingGridItem && !matchingAttachedField)) {
					return;
				}

				let gridLayoutHeights = childRenderObj.gridLayoutHeights;

				if(childFieldSettings && childFieldSettings.viewVariant === 'fieldContainerMultiTilesView') {
					if(omitRepeating) {
						return;
					}

					// @TODO: This could probably be re-implemented and cleaned up for more efficiency, but it works for now.
					// if(renderObj.children) {
				// 		let recalcAndSkip = [{
				// 			// Needs to be of the form attachedFields, modes, renderId, componentId, dataRecordId, dataTableSchemaName
				// 			attachedFields: childFieldObj.attachedFields ? JSON.parse(childFieldObj.attachedFields) : [],
				// 			fieldPosition: childFieldObj.fieldPosition ? JSON.parse(childFieldObj.fieldPosition) : {},
				// 			fieldPositionExtras: childFieldObj.fieldPositionExtras ? JSON.parse(childFieldObj.fieldPositionExtras) : {},
				// 			modes: obj.modes,
				// 			dataRecordId: childRenderObj.dataRecordId,
				// 			dataTableSchemaName: childRenderObj.dataTableSchemaName,
				// 			renderId: childRenderObj.renderId,
				// 			renderParentId: parentRenderId,
				// 			gridLayoutHeights:gridLayoutHeights,
				// 			componentType: childRenderObj.componentType,
				// 			componentId: childRenderObj.componentId,
				// 			componentObj: childFieldObj
				// 		}];
				// 		this.getNextGridLayer(recalcAndSkip).forEach(gridItem => {
				// 			nextLayer[gridItem.renderId] = gridItem;
				// 		});
					// }
				}

				nextLayer[childRenderId] = {
					// Needs to be of the form attachedFields, modes, renderId, componentId, dataRecordId, dataTableSchemaName
					attachedFields: childFieldObj.attachedFields ? JSON.parse(childFieldObj.attachedFields) : [],
					fieldPosition: childFieldObj.fieldPosition ? JSON.parse(childFieldObj.fieldPosition) : {},
					fieldPositionExtras: childFieldObj.fieldPositionExtras ? JSON.parse(childFieldObj.fieldPositionExtras) : {},
					modes: obj.modes,
					dataRecordId: matchingAttachedField ? matchingAttachedField.dataRecordId : childRenderObj.dataRecordId,
					dataTableSchemaName: matchingAttachedField ? matchingAttachedField.dataTableSchemaName : childRenderObj.dataTableSchemaName,
					renderId: childRenderObj.renderId,
					renderParentId: parentRenderId,
					gridLayoutHeights:gridLayoutHeights,
					componentType: childRenderObj.componentType,
					componentId: componentId,
					componentObj: childFieldObj,
					attachmentId: childRenderObj.attachmentId,
					attachmentKey: childRenderObj.attachmentKey,
					repeatingGrid: childRenderObj.repeatingGrid,
					gridInfo: matchingAttachedField ? matchingAttachedField.gridInfo : childRenderObj.gridInfo
				};
				if(matchingAttachedField) {
					nextLayer[childRenderId].availableModes = matchingAttachedField.availableModes;
				}
			});

		});
		// Convert to an array now that we're done with this
		return Object.keys(nextLayer).map(key => nextLayer[key]);
	},

	/**
	 * Calculates a page and all its children.
	 * @param {object} pageObj The page being calculated.
	 * @param {string} dataRecordId The data record ID to open the page about
	 * @param {string} dataTableSchemaName The data TSN to open the page about
	 * @param {string} renderId The render ID for the page
	 * @param {string} renderParentId Optional: the parent render for the page (if this is a dialog)
	 * @param {array} activeOverlays The overlays active when this runs
	 * @param {string} mode Optional: The mode (view vs. edit, etc.) to run this for
	 * @param {string} responsiveMode Optional: The responsive mode (sm, md, lg, etc.) for which to calculate this
	 * @param {object} dialogOptions Optional: the options for the dialog, if page is opened as a dialog 
	 * @returns 
	 */
	initiatePage(pageObj, dataRecordId, dataTableSchemaName, renderId, renderParentId, activeOverlays, mode, responsiveMode, dialogOptions) {
		let pageQuery = {
			// "Query" for the page's record
			results: [{ recordId: dataRecordId, tableSchemaName: dataTableSchemaName }],
			fields: [],
			expressions: {},
			queries: [],
			queriesLookup: {}
		};

		let attachedFieldsArr = pageObj.attachedFields ? JSON.parse(pageObj.attachedFields) : [];

		let parentGridInfo = {
			query: pageQuery,
			componentId: pageObj.recordId,
			componentType: 'page',
			availableModes: pageObj.availableModes ? pageObj.availableModes.split(',') : ['view', 'edit'],
			renderId,
			renderParentId,
			attachedFields: attachedFieldsArr,
			fieldPosition: pageObj.fieldPosition ? JSON.parse(pageObj.fieldPosition) : {},
			fieldPositionExtras: pageObj.fieldPositionExtras ? JSON.parse(pageObj.fieldPositionExtras) : {},
		};
		if(dialogOptions) {
			parentGridInfo.dialogOptions = dialogOptions;
		}

		// Include page below record context (31268 - Cannot Query for Page Record Below From Add Page)
		let extraRecordContext = {};
		let parentRenderInfo = renderParentId ? RenderStore.getPageRenderObj(renderParentId) : undefined;
		if(parentRenderInfo) {
			extraRecordContext.pageBelow = [{
				recordId: parentRenderInfo.dataRecordId,
				tableSchemaName: parentRenderInfo.dataTableSchemaName
			}];
		}

		let grids = [];

		// Get all of the queries required for this page as well as the fields they'll look up.
		// @TODO: We also need to match the queries up with the grids they correspond to
		// in order to calculate the grids with their visibility and attached fields
		// recursively using the correct data TSN.
		if (pageObj.attachedFields) {
			// Modify queries in-place
			GridHeightUtils.getPossibleAttachedFields(pageObj.recordId, 'page', attachedFieldsArr, pageQuery, responsiveMode);
			// Now run the queries and store the results for use later

		}

		return new Promise((resolve, reject) => {
			GridHeightUtils.processAllQueriesToRows(pageQuery, undefined, pageQuery.results, undefined, pageQuery.results, undefined, undefined, extraRecordContext)
				.then(() => {
					return GridHeightUtils.processGridsRecursively(pageQuery, parentGridInfo, grids, pageQuery.results, pageQuery.results, activeOverlays, mode, responsiveMode, extraRecordContext);
				})
				.then(resolve)
				.catch(reject)
		});
	},

	/**
	 * Gets the possible attached fields for a parent grid
	 * and also gets the queries and expressions which will need to
	 * be run for it.
	 * 
	 * @param {object} parentComponent The parent component information
	 * @param {array} attachedFields The raw attachedFields
	 * @param {object} parentQuery The parent query information for this grid
	 * @param {array} queries The general array of queries being tracked for this context
	 */
	getPossibleAttachedFields(parentComponent, parentComponentType, attachedFields, parentQuery, responsiveMode) {

		if(!parentQuery) {
			console.log('Missing parentQuery for', parentComponentType, parentComponent);
			console.error(new Error('Missing parentQuery for ' + parentComponentType + ' ' + parentComponent));
			return;
		}

		let FieldComponents = require('../utils/field-components').default;
		// We basically need to match each query to attached fields
		// using compareQueries to get identical queries
		// then store the fields that each query might need with each query
		// We need to figure out which fields are controlled by which queries as well
		// We assume that the parent for this field has already taken care of any queries
		// so we only need to care about the child queries
		// however, we do need to know which query is controlling which field here

		// Queries structure:

		/*
		[{
			queryJSON,
			fields,
			expressions,
			parentQuery // Reference to another query object like this
		}]
		*/

		parentQuery.queries = parentQuery.queries || [];
		parentQuery.queriesLookup = parentQuery.queriesLookup || {};

		attachedFields.forEach(attachedField => {
			// First, get the fields which can go here
			let { attachmentId, recordId, recordIds } = attachedField;
			recordIds = recordIds || [recordId];
			attachmentId = attachmentId || recordId;
			recordIds.forEach(fieldId => {
				let currentQuery = parentQuery;
				let fieldObj = FieldStore.get(fieldId);
				let fieldTypeObj = fieldObj ? FieldTypeStore.get(fieldObj.fieldType) : {};
				let fieldTypeSettings = fieldTypeObj.settings;
				// Find each field's field object
				let fieldSettingsObj = attachmentId
					? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, fieldId, parentComponent, responsiveMode)
					: FieldSettingsStore.getSettings(fieldId, parentComponent, responsiveMode);

				if (fieldSettingsObj.query && fieldSettingsObj.query.startsWith('{') && fieldObj.fieldType === '7ebd9251-675c-4129-95e3-6b8e31c135a2') {

					if(fieldSettingsObj.query.includes('should be first nodeId')) {
						console.warn('Malformed query on field %s. Value was', fieldId, fieldSettingsObj.query);
					}

					// If this is a container field with an override,
					// it and all of its children without their own overrides now belong to the new query
					// See if it's already in the queries list and add it if it's not.
					// However, remember to track that the "current record" for this query belongs to the parentQuery.
					// What data format should we use for that?

					let matchingQuery;
					
					try {
						matchingQuery = parentQuery.queries.find(query => query && query.queryJSON && FieldComponents.query.compareQueries(query.queryJSON, fieldSettingsObj.query));
					} catch(err) {
						console.error('Error finding matching query', err);
					}

					if (!matchingQuery) {
						matchingQuery = {
							queryJSON: fieldSettingsObj.query,
							fields: [],
							expressions: {},
							queries: [],
							queriesLookup: {}
						}
						parentQuery.queries.push(matchingQuery);
					}

					matchingQuery.recordSetInfo = {
						setName: fieldId + '-results',
						// uiName: '' // Let the record set store generate the friendly name for us
					};

					parentQuery.queriesLookup[fieldId] = matchingQuery;

					// Set currentQuery to this so that all the subsequent logic will use this
					// as its parentQuery appropriately
					currentQuery = matchingQuery;
				}

				// Does this field have data? Push it if so.
				let fieldHasData = FieldStore.getHasData(fieldId);
				if (!fieldHasData) {
					// Also get dynamic selection fields
					fieldHasData = fieldTypeObj && fieldTypeObj.dataType === 'relationship';
				}

				if (fieldHasData && !currentQuery.fields.includes(fieldId)) {
					currentQuery.fields.push(fieldId);
				}

				//@TODO: Also record set handling.
				if (Array.isArray(fieldTypeSettings)) {
					fieldTypeSettings.forEach(fieldTypeSetting => {
						let fieldTypeSettingObj = FieldStore.get(fieldTypeSetting['recordId']);
						if (fieldTypeSettingObj) {
							let settingFieldSchemaName = fieldTypeSettingObj.fieldSchemaName;
							// Expression Field Type
							if (
								fieldTypeSettingObj.fieldType === 'd7183192-d4d0-42e9-a1f6-78f3984cef8c' &&
								fieldSettingsObj[settingFieldSchemaName]
							) {
								let expressionObj = JSON.parse(fieldSettingsObj[settingFieldSchemaName]);
									

								// Push single field expressions into the field data
								if (
									expressionObj && expressionObj.optimizationScheme === 'singleField' &&
									expressionObj.optimizationData &&
									expressionObj.optimizationData.fieldData &&
									!currentQuery.fields.includes(expressionObj.optimizationData.fieldData.fieldId)
								) {
									currentQuery.fields.push(expressionObj.optimizationData.fieldData.fieldId);
								}
								if (expressionObj) {
									expressionObj.fieldId = fieldId;
									expressionObj.parentComponent = parentComponent;
									expressionObj.parentComponentType = parentComponentType;
									currentQuery.expressions[attachmentId] = currentQuery.expressions[attachmentId] || {};
									currentQuery.expressions[attachmentId][fieldId] = currentQuery.expressions[attachmentId][fieldId] || {};
									currentQuery.expressions[attachmentId][fieldId][settingFieldSchemaName] = expressionObj;
								}
							}
						}
					});
				}

				// Now loop over attached fields
				// Currently only running for containers and content tabs/drop-downs
				// Lists can take care of themselves
				if (fieldSettingsObj.attachedFields) {
					// Is this a container? Recurse over it.
					if (fieldObj.fieldType === '7ebd9251-675c-4129-95e3-6b8e31c135a2') {
						GridHeightUtils.getPossibleAttachedFields(fieldObj.fieldId || fieldObj.recordId, 'field', JSON.parse(fieldSettingsObj.attachedFields), currentQuery, responsiveMode);
					}

					// Is this a content tab or dropdown? We need to recurse over the children
					// while also respecting the current query overrides of any dynamic children
					// @TODO: Transfer this to a static method on the field type or variant

					if (fieldObj.fieldType === 'bb5bedc3-44d1-4e4c-9c40-561a675173b1' || fieldObj.fieldType === '846b747e-25a0-40df-8115-af4a00a1cab5') {
						let tabsArr = JSON.parse(fieldSettingsObj.attachedFields);
						tabsArr.forEach(tab => {
							let { recordIds, recordId, attachmentId } = tab;
							attachmentId = attachmentId || recordId;
							recordIds = recordIds || [recordId];
							// Is it a static tab? Treat the children the same way as containers, except that it also has expressions
							if (tab.type === 'static') {
								// Possible expressions: displayedName, icon, rightAlignedText, secondLineText
								['displayedName', 'icon', 'rightAlignedText', 'secondLineText'].forEach(key => {
									try {
										let expressionObj = tab[key] ? JSON.parse(tab[key]) : undefined;
										if (
											expressionObj && expressionObj.optimizationScheme === 'singleField' &&
											expressionObj.optimizationData &&
											expressionObj.optimizationData.fieldData &&
											!currentQuery.fields.includes(expressionObj.optimizationData.fieldData.fieldId) && 
											expressionObj.optimizationData.fieldData.fieldId !== 'recordId'
										) {
											currentQuery.fields.push(expressionObj.optimizationData.fieldData.fieldId)
										}
										// if(expressionObj) {
										// 	currentQuery.expressions.push(expressionObj);
										// }
									} catch (err) {
										// Suppress error; this may be an old expression value
									}

								});
								recordIds.forEach(childFieldId => {
									let fieldObj = FieldStore.get(childFieldId);
									if (fieldObj && fieldObj.attachedFields) {
										// I think we also need to figure out if this field has a query itself and include it further
										let matchingQuery = currentQuery;
										if(fieldObj.query && fieldObj.query.startsWith('{')) {
											if(fieldObj.query.includes('should be first nodeId')) {
												console.warn('Malformed query on field %s. Value was', childFieldId, fieldObj.query);
											}
											matchingQuery = currentQuery.queries.find(query => query.queryJSON && query.queryJSON.startsWith('{') && FieldComponents.query.compareQueries(query.queryJSON, fieldObj.query));

											if (!matchingQuery) {
												matchingQuery = {
													queryJSON: fieldObj.query,
													// dataQueryJSON: tab.query,
													fields: [],
													expressions: {},
													queries: [],
													queriesLookup: {}
												};
												currentQuery.queries.push(matchingQuery);
											}
											currentQuery.queriesLookup[childFieldId] = matchingQuery;
										}
										// Commented out so that this will only run for the selected container
										// which improves performance
										// GridHeightUtils.getPossibleAttachedFields(childFieldId, 'field', JSON.parse(fieldObj.attachedFields), matchingQuery, responsiveMode);
									}
								});
							} else if (tab.query) {
								// Dynamic tab? This tab's query becomes the parentQuery for any children and expressions
								// Possible expressions: displayedName, icon, rightAlignedText, secondLineText
								let matchingQuery = currentQuery.queries.find(query => query && query.queryJSON && FieldComponents.query.compareQueries(query.queryJSON, tab.query));

								if (!matchingQuery) {
									matchingQuery = {
										queryJSON: tab.query,
										dataQueryJSON: tab.query,
										fields: [],
										expressions: {},
										queries: [],
										queriesLookup: {}
									};
									currentQuery.queries.push(matchingQuery);
								}
								currentQuery.queriesLookup[attachmentId] = matchingQuery;
								currentQuery = matchingQuery;

								// Possible expressions: displayedName, icon, rightAlignedText, secondLineText
								['displayedName', 'icon', 'rightAlignedText', 'secondLineText'].forEach(key => {
									let expressionObj = tab[key] ? JSON.parse(tab[key]) : undefined;
									if (
										expressionObj && expressionObj.optimizationScheme === 'singleField' &&
										expressionObj.optimizationData &&
										expressionObj.optimizationData.fieldData &&
										!matchingQuery.fields.includes(expressionObj.optimizationData.fieldData.fieldId) &&
										expressionObj.optimizationData.fieldData.fieldId !== 'recordId'
									) {
										matchingQuery.fields.push(expressionObj.optimizationData.fieldData.fieldId)
									}

									// if(expressionObj) {
									// 	currentQuery.expressions.push(expressionObj);
									// }

									recordIds.forEach(childFieldId => {
										let fieldObj = FieldStore.get(childFieldId);
										if (fieldObj && fieldObj.attachedFields) {
											// If this field further has a query for some reason
											if(fieldObj.query && fieldObj.query.startsWith('{')) {
												if(fieldObj.query.includes('should be first nodeId')) {
													console.warn('Malformed query on field %s. Value was', childFieldId, fieldObj.query);
												}
												let matchingQuery = currentQuery.queries.find(query => query.queryJSON && query.queryJSON.startsWith('{') && FieldComponents.query.compareQueries(query.queryJSON, fieldObj.query));
	
												if (!matchingQuery) {
													matchingQuery = {
														queryJSON: fieldObj.query,
														// dataQueryJSON: tab.query,
														fields: [],
														expressions: {},
														queries: [],
														queriesLookup: {}
													};
													currentQuery.queries.push(matchingQuery);
												}
												currentQuery.queriesLookup[childFieldId] = matchingQuery;
											}
											// Commented out so that this will only run for the selected container
											// which improves performance
											// GridHeightUtils.getPossibleAttachedFields(childFieldId, 'field', JSON.parse(fieldObj.attachedFields), matchingQuery, responsiveMode);
										}
									});
								});
							}
						})
					}
				}

				// If this is a calendar field, we need to add its queries
				if(fieldSettingsObj.eventRecords) {
					let eventConfigArr = fieldSettingsObj && fieldSettingsObj.eventRecords
						? ObjectUtils.getObjFromJSON(fieldSettingsObj.eventRecords)
						: [];

					eventConfigArr.forEach((eventSet, index) => {
						if(eventSet && eventSet.query && eventSet.query.startsWith('{')) {
							if(eventSet.query.includes('should be first nodeId')) {
								console.warn('Malformed query on eventSet %s for field %s. Value was', index, fieldId, eventSet.query);
							}
							// This query needs to be added now
							let matchingQuery = currentQuery.queries.find(query => query.queryJSON && query.queryJSON.startsWith('{') && FieldComponents.query.compareQueries(query.queryJSON, eventSet.query));

							if (!matchingQuery) {
								matchingQuery = {
									queryJSON: eventSet.query,
									// dataQueryJSON: tab.query,
									fields: [],
									expressions: {},
									queries: [],
									queriesLookup: {}
								};
								currentQuery.queries.push(matchingQuery);
							}

							matchingQuery.fields = matchingQuery.fields || [];

							// Now we need to check on and add the name field, start time, end time, and color expression (if singleField)

							let startFieldId = eventSet.startField ? ObjectUtils.getObjFromJSON(eventSet.startField).fieldId : '';

							if(startFieldId && startFieldId !== 'recordId' && matchingQuery.fields.indexOf(startFieldId) === -1) {
								matchingQuery.fields.push(startFieldId);
							}

							let endFieldId = eventSet.endField ? ObjectUtils.getObjFromJSON(eventSet.endField).fieldId : '';

							if(endFieldId && endFieldId !== 'recordId' && matchingQuery.fields.indexOf(endFieldId) === -1) {
								matchingQuery.fields.push(endFieldId);
							}

							let nameFieldId = eventSet.nameField ? ObjectUtils.getObjFromJSON(eventSet.nameField).fieldId : '';
							let nameFieldObj = nameFieldId ? FieldStore.get(nameFieldId) : {};
							let nameFieldType = nameFieldObj.fieldType;
							let nameFieldTypeObj = nameFieldType ? FieldTypeStore.get(nameFieldType) : {};
							let nameDataType = nameFieldTypeObj.dataType;
							let nameSettings = nameFieldId ? FieldStore.getSettings(nameFieldId) : {};

							let includeNameField = nameDataType !== 'none';

							if (!includeNameField) {
								let expressionJSON = '';
								let settingSchemaName = '';
								if (nameFieldType === '94dc3a69-8f25-420f-9e36-d0ef19ce7c45') {
									// If name is an expression field
									settingSchemaName = 'customExpression';
									expressionJSON = nameSettings.customExpression;
								} else if (nameFieldType === '4fb3fe77-76ea-4327-85dd-5da41d59c403') {
									// If name is a link field
									settingSchemaName = 'linkText';
									expressionJSON = nameSettings.linkText;
								}
								let expressionObj = expressionJSON ? ObjectUtils.getObjFromJSON(expressionJSON) : {};
								if (
									expressionObj
									&& expressionObj.optimizationScheme === 'singleField'
									&& expressionObj.optimizationData
									&& expressionObj.optimizationData.fieldData
									&& expressionObj.optimizationData.fieldData.fieldId
									&& expressionObj.optimizationData.fieldData.fieldId !== 'recordId'
								) {
									nameFieldId = expressionObj.optimizationData.fieldData.fieldId;
									includeNameField = true;
								}

								if(expressionObj) {
									// We don't really have attachmentIds in this case
									// so just use the index to help track it and keep the format the same


									expressionObj.fieldId = nameFieldId;
									expressionObj.parentComponent = parentComponent;
									expressionObj.parentComponentType = parentComponentType;

									matchingQuery.expressions = matchingQuery.expressions || {};
									matchingQuery.expressions[attachmentId] = matchingQuery.expressions[attachmentId] || {};
									matchingQuery.expressions[attachmentId][nameFieldId] = matchingQuery.expressions[attachmentId][nameFieldId] || {};
									matchingQuery.expressions[attachmentId][nameFieldId][settingSchemaName] = expressionObj;

								}
							}

							if(includeNameField && nameFieldId && nameFieldId !== 'recordId' && matchingQuery.fields.indexOf(nameFieldId) === -1) {
								matchingQuery.fields.push(nameFieldId);
							}

							// If color is a singleField expression, include it in the fields
							let color = eventSet.color;
							if(color && color.startsWith('{')) {
								let expressionObj = color ? ObjectUtils.getObjFromJSON(color) : {};
								if (
									expressionObj
									&& expressionObj.optimizationScheme === 'singleField'
									&& expressionObj.optimizationData
									&& expressionObj.optimizationData.fieldData
									&& expressionObj.optimizationData.fieldData.fieldId
									&& expressionObj.optimizationData.fieldData.fieldId !== 'recordId'
									&& matchingQuery.fields.indexOf(expressionObj.optimizationData.fieldData.fieldId) === -1
								) {
									matchingQuery.fields.push(expressionObj.optimizationData.fieldData.fieldId);
								}

								if(expressionObj) {
									// We don't really have attachmentIds in this case
									// so just use the index to help track it and keep the format the same


									expressionObj.fieldId = fieldId;
									expressionObj.parentComponent = parentComponent;
									expressionObj.parentComponentType = parentComponentType;
									expressionObj.settingPath = [index, 'color'];

									matchingQuery.expressions = matchingQuery.expressions || {};
									matchingQuery.expressions[attachmentId] = matchingQuery.expressions[attachmentId] || {};
									matchingQuery.expressions[attachmentId][fieldId] = matchingQuery.expressions[attachmentId][nameFieldId] || {};
									matchingQuery.expressions[attachmentId][fieldId].eventRecords = expressionObj;

								}
							}

							parentQuery.queriesLookup[fieldId + '-' + index] = matchingQuery;

							// None of these fields will have children, so we don't need to recurse
							// @TODO: We should, however, still push the expression values in as well
						}
					});
				}

				// Itemization per field type:
				/*
				
				Address: 50588e62-0132-4307-af80-9d2c3c41bf2c
					* No queries, expressions overrides

				Authorize.net payment: 21ed0311-bfca-4b93-8d4b-1214c4b71320
					* No overrides: has one expression. Not sure about the Authorize.net Configuration setting

				CAPTCHA: e2da2fbc-ae06-4bda-bf10-fac56c6ae30d
					* No queries, expressions or overrides

				Calendar: 0a15630b-700a-4db4-ae88-90a36732c1c3
					* No overrides: has complicated configuration with record sets and expressions.

				Checkbox: 071f142c-6146-421a-9183-d989869aaee8
					* No queries, expressions or overrides

				Colorpicker: 6e5b3bca-88bc-4db8-bd5d-531890660e49
					* No queries, expressions or overrides

				Content Drop-Down: bb5bedc3-44d1-4e4c-9c40-561a675173b1
					* Has complicated configuration with record sets and expressions. Child container fields may have visibility and overrides.
				
				Content Tabs: 846b747e-25a0-40df-8115-af4a00a1cab5
					* Same as above

				Currency: 01a20424-6b60-455c-9172-e74f4c0190b4
					* No queries, expressions or overrides

				Date/Time: 2b3b1810-1cfb-4de6-a8a6-f41305efc102
					* No queries, expressions or overrides

				Email: 9e370d09-f21b-4950-a31b-d5ba18aa33ae
					* No queries, expressions or overrides

				Engineering Access Only: 0817a9f4-769c-47b0-bead-7e356b298aa2
					* No queries, expressions or overrides

				Expression: 94dc3a69-8f25-420f-9e36-d0ef19ce7c45
					* Has expression, obviously. No queries or overrides.

				Facebook Login: 7ff16cd0-3c0c-4aae-950e-842c5e5226c7
					* Has several expressions. No queries or overrides.

				Field Container: 7ebd9251-675c-4129-95e3-6b8e31c135a2
					* Has overrides. May be a repeating grid. Itself has children to calculate.

				File Attachment: dbe04c76-8ed5-4521-9f32-b896b54455ba
					* No queries, expressions or overrides

				Google Login: 971bae4d-0781-43e4-a3c8-c46618cf57d6
					* Has several expressions. No queries or overrides.

				Icon: d33fd24c-75ac-4f34-86f8-ff844e189281
					* No queries, expressions or overrides

				Image: 1036e34b-aa26-4fa9-973a-9c9f97c67c17
					* No queries, expressions or overrides.

				Link: 4fb3fe77-76ea-4327-85dd-5da41d59c403
					* Has expression, but no queries or overrides.

				List: 9b782b83-4962-4bd6-993c-f72096e02610
					* Major featureset, much of which has its own logic to handle its queries, column overrides, and expressions. Does not itself have an override.

				Long Text: 2e8c9ff8-b6a5-4f32-98ba-24fd8794c6a7
					* No queries, expressions or overrides

				Map: 3cbe12e7-4742-4c18-966f-e44e650af199
					* Has complicated configuration with record sets and expressions. No overrides.

				Navigation Tabs: cd0ee38e-d63f-44d2-b02b-44376fcc7c2e
					* Has complicated configuration which may include queries and expressions. No overrides, but can open pages about records.

				Number: 867b0b3b-f076-4fbc-b979-f1c5e7cb495f
					* No queries, expressions or overrides

				Password: bbc67368-3f04-47d1-bbb8-3d1c39fa9c7c
					* No queries, expressions or overrides

				Pay with Paypal: a0f2cecd-aa8a-4520-8305-885c4dd5111c
					* Has expressions. Needs API update, but that's its own thing.

				Progress Bar: 94a222ed-81e2-4fe5-a03e-80c516573bce
					* Has complicated configuration which may include queries and expressions.

				All Reports: [record IDs later]
					* Has complicated configuration which is currently run by an external service and not stored in a store. This should probably change at some point.

				Secure Key: e24cc98a-07a4-4fd2-9733-5e1c4055f66a
					* No queries, expressions or overrides

				Selection Field - Dynamic: 528c3e72-3a0d-4dc9-8e81-8be6f3c29c5c
					* Has queries, no overrides. Complicated with some unique functionality in the app already.

				Selection Field - Static: 0bc7afe8-a123-4a84-962e-f8b7b1aeb3c3
					* No queries, expressions or overrides

				Short Text: d965b6d9-8dd0-440c-a31c-f40bf72accea
					* No queries, expressions or overrides

				Signature: 9aee559c-d5b5-4c3c-a0bc-2bf96664b310
					* No queries, expressions or overrides

				Social Security: 723a56c6-37cf-40a3-8cee-ca800a52024c
					* No queries, expressions or overrides

				Static Page Content: f39e24c5-d823-4e87-8769-201cc6b42802
					* No queries, expressions or overrides

				URL: f14d598d-a798-4ee5-bf5e-f8ffd522eacc
					* No queries, expressions or overrides
				
				*/
			});
		});

	},

	/**
	 * Recursive function to take in a parent query and process its queries and expressions
	 * @param {object} parentQuery Parent query object
	 * @param {string | object} dataQueryJSON If this is a bulk query use this for the context query
	 * @param {array} currentRecordContext The current record context
	 * @param {array} parentRecordContext The current record of the parent (needed for some bulk queries)
	 * @param {array} pageRecordContext The page record
	 * @param {object} FieldComponents Optional The FieldComponents utilities
	 * @param {object} RecordStore Optional The RecordStore
	 * @returns 
	 */
	processAllQueriesToRows(parentQuery, dataQueryJSON, currentRecordContext, parentRecordContext, pageRecordContext, FieldComponents, RecordStore, extraRecordContext) {
		RecordStore = RecordStore ||  require('../stores/record-store').default;
		FieldComponents = FieldComponents || require('../utils/field-components').default;
		let recordSetContext = {
			startingContext: currentRecordContext,
			page: pageRecordContext,
			'application': [{
				'recordId': ContextStore.getApplicationId(),
				'tableSchemaName': 'applications'
			}],
			'installation': [{
				'recordId': ContextStore.getInstallationId(),
				'tableSchemaName': 'installations'
			}],
			'currentUser': [{
				'recordId': AuthenticationStore.getUserId(),
				'tableSchemaName': 'users'
			}],
			'pagePageRecord': pageRecordContext,
			'pageCurrentRecord': currentRecordContext
		};

		Object.assign(recordSetContext, extraRecordContext);

		let {results, queries, queryJSON, fields, expressions, dataQueryJSON: parentDataQuery} = parentQuery;

		let dataRecordId = currentRecordContext && currentRecordContext[0] && currentRecordContext[0].recordId
				? currentRecordContext[0].recordId
				: '';
		let dataTableSchemaName = currentRecordContext && currentRecordContext[0] && currentRecordContext[0]
			? currentRecordContext[0].tableSchemaName : (queryJSON ? FieldComponents.query.getReturnTable(queryJSON)
				: '');

		let queryLookupPromise;


		if (!results && !parentQuery.contextMap && queryJSON && queryJSON.startsWith('{')) {
			if(queryJSON.includes('should be first nodeId')) {
				console.warn('Malformed query:', queryJSON);
			}
			// Should this be run as a singular query or a bulk query?
			// Pretty simple check but we'll make it more complicated if someone somehow runs afoul of it
			if(dataQueryJSON && queryJSON.includes('startingContext')) {
				queryLookupPromise = FieldComponents.query.processQueryBulkWithoutRenderEntry(
					queryJSON,
					fields,
					Object.assign({}, recordSetContext, {
						startingContext: parentRecordContext,
						pageCurrentRecord: parentRecordContext
					}),
					parentRecordContext,
					parentRecordContext && parentRecordContext[0] && parentRecordContext[0].tableSchemaName ? parentRecordContext[0].tableSchemaName : '',
					dataQueryJSON
				)
					.then((results) => {
						return results;
					});
			} else if(parentQuery.contextMap) {
				let resultsTsn = parentQuery.records ? Object.keys(parentQuery.records)[0] : undefined;
				queryLookupPromise = Promise.resolve({
					rows: parentQuery.contextMap[dataRecordId]
						? parentQuery.contextMap[dataRecordId].map(recordId => {
							return {recordId, tableSchemaName: resultsTsn};
						})
						: []
				});
			} else {
				queryLookupPromise = FieldComponents.query.processQueryWithoutRenderEntry(
					queryJSON,
					fields,
					{
						dataRecordId: currentRecordContext && currentRecordContext[0] && currentRecordContext[0].recordId ? currentRecordContext[0].recordId : '',
						dataTableSchemaName: currentRecordContext && currentRecordContext[0] && currentRecordContext[0].tableSchemaName ? currentRecordContext[0].tableSchemaName : ''
					},
					recordSetContext,
					currentRecordContext && currentRecordContext[0] && currentRecordContext[0].recordId ? currentRecordContext[0].recordId : '',
					currentRecordContext && currentRecordContext[0] && currentRecordContext[0].tableSchemaName ? currentRecordContext[0].tableSchemaName : ''
				);
			}
			
		} else {
			let fieldDict = {};
			let lookupFields = [];

			// Look up the fields here
			let RecordStore = require('../stores/record-store').default;
			let RecordsAPI = require('../apis/records-api').default;

			fields.forEach(fieldId => {
				let fieldHasData = FieldStore.getHasData(fieldId);
				let recordData = RecordStore.getRecord(dataTableSchemaName, dataRecordId);

				if (!fieldDict[fieldId]) {
					let fieldObj = FieldStore.get(fieldId) || {};
					if (!fieldHasData) {
						// Also get dynamic selection fields
						let fieldTypeObj = fieldObj ? FieldTypeStore.get(fieldObj.fieldType) : {};
						fieldHasData = fieldTypeObj && fieldTypeObj.dataType === 'relationship';
					}
					// @TODO: Does/can this handle empty field values?
					// If our field requires data && we don't yet have ANY data... || we have data, we just don't have THIS piece of data..
					if (fieldHasData && (!recordData || typeof recordData[fieldObj.fieldSchemaName] === 'undefined')) {
						fieldObj.fieldId = fieldId;
						fieldDict[fieldId] = fieldObj;
					}
				}
			});

			lookupFields = Object.keys(fieldDict).map(recordId => fieldDict[recordId]);
			if (lookupFields.length) {
				let fieldSchemaNamesToGet = lookupFields.map(({ fieldSchemaName }) => fieldSchemaName);
				queryLookupPromise = RecordsAPI.getRecord(dataTableSchemaName, dataRecordId, fieldSchemaNamesToGet)
					.then(() => {
						return parentQuery.results ? { rows: parentQuery.results } : { rows: currentRecordContext };
					});
			} else if (parentQuery.results) {
				queryLookupPromise = Promise.resolve({ rows: parentQuery.results });
			} else {
				// Some "queries" have fields for lookup but do not actually have queries

				// We don't wait for these because nothing depends on the field values we find
				// The fields will process it when the fields process it
				// let RecordsAPI = require('../apis/records-api').default;
				// RecordsAPI.getRecordByFieldIds(dataTableSchemaName, dataRecordId, fields);

				queryLookupPromise = Promise.resolve({
					rows: currentRecordContext
				});
			}
		}

		return new Promise((resolve, reject) => {
			queryLookupPromise
				.then(queryResult => {
					let { rows, contextMap, records } = queryResult;
					if (rows && rows[0]) {
						dataRecordId = rows[0].recordId;
						dataTableSchemaName = rows[0].tableSchemaName;
					}

					if(!parentQuery.results) {
						parentQuery.results = rows;
					}

					if(!parentQuery.contextMap) {
						parentQuery.contextMap = contextMap;
						parentQuery.records = records;
					}

					if(parentQuery.contextMap) {
						let resultsTsn = parentQuery.records ? Object.keys(parentQuery.records)[0] : undefined;
						rows = parentQuery.contextMap[dataRecordId]
							? parentQuery.contextMap[dataRecordId].map(recordId => {
								return {recordId, tableSchemaName: resultsTsn};
							})
							: [];
					}

					let expressionsPromise = new Promise((resolve, reject) => {
						// Now we need to process the expressions which use this query as a current context
						let expressionPromises = [];
						let expressionResults = {};
						if (expressions) {
							Object.keys(expressions)
								.forEach(attachmentId => {
									if (expressions[attachmentId]) {
										Object.keys(expressions[attachmentId])
											.forEach(fieldId => {
												Object.keys(expressions[attachmentId][fieldId])
													.forEach(settingSchemaName => {
														let expressionObj = expressions[attachmentId][fieldId][settingSchemaName];
														if (expressionObj) {
															let {result, optimizationScheme, optimizationData, generatedJavascript} = expressionObj;
															let expressionPromise;
															if (typeof result !== 'undefined') {
																expressionPromise = Promise.resolve(result);
															} else if (
																rows &&
																optimizationScheme === 'singleField'
																&& optimizationData
																&& optimizationData.fieldData
															) {
																	if(generatedJavascript && generatedJavascript.includes('startingContext')) {
																		// If this is from the startingContext
																		// Bulk QP doesn't already handle singleField optimizations, so we need to do that
																		let fsn = optimizationData.fieldData.fieldSchemaName;
																		let part = optimizationData.fieldData.part;
																		let resultObj = {};
																		rows.forEach(({recordId, tableSchemaName}) => {
																			let value = RecordStore.getValueByFieldSchemaName(tableSchemaName, recordId, fsn);
																			value = value && value.get ? value.get('value') : undefined;
																			if(part) {
																				value = value ? value[part] : undefined;
																			}
																			resultObj[recordId] = value;
																		});
																		expressionResults[attachmentId] = expressionResults[attachmentId] || {};
																		expressionResults[attachmentId][fieldId] = expressionResults[attachmentId][fieldId] || {};
																		expressionResults[attachmentId][fieldId][settingSchemaName] = resultObj;
																		expressionObj.result = resultObj;
																		expressionPromise = Promise.resolve(resultObj);
																	} else {
																		// This must be another default context, and will have the same result for each row
																		let localRecordSetContext = Object.assign({}, recordSetContext, { startingContext: rows, currentRecord: rows, pageCurrentRecord: rows });
																		// We don't need to use the bulk EP for one record
																			expressionPromise = (FieldComponents.expression.processExpression(rows[0].recordId, rows[0].tableSchemaName, expressionObj,
																					localRecordSetContext)
																				.then(expressionResult => {
																					let resultObj = {};
																					rows.forEach(({recordId, tableSchemaName}) => {
																						resultObj[recordId] = expressionResult;
																					});
																					
																					expressionResults[attachmentId] = expressionResults[attachmentId] || {};
																					expressionResults[attachmentId][fieldId] = expressionResults[attachmentId][fieldId] || {};
																					expressionResults[attachmentId][fieldId][settingSchemaName] = expressionResult;
																					expressionObj.result = resultObj;
																					return resultObj;
																				}));

																	}
															} else if (rows && rows.length === 1) {
																let localRecordSetContext = Object.assign({}, recordSetContext, { startingContext: rows, currentRecord: rows, pageCurrentRecord: rows });
																// We don't need to use the bulk EP for one record
																	expressionPromise = (FieldComponents.expression.processExpression(rows[0].recordId, rows[0].tableSchemaName, expressionObj,
																			localRecordSetContext)
																		.then(expressionResult => {
																			let resultObj = {[rows[0].recordId]: expressionResult};
																			expressionResults[attachmentId] = expressionResults[attachmentId] || {};
																			expressionResults[attachmentId][fieldId] = expressionResults[attachmentId][fieldId] || {};
																			expressionResults[attachmentId][fieldId][settingSchemaName] = expressionResult;
																			expressionObj.result = resultObj;
																			return resultObj;
																		}));
															} else if (rows && !rows.length) {
																// Empty record but we should still return the expression values as best as we can
																expressionPromise = (FieldComponents.expression.processExpression('', '', expressionObj, Object.assign({}, recordSetContext, { startingContext: rows, currentRecord: rows, pageCurrentRecord: rows }))
																	.then(expressionResult => {
																		let resultObj = {'': expressionResult};
																		expressionResults[attachmentId] = expressionResults[attachmentId] || {};
																		expressionResults[attachmentId][fieldId] = expressionResults[attachmentId][fieldId] || {};
																		expressionResults[attachmentId][fieldId][settingSchemaName] = expressionResult;
																		expressionObj.result = resultObj;
																		return resultObj;
																	}));
															} else if (!rows && contextMap && records) {
																let expressionPromises = [];
																Object.keys(contextMap).forEach(recordId => {
																	let localRows = contextMap[recordId] || [];
																	let tableSchemaName = Object.keys(records)[0];
																	localRows = localRows.map(recordId => {
																		return {recordId, tableSchemaName};
																	});
																	if(
																		optimizationScheme === 'singleField'
																		&& optimizationData
																		&& optimizationData.fieldData
																	) {
																		// Bulk QP doesn't already handle singleField optimizations, so we need to do that
																		let fsn = optimizationData.fieldData.fieldSchemaName;
																		let part = optimizationData.fieldData.part;
																		let resultObj = {};
																		localRows.forEach(({recordId, tableSchemaName}) => {
																			let value = RecordStore.getValueByFieldSchemaName(tableSchemaName, recordId, fsn);
																			value = value && value.get ? value.get('value') : undefined;
																			if(part) {
																				value = value ? value[part] : undefined;
																			}
																			resultObj[recordId] = value;
																		});
																	} else {
																		expressionPromises.push(
																			expressionPromise = FieldComponents.expression.processExpressionBulkWithoutRenderEntry(
																			expressionObj.fieldId, settingSchemaName, localRows, {renderId: ''}, recordSetContext,
																			expressionObj.parentComponent, expressionObj.parentComponentType || 'field', attachmentId, expressionObj.settingPath
																		));
																	}
																});
																Promise.all(expressionPromises)
																		.then(bulkResults => {
																			let resultObj = {};
																			bulkResults.forEach(bulkResult => {
																				bulkResult.forEach(({recordId, value}) => resultObj[recordId] = value);
																			});
																			expressionObj.result = resultObj;
																			expressionResults[attachmentId] = expressionResults[attachmentId] || {};
																			expressionResults[attachmentId][fieldId] = expressionResults[attachmentId][fieldId] || {};
																			expressionResults[attachmentId][fieldId][settingSchemaName] = resultObj;
																			return bulkResults;
																		});
															} else {
																expressionPromise = FieldComponents.expression.processExpressionBulkWithoutRenderEntry(
																	expressionObj.fieldId, settingSchemaName, rows, {renderId: ''}, recordSetContext,
																	expressionObj.parentComponent, expressionObj.parentComponentType || 'field', attachmentId,  expressionObj.settingPath
																	)
																	.then(bulkResult => {
																		let resultObj = {};
																		bulkResult.forEach(({recordId, value}) => resultObj[recordId] = value);
																		expressionObj.result = resultObj;
																		expressionResults[attachmentId] = expressionResults[attachmentId] || {};
																		expressionResults[attachmentId][fieldId] = expressionResults[attachmentId][fieldId] || {};
																		expressionResults[attachmentId][fieldId][settingSchemaName] = resultObj;
																		return bulkResult;
																	});
															}
															// else if(optimizationScheme === 'static' && optimizationData) {
															// 	expressionObj.result = optimizationData.value;
															// 	expressionPromise = Promise.resolve(optimizationData.value);
															// } else if (
															// 	optimizationScheme === 'singleField'
															// 	&& optimizationData
															// 	&& optimizationData.fieldData
															// ) {
															// 	let fsn = optimizationData.fieldData.fieldSchemaName;
															// 	let part = optimizationData.fieldData.part;
															// 	let value = RecordStore.getValueByFieldSchemaName(dataTableSchemaName, dataRecordId, fsn);
															// 	value = value && value.get ? value.get('value') : undefined;
															// 	if(part) {
															// 		value = value ? value[part] : undefined;
															// 	}
															// 	expressionObj.result = value;
															// 	expressionPromise = Promise.resolve(value);
															// } else {
															// 	expressionPromise = (FieldComponents.expression.processExpression(dataRecordId, dataTableSchemaName, expressionObj, { currentRecord: rows })
															// 		.then(expressionResult => {
															// 			console.log('expressionResult with currentRecord', rows, expressionResult);
															// 			expressionResults[attachmentId] = expressionResults[attachmentId] || {};
															// 			expressionResults[attachmentId][settingSchemaName] = expressionResult;
															// 			expressionObj.result = expressionResult;
															// 		}));
															// }
															
															expressionPromises.push(expressionPromise);
														}
													});
											});
									}
								});
						}
						Promise.all(expressionPromises)
							.then(() => {
								return resolve(expressionResults);
							})
							.catch(reject);
					});

					let childQueriesPromise = Promise.resolve();
					if(queries) {
						childQueriesPromise = queries.map(query => {
							return GridHeightUtils.processAllQueriesToRows(query, parentDataQuery, rows, currentRecordContext, pageRecordContext, FieldComponents, RecordStore, recordSetContext);
						});
					}

					return Promise.all([expressionsPromise, Promise.all(childQueriesPromise), rows]);
				})
				.then(resolve)
				.catch(reject);
		});
		
	},

	/**
	 * Processes an individual grid query, including
	 * seeing if it's already been processed and getting any
	 * necessary fields.
	 * 
	 * @param {object} parentQuery The parent query
	 * @param {object} parentGridInfo The gridInfo of the current grid
	 * @param {array} currentRecordContext The current record context
	 * @param {array} pageRecordContext The page record context
	 * @param {array} fields Any fields to look up
	 * @param {object} recordSetContext The recordSetContext to run with
	 * @param {string} mode The current mode
	 * @param {object} FieldComponents Optional The FieldComponents utils
	 * @returns 
	 */
	processIndividualGridQuery(parentQuery, parentGridInfo, currentRecordContext, pageRecordContext, fields, recordSetContext, mode, FieldComponents) {
		FieldComponents = FieldComponents || require('../utils/field-components').default;

		let {
			queryJSON,
			// expressions,
			// recordSetInfo
			// queries
		} = parentQuery;

		let dataRecordId = currentRecordContext && currentRecordContext[0] && currentRecordContext[0].recordId
			? currentRecordContext[0].recordId
			: '';
		let dataTableSchemaName = currentRecordContext && currentRecordContext[0] && currentRecordContext[0].tableSchemaName
			? currentRecordContext[0].tableSchemaName : (queryJSON ? FieldComponents.query.getReturnTable(queryJSON)
				: '');

		let {
			componentId,
			// componentType,
			renderId,
			// renderParentId,
		} = parentGridInfo;

		recordSetContext = Object.assign({
			startingContext: currentRecordContext,
			page: pageRecordContext,
			'application': [{
				'recordId': ContextStore.getApplicationId(),
				'tableSchemaName': 'applications'
			}],
			'installation': [{
				'recordId': ContextStore.getInstallationId(),
				'tableSchemaName': 'installations'
			}],
			'currentUser': [{
				'recordId': AuthenticationStore.getUserId(),
				'tableSchemaName': 'users'
			}],
			'pagePageRecord': pageRecordContext,
			'pageCurrentRecord': currentRecordContext
		}, recordSetContext);


		let queryLookupPromise;
		if (queryJSON && queryJSON.startsWith('{') && !parentQuery.results && !parentQuery.contextMap) { // If we've already run this query for the parent we don't want to run it again for the child

			if(queryJSON.includes('should be first nodeId')) {
				console.warn('Malformed query:', queryJSON);
			}
			
			// Run the query with the fields and expressions to look up
			// using the currentRecordContext as the parent record for the query
			queryLookupPromise = FieldComponents.query.processQueryWithoutRenderEntry(
				queryJSON,
				fields,
				{
					dataRecordId: currentRecordContext && currentRecordContext[0] && currentRecordContext[0].recordId ? currentRecordContext[0].recordId : '',
					dataTableSchemaName: currentRecordContext && currentRecordContext[0] && currentRecordContext[0].tableSchemaName ? currentRecordContext[0].tableSchemaName : '',
					componentId,
					renderId
				},
				recordSetContext,
				currentRecordContext && currentRecordContext[0] && currentRecordContext[0].recordId ? currentRecordContext[0].recordId : '',
				currentRecordContext && currentRecordContext[0] && currentRecordContext[0].tableSchemaName ? currentRecordContext[0].tableSchemaName : ''
			);
		} else if (parentQuery.contextMap) {
			let resultsTsn = parentQuery.records ? Object.keys(parentQuery.records)[0] : undefined;
			queryLookupPromise = Promise.resolve({
				rows: parentQuery.contextMap[dataRecordId]
					? parentQuery.contextMap[dataRecordId].map(recordId => {
						return {recordId, tableSchemaName: resultsTsn};
					})
					: []
			});
		} else {
			if (mode !== 'add') {
				let fieldDict = {};
				let lookupFields = [];

				// Look up the fields here
				let RecordStore = require('../stores/record-store').default;
				let RecordsAPI = require('../apis/records-api').default;

				fields.forEach(field => {
					let fieldId = typeof field === 'string' ? field : field.fieldId;
					let fieldHasData = FieldStore.getHasData(fieldId);
					let recordData = RecordStore.getRecord(dataTableSchemaName, dataRecordId);

					if (!fieldDict[fieldId]) {
						let fieldObj = FieldStore.get(fieldId) || {};
						if (!fieldHasData) {
							// Also get dynamic selection fields
							let fieldTypeObj = fieldObj ? FieldTypeStore.get(fieldObj.fieldType) : {};
							fieldHasData = fieldTypeObj && fieldTypeObj.dataType === 'relationship';
						}
						// @TODO: Does/can this handle empty field values?
						// If our field requires data && we don't yet have ANY data... || we have data, we just don't have THIS piece of data..
						if (fieldHasData && (!recordData || typeof recordData[fieldObj.fieldSchemaName] === 'undefined')) {
							fieldObj.fieldId = fieldId;
							fieldDict[fieldId] = fieldObj;
						}
					}
				});

				lookupFields = Object.keys(fieldDict).map(recordId => fieldDict[recordId]);
				if (lookupFields.length) {
					let fieldSchemaNamesToGet = lookupFields.map(({ fieldSchemaName }) => fieldSchemaName);
					queryLookupPromise = RecordsAPI.getRecord(dataTableSchemaName, dataRecordId, fieldSchemaNamesToGet)
						.then(() => {
							return parentQuery.results ? { rows: parentQuery.results } : { rows: currentRecordContext };
						});
				} else if (parentQuery.results) {
					queryLookupPromise = Promise.resolve({ rows: parentQuery.results });
				} else {
					// Some "queries" have fields for lookup but do not actually have queries

					// We don't wait for these because nothing depends on the field values we find
					// The fields will process it when the fields process it
					// let RecordsAPI = require('../apis/records-api').default;
					// RecordsAPI.getRecordByFieldIds(dataTableSchemaName, dataRecordId, fields);

					queryLookupPromise = Promise.resolve({
						rows: currentRecordContext
					});
				}
			} else if (parentQuery.results) {
				queryLookupPromise = Promise.resolve({ rows: parentQuery.results });
			} else {
				// Some "queries" have fields for lookup but do not actually have queries

				// We don't wait for these because nothing depends on the field values we find
				// The fields will process it when the fields process it
				// let RecordsAPI = require('../apis/records-api').default;
				// RecordsAPI.getRecordByFieldIds(dataTableSchemaName, dataRecordId, fields);

				queryLookupPromise = Promise.resolve({
					rows: currentRecordContext
				});
			}
		}

		return queryLookupPromise;
	},

	/**
	 * Recursive function to iterate over and process grids, pushing them into an array of grids
	 * @param {object} parentQuery The parent query information for the grid
	 * @param {object} parentGridInfo Information for the parent grid being calculated and recursed over
	 * @param {array} grids Array of grids to push into
	 * @param {array} currentRecordContext The current record context
	 * @param {array} pageRecordContext The page record context
	 * @param {array} activeOverlays The overlays active when this calculates
	 * @param {string} mode Optional: the mode (view vs. edit, etc.) to calculate this in
	 * @param {string} responsiveMode Optional: the responsive mode (sm/md/lg, etc.) to calculate this
	 * @param {object} extraRecordContext Optional: extra record sets to include in the recordSets object
	 * @returns 
	 */
	processGridsRecursively(parentQuery, parentGridInfo, grids, currentRecordContext, pageRecordContext, activeOverlays, mode, responsiveMode, extraRecordContext) {
		let FieldComponents = require('../utils/field-components').default;
		return new Promise((resolve, reject) => {
			mode = mode || 'view';
			let {
				queryJSON,
				fields, // @TODO: Somehow, the fields for at least repeating grids have some fields from the wrong table. Find out why.
				expressions,
				recordSetInfo
				// queries
			} = parentQuery;
			let dataRecordId = currentRecordContext && currentRecordContext[0] && currentRecordContext[0].recordId
				? currentRecordContext[0].recordId
				: '';
			let dataTableSchemaName = currentRecordContext && currentRecordContext[0] && currentRecordContext[0].tableSchemaName
				? currentRecordContext[0].tableSchemaName : (queryJSON ? FieldComponents.query.getReturnTable(queryJSON)
					: '');

			let {
				componentId,
				parentComponentId,
				componentType,
				renderId,
				// renderParentId,
				attachmentId,
				availableModes,
				attachedFields,
				variantNameOverride
			} = parentGridInfo;

			let componentObj = componentType === 'page' ? PageStore.get(componentId) : FieldStore.get(componentId);
			if(!componentObj) {
				console.warn('componentObj not found for %s %s', componentType, componentId);
			}
			if(componentObj && componentType === 'field' && parentComponentId) {
				let fieldSettings = attachmentId
					? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, componentId, parentComponentId, responsiveMode) 
					: FieldSettingsStore.getSettings(componentId, parentComponentId, responsiveMode);
				Object.assign(componentObj, fieldSettings);
			}

			let recordSetContext = {
				startingContext: currentRecordContext,
				page: pageRecordContext,
				'application': [{
					'recordId': ContextStore.getApplicationId(),
					'tableSchemaName': 'applications'
				}],
				'installation': [{
					'recordId': ContextStore.getInstallationId(),
					'tableSchemaName': 'installations'
				}],
				'currentUser': [{
					'recordId': AuthenticationStore.getUserId(),
					'tableSchemaName': 'users'
				}],
				'pagePageRecord': pageRecordContext,
				'pageCurrentRecord': currentRecordContext
			};

			Object.assign(recordSetContext, extraRecordContext);

			let queryLookupPromise = GridHeightUtils.processAllQueriesToRows(parentQuery, undefined, currentRecordContext, undefined, pageRecordContext, FieldComponents, undefined, extraRecordContext);
			queryLookupPromise
				.then(([, , rows]) => {
					if (parentQuery.contextMap) {
						let resultsTsn = parentQuery.records ? Object.keys(parentQuery.records)[0] : undefined;
						rows = parentQuery.contextMap[dataRecordId]
							? parentQuery.contextMap[dataRecordId].map(recordId => {
								return {recordId, tableSchemaName: resultsTsn};
							})
							: [];
					}
					if(rows && rows[0]) {
						dataTableSchemaName = rows[0].tableSchemaName;
						dataRecordId = rows[0].recordId;
					} else if(rows) {
						// If rows is empty, blank out the record ID and TSN to make this unbound. (31227 - field that should be unbound is showing DB Data)
						dataTableSchemaName = '';
						dataRecordId = '';
					}

					parentGridInfo.dataRecordId = dataRecordId;
					parentGridInfo.dataTableSchemaName = dataTableSchemaName;
					// Now calculate the grid's children
					// This may require special handling for some field types.
					let expressionResults = {};
					Object.keys(expressions).forEach(attachmentId => {
						Object.keys(expressions[attachmentId]).forEach(fieldId => {
							Object.keys(expressions[attachmentId][fieldId]).forEach(settingSchemaName => {
								expressionResults[attachmentId] = expressionResults[attachmentId] || {};
								expressionResults[attachmentId][fieldId] = expressionResults[attachmentId][fieldId] || {};
								let result = expressions[attachmentId][fieldId][settingSchemaName].result;
								expressionResults[attachmentId][fieldId][settingSchemaName] = result && result[dataRecordId] ? result[dataRecordId] : '';
							});
						});
					});
					if (componentType === 'page') {
						// Page child handling is pretty straightforward
						return FieldComponents.visibility.runAttachedFieldVisibility(attachedFields, ['view', 'edit'], renderId, componentId, dataRecordId, dataTableSchemaName, undefined, componentType, activeOverlays, recordSetContext)
							.then(newAttachedFields => {
								// toPush is the finalized grid info for THIS grid being processed.
								// No child grids have yet been considered.
								let toPush = GridHeightUtils.processSimpleAttachedFields(parentGridInfo, newAttachedFields, expressionResults, dataRecordId, dataTableSchemaName);
								grids.push(toPush);
								let childGrids = [];
								// Now we also need to recursively process our children
								// First, loop over the children and identify if they have children or other processing to do
								// Next, build the parentGridInfo object for the corresponding children
								// and recurse
								newAttachedFields.forEach(field => {
									let { attachmentId, fieldId } = field;
									// Once processed, the newAttachedFields have a single field ID
									// @TODO: Should this be changed to pre-process all possible attached fields in a location?
									let fieldSettingsObj = attachmentId
										? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, fieldId, componentId, responsiveMode)
										: FieldSettingsStore.getSettings(fieldId, componentId, responsiveMode);
									// Because the recursor checks field types, we really just need
									// to check if this field has any potential children
									if (fieldSettingsObj && (fieldSettingsObj.attachedFields || fieldSettingsObj.eventRecords)) { // @TODO: More generic lookup for this involving checking for the variant + calculateGridInfo method
										let queryForChild = (parentQuery && parentQuery.queriesLookup && parentQuery.queriesLookup[fieldId]) ? parentQuery.queriesLookup[fieldId] : parentQuery;
										// @TODO: Are there any other ways for fields to have children? Or is this sufficient?
										let childGridInfo = {
											componentId: fieldId,
											parentComponentId: componentId,
											parentComponentType: componentType,
											attachmentId,
											componentType: 'field',
											renderId: field.renderId,
											renderParentId: renderId,
											attachedFields: fieldSettingsObj.attachedFields ? JSON.parse(fieldSettingsObj.attachedFields) : [],
											fieldPosition: fieldSettingsObj.fieldPosition ? JSON.parse(fieldSettingsObj.fieldPosition) : {},
											fieldPositionExtras: fieldSettingsObj.fieldPositionExtras ? JSON.parse(fieldSettingsObj.fieldPositionExtras) : {},
											availableModes: field.availableModes ? field.availableModes.filter(mode => availableModes.includes(mode)) : [],
											query: queryForChild
										};
										childGrids.push(childGridInfo);
									}
								});
								return Promise.all(childGrids.map(childGridInfo => GridHeightUtils.processGridsRecursively(childGridInfo.query, childGridInfo, grids, rows, pageRecordContext, activeOverlays, mode, responsiveMode, extraRecordContext)));
							});
					} else if (componentObj) {
						/* Special handling for children of:
						* Content Drop-Down
						* Content Tabs
						* Field Container - Non-repeating
						* Field Container - repeating
						* Lists
						*/
						// Add mode counts as edit mode for purposes of variant lookups
						let modeForLookup = mode === 'view'
							? 'view'
							: 'edit';
						let variantName = variantNameOverride;
						if(!variantName) {
							// If this is in edit (or add -- see above) mode and has an edit variant, use that
							// Otherwise, use the view variant
							variantName = modeForLookup === 'edit' && componentObj.editVariant
								? componentObj.editVariant
								: componentObj.viewVariant;
						}
						// Lookup the default component name for this mode.
						if(!variantName) {
							variantName = FieldStore.getDefaultVariantComponentName(componentId, modeForLookup, componentObj.fieldType);
						}
						// Okay, at this point just use the default view variant, there's always one of those
						if(!variantName) {
							variantName = FieldStore.getDefaultVariantComponentName(componentId, 'view', componentObj.fieldType);
						}
						let variantObj = citDev[variantName];
						// Process the variant's calculateGridInfo
						if(variantObj && variantObj.calculateGridInfo) {
							return variantObj.calculateGridInfo(parentGridInfo, componentObj, grids,
									{rows, recordSetContext, pageRecordContext},
									fields.map(fieldId => FieldStore.get(fieldId)),
									availableModes, activeOverlays, mode, responsiveMode
								)
								.catch(console.error);
						}

						if (componentObj.fieldType === '7ebd9251-675c-4129-95e3-6b8e31c135a2') {
							// Is this a repeating or non-repeating grid?
							// If it's non-repeating, handle the same way as pages;
							// Otherwise, use repeating grid handling
							// We expect to mark attached field children as repeating in their gridInfo when calculating
							// and so do not need to do more than check the parentGridInfo here
							if (!parentGridInfo.isRepeating && variantName !== 'fieldContainerMultiTilesView') {
								// Basically the same as a page
								// Using view and edit as available modes because we sometimes have view children of edit-only containers.
								return FieldComponents.visibility.runAttachedFieldVisibility(attachedFields, ['view', 'edit'], renderId, componentId, dataRecordId, dataTableSchemaName, undefined, componentType, activeOverlays, recordSetContext)
									.then(newAttachedFields => {
										let toPush = GridHeightUtils.processSimpleAttachedFields(parentGridInfo, newAttachedFields, expressionResults, dataRecordId, dataTableSchemaName);
										if(recordSetInfo) {
											toPush.recordSets = toPush.recordSets || {};
											toPush.recordSets[recordSetInfo.setName] = Object.assign({
												tableSchemaName: dataTableSchemaName, // @TODO: Should this be the TSN we have or the first one from the rows?
												recordId: dataRecordId,
												setName: recordSetInfo.setName,
												uiName: 'results',
												query: queryJSON,
												fields,
												rows
											}, recordSetInfo);
										}
										grids.push(toPush);
										let childGrids = [];
										// Now we also need to recursively process our children
										// First, loop over the children and identify if they have children or other processing to do
										// Next, build the parentGridInfo object for the corresponding children
										// and recurse
										newAttachedFields.forEach(field => {
											let { attachmentId, fieldId } = field;
											// Once processed, the newAttachedFields have a single field ID
											// @TODO: Should this be changed to pre-process all possible attached fields in a location?
											let fieldSettingsObj = attachmentId
												? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, fieldId, componentId)
												: FieldSettingsStore.getSettings(fieldId, componentId);
											// Because the recursor checks field types, we really just need
											// to check if this field has any potential children
											if (fieldSettingsObj && (fieldSettingsObj.attachedFields || fieldSettingsObj.eventRecords)) { // @TODO: More generic lookup for this involving checking for the variant + calculateGridInfo method
												let queryForChild = (parentQuery && parentQuery.queriesLookup && parentQuery.queriesLookup[fieldId]) ? parentQuery.queriesLookup[fieldId] : parentQuery;
												// @TODO: Are there any other ways for fields to have children? Or is this sufficient?
												let childGridInfo = {
													componentId: fieldId,
													attachmentId,
													componentType: 'field',
													parentComponentId: componentId,
													parentComponentType: componentType,
													renderId: field.renderId,
													renderParentId: renderId,
													attachedFields: fieldSettingsObj.attachedFields ? JSON.parse(fieldSettingsObj.attachedFields) : [],
													fieldPosition: fieldSettingsObj.fieldPosition ? JSON.parse(fieldSettingsObj.fieldPosition) : {},
													fieldPositionExtras: fieldSettingsObj.fieldPositionExtras ? JSON.parse(fieldSettingsObj.fieldPositionExtras) : {},
													availableModes: field.availableModes ? field.availableModes.filter(mode => availableModes.includes(mode)) : [],
													query: queryForChild
												};
												childGrids.push(childGridInfo);
											}
										});
										return Promise.all(childGrids.map(childGridInfo => GridHeightUtils.processGridsRecursively(childGridInfo.query, childGridInfo, grids, rows, pageRecordContext, activeOverlays, mode, responsiveMode, extraRecordContext)));
									});
							}
						}
					}
				})
				.then(() => {
					return resolve(grids);
				})
				.catch(reject);
		});
	},

	/**
	 * Gets the gridInfo for all children of a parent grid.
	 * 
	 * @param {object} parentGridInfo Parent information for the grid
	 * @param {array} newAttachedFields The post-visibility attached fields
	 * @param {array} expressionResults Array of expression results if processed
	 * @param {string} dataRecordId The data record ID for the current context
	 * @param {string} dataTableSchemaName The data TSN for the current context
	 * @returns 
	 */
	processSimpleAttachedFields(parentGridInfo, newAttachedFields, expressionResults, dataRecordId, dataTableSchemaName) {
		let {
			componentId,
			attachmentId,
			attachmentKey,
			componentType,
			renderId,
			renderParentId,
			gridLayoutHeights,
			fieldPosition,
			fieldPositionExtras,
			availableModes,
			gridInfo,
			dialogOptions
		} = parentGridInfo;

		// @TODO: Process settings and stuff for individual attached fields,
		// recurse if necessary
		let fieldPositionsBySize = {};
		let columnYTracker = {};
		let hasExpanding = {};
		let spacerHeights = {};

		if (!gridLayoutHeights) {
			// Get the RenderStore value
			let renderObj = RenderStore.get(renderId);
			gridLayoutHeights = renderObj && renderObj.gridLayoutHeights ? renderObj.gridLayoutHeights : undefined;
		}

		newAttachedFields.forEach(attachedField => {
			let gridInfo = {};
			let attachmentId = attachedField.attachmentId;
			let fieldId = attachedField.recordId || attachedField.fieldId;
			let renderId = attachedField.renderId;
			let renderObj = RenderStore.get(renderId) || {};
			// The format of newAttachedFields uses fieldId instead of recordId
			let gridKey = attachmentId || attachedField.recordId || attachedField.fieldId;

			Object.keys(fieldPosition).forEach(screensize => {
				columnYTracker[screensize] = columnYTracker[screensize] || new Array(12);
				fieldPositionsBySize[screensize] = fieldPositionsBySize[screensize] || [];

				gridInfo[screensize] = {};
				let positionsForSize = fieldPosition[screensize];
				let fieldPositionExtrasObj = {};
				if (fieldPositionExtras && fieldPositionExtras[screensize]) {
					Object.keys(fieldPositionExtras[screensize]).forEach(index => {
						fieldPositionExtrasObj[fieldPositionExtras[screensize][index].i] = fieldPositionExtras[screensize][index];
					});
				}
				let fieldPositionDict = {};
				if (Array.isArray(positionsForSize) && positionsForSize.length) {
					positionsForSize.forEach(positionObj => {
						fieldPositionDict[positionObj.i] = positionObj;
					});
				}

				let gridInfoForSize = fieldPositionDict[gridKey];
				let gridExtras = fieldPositionExtrasObj[gridKey];
				if (gridInfoForSize) {

					// This value accidentally made it into some locations on dev-eng
					// Originally, "originalH" was named "minH," which it turns out is
					// a reserved value in our grid provider
					// So clear out any old values
					delete gridInfoForSize.minH;

					// Here's where we need to set grid autosizing/expansion info
					// but we don't calculate anything at this point

					gridInfoForSize.originalH = gridInfoForSize.h;
					gridInfoForSize.originalY = gridInfoForSize.y;
					gridInfoForSize.autofit = gridExtras && gridExtras.autofit === 'true';
					gridInfoForSize.expands = gridExtras && gridExtras.expands === 'true';

					let renderGridInfo = renderObj && renderObj.gridInfo && renderObj.gridInfo[screensize] ? renderObj.gridInfo[screensize] : {};

					// Because this is initializing and nothing's measured yet, I don't think we track
					// field position pushing here either

					if (gridInfoForSize.autofit) {
						gridInfoForSize.h = Math.max(gridInfoForSize.originalH, renderGridInfo.h || 0);
					}

					fieldPositionsBySize[screensize].push(gridInfoForSize);

					// Grid information tracking
					let left = gridInfoForSize.x;
					let right = gridInfoForSize.x + gridInfoForSize.w;

					for (let i = left; i < right; i++) {
						columnYTracker[screensize][i] = columnYTracker[screensize][i] || [];
						columnYTracker[screensize][i].push(gridInfoForSize);
					}
				}
				gridInfo[screensize] = gridInfoForSize;
			});
			attachedField.gridInfo = gridInfo;

			attachedField.settings = expressionResults && expressionResults[gridKey] && expressionResults[gridKey][fieldId] ? expressionResults[gridKey][fieldId] : undefined;
		});

		Object.keys(columnYTracker).forEach(screensize => {
			columnYTracker[screensize].forEach((col, index) => {
				col = col.sort((a, b) => {
					return a.y - b.y;
				});
				columnYTracker[screensize][index] = col;
			});
		});

		Object.keys(columnYTracker).forEach(screensize => {
			// Now that this is all built, reconcile the grid shifts
			let { hasExpanding: hasExpandingForSize, columnSpacers } = GridHeightUtils.reconcileGridShifts(gridLayoutHeights && gridLayoutHeights[screensize] ? gridLayoutHeights[screensize] : 0, columnYTracker[screensize], fieldPositionsBySize[screensize]);
			hasExpanding[screensize] = hasExpandingForSize;
			spacerHeights[screensize] = columnSpacers;
		});

		// Grid entry for this field
		let toPush = {
			attachmentId: attachmentId,
			attachmentKey: attachmentKey,
			parentRenderId: renderId,
			grandparent: renderParentId,
			dataRecordId,
			dataTableSchemaName,
			componentId,
			componentType,
			attachedFields: newAttachedFields,
			gridLayoutHeights: gridLayoutHeights,
			hasExpanding: hasExpanding,
			spacerHeights: spacerHeights,
			availableModes: availableModes // @TODO: Is this right?
		};
		if (gridInfo) {
			toPush.gridInfo = gridInfo;
		}

		if(dialogOptions) {
			toPush.dialogOptions = dialogOptions;
		}

		return toPush;
	},

	/**
	 * This code is redundant across all content tabs and dropdowns
	 * and so has been factored out to here.
	 * 
	 * @param {object} gridInfo The gridInfo
	 * @param {object} settings The field settings
	 * @param {array} grids The grids array to modify
	 * @param {object} queryResult queryResult info
	 * @param {array} fields Fields to look up
	 * @param {array} availableModes The available modes
	 * @param {array} activeOverlays Which overlays are currently active
	 * @param {string} mode The current mode
	 * @param {string} responsiveMode The current responsive mode
	 * @returns 
	 */
	calculateGridInfoForContentTabsAndDropdowns(gridInfo, settings, grids, queryResult, fields, availableModes, activeOverlays, mode, responsiveMode) {
		let newGrids = [];
		let attachedFields = settings.attachedFields;
		let attachedFieldsCorrected = [];
		let {
			renderId: parentRenderId,
			renderParentId: grandparent,
			dataRecordId,
			dataTableSchemaName,
			componentId: fieldId,
			attachmentId
		} = gridInfo;
		let FieldComponents = require('../utils/field-components').default;
		let RecordStore = require('../stores/record-store').default;
		let fieldObj = FieldStore.get(fieldId);
		let tabValue = undefined;
		
		if(dataRecordId) {
			tabValue = fieldObj
				? RecordStore.getValueByFieldSchemaName(dataTableSchemaName, dataRecordId, fieldObj.fieldSchemaName)
				: undefined;
		} else {
			// This is from browser storage
			let BrowserStorageStore = require('../stores/browser-storage-store').default;
			tabValue = fieldObj
				? BrowserStorageStore.getValueByFieldSchemaName(fieldObj.fieldSchemaName)
				: undefined;
		}
		
		tabValue = tabValue && tabValue.get ? tabValue.get('value') : undefined;
		let valueIsInResults = false;
		let tabValueObj = tabValue ? ObjectUtils.getObjFromJSON(tabValue) : undefined;

		return Promise.all([
			FieldComponents.visibility.filterOptionsByVisibility(
				attachedFields, 'view', parentRenderId, dataRecordId, dataTableSchemaName, fieldId,
				queryResult && queryResult.recordSetContext ? queryResult.recordSetContext : undefined,
				activeOverlays
			),
			FieldComponents.visibility.runAttachedFieldVisibility(
				attachedFields, ['view', 'edit'], parentRenderId, fieldId,
				dataRecordId, dataTableSchemaName, undefined, 'field',
				activeOverlays, queryResult && queryResult.recordSetContext ? queryResult.recordSetContext : undefined)
		])
			.then(([tabResults, attachedFieldResults]) => {
				// Convert to options format needed by the render
				// Stored as a combination of grids in the render store,
				// recordSets in the render + record sets stores
				// and localContextInfo in the local context store (grid.localContextInfo)
				// We might be able to skip over the localContextInfo for now and keep that portion of the lifecycle? Investigate.

				let attachedFieldDict = {};
				attachedFieldResults.forEach(result => {
					let attachmentId = result.attachmentId || result.fieldId || result.recordId;
					attachedFieldDict[attachmentId] = result;
				});

				let options = [];
				let recordSets = {};
				let recordFields = {};
				// Now we need to process the queries and expressions in these results
				let tabLookupPromises = [];
				let expressions = [];
				tabResults.forEach((option, index) => {
					let type = option.type;
					if (type === 'dynamic') {
						let recordSetName = option.recordSetName;

						let displayFieldSchemaNames = [];
						let query = option.query;
						let tableSchemaName = FieldComponents.query.getReturnTable(query);
						let attachmentId = option.attachmentId || option.fieldId;
						
						// We already have logic when we build our gridInfo to determine some child fields. Make sure to include these fields, too.
						let matchingQuery = gridInfo && gridInfo.query && gridInfo.query.queriesLookup && gridInfo.query.queriesLookup[attachmentId]
						    ? gridInfo.query.queriesLookup[attachmentId]
						    : undefined;
						matchingQuery = Object.assign({
							queryJSON: query,
							dataQueryJSON: query,
							fields: [],
							expressions: {},
							queries: [],
							queriesLookup: {}
						}, matchingQuery);
					    if(matchingQuery && matchingQuery.fields) {
					        matchingQuery.fields.forEach(fieldId => {
					            let fieldObj = FieldStore.get(fieldId);
					            fieldObj && displayFieldSchemaNames.push({
					                fieldSchemaName: fieldObj.fieldSchemaName,
					                fieldType: fieldObj.fieldType,
					                fieldId: fieldId
					            });
					        });
					    }

						// Process single field type expressions for dynamic row
						['displayedName', 'icon', 'rightAlignedText', 'secondLineText'].forEach(settingName => {
							let expressionJSON = option[settingName];
							let expressionObj = expressionJSON ? ObjectUtils.getObjFromJSON(expressionJSON) : null;
							if (
								expressionObj &&
								expressionObj.optimizationScheme === 'singleField' &&
								expressionObj.optimizationData &&
								expressionObj.optimizationData.fieldData
							) {
								// Make sure the required field information is pushed
								displayFieldSchemaNames.push({
									fieldSchemaName: expressionObj.optimizationData.fieldData.fieldSchemaName,
									fieldType: expressionObj.optimizationData.fieldData.fieldTypeId,
									fieldId: expressionObj.optimizationData.fieldData.fieldId
								});
							}
							expressions[index] = expressions[index] || {};
							expressions[index][settingName] = expressionObj;
						});

						// Now actually look up the values
						let lookupPromise = new Promise((resolve, reject) => {
							let localPromise = GridHeightUtils.processIndividualGridQuery
    							? GridHeightUtils.processIndividualGridQuery(
    							    matchingQuery,
    							    { componentId: fieldId, renderId: parentRenderId, dataTableSchemaName, dataRecordId },
    							    queryResult.currentRecordContext || (queryResult.recordSetContext ? queryResult.recordSetContext.startingContext : undefined),
    							    queryResult.pageRecordContext || (queryResult.recordSetContext ? queryResult.recordSetContext.page : undefined),
    							    displayFieldSchemaNames,
    							    queryResult && queryResult.recordSetContext ? queryResult.recordSetContext : undefined,
    							    mode,
    							    FieldComponents // Optional but saves us a lookup
							    )
    							: FieldComponents.query.processQueryWithoutRenderEntry(
    								query, displayFieldSchemaNames,
    								{ componentId: fieldId, renderId: parentRenderId, dataTableSchemaName, dataRecordId },
    								queryResult && queryResult.recordSetContext ? queryResult.recordSetContext : undefined,
    								dataRecordId, dataTableSchemaName,
    								true
    							);
							localPromise
								.then(queryResults => {
									// @TODO: Wait, we need to process the expressions in here as well.
									return resolve({
										setName: recordSetName,
										tableSchemaName,
										uiName: recordSetName.replace(fieldId + '-', ''),
										query,
										fields: displayFieldSchemaNames,
										order: option.order,
										rows: queryResults.rows // @TODO: Any other values from queryResults we need?
									});
								})
								.catch(reject);
						});
						tabLookupPromises.push(lookupPromise);
					} else {
						// Static tabs are much more straightforward, but may still have expressions
						// @TODO

						let displayFieldSchemaNames = [];

						// Process single field type expressions for static row
						['displayedName', 'icon'].forEach(settingName => {
							let expressionJSON = option[settingName];
							if (expressionJSON && expressionJSON.startsWith('{')) {
								let expressionObj = expressionJSON ? ObjectUtils.getObjFromJSON(expressionJSON) : null;
								if (
									expressionObj &&
									expressionObj.optimizationScheme === 'singleField' &&
									expressionObj.optimizationData &&
									expressionObj.optimizationData.fieldData
								) {
									// Make sure the required field information is pushed
									displayFieldSchemaNames.push({
										fieldSchemaName: expressionObj.optimizationData.fieldData.fieldSchemaName,
										fieldType: expressionObj.optimizationData.fieldData.fieldTypeId,
										fieldId: expressionObj.optimizationData.fieldData.fieldId
									});
								}

								expressions[index] = expressions[index] || {};
								expressions[index][settingName] = expressionObj;
							} else {
								expressions[index] = expressions[index] || {};
								expressions[index][settingName] = expressionJSON;
							}
						});

						tabLookupPromises.push(Promise.resolve({
							tableSchemaName: dataTableSchemaName,
							fields: displayFieldSchemaNames,
							rows: [{ tableSchemaName: dataTableSchemaName, recordId: dataRecordId }]
						}));
					}
				});

				return Promise.all(tabLookupPromises)
					.then(tabLookupResults => {
						// Each item in each row of each content tab should have a grid item to match it
						// The parent grid should also store the record sets for its tabs if needed
						// We also need to process the expressions in here somewhere
						// @TODO: Actually, we need to look up the expressions here
						let expressionPromises = [];
						if (tabLookupResults) {
							tabLookupResults.forEach((result, index) => {
								let matchingTab = tabResults[index];
								let matchingAttachedField = attachedFieldDict[matchingTab.attachmentId || matchingTab.recordId];
								let matchingFieldObj = FieldStore.get(matchingAttachedField.fieldId);
								// @TODO: Match these up with the tabs and build the correct grids from them
								// Also, do the expressions higher up in the Promise chain
								// We need to get the attachedFields for each attached field as well
								// We also need to get the LocalContextStore values
								if (result.rows) {
									// Dynamic results need to be tracked in the recordSets
									if (matchingTab.type === 'dynamic') {
										let setName = fieldId + '-' + result.setName;
										recordSets[setName] = {
											tableSchemaName: result.tableSchemaName,
											setName: setName,
											uiName: result.uiName,
											query: matchingTab.query,
											fields: result.fields,
											rows: result.rows
										};
									}
									result.rows.forEach(({ recordId, tableSchemaName }) => {
										// Dynamic results need new UUIDs generated because there's more than one child.
										let attachmentKey = (matchingTab.attachmentId || matchingTab.recordId) + '-' + recordId;
										let matchingRenderEntry = RenderStore.findChildRenderId(parentRenderId, (matchingTab.attachmentId || matchingTab.recordId), attachmentKey);
										let renderId = matchingRenderEntry
											? matchingRenderEntry
											: (matchingTab.type === 'dynamic' ? uuid.v4() : matchingAttachedField.renderId);
										let localMatchingAttachedField = Object.assign({}, matchingAttachedField, { renderId });
										attachedFieldsCorrected.push(localMatchingAttachedField)
										// @TODO: What if the matching attached field has a query?
										let matchingQuery = matchingTab.type === 'static' 
											? gridInfo.query
											: undefined;

										if(gridInfo && gridInfo.query && gridInfo.query.queriesLookup && gridInfo.query.queriesLookup[matchingTab.attachmentId || matchingTab.recordId]) {
											matchingQuery = gridInfo.query.queriesLookup[matchingTab.attachmentId || matchingTab.recordId];
										}
										// Now, do we separately have a query child for the container field being displayed?

										if(matchingQuery && matchingQuery.queriesLookup && matchingQuery.queriesLookup[matchingAttachedField.fieldId]) {
											matchingQuery = matchingQuery.queriesLookup[matchingAttachedField.fieldId];
										} else if(matchingTab.type === 'dynamic') {
											// Dynamic field? Then we need to make sure the query matches the specific option selected
											// if there's no other query on the container
											// De-reference
											matchingQuery = JSON.parse(JSON.stringify(matchingQuery));

											// Now override the results
											delete matchingQuery.queryJSON;
											delete matchingQuery.dataQueryJSON;
											matchingQuery.results = [{recordId, tableSchemaName}];
										}

									    // We want to overwrite results in this case 
									    let newQuery = Object.assign({
												fields: [],
												expressions: {}
											}, matchingQuery); // @TODO: See if we need to put the results back in, and if so, why

										newGrids.push({
											query: newQuery,
											attachmentId: matchingTab.attachmentId || matchingTab.recordId,
											attachmentKey: attachmentKey,
											componentType: 'field',
											componentId: localMatchingAttachedField.fieldId,
											dataRecordId: recordId,
											dataTableSchemaName: tableSchemaName,
											parentRenderId: localMatchingAttachedField.renderId,
											grandparent: parentRenderId,
											// @TODO: Do these need parsed in some form, or?
											attachedFields: matchingFieldObj && matchingFieldObj.attachedFields ? JSON.parse(matchingFieldObj.attachedFields) : [],
											fieldPosition: matchingFieldObj && matchingFieldObj.fieldPosition ? JSON.parse(matchingFieldObj.fieldPosition) : {},
											fieldPositionExtras: matchingFieldObj && matchingFieldObj.fieldPositionExtras ? JSON.parse(matchingFieldObj.fieldPositionExtras) : {},
											availableModes: matchingAttachedField.availableModes,
											// @TODO: Any gridInfo?
										})
									})
								}

								// Now it's time for the expression processing portion of this
								// so that we can form these into the options used by the fields
								let matchingExpressions = expressions[index];
								// Initialize the namedContexts object for use in processing
								let namedContextsTemplate = Object.assign({}, queryResult && queryResult.recordSetContext ? queryResult.recordSetContext : {}, {
									startingContext: [{
										recordId: dataRecordId,
										tableSchemaName: dataTableSchemaName
									}]
								});
								let expressionPromise = new Promise((resolve, reject) => {
									if (!matchingExpressions) {
										return resolve({});
									}
									let settingExpressionPromises = [];
									Object.keys(matchingExpressions).forEach(schemaName => {
										let expressionObj = matchingExpressions[schemaName];
										// For now we can't pass this on to the bulk processor, so we manually loop
										if (expressionObj && expressionObj.generatedJavascript) {
											let {result: expressionResult, optimizationData, optimizationScheme} = expressionObj;
											let rowExpressionPromises = result.rows.map(context => {
												let recordId = context && context.recordId ? context.recordId : '';
												let tableSchemaName = context && context.tableSchemaName ? context.tableSchemaName : '';
												let namedContexts = Object.assign({}, namedContextsTemplate, {
													currentQuery: [{
														recordId,
														tableSchemaName
													}]
												});

												if(optimizationScheme === 'static' && optimizationData) {
													return Promise.resolve({recordId, value: optimizationData.value});
												} else if (typeof expressionResult !== 'undefined') {
													return Promise.resolve({recordId, value: expressionResult});
												} else if (
													optimizationScheme === 'singleField'
													&& optimizationData
													&& optimizationData.fieldData
												) {
													let fsn = optimizationData.fieldData.fieldSchemaName;
													let part = optimizationData.fieldData.part;
													let value = RecordStore.getValueByFieldSchemaName(tableSchemaName, recordId, fsn);
													value = value && value.get ? value.get('value') : undefined;
													if(part) {
														value = value ? value[part] : undefined;
													}
													return Promise.resolve({recordId, value});
												} else {
													return FieldComponents.expression.processExpression(
														dataRecordId,
														dataTableSchemaName,
														expressionObj, namedContexts, parentRenderId
													)
														.then(value => {
															return {
																recordId,
																value
															};
														});
												}
											});
											let rowExpressionPromise = Promise.all(rowExpressionPromises)
												.then(results => {
													return { key: schemaName, value: results };
												});
											settingExpressionPromises.push(rowExpressionPromise);
										} else if (expressionObj && typeof expressionObj === 'string') {
											// It's an old static expression
											let rowExpressionPromises = result.rows.map(context => {
												let recordId = context && context.recordId ? context.recordId : '';
												let tableSchemaName = context && context.tableSchemaName ? context.tableSchemaName : '';
												return new Promise((resolve, reject) => {
													resolve({
														recordId,
														tableSchemaName,
														value: expressionObj
													});
												});
											});
											let rowExpressionPromise = Promise.all(rowExpressionPromises)
												.then(results => {
													return { key: schemaName, value: results };
												});
											settingExpressionPromises.push(rowExpressionPromise);
										}
									});
									Promise.all(settingExpressionPromises)
										.then(results => {
											let toResolve = {};
											results.forEach(({ key, value }) => {
												toResolve[key] = value;
											});
											return resolve(toResolve);
										})
										.catch(reject);
								});
								expressionPromises.push(expressionPromise);
							});
						}
						return Promise.all(expressionPromises);
					})
					.then((expressionResults) => {

						expressionResults.forEach((expressions, index) => {
							let matchingTab = tabResults[index];
							let matchingAttachedField = attachedFieldDict[matchingTab.attachmentId || matchingTab.recordId];
							let option = Object.assign({}, matchingTab, matchingAttachedField);
							if (matchingAttachedField) {
								option.containerFieldId = matchingAttachedField.fieldId;
							}
							let optionAttachmentId = option.attachmentId || option.fieldId;
							if (option.type === 'static') {
								option.contentRenderId = option.renderId;
								option.tableSchemaName = dataTableSchemaName;
								option.index = index;
								// option.recordId = dataRecordId;
								Object.keys(expressions).forEach(schemaName => {
									option[schemaName + 'Result'] = expressions[schemaName][0].value;
								});
								if(tabValueObj && tabValueObj.fieldId === optionAttachmentId) {
									valueIsInResults = true;
								}
							} else {
								let tableSchemaName = option.query
									? FieldComponents.query.getReturnTable(option.query)
									: option.tableSchemaName;
								// @TODO:
								let optionsObj = {};
								Object.keys(expressions).forEach(schemaName => {
									let expressionsForSchema = expressions[schemaName];
									expressionsForSchema.forEach(({ recordId, value }, i) => {
										if (!optionsObj[recordId]) {
											optionsObj[recordId] = {
												index: i,
												tableSchemaName,
												recordId,
											};
										}

										if(
											tabValueObj
											&& tabValueObj.fieldId === optionAttachmentId
											&& tabValueObj.recordId === recordId
											&& tabValueObj.tableSchemaName === tableSchemaName
										) {
											valueIsInResults = true;
										}

										if (!optionsObj[recordId].contentRenderId) {
											// @TODO: Could this result in any false positives?
											let matchingGrid = newGrids.find(grid => grid.dataRecordId === recordId);
											if (matchingGrid) {
												optionsObj[recordId].contentRenderId = matchingGrid.parentRenderId;
											}
										}

										optionsObj[recordId][schemaName + 'Result'] = value;
									});
								});
								option.options = [];
								Object.keys(optionsObj).forEach(id => {
									let val = optionsObj[id];
									option.options[val.index] = val;
								});
							}
							options.push(option);
						});

						if((!tabValue || !valueIsInResults) && options && options.length) {
							let option = options[0];
							let type = option.type;
							let attachmentId = option.attachmentId || option.fieldId;
							let newTabValueObj = {};
							if(type === 'dynamic' && option.options) {
								let dynOption = option.options[0];
								if(dynOption) {
									let recordId = dynOption.recordId;
									let tableSchemaName = dynOption.tableSchemaName;
									newTabValueObj = {
										fieldId: attachmentId,
										recordId: recordId,
										tableSchemaName: tableSchemaName
									};
									tabValue = JSON.stringify(newTabValueObj);
								}
							} else {
								newTabValueObj = {
									fieldId: attachmentId,
									recordId: dataRecordId || '',
									tableSchemaName: dataTableSchemaName || ''
								};
								tabValue = JSON.stringify(newTabValueObj);
							}

							let setName = fieldId + '-selected';
							recordSets[setName] = {
								tableSchemaName: newTabValueObj.tableSchemaName,
								setName: setName,
								uiName: 'Selected Tab',
								recordSetArray: [newTabValueObj.recordId]
							};
							recordFields[fieldObj.fieldSchemaName] = tabValue;

							tabValueObj = tabValue ? ObjectUtils.getObjFromJSON(tabValue) : undefined;
						} else if (options && tabValueObj) {
							// We still need to set the selected record set
							let setName = fieldId + '-selected';
							recordSets[setName] = {
								tableSchemaName: tabValueObj.tableSchemaName,
								setName: setName,
								uiName: 'Selected Tab',
								recordSetArray: [tabValueObj.recordId]
							};
						}

						// Now that that's done, push in our parent grid,
						// and include the options setting
						// for the local context store
						// 		newGrids.unshift({
						grids.push({
							attachmentId,
							parentRenderId: parentRenderId,
							grandparent: grandparent,
							dataRecordId,
							dataTableSchemaName,
							componentId: fieldId,
							componentType: 'field',
							// 			attachedFields: attachedFieldResults,
							attachedFields: attachedFieldsCorrected, // One attachedField for each render child
							// Ignore hasExpanding and spacerHeights for now
							// Unsure about gridInfo
							availableModes: availableModes, // @TODO: Is this right?
							localContextInfo: { options },
							recordSets: recordSets,
							recordFields: recordFields
						});

						// @TODO: This needs to recursively calculate its grid children now, too, the same as in repeating grids

						// Push them in the long way because we need reference equality.
						// 		newGrids.forEach(grid => {
						// 		 grids.push(grid);
						// 		});

						let recursionPromises = [];
						newGrids.forEach((grid, index) => {
							// Skip the first one
							// if(index === 0) {
							// return;
							// }

							/*
							let {
				   componentId,
				   attachmentId,
				   attachmentKey,
				   componentType,
				   renderId,
				   renderParentId,
				   gridLayoutHeights,
				   fieldPosition,
				   fieldPositionExtras,
				   availableModes,
				   gridInfo
			   } = parentGridInfo;
							*/

							// Determine which option this grid matches in order to determine whether it's the selected grid


							let gridFieldObj = FieldStore.get(grid.componentId);
							let gridAttachedFields = gridFieldObj && gridFieldObj.attachedFields
								? JSON.parse(gridFieldObj.attachedFields)
								: [];

							GridHeightUtils.getPossibleAttachedFields(grid.componentId, grid.componentType, gridAttachedFields, grid.query, responsiveMode);

							if(
								tabValueObj
								&& tabValueObj.fieldId === grid.attachmentId && (tabValueObj.recordId === grid.dataRecordId || (!tabValueObj.recordId && !grid.dataRecordId))
								&& (tabValueObj.tableSchemaName === grid.dataTableSchemaName || (!tabValueObj.tableSchemaName && !grid.dataTableSchemaName))
							) {
								let contextToRunWith = grid.query && grid.query.results ? grid.query.results : [{recordId: grid.dataRecordId, tableSchemaName: grid.dataTableSchemaName}];
								// Nonexistent results should have one "row" about a blank record to display appropriately.
								if(!contextToRunWith || !contextToRunWith.length) {
									contextToRunWith = [{recordId: '', tableSchemaName: ''}];
								}
								let extraRecordContext = {};
								if(recordSets) {
									Object.keys(recordSets).forEach(setName => {
										let value = recordSets[setName] || {};
										let {rows, recordSetArray, tableSchemaName} = value;
										if(!rows && recordSetArray) {
											rows = recordSetArray.map(recordId => {
												return {recordId, tableSchemaName};
											})
										}
										extraRecordContext['recordSets_' + setName] = rows;
									});
								}
								return recursionPromises.push(GridHeightUtils.processGridsRecursively(
									grid.query, {
									componentId: grid.componentId,
									parentComponentId: fieldId,
									componentType: 'field',
									renderId: grid.parentRenderId,
									renderParentId: grid.grandparent,
									attachmentId: grid.attachmentId,
									attachmentKey: grid.attachmentKey,
									availableModes: grid.availableModes,
									attachedFields: gridAttachedFields,
									gridLayoutHeights: grid.gridLayoutHeights,
									fieldPosition: grid.fieldPosition,
									fieldPositionExtras: grid.fieldPositionExtras,
									gridInfo: grid.gridInfo
								},
									grids, contextToRunWith,
									queryResult && queryResult.pageRecordContext ? queryResult.pageRecordContext : undefined,
									activeOverlays, mode, responsiveMode,
									extraRecordContext
								));
							} else {
								// @TODO: In future optimizations, find and preserve existing grid and its children and avoid unnecessary recalculation
								let toPush = GridHeightUtils.processSimpleAttachedFields({
									componentId: grid.componentId,
									parentComponentId: fieldId,
									componentType: 'field',
									renderId: grid.parentRenderId,
									renderParentId: grid.grandparent,
									attachmentId: grid.attachmentId,
									attachmentKey: grid.attachmentKey,
									availableModes: grid.availableModes,
									attachedFields: gridAttachedFields,
									gridLayoutHeights: grid.gridLayoutHeights,
									fieldPosition: grid.fieldPosition,
									fieldPositionExtras: grid.fieldPositionExtras,
									gridInfo: grid.gridInfo
								}, [], [], grid.dataRecordId, grid.dataTableSchemaName);
								toPush.query = grid.query;
								// This grid is not selected and we need to do something about that.
								grids.push(toPush);
							}
							
							
						});
						return Promise.all(recursionPromises);
					})
					.catch(console.error);
			});
	},

	/**
	 * Finds and calculates the new child grid when a content tab/dropdown is changed
	 * @param {string} parentRenderId The content tab/dropdown's render ID
	 * @param {Immutable} options The options for the content tab/dropdown
	 * @param {string} newValue The new value for the content tab/dropdown
	 * @param {Array} grids The grids array into which to push new entries
	 * @returns 
	 */
	changeContentTabsAndDropdownsSelection(parentRenderId, options, newValue, grids) {
		let parentRenderObj = RenderStore.get(parentRenderId);
		let parentComponentId = parentRenderObj ? parentRenderObj.componentId : '';
		let pageObj = RenderStore.getPageRenderObj(parentRenderId);
		grids = grids || [];

		let tabValueObj = ObjectUtils.getObjFromJSON(newValue);
		if(options && tabValueObj) {
			let matchingRenderId = undefined;
			let matchingOption;
			let attachmentId, attachmentKey, dataRecordId, dataTableSchemaName, componentId;
			options.forEach(option => {
				let optionAttachmentId = option.get('attachmentId') || option.get('fieldId');
				if(option.get('type') === 'static') {
					if(
						(optionAttachmentId === tabValueObj.fieldId) // We don't need the record comparison because there's only one option with this fieldId
					) {
						attachmentId = optionAttachmentId;
						matchingRenderId = option.get('contentRenderId');
						componentId = option.get('fieldId');
						let matchingRenderEntry = RenderStore.get(matchingRenderId);
						// The recordId stored on static content tabs is actually the same as the component/attachment ID
						// I referenced this against platform-prod to confirm.
						// So we need to use the value from the container instead
						// TSN should always be consistent, but just in case
						dataRecordId = matchingRenderEntry ? matchingRenderEntry.dataRecordId : dataRecordId;
						dataTableSchemaName = matchingRenderEntry.dataTableSchemaName ? matchingRenderEntry.dataTableSchemaName : option.get('tableSchemaName');
						attachmentKey = attachmentId + '-' + dataRecordId;
						matchingOption = option;
					}
				} else if (option.get('options')) {
					option.get('options').forEach(dynOption => {
						if(
							optionAttachmentId === tabValueObj.fieldId
							&& dynOption.get('recordId') === tabValueObj.recordId
							&& dynOption.get('tableSchemaName') === tabValueObj.tableSchemaName
						) {
							attachmentId = optionAttachmentId;
							componentId = option.get('fieldId');
							matchingRenderId = dynOption.get('contentRenderId');
							dataRecordId = dynOption.get('recordId');
							dataTableSchemaName = dynOption.get('tableSchemaName');
							attachmentKey = attachmentId + '-' + dataRecordId;
							matchingOption = dynOption;
						}
					});
				}
			});

			let query = {
				fields: [],
				expressions: {},
				results: [{recordId: dataRecordId, tableSchemaName: dataTableSchemaName}]
			}

			let gridFieldObj = FieldStore.get(componentId);

			if(gridFieldObj && gridFieldObj.query && gridFieldObj.query.startsWith('{')) {
				// Does this itself have a query? That needs to control our current query information, then.
				if(gridFieldObj.query.includes('should be first nodeId')) {
					console.warn('Malformed query on field %s. Value was', componentId, gridFieldObj.query);
				}
				delete query.results;
				query.queryJSON = gridFieldObj.query;
			}


			return new Promise((resolve, reject) => {
				if(matchingRenderId && matchingOption) {
					let gridAttachedFields = gridFieldObj.attachedFields
						? JSON.parse(gridFieldObj.attachedFields)
						: [];

					if(gridFieldObj && gridFieldObj.query) {
						delete query.results;
						GridHeightUtils.getPossibleAttachedFields(parentComponentId, 'field', gridAttachedFields, query);
					}

					GridHeightUtils.getPossibleAttachedFields(componentId, 'field', gridAttachedFields, query);
					let grid = {
						query,
						attachmentId,
						attachmentKey,
						parentRenderId: matchingRenderId,
						dataRecordId,
						dataTableSchemaName,
						grandparent: parentRenderId,
						componentId,
						componentType: 'field',
						attachedFields: gridFieldObj.attachedFields ? JSON.parse(gridFieldObj.attachedFields) : [],
						fieldPosition: gridFieldObj.fieldPosition ? JSON.parse(gridFieldObj.fieldPosition) : {},
						fieldPositionExtras: gridFieldObj.fieldPositionExtras ? JSON.parse(gridFieldObj.fieldPositionExtras) : {},
						availableModes: ['view', 'edit']
					};

					let contextToRunWith = grid.query && grid.query.results ? grid.query.results : [{recordId: grid.dataRecordId, tableSchemaName: grid.dataTableSchemaName}];
					// Nonexistent results should have one "row" about a blank record to display appropriately.
					if(!contextToRunWith || !contextToRunWith.length) {
						contextToRunWith = [{recordId: '', tableSchemaName: ''}];
					}

					let extraRecordContext = tabValueObj ? {
						['recordSets_' + parentComponentId + '-selected']: [{recordId: tabValueObj.recordId, tableSchemaName: tabValueObj.tableSchemaName}]
					} : {};

					GridHeightUtils.processGridsRecursively(
						grid.query, {
						componentId: grid.componentId,
						parentComponentId: parentRenderObj.componentId,
						componentType: 'field',
						renderId: grid.parentRenderId,
						renderParentId: grid.grandparent,
						attachmentId: grid.attachmentId,
						attachmentKey: grid.attachmentKey,
						availableModes: grid.availableModes,
						attachedFields: gridAttachedFields,
						gridLayoutHeights: grid.gridLayoutHeights,
						fieldPosition: grid.fieldPosition,
						fieldPositionExtras: grid.fieldPositionExtras,
						gridInfo: grid.gridInfo
					},
						grids, contextToRunWith,
						[{recordId: pageObj.dataRecordId, tableSchemaName: pageObj.dataTableSchemaName}],
						undefined, undefined, undefined,
						extraRecordContext
					)
						.then(resolve)
						.catch(reject);
				} else {
					return resolve();
				}
	
				// Find the grid to recalculate
			});
			
		}
		return Promise.resolve(grids);

	},

	reconcileGridShifts(gridLayoutHeight, columnYTracker, fieldPositions) {
		// What we basically need is to, for each element in each row, figure out the maximum shift
		// from elements above it and then shift it down
		// This looks sort of complicated in practice, but I'll try to break it down:

		// Find the shifts from each column based on the change in height and, if present, y position
		// then store them so they can be looked up in a way which parallels the construction of
		// gridLayoutHeight
		let rowHeight = 12;
		let colPositionLookup = {};
		columnYTracker.forEach((col, columnIndex) => {
			col.forEach((gridInfo, rowIndex) => {
				colPositionLookup[gridInfo.i] = colPositionLookup[gridInfo.i] || Array(12).fill(0);
				colPositionLookup[gridInfo.i][columnIndex] = rowIndex;
			});
		});

		// Ensure field positions are sorted
		fieldPositions = fieldPositions.sort((a, b) => {
			return a.y - b.y;
		});

		// Now that we have our basic shifts, go through and make each one factor in the shifts above
		/*
		Pseudocode:
		* Find all top-level items
		* For each top-level item, find the item below it. Shift that item's y.
		* Repeat for the second-highest level, keeping the shifts cumulative
		*/

		let hasExpanding = false;

		// Now go through each grid item and figure out the shift above it, then shift it by that
		fieldPositions.forEach((gridInfo) => {
			let left = gridInfo.x;
			let right = gridInfo.x + gridInfo.w;
			// If this array is empty then Math.max of its spread is negative Infinity
			let shiftsForGrid = [0];
			for (let colIndex = left; colIndex < right; colIndex++) {
				let positionInColumn = colPositionLookup[gridInfo.i][colIndex];
				let prevItem = positionInColumn ? columnYTracker[colIndex][positionInColumn - 1] : undefined;
				if(prevItem) {
					let expandedBy = Math.max(0, prevItem.h - prevItem.originalH + prevItem.y - prevItem.originalY);
					shiftsForGrid.push(expandedBy);
				}
			}
			if(gridInfo.expands) {
				hasExpanding = true;
			}
			let totalShift = Math.max(...shiftsForGrid);
			gridInfo.y += totalShift;
		});

		// Now we need to find out the space left in each column in order to
		// calculate the height of any expanded grid items
		let columnSpacers = columnYTracker.map((col, colIndex) => {
			// This needs to subtract the y position of the bottom of the lowest component in this row
			// not the shift
			let lastY = ((col[col.length - 1].y + col[col.length - 1].h) * rowHeight);

			let spacerHeight = Math.max(0, gridLayoutHeight - lastY) || 0;
			return spacerHeight;
		});

		let alreadyExpandedLookup = new Array(12).fill(false);
		fieldPositions.forEach(gridInfo => {
			let left = gridInfo.x;
			let right = gridInfo.x + gridInfo.w;
			let somethingAlreadyExpanded = alreadyExpandedLookup.slice(left, right).reduce((prev, cur) => prev || cur, false);
			let expandBy = 0;
			if(!somethingAlreadyExpanded && gridInfo.expands) {
				let newVals = GridHeightUtils.findAllColumnsPushedDown({}, gridInfo.i, columnYTracker, left, right);
				let relevantSpacers = columnSpacers.slice(newVals.left, newVals.right).filter(spacer => spacer || spacer === 0);
				expandBy = Math.min(...relevantSpacers);
				expandBy = Math.floor(Math.max(0, expandBy) / rowHeight);
				// This is temporary to resolve an issue with infinitely expanding grids
				// which only occurs when they are at the top of the container and full-width
				gridInfo.h += expandBy - (expandBy > 0 ? 1 : 0);
				hasExpanding = true;
			}
			for (let colIndex = left; colIndex < right; colIndex++) {
				if(gridInfo.expands) {
					alreadyExpandedLookup[colIndex] = true;
				}
			}
		});

		// Now re-shift the column heights
		fieldPositions.forEach((gridInfo) => {
			// Reset the y because we need to re-shift everything
			// Becaues fieldPositions is sorted, we should never undercut an already expanded field
			gridInfo.y = gridInfo.originalY;
			let left = gridInfo.x;
			let right = gridInfo.x + gridInfo.w;
			// If this array is empty then Math.max of its spread is negative Infinity
			let shiftsForGrid = [0];
			for (let colIndex = left; colIndex < right; colIndex++) {
				let positionInColumn = colPositionLookup[gridInfo.i][colIndex];
				let prevItem = positionInColumn ? columnYTracker[colIndex][positionInColumn - 1] : undefined;
				if(prevItem) {
					let expandedBy = prevItem.h - prevItem.originalH + prevItem.y - prevItem.originalY;
					shiftsForGrid.push(expandedBy);
				}
			}
			if(gridInfo.expands) {
				hasExpanding = true;
			}
			let totalShift = Math.max(...shiftsForGrid);
			gridInfo.y += totalShift;
		});

		return {hasExpanding, columnSpacers};
	},
	/**
	 * Calculate the grid layout height by using the lowest grid item's y position and adding its height then multiply by 12
	 * @param {Array} fieldPositionArr
	 */
	calculateGridLayoutHeight(fieldPositionArr) {
		let lowestHeight = 0;
		fieldPositionArr.forEach(fieldPos => {
			let calcHeight = fieldPos.h + fieldPos.y;
			if(calcHeight > lowestHeight) {
				lowestHeight = calcHeight;
			}
		});
		return lowestHeight * 12;
	},
	/**
	 * Recursive function to find all columns affected by an expanded field
	 * @param {object} fieldsDict Fields reviwed thus far
	 * @param {string} i The i value of the field being reviewed
	 * @param {array} columnYTracker Tracks fields in each column
	 * @param {integer} left Leftmost column index impacted thus far
	 * @param {integer} right Rightmost column index impacted thus far
	 * @returns 
	 */
	findAllColumnsPushedDown(fieldsDict, i, columnYTracker, left, right) {
		fieldsDict = fieldsDict || {};
		fieldsDict[i] = true;
		let impactedCols = columnYTracker.slice(left, right);
		impactedCols.forEach(col => {
			col.forEach(gridInfo => {
				left = Math.min(gridInfo.x, left);
				right = Math.max(gridInfo.x + gridInfo.w, right);
				if(!fieldsDict[i]) {
					let newVals = GridHeightUtils.findAllColumnsPushedDown(fieldsDict, gridInfo.i, columnYTracker, left, right);
					left = Math.min(newVals.left, left);
					right = Math.max(newVals.right, right);
				}
			});
		});

		return {left, right};
	}
};

export default GridHeightUtils;
