import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'flux/utils';
import { AuthenticationStore, ContextStore, FieldTypeStore, MetadataStore } from '../../../stores';
import { AdminSettingsActions, MetadataActions } from '../../../actions';
import { ObjectUtils } from '../../../utils';
import { CodeEditor } from '../../forms';
import CodeReview from './code-review.react';
import Authenticator from './authenticator.react';


/**
 * Reusable VariantModalContainer Component 
 * 
 */
class VariantModalContainer extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onForceHeightChange = this.onForceHeightChange.bind(this);
		this.onVersionChange = this.onVersionChange.bind(this);
		this.codeReview = this.codeReview.bind(this);
		this.pushToStage = this.pushToStage.bind(this);
		this.onKeyDown = this.onKeyDown.bind(this);
		this.onLock = this.onLock.bind(this);
		this.onUnlock = this.onUnlock.bind(this);
	}
	/**
	 * 
	 * @returns {Array of Object}
	 * @memberof VariantModalContainer
	 */
	static getStores() {
		return [MetadataStore];
	}
	/**
	 * Returns the current State of the VariantModalContainer
	 * 
	 * @static
	 * @memberof VariantModalContainer
	 */
	static calculateState(prevState, props) {
		let { fieldTypeRecordId, componentName } = props,
			variantOverrideObj = MetadataStore.get(componentName, 'fieldtypevariant'),
			variantOriginalObj = {},
			fieldTypeObj = MetadataStore.get(fieldTypeRecordId, 'fieldtypes'),
			isDirty = false,
			visibleIndex = (prevState && prevState.visibleIndex ? prevState.visibleIndex : undefined);
		
		if(fieldTypeObj.configurationJson) {
			let configJsonObj = ObjectUtils.getObjFromJSON(fieldTypeObj.configurationJson);
			variantOriginalObj = configJsonObj.variants.filter(variant => {
				return variant.reactComponentName === componentName;
			})
			if(Array.isArray(variantOriginalObj) && variantOriginalObj.length) {
				variantOriginalObj = variantOriginalObj[0];
			}
		}

		// If there is no variantOverrideObject found, then we've never overridden this one so lets make one!
		if(!variantOverrideObj) {
			variantOverrideObj = {
				recordId: componentName,
				reactCodeVersions: [{
					reactCode: variantOriginalObj.reactCode,
					author: AuthenticationStore.getUsername(),
					forceHeight: variantOriginalObj.forceHeight,
					created: Date().toString()
				}],
				lockedUsername: ''
			};
			visibleIndex = 0;
		} else {
			isDirty = true;

			// If we have one, then make sure the reactCodeVersions is an array.
			if(variantOverrideObj.reactCodeVersions && !Array.isArray(variantOverrideObj.reactCodeVersions)) {
				variantOverrideObj.reactCodeVersions = ObjectUtils.getObjFromJSON(variantOverrideObj.reactCodeVersions);
			}

			let ImLockedOut = false;
			if(variantOverrideObj.lockedUsername && variantOverrideObj.lockedUsername.length > 0 && 
				variantOverrideObj.lockedUsername !== AuthenticationStore.getUsername()) {
					ImLockedOut = true;
				}

			// If we got an array, then lets push a new index onto it.
			if(Array.isArray(variantOverrideObj.reactCodeVersions)) {
				// If we didn't get a visible index, set it to the last index.
				if(!visibleIndex) {
					visibleIndex = variantOverrideObj.reactCodeVersions.length - 1;
					
					// If we're NOT locked out, then increment the visibleIndex and push a new code version onto the array
					if(!ImLockedOut) {
						visibleIndex++;
						
						// If its not in the array, then push it onto the array as a new index (pull code from the index below..)
						variantOverrideObj.reactCodeVersions.push({
							reactCode: variantOverrideObj.reactCodeVersions[visibleIndex-1].reactCode,
							forceHeight: variantOverrideObj.reactCodeVersions[visibleIndex-1].forceHeight,
							author: AuthenticationStore.getUsername(),
							created: Date().toString()
						});
					}
				}
			} else {
				// This will only happen with old data.. where there is an override, but it's not an array in reactCodeVersions..
				// Set move the data from reactCode to reactCodeVersions.
				variantOverrideObj.reactCodeVersions = [{
					reactCode: variantOverrideObj.reactCode,
					forceHeight: variantOverrideObj.forceHeight,
					author: AuthenticationStore.getUsername(),
					created: Date().toString()
				}];
				visibleIndex = 0;
			}

		}

		return {
			'variantOverrideObj': variantOverrideObj,
			'variantOriginalObj': variantOriginalObj,
			'isDirty': isDirty,
			'visibleIndex': visibleIndex
		}
	}
	/**
	 * Occurs when values are modified
	 * 
	 * @param {any} event 
	 * @memberof VariantModalContainer
	 */
	onChange(event) {
		let { variantOverrideObj, visibleIndex } =  this.state;

		// Clear the object reference.
		variantOverrideObj = JSON.parse(JSON.stringify(variantOverrideObj));

		// Old data stripped.
		delete variantOverrideObj.reactCode;
		delete variantOverrideObj.forceHeight;

		let previousVersion = variantOverrideObj.reactCodeVersions[visibleIndex] || {};

		// New format saved
		variantOverrideObj.reactCodeVersions[visibleIndex] = {
			reactCode: event.target.value,
			author: AuthenticationStore.getUsername(),
			forceHeight: previousVersion.forceHeight,
			created: Date().toString()
		};


		// Stringify the reactCodeVersions key before storing it.
		variantOverrideObj.reactCodeVersions = JSON.stringify(variantOverrideObj.reactCodeVersions);

		// get the entire object for the recordId (fieldtype) 
		MetadataActions.pushToStore(this.props.componentName, 'fieldtypevariant', variantOverrideObj);
	}

	/**
	 * Occurs when the forceHeight dropdown is changed
	 * @param {any} event 
	 * @memberof VariantModalContainer
	 */
	onForceHeightChange(event) {
		let { variantOverrideObj, visibleIndex } =  this.state;
		// Old data stripped.

		// Clear the object reference.
		variantOverrideObj = JSON.parse(JSON.stringify(variantOverrideObj));

		let previousVersion = variantOverrideObj.reactCodeVersions[visibleIndex] || {};

		// New format saved
		variantOverrideObj.reactCodeVersions[visibleIndex] = {
			reactCode: previousVersion.reactCode,
			author: AuthenticationStore.getUsername(),
			forceHeight: event.target.value,
			created: Date().toString()
		};

		delete variantOverrideObj.forceHeight;
		delete variantOverrideObj.reactCode;

		// Clear the object reference.
		let overrideStorageObj = JSON.parse(JSON.stringify(variantOverrideObj));
		// Stringify the reactCodeVersions key before storing it.
		overrideStorageObj.reactCodeVersions = JSON.stringify(overrideStorageObj.reactCodeVersions);

		// get the entire object for the recordId (fieldtype) 
		MetadataActions.pushToStore(this.props.componentName, 'fieldtypevariant', overrideStorageObj);
	}

	/**
	 * Change the version to display.
	 * @param {object} event 
	 */
	onVersionChange(event) {
		let newVisibleIndex = parseInt(event.target.value, 10);
		this.setState({visibleIndex: newVisibleIndex});
	}
	
	/**
	 * Lock this variant override to the currently authenticated userName.
	 */
	onLock() {
		let { variantOverrideObj } =  this.state;

		// Lock it up!
		variantOverrideObj.lockedUsername = AuthenticationStore.getUsername();

		// Clear the object reference.
		let overrideStorageObj = JSON.parse(JSON.stringify(variantOverrideObj));

		// Stringify the reactCodeVersions key before storing it.
		overrideStorageObj.reactCodeVersions = JSON.stringify(overrideStorageObj.reactCodeVersions);

		// get the entire object for the recordId (fieldtype) 
		MetadataActions.pushToStore(this.props.componentName, 'fieldtypevariant', overrideStorageObj);
	}

	/**
	 * Lock this variant override.
	 */
	onUnlock() {
		let { variantOverrideObj } =  this.state;

		// Clear who it was locked by
		variantOverrideObj.lockedUsername = "";

		// Clear the object reference.
		let overrideStorageObj = JSON.parse(JSON.stringify(variantOverrideObj));

		// Stringify the reactCodeVersions key before storing it.
		overrideStorageObj.reactCodeVersions = JSON.stringify(overrideStorageObj.reactCodeVersions);

		// get the entire object for the recordId (fieldtype) 
		MetadataActions.pushToStore(this.props.componentName, 'fieldtypevariant', overrideStorageObj);
	}

	/**
	 * Listen to key down events for a control/open apple S.. and save.
	 */
	onKeyDown(event) {
		// Check if keys pressed are ctrl+s
		if(event.ctrlKey && event.key === 's') {
			event.preventDefault();
			this.props.onSave();
		}
		// when on MAC
		if(event.metaKey && event.key === 's') {
			event.preventDefault();
			this.props.onSave();
		}
	}
	/**
	 * Opens code review modal
	 * 
	 * @memberof VariantModalContainer
	 */
	codeReview() {
		let { variantOverrideObj, variantOriginalObj } = this.state;
		AdminSettingsActions.openModal('codeReview', 
			<CodeReview 
				originalCode={variantOriginalObj.reactCode}
				newCode={variantOverrideObj.reactCodeVersions[variantOverrideObj.reactCodeVersions.length - 1].reactCode}
				onChange={this.onChange}
				onSave={this.props.onSave}
			/>
		);
	}
	
	/**
	 * Open push to stage authenticator
	 */
	pushToStage() {
		let { fieldTypeRecordId, componentName } = this.props,
			{ variantOverrideObj } = this.state;
		
		AdminSettingsActions.openModal('pushToStage', 
			<Authenticator 
				fieldTypeId={fieldTypeRecordId} 
				reactComponentName={componentName}
				reactCode={variantOverrideObj.reactCodeVersions[variantOverrideObj.reactCodeVersions.length - 1].reactCode}
				forceHeight={variantOverrideObj.reactCodeVersions[variantOverrideObj.reactCodeVersions.length - 1].forceHeight}
			/>
		);
	}
	/**
	 * 
	 * @memberOf VariantModalContainer
	 */
	render() {
		let { fieldTypeRecordId, onClose, onSave, onReset }= this.props,
			{ variantOverrideObj, variantOriginalObj, isDirty, visibleIndex } = this.state,
			fieldTypeObj = FieldTypeStore.get(fieldTypeRecordId) || {},
			// forceHeight needs to look at the existing variant's forceHeight value if none exists
			forceHeight = (variantOverrideObj && variantOverrideObj.reactCodeVersions && variantOverrideObj.reactCodeVersions[visibleIndex] && (variantOverrideObj.reactCodeVersions[visibleIndex].forceHeight || variantOverrideObj.reactCodeVersions[visibleIndex].forceHeight === '') ? 
				variantOverrideObj.reactCodeVersions[visibleIndex].forceHeight : 
				variantOriginalObj.forceHeight),
			variantCode = (variantOverrideObj && variantOverrideObj.reactCodeVersions && variantOverrideObj.reactCodeVersions[visibleIndex] && variantOverrideObj.reactCodeVersions[visibleIndex].reactCode ? 
				variantOverrideObj.reactCodeVersions[visibleIndex].reactCode : 
				''),
			lockedOut = false,
			isLastVisibleIndex = (visibleIndex === (variantOverrideObj.reactCodeVersions.length - 1)),
			codeEditorIsEditable = false,
			codeEditorComponent = null,
			versionOptions = [];

		// Gather the Version options for the dropdown
		variantOverrideObj.reactCodeVersions.forEach((reactCodeVersion, index) => {
			// If its the last one..
			if(index === (variantOverrideObj.reactCodeVersions.length - 1)) {
				versionOptions.push(<option key={index} value={index}>Newest - {reactCodeVersion.author}</option>);
			} else {
				versionOptions.push(<option key={index} value={index}>{reactCodeVersion.created} - {reactCodeVersion.author}</option>);
			}
		});

		// Setup the Locked UI and Username.
		let lockedUsername = 'No one',
			lockUI = (<button className="btn btn-warning" onClick={this.onLock}>Lock</button>);

		if(variantOverrideObj.lockedUsername && variantOverrideObj.lockedUsername.length > 0) {
			if(variantOverrideObj.lockedUsername === AuthenticationStore.getUsername()) {
				lockedUsername = 'You (' + variantOverrideObj.lockedUsername + ')';
				lockUI = (<button className="btn btn-warning" onClick={this.onUnlock}>Unlock</button>);
			} else {
				lockedUsername = variantOverrideObj.lockedUsername;
				lockUI = (<button className="btn btn-warning" disabled="disabled">Unlock</button>);
				lockedOut = true;
			}
		}

		if(isLastVisibleIndex && !lockedOut) {
			codeEditorIsEditable = true;
		}

		if(codeEditorIsEditable) {
			codeEditorComponent = 
				<CodeEditor
					fieldSchemaName={variantOverrideObj.recordId}
					value={variantCode || ''}
					onChange={this.onChange}
					theme="monokai"
					readOnly={false}
					/>;
		} else {
			codeEditorComponent = 
				<CodeEditor
					fieldSchemaName={variantOverrideObj.recordId}
					value={variantCode || ''}
					theme="monokai"
					readOnly={true}
					/>
		}

		return (
			<div className="field-component-map-modal modal-dialog modal-lg show">
				<div className="modal-content container">
					<div className="modal-header justify-content-end align-items-center">
						<h1 className="modal-title align-self-center mr-auto">
							<span>{variantOriginalObj.name}</span>
						</h1>
						{ lockUI }
						<div className='btn-wrapper d-flex'>
							<button
								disabled={lockedOut || !isDirty} 
								type="button" 
								className="btn btn-warning" 
								aria-label="Push-to-Stage" 
								onClick={this.codeReview}
								>
								Code Review
							</button> 
							<button 
								disabled={lockedOut || !isDirty || ContextStore.getMDGWMode() !== 'stage'}
								type="button" 
								className="btn btn-danger" 
								aria-label="Push-to-Stage" 
								onClick={this.pushToStage}
								>
								Push to Stage
							</button> 
							<button 
								disabled={lockedOut || !isDirty}
								type="button" 
								className="btn btn-secondary" 
								aria-label="Reset"
								onClick={onReset.bind(this, this.props.fieldTypeRecordId, this.props.componentName)}
								>
								Reset
							</button> 
							<button 
								disabled={!codeEditorIsEditable}
								type="submit" 
								className="btn btn-primary mr-4" 
								aria-label="Add/Save"  
								onClick={onSave}
								>
								Save
							</button>
							<button 
								type="button" 
								className="close float-right m-0 align-self-center p-0" 
								aria-label="Close"
								onClick={onClose} 
								>
									&times;
						</button>
						</div>
					</div>
					<div style={{ overflowY: 'auto' }} className="modal-body pb-0">
						<form id="field-component-map-modal-form" onSubmit={(e) => { e.preventDefault(); return false; }}>
							<div className="input-form-container container-fluid">
								<div className="row">
									<div className="col-6">
										<div className="row">	
											<h4 className="form-control-label labels col-4">Field Type: </h4>
											<div className="form-control-input col-8">
												{fieldTypeObj.name || ''}
											</div>
										</div>
									</div>
									<div className="col-6">
										<div className="row">
											<h4 className="form-control-label labels col-4">Locked By</h4>
											<div className="form-control-input col-8">
												{lockedUsername}
											</div>
										</div>
									</div>
								</div>
								<div className="row mt-2">
									<div className="col-6">
										<div className="row align-items-center">
											<h4 className="form-control-label labels col-4">Component Name: </h4>
											<div className="form-control-input col-8">
												<input type="text" 
													className="form-control" 
													id="recordId" 
													value={variantOriginalObj.reactComponentName || ''} 
													disabled={true}/>
											</div>
										</div>
									</div>
									<div className="col-6">
										<div className="row">
											<h4 className="form-control-label labels col-4"></h4>
											<div className="form-control-input col-8">
												<select name="versionIndex" 
													onChange={this.onVersionChange}
													className="form-control" value={visibleIndex}>
													{versionOptions}
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className="row my-2">
									<div className="col-6">
										<div className="row align-items-center">
											<h4 className="form-control-label labels col-4">Force Full Height: </h4>
											<div className="form-control-input col-8">
												<select className='form-control' onChange={this.onForceHeightChange} value={forceHeight}>
													<option value=''>None</option>
													<option value='100%'>100%</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								<div className="row code-editor">
									<div className="form-control-input col-12" onKeyDown={this.onKeyDown}>
										{codeEditorComponent}
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

if ('development' === process.env.NODE_ENV) {
	VariantModalContainer.propTypes = {
		fieldTypeRecordId: PropTypes.string,
		componentName: PropTypes.string,
		onChange: PropTypes.func,
		onClose: PropTypes.func,
		onSave: PropTypes.func,
		onReset: PropTypes.func,
	};
}

//Use the Flux Container to wire up the watch for the stores
const container = Container.create(VariantModalContainer, {withProps: true});
export default container;
