const FieldComponentCompiler = {
	/**
	 * Build the component untransformet
	 * 
	 * @params {String} reactCode from variant
	 * @returns Component
	 */
	compileComponent(code) {
		//Babel Standalone is loaded via cdn and not directly packed
		/*global Babel*/
		if(!Babel) {
			throw new Error( 'Missing module babel-standalone!' );
		}

		try {
			let jsCode = Babel.transform(code,{ presets: ['react', 'es2015'] }).code;
			// eslint-disable-next-line
			eval(jsCode);
			return exports.default;
		} catch( error ) {
			return error.toString();
		}
	}
}

module.exports = FieldComponentCompiler;