import Immutable from 'immutable';
import {MetadataConstants} from '../constants/metadata-constants';
import MetadataBuilder from '@dmclain-citizendeveloper/citdev-module-metadata-builder';
import fetchMetadata from '../utils/fetch-utils';
import AppDispatcher from '../dispatcher/app-dispatcher';
// import UIUtils from '../utils/ui-utils';

let _instance = null;

/**
 * Actions for the core store that contains metadata records for the dev environment where we talk to the MDGW
 *
 * @class MetadataActionsDev
 */
class MetadataActionsDev {
	/**
	 * Singleton instance of MetadataActionsDev
	 * @param {Object} options
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if(options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Set the Installation Id and (optionally the endPoint) and setup the Metadata builder
	 * 
	 * @param {string} installationId 
	 * @param {string} endPoint 
	 */
	setInstallationId(installationId, signedMdKey, endPoint) {
		this._metadataBuilder = new MetadataBuilder(installationId, signedMdKey, endPoint);
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw(new Error('MetadataActionsDev not properly initialized with a dispatcher'));
		}
		if (!this._metadataBuilder) {
			throw(new Error('MetadataActionsDev not properly initialized with an installationId'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: MetadataConstants.METADATA_PULL_ERROR,
			error: error.message
		}));
	}

	receiveBroadcast(kind, records) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: MetadataConstants.METADATA_RECEIVE_BROADCAST,
			records: records,
			kind: kind
		}));
	}

	/**
	 * Deletes a metadata from the database AND the store
	 * 
	 * @param {string} recordId Metadata Record ID to delete from the database and the store
	 * @param {string} kind Kind of Metadata to delete from the database and the store
	 */
	deleteFromDatabase(recordId, kind) {
		// Just wrap the Promisified version without returning it
		this.deleteFromDatabasePromise(recordId, kind);
	}

	/**
	 * Deletes a metadata from the database AND the store and returns a promise.
	 * 
	 * @param {string} recordId Metadata Record ID to delete from the database and the store
	 * @param {string} kind Kind of Metadata to delete from the database and the store
	 * @return {Promise}
	 */
	deleteFromDatabasePromise(recordId, kind) {
		return new Promise((resolve, reject) => {
			try {
				this._checkInit();
			} catch (error) {
				return reject(error);
			}

			// Local Meta Data Builder
			this._metadataBuilder
				// Get the field(s)
				.deleteRecords([{'recordId': recordId}], kind)
				.then(function(){
					// Pass the fields to the store (call reduce with the object below)
					this._dispatcher.dispatch(Immutable.fromJS({
						type: MetadataConstants.METADATA_DELETE_FROM_STORE,
						recordId: recordId,
						kind: kind
					}));
					return resolve(recordId);
				}.bind(this))
				.catch(function(error){ 
					console.error(error); 
					this._onError(error);
					return reject(error);
				}.bind(this));
		});
	}

	/**
	 * Deletes a metadata from the store
	 * 
	 * @param {string} recordId Metadata Record ID to delete from the store.
	 * @param {string} kind Kind of Metadata to delete from the store
	 */
	deleteFromStore(recordId, kind) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: MetadataConstants.METADATA_DELETE_FROM_STORE,
			recordId: recordId,
			kind: kind
		}));
	}

	/**
	 * Update Metadata Gateway with this metadata's data.
	 * @param {string} recordId Metadata Record ID to push from the store to the database
	 * @param {string} kind Kind of Metadata to push
	 */
	pushToDatabase(metadataObject, kind) {
		// Just wrap the Promisified version without returning it
		this.pushToDatabasePromise(metadataObject, kind);
	}

	/**
	 * Update Metadata Gateway with this metadata's data and return a promise
	 * @param {string} recordId Metadata Record ID to push from the store to the database
	 * @param {string} kind Kind of Metadata to push
	 * @return {Promise}
	 */
	pushToDatabasePromise(metadataObject, kind) {
		return new Promise((resolve, reject) => {
			try {
				this._checkInit();
			} catch (error) {
				return reject(error);
			}

			// Strip out the hideCSS property, if it was set on the application record.
			if(kind === 'applications' && typeof metadataObject === 'object'){
				delete metadataObject.hideCSS;
			}

			// Local Meta Data Builder
			this._metadataBuilder
				// Update the metadata
				.updateRecords([metadataObject], kind)
				.then(function(){
					// Pass the tables to the store (call reduce with the object below)
					this._dispatcher.dispatch(Immutable.fromJS({
						type: MetadataConstants.METADATA_PUSH_TO_DATABASE,
						kind: kind,
						metadataObject
					}));
					return resolve(metadataObject);
				}.bind(this))
				.catch(function(error){ 
					console.error(error); 
					this._onError(error);
					return reject(error);
				}.bind(this));
		});
	}

	/**
	 * Update store metadata properties
	 * @param {string} recordId Metadata Record ID to update
	 * @param {string} kind Kind of Metadata to push
	 * @param {Object} recordProperties Properties to update
	 * @param {boolean} forceClean Should this store entry be clean?
	 */
	pushToStore(recordId, kind, recordProperties, forceClean) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: MetadataConstants.METADATA_PUSH_TO_STORE,
			recordId: recordId,
			kind: kind,
			recordProperties: recordProperties,
			forceClean: forceClean
		}));
	}

	/**
	 * Update store with a metadata's data.
	 * @param {string} recordId Metadata's Record ID to pull from the database to the database
	 * @param {string} kind Kind of Metadata to pull
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, kind, overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		// Local Meta Data Builder
		this._metadataBuilder
			// Get the metadata
			.searchRecords(kind, { 'recordId': recordId})
			.then(function(metadataArray){
				// Pass the metadatas to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: MetadataConstants.METADATA_PULL_FROM_DATABASE,
					metadataArray: metadataArray,
					kind: kind,
					overwriteStore: overwriteStore
				}));
			}.bind(this))
			.catch(function(error){ 
				console.error(error); 
				this._onError(error);
			}.bind(this));
	}

	/**
	 * Update store with all of the metadata's data.
	 * @param {string} kind Kind of Metadata to pull
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabaseAll(kind, overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		// Local Meta Data Builder
		this._metadataBuilder
			// Get all the metadata
			.browseRecords(kind)
			.then(function(metadataArray){
				// Pass the metadatas to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: MetadataConstants.METADATA_PULL_FROM_DATABASE_ALL,
					metadataArray: metadataArray,
					kind: kind,
					overwriteStore: overwriteStore
				}));
			}.bind(this))
			.catch(function(error){ 
				console.error(error); 
				this._onError(error);
			}.bind(this));
	}
}

/**
 * Actions for the core store that contains metadata records for the test/prod environments where we talk to the CDN JSON
 *
 * @class MetadataActionsProd
 */
class MetadataActionsProd {
	/**
	 * Singleton instance of MetadataActionsProd
	 * @param {Object} options
	 */
	constructor(options) {
		// Singleton.. if there already IS one of these, then pass it back out!
		if (_instance) {
			return _instance;
		}

		//callbacks
		this.setInstallationId = this.setInstallationId.bind(this);
		this.setInstallationVersion = this.setInstallationVersion.bind(this);
		this._checkInit = this._checkInit.bind(this);
		this._onError = this._onError.bind(this);

		// instance and dispatcher
		_instance = this;
		this._dispatcher = options.dispatcher;

		// Setup installation local variables, if passed in
		if (options.installationId) {
			this.setInstallationId(options.installationId);
		}
		if(options.installationVersion) {
			this.setInstallationVersion(options.installationVersion);
		}
	}

	/**
	 * Set the Installation Id
	 * 
	 * @param {string} installationId 
	 */
	setInstallationId(installationId) {
		this._installationId = installationId;
	}

	/**
	 * Set the Installation Version
	 * 
	 * @param {string} installationVersion
	 */
	setInstallationVersion(installationVersion) {
		this._installationVersion = installationVersion;
	}

	/**
	 * Private function to check if the instance has the necessary components to function properly
	 */
	_checkInit() {
		if (!this._dispatcher) {
			throw(new Error('MetadataActionsProd not properly initialized with a dispatcher'));
		}
		if (!this._installationId) {
			throw(new Error('MetadataActionsProd not properly initialized with an installationId'));
		}
		if (!this._installationVersion) {
			throw(new Error('MetadataActionsProd not properly initialized with an installationVersion'));
		}
	}

	/**
	 * Private callback wrapper for errors
	 */
	_onError(error) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: MetadataConstants.METADATA_PULL_ERROR,
			error: error.message
		}));
	}

	/**
	 * Deletes a table from the database AND the store
	 */
	deleteFromDatabase() {
		console.warn('deleteFromDatabase not implemented.');
	}

	/**
	 * Deletes a table from the store
	 * 
	 * @param {string} recordId Table Record ID to delete from the store.
	 * @param {string} kind Kind of Metadata to delete
	 */
	deleteFromStore(recordId, kind) {
		this._dispatcher.dispatch(Immutable.fromJS({
			type: MetadataConstants.METADATA_DELETE_FROM_STORE,
			recordId: recordId,
			kind: kind
		}));
	}

	/**
	 * Update Metadata Gateway with this record's data.
	 */
	pushToDatabase() {
		console.warn('pushToDatabase not implemented.');
	}

	/**
	 * Update store record properties
	 * @param {string} recordId Record to update
	 * @param {string} kind Kind of Metadata to push
	 * @param {Object} recordProperties Properties to update
	 */
	pushToStore(recordId, kind, recordProperties) {
		this._checkInit();

		this._dispatcher.dispatch(Immutable.fromJS({
			type: MetadataConstants.METADATA_PUSH_TO_STORE,
			recordId: recordId,
			kind: kind,
			recordProperties: recordProperties
		}));
	}

	/**
	 * Update store with a record's data.
	 * @param {string} recordId Record to pull from the database to the database
	 * @param {string} kind Kind of Metadata to pull
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabase(recordId, kind, overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		let installationId = this._installationId,
			installationVersion = this._installationVersion,
			metadataPath = 'https://cdn3.citizendeveloper.com/installations/' + 
				installationId + '/' + installationVersion + '/' + kind + '/' + recordId + '.json';
		fetchMetadata(metadataPath)
			.then((jsonResponse) => {
				// Pass the tables to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: MetadataConstants.METADATA_PULL_FROM_DATABASE,
					metadataArray: [jsonResponse],
					kind: kind,
					overwriteStore: overwriteStore
				}));
			})
			.catch(function(error){
				console.error('Unable to retrieve metadata from ', metadataPath, '. Error was: ', error);
			});
	}

	/**
	 * Update store with all of the records' data.
	 * @param {string} kind Kind of Metadata to pull
	 * @param {boolean} overwriteStore Do you want changes in the store to remain, or be overwritten by the database?  Defaults to true : overwrite the store!
	 */
	pullFromDatabaseAll(kind, overwriteStore) {
		this._checkInit();

		if(overwriteStore === undefined) {
			overwriteStore = true;
		}

		let installationId = this._installationId,
			installationVersion = this._installationVersion,
			metadataPath = 'https://cdn3.citizendeveloper.com/installations/' + 
				installationId + '/' + installationVersion + '/' + kind + '/index.json';
		fetchMetadata(metadataPath)
			.then((jsonResponse) => {
				// Pass the tables to the store (call reduce with the object below)
				this._dispatcher.dispatch(Immutable.fromJS({
					type: MetadataConstants.METADATA_PULL_FROM_DATABASE_ALL,
					metadataArray: jsonResponse,
					kind: kind,
					overwriteStore: overwriteStore
				}));
			})
			.catch(function(error){
				console.error('Unable to retrieve metadata from ', metadataPath, '. Error was: ', error);
			});
	}
}

let toExport;

if(process.env.CITDEV_ENV === 'development'){
	
	try {
		if(window.location.href.includes('settings.html') && typeof window.opener.location.href === 'string') {
			toExport = window.opener.MetadataActions;
		} else {
			const instance = new MetadataActionsDev({dispatcher: AppDispatcher});
			toExport = instance;
		}
	} catch(error) { 
		const instance = new MetadataActionsDev({dispatcher: AppDispatcher});
		toExport = instance;
	}

} else {
	toExport = new MetadataActionsProd({dispatcher: AppDispatcher});
}

export default toExport;