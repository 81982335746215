import React,{Component} from 'react';
import { Container } from 'flux/utils';
import PropTypes from 'prop-types';
import FieldTypeSettingsUI from '../fieldtype-settings-ui.react';
import ObjectUtils from '../../utils/object-utils';
import AdminSettingsStore from '../../stores/admin-settings-store';

/**
 * Component that renders the proper FieldTypeSetting
 *
 * @class FieldTypeSettingUI
 * @extends {Component}
 */
class AppearanceLocalSettings extends Component {
		
	/**
	 * @static getStores - Loads the Stores to watch
	 *
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore]; 
	}
	
	/**
	 * static - description
	 *
	 * @param  {type} prevState description
	 * @param  {type} props     description
	 * @return {type}           description
	 */
	static calculateState(prevState) {
		let newState = {};
		if (!prevState || prevState.settingSchemaName !== AdminSettingsStore.getSettingSchemaName()) {
			newState.addRow = {'value': '', 'screenSizes': []};
		} else {
			newState.addRow = prevState.addRow;
		}
		return newState;
	}
	
	/**
	 * Adds a local setting.
	 * @param {number} childIndex 
	 * @param {Object} event 
	 */
	addLocalSetting(childIndex, event) {
		event.preventDefault();
		let value = ObjectUtils.copyObj(this.state.addRow);
		value.action = 'add';
		value.childIndex = childIndex;

		this.props.onChange({
			'target': {
				'value': value
			}
		});
	}
	
	/**
	 * Drops the local setting at the index
	 * @param {number} childIndex 
	 * @param {Object} event 
	 */
	deleteLocalSetting(childIndex, event) {
		event.preventDefault();
		let value = {action: 'delete',
			childIndex: childIndex};
		this.props.onChange({
			'target': {
				'value': value
			}
		});
	}
	
	/**
	 * Updates the available screensizes for a child index.
	 * @param {number} childIndex Which index to toggle
	 * @param {string} screensize Which screensize to toggle.
	 */
	updateSettingScreenSize(childIndex, screensize) {
		let newValue = ObjectUtils.copyObj(this.props.value[childIndex]);

		// If we don't yet have screensizes...
		if(!Array.isArray(newValue.screenSizes)) {
			newValue.screenSizes = [];
		}

		// If we don't have the screensize then push it on
		if (!newValue.screenSizes.includes(screensize)) {
			newValue.screenSizes.push(screensize);
		} else {
			// Otherwise spice it off.
			newValue.screenSizes.splice(
				newValue.screenSizes.indexOf(screensize), 1);
		}

		// onChange is an update of this index.
		newValue.action = 'update';
		newValue.childIndex = childIndex;

		this.props.onChange({
			'target': {
				'value': newValue
			}
		});
		
	}
	
	/**
	 * Update the value for the local override at the child index.
	 * @param {number} childIndex Which index to update.
	 * @param {object} event Carrier of the value.
	 */
	updateSettingValue(childIndex, event) {
		let newValue = ObjectUtils.copyObj(this.props.value[childIndex]);
		newValue.value = event.target.value
		newValue.action = 'update';
		newValue.childIndex = childIndex;
		this.props.onChange({
			'target': {
				'value': newValue
			}
		});
	}

	/**
	 * Render the FieldTypeSetting
	 *
	 * @returns React
	 *
	 * @memberOf FieldTypeSettingUI
	 */
	render() {
		let {setting, recordId, tableSchemaName, value, fieldType, label} = this.props;
		let rows = value.map((currentValue, index) => {

			return (
				<div className="setting-local" key={'row-' + index}>
					<div className="setting-controls">
						<div style={{ cursor: 'pointer' }} className="override-add" onClick={this.deleteLocalSetting.bind(this, index)}>
							<button className="close pr-2">
								<i className="fa fa-minus-circle" aria-hidden="false"></i>
							</button>
							<h4>Remove Override</h4>
						</div>
					</div>
					<div className="setting-wrapper">
						<div className="localOverrideScreenSizeSelector">
								<div
									onClick={this.updateSettingScreenSize.bind(this, index, 'sm')} 
									onKeyPress={this.updateSettingScreenSize.bind(this, index, 'sm')}
									className={"screensizeOption rounded-left " + (!!(currentValue.screenSizes.includes('sm')) ? 'selected' : '')}>
									<div className={"screensizeOptionIcon fa fa-mobile"}></div>
								</div>
								<div
									onClick={this.updateSettingScreenSize.bind(this, index, 'md')} 
									onKeyPress={this.updateSettingScreenSize.bind(this, index, 'md')}
									className={"screensizeOption " + (!!(currentValue.screenSizes.includes('md')) ? 'selected' : '')}>
									<div className={"screensizeOptionIcon fa fa-tablet"}></div>
								</div>
								<div
									onClick={this.updateSettingScreenSize.bind(this, index, 'lg')} 
									onKeyPress={this.updateSettingScreenSize.bind(this, index, 'lg')}
									className={"screensizeOption rounded-right " + (!!(currentValue.screenSizes.includes('lg')) ? 'selected' : '')}>
									<div className={"screensizeOptionIcon fa fa-desktop"}></div>
								</div>
								<div className="ml-2 muted theme-font-regular">Select device type(s) to apply override to.</div>
							</div>
						<FieldTypeSettingsUI recordId={recordId} 
							tableSchemaName={tableSchemaName} 
							fieldType={fieldType}
							setting={setting} 
							value={currentValue.value}
							skipDescriptiveText={true}
							onChange={this.updateSettingValue.bind(this, index)}
							label={label} />
					</div>
				</div>
			)
		});

		// No reason to ever have more than 3 overrides.
		if(rows.length < 3) {
			rows.push(
				<div className="setting-local add" key={'add'}>
					<div className="setting-controls">
						<div style={{ cursor: 'pointer' }} className="override-add" onClick={this.addLocalSetting.bind(this, rows.length)}>
							<button className="close pr-2">
								<i className="fa fa-plus-circle" aria-hidden="false"></i>
							</button>
							<h4>Override {this.props.label}</h4>
						</div>
					</div>
				</div>
			);
		}

		return rows;
	}
}

if ('development' === process.env.NODE_ENV) {
	AppearanceLocalSettings.propTypes = {
		setting: PropTypes.object.isRequired,
		fieldType: PropTypes.string,
		onChange: PropTypes.func,
		recordId: PropTypes.string,
		tableSchemaName: PropTypes.string,
		value: PropTypes.any,
		label: PropTypes.string
	};
}

const container = Container.create(AppearanceLocalSettings, {withProps:true});
export default container;
