import React, { Component } from 'react';
import Helmet from 'react-helmet';
import ContextStore from '../stores/context-store';

/**
 * React Component for the Not Found Message
 * 
 * @class NotFound
 * @extends {Component}
 */
class NotFound extends Component {
	/**
	 * render - renders wrapper
	 *
	 * @returns JSX
	 */
	render() {
		let { title, loadingSubject } = this.props;
		if(!title || title.length === 0) {
			title = 'NOT FOUND';
		}
		if(!loadingSubject || loadingSubject.length === 0) {
			loadingSubject = 'No record data found for record.';
		}
		return (
			<div className={this.props.className} data-cd-message={this.props.message}>
				<Helmet title="Not Found" />
				<div id='loading-container'>
					<div id='loading-main'>
						<img className='loading-image' src={ContextStore.getUrlMedia() + "/animation-not-found.gif"} alt="Not found animation"/>
						<h1 className='loading-title'>{title}</h1>
						<h3 className='loading-subject'>{loadingSubject}</h3>
					</div>
				</div>
			</div>
		);
	}
}

export default NotFound;