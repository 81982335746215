export const GroupFilters = {
	CHANGED: 'changed',
	COMPLIANCE: 'compliance',
	FIELD_TYPE: 'fieldtype',
	LAST_UPDATED_DATE: 'last_updated_date',
	NAME: 'name',
	PAGE_ROLE: 'pageRole',
	PRIORITY: 'priority',
	STATUS: 'status',
	TABLE_NAME: 'tableSchemaName',
	TABLE_ROLE: 'tableRole',
	TAG: 'tag',
	TYPE: 'type',
};

export default GroupFilters;