import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { AdminSettingsActions, MetadataActions } from '../../../actions';
import { AdminSettingsStore, ContextStore, FieldStore, MetadataStore } from '../../../stores';
import UIUtils from '../../../utils/ui-utils';
import ThemeBuilderUtils from '../../../utils/theme-builder-utils';

// Components;
import FieldTypeSettingsUI from '../../fieldtype-settings-ui.react';

import 'brace/ext/searchbox';

/**
 * Right panel displaying Them Builder Settings
 */
class ThemeSetting extends Component {
	constructor(props) {
		super(props)
		this._onKeyDown = this._onKeyDown.bind(this);
		this._onSettingChange = this._onSettingChange.bind(this);
		this._onToggleStyleDisabled = this._onToggleStyleDisabled.bind(this);
	}
	
	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
	 return [ AdminSettingsStore, FieldStore, MetadataStore ]; 
	}

	/**
	 * Calculate the current state of the component
	 * @static
	 * @param  {Object} prevState Previous State
	 * @param  {Object} prevProps Previous Props
	 * @return {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId = ContextStore.getApplicationId();
		let tableSchemaName = 'applications';
		let settingRecordId = AdminSettingsStore.getSettingRecordId();
		let settingObj = FieldStore.get(settingRecordId);
		let value = '';

		// Pull the value from the MD Store
		if(settingObj){
			let settingSchemaName = settingObj.fieldSchemaName;

			let themeObj = MetadataStore.get(recordId, tableSchemaName);
			if(themeObj) {
				value = themeObj[settingSchemaName];
			}
		}

		return {
			settingObj: settingObj,
			settingRecordId: settingRecordId,
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			value: value,
			settingsHidden: AdminSettingsStore.getSettingsListHidden(),
		};
	}

	/**
	 * Listen to key down events for a control/open apple S.. and save.
	 * 
	 * @todo Bailed out on this functionality because the save method is one component higher then this component and didn't
	 * have time to resolve.  But, this method runs when someone hits Control-S in the Appearance Settings area.
	 */
	_onKeyDown(event) {
		// Check if keys pressed are ctrl+s
		if(event.ctrlKey && event.key === 's') {
			console.warn('Ctrl-S to save not yet implemented.');
			event.preventDefault();
		}
		// when on MAC
		if(event.metaKey && event.key === 's') {
			console.warn('Ctrl-S to save not yet implemented.');
			event.preventDefault();
		}
	}
	
	/**
	 * Save the value as a value is entered
	 * @param {Object} event
	 */
	_onSettingChange(event) {
		let { recordId, tableSchemaName, settingObj } = this.state;
		let settingSchemaName = settingObj.fieldSchemaName;
		let value = '';
		if(settingSchemaName === 'css') {
			value = event;
		} else {
			value = event.target.value;
		}
		let applicationObj = MetadataStore.get(recordId, tableSchemaName);

		// Update the API Object with this setting, at its new value
		applicationObj[settingSchemaName] = value;

		// Push the update API Object into the store.
		MetadataActions.pushToStore(recordId, tableSchemaName, applicationObj);
	}

	/**
	 * Toggle the hideCSS Setting
	 */
	_onToggleStyleDisabled() {
		let { recordId, tableSchemaName } = this.state;
		let applicationObj = MetadataStore.get(recordId, tableSchemaName);

		if(applicationObj['hideCSS']) {
			applicationObj['hideCSS'] = !applicationObj['hideCSS'];
		} else {
			applicationObj['hideCSS'] = true;
		}

		// Close the panel so you can see your change.
		UIUtils.closeSettingsPanel();

		// Push the update API Object into the store.
		MetadataActions.pushToStore(recordId, tableSchemaName, applicationObj);
	}

	/**
	 * Render the component
	 * @return JSX
	 */
	render() {
		let {recordId, tableSchemaName, value, settingObj, settingsHidden } = this.state;

		if (!settingObj || !settingObj.fieldSchemaName) {
			return (<div className="select-setting">
						<div className="select-setting-text-wrap">
							Select a Setting to configure on the left.
						</div>
					</div>);
		}

		let settingSchemaName = settingObj.fieldSchemaName;
		let settingFieldType = settingObj.fieldType;
		let descriptiveText = settingObj.descriptiveText;
		let label = settingObj.fieldLabel;

		// Update the description to the dark theme.
		// @TODO - Make this a parameter on the normal field types!
		if(settingSchemaName === 'description') {
			settingObj.config = {
				// Dark mode
				skin:"oxide-dark",
				content_css:"dark",
			}
		}
		// For the Date/Time, parse it!
		if(settingSchemaName === 'lastUpdatedDateTime') {
			value = (value ? JSON.parse(value) : {});
			settingObj.viewVariant = 'dateTimeView';
			settingObj.editVariant = 'dateTimeView';
		}

		let buttons = [];
		let applicationObj = MetadataStore.get(recordId, tableSchemaName);

		if(applicationObj) {
			if(applicationObj.hideCSS) {
				buttons.push(<button 
					key="disable" 
					className="btn btn-secondary mr-1" 
					form="appearance-form" 
					aria-label="Show Theme"
					title="Activate the theme and apply it to your application."
					onClick={this._onToggleStyleDisabled}>
					Activate Theme
				</button>);
			} else {
				buttons.push(<button 
					key="disable" 
					className="btn btn-secondary mr-1" 
					form="appearance-form" 
					aria-label="Hide Theme"
					title="Temporarily deactivate the theme for debugging purposes."
					onClick={this._onToggleStyleDisabled}>
					Deactivate Theme
				</button>);
			} 
		}

		// Setup the Reset and Save buttons
		if(AdminSettingsStore.getSettingsListHidden()) {
			buttons.push(
				<button 
					key="reset" 
					className="btn btn-warning ml-1 mr-2 w-50 d-flex justify-content-center" 
					form="appearance-form" 
					aria-label="Reset" 
					onClick={(event) => {ThemeBuilderUtils.resetTheme(event, recordId, tableSchemaName)}}>
						Reset
				</button>,
				<button 
					key="submit" 
					className="btn btn-primary mr-1 w-50 d-flex justify-content-center" 
					form="appearance-form" 
					aria-label="Save" 
					title="Save (Ctrl-S)" 
					onClick={(event) => ThemeBuilderUtils.saveTheme(event, recordId, tableSchemaName)}>
						Save
				</button>
			);
		}

		return (
			<div id="appearance-settings" onKeyDown={this._onKeyDown}>
				<div className="appearance-settings-container mx-3">
					<div className="d-flex w-100 justify-content-between align-items-center py-3">
						<div className="d-flex align-items-center" ></div>
						<div className="d-flex align-items-center justify-content-between mr-2" style={{ flex: "1 1 0%" }}>
							<h3 className='my-1'>
								{/* Show collapse only when setting has been selected */} 
								{settingsHidden ?
									<button 
										className="btn btn-back pl-0"
										title="Show Theme settings." 
										form="appearance-form" 
										onClick={() => {AdminSettingsActions.onSettingsListHideChange(false);}}>
										<img height="26" 
										width="26" 
										src={ContextStore.getUrlMedia() + "/expand-settings-list.svg"} 
										alt="" />
									</button>
								: null }
								{label}
							</h3>
							<div className="btn-wrapper d-flex align-items-center justify-content-around">
								{buttons}
							</div>
						</div>
					</div>
					<div className="setting-global mt-0 h-100">
						<div className={"setting-wrapper h-100 setting-" + settingSchemaName}>
							<FieldTypeSettingsUI
								recordId={recordId} 
								tableSchemaName={tableSchemaName}
								value={value} 
								setting={settingObj} 
								onChange={this._onSettingChange} 
								fieldType={settingFieldType} 
								label={label}
								skipDescriptiveText={true}
								dataRecordId={recordId}
								dataTableSchemaName={tableSchemaName}
								/>
								{descriptiveText && 
									<small 
										id={'description-' + this.props.renderId} 
										className='form-text text-muted' 
										dangerouslySetInnerHTML={{ __html: descriptiveText || '' }}></small>
								}
						</div>
					</div>
				</div>
			</div>);
	}
}
const container = Container.create(ThemeSetting, {withProps: true});
export default container;