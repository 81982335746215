import React, { Component } from 'react';
import { Container } from 'flux/utils';
import { AdminSettingsStore, FieldSettingsStore, FieldStore, FieldTypeStore, PageStore, LogicFunctionStore } from '../../stores';
import { AdminSettingsActions } from '../../actions';
import { FieldUtils, ObjectUtils } from '../../utils';

const triggerFriendlyNames = {
	onBlur: 'Lose Focus',
	onBlurChange: 'Lose Focus and Change',
	onClick: 'Click',
	onFocus: 'Gain Focus',
	onEnterUp: 'On Enter Up', // @TODO : Set this one from FT BUILDER
	onMouseOut: 'Mouse Out',
	onMouseOver: 'Mouse Over',
	validate: 'Validate (Local)', // @TODO : Set this one from FT BUILDER
	preFieldSave: 'Validate (Global)',
	onPageLoad: 'After Page Loads',
	prePageSave: 'Page Validates',
	postPageSave: 'After Page Saves',
};


/**
 * Renderer for the appearance assistant
 * 
 * @class AppearanceAssistant
 * @extends {Component}
 */
class AutomationSettingsChooser extends Component {
	/**
	 * Creates an instance of AppearanceAssistant.
	 * @param {any} props 
	 * 
	 * @memberOf AppearanceAssistant
	 */
	// constructor(props) {
	// 	super(props);
	// }

	/**
	 * @static
	 * @returns - the stores that are being watch by NLPBuilder component
	 * 
	 * @memberOf NLPBuilder
	 */
	static getStores() {
		return [AdminSettingsStore, FieldSettingsStore, FieldStore, FieldTypeStore, PageStore];
	}

	/**
	 * Click on a Setting and update the admin settings store with where we are.
	 * 
	 * @param {any} settingSchemaName
	 * @param {any} event 
	 * @memberof SettingsPanel
	 */
	onSettingClick(settingSchemaName, event) {
		event.preventDefault();
		//Toggle to select and deselect the Setting
		AdminSettingsActions.onSettingChange(settingSchemaName);

		// Ensure the Settings are hidden
		AdminSettingsActions.onSettingsListHideChange(true);

		/* find a better way to do this once assistant is in place */
		// setTimeout(() => {
		// 	let sizeObj = AdminSettingsStore.getSizeJS();
		// 	sizeObj.height--;
		// 	AdminSettingsActions.onCenterColumnResize(sizeObj);
		// }, 800);

		// This was here so the old NLP Query gets cleared out when a new setting is chosen.
		// AssistantSearchUtils.runAssistantSearch();
	}

	/**
	 * @static
	 * @param {any} prevState 
	 * @returns {Object} - The Dictionaries from NLPBuilderDictionaries Store 
	 * 
	 * @memberOf NLPBuilder
	 */
	static calculateState(prevState) {
		let automationTriggers = [];
		let customTriggers = [];
		let settingsValue = '';
		let settingsValueSource = '';
		let valueSource = '';
		let	valueLabelClass = '';
		let	badgeTypeClass = '';

		let recordId = AdminSettingsStore.getRecordId();
		let tableSchemaName = AdminSettingsStore.getTableSchemaName();
		let parentRecordId = AdminSettingsStore.getParentRecordId();
		let parentTableSchemaName = AdminSettingsStore.getParentTableSchemaName();
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let settingsSource = FieldSettingsStore.getSettingsWithSource(recordId, parentRecordId);

		if (tableSchemaName === 'field') {
			let fieldId = recordId;
			let fieldObj = FieldStore.get(fieldId);

			// let component = PageStore.getComponent(pageId, recordId, 'lg');
			if (fieldObj) {
				// Get available triggers for this field type.
				let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType);
				
				
				if(fieldTypeObj.supportedActionTriggers) {
					let supportedActionTriggersArray = fieldTypeObj.supportedActionTriggers.split(',');

					//Adding Local Validation for every field, since the page runs them
					supportedActionTriggersArray.push('preFieldSave'); // @TODO : Set this one from FT BUILDER
					supportedActionTriggersArray.push('onEnterUp'); // @TODO : Set this one from FT BUILDER

					automationTriggers = supportedActionTriggersArray.map(triggerName => {
						// get the settings value
						settingsValue = settingsSource['automation-'+triggerName] 
						? settingsSource['automation-'+triggerName].value 
						: null;
						let automationObj = ObjectUtils.getObjFromJSON(settingsValue);
						if(!automationObj || !automationObj.js || automationObj.js.length === 0) {
							badgeTypeClass = valueSource = valueLabelClass = settingsValue = '';
						} else {
							// get the source of the setting Local/Global
							settingsValueSource = settingsSource['automation-'+triggerName] 
												? settingsSource['automation-'+triggerName].source 
												: null;
							// set the badges according to local/global
							if(settingsValueSource === 'local') {
								valueSource = 'L';
								badgeTypeClass = 'badge-secondary';
							}
							settingsValue = 'Yes';
						}

						// Handle converting logicFunctionsUsed into an array
						let logicFunctionIds = automationObj && automationObj.logicFunctionsUsed ? automationObj.logicFunctionsUsed : '';
						let logicFunctionIdsArr =[]; 
						if (logicFunctionIds) {
							if(Array.isArray(logicFunctionIds)) {
								logicFunctionIdsArr = logicFunctionIds;
							} else {
								logicFunctionIdsArr = logicFunctionIds.split(',');
							}
						}
						
						return {
							name: triggerName,
							selectedSetting: selectedSetting === triggerName,
							badgeTypeClass: badgeTypeClass,
							valueSource: valueSource,
							valueLabelClass: valueLabelClass,
							settingsValue: settingsValue,
							logicFunctions:  logicFunctionIdsArr
						};
					});
				}
				
				let customActionTriggers = fieldTypeObj.customActionTriggers;
				if(customActionTriggers){
					customActionTriggers = ObjectUtils.getObjFromJSON(customActionTriggers);

					customTriggers = customActionTriggers.map(triggerObj => {
						let customTriggerCodeName = triggerObj.codeName;
						let customTriggerFriendlyName = triggerObj.friendlyName;

						//Include the custom Triggers to the Friendly Names list 
						triggerFriendlyNames[customTriggerCodeName] = customTriggerFriendlyName;

						// get the settings value
						settingsValue = settingsSource['automation-'+customTriggerCodeName] 
						? settingsSource['automation-'+customTriggerCodeName].value 
						: null;
						let automationObj = ObjectUtils.getObjFromJSON(settingsValue);
						if(!automationObj || !automationObj.js || automationObj.js.length === 0) {
							badgeTypeClass = valueSource = valueLabelClass = settingsValue = '';
						} else {
							// get the source of the setting Local/Global
							settingsValueSource = settingsSource['automation-'+customTriggerCodeName] 
												? settingsSource['automation-'+customTriggerCodeName].source 
												: null;
							// set the badges according to local/global
							if(settingsValueSource === 'local') {
								valueSource = 'O';
								badgeTypeClass = 'badge-secondary';
							}
							settingsValue = 'Yes';
						}

						// Handle converting logicFunctionsUsed into an array
						let logicFunctionIds = automationObj && automationObj.logicFunctionsUsed ? automationObj.logicFunctionsUsed : '';
						let logicFunctionIdsArr =[]; 
						if (logicFunctionIds) {
							if(Array.isArray(logicFunctionIds)) {
								logicFunctionIdsArr = logicFunctionIds;
							} else {
								logicFunctionIdsArr = logicFunctionIds.split(',');
							}
						}

						return {
							name: customTriggerCodeName,
							selectedSetting: selectedSetting === customTriggerCodeName,
							badgeTypeClass: badgeTypeClass,
							valueSource: valueSource,
							valueLabelClass: valueLabelClass,
							settingsValue: settingsValue,
							logicFunctions: logicFunctionIdsArr
						};
					});
				}	
			}
		} else if (tableSchemaName === 'page') {
			let pageObj = PageStore.get(recordId);
			if (pageObj) {
				automationTriggers = ['onPageLoad','prePageSave', 'postPageSave', 'onEnterUp'] // @TODO : Set this one from FT BUILDER
					.map(triggerName => { 
					// get the setting for the popover pin
					let automationObj = PageStore.getAutomation(recordId, triggerName);
					let hasActions = '';
					// check if it has js, if it does get the xml value
					if(automationObj && automationObj.js && automationObj.js.length > 0) {
						hasActions = 'Yes';
					}
					// Handle converting logicFunctionsUsed into an array
					let logicFunctionIds = automationObj && automationObj.logicFunctionsUsed ? automationObj.logicFunctionsUsed : '';
					let logicFunctionIdsArr =[]; 
					if (logicFunctionIds) {
						if(Array.isArray(logicFunctionIds)) {
							logicFunctionIdsArr = logicFunctionIds;
						} else {
							logicFunctionIdsArr = logicFunctionIds.split(',');
						}
					}
					return {
						name: triggerName,
						selectedSetting: selectedSetting === triggerName,
						settingsValue: hasActions,
						valueSource:'',
						badgeTypeClass: 'badge-dark',
						logicFunctions: logicFunctionIdsArr
					};
				});
			}
		}
		
		//Join the supported and custom action triggers 
		automationTriggers = automationTriggers.concat(customTriggers);
		
		automationTriggers = automationTriggers.sort((a, b) => {
			return (triggerFriendlyNames[a.name] > triggerFriendlyNames[b.name]) ? 1 : -1;
		});
		return {
			'automationTriggers': automationTriggers,
			'recordId': recordId,
			'tableSchemaName': tableSchemaName,
			'parentRecordId': parentRecordId,
			'parentTableSchemaName': parentTableSchemaName,
			'settingsHidden': AdminSettingsStore.getSettingsListHidden()
		};
	}

	/**
	 * @returns 
	 * @memberof AutomationSettingsChooser
	 */
	render() {
		let {automationTriggers, recordId, tableSchemaName} = this.state;

		let settingsList = automationTriggers.map(trigger => {
			let labelClassNames = 'setting-label';
			if(trigger.selectedSetting) { 
				labelClassNames += ' selected';
			}
			// let iconClassNames = 'fa custom fa-check-circle-o';
			return (<li key={this.state.recordId+'-'+trigger.name} className="nav-item">
					<div className="nav-link" onClick={this.onSettingClick.bind(this, trigger.name)}>
						{/* Temporarily removed the following div */}
						{/* <div className={"setting-icon " + iconClassNames} /> */}
						{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
						{/* Add this back in when the setting-icon is used again */}
						<div className="d-flex setting-text-container">
							<div className="w-50 setting-label-container">
								<div className={labelClassNames}><h4>{triggerFriendlyNames[trigger.name]}</h4></div>
							</div>
							<div className="w-50 d-flex justify-content-between setting-value-container">
								<div className={"text-right setting-pattern-list setting-value " + trigger.valueLabelClass }>
									{ trigger.settingsValue }
								</div>
								{ trigger.valueSource ? 
									<div className={"setting-scope badge badge-pill " + trigger.badgeTypeClass + " align-self-center"}> 
										{ trigger.valueSource } 
									</div>
								: null
								}
							</div>
						</div>
					</div>
					{trigger.logicFunctions && trigger.logicFunctions.length ?
						(<ul style={{ listStyleType: 'none' }}>
							{trigger.logicFunctions.map(functionId => {
								let functionObj = LogicFunctionStore.get(functionId) || {};
								let name = functionObj.name ? functionObj.name : functionId;
								let description = functionObj.description ? functionObj.description : '';
								return (<li key={functionId} className='setting-pattern-list setting-value logic-sub-item' title={description}>
									{name}
								</li>);
							})}
						</ul>) :
						null}
				</li>);
		});

		let componentIcon = '';
		let headerLabel = 'Logic Triggers';
		if(tableSchemaName === 'page') {
			componentIcon = FieldUtils.getFieldTypeIcon('page');
			headerLabel = 'Logic Triggers';
			// headerLabel = 'Page Logic Triggers';
		} else {
			componentIcon = FieldUtils.getFieldTypeIcon(recordId);
			headerLabel = 'Logic Triggers';
			// headerLabel = FieldUtils.getFieldTypeName(recordId) + ' Logic Triggers';
		}

		return (
			<div>
				<ul className="nav flex-column settings-list-wrapper mt-0">
					<li className="settings-list-header field-settings-list-header d-flex">
						<img style={{ marginTop: '4px' }} height="22" width="22" className="mr-2" src={componentIcon} alt="" />
						<h3>{ headerLabel }</h3>
					</li>
					{settingsList && settingsList.length
						? settingsList
						: <div className='no-settings-found'>No Settings Found</div>
					}
				</ul>
			</div>
		);
	};
}

const container = Container.create(AutomationSettingsChooser, {withProps: true});
export default container;
