import React, { Component } from 'react';
import { Container } from 'flux/utils';
// import ObjectUtils from '../utils/object-utils';

// Actions
import AdminSettingsActions from '../actions/admin-settings-actions';
import MapActions from '../actions/map-actions';

// Constants
import GroupFilters from '../constants/group-filters';

// Dashboard Headers
import DataDashboardHeader from './dashboards/data-header.react';
import EngineeringDashboardHeader from './dashboards/engineering-header.react';
import LogicDashboardHeader from './dashboards/logic-header.react';
import PresentationDashboardHeader from './dashboards/presentation-header.react';
import ProjectDashboardHeader from './dashboards/project-header.react';

// Footer....
import Footer from './dashboards/footer.react';

// Stores
import AdminSettingsStore from '../stores/admin-settings-store';
import AuthenticationStore from '../stores/authentication-store';

// Tabs
import DataDashboardTabs from './dashboards/data-tabs.react';
import EngineeringDashboardTabs from './dashboards/engineering-tabs.react';
import LogicDashboardTabs from './dashboards/logic-tabs.react';
import PresentationDashboardTabs from './dashboards/presentation-tabs.react';
import ProjectDashboardTabs from './dashboards/project-tabs.react';

/**
 * Displays dashboard panel
 */
class DashboardPanel extends Component {
	/**
	 * Creates an instance of SettingsPanel.
	 * 
	 * @param {any} props 
	 * @memberof SettingsPanel
	 */
	constructor(props) {
		super(props);
		this.doOverlaysInclude = this.doOverlaysInclude.bind(this);
		this._onOverlayToggle = this._onOverlayToggle.bind(this);
		this._onDashboardSelect = this._onDashboardSelect.bind(this);
	}
	/**
	 * Return the stores we're listening to for changes.
	 *
	 * @return {array}  description
	 */
	static getStores() {
		return [AdminSettingsStore, AuthenticationStore];
	}

	/**
	 * Calculate our state anytime a store changes.
	 *
	 * @param  {object} prevState
	 * @return {object}
	 */
	static calculateState(prevState) {
		return {
			'activeDashboard': AdminSettingsStore.getActiveDashboard(),
			'activeOverlays': AdminSettingsStore.getActiveOverlays(),
			'showEngineeringDashboard': AuthenticationStore.getHasEngineeringTools(),
			'selectedOverlay': AdminSettingsStore.getSelectedOverlay(),
			'panelDashboard': AdminSettingsStore.getSettingsPanelDashboard(),
			'isRightPanelOpen': AdminSettingsStore.getIsRightPanelOpen(),
		};
	}

	/**
	 * doOverlaysInclude - Checks to see if overlay is listed in activeOverlays
	 *
	 * @param  {string} target name of overlay
	 * @return {boolean}
	 */
	doOverlaysInclude(target) {
		let returnVal = false;
		let activeOverlays = this.state.activeOverlays;
		if (activeOverlays !== undefined) {
			if (activeOverlays.includes(target)) {
				returnVal = true;
			}
		}
		return returnVal;
	}

	/**
	 * Method to pass into Dashboard Headers to use for toggling an overlay on or off.
	 * @param {string} targetOverlay Which overlay to turn on
	 */
	_onOverlayToggle(targetOverlay) {
		if (targetOverlay === 'resizer' || targetOverlay === 'visibility' || targetOverlay === 'visibilityV2') {
			AdminSettingsActions.onOverlayChangeWithRecalculation(targetOverlay);
		} else {
			AdminSettingsActions.onOverlayChange(targetOverlay);
		}
	}

	/**
	 * Method to pass into Dashboard Headers to use for toggling the dashboard section on or off.
	 * @param {string} targetDashboard 
	 */
	_onDashboardSelect(targetDashboard) {
		// If this dashboard is already open...
		if (AdminSettingsStore.getActiveDashboard() === targetDashboard) {
			AdminSettingsActions.onLeftPanelChange(null, true);
			// If this dashboard is NOT already open...
		} else {
			// Set the default group by, and the group toggle status for any 
			// groups that need to be OPEN by default.
			switch (targetDashboard) {
				case 'data':
					this._onDashboardTabSelect('data-tables')
					MapActions.groupBy(GroupFilters.TYPE);
					break;
				case 'presentation':
					this._onDashboardTabSelect('presentation-pages')
					break;
				case 'logic':
					this._onDashboardTabSelect('logic-functions-by-table')
					break;
				case 'projects':
					this._onDashboardTabSelect('project-discussions')
					MapActions.groupBy(GroupFilters.TYPE);
					break;
				case 'engineering':
					this._onDashboardTabSelect('engineering-field-components')
					MapActions.groupBy(GroupFilters.CHANGED);
					break;
				default:
					MapActions.groupBy(GroupFilters.NAME);
					break;
			}

			AdminSettingsActions.onLeftPanelChange(targetDashboard, true);
		}
	}

	/**
	 * Method to pass into Dashboard Tabs to use for toggling the tabs
	 * @param {string} targetTabName 
	 */
	_onDashboardTabSelect(targetTabName) {
		// Set the default group by, and the group toggle status for any 
		// groups that need to be OPEN by default.
		switch (targetTabName) {
			case 'data-tables':
			case 'data-fields':
			case 'data-relationships':
			case 'presentation-pages':
			case 'presentation-theme':
			case 'logic-webhooks':
			case 'logic-pages':
			case 'logic-fields':
			case 'logic-apis-by-name':
			case 'logic-scheduled':
			case 'logic-functions-by-table':
				MapActions.groupBy(GroupFilters.TABLE_NAME);
				break;
			case 'engineering-field-components':
				MapActions.groupBy(GroupFilters.CHANGED);
				break;
			case 'project-compliances':
				MapActions.groupBy(GroupFilters.COMPLIANCE);
				break;
			case 'project-discussions':
				MapActions.groupBy(GroupFilters.TYPE);
			break;
			default:
				console.warn('targetTabName', targetTabName, 'not found');
				break;
		}

		AdminSettingsActions.onDashboardTabChange(targetTabName);
	}

	/**
	 * render the component
	 *
	 * @return {JSX}
	 */
	render() {
		let { activeDashboard, showEngineeringDashboard, panelDashboard, isRightPanelOpen, selectedOverlay } = this.state;
		// Build both the enabled and disabled Engineering Tools...
		let engineeringTools = null;
		if (activeDashboard === 'engineering') {
			engineeringTools = [
				<EngineeringDashboardHeader
					key="EngineeringDashboardHeader"
					onDashboardSelect={this._onDashboardSelect} />,
				<EngineeringDashboardTabs
					key="EngineeringDashboardTabs"
					onTabSelect={this._onDashboardTabSelect} />
			]
		} else {
			engineeringTools =
				<EngineeringDashboardHeader
					key="EngineeringDashboardHeader"
					onDashboardSelect={this._onDashboardSelect} />
		}
		return (
			<div id="left-panel" className="panel cd-tools d-flex flex-column">
				<div className={`d-flex flex-column header-list ${isRightPanelOpen && selectedOverlay}`}>
					<DataDashboardHeader
						key="DataDashboardHeader"
						onOverlayEnable={this._onOverlayToggle}
						onDashboardSelect={this._onDashboardSelect}
						panelDashboard={isRightPanelOpen && panelDashboard}
						isSecurityOn={this.doOverlaysInclude('security')}
						isRecordsOn={this.doOverlaysInclude('query')}
					/>
					{(activeDashboard === 'data'
						? <DataDashboardTabs
							onTabSelect={this._onDashboardTabSelect}
							key="DataDashboardTabs" />
						: null)}
					<PresentationDashboardHeader
						key="PresentationDashboardHeader"
						onOverlayEnable={this._onOverlayToggle}
						onDashboardSelect={this._onDashboardSelect}
						panelDashboard={isRightPanelOpen && panelDashboard}
						isResizerOn={this.doOverlaysInclude('resizer')}
						isVisibilityOn={this.doOverlaysInclude('visibility')}
						isAppearanceOn={this.doOverlaysInclude('appearance')} />
					{(activeDashboard === 'presentation'
						? <PresentationDashboardTabs
							onTabSelect={this._onDashboardTabSelect}
							key="PresentationDashboardTabs" />
						: null)}
					<LogicDashboardHeader
						key="LogicDashboardHeader"
						onOverlayEnable={this._onOverlayToggle}
						panelDashboard={isRightPanelOpen && panelDashboard}
						onDashboardSelect={this._onDashboardSelect}
						isLogicOn={this.doOverlaysInclude('automation')}
					/>
					{(activeDashboard === 'logic'
						? <LogicDashboardTabs
							onTabSelect={this._onDashboardTabSelect}
							key="LogicDashboardTabs" />
						: null)}
					<ProjectDashboardHeader
						key="ProjectDashboardHeader"
						onOverlayEnable={this._onOverlayToggle}
						onDashboardSelect={this._onDashboardSelect}
						panelDashboard={isRightPanelOpen && panelDashboard}
						isDiscussionsOn={this.doOverlaysInclude('discussion')}
						isComplianceOn={this.doOverlaysInclude('compliance')} />
					{(activeDashboard === 'projects'
						? <ProjectDashboardTabs
							onTabSelect={this._onDashboardTabSelect}
							key="ProjectDashboardTabs" />
						: null)}
					{(showEngineeringDashboard
						? engineeringTools
						: null
					)}
				</div>
				<Footer key="Footer" />
			</div>
		);
	}
}
const container = Container.create(DashboardPanel);
export default container;