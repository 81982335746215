import React, { Component } from 'react';
import Helmet from 'react-helmet';
import AuthenticationAPI from '../apis/authentication-api';
import InterfaceActions from '../actions/interface-actions';

/**
 * Renders the Login Page
 * 
 * @class Login
 * @extends {Component}
 */
class Login extends Component {
	/**
	* Creates instance of Login Component
	*/
	constructor(props) {
		super(props);
		this.onSubmit = this.onSubmit.bind(this);
		this.onUsernameChange = this.onUsernameChange.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);

		this.state = {username: '', password: ''};
	}

	/**
	 * render - renders wrapper
	 *
	 * @returns JSX
	 */
	render() {
		if(localStorage.getItem('authenticated') && sessionStorage.getItem('redirectPath')){
			// console.log('redirect to the back page!')
			let redirectUrl = sessionStorage.getItem('redirectPath');
			sessionStorage.removeItem('redirectPath');
			InterfaceActions.replacePage({
				'url': redirectUrl ? redirectUrl : '/',
				// 'replaceHistory': true
			});
			//And then clean after ourselves
		}
		return (
			<div className={this.props.className}>
				<Helmet title="Login" />
				<div className="container">
					<form className="form-signin" onSubmit={this.onSubmit}>
						<h2 className="form-signin-heading">Please sign in</h2>
						<label htmlFor="username" className="sr-only">Email address</label>
						<input type="text" id="username" value={this.state.username} onChange={this.onUsernameChange} className="form-control" placeholder="Username" required autoFocus />
						<label htmlFor="inputPassword" className="sr-only">Password</label>
						<input type="password" id="inputPassword" className="form-control" value={this.state.password} onChange={this.onPasswordChange} placeholder="Password" required />
						<button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
					</form>
				</div>
			</div>
		);
	}

	/**
	 * Handle the username field change
	 * 
	 * @param {any} event 
	 * @memberof Login
	 */
	onUsernameChange(event) {
		this.setState({'username': event.target.value});
	}

	/**
	 * Handle the password field change
	 * 
	 * @param {any} event 
	 * @memberof Login
	 */
	onPasswordChange(event) {
		this.setState({'password': event.target.value});
	}

	/**
	 * Processes the login form
	 * 
	 * @param {any} event 
	 * 
	 * @memberof Login
	 */
	onSubmit(event) {
		event.preventDefault();
		AuthenticationAPI.login(this.state.username, this.state.password);
	}
}

export default Login;
