import AppDispatcher from '../dispatcher/app-dispatcher'
import AuthenticationConstants from '../constants/authentication-constants'
import Immutable from 'immutable'
// import UIUtils from '../utils/ui-utils';


const AuthenticationActions = {
    onAuthLogin(response){
      AppDispatcher.dispatch(Immutable.fromJS({
        'type': AuthenticationConstants.AUTH_LOGIN,
        'userId': response.userId,
		'username': response.username,
		'signedMdKey': response.signedMdKey,
		'userSecurityGroups': response.userSecurityGroups,
		'hasDevTools': response.hasDevTools,
		'enableEngineeringTools': response.enableEngineeringTools,
		'planName': response.planName,
		'maxRecordsPerTable': response.maxRecordsPerTable,
		'maxTables': response.maxTables,
		'maxPages': response.maxPages,
		'enableIncomingAPI': response.enableIncomingAPI,
		'enableRestAPILogic': response.enableRestAPILogic,
		'enableScheduledLogic': response.enableScheduledLogic,
		'enableVisibility': response.enableVisibility,
		'enableSecurityGroups': response.enableSecurityGroups,
		'enableAuditLogging': response.enableAuditLogging,
		'enableCompliance': response.enableCompliance,
		'enableSecurity': response.enableSecurity,
		'enableStripe': response.enableStripe,
		'enableChangeManagement': response.enableChangeManagement
      }));
	  if(response.maxRecordsPerTable || response.maxRecordsPerTable === '0') {
		let RecordsApi = require('../apis/records-api').default;
		RecordsApi.getRecordCounts().catch(console.error);
	  }
    },
    onAuthLogout(){
      AppDispatcher.dispatch(Immutable.fromJS({
        'type': AuthenticationConstants.AUTH_LOGOUT,
      }))
    },
    onAuthError(){
      AppDispatcher.dispatch(Immutable.fromJS({
        'type': AuthenticationConstants.AUTH_ERROR
      }))
	},
	onSetUserIP(userIp){
		AppDispatcher.dispatch(Immutable.fromJS({
		  'type': AuthenticationConstants.SET_USER_IP,
		  'userIp': userIp
		}))
	},
	haveAuthCreds(haveAuthCreds) {
		AppDispatcher.dispatch(Immutable.fromJS({
		  'type': AuthenticationConstants.AUTH_CREDS_SET,
		  'haveAuthCreds': haveAuthCreds
		}))
	}
}

export default AuthenticationActions;