/* global citDev */
import AppDispatcher from '../dispatcher/app-dispatcher';
import RecordSetConstants from '../constants/record-set-constants';
import Immutable from 'immutable';
import RecordSetStore from '../stores/record-set-store';
import RenderStore from '../stores/render-store';
import RenderConstants from '../constants/render-constants';
// import FieldStore from '../stores/field-store';
// import FieldModesStore from '../stores/field-modes-store';
import FieldSettingsStore from '../stores/field-settings-store';
import ObjectUtils from '../utils/object-utils';
import { FieldStore } from '../stores';
// import RenderActions from './render-actions';
import GridHeightUtils from '../utils/grid-height-utils';

let calculatingRecordSets = {};

/**
 * Record Set Actions
 * 
 * @namespace RecordSetActions
 */
const RecordSetActions = {
    /**
     * Loads record set into the record set store.
	 * The record set shoud be should be Array of Object.<tableSchemaName, recordId>
     * 
     * @param {string} renderId
	 * @param {string} setName
     * @param {string} tableSchemaName
     * @param {Array<{tableSchemaName: string, recordId: string}>} recordSetArray
	 * @param {string} [setUIName=setName]
	 * @memberof RecordSetActions
     */
	setRecordSet(renderId, setName, tableSchemaName, recordSetArray, setUIName) {
		if (typeof setUIName !== 'string' || !setUIName) {
			let renderObj = RenderStore.get(renderId);
			if (renderObj) {
				setUIName = setName.replace(renderObj.componentId + '-', '');
			} else {
				setUIName = setName.replace(/(.*-)(.*)$/, '$2');
			}
		}

		AppDispatcher.dispatch(Immutable.fromJS({
			type: RecordSetConstants.SET_RECORD_SET,
			renderId: renderId,
			setName: setName,
			tableSchemaName: tableSchemaName,
			recordSetArray: recordSetArray,
			uiName: setUIName
		}));
	},
    /**
     * Loads record set into the record set store.
	 * The same as setRecordSet but it uses the rows key
	 * The record set shoud be should be Array of Object.<tableSchemaName, recordId>
     * 
     * @param {string} renderId
	 * @param {string} setName
     * @param {string} tableSchemaName
     * @param {Array<{tableSchemaName: string, recordId: string}>} recordSetArray
	 * @param {string} [setUIName=setName]
	 * @memberof RecordSetActions
     */
	setRecordSetRows(renderId, setName, tableSchemaName, rows, setUIName) {
		if (typeof setUIName !== 'string' || !setUIName) {
			let renderObj = RenderStore.get(renderId);
			if (renderObj) {
				setUIName = setName.replace(renderObj.componentId + '-', '');
			} else {
				setUIName = setName.replace(/(.*-)(.*)$/, '$2');
			}
		}

		AppDispatcher.dispatch(Immutable.fromJS({
			type: RecordSetConstants.SET_RECORD_SET,
			renderId: renderId,
			setName: setName,
			tableSchemaName: tableSchemaName,
			rows: rows,
			uiName: setUIName
		}));
	},

	/**
	 *
	 * Used to set a record set in a dynamic selection field.
	 * Intended to update all relevant stores with a single dispatch
	 * 
	 * @param {string} renderId
	 * @param {string} setName
	 * @param {string} setTableSchemaName
	 * @param {Array} recordSetArray
	 * @param {string} startingRelatedRecordsJSON
	 * @param {string} uiName
	 * @param {string} dataTableSchemaName
	 * @param {string} dataRecordId
	 * @param {string} fieldSchemaName
	 */
	setRecordSetSelected(renderId, setName, setTableSchemaName, recordSetArray, startingRelatedRecordsJSON, uiName, dataTableSchemaName, dataRecordId, fieldSchemaName) {
		if (typeof uiName !== 'string' || !uiName) {
			let renderObj = RenderStore.get(renderId);
			if (renderObj) {
				uiName = setName.replace(renderObj.componentId + '-', '');
			} else {
				uiName = setName.replace(/(.*-)(.*)$/, '$2');
			}
		}
		AppDispatcher.dispatch(Immutable.fromJS({
			dataTableSchemaName: dataTableSchemaName,
			dataRecordId: dataRecordId,
			fieldSchemaName: fieldSchemaName,
			recordSetArray: recordSetArray,
			renderId: renderId,
			setName: setName,
			setTableSchemaName: setTableSchemaName,
			startingRelatedRecordsJSON: startingRelatedRecordsJSON,
			type: RecordSetConstants.SET_SELECTED_RECORD_SET,
			uiName: uiName
		}));
	},
	
	/**
	 * Sets multiple recordSets
	 * 
	 * @param {Object<{renderId: {setName: {tableSchemaName: string, recordSetArray: string[]}}}>} recordSets
	 * @memberof RecordSetActions
	 */
	setRecordSetBulk(recordSets) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RecordSetConstants.SET_RECORD_SET_BULK,
			recordSets: recordSets
		}));
	},

	/**
	 * Specifically sets content tabs
	 * @param {*} renderId 
	 * @param {*} dataTableSchemaName 
	 * @param {*} dataRecordId 
	 * @param {*} fieldSchemaName 
	 * @param {*} setName 
	 * @param {*} tableSchemaName 
	 * @param {*} newValue 
	 * @param {*} friendlyName 
	 */
	setSelectedContentTab(renderId, dataTableSchemaName, dataRecordId, fieldSchemaName, setName, tableSchemaName, newValue, friendlyName, skipUpdate) {
		AppDispatcher.dispatch(Immutable.fromJS({
			type: RecordSetConstants.SET_SELECTED_CONTENT_TAB,
			renderId,
			fieldSchemaName,
			dataTableSchemaName,
			dataRecordId,
			setName,
			setTableSchemaName: tableSchemaName,
			value: newValue,
			uiName: friendlyName
		}));

		// Now also find and recalculate the matching grid child to display

		let LocalContextStore = require('../stores/local-context-store').default;
		let options = LocalContextStore.getValue(renderId, 'options');
		let tabValueObj = ObjectUtils.getObjFromJSON(newValue);
		if(!skipUpdate && options && tabValueObj) {
			let matchingRenderId = undefined;
			options.forEach(option => {
				let optionAttachmentId = option.get('attachmentId') || option.get('fieldId');
				if(option.get('type') === 'static') {
					if(
						(optionAttachmentId === tabValueObj.fieldId) // We don't need the record comparison because there's only one option with this fieldId
					) {
						matchingRenderId = option.get('contentRenderId') || option.get('renderId');
					}
				} else if (option.get('options')) {
					option.get('options').forEach(dynOption => {
						if(
							optionAttachmentId === tabValueObj.fieldId
							&& dynOption.get('recordId') === tabValueObj.recordId
							&& dynOption.get('tableSchemaName') === tabValueObj.tableSchemaName
						) {
							matchingRenderId = dynOption.get('contentRenderId') || dynOption.get('renderId');
						}
					});
				}
			});


			if(matchingRenderId) {
				let lastRefresh = +new Date();
				let grids = [];
				GridHeightUtils.changeContentTabsAndDropdownsSelection(renderId, options, newValue, grids)
					.then(() => {
						if(grids[0]) {
							AppDispatcher.dispatch(Immutable.fromJS({
								type: RenderConstants.REFRESH_FIELD,
								fieldId: grids[0].componentId,
								lastRefresh: lastRefresh,
								grids: grids
							}));
						}
					})
					.catch(console.error);
				
			}
		}
	},

	recalculateRecordSet(renderId, setName) {

		let recordSet = RecordSetStore.getRecordSetFull(renderId, setName);
		let renderObj = RenderStore.get(renderId);
		if(recordSet && recordSet.has('query') && renderObj && renderObj.componentType === 'field') {
			// Is this a repeating grid?
			let parentRenderObj = RenderStore.get(renderObj.renderParentId);

			let fieldObj = FieldStore.get(renderObj.componentId);
			let fieldSettingsObj = renderObj.attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(renderObj.attachmentId, renderObj.componentId, parentRenderObj ? parentRenderObj.compomentId : undefined) : FieldSettingsStore.getSettings(renderObj.componentId, parentRenderObj ? parentRenderObj.compomentId : undefined);

			// We currently have nothing which has calculateGridInfo in a view variant but no edit variant
			// so just use the view variant for now
			let variantName = fieldObj.viewVariant || fieldObj.editVariant;
			// Lookup the default component name for this mode.
			if(!variantName) {
				variantName = FieldStore.getDefaultVariantComponentName(renderObj.componentId, 'view', fieldObj.fieldType);
			}
			let variantObj = citDev[variantName];

			if(
				(fieldSettingsObj && fieldSettingsObj.viewVariant === 'fieldContainerMultiTilesView') ||
				(fieldObj && fieldObj.fieldType === '7ebd9251-675c-4129-95e3-6b8e31c135a2') ||
				(variantObj && variantObj.calculateGridInfo)
				) {
				// This field is a repeating container, regular container, or otherwise has calculateGridInfo logic
				// so just refresh the whole field, honestly
				let RenderActions = require('./render-actions').default;
				return RenderActions.refreshRender(renderId);
			}  else if (recordSet && recordSet.has('query')) {
				let queryJSON = recordSet.get('query');
				let fields = recordSet.get('fields');
				//@todo read the filters, sorts, limits and offsets and apply them
				//to the query
				return this.calculateRecordSet(renderId, setName, queryJSON, (fields && fields.toJS) ? fields.toJS() : fields);
			} else {
				return Promise.resolve([]);
			}
		} else {
			// This can happen if the record set does not exist because you have a refresh button
			// which refreshes multiple record sets,
			// some of which are not on the page
			return Promise.resolve([]);
		}
	},

	/**
	 * Runs a query, then adds the results to the recordSetStore
	 * @param {string} renderId 
	 * @param {string} setName 
	 * @param {string} queryJSON 
	 * @param {string|Object[]} fields 
	 * @param {boolean} [storeQueryDetails=true] Should the record set be recalculatable or manipulated by other components
	 * @param {string} [setUIName=setName] The Label for the Set in the UI
	 * @returns {Promise<Array>}
	 * @memberof RecordSetActions
	 */
	calculateRecordSet(renderId, setName, queryJSON, fields, storeQueryDetails, setUIName, dataRecordId, dataTableSchemaName) {
		
		let initialTimestamp = +new Date();
		
		storeQueryDetails = typeof storeQueryDetails === 'undefined' ? true : !!storeQueryDetails;
		let renderObj = RenderStore.get(renderId);
		if (typeof setUIName !== 'string' || !setUIName) {
			if (renderObj) {
				setUIName = setName.replace(renderObj.componentId + '-', '');
			} else {
				setUIName = setName.replace(/(.*-)(.*)$/, '$2');
			}
		}
		let FieldComponentUtils = require('../utils/field-components').default;
		return new Promise((resolve, reject) => {
			/* Construct the key for the window tracking for record set calculation */
			let key = renderId + setName + queryJSON;

			if(renderObj) {
				key += renderObj.dataRecordId;
			}
	
			let recordSetValueString = '';
			let queryObj = ObjectUtils.getObjFromJSON(queryJSON);
			let recordSetList = RecordSetStore.getRecordSetsInQuery(renderId, queryObj);

			// Add JSON Stringified getRecordSet results to the object (which should cause re-rendering)
			Object.keys(recordSetList).forEach(recordSetKey => {
				recordSetValueString += JSON.stringify(recordSetList[recordSetKey]);
			});
	
			key += recordSetValueString;
	
			//Prevent multiple calls to calculate the same record set from running multiple query calls
			if(!calculatingRecordSets[key]) {
				calculatingRecordSets[key] = true;
	
				// Run the query to get the results to populate the recordSet with...
				FieldComponentUtils.query.processQueryV2(queryJSON, fields, renderId, dataRecordId, dataTableSchemaName).then(queryResult => {
						calculatingRecordSets[key] = false;

						let currentRecordSet = RecordSetStore.getRecordSet(renderId, setName);
						let recordSetLastModified = RecordSetStore.getLastModifiedTime(renderId, setName);
						if(!currentRecordSet || (currentRecordSet && (!recordSetLastModified || recordSetLastModified < initialTimestamp) && !setName.includes('-selected'))) {
							// console.log('Updating record set store for recordSet %s with value', setName, rows);
							if(queryResult && queryResult.rows && queryResult.rows.length) {
								let tableSchemaName = queryResult.rows[0].tableSchemaName;
				
								let action = Object.assign(
									{
										'type': RecordSetConstants.SET_RECORD_SET,
										'renderId': renderId,
										'setName': setName,
										'tableSchemaName': tableSchemaName,
										'uiName': setUIName,
										'lastDt': initialTimestamp
									}, 
									queryResult);
								
								if (storeQueryDetails) {
									Object.assign(
										action,
										{
											'query': queryJSON,
											'fields': fields
										}
									);
								}
								
								// Push the result record set into the record set store.
								AppDispatcher.dispatch(Immutable.fromJS(action));
								return resolve(queryResult.rows);
							} else {
								// Push the result record set into the record set store.
								let action = {
									'type': RecordSetConstants.SET_RECORD_SET,
									'renderId': renderId,
									'setName': setName,
									'tableSchemaName': FieldComponentUtils.query.getReturnTable(queryJSON),
									'rows': [],
									'uiName': setUIName,
									'lastDt': initialTimestamp
								}
								if (storeQueryDetails) {
									Object.assign(
										action,
										{
											'query': queryJSON,
											'fields': fields
										}
									);
								}
								AppDispatcher.dispatch(Immutable.fromJS(action));
								return resolve([]);
							}
						} else {
							return resolve(currentRecordSet);
						}
					}).catch(function (error) {
						calculatingRecordSets[key] = false;
						console.error('Error processing query %s', key, error);
						return reject(error);
					});
			} else {
				console.warn('Record set is already calculating. Returning empty record set until it completes.');
				return resolve([]);
			}
		});

	}
};

export default RecordSetActions;