import InterfaceActions from '../actions/interface-actions';
import RecordActions from '../actions/record-actions';
import RecordSetActions from '../actions/record-set-actions';
import AdminSettingsStore from '../stores/admin-settings-store';
import BrowserStorageStore from '../stores/browser-storage-store';
import ContextStore from '../stores/context-store';
import PageStore from '../stores/page-store';
import FieldStore from '../stores/field-store';
import FieldSettingsStore from '../stores/field-settings-store';
import FieldTypeStore from '../stores/field-type-store';
import PageModeStore from '../stores/page-mode-store';
import RelationshipStore from '../stores/relationship-store';
import RenderStore from '../stores/render-store';
import RecordSetStore from '../stores/record-set-store';
import RecordStore from '../stores/record-store';

import TableStore from '../stores/table-store';
import AuthenticationStore from '../stores/authentication-store';

import FieldModeActions from '../actions/field-mode-actions';

import socketFetcher from '../utils/socket-fetcher';
import RecordVariableUtils from './record-variable-utils';
import ObjectUtils from './object-utils';
import UIUtils from './ui-utils';
import ExpressionProcessor from '../utils/expression-processor';
import ActionProcessor from '../utils/action-processor';
import TimeUtils from '../utils/time-utils';
import RemoteFileStorage from '../utils/stream-data-utils.js';
import parseXml from '@dmclain-citizendeveloper/citdev-expression-optimizer';
import sanitizeHtml from 'sanitize-html';
import uuid from 'uuid';

import { templateParser, templateFormatter, parseDigit } from 'input-format';

import { constants } from '@dmclain-citizendeveloper/citdev-utils';
import internationalPhoneNumberExamples from '../components/phone-number/internationalPhoneNumberExamplesMobile.json';
import {parsePhoneNumber, AsYouType, getExampleNumber, getCountryCallingCode} from 'libphonenumber-js/core';
import metadata from '../components/phone-number/metadata.min.json';
import Flags from 'country-flag-icons/react/3x2'
import labels from '../components/phone-number/labels/en.json';
import { getCountries } from 'libphonenumber-js/core';

/*global Highcharts */

let toExport = {
	form: {
		/**
		 * sends a request to the specified url from a form. this will change the window location.
		 * @param {string} path the path to send the post request to
		 * @param {object} params the paramiters to add to the url
		 * @param {string} [method=post] the method to use on the form
		 */

		post: function(path, params, method='post') {

			// The rest of this code assumes you are not using a library.
			// It can be made less wordy if you use one.
			const form = document.createElement('form');
			form.method = method;
			form.action = path;
		
			for (const key in params) {
				if (Object.prototype.hasOwnProperty.call(params, key)) {
					const hiddenField = document.createElement('input');
					hiddenField.type = 'hidden';
					hiddenField.name = key;
					hiddenField.value = params[key];
			
					form.appendChild(hiddenField);
				}
			}
		
			document.body.appendChild(form);
			form.submit();
		}
	},
	/**
	 * Get Create Search Results for Pages
	 * 
	 * @param {string} recordId 
	 * @param {object} inputParsed 
	 * @returns - Search Results
	 */
	expression: {
		/**
		 * Gets the value of a single expression for a single Field 
		 * 
		 * @param {string} recordId 
		 * @param {string} tableSchemaName
		 * @param {object} expressionObj 
		 * @param {object} namedContexts 
		 * @returns {string} - Value from expression
		 */
		processExpression: function (recordId, tableSchemaName, expressionObj, namedContexts, renderId) {
			return new Promise((resolve, reject) => {
				ExpressionProcessor.processExpression({
					namedContexts: namedContexts,
					expression: expressionObj.generatedJavascript,
					recordId: recordId,
					renderId: renderId,
					tableSchemaName: tableSchemaName,
					installationId: ContextStore.getInstallationId()
				}).then(results => {
					return resolve(results);
				}).catch(error => {
					console.warn(`Error processing Expression for recordId ${recordId} Error was: ${error}`);
					console.warn('Problematic expression was', expressionObj);
					return resolve('');
				});
			});
		},
		/**
		 * Calls expression run Bulk v1 route to process the expression for the different records 
		 * 
		 * @param {string} fieldId expressionToRun - Code to be processed \
		 * @param {string} settingSchemaName The schema name of the setting this expression is for 
		 * @param {array} arrayOfContext The records to get the values for 
		 * @param {string=} renderId The renderId of the field being processed
		 * @param {string=} parentComponentId The parent component id to use for local settings checks
		 * @param {string=} parentComponentType The parent component type to use for local settings checks
		 * @param {string=} attachmentId The attachment ID to use when processing the expression, if present, for doing lookups from the parent
		 * @return {Promise}
		 */
		processExpressionBulk: function (fieldId, settingSchemaName, arrayOfContexts, renderId, parentComponentId, parentComponentType, settingPath, attachmentId) {
			return new Promise((resolve, reject) => {

				// Validate Params 
				if (!Array.isArray(arrayOfContexts)) {
					return reject('processExpressionBulk Error: Array of Expressions is required.');
				}

				if (!fieldId) {
					return reject('processExpressionBulk Error: fieldId is required.');
				}

				if (!settingSchemaName) {
					return reject('processExpressionBulk Error: settingSchemaName is required.');
				}

				// Validate arrayOfContexts
				arrayOfContexts.forEach(expressionContext => {
					if (!expressionContext.recordId || !expressionContext.tableSchemaName) {
						return reject('processExpressionBulk Error: One or more expressions failed to process. It should have recordId and tableSchemaName');
					}
				});

				// Default renderId
				renderId = renderId ? renderId : ContextStore.getPageRenderId();

				// Get the pageRenderObj for use when building namedContexts
				let pageRenderObj = RenderStore.getPageRenderObj(renderId) || {};

				// Initialize the namedContexts object for use in processing
				let namedContexts = {
					page: [{
						recordId: pageRenderObj.dataRecordId,
						tableSchemaName: pageRenderObj.dataTableSchemaName
					}]
				};

				// This expression could potentially have other contexts in them; check

				// Get information regarding the setting so we know if we need to send it forward to the bulk processor or not.
				let fieldSettings = attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, fieldId, parentComponentId) : FieldSettingsStore.getSettings(fieldId, parentComponentId);
				fieldSettings = fieldSettings || {};
				let expressionJSON = fieldSettings[settingSchemaName];
				let expressionObj = ObjectUtils.getObjFromJSON(expressionJSON);
				if(settingPath && settingPath.length) {
					settingPath.forEach(key => {
						let subObj = expressionObj[key];
						expressionObj = subObj && typeof subObj === 'string' ? JSON.parse(subObj) : subObj;
					});
				}

				if (!expressionObj) {
					return reject('processExpressionBulk Error: valid path to expressionObj is required.');
				}

				if(!expressionObj.generatedJavascript) {
					return resolve(arrayOfContexts.map(context => {
						return {
							recordId: context.recordId,
							value: ''
						};
					}));
				}

				// We don't even need to process static expressions
				if (expressionObj.optimizationScheme === 'static') {
					return resolve(arrayOfContexts.map(context => {
						return {
							recordId: context.recordId,
							value: (expressionObj.optimizationData && expressionObj.optimizationData.value ? expressionObj.optimizationData.value : '')
						};
					}));
				}

				// 'singular' type expressions will not change by row: they may be run once, and then returned
				if (expressionObj.optimizationScheme === 'singular') {
					return this.processExpression(
						arrayOfContexts && arrayOfContexts[0] ? arrayOfContexts[0].recordId : '',
						arrayOfContexts && arrayOfContexts[0] ? arrayOfContexts[0].tableSchemaName : '',
						expressionObj, namedContexts, renderId
					).then(results => {
						return resolve(arrayOfContexts.map(context => {
							return {
								recordId: context.recordId,
								value: results
							};
						}));
					}).catch(reject);
				}
				// Make the call to the new route: 

				// We need to see if this requires the location or not
				let locationPromise = Promise.resolve({
					lat: undefined,
					lng: undefined
				});
				// Check if we need the user location
				if(expressionObj.generatedJavascript.includes('citDev.getUserLocation')) {
					locationPromise = InterfaceActions.getUserLocation("full");
				}

				// We also need to make sure the namedContexts include any other information

				namedContexts = Object.assign(RecordVariableUtils.buildNamedContexts(renderId, undefined, expressionObj.generatedJavascript), namedContexts);

				// Strip out any extra information which may have come in
				let newArrayOfContexts = arrayOfContexts.map(({recordId, tableSchemaName}) => ({recordId, tableSchemaName}));

				locationPromise
					.then((location) => {

						return socketFetcher('gw/expressionRunBulk-v2', JSON.stringify({
							expressionContexts: newArrayOfContexts,
							fieldId: fieldId,
							settingSchemaName: settingSchemaName,
							settingPath: settingPath,
							namedContexts: namedContexts,
							parentComponentId: parentComponentId,
							parentComponentType: parentComponentType,
							screenSize: ContextStore.getResponsiveMode(),
							requestInfo: {location, userAgent: navigator.userAgent},
							attachmentId
						}));
					})
					.then(response => {
						if (response.responseCode === 200) {

							let results = response && response.response ? response.response.results : [];
							//resolve 
							return resolve(results);
						}
					})
					.catch(error => {
						return reject(error);
					});
			});
		},

		/**
		 * Similar to processExpressionBulk but without relying on render store information to exist
		 * @param {string} fieldId The ID of the field with the expression
		 * @param {string} settingSchemaName The setting schema name of the field with the expression
		 * @param {array} arrayOfContexts The contexts over which to run the bulk expressions
		 * @param {object} renderObj The fake render object
		 * @param {object} recordSets The recordSets to run this with
		 * @param {string} parentComponentId The parent component ID
		 * @param {string} parentComponentType The parent component type
		 * @param {string} attachmentId The field's attachment ID
		 * @returns Promise
		 */
		processExpressionBulkWithoutRenderEntry: function(fieldId, settingSchemaName, arrayOfContexts, renderObj, recordSets, parentComponentId, parentComponentType, attachmentId, settingPath) {
			return new Promise((resolve, reject) => {

				// Validate Params 
				if (!Array.isArray(arrayOfContexts)) {
					return reject('processExpressionBulk Error: Array of Expressions is required.');
				}

				if (!fieldId) {
					return reject('processExpressionBulk Error: fieldId is required.');
				}

				if (!settingSchemaName) {
					return reject('processExpressionBulk Error: settingSchemaName is required.');
				}

				// Validate arrayOfContexts
				arrayOfContexts.forEach(expressionContext => {
					if (!expressionContext.recordId || !expressionContext.tableSchemaName) {
						return reject('processExpressionBulk Error: One or more expressions failed to process. It should have recordId and tableSchemaName');
					}
				});

				// This expression could potentially have other contexts in them; check

				// Get information regarding the setting so we know if we need to send it forward to the bulk processor or not.
				let fieldSettings = attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, fieldId, parentComponentId) : FieldSettingsStore.getSettings(fieldId, parentComponentId);
				fieldSettings = fieldSettings || {};
				let expressionJSON = fieldSettings[settingSchemaName];
				let expressionObj = ObjectUtils.getObjFromJSON(expressionJSON);
				if(settingPath && settingPath.length) {
					settingPath.forEach(key => {
						let subObj = expressionObj[key];
						expressionObj = subObj && typeof subObj === 'string' ? JSON.parse(subObj) : subObj;
					});
				}

				if(!expressionObj) {
					return reject('processExpressionBulk Error: valid path to expressionObj is required.');
				}

				if(!expressionObj.generatedJavascript) {
					return resolve(arrayOfContexts.map(context => {
						return {
							recordId: context.recordId,
							value: ''
						};
					}));
				}

				// We don't even need to process static expressions
				if (expressionObj.optimizationScheme === 'static') {
					return resolve(arrayOfContexts.map(context => {
						return {
							recordId: context.recordId,
							value: (expressionObj.optimizationData && expressionObj.optimizationData.value ? expressionObj.optimizationData.value : '')
						};
					}));
				}

				// 'singular' type expressions will not change by row: they may be run once, and then returned
				if (expressionObj.optimizationScheme === 'singular') {
					return this.processExpression(
						arrayOfContexts && arrayOfContexts[0] ? arrayOfContexts[0].recordId : '',
						arrayOfContexts && arrayOfContexts[0] ? arrayOfContexts[0].tableSchemaName : '',
						expressionObj, recordSets, renderObj.renderId
					).then(results => {
						return resolve(arrayOfContexts.map(context => {
							return {
								recordId: context.recordId,
								value: results
							};
						}));
					}).catch(reject);
				}
				// Make the call to the new route: 

				// We need to see if this requires the location or not
				let locationPromise = Promise.resolve({
					lat: undefined,
					lng: undefined
				});
				// Check if we need the user location
				if(expressionObj.generatedJavascript.includes('citDev.getUserLocation')) {
					locationPromise = InterfaceActions.getUserLocation("full");
				}

				// We also need to make sure the namedContexts include any other information

				recordSets = Object.assign(RecordVariableUtils.buildNamedContexts(renderObj.renderId, undefined, expressionObj.generatedJavascript), recordSets);

				// Strip out any extra information which may have come in
				let newArrayOfContexts = arrayOfContexts.map(({recordId, tableSchemaName}) => ({recordId, tableSchemaName}));

				locationPromise
					.then((location) => {

						return socketFetcher('gw/expressionRunBulk-v2', JSON.stringify({
							fieldId: fieldId,
							settingSchemaName: settingSchemaName,
							settingPath: settingPath,
							expressionContexts: newArrayOfContexts,
							namedContexts: recordSets,
							parentComponentId: parentComponentId,
							parentComponentType: parentComponentType,
							screenSize: ContextStore.getResponsiveMode(),
							requestInfo: {location, userAgent: navigator.userAgent},
							attachmentId
						}));
					})
					.then(response => {
						if (response.responseCode === 200) {

							let results = response && response.response ? response.response.results : [];
							//resolve 
							return resolve(results);
						} else {
							// console.log('response', response);
							return reject(new Error(response.response));
						}
					})
					.catch(error => {
						return reject(error);
					});
			});
		},
		parseXml: parseXml
	},

	query: {
		/**
	 * Compares 2 queries to determine if they are the same or not. 
	 * @param {string} query1 
	 * @param {string} query2 
	 * @returns {Boolean}
	 */
		compareQueries(query1, query2) {
			let errorMessage = '';

			//2 queries are required 
			if (!query1 || !query2) {
				errorMessage = `compareQueries Error: Cannot process query1 of ${query1} vs. query2 of ${query2}`;
				console.error(errorMessage);
				return new Error(errorMessage);
			}

			
			//At first sight are they equal?
			if (query1 === query2) {
				return true;
			}
			
			// If either of them are objects, do a JSON.stringify to turn them back into strings for quick comparison
			if(typeof query1 === 'object' || typeof query2 === 'object') {
				let query1JSON = typeof query1 === 'object' ? JSON.stringify(query1) : query1;
				let query2JSON = typeof query2 === 'object' ? JSON.stringify(query2) : query2;
				if(query1JSON === query2JSON) {
					return true;
				}
			}

			let queriesToCompare = {};

			//Strip out uuid Values 
			[query1, query2].forEach((query, index) => {

				let queryName = 'query' + (index + 1);


				queriesToCompare[queryName] = {};
				let originalQuery = {};

				if(query) {
					//Parse query1 and 2
					try {
						originalQuery = typeof query === 'object' ? query : JSON.parse(query);
					} catch (error) {
						errorMessage = `compareQueries Error parsing query${index + 1} of ${query}`;
						console.error(errorMessage);
						return new Error(errorMessage);
					}
				}

				originalQuery = originalQuery || {}; // Somehow we had some '""' values for some queries.

				//Some queries accidentally have this property
				delete originalQuery.flushBlockly;

				//Strip out uuid Values 
				delete originalQuery['queryId'];

				// nodes
				let nodes = originalQuery['nodes'] || [],
					nodesObj = {},
					returnNode = originalQuery['returnNode'];

				if (originalQuery && nodes && nodes.length) {
					let nodePlaceholder = '';
					nodes.forEach((node, nodeIndex) => {

						//Handle ReturnNode && Node Values 
						if (node.nodeId === returnNode) {
							nodePlaceholder = 'returnNode';
						} else {
							nodePlaceholder = 'node' + (nodeIndex + 1);
						}

						//Keep track of placeholders and nodeIds
						nodesObj[node.nodeId] = nodePlaceholder;
						//Set the placeholder instead of the uuids values
						nodes[nodeIndex].nodeId = nodePlaceholder;

						//Delete information that is not supposed to be on the node
						delete node.roles;
						delete node.pluralName;
						delete node.singularName;
						delete node.icon;
						delete node.displayName;

						// filters
						if (node.filters) {
							let filters = node['filters'];
							filters.forEach((filter, filterIndex) => {
								//Get the UUID value of the filteredNode 
								let filterNode = filters[filterIndex]['filteredNode'];

								//Replace it with the Placeholder value found ind NodesObj
								filters[filterIndex]['filteredNode'] = nodesObj[filterNode];

								if (filters[filterIndex]['relatedNode']) {
									//Get the UUID value for the relatedNode 
									let relatedNode = filters[filterIndex]['relatedNode'];

									//Replace it with the Placeholder value found ind NodesObj
									filters[filterIndex]['relatedNode'] = nodesObj[relatedNode];
								}
							});
						}
					});
				};

				// filters
				if (originalQuery.filters) {
					let filters = originalQuery.filters;
					filters.forEach((filter, filterIndex) => {
						//Get the UUID value of the filteredNode 
						let filterNode = filters[filterIndex]['filteredNode'];

						//Replace it with the Placeholder value found ind NodesObj
						filters[filterIndex]['filteredNode'] = nodesObj[filterNode];

						if (filters[filterIndex]['relatedNode']) {
							//Get the UUID value for the relatedNode 
							let relatedNode = filters[filterIndex]['relatedNode'];

							//Replace it with the Placeholder value found ind NodesObj
							filters[filterIndex]['relatedNode'] = nodesObj[relatedNode];
						}
					});

					originalQuery.filters = filters;
				}

				// sorts
				let sorts = originalQuery['sorts'] || [];

				sorts.forEach((sort, sortIndex) => {
					//Get the UUID value of the nodeId for the  current sort 
					let sortNodeId = sorts[sortIndex]['nodeId'];

					//Replace it with the Placeholder value found ind NodesObj
					sorts[sortIndex]['nodeId'] = nodesObj[sortNodeId];
				});

				originalQuery['sorts'] = sorts;

				//returnNode: 
				delete originalQuery['returnNode'];

				//Stringify the Final Version of the query
				queriesToCompare[queryName] = JSON.stringify(originalQuery);

			});

			//Compare Queries
			return queriesToCompare['query1'] === queriesToCompare['query2'];
		},

		/**
		 * Take in an array of fields (of different formats) and return an array in a specific format for record-browse and 
		 * record-read that are only fields that match the tableSchemaName passed in.
		 * 
		 * @param {array} fields Fields to start with
		 * @param {string} tableSchemaName TSN that each field must match to be passed back.
		 * @return {Object[]} fields
		 */
		getFieldsForTableSchemaName: function (fields, tableSchemaName) {
			// If we didnt get an array, or couldn't make one above.. just ignore the FSN value.
			if (Array.isArray(fields)) {
				let invalidFieldFound = false;
				fields = fields.map((field) => {
					// fields was an array of strings (that are FSN's)...
					if (typeof field === 'string') {
						let fieldSchemaName = field;
						let fieldId = FieldStore.getFieldId(fieldSchemaName, tableSchemaName);
						let fieldObj = FieldStore.get(fieldId);
						if (fieldObj && fieldObj.tableSchemaName === tableSchemaName) {
							return {
								fieldSchemaName: fieldSchemaName,
								fieldType: fieldObj.fieldType,
								fieldId: fieldId
							};
						}
						// fields was an array of objects, each with a recordId property...
					} else if (field.recordId || field.fieldId) {
						let fieldId = field.recordId || field.fieldId;
						let fieldObj = FieldStore.get(fieldId);
						if (fieldObj && fieldObj.tableSchemaName === tableSchemaName) {
							return {
								fieldSchemaName: fieldObj.fieldSchemaName,
								fieldType: fieldObj.fieldType,
								fieldId: fieldId
							};
						}
						// fields was an array of objects, each with a fieldSchemaName and fieldType properties.
					} else if (field.fieldSchemaName && field.fieldType) {
						let fieldObj = FieldStore.get(field.fieldId);
						if (fieldObj && fieldObj.tableSchemaName === tableSchemaName) {
							return field;
						}
						// Unknown/invalid!
					} else {
						let message = 'Invalid field array element passed into citDev.fieldComponents.query.getFieldsForTableSchemaName in the fields array.';
						console.error(new Error(message), field);
						invalidFieldFound = true;
					}
					return false;
				}).filter((field) => {
					return field;
				});

				// if one of our fields was invalid, clear the fields array.
				if (invalidFieldFound) {
					fields = [];
				}
			} else {
				let message = 'Invalid field parameter passed into citDev.fieldComponents.query.getFieldsForTableSchemaName, it\'s not an array!.';
				console.warn(message, fields);
				fields = [];
			}

			return fields;
		},

		/**
		 * Examines the fields and returns any related fields configuration
		 * 
		 * @param {Object[]} fields
		 * @param {string} fields[].fieldId 
		 * @param {string} fields[].fieldType 
		 * @param {string} fields[].fieldSchemaName
		 * @param {string=} parentComponentId Used for field Settings lookup for overrides
		 * @param {string=} attachmentId Used for field Settings lookup for overrides
		 * 
		 * @returns {Object[]} 
		 */
		getRelatedFields: function (fields, parentComponentId, attachmentId) {
			let relatedFields = [];
			fields.forEach((field) => {
				if (field.fieldSchemaName && field.fieldType) {
					let fieldTypeObj = FieldTypeStore.get(field.fieldType);
					//If it is not a relationship data type skip it
					if (fieldTypeObj.dataType !== 'relationship') {
						return;
					}

					let settings = attachmentId ? FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, field.fieldId, parentComponentId) : FieldSettingsStore.getSettings(field.fieldId, parentComponentId);

					let relatedData = {};
					fieldTypeObj.settings.forEach((fieldTypeSetting) => {
						let fieldTypeSettingsObj = FieldStore.get(fieldTypeSetting.recordId);
						//if the setting is a field selector
						if (fieldTypeSettingsObj.fieldType === '0df9c7cd-0885-4158-8d92-6c2330bcac52' &&
							settings[fieldTypeSettingsObj.fieldSchemaName]) {
							let settingValue = settings[fieldTypeSettingsObj.fieldSchemaName];
							let fieldValue = null;
							//If the setting value starts with a curly brace then
							//it is a json string with a field id else it is
							//a fieldSchemaName (old data)
							if (settingValue[0] === '{') {
								let settingValueObj = ObjectUtils.getObjFromJSON(settingValue);
								if (settingValueObj.fieldId) {
									//If we have a field Id verify that the dataType is not none or relation
									let fieldObj = FieldStore.get(settingValueObj.fieldId);
									if(!fieldObj) {
										console.error('Unable to find field setting object %s in setting %s on field %s', settingValueObj.fieldId, fieldTypeSettingsObj.fieldSchemaName, field.fieldId);
										return;
									}
									let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType)
									if(!fieldTypeObj) {
										console.error('Unable to find field type %s in setting %s on field %s', fieldObj.fieldType, fieldTypeSettingsObj.fieldSchemaName, field.fieldId);
										return;
									}
									if (fieldTypeObj.dataType !== 'none' && fieldTypeObj.dataType !== 'relationship') {
										fieldValue = {
											fieldSchemaName: fieldObj.fieldSchemaName,
											fieldId: settingValueObj.fieldId,
											fieldType: fieldObj.fieldType
										};
									}
								}
							} else {
								fieldValue = settingValue;
							}

							if (fieldValue) {
								if (!relatedData.fields) {
									relatedData.fields = [];
								}
								relatedData.fields.push(fieldValue);
							}
						} else if (fieldTypeSettingsObj.fieldType === 'ce0dbfec-e9d9-4dc3-b39a-456eca2b5282' &&
							settings[fieldTypeSettingsObj.fieldSchemaName]) {
							//if this is a relationship selector
							let relationValue = ObjectUtils.getObjFromJSON(settings[fieldTypeSettingsObj.fieldSchemaName]);
							let relationObj = RelationshipStore.get(relationValue.relationId);

							if ((relationValue.direction === 'ltor' && relationObj.lCardinality === '1') ||
								(relationValue.direction === 'rtol' && relationObj.rCardinality === '1')) {
								relatedData.relation = {
									direction: relationValue.direction,
									relationId: relationValue.relationId,
									relationSchemaName: relationObj.relationSchemaName,
									rCardinality: relationObj.rCardinality,
									rTableSchemaName: relationObj.rTableSchemaName,
									lCardinality: relationObj.lCardinality,
									lTableSchemaName: relationObj.lTableSchemaName
								}
							}
						}
					});
					//If at this point we have fields and relation data then
					//filter the fields for duplicates or missing data
					//and add to the related fields
					if (relatedData.relation && relatedData.fields) {
						let targetTableSchemaName = null;
						if (relatedData.relation.direction === 'ltor') {
							targetTableSchemaName = relatedData.relation.rTableSchemaName;
						} else {
							targetTableSchemaName = relatedData.relation.lTableSchemaName;
						}
						//Fill in any missing data and remove any fields we
						//couldn't complete
						relatedData.fields = this.getFieldsForTableSchemaName(
							relatedData.fields, targetTableSchemaName);

						//Remove any duplicates
						let foundFieldIds = [];
						relatedData.fields = relatedData.fields.filter((field) => {
							if (foundFieldIds.includes(field.fieldId)) {
								return false;
							} else {
								foundFieldIds.push(field.fieldId);
								return true;
							}
						});

						relatedData.sourceFieldId = field.fieldId;
						relatedFields.push(relatedData);
					}

				} else {
					console.warn('Invalid FieldType: ' + field.fieldType + ' for ' + field.fieldSchemaName);
				}
			});

			return relatedFields;
		},

		/**
		 * Get a count of filters in the query JSON.
		 * @param {string} queryJSON 
		 * @return {integer}
		 */
		getFilterCount: function (queryJSON) {
			// Parsing queryJSON
			if (queryJSON) {
				let queryObj = ObjectUtils.getObjFromJSON(queryJSON);
				if (queryObj && queryObj.filters && queryObj.filters.length) {
					return queryObj.filters.length;
				}
			}
			return 0;
		},

		/**
		 * Get a count of joins in the query JSON.
		 * @param {string} queryJSON 
		 * @return {integer}
		 */
		getJoinCount: function (queryJSON) {
			// Parsing queryJSON
			if (queryJSON) {
				let queryObj = ObjectUtils.getObjFromJSON(queryJSON);
				if (queryObj && queryObj.nodes && queryObj.nodes.length) {
					return (queryObj.nodes.length - 1);
				}
			}
			return 0;
		},

		/**
		 * Get a count of sorts in the query JSON.
		 * @param {string} queryJSON 
		 * @return {integer}
		 */
		getSortCount: function (queryJSON) {
			// Parsing queryJSON
			if (queryJSON) {
				let queryObj = ObjectUtils.getObjFromJSON(queryJSON);
				if (queryObj && queryObj.sorts && queryObj.sorts.length) {
					return queryObj.sorts.length;
				}
			}
			return 0;
		},

		/**
		 * Get an object with the table and icon of the return from the query
		 * @param {string} queryJSON 
		 * @return {Object}
		 */
		getReturnTableAndIcon: function (queryJSON) {
			let returnObj = {};
			// Parsing queryJSON
			if (queryJSON) {
				let queryObj = ObjectUtils.getObjFromJSON(queryJSON);
				let settingTableSchemaName = this.getReturnTable(queryObj)
				let tableObj = TableStore.getByTableSchemaName(settingTableSchemaName);
				// get the tabele schema name from the query selector
				returnObj['tablePluralName'] = tableObj ? tableObj.pluralName : '';
				returnObj['tableIconName'] = tableObj ? tableObj.icon : '';
			}

			return returnObj;
		},

		/**
		 * Examine a queryObj (or queryJSONString) and return the table schema name of what the query returns.
		 * @param {mixed} queryObj Query Object or JSON String to examine
		 * @return {string} Table Schema Name of the table that this query returns
		 */
		getReturnTable: function (queryObj) {
			if (typeof queryObj === 'string' && queryObj.length) {
				try {
					queryObj = JSON.parse(queryObj);
				} catch (error) {
					console.warn('Error JSON parsing query string in citDev.fieldComponents.query.getReturnTable:', queryObj);
				}
			}

			let returnNode = queryObj && queryObj.nodes ? queryObj.nodes.find((element) => {
				return element.nodeId === queryObj.returnNode;
			}) : null;

			return returnNode ? returnNode.tableSchemaName : null;
		},

		/**
		 * Determine if the query provided has nodes and return the count or is essentially empty and return false
		 * @param {mixed} queryObj Query Object or String to examine
		 * @return {boolean} 
		 */
		hasNodes: function (queryObj) {
			if (typeof queryObj === 'string') {
				try {
					queryObj = JSON.parse(queryObj);
				} catch (error) {
					console.warn('Error JSON parsing query string in citDev.fieldComponents.query.hasNodes:', queryObj);
				}
			}

			if (queryObj.nodes) {
				return queryObj.nodes.length;
			} else {
				return false;
			}
		},

		getRelationshipQuery: function () {

		},
		/**
		 * Process a query's JSON, given Field Schema Names to return, and a potentially overridden page record to run against.
		 * 
		 * @param {string} queryJSON Query JSON of the query you want to run
		 * @param {Object[]|string} fields Array of field schema names or String (comma sep) of field schema names or an array of field objects with fieldtype to return.
		 * @param {string} renderId Render Id of the compoent to use for context
		 * @param {string} dataRecordId Record ID of the current dataRecordId of the componnt
		 * @param {string} dataTableSchemaName TSN of the current dataTableSchemaName of the componnt
		 * @returns {Promise.<Object[]>} array A Promise, which resolves with Rows resulting from your query
		 */
		processQuery: function (queryJSON, fields, renderId, dataRecordId, dataTableSchemaName) {
			// Temporary override for debugging purposes
			return new Promise((resolve, reject) => {
				// If we were given FSN's and they are a string, then try and make an array out of them.
				if (typeof fields === 'string') {
					fields = fields.split(',');
				}

				// No queryJSON?  No query!
				if (!queryJSON) {
					return resolve([]);
				}

				// Find the return node's table schema name
				let tableSchemaName = this.getReturnTable(queryJSON);
				if (!tableSchemaName) {
					return resolve([]);
				}

				let recordSets = RecordVariableUtils.buildNamedContexts(renderId, queryJSON);

				fields = this.getFieldsForTableSchemaName(fields, tableSchemaName);

				// We need to see if this requires the location or not
				let queryString = typeof queryJSON === 'string' ? queryJSON : JSON.stringify(queryJSON);
				let locationPromise = Promise.resolve({
					lat: undefined,
					lng: undefined
				});
				// Check if we need the user location
				if(queryString.includes('citDev.getUserLocation')) {
					locationPromise = InterfaceActions.getUserLocation("full");
				}

				locationPromise.then(location => {
					// Setup the parameters for our callService call.
					let request = {
						tableSchemaName: tableSchemaName,
						fields: fields,
						query: queryJSON,
						recordSets: recordSets,
						browserStorage: BrowserStorageStore.getStateJS(),
						requestInfo: {location, userAgent: navigator.userAgent}
					};
					return socketFetcher('gw/recordBrowse-v4', JSON.stringify(request));
				})
				.then(function (jsonResponse) {
					// Check our response and see if we got something in the success range.
					if (jsonResponse.responseCode >= 200 && jsonResponse.responseCode < 300) {
						// setup the rows array from the response.
						let rows = Object.keys(jsonResponse.response.records[tableSchemaName]).map(function (recordKey) {
							// Build our record for the rows array
							let record = jsonResponse.response.records[tableSchemaName][recordKey];
							record['tableSchemaName'] = tableSchemaName;
							record['recordId'] = recordKey;
							// Return our record for the rows array
							return record;
						});

						// Load our response into the record store for later use.
						RecordActions.onDataLoaded(jsonResponse.response.records);

						// And resolve the rows from our promise.
						return resolve(rows);
					} else if (jsonResponse.responseCode < 500) {
						console.warn(jsonResponse.response);
						resolve([]);
					} else {
						// If we didn't get a success range response.. reject.
						var error = new Error(jsonResponse.response);
						return reject(error);
					}
					// If our fetch call just flat out failed, then reject.
				})
				.catch(function (error) {
					return reject(error.message);
				});
			});
		},

		/**
		 * Process a query's JSON, given Field Schema Names to return, and a 
		 * potentially overridden page record to run against.   This version also
		 * returns additional data about related records and fields that use relation data
		 * 
		 * @param {string} queryJSON Query JSON of the query you want to run
		 * @param {string|Object[]} fields Array of field schema names or String (comma sep) of field schema names or an array of field objects with fieldtype to return.
		 * @param {string} renderObj Dummy render component for context lookup
		 * @param {string} [dataRecordId] Record ID of the current dataRecordId of the componnt
		 * @param {string} [dataTableSchemaName] TSN of the current dataTableSchemaName of the componnt
		 * @returns {Promise.<{rows: Object[], relatedFields: Object[], relatedRecords: Object, relations: Object}>} A Promise, which resolves with Rows resulting from your query
		 */
		processQueryWithoutRenderEntry: function (queryJSON, fields, renderObj, recordSets, dataRecordId, dataTableSchemaName, omitRelationships) {
			// Temporary override for debugging purposes
			return new Promise((resolve, reject) => {
				// No queryJSON?  No query!
				if (!queryJSON) {
					return resolve({rows: []});
				}

				// Find the return node's table schema name
				let tableSchemaName = this.getReturnTable(queryJSON);
				if (!tableSchemaName) {
					return resolve({rows: []});
				}

				fields = fields.map(fieldId => {
					if(typeof fieldId === 'string') {
						let fieldObj = FieldStore.get(fieldId);
						if(!fieldObj) {
							console.warn('No fieldObj found for field %s', fieldId);
						} else {
							fieldObj.fieldId = fieldId;
						}
						return fieldObj;
					} else if(fieldId && fieldId.fieldId) {
						Object.assign(fieldId, FieldStore.get(fieldId.fieldId));
						return fieldId;
					} else {
						return fieldId;
					}
				})
				.filter(fieldObj => {
					if(!fieldObj) {
						return false;
					}
					if(fieldObj.tableSchemaName !== tableSchemaName) {
						console.warn('Field lookup included field not on table %s. Field was: ', tableSchemaName, fieldObj);
						return false;
					} else {
						return true;
					}
				});

				if (typeof dataRecordId === 'undefined' || dataRecordId === null) {
					dataRecordId = renderObj.dataRecordId;
					dataTableSchemaName = renderObj.dataTableSchemaName;
				}

				// let componentId = renderObj.componentId ? renderObj.componentId : null;


				// Used for record browse bulk calls after initial call
				// (Used to populate record store with dyn. select values)
				let relationshipsForLookup = [];
				if(!omitRelationships) {
					fields.forEach(fieldObj => {
						let fieldId = fieldObj ? fieldObj.recordId : undefined;
						let fieldTypeObj = fieldObj ? FieldTypeStore.get(fieldObj.fieldType) : {};
						if (fieldTypeObj.dataType === 'relationship') {
							let settings = fieldId ? FieldSettingsStore.getSettings(fieldId) : {};
							let selectListOfObj = settings.selectListOf && typeof settings.selectListOf === 'string'
								? ObjectUtils.getObjFromJSON(settings.selectListOf)
								: settings.selectListOf;
							let valueLookupQuery = settings.relationshipSelector ? toExport.query.getValueLookupQuery(fieldId, settings.relationshipSelector, selectListOfObj) : '';
							if (valueLookupQuery) {
								let lookupTableSchemaName = toExport.query.getReturnTable(settings.selectListOf);
								let viewMode = settings.viewMode ? toExport.UIUtils.convertFieldSelectorToFieldSchemaName(settings.viewMode, lookupTableSchemaName) : '';
								let editMode = settings.editMode ? toExport.UIUtils.convertFieldSelectorToFieldSchemaName(settings.editMode, lookupTableSchemaName) : '';
								relationshipsForLookup.push({
									fieldSchemaName: fieldObj.fieldSchemaName,
									tableSchemaName: lookupTableSchemaName,
									fields: [viewMode, editMode],
									query: valueLookupQuery
								});
							}
						}
					});
				}

				// Used in initial record browse call itself
				// Ignoring this for now because it doesn't really come up in our circumstances
				// and will be manually provided later if it does
				// let relatedFields = this.getRelatedFields(fields, componentId);
				
				// We need to see if this requires the location or not
				let queryString = typeof queryJSON === 'string' ? queryJSON : JSON.stringify(queryJSON);
				let locationPromise = Promise.resolve({
					lat: undefined,
					lng: undefined
				});
				// Check if we need the user location
				if(queryString.includes('citDev.getUserLocation')) {
					locationPromise = InterfaceActions.getUserLocation("full");
				}

				let requestPromise = locationPromise.then(location => {
					// Setup the parameters for our callService call.
					// Setup the parameters for our callService call.
					let request = {
						tableSchemaName: tableSchemaName,
						fields: fields,
						relatedFields: [],
						query: queryJSON,
						currentContextRecordObj: {
							recordId: dataRecordId,
							tableSchemaName: dataTableSchemaName,
						},
						recordSets: recordSets,
						browserStorage: BrowserStorageStore.getStateJS(),
						requestInfo: {location, userAgent: navigator.userAgent}
					};

					// Removing all escaping slashes from query so as to catch cases like 'namedContexts[\"pagePageRecord\"], etc.
					let unescapedQuery = queryString ? queryString.replace(/\\/g, '') : '';
					let hasPageInterfaceValues = (unescapedQuery.includes('namedContexts["pagePageRecord"]'));
					let hasCurrentRecordInterfaceValues = (unescapedQuery.includes('namedContexts["pageCurrentRecord"]'));

					// See if we need to include any recordStoreRecords
					let recordStoreRecords = {};
					
					if (hasPageInterfaceValues) {
						let tsn = recordSets && recordSets['page'] && recordSets['page'][0] ?
							recordSets['page'][0].tableSchemaName :
							'';
						let recordId = recordSets && recordSets['page'] && recordSets['page'][0] ?
							recordSets['page'][0].recordId :
							'';
						if (tsn && recordId) {
							recordStoreRecords[tsn] = {
								[recordId]: RecordStore.getRecord(tsn, recordId)
							};
						}
					}
					if (hasCurrentRecordInterfaceValues) {
						let tsn = recordSets && recordSets['startingContext'] && recordSets['startingContext'][0] ?
							recordSets['startingContext'][0].tableSchemaName :
							'';
						let recordId = recordSets && recordSets['startingContext'] && recordSets['startingContext'][0] ?
							recordSets['startingContext'][0].recordId :
							'';
						if (tsn && recordId) {
							recordStoreRecords[tsn] = {
								[recordId]: RecordStore.getRecord(tsn, recordId)
							};
						}
					}

					request.recordStoreRecords = recordStoreRecords;

					return socketFetcher('gw/recordBrowse-v4', JSON.stringify(request));
				});

				let lookupPromises = [requestPromise];
				if(relationshipsForLookup.length) {
					lookupPromises = lookupPromises.concat(relationshipsForLookup.map(request => {
						return toExport.query.processQueryBulk(request.query, request.fields, renderObj.renderId, null, request.tableSchemaName, queryJSON).then(result => {
							result.fieldSchemaName = request.fieldSchemaName;
							result.tableSchemaName = request.tableSchemaName;
							return result;
						});
					}));
				}

				Promise.all(lookupPromises).then(([jsonResponse, ...postprocessingResults]) => {
					// Check our response and see if we got something in the success range.
					if (jsonResponse.responseCode >= 200 && jsonResponse.responseCode < 300) {
						// setup the rows array from the response.
						let rows = Object.keys(jsonResponse.response.records[tableSchemaName]).map(function (recordKey) {
							// Build our record for the rows array
							let record = Object.assign({}, jsonResponse.response.records[tableSchemaName][recordKey]);
							record['tableSchemaName'] = tableSchemaName;
							record['recordId'] = recordKey;
							// Return our record for the rows array
							return record;
						});

						//Combine the related records to the records and update the store
						if (jsonResponse.response.relatedRecords) {
							Object.keys(jsonResponse.response.relatedRecords).forEach((relatedTableSchemaName) => {
								if (relatedTableSchemaName === tableSchemaName) {
									//Merge the data
									Object.keys(jsonResponse.response.relatedRecords[relatedTableSchemaName]).forEach((recordKey) => {
										if (jsonResponse.response.records[relatedTableSchemaName][recordKey]) {
											Object.assign(
												jsonResponse.response.records[relatedTableSchemaName][recordKey],
												jsonResponse.response.relatedRecords[relatedTableSchemaName][recordKey]
											);
										} else {
											jsonResponse.response.records[relatedTableSchemaName][recordKey] =
												jsonResponse.response.relatedRecords[relatedTableSchemaName][recordKey];
										}
									})
								} else {
									jsonResponse.response.records[relatedTableSchemaName] =
										jsonResponse.response.relatedRecords[relatedTableSchemaName]
								}
							})
						}

						

						if(postprocessingResults && postprocessingResults.length) {
							postprocessingResults.forEach(result => {
								if(result.contextMap) {
									Object.keys(result.contextMap).forEach(recordId => {
										let records = result.contextMap[recordId] ? result.contextMap[recordId].map(relatedRecordId => {
											return {
												recordId: result.records &&  result.records[result.tableSchemaName] && result.records[result.tableSchemaName][relatedRecordId] && result.records[result.tableSchemaName][relatedRecordId].recordId ?
													result.records[result.tableSchemaName][relatedRecordId].recordId :
													result.records[result.tableSchemaName][relatedRecordId],
												tableSchemaName: result.tableSchemaName
											};
										}) : [];
										let startingRelatedRecordsJSON = JSON.stringify(records);
										jsonResponse.response.records[tableSchemaName][recordId] = jsonResponse.response.records[tableSchemaName][recordId] ? jsonResponse.response.records[tableSchemaName][recordId] : {};
										jsonResponse.response.records[tableSchemaName][recordId][result.fieldSchemaName] = JSON.stringify({
											startingRelatedRecordsJSON,
											newRecordJSON: startingRelatedRecordsJSON
										});
									});
								}
							})
						}

						// Load our response into the record store for later use.
						RecordActions.onDataLoaded(jsonResponse.response.records);
	
						// And resolve the rows from our promise.
						return resolve({
							'rows': rows,
							'relatedRecords': jsonResponse.response.relatedRecords,
							// 'relatedFields': relatedFields,
							"relatedFields": [],
							'relations': jsonResponse.response.relations
						});

					} else if (jsonResponse.responseCode < 500) {
						console.warn(jsonResponse.response);
						resolve({
							'rows': []
						});
					} else {
						// If we didn't get a success range response.. reject.
						var error = new Error(jsonResponse.response);
						return reject(error);
					}
					// If our fetch call just flat out failed, then reject.
				}).catch(function (error) {
					console.error('Error processing query', queryJSON, error);
					return reject(error.message);
				});
			});
		},

		/**
		 * Process a query's JSON, given Field Schema Names to return, and a 
		 * potentially overridden page record to run against.   This version also
		 * returns additional data about related records and fields that use relation data
		 * 
		 * @param {string} queryJSON Query JSON of the query you want to run
		 * @param {string|Object[]} fields Array of field schema names or String (comma sep) of field schema names or an array of field objects with fieldtype to return.
		 * @param {string} renderId Render Id of the component for context lookup
		 * @param {string} [dataRecordId] Record ID of the current dataRecordId of the componnt
		 * @param {string} [dataTableSchemaName] TSN of the current dataTableSchemaName of the componnt
		 * @returns {Promise.<{rows: Object[], relatedFields: Object[], relatedRecords: Object, relations: Object}>} A Promise, which resolves with Rows resulting from your query
		 */
		processQueryV2: function (queryJSON, fields, renderId, dataRecordId, dataTableSchemaName, omitRelationships) {
			// Temporary override for debugging purposes
			return new Promise((resolve, reject) => {
				// If we were given FSN's and they are a string, then try and make an array out of them.
				if (typeof fields === 'string') {
					fields = fields.split(',');
				}

				// No queryJSON?  No query!
				if (!queryJSON) {
					return resolve({rows: []});
				}

				// Find the return node's table schema name
				let tableSchemaName = this.getReturnTable(queryJSON);
				if (!tableSchemaName) {
					return resolve({rows: []});
				}

				let recordSets = RecordVariableUtils.buildNamedContexts(renderId, queryJSON, undefined, dataTableSchemaName, dataRecordId);
				let renderObj = RenderStore.get(renderId);

				if(!renderObj) {
					console.warn('No renderObj found for renderId %s. Skipping query lookup', renderId);
					return resolve({rows: []});
				}

				if (typeof dataRecordId === 'undefined' || dataRecordId === null) {
					dataRecordId = renderObj.dataRecordId;
					dataTableSchemaName = renderObj.dataTableSchemaName;
				}

				let componentId = renderObj.componentId ? renderObj.componentId : null;

				fields = this.getFieldsForTableSchemaName(fields, tableSchemaName);


				// Used for record browse bulk calls after initial call
				// (Used to populate record store with dyn. select values)
				let relationshipsForLookup = [];
				if(!omitRelationships) {
					fields.forEach(fieldObj => {
						let fieldId = fieldObj ? fieldObj.fieldId || fieldObj.recordId : undefined;
						let fieldTypeObj = fieldObj ? FieldTypeStore.get(fieldObj.fieldType) : {};
						if (fieldTypeObj.dataType === 'relationship') {
							let settings = fieldId ? FieldSettingsStore.getSettings(fieldId) : {};
							let selectListOfObj = settings.selectListOf && typeof settings.selectListOf === 'string'
								? ObjectUtils.getObjFromJSON(settings.selectListOf)
								: settings.selectListOf;
							let valueLookupQuery = settings.relationshipSelector ? toExport.query.getValueLookupQuery(fieldId, settings.relationshipSelector, selectListOfObj) : '';
							if (valueLookupQuery) {
								let lookupTableSchemaName = toExport.query.getReturnTable(settings.selectListOf);
								let viewMode = settings.viewMode ? toExport.UIUtils.convertFieldSelectorToFieldSchemaName(settings.viewMode, lookupTableSchemaName) : '';
								let editMode = settings.editMode ? toExport.UIUtils.convertFieldSelectorToFieldSchemaName(settings.editMode, lookupTableSchemaName) : '';
								relationshipsForLookup.push({
									fieldSchemaName: fieldObj.fieldSchemaName,
									tableSchemaName: lookupTableSchemaName,
									fields: [viewMode, editMode],
									query: valueLookupQuery
								});
							}
						}
					});
				}

				// Used in initial record browse call itself
				let relatedFields = this.getRelatedFields(fields, componentId);
				
				// We need to see if this requires the location or not
				let queryString = typeof queryJSON === 'string' ? queryJSON : JSON.stringify(queryJSON);
				let locationPromise = Promise.resolve({
					lat: undefined,
					lng: undefined
				});
				// Check if we need the user location
				if(queryString.includes('citDev.getUserLocation')) {
					locationPromise = InterfaceActions.getUserLocation("full");
				}

				let requestPromise = locationPromise.then(location => {
					// Setup the parameters for our callService call.
					// Setup the parameters for our callService call.
					let request = {
						tableSchemaName: tableSchemaName,
						fields: fields,
						relatedFields: relatedFields,
						query: queryJSON,
						currentContextRecordObj: {
							recordId: dataRecordId,
							tableSchemaName: dataTableSchemaName,
						},
						recordSets: recordSets,
						browserStorage: BrowserStorageStore.getStateJS(),
						requestInfo: {location, userAgent: navigator.userAgent}
					};

					// Removing all escaping slashes from query so as to catch cases like 'namedContexts[\"pagePageRecord\"], etc.
					let unescapedQuery = queryString ? queryString.replace(/\\/g, '') : '';
					let hasPageInterfaceValues = (unescapedQuery.includes('namedContexts["pagePageRecord"]'));
					let hasCurrentRecordInterfaceValues = (unescapedQuery.includes('namedContexts["pageCurrentRecord"]'));

					// See if we need to include any recordStoreRecords
					let recordStoreRecords = {};
					
					if (hasPageInterfaceValues) {
						let tsn = recordSets && recordSets['page'] && recordSets['page'][0] ?
							recordSets['page'][0].tableSchemaName :
							'';
						let recordId = recordSets && recordSets['page'] && recordSets['page'][0] ?
							recordSets['page'][0].recordId :
							'';
						if (tsn && recordId) {
							recordStoreRecords[tsn] = {
								[recordId]: RecordStore.getRecord(tsn, recordId)
							};
						}
					}
					if (hasCurrentRecordInterfaceValues) {
						let tsn = recordSets && recordSets['startingContext'] && recordSets['startingContext'][0] ?
							recordSets['startingContext'][0].tableSchemaName :
							'';
						let recordId = recordSets && recordSets['startingContext'] && recordSets['startingContext'][0] ?
							recordSets['startingContext'][0].recordId :
							'';
						if (tsn && recordId) {
							recordStoreRecords[tsn] = {
								[recordId]: RecordStore.getRecord(tsn, recordId)
							};
						}
					}

					request.recordStoreRecords = recordStoreRecords;

					return socketFetcher('gw/recordBrowse-v4', JSON.stringify(request));
				});

				let lookupPromises = [requestPromise];
				if(relationshipsForLookup.length) {
					lookupPromises = lookupPromises.concat(relationshipsForLookup.map(request => {
						return toExport.query.processQueryBulk(request.query, request.fields, renderId, null, request.tableSchemaName, queryJSON).then(result => {
							result.fieldSchemaName = request.fieldSchemaName;
							result.tableSchemaName = request.tableSchemaName;
							return result;
						});
					}));
				}

				Promise.all(lookupPromises).then(([jsonResponse, ...postprocessingResults]) => {
					// Check our response and see if we got something in the success range.
					if (jsonResponse.responseCode >= 200 && jsonResponse.responseCode < 300) {
						// setup the rows array from the response.
						let rows = Object.keys(jsonResponse.response.records[tableSchemaName]).map(function (recordKey) {
							// Build our record for the rows array
							let record = Object.assign({}, jsonResponse.response.records[tableSchemaName][recordKey]);
							record['tableSchemaName'] = tableSchemaName;
							record['recordId'] = recordKey;
							// Return our record for the rows array
							return record;
						});

						//Combine the related records to the records and update the store
						if (jsonResponse.response.relatedRecords) {
							Object.keys(jsonResponse.response.relatedRecords).forEach((relatedTableSchemaName) => {
								if (relatedTableSchemaName === tableSchemaName) {
									//Merge the data
									Object.keys(jsonResponse.response.relatedRecords[relatedTableSchemaName]).forEach((recordKey) => {
										if (jsonResponse.response.records[relatedTableSchemaName][recordKey]) {
											Object.assign(
												jsonResponse.response.records[relatedTableSchemaName][recordKey],
												jsonResponse.response.relatedRecords[relatedTableSchemaName][recordKey]
											);
										} else {
											jsonResponse.response.records[relatedTableSchemaName][recordKey] =
												jsonResponse.response.relatedRecords[relatedTableSchemaName][recordKey];
										}
									})
								} else {
									jsonResponse.response.records[relatedTableSchemaName] =
										jsonResponse.response.relatedRecords[relatedTableSchemaName]
								}
							})
						}

						

						if(postprocessingResults && postprocessingResults.length) {
							postprocessingResults.forEach(result => {
								if(result.contextMap) {
									Object.keys(result.contextMap).forEach(recordId => {
										let records = result.contextMap[recordId] ? result.contextMap[recordId].map(relatedRecordId => {
											return {
												recordId: result.records &&  result.records[result.tableSchemaName] && result.records[result.tableSchemaName][relatedRecordId] && result.records[result.tableSchemaName][relatedRecordId].recordId ?
													result.records[result.tableSchemaName][relatedRecordId].recordId :
													result.records[result.tableSchemaName][relatedRecordId],
												tableSchemaName: result.tableSchemaName
											};
										}) : [];
										let startingRelatedRecordsJSON = JSON.stringify(records);
										jsonResponse.response.records[tableSchemaName][recordId] = jsonResponse.response.records[tableSchemaName][recordId] ? jsonResponse.response.records[tableSchemaName][recordId] : {};
										jsonResponse.response.records[tableSchemaName][recordId][result.fieldSchemaName] = JSON.stringify({
											startingRelatedRecordsJSON,
											newRecordJSON: startingRelatedRecordsJSON
										});
									});
								}
							})
						}

						// Load our response into the record store for later use.
						RecordActions.onDataLoaded(jsonResponse.response.records);
	
						// And resolve the rows from our promise.
						return resolve({
							'rows': rows,
							'relatedRecords': jsonResponse.response.relatedRecords,
							'relatedFields': relatedFields,
							'relations': jsonResponse.response.relations
						});

					} else if (jsonResponse.responseCode < 500) {
						console.warn(jsonResponse.response);
						resolve({
							'rows': []
						});
					} else {
						// If we didn't get a success range response.. reject.
						var error = new Error(jsonResponse.response);
						return reject(error);
					}
					// If our fetch call just flat out failed, then reject.
				}).catch(function (error) {
					console.error('Error processing query', queryJSON, error);
					return reject(error.message);
				});
			});
		},

		processQueryBulk: function (queryJSON, fields, renderId, dataRecordIds, dataTableSchemaName, dataQueryJSON) {
			// Temporary override for debugging purposes
			return new Promise((resolve, reject) => {
				// If we were given FSN's and they are a string, then try and make an array out of them.
				if (typeof fields === 'string') {
					fields = fields.split(',');
				}

				if (typeof dataRecordIds === 'string') {
					dataRecordIds = dataRecordIds.split(',');
				}

				// No queryJSON?  No query!
				if (!queryJSON) {
					console.warn('Missing queryJSON');
					return resolve([]);
				}

				// Find the return node's table schema name
				let tableSchemaName = this.getReturnTable(queryJSON);
				if (!tableSchemaName) {
					console.warn('Missing query return table');
					return resolve([]);
				}

				let recordSets = RecordVariableUtils.buildNamedContexts(renderId, queryJSON);
				if (dataQueryJSON) {
					recordSets = Object.assign(RecordVariableUtils.buildNamedContexts(renderId, dataQueryJSON), recordSets);
				}
				let currentContextRecordObjs = null;
				if (dataRecordIds && Array.isArray(dataRecordIds)) {
					currentContextRecordObjs = dataRecordIds.map((element) => {
						if (element.recordId && element.tableSchemaName) {
							return element;
						} else {
							return {
								recordId: element,
								tableSchemaName: dataTableSchemaName
							};
						}
					})
				}
				fields = this.getFieldsForTableSchemaName(fields, tableSchemaName);

				// We need to see if this requires the location or not
				let queryString = typeof queryJSON === 'string' ? queryJSON : JSON.stringify(queryJSON);
				let locationPromise = Promise.resolve({
					lat: undefined,
					lng: undefined
				});
				// Check if we need the user location
				if(queryString.includes('citDev.getUserLocation')) {
					locationPromise = InterfaceActions.getUserLocation("full");
				}

				locationPromise.then(location => {

					// Setup the parameters for our callService call.
					let request = {
						tableSchemaName: tableSchemaName,
						fields: fields,
						query: queryJSON,
						currentContextQuery: typeof dataQueryJSON === 'object' ? JSON.stringify(dataQueryJSON) : dataQueryJSON,
						currentContextRecordObjs: currentContextRecordObjs,
						recordSets: recordSets,
						browserStorage: BrowserStorageStore.getStateJS(),
						requestInfo: {location, userAgent: navigator.userAgent}
					};

					return socketFetcher('gw/recordBrowseBulk-v2', JSON.stringify(request));
				})
				.then(function (jsonResponse) {
					// Check our response and see if we got something in the success range.
					if (jsonResponse.responseCode >= 200 && jsonResponse.responseCode < 300) {
						// Load our response into the record store for later use.
						RecordActions.onDataLoaded(jsonResponse.response.records);

						// And resolve the rows from our promise.
						return resolve(jsonResponse.response);
					} else if (jsonResponse.responseCode < 500) {
						console.warn(jsonResponse.response);
						resolve([]);
					} else {
						// If we didn't get a success range response.. reject.
						var error = new Error(jsonResponse.response);
						return reject(error);
					}
					// If our fetch call just flat out failed, then reject.
				})
				.catch(function (error) {
					return reject(error.message);
				});
			});
		},

		/**
		 * Similar to process query bulk but does not rely
		 * on the render store entry already existing
		 * @param {string | object} queryJSON 
		 * @param {array} fields Array of fields
		 * @param {object} recordSets The recordSets to use for this
		 * @param {string | array} dataRecordIds The data record IDs for the context
		 * @param {string} dataTableSchemaName The TSN of the data
		 * @param {string} dataQueryJSON The current context query
		 * @returns 
		 */
		processQueryBulkWithoutRenderEntry: function(queryJSON, fields, recordSets, dataRecordIds, dataTableSchemaName, dataQueryJSON) {
			return new Promise((resolve, reject) => {
				// If we were given FSN's and they are a string, then try and make an array out of them.
				if (typeof fields === 'string') {
					fields = fields.split(',');
				}

				if (typeof dataRecordIds === 'string') {
					dataRecordIds = dataRecordIds.split(',');
				}

				// No queryJSON?  No query!
				if (!queryJSON) {
					console.warn('Missing queryJSON');
					return resolve([]);
				}

				// Find the return node's table schema name
				let tableSchemaName = this.getReturnTable(queryJSON);
				if (!tableSchemaName) {
					console.warn('Missing query return table');
					return resolve([]);
				}

				// I do not believe this is actually needed for these uses
				// let currentContextRecordObjs = null;
				// if (dataRecordIds && Array.isArray(dataRecordIds)) {
				// 	currentContextRecordObjs = dataRecordIds.map((element) => {
				// 		if (element.recordId && element.tableSchemaName) {
				// 			return element;
				// 		} else {
				// 			return {
				// 				recordId: element,
				// 				tableSchemaName: dataTableSchemaName
				// 			};
				// 		}
				// 	})
				// }
				fields = fields
					? fields.map(fieldId => {
						let fieldObj = typeof fieldId === 'string'
							? FieldStore.get(fieldId)
							: fieldId;
						if(fieldObj && !fieldObj.fieldId) {
							fieldObj.fieldId = fieldObj.recordId;
						}
						return fieldObj;
					})
					: fields;

				// We need to see if this requires the location or not
				let queryString = typeof queryJSON === 'string' ? queryJSON : JSON.stringify(queryJSON);
				let locationPromise = Promise.resolve({
					lat: undefined,
					lng: undefined
				});
				// Check if we need the user location
				if(queryString.includes('citDev.getUserLocation')) {
					locationPromise = InterfaceActions.getUserLocation("full");
				}

				locationPromise
					.then(location => {

						// Setup the parameters for our callService call.
						let request = {
							tableSchemaName: tableSchemaName,
							fields: fields,
							query: queryJSON,
							currentContextQuery: typeof dataQueryJSON === 'object' ? JSON.stringify(dataQueryJSON) : dataQueryJSON,
							currentContextRecordObjs: null,
							recordSets: recordSets,
							browserStorage: BrowserStorageStore.getStateJS(),
							requestInfo: {location, userAgent: navigator.userAgent}
						};

						return socketFetcher('gw/recordBrowseBulk-v2', JSON.stringify(request));
					})
					.then(function (jsonResponse) {
						// Check our response and see if we got something in the success range.
						if (jsonResponse.responseCode >= 200 && jsonResponse.responseCode < 300) {
							// Load our response into the record store for later use.
							RecordActions.onDataLoaded(jsonResponse.response.records);

							// And resolve the rows from our promise.
							return resolve(jsonResponse.response);
						} else if (jsonResponse.responseCode < 500) {
							console.warn(jsonResponse.response);
							resolve([]);
						} else {
							// If we didn't get a success range response.. reject.
							var error = new Error(jsonResponse.response);
							return reject(error);
						}
						// If our fetch call just flat out failed, then reject.
					})
					.catch(function (error) {
						return reject(error.message);
					});
			});
		},

		/**
		 * Process a RecordId, given Field Schema Names to return.
		 * 
		 * @param {string} dataRecordId RecordId to get data for.
		 * @param {string} dataTableSchemaName TSN for the Record to get data for.
		 * @param {mixed} fields Array of field schema names or String (comma sep) of field schema names or an array of field objects with fieldtype to return.
		 * @returns array A Promise, which resolves with Rows resulting from your query
		 */
		processRecord: function (dataRecordId, dataTableSchemaName, fields) {
			return new Promise((resolve, reject) => {
				// If we were given FSN's and they are a string, then try and make an array out of them.
				if (typeof fields === 'string') {
					fields = fields.split(',');
				}

				// No queryJSON?  No query!
				if (!dataRecordId || !dataTableSchemaName) {
					return resolve([]);
				}

				fields = this.getFieldsForTableSchemaName(fields, dataTableSchemaName);

				// Setup the parameters for our callService call.
				let request = {
					recordId: dataRecordId,
					tableSchemaName: dataTableSchemaName,
					fields: fields,
				};

				socketFetcher('gw/recordRead-v4', JSON.stringify(request)).then(function (jsonResponse) {
					// Check our response and see if we got something in the success range.
					if (jsonResponse.responseCode >= 200 && jsonResponse.responseCode < 300) {
						// setup the rows array from the response.
						let rows = Object.keys(jsonResponse.response[dataTableSchemaName]).map(function (recordKey) {
							// Build our record for the rows array
							let record = jsonResponse.response[dataTableSchemaName][recordKey];
							record['tableSchemaName'] = dataTableSchemaName;
							record['recordId'] = recordKey;
							// Return our record for the rows array
							return record;
						});

						// Load our response into the record store for later use.
						RecordActions.onDataLoaded(jsonResponse.response);

						// And resolve the rows from our promise.
						return resolve(rows);
					} else if (jsonResponse.responseCode < 500) {
						console.warn(jsonResponse.response);
						resolve([]);
					} else {
						// If we didn't get a success range response.. reject.
						var error = new Error(jsonResponse.response);
						return reject(error);
					}
					// If our fetch call just flat out failed, then reject.
				}).catch(function (error) {
					return reject(error.message);
				});
			});
		},
		/**
		 * Returns a boolean for if the query is valid or not
		 * 
		 * @param {string|Object} query The JSON encoded string or Object representation of a query
		 * @returns {boolean} 
		 */
		queryIsValid: function (query) {
			let isValid = false;
			let type = typeof query;
			if (query && ((type === 'string' && query !== '{}') || type === 'object')) {
				//if we pass a basic falsy check try and parse the string into an object
				let queryObject = type === 'string' ? ObjectUtils.getObjFromJSON(query) : query;

				//Check for the existance of the nodes and the return node property
				if (queryObject.nodes && queryObject.nodes.length && queryObject.returnNode) {
					//we are not currently checking if the return node exists in the nodes
					//list but we could add that check
					isValid = true;
				}
			}

			return isValid;
		},
		/**
		 * Returns if a record id exists inside the results of the record set
		 * 
		 * @param {string|Object} query 
		 * @param {boolean} recordId 
		 */
		recordExistsInRecordSet: function (query, renderId, dataRecordId, recordSets) {
			// Setup the parameters for our callService call.
			recordSets = recordSets || RecordVariableUtils.buildNamedContexts(renderId, query);
			let renderObj = RenderStore.get(renderId);

			if (renderObj && (typeof dataRecordId === 'undefined' || dataRecordId === null)) {
				dataRecordId = renderObj.dataRecordId;
			}

			// We need to see if this requires the location or not
			let queryString = typeof queryJSON === 'string' ? query : JSON.stringify(query);
			let locationPromise = Promise.resolve({
				lat: undefined,
				lng: undefined
			});
			// Check if we need the user location
			if(queryString.includes('citDev.getUserLocation')) {
				locationPromise = InterfaceActions.getUserLocation("full");
			}

			return locationPromise
				.then(location => {
					let request = {
						query: query,
						recordSets: recordSets,
						browserStorage: BrowserStorageStore.getStateJS(),
						recordExists: dataRecordId,
						requestInfo: {location, userAgent: navigator.userAgent}
					};
					return socketFetcher('gw/queryProcessor-v2', JSON.stringify(request))
				})
				.then((result) => {
					return result.responseCode === 200 && result.response && result.response[0] && 
						result.response[0] === dataRecordId
				});
		},
		/**
		 * Returns a query which connects the record we are on with the related records saved in the Dyn Selection Field
		 * @param {string} fieldId The ID of the field being connected.
		 * @param {string} relationshipSelector Relationship which will connect the records
		 * @param {object} referenceQuery Reference query to use when generating our query (used to pull sorts, etc.)
		 */
		getValueLookupQuery(fieldId, relationshipSelector, referenceQuery) {
			// Find out the selected record:
			// Read some information from our config to setup our query properly.
			let relationshipSelectorJSON = relationshipSelector;
			let relationshipSelectorObj = ObjectUtils.getObjFromJSON(relationshipSelectorJSON);

			let relationshipObj = RelationshipStore.get(relationshipSelectorObj.relationId);
			if (!relationshipObj) {
				console.error('Invalid Relationship selected in DynamicDropdown.  Relationship', relationshipSelectorObj.relationId, 'does not exist.');
				return;
			}

			let fieldRecordId = fieldId;
			let fieldObj = FieldStore.get(fieldRecordId);
			if (!fieldObj) {
				console.error('Unable to load the field object for this field!!', fieldRecordId);
				return;
			}

			// Assuming we're ltor, we want to setup the return and related tables appropriately.
			let direction = relationshipSelectorObj.direction;

			let returnTableSchemaName = (direction === 'ltor' ? relationshipObj.rTableSchemaName : relationshipObj.lTableSchemaName);
			let relatedTableSchemaName = (direction === 'ltor' ? relationshipObj.lTableSchemaName : relationshipObj.rTableSchemaName);

			let queryId = uuid.v4();
			let returnNodeId = uuid.v4();
			let query = {
				queryId: queryId,
				returnNode: returnNodeId,
				filters: [{
					type: "filter",
					operation: "recordIs",
					filteredNode: "1a60f7b6-a5d1-4577-a131-bc35ae0cd85b",
					fieldSchemaName: "recordIs",
					value: "startingContext"
				}],
				nodes: [
					{
						tableSchemaName: returnTableSchemaName,
						nodeId: returnNodeId,
					},
					{
						tableSchemaName: relatedTableSchemaName,
						nodeId: "1a60f7b6-a5d1-4577-a131-bc35ae0cd85b",
						filters: [
							{
								"type": "filter",
								"operation": "relation",
								"recordId": relationshipSelectorObj.relationId, // relationship record ID
								"filteredNode": "1a60f7b6-a5d1-4577-a131-bc35ae0cd85b",
								"relatedNode": returnNodeId,
								"relationSchemaName": relationshipObj.relationSchemaName,
								"direction": direction,
								"lCardinality": relationshipObj.lCardinality,
								"rCardinality": relationshipObj.rCardinality,
								"requirement": "must"
							}
						],
					}
				],
				sorts: []
			};

			if (referenceQuery && referenceQuery.sorts && referenceQuery.sorts.length) {
				referenceQuery.sorts.forEach(sort => {
					if (sort.nodeId === referenceQuery.returnNode) {
						sort.nodeId = returnNodeId;
						query.sorts.push(sort);
					}
				});
			}

			return JSON.stringify(query);
		},
		dynamicSelect: {
			calculateState: function (selectionType, prevState, props) {
				// Initialize newState
				let newState = Object.assign({
					rows: [], // The available options to select from
					startingRelatedRecordsJSON: '', // The initial selection in the field (used to remove old relationship values on save)
					newRecordJSON: '' // The current selection in the field (used to add new relationship values on save)
				}, prevState);

				// Initially indicate that we don't have a mismatch in selected record
				newState.selectedRecordMismatch = false;

				let tableSchemaName = toExport.query.getReturnTable(props.selectListOf);
				let editMode = toExport.UIUtils.convertFieldSelectorToFieldSchemaName(props.editMode, tableSchemaName, false);

				// Get information for rows (the available options to select from)
				newState.rows = RecordSetStore.getRecordSetFull(props.renderId, props.fieldId + '-results');
				if (newState.rows && newState.rows.has('rows')) {
					// Get a string representation of the current value of each record set depended on by the options query for this field.
					// (This is used to determine when and whether to recalculate the record set)

					// Get the object form of the query
					let selectListOfQuery = typeof props.selectListOf === 'string' ?
						ObjectUtils.getObjFromJSON(props.selectListOf) :
						props.selectListOf;

					// Get the record sets in the query
					let recordSetList = RecordSetStore.getRecordSetsInQuery(props.renderId, selectListOfQuery);

					// Save the record set representation to the new state
					newState.recordSetValueString = Object.keys(recordSetList).map(recordSetKey => JSON.stringify(recordSetList[recordSetKey])).join();
				}

				// Get information for the selected records and update the state if necessary
				let { newRecordJSON: currentRecordJSON } = newState;
				let selectedRecordSet = RecordSetStore.getRecordSet(props.renderId, props.fieldId + '-selected');
				if (selectedRecordSet) {
					// Get the edit mode display values for any selected records.
					let selectedRows = selectedRecordSet.map(recordSetValue => {
						let { recordId, tableSchemaName } = recordSetValue;
						if (recordId && tableSchemaName) {

							let toReturn = {
								recordId,
								tableSchemaName
							};

							// Get the record info
							let recordObj = RecordStore.getRecord(tableSchemaName, recordId) || {};

							if (editMode) {
								toReturn[editMode] = recordObj && recordObj[editMode] ? recordObj[editMode].value : '';
							}

							return toReturn;

						} else {
							// console.warn('Selected record without recordId or tableSchemaName. Value was', recordSetValue);
							return {};
						}

					});

					if (selectedRows[0]) {
						// Convert multiselect values to single select, if necessary.
						if (selectionType === 'single') {
							// Update the currently selected record JSON.
							currentRecordJSON = JSON.stringify({
								recordId: selectedRows[0].recordId,
								tableSchemaName: selectedRows[0].tableSchemaName
							});
						} else {
							currentRecordJSON = JSON.stringify(selectedRows.map(row => {
								return {
									label: row[editMode],
									value: JSON.stringify({
										recordId: row.recordId,
										tableSchemaName: row.tableSchemaName
									})
								};
							}));
						}

						// @TODO: See whether to re-enable this.
						// Update the starting related records JSON.
						//newState.startingRelatedRecordsJSON = JSON.stringify(selectedRows);
					} else if (props && props.value) {
						// We have no value in the record set store, yet we have a value. componentDidUpdate will need to update the store.
						newState.selectedRecordMismatch = true;
						let valueObj = ObjectUtils.getObjFromJSON(props.value);
						currentRecordJSON = valueObj.newRecordJSON || '[]';
					} else {
						currentRecordJSON = '[]';
					}

					// We do NOT need to update the Record Set Store with the value from the Record Store.
					newState.syncStores = false;
				}
				newState.newRecordJSON = currentRecordJSON;

				// Get information for the starting related records
				let { startingRelatedRecordsJSON } = newState;
				let originalRecordSet = RecordSetStore.getRecordSet(props.renderId, props.fieldId + '-original');
				if (originalRecordSet) {
					// Get the edit mode display values for any selected records.
					let originalRows = originalRecordSet.map(recordSetValue => {
						let { recordId, tableSchemaName } = recordSetValue;
						if (recordId && tableSchemaName) {

							let toReturn = {
								recordId,
								tableSchemaName
							};

							// Get the record info
							let recordObj = RecordStore.getRecord(tableSchemaName, recordId) || {};

							if (editMode) {
								toReturn[editMode] = recordObj && recordObj[editMode] ? recordObj[editMode].value : '';
							}

							return toReturn;

						} else {
							console.warn('Original record without recordId or tableSchemaName. Value was', recordSetValue);
							return {};
						}

					});

					if (originalRows[0]) {
						startingRelatedRecordsJSON = JSON.stringify(originalRows.map(row => {
							return {
								recordId: row.recordId,
								tableSchemaName: row.tableSchemaName
							};
						}));

						// @TODO: See whether to re-enable this.
						// Update the starting related records JSON.
						//newState.startingRelatedRecordsJSON = JSON.stringify(selectedRows);
					}
				}
				newState.startingRelatedRecordsJSON = startingRelatedRecordsJSON;

				// Return the new state
				return newState;
			},
			calculateOriginalSelectedValue(props) {
				if(props.waitForRecordSets) {
					return false;
				}
				
				let recordSetNameOrig = props.fieldId + '-original';
				let selectListOfObj = props.selectListOf && typeof props.selectListOf === 'string' 
						? ObjectUtils.getObjFromJSON(props.selectListOf)
						: props.selectListOf;
				let valueLookupQuery = props.relationshipSelector 
					? toExport.query.getValueLookupQuery(props.fieldId, props.relationshipSelector, selectListOfObj) 
					: '';
				let tableSchemaName = toExport.query.getReturnTable(props.selectListOf);
				let viewMode = toExport.UIUtils.convertFieldSelectorToFieldSchemaName(props.viewMode,  tableSchemaName, false);

				// Recalculate it!
				return RecordSetActions.calculateRecordSet(
					props.renderId,
					recordSetNameOrig,
					valueLookupQuery,
					[viewMode],
					false, // Change this to "true" to implement 3998, but that still needs discussion
					'Original User Selected Record(s)'
				)
			},
			processValueLookup(props, reconcileOriginalVsSelected, forceUpdate) {
				// If the renderObj doesn't exist then calculateRecordSet is going to skip anyway
				// but letting it run can cause some performance issues (ticket 22864)
				let renderObj = RenderStore.get(props.renderId);

				if(props.waitForRecordSets || !renderObj) {
					// Skip if the render object doesn't exist yet
					return Promise.resolve(false);
				}

				let recordSetNameOrig = props.fieldId + '-original',
					recordSetNameSelected = props.fieldId + '-selected',
					recordSetOrig = RecordSetStore.getRecordSet(props.renderId, recordSetNameOrig),
					recordSetSelected = RecordSetStore.getRecordSet(props.renderId, recordSetNameSelected);

				// We only attempt to update if we have an original record set and we've been told to
				reconcileOriginalVsSelected  = reconcileOriginalVsSelected && recordSetOrig && recordSetSelected &&
					JSON.stringify(recordSetSelected) !== JSON.stringify(recordSetOrig);

				// If this field is in a List, then this.props.waitForRecordSets will ALWAYS be true
				// and the below block will never run; because List is handling the mounting/record set generation 
				// work for us.
				// Or if there is a mismatch between original and selected values and we expect them to be in sync
				if ((forceUpdate || !recordSetOrig || reconcileOriginalVsSelected)) {
					// console.log('Reconciling the original and selected values.');
					// If we don't have an Orig record set yet...
					// Get the query to run to find our value
					let selectListOfObj = props.selectListOf && typeof props.selectListOf === 'string' 
							? ObjectUtils.getObjFromJSON(props.selectListOf)
							: props.selectListOf;
					let valueLookupQuery = props.relationshipSelector 
						? toExport.query.getValueLookupQuery(props.fieldId, props.relationshipSelector, selectListOfObj) 
						: '';
					
					let tableSchemaName = toExport.query.getReturnTable(props.selectListOf);
					let viewMode = toExport.UIUtils.convertFieldSelectorToFieldSchemaName(props.viewMode,  tableSchemaName, false);
			
					let lastDt = +new Date();
					// use it!
					return RecordSetActions.calculateRecordSet(
						props.renderId,
						recordSetNameOrig,
						valueLookupQuery,
						[viewMode],
						false, // Change this to "true" to implement 3998, but that still needs discussion
						'Original User Selected Record(s)'
					)
						.then(rows => {
							// console.log('Received rows value for original selected records with which to update current selected records', rows);
							
							// Get the new selected record set
							let selectedLastDt = RecordSetStore.getLastModifiedTime(props.renderId, recordSetNameSelected);

							// If it has a more recent value, we don't want to overwrite it.
							// (Provisional fix for 4093 that I hope won't break anything else)
							if(!selectedLastDt || selectedLastDt < lastDt || forceUpdate) {
								// console.log('Updating the selected record set.');
								if(rows && Array.isArray(rows) && rows.length) {
									// Set the selected record set
									let targetTableSchemaName = rows && rows[0] && rows[0].tableSchemaName ? rows[0].tableSchemaName : '';
									RecordSetActions.setRecordSet(props.renderId, recordSetNameSelected,
										targetTableSchemaName, rows.map(row => {
											return row.recordId;
										}), 'Current User Selected Record(s)');
								} else {
									RecordSetActions.setRecordSet(props.renderId, 
										recordSetNameSelected, '', [], 'Current User Selected Record(s)');
								}
							}

						})
						.catch((error) => {
							console.error('Invalid configuration for field:', props.fieldLabel, '(', props.fieldId, ')');
							console.error(error);
						});
				} else {
					return Promise.resolve();
				}
			},
			calculateAvailableRecords: function (selectListOf, displayFieldInfo, renderId, fieldId) {
				let tableSchemaName = toExport.query.getReturnTable(selectListOf);
				let displayField = toExport.UIUtils.convertFieldSelectorToFieldSchemaName(displayFieldInfo, tableSchemaName, false);

				// Recalculate the available options
				// (We don't need a then method because calculateState will handle it anyway)
				RecordSetActions.calculateRecordSet(
					renderId, // Parent 
					fieldId + '-results',  // Set Identifier
					selectListOf, // List of Options Query 
					displayField, // FieldSchemaName to Display 
					true, // true when the query info should be stored in the RecordSet store 
					'All Records' // Friendly UI Set Name for us
				).catch(err => {
					console.warn('Error calculating record set for render ID %s', renderId, err);
				});

				// @TODO: The old version of this had the following code. See if the if/else breakdown here is actually necessary.
				/*
				 * if(!this.state.rows){
					// Calculate the RecordSets for the Field Options: 
					RecordSetActions.calculateRecordSet(
						renderId, // Parent 
						recordSetName,  // Set Identifier
						selectListOf, // List of Options Query 
						editMode, // FieldSchemaName to Display 
						true, // true when the query info should be stored in the RecordSet store 
						'All Records' // Friendly UI Set Name for us
					);
				} else {
					// Compare if there has been an update in the related RecordSets: 
					let recordSetValueString = '';
					let selectListOfObj = selectListOf && typeof selectListOf === 'string' 
						? ObjectUtils.getObjFromJSON(selectListOf)
						: selectListOf;
					let recordSetList = RecordSetStore.getRecordSetsInQuery(renderId, selectListOfObj);
					
					 // Add JSON Stringified getRecordSet results to the object (which should cause re-rendering)
					 Object.keys(recordSetList).forEach(recordSetKey => {
						recordSetValueString += JSON.stringify(recordSetList[recordSetKey]);
					});
				    
					// If a dependent record sets value has changed or the dataRecordId has... 
					if((recordSetValueString && recordSetValueString !== prevState.recordSetValueString) || (prevProps.dataRecordId !== dataRecordId)) {
						// Re-run getPossibleOptions with props
						RecordSetActions.calculateRecordSet(renderId, 
							recordSetName, selectListOf, editMode, true, 'All Records').catch(console.error);
						    
					}
				}
			}
				 */
			},
			componentDidUpdate: function (prevProps, prevState, props, state) {
				let recalculateAvailableRecords =
					(prevProps.selectListOf !== props.selectListOf) // Query has changed
					|| (prevProps.dataRecordId !== props.dataRecordId) // Record in this field has changed
					// One or more of the record sets used in this query has changed
					// (Truthiness check is to avoid undefined !== '' issues)
					// This was added as a fix for 4367 (Default dynamic selection values flashing on load of the page)
					|| ((prevState.recordSetValueString !== state.recordSetValueString) && (state.recordSetValueString || prevState.recordSetValueString))
					|| prevProps.editMode !== props.editMode; // Field to display in this mode mode has changed

				let selectedRecordSet = RecordSetStore.getRecordSet(props.renderId, props.fieldId + '-selected');
				let updateSelectedRecordsStore = state.syncStores // RecordSetStore does not yet have the value from the Record Store
					// || prevState.newRecordJSON !== state.newRecordJSON // NewRecordJSON has changed
					|| !selectedRecordSet // The store does not yet have the selected value
					|| state.selectedRecordMismatch // We have a selected record mismatch
					|| (props.editMode !== prevProps.editMode); // Field to display in this mode mode has changed

				if (recalculateAvailableRecords && !props.waitForRecordSets) {
				    // Recalculate the available records
					toExport.query.dynamicSelect.calculateAvailableRecords(props.selectListOf, props.editMode, props.renderId, props.fieldId);
					// Reprocess the value lookup (added for ticket 4318)

					// We set the reconcileOriginalVsSelected and forceUpdate parameters based on if our incoming value has changed
					// We do this because otherwise, when a query gets recalculated, it can overwrite a value intentionally selected via logic
					// (This is also part of the fix for ticket 4367 - Default dynamic selection values flashing on load of the page)
					// Commented out and replaced with the below because of the third instance of ticket 4238
					// toExport.query.dynamicSelect.processValueLookup(
					// 	props,
					// 	// We don't want to evaluate whether we need value reconciliation if our value has changed
					// 	// as then the selected record set is supposed to use the incoming value
					// 	// (This is for part two of the fix for ticket 4367 - Default dynamic selection values flashing on load of the page)
					// 	prevProps ? prevProps.value === props.value : false,
					// 	// We don't want to FORCE value reconciliation if we have a value
					// 	// (part three of the fix for ticket 4367 - Default dynamic selection values flashing on load of the page)
					// 	!props.value
					// );


					// We only calculate the original selected values here and let calculateState deal with the current selected record
					toExport.query.dynamicSelect.calculateOriginalSelectedValue(props);
				} else if (!prevProps || !prevProps.lastRefresh || prevProps.lastRefresh < props.lastRefresh) {
					toExport.query.dynamicSelect.calculateOriginalSelectedValue(props)
						.then(results => {
							// We need to figure out whether the selected value is dirty and update it to match if it isn't
							let value = props.value ? ObjectUtils.getObjFromJSON(props.value) : {};
							if(value.startingRelatedRecordsJSON === value.newRecordJSON) {
								let tableSchemaName = results && results[0] ? results[0].tableSchemaName : toExport.query.getReturnTable(props.selectListOf);
								// Update store with new value
								setTimeout(() => {
									// This appears to cause dispatch in a dispatch errors if omitted (ticket 4500)
									RecordSetActions.setRecordSet(props.renderId, props.fieldId + '-selected',
										tableSchemaName, results ? results.map(({recordId}) => recordId) : [], 'Current User Selected Record(s)');
								});
							}
						});
				}

				// @TODO: Fix for 3794 - Required Fields That Load With Value Fail Validation which does not also cause 3875 - Dynamic Selection field is required even when the field value is selected in dialog

				// If we're getting a new value AND we don't have a record set in the record set store
				// then it probaby came from the action but hasn't been set yet.
				// Update it.
				if(props.renderId && prevProps.value !== props.value && props.value && !selectedRecordSet) {
					let value = ObjectUtils.getObjFromJSON(props.value);
					let {newRecordJSON} = value ? value : {};

					let tableSchemaName = toExport.query.getReturnTable(props.selectListOf);
					let valueArray = null;
					let selectedRecordObj = newRecordJSON ? ObjectUtils.getObjFromJSON(newRecordJSON) : undefined;
					if(selectedRecordObj && Array.isArray(selectedRecordObj)) {
						valueArray = selectedRecordObj.map(({recordId}) => recordId);
						// tableSchemaName = selectedRecordObj[0] && selectedRecordObj[0].tableSchemaName ? selectedRecordObj[0].tableSchemaName : null;
					} else if (selectedRecordObj) {
						valueArray = [selectedRecordObj.recordId];
						// tableSchemaName = selectedRecordObj.tableSchemaName;
					}
					if (tableSchemaName && valueArray) {

						// Update store with new value
						setTimeout(() => {
							// This appears to cause dispatch in a dispatch errors if omitted (ticket 4500)
							RecordSetActions.setRecordSet(props.renderId, props.fieldId + '-selected',
								tableSchemaName, valueArray, 'Current User Selected Record(s)');
						});
					}
				} else  if (updateSelectedRecordsStore) {
					let selectedRecordObj = state.newRecordJSON
						? ObjectUtils.getObjFromJSON(state.newRecordJSON)
						: null;
					let valueArray = null;
					let tableSchemaName = toExport.query.getReturnTable(props.selectListOf);
					// Support both array and object values selectedRecordObj
					if(selectedRecordObj && Array.isArray(selectedRecordObj)) {
						valueArray = selectedRecordObj.map(({recordId}) => recordId);
						// tableSchemaName = selectedRecordObj[0] && selectedRecordObj[0].tableSchemaName ? selectedRecordObj[0].tableSchemaName : null;
					} else if (selectedRecordObj) {
						valueArray = [selectedRecordObj.recordId];
						// tableSchemaName = selectedRecordObj.tableSchemaName;
					}
					if (tableSchemaName && valueArray) {

						// Update store with new value
						setTimeout(() => {
							// This appears to cause dispatch in a dispatch errors if omitted (ticket 4500)
							RecordSetActions.setRecordSet(props.renderId, props.fieldId + '-selected',
								tableSchemaName, valueArray, 'Current User Selected Record(s)');
						});
					}
				}
			},
			setValue: function (selectionType, renderId, fieldId, dataTableSchemaName, dataRecordId, setTableSchemaName, recordIds, startingRelatedRecordsJSON) {
				let field = FieldStore.get(fieldId);
				let fieldSchemaName = field ? field.fieldSchemaName : '';
				let valueArray = selectionType === 'single' && recordIds.length ? recordIds.slice(0, 1) : recordIds;

				// Push the selected record set into the record set store.
				RecordSetActions.setRecordSetSelected(renderId, fieldId + '-selected',
					setTableSchemaName, valueArray, startingRelatedRecordsJSON, 'Current User Selected Record(s)',
					dataTableSchemaName, dataRecordId, fieldSchemaName);
			},
			/**
			 * Read a value from field selector with parts and return an object with multipart, fieldId, fieldPart, and fieldSchemaName
			 * @param {string} fieldDisplaySetting A setting value from the field selector with parts
			 * @return {object} Includes multipart (boolean), fieldId, fieldPart, fieldSchemaName
			 */
			getFieldDisplayInfo: function (fieldDisplaySetting) {
				let returnObj = {
					multipart: undefined,
					fieldId: undefined,
					fieldPart: undefined,
					fieldSchemaName: undefined
				};

				let fieldDisplaySettingObj = ObjectUtils.getObjFromJSON(fieldDisplaySetting);
				if(typeof fieldDisplaySettingObj === 'object' && fieldDisplaySettingObj.fieldId) {
                	let fieldId = fieldDisplaySettingObj.fieldId;
                	let fieldPart = fieldDisplaySettingObj.part;
                
                	let fieldObj = FieldStore.get(fieldId);
                
					if(fieldObj) {
						returnObj.fieldId = fieldId;
						returnObj.fieldPart = fieldPart;
						returnObj.fieldSchemaName = fieldObj.fieldSchemaName;
						let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType);

						returnObj.multipart = false;

						if(fieldTypeObj && (fieldTypeObj.dataType === 'multipart' || fieldTypeObj.dataType === 'file')) {
							returnObj.multipart = true;
						}

						if(!fieldTypeObj) {
							console.error(new Error('Missing fieldTypeObj for field ' + returnObj.fieldId));
						}
					}
				}
					
				return returnObj;
			}
		}
	},
	visibility: {
		/**
		 * Used for 'smart' recursing of result options.
		 * 
		 * Basically: will look at the resolution of the first promise for results. If it finds results, it will return the corresponding option;
		 * Otherwise, it will continue on to the next, until it runs out of room in the array, at which point it will return the last option in the array.
		 * (We assume that the array is sorted in descending order of priority, which is handled elsewhere)
		 *
		 * 
		 * @param {string} optionsJSON JSON.stringified Array of options to filter as needed
		 * @returns {Promise.<array>} A Promise, which resolves with an array of options suitable for field configuration
		 */
		resultRecursor: function (rulesArray, index) {
			return rulesArray[index]['resultPromise'].then(results => {
				// If there are results or this is the last rule in the index, return the rule for this index
				// Otherwise move on to the next item.
				return ((results && results.rows && results.rows.length) || (index >= rulesArray.length - 1)) ?
					rulesArray[index]['rule'] :
					this.resultRecursor(rulesArray, index + 1);
			}).catch(console.error);
		},
		/**
		 * Depending on whether or not the visibility overlay is active, either just returns a promise which resolves to these options or goes through the visibility settings for each option
		 * in order to determine whether to put them into the final result or not.
		 * 
		 * Used for filtering for navTabs, contentTabs, and contentDropdown fields.
		 * 
		 * @param {string} optionsJSON JSON.stringified Array of options to filter as needed
		 * @param {string|[]} modes Mode(s) that is being checked for
		 * @param {string} renderId the current context data render id
		 * @param {string} dataRecordId the current context data record id
		 * @param {string} dataTableSchemaName the current context table schema name
		 * @returns array A Promise, which resolves with an array of options suitable for field configuration
		 */
		filterOptionsByVisibility: function (optionsJSON, modes, renderId, dataRecordId, dataTableSchemaName, fieldId, recordSets, activeOverlays) {
		    // Ensure mode is an array.
			if (modes) {
				if (typeof modes === 'string') {
					if (modes === 'show') {
						modes = ['view'];
					} else {
						modes = [modes];
					}
				} else if (!Array.isArray(modes)) {
					console.error('modes', modes);
					throw new Error('Modes must be an array or string');
				}
			} else {
				modes = ['view'];
			}
			var options = [];
			let optionPromises = [];
			if (optionsJSON) {
				try {
					options = JSON.parse(optionsJSON);
				} catch (err) {
				}
			}
			// When the visibility overlay is active, we want to show everything to allow for configuration.
			if (activeOverlays
				? (activeOverlays.includes('visibilityV1') || activeOverlays.includes('visibility'))
				: (AdminSettingsStore.getIsOverlayActive('visibilityV1') || AdminSettingsStore.getIsOverlayActive('visibility'))
			) {
				return Promise.resolve(options);
			}

			options.forEach((optionObj, subSettingIndex) => {
				let attachmentId = optionObj.attachmentId;
				let subSettingSchemaName = 'attachedFields'; // This is always the same value
				// First of all, figure out if there's visibility logic
				let fieldObj = FieldStore.get(fieldId) || {};
				let customVisibilityLogic = fieldObj[attachmentId + '-' + subSettingSchemaName + '-' + subSettingIndex + '-visibility'];
				if (optionObj.visibility) {
					let visibilityRules = {};
					try {
						visibilityRules = typeof optionObj.visibility === 'string' ? JSON.parse(optionObj.visibility) : optionObj.visibility;
					} catch (err) {
						console.error('Error when parsing visibility. Value was:', optionObj.visibility);
					}

					// Rules are additive, so we don't need to bother with sorting for the phase 1 version.
					// However, we should check authenticated/nonauthenticated rules before all else,
					// As we don't need to care if they're empty

					// @TODO: Possibly replace this with a reference to the AuthenticationStore?
					let isAuthenticated = !!AuthenticationStore.getUserId();
					let permissionPushed = false;
					if (isAuthenticated) {
						let authenticatedUserRules = visibilityRules['authenticated'];

						if (!authenticatedUserRules) {
							// If no visibility rules are present for authenticated users,
							// the field should be visible to all users
							optionPromises.push(true);
							permissionPushed = true;
						} else {
							for (let key = 0; key < modes.length && !permissionPushed; key++) {
								let mode = modes[key];
								if (authenticatedUserRules[mode] === '1' && 
									(!authenticatedUserRules[mode+'Processing'] || authenticatedUserRules[mode+'Processing'] === 'always')) {
									// If the authenticated user has permission for this mode, just proceed
									optionPromises.push(true);
									permissionPushed = true;
								}
							}
						} 
						if (!permissionPushed) {
							//First check all the groups for  alway view permission
							let userGroups = AuthenticationStore.getUserSecurityGroups() || [];
							let permissionFound = !!(userGroups.find(userGroup => {
								let groupRules = visibilityRules[userGroup];
								for (let key = 0; key < modes.length; key++) {
									let mode = modes[key];
									if (groupRules && groupRules[mode] === '1' && 
										(!groupRules[mode+'Processing'] || groupRules[mode+'Processing'] === 'always')) {
										return true;
									}
								}
								return false;
							}));
							if (permissionFound) {
								optionPromises.push(permissionFound);
								permissionPushed = true;
							} else {
								// Check any custom visibility logic
								let customLogicObj = customVisibilityLogic ? ObjectUtils.getObjFromJSON(customVisibilityLogic) : undefined;
								let logicPromise = Promise.resolve(false);

								if(customLogicObj && customLogicObj.js) {
									let js = 'output.displayField = {};\n' +
										customLogicObj.js;
									logicPromise = ActionProcessor.processAction({
										actionRecordId: attachmentId,
										actionTableSchemaName: 'visibility',
										hookId: attachmentId + '-visibility',
										action: js,
										recordId: dataRecordId,
										renderId: renderId,
										tableSchemaName: dataTableSchemaName,
										runOnBackend: customLogicObj.runOnBackend,
										memUse: customLogicObj.memUse,
										namedContexts: recordSets
									})
										.then(output => {
											let enableView = output.displayField.enableView;
											return !!enableView;
										})
										.catch(error => {
											console.error('Error processing action in filterOptionsByVisibility', error);
											return false;
										});
								}

								//Check and see if the groups including "authenticated" have any query based checks
								let groups = userGroups.slice();
								groups.push('authenticated');
								let queries = [];
								modes.forEach((mode) => {
									queries = queries.concat(groups.map(userGroup => {
										let groupRules = visibilityRules[userGroup];
										if (groupRules && groupRules[mode] === '1' && 
											groupRules[mode+'Processing'] === 'query' &&
											groupRules[mode+'Query']) {
											return groupRules[mode+'Query'];
										} else {
											return null;
										}
									}).filter((query) => !!query));
								});
								let queryPromise = queries.length ? 
									new Promise((resolve, reject) => {
										Promise.all(queries.map((query) => {
											return toExport.query.recordExistsInRecordSet(query, renderId, dataRecordId, recordSets);
										})).then((results) => {
											resolve(!!results.find((value) => value));
										}).catch(reject);
									}) :
									Promise.resolve(false);
									optionPromises.push(new Promise((resolve, reject) => {
										Promise.all([logicPromise, queryPromise])
											.then(([logicResult, queryResult]) => {
												return resolve(logicResult || queryResult);
											})
											.catch(reject);
									}));
							}
						}
					} else {
						let nonauthenticatedUserRules = visibilityRules['nonauthenticated'];
						if (!nonauthenticatedUserRules) {
							// If no visibility rules are present for nonauthenticated users,
							// the field should be visible to all nonauthenticated users
							optionPromises.push(true);
						} else {
							let queries = [];
							for (let key = 0; key < modes.length && !permissionPushed; key++) {
								let mode = modes[key];
								if (nonauthenticatedUserRules[mode] === true || (nonauthenticatedUserRules[mode] === '1' && 
									(!nonauthenticatedUserRules[mode+'Processing'] || nonauthenticatedUserRules[mode+'Processing'] === 'always'))) {
									// If the nonauthenticated user has permission for this mode, just proceed
									optionPromises.push(true);
									permissionPushed = true;
								}

								if (nonauthenticatedUserRules[mode+'Processing'] && nonauthenticatedUserRules[mode+'Processing'] === 'query') {
									queries.push(nonauthenticatedUserRules[mode+'Query'])
								}
							}

							if (!permissionPushed) {
								// Check to see if there's custom visibility logic and also run it if so
								let customLogicObj = customVisibilityLogic ? ObjectUtils.getObjFromJSON(customVisibilityLogic) : undefined;
								let logicPromise = Promise.resolve(false);

								if(customLogicObj && customLogicObj.js) {
									let js = 'output.displayField = {};\n' +
										customLogicObj.js;
									logicPromise = ActionProcessor.processAction({
										actionRecordId: attachmentId,
										actionTableSchemaName: 'visibility',
										hookId: attachmentId + '-visibility',
										action: js,
										recordId: dataRecordId,
										renderId: renderId,
										tableSchemaName: dataTableSchemaName,
										runOnBackend: customLogicObj.runOnBackend,
										memUse: customLogicObj.memUse,
										namedContexts: recordSets
									})
										.then(output => {
											let enableView = output.displayField.enableView;
											return !!enableView;
										})
										.catch(error => {
											console.error('Error processing action in filterOptionsByVisibility', error);
											return false;
										});
									}
									
								//Check and see if the "unauthenticated" have any query based checks
								let queryPromise = queries.length ? 
									new Promise((resolve, reject) => {
										Promise.all(queries.map((query) => {
											return toExport.query.recordExistsInRecordSet(query, renderId, dataRecordId, recordSets);
										})).then((results) => {
											resolve(!!results.find((value) => value));
										}).catch(reject);
									}) :
									Promise.resolve(false);

								optionPromises.push(new Promise((resolve, reject) => {
									Promise.all([logicPromise, queryPromise])
										.then(([logicResult, queryResult]) => {
											return resolve(logicResult || queryResult);
										})
										.catch(reject);
								}));
							}
						}
					}
				} else if (optionObj.visibilityRules) {
					// Old visibility rules handling (used for backwards compatibility)
					let visibilityRules = {};
					let visibilityPromises = [];
					let mode = modes[0];
					try {
						visibilityRules = typeof optionObj.visibilityRules === 'string' ? JSON.parse(optionObj.visibilityRules) : optionObj.visibilityRules;
					} catch (err) {
						console.error('Error when parsing visibility rules. Value was:', visibilityRules);
					}
					// "All users" rules sorted in descending order of sequence (top priority rule is first, etc.)
					// @TODO: Will need to be updated once specific user group rules are implemented.
					let sortedRules = visibilityRules.allUsers.sort((ruleA, ruleB) => {
						if (ruleA.sequence > ruleB.sequence) {
							return -1;
						} else if (ruleA.sequence < ruleB.sequence) {
							return 1;
						} else {
							return 0;
						}
					});
					visibilityPromises = sortedRules.map(rule => {
						let toReturn = {
							rule: rule,
							resultPromise: rule.query ? toExport.query.processQueryV2(rule.query, [], renderId, dataRecordId, dataTableSchemaName) : Promise.resolve(['true'])
						};
						return toReturn;
					});
					let toPush = this.resultRecursor(visibilityPromises, 0)
						.then(results => {
							return results ? results[mode] : false;
						});
					// tabPromises.push(true);
					optionPromises.push(toPush);
				} else {
					optionPromises.push(true);
				}
			});
			return Promise.all(optionPromises)
				.then(results => {
					let toReturn = [];
					results.forEach((result, i) => {
						if (result) {
							toReturn.push(options[i]);
						}
					});
					return toReturn;
				});
		},
		/**
		 * Given a set of available parent modes, calculates
		 * the modes available to the child.
		 *
		 * @param {object} optionObj The object to evaluate
		 * @param {array|string} availableModes The parent available modes
		 * @param {string} renderId The renderId of the field being evaluated
		 * @param {string} dataRecordId The data record ID of the field being evaluated
		 * @returns
		 */
		calculateAvailableModes(optionObj, availableModes, renderId, dataRecordId) {
			return new Promise((resolve, reject) => {
				if (optionObj.visibility) {
					let visibilityRules = {};
					try {
						visibilityRules = typeof optionObj.visibility === 'string' ? JSON.parse(optionObj.visibility) : optionObj.visibility;
					} catch (err) {
						console.error('Error when parsing visibility. Value was:', optionObj.visibility);
					}
	
					// Rules are additive, so we don't need to bother with sorting for the phase 1 version.
					// However, we should check authenticated/nonauthenticated rules before all else,
					// As we don't need to care if they're empty
	
					// @TODO: Possibly replace this with a reference to the AuthenticationStore?
					let isAuthenticated = !!AuthenticationStore.getUserId();
					let modes = {};
					let queryPromises = [];
					availableModes.forEach((mode, index) => {
						if (isAuthenticated) {
							let authenticatedUserRules = visibilityRules.authenticated;
		
							if (!authenticatedUserRules) {
								// If no visibility rules are present for authenticated users,
								// the field should be visible to all users
								modes[mode] = true;
							} else if (
								authenticatedUserRules[mode] === '1' &&
								(!authenticatedUserRules[mode + 'Processing'] || authenticatedUserRules[mode + 'Processing'] === 'always')
							) {
								// If the authenticated user has permission for this mode, just proceed
								modes[mode] = true;
							}

							// Check the user groups if we didn't receive permission from authed user settings
							if(!modes[mode]) {
								//First check all the groups for always view permission
								let userGroups = AuthenticationStore.getUserSecurityGroups() || [];
								if (authenticatedUserRules[mode] === '1' &&
									(!authenticatedUserRules[mode + 'Processing'] || authenticatedUserRules[mode + 'Processing'] === 'always')
								) {
									// If the authenticated user has permission for this mode, just proceed
									modes[mode] = true;
								} else {
									// Check to see if any of the user groups has permission
									let permissionFound = !!(userGroups.find(userGroup => {
										let groupRules = visibilityRules[userGroup];
										if (groupRules && groupRules[mode] === '1' &&
											(!groupRules[mode + 'Processing'] || groupRules[mode + 'Processing'] === 'always')
										) {
											return true;
										}
										return false;
									}));
									if(permissionFound) {
										modes[mode] = true;
									} else {
										// Check the queries
										//Check and see if the groups including "authenticated" have any query based checks
										let groups = userGroups.slice();
										groups.push('authenticated');
										let queries = groups.map(userGroup => {
												let groupRules = visibilityRules[userGroup];
												if (groupRules && groupRules[mode] === '1' &&
													groupRules[mode + 'Processing'] === 'query' &&
													groupRules[mode + 'Query']
												) {
													return groupRules[mode + 'Query'];
												} else {
													return null;
												}
											}).filter((query) => !!query);
										// If all users have a query, push it
										if (authenticatedUserRules && authenticatedUserRules[mode + 'Processing'] === 'always' && authenticatedUserRules[mode + 'Query']) {
											queries.push(authenticatedUserRules[mode + 'Query']);
										}
										queryPromises[index] = new Promise((resolve, reject) => {
											Promise.all(queries.map((query) => {
												return toExport.query.recordExistsInRecordSet(query, renderId, dataRecordId);
											})).then((results) => {
												resolve(!!results.find((value) => value));
											}).catch(reject);
										});
									}
								}
							}
	
						} else {
							let nonauthenticatedUserRules = visibilityRules.nonauthenticated;

							if (!nonauthenticatedUserRules) {
								// If no visibility rules are present for unauthenticated users,
								// the field should be visible to all users
								modes[mode] = true;
							} else if (
								nonauthenticatedUserRules[mode] === '1' &&
								(!nonauthenticatedUserRules[mode + 'Processing'] || nonauthenticatedUserRules[mode + 'Processing'] === 'always')
							) {
								// If the unauthenticated user has permission for this mode, just proceed
								modes[mode] = true;
							}

							// Check for query-based permissions
							if(!modes[mode]) {
								if (nonauthenticatedUserRules[mode + 'Processing'] && nonauthenticatedUserRules[mode + 'Processing'] === 'query') {
									let query = nonauthenticatedUserRules[mode + 'Query'];
									queryPromises[index] = toExport.query.recordExistsInRecordSet(query, renderId, dataRecordId);
								}
							}
						}
					});

					Promise.all(queryPromises)
						.then(results => {
							results.forEach((result, index) => {
								if(result) {
									let mode = availableModes[index];
									modes[mode] = true;
								}
							});
							return resolve(Object.keys(modes));
						})
						.catch(reject);
					// If you are using this method then we do not need to care about backwards visibility compatibility. I refuse.
				} else {
					return resolve(availableModes);
				}
			});
		},
		
		/**
		 * Given a set of available parent modes, runs the visibility
		 * for each attached child entry and returns the field to display + its available modes
		 *
		 * @param {array} attachedFields Array of the available attached fields
		 * @param {array} modes The modes available to the parent
		 * @param {string} renderId Render ID of the parent component to evaluate
		 * @param {string} parentComponentId Component ID of the parent component
		 * @param {string} dataRecordId Data record ID of the parent
		 * @param {string} dataTableSchemaName Data table schema name of the parent
		 * @returns
		 */
		runAttachedFieldVisibility: function (attachedFields, modes, renderId, parentComponentId, dataRecordId, dataTableSchemaName, subSettingSchemaName, parentComponentType, activeOverlays = [], recordSets) {
			if(!attachedFields) {
				return Promise.resolve([]);
			}
			attachedFields = typeof attachedFields === 'string' ? ObjectUtils.getObjFromJSON(attachedFields) : attachedFields;
			let parentRenderObj = RenderStore.get(renderId);
			if(!parentComponentType && parentRenderObj) {
				parentComponentType = parentRenderObj.componentType;
			}
			if(!parentComponentId && parentRenderObj) {
				parentComponentId = parentRenderObj.componentId;
			}
			if(!parentComponentType || !parentComponentId) {
				console.log('Returning empty attached fields because parentComponentType or parentComponentId is missing');
				return Promise.resolve([]);
			}
			let parentObj = parentComponentType === 'field' ? FieldStore.get(parentComponentId) : PageStore.get(parentComponentId);
			if(!parentObj) {
				console.log('Returning empty attached fields because parentObj is missing');
				console.log('parentComponentType was', parentComponentType);
				console.log('parentComponentId was', parentComponentId);
				return Promise.resolve([]);
			}

			// Ensure mode is an array.
			if (modes) {
				if (typeof modes === 'string') {
					if (modes === 'show') {
						modes = ['view'];
					} else {
						modes = [modes];
					}
				} else if (!Array.isArray(modes)) {
					console.error('modes', modes);
					throw new Error('Modes must be an array or string');
				}
			} else {
				modes = ['view'];
			}

			if(modes && modes.includes('add')) {
				// Change add to edit
				let index = modes.findIndex(v => v === 'add');
				if(index > -1) {
					modes[index] = 'edit';
				}
			}


			// @TODO: We need to process all of the other visibility stuff here, too
			let visibilityPromises = [];
			
			attachedFields.forEach((attachmentEntry, index) => {
				let availableModes = [];
				// let currentMode = '';
				let visibilityActive = activeOverlays.includes('visibilityV1') || activeOverlays.includes('visibility');
				if(visibilityActive) {
					availableModes = modes.slice();
					// currentMode = availableModes[0] || 'view';
				}
				let {attachmentId, recordId, recordIds, order, hidden} = attachmentEntry;

				let fieldRenderId = attachmentId ? RenderStore.findChildRenderIdFromAttachment(renderId, attachmentId || recordId, attachmentId) : RenderStore.findChildRenderId(renderId, recordId, undefined);
				fieldRenderId = fieldRenderId || uuid.v4();
				// Backwards compatibility
				if (attachmentId) {
					let visibilityKey = subSettingSchemaName ? [attachmentId, subSettingSchemaName, index, 'visibility'].join('-') : (attachmentId + '-visibility');
					// Do we have any custom visibility logic on the parent field?
					let customLogicJSON = parentObj[visibilityKey];
					let customLogicObj = customLogicJSON ? ObjectUtils.getObjFromJSON(customLogicJSON) : {};

					if(customLogicObj && customLogicObj.js) {
						// We need to run the logic first in order to do any subsequent permissions, as otherwise
						// We don't know which field to get other visibility permissions from
						let js = 'output.displayField = {};\n' +
							customLogicObj.js;

						let fieldId = '';
						visibilityPromises.push(new Promise((resolve, reject) => {
							let actionRequest = {
								actionRecordId: attachmentId,
								actionTableSchemaName: 'visibility',
								hookId: attachmentId + '-visibility',
								actionParentRecordId: parentComponentId,
								actionParentTableSchemaName: parentComponentType,
								action: js,
								recordId: dataRecordId,
								renderId: renderId,
								tableSchemaName: dataTableSchemaName,
								runOnBackend: customLogicObj.runOnBackend,
								memUse: customLogicObj.memUse,
								// Override the starting context, because the store may not have updated by the time this runs
								overrideStartingRecord: true,
								namedContexts: recordSets
							};
							if(recordSets) {
								actionRequest.namedContexts = recordSets;
							}
							ActionProcessor.processAction(actionRequest)
								.then(output => {
									let {displayField: {toShow, enableView, enableEdit}} = output;
									if(enableView && modes.indexOf('view') > -1 && availableModes.indexOf('view') === -1) {
										availableModes.push('view');
									}
									if(enableEdit && modes.indexOf('edit') > -1 && availableModes.indexOf('edit') === -1) {
										availableModes.push('edit');
									}

									// If no field is rendered, pick the first one that could potentially be rendered
									// We just leave the modes off if there is none
									fieldId = toShow ? toShow : '';

									// If any of the available modes for which we're evaluating are missing here, check the other locations for permissions
									let permissionsMissing = [];
									modes.forEach(mode => {
										if(availableModes.indexOf(mode) === -1) {
											permissionsMissing.push(mode);
										}
									});

									if(permissionsMissing.length) {
										let fieldSettings = FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, fieldId, parentComponentId);
										return toExport.visibility.calculateAvailableModes(fieldSettings, permissionsMissing, renderId, dataRecordId);
									}
								})
								.then((additionalModes) => {
									if(additionalModes && additionalModes.length) {
										additionalModes.forEach(mode => {
											if(availableModes.indexOf(mode) === -1) {
												availableModes.push(mode);
											}
										})
									}
									if(!fieldId) {
										// We always want to have a fieldID so that the render store entry will exist for refreshing
										// However, we don't want it to have available modes if the visibility and resizer overlays are inactive
										fieldId = recordIds && recordIds[0] ? recordIds[0] : '';
										if(!visibilityActive && !activeOverlays.includes('resizer')) {
											availableModes = [];
										}

									}
									return resolve({
										attachmentId,
										fieldId: fieldId,
										order,
										hidden,
										availableModes: availableModes,
										renderId: fieldRenderId,
										dataRecordId,
										dataTableSchemaName
									});
								})
								.catch(error => {
									InterfaceActions.notification({title: 'Error processing visibility action.', message: 'See console for more information.', level: 'error'});
									console.error('Error processing action in runAttachedFieldVisibility', error);
									return resolve({
										attachmentId,
										fieldId: recordIds && recordIds[0] ? recordIds && recordIds[0] : fieldId,
										order,
										hidden,
										availableModes: availableModes,
										renderId: fieldRenderId,
										dataRecordId,
										dataTableSchemaName
									});
								});
						}));
					} else if (recordIds && recordIds[0]) {
						// Get the visibility from the settings
						// We treat the first field as the 'primary' field and run its other visibility stuff
						let fieldId = recordIds[0];
						let fieldSettings = FieldSettingsStore.getSettingsFromAttachmentId(attachmentId, fieldId, parentComponentId);

						visibilityPromises.push(new Promise((resolve, reject) => {
							toExport.visibility.calculateAvailableModes(fieldSettings, modes, renderId, dataRecordId)
								.then(modes => {
									return resolve({
										attachmentId,
										fieldId: recordIds && recordIds[0] ? recordIds[0] : '',
										order,
										hidden,
										availableModes: modes,
										renderId: fieldRenderId,
										dataRecordId,
										dataTableSchemaName
									});
								})
								.catch(reject);
						}));
					}
				} else if(recordId) {
					// Get the visibility from the settings
						// We treat the first field as the 'primary' field and run its other visibility stuff
						let fieldId = recordId;
						let fieldSettings = FieldSettingsStore.getSettings(fieldId, parentComponentId);

						visibilityPromises.push(new Promise((resolve, reject) => {
							toExport.visibility.calculateAvailableModes(fieldSettings, modes, renderId, dataRecordId)
								.then(modes => {
									return resolve({
										attachmentId,
										fieldId: recordId,
										order,
										hidden,
										availableModes: modes,
										renderId: fieldRenderId
									});
								})
								.catch(reject);
						}));
				}
			});

			return Promise.all(visibilityPromises).then(results => {
				// Filter out missing fieldIds, in case the field from the custom visibility doesn't show at all
				results = results.filter(result => !!result.fieldId);
				return results;
			});
		},
		/**
		 * Similar to above, gets a set of rules for a field and determines the 
		 * appropriate available modes.
		 * 
		 * Used for identifying availalbeModes for fields
		 * 
		 * @param {string} fieldId The ID of the field whose rules are being evaluated
		 * @param {string} renderId
		 * @param {string} parentRecordId The ID of the field's parents (used to find local overrides)
		 * @returns string A Promise, which resolves with a comma-separated list of valid modes
		 */
		getAvailableModes: function (fieldId, parentComponentId, renderId, dataRecordId, dataTableSchemaName, attachmentId, recordSets) {
			attachmentId = attachmentId || fieldId;
			let fieldObj = FieldStore.get(fieldId);
			let settings = {};
			let visibilityRules = {};
			let availableModes = {
				view: true,
				edit: true
			};
			let pageAvailableModes = [];
			let pageRenderObj = RenderStore.getPageRenderObj(renderId);
			if (pageRenderObj) {
				let pageId = pageRenderObj.componentId;
				if (pageId) {
					pageAvailableModes = PageModeStore.getAvailableModes(pageId) || [];
					pageAvailableModes = pageAvailableModes && pageAvailableModes.toJS ? pageAvailableModes.toJS() : pageAvailableModes;
				}
			}

			let automationPromise = Promise.resolve(null);
			let visibilityAutomation;
			if (fieldObj) {
				// settings = ObjectUtils.getObjFromJSON(fieldObj.settings);
				settings = parentComponentId ?
					FieldSettingsStore.getSettings(fieldId, parentComponentId) :
					ObjectUtils.getObjFromJSON(fieldObj.settings);
				let renderObj = RenderStore.get(renderId) || {};
				let parentRenderId = renderObj.renderParentId;
				let parentRenderObj = parentRenderId ? RenderStore.get(parentRenderId) : undefined;
				if(parentRenderObj) {
					let componentType = parentRenderObj.componentType;
					let parentObj = componentType === 'field' ?
						FieldStore.get(parentComponentId) :
						PageStore.get(parentComponentId);
					parentObj = parentObj || {}; // On the off-chance the parent is missing
					visibilityAutomation = parentObj[attachmentId + '-visibility'];
					visibilityAutomation = visibilityAutomation ? ObjectUtils.getObjFromJSON(visibilityAutomation) : undefined;
					if(visibilityAutomation) {
						// Add dummy visibility in case only automation is set
						settings.visibility = settings.visibility || {};
						automationPromise = ActionProcessor.processAction({
							actionRecordId: attachmentId,
							actionTableSchemaName: 'visibility',
							hookId: attachmentId + '-visibility',
							actionParentRecordId: parentComponentId,
							actionParentTableSchemaName: parentRenderObj.componentType,
							action: 'output.displayField = {};\n' + visibilityAutomation.js,
							recordId: dataRecordId,
							renderId: renderId,
							tableSchemaName: dataTableSchemaName,
							runOnBackend: visibilityAutomation.runOnBackend,
							memUse: visibilityAutomation.memUse,
							// Override the starting context, because the store may not have updated by the time this runs
							overrideStartingRecord: true,
							namedContexts: recordSets
						})
							.then(output => {
								return output;
							});
					}
				}
			}
			if (settings.visibility) {
				visibilityRules = typeof settings.visibility === 'string' ?
					ObjectUtils.getObjFromJSON(settings.visibility) :
					settings.visibility;
				let isAuthenticated = !!AuthenticationStore.getUserId();
				return new Promise((resolve, reject) => {
					automationPromise
						.then((automationResults) => {
							if(automationResults) {
								availableModes = {};
								let {displayField: {enableEdit, enableView}} = automationResults;
								if(enableEdit) {
									availableModes.edit = true;
								}
								if(enableView) {
									availableModes.view = true;
								}
							}
							
							if (isAuthenticated) {
								let authenticatedUserRules = visibilityRules['authenticated'];
								if (!authenticatedUserRules) {
									// If no visibility rules are present for authenticated users,
									// the field should be visible to all users
									return resolve(Object.keys(availableModes));
								} else {
									// If we do NOT have automation results, then the available modes default
									// back to being empty unless otherwise specified
									availableModes = automationResults ? availableModes : {};
									if (authenticatedUserRules.view === '1') {
										availableModes.view = true;
									}
									if (
										(authenticatedUserRules.edit === '1' && pageAvailableModes.indexOf('edit') > -1) ||
										(authenticatedUserRules.add === '1' && pageAvailableModes.indexOf('add') > -1)
									) {
										availableModes.edit = true;
									}
									let userGroups = AuthenticationStore.getUserSecurityGroups() || [];
									userGroups.forEach(userGroup => {
										let groupRules = visibilityRules[userGroup] || {};
										if (groupRules.view === '1') {
											availableModes.view = true;
										}
										if ((groupRules.edit === '1' && pageAvailableModes.indexOf('edit') > -1) ||
											(groupRules.add === '1' && pageAvailableModes.indexOf('add') > -1)
										) {
											availableModes.edit = true;
										}
									});
									return resolve(Object.keys(availableModes));
								}
							} else {
								let nonauthenticatedUserRules = visibilityRules['nonauthenticated'];
								if (!nonauthenticatedUserRules) {
									// If no visibility rules are present for nonauthenticated users,
									// the field should be visible to all nonauthenticated users
									return resolve(availableModes);
								} else {
									// If we do NOT have automation results, then the available modes default
									// back to being empty unless otherwise specified
									availableModes = automationResults ? availableModes : {};
									if (nonauthenticatedUserRules.view === '1') {
										availableModes.view = true;
									}
									if (
										(nonauthenticatedUserRules.edit === '1') ||
										(nonauthenticatedUserRules.add === '1')
									) {
										availableModes.edit = true;
									}
									return resolve(Object.keys(availableModes));
								}
							}
						})
						.catch(reject);
				});
			} else {
				visibilityRules = typeof settings.visibilityRules === 'string' ?
					ObjectUtils.getObjFromJSON(settings.visibilityRules) :
					settings.visibilityRules;
				if (visibilityRules && Object.keys(visibilityRules).length) {
					let sortedRules = visibilityRules.allUsers.sort((ruleA, ruleB) => {
						if (ruleA.sequence > ruleB.sequence) {
							return -1;
						} else if (ruleA.sequence < ruleB.sequence) {
							return 1;
						} else {
							return 0;
						}
					});
					let visibilityPromises = sortedRules.map(rule => {
						let toReturn = {
							rule: rule,
							resultPromise: rule.query && rule.query !== 'always' ?
								toExport.query.processQueryV2(rule.query, [], renderId, dataRecordId, dataTableSchemaName) : Promise.resolve(['true'])
						};
						// return rule.query ? this.processQuery(rule.query, [], dataRecordId, dataTableSchemaName) : Promise.resolve(['true']);
						return toReturn;
					});
					let modePromises = this.resultRecursor(visibilityPromises, 0);
					return modePromises
						.then(results => {
							let availableModes = [];
							if (results.show) {
								availableModes.push('view');
							}
							if (results.edit) {
								availableModes.push('edit');
							}
							if (results.add && (!pageAvailableModes || (pageAvailableModes && pageAvailableModes.includes('add')))) {
								availableModes.push('edit');
							}
							return availableModes;
						});
					} else {
						return Promise.resolve(Object.keys(availableModes));
					}
			}

		},
		updateAvailableModes: function (fieldId, parentComponentId, renderId, dataRecordId, dataTableSchemaName) {
			return new Promise((resolve, reject) => {
				toExport.visibility.getAvailableModes(fieldId, 
					parentComponentId, renderId, dataRecordId, 
					dataTableSchemaName).then(availableModes => {
						FieldModeActions.setAvailableModes(renderId, availableModes);
						return resolve();
					}).catch(error => {
						reject(error);
					});
			});
		},
	},
	recordVariables: {
		buildNamedContexts: RecordVariableUtils.buildNamedContexts,
		recordSetRecursor: RecordVariableUtils.recordSetRecursor
	},
	api: {
		/**
		 * Creates a script tag in the DOM to make use of the source resources 
		 * @param {string} src Script we are trying to load
		 * @param {string} scriptTagId The id of the component
		 */
		loadScript: function (src, scriptTagId) {
			if(!scriptTagId) {
				scriptTagId = uuid.v4();
			}
			return new Promise(function (resolve, reject) {
				let existingTags = document.getElementById(scriptTagId);
				if(!existingTags) {
					var s;
					s = document.createElement('script');
					s.src = src;
					s.setAttribute('id', scriptTagId);
					s.onload = resolve;
					s.onerror = reject;
					document.head.appendChild(s);
				}
				else {
					return resolve(true);
				}
			});
		},
		/**
		 * Creates a style tag in the DOM to append CSS.
		 * @param {string} css The CSS to add to the style tag.
		 * @param {string} styleTagId The id of the component
		 */
		setStyle: function (css, styleTagId) {
			if(!styleTagId) {
				styleTagId = uuid.v4();
			}
			return new Promise(function (resolve, reject) {
				let existingTags = document.getElementById(styleTagId);
				if(!existingTags) {
					var s;
					s = document.createElement('style');
					s.setAttribute('type', 'text/css');
					s.setAttribute('id', styleTagId);
					s.innerHTML = css;
					document.head.appendChild(s);
				}
				return resolve(true);
				
			});
		},
	},
	UIUtils: {
		/**
		 * Convert a Field Selector to it's Field Schema Name, given an old or newer value.
		 * @param {string} value - Content to be sanitized
		 * @param {string} tableSchemaName Needed for older values to find the field
		 * @param {boolean} respectPartName Whether to include the part name in the return
		 * @returns {string} fieldSchemaName
		 */
		convertFieldSelectorToFieldSchemaName: (value, tableSchemaName, respectPartName) => {
			if (!value) {
				return '';
			}
			if(respectPartName === undefined) {
				respectPartName = true;
			}
			let fieldSchemaName = value || '';
			let displayColumnObj = {};

			// ssee if this field is a JSON stringified object...
			if (typeof value === 'string' && value.startsWith('{')) {
				displayColumnObj = ObjectUtils.getObjFromJSON(value);
			} else if (typeof value === 'object') {
				displayColumnObj = value;
			} else {
				let fieldObj = FieldStore.getByFieldSchemaName(value, tableSchemaName);
				let fieldId = fieldObj ? fieldObj.recordId : '';
				displayColumnObj = {
					'fieldId': fieldId
				};
			}

			let fieldObj = FieldStore.get(displayColumnObj.fieldId) || {};
			if(respectPartName) {
				fieldSchemaName = fieldObj.fieldSchemaName + (displayColumnObj.part ? '_' + displayColumnObj.part : '');
			}
			else {
				fieldSchemaName = fieldObj.fieldSchemaName;
			}
			return fieldSchemaName;
		},

		getResponsiveMode: UIUtils.getResponsiveMode,
		getSize: UIUtils.getSize,

		/**
		 * Output Sanitizer for HTML Content
		 * @param {string} html - Content to be sanitized
		 * @returns {string} cleanHtml - sanitized content 
		 */
		sanitizeHtml: (html) => {
			let tagsBlacklistObj = {
				base: true,
				body: true,
				head: true,
				html: true,
				link: true,
				meta: true,
				noscript: true,
				object: true,
				script: true,
				style: true,
				textarea: true
			};

			let whiteListAttributesArr = ['style', 'title', 'class'];

			return sanitizeHtml(html, {
				// Setting it to false, Allows all the Tags and Attributes 
				allowedTags: false,
				allowedAttributes: false,
				// This must be enabled and should include 'style' for `allowedStyles` to be processed
				// allowedAttributes: {
				// 	'*': whiteListAttributesArr
				// },
				// allowedStyles: {
				// 	'*': {
				// 		'width': [/^\d+(?:px|em|rem|%)$/]
				//		}
				// },
				/**
				 * Strips out frames(tags) that pass the check
				 * Sanitizer Package takes care of lower casing the frame names
				 */
				exclusiveFilter: (frame) => {
					return tagsBlacklistObj[frame.tag];
				},
				/**
				 * Transforms tags, attributes and value attributes
				 * Transformation includes
				 * - Replace a tag for another
				 * - Replace an attribute for another
				 * - Escape tags, attributes and their values based on:
				 * https://www.owasp.org/index.php/XSS_(Cross_Site_Scripting)_Prevention_Cheat_Sheet#A_Positive_XSS_Prevention_Model#XSS_Prevention_Rules_Summary
				 * https://www.owasp.org/index.php/XSS_Filter_Evasion_Cheat_Sheet 
				 * 
				 * Encoding Ruleset: 
				 * https://www.owasp.org/index.php/XSS_(Cross_Site_Scripting)_Prevention_Cheat_Sheet#A_Positive_XSS_Prevention_Model#Output_Encoding_Rules_Summary
				 */
				transformTags: {
					'*': (tagName, attributeObj) => {

						let newAttributesObj = {};

						// Sanitize Attribute Values 
						Object.keys(attributeObj).forEach((attributeName, index) => {
							// Grab the value for the attribute
							let attributeValue = attributeObj[attributeName];

							// Attribute is other than event Handlers or Command Events 
							if (!attributeName.startsWith('on') &&
								!attributeName.includes('command') &&
								!attributeName.includes('print')) {

								// if it does NOT detect it includes malicious Code, escape it  
								if (!attributeValue.toLowerCase().includes('javascript') &&
									// I cannot find a single instance of expression being an HTML injection concern
									// But it is causing issues when they want "expression" in the class name
									// so I am commenting it out as per "25161 - When configuring an Expression field with Render as HTML setting enabled, if any HTML attribute conta"
									// unless given instructions to the contrary
									// !attributeValue.toLowerCase().includes('expression') &&
									!attributeValue.toLowerCase().includes('eval(') &&
									//I can only find a reference to this attribute value being an issue with IE
									//and we no longer support it.  If we decide we want to limit url parameters then
									//we should probably change the check to url( or some other variant
									//This is being removed to resolve 30591 - Expression field with Render as HTML setting enabled,HTML attribute containing 'url' are stripped
									//!attributeValue.toLowerCase().includes('url') &&
									//Verify src does not point to our domain 
									!attributeValue.startsWith('/') &&
									!attributeValue.includes(window.location.hostname)) {

									// For now if the attribute is in the whiteListAttributesArr (['style']) 
									// do not encode it. 
									// In the future we want to still NOT encode it 
									// but also use the `allowedStyles` and `allowedAttributes` from the sanitize-html plugin
									// to evaluate/remove style properties that do not meet their safe standard values (Regex)
									if (whiteListAttributesArr.includes(attributeName)) {
										newAttributesObj[attributeName] = attributeValue;
									} else {
										//Escape Attribute's value 
										newAttributesObj[attributeName] = encodeURI(attributeValue);
									}
								}
							}
						});

						// Mitigate risk for special cases:
						if (tagName === 'embed') {
							newAttributesObj['allownetworking'] = 'internal';
							newAttributesObj['allowscriptaccess'] = 'never';

						}
						return {
							tagName: tagName,
							attribs: newAttributesObj
						};
					}
				}
			});
		}
	},
	time: {
		getTimezone: TimeUtils.getTimezone,
		/**
		 * Converts the date format from moment.js to date-fns for use with the React datepicker
		 * @param string moment dateFormat
		 * @return String representing this same date format, using the DateFNS equivalent characters
		 */
		 convertMomentFormatToDateFNS: function(momentFormat) {
			if(!momentFormat) {
				return momentFormat
			}
			let dateFNSFormat = momentFormat;
			let conversions = [
				// replace Y -> y for Year format
				{
					momentRegex: /Y/g,
					dateFNSCharacter: 'y'
				},
				// replace d -> i for Day of Week format
				{
					momentRegex: /[d]/g,
					dateFNSCharacter: 'i'
				},
				 // replace ii -> iiiiii specifically for the two character Day of Week
				{
					momentRegex: /ii/g,
					dateFNSCharacter: 'iiiiii'
				},
				// replace DDDD -> ttt - for the three-digit Day of Year
				{
					momentRegex: /DDDD/g,
					dateFNSCharacter: 'ttt'
				},
				// replace DDDo -> to - for the nth Day of Year
				{
					momentRegex: /DDDo/g,
					dateFNSCharacter: 'to'
				},
				// replace DDD -> t - for the Day of Year
				{
					momentRegex: /DDD/g,
					dateFNSCharacter: 't'
				},
				// replace D -> d for replacing all occurences to lowercase d
				{
					momentRegex: /[D]/g,
					dateFNSCharacter: 'd'
				},
				// replace t -> D for replacing all occurences to uppercase D (Day of Year)
				{
					momentRegex: /[t]/g,
					dateFNSCharacter: 'D'
				},
				// replace e -> c for the two character Day of Week (Locale) ** DOES NOT CURRENTLY WORK **
				{
					momentRegex: /[e]/g,
					dateFNSCharacter: ''
				},
				// replace E -> i for Day of Week (ISO)
				{
					momentRegex: /[E]/g,
					dateFNSCharacter: 'i'
				},
				// replace Wo -> Io Week of Year (ISO)
				{
					momentRegex: /Wo/g,
					dateFNSCharacter: 'Io'
				},
				// replace WW -> II Week of Year (ISO)
				{
					momentRegex: /WW/g,
					dateFNSCharacter: 'II'
				},
				// replace W -> I Week of Year (ISO)
				{
					momentRegex: /[W]/g,
					dateFNSCharacter: 'I'
				},
				// replace GG -> yy Week Year (ISO)
				{
					momentRegex: /GG/g,
					dateFNSCharacter: 'yy'
				},
				// replace GGGG -> y Week Year (ISO)
				{
					momentRegex: /GGGG/g,
					dateFNSCharacter: 'y'
				},
				// replace N -> G replace all occurences of N with G (Era Year)
				{
					momentRegex: /[N]/g,
					dateFNSCharacter: 'G'
				},
				// replace gg -> yy Week Year (2-digit year)
				{
					momentRegex: /gg/g,
					dateFNSCharacter: 'yy'
				},
				// replace gggg -> y Week Year (4-digit year)
				{
					momentRegex: /gggg/g,
					dateFNSCharacter: 'y'
				},
				// replace a -> aaa am pm
				{
					momentRegex: /[a]/g,
					dateFNSCharacter: 'aaa'
				},
				// replace A -> a AM PM
				{
					momentRegex: /[A]/g,
					dateFNSCharacter: 'a'
				},
				// replace X -> t Unix Timestamp
				{
					momentRegex: /[X]/g,
					dateFNSCharacter: 't'
				},
				// replace x -> T Unix Millisecond Timestamp
				{
					momentRegex: /[x]/g,
					dateFNSCharacter: 'T'
				},
				// replace Z -> XXX specifically for the two character Day of Week ** DOES NOT CURRENTLY WORK **
				{
					momentRegex: /[Z]/g,
					dateFNSCharacter: ''
				},
				// replace ZZ -> XXXX specifically for the two character Day of Week ** DOES NOT CURRENTLY WORK **
				{
					momentRegex: /ZZ/g,
					dateFNSCharacter: ''
				}
			];
			conversions.forEach((c) => {
				dateFNSFormat = dateFNSFormat.replace(c.momentRegex, c.dateFNSCharacter);
			});
			return dateFNSFormat;
		}
	},
	render: {
		/**
		 * Shortcut function to get the default data for a given renderId.
		 * 
		 * @param {string} renderId The renderId of the default data
		 * 
		 * @returns {object} Object of the form {dataRecordId, dataTableSchemaName}
		 */
		getRecordData(renderId) {
			let toReturn = {};
			if (renderId) {
				let renderObj = RenderStore.get(renderId) || {};
				toReturn = {
					dataRecordId: renderObj.dataRecordId,
					dataTableSchemaName: renderObj.dataTableSchemaName
				};
			}
			return toReturn;
		}
	},
	highcharts: {
		/**
		 * Loads up highcharts. Includes checking to verify whether highcharts exists in the window.
		 */
		load: function (params) {
			toExport.highChartsPromise = toExport.highChartsPromise ? toExport.highChartsPromise : Promise.resolve();
			if (typeof Highcharts !== 'undefined' && Highcharts &&
				(!params || !params.includeMore || Highcharts.seriesTypes.gauge) &&
				(!params || !params.includeMore || !params.includeSolidGauge || Highcharts.seriesTypes.solidgauge)) {
				// Just immediately return if everything we need has already been defined
				return toExport.highChartsPromise;
			}
			toExport.highChartsPromise = toExport.highChartsPromise
				.then(() => {
					if (typeof Highcharts === 'undefined' || !Highcharts) {
						return toExport.api.loadScript('https://cdn3.citizendeveloper.com/engine-build/high-charts/v8.2.2/gantt/highcharts-gantt.js')
							// .then(() => {
							// 	return toExport.api.loadScript('https://cdn3.citizendeveloper.com/engine-build/high-charts/v8.2.2/modules/series-label.js');
							// })
							.then(() => {
								return toExport.api.loadScript('https://cdn3.citizendeveloper.com/engine-build/high-charts/v8.2.2/gantt/modules/exporting.js');
							})
							.then(() => {
								return toExport.api.loadScript('https://cdn3.citizendeveloper.com/engine-build/high-charts/v8.2.2/modules/export-data.js');
							})
							.then(() => {
								return toExport.api.loadScript('https://cdn3.citizendeveloper.com/engine-build/high-charts/v8.2.2/modules/accessibility.js');
							})
							.then(() => {
								// Set timezone offset to that of the browser.
								let d = new Date();
								let n = d.getTimezoneOffset();
								Highcharts.setOptions({
									global: {
										timezoneOffset: n
									},
									lang: {
										thousandsSep: ','
									}
								});
							});
					}
				})
				.then(() => {
					if (params && params.includeMore && !Highcharts.seriesTypes.gauge) {
						return toExport.api.loadScript('https://cdn3.citizendeveloper.com/engine-build/high-charts/v8.2.2/highcharts-more.js')
					}
				})
				.then(() => {
					if (params && params.includeMore && params.includeSolidGauge && !Highcharts.seriesTypes.solidgauge) {
						return toExport.api.loadScript('https://cdn3.citizendeveloper.com/engine-build/high-charts/v8.2.2/modules/solid-gauge.js')
					}
				});

			return toExport.highChartsPromise;
		},
		fetchOptions: function(dataFieldId, chartType, renderId) {
			let chartConfigPropName = chartType + 'Config';
			let settings = FieldStore.get(dataFieldId);
			let chartConfig = settings[chartConfigPropName];
			let chartConfigArr = ObjectUtils.getObjFromJSON(chartConfig);
			let recordSets = {};
			if(chartConfigArr && Array.isArray(chartConfigArr)) {
				chartConfigArr.forEach(({query}) => {
					Object.assign(recordSets, RecordVariableUtils.buildNamedContexts(renderId, query));
				});
			}

			let request = {
				dataFieldId,
				chartType,
				namedContexts: recordSets,
				browserStorage: BrowserStorageStore.getStateJS(),
				currentContextObj: ContextStore.getState().toJS(),
			}

			return new Promise((resolve, reject) => {
				socketFetcher('gw/chartConfig-v2', JSON.stringify(request))
					.then(({response, responseCode, error}) => {
						if(responseCode >= 200 && responseCode < 300) {
							return resolve(response.result);
						} else {
							throw new Error(responseCode + 'Error: ' + error);
						}
					})
					.catch(error => {
						console.error('Error getting chart configuration', error);
						return reject(error);
					})
			});
		}
	},
	ReactInput: {
		templateParser,
		templateFormatter,
		parseDigit
	},
	PhoneNumberInput: {
		metadata,
		labels,
		formatPhoneNumber: function(value, format, metadata) {
			let PhoneNumber = parsePhoneNumber(value, metadata);
			if(!PhoneNumber) {
				return '';
			}

			return PhoneNumber.format(format);
		},
		formatPhoneNumberIntl: function(value, metadata) {
			let PhoneNumber = parsePhoneNumber(value, metadata);
			if(!PhoneNumber) {
				return '';
			}
			return PhoneNumber.format('INTERNATIONAL');
		},
		parsePhoneNumber,
		AsYouType,
		getCountries,
		Flags,
		// getExampleNumber,
		getExampleNumber: function(country, metadata) {
			return getExampleNumber(country, internationalPhoneNumberExamples, metadata);
		},
		getCountryCallingCode
	},
	address: {
		/**
		 * Helper function to get the geocode of an address
		 * 
		 * @param {object} value The value to pass through to the geocode service
		 * 
		 * @returns {Promise(<{result}>)}
		 */
		geocode: function (value) {
			if (!value) {
				return Promise.resolve();
			}
			return socketFetcher('gw/gcp-maps-geocode-service-v1', JSON.stringify(value));
		},

		/**
		 * Helper function to get the distance between two addresses
		 * 
		 * @param {number} lat1 The latitude of the first address
		 * @param {number} lat2 The latitude of the second address
		 * @param {number} lon1 The longitude of the first address
		 * @param {number} lon2 The longitude of the second address
		 * @param {string} units The units for which to get the distance. Defaults to miles.
		 * Only miles and meters currently available.
		 * 
		 * @returns {number} The distance between the two points in the given units
		 */
		geodistance: function (lat1, lat2, lon1, lon2, units) {
			let R = units === 'kilometers' ?
				6371 : // km
				3959; //miles
			let radLat1 = radians(lat1);
			let radLat2 = radians(lat2);
			let radLatDiff = radians(lat2 - lat1);
			let radLonDiff = radians(lon2 - lon1);

			let a = Math.sin(radLatDiff / 2) * Math.sin(radLatDiff / 2) +
				Math.cos(radLat1) * Math.cos(radLat2) *
				Math.sin(radLonDiff / 2) * Math.sin(radLonDiff / 2);
			let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); // Unitless

			let d = R * c; // Takes the units of R

			return d;
		}
	},
	file: {
		sanitizeFilename: RemoteFileStorage.sanitizeFilename
	},
	constants: constants
};

/**
 * Converts to radians
 *
 * @param {*} degrees
 * @return {*} 
 */
function radians(degrees) {
    return degrees * Math.PI / 180;
}

export default toExport;
