/* global citDev */
import React, { Component } from 'react';
import { Container } from 'flux/utils';

import { 
	AdminSettingsActions, 
	FieldActions,
	InterfaceActions,
} from '../../../actions';

import { 
	AdminSettingsStore, 
	FieldStore,
	RelationshipStore
} from '../../../stores';

import { 
	FieldUtils,
	UIUtils 
} from '../../../utils';

class FieldSettingsChooser extends Component {
	/**
	 * Creates instance of FieldSettingsChooser
	 */
	constructor(props) {
		super(props);
		this._getSettingsList = this._getSettingsList.bind(this);
		this._onDeleteHandler = this._onDeleteHandler.bind(this)
		this._onResetHandler = this._onResetHandler.bind(this)
		this._onSaveHandler = this._onSaveHandler.bind(this)
	}

	/**
	 * @static getStores - Loads the Stores to watch
	 * @returns {array}
	 */
	static getStores() {
		return [AdminSettingsStore, FieldStore];
	}

	/**
	 * Calculate the current state of this component
	 * @static
	 * @param {Object} prevState 
	 * @param {Object} prevState 
	 * @returns {Object} State!
	 */
	static calculateState(prevState, prevProps) {
		let recordId = AdminSettingsStore.getRecordId();
		let tableSchemaName = 'field';
		let fieldObj = FieldStore.get(recordId) || {};
		let selectedSetting = AdminSettingsStore.getSettingSchemaName();
		let componentSettings = [];
		let isNew = fieldObj.new;

		let fieldSettings =
			[
				{ recordId: '27fdf34c-252d-4196-bbd8-33486e18db64',
				  sortOrder: 0, required: true  }, // Field Label
				{ recordId: '2423aa7a-8069-485c-956b-c6a1b3bada63',
				  sortOrder: 1, required: true }, // Field Type
				{ recordId: 'aeb2dc2c-3153-4a27-8820-cd3e1379d29c',
				  sortOrder: 2, required: true }, // Table
				{ recordId: '11ccf3bb-8c30-43c8-8c32-76f71c809f5e',
				  sortOrder: 3, required: true }, // Technical Name
			];

		let hasMissingRequiredSettings = false;
			
		fieldSettings.forEach(fieldSetting => {
			let settingId = fieldSetting.recordId;
			let settingObj = FieldStore.get(settingId) || {};
			let settingSchemaName = settingObj.fieldSchemaName;

			// Empty/No Value values.
			let valueObj = {};
			if (fieldObj[settingSchemaName]) {
				valueObj.value = fieldObj[settingSchemaName];
			}

			// Check to see if this setting is required, and has no value
			if(fieldSetting.required && (!valueObj.value || valueObj.length === 0)) {
				hasMissingRequiredSettings = true;
			}

			componentSettings.push({
				// Basics
				fieldId: settingId,
				fieldObj: settingObj,

				// Label, Value and Sorting..
				label: settingObj.fieldLabel,
				valueObj: valueObj,
				sortOrder: fieldSetting.sortOrder,

				// Used for styling
				settingSelected: (selectedSetting === settingSchemaName),
				required: fieldSetting.required
			});
		});

		// Sort the results by setting name
		componentSettings.sort(function (a, b) {
			if (a.sortOrder !== b.sortOrder) {
				return a.sortOrder - b.sortOrder;
			} else if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			return 1;
		});

		return {
			componentSettings: componentSettings,
			recordId: recordId,
			tableSchemaName: tableSchemaName,
			settingsHidden: AdminSettingsStore.getSettingsListHidden(),
			hasMissingRequiredSettings: hasMissingRequiredSettings,
			isNew: isNew,
			fieldTypeIcon: FieldUtils.getFieldTypeIcon(recordId)
		}
	}

	/**
	 * Render the component
	 * @returns JSX
	 */
	render() {
		let { componentSettings, fieldTypeIcon, hasMissingRequiredSettings } = this.state;

		if (!componentSettings.length) {
			return (
				<div key="settings" className='settings-list-wrapper'>
					<div style={{ marginTop: '8px' }} className='no-settings-found'>No Settings Found</div>
				</div>
			);
		}

		let settingsList = this._getSettingsList(componentSettings); // component settings

		/* The ids for these buttons are set in a specific way, so that Ctrl-S and Ctrl-R (UIUtils.onkeyDown method) can find these buttons */
		let buttons = [
			<button id={'field-addSave'} key="submit" className="btn btn-primary btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Save" title="Save (Ctrl-S)" onClick={this._onSaveHandler}>Save</button>,
			<button id={'field-addReset'} key="reset" className="btn btn-warning btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Reset" onClick={this._onResetHandler}>Reset</button>,
			<button key="delete" className="btn btn-danger btn-lg setting-list-button mr-0 d-flex justify-content-center" form="appearance-form" aria-label="Delete" onClick={this._onDeleteHandler}>Delete</button>
		];

		let requiredMarker = null;
		if(hasMissingRequiredSettings) {
			requiredMarker = <div className="required-marker mx-4 d-flex"><i className="fa fa-circle d-flex align-items-center" aria-hidden="true"></i> <h4 className="ml-2">Required</h4></div>;
		}

		return [
			<div key="settings" className='settings-list-wrapper'>
				<ul key="list" className="nav flex-column">
					{settingsList && settingsList.length
						?   <li className="settings-list-header">
								<div className="d-flex align-items-center">
									<img height="22" width="22" className="mr-2" src={fieldTypeIcon} alt="" />
									<h3>Field Settings</h3>
								</div>
							</li>
						: null
					}
					{settingsList && settingsList.length
						? settingsList
						: <div style={{ marginTop: 0 }} className='no-settings-found'>No Settings Found</div>
					}
					{ requiredMarker }
				</ul>
			</div>,
			<div key="buttons" className="btn-wrapper appearance-btn-wrapper">
				{buttons}
			</div>
		];
	}

	/**
	 * Returns an array of list item elements
	 * @param {array} settingsArr array of settings
	 * @returns { array } array of <li> settings elements
	 */
	_getSettingsList(settingsArr) {

		let { fieldType } = this.state;

		// iterate over the settings and return an array of list items
		return settingsArr.map(setting => {
			let labelClassNames = 'setting-label';
			if (setting.settingSelected) {
				labelClassNames += ' selected';
			}

			let componentName = '';
			if(setting && setting.fieldObj && setting.fieldObj.viewVariant) {
				componentName = setting.fieldObj.viewVariant;
			} else {
				componentName = FieldStore.getDefaultVariantComponentName(
					setting.fieldId, 'view', setting.fieldObj.fieldTypeId);
			}

			let componentProps = setting.fieldObj;
			componentProps.value = setting.valueObj.value;
			componentProps.fieldType = fieldType;

			let valueDisplay = <h5 className={`settings-list-value ${componentName}`}>
					{(componentProps.value ? React.createElement(citDev[componentName], componentProps, null) : null)}
				</h5>;

			let requiredMarker = null;
			if(setting.required && (!setting.valueObj.value || setting.valueObj.value.length === 0)) {
				requiredMarker = <div style={{ left: '-14px', top: '2px' }} className="required-marker position-absolute"><i className="fa fa-circle fa-1" aria-hidden="true"></i></div>;
			}

			return (<li key={setting.fieldId} className={"nav-item d-flex flex-column justify-content-center " + (setting.settingSelected ? 'setting-selected' : '')}>
				<div className="nav-link" onClick={this._onSettingClick.bind(this, setting.fieldId)}>
					{/* Temporarily removed the following div */}
					{/* <div className={"setting-icon " + iconClassNames} /> */}
					{/* The class below 'setting-text-container' has had the padding-left: 1.7rem removed */}
					{/* Add this back in when the setting-icon is used again */}
					<div className="d-flex setting-text-container">
						<div className="w-50 setting-label-container">
							<div className={labelClassNames}>{requiredMarker}<h4>{setting.label}</h4></div>
							<div className="setting-pattern-list">{setting.mostRecentPattern}</div>
						</div>
						<div className="w-50 d-flex justify-content-end setting-value-container">
							<div className={`text-right setting-pattern-list setting-value align-self-center ${componentName}`}>
								{valueDisplay}
							</div>
							{setting.valueObj.source ?
								<div className={"setting-scope badge badge-pill " + setting.valueObj.badgeTypeClass + " align-self-center"}>
									{setting.valueObj.source}
								</div>
								: null
							}
						</div>
					</div>
				</div>
			</li>);
		});
	}

	/**
	 * _onDeleteHandler - Deletes the object from the store and the database.
	 *
	 * @param  {object} event
	 */
	_onDeleteHandler(event) {
		event.preventDefault();
		let { recordId, isNew } = this.state;
		let confirmRemove = confirm('Are you sure you want to delete this Field?');
		if (confirmRemove) {
			// Display notification to user
			let id = InterfaceActions.stickyNotification({ 'level': 'warning', 'message': 'Deleting Field...' });
			
			// // Push to database
			if(isNew) {
				InterfaceActions.clearStickyNotification(id);
				UIUtils.closeSettingsPanel();
				FieldActions.deleteFromStore(recordId);
			} else {
				FieldActions.deleteFromDatabasePromise(recordId).then(() => {
					InterfaceActions.clearStickyNotification(id);
					UIUtils.closeSettingsPanel();
					FieldActions.deleteFromStore(recordId);
				}).catch(error => {
					InterfaceActions.clearStickyNotification(id);
					InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to delete Field' });
					console.error('Unable to delete Field:', error);
				})
			}
		}
	}

	/**
	 * _onResetHandler - Calls API to retreive data to reset value in store
	 *
	 * @param  {object} event
	 */
	_onResetHandler(event) {
		event.preventDefault();
		let { recordId } = this.state;
		// Display notification to user
		InterfaceActions.notification({ 'level': 'success', 'message': 'Resetting Field...' });
		// Pull from database, therefore resetting it
		FieldActions.pullFromDatabase(recordId);
	}

	/**
	 * _onSaveHandler - retrieves settings object and calls API to save data
	 *
	 * @param  {object} event
	 */
	_onSaveHandler(event) {
		let { recordId } = this.state;
		let fieldObj = FieldStore.get(recordId);
		let valid = true;
		let targetSettingName = '';
		let targetSettingId = '';

		// Field Label
		if(!fieldObj.fieldLabel || fieldObj.fieldLabel.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Field Label is required for a Field.' });
			targetSettingName = 'fieldLabel';
			targetSettingId = '27fdf34c-252d-4196-bbd8-33486e18db64';
		}

		// Field Type
		if(!fieldObj.fieldType || fieldObj.fieldType.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Field Type is required for a Field.' });
			if(!targetSettingName) {
				targetSettingName = 'fieldType';
				targetSettingId = '2423aa7a-8069-485c-956b-c6a1b3bada63';
			}
		}

		// Table
		if(!fieldObj.tableSchemaName || fieldObj.tableSchemaName.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Table is required for a Field.' });
			if(!targetSettingName) {
				targetSettingName = 'tableSchemaName';
				targetSettingId = 'aeb2dc2c-3153-4a27-8820-cd3e1379d29c';
			}
		}

		// Technical Name
		let fsnSettingName = 'fieldSchemaName';
		let fsnSettingId = '11ccf3bb-8c30-43c8-8c32-76f71c809f5e';
		if(!fieldObj.fieldSchemaName || fieldObj.fieldSchemaName.length === 0) {
			valid = false;
			InterfaceActions.notification({ 'level': 'error', 'message': 'Technical Name is required for a Field.' });
			if(!targetSettingName) {
				targetSettingName = fsnSettingName;
				targetSettingId = fsnSettingId;
			}
		} else {
			// Validate the technical name

			// starting with a-z or A-Z, and containing letters a-z, A-Z, numbers and _ characters
			// Thanks ChatGPT!!
			const regex = /^[a-zA-Z][a-zA-Z0-9_]*$/;
			// A max of 60 characters
			if (fieldObj.fieldSchemaName.length >= 60) {
				valid = false;
				InterfaceActions.notification({ 'level': 'error', 'message': 'Technical Name too long, must be a max of 60 characters.' });
				if(!targetSettingName) {
					targetSettingName = fsnSettingName;
					targetSettingId = fsnSettingId;
				}
			} else if(!regex.test(fieldObj.fieldSchemaName)) {
				valid = false;
				InterfaceActions.notification({ 'level': 'error', 'message': 'Technical Name contains invalid characters.' });
				if(!targetSettingName) {
					targetSettingName = fsnSettingName;
					targetSettingId = fsnSettingId;
				}
			}

			// Make sure its unique
			let existingFields = FieldStore.getByTableSchemaName(fieldObj.tableSchemaName);
			// If we found a matching table, and its NOT us...
			existingFields.forEach(existingField => {
				if(existingField.recordId && // If the field has a record ID...
					existingField.recordId !== recordId && // and it does NOT match our new record ID...
					existingField.fieldSchemaName === fieldObj.fieldSchemaName) { // but its Schema Name DOES match ours...
						valid = false;
						InterfaceActions.notification({ 'level': 'error', 'message': 'Another Field with this Technical Name already exists on this Table: ' + existingField.fieldLabel});
						if(!targetSettingName) {
							targetSettingName = fsnSettingName;
							targetSettingId = fsnSettingId;
						}
					}
				});
				
			let existingRelationship = RelationshipStore.getByRelationSchemaName(fieldObj.fieldSchemaName);
			// If we found a matching relationship...
			if(existingRelationship && existingRelationship.relationshipSchemaName) {
				valid = false;
				InterfaceActions.notification({ 'level': 'error', 'message': 'A Relationship with this Technical Name already exists: ' + existingRelationship.ltorLabel});
				if(!targetSettingName) {
					targetSettingName = fsnSettingName;
					targetSettingId = fsnSettingId;
				}
			}
		}

		if(!valid) {
			AdminSettingsActions.onSettingChange(targetSettingName, targetSettingId);
		} else {
			delete fieldObj.new;

			// Trim all of the values...
			Object.keys(fieldObj).forEach(settingName => {
				fieldObj[settingName] = fieldObj[settingName].trim();
			})

			let id = InterfaceActions.stickyNotification({ 'level': 'success', 'message': 'Updating Field...' });
			// Push to database
			FieldActions.pushToDatabasePromise(fieldObj).then(() => {
				// Push the fact we're no longer new...
				FieldActions.pullFromDatabasePromise(recordId, true).then(() => {
					// Clear the Sticky about saving.
					InterfaceActions.clearStickyNotification(id);
					UIUtils.openSettingsPanel('appearance', recordId, 'field');

					// Select FieldLabel
					AdminSettingsActions.onSettingChange('fieldLabel', '27fdf34c-252d-4196-bbd8-33486e18db64');

					// Unhide the settings, always
					AdminSettingsActions.onSettingsListHideChange(false);
				}).catch(error => {
					InterfaceActions.clearStickyNotification(id);
					InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save Field.' });
					console.error('Unable to reset Field after save:', error, fieldObj);
				})
			}).catch(error => {
				InterfaceActions.clearStickyNotification(id);
				InterfaceActions.notification({ 'level': 'error', 'message': 'Unable to save Field.' });
				console.error('Unable to save Field:', error, fieldObj);
			})
		}
	}


	/**
	 * Click on a Setting and update the admin settings store.
	 * 
	 * @param {string} settingFieldId
	 * @param {Object} event 
	 */
	_onSettingClick(settingFieldId, event) {
		event.preventDefault();

		let settingFieldObj = FieldStore.get(settingFieldId);
		let settingSchemaName = settingFieldObj.fieldSchemaName;

		// Expand the Settings Panel
		AdminSettingsActions.onSettingsListHideChange(false);
		if (AdminSettingsStore.getSettingSchemaName() === settingSchemaName) {
			AdminSettingsActions.onSettingChange('', '');
		} else {
			AdminSettingsActions.onSettingChange(settingSchemaName, settingFieldId);
		}
	}
}
const container = Container.create(FieldSettingsChooser);
export default container;