import React, { Component } from 'react';
import speechToTextUtils from '../../../utils/speech-to-text-utils';

/**
 * Microphone component
 * 
 * @class MicrophoneComponent
 * @extends {Component}
 */
class MicrophoneComponent extends Component {
	/**
	 * Creates an instance of MicrophoneComponent.
	 * @param {any} props 
	 * 
	 * @memberOf MicrophoneComponent
	 */
	constructor(props) {
		super(props);
		this.state = {
			recording: false,
			MicrophoneComponent: null
		};

		this.onClick = this.onClick.bind(this);
		this.onKeyPress = this.onKeyPress.bind(this);
		this.onStart = this.onStart.bind(this);
		this.onStop = this.onStop.bind(this);
		this.onUtteranceEnd = this.onUtteranceEnd.bind(this);
	}

	/**
	 * Starts recording
	 */
	onStart() {
		this.setState({
			recording: true
		});
		if(this.props.onStart) {
			this.props.onStart();
		}
		speechToTextUtils.initRecording((data) => {
			if(this.props.onUpdate) {
				this.props.onUpdate(data);
			}	
		}, this.onUtteranceEnd, (error) => {
			console.error('Error when recording', error);
			this.setState({recording: false});
			// No further action needed, as this already closes itself on error
		});
	}

	/**
	 * Stops recording
	 */
	onStop() {
		this.setState({recording: false});
		speechToTextUtils.stopRecording();
		if(this.props.onStop) {
			this.props.onStop();
		}
	}

	/**
	 * Stops recording and runs the callback function on utterance end
	 * (e.g., when someone stops talking.)
	 */
	onUtteranceEnd() {
		this.setState({recording: false}, () => {
			if(this.props.onUtteranceEnd) {
				this.props.onUtteranceEnd();
			}
		});
		// No further action needed, as this already closes itself on utterance end
	}

	/**
	 * Handles clicking on the microphone
	 */
	onClick() {
		let {recording} = this.state;
		if(recording) {
			this.onStop();
		} else {
			this.onStart();
		}
	}

	/**
	 * Stops recording when the component is about to unmount
	 */
	componentWillUnmount() {
		let {recording} = this.state;
		if(recording) {
			this.onStop();
		}
	}

	/**
	 * Handles key presses when focused on the microphone
	 * (e.g., allows keyboard users to begin recording)
	 * 
	 * @param {object} event
	 */
	onKeyPress({charCode} = event) {
		event.preventDefault();
		// If key is enter or space
		if (charCode === 13 || charCode === 32) {
			this.onClick();
		}
	}

	/**
	 * render - NLPBuilder
	 *
	 * @return {JSX}
	 */
	render() {
		return (
			<span className={this.props.className}>
				<span title={this.state.recording ? 'Recording Audio. Click to finish.' : 'Click to record audio.'} role='button' tabIndex='0' onClick={this.onClick} onKeyPress={this.onKeyPress} className={"fa " + (this.state.recording ? 'fa-microphone text-info' : 'fa-microphone')}>
					<span style={{cursor: 'pointer'}} className='sr-only'>
						{this.state.recording ? 'Recording Audio. Click to finish.' : 'Click to record audio.'}
					</span>
				</span>
			</span>
		);
	};
}
export default MicrophoneComponent;
