/**
 * This index is used to generate the -prod bundle, which is used for testing and production CitizenDeveloper.com installations
 */

// React Core
import React, { Component, createContext, useContext, useRef, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';

// Flux Core
import {Container} from 'flux/utils';
import Immutable from 'immutable';
window.Immutable = Immutable;

// Routing
import { Router, Route, Switch } from 'react-router-dom';
import history from './utils/history';

// CAPTCHA
import ReCAPTCHA from "react-google-recaptcha";

// Signature Pad
import SignatureCanvas from 'react-signature-canvas';

// Used by map components for pin clustering
import Supercluster from 'supercluster';

// Determine if the browser we're on supports generators
window.supportsGenerators = true;
try {
	// eslint-disable-next-line
	eval("(function *(){})");
} catch(err) {
	window.supportsGenerators = false;
}

// "Pages"
// import AccessDenied from './pages/access-denied.react';
// import Home from './pages/home.react';
import NotFound from './pages/not-found.react';
import Page from './pages/page.react';
import Login from './pages/login.react';

// Actions
// import AdminSettingsActions from './actions/admin-settings-actions';
import AuthenticationActions from './actions/authentication-actions';
import ContextActions from './actions/context-actions';
import ConsumableCreditsActions from './actions/consumable-credits-actions';
import ConsumableCreditsAPIActions from './actions/consumable-credits-api-actions';
import InterfaceActions from './actions/interface-actions';
import LocalContextActions from './actions/local-context-actions';
import QueryActions from './actions/query-actions';
import RecordActions from './actions/record-actions';
import RecordSetActions from './actions/record-set-actions';
import ListActions from './actions/list-actions';
import RenderActions from './actions/render-actions';
// Stores
import ContextStore from './stores/context-store';
import ConsumableCreditsStore from './stores/consumable-credits-store';
import ProcessingStore from './stores/processing-store';
import FieldSettingsStore from './stores/field-settings-store';
import LocalContextStore from './stores/local-context-store';
import FieldModesStore from './stores/field-modes-store';
import PageModeStore from './stores/page-mode-store';
import QueryStore from './stores/query-store';
import RecordStore from './stores/record-store';
import RecordSetStore from './stores/record-set-store';
import ListStore from './stores/list-store';
import RenderStore from './stores/render-store';
// APIs
import RecordsAPI from './apis/records-api';

// Core Stores
import FieldStore from './stores/field-store';
import FieldActions from './actions/field-actions';
import FieldTypeStore from './stores/field-type-store';
import FieldTypeActions from './actions/field-type-actions';
import LogicFunctionStore from './stores/logic-function-store';
import LogicFunctionActions from './actions/logic-function-actions';
import MetadataStore from './stores/metadata-store';
import MetadataActions from './actions/metadata-actions';
import PageStore from './stores/page-store';
import PageActions from './actions/page-actions';
import RelationshipStore from './stores/relationship-store';
import RelationshipActions from './actions/relationship-actions';
import TableStore from './stores/table-store';
import TableActions from './actions/table-actions';
import AuthenticationStore from './stores/authentication-store';
// End Core Stores

// Discussion Store
import DiscussionStore from './stores/discussion-store';
// import DiscussionActions from './actions/discussion-actions';
// End Discussion Store

// React Tooltip for Descriptive Text
import ReactTooltip from 'react-tooltip';

// Load the React Number Format
import { NumericFormat } from 'react-number-format';

// Processors
import ActionProcessor from './utils/action-processor';
import ExpressionProcessor from './utils/expression-processor';

/* Component dependencies */
import { Calendar, momentLocalizer } from 'react-big-calendar';

let BigCalendar = Calendar;
BigCalendar.momentLocalizer = momentLocalizer;
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { format } from 'date-fns'
import ReactDataGrid, { SelectColumn, Row } from 'react-data-grid';
import GoogleMapReact from 'google-map-react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ReactInput from 'input-format/react';
import { Editor as RichTextEditor } from '@tinymce/tinymce-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Countdown from 'react-countdown';

import uuid from 'uuid';
import GridComponent from './components/grid-component.react';
import { SketchPicker, SwatchesPicker, TwitterPicker } from 'react-color';
import Steps from 'react-steps';
import ReactSelect from 'react-select';
import ReactWindowedSelect, {createFilter} from '@dmclain-citizendeveloper/citdev-windowed-select';
import ReactTether from 'react-tether';
import ReactGridLayout, {WidthProvider, Responsive} from '@dmclain-citizendeveloper/citdev-react-grid-layout';
import GridItem from './components/grid-item.react';
import {GridItem as GridItemView, GridLayout as GridLayoutView} from './components/grid-layout-view.react.js';
import sizeMe from 'react-sizeme';

import { BlockUtils, FieldComponents, FieldUtils, InstallationUtils, ObjectUtils, PageUtils, TableUtils, UIUtils } from './utils';
import GridHeightUtils from './utils/grid-height-utils';
import FieldContainer from './components/field-container.react';

/* Load the React DatePicker CSS */
require('react-datepicker/dist/react-datepicker.css');

/* Load the React Big Calendar CSS */
require('react-big-calendar/lib/css/react-big-calendar.css');

// Setup the citDev object overrides
let citDev = window.citDev || {};

// Setup interface actions
citDev.interface = function(action, parameters) {
	InterfaceActions[action].apply(this, [parameters]);
}

// Setup fieldComponents
citDev.fieldComponents = FieldComponents;
citDev.installation = InstallationUtils;

// Re-attach to the window.
window.citDev = citDev;

/* Used by the Fieldtype Bundle - Community Fields */
window.BigCalendar = BigCalendar;
window.ObjectUtils = ObjectUtils;
window.BlockUtils = BlockUtils;
window.Component = Component;
window.createContext = createContext;
window.useContext = useContext;
window.useRef = useRef;
window.useLayoutEffect = useLayoutEffect;
window.Container = Container;   // Used in List
window.DatePicker = DatePicker;
window.FieldContainer = FieldContainer; // Tab Content needs to render child field containers
window.FieldSettingsStore = FieldSettingsStore; //Used in List
window.FieldUtils = FieldUtils;
window.GridComponent = GridComponent;
window.GridItem = GridItem;
window.GridItemView = GridItemView;
window.GridLayoutView = GridLayoutView;
window.LocalContextStore = LocalContextStore;   // Used in List
window.LocalContextActions = LocalContextActions;   // Used in List
window.moment = moment;
window.dateFns = format;
window.PageUtils = PageUtils;
window.ReactGridLayout = WidthProvider(ReactGridLayout);
window.ReactResponsiveGridLayout = Responsive;
window.ReactSelect = ReactSelect;
window.ReactWindowedSelect = ReactWindowedSelect;
window.ReactWindowedSelectUtils = {createFilter};
window.ReactTether = ReactTether;
window.RecordsAPI = RecordsAPI;
window.RecordSetActions = RecordSetActions;
window.ListActions = ListActions;
window.RecordSetStore = RecordSetStore;
window.ListStore = ListStore;
window.RenderActions = RenderActions;
window.RenderStore = RenderStore;
window.sizeMe = sizeMe;
window.SketchPicker = SketchPicker;
window.Steps = Steps;
window.SwatchesPicker = SwatchesPicker;
window.TwitterPicker = TwitterPicker;
window.UIUtils = UIUtils;
window.LogicFunctionStore = LogicFunctionStore;
window.Cropper = Cropper;
window.ReactInput = ReactInput;
window.ReCAPTCHA = ReCAPTCHA;
window.SignatureCanvas = SignatureCanvas;
window.Supercluster = Supercluster;

window.React = React;
window.ReactDOM = ReactDOM;
window.ReactDataGrid = ReactDataGrid;   // Used in List
window.SelectColumn = SelectColumn;
window.Row = Row;
window.RichTextEditor = RichTextEditor; // Used in new rich text component
window.GoogleMapReact = GoogleMapReact; // Used in map component
window.uuid = uuid;

window.DragDropContext = DragDropContext;              // Used in List
window.Droppable = Droppable;          // Used in List
window.Draggable = Draggable;          // Used in List
window.Countdown = Countdown;		// Used in Countdown
window.GridHeightUtils = GridHeightUtils;

// Context and Records
window.ContextStore = ContextStore;
window.ConsumableCreditsActions = ConsumableCreditsActions;
window.ConsumableCreditsStore = ConsumableCreditsStore;
window.ProcessingStore = ProcessingStore;
window.RecordStore = RecordStore;
window.RecordActions = RecordActions;

// React Tooltip
window.ReactToolTip = ReactTooltip;

// React Number Format
window.NumericFormat = NumericFormat;

// Attach core stores
window.FieldStore = FieldStore;
// window.FieldActions = FieldActions;
window.FieldTypeStore = FieldTypeStore;
// window.FieldTypeActions = FieldTypeActions;
window.MetadataStore = MetadataStore;
// window.MetadataActions = MetadataActions;
// window.PageActions = PageActions;
window.PageModeStore = PageModeStore;
window.PageStore = PageStore;
window.FieldModesStore = FieldModesStore;
// window.PatternStore = PatternStore;
// window.PatternActions = PatternActions;
window.RelationshipStore = RelationshipStore;
// window.RelationshipActions = RelationshipActions;
window.TableStore = TableStore;
// window.TableActions = TableActions;
// End core stores.

// Attach Discussion Store
window.DiscussionStore = DiscussionStore;
// End Discussion Store

// Stores require for expressions
window.AuthenticationStore = AuthenticationStore;

// Attach processors (used in community components that run actions on children.. like lists.)
window.ActionProcessor = ActionProcessor;
window.ExpressionProcessor = ExpressionProcessor;

// Used in Attached Fields Setting.
window.BlockUtils = BlockUtils;
window.FieldUtils = FieldUtils;
window.PageUtils = PageUtils;
window.TableUtils = TableUtils;

// Used in NLP Effects, Settings Popup and during action processing
window.InterfaceActions = InterfaceActions;

// Used in NLP Effects and on Settings Popup
// window.AdminSettingsActions = AdminSettingsActions;

// Used in Settings Popup
// window.ToolboxStore = ToolboxStore;
// window.QueryActions = QueryActions;
window.QueryStore = QueryStore;		// Why in Prod?
// window.AdminSettingsStore = AdminSettingsStore;
// window.NLPBuilderDictionariesStore = NLPBuilderDictionariesStore;

// Handle window reloading
window.onbeforeunload = function() {
	if(ProcessingStore.getRunningActions().size) {
		var message = "Actions are currently processing in your browser. If you navigate away before they are finished, you will stop these actions. Are you sure you want to navigate away from this page?\n\nPress OK to continue or Cancel to stay on the current page.";
		return message;
	}

	// Check if there are any unsaved records on this page
	let hasDirty = RenderStore.hasDirtyRecords(ContextStore.getPageRenderId());

	if(hasDirty) {
		let message = "Changes you made may not be saved.";
		return message;
	}
}

var optimizedResize = (function() {

    var callbacks = [],
        running = false;

    // fired on resize event
    function resize() {

        if (!running) {
            running = true;

            if (window.requestAnimationFrame) {
                window.requestAnimationFrame(runCallbacks);
            } else {
                setTimeout(runCallbacks, 66);
            }
        }

    }

    // run the actual callbacks
    function runCallbacks() {

        callbacks.forEach(function(callback) {
            callback();
        });

        running = false;
    }

    // adds callback to loop
    function addCallback(callback) {

        if (callback) {
            callbacks.push(callback);
        }

    }

    return {
        // public method to add additional callback
        add: function(callback) {
            if (!callbacks.length) {
                window.addEventListener('resize', resize);
            }
            addCallback(callback);
        }
    }
}());

var setResponsiveMode = function() {
	// Because we have no grids yet, I believe this is appropriate.
	let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	// AdminSettingsActions.onCenterColumnResize({ 'width': width});
	let mode = UIUtils.getResponsiveMode(width);
	ContextActions.onResponsiveModeChange(mode);
};
//Call it once to initialize the mode
setResponsiveMode();


/**
 * domReady - renders routes when DOM is ready
 */
function domReady() {
	// Store the landingRef in case we are redirected to the login page.
	sessionStorage.setItem('targetHref', window.location.href);

	// start process
	optimizedResize.add(function() {
		setResponsiveMode()
	});
	
	
	// Retrieve application data from body attribute
	let myBody = document.querySelector('body').dataset;

	if (!myBody.citdevDefaultHomePageId) {
		//Default fall back
		myBody.citdevDefaultHomePageId = 'e6dd74fe-a49a-40c3-bfd9-09eb75510563';
	}
	
	if (myBody.citdevPageId) {
		history.replace(history.location.pathname, {
			pageId: myBody.citdevPageId, 
			recordId: myBody.citdevRecordId, 
			tableSchemaName: myBody.citdevTableSchemaName
		});
	} else if (history.location.pathname === '/' && myBody.citdevDefaultHomePageId) {
		history.replace(history.location.pathname, {
			pageId: myBody.citdevDefaultHomePageId
		});
	}

	// Figure out the base path for our back end.
	let basePath = 'https://' + window.location.host;
	if(myBody.citdevDefaultapigwoverride) { 
		basePath = 'https://' + myBody.citdevDefaultapigwoverride + '.citizendeveloper.com';
	}

	let portRegexp = new RegExp(/:\d+$/);
	let portMatches = portRegexp.exec(basePath);
	if (portMatches) {
		//if the base path has a port number then this is a webpack dev server
		console.log('Dev Server Detected')
		basePath = basePath.replace(portMatches[0], '');
	}
	
	ContextActions.onApplicationInit(
		myBody.citdevApplicationid,
		myBody.citdevInstallationid,
		myBody.citdevInstallationversion,
		myBody.citdevInstallationrole,
		myBody.citdevUrlMedia,
		myBody.citdevUrlFontawesome,
		myBody.citdevUrlExpression,
		myBody.citdevUrlBlockly,
		myBody.citdevUrlEngine,
		myBody.citdevUrlCommunity,
		myBody.citdevMdgwMode,
		myBody.citdevDefaultHomePageId,
		myBody.citdevDefaultLoginPageId,
		basePath
	);
	
	if(myBody.citdevDefaultapigwoverride) {
		sessionStorage.setItem('defaultAPIGWOverride', myBody.citdevDefaultapigwoverride);
	}
	let userIp = '';
	if (myBody.citdevForwardedFor) {
		// let newBuffer = new Buffer(myBody.citdevForwardedFor, 'base64');
		// userIp = newBuffer.toString('ascii');	
		userIp = atob(myBody.citdevForwardedFor);
	}
	AuthenticationActions.onSetUserIP(userIp);	
	if (myBody.citdevHasAuthCreds === 'true' || myBody.citdevHasAuthCreds === true)	{
		AuthenticationActions.haveAuthCreds(true);
	}	
	let signedMdKey = AuthenticationStore.getSignedMdKey();
	// Set the installation ID on the metadata store; get and Metadata application
	MetadataActions.setInstallationId(myBody.citdevDevinstallationid, signedMdKey, myBody.citdevMdgwMode);
	MetadataActions.setInstallationVersion(myBody.citdevDevinstallationversion);
	// @todo Potentially not required on Test/Prod.
	MetadataActions.pullFromDatabase(myBody.citdevApplicationid, 'applications');

	// Set the installation ID on the relationship store
	PageActions.setInstallationId(myBody.citdevDevinstallationid, signedMdKey, myBody.citdevMdgwMode);
	PageActions.setInstallationVersion(myBody.citdevDevinstallationversion);
	PageActions.pullFromDatabaseAll();

	// Set the installation ID on the table store
	TableActions.setInstallationId(myBody.citdevDevinstallationid, signedMdKey, myBody.citdevMdgwMode);
	TableActions.setInstallationVersion(myBody.citdevDevinstallationversion);
	TableActions.pullFromDatabaseAll();

	// Set the installation ID on the field store
	FieldActions.setInstallationId(myBody.citdevDevinstallationid, signedMdKey, myBody.citdevMdgwMode);
	FieldActions.setInstallationVersion(myBody.citdevDevinstallationversion);
	FieldActions.pullFromDatabaseAll(true, myBody.citdevUrlCommunity);

	// Set the installation ID on the relationship store
	RelationshipActions.setInstallationId(myBody.citdevDevinstallationid, signedMdKey, myBody.citdevMdgwMode);
	RelationshipActions.setInstallationVersion(myBody.citdevDevinstallationversion);
	RelationshipActions.pullFromDatabaseAll();

	// Set the field type path on the field type store
	FieldTypeActions.setFieldtypeConfigPath(myBody.citdevUrlCommunity + '/fieldtype-configuration.json');
	FieldTypeActions.pullFromDatabaseAll();

	// Set the installation ID on the logic functions store
	LogicFunctionActions.setInstallationId(myBody.citdevDevinstallationid, signedMdKey, myBody.citdevMdgwMode);
	LogicFunctionActions.setInstallationVersion(myBody.citdevDevinstallationversion);
	LogicFunctionActions.pullFromDatabaseAll();

	// make installation id available to QueryStore immediately before ContextStore
	QueryActions.setInstallationId(myBody.citdevDevinstallationid, signedMdKey, myBody.citdevMdgwMode);
	// QueryActions.browseTables();

	// Pull the consumable credits
	ConsumableCreditsAPIActions.getResources();

	// read the Actions and Expression Toolbox files.
	// ToolboxActions.loadActionToolbox(myBody.citdevUrlCommunity);
	// ToolboxActions.loadExpressionToolbox(myBody.citdevUrlCommunity);
	let actionToolboxURL = myBody.citdevUrlCommunity + '/action-toolbox.json';
	fetch(actionToolboxURL).then(function (response) {
		if (response.status === 200) {
			response.json().then(function (resultsObj) {
				window.actionToolbox = resultsObj;
			}).catch(function () {
				console.error('Error reading action toolbox, invalid JSON');
			});
		} else {
			console.error('Error reading action toolbox, error was : ' + response.status + ' - ' + response.statusText);
		}
	}).catch(function () {
		console.error('Error reading action toolbox from ' + actionToolboxURL);
	});

	const rootElement = document.getElementById('root');

	if(window.citDevFieldTypes) {
		// For now, put the fieldType Variant objects back where we had them...
		Object.keys(window.citDevFieldTypes).forEach(variantName => {
			window.citDev[variantName] = window.citDevFieldTypes[variantName];
		})

		// But later we can do this!
		// window.citDev.fieldTypeVariantComponents = window.citDevFieldTypes;
	} else {
		console.error('window.citDevFieldTypes not available when renderReact ran!');
	}

	// If we've pulled all pages and fields...
	if(PageStore.allPulledFromDatabase() && FieldStore.allPulledFromDatabase()) {
		// Start up React!
		renderReact(rootElement);
		// Receive interface actions JSON and perform specified actions
		runInterfaceActions(myBody.citdevInterfaceActions);
	} else {
		// If we haven't pulled yet... then set up a subscription and wait.

		// Manipulate the DOM to add in a loading message
		let div = document.createElement('DIV');
		div.id = 'loading-main';

		let animationDiv = document.createElement('IMG');
		animationDiv.className = 'loading-image';
		animationDiv.src = ContextStore.getUrlMedia() + "/loading-helix.gif";
		div.appendChild(animationDiv);

		let h1 = document.createElement('H1');
		h1.className = 'loading-title';
		let h1Text = document.createTextNode('Loading...');
		h1.appendChild(h1Text);

		let h3 = document.createElement('H3');
		h3.className = 'loading-subject';
		let h3Text = document.createTextNode('Loading application data');
		h3.appendChild(h3Text);

		div.appendChild(h1);
		div.appendChild(h3);

		rootElement.appendChild(div);

		let fieldSubscription, pageSubscription;
		function listener() {
			if(PageStore.allPulledFromDatabase() && FieldStore.allPulledFromDatabase()){
				fieldSubscription.remove();
				pageSubscription.remove();
				// Start up React!
				renderReact(rootElement);
				// Receive interface actions JSON and perform specified actions
				setTimeout(() => {
					runInterfaceActions(myBody.citdevInterfaceActions);
				}, 1);
			}
		}
		fieldSubscription = FieldStore.addListener(listener);
		pageSubscription = PageStore.addListener(listener);
	}
}

/**
 * Runs the interface actions from the body data tag
 * @param {*} interfaceActionsJSON 
 */
function runInterfaceActions(interfaceActionsJSON) {
	if(interfaceActionsJSON && interfaceActionsJSON !== '[INTERFACE-ACTIONS]') {
		let interfaceActions = [];
		try {
			interfaceActions = JSON.parse(decodeURI(interfaceActionsJSON));
		} catch(err) {
			console.warn('Unable to parse myBody.citdevInterfaceActions on page load. Value was', interfaceActionsJSON);
		}

		if(interfaceActions && Array.isArray(interfaceActions)) {
			let interfacePromises = interfaceActions.map(config => {
				return InterfaceActions[config.action].apply(this, [config.parameters]);
			});
	
			Promise.all(interfacePromises).catch(error => {
				console.warn('Error when running interface actions:', error);
			});
		}
	}
}

function renderReact(rootElement) {
	/*
	 * The /auth/(google|facebook)/callback is needed for changing the timing
	 * to load the react router but not load the page
	 */
	ReactDOM.render((
		<Router history={history}>
			<Switch>
				<Route path="/citdev/login" component={Login} />
				<Route exact path="/" render={renderPage} />
				<Route path="/auth/(google|facebook)/callback" render={waitForActions} />
				<Route path="/:pageId/:tableSchemaName/:recordId" render={renderPage} />
				<Route path="/:pageId" render={renderPage} />
				<Route component={NotFound} />
			</Switch>
		</Router>
	), rootElement);
}

function waitForActions() {
	return true;
}

function renderPage(routeProps) {
	//Keep the location path in case we need to replace /citdev/login once we are already authenticated 
	sessionStorage.setItem('redirectPath', routeProps.location.pathname);
	if (routeProps.location.state) {
		sessionStorage.setItem('redirectState', JSON.stringify(routeProps.location.state));
	} else {
		sessionStorage.removeItem('redirectState');
	}

	//Generate and pass the renderId
	let renderId = uuid.v4();

	//Read the pageId, tableSchemaName, and recordId from the path or the state 
	let pageId = '';
	let tableSchemaName = '';
	let recordId = '';
	if (routeProps.location.state && routeProps.location.state.pageId) {
		pageId = routeProps.location.state.pageId;
		tableSchemaName = routeProps.location.state.tableSchemaName ? routeProps.location.state.tableSchemaName : '';
		recordId = routeProps.location.state.recordId ? routeProps.location.state.recordId : '';
	} else {
		// explodes pathname into parts
		let parts = routeProps.location.pathname.split('/');
		pageId = parts[1];
		tableSchemaName = parts[2] ? parts[2] : '';
		recordId = parts[3] ? parts[3] : '';
	}

	onPageChange(pageId, tableSchemaName, recordId, renderId);
	return <Page renderId={renderId} dataRecordId={recordId} dataTableSchemaName={tableSchemaName} pageId={pageId} />;
}

/**
 * onPageChange - updates context with latest location props
 *
 * @param  {object} props props passed via React Router
 */
function onPageChange(pageId, tableSchemaName, recordId, renderId) {
	setTimeout(() => {

		let oldRenderId = ContextStore.getPageRenderId();
		//This is temporary until a more holistic approach to page boot strapping
		//can be implemented
		//Make sure to clear the all loaded flag first before any other context actions
		//or it will cause the page actions to fire early
		ContextActions.onAllRecordDataReceived(false, pageId, tableSchemaName, recordId);
		// Ignore it if this is a login page (temporary hack for ticket 10690)
		if (oldRenderId && oldRenderId !== renderId) {
			RenderActions.deleteRender(oldRenderId);
		}

		// Get page data from page store
		let pageObj = PageStore.get(pageId);
		let pageModeStoreHasPage = PageModeStore.hasPage(pageId);
		if(pageObj && pageModeStoreHasPage) {


			let isAuthenticated = localStorage.getItem('authenticated');
			
			// if not authenticated and not public page, redirect to login page
			if (!isAuthenticated && (!pageObj.allowPublicAccess || pageObj.allowPublicAccess === 'no')) {
				redirectToLoginPage();
				//bail out
				return;
			}

			// Generate the new data so it'll be ready when the grid initiates
			let attachedFieldsArr = ObjectUtils.getObjFromJSON(pageObj.attachedFields);

			// Initiate the record
			if(!recordId) {
				let recordData = {};
				if (PageModeStore.hasAvailableMode(pageId, 'add')) {
					// Use a "new" variable here so we don't overwrite recordID and continue processing later.  We want to 
					// "Wrap around" with the recordID from context store.
					recordId = uuid.v4();
					tableSchemaName = pageObj.tableSchemaName;
					recordData.recordId = recordId;
					if(Array.isArray(attachedFieldsArr)) {
						attachedFieldsArr.forEach(attachedField => { 
							let fieldIds = attachedField.recordIds ? attachedField.recordIds : [attachedField.recordId];
							fieldIds.forEach(fieldId => {
								let fieldObj = FieldStore.get(fieldId);
								if(fieldObj) {
									recordData[fieldObj.fieldSchemaName] = null;
								}
							})
						});
					}
					let loadData = {};
					loadData[tableSchemaName] = {};
					loadData[tableSchemaName][recordId] = recordData;
					// RenderActions.setRecord(renderId, recordId, tableSchemaName);
					// ContextActions.onContextChange(pageId, tableSchemaName, recordId);
					// RecordActions.createNewRecords(loadData);
				}
			}
	
			// Subscribe to changes in the context store, until it reads "record data loaded"
			// Then run the On Page Load Actions AND remove our subscription.
			let pageLoadRunning = false;

			// Update context with parts
			ContextActions.setPageRenderId(renderId);

			RenderActions.initiatePage(pageObj, recordId, tableSchemaName, renderId)
				.then(grids => {
					recordId = grids[0].dataRecordId;
					tableSchemaName = grids[0].dataTableSchemaName;
					ContextActions.onContextChange(pageId, tableSchemaName, recordId);

					let onPageLoad = PageStore.getAutomation(pageId, 'onPageLoad');
					if (onPageLoad && onPageLoad.js && !pageLoadRunning) {
						pageLoadRunning = true;
						setTimeout(() => {
							ActionProcessor.processAction({
								actionRecordId: pageId,
								actionTableSchemaName: 'page',
								hookId: 'onPageLoad',
								action: onPageLoad.js,
								renderId: renderId,
								recordId: recordId,
								tableSchemaName: tableSchemaName,
								namedContexts: {
									'startingContext': [{
										'recordId': recordId,
										'tableSchemaName': tableSchemaName
									}],
									'page': [{
										'recordId': recordId,
										'tableSchemaName': tableSchemaName
									}],
									'application': [{
										'recordId': ContextStore.getApplicationId(),
										'tableSchemaName': 'applications'
									}],
									'installation': [{
										'recordId': ContextStore.getInstallationId(),
										'tableSchemaName': 'installations'
									}],
									'currentUser': [{
										'recordId': AuthenticationStore.getUserId(),
										'tableSchemaName': 'users'
									}],
									'pagePageRecord': [{
										'recordId': recordId,
										'tableSchemaName': tableSchemaName
									}],
									'pageCurrentRecord': [{
										'recordId': recordId,
										'tableSchemaName': tableSchemaName
									}]
								}
							})
							.then(() => {
								pageLoadRunning = false;
							})
							.catch(error => {
								pageLoadRunning = false;
								console.warn('Page Load Action Processing Error: ' + (error.message ? error.message : error));
							});
						}, 75);
					}
				})
				.catch(console.error);
	
			
		} else if (pageObj) {
			// It's not in the PageModeStore yet; set up a watch and load it when it is
			let subscription = PageModeStore.addListener(() => {
				if (PageModeStore.hasPage(pageId)) {
					subscription.remove();
					onPageChange(pageId, tableSchemaName, recordId, renderId);
				}
			});
		} else {
			//If we return to a load data per page load we will need this code
			//otherwise it is redundant
			//PageActions.pullFromDatabase(pageId);
			
			//Setup a watch for the page store to make sure it is loaded and once it is call this again
			let subscription = PageStore.addListener(() => {
				if (PageStore.allPulledFromDatabase()) {
					subscription.remove();
					onPageChange(pageId, tableSchemaName, recordId, renderId);
				}
			});
		}
	}, 0);
}

/**
 * Helper function to redirect to the login page
 *
 */
function redirectToLoginPage() {
	let citDevLoginRegex = new RegExp(/\/citdev\/login$/);
	let loginPageId = ContextStore.getDefaultLoginPageId();
	if (loginPageId) {
		//If we are not already on the login page or the internal login page
		//redirect to it
		let defaultLoginRegex = new RegExp('/'+loginPageId+'$');
		if (!defaultLoginRegex.test(window.location.href) && 
			!citDevLoginRegex.test(window.location.href)) {
			InterfaceActions.replacePage({'pageId': loginPageId}); //replaceHistory: true
		}
	} else if (!citDevLoginRegex.test(window.location.href)) {
		// Go to Internal Login Page!
		InterfaceActions.replacePage({'url': '/citdev/login'});//replaceHistory: true
	}
}

// Watch for the Authentication Store to change (typically only during login and log events)
// and re-load the appropriate stores with the new signed MD Key.
AuthenticationStore.addListener(() => {
	let signedMdKey = AuthenticationStore.getSignedMdKey();
	let installationId = ContextStore.getInstallationId();
	let mdgwMode = ContextStore.getMDGWMode();

	// Add height to html - (Resolves Ticket 18454)
	document.getElementsByTagName('html')[0].style['height'] = '100%';

	MetadataActions.setInstallationId(installationId, signedMdKey, mdgwMode);
	PageActions.setInstallationId(installationId, signedMdKey, mdgwMode);
	TableActions.setInstallationId(installationId, signedMdKey, mdgwMode);
	FieldActions.setInstallationId(installationId, signedMdKey, mdgwMode);
	RelationshipActions.setInstallationId(installationId, signedMdKey, mdgwMode);
	LogicFunctionActions.setInstallationId(installationId, signedMdKey, mdgwMode);
	QueryActions.setInstallationId(installationId, signedMdKey, mdgwMode);
});

// Listen for the stores to emit changes...
// Listen for the stores to emit changes...
[ContextStore, PageStore, FieldStore, PageModeStore, RecordStore].forEach(storeToWatch => {
	storeToWatch.addListener(() => {
		// Not ready yet if the Page or Field Stores aren't completely pulled.
		if(!PageStore.allPulledFromDatabase() || !FieldStore.allPulledFromDatabase()) {
			return false;
		}

		// Is the user authenticated?
		let isAuthenticated = localStorage.getItem('authenticated');

		// Get page data once Context Store has loaded it
		let pageId = ContextStore.getPageId();
		let renderId = ContextStore.getPageRenderId();
		if (!pageId) {

			let myBody = document.querySelector('body').dataset;
			
			//If page was not found on load AND we are not authed, redirect to login page. Only do this if we have no interface actions
			if(!isAuthenticated && (!myBody.citdevInterfaceActions || myBody.citdevInterfaceActions === '[INTERFACE-ACTIONS]')){
				//Store the redirect path for after login:
				sessionStorage.setItem('redirectPath', '/');

				//Redirect to Login Page
				redirectToLoginPage();
			} 

			// Terminate regardless
			return;
		}

		let pageObj = PageStore.get(pageId);

		// Parse data and display
		if (pageObj) {
			// If not authenticated and not public page, redirect to login page
			if(!isAuthenticated && (!pageObj.allowPublicAccess || pageObj.allowPublicAccess === 'no')){
				redirectToLoginPage();
			}

			// LOAD SETTINGS DATA
			let attachedFieldsArr = ObjectUtils.getObjFromJSON(pageObj.attachedFields);
			
			// LOAD RECORD DATA
			let recordId = ContextStore.getRecordId();
			let tableSchemaName = ContextStore.getTableSchemaName();
			let recordData = {};
			let dashboardPage = false;
			if (!recordId && renderId) {
				if (PageModeStore.hasAvailableMode(pageId, 'add')) {
					// Use a "new" variable here so we don't overwrite recordID and continue processing later.  We want to 
					// "Wrap around" with the recordID from context store.
					let newRecordId = uuid.v4();
					tableSchemaName = pageObj.tableSchemaName;
					recordData.recordId = newRecordId;
					if(Array.isArray(attachedFieldsArr)) {
						attachedFieldsArr.forEach(attachedField => { 
							let fieldIds = attachedField.recordIds ? attachedField.recordIds : [attachedField.recordId];
							fieldIds.forEach(fieldId => {
								let fieldObj = FieldStore.get(fieldId);
								if(fieldObj) {
									recordData[fieldObj.fieldSchemaName] = null;
								}
							})
						});
					}
					setTimeout(function() {
						let loadData = {};
						loadData[tableSchemaName] = {};
						loadData[tableSchemaName][newRecordId] = recordData;
						RenderActions.setRecord(renderId, newRecordId, tableSchemaName);
						ContextActions.onContextChange(pageId, tableSchemaName, newRecordId);
						RecordActions.createNewRecords(loadData);
					},1);
				} else {
					dashboardPage = true;
				}
			} else {
				recordData = RecordStore.getRecord(tableSchemaName, recordId);
			}

			let fieldSchemaNamesToGet = [];

			// Loop through components to find fields which need data lookups
			if(Array.isArray(attachedFieldsArr)) {
				attachedFieldsArr.forEach(attachedField => {
					let fieldIds = attachedField.recordIds ? attachedField.recordIds : [attachedField.recordId];
					fieldIds.forEach(fieldId => {
						if(fieldId) {
							let fieldObj = FieldStore.get(fieldId);
							if(fieldObj) {
								let fieldSchemaName = fieldObj.fieldSchemaName;
								if (fieldSchemaName) {
									let fieldHasData = FieldStore.getHasData(fieldId);
									if(!fieldHasData) {
										// Also get dynamic selection fields
										let fieldTypeObj = fieldObj ? FieldTypeStore.get(fieldObj.fieldType) : {};
										fieldHasData = fieldTypeObj.dataType === 'relationship';
									}
									// If our field requires data && we don't yet have ANY data... || we have data, we just don't have THIS piece of data..
									if (fieldHasData && (!recordData || typeof recordData[fieldSchemaName] === 'undefined')) {
										fieldSchemaNamesToGet.push(fieldSchemaName);
									}
								}
							} else {
								console.warn('Unable to find on page attachedField', attachedField);
							}
						}
					});
				});
			}

			// If we have loaded all the config data
			if (renderId && (recordId || dashboardPage)) {
				// If there are pieces of field data we need to get...
				if (dashboardPage) {
					// Dashboard pages are not 'about' a record, so we're done here.
					setTimeout(function () {
						ContextActions.onAllRecordDataReceived(true);
					}, 1);
				} else if (fieldSchemaNamesToGet.length > 0) {
					// Get the field data we need for this record
					RecordsAPI.getRecord(ContextStore.getTableSchemaName(), 
						ContextStore.getRecordId(), fieldSchemaNamesToGet);
					// No further handling done here; the update to the Record Store will be triggered by this listener upon completion
				} else if (!recordData || Object.keys(recordData).length === 0) {
					// We don't need any record data, and we never will for this page, so set up the empty record.
					setTimeout(function () {
						let emptyRecord = {};
						emptyRecord[tableSchemaName] = {};
						emptyRecord[tableSchemaName][recordId] = {
							recordId: recordId
						};
						RecordActions.onDataLoaded(emptyRecord);
						// Tell the context store we have all the record data for this page.
						if(!ContextStore.getAllRecordDataReceived()) {
							ContextActions.onAllRecordDataReceived(true);
						}
					}, 1);
					// SetTimeout to avoid dispatch in a dispatch
				} else {
					// Tell the context store we have all the record data for this page.
					if(!ContextStore.getAllRecordDataReceived()) {
						setTimeout(function () {
							ContextActions.onAllRecordDataReceived(true);
						}, 1);
					}
				}
			}
		}
	});
});

//Wait for the dom to be ready and load the Page
if (document.addEventListener) {
	document.addEventListener('DOMContentLoaded', function () {
		domReady();
	}, false);
	// If IE event model is used
} else if (document.attachEvent) {
	// ensure firing before onload
	document.attachEvent('onreadystatechange', function () {
		if (document.readyState === 'complete') {
			domReady();
		}
	});
}
