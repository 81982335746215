import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {Container} from 'flux/utils';

import {AdminSettingsActions} from '../actions';
import {AdminSettingsStore} from '../stores';


/**
 * ModalContainer that displays stacked modals at AdminSettingsStore
 */
class ModalContainer extends Component {
  /**
   * Creates instance of ModalContainer and binds methods
   */
  constructor(props) {
    super(props);
    this._close = this._close.bind(this);
    this._onKeyDown = this._onKeyDown.bind(this);
    this._renderModal = this._renderModal.bind(this);
  }

  /**
   * static Get array of stores to listen
   */
  static getStores() {
    return [AdminSettingsStore];
  }

  /**
   * static Calculates the state based on listened stores
   */
  static calculateState() {
    return {
      modals: AdminSettingsStore.getStackedModals()
    };
  }

  componentDidUpdate() {
    let modalRef = 'modal-' + (this.state.modals.length - 1),
        upmostModal = this.refs[modalRef];

    if (upmostModal) {
      ReactDOM.findDOMNode(upmostModal).focus();
    }
  }

  /**
   * Renders available Modals on AdminSettingsStore
   */
  render() {
    let {modals} = this.state;

    if (!modals.length) {
      return null;
    }

    return (
      <div className="stacked-modals cd-tools">
        {this._renderModal(0)}
      </div>
    );
  }

  /**
   * private Triggers the most recent opened modal
   */
  _close(event) {
    let {target} = event,
        classes = target.className;

    if (classes.includes && classes.includes('modal') && classes.includes('show')) {
      AdminSettingsActions.closeModal(target.id);
    }
  }

  /**
   * private onKeydown - Handles keydown event
   *
   * @param  {object} event
   */
  _onKeyDown(event) {
    let {key} = event;

    if (key === 'Escape') {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      AdminSettingsActions.closeModal();
    }
  }
  /**
   * private Render each level of modal
   */
  _renderModal(depth) {
    let {modals} = this.state,
        modal = modals[depth];

    if (!modal) {
      return null;
    }

    return [
      <div
        id={modal.modalId}
        ref={'modal-' + depth}
        key={depth}
        className="maps-modal modal fade show"
        // onClick={this._close} // Removed because people kept accidentally closing their logic while working. (Ticket WX3487)
        onKeyDown={this._onKeyDown}
        tabIndex={depth}>
        {modal.component}
        {this._renderModal(depth + 1)}
      </div>,
      <div key={`backdrop-${depth}`} className="modal-backdrop fade show"></div>
    ];
  }
}

const container = Container.create(ModalContainer);
export default container;
