import React, { Component } from 'react';
import {Container} from 'flux/utils';
import { map } from 'lodash';
import uuid from 'uuid';

/* Stores */
import ContextStore from '../../../stores/context-store';

// Actions
import {
	AdminSettingsActions,
	MapActions,
	TableActions
} from '../../../actions';

// Constants
import Help from '../../../constants/help-upgrade-constants';

// Stores
import { 
	AdminSettingsStore, 
	MapStore, 
	TableStore 
} from '../../../stores';

// Utils
import UIUtils from '../../../utils/ui-utils';

/**
 * Container for DataMap
 *
 * @class DataMapContainer
 * @extends {Component}
 */
class TablesDataDashboardTabContainer extends Component {
	/**
	 * Creates an instance of DataMapContainer
	 *
	 * @memberOf DataMapContainer
	 */
	constructor(props) {
		super(props);
		this.addTable = this.addTable.bind(this);
		this.onChangeSearch = this.onChangeSearch.bind(this);
		this.renderTable = this.renderTable.bind(this);
	}

	/**
	 * Loads the Stores to watch
	 *
	 * @static
	 * @returns {Array of Object}
	 *
	 * @memberOf DataMapContainer
	 */
	static getStores() {
		return [ AdminSettingsStore, MapStore, TableStore ];
	}

	static calculateState(prevState) {
		let tableArray = TableStore.getAllArray();
		let search = MapStore.getSearch();

		if(search && search.length > 0) {
			// Filter the tables, if there is a search...
			tableArray = tableArray.filter((table) => {
				let searchableValue = '';
				// Add singular name to the searchable elements
				if(table.singularName && table.singularName.length) {
					searchableValue += table.singularName + ' ';
				}
				// Add plural name to the searchable elements
				if(table.pluralName && table.pluralName.length) {
					searchableValue += table.pluralName + ' ';
				}

				// Add tableSchemaName name to the searchable elements
				searchableValue += table.tableSchemaName;

				// Check the searchableValue to see if it has our search term in it...
				if(searchableValue.toLowerCase().includes(search.toLowerCase())) {
					return true;
				}
				return false;
			});
		}

		return {
			search: search,
			tableArray: tableArray
		};
	}

	addTable() {
		const iconList = [
			'microchip', 'user-o', 'bank', 'briefcase', 'car', 'calendar', 'cogs', 'coffee', 'cog', 'credit-card', 'cube', 'envelope-o',
			'film', 'fire', 'gift', 'group', 'globe', 'laptop', 'map-o', 'plane', 'rocket', 'ship', 'sitemap', 'snowflake-o', 'soccer-ball-o',
			'space-shuttle', 'sticky-note-o', 'tags', 'tag', 'television', 'ticket', 'tint', 'tree', 'trophy', 'user-secret'
		];
		let randMult = Math.random();
		let recordId = uuid.v4(),
			newTable = {
				recordId: recordId,
				tableSchemaName: '',
				singularName: '',
				pluralName: '',
				roles: '',
				icon: iconList[Math.round(randMult * iconList.length)],
				new: true,
				crudInterfaces: JSON.stringify({
					createShortText: true,
					sampleRecord: true,
					searchAdvanced: true
				})
			};

		TableActions.pushToStore(recordId, newTable);

		// Open the Settings Panel
		UIUtils.openSettingsPanel('table', recordId, 'table');
		AdminSettingsActions.onSettingChange('pluralName', 'e97b9453-08e2-4ca8-94fb-36d8867f4fc4');
	}

	/**
	 * Handles typing into the search box.
	 * @param
	 */
	onChangeSearch(event) {
		let search = event.target.value;
		MapActions.search(search);
	}

	/**
	 * Render a Table
	 * @param {Array} table The table to render.
	 */
	renderTable(table, index) {
		let iconClass = table.icon;
		return (<li key={index} className="table-name-item">
				<div className="list-item-row">
					<div className="mb-0 py-1 d-flex align-items-center">
						<span className={"table-icon mr-1 fa fa-" + iconClass}></span>
						<h4 className="bold">{table.pluralName}</h4>
					</div>
					<div className="action-icon">
						<span onClick={() => { this.showTable(table.recordId) }} title="Update Table">
							<img height='16' width='16' src={ContextStore.getUrlFontawesome() + "/database.svg"} alt="" />
						</span>
					</div>
				</div>
			</li>
		);
	}

	showTable(tableId) {
		// targetOverlay, 
		// recordId, tableSchemaName, 
		// parentRecordId, parentTableSchemaName, mainTab, subSettingSchemaName, 
		// subSettingIndex, renderId, attachmentId
		UIUtils.openSettingsPanel('table',
		tableId, 'table',
		undefined, undefined, undefined, undefined,
		undefined, undefined, undefined
		);

		// Unhide the settings, always
		AdminSettingsActions.onSettingsListHideChange(false);
	}

	/**
	 * Render method for DataMapContainer
	 *
	 * @memberOf DataMapContainer
	 */
	render() {
		let { tableArray, search } = this.state;

		// Sort by plural name
		tableArray = tableArray.sort((a,b) => (a.pluralName > b.pluralName) ? 1 : -1 );

		let noSearchFound = null;
		if(tableArray.length === 0 && search.length) {
			noSearchFound = <li className="no-search-found" style={{ color: 'white'}}><h4>No Results for '{search}' found.</h4></li>
		}

		return (
			<div id="data-map__content" className="map">
				<div className="cd-search-container">
					<input className="form-control select-group-by" placeholder="Search" type="text" value={search} onChange={this.onChangeSearch} />
				</div>
				<div className="section-header" key="expand-collapse-tools">
					<div className="d-flex justify-content-end align-items-center">
						<h5 className="bold">Tables</h5>
						<div className="d-flex">
							<div title="Table Help" className="info-icon ml-2" onClick={() => { UIUtils.onHelpClick(Help.HELP_DASHBOARD_DATA_TABLES); }}>
								<i className="fa fa-info-circle mr-1"></i> | 
							</div>
							<div title="Create Table" data-toggle="modal" onClick={this.addTable} >
								<i className="fa fa-plus-circle ml-1"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="list-content-wrapper">
					<ol>
						{ map(tableArray, this.renderTable) }
						{ noSearchFound }
					</ol>
				</div>
			</div>
		);
	}
}

const container = Container.create(TablesDataDashboardTabContainer);
export default container;
