var keyMirror = require('keymirror');

// Define action constants
var DiscussionConstants = keyMirror({
	'DISCUSSION_DELETE_FROM_STORE': null,
	'DISCUSSION_PULL_FROM_DATABASE': null,
	'DISCUSSION_PULL_FROM_DATABASE_ALL': null,
	'DISCUSSION_PULL_ERROR': null,
	'DISCUSSION_PUSH_TO_DATABASE': null,
	'DISCUSSION_PUSH_TO_STORE': null,
	'DISCUSSION_COMMENT_PULL_FROM_DATABASE_ALL': null,
	'DISCUSSION_COMMENT_PUSH_TO_STORE': null,
	'DISCUSSION_COMMENT_PUSH_TO_DATABASE': null
});
module.exports = DiscussionConstants;
