import { FieldStore, PageStore, FieldTypeStore } from "../stores";
import {FieldActions, PageActions, InterfaceActions} from '../actions';
import ObjectUtils from './object-utils';
import uuid from 'uuid';

export default class ConversionUtils {
	/**
	 * Converts the attached fields on a component to the new format.
	 *
	 * @static
	 * @param {string} componentId The ID of the component whose attached fields to convert
	 * @param {string} componentType The component type of the component whose attached fields to convert. ('field' or 'page')
	 * @memberof ConversionUtils
	 */
	static convertAttachedFields(componentId, componentType) {
		let componentObj = componentType === 'field' ? FieldStore.get(componentId) : PageStore.get(componentId);
		let conversionNotification = InterfaceActions.stickyNotification({
			level: 'warning',
			message: 'Enabling Custom Visibility Logic...'
		});
		let attachedFields = ObjectUtils.getObjFromJSON(componentObj.attachedFields);
			let childConfigurations = componentType === 'field' ?
				FieldStore.getChildConfigurations(componentId) :
				PageStore.getChildConfigurations(componentId);
		if(
			componentObj && componentObj.attachedFields &&
			// Don't break content tabs and fields, which have their tabs + fields stored as "attachedFields" for unclear reasons
			(componentType === 'page' || ['bb5bedc3-44d1-4e4c-9c40-561a675173b1', '846b747e-25a0-40df-8115-af4a00a1cab5'].indexOf(componentObj.fieldType) === -1)
		) {
			let fieldPositions = ObjectUtils.getObjFromJSON(componentObj.fieldPosition);
			attachedFields.forEach(attachedField => {
				if(!attachedField.attachmentId) {
					attachedField.attachmentId = uuid.v4();
					attachedField.recordIds = [];
					if(attachedField.recordId) {
						// Update any of the existing field child settings
						if(childConfigurations[attachedField.recordId]) {
							// We need to update only the settings from the appearance settings and leave automation, visibility, etc. alone
							childConfigurations[attachedField.attachmentId] = {};
							
							let fieldObj = FieldStore.get(attachedField.recordId) || {};
							let fieldTypeObj = FieldTypeStore.get(fieldObj.fieldType) || {};
							let settings = fieldTypeObj.settings || [];

							settings = settings.map(({recordId}) => FieldStore.get(recordId).fieldSchemaName);

							settings.forEach(settingSchemaName => {
								if(childConfigurations[attachedField.recordId].hasOwnProperty(settingSchemaName)) {
									childConfigurations[attachedField.attachmentId][settingSchemaName] = childConfigurations[attachedField.recordId][settingSchemaName];
									delete childConfigurations[attachedField.recordId][settingSchemaName];
								}
							});
						}

						// Update the field positions, too
						Object.keys(fieldPositions).forEach(key => {
							let positions = fieldPositions[key];
							if(positions && Array.isArray(positions)) {
								positions.forEach(position => {
									if(position.i === attachedField.recordId) {
										position.i = attachedField.attachmentId;
									}
								});
							}
						});
						
						// Change the value in attachedFields
						attachedField.recordIds.push(attachedField.recordId);
						delete attachedField.recordId;
					}
				}
			});

			componentObj.attachedFields = JSON.stringify(attachedFields);
			// @TODO: This is backwards compatible and can stay for now, but at some point we may need to update this for child configuration splitting
			componentObj.childConfigurations = JSON.stringify(childConfigurations);
			componentObj.fieldPosition = JSON.stringify(fieldPositions);
		} else {
			// Content tabs and fields, in order to maintain backwards compatibility, must have an attachment ID which matches the field ID for their primary attached container
			// Aside from the values saved in these fields, nothing relies on this, and there is no reason this need be the case going forward, only when converting
			attachedFields.forEach(attachedField => {
				if(!attachedField.attachmentId) {
					attachedField.attachmentId = attachedField.recordId || uuid.v4();
					attachedField.recordIds = [];
					if(attachedField.recordId) {

						// Change the value in attachedFields
						attachedField.recordIds.push(attachedField.recordId);
						delete attachedField.recordId;
					}
				}
			});

			// Because we are keeping the ID the same, we do not need to change the child settings or field positions keyed by it
			componentObj.attachedFields = JSON.stringify(attachedFields);
		}

		let pushPromise = Promise.resolve();
			if(componentType === 'page') {
				PageActions.pushToStore(componentId, componentObj);
				pushPromise = PageActions.pushToDatabasePromise(PageStore.get(componentId, true));
			} else if (componentType === 'field') {
				FieldActions.pushToStore(componentId, componentObj);
				pushPromise = FieldActions.pushToDatabasePromise(FieldStore.get(componentId, true));
			}

			pushPromise
				.then(() => {
					InterfaceActions.reloadTarget({
						target: 'page'
					});
				})
				.catch(error => {
					InterfaceActions.clearStickyNotification(conversionNotification);
					InterfaceActions.stickyNotification({
						level: 'error',
						message: 'Error enabling custom visibility logic for ' + componentType + ' ' + (componentType === 'field' ? componentObj.fieldLabel : componentObj.name) + '. Please check your console for more information.'
					});
					console.error('Error enabling custom visibility logic for %s %s', componentType, componentId, error);
				});
	}
};