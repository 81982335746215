import AppDispatcher from '../dispatcher/app-dispatcher';
import AppearanceAssistantConstants from '../constants/appearance-assistant-constants';
import Immutable from 'immutable';
// import {UIUtils} from '../utils/ui-utils';

const AppearanceAssistantActions = {

	/**
	 * When the Input changes
	 * 
	 * @param {string} input 
	 */
	onInputChange(input) {
		AppDispatcher.dispatch(Immutable.fromJS({
			'type': AppearanceAssistantConstants.INPUT_CHANGE,
			'input': input
		}));
	},
};

export default AppearanceAssistantActions;