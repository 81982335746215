import AppDispatcher from '../dispatcher/app-dispatcher';
import { ReduceStore } from 'flux/utils';
import Immutable from 'immutable';
import AssistantResultsConstants from '../constants/assistant-results-constants';
import AdminSettingsConstants from '../constants/admin-settings-constants';
// import UIUtils from '../utils/ui-utils';

/**
 * UI store that contains assistant results
 *
 * @class AssistantStore
 * @extends {ReduceStore}
 */
class AssistantResultsStore extends ReduceStore {
	/**
	 * getInitialState - initial state for AssistantStore
	 *
	 * @return {Object}  event
	 */
	getInitialState() {
		return Immutable.Map({
			results: Immutable.Map(),
			analyzerResults: Immutable.Map()
		});
	}
	/**
	 * Called every time an action is dispatched (for any store)
	 *
	 * @param {Object} state - current state of this store
	 * @param {Object} action - action that's coming in
	 * @returns {Object} new state after the action has been processed.
	 */
	reduce(state, action) {
		// console.log('Reducing with action', action.toJS());
		switch (action.get('type')) {
			case AssistantResultsConstants.ASSISTANT_UPSERT_RESULTS: {
				// Read the new/updates to our results
				let clearResults = action.get('clearResults'),
					overrideProperties = action.get('overrideProperties'),
					resultsArray = action.get('results').toJS(),
					resultsState = Immutable.Map().toJS();
				if(!clearResults) {
					resultsState = state.get('results').toJS();
				}

				if(Array.isArray(resultsArray)) {
					resultsArray.forEach(resultObj => {
						if(resultObj.resultId) {
							// Update OR Insert the result in the state['results'][resultId] spot
							let existingResult = resultsState[resultObj.resultId];
							
							// Update
							if(existingResult !== undefined) {
								// If we have a score to add...
								if(resultObj.score !== undefined) {
									// Update our score with the new score, if we have one and don't mark this as override.. otherwise just use the new score.
									resultsState[resultObj.resultId].score = (resultsState[resultObj.resultId].score && !overrideProperties ? 
										resultsState[resultObj.resultId].score + resultObj.score : resultObj.score);
								}
								// If we have a score description to append...
								if(resultObj.scoreDescription) {
									// and our result object already has a scoreDescription, and it's an array, and it's not marked as override...
									if(resultsState[resultObj.resultId].scoreDescription && 
										Array.isArray(resultsState[resultObj.resultId].scoreDescription) &&
										!overrideProperties) {
											// Append the score description arrays together.
											resultsState[resultObj.resultId].scoreDescription = 
											resultsState[resultObj.resultId].scoreDescription.concat(resultObj.scoreDescription);
										} else {
											// If our state didn't have a score description, or it wasnt an array.. 
											resultsState[resultObj.resultId].scoreDescription = resultObj.scoreDescription;
										}
								}
								resultsState[resultObj.resultId].NLPInput = resultObj.NLPInput;
								resultsState[resultObj.resultId].label = resultObj.label;
								// console.log('New object after upsert', resultsState[resultObj.resultId]);
							} else {
								// Insert
								resultsState[resultObj.resultId] = resultObj;
							}
						}
					});
				}
				return state.setIn(['results'], Immutable.fromJS(resultsState));
			}
			case AssistantResultsConstants.ANALYZER_UPSERT_RESULTS: {
				let analyzerResults = action.get('analyzerResults');
				// console.log('Dispatching with new analyzerResults', analyzerResults.toJS());
				return state.set('analyzerResults', analyzerResults);
			}
			case AdminSettingsConstants.RIGHT_PANEL_CHANGE: {
				let isRightPanelOpen = action.get('isRightPanelOpen');
				if(!isRightPanelOpen) {
					return this.getInitialState();
				}
				return state;
			}
			case AssistantResultsConstants.NEW_CONNECTION: {
				let mostRecentConnection = action.get('mostRecentConnection');
				// console.log('Setting new mostRecentConnection to', mostRecentConnection);
				return state.set('mostRecentConnection', mostRecentConnection);
			}
			default: {
				return state;
			}
		}
	}
	/**
	 * Gets the entire store as an object
	 *
	 * @returns {Object} current store as an object
	 */
	getAll() {
		return this.getState().get('results').toJS();
	}
	/**
	 * Gets the entire store as an array, sorted
	 *
	 * @returns {Array} current store as an array
	 */
	getAllArray() {
		return this._getSortedResults(this.getState().get('results').toList().toJS());
	}

	/**
	 * Gets the analyzer results from the store. Used to display the analyzer
	 */
	getAnalyzerResults() {
		return this.getState().get('analyzerResults').toJS();
	}

	/**
	 * Get the ID of the most recent connection.
	 * 
	 * @returns {string} UUID corresponding to ID of most recent connection
	 * @memberof AssistantResultsStore
	 */
	getMostRecentConnection() {
		return this.getState().get('mostRecentConnection');
	}

	/**
	 * Sort search results by score, then by name.
	 * 
	 * @param {Array} rawResults Array of Result Objects.
	 * @returns {Array} Sorted result Array
	 * @memberof AssistantResultsStore
	 */
	_getSortedResults(rawResults) {
		// Sort the results by pattern name
		return rawResults.sort(function(a,b){
			if(a.score !== b.score) {
				return b.score - a.score;
			} else if(a.label.toLowerCase() < b.label.toLowerCase()) { 
				return -1;
			}
			return 1;
		});
	}
}	

const instance = new AssistantResultsStore(AppDispatcher);
export default instance;